import React from 'react';
import { GetWalletLogic } from '../walletController';
import IconToolTip from '../../../common/IconToolTip';
import ButtonScogoPrimary from '../../../common/Buttons/ButtonScogoPrimary';
import ButtonScogoPrimaryOutlined from '../../../common/Buttons/ButtonScogoPrimaryOutlined';
import { useSelector } from 'react-redux';
import { getIsDisableActions } from '../../../utils/common';

export default function WalletActionButtons(props) {
    const { handleRequestStatement, handleSendToBank, handlePay, handleAddMoney, showRequestStatementButton } = props;
    const isToShowAddMoneyButton = GetWalletLogic('isToShowAddMoneyButton');
    const isToShowPayButton = GetWalletLogic('isToShowPayButton');
    const isToShowSendToBankButton = GetWalletLogic('isToShowSendToBankButton');
    const isToShowPayFeInPayButton = GetWalletLogic('isToShowPayFeInPayButton');
    const isToShowPayScogoInPayButton = GetWalletLogic('isToShowPayScogoInPayButton');
    const { loggedUser } = useSelector((state) => state.auth);

    const isDisableActions = getIsDisableActions(loggedUser);

    const isSendToBankDisabled = isDisableActions || loggedUser.is_withdraw_disabled === 1;

    const getPayButtonText = () => {
        let text = 'Pay';
        if (isToShowPayFeInPayButton) text = 'Pay FE';
        if (isToShowPayScogoInPayButton) text = 'Pay Scogo';
        return text;
    };

    const getRequestStatementButton = () => {
        let button = (
            <ButtonScogoPrimaryOutlined
                textOrComponent='Request Statement'
                onClick={handleRequestStatement}
                disabled={!showRequestStatementButton || isDisableActions}
                buttonIcon={'./img/question_mark.svg'}
            />
        );

        if (!showRequestStatementButton)
            return (
                <IconToolTip title='No transactions found in the selected date range'>
                    <div>{button}</div>
                </IconToolTip>
            );
        return button;
    };

    return (
        <div>
            <div className='flex gap-2'>
                {isToShowAddMoneyButton && <ButtonScogoPrimary textOrComponent='Add Money' onClick={handleAddMoney} buttonIcon={'./img/plus.svg'} disabled={isDisableActions} />}
                {isToShowSendToBankButton && (
                    <ButtonScogoPrimary textOrComponent='Send To Bank' onClick={handleSendToBank} buttonIcon={'./img/rupee2.svg'} disabled={isSendToBankDisabled} />
                )}
                {isToShowPayButton && <ButtonScogoPrimary textOrComponent={getPayButtonText()} onClick={handlePay} buttonIcon={'./img/rupee2.svg'} disabled={isDisableActions} />}
                {getRequestStatementButton()}
            </div>
        </div>
    );
}
