const urls = {
    SP: {
        getCustomersAddons: { method: 'get', url: 'customer/{customerId}/addOnUsers' },
        addCities: { method: 'post', url: 'servicePartner/{id}/locations' },
        getExistingCities: { method: 'get', url: 'servicePartner/{id}/locations' },
        getFieldEngineers: { method: 'get', url: 'servicePartner/{id}/fieldEngineers/0' },
        getProfile: { method: 'get', url: 'servicePartner/{id}' },
        verifyPayments: { method: 'post', url: 'servicePartner/{spId}/bank/{bankId}/verify' },
        listEligibleSPs: { method: 'post', url: 'ticketAssignment/getEligbleSP' },
        updateDetails: { method: 'post', url: 'servicePartner/newOnBoarding' },
        updateStateDetails: { method: 'post', url: 'servicePartner/{id}/addStateLocations' },
        updateCustomerStatus: { method: 'post', url: 'servicePartner/underCustomer/{customerId}' },
    },
    auth: {
        checkIfUserIsRegistered: { method: 'post', url: 'auth/sentOtp' },
        getOTP: { method: 'post', url: 'auth/sentOtp' },
        sendForgotPasswordEmail: { method: 'post', url: 'auth/forgotPassword' },
        changePassword: { method: 'post', url: 'auth/forgotPasswordChange' },
        signInWithOTP: { method: 'post', url: 'auth/otpLogin' },
        signInWithPassword: { method: 'post', url: 'auth' },
        getCustomerStatus: { method: 'get', url: 'customer/isCustomerDetailPending' },
        userprofile: { method: 'get', url: 'auth' },
        updateProfile: { method: 'put', url: 'profile' },
        getUser: { method: 'get', url: 'auth/{userId}' },
        getBankDetails: { method: 'get', url: 'servicePartner/{id}/bank' },
        registerWithMobile: { method: 'post', url: 'auth/registerWithMobile' },
        signUpWithOtp: { method: 'post', url: 'auth/processMobileRegister' },
        getSPOnboardingStatus: { method: 'get', url: 'servicePartner/onBoardingStatus' },
        getFEOnboardingStatus: { method: 'get', url: 'fieldEngineer/onBoardingStatus' },
        updateEmail: { method: 'post', url: 'auth/updateMail' },
        uploadIdProofs: { method: 'post', url: 'userProof' },
        userLogout: { method: 'get', url: 'auth/logout' },
    },
    payments: {
        listAll: { method: 'get', url: 'tickets/search' },
    },
    states: {
        listAll: { method: 'get', url: 'states' },
    },

    cities: {
        getAll: { method: 'get', url: 'states/0/city' },
        getCityFromPincode: { method: 'post', url: 'projects/pincodeDetails' },
        getStateCities: { method: 'get', url: 'states/{stateId}/city' },
    },
    endUsers: {
        listAll: { method: 'get', url: 'enduser' },
    },

    tickets: {
        boqList: { method: 'get', url: 'tickets/listAllBoqAssets' },
        listSPEligibleTickets: { method: 'get', url: 'ticketAssignment/getEligibleTickets' },
        getExecDateChangeRemarks: { method: 'get', url: 'executionDateNotes/comments' },
        listSPTickets: { method: 'get', url: 'tickets/getSPAcceptedTickets' },
        listFETickets: { method: 'get', url: 'tickets/getFEAcceptedTickets' },
        viewTicket: { method: 'get', url: 'tickets/viewTicket' },
        getExecDateTimeline: { method: 'post', url: 'executionDateNotes/fetchExecutionDateNotesByTicket' },
        generateReport: { method: 'get', url: 'projects/{ticketId}/sign_off_generated_report' },
        getTicketDetails: { method: 'get', url: 'tickets/viewTicket' },
        statusWiseTicketCount: { method: 'get', url: 'ticketAssignment/statusWiseTicketCount' },
        feTicketCount: { method: 'get', url: 'tickets/getFETicketsCount' },
        getAssetConfigTemplate: { method: 'get', url: 'configManagement/fetchAssetConfigTemplate/{ticketId}' },
        editExecutionDate: { method: 'post', url: 'ticketAssignment/editTicketExecutionDate' },
        bulkEditExecutionDate: { method: 'post', url: 'ticketAssignment/bulkEditTicketExecutionDate' },
        assignToScogo: { method: 'post', url: 'ticketAssignment/customerAssignToScogo' },
        bulkAssignToScogo: { method: 'post', url: 'ticketAssignment/bulkCustomerAssignToScogo' },
        customerSignOff: { method: 'post', url: 'tickets/customerSignoffTicketAcceptReject' },
        bulkCloneTickets: { method: 'post', url: 'tickets/bulkCloneTicket' },
        editTicket: { method: 'post', url: 'tickets/editTicket' },
        addSites: { method: 'post', url: 'projects/addSitesInProject' },
        updateTicketAllowance: { method: 'post', url: 'tickets/updateTicketAllowance' },
        updateRLCourierDetails: { method: 'post', url: 'ticketExecution/updateRLTicketStepData' },
    },
    project: {
        create: { method: 'post', url: 'projects/create' },
        getProjectList: { method: 'get', url: 'projects/getOngoingProjectList' },
        listAll: { method: 'get', url: 'projects/listAll' },
        getCount: { method: 'get', url: 'projects/statusWiseCount' },
        listPrerequisite: { method: 'get', url: 'prerequisite' },
        savePreRequisite: { method: 'post', url: 'projects/savePreReq' },
        editProject: { method: 'post', url: 'projects/editProject' },
        listAllTemplates: { method: 'post', url: 'projects/templates' },
        getSOWDocument: { method: 'get', url: 'projects/{ticketId}/sow_document' },
        getSignOffSampleReport: { method: 'get', url: 'projects/{ticketId}/sign_off_sample' },
        bulkUploadSite: { method: 'post', url: 'projects/addBulkSitesInProject' },
    },
    customers: {
        listAll: { method: 'get', url: 'customers' },
        listAllVendors: { method: 'get', url: 'customer/{customer_id}/servicePartners' },
        getProfile: { method: 'get', url: 'customer/{customerId}' },
        updateDetails: { method: 'put', url: 'customer' },
        uploadLogo: { method: 'post', url: 'customerLogo' },
    },

    services: {
        listAll: { method: 'get', url: 'services' },
    },

    usecases: {
        getAll: { method: 'get', url: 'usecase' },
    },

    invitations: {
        sendInvite: { method: 'post', url: 'invitation/bulk/users' },
        inviteCustomersEndUsers: { method: 'post', url: 'customer/inviteEndUser' },
        getUsersList: { method: 'get', url: 'invitation/users' },
        setEnalbeDisable: { method: 'get', url: 'invitation/users/{userId}/status/{action}' },
        deleteUser: { method: 'delete', url: 'invitation/user/{userId}' },
        adminSendCSPInvite: { method: 'post', url: 'customer/spBulkInvite' },
        adminSendInvite: { method: 'post', url: 'adminActivity/bulkServicePartners' },
        customerSendInvites: { method: 'post', url: 'customer/addOnUserInvite' },
        sendAndroidAppLink: { method: 'post', url: 'invitation/msgAppLink' },
        inviteFE: { method: 'get', url: 'invitation/user/{feId}/inviteMail' },
    },
    inventory: {
        bulkUploadSites: { method: 'post', url: 'projects/addBulkSites' },
        bulkUploadSpareAssets: { method: 'post', url: 'projects/addBulkSpares' },
    },
    courier: {
        getVendors: { method: 'get', url: 'courierVendors' },
        trackCourier: { method: 'post', url: 'couriertrack/trackCourier' },
    },
    scm: {
        getTools: { method: 'get', url: 'tools' },
        getSiteType: { method: 'get', url: 'sitetypes' },
        addTools: { method: 'post', url: 'servicePartner/{spId}/updateSiteTools' },
    },
    FE: {
        delete: { method: 'delete', url: 'fieldEngineer/{spId}/{feId}' },
        getProfile: { method: 'get', url: 'fieldEngineer/{id}' },
        updateDetails: { method: 'post', url: 'fieldEngineer' },
        onboard: { method: 'post', url: 'fieldEngineer' },
    },
    ticketExecution: {
        resumeTicket: { method: 'post', url: 'ticketExecution/resumeTicket' },
        holdTicket: { method: 'post', url: 'ticketExecution/escalateTicket' },
        approveSignOff: { method: 'post', url: 'tickets/pmSignoffTicketAccept' },
        initiateTicket: { method: 'post', url: 'ticketExecution/reachedSite' },
        getPrequisite: { method: 'post', url: 'ticketExecution/getPreReq' },
        withdrawTicket: { method: 'post', url: 'tickets/withdrawTicket' },
        savePrequisite: { method: 'post', url: 'ticketExecution/savePreReq' },
        getFESiteBOQ: { method: 'get', url: 'ticketExecution/getFeSiteBOQ' },
        updateSiteBOQ: { method: 'post', url: 'ticketExecution/updateSiteBOQ' },
        getSteps: { method: 'post', url: 'ticketExecution/getSteps' },
        getStepsFields: { method: 'post', url: 'ticketExecution/getStepsFields' },
        updateStepData: { method: 'post', url: 'ticketExecution/updateStepData' },
        uploadReport: { method: 'post', url: 'ticketExecution/addSignOffReport' },
        closeTicket: { method: 'post', url: 'ticketExecution/signoffTicket' },
        sendEmailReport: { method: 'post', url: 'ticketExecution/emailReport' },
        rejectSignOff: { method: 'post', url: 'tickets/pmSignoffTicketReject' },
        hardCloseTicket: { method: 'post', url: 'tickets/hardCloseTicket' },
    },
    wallet: {
        approvePayment: { method: 'post', url: 'payment/walletApprove' },
    },
    banks: {
        getAll: { method: 'get', url: 'banks' },
    },
    user: {
        saveBankDetails: { method: 'post', url: 'servicePartner/{id}/bank' },
        uploadProfilePic: { method: 'post', url: 'profileImage' },
        setUserPwd: { method: 'post', url: 'setUserPwd' },
        skipBankDetails: { method: 'post', url: 'onBoarding/skip' },
        verifyMail: { method: 'post', url: 'verification/mail_verify' },
        generateOTP: { method: 'post', url: 'auth/mobileVerifyOTP' },
        verifyOTP: { method: 'post', url: 'auth/mobileVerify' },
    },
    profile: {
        feProfileDetail: { method: 'get', url: 'fieldEngineer/{feId}' },
    },
    noc: {
        updateNocData: { method: 'post', url: 'ticketExecution/updateNocData' },
    },
    fm: {
        receivableGenerateInvoiceList: { method: 'get', url: 'financeManager/accountReceivable' },

        giGenerateInvoice: { method: 'post', url: 'financeManager/generateInvoice/customer' },
        revenuesList: { method: 'get', url: 'financeManager/revenueDetail' },
        downloadInvoice: { method: 'get', url: 'invoice/customerInvoice/{customer_invoice_id}' },
        updateCustomerInvoiceStatus: { method: 'post', url: 'financeManager/customerInvoicePaymentDate' },
    },
    fcm: {
        mapUser: { method: 'get', url: 'fcm_notification/map_user' },
    },
};

export default urls;
