import _ from 'lodash';
import { useFormContext } from 'react-hook-form';
import MaterialIconToolTip from '../MaterialIconToolTip';
import Loader from '../Loader';

const Input = ({
    id,
    name,
    label,
    required = false,
    placeholder,
    type = 'input',
    maxLength,
    minLength,
    validate,
    readOnly,
    className = '',
    inputClassName = `font-normal text-font09 md:text-font11 block w-full focus:ring-0 px-0 ${readOnly ? 'border-b-0 cursor-not-allowed' : 'border-b-2'}  border-t-0 border-r-0 border-l-0 placeholder:text-inputfieldtext placeholder:text-lg`,
    style = {},
    pattern,
    value,
    shouldUnregister,
    labelClassName = '',
    iconRight,
    isLoading,
    onChange,
    iconLeft,
    onBlur = () => { },
    step,
    childComponent,
    iconLeftImage,
    src,
    imgClassName,
    renderInfo,
    autofocus = false,
    inputStyle = { 'height': '3rem' },
    randerForgotLabel = <></>
}) => {
    const {
        register,
        formState: { errors },
        watch,
    } = useFormContext();

    const error = _.get(errors, name) ? _.get(errors, name).message : '';
    return (
        <div
            className={`block px-2  ${className}`}
            style={{
                minHeight: 0,
                minWidth: 0,
                ...style,
            }}
        >
            {label && (
                <label htmlFor={id} className={`text-scogo99 font-normal text-font09 md:text-font11 labelFotnSize ${labelClassName}`}>
                    {label} {required && <span className='text-scogoorange'>*</span>}
                    {renderInfo ? renderInfo : <></>}
                </label>
            )}
            <div className={iconRight || iconLeft || isLoading || iconLeftImage ? 'relative' : ''}>
                {iconLeft && (
                    <MaterialIconToolTip
                        materialIconClass={`material-icons-outlined text-font17 absolute left-2 top-1 text-scogoorange`}
                        title={iconLeft.hoverTitle}
                        materialIconName={iconLeft.name}
                        childComponent={childComponent}
                    />
                )}
                {iconLeftImage && (
                    <img src={src} className={imgClassName + ' absolute left-2 top-1 '} alt='' />
                )}
                <input
                    id={id}
                    key={name}
                    type={type}

                    style={inputStyle}
                    className={`${_.get(errors, name) ? 'border-scogoclosed focus:border-scogoclosed' : 'border-scogoddd focus:border-scogoprimary inputFontSize'
                        } ${inputClassName}`}
                    {...(value === undefined
                        ? register(name, {
                            required: { value: required, message: 'Required' },
                            maxLength: { value: maxLength, message: `Can't be more than ${maxLength} length` },
                            minLength: { value: minLength, message: `Can't be less than ${minLength} length` },
                            pattern: { value: pattern, message: `Invalid ${label}` },
                            validate,
                            shouldUnregister,
                            onChange,
                            autoFocus: autofocus,
                            onBlur,
                        })
                        : { value, onBlur })}
                    readOnly={readOnly}
                    placeholder={placeholder}
                    title={watch(name)}
                    step={step}
                    autoFocus={autofocus}
                />
                {iconRight && (
                    <MaterialIconToolTip
                        materialIconClass={`material-icons-outlined text-font17 absolute right-0 top-2 text-scogoprimary hover:text-scogoorange cursor-pointer w-12`}
                        title={iconRight.hoverTitle}
                        materialIconName={iconRight.name}
                        onClick={iconRight.onClick}
                    />
                )}

                {isLoading && <Loader thickness='2' size='15' position='absolute' right='0' top='0' margin='0.5rem' />}
            </div>
            <div className='flex mb-1'>
                {error && <span className='text-scogoclosed text-font08 font-normal inputFontSize h-6 w-[100%]'>{error}</span>}
                {randerForgotLabel}
            </div>

        </div>
    );
};

export default Input;
