import { Tooltip } from '@mui/material';
import ButtonScogoClosed from '../../../common/Buttons/ButtonScogoClosed';
import ButtonScogoPrimary from '../../../common/Buttons/ButtonScogoPrimary';
import { useDispatch } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import { useEffect, useState } from 'react';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import { RibbonContainer } from '../../../components/TicketExecution/RibbonContainer';
import { downloadFiles } from '../../../actions/utils';
import ButtonScogoOrange from '../../../common/Buttons/ButtonScogoOrange';
import { updateInventoryAsset, updateInventorySpareAsset, uploadAttachmentAssetConfig } from '../../../actions/inventory';
import { useSelector } from 'react-redux';
import { useFileUploadOnClick } from '../../../common/useFileUploadOnClick';
import { fileToBase64 } from '../../../utils/utils';
import { sendToastMessage } from '../../../actions/toast';
import Chip from '../../../common/Chip';
import ButtonScogoPrimaryOutlined from '../../../common/Buttons/ButtonScogoPrimaryOutlined';
import { GetQueryParam } from '../../../utils/common';
import { assets as assetsTab, spareAssets as spareAssetsTab } from '../InventoryController';

const AccordionJSON = ({ assetId, serial_number, showJsonEditor, source, poAssets, enduserId }) => {

  const assetGroupView = GetQueryParam('assetGroupView', Boolean);
  const { assets, spareAssets, assetsGroupByEndusers } = useSelector((state) => state.inventory);

  let assetOrSpareAsset = GetQueryParam("tab");
  let asset = assets.find((item) => {
    return item.id === assetId;
  });

  if (assetGroupView && enduserId && assetsGroupByEndusers[enduserId]) {
    asset = assetsGroupByEndusers[enduserId].find((asset) => asset.id === assetId);
  };

  if (!asset) {
    asset = spareAssets.find((item) => {
      return item.id === assetId;
    })
  }

  let JSONdata;
  if (source === 'po') {
    JSONdata = poAssets.device_config
  } else {
    JSONdata = asset.device_config;
  }

  if (JSONdata === null) {
    JSONdata = {
      attachmentList: { files: [] }
    }
  }

  const transformedJSONdata = { ...JSONdata };

  delete transformedJSONdata.attachmentList;

  const { selectedFiles, openFileExplorer, setSelectedFiles } = useFileUploadOnClick();

  const formnameforUpload = `edit-asset-attachments`;

  const formnameforUpdate = `edit-asset-config`;

  const { formButtonLoading } = useSelector((state) => state.loading);

  const [isViewOnly, setIsViewOnly] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    if (formButtonLoading?.[formnameforUpload] === false) {
      setSelectedFiles([]);
    }
    if (formButtonLoading?.[formnameforUpdate] === false) {
      setIsViewOnly(true);
    }

  }, [formButtonLoading, setSelectedFiles]);

  const hanldeClose = () => {
    dispatch(closeModalAction());
  }

  const mimeTypeLookup = (mime_type) => {
    if (mime_type.includes("text")) {
      return '/img/txt-file.png';
    }
    if (mime_type.includes("video")) {
      return '/img/video.png';
    }
    if (mime_type.includes("image")) {
      return '/img/img.png';
    }
    if (mime_type.includes("pdf")) {
      return './img/pdf.png';
    }
    return '/img/unknownFileType.png';
  }

  const handleDownloadJSON = (data) => {
    const jsonData = JSON.stringify(data, null, 4);
    const blob = new Blob([jsonData], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "config.json";
    link.click();
    URL.revokeObjectURL(url);
  }

  const style = {
    outerBox: { marginTop: '4rem' }
  }

  const handleDownload = (item, e) => {
    e.stopPropagation();
    dispatch(downloadFiles({ files: [{ url: item.url, fileName: item.name, mimeType: item.mime_type }] }));
  }

  const handleIconClick = (item) => {
    window.open(item.url, '_blank')
  }

  let payloadJSON = { ...JSONdata };

  const handleJsonOnChange = (data) => {
    if (!data.error) {
      payloadJSON = { ...data.jsObject, attachmentList: JSONdata.attachmentList };
    }
  }

  const handleUpdateClick = () => {
    if (assetOrSpareAsset === assetsTab.path) {
      dispatch(updateInventoryAsset({ data: { device_config: payloadJSON }, assetId: assetId, formName: formnameforUpdate, closeModal: false, enduserId }));
    }
    else if (assetOrSpareAsset === spareAssetsTab.path) {
      dispatch(updateInventorySpareAsset({ data: { device_config: payloadJSON }, assetId: assetId, formName: formnameforUpdate, closeModal: false }))
    }
  }

  const handleUploadClick = () => {
    selectedFiles.map((file) => {
      return fileToBase64(file).then((base64String) => {
        dispatch(uploadAttachmentAssetConfig({ serial_number, file: base64String, action: `asset_config_upload`, mime_type: file.type, formName: formnameforUpload, assetId, assetOrSpareAsset }))
      }).catch((err) => {
        dispatch(sendToastMessage({ message: `${file.name} upload failed`, status: 'danger' }));
      })
    });
  }

  const handleRemoveFile = (filename) => {
    const filteredFileList = selectedFiles.filter((file) => {
      if (file.name === filename) {
        return false;
      }
      return true;
    });
    setSelectedFiles(filteredFileList);
  }

  const getLatestJson = () => {
    const latestJSON = { ...payloadJSON };
    delete latestJSON.attachmentList;
    return latestJSON;
  }

  return (
    <div className=' h-full flex flex-col justify-between'>
      <div className='h-[90%] flex flex-col justify-start gap-1'>
        {
          showJsonEditor === 1 &&
          <div className=' max-h-[75%] p-2 relative'>
            <RibbonContainer headerLabel={`Edit Config`} isCollapsible={true} openCardDefault={true} >
              <div className='absolute right-24 top-6 '>
                <ButtonScogoOrange textOrComponent={`Download config.json`} onClick={() => handleDownloadJSON(getLatestJson())} />
              </div>
              <JSONInput
                id='a_unique_id'
                placeholder={transformedJSONdata}
                locale={locale}
                width='100%'
                height='30rem'
                viewOnly={isViewOnly}
                style={style}
                waitAfterKeyPress={1500}
                onChange={(data) => handleJsonOnChange(data)}
              />
            </RibbonContainer>
          </div>
        }
        <div className=' max-h-[50%] p-2 overflow-y-auto relative'>
          <RibbonContainer headerLabel={`Files`} isCollapsible={true} openCardDefault={true} >
            <div className='absolute right-24 top-6 '>
              <ButtonScogoOrange textOrComponent={`Add Attachment`} onClick={() => openFileExplorer()} />
            </div>
            <div className=' flex flex-wrap gap-1 p-2 mt-[3.5rem]'>
              {JSONdata?.attachmentList?.files && JSONdata?.attachmentList?.files.map((item) => {
                return (
                  <Tooltip title={item.name}>
                    <span className={`relative attachment hover:cursor-pointer`} onClick={() => handleIconClick(item)}>

                      <img
                        src={mimeTypeLookup(item.mime_type)}
                        className='w-[6rem] h-[6rem] rounded-md hover:border-scogoorange border-transparent border-2'
                        alt='Media Type' />
                      <span className='material-icons text-font18 text-scogoorange absolute top-0 right-0 translate-x-[30%] translate-y-[-30%] hover:cursor-pointer hover:text-black' onClick={(e) => handleDownload(item, e)}>file_download</span>
                    </span>
                  </Tooltip>
                )
              })}
            </div>
          </RibbonContainer>
        </div>
      </div>
      <div className='flex gap-2 justify-between p-2 h-[7%]'>
        <div className='flex gap-2'>
          {
            selectedFiles.length > 0 && selectedFiles.map((file) => <Chip text={file.name} showIcon={true} iconOnClick={() => handleRemoveFile(file.name)} />)
          }
        </div>
        <div className='flex gap-2'>
          {
            selectedFiles?.length > 0 &&
            <ButtonScogoPrimaryOutlined textOrComponent={`Upload`} loading={formButtonLoading?.[formnameforUpload]} onClick={() => handleUploadClick()} />
          }
          {
            showJsonEditor === 1 &&
            (
              isViewOnly ?
                <ButtonScogoPrimary textOrComponent={`Edit`} onClick={() => setIsViewOnly(!isViewOnly)} /> :
                <ButtonScogoPrimary textOrComponent={`Update`} loading={formButtonLoading?.[formnameforUpdate]} onClick={() => handleUpdateClick()} />
            )
          }

          <ButtonScogoClosed textOrComponent={`Close`} onClick={hanldeClose} />
        </div>
      </div>
    </div>
  )
}

export default AccordionJSON;