import React, { useMemo } from 'react';
import Form from '../../../common/Form';
import { useForm } from 'react-hook-form';
import { closeModalAction } from '../../../actions/modal';
import { useDispatch } from 'react-redux';
import MultipleEmails from '../../../common/Form/MultipleEmail';
import Select from '../../../common/Form/Select';
import { exportTicketCsvData } from '../../../actions/tickets';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getprojectListName } from '../../../actions/customer';
import { getCustomerIdOfLoginUser } from '../../../utils/utils';
import { serviceTypeIds } from '../TicketController';
import { isPsp } from '../../../utils/role';

const formName = 'downloadCsv';
const allProject = { key: 'all', value: 'all', label: 'All' }

export default function DownloadCsv({ type, status, module = 'TICKETS', isFeasibilityReport = false }) {
    const { loggedUser } = useSelector((state) => state.auth);
    const { formButtonLoading } = useSelector((state) => state.loading);
    const { filterPayload } = useSelector((state) => state.tickets);
    const { projectListNames, projectListLoading } = useSelector((state) => state.customer);
    const loggedUserRole = loggedUser.role.id;
    const loggedUserType = loggedUser.type;

    const projectDropdownList = useMemo(() => {
        let list = [allProject]
        if (Array.isArray(projectListNames)) {
            let projects = projectListNames
            if (isFeasibilityReport) {
                projects = projectListNames.filter((proj) => proj.service_type_id === serviceTypeIds.feasibility)
            }
            list = [...list, ...projects]
        }
        return list
    }, [projectListNames, isFeasibilityReport]);

    const defaultValues = {
        emails: loggedUser?.email ? [loggedUser?.email] : [],
        projects: [allProject]
    };
    const dispatch = useDispatch();
    const customerId = getCustomerIdOfLoginUser(loggedUser);
    const methods = useForm({ defaultValues, mode: 'all' });
    const { setValue } = methods;

    useEffect(() => {
        if (filterPayload?.projectIds && Array.isArray(filterPayload.projectIds) && Array.isArray(projectDropdownList)) {
            const selectedProject = projectDropdownList.filter((project) => filterPayload?.projectIds.includes(project.value))
            setValue('projects', selectedProject)
        }
    }, [filterPayload?.projectIds, projectDropdownList, setValue])

    useEffect(() => {
        dispatch(getprojectListName({ customerId }));
    }, [dispatch, customerId]);

    const submit = (formValues) => {
        const projectIds = formValues.projects.filter((p) => p.value !== allProject.value).map((proj) => proj.value);
        let filterData = { projectIds };
        if (isPsp(loggedUserRole, loggedUserType)) {
            filterData.pspIds = [loggedUser.id];
        }
        let payload = {
            customerId,
            status,
            emails: formValues.emails,
            type,
            formName,
            data: {
                download: true,
                act: 'company',
                archive: 'both',
                assetswise: 'all',
                categorise: 'both',
                range: 'daterange',
                rate: 'both',
            },
            module,
            filterData
        };
        dispatch(exportTicketCsvData(payload));
    };

    return (
        <Form
            onSubmit={submit}
            methods={methods}
            showSubmit
            submitContainerClassName='flex gap-2 justify-end py-4 px-4'
            isLoading={formButtonLoading[formName]}
            submitButtonText='Download'
            onCancel={() => dispatch(closeModalAction())}
        >
            <div className='px-2 py-2 flex gap-2 w-full items-end'>
                <Select className='w-full' name='projects' label='Project' options={projectDropdownList} isLoading={projectListLoading} isMulti />
            </div>
            <div className='py-2'>
                <MultipleEmails name='emails' label='Emails' required />
            </div>
        </Form>
    );
}
