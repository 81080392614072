import React from 'react';
import Layout from '../Layout';
import RenderChatIcon from '../RenderChatIcon';
import { useSelector } from 'react-redux';
import { trackSupportTicket } from '../../../router/roleWisePageRoute';
import { useNavigate } from 'react-router';
import RenderTrack from './RenderTrack';

export default function TrackPage({ ticketId, userId, address, site_lat, site_lng }) {
    const { loggedUser } = useSelector((state) => state.auth);
    const { ticketDetails } = useSelector((state) => state.tickets);
    const navigate = useNavigate();

    const redirectToSupportTrack = () => {
        navigate(`${trackSupportTicket.path}?ticketId=${ticketId}&redirectToChat=true`);
    }

    return <>
        <RenderTrack userId={userId} ticketId={ticketId} address={address} site_lat={site_lat} site_lng={site_lng} />
        <RenderChatIcon onChatIconClick={redirectToSupportTrack} showConversation={false} loggedUser={loggedUser} ticketDetails={ticketDetails} showIcon />
    </>
}