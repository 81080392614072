import * as React from 'react';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';

export default function RatingComponent(props) {
    const { rating, size = 'medium' } = props;
    return (
        <Stack spacing={0.5}>
            <Rating size={size} name='half-rating-read' defaultValue={rating} precision={0.5} readOnly />
        </Stack>
    );
}
