import Checkbox from '../Checkbox';
import React, { useEffect, useState, forwardRef, useCallback, useImperativeHandle } from 'react';
import Popper from '@mui/material/Popper';
import { ClickAwayListener } from '@mui/base';
import Box from '@mui/material/Box';
import { textCss } from '../Css';
import IconToolTip from '../IconToolTip';

export const CheckBoxFilter = forwardRef((props, ref) => {
    const { filterName, title, filterList, setIsApplyDisabled, isSingleDropDown, showSelectedFilters, localFilters, setLocalFilters, keysToFilter } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [localList, setLocalList] = useState([]);
    const [searchList, setSearchList] = useState([]);

    useImperativeHandle(ref, () => ({
        getLocalFilter() {
            return { [filterName]: localList };
        },
    }));

    const curentFilter = localFilters?.[filterName]

    useEffect(() => {
        if (Array.isArray(curentFilter) && curentFilter?.length !== localList?.length) {
            setLocalList(curentFilter);
            setSearchList(curentFilter);
        }
    }, [curentFilter, filterName, localList?.length]);

    useEffect(() => {
        // Whenever filters changed(select/unselect), checking and filtering out local filters using sieve(filter method) defined in filterSchema
        let updatedFilterList = {};
        keysToFilter.forEach((filter) => {
            if (filter.sieveForFilterItems && typeof filter.sieveForFilterItems === 'function' && localList.length > 0) {
                const currentFilter = {};
                const values = filter.sieveForFilterItems({ filterList, currentFilter: { [filterName]: localList } });
                if (values) currentFilter[filter.filterName] = values;
                updatedFilterList = { ...updatedFilterList, ...currentFilter };
            }
        });
        setLocalFilters((prevState) => {
            return { ...prevState, ...updatedFilterList }
        });
    }, [localList, keysToFilter, setLocalFilters, filterList, filterName]);

    useEffect(() => {
        let selectedValue = localList.filter((filx) => filx.isSelected);
        let isFilterNotSelected = selectedValue.length === 0;
        setIsApplyDisabled(isFilterNotSelected);
    }, [localList, setIsApplyDisabled]);

    const inputRef = useCallback((inputElement) => {
        if (inputElement) {
            inputElement.focus();
        }
    }, []);

    const handleSelect = ({ checked, index }) => {
        let currentFilter = JSON.parse(JSON.stringify(localList));
        let findFilter = currentFilter.find((fil) => fil.index === index);
        if (findFilter) {

            currentFilter.find((fil) => fil.index === index).isSelected = checked;
        }
        setLocalList(currentFilter);
    };

    const handleSelectAll = ({ checked }) => {
        let previousFilter = JSON.parse(JSON.stringify(localList));
        const searchFilterListMap = new Map(searchList.map((f) => [f.index, f]));
        let currentFilter = previousFilter.map((filter) => {
            const searchResult = searchFilterListMap.get(filter.index);
            return { ...filter, isSelected: searchResult ? checked : filter.isSelected };
        });
        setLocalList(currentFilter);
    };

    const handleChange = (e) => {
        let keyword = e.target.value.trim().toLowerCase();
        handleSearch(keyword);
    };

    const handleSearch = (searchTerm) => {
        let localFilter = localList;
        let isSearchTermAvailable = searchTerm !== '';
        if (isSearchTermAvailable) {
            localFilter = localFilter.filter((filx) => filx.displayValue.toLowerCase().includes(searchTerm));
        }
        setSearchList(localFilter);
    };

    const handleClick = (event) => {
        if (anchorEl) setSearchList(curentFilter);
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const boxStyle = {
        border: 0,
        p: 1,
        maxHeight: '18rem',
        width: '28rem',
        bgcolor: 'white',
    };

    const onClickAwayHandler = () => {
        if (!anchorEl) return;
        if (anchorEl) setAnchorEl(false);
        setSearchList(curentFilter);
    };

    const selectedCheckboxTexts = () => {
        let filters = localList;
        if (Array.isArray(filters) && filters.length > 0) {
            filters = filters.filter((filx) => filx.isSelected).slice(0, 8);
            return filters.map((filter) => filter.displayValue + ', ');
        }
        return 'Select';
    };

    const GetSelectedCheckbox = () => {
        return (
            <div className='flex flex-col gap-2'>
                {Array.isArray(localList) &&
                    localList.map((filter, index) => {
                        if (filter.isSelected) {
                            return (
                                <div className='flex items-center gap-2'>
                                    <IconToolTip title='Remove'>
                                        <span
                                            onClick={() =>
                                                handleSelect({
                                                    checked: false,
                                                    index,
                                                    filterName,
                                                })
                                            }
                                            className='material-icons text-scogoprimary text-font16 cursor-pointer'
                                        >
                                            highlight_off
                                        </span>
                                    </IconToolTip>
                                    <p className={`${textCss.inputText}`}>{filter.displayValue}</p>
                                </div>
                            );
                        }
                        return <></>;
                    })}
            </div>
        );
    };

    return (
        <div>
            <h1 className={`${textCss?.headerTwo} px-3 py-2`}>{title}</h1>
            <div className='flex mx-2 bg-white border-2 rounded-md'>
                <ClickAwayListener onClickAway={onClickAwayHandler}>
                    <div className='w-full rounded-b-xl cursor-pointer'>
                        <div className={`${textCss?.headerThree} w-full px-2 pr-6 my-2 truncate h-7  relative`} onClick={handleClick}>
                            {selectedCheckboxTexts()}
                            <span className={'material-icons absolute right-0 cursor-pointer '}>arrow_drop_down</span>
                        </div>

                        {isSingleDropDown ?
                            <SingleCheckBoxDropdown handleChange={handleChange} inputRef={inputRef} searchList={searchList} localList={localList} filterName={filterName} handleSelectAll={handleSelectAll} handleSelect={handleSelect} />
                            :
                            <Popper id={id} open={open} anchorEl={anchorEl} className='z-50 absolute pt-3'>
                                <Box sx={boxStyle} className='shadow-2xl overflow-auto'>
                                    <input
                                        onChange={handleChange}
                                        className={`${textCss?.headerThree}  w-full py-1 my-2 px-2 border-2 rounded-l`}
                                        placeholder={'Search'}
                                        ref={inputRef}
                                        autoFocus
                                    />
                                    <CheckboxList searchList={searchList} localList={localList} filterName={filterName} handleSelectAll={handleSelectAll} handleSelect={handleSelect} />
                                </Box>
                            </Popper>
                        }

                    </div>
                </ClickAwayListener>
            </div>

            <div className='mt-2 px-3 overflow-auto max-h-28'>{showSelectedFilters && localList?.find((filx) => !filx.isSelected) && <GetSelectedCheckbox />}</div>
        </div>
    );
});



const SingleCheckBoxDropdown = ({ handleChange, inputRef, searchList, localList, filterName, handleSelectAll, handleSelect }) => {
    return <div  >
        <input
            onChange={handleChange}
            className={`${textCss?.headerThree}  w-full py-1 my-2 px-2 border-2 rounded-l`}
            placeholder={'Search'}
            ref={inputRef}
            autoFocus
        />
        <div className='max-h-48 overflow-auto'  >
            <CheckboxList searchList={searchList} localList={localList} filterName={filterName} handleSelectAll={handleSelectAll} handleSelect={handleSelect} />
        </div>
    </div>

}



const CheckboxList = ({ searchList, localList, filterName, handleSelectAll, handleSelect }) => {
    const isSelectedFilter = (index, filterName) => {
        return localList.find((fil) => fil.index === index).isSelected;
    };
    const isAllSelected = searchList?.every((filter) => isSelectedFilter(filter.index));

    return (
        <div className='flex flex-col gap-2 px-2'>
            {
                <Checkbox
                    CheckContent={'Select All'}
                    checkColor='text-scogoorange mr-2'
                    onChange={(checked) => handleSelectAll({ checked, filterName })}
                    textStyle={`${textCss?.inputText}`}
                    dynamicSize={'1.3rem'}
                    checked={isAllSelected}
                />
            }
            {searchList?.map((filter, index) => (
                <Checkbox
                    CheckContent={filter.displayValue}
                    checkColor='text-scogoorange mr-2'
                    onChange={(checked) =>
                        handleSelect({
                            checked,
                            index: filter.index,
                            filterName,
                        })
                    }
                    key={index}
                    textStyle={`${textCss?.inputText}`}
                    dynamicSize={'1.3rem'}
                    checked={isSelectedFilter(filter.index, filterName)}
                />
            ))}
        </div>
    );
}