import { admin, loading } from '../types';
import { takeEvery, call, put } from 'redux-saga/effects';
import lambdas from '../lambdas/apis'

export function* getSignOffTemplates(action) {

    const { customer_id } = action.payload
    yield put({ type: loading.GET_SIGN_OFF_TEMPLATES_LOADING, payload: true });
    try {
        const response = yield call(lambdas.workflow.signOffTemplates, {}, {}, { customer_id });
        if (response.data.code === 200) {
            let signOffTemplates = response.data.data
            yield put({ type: admin.GET_SIGN_OFF_TEMPLATES_SUCCESS, payload: signOffTemplates })
        } else {
            yield put({ type: admin.GET_SIGN_OFF_TEMPLATES_FAILURE, payload: { message: 'Oops Something went wrong' } })
        }
    } catch (error) {
        yield put({ type: admin.GET_SIGN_OFF_TEMPLATES_FAILURE, payload: error })
    }
    yield put({ type: loading.GET_SIGN_OFF_TEMPLATES_LOADING, payload: false })
}


export default function* workflowSaga() {
    yield takeEvery(admin.GET_SIGN_OFF_TEMPLATES_REQUEST, getSignOffTemplates)
}
