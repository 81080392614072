import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Buttons from '../../common/Buttons';

import { checkIfUserIsRegisteredAction, getLoginOTP, loginWithOTPAction, requestOtpAction, setMobileOrEmailAction, verifyUsersEmail } from '../../actions/auth';

import Loader from '../../common/Loader';

import Layout from './Layout';
import { GetQueryParam } from '../../utils/common';
import { isValidMobileNumber } from '../../utils/utils';
import { useParams } from 'react-router-dom';
import Input from '../../common/Form/Input';
import Form from '../../common/Form';
import { useForm } from 'react-hook-form';
import { FormLabel } from './Login';
import { NewScogoButton, isMobileView } from './components/NewScogoButton';

function OTP() {
    const { checkUserDetails, isEmailVerifiedFromToken } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loading);
    const { isOtpButtonLoading } = loading;
    const [isResend, setIsResend] = useState(true);
    const navigate = useNavigate();

    const onContinueClick = ({ otp }) => {
        const mobile = checkUserDetails?.data?.mobile;
        dispatch(loginWithOTPAction({ mobile, otp, navigate }));
    };
    const onEnterPasswordClick = (e) => {
        navigate('/password')
    };

    const [counter, setCounter] = useState(20);
    useEffect(() => {
        if (counter === 0) setIsResend(false);
        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);

    const onResendOtpClick = ({ otp }) => {
        setIsResend(true);
        setCounter(20);
        const mobile = checkUserDetails?.data?.mobile;
        dispatch(requestOtpAction({ mobile, otp, navigate, isRedirect: false }))
    };

    const mobile = GetQueryParam('mobile');
    const { token_id } = useParams();

    useEffect(() => {
        if (token_id) dispatch(verifyUsersEmail({ token: token_id, navigate }));
    }, [token_id, navigate, dispatch]);

    useEffect(() => {
        if (isEmailVerifiedFromToken) {
            if (isValidMobileNumber(mobile)) {
                dispatch(setMobileOrEmailAction({ mobileOrEmail: mobile }));
                dispatch(
                    checkIfUserIsRegisteredAction({
                        mobileOrEmail: mobile,
                        navigate,
                        isRedirect: false,
                    })
                );
                dispatch(
                    getLoginOTP({
                        mobile: mobile,
                        skip: true,
                        navigate,
                    })
                );
            }
        }
    }, [isEmailVerifiedFromToken, dispatch, navigate, mobile]);

    return (
        <Layout>
            <div className='px-2 ' style={{ width: '30rem' }}>
                <FormLabel text={'OTP Verification'} />
                <OtpForm onResendOtpClick={onResendOtpClick} onContinueClick={onContinueClick} onEnterPasswordClick={onEnterPasswordClick} isOtpButtonLoading={isOtpButtonLoading} isResend={isResend} counter={counter} />
            </div>
        </Layout>
    );
}

export default OTP;

const OtpForm = ({ onResendOtpClick, onContinueClick, onEnterPasswordClick, isOtpButtonLoading, isResend, counter }) => {
    const defaultValues = {};
    const methods = useForm({ defaultValues, mode: 'all' });
    const { watch } = methods;
    const otp = watch('otp')
    const submitButtonRef = useRef();

    const { checkUserLoading } = useSelector((state) => state.loading);


    return <div>
        <Form
            methods={methods}
            submitRef={submitButtonRef}
            onSubmit={onContinueClick}
            className='flex flex-col gap-3'
            onEnterPressSubmit
        >
            <div>
                <Input
                    type={'number'}
                    name='otp'
                    inputClassName={`font-normal block w-full focus:ring-0 py-2 text-font14 rounded-md     placeholder:text-inputfieldtext placeholder:text-lg`}
                    placeholder='Enter your OTP'
                    required
                    label='OTP'
                    validate={(value) => {
                        return (value.length === 4) || 'Enter Valid OTP';

                    }}
                    inputStyle={{ 'height': isMobileView ? '4.5rem' : '3.5rem' }}
                />
            </div>
            <div className='flex gap-4 px-2'>
                <NewScogoButton onClick={(e) => {
                    e.preventDefault();
                    submitButtonRef.current.click();
                }} isLoading={isOtpButtonLoading} buttonName='Continue' />

            </div>
        </Form>
        <div className='text-center text-font14 px-2 pt-4'>
            <span className='text-scogo99'>Didn’t received OTP?</span>
            {isResend ? <span className=' text-scogoorange pl-2'>
                Resend in {counter} sec
            </span> :
                <span onClick={() => onResendOtpClick({ otp })} className=' pl-2 text-scogoorange cursor-pointer'>Resend</span>
            }

        </div>
        <div className='flex justify-center items-center gap-2 px-2 py-4'>
            <p className='border bg-scogoclosed w-40 h-0.5'></p>
            <span className='font text-scogo99'>Or</span>
            <p className='border bg-scogoclosed w-40 h-0.5'></p>
        </div>
        <div className='flex gap-4 px-2'>
            <NewScogoButton
                isLoading={checkUserLoading}
                onClick={onEnterPasswordClick}
                color={'orange'}
                buttonName={'Enter Password'}
            />

        </div>
    </div>
}