import React from 'react';
import OverflowTip from '../../../../../common/OverflowTip';
import { FileDownloadIcon } from './icons';

export default function OtherFile({ fileUrl, fileName, mimeType, showIcon, downloadFile, onAttachmentClick }) {
    return (
        <>
            {showIcon && (
                <div className='w-6/12 m-auto relative'>
                    <span onClick={onAttachmentClick}>
                        <img className='py-2 m-auto w-full max-h-480  attacgmentImg' src='/img/unknownFileType.png' alt={fileName} />
                        <div className='absolute top-0 attachmentGlassEffect w-full h-full '></div>
                    </span>
                </div>
            )}

            <span className='flex max-w-xl relative items-center ml-2 pr-20'>
                <span className='pr-2 material-icons text-scogoprimary text-3xl'>article</span>{' '}
                <a href={fileUrl} rel='noreferrer' target='_blank' className='text-font12 font-normal hover:text-scogoorange text-scogobgsky ' style={{ width: '95%' }}>
                    <OverflowTip textClass='truncate w-full' someLongText={fileName} />
                </a>
                {!showIcon && (
                    <FileDownloadIcon
                        onClick={() => downloadFile({ fileUrl, fileName, mimeType })}
                        position='top-0 right-5'
                        color={{ hover: 'scogoorange', normal: 'scogobgsky' }}
                    />
                )}
            </span>

            {showIcon && <FileDownloadIcon onClick={() => downloadFile({ fileUrl, fileName, mimeType })} position='top-5 right-5' />}
        </>
    );
}
