import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import { useFormContext } from 'react-hook-form';

import Loader from '../Loader';

import { ClickAwayListener } from '@mui/material';
const Input = ({
    id,
    name,
    label = 'Address',
    required = false,
    placeholder,
    type = 'input',
    maxLength,
    minLength,
    validate,
    readOnly,
    className = '',
    inputClassName = `font-normal text-font09 md:text-font11 block w-full focus:ring-0 px-0 ${readOnly ? 'border-b-0 cursor-not-allowed' : 'border-b-2'}  border-t-0 border-r-0 border-l-0 placeholder:text-inputfieldtext placeholder:text-lg`,
    style = {},
    pattern,
    value,
    shouldUnregister,
    labelClassName = '',
    isLoading,
    onChange: onTextAreaChange = () => { },
    onBlur = () => { },
    step,
    renderInfo,
    autofocus = false,
    inputStyle,
    randerForgotLabel = <></>,
    place_i, setplace_id, options,
    defaultHeight = 3,
    optionStyle
}) => {
    const {
        register,
        formState: { errors },
        watch, setValue
    } = useFormContext();
    const [inputHeight, setInputHeight] = useState(defaultHeight);
    const inpuRef = useRef(null);

    const inputValue = watch(name);

    const closeDropdown = () => {
        if (place_i.onOff) {
            setplace_id({ ...place_i, onOff: false })
        } else if (place_i.onOff === false) {
            setplace_id({ ...place_i, onOff: true })
        }
    };

    const closeDropdownOutSideDropdown = () => {

        setplace_id({ ...place_i, onOff: false })
    }

    const handleSubmit = (e) => {

        let event = {
            "target": {
                "value": e.value,
                "name": name
            }
        }

        setValue(name, event.target.value)
        setInputHeight(6.0)
        setplace_id({
            onOff: false,
            placeId: e.label
        })
    }

    const highlightMatch = (suggestion) => {
        const index = suggestion?.toLowerCase().indexOf(inputValue?.toLowerCase());
        if (index !== -1) {
            return (
                <>
                    {suggestion.substring(0, index)}
                    <span className="font-semibold  text-zinc-950">{suggestion.substring(index, index + inputValue.length)}</span>
                    {suggestion.substring(index + inputValue.length)}
                </>
            );
        } else {
            return suggestion;
        }
    };

    useEffect(() => {
        setInputHeight(defaultHeight)
    }, [defaultHeight])

    const error = _.get(errors, name) ? _.get(errors, name).message : '';
    return (
        <ClickAwayListener onClickAway={() => { place_i.onOff && closeDropdownOutSideDropdown() }}>
            <div
                className={`block px-2 relative ${className}`}
                style={{
                    minHeight: 0,
                    minWidth: 0,
                    ...style,
                }}
            >
                {label && (
                    <label htmlFor={id} className={`text-scogo99 font-normal text-font09 md:text-font11 labelFotnSize ${labelClassName}`}>
                        {label} {required && <span className='text-scogoorange'>*</span>}
                        {renderInfo ? renderInfo : <></>}
                    </label>
                )}
                <div className='flex'>
                    <textarea
                        ref={inpuRef}
                        id={id}
                        key={name}
                        type={type}
                        autoComplete='off'
                        style={{ ...inputStyle, height: `${inputHeight}rem` }}
                        className={`${_.get(errors, name) ? 'border-scogoclosed focus:border-scogoclosed' : 'border-scogoddd focus:border-scogoprimary inputFontSize'
                            } ${inputClassName}`}
                        {...(value === undefined
                            ? register(name, {
                                required: { value: required, message: 'Required' },
                                maxLength: { value: maxLength, message: `Can't be more than ${maxLength} length` },
                                minLength: { value: minLength, message: `Can't be less than ${minLength} length` },
                                pattern: { value: pattern, message: `Invalid ${label}` },
                                validate,
                                shouldUnregister,
                                onChange: (event) => {
                                    onTextAreaChange(event);
                                },
                                autoFocus: autofocus,
                                onBlur
                            })
                            : { value, onBlur })}
                        readOnly={readOnly}
                        placeholder={placeholder}
                        title={watch(name)}
                        step={step}
                        autoFocus={autofocus}

                    />
                    {options.length > 0 ? <span onClick={() => closeDropdown()} className="material-icons cursor-pointer">{place_i.onOff ? "arrow_drop_up" : "arrow_drop_down"}</span> : ""}

                    {isLoading && <Loader thickness='2' size='15' position='absolute' right='0' top='0' margin='0.5rem' />}
                </div>
                {options.length > 4 && <div style={optionStyle}
                    className={` cursor-pointer absolute bg-white shadow-md m-4 border   rounded-t-xl z-40 ${place_i.onOff ? "visible" : "invisible"
                        }`}
                >
                    {options?.map((e, i) => {
                        return (
                            <div key={i} onClick={() => handleSubmit(e)} className="hover:bg-scogoBgBrop">
                                <div
                                    className=" p-3 cursor-pointer text-scogogray text-lg border-b-1"
                                >{highlightMatch(e.value)}

                                </div>

                            </div>
                        )
                    })}
                    <div className={`p-3  text-scogogray bg-scogoddd border-b rounded-b-xl `}>powered by <span className='text-scogogray font-bold text-lg'>Google</span>
                    </div>
                </div>}
                <div className='flex mb-1'>
                    {error && <span className='text-scogoclosed text-font08 font-normal inputFontSize h-6 w-[100%]'>{error}</span>}
                    {randerForgotLabel}
                </div>

            </div>
        </ClickAwayListener>
    );
};

export default Input;
