export const getPartnerFilterDetails = (state) => {
    let partnerState = state.partners;
    return {
        limit: partnerState.rowsPerPage,
        page: partnerState.page,
        query: partnerState.searchQuery,
        tab: partnerState.tab,
        filterPayload: partnerState.filterPayload,
        roles: partnerState.roles,
        status: partnerState.status,
    };
};
