import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updatePartnersProfile } from '../../../actions/users';
import { closeModalAction } from '../../../actions/modal';
import Form from '../../../common/Form';
import Input from '../../../common/Form/Input';
import { useForm } from 'react-hook-form';
import { validateImageFile, validateStringValue } from '../../../utils/common';
import { isValidAadhaar, validatePAN } from '../../../utils/utils';
import IconToolTip from '../../../common/IconToolTip';
import Upload from '../../../common/Form/FileUpload';
import { sendToastMessage } from '../../../actions/toast';
import { updateProfilePicture } from '../../../actions/auth';

const formName = 'updateUserDetails';

const getComponyName = (userDetails) => {
    let componyName = '';
    if (userDetails?.customer_detail?.customer_company_name) {
        componyName = userDetails?.customer_detail?.customer_company_name;
    } else if (userDetails?.service_partner?.sp_name) {
        componyName = userDetails?.service_partner?.sp_name;
    } else if (userDetails?.sp_name) {
        componyName = userDetails?.sp_name;
    }
    return componyName;
};

export default function UpdateVendorsDetails(props) {
    const dispatch = useDispatch();
    const { formButtonLoading } = useSelector((state) => state.loading);
    const { userDetails, role, userType, customer, customerId, tabName, fk_cluster_id, callDetails } = props;

    const defaultValues = {
        first_name: validateStringValue(userDetails?.first_name),
        last_name: validateStringValue(userDetails?.last_name),
        email: validateStringValue(userDetails?.email),
        sp_name: validateStringValue(getComponyName(userDetails)),
        net_plan: validateStringValue(userDetails?.service_partner?.net_plan),
        net_provider: validateStringValue(userDetails?.service_partner?.net_provider),
    };
    const [selectedLogo, setSelectedLogo] = useState();
    const [selectedLogoToShow, setSlectedLogoToShow] = useState(userDetails.profile_pic);
    const inputBrowseFileRef = useRef();
    const isIspType = userDetails.type === 'ISP';
    const user_id = userDetails.id
    useEffect(() => {
        const isProfileChange = typeof selectedLogoToShow === 'string' && selectedLogoToShow.startsWith('blob');
        if (isProfileChange) dispatch(updateProfilePicture(selectedLogo, selectedLogoToShow, user_id));
    }, [selectedLogoToShow, selectedLogo, dispatch, user_id]);

    const uploadImage = (event) => {
        let image = userDetails.profile_pic;
        if (event.target.files.length > 0) {
            let isValidImage = validateImageFile(event.target.files);
            if (isValidImage) {
                let imageUrl = URL.createObjectURL(event.target.files[0]);
                image = imageUrl;
                setSelectedLogo(event.target.files);
            } else {
                dispatch(sendToastMessage({ status: 'danger', message: 'Only Image File can be Uploaded' }));
            }
        }
        setSlectedLogoToShow(image);
    };

    const submit = (form) => {
        let payload = {
            first_name: form.first_name,
            last_name: form.last_name,
            email: form.email,
            sp_name: form.sp_name,
            user_id: userDetails.id,

        };
        if (form.pancard_number) payload.pancard_number = form.pancard_number;
        if (form.adhaar_number) payload.adhaar_number = form.adhaar_number;
        else payload.adhaar_number = userDetails.address_proof_number || userDetails?.service_partner?.sp_adhaar_number;

        if (isIspType) {
            payload = {
                ...payload,
                net_plan: form?.net_plan,
                net_provider: form?.net_provider,
            };
        }
        dispatch(updatePartnersProfile({ data: payload, role, userType, customer, customerId, formName, tabName, fk_cluster_id, address_proof: form?.address_proof?.[0], address_proof_back: form?.address_proof_back?.[0], profile_pic: callDetails }));
    };

    const methods = useForm({ defaultValues, mode: 'all' });
    let showPanCardInput = !userDetails.pancard_number && userDetails.service_partner && !userDetails.service_partner.sp_pan_card_number;
    let showAadharCardInput = !userDetails.address_proof_number && userDetails.service_partner && !userDetails.service_partner.sp_adhaar_number;
    let showAadharCardFront = !userDetails.address_proof_url;
    let showAadharCardBack = !userDetails.address_proof_url_back;
    if (tabName === 'customer_vendors') {
        showPanCardInput = !userDetails.sp_pan_card_number && !userDetails.user.pancard_number;
        showAadharCardInput = !userDetails.sp_adhaar_number && !userDetails.user.address_proof_number;
        showAadharCardFront = !userDetails?.user?.address_proof_url;
        showAadharCardBack = !userDetails?.user?.address_proof_url_back;
    }

    return (
        <Form
            methods={methods}
            onSubmit={submit}
            formName={formName}
            submitButtonText={'Update'}
            onCancel={() => dispatch(closeModalAction())}
            showSubmit
            submitContainerClassName='flex gap-2 justify-end py-4 px-4'
            isLoading={formButtonLoading[formName]}
        >
            <div className='flex  items-center'>
                <div className='relative w-24 h-24 m-4'>
                    <img
                        src={selectedLogoToShow}
                        alt=''
                        loading='lazy'
                        className='w-24 h-24 rounded-full object-cover hover:opacity-25  cursor-pointer border-2'
                    />
                    <div className='absolute  opacity-0 hover:opacity-100 top-0 group w-full h-full bg-black rounded-full  bg-opacity-25 flex items-center justify-center'>
                        <span
                            onClick={() => inputBrowseFileRef?.current?.click()}
                            className='material-icons hover:bg-white   rounded-full cursor-pointer hover:bg-opacity-50 p-2 text-font24 focus:outline-none text-white  transition duration-200'
                        >
                            photo_camera
                        </span>
                        <input className='hidden' onChange={uploadImage} type='file' id='file' ref={inputBrowseFileRef} />
                    </div>
                </div>
                <div className='grid grid-cols-2 rows-fr'>
                    <Input label='First Name' name='first_name' required type={'text'} className='w-[30.5rem] ' />
                    <Input label='Last Name' name='last_name' required type={'text'} className='w-[30.5rem] ' />
                    <Input
                        label='Email Address'
                        name='email'
                        required
                        validate={(email) => {
                            return (
                                email.match(
                                    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                ) || 'Invalid Email'
                            );
                        }}
                    />
                    <Input label='Company Name' name='sp_name' required />
                </div>
            </div>
            <div className='px-3 grid grid-cols-2 rows-fr grid-flow-row-dense'>



                {showAadharCardInput && <Input
                    label="Aadhaar Number"
                    name="adhaar_number"
                    shouldUnregister
                    validate={(aadhar) => {
                        if (aadhar === '') return true;
                        return isValidAadhaar(aadhar) || 'Invalid Aadhaar';
                    }}
                />}
                {showPanCardInput && <Input
                    label="Pancard Number"
                    name="pancard_number"
                    shouldUnregister
                    validate={(pan) => {
                        if (pan === '') return true;
                        return (validatePAN(pan) || 'Invalid Pancard');
                    }}
                />}

                {/* <Upload
                    className='w-full'
                    name='profile_pic'
                    label='Profile Photo'
                    accept='image/jpeg,image/png'
                    validateFileType={(file) => {
                        const isImage = ['image/jpeg', 'image/png'].includes(file.type);
                        return isImage || `${file.name} is not an image`;
                    }}
                    rednderClass='w-full grid grid-cols-1 rows-fr grid-flow-row-dense'
                    renderFileDisplay={({ files, removeFile }) => {
                        if (files) {
                            return (
                                <div className='pl-4 w-6/12'>
                                    {Array.from(files).map((file) => {
                                        let imageUrl = URL.createObjectURL(file);
                                        return (
                                            <div key={file.lastModified} className='h-48 w-48 relative'>
                                                <IconToolTip title='Close'>
                                                    <span
                                                        onClick={() => removeFile(file)}
                                                        className='cursor-pointer material-icons text-black font-medium align-middle text-3xl hover:text-scogoclosed absolute right-0 top-0'
                                                    >
                                                        highlight_off
                                                    </span>
                                                </IconToolTip>
                                                <img className='object-cover h-48 w-48' src={imageUrl} alt={file.name} />
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        }
                        return <></>;
                    }}
                /> */}

                <RenderAadharCardImagesInput showAadharCardFront={showAadharCardFront} showAadharCardBack={showAadharCardBack} />
                {isIspType && (
                    <>
                        <Input label='Net Plan' name='net_plan' required shouldUnregister />
                        <Input label='Net Provider' name='net_provider' required shouldUnregister />
                    </>
                )}
            </div>
        </Form>
    );
}



const RenderAadharCardImagesInput = ({ showAadharCardFront, showAadharCardBack }) => {
    return <>
        {showAadharCardFront && <Upload
            className='w-full'
            name='address_proof'
            label='Aadhaar Front Image'
            accept='image/jpeg,image/png'
            validateFileType={(file) => {
                const isImage = ['image/jpeg', 'image/png'].includes(file.type);
                return isImage || `${file.name} is not an image`;
            }}
            rednderClass='w-full grid grid-cols-1 rows-fr grid-flow-row-dense'
            renderFileDisplay={({ files, removeFile }) => {
                if (files) {
                    return (
                        <div className='pl-4 w-6/12'>
                            {Array.from(files).map((file) => {
                                let imageUrl = URL.createObjectURL(file);
                                return (
                                    <div key={file.lastModified} className='h-48 w-48 relative'>
                                        <IconToolTip title='Close'>
                                            <span
                                                onClick={() => removeFile(file)}
                                                className='cursor-pointer material-icons text-black font-medium align-middle text-3xl hover:text-scogoclosed absolute right-0 top-0'
                                            >
                                                highlight_off
                                            </span>
                                        </IconToolTip>
                                        <img className='object-cover h-48 w-48' src={imageUrl} alt={file.name} />
                                    </div>
                                );
                            })}
                        </div>
                    );
                }
                return <></>;
            }}
        />}
        {showAadharCardBack && <Upload
            className='w-full'
            name='address_proof_back'
            label='Aadhaar Back Image'
            accept='image/jpeg,image/png'
            validateFileType={(file) => {
                const isImage = ['image/jpeg', 'image/png'].includes(file.type);
                return isImage || `${file.name} is not an image`;
            }}
            rednderClass='w-full grid grid-cols-1 rows-fr grid-flow-row-dense'
            renderFileDisplay={({ files, removeFile }) => {
                if (files) {
                    return (
                        <div className='pl-4 w-6/12 '>
                            {Array.from(files).map((file) => {
                                let imageUrl = URL.createObjectURL(file);
                                return (
                                    <div key={file.lastModified} className='h-48 w-48 relative '>
                                        <IconToolTip title='Close'>
                                            <span
                                                onClick={() => removeFile(file)}
                                                className='cursor-pointer material-icons text-black font-medium align-middle text-3xl hover:text-scogoclosed absolute right-0 top-0'
                                            >
                                                highlight_off
                                            </span>
                                        </IconToolTip>
                                        <img className='object-cover h-48 w-48  ' src={imageUrl} alt={file.name} />
                                    </div>
                                );
                            })}
                        </div>
                    );
                }
                return <></>;
            }}
        />}
    </>
}