import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addUsersInTeam } from '../../../actions/superdesk';
import { closeModalAction } from '../../../actions/modal';
import { SkeltonCardOne } from '../../../common/SkeltonCardOne';
import { getPartnersListDropDown, clearPartnerListDropDown } from '../../../actions/partners';
import { getCustomerIdOfLoginUser } from '../../../utils/utils';
import { getCustomerAddonsListDropDown, clearDropDown } from '../../../actions/customer';
import Form from '../../../common/Form';
import Select from '../../../common/Form/Select';
import { useForm } from 'react-hook-form';

const formName = 'addUsersInTeamform';
export default function AddUsersInTeam(props) {
    const { teamDetails, projectDetail, tabName } = props;
    const { loggedUser } = useSelector((state) => state.auth);
    const [userDropDown, setUserDropDown] = useState([]);
    const customerId = getCustomerIdOfLoginUser(loggedUser);
    const dispatch = useDispatch();
    const { partnersListDropdown } = useSelector((state) => state.partners);
    const { isPartnersDropdownListLoading, formButtonLoading } = useSelector((state) => state.loading);
    const { customerAddonsDropdown, customerDropdownAddonsLoading } = useSelector((state) => state.customer);

    const isValidArray = (elem) => {
        return Array.isArray(elem);
    };

    const previousUsersInTeams =
        isValidArray(teamDetails?.agents) &&
        teamDetails?.agents.map((elem) => {
            return { key: elem?.id, value: elem?.id, label: `${elem?.first_name} ${elem?.last_name}` };
        });

    const defaultValues = {
        users: previousUsersInTeams,
    };

    const submit = (form) => {
        const users = form?.users?.map((elem) => elem?.value);
        const usersName = form?.users?.map((elem) => elem?.label);
        const teamName = teamDetails?.name;
        let payload = {
            data: { users },
            teamId: teamDetails.id,
            usersName,
            teamName,
            projectDetail,
            tabName,
            customerId,
            formName,
        };
        dispatch(addUsersInTeam(payload));
    };

    const getUsers = useCallback(
        ({ type, customerId }) => {
            let payload = {};
            if (customerId > 0) payload.customerId = customerId;
            if (['SUPPORT', 'HELPDESK'].includes(type)) {
                dispatch(getCustomerAddonsListDropDown(payload));
            } else if (type === 'CUSTOMER') {
                payload.user_role = [16];
                dispatch(getPartnersListDropDown(payload));
            } else if (type === 'INTERNAL') {
                payload.user_role = [6];
                dispatch(getPartnersListDropDown(payload));
            }
        },
        [dispatch]
    );

    useEffect(() => {
        const teamType = teamDetails?.type?.toUpperCase();
        if (teamType === 'VENDOR') return setUserDropDown([]);
        getUsers({ type: teamType, customerId: teamDetails?.fk_customer_id });
    }, [getUsers, teamDetails?.fk_customer_id, teamDetails?.type]);

    useEffect(() => {
        setUserDropDown(customerAddonsDropdown);
    }, [customerAddonsDropdown]);

    useEffect(() => {
        setUserDropDown(partnersListDropdown);
    }, [partnersListDropdown]);

    useEffect(() => {
        dispatch(clearPartnerListDropDown());
        return () => dispatch(clearDropDown());
    }, [dispatch]);

    const isUsersDropDownLoading = customerDropdownAddonsLoading || isPartnersDropdownListLoading;

    const methods = useForm({ defaultValues, mode: 'all' });

    if (isPartnersDropdownListLoading) return <SkeltonCardOne />;

    return (
        <Form
            methods={methods}
            onSubmit={(e) => {
                submit(e);
            }}
            buttonPostion={'justify-end'}
            submitButtonName={'Submit'}
            onCancel={() => dispatch(closeModalAction())}
            showSubmit
            submitContainerClassName='flex gap-2 justify-end py-4 px-4'
            isLoading={formButtonLoading[formName]}
        >
            <div className='px-3 flex justify-between'>
                <Select className='w-full' label='Users' name='users' required isMulti={true} options={userDropDown} isLoading={isUsersDropDownLoading} />
            </div>
        </Form>
    );
}
