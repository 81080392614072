import React, { useState, useEffect } from 'react';
import { updateServiceLocations, removeExistingCitiesFromStore } from '../../../actions/users';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from '../../../common/Checkbox';
import Popper from '@mui/material/Popper';
import { ClickAwayListener } from '@mui/base';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { getAllExistingCities, getAllCities } from '../../../actions/users';
import ButtonScogoPrimary from '../../../common/Buttons/ButtonScogoPrimary';

export default function AddCities(props) {
    const [selectedCities, setSelectedCities] = useState([]);
    const [existingCitiesData, setExistingCitiesData] = useState([]);
    const [allCities, setAllCities] = useState([]);
    const { formButtonLoading } = useSelector((state) => state.loading);
    const formName = 'addCities';

    const { servicePartnerId, tabName, mode, userId, status, roles } = props;

    const dispatch = useDispatch();
    const { existingCities, cities } = useSelector((state) => state.user);
    const { allExistingCityLoading, isCitiesLoading } = useSelector((state) => state.loading);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const boxStyle = {
        border: 0,
        p: 1,
        maxHeight: '15rem',
        width: '38rem',
        bgcolor: 'white',
    };

    const onClickAwayHandler = () => {
        if (!anchorEl) return;
        if (anchorEl) setAnchorEl(false);
    };

    const textShowForDiv = () => {
        if (selectedCities.length)
            return selectedCities.map((names) => {
                if (names) return ' ' + names.label + ',';
            });
        else return 'Select';
    };

    const getCitiesData = () => {
        dispatch(getAllExistingCities(servicePartnerId));
        dispatch(getAllCities());
    };

    useEffect(() => {
        getCitiesData();
    }, []);
    useEffect(() => {
        setSelectedCities(existingCities);
        setExistingCitiesData(existingCities);
    }, [existingCities]);

    useEffect(() => {
        setAllCities(cities);
    }, [cities]);

    const handleChange = (isSelected, item) => {
        if (isSelected) {
            setSelectedCities([...selectedCities, item]);
            if (!existingCitiesData.includes(item)) {
                setExistingCitiesData([...existingCitiesData, item]);
            }
        } else {
            let newSelectedCities = selectedCities.filter((elem) => elem.value !== item.value);
            setSelectedCities(newSelectedCities);
        }
    };

    const handleSubmit = () => {
        let cities = selectedCities.map((item) => item.value);
        const updatedCities = selectedCities?.map((city) => {
            city = city?.label?.split(',');
            return city[0];
        });

        let payload = {
            data: { add_service_location: cities },
            servicePartnerId: servicePartnerId,
            formName,
            tabName,
            updatedCities
        };
        if (userId) payload.userIds = [userId]
        if (status) payload.status = status;
        if (roles) payload.roles = roles;
        dispatch(updateServiceLocations(payload));
    };

    useEffect(() => {
        return () => {
            dispatch(removeExistingCitiesFromStore());
        };
    }, [dispatch]);

    const findIfChecked = (item) => {
        let isSelected = selectedCities.find((elem) => elem.label === item.label);
        return isSelected === undefined ? false : true;
    };

    const handleShowCheckbox = () => {
        return (
            <div className='flex flex-col gap-2 px-2'>
                {allCities?.map((item) => (
                    <Checkbox
                        CheckContent={item.label}
                        checkColor='text-scogoorange mr-2'
                        onChange={(isSelected) => handleChange(isSelected, item)}
                        checked={findIfChecked(item)}
                        dynamicSize={'1.3rem'}
                        textStyle={'text-font12'}
                    />
                ))}
            </div>
        );
    };

    const handleSearch = (e) => {
        let searchKey = e.target.value.trim().toLowerCase();
        if (searchKey === '') setAllCities(cities);
        const valueListWithSearchTerm = cities.filter((value) => value.label.toLowerCase().includes(searchKey));
        setAllCities(valueListWithSearchTerm);
    };

    const conditionForSkelton = allExistingCityLoading || isCitiesLoading;

    if (conditionForSkelton) return <SkeltonCard />;
    else
        return (
            <div className='flex flex-col gap-2 px-4 py-4 relative'>
                <div className='flex bg-white border-2 rounded-md'>
                    <ClickAwayListener onClickAway={onClickAwayHandler}>
                        <div className='w-full rounded-b-xl cursor-pointer'>
                            <div className='w-full px-2 pr-6 my-2 truncate h-7 w-full text-font12' onClick={handleClick}>
                                {textShowForDiv()}
                                <span className={'material-icons absolute right-5 top-5 cursor-pointer '}>arrow_drop_down</span>
                            </div>
                            <Popper id={id} open={open} anchorEl={anchorEl} className='z-50 absolute pt-3'>
                                <Box sx={boxStyle} className='shadow-2xl overflow-auto'>
                                    <input onChange={handleSearch} className='w-full py-2 my-2 px-2 border-2 rounded-l text-font11' placeholder={'Search'} autoFocus />
                                    {handleShowCheckbox()}
                                </Box>
                            </Popper>
                        </div>
                    </ClickAwayListener>
                </div>
                <div className='flex flex-col gap-2 h-32 overflow-scroll px-2 py-2'>
                    {existingCitiesData?.map((item) => (
                        <Checkbox
                            CheckContent={item.label}
                            checkColor='text-scogoorange mr-2 cursor-pointer'
                            onChange={(checked) => handleChange(checked, item)}
                            checked={selectedCities.includes(item)}
                            dynamicSize={'1.3rem'}
                            textStyle={'text-font12'}
                        />
                    ))}
                </div>
                <div className={`pt-2 flex justify-end`}>
                    <ButtonScogoPrimary textOrComponent={'Submit'} disabled={mode === 'viewMode' ? true : false} onClick={handleSubmit} loading={formButtonLoading?.[formName]} />
                </div>
            </div>
        );
}

const SkeltonCard = () => {
    return (
        <Box sx={{ paddingLeft: 2, paddingRight: 2, paddingBottom: 2, paddingTop: 2 }}>
            <div className='mb-4'>
                <Skeleton sx={{ background: 'rgb(221,221,221)' }} variant='rectangular' width={'full'} height={20} animation='wave' />
            </div>
            {[1, 2, 3].map(() => {
                return (
                    <div className='mt-1'>
                        <Skeleton sx={{ background: 'rgb(221,221,221)' }} variant='rectangular' width={'full'} height={35} animation='wave' />
                    </div>
                );
            })}
        </Box>
    );
};
