import React, { useMemo } from 'react';
import Form from '../../../common/Form';
import { useForm } from 'react-hook-form';
import DateRange from '../../../common/Form/DateRange';
import { closeModalAction } from '../../../actions/modal';
import { useDispatch } from 'react-redux';
import MultipleEmails from '../../../common/Form/MultipleEmail';
import Select from '../../../common/Form/Select';
import { exportTicketCsvData } from '../../../actions/tickets';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getCustomersList } from '../../../actions/customer';
import { getCustomerIdOfLoginUser } from '../../../utils/utils';

const formName = 'downloadRmaReport';
const allCustomer = { key: 'all', value: 'all', label: 'All' }

export default function DownloadRmaReports(props) {
    const { loggedUser } = useSelector((state) => state.auth);
    const { formButtonLoading, isCustomerListLoading } = useSelector((state) => state.loading);
    const { customers } = useSelector((state) => state.customer);

    const customerDropdownList = useMemo(() => {
        let list = [allCustomer]
        if (Array.isArray(customers)) {
            const rmaCustomerList = customers.filter((cus) => cus.is_rma_eligible === 1)
            list = [...list, ...rmaCustomerList]
        }
        return list
    }, [customers]);

    const defaultValues = {
        emails: loggedUser?.email ? [loggedUser?.email] : [],
        customers: [allCustomer]
    };

    const dispatch = useDispatch();
    const methods = useForm({ defaultValues, mode: 'all' });
    const customerId = getCustomerIdOfLoginUser(loggedUser);


    useEffect(() => {
        if (!customers) dispatch(getCustomersList());
    }, [dispatch, customerId, customers]);

    const submit = (formValues) => {
        const customerIds = formValues.customers.filter((customer) => customer.value !== allCustomer.value).map((cus) => cus.value)

        let filterData = { customerIds }

        let payload = {
            customerId,
            status: 'ALL',
            emails: formValues.emails,
            type: 1,
            formName,
            data: {
                download: true,
                act: 'company',
                archive: 'both',
                assetswise: 'all',
                categorise: 'both',
                range: 'daterange',
                rate: 'both',
            },
            module: "TICKETS",
            filterData
        };

        dispatch(exportTicketCsvData(payload));
    };
    return (
        <Form
            onSubmit={submit}
            methods={methods}
            showSubmit
            submitContainerClassName='flex gap-2 justify-end py-4 px-4'
            isLoading={formButtonLoading[formName]}
            submitButtonText='Download'
            onCancel={() => dispatch(closeModalAction())}
        >
            <div className='px-2 py-2 flex gap-2 w-full items-end'>
                <Select required isMulti className='w-full' name='customers' label='Customer' options={customerDropdownList} isLoading={isCustomerListLoading} />
            </div>
            <div className='py-2'>
                <MultipleEmails name='emails' label='Emails' required />
            </div>
        </Form>
    );
}
