import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Form from '../../common/Form';
import Layout from './Layout';
import { useForm } from 'react-hook-form';
import { closeModalAction } from '../../actions/modal';
import Input from '../../common/Form/Input';
import { resetUserPassword } from '../../actions/auth';
import { FormLabel } from './Login';
import { NewScogoButton, isMobileView } from './components/NewScogoButton';


const formName = 'reset_password';

function ResetPassword() {
    const { formButtonLoading } = useSelector((state) => state.loading);
    const dispatch = useDispatch();
    const submitButtonRef = useRef();
    const defaultValues = {};
    const { token_id } = useParams();
    const navigate = useNavigate();

    const onSubmit = (formValues) => {
        dispatch(resetUserPassword({
            forgot_password_token: token_id,
            password: formValues.password,
            password_confirmation: formValues.password_confirmation,
            formName,
            navigate
        }))
    }

    const methods = useForm({ defaultValues, mode: 'all' });
    const { watch } = methods;
    const password = watch('password');
    const password_confirmation = watch('password_confirmation');

    return (
        <Layout>
            <div className=' ' style={{ width: '30rem' }}>
                <Form
                    methods={methods}
                    onSubmit={onSubmit}
                    formName={formName}
                    submitRef={submitButtonRef}

                    onCancel={() => dispatch(closeModalAction())}

                    submitContainerClassName='flex w-[396px] py-4 px-4'
                    showCancelButton={false}
                >
                    <div className='flex flex-col gap-5 lg:gap-0 '>

                        <FormLabel text={'Set Your New Password'} />
                        <Input
                            type={'password'}
                            name='password'
                            inputClassName={`font-normal rounded-lg block w-full focus:ring-0 px-4 pl-6 py-2 ${password ? 'text-font24' : ' text-font13'} 
                             placeholder:text-inputfieldtext placeholder:text-lg`}
                            placeholder='Enter Your New Password'
                            required
                            inputStyle={{ 'height': isMobileView ? '3.5rem' : '4.5rem' }}
                        />
                        <Input
                            type={'password'}
                            name='password_confirmation'
                            inputClassName={` font-normal rounded-lg block w-full focus:ring-0 px-4 pl-6 py-2 ${password_confirmation ? 'text-font24' : ' text-font13'}
                               placeholder:text-inputfieldtext placeholder:text-lg`}
                            placeholder='Confirm Your New Password'
                            required
                            inputStyle={{ 'height': isMobileView ? '3.5rem' : '4.5rem' }}
                            validate={(confirm_password) => {
                                if (confirm_password === '') return true;
                                return confirm_password === password || 'Password and Confirm Password is Different';
                            }}
                        />
                        <NewScogoButton onClick={(e) => {
                            e.preventDefault();
                            submitButtonRef.current.click();
                        }} isLoading={formButtonLoading[formName]} buttonName='Continue' />
                    </div>
                </Form>
            </div>
        </Layout>
    );
}

export default ResetPassword;