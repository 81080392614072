import { whatsapp } from '../types';

const INITIAL_STATE = {
    error: undefined,
    showDialpad: false
};

const whatsappReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case whatsapp.WHATSAPP_GROUP_LIST_SUCCESS:
            let { listOfChats = [], recentChats = [], filteredList = [] } = action.payload;

            let whatsappAllChatList = listOfChats

            if (listOfChats?.length === 0 && filteredList?.length > 0) {
                whatsappAllChatList = state.whatsappAllChatList
            }

            if (filteredList?.length > 0) {
                filteredList.unshift({ title: "All Chats" })
            }
            if (listOfChats?.length > 0) {
                listOfChats.unshift({ title: "All Chats" })
            }
            if (recentChats?.length > 0) {
                recentChats.unshift({ title: "Recent Chats" })
            }

            let list = [...recentChats, ...filteredList, ...listOfChats]

            return {
                ...state,
                whatsappChatList: transformWhatsappList(list),
                whatsappAllChatList: whatsappAllChatList 
            }

        case whatsapp.WHATSAPP_QR_SUCCESS:
            let { url, status = -1 } = action.payload
            return {
                ...state,
                whatsappLinkDetails: {
                    url,
                    status
                }
            }
        case whatsapp.SHOW_DIALPAD:

            return {
                ...state,
                showDialpad: action.payload
            }


        default:
            return {
                ...state,
            };
    }
};

export default whatsappReducer;




const transformWhatsappList = (list) => {
    const profile = (string) => {
        const style = [
            {
                backgroundColor: "#9966FF",
                color: "#FFFFFF",
            },
            {
                backgroundColor: "rgb(69 208 0)",
                color: "#FFFFFF",
            },
            {
                backgroundColor: "#6699FF",
                color: "#FFFFFF",
            }, {
                backgroundColor: "#FF9966",
                color: "#FFFFFF",
            }, {
                backgroundColor: "#FF6699",
                color: "#FFFFFF",
            }
        ]

        const replaced = string?.replace(/[^A-Za-z]/gi, '')?.[0];
        return {
            iconName: replaced,
            style: style[Math.floor(Math.random() * 5)]
        }
    }


    return list?.map(item => {
        return {
            ...item,
            profile: profile(item?.subject)
        }
    })
}