import moment from 'moment';
import { linkTypeOptions } from '../pages/Ticket/CloseFeasibility/TableForm';
import { sp } from '../types';

const INITIAL_STATE = {
    error: undefined,
};

const spReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case sp.SHARE_PUBLIC_PROFILE_SUCCESS:
            return {
                ...state,
                shareProfile: action.payload,
            };
        case sp.REMOVE_PUBLIC_PROFILE_REQUEST:
            return {
                ...state,
                shareProfile: undefined,
            };
        case sp.SHARE_ID_CARD_SUCCESS:
            return {
                ...state,
                idCard: action.payload,
            };
        case sp.REMOVE_ID_CARD_REQUEST:
            return {
                ...state,
                idCard: {},
            };

        case sp.GET_ELIGIBLE_SP_LIST_SUCCESS: {
            return {
                ...state,
                eligibleSPs: transFormSpData(action.payload),
            };
        }
        case sp.CLEAR_ELIGIBLE_SP_LIST: {
            return {
                ...state,
                eligibleSPs: undefined,
            };
        }
        case sp.GET_ELIGIBLE_SP_DROPDOWN_LIST_SUCCESS: {
            return {
                ...state,
                eligibleSPsDropdown: action.payload,
            };
        }
        case sp.GET_PARTNER_DETAILS_LIST_SUCCESS_SP: {
            const { accepted_ticket_count, execution_date } = action.payload

            let data = state.eligibleSPsDropdown
            let user = []
            data = data.map(sp => {
                user = accepted_ticket_count.filter(e => {
                    return e.accepted_sp_id === sp.user.id
                })
                let dateOrCount = {
                }


                user.forEach(e => {
                    if (moment(execution_date).format("YYYY-MM-DD") === e.execution_date) {
                        dateOrCount.first_execution = { data: e.execution_date, count: e.count, sp_as_fe_count: e.sp_as_fe_count }
                    } else if (moment(execution_date).add(1, 'days').format("YYYY-MM-DD") === e.execution_date) {
                        dateOrCount.second_execution = { data: e.execution_date, count: e.count, sp_as_fe_count: e.sp_as_fe_count }
                    } else if (moment(execution_date).add(2, 'days').format("YYYY-MM-DD") === e.execution_date) {
                        dateOrCount.third_execution = { data: e.execution_date, count: e.count, sp_as_fe_count: e.sp_as_fe_count }
                    }
                })
                sp.dateOrCount = dateOrCount
                return sp
            })


            return {
                ...state,
                eligibleSPsDropdown: data,
            };
        }
        case sp.CLEAR_ELIGIBLE_SP_DROPDOWN_LIST: {
            return {
                ...state,
                eligibleSPsDropdown: undefined,
            };
        }
        // Error

        case sp.GET_ELIGIBLE_SP_LIST_FAILURE:
        case sp.SHARE_PUBLIC_PROFILE_FAILURE:
        case sp.SHARE_ID_CARD_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        default:
            return {
                ...state,
            };
    }
};

export default spReducer;

const transFormSpData = (eligibleSPs) => {
    let responsedIsp = [];
    let detailList = [];
    let ispList = [];
    let arrayName = 'eligibleSPs';
    if (Array.isArray(eligibleSPs)) {
        eligibleSPs.forEach((sp) => {
            let { ispTicketResponse, ispLinkDetails } = sp;
            if (Array.isArray(ispTicketResponse) && ispTicketResponse.length > 0) {
                responsedIsp.push(sp);
            } else if (Array.isArray(ispLinkDetails) && ispLinkDetails.length > 0) {
                detailList.push(sp);
            } else ispList.push(sp);
        });
    }

    return [...responsedIsp, ...detailList, ...ispList].map((isp, idx) => {
        const { ispTicketResponse, ispLinkDetails, lastResponseTime } = isp;
        let isEditable = true;

        let linktype, remark, response, response_type, first_name, last_name, status = '';
        let otc, router_cost, monthly_plan, static_ip_charge;
        if (Array.isArray(ispTicketResponse) && ispTicketResponse.length > 0) {
            let ispResponse = ispTicketResponse[0];
            linktype = ispResponse.link_type;
            router_cost = ispResponse.router_cost;
            static_ip_charge = ispResponse.static_ip_charge;
            remark = ispResponse.remark;
            monthly_plan = ispResponse.monthly_plan;
            otc = ispResponse.otc;
            response = ispResponse.response;
            first_name = ispResponse.first_name;
            last_name = ispResponse.last_name;
            response_type = ispResponse.response_type;
            status = ispResponse.status
            isEditable = false;
        } else if (Array.isArray(ispLinkDetails) && ispLinkDetails.length > 0) {
            let linkDetail = ispLinkDetails[0];
            linktype = linkDetail.link_type;
            router_cost = linkDetail.router_cost;
            static_ip_charge = linkDetail.static_ip_charge;
            remark = linkDetail.remark;
            monthly_plan = linkDetail.monthly_plan;
            otc = linkDetail.otc;
        }
        return {
            linktype,
            otc,
            router_cost,
            monthly_plan,
            static_ip_charge,
            remark,
            name: `${isp.first_name} ${isp.last_name}`,
            mobile: isp.mobile,
            sp_name: isp.sp_name,
            feasibility_shared_at: isp.feasibility_shared_at,
            response,
            sharedAt: isp.feasibility_shared_at,
            sharedBy: isp.feasibility_shared_by,
            lastResponseTime,
            isEditable,
            customer_id: isp.customer_id,
            id: isp.id,
            sp_id: isp.service_partner_id,
            first_name, last_name, response_type, status,
            is_pancard_linked_with_aadhaar: isp.is_pancard_linked_with_aadhaar,
            employee_type: isp.employee_type,
        };
    });
};
