import { useFormContext, Controller } from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';
import _ from 'lodash';

const DatePicker = ({
    label,
    name,
    required,
    placeholder,
    className = '',
    isClearable = true,
    showTimeSelect,
    showTimeSelectOnly,
    dateFormat,
    shouldUnregister,
    excludeTimes,
    filterTime,
    maxDate,
    minDate,
    onChangeHandler,
    readOnly,
    padding = 'px-4 py-2'
}) => {
    const {
        formState: { errors },
        control,
    } = useFormContext();

    const getDateFormat = () => {
        let format = 'dd/MM/yyyy';
        if (showTimeSelect) {
            if (showTimeSelectOnly) {
                format = 'HH:mm a';
            } else if (dateFormat) {
                format = dateFormat;
            } else {
                format = 'dd/MM/yyyy HH:mm';
            }
        }
        return format;
    };

    return (
        <div className={`${padding} ${className}`}>
            {label && (
                <label>
                    <span className='text-scogo99 font-normal text-font09 md:text-font11 labelFotnSize'>
                        {label} {required && <span className='text-scogoorange'>*</span>}{' '}
                    </span>
                </label>
            )}
            <div className=' relative'>
                <Controller
                    key={name}
                    control={control}
                    name={name}
                    rules={{ required: { value: required, message: 'Required' } }}
                    shouldUnregister={shouldUnregister}
                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                        <ReactDatePicker
                            className={`border inputFontSize ${readOnly ? 'border-scogogray bg-scogof2' : 'border-scogoccc focus:border-scogoprimary bg-white'
                                } w-full  pl-10 rounded-6px  text-scogo99 font-normal text-font09 md:text-font11`}
                            placeholderText={placeholder}
                            isClearable={isClearable}
                            dateFormat={getDateFormat()}
                            name={name}
                            selected={value}
                            onChange={(event) => {
                                onChange(event);
                                if (onChangeHandler) {
                                    onChangeHandler(event);
                                }
                            }}
                            showTimeSelect={showTimeSelect}
                            showTimeSelectOnly={showTimeSelectOnly}
                            popperProps={{ strategy: 'fixed' }}
                            excludeTimes={excludeTimes}
                            filterTime={filterTime}
                            maxDate={maxDate ? maxDate : undefined}
                            minDate={minDate ? minDate : undefined}
                            readOnly={readOnly}
                        />
                    )}
                />
                <span className='material-icons-outlined text-scogoorange absolute left-2 top-2 font-normal text-font16 inputFontSize'>event</span>
                {_.get(errors, name) && <span className='text-scogoclosed text-font08 font-normal'>{_.get(errors, name).message}</span>}
            </div>
        </div>
    );
};

export default DatePicker;
