import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DetailTag from '../../../pages/project/DetailTag';
import { textCss } from '../../../common/Css';
import moment from 'moment';
import { getDateDifference, validateDateValue } from '../../../utils/common';
import { updateCourierStatus } from '../../../actions/tickets';
import ButtonScogoOrange from '../../../common/Buttons/ButtonScogoOrange';
import { handHelding } from '../../Ticket/TicketTableComponent';

export const logisticsType = {
    forward: 'forward',
    reverse: 'reverse'
}

const TrackCourier = ({ viewTicket, rltracking_detail, tracking_detail, ticketId }) => {
    const rlCourierStatus = rltracking_detail ? rltracking_detail : viewTicket?.rltracking_detail;
    const courierStatus = tracking_detail ? tracking_detail : viewTicket?.tracking_detail;
    return (
        <div className='px-4 py-4'>
            {courierStatus && <CourierDetails courierStatus={courierStatus} title={'Forward Logistics'} viewTicket={viewTicket} type={logisticsType.forward} ticketId={ticketId} />}
            <br /> <hr />
            {rlCourierStatus?.awbno && <CourierDetails courierStatus={rlCourierStatus} title={'Reverse Logistics'} viewTicket={viewTicket} type={logisticsType.reverse} ticketId={ticketId} />}
        </div>
    );
};

export default TrackCourier;

const TrackingDetailsNotAvailable = () => {
    return (
        <div
            className='my-4 h-10 items-center flex px-6 rounded-md gap-6 border-4 col-span-2'
            style={{ border: '1px solid rgb(178,95,94)', color: 'rgb(145,45,56)', background: 'rgb(255,246,246)' }}
        >
            <p className={textCss?.inputText} style={{ color: 'rgb(145,45,56)' }}>
                Tracking Details not available yet
            </p>
        </div>
    );
};

const CourierDetails = ({ courierStatus, title, type, ticketId }) => {
    const trackingId = courierStatus && courierStatus.tracking_id;
    const isHandHelding = courierStatus.carrier === handHelding;
    const isToShowCourierTime = ['Delivered', 'DELIVERED'].includes(courierStatus?.current_status);
    const { isUpdateCourierLoading } = useSelector((state) => state.loading);
    const dispatch = useDispatch();

    const handleUpdateCourierStatus = (id, type) => {
        if (id) {
            dispatch(updateCourierStatus({ trackingId: id, type, ticketId }));
        }
    };

    const showRefresh = !['DELIVERED', 'RTO Delivered', 'Delivered'].includes(courierStatus?.current_status);

    const openUrl = (url) => {
        window.open(url, '_blank');
    };

    const deliverystatuslookUp = {
        'in transit': 'scogoBasicYellow',
        delivered: 'scogo2e',
        return: 'scogoclosed',
    };

    if (!courierStatus) {
        return <TrackingDetailsNotAvailable />;
    }

    const getDetails = () => {
        if (isHandHelding) {
            return (
                <div className='grid col-span-2'>
                    <DetailTag
                        tagHeadClass={textCss.detailsTitle}
                        tagValueClass={`${textCss.detailsValue} pr-4`}
                        tagClass='py-3'
                        tagName='Carrier'
                        tagValue={courierStatus?.carrier ? courierStatus?.carrier : ''}
                    />

                    {isToShowCourierTime && (
                        <div>
                            <p className='text-font11 mb-1 text-scogogray'>Delivery Date</p>
                            <span className='bg-scogoLogoGreen  text-white max-w-max text-font10 px-2 py-1 rounded-3px'>
                                {courierStatus?.delivery_date ? moment(courierStatus.delivery_date).format('DD-MM-YYYY') : ''}
                            </span>
                        </div>
                    )}
                </div>
            );
        }

        return (
            <>
                <DetailTag
                    tagHeadClass={textCss.detailsTitle}
                    tagValueClass={`${textCss.detailsValue} pr-4 `}
                    tagClass='py-3'
                    tagName='AWB Number'
                    tagValue={courierStatus?.awbno ? courierStatus?.awbno : ''}
                />

                <DetailTag
                    tagHeadClass={textCss.detailsTitle}
                    tagValueClass={`${textCss.detailsValue} pr-4`}
                    tagClass='py-3'
                    tagName='Carrier'
                    tagValue={courierStatus?.carrier ? courierStatus?.carrier : ''}
                />

                <div>
                    <p className='text-font11 mb-1 text-scogogray'>Status</p>
                    <span className={`bg-scogogray bg-${deliverystatuslookUp[courierStatus?.current_status?.toLowerCase()]}  text-white max-w-max text-font10 px-2 py-1 rounded-3px`}>
                        {courierStatus?.current_status ? courierStatus?.current_status : ''}
                    </span>
                </div>

                {isToShowCourierTime && courierStatus?.delivery_date?.length > 0 && (
                    <div>
                        <p className='text-font11 mb-1 text-scogogray'>Delivery Date</p>
                        <span className='bg-scogoLogoGreen  text-white max-w-max text-font10 px-2 py-1 rounded-3px'>
                            {courierStatus?.delivery_date ? moment(courierStatus.delivery_date).format('DD-MM-YYYY') : ''}
                        </span>
                    </div>
                )}

                {courierStatus?.pickup_date?.length > 1 && (
                    <div>
                        <p className='text-font11 mb-1 text-scogogray'>Transit Duration</p>
                        <span className='bg-scogoLogoGreen  text-white max-w-max text-font10 px-2 py-1 rounded-3px'>
                            {moment(courierStatus?.pickup_date).isValid()
                                ? `${getDateDifference(validateDateValue(courierStatus?.delivery_date) ? courierStatus.delivery_date : Date(), validateDateValue(courierStatus?.pickup_date))} days`
                                : ''}
                        </span>
                    </div>
                )}

                {courierStatus?.to && <DetailTag
                    tagHeadClass={textCss.detailsTitle}
                    tagValueClass={`${textCss.detailsValue} pr-4`}
                    tagClass='py-3'
                    tagName='Drop Location'
                    tagValue={courierStatus?.to}
                />}

                {courierStatus?.recipient && <DetailTag
                    tagHeadClass={textCss.detailsTitle}
                    tagValueClass={`${textCss.detailsValue} pr-4`}
                    tagClass='py-3'
                    tagName='Recipient Name'
                    tagValue={courierStatus?.recipient}
                />}
            </>
        );
    };

    return (
        <div
            className='w-full gap-2 grid grid-cols-2 justify-around border pb-3 p-2 mt-4 bg-white '
            style={{
                'box-shadow': ' 0px 3px 6px #00000029',
                'border-radius': '10px',
            }}
        >
            <div className='col-span-2 '>
                <div className='ribbon col-span-2 '>
                    <span className='ribbon3 bg-scogobgsky ribbonblue'>{title}</span>
                    <div className='absolute right-2 flex gap-x-3 top-4'>
                        {courierStatus?.pod && <ButtonScogoOrange textOrComponent={'POD'} onClick={() => openUrl(courierStatus?.pod)} />}
                        {courierStatus?.tracking_url && <ButtonScogoOrange textOrComponent={'Track'} onClick={() => openUrl(courierStatus?.tracking_url)} />}
                        {showRefresh && <ButtonScogoOrange loading={isUpdateCourierLoading} textOrComponent={'Refresh'} onClick={() => handleUpdateCourierStatus(trackingId, type)} />}
                    </div>
                </div>
            </div>
            {getDetails()}
        </div>
    );
};
