import React, { useState, useEffect } from 'react';
import { TablePagination } from '@mui/material';

export default function Paginate(props) {
    const { count, onPageChange = () => { }, entriesPerPage, setRowsPerPage = () => { }, page = 1, rowsPerPageOptions = [10, 25, 50, 100, 1000, 'ALL'], style } = props;
    const [localPage, setLocalPage] = useState(page);
    const [disableBackButton, setDisableBackButton] = useState(true);
    const [disableNextButton, setDisableNextButton] = useState(false);
    const [localRowsPerPage, setLocalRowsPerPage] = useState(entriesPerPage || 25);

    useEffect(() => {
        setLocalRowsPerPage(entriesPerPage);
    }, [entriesPerPage]);

    useEffect(() => {
        setLocalPage(page);
    }, [page]);

    const handleChangePage = (event, newPage) => {
        setLocalPage(newPage + 1);
        onPageChange(localRowsPerPage, newPage + 1);
        setRowsPerPage(localRowsPerPage, newPage + 1);
    };

    useEffect(() => {
        setLocalPage(page);
        setDisableNextButton(localPage * localRowsPerPage >= count);
    }, [count]);

    const handleChangeRowsPerPage = (event) => {
        if (event.target.value === 'ALL') {
            setLocalRowsPerPage(event.target.value);
            setLocalPage(page);
            onPageChange(count, 1);
            setRowsPerPage(event.target.value, 1);
        } else {
            setLocalPage(page);
            setDisableBackButton(false);
            setDisableNextButton(false);
            setLocalRowsPerPage(parseInt(event.target.value, 10));
            setRowsPerPage(parseInt(event.target.value, 10), 1);
            onPageChange(event.target.value, 1);
        }
    };

    useEffect(() => {
        if (count <= localRowsPerPage) {
            setDisableBackButton(true);
            setDisableNextButton(true);
        } else {
            setDisableBackButton(localPage === 1);
            setDisableNextButton(localPage * localRowsPerPage >= count || localRowsPerPage === 'ALL');
        }
    }, [localPage, localRowsPerPage]);

    const displayPaginatelabel = ({ from, count, page, to }) => {
        if ([from, count, page, to].includes(undefined)) return '...';
        return `${from ? from : page + 1}–${to ? to : count} of ${count !== -1 ? count : `more than ${to}`}`;
    };



    return (
        <>
            <div style={{ textAlign: 'right' }}>
                <div style={{ display: 'inline' }}>
                    <TablePagination
                        component='div'
                        count={count}
                        page={localPage - 1}
                        onPageChange={handleChangePage}
                        sx={style}
                        rowsPerPage={localRowsPerPage}
                        nextIconButtonProps={{ disabled: disableNextButton }}
                        backIconButtonProps={{ disabled: disableBackButton }}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={rowsPerPageOptions}
                        labelDisplayedRows={displayPaginatelabel}
                    />
                </div>
            </div>
        </>
    );
}
