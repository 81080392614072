import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from "react-router-dom";
import { isLogged } from '../utils/common';
import { getDefaultRoute } from './roleWisePageRoute';

const HomeLayout = () => {
    const isLoggedIn = isLogged();
    const { loggedUser } = useSelector(state => state.auth);


    if (isLoggedIn && loggedUser) {
        const defaultRoute = getDefaultRoute(loggedUser.role.id, loggedUser.type, {
            frontend_controller: loggedUser.frontend_controller,
            status: loggedUser.status,
            spOnboardingStatus: loggedUser.spOnboardingStatus,
            customerOnboardingStatus: loggedUser.customerOnboardingStatus,
            feOnboardingStatus: loggedUser.feOnboardingStatus,
            email: loggedUser.email, pancard_number: loggedUser.pancard_number,
            address_proof_number: loggedUser.address_proof_number,
        })
        return <Navigate to={defaultRoute} />
    }
    return (
        <div className="h-screen md:bg-scogof2 overflow-x-hidden">
            <Outlet />
        </div>
    )
}

export default HomeLayout;