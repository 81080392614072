import React from 'react';

const OtherfileLookUp = (mimeType) => {
    let name = '';
    let color = '';
    if (
        mimeType === 'application/vnd.ms-excel' ||
        mimeType === 'application/msexcel' ||
        mimeType === 'application/x-msexcel' ||
        mimeType === 'application/x-ms-excel' ||
        mimeType === 'application/x-excel' ||
        mimeType === 'application/x-dos_ms_excel' ||
        mimeType === 'application/xls' ||
        mimeType === 'application/x-xls' ||
        mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
        name = 'table_view';
        color = 'text-excelFile';
    } else {
        name = 'description';
        color = 'text-scogodarkgray';
    }
    return { name, color };
};

const getFileIcon = (type, mimeType) => {
    const lookup = {
        image: {
            name: 'image',
            color: 'text-scogobgsky',
        },
        audio: {
            name: 'volume_up',
            color: 'text-gray-900',
        },
        video: { name: 'videocam', color: 'text-gray-900' },
        pdf: { name: 'picture_as_pdf', color: 'text-scogoclosed' },
        others: OtherfileLookUp(mimeType),
        text: { name: 'text_snippet', color: 'text-noteFile' },
    };
    return <span className={`material-icons ${lookup[type]?.color}`}>{lookup[type]?.name}</span>;
};

const getComponentByType = ({ type, url }) => {
    const lookup = {
        image: <img src={url} className='h-24 w-24 m-auto' alt='' />,
        audio: <img src={process.env.PUBLIC_URL + '/img/audio.png'} className='h-24 w-24  m-auto' alt='' />,
        video: <video className='h-24 w-24  m-auto' src={url} />,
        pdf: <img src={process.env.PUBLIC_URL + '/img/pdf.png'} className='h-24 w-24  m-auto' alt='' />,
        others: <img src={process.env.PUBLIC_URL + '/img/unknownFileType.png'} className='h-24 w-24  m-auto' alt='' />,
        text: <img src={process.env.PUBLIC_URL + '/img/unknownFileType.png'} className='h-24 w-24  m-auto' alt='' />,
    };
    return lookup[type];
};

function ReplyBox({ message, closeReply, isChatPopup }) {
    let replyMessage = () => {
        if (message.type === 'message' || message.type === 'reply') {
            return <p className=' font-medium truncate text-gray-500 text-font14 w-11/12 break-all absolute '>{message.message}</p>;
        } else if (message.type === 'attachments') {
            const attachments = message.attachments;
            let { type, mimeType, fileUrl } = attachments[0];
            const isSingle = attachments.length === 1;
            return (
                <>
                    <p className='font-medium  flex gap-2 text-font14 text-gray-500 items-center '>
                        {getFileIcon(type, mimeType)}
                        <span>{type?.charAt(0).toUpperCase() + type?.slice(1)}</span>
                        {!isSingle && <p className='text-scogoorange text-font12 font-bold ml-1'> +{attachments.length - 1}</p>}
                    </p>
                    <div className='absolute m-auto top-2 right-2 truncate h-4/5 mt-1 mb-1' style={{ width: '11%' }}>
                        {getComponentByType({ type, url: fileUrl })}
                    </div>
                </>
            );
        } else if (message.type === 'card') {
            return <p className='text-scogo15 font-medium 2xl:text-font15 text-font12 break-words'>{message.payload.title}</p>;
        }
    };

    return (
        <div className={`relative w-full h-36 bottom-1 bg-blue-300 ${isChatPopup ? 'p-1' : 'p-3'} `}>
            <div className='w-full relative border m-auto bg-white h-full p-4 break-word rounded-md'>
                <span className='font-bold text-font13 text-scogoorange'>{message.from.username}</span>
                <div className='pl-4 mt-4 w-full truncate '>{replyMessage()}</div>
            </div>
            <div className=' absolute right-3 top-3 cursor-pointer' onClick={closeReply}>
                <img width='100%' src={process.env.PUBLIC_URL + '/img/close.png'} alt='' />
            </div>
        </div>
    );
}

export default ReplyBox;
