import React from 'react'


const Checkbox = ({ checkColor, CheckContent, onChange, checked, textStyle, dynamicSize }) => {

    const handleChange = (event) => {
        const { checked } = event.target
        onChange(checked);
    }
    return (
        <label className="inline-flex items-center">
            <input
                type="checkbox"
                onChange={handleChange}
                checked={checked}
                className={`${checkColor} h-4 w-4 rounded-3px  border-2 border-scogo88 focus:outline-none focus:ring-0 cursor-pointer`}  
                style={{height : dynamicSize, width : dynamicSize}}          
            />
            <span className={`${textStyle}`}>{CheckContent}</span>
        </label>
    )
}

export default Checkbox
