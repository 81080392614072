import { Button } from '@mui/material';
import Footer from '../layout/footer/Footer';
import Header from '../layout/header/Header';
import { signOutUserAction } from '../actions/auth';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import TextModal from '../common/Modals/TextModal';

const NotFound = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { isTextModalOpen } = useSelector((state) => state.modal);
    return (
        <div className='h-screen bg-scogogray'>
            <Header isInactiveUser />
            <div className='bg-scogof8 md:overflow-auto lg:overflow-hidden h-full pt-20'>
                <div className='flex w-full' style={{ justifyContent: 'center' }}>
                    <img src={process.env.PUBLIC_URL + '/img/something-went-wrong-img.png'} alt='user not found' />
                </div>
                <div className='flex w-full' style={{ justifyContent: 'center', margin: '70px 0px' }}>
                    <h1 style={{ font: 'normal normal bold 48px/24px Roboto', color: '#12355F' }}>
                        Your Account has been deactivated. Please contact the administrator.
                    </h1>
                </div>
                <div className='flex w-full' style={{ justifyContent: 'center' }}>
                    <Button
                        onClick={() => dispatch(signOutUserAction(navigate))}
                        style={{ background: '#F48A21', color: '#FFFFFF', font: 'normal normal medium 16px/19px Roboto', borderRadius: '6px' }}
                    >
                        Sign out
                    </Button>
                </div>
            </div>
            <Footer />
            {isTextModalOpen && <TextModal />}
        </div>
    );
};
export default NotFound;
