import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import { useSelector } from 'react-redux';
import { useRef } from 'react';
import ButtonScogoPrimary from '../../../common/Buttons/ButtonScogoPrimary';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import Form from '../../../common/Form';
import { useForm } from 'react-hook-form';
import Binary from '../../../common/Form/Binary';
import { closeFeasibiblity, getBOQList } from '../../../actions/tickets';
import { TableForm } from './TableForm';

const formName = 'close_feasibility';
const arrayName = 'eligibleSPs';

function CloseFeasibilityForm({ ticketId, searchTerm, setSeachTerm, isOnlyView }) {
    const defaultValues = {
        [arrayName]: [],
        raise_po: 'no',
    };

    const dispatch = useDispatch();
    const { isSpDropdownListLoading } = useSelector((state) => state.loading);
    const { eligibleSPs } = useSelector((state) => state.sp);
    const submitRef = useRef();
    const [isEditingRow, setIsEditingRow] = useState([]);
    const [selectedIspCustomerId, setSelectedIspCustomerId] = useState(undefined);
    const [spList, setspList] = useState([]);
    const { formButtonLoading } = useSelector((state) => state.loading);
    const [isISPsListLoading, setIsISPsListLoading] = useState(true);
    const navigate = useNavigate();
    const { viewTicket } = useSelector((state) => state.tickets);

    const stateCleanup = useCallback(() => {
        setIsEditingRow([]);
        setSeachTerm('');
        setSelectedIspCustomerId(undefined);
        setspList([]);
    }, [setSeachTerm]);

    useEffect(() => {
        setIsISPsListLoading(true);
        stateCleanup();
        setspList(eligibleSPs);

        setIsISPsListLoading(false);
    }, [eligibleSPs, stateCleanup]);

    const submit = (formValues) => {
        const data = {
            action: 'APPROVE',
            ispId: selectedIspCustomerId,
        };
        dispatch(
            closeFeasibiblity({
                data,
                formName,
                ticketId,
                refresh: true,
                navigate,
                openRaisePoModal: formValues.raise_po === 'yes',
                job_ticket_number: viewTicket?.job_ticket_number || ''
            })
        );
    };

    const methods = useForm({ defaultValues, mode: 'all' });

    useEffect(() => {
        if (viewTicket) {
            const genre = 'LINK';
            dispatch(getBOQList(viewTicket?.customer_details?.customer_id, genre));
        }
    }, [dispatch, viewTicket?.customer_details?.customer_id, viewTicket]);

    const { boqList } = useSelector((state) => state.tickets);

    const transformedBoqList = useMemo(() => {
        if (Array.isArray(boqList)) {
            return boqList.map((asset) => {
                return { ...asset, value: asset?.label?.toLowerCase() || '' };
            });
        };
        return [];
    }, [boqList]);

    return (
        <div className='shadow-lg rounded-lg bg-white'>
            <Form methods={methods} onSubmit={submit} buttonPostion={'justify-end'} onCancel={() => dispatch(closeModalAction())} customFormButtons submitRef={submitRef}>
                <TableForm
                    isOnlyView={isOnlyView}
                    isEditingRow={isEditingRow}
                    setSelectedIspCustomerId={setSelectedIspCustomerId}
                    setIsEditingRow={setIsEditingRow}
                    ticketId={ticketId}
                    spList={spList}
                    searchTerm={searchTerm}
                    isISPsListLoading={isISPsListLoading || isSpDropdownListLoading}
                    arrayName={arrayName}
                    transformedBoqList={transformedBoqList}
                />
                {!isOnlyView && (
                    <div className='flex items-center justify-between py-4 px-4'>
                        <div className='flex'>
                            <Binary label='Raise PO' name='raise_po' className='grid grid-cols-2 rows-fr grid-flow-row-dense items-center' />
                        </div>

                        <div className='rounded-2xl w-full'>
                            <ButtonScogoPrimary
                                onClick={(e) => {
                                    e.preventDefault();
                                    submitRef.current.click();
                                }}
                                textOrComponent={'Close Feasibility'}
                                loading={formButtonLoading[formName]}
                                buttonClass='bg-scogoprimary  float-right text-white font-medium h-14  rounded-lg  hover:bg-scogobgsky'
                                disabledClass='bg-scogogray cursor-not-allowed float-right text-white font-medium text-font12 h-14 rounded-lg '
                                loaderClass={'w-full py-2'}
                                disabled={!selectedIspCustomerId}
                            />
                        </div>
                    </div>
                )}
            </Form>
        </div>
    );
}

export default CloseFeasibilityForm;
