import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DropdownMenu from '../../../common/MenuDropdown';
import { IconButton } from '../../Payments/Modals/EditPayout/IconButton';
import {
    getIsCUstomerSignOffApproved,
    getIsEligibleToCloseFeasibility,
    getIsToShowApproveRejectAssetButton,
    getIsToShowSignOffRejectButton,
    getTicketActions,
    getIsToShowPendingDelivery,
    getIsToShowApprovePoButton,
    actionLabelList,
    approvePo,
    feasibilityAction,
    updateHardCopyStatus,
    addDocket,
    getIsToShowReverseDocket,
} from '../TicketController';
import { openTicketPaymentModal } from './Price';
import { getIsToShowSignOffApproveButton } from '../TicketController';
import { closeModalAction, openCustomModal, openFullscreenModal, openTextModalAction } from '../../../actions/modal';
import RejectSignOff from '../Modal/RejectSignOff';
import { customerSignOffAcceptTicket } from '../../../actions/ticketExecution';
import AcceptAsset from '../Modal/AcceptAsset';
import RejectAsset from '../Modal/RejectAsset';
import { updateDocketDetails } from '../../../actions/tickets';
import { useNavigate } from 'react-router-dom';
import CloseFeasibility from '../CloseFeasibility';
import PoApproval from '../PoApproval';
import { SimpleButton } from '../../../common/Buttons/SimpleButton';
import StatusLabel from '../../Payments/reusableComponents/StatusLabel';
import { getCustomerIdOfLoginUser } from '../../../utils/utils';

export const TicketAction = ({ ticket, tab, isChatPopup }) => {

    const { loggedUser } = useSelector((state) => state.auth);
    const loggedUserCustomerId = getCustomerIdOfLoginUser(loggedUser);
    const navigate = useNavigate();
    const type = loggedUser.type;
    const role = loggedUser.role.id;
    const ticketActions = getTicketActions(role, type, { ticket, loggedUserId: loggedUser.id, frontend_controller: loggedUser.frontend_controller, tab, loggedUserCustomerId });
    const action_label = actionLabelList.find((item) => item.check(ticket, { loggedUserId: loggedUser.id, loggedUserRole: role }));

    const showApproveButton = getIsToShowSignOffApproveButton({
        ticket,
        loggedUser,
    });
    const showRejectButton = getIsToShowSignOffRejectButton({ loggedUser, ticket });
    const isToShowCustomerSignOffApproveButton = getIsCUstomerSignOffApproved({ ticket, loggedUser });
    const showApproveRejectAsset = getIsToShowApproveRejectAssetButton({ ticket });
    const showCloseFeasibilityButton = getIsEligibleToCloseFeasibility({ ticket });
    const showPendingDocketDetailAction = getIsToShowPendingDelivery({ ticket, role, type });
    const showApprovePoButton = getIsToShowApprovePoButton({ ticket, loggedUserId: loggedUser.id });
    const showIcons =
        showApproveButton ||
        showRejectButton ||
        isToShowCustomerSignOffApproveButton ||
        showApproveRejectAsset ||
        showCloseFeasibilityButton ||
        showPendingDocketDetailAction ||
        showApprovePoButton;
    const showDropdownMenu = !(showApproveButton || showRejectButton || isToShowCustomerSignOffApproveButton || showApproveRejectAsset || showPendingDocketDetailAction);


    const labelOrdropDownAction = [
        {
            label: 'Approve PO',
            addAction: () => [approvePo, ...ticketActions],
            color: 'scogo2e',
            showinDropDown: showApprovePoButton,
        },
        {
            label: ticket.signOffAccept === 1 ? 'View Feasibility' : 'Close Feasibility',
            addAction: () => [feasibilityAction, ...ticketActions],
            color: 'scogo2e',
            showinDropDown: showCloseFeasibilityButton,
        },
        {
            label: 'Pending Delivery',
            color: 'scogo2e',
            addAction: () => [updateHardCopyStatus, ...ticketActions],
            showinDropDown: showPendingDocketDetailAction,
        },
        {
            label: 'Pending Docket',
            color: 'scogoorange',
            addAction: () => [addDocket, ...ticketActions],
            showinDropDown: getIsToShowReverseDocket(ticket),
        },
        {
            label: action_label?.label,
            addAction: () => ticketActions,
            color: action_label?.color,
            labelClass: action_label?.labelClass,
            showinDropDown: !showApprovePoButton && !showCloseFeasibilityButton && !showPendingDocketDetailAction && action_label?.label?.length > 1,
        },
        {
            label: 'more_vert',
            addAction: () => ticketActions,
            showinDropDown: !showApprovePoButton && !showCloseFeasibilityButton && !action_label?.label && !showPendingDocketDetailAction && ticketActions?.length > 0,
        },
        {
            label: action_label?.label,
            addAction: () => ticketActions,
            color: action_label?.color,
            labelClass: action_label?.label,
            showinDropDown: !showApprovePoButton && !showCloseFeasibilityButton && !action_label?.label && !showPendingDocketDetailAction && ticketActions?.length === 0,
        },
    ].filter((e) => e.showinDropDown);

    const approveReject = showApproveButton || isToShowCustomerSignOffApproveButton || showRejectButton || showApproveRejectAsset;


    if (approveReject) {
        return (
            <ApprovRejectIconActions
                action_label={action_label}
                ticket={ticket}
                showRejectButton={showRejectButton}
                isToShowCustomerSignOffApproveButton={isToShowCustomerSignOffApproveButton}
                showApproveRejectAsset={showApproveRejectAsset}
                showApproveButton={showApproveButton}
                isChatPopup={isChatPopup}
            />
        );
    } else if (showIcons && !showDropdownMenu) {
        return (
            <IconActions
                action_label={action_label}
                ticket={ticket}
                showCloseFeasibilityButton={showCloseFeasibilityButton}
                showPendingDocketDetailAction={showPendingDocketDetailAction}
                showApprovePoButton={showApprovePoButton}
                showApproveButton={showApproveButton}
            />
        );
    } else if (showDropdownMenu) {
        return <ShowDropdownMenuOrLabel payload={{ loggedUser, navigate }} dropDownAction={labelOrdropDownAction} ticket={ticket} isChatPopup={isChatPopup} />;
    }
};

const IconActions = ({ ticket, showCloseFeasibilityButton, showPendingDocketDetailAction, showApprovePoButton, action_label }) => {
    const ticketId = ticket.ticket_id;
    const dispatch = useDispatch();

    const onUpdateDocketDetailClick = () => {
        const formName = 'hard_copy_received';
        let heading = 'Hard Copy Received?';
        let text = `Are you sure you have received hard copy?`;
        let buttons = [
            {
                showLoading: true,
                textOrComponent: 'Yes',
                type: 'blue',
                buttonClass: 'hover:bg-scogobgsky',
                onClick: () =>
                    dispatch(
                        updateDocketDetails({
                            data: { action: 'HARD_COPY_RECEIVED', ticket_id: ticketId },
                            formName,
                            callCloseTicket: false
                        })
                    ),
            },
            {
                textOrComponent: 'No',
                type: 'redOutlined',
                buttonClass: 'hover:bg-scogoLightFillRed',
                onClick: () => dispatch(closeModalAction()),
            },
        ];
        let closeOnBackdropClick = false;
        dispatch(openTextModalAction({ heading, text, buttons, closeOnBackdropClick, formName }));
    };

    const onCloseFeasibilityClick = () => {
        let heading = 'Close Feasibility';
        let isOnlyView = false;
        if (ticket.signOffAccept === 1) {
            heading = 'View Feasibility';
            isOnlyView = true;
        }
        let modalComponent = <CloseFeasibility isOnlyView={isOnlyView} ticketId={ticketId} heading={heading} />;
        dispatch(openFullscreenModal({ heading, modalComponent }));
    };

    const onApprovePoClick = () => {
        let modalComponent = <PoApproval ticketId={ticketId} />;
        dispatch(openFullscreenModal({ modalComponent }));
    };

    const labelOrAction = [
        {
            label: action_label?.label?.length > 0 ? action_label.label : 'Approve PO',
            onClick: () => onApprovePoClick(),
            color: action_label?.label?.length > 0 ? action_label?.color : 'scogo2e',
            showinDropDown: showApprovePoButton,
        },
        {
            label: action_label?.label?.length > 0 ? action_label?.label : ticket.signOffAccept === 1 ? 'View Feasibility' : 'Close Feasibility',
            onClick: () => onCloseFeasibilityClick(),
            color: action_label?.label?.length > 0 ? action_label?.color : 'scogo2e',
            showinDropDown: showCloseFeasibilityButton,
        },
        {
            label: action_label?.label?.length > 0 ? action_label?.label : 'Update Docket',
            color: action_label?.label?.length > 0 ? action_label?.color : 'scogo2e',
            onClick: () => onUpdateDocketDetailClick(),
            showinDropDown: showPendingDocketDetailAction,
        },
    ].filter((e) => e.showinDropDown);

    return (
        <SimpleButton
            buttonIconComponent={<span className='material-icons text-font16'>manage_accounts</span>}
            btnClass={`bg-white border flex items-center justify-center leading-6 cursor-pointer  rounded-lg gap-1 font-medium cursor-pointer px-2 hover:bg-${labelOrAction[0].color} hover:text-white text-font11 border-${labelOrAction[0].color} text-${labelOrAction[0].color}`}
            textOrComponent={labelOrAction[0].label}
            onClick={() => labelOrAction[0].onClick()}
        />
    );
};

const ShowDropdownMenuOrLabel = ({ payload, ticket, dropDownAction, isChatPopup }) => {
    if (dropDownAction?.[0]?.addAction()?.length === 0 && dropDownAction?.[0].label?.length > 0) {
        return (
            <StatusLabel
                borderColor={`border-${dropDownAction?.[0]?.color}`}
                content={dropDownAction?.[0]?.label}
                color={dropDownAction?.[0].color}
                labelClass={dropDownAction?.[0]?.labelClass}
            />
        );
    } else if (dropDownAction?.[0]?.addAction()?.length === 0 && !dropDownAction?.[0].label) {
        if (ticket?.sign_off_response?.length > 0) {
            return <StatusLabel
                borderColor={"scogo2e"}
                content={ticket?.sign_off_response}
                color={"scogo2e"}
            />;
        } else {
            <></>
        }
    }

    const lookUp = (label, color) => {
        if (label === 'more_vert') {
            return label;
        } else {
            return (
                <SimpleButton
                    btnClass={` border flex items-center justify-center  leading-6 cursor-pointer rounded-lg gap-1 py-[0.5px] font-medium cursor-pointer px-1 border-${color} text-${color} ${dropDownAction?.[0].labelClass}`}
                    textOrComponent={
                        <p className={`text-font10  flex items-center justify-center `}>
                            {label}
                            <span className='material-icons text-font16 '>expand_more</span>
                        </p>
                    }
                // onClick={() => { }}
                />
            );
        }
    };

    const menuActions = dropDownAction?.[0]?.addAction();
    let uniqueActions = [...new Set(menuActions)];

    return (
        <DropdownMenu
            payload={payload}
            menuData={uniqueActions}
            item={ticket}
            componentOrIcon={lookUp(dropDownAction?.[0]?.label, dropDownAction?.[0]?.color)}
        />
    );
};

const ApprovRejectIconActions = ({ ticket, showRejectButton, isToShowCustomerSignOffApproveButton, showApproveRejectAsset, showApproveButton, action_label, isChatPopup }) => {
    const ticketId = ticket.ticket_id;
    const dispatch = useDispatch();
    const { loggedUser } = useSelector((state) => state.auth);
    const role = loggedUser.role.id;
    const type = loggedUser.type;
    const navigate = useNavigate()

    const handleCustomerSignOffApprove = () => {
        const formName = 'customer_signoff_approve';
        let heading = 'Sign Off';
        let text = `Do you want to close this ticket?`;
        let buttons = [
            {
                showLoading: true,
                textOrComponent: 'Approve Sign Off',
                type: 'blue',
                buttonClass: 'hover:bg-scogobgsky',
                onClick: () =>
                    dispatch(
                        customerSignOffAcceptTicket({
                            ticket_no: ticketId,
                            formName,
                        })
                    ),
            },
            {
                textOrComponent: 'Close',
                type: 'redOutlined',
                buttonClass: 'hover:bg-scogoLightFillRed',
                onClick: () => dispatch(closeModalAction()),
            },
        ];

        let closeOnBackdropClick = false;
        dispatch(openTextModalAction({ heading, text, buttons, closeOnBackdropClick, formName }));
    };

    const handleRejectApproveClick = () => {
        let heading = 'Sign Off';
        let modalWidth = '50rem';
        let modalHeight = 'auto';
        let modalComponent = <RejectSignOff ticketId={ticketId} />;
        dispatch(openCustomModal({ heading, modalWidth, modalHeight, modalComponent }));
    };

    const handleAcceptAssetClick = () => {
        let heading = 'Sign Off';
        let modalWidth = '50rem';
        let modalHeight = 'auto';
        let modalComponent = <AcceptAsset ticketId={ticketId} />;
        dispatch(openCustomModal({ heading, modalWidth, modalHeight, modalComponent }));
    };

    const handleRejectAssetClick = () => {
        let heading = 'Sign Off';
        let modalWidth = '50rem';
        let modalHeight = 'auto';
        let modalComponent = <RejectAsset ticketId={ticketId} />;
        dispatch(openCustomModal({ heading, modalWidth, modalHeight, modalComponent }));
    };


    return (
        <div className='flex gap-2 items-center justify-center flex-wrap  relative ' >
            <div className={`flex align-middle gap-x-3 labelClass`}>
                {action_label && (
                    <SimpleButton
                        buttonIconComponent={<span className='material-icons text-font14'>{action_label?.icon ? action_label?.icon : "fact_check"}</span>}
                        btnClass={`bg-white  border flex items-center text-font10 justify-center leading-6 cursor-pointer rounded-lg gap-1 font-medium cursor-pointer px-1 py-[0.5px] border-${action_label?.color}  text-${action_label?.color} `}
                        textOrComponent={action_label?.label || ticket?.sign_off_response || 'Asset'}
                        isChatPopup={isChatPopup}
                    />
                )}
                <div className={` py-4 childDiv w-full hidden z-50 absolute top-4 `}>
                    <div className='flex gap-x-3 px-10 z-50 justify-center pt-2'>
                        {showApproveButton && (
                            <IconButton
                                loader={false}
                                onClick={() => openTicketPaymentModal(ticket, { role, type, heading: 'Signoff', navigate, dispatch })}
                                onHoverTitle={'Approve Ticket'}
                                iconName={'done'}
                                backgroundColor={'bg-labelLightGreen text-scogo2e border border-scogo2e greenlinear-gradient'}
                                loaderSize='12'
                            />
                        )}
                        {isToShowCustomerSignOffApproveButton && (
                            <IconButton
                                loader={false}
                                onClick={() => handleCustomerSignOffApprove()}
                                onHoverTitle={'Approve Ticket'}
                                iconName={'done'}
                                backgroundColor={'bg-labelLightGreen text-scogo2e border border-scogo2e greenlinear-gradient'}
                                loaderSize='12'
                            />
                        )}
                        {showRejectButton && (
                            <IconButton
                                loader={false}
                                onClick={(e) => {
                                    handleRejectApproveClick();
                                }}
                                onHoverTitle={'Reject Ticket'}
                                iconName={'close'}
                                backgroundColor={'bg-scogoLightRed text-scogoclosed border border-scogoclosed redlinear-gradient'}
                                loaderSize='12'
                            />
                        )}
                        {showApproveRejectAsset && (
                            <>
                                <IconButton
                                    loader={false}
                                    onClick={(e) => {
                                        handleAcceptAssetClick();
                                    }}
                                    onHoverTitle={'Accept Asset'}
                                    iconName={'done'}
                                    backgroundColor={'bg-labelLightGreen text-scogo2e border border-scogo2e greenlinear-gradient'}
                                    loaderSize='12'
                                />
                                <IconButton
                                    loader={false}
                                    onClick={(e) => {
                                        handleRejectAssetClick();
                                    }}
                                    onHoverTitle={'Reject Asset'}
                                    iconName={'close'}
                                    backgroundColor={'bg-scogoLightRed text-scogoclosed border border-scogoclosed redlinear-gradient'}
                                    loaderSize='12'
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
