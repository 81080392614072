
import CustomizedSlider from '../Filter/Slider'
import { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { convertSecToDays, convertDaysToSec } from '../../utils/common';
import { textCss } from '../Css';

const SliderFIlter = forwardRef((props, ref) => {
    const { filterName, title, filterList, toConvert = true } = props;
    const { min, max, selectedMax, selectedMin } = filterList[filterName];
    const [updatedFilter, setUpdatedFilter] = useState({ low: selectedMin ? selectedMin : min, high: selectedMax ? selectedMax : max });

    const handleChangeSlideFilter = ({ filterRange }) => {
        let filterDays = { low: Math.floor(filterRange[0]), high: Math.ceil(filterRange[1]) }
        if (toConvert) {
            filterDays = { low: +convertDaysToSec(Math.floor(filterRange[0])), high: +convertDaysToSec(Math.ceil(filterRange[1])) }
        }
        setUpdatedFilter(filterDays);
    }

    useImperativeHandle(ref, () => ({
        getLocalFilter() {
            const selectedMin = updatedFilter.low
            const selectedMax = updatedFilter.high;;
            if (min !== selectedMin || selectedMax !== max) {
                return { [filterName]: { min, max, selectedMax, selectedMin } };
            }
            return { min, max, selectedMax: 0, selectedMin: 0 }
        },
    }));

    return <div className='flex flex-col gap-4 px-4 overflow-hidden border-2'>
        <p className={`${textCss?.headerTwo} mb-6 pt-2`}>{title}: </p>
        <CustomizedSlider customMethod={handleChangeSlideFilter} keyName={filterName} low={toConvert ? +convertSecToDays(updatedFilter.low) : updatedFilter.low} high={toConvert ? +convertSecToDays(updatedFilter.high) : updatedFilter.high} min={toConvert ? +convertSecToDays(min) : min} max={toConvert ? +convertSecToDays(max) : max} />
    </div>
});

export default SliderFIlter;