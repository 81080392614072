import React from 'react';
import { textCss } from '../../../../common/Css';
import OverflowTip from '../../../../common/OverflowTip';
import { SkeltonCardTwo } from '../../../../common/SkeltonCardTwo';
import { validateStringValue } from '../../../../utils/common';

export default function AssetDetails({ title, isLoading, headerTopRightComponent, partcode, serial_number, assetTypeTitle }) {
    const details = [
        {
            label: 'Asset Type',
            value: validateStringValue(assetTypeTitle),
        },
        {
            label: 'Serial Number',
            value: validateStringValue(serial_number),
        },
        {
            label: 'PartCode',
            value: validateStringValue(partcode),
        },
    ];

    const getDetails = () => {
        if (isLoading) {
            return (
                <div className='h-full'>
                    <SkeltonCardTwo arr={[1, 2]} height={12} className={''} padding={1} />
                </div>
            );
        }
        return (
            <div className='grid grid-cols-3 rows-fr grid-flow-row-dense gap-2 py-2'>
                {details.map((item) => {
                    return (
                        <span>
                            <h3 className={textCss?.detailsTitle}>{item.label}</h3>
                            <OverflowTip textClass={textCss?.detailsValue} someLongText={item.value} />
                        </span>
                    );
                })}
            </div>
        );
    };
    return (
        <div className='bg-white relative border-gray-200 shadow-lg w-full py-2 rounded-lg'>
            <div className='flex items-center border-b-2'>
                <div className='ribbon w-3/4'>
                    <span
                        className='ribbon3 bg-scogobgsky ribbonblue'
                        style={{
                            left: '-10px',
                        }}
                    >
                        {title}
                    </span>
                </div>
                {headerTopRightComponent && headerTopRightComponent}
            </div>
            <div className='px-4'>{getDetails()}</div>
        </div>
    );
}
