import React from 'react';
import { createRoot } from 'react-dom/client';

import { Provider } from 'react-redux';
import './index.css';
import './text.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import '../node_modules/react-datepicker/dist/react-datepicker.css';
import store from './store';
import { HMSRoomProvider } from "@100mslive/react-sdk";
import config from './config';
if (window.Cypress) {
    window.store = store;
}

const clarityFunction = (function (c, l, a, r, i, t, y) {
    c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
    t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
    y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
})(window, document, "clarity", "script", config.clarityKey);

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <>
        <Provider store={store}>
            <HMSRoomProvider>
                <App />
                <script type="text/javascript">
                    {clarityFunction}
                </script>
            </HMSRoomProvider>
        </Provider>
    </>
);

reportWebVitals();