import React, { useMemo, useEffect, useState } from 'react';
import Input from '../../../../common/Form/Input';
import Select from '../../../../common/Form/Select';
import { useSelector, useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import Checkbox from '../../../../common/Form/Checkbox';
import DatePicker from '../../../../common/Form/DatePicker';
import { findAllRepairCenters, getAssetsTypes, getInventoryPartcodes } from '../../../../actions/inventory';
import { actualValueForDropdown } from '../../../../utils/common';
import { assetsReceivedAs, warrantyType, workingCondition } from './index';

export const AddSpareAssetInputs = ({ warehouseId, isToShowCustomerDropdown }) => {
    const { repaircentersDropdown, warehousesDropDown, spareCategory, assetTypes, partCodes, endUsersList } = useSelector((state) => state.inventory);
    const { isCustomerListLoading, isWarehouseOwnerLoading, isAssetTypeLoading, isEndUserListLoading } = useSelector((state) => state.loading);
    const { customers } = useSelector((state) => state.customer);
    const { watch, setValue } = useFormContext();
    const dispatch = useDispatch();
    const [titleOptions, setTitleOptions] = useState([]);

    const hasGrn = watch('hasGrn');
    const hasFrn = watch('hasFrn');
    const hasOem = watch('hasOem');
    const hasWarranty = watch('hasWarranty');
    const customer_id = watch('customer_id');
    const title = watch('title');
    const category = watch('category');
    const partcode = watch('partcode');
    const addingNewTitle = actualValueForDropdown(title) === 'add_new_title';
    const addingNewCategory = actualValueForDropdown(category) === 'add_new_category';
    const addingNewPartcode = actualValueForDropdown(partcode) === 'add_new_partcode';

    useEffect(() => {
        const customerId = actualValueForDropdown(customer_id);
        if (isToShowCustomerDropdown && customerId) {
            dispatch(getInventoryPartcodes({ customerId, dropdown: true }));
            dispatch(findAllRepairCenters({ customerId, dropdown: true }));
        }
    }, [customer_id, dispatch, isToShowCustomerDropdown]);

    useEffect(() => {
        if (Array.isArray(assetTypes)) {
            setTitleOptions([
                {
                    key: 'add_new_title',
                    label: 'Add New Title',
                    value: 'add_new_title',
                },
                ...assetTypes,
            ]);
        }
    }, [assetTypes]);

    const categoryOptions = useMemo(() => {
        let list = [
            {
                key: 'add_new_category',
                label: 'Add New Category',
                value: 'add_new_category',
            },
        ];
        if (Array.isArray(spareCategory)) {
            list.push(...spareCategory);
        }
        return list;
    }, [spareCategory]);

    const partcodeOptions = useMemo(() => {
        let list = [
            {
                key: 'add_new_partcode',
                label: 'Add New Partcode',
                value: 'add_new_partcode',
            },
        ];
        if (Array.isArray(partCodes)) {
            list.push(...partCodes);
        }
        return list;
    }, [partCodes]);

    const customerDropdown = useMemo(() => {
        let list = [];
        if (Array.isArray(customers)) {
            customers.forEach((item) => {
                if (item.is_rma_eligible === 1) {
                    list.push({
                        key: item.customer_id,
                        value: item.customer_id,
                        label: item.label,
                        customer_id: item.customer_id,
                    });
                }
            });
        }
        return list;
    }, [customers]);

    const titleDropDownProps = {
        label: 'Title',
        required: true,
        name: 'title',
        options: titleOptions,
        isLoading: isAssetTypeLoading,
    };

    const titleTextProps = {
        label: 'Title Name',
        required: true,
        name: 'title_name',
        type: 'text',
    };

    return (
        <div className='px-3'>
            {!warehouseId && (
                <div className='w-full'>
                    <Select label='Warehouse' required name='warehouse_id' options={warehousesDropDown} isLoading={isWarehouseOwnerLoading} />
                </div>
            )}
            {isToShowCustomerDropdown && (
                <div className='w-full'>
                    <Select label='Customer' required name='customer_id' options={customerDropdown} isLoading={isCustomerListLoading} />
                </div>
            )}
            <div className='grid grid-cols-2 rows-fr grid-flow-row-dense'>
                <Select label='Asset Received As' required name='asset_received_as' options={assetsReceivedAs} />
                <Select label='Inward Source' required name='inward_source' options={repaircentersDropdown} />
            </div>
            <div className='flex w-full'>
                <Select
                    label='Category'
                    onChange={(e) => {
                        const isAdding = e?.key === 'add_new_category';
                        if (!isAdding && e?.key) {
                            dispatch(getAssetsTypes({ customerId: customer_id, categoryId: e?.value, type: 'SPARE', dropdown: true }));
                        }
                        if (!e?.key) setTitleOptions([]);
                        if (isAdding) {
                            setTitleOptions([
                                {
                                    key: 'add_new_title',
                                    label: 'Add New Title',
                                    value: 'add_new_title',
                                },
                            ]);
                        }
                    }}
                    required
                    name='category'
                    options={categoryOptions}
                    className='w-full'
                />
                {addingNewCategory && <Input label='Category Name' required name='category_name' type={'text'} className='w-full' />}
                {!addingNewCategory && (
                    <>
                        <Select {...titleDropDownProps} className='w-full' />
                        {addingNewTitle && <Input {...titleTextProps} className='w-full' />}
                    </>
                )}
            </div>
            <div>
                <Select label='End User' name='enduser_id' type={'dropdown'} options={endUsersList} isLoading={isEndUserListLoading} />
            </div>
            <div className={addingNewTitle ? ' flex justify-between' : ''}>
                {addingNewCategory && (
                    <>
                        <Select {...titleDropDownProps} className='w-full' />
                        {addingNewTitle && <Input {...titleTextProps} className='w-full' />}
                    </>
                )}
            </div>
            <div className='w-full flex justify-between'>
                <Input label='Make' name='make' type={'text'} className={'w-6/12'} />
                <Input label='Model' name='model' type={'text'} className={'w-6/12'} />
            </div>
            <div className={addingNewPartcode ? ' flex justify-between' : ''}>
                <Select label='Partcode' name='partcode' className={addingNewPartcode ? 'w-6/12' : ''} options={partcodeOptions} />
                {addingNewPartcode && (
                    <Input label='Partcode' required name='partcode_name' type={'text'} placeholder={'Partcode'} className={'w-6/12'} isClearableOnSuccess={true} />
                )}
            </div>
            <div className='w-full'>
                <Input label='Serial Number' name='serial_number' type={'text'} />
            </div>
            <div className='w-full flex justify-between'>
                <Input label='Type' type={'text'} name='type' className={'w-6/12'} />
                <Input label='External Order Id' type={'text'} name='external_order_id' className={'w-6/12'} />
            </div>
            <div className='w-full flex justify-between'>
                <Input label='Quantity' type={'number'} name='quantity' className={'w-6/12'} />
                <Input label='Unit' type={'number'} name='unit' className={'w-6/12'} />
            </div>
            <div className='w-full'>
                <Input label='Price (₹)' type={'number'} name='value' step={'any'} />
            </div>
            <div className='w-full flex justify-between'>
                <Select className='w-6/12' label='Working Condition' name='working_condition' options={workingCondition} />
                <DatePicker className='w-6/12' label='Added On' name='added_on' dateFormat='dd-MM-yyyy' />
            </div>
            <div className='px-1'>
                <Checkbox id='hasGrn' label='Do you have GRN' name='hasGrn' />
            </div>
            {hasGrn && (
                <div className='w-full'>
                    <Input label='GRN' type={'text'} name='grn' />
                </div>
            )}
            <div className='px-1'>
                <Checkbox id='hasFrn' label='Do you have FRN' name='hasFrn' />
            </div>
            {hasFrn && (
                <div className='w-full'>
                    <Input label='FRN' type={'text'} name='frn' />
                </div>
            )}
            <div className='px-1'>
                <Checkbox id='hasWarranty' label='Do you have Warranty Details' name='hasWarranty' />
            </div>
            {hasWarranty && (
                <>
                    <div className='w-full'>
                        <Select label='Warranty Type' name='warranty_type' options={warrantyType} />
                    </div>
                    <div className='w-full flex justify-between'>
                        <DatePicker label='Warranty Start Date' name='warranty_start_date' dateFormat='dd-MM-yyyy' className={'w-6/12'} />
                        <DatePicker label='Warranty End Date' name='warranty_end_date' dateFormat='dd-MM-yyyy' className={'w-6/12'} />
                    </div>
                </>
            )}
            <div className='px-1'>
                <Checkbox id='hasOem' label='Do you have OEM Details' name='hasOem' />
            </div>
            {hasOem && (
                <>
                    <div className='w-full flex justify-between'>
                        <Input label='OEM Id' type={'text'} name='oem_id' className={'w-6/12'} />
                        <Input label='OEM Type' type={'text'} name='oem_warranty_type' className={'w-6/12'} />
                    </div>
                    <div className='w-full flex justify-between'>
                        <DatePicker label='OEM Start Date' name='oem_start_date' dateFormat='dd-MM-yyyy' className={'w-6/12'} />
                        <DatePicker label='OEM End Date' name='oem_end_date' dateFormat='dd-MM-yyyy' className={'w-6/12'} />
                    </div>
                </>
            )}
        </div>
    );
};
