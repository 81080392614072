import { sp } from '../types';

export const sharePublicProfile = ({ userId, username }) => ({
    type: sp.SHARE_PUBLIC_PROFILE_REQUEST,
    payload: { userId, username },
});

export const getIdCard = ({ userId }) => ({
    type: sp.SHARE_ID_CARD_REQUEST,
    payload: { userId },
});

export const removeIdCardFromStore = () => ({
    type: sp.REMOVE_ID_CARD_REQUEST,
});

export const removePublicProfileFromStore = () => ({
    type: sp.REMOVE_PUBLIC_PROFILE_REQUEST,
});

export const getEligibleSPsList = ({ ticketId, customer, type, dropdown, raisePo = false, getdetails, execution_date }) => ({
    type: sp.GET_ELIGIBLE_SP_LIST_REQUEST,
    payload: { ticketId, customer, type, dropdown, raisePo, getdetails, execution_date },
});

export const updateSPDetails = (formValues, spId) => {
    let form = new FormData();
    Object.keys(formValues).map((key) => {
        if (key === 'sp_city_ids') {
            if (Array.isArray(formValues.sp_city_ids)) {
                formValues.sp_city_ids.map((item) => {
                    return form.append(`sp_city_ids[]`, parseInt(item));
                });
            } else {
                form.append(`sp_city_ids[]`, parseInt(formValues.sp_city_ids));
            }
        } else if (key === 'sp_use_cases') {
            formValues.sp_use_cases.map((item) => {
                return form.append(`sp_use_cases[]`, parseInt(item));
            });
        } else if (key === 'sp_have_gst') {
            if (formValues.hasOwnProperty('sp_have_gst') && formValues.sp_have_gst) {
                form.set(`sp_have_gst`, 1);
            } else {
                form.set(`sp_have_gst`, 0);
            }
        } else if (key === 'sp_applied_as') {
            form.set(`sp_applied_as`, formValues[key]);
            if (formValues.sp_applied_as.toString() === '1') {
                form.set(`apply_as_fe`, 1);
            } else {
                if (formValues.hasOwnProperty('apply_as_fe') && formValues.apply_as_fe) {
                    form.set(`apply_as_fe`, 1);
                } else {
                    form.set(`apply_as_fe`, 0);
                }
            }
        } else {
            form.set(`${key}`, formValues[key]);
        }
    });
    return {
        type: sp.UPDATE_SP_DETAILS_REQUEST,
        payload: { form, spId },
    };
};

export const clearEligibleSpListFromStore = (payload) => ({
    type: sp.CLEAR_ELIGIBLE_SP_LIST,
    payload,
});

export const clearEligibleSpDropdownListFromStore = (payload) => ({
    type: sp.CLEAR_ELIGIBLE_SP_DROPDOWN_LIST,
});

export const updateStateDetail = ({ formValues, spId }) => {
    let form = new FormData();
    Object.keys(formValues).map((key) => {
        if (key === 'sp_state_ids') {
            if (Array.isArray(formValues.sp_state_ids)) {
                formValues.sp_state_ids.map((item) => {
                    return form.append(`sp_state_ids[]`, parseInt(item));
                });
            } else {
                form.append(`sp_state_ids[]`, parseInt(formValues.sp_state_ids));
            }
        }
    });
    return {
        type: sp.UPDATE_STATE_DETAIL_REQUEST,
        payload: { form, spId },
    };
};

export const updateSpCustomerStatus = ({ customerId, status }) => ({
    type: sp.UPDATE_SP_CUSTOMER_STATUS_REQUEST,
    payload: { customerId, status },
});

export const putToSPStore = (payload) => ({
    type: sp.PUT_TO_SP_STORE,
    payload,
});
