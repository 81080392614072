import { useSelector } from 'react-redux';
import { copyToClipboard } from '../../actions/utils';
import { openTextModalAction, closeModalAction, openCustomModal } from '../../actions/modal';
import { sendDataThroughWebsocket } from '../../actions/websocket';
import { getCustomerIdOfLoginUser } from '../../utils/utils';
import { websocketEvents } from '../../utils/websocket';
import { isCluster, isCustomer, isCustomerAdmin, isCustomerGroup, isCustomerUser, isFe, isIsp, isPsp, isScm, isSp, isSpIsp, isSuperVisor } from '../../utils/role';
import { getDevelopersList, getFEListWithSpId } from '../../actions/conversations';
import { getCustomerAddonsListAction } from '../../actions/customer';
import { isHelpAllowed } from '../../utils/access';
import { formatCount, validateNumberValue } from '../../utils/common';
import { chatSidebarTab } from '../../layout/sidebar';
import { getGroupUserList } from '../../actions/partners';
import WhatsappChatList from './Modals/WhatsappChatList';
import IconToolTip from '../../common/IconToolTip';
import { getWhatsappChatList, setDialpad } from '../../actions/whatsapp';
import { useDispatch } from 'react-redux';
import NotifyEndCustomer from './Modals/NotifyEndCustomer';


export const chatType = {
    ticket: 'ticket',
    single: 'single',
    group: 'group',
    help: 'support',
};

export const allTicketsTab = {
    tabName: 'All',
    tabID: 'alltickets',
    path: 'alltickets',
    belongToTab: ({ type }) => {
        return type === chatType.ticket;
    },
};
const tagCheck = ({ type, tabTags, tags }) => type === chatType.ticket && tabTags.some((tag) => tags?.includes?.(tag));
export const newTicketsTab = {
    tabName: 'New',
    tabID: 'newtickets',
    path: 'newtickets',
    tags: ['new'],
    countKey: 'new',
    get belongToTab() {
        return ({ type, tags }) => tagCheck({ type, tabTags: this.tags, tags });
    },
};
export const ongoingTicketsTab = {
    tabName: 'Ongoing',
    tabID: 'ongoingtickets',
    path: 'ongoingtickets',
    tags: ['ongoing', 'triggered', 'planned'],
    countKey: 'ongoing',
    get belongToTab() {
        return ({ type, tags }) => tagCheck({ type, tabTags: this.tags, tags });
    },
};
export const issuesTicketsTab = {
    tabName: 'Issues',
    tabID: 'issuetickets',
    path: 'issuetickets',
    tags: ['on_hold', 'sign_off_rejected'],
    countKey: 'issues',
    get belongToTab() {
        return ({ type, tags }) => tagCheck({ type, tabTags: this.tags, tags });
    },
};

export const mentionsTicketsTab = {
    tabName: 'Mentions',
    tabID: 'mentionsTickets',
    path: 'mentionsTickets',
    tags: [],
    countKey: 'mentioned',
    get belongToTab() {
        return (conversation, message, loggedUser) => {
            if (Array.isArray(message.tags)) {
                return Boolean(message.tags.find((msg) => msg?.user?.userId === loggedUser.id));
            }
            return false
        }
    },
};

export const completedTicketsTab = {
    tabName: 'Completed',
    tabID: 'completedtickets',
    path: 'completedtickets',
    tags: ['sign_off_requested', 'site_complete', 'hard_closed', 'sign_off_accepted'],
    countKey: 'completed',
    get belongToTab() {
        return ({ type, tags }) => tagCheck({ type, tabTags: this.tags, tags });
    },
};
export const myTicketsTab = {
    tabName: 'My Tickets',
    tabID: 'mytickets',
    path: 'mytickets',
    countKey: 'myTickets',
    tags: ['my_ticket'],
    get belongToTab() {
        return ({ type, tags }) => tagCheck({ type, tabTags: this.tags, tags });
    },
};
export const ticketsTab = {
    tabName: 'Tickets',
    tabID: 'tickets',
    path: 'tickets',
    countKey: 'ticket',
    subTabs: [allTicketsTab, mentionsTicketsTab, completedTicketsTab,],
};

export const ticketsTabWithMyTickets = {
    ...ticketsTab,
    subTabs: [allTicketsTab, mentionsTicketsTab, myTicketsTab, completedTicketsTab],
};

export const helpOpenTab = {
    tabName: 'Open',
    tabID: 'helpopen',
    forCount: 'open',
    path: 'helpopen',
    countKey: 'open',
    belongToTab: ({ type, active, tags }, _, loggedUser) => {
        const base = type === chatType.help && active;
        if (isScm(loggedUser.role.id, loggedUser.type)) {
            const hasntBeenAskedForClosure = !Array.isArray(tags) || !tags.includes('requested_closure');
            return base && (hasntBeenAskedForClosure);
        }
        return base;
    },
};

export const helpCloseTab = {
    tabName: 'Closed',
    tabID: 'helpclosed',
    path: 'helpclosed',
    countKey: 'closed',
    belongToTab: ({ type, active, tags }, _, loggedUser) => {
        const scmAskedForClosure = isScm(loggedUser.role.id, loggedUser.type) && active && Array.isArray(tags) && tags.includes('requested_closure');
        return type === chatType.help && (!active || (scmAskedForClosure));
    },
};

export const helpTab = {
    tabName: 'Help',
    tabID: 'help',
    path: 'help',
    countKey: 'support',
    subTabs: [helpOpenTab, helpCloseTab],
};

export const groupTab = {
    tabName: 'Chats',
    tabID: 'groups',
    path: 'groups',
    belongToTab: ({ type }) => {
        return [chatType.group, chatType.single].includes(type);
    },
};
export const notifcationsTab = {
    tabName: 'Notifications',
    tabID: 'notifications',
    path: 'notifications',
    render: ({ onClick, openTabId }) => {
        return <NotificationBell onClick={onClick} openTabId={openTabId} />;
    },
    belongToTab: () => { },
};

const NotificationBell = ({ onClick, openTabId }) => {
    const user = useSelector((state) => state.user);
    const count = user?.[chatSidebarTab.countKey];
    return (
        <div
            className={`relative flex items-center hover:text-scogoprimary cursor-pointer h-full ${openTabId === notifcationsTab.tabID ? 'border-b-2 border-scogoprimary' : 'text-scogogray'
                }`}
            onClick={onClick}
        >
            <span className='material-icons h-full pt-4 px-4 text-font16'>notifications</span>
            {count > 0 && (
                <span className='absolute text-white text-font07 flex items-center justify-center transform bg-scogoorange rounded-full left-7 top-3 px-1'>
                    {formatCount(count)}
                </span>
            )}
        </div>
    );
};

export const chatActions = {
    edit: 'edit',
    delete: 'delete',
    copy: 'copy',
    reply: 'reply',
    forwardMessageToWhatsapp: "forwardMessageToWhatsapp",
    notify_end_user: 'notify_end_user'

};

export const messageType = {
    message: 'message',
    card: 'card',
    attachments: 'attachments',
    reply: 'reply',
};

export const botMessageType = {
    Chips: 'Chips',
    FAQ: 'FAQ',
    Video: 'Video',
    Greet: 'Greet',
};

export const specialUser = {
    all: -2,
    bot: -1,
};

const messageReply = {
    key: chatActions.reply,
    menuName: 'Reply',
    menuIcon: 'reply',
    iconColor: 'text-scogoprimary',
    onClick: (message, _, { onReplyClick } = {}) => {
        return onReplyClick(message);
    },
};

const editMessage = {
    key: chatActions.edit,
    menuName: 'Edit',
    menuIcon: 'edit',
    iconColor: 'text-scogoprimary',
    onClick: (message, _, { onEditClick } = {}) => {
        return onEditClick(message);
    },
};

const copyMessage = {
    key: chatActions.copy,
    menuName: 'Copy',
    menuIcon: 'content_copy',
    iconColor: 'text-scogoorange',
    onClick: (message, dispatch) => {
        let messageCopyLookupFunction = {
            message: (msg) => msg.message,
            reply: (msg) => msg.message,
            attachments: (msg) => msg.attachments.map((attachment) => attachment.caption).join(','),
            event: () => { },
        };
        let toCopy = messageCopyLookupFunction[message.type](message);
        return dispatch(copyToClipboard({ data: toCopy }));
    },
};


export const DialpadIcon = () => {
    const { showDialpad } = useSelector((state) => state.whatsapp)
    const dispatch = useDispatch()
    return <IconToolTip title="Number" onClick={() => {
        dispatch(setDialpad(!showDialpad))
    }}>
        <span className='material-icons text-scogoprimary font-medium align-middle text-4xl hover:text-scogoorange cursor-pointer'  >
            dialpad
        </span>

    </IconToolTip>


}

const forwardMessageToWhatsapp = {
    key: chatActions.forwardMessageToWhatsapp,
    menuName: 'Forward To WhatsApp',
    imgIcon: '/img/whatsapp.png',
    iconColor: 'text-scogoorange',
    onClick: (message, dispatch) => {
        dispatch(
            openCustomModal({
                heading: `Forward to WhatsApp Group`,
                modalWidth: '30vw',
                modalHeight: 'auto',
                maxHeight: '72vh',
                headerComponent: <div className='flex items-center gap-2' >
                    <DialpadIcon />

                    <IconToolTip title="Refresh" onClick={() => {
                        let data = {
                            type: "REFERSH_WHATSAPP_LIST",
                        }
                        if (message?.conversation?.type === chatType.ticket) {
                            data.ticketId = message?.conversation?.conversationId
                        } else {
                            data.messageId = message?._id
                        }
                        dispatch(getWhatsappChatList(data))
                    }}>
                        <span className='material-icons text-scogoprimary font-medium align-middle text-4xl hover:text-scogoorange cursor-pointer'  >
                            refresh
                        </span>

                    </IconToolTip>
                </div>,
                modalComponent: <WhatsappChatList message={message} ticketId={message.conversation?.conversationId} />,
            })
        );
    },
};

const notifyEndCustomer = {
    key: chatActions.notify_end_user,
    menuName: 'Notify End Customer',
    menuIcon: 'notifications',
    iconColor: 'text-scogoorange',
    onClick: (message, dispatch, { ticket }) => {
        let heading = (<span className='text-black'>Notify End Customer</span>);
        let mobile, customerId = '';
        if (validateNumberValue(ticket?.requester_mobile)) mobile = validateNumberValue(ticket?.requester_mobile);
        else if (validateNumberValue(ticket?.job_site_contact_mobile)) mobile = validateNumberValue(ticket?.job_site_contact_mobile);
        if (validateNumberValue(ticket?.customer_details?.customer_id)) customerId = validateNumberValue(ticket?.customer_details?.customer_id);
        return dispatch(
            openCustomModal({
                heading,
                modalWidth: '40rem',
                modalHeight: 'auto',
                modalComponent: <NotifyEndCustomer mobile={mobile} customerId={customerId} message={message.message} domain={ticket?.customer_details?.domain} ticketId={ticket?.id} />,
            })
        );
    },
};

const onDeleteClick = (dispatch, { conversationId, messageId, deletedFor = 'EVERYONE' }) => {
    dispatch(
        sendDataThroughWebsocket({
            action: websocketEvents.chat.deleteMessage,
            data: {
                conversation: conversationId,
                messageId,
                deletedFor,
            },
        })
    );
};

const deleteMessage = {
    key: chatActions.delete,
    menuName: 'Delete',
    menuIcon: 'delete',
    iconColor: 'text-scogoclosed',
    onClick: (message, dispatch) => {
        const conversationId = message.conversation._id;
        const messageId = message._id;
        return dispatch(
            openTextModalAction({
                heading: 'Delete Message',
                text: 'Are you sure you want to continue?',
                closeOnBackdropClick: false,
                formName: websocketEvents.chat.deleteMessage,
                buttons: [
                    {
                        showLoading: true,
                        textOrComponent: 'Yes',
                        type: 'red',
                        buttonClass: 'hover:bg-scogoLightFillRed',
                        onClick: () => onDeleteClick(dispatch, { conversationId, messageId }),
                    },
                    {
                        textOrComponent: 'No',
                        type: 'blue',
                        buttonClass: 'hover:bg-scogobgsky',
                        onClick: () => {
                            dispatch(closeModalAction());
                        },
                    },
                ],
            })
        );
    },
};
export const ChatFormField = (fieldName) => {
    const { loggedUser } = useSelector((state) => state.auth);
    const role = loggedUser.role.id,
        type = loggedUser.type;
    if (fieldName === 'createGroupFormCustomerField') return isScm(role, type);
    return false;
};

const getChatActionTabs = ({ role, type }) => {
    const action = [editMessage, copyMessage, deleteMessage];
    if (isScm(role, type)) action.push(forwardMessageToWhatsapp, notifyEndCustomer);
    return action
};

const getChatCreatedByCustomerId = ({ role, type, chatCustomerId, loggedUserCustomerId }) => {
    let customer_id = loggedUserCustomerId;
    if (isScm(role, type)) customer_id = chatCustomerId;
    return customer_id;
};

const getCategoryList = ({ role, type, loggedUserCustomerId, servicePartnerId }) => {
    let group = {
        name: 'New Group',
        key: 'group',
        icon: 'people',
    };
    let scogo = {
        name: 'SCOGO',
        key: 'scmClusterPspIwan',
        icon: 'manage_accounts',
        action: getGroupUserList,

        roles: 'SCM,CLUSTER,PSP,IWAN,SUPERVISOR'
    };
    let scogoScm = {
        name: 'SCOGO',
        key: 'scmscogo',
        icon: 'manage_accounts',
        action: getGroupUserList,

        roles: 'SCM'
    }
    let clusterScogo = {
        name: 'SCOGO',
        key: 'clusterScogo',
        icon: 'manage_accounts',
        action: getGroupUserList,

        roles: 'SCM,PSP,SUPERVISOR'
    }
    let supervisorScogo = {
        name: 'SCOGO',
        key: 'supervisorSupervisor',
        icon: 'manage_accounts',
        action: getGroupUserList,

        roles: 'CLUSTER,PSP'
    }
    let pspScogo = {
        name: 'SCOGO',
        key: 'pspScogo',
        icon: 'manage_accounts',
        action: getGroupUserList,

        roles: 'SCM,CLUSTER'
    }
    let spFe = {
        name: 'SP/FE',
        key: 'spfe',
        icon: 'person',

        action: getGroupUserList,
        roles: 'PARTNER,ENGINEER'
    };
    let sp = {
        name: 'SP',
        key: 'sp',
        icon: 'person',

        action: getGroupUserList,
        roles: 'PARTNER'
    };
    let isp = {
        name: 'ISP',
        key: 'isp',
        icon: 'person',
        action: getGroupUserList,

        roles: 'ISP'
    };
    let feWithSpId = {
        name: 'FE',
        key: 'feWithSpId',
        icon: 'engineering',
        action: () => getFEListWithSpId(servicePartnerId),
    };


    let developers = {
        name: 'Developers',
        key: 'developers',
        icon: 'person',
        action: getDevelopersList,
    }
    let customer = {
        name: 'Customer',
        key: 'customers',
        icon: 'person',
    };
    let customer_users = {
        name: 'Users',
        key: 'customer_users',
        action: () => getCustomerAddonsListAction(loggedUserCustomerId),
    };
    if (isCustomer(role, type) || isCustomerAdmin(role, type) || isCustomerUser(role, type)) return [group, scogoScm, customer_users];
    if (isSp(role, type)) return [scogoScm, feWithSpId];
    if (isIsp(role, type)) return [scogoScm];
    if (isFe(role, type)) return [scogoScm];
    if (isSuperVisor(role, type)) return [supervisorScogo, spFe];
    if (isScm(role, type)) return [group, scogo, spFe, isp, customer, developers];
    if (isCluster(role, type)) return [clusterScogo, sp]
    if (isPsp(role, type)) return [pspScogo, sp]
    return [];
};


export const GetChatLogic = (logic, { chatCustomerId } = {}) => {
    const { loggedUser } = useSelector((state) => state.auth);
    const role = loggedUser.role.id,
        type = loggedUser.type,
        loggedUserCustomerId = getCustomerIdOfLoginUser(loggedUser),
        servicePartnerId = loggedUser.service_partner_id;
    if (logic === 'messageAction') return getChatActionTabs({ role, type, });
    if (logic === 'getChatCreatedByCustomerId') return getChatCreatedByCustomerId({ role, type, loggedUserCustomerId, chatCustomerId });
    if (logic === 'getCategoryList') return getCategoryList({ role, type, loggedUserCustomerId, servicePartnerId });
};

export const showApprovalOptionsinMedia = ({ conversationType, isApproved, role, type }) => {
    if (isScm(role, type) || isCluster(role, type) || isSuperVisor(role, type) || isCustomerGroup(role, type)) {
        return conversationType === chatType.ticket && typeof isApproved !== 'boolean';
    }
    return false;
};

export const showApprovalOptionsinCardMessage = ({ role, type }) => {
    if (isScm(role, type) || isCluster(role, type) || isSuperVisor(role, type) || isCustomerGroup(role, type)) {
        return true;
    }
    return false;
};

const getTabFromQueryString = (tabs, urlQueryString) => {
    const match = urlQueryString?.match(/[\?&]tab=(\w+)/);
    let queryTab;
    if (match) queryTab = match[1];
    const matchingTab = tabs.find((tab) => {
        if (!tab?.subTabs?.length) {
            return tab.path === queryTab;
        } else return tab.subTabs.find((subTab) => subTab.path === queryTab);
    });
    if (matchingTab) {
        const matchingSubTab = matchingTab?.subTabs?.find?.((subTab) => subTab.path === queryTab);
        return { matchingTab, matchingSubTab };
    }
    return {};
};

export const getChatsDefaultTab = (role, type, { frontend_controller, urlQueryString, is_possible_tech_support }) => {
    const tabs = getChatMainTabs(role, type, { frontend_controller, is_possible_tech_support });
    let tab = tabs[0],
        subTab = tab.subTabs ? tab.subTabs[0] : null;
    if (urlQueryString) {
        const { matchingTab, matchingSubTab } = getTabFromQueryString(tabs, urlQueryString);
        if (matchingTab) {
            tab = matchingTab;
            subTab = matchingSubTab;
        }
    }
    return { rootTab: tab, subTab: subTab };
};

export const getChatMainTabs = (role, type, { frontend_controller, is_possible_tech_support }) => {
    let tabs = [groupTab, ticketsTab, ticketsTabWithMyTickets, notifcationsTab];
    if (
        isSp(role, type) ||
        isFe(role, type) ||
        isPsp(role, type) ||
        isScm(role, type) ||
        isCluster(role, type) ||
        isSuperVisor(role, type) ||
        isIsp(role, type) ||
        isSpIsp(role, type)
    ) {
        tabs = [groupTab, ticketsTab, ticketsTabWithMyTickets, helpTab, notifcationsTab];
    }

    return tabs.filter((tab) => {
        if (tab.tabID === helpTab.tabID && isScm(role, type)) {
            return isHelpAllowed(frontend_controller);
        }
        // only 1 among ticketsTab and ticketsTabWithMyTickets can be in the array or we'll have a bad time
        if (tab.tabID === ticketsTab.tabID) {

            if (tab.subTabs.find((subTab) => subTab.tabID === myTicketsTab.tabID)) {
                // ticketsTabWithMyTickets
                return is_possible_tech_support === 1;
            } else {
                // normal ticketsTab
                return is_possible_tech_support !== 1;
            }
        }
        return true;
    });
};

export const defaultGroupChatImage = process.env.PUBLIC_URL + '/img/default_group.png';
export const maxChatFileSizeInBytes = 20000000;
export const defaultSingleChatPic = process.env.PUBLIC_URL + '/img/single_user.png';
export const defaultTicketChatPic = process.env.PUBLIC_URL + '/img/ticket.svg';
export const defaultHelpChatPic = process.env.PUBLIC_URL + '/img/help.svg';
export const groupCategory = 'group';
export const customerCategory = 'customers';
export const customerUserCategory = 'customer_users';
export const clusterPSPCategory = 'cluster_psp';
export const isUserEnabled = 1;
export const defaultMessageLimit = 20;
