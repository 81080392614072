import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { SkeletonLoader } from './SkeletonLoader';

export const SkeltonCardOne = () => {
    return (
        <Box sx={{ paddingLeft: 2, paddingRight: 2, paddingBottom: 2, paddingTop: 2 }}>
            {[1, 2, 3, 4].map((elem, index) => {
                return (
                    <div className='mt-2' key={index}>
                        <Skeleton sx={{ background: 'rgb(221,221,221)' }} variant='rectangular' width={'full'} height={25} animation='wave' />
                    </div>
                );
            })}
        </Box>
    );
};
