import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import { addEndUser } from '../../../actions/users';
import { useForm } from 'react-hook-form';
import Form from '../../../common/Form';
import Input from '../../../common/Form/Input';

const formName = 'addNewEndUser';

const AddNewEndUser = ({ modalData, customerId }) => {
    const dispatch = useDispatch();
    const { formButtonLoading } = useSelector((state) => state.loading);

    const defaultValues = {
        end_user_name: '',
    };

    const submit = (formValues) => {
        const end_user_name = formValues.end_user_name;
        const payload = { end_user_name, formName };
        if (customerId) payload.customerId = customerId;
        if (end_user_name) {
            dispatch(addEndUser(payload));
        }
    };

    const closeModal = () => {
        dispatch(closeModalAction());
    };

    const methods = useForm({ defaultValues, mode: 'all' });

    return (
        <>
            <Form
                className='w-full'
                onSubmit={submit}
                onCancel={closeModal}
                submitButtonText={'Add'}
                showSubmit
                submitContainerClassName='flex gap-2 justify-end py-4 px-4'
                isLoading={formButtonLoading[formName]}
                methods={methods}
            >
                <div className='px-3 w-full'>
                    <Input label='End User' required name='end_user_name' />
                </div>
            </Form>
        </>
    );
};

export default AddNewEndUser;
