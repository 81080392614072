import React from 'react';
import { selectIsPeerAudioEnabled, selectIsPeerVideoEnabled, useHMSStore } from '@100mslive/react-sdk';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import StopScreenShareIcon from '@mui/icons-material/StopScreenShare';


export const ParticipantsList = ({ peer }) => {

    const isPeerVideoEnabled = useHMSStore(selectIsPeerVideoEnabled(peer.id));
    const isPeerAudioEnabled = useHMSStore(selectIsPeerAudioEnabled(peer.id));
    const isPeerScreenShareEnabled = peer.auxiliaryTracks?.length > 0;
    return (
        <div className='flex items-center justify-between p-5 gap-10'>
            <p className=' text-font18'>{peer.name}</p>
            <div className='flex gap-6'>

                {
                    isPeerAudioEnabled ?
                        <MicIcon sx={{ fontSize: 30 }} className='text-scogoclosed hover:text-gray-700' />
                        :
                        <MicOffIcon sx={{ fontSize: 30 }} className='hover:text-gray-700' />
                }
                {
                    isPeerVideoEnabled ?
                        <VideocamIcon sx={{ fontSize: 30 }} className=' text-scogoclosed hover:text-gray-700' />
                        :
                        <VideocamOffIcon sx={{ fontSize: 30 }} className=' hover:text-gray-700' />
                }
                {
                    isPeerScreenShareEnabled ?
                        <ScreenShareIcon sx={{ fontSize: 30 }} className=' text-scogoclosed hover:text-gray-700' />
                        :
                        <StopScreenShareIcon sx={{ fontSize: 30 }} className='  hover:text-gray-700' />
                }
            </div>
        </div>
    )
}
