import React, { useState } from "react";
import _ from 'lodash';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { textCss } from "../../../../../common/Css";
import moment from 'moment';
import { useSelector } from 'react-redux';

export default function DateTimePicker(props) {
  const { className, label, required, name, placeholder, onChangeHandler, isSingleDatePicker, maxDate, value, minDate, showTimeSelect = false, errors, dateFormat, inputValueFormat, defaultValue, form, todayButton = true, timePicker, defaultStartEndDate = [null, null], padding = 'px-4 py-3', readOnly } = props;

  const { setInitialForm } = useSelector((state) => state.utils);


  const getDefaultDate = (date) => {
    let newDate = moment(date);
    if (date && newDate.isValid()) return new Date(date);
    else return undefined;
  }

  const [singleDate, setSingleDate] = useState(getDefaultDate(defaultValue));

  const [selectedTime, setSelectedTime] = useState(getDefaultDate(defaultValue));

  React.useEffect(() => {
    setSingleDate(getDefaultDate());
  }, [setInitialForm])

  let [dateRange, setDateRange] = useState(defaultStartEndDate);
  let [startDate, endDate] = dateRange;

  const onDatePickerChange = (event) => {
    setDateRange(event);
    [startDate, endDate] = event;
    let date = { 'startDate': startDate, 'endDate': endDate }
    onChangeHandler({ name, value: date });
  }

  const singleDatepickerChange = (event) => {
    setSingleDate(prevValue => {
      let customeEvent = {
        target: {
          name: '',
          value: ''
        }
      }
      customeEvent.target.value = event;
      customeEvent.target.name = name
      onChangeHandler(customeEvent)
      return event;
    })
  }

  const timePickerChange = (event) => {
    setSelectedTime(prevValue => {
      let customeEvent = {
        target: {
          name: '',
          value: ''
        }
      }
      customeEvent.target.value = event;
      customeEvent.target.name = name
      onChangeHandler(customeEvent)
      return event;
    })
  }

  const getMaxDate = (maxDate) => {
    let newMaxDate = new Date();
    newMaxDate.setDate(newMaxDate.getDate() + maxDate);
    return newMaxDate;
  }

  const defaultInputValueFormat = showTimeSelect ? "dd-MM-yyyy HH:mm:ss" : "dd-MM-yyyy";
  const defaultDateFormat = showTimeSelect ? "dd-MM-yyyy HH:mm:ss" : "dd-MM-yyyy";
  return (<>
    <div className={` ${className} ${padding}`} >
      {label && <><label>
        <span className={textCss?.inputLabel}>{label} {required && <span className="text-scogoorange">*</span>} </span>
      </label>
      </>}
      <div className="relative">
        {!isSingleDatePicker && !timePicker && <DatePicker
          className={`${readOnly ? 'border-scogogray bg-scogof2' : ' border-scogoccc bg-white focus:ring-0'} border pl-12 rounded-6px  w-full h-14 text-font13`}
          selectsRange={true}
          name={name}
          isClearable
          onChange={(event) => {
            onDatePickerChange(event)
          }}
          minDate={minDate ? minDate : undefined}
          maxDate={!['', undefined, null].includes(maxDate) ? getMaxDate(maxDate) : undefined}
          showTimeSelect={showTimeSelect}
          dateFormat={dateFormat ? dateFormat : defaultDateFormat}
          inputValueFormat={inputValueFormat ? inputValueFormat : defaultInputValueFormat}
          placeholderText={placeholder}
          startDate={startDate}
          endDate={endDate}
          todayButton={todayButton ? 'Today' : todayButton}
          popperProps={{ strategy: 'fixed' }}
          readOnly={readOnly}
        />}
        {
          isSingleDatePicker && !timePicker && <DatePicker
            className={`${readOnly ? 'border-scogogray bg-scogof2' : ' border-scogoccc bg-white focus:ring-0'} border   pl-12 truncate rounded-6px w-full h-14 text-font12`}
            selected={value
              ? value
              : singleDate}
            name={name}
            isClearable
            onChange={(event) => singleDatepickerChange(event)}
            minDate={minDate ? minDate : undefined}
            maxDate={maxDate ? getMaxDate(maxDate) : undefined}
            showTimeSelect={showTimeSelect}
            dateFormat={dateFormat ? dateFormat : defaultDateFormat}
            inputValueFormat={inputValueFormat ? inputValueFormat : defaultInputValueFormat}
            placeholderText={placeholder}
            todayButton={todayButton ? 'Today' : todayButton}
            popperProps={{ strategy: 'fixed' }}
            readOnly={readOnly}
          />
        }
        {timePicker && <DatePicker
          className={`border border-scogoccc focus:ring-0 pl-12  rounded-6px bg-white w-full h-14 text-font12`}
          required={required}
          placeholderText={placeholder}
          selected={selectedTime}
          onChange={(event) => timePickerChange(event)}
          showTimeSelect showTimeSelectOnly
          timeIntervals={15}
          timeCaption='Time'
          dateFormat='hh:mm aa'
        />}

        <div className="absolute top-2.5 left-2">
          <img src="./img/event.svg" alt="" />
        </div>
      </div>
      {errors && _.get(errors, name) && <span className="text-scogoclosed text-font10 font-normal">{_.get(errors, name)}</span>}
    </div>
  </>)
}
