import GridTable from "../../../common/GridTable";
export default function TableModal({ data }) {


    const tableSchema = {
        table: [
            {
                head: {
                    render: (headName) => {
                        return headName;
                    },
                    headName: 'Name',
                    width: 1
                },
                body: {
                    render: (invalidData) => {
                        return <>
                            {invalidData.name} </>
                    },
                },
            },
            {
                head: {
                    render: (headName) => {
                        return headName
                    },
                    headName: 'Mobile',
                    width: 0.6
                },
                body: {
                    render: (invalidData) => {
                        return <>
                            {invalidData.mobile}
                        </>
                    }
                }
            }, {
                head: {
                    render: (headName) => {
                        return headName
                    },
                    headName: 'Email',
                    width: 1
                },
                body: {
                    render: (invalidData) => {
                        return <>
                            {invalidData.email}
                        </>
                    }
                }
            },
            {
                head: {
                    render: (headName) => {
                        return headName
                    },
                    headName: 'Company',
                    width: 1
                },
                body: {
                    render: (invalidData) => {

                        return <>
                            {invalidData.company_name}
                        </>
                    }
                }
            },
            {
                head: {
                    render: (headName) => {
                        return headName
                    },
                    headName: 'Reason',
                    width: 1.5
                },
                body: {
                    render: (invalidData) => {
                        return <div className='text-scogoclosed'>
                            {invalidData.reason}
                        </div>
                    }
                }
            }
        ],
    };
    return (<div className="px-5 py-5">
        <GridTable
            schema={tableSchema}
            rowData={data.invalidList}
            tableError={'No Assets Found'}
            tablePagination={false}
            columnsGap='gap-x-6'
        />
    </div>)
}