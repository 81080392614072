import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import { useEffect } from 'react';
import { Howl } from 'howler';

export const Ringing = ({ userCallStatus }) => {

    useEffect(() => {
        const callRingtone = new Howl({
            src: ['/audio/call-waiting.mp3'],
        });
        callRingtone.play();
        return () => {
            callRingtone.stop();
        }
    }, []);

    return (
        <div className={`w-[40rem] h-[22.7rem] transition-all ease-linear duration-300 border-2 border-white bg-gray-700 rounded-3xl overflow-hidden animate-pulse relative hover:cursor-pointer`}>
            <PhoneInTalkIcon sx={{ fontSize: 80, color: "#aaaaaa" }} className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2' />
            <p className='text-font15 text-white absolute bottom-5 left-1/2 -translate-x-1/2 drop-shadow-[0_3px_10px_rgb(0,0,0,0.2)]'>{`${userCallStatus?.length} ${userCallStatus?.length === 1 ? 'Person' : 'People'} Invited`}</p>
        </div>
    )
}
