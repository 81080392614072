import React from 'react';
import { useState } from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { respondFeasibility } from '../../../actions/tickets';
import { sendToastMessage } from '../../../actions/toast';
import ButtonScogoClosedOutlined from '../../../common/Buttons/ButtonScogoClosedOutlined';
import { actualValueForDropdown } from '../../../utils/common';
import { IconButton } from '../../Payments/Modals/EditPayout/IconButton';
import StatusLabel from '../../Payments/reusableComponents/StatusLabel';
import { useFormContext } from 'react-hook-form';
const feasibility_response = 'feasibility_response';

export const ActionColumn = ({
    isp,
    arrayName,
    setIsEditingRow,
    index,
    isUserEditing,
    isIspResponded,
    showeFeasibleAction,
    ticketId,
    isOnlyView,
}) => {
    const dispatch = useDispatch();
    const [buttonLoadingIndex, setButtonLoadingIndex] = useState();
    const { formButtonLoading } = useSelector((state) => state.loading);
    const { ticketDetails } = useSelector((state) => state.tickets);
    const assetType = ticketDetails?.assets?.[0]?.title;

    let statusLabel = <></>;
    let editButton = <></>;
    let actionButtons = <></>;
    let cancelButton = <></>;
    const { getValues } = useFormContext();

    const onRespond = useCallback(
        (response) => {
            const formValue = getValues();
            const isp = formValue?.[arrayName]?.[index];

            const detail = {
                link_type: isp?.linktype?.label || '',
                monthly_plan: isp.monthly_plan,
                otc: isp.otc,
                remark: isp.remark,
                router_cost: isp.router_cost,
                static_ip_charge: isp.static_ip_charge,
            };
            const payload = {
                ticketId,
                response,
                ispId: isp.customer_id,
                data: detail,
            };

            let errorMessage = '';
            let amountErrorMessage = '';
            if (response === 'YES') {
                errorMessage = validate(detail, assetType).errorMessage;
                amountErrorMessage = validate(detail, assetType).amountErrorMessage;
            }

            if (errorMessage || amountErrorMessage) {
                if (errorMessage.length > 0) {
                    dispatch(sendToastMessage({ status: 'danger', message: errorMessage }));
                }
                if (amountErrorMessage) {
                    dispatch(sendToastMessage({ status: 'danger', message: amountErrorMessage }));
                }
            } else {
                setButtonLoadingIndex(`${response}_${index}`);
                dispatch(
                    respondFeasibility({ data: payload, refresh: true, ticketId, ispId: isp.id, formName: feasibility_response, isGetSpList: true, closeModal: false })
                );
            }
        },
        [dispatch, ticketId, index, assetType, getValues, arrayName]
    );

    if (isIspResponded(isp.response) && !isUserEditing(isp.id)) {
        let content = 'Rejected';
        let color = 'scogoclosed';
        if (isp.response === 'YES') {
            content = 'Accepted';
            color = 'scogo2e';
        }
        statusLabel = <StatusLabel content={content} color={color} />;
    }

    if (isIspResponded(isp.response) && !isUserEditing(isp.id) && !isOnlyView) {
        editButton = (
            <IconButton
                loader={formButtonLoading[feasibility_response] && buttonLoadingIndex === `YES_${index}`}
                onClick={(event) => {
                    event.preventDefault();
                    setIsEditingRow((ids) => [...ids, isp.id]);
                }}
                onHoverTitle={'Edit'}
                iconName={'edit'}
                backgroundColor={'bg-scogobgsky'}
            />
        );
    }

    if (showeFeasibleAction({ isEditable: isp.isEditable, response: isp.response, ispId: isp.id }) && !isOnlyView) {
        actionButtons = (
            <div className='flex gap-2'>
                <IconButton
                    loader={formButtonLoading[feasibility_response] && buttonLoadingIndex === `YES_${index}`}
                    onClick={(event) => {
                        event.preventDefault();
                        onRespond('YES');
                    }}
                    onHoverTitle={'Yes'}
                    iconName={'done'}
                    backgroundColor={'bg-labelLightGreen text-scogo2e border border-scogo2e greenlinear-gradient'}
                />
                <IconButton
                    loader={formButtonLoading[feasibility_response] && buttonLoadingIndex === `NO_${index}`}
                    onClick={(event) => {
                        event.preventDefault();
                        onRespond('NO');
                    }}
                    onHoverTitle={'No'}
                    iconName={'cancel'}
                    backgroundColor={'bg-scogoLightRed text-scogoclosed border border-scogoclosed redlinear-gradient'}
                />
            </div>
        );
    }

    if (isUserEditing(isp.id) && isIspResponded(isp.response) && !isOnlyView) {
        cancelButton = (
            <ButtonScogoClosedOutlined
                onClick={(e) => {
                    e.preventDefault();
                    setIsEditingRow((oldIds) => {
                        let newIds = oldIds.filter((idx) => idx !== isp.id);
                        return newIds;
                    });
                }}
                textOrComponent={'Cancel'}
                loading={false}
                buttonClass='text-font14 h-8 rounded-lg '
                disabledClass='text-font14 h-8 rounded-lg '
                loaderClass={'w-full py-2'}
                disabled={false}
            />
        );
    }

    return (
        <div className='flex flex-col gap-2 justify-center'>
            <div className='flex items-center'>
                {statusLabel}
                {editButton}
            </div>
            <div>{actionButtons}</div>
            <div>{cancelButton}</div>
            {isp ?
                isp.response_type === 'AUTO' ?
                    <>
                        <div>Auto Closed via {isp.status === 'DELIVERED' ? 'Delivery' : 'Feasibility'}</div>
                    </>
                    :
                    <>
                        {['YES', 'NO'].includes(isp.response) && isp.first_name ?
                            <>
                                <div>Closed By: {isp.first_name} {isp.last_name}</div>
                            </>
                            :
                            <></>
                        }
                    </>
                :
                <></>
            }
        </div>
    );
};

const validate = (detail, assetType) => {
    let errorMessage = [];
    let amountErrorMessage = [];
    Object.entries(detail).forEach(([key, value]) => {
        if (!value && key === 'link_type') {
            errorMessage.push(`Link Type`);
        }

        let isInValidAmount = isNaN(parseFloat(value)) || parseFloat(value) < 0;

        if (key === 'monthly_plan') {
            if (isInValidAmount) {
                errorMessage.push(`Monthly Plan`);
            }
            if (value && assetType === 'ILL' && parseInt(value) < 5000) {
                amountErrorMessage.push('Monthly Plan should be more than 5000');
            } else if (value && assetType === 'BB' && (parseInt(value) < 5000 || parseInt(value) > 5000)) {
                amountErrorMessage.push('Monthly Plan should be more than 100 but less than 5000');
            }
        }

        if (key === 'otc') {
            if (isInValidAmount) {
                errorMessage.push(`One Time Charge`);
            }
            if (value) {
                if (parseInt(value) > 15000 && assetType === 'BB') amountErrorMessage.push('OTC should be less than 15000');
            }
        }

        if (key === 'router_cost') {
            if (isInValidAmount) {
                errorMessage.push(`Router Cost`);
            }
            if (value) {
                if (parseInt(value) > 5000) amountErrorMessage.push('Router Cost should be less than 5000');
            }
        }

        if (key === 'static_ip_charge') {
            if (isInValidAmount) {
                errorMessage.push(`Static IP Charge`);
            }
            if (value) {
                if (parseInt(value) > 5000) amountErrorMessage.push('Static IP Charge should be less than 5000');
            }
        }
        if (!value && key === 'remark') {
            errorMessage.push(`Remark`);
        }
    });

    return {
        errorMessage: errorMessage.length > 0 ? `${errorMessage.join(', ')} is required` : '',
        amountErrorMessage: amountErrorMessage.length > 0 ? `${amountErrorMessage.join(', ')}` : '',
    };
};
