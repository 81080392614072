import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import { ClickAwayListener } from '@mui/base';
import { CheckBoxFilter } from './CheckBoxFilter';
import IconToolTip from '../IconToolTip';
import { SkeltonCardTwo } from '../SkeltonCardTwo';
import { deepEqual, getFilterPayload, isSomeFilterSelected } from '../../utils/common';
import { useDispatch } from 'react-redux';
import DateRangePicker from './DateRange';
import SliderFIlter from './SliderFilter';
import { useCallback } from 'react';
import ButtonScogoPrimary from '../Buttons/ButtonScogoPrimary';
import ButtonScogoClosedOutlined from '../Buttons/ButtonScogoClosedOutlined';

export default function APIFilterPopup(props) {
    const {
        submit = () => { },
        filterList,
        setFilterList,
        submitButtonName = 'Apply',
        keysToFilter,
        placement,
        isLoading,
        setClearFilterButton,
        filtersData,
        filterSchema,
        setPayloadAndFilterStatus,
        filterQueryObject,
        filtersActiveStatus,
        triggerRender,
        disable = false,
        disableText = ''
    } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [showSelected, setShowSelected] = useState({});
    const checkBoxFilterRef = useRef(new Array());
    const [isApplyDisable, setIsApplyDisabled] = useState(false);
    const [showSelectedFilters, setShowSelectedFilters] = useState(false);
    const [localFilters, setLocalFilters] = useState();  // Copy of filterList, cannot change filterList directly because using filterList as default list, used in filterSchema for filtering list;

    const dispatch = useDispatch();
    const updateFilterList = useCallback((value) => {
        setLocalFilters(value);
        setFilterList(value);
    }, [setFilterList]);

    const transformFilterList = ({ filterLookup, filterSchema, filterQueryObject } = {}) => {
        let updatedFilters = {};

        Object.values(filterSchema).forEach(({ filterName, apiValueKey, displayValueKey, typeOfFilter, queryParamKey, getSelectedFilterValues, isCurrentFilterSelected }) => {
            let transformedFilter;
            let currentSelectedQueryFilters;
            if (typeOfFilter === 'checkbox') {
                if (typeof queryParamKey === 'string') {
                    currentSelectedQueryFilters = filterQueryObject?.[queryParamKey];
                }

                transformedFilter = filterLookup?.[filterName]?.map((item, index) => {
                    let apiValue = typeof apiValueKey === 'function' ? apiValueKey(item) : item[apiValueKey]; // send this when user clicks on apply filter
                    let displayValue = typeof displayValueKey === 'function' ? displayValueKey(item) : item[displayValueKey]; // display this to user in filter component
                    let isSelected = true;

                    // Need To Recheck this if condition
                    if (typeof apiValueKey === 'function') {
                        if (typeof queryParamKey === 'object') {
                            let currentQuery = {};
                            queryParamKey.keyList.forEach((key) => {
                                if (filterQueryObject?.[key]) {
                                    Object.assign(currentQuery, { [key]: filterQueryObject[key] });
                                }
                            });

                            if (typeof isCurrentFilterSelected === 'function') {
                                isSelected = isCurrentFilterSelected(apiValue, currentQuery);
                            }
                        }
                    }
                    if (Array.isArray(currentSelectedQueryFilters) && currentSelectedQueryFilters.length > 0) {
                        isSelected = currentSelectedQueryFilters.includes(apiValue);
                    }
                    return {
                        apiValue,
                        displayValue,
                        isSelected,
                        index,
                        ...item
                    };
                });
            } else if (typeOfFilter === 'daterange' || typeOfFilter === 'slider') {
                transformedFilter = {};
                if (filterLookup?.[filterName] && typeOfFilter === 'slider') transformedFilter = filterLookup?.[filterName] || {};
                if (typeof queryParamKey === 'object') {
                    let currentQuery = {};
                    queryParamKey.keyList.forEach((key) => {
                        if (filterQueryObject?.[key]) {
                            Object.assign(currentQuery, { [key]: filterQueryObject[key] });
                        }
                    });
                    if (typeof getSelectedFilterValues === 'function') {
                        if (Object.keys(currentQuery).length > 0) transformedFilter = { ...transformedFilter, ...getSelectedFilterValues(currentQuery) || {} }
                    }
                }
            }
            updatedFilters = Object.assign(updatedFilters, {
                [filterName]: transformedFilter,
            });
        });
        return updatedFilters;
    };

    const updateFilterListOnMount = useCallback(() => {
        if (filtersData) {
            updateFilterList(
                transformFilterList({
                    filterLookup: filtersData,
                    filterSchema,
                    filterQueryObject,
                })
            );
        }
    }, [filterQueryObject, filterSchema, filtersData, updateFilterList]);

    useEffect(() => {
        updateFilterListOnMount();
    }, [filtersData, filterQueryObject, updateFilterListOnMount]);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const boxStyle = {
        border: 0,
        p: 1,
        bgcolor: 'rgb(248,248,248)',
        width: props?.width || '30rem',
        maxHeight: ' 50rem'
    };

    const onClickAwayHandler = ({ isClickedOnClose = false }) => {
        if (!anchorEl) return;
        if (anchorEl) setAnchorEl(false);
        setShowSelected({});
    };

    const handleSubmit = () => {
        let previousFilters = { ...filterList };
        let updatedFilters = { ...previousFilters };

        if (Array.isArray(checkBoxFilterRef.current)) {
            checkBoxFilterRef.current.forEach((elem) => {
                let localFilter = elem.getLocalFilter();
                updatedFilters = {
                    ...updatedFilters,
                    ...localFilter,
                };
            });
        }

        const isFilterChanged = !deepEqual(filterList, updatedFilters);
        const isAnyFilterActive = isSomeFilterSelected(updatedFilters);

        if (isFilterChanged) {
            updateFilterList(updatedFilters);
            const payload = getFilterPayload({ filterSchema, filterList: updatedFilters, previousFilters });
            setPayloadAndFilterStatus && dispatch(setPayloadAndFilterStatus({ payload, isFilterActive: isAnyFilterActive, page: 1 }));
            submit({ payload, page: 1, isFilterApplied: isAnyFilterActive, filterList: updatedFilters });
        }
        setShowSelected({});
        onClickAwayHandler({ isClickedOnClose: true });
    };

    const handleShowSelected = (key) => {
        setShowSelected({ ...showSelected, [key]: !showSelected[key] });
    };

    const getFilterStatusColor = () => {
        let isFilterOn = false;
        keysToFilter?.forEach((filter) => {
            const { filterName, typeOfFilter } = filter;
            if (filtersActiveStatus) {
                if (filtersActiveStatus[filterName]) {
                    isFilterOn = filtersActiveStatus[filterName];
                }
            } else {
                let appliedFilters = filterList?.[filterName]?.find((filter) => !filter?.isSelected);
                if (appliedFilters && typeOfFilter === 'checkbox') {
                    isFilterOn = appliedFilters ? true : false;
                }
            }
        });
        if (isLoading) return '';
        return isFilterOn ? 'orange' : '';
    };

    const handleCheckBoxFIlter = ({ title, filterName, index }) => {

        if (isLoading) return <SkeltonCardTwo arr={[1, 2, 3]} height={20} />;
        return (
            <>
                <CheckBoxFilter
                    ref={(elem) => {
                        return (checkBoxFilterRef.current[index] = elem);
                    }}
                    isSingleDropDown={keysToFilter?.length === 1 ? true : false}
                    title={title}
                    filterName={filterName}
                    showSelected={showSelected}
                    handleShowSelected={handleShowSelected}
                    setShowSelected={setShowSelected}
                    filterList={filterList}
                    setClearFilterButton={setClearFilterButton}
                    setIsApplyDisabled={setIsApplyDisabled}
                    showSelectedFilters={showSelectedFilters}
                    localFilters={localFilters}
                    setLocalFilters={setLocalFilters}
                    keysToFilter={keysToFilter}
                />

            </>
        );
    };

    const filterLookup = ({ title, filterName, typeOfFilter, index, maxDateFromToday, toConvert }) => {
        const lookup = {
            checkbox: handleCheckBoxFIlter({
                title,
                filterName,
                index,
            }),
            daterange: (
                <DateRangePicker
                    maxDateFromToday={maxDateFromToday}
                    filterList={filterList}
                    filterName={filterName}
                    title={title}
                    ref={(elem) => {
                        return (checkBoxFilterRef.current[index] = elem);
                    }}
                    setIsApplyDisabled={setIsApplyDisabled}
                />
            ),
            slider: <SliderFIlter
                ref={(elem) => {
                    return (checkBoxFilterRef.current[index] = elem);
                }}
                title={title}
                filterList={filterList}
                filterName={filterName}
                toConvert={toConvert}
            />
        };
        return lookup[typeOfFilter];
    };

    const handleTypeOfFilterUI = () => {
        return keysToFilter?.map(({ title, filterName, typeOfFilter, maxDateFromToday, toConvert }, index) => {
            return (
                <div key={index}>
                    {filterLookup({
                        title,
                        filterName,
                        typeOfFilter,
                        index,
                        maxDateFromToday,
                        toConvert
                    })}
                </div>
            );
        });
    };

    return (
        <ClickAwayListener onClickAway={onClickAwayHandler}>
            <div>
                <IconToolTip title={`${disableText && disable ? disableText : 'Filter'}`}>
                    {triggerRender ? triggerRender({ onClick: handleClick }) : <span
                        className={`material-icons text-font10 text-scogoprimary ml-1 ${disable ? 'opacity-60 cursor-not-allowed' : 'cursor-pointer hover:text-scogoorange'}`}
                        onClick={disable ? () => { } : handleClick}
                        style={{ color: getFilterStatusColor() }}
                    >
                        filter_alt
                    </span>}
                </IconToolTip>
                <Popper id={id} open={open} anchorEl={anchorEl} className='z-50 rounded-xl' style={{ background: 'rgb(248,248,248)' }} placement={placement}>
                    <Box sx={boxStyle} className='rounded-2xl shadow-2xl pt-2'>
                        <div style={{ maxHeight: '40rem', overflowY: 'scroll' }}>
                            {handleTypeOfFilterUI()}
                        </div>
                        <div className={'right-2 mt-1 bottom-2 py-2 flex gap-2'}>
                            <div className='flex justify-start'>
                                <ViewSelectedInput value={showSelectedFilters} setValue={setShowSelectedFilters} />
                            </div>
                            <div className='mr-2 flex items-center justify-end gap-2 ml-auto'>
                                <ButtonScogoClosedOutlined textOrComponent={'Cancel'} onClick={onClickAwayHandler} />
                                <ButtonScogoPrimary
                                    textOrComponent={submitButtonName}
                                    disabled={isApplyDisable}
                                    onClick={(e) => {
                                        handleSubmit(e);
                                    }}
                                />
                            </div>
                        </div>
                    </Box>
                </Popper>
            </div>
        </ClickAwayListener>
    );
}

const ViewSelectedInput = ({ value, setValue }) => {
    return <div className={`flex items-center w-max gap-2 mt-2 ml-3 mr-auto `}>
        <label htmlFor={'new_checkbox'} className='flex items-center cursor-pointer relative'>
            <input type='checkbox' id={'new_checkbox'} className='sr-only' onClick={() => setValue((prevState) => !prevState)} checked={value} />
            <div className='toggle-bg bg-gray-400 border-2 border-gray-200 h-8 w-12 rounded-full'></div>
        </label>
        <p className={`text-scogo21 text-font11`}>View Selected only</p>
    </div>
}