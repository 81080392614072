import axios from './axios';

class API {

    constructor({ url }) {
        this.url = url;
        this.endpoints = {};
    }

    /**
     * Create and store entity's endpoints
     * @param {Object} entity A entity
     */
    createEntities(entities) {
        /**
         * api.createBasicCRUDEndpoints(entity)
         * api.endpoints.fooBar.getAll(...)
         */

        Object.keys(entities).map(endpoint => {
            return this.endpoints[endpoint] = this.createBasicCRUDEndpoints(entities[endpoint]);
        });
    }

    /**
     * Create the basic endpoints handlers for CRUD operations
     * @param {Object} entity A entity
     */
    createBasicCRUDEndpoints(entities) {
        let result = {};
        Object.keys(entities).map(entity => {
            let data = entities[entity];
            if (data.method) {
                switch (data.method) {
                    case 'get':
                        return result[entity] = (headers, urlParams = {}, queryParams = {}) => {
                            let resourceURL = `${this.url}/${data.url}`
                            Object.keys(urlParams).map(param => {
                                return resourceURL = resourceURL.replace('{' + param + '}', urlParams[param]);
                            });
                            let config = {};
                            config.params = queryParams;
                            config.headers = headers;
                            return axios.get(`${resourceURL}`, config);
                        }
                    case 'post':
                        return result[entity] = (headers, urlParams = {}, queryParams = {}, toCreate) => {
                            let resourceURL = `${this.url}/${data.url}`
                            Object.keys(urlParams).map(param => {
                                return resourceURL = resourceURL.replace('{' + param + '}', urlParams[param]);
                            });
                            let config = {};
                            config.params = queryParams;
                            config.headers = headers;
                            return axios.post(resourceURL, toCreate, config);
                        }
                    case 'put':
                        return result[entity] = (headers, urlParams = {}, queryParams = {}, toUpdate) => {
                            let resourceURL = `${this.url}/${data.url}`
                            Object.keys(urlParams).map(param => {
                                return resourceURL = resourceURL.replace('{' + param + '}', urlParams[param]);
                            });
                            let config = {};
                            config.params = queryParams;
                            config.headers = headers;
                            return axios.put(`${resourceURL}`, toUpdate, config);
                        }
                    case 'delete':
                        return result[entity] = (headers, urlParams = {}, queryParams = {}, body = {}) => {
                            let resourceURL = `${this.url}/${data.url}`
                            Object.keys(urlParams).map(param => {
                                return resourceURL = resourceURL.replace('{' + param + '}', urlParams[param]);
                            });
                            let config = {};
                            config.params = queryParams;
                            config.headers = headers;
                            config.data = body;
                            return axios.delete(`${resourceURL}`, config);
                        }
                    default:
                        break;
                }
            } else {
                Object.keys(entities).map(entity => {
                    result[entity] = this.createBasicCRUDEndpoints(entities[entity])
                })
            }

        });
        return result;
    }
}

export default API
