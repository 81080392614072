import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import { exportCsvPayments } from '../../../actions/payments';
import { getCustomerIdOfLoginUser } from '../../../utils/utils';
import { actualValueForDropdown } from '../../../utils/common';
import { getCustomersList } from '../../../actions/customer';
import { GetPaymentsListLogic } from '../PaymentController';
import Form from '../../../common/Form';
import Select from '../../../common/Form/Select';
import MultipleEmails from '../../../common/Form/MultipleEmail';
import { useForm } from 'react-hook-form';

const ExportCsvPayments = ({ module, tab }) => {
    const { loggedUser } = useSelector((state) => state.auth);
    const formName = 'exportCsvPayments';
    const dispatch = useDispatch();
    const { customers } = useSelector((state) => state.customer);
    const { isCustomerListLoading, formButtonLoading } = useSelector((state) => state.loading);
    const isToShowCustomerInput = GetPaymentsListLogic('isToShowCustomer') && module === 'PO';

    const defaultValues = {
        emails: loggedUser?.email ? [loggedUser?.email] : [],
    };

    const onSubmit = (formValues) => {
        let customerId = getCustomerIdOfLoginUser(loggedUser);
        if (isToShowCustomerInput) customerId = actualValueForDropdown(formValues.fk_customer_id);
        dispatch(exportCsvPayments({ module, tab, emails: formValues.emails, formName, customerId }));
    };

    const closeModal = () => {
        dispatch(closeModalAction());
    };

    useEffect(() => {
        if (isToShowCustomerInput) dispatch(getCustomersList());
    }, [dispatch, isToShowCustomerInput]);

    const methods = useForm({ defaultValues, mode: 'all' });

    return (
        <>
            <Form
                className='w-full'
                onSubmit={onSubmit}
                onCancel={closeModal}
                defaultValues={defaultValues}
                submitButtonText='Submit'
                showSubmit
                submitContainerClassName='flex gap-2 justify-end py-4 px-4'
                isLoading={formButtonLoading[formName]}
                methods={methods}
            >
                <div className='py-2 px-3'>
                    {isToShowCustomerInput && (
                        <Select label='Customer' name='fk_customer_id' className={'w-full'} options={customers} isLoading={isCustomerListLoading} />
                    )}
                    <MultipleEmails name='emails' label='Emails' required />
                </div>
            </Form>
        </>
    );
};

export default ExportCsvPayments;
