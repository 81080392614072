import React from 'react'
import OverflowTip from './OverflowTip';

const Chip = ({ text, showIcon, iconValue = 'close', iconOnClick = () => { } }) => {
    return (
        <span className='px-2 bg-scogoorange text-white flex gap-2 items-center rounded-xl w-fit max-w-[8rem] break-all'>
            <OverflowTip textClass={`text-inherit text-font10 text-black w-full font-normal`} someLongText={text} />
            {showIcon && (
                <>
                    <span className='text-white'>|</span>
                    <span
                        className='material-icons text-font12 text-white pr-1 cursor-pointer '
                        onClick={() => {
                            iconOnClick();
                        }}
                    >
                        {iconValue}
                    </span>
                </>
            )}
        </span>
    )
}

export default Chip