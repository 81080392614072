/* eslint-disable max-len */

export const CONNECT_TO_WEBSOCKET_REQUEST = 'CONNECT_TO_WEBSOCKET_REQUEST';
export const CONNECT_TO_WEBSOCKET_SUCCESS = 'CONNECT_TO_WEBSOCKET_SUCCESS';
export const CONNECT_TO_WEBSOCKET_FAILURE = 'CONNECT_TO_WEBSOCKET_FAILURE';
export const DISCONNECT_FROM_WEBSOCKET_REQUEST = 'DISCONNECT_FROM_WEBSOCKET_REQUEST';
export const DISCONNECT_FROM_WEBSOCKET_SUCCESS = 'DISCONNECT_FROM_WEBSOCKET_SUCCESS';

export const HANDLE_WEBSOCKET_MESSAGE = 'HANDLE_WEBSOCKET_MESSAGE';

export const SEND_WEBSOCKET_DATA = 'SEND_WEBSOCKET_DATA';
export const CHECK_SEND_MESSAGE_ERROR = 'CHECK_SEND_MESSAGE_ERROR';
