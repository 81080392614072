import React, { useState, useRef, useEffect } from 'react';
import jsQR from 'jsqr';
import MaterialIconToolTip from '../../common/MaterialIconToolTip';
import { ClickAwayListener } from '@mui/base';
import { isMobileView } from "../auth/components/NewScogoButton";
import { useSelector } from 'react-redux';

const QRCodeScanner = ({ onScan, label }) => {
    const { isScanAssetLoading } = useSelector((state) => state.loading);
    const [scanning, setScanning] = useState(false);
    const [scannedData, setScannedData] = useState(null);
    const videoRef = useRef(null);

    useEffect(() => {
        if (scanning) {
            startScan();
        } else {
            stopScan();
        }
    }, [scanning]);

    const startScan = () => {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } })
                .then((stream) => {
                    videoRef.current.srcObject = stream;
                    videoRef.current.setAttribute('playsinline', true); // required to tell iOS safari we don't want fullscreen
                    videoRef.current.play();
                    requestAnimationFrame(tick);
                });
        }
    };

    const stopScan = () => {
        const stream = videoRef.current?.srcObject;
        const tracks = stream?.getTracks();

        if (tracks) {
            tracks.forEach(track => track.stop());
        }
    };

    const tick = () => {
        if (videoRef.current.readyState === videoRef.current.HAVE_ENOUGH_DATA) {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            canvas.width = videoRef.current.videoWidth;
            canvas.height = videoRef.current.videoHeight;

            context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

            const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
            const code = jsQR(imageData.data, canvas.width, canvas.height);

            if (code) {
                setScannedData(code.data);
                onScan(code.data);
                setScanning(false);
                stopScan();
            } else {
                requestAnimationFrame(tick);
            }
        } else {
            requestAnimationFrame(tick);
        }
    };

    return (
        <div className={`${isMobileView ? 'pr-4' : ''}`}>
            {isScanAssetLoading ? <span className={`animate-spin material-icons cursor-pointer text-2xl`}>refresh</span> : <button onClick={() => setScanning(!scanning)} style={{ fontSize: '24px', cursor: 'pointer' }}>
                <MaterialIconToolTip
                    onClick={() => { }}
                    title='Scan Asset'
                    materialIconName='qr_code_scanner'
                    materialIconClass={`material-icons text-font16 mt-3 hover:text-scogoorange text-scogoToastDangerIcon cursor-pointer`}
                />
            </button>}
            {scanning && (
                <div className='fixed top-0 left-0 right-0 bottom-0 z-50' style={{ position: "fixed", width: '100%', height: '100%' }}>

                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%'
                    }}>
                        <ClickAwayListener onClickAway={() => setScanning(!scanning)}>
                            <video ref={videoRef} style={{ width: isMobileView ? '80%' : '60%', height: '60%' }} />
                        </ClickAwayListener>
                    </div>
                </div>

            )}
        </div>
    );
};

export default QRCodeScanner;
