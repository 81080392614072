import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getFileType, getLookupWitHDefaultValue, modulo } from '../../../../utils/common';
import ButtonScogoClosedOutlined from '../../../../common/Buttons/ButtonScogoClosedOutlined';
import Bedges from '../../../../common/Badges';
import ButtonScogoPrimary from '../../../../common/Buttons/ButtonScogoPrimary';
import { closeModalAction } from '../../../../actions/modal';
import { useDispatch } from 'react-redux';
import { sendToastMessage } from '../../../../actions/toast';

export const ViewFilesModal = ({ fileForView = [], onSubmit, showButtons = false, formName }) => {
    const { formButtonLoading } = useSelector((state) => state.loading);
    const [openedIndex, setOpenedIndex] = useState(0);
    const [files, setFiles] = useState([...fileForView]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const dispatch = useDispatch();

    const handleArrowClick = (arrowDirection) => {
        const increment = arrowDirection === 'right' ? 1 : -1;
        const attachmentsArray = files.length;
        const nextIndex = modulo(openedIndex + increment, attachmentsArray);
        setOpenedIndex(nextIndex);
    }

    const getArrow = (direction) => {
        const classLookup = { left: 'left-2', right: 'right-2' };
        const angleLookup = { left: '<', right: '>' };

        const styleLookup = { left: { marginLeft: '1%' }, right: { marginRight: '1%' } };
        return (
            <div
                className={`${classLookup[direction]} absolute cursor-pointer backdrop-filter backdrop-blur-lg rounded-full leading-6 border border-scogoblack text-center bg-gray-300 shadow-primaryBtnShadow h-12 w-12 flex justify-center items-center`}
                onClick={() => handleArrowClick(direction)}
                style={styleLookup[direction]}
            >
                <span className='text-black text-2xl text-font25 font-bold'>{angleLookup[direction]}</span>
            </div>
        );
    };

    const image = files[openedIndex];

    const onImageUpload = (event) => {
        let selectedFiles = Array.from(event.target.files);
        selectedFiles = selectedFiles.filter((file) => getFileType(file.type) === 'image');
        if (selectedFiles.length !== Array.from(event.target.files).length) {
            dispatch(sendToastMessage({ message: 'Please Select Image Only', status: 'warning' }))
        }
        setUploadedFiles(Array.from(selectedFiles));
        const transformedFiles = Array.from(selectedFiles).map((file) => {
            const imageUrl = URL?.createObjectURL(file);
            return { file_path: imageUrl, file_name: file.name, mimeType: file.type };

        })
        let updatedFiles = [...files, ...transformedFiles]
        setFiles(updatedFiles);
        setOpenedIndex(updatedFiles.length - 1);
    };

    return <div className='overflow-hidden flex justify-center w-full relative'>
        <div className='h-full w-full'>
            <div className={`${showButtons ? 'h-8/12' : 'h-full'} w-full relative flex items-center m-auto`} style={{ height: '50vh' }}>
                <Bedges className='absolute bottom-4 right-2 opacity-70 text-font12 font-normal text-scogoddd bg-scogodarkgray h-5 leading-5 rounded-5px px-p-6'>
                    {openedIndex + 1}/{files.length}
                </Bedges>
                {files?.length > 1 && getArrow('left')}
                {files?.length > 1 && getArrow('right')}
                <GetComponentByType file={image} />
            </div>
            {showButtons && <div className='h-4/12 flex justify-end items-center w-full py-4 px-4 gap-2'>
                <div className='cursor-pointer relative'>
                    <input accept='image/jpeg,image/png' disabled={false} name='key' type='file' className='absolute cursor-pointer opacity-0 z-20 h-full w-full' onChange={onImageUpload} value='' multiple={true} />
                    <ButtonScogoPrimary textOrComponent={'Add More'} buttonClass='w-32' />
                </div>
                <ButtonScogoPrimary
                    disabled={false}
                    textOrComponent={'Submit'}
                    loading={formButtonLoading[formName]}
                    onClick={() => onSubmit(uploadedFiles)}
                />
                <ButtonScogoClosedOutlined textOrComponent={'Cancel'} type='button' onClick={() => dispatch(closeModalAction())} />
            </div>}
        </div>
    </div >
}

const GetComponentByType = ({ file }) => {
    const type = getFileType(file.mimeType);
    const className = 'object-contain h-full w-full';
    const url = file.file_path;
    const lookup = getLookupWitHDefaultValue({
        lookup: {
            image: <img src={url} className={className} alt='' />,
            pdf: <object data={url} aria-label='pdf' className={`${className} w-3/4`} />,
        },
        defaultValue: <img src={process.env.PUBLIC_URL + '/img/unknownFileType.png'} className={`${className} rounded-lg `} alt='' />,
    });
    return lookup[type];
};
