
import { useState, forwardRef, useImperativeHandle } from 'react';
import Popover from '@mui/material/Popover';

const BasicPopover = forwardRef(({ trigger, children, onClose = () => { }, containerRef }, ref) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(containerRef.current || event.currentTarget);
    };

    const handleClose = () => {
        onClose();
        setAnchorEl(null);
    };

    useImperativeHandle(ref, () => ({
        close() {
            handleClose()
        }
    }));


    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <div onClick={handleClick}>
                {trigger}
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {children}
            </Popover>
        </>
    );
})

export default BasicPopover;