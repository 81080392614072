import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBOQList } from '../../../actions/tickets';
import { closeModalAction } from '../../../actions/modal';
import { updateInventoryAsset } from '../../../actions/inventory';
import moment from 'moment';
import { actualValueForDropdown, getSelectedItemFromDropdownList, isLooksLikeId } from '../../../utils/common';
import { validateStringValue } from '../../../utils/common';
import { sendToastMessage } from '../../../actions/toast';
import Form from '../../../common/Form';
import Input from '../../../common/Form/Input';
import Select from '../../../common/Form/Select';
import DatePicker from '../../../common/Form/DatePicker';
import { useForm, useFormContext } from 'react-hook-form';
import Checkbox from '../../../common/Form/Checkbox';
import { isCluster, isPsp, isScm } from '../../../utils/role';
import { render } from '@testing-library/react';

const formName = 'editInventoryAsset';

const warrantyType = [
    { key: 1, value: 'Manufacturing', label: 'Manufacturing' },
    { key: 2, value: 'Installation', label: 'Installation' },
    { key: 3, value: 'Billing', label: 'Billing' },
];
const maintenanceSchedule = [
    { key: 1, value: 'Monthly', label: 'Monthly' },
    { key: 2, value: 'Quarterly', label: 'Quarterly' },
    { key: 3, value: 'Half Yearly', label: 'Half Yearly' },
    { key: 4, value: 'Yearly', label: 'Yearly' },
];

const EditInventoryAsset = ({ modalData, customerId, siteId, renderButtons }) => {
    const dispatch = useDispatch();
    const { formButtonLoading } = useSelector((state) => state.loading);
    const { boqList } = useSelector((state) => state.tickets);
    const { loggedUser } = useSelector(state => state.auth);

    const role = loggedUser.role.id;
    const type = loggedUser.type;

    if (!(isScm(role, type) || isCluster(role, type) || isPsp(role, type))) {
        renderButtons = null;
    }

    const closeModal = () => {
        dispatch(closeModalAction());
    };

    const hasWarrantyDetails = (asset) => {
        if (validateStringValue(asset?.warranty_type) || moment(asset?.warranty_start_date).isValid() || moment(asset?.warranty_end_date).isValid()) return true;
        return false;
    };

    const defaultValues = {
        asset_type_id: getSelectedItemFromDropdownList(modalData.asset_type_id, boqList),
        asset_details: modalData.asset_details,
        make: modalData.assetMake,
        model: modalData.assetModel,
        partcode: modalData.partcode,
        serial_number: modalData.serialNo,
        type: '',
        externel_order_id: modalData.externel_order_id,
        service_support: '',
        hasWarranty: hasWarrantyDetails(modalData),
        warranty_type: getSelectedItemFromDropdownList(modalData.warranty_type, warrantyType),
        warranty_duration: '',
        warranty_start_date: modalData?.warranty_start_date && moment(modalData?.warranty_start_date).isValid() ? new Date(modalData?.warranty_start_date) : null,
        warranty_end_date: modalData?.warranty_end_date && moment(modalData?.warranty_end_date).isValid() ? new Date(modalData?.warranty_end_date) : null,
        preventive_maintenance_schedule: getSelectedItemFromDropdownList(modalData.preventive_maintenance_schedule, maintenanceSchedule),
        hasOem: false,
        oem_id: '',
        oem_warranty_type: '',
        oem_start_date: '',
        oem_end_date: '',
        po_amount: modalData.po_amount,
        vendor_supplier_name: modalData.vendor_supplier_name,
        bill_date: modalData?.bill_date && moment(modalData?.bill_date).isValid() ? new Date(modalData?.bill_date) : null,
        capex_date: modalData?.capex_date && moment(modalData?.capex_date).isValid() ? new Date(modalData?.capex_date) : null,
    };

    useEffect(() => {
        dispatch(getBOQList());
    }, [dispatch]);

    const submit = (formValues) => {
        const assetId = modalData.id;

        let data = {
            asset_details: formValues.asset_details,
            asset_type_id: actualValueForDropdown(formValues.asset_type_id),
            capex_date: formValues.capex_date ? moment(formValues.capex_date).format('YYYY-MM-DD') : '',
            externel_order_id: formValues.externel_order_id,
            hasWarranty: formValues.hasWarranty,
            make: formValues.make,
            model: formValues.model,
            oem_end_date: formValues.oem_end_date ? moment(formValues.oem_end_date).format('YYYY-MM-DD') : '',
            oem_id: formValues.oem_id,
            oem_start_date: formValues.oem_start_date ? moment(formValues.oem_start_date).format('YYYY-MM-DD') : '',
            oem_warranty_type: formValues.oem_warranty_type,
            partcode: formValues.partcode,
            po_amount: formValues.po_amount,
            preventive_maintenance_schedule: actualValueForDropdown(formValues.preventive_maintenance_schedule),
            serial_number: formValues.serial_number,
            vendor_supplier_name: formValues.vendor_supplier_name,
            warranty_duration: formValues.warranty_duration,
            warranty_end_date: formValues.warranty_end_date ? moment(formValues.warranty_end_date).format('YYYY-MM-DD') : '',
            warranty_start_date: formValues.warranty_start_date ? moment(formValues.warranty_start_date).format('YYYY-MM-DD') : '',
            warranty_type: actualValueForDropdown(formValues.warranty_type),
            bill_date: formValues.bill_date ? moment(formValues.bill_date).format('YYYY-MM-DD') : '',
        };

        const isValidAssetId = isLooksLikeId(assetId);

        if (isValidAssetId) {
            dispatch(updateInventoryAsset({ data, assetId, siteId, customerId, formName, enduserId: modalData.enduserId }));
        } else dispatch(sendToastMessage({ status: 'danger', message: 'Invalid Asset ID' }));
    };

    const methods = useForm({ defaultValues, mode: 'all' });

    return (
        <>
            <Form
                className='w-full'
                onSubmit={submit}
                onCancel={closeModal}
                submitButtonText={'Update'}
                showSubmit
                submitContainerClassName='flex gap-2 justify-end py-4 px-4'
                isLoading={formButtonLoading[formName]}
                methods={methods}
                renderButtons={renderButtons}
            >
                <EditAssetInputs />
            </Form>
        </>
    );
};

export default EditInventoryAsset;

const EditAssetInputs = () => {
    const { boqList } = useSelector((state) => state.tickets);
    const { watch } = useFormContext();
    const hasWarranty = watch('hasWarranty');

    return (
        <div className='px-3'>
            <div className='grid grid-cols-4 rows-fr grid-flow-row-dense'>
                <Select label='Asset Type' required name='asset_type_id' options={boqList} />
                <Input label='Asset Details' name='asset_details' />
                <Input label='Make' name='make' type={'text'} />
                <Input label='Model' name='model' type={'text'} />
            </div>
            <div className='grid grid-cols-3 rows-fr grid-flow-row-dense'>
                <Input label='Partcode' name='partcode' type={'text'} />
                <Input label='Serial Number' name='serial_number' type={'text'} />
                <Input label='External Id' name='externel_order_id' type={'text'} />
            </div>
            <div className='grid grid-cols-3 rows-fr grid-flow-row-dense'>
                <DatePicker label='Capex Date' name='capex_date' />
                <Input label='PO Amount' name='po_amount' type={'number'} step={'any'} />
                <Input label='Vendor Supplier Name' type={'text'} name='vendor_supplier_name' />
            </div>
            <div className='grid grid-cols-2 rows-fr grid-flow-row-dense'>
                <DatePicker label='Bill Date' name='bill_date' />
                <Select label='Preventive Maintenance Schedule' name='preventive_maintenance_schedule' options={maintenanceSchedule} />
            </div>
            <div className='px-3'>
                <Checkbox label='Do you have Warranty Details' type={'checkbox'} name='hasWarranty' />
            </div>
            {hasWarranty && (
                <>
                    <div className='grid grid-cols-3 rows-fr grid-flow-row-dense'>
                        <Select label='Warranty Type' name='warranty_type' required options={warrantyType} shouldUnregister />
                        <DatePicker label='Warranty Start Date' name='warranty_start_date' />
                        <DatePicker label='Warranty End Date' name='warranty_end_date' shouldUnregister />
                    </div>
                </>
            )}
        </div>
    );
};
