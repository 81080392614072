import { fe } from '../types';

export const getFieldEngineersList = ({ servicePartnerId }) => ({
    type: fe.FIELD_ENGINEERS_LIST_REQUEST,
    payload: { servicePartnerId },
});

export const deleteFEUser = ({ data, formName, userName }) => ({
    type: fe.DELETE_FE_REQUEST,
    payload: { data, formName, userName },
});

export const getFeProfileDetail = ({ feId }) => ({
    type: fe.FE_PROFILE_DETAIL_REQUEST,
    payload: { feId },
});

export const updateFEDetails = ({ formValues, engineerId }) => {
    let form = new FormData();
    Object.keys(formValues).map((key) => {
        if (key === 'engineer_address_1') {
            form.set(`engineer_address_1`, formValues[key]);
        } else if (key === 'engineer_address_2') {
            form.set(`engineer_address_2`, formValues[key]);
        } else if (key === 'city') {
            form.set(`city`, formValues[key]);
        } else if (key === 'pincode') {
            form.set(`pincode`, formValues[key]);
        } else if (key === 'adhar_number') {
            form.set(`adhar_number`, formValues[key]);
        } else if (key === 'id_proof_type') {
            form.set(`id_proof_type`, formValues[key]);
        } else if (key === 'fe_document_image' && typeof formValues.fe_document_image !== 'string' && formValues.fe_document_image !== undefined) {
            form.set(`fe_document_image`, formValues.fe_document_image[0]);
        } else {
            form.set(`${key}`, formValues[key]);
        }
    });
    return {
        type: fe.UPDATE_FE_DETAILS_REQUEST,
        payload: { form, engineerId },
    };
};

export const getFieldEngineersDropdownList = ({ servicePartnerId }) => ({
    type: fe.FIELD_ENGINEERS_DROPDOWN_LIST_REQUEST,
    payload: { servicePartnerId },
});
