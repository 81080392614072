import React from 'react';
import IconToolTip from '../../common/IconToolTip';

export const LabelValueComponent = ({ label, email, mobile, name, valueFontSize = 'text-font11 text-scogogray', labelFontSize = 'text-font12 text-black' }) => {
    if (mobile || email || name) {
        return (
            <div className='w-full line-clamp-2 break-all'>
                <p className={`text-scogogray font-medium ${labelFontSize}`}>
                    {label}: {name && <span className={'text-black ' + valueFontSize}>{name},</span>}
                </p>
                <span className='gap-1 break-all '>{mobile && <span className={valueFontSize + ' text-scogogray'}>{mobile}</span>}</span>
                {email && <p className={valueFontSize + ' text-scogogray'}>{email}</p>}
            </div>
        );
    }
    return <></>;
};

export const PriceWithLabel = ({
    value,
    label,
    className = '',
    labelClass = 'text-scogogray text-font10 font-medium',
    valueClass = 'text-scogoprimary text-font-09 text-black',
    renderLabel = <></>,
    onClick = () => { }
}) => {
    if (isNaN(parseInt(value, 10))) return <></>;
    return (
        <div className={className} onClick={onClick}>
            <span className={labelClass}>{label}</span>
            <span className=' flex items-center'>
                <span className={valueClass}>₹ {value}</span>
                {renderLabel}
            </span>
        </div>
    );
};

export const LineClampComponent = ({ value, lineClamp = 'line-clamp-2', textCss = 'text-black text-font11', label, labelClass = 'text-font11' }) => {
    if (label) {
        return (
            <IconToolTip placement={'bottom'} title={`${label} : ${value}`}>
                <span className={`${lineClamp ? lineClamp : ''} ${labelClass} break-all text-scogogray font-medium`}>
                    {label} :<span className={`${textCss}`}>{` ${value}`}</span>
                </span>
            </IconToolTip>
        );
    }
    return (
        <div className='break-all'>
            <IconToolTip placement={'bottom'} title={value}>
                <span className={`${lineClamp ? lineClamp : ''} break-all ${textCss} `}>{value}</span>
            </IconToolTip>
        </div>
    );
};

export const handHelding = 'Hand Helding';
