import PersonAddIcon from '@mui/icons-material/PersonAdd';
import IconToolTip from '../../../common/IconToolTip';
import { useState } from 'react';
import { Input, Popover } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { defaultSingleChatPic } from '../../chat/ChatController';
import ButtonScogoPrimary from '../../../common/Buttons/ButtonScogoPrimary';
import { sendDataThroughWebsocket } from '../../../actions/websocket';
import { websocketEvents } from '../../../utils/websocket';
import { useDispatch } from 'react-redux';
import { sendToastMessage } from '../../../actions/toast';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { generateRoomLink } from './constants';
import { GetQueryParam } from '../../../utils/common';
import { getUserCallStatusAction } from '../../../actions/users';

const blockedList = ["alpesh@scogo.in", "karan@scogo.in", "monika@scogo.in", "mohit@scogo.in", "nitin@scogo.in"];

export const InviteUsersPopover = ({ users = [], ticketId, setIsRinging }) => {

    const { loggedUser } = useSelector(state => state.auth);

    users = users.filter(e => e?.userId !== loggedUser?.id);
    users = users.filter(e => !blockedList.includes(e?.email));

    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedIds, setSelectedIds] = useState([]);
    const [filteredMembers, setFilteredMembers] = useState(users);
    const { room_id } = useParams();
    const dispatch = useDispatch();
    const openedConversationId = GetQueryParam('openedConversationId', String);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setSelectedIds([]);
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleSelectOnChange = (id) => {
        if (selectedIds.includes(id)) {
            setSelectedIds(prev => prev.filter(e => e !== id))
        }
        else {
            setSelectedIds([...selectedIds, id]);
        }
    }

    const handleSelectAllOnChange = (e) => {
        if (e.target.checked) {
            setSelectedIds(filteredMembers.map(a => a.userId));
        }
        else {
            setSelectedIds([]);
        }
    }


    const handleInputOnChange = (e) => {
        const value = e.target.value;
        if (value) {
            setFilteredMembers(_ => users.filter(e => e.username.toLowerCase().includes(value.toLowerCase())));
        }
        else {
            setFilteredMembers(users);
        }
    }


    const handleInviteOnClick = () => {
        if (ticketId.includes('single')) { //P2P chat
            dispatch(sendDataThroughWebsocket({ action: websocketEvents.chat.videoMeetInvite, data: { userIds: selectedIds, videoMeetDetails: { videoMeetLink: generateRoomLink(room_id, openedConversationId), room_id: room_id } } }));
        }
        else {
            dispatch(sendDataThroughWebsocket({ action: websocketEvents.chat.videoMeetInvite, data: { userIds: selectedIds, videoMeetDetails: { videoMeetLink: generateRoomLink(room_id, openedConversationId), ticketId: ticketId, room_id: room_id } } }));
        }
        setTimeout(() => {
            dispatch(getUserCallStatusAction({ roomId: room_id, status: [1, 3] }));
        }, 3000)
        setTimeout(() => {
            setIsRinging(false);
        }, 40000);
        dispatch(sendToastMessage({ status: 'success', message: 'Invite Sent' }));
        handleClose();
    }

    return (
        <>
            <IconToolTip title="Invite Users">
                <div className='h-full flex items-center' onClick={handleClick}>
                    <PersonAddIcon sx={{ fontSize: 28 }} className='hover:cursor-pointer hover:text-gray-600 transition-all' />
                </div>
            </IconToolTip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <div className='h-[40rem] w-[30rem]'>
                    <div className="h-[90%] flex flex-col gap-4 overflow-y-auto p-5">
                        <Input placeholder="Search" autoFocus onChange={handleInputOnChange} />
                        <FormControlLabel key={`select-all`} control={<Checkbox onChange={handleSelectAllOnChange} checked={selectedIds.length === filteredMembers.length} />} label={<p className=" text-font14">{`Select All`}</p>} />
                        {
                            filteredMembers.length > 0 && filteredMembers.map((user) => {
                                return (
                                    <FormControlLabel key={user.userId} control={<Checkbox onChange={() => handleSelectOnChange(user.userId)} checked={selectedIds.includes(user.userId) || selectedIds.length === filteredMembers.length} />} label={<div className="flex gap-3 items-center"><img src={`${user?.profilePic || defaultSingleChatPic}`} alt="profile_pic" className=" rounded-full h-[3rem] w-[3rem] object-cover" /><p className=" text-font14">{`${user.username}`}</p></div>} />
                                )
                            })
                        }
                    </div>
                    <div className='h-[10%] flex justify-center items-center border-t-1 border-gray-500'><ButtonScogoPrimary textOrComponent={'Invite'} onClick={handleInviteOnClick} disabled={selectedIds?.length === 0} /></div>
                </div>
            </Popover>
        </>
    )
}
