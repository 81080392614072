import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openChatInNewTab } from '../../../../actions/conversations';
import { getAssetHistory } from '../../../../actions/inventory';
import IconToolTip from '../../../../common/IconToolTip';
import Loader from '../../../../common/Loader';
import Verticalline from '../../../../common/Verticalline';
import { getLookupWitHDefaultValue, validateStringValue } from '../../../../utils/common';
import NoResultFound from '../../../../common/NoResultFound';
import { serviceTypeIds } from '../../../Ticket/TicketController';
import { SP, isCustomerGroup, isScm } from '../../../../utils/role';
import { getCustomerIdOfLoginUser } from '../../../../utils/utils';
import { openCustomModal } from '../../../../actions/modal';
import EditAssetDate from './EditAssetHistoryDate';


export default function AssetHistory({ assetId, assetSource }) {
    const { assetHistory, error } = useSelector((state) => state.inventory);
    const { assetHistoryLoading } = useSelector((state) => state.loading);
    const dispatch = useDispatch();
    const { loggedUser } = useSelector((state) => state.auth);
    const type = loggedUser.type;
    const role = loggedUser.role.id;
    useEffect(() => {
        dispatch(getAssetHistory({ assetId, assetSource }));
    }, [assetId, dispatch]);


    if (error && assetHistory?.length < 1) {
        return <div className="py-4 px-4" >
            <NoResultFound message="Asset Details Not Found" paddingy="py-2" paddingx="px-2" />
        </div>
    }

    const spareAsset = 'spareAsset',
        ticket = 'ticket',
        asset = 'asset';
    const iconNames = {
        [spareAsset]: 'warehouse',
        [ticket]: 'confirmation_number',
        [asset]: 'business',
    };


    if (assetHistoryLoading) {
        return (
            <div className='border border-white'>
                <Loader color='#F48A21' size='65' speed='1' thickness='4' margin='150px' />
            </div>
        );
    }

    const showAssetInfo = (_asset) => {
        const assetInfo = getLookupWitHDefaultValue({
            lookup: {
                [spareAsset]: <Warehouse asset={_asset} />,
                [ticket]: <Ticket ticket={_asset} />,
                [asset]: <Site asset={_asset} />,
            },
            defaultValue: <></>,
        });
        return assetInfo[_asset?.itemType];
    };

    return (
        <div className='relative w-full'>
            <ul>
                {assetHistory?.map((asset, i) => {
                    return (
                        <li key={asset?.id} className={`border bottom-2  relative flex flex-row items-center  h-36  ${i % 2 !== 0 ? 'bg-gray-100' : ''}`}>
                            <div className='flex items-center w-full'>
                                <div className='px-4'>
                                    <span
                                        style={{ height: '3.5rem', width: '3.5rem' }}
                                        className='material-icons text-scogoprimary text-font15 border-2 flex items-center justify-center rounded-full  border-scogoprimary'
                                    >
                                        {iconNames[asset?.itemType]}
                                    </span>
                                </div>
                                <div className={`text-black w-full overflow-hidden text-font12`}>
                                    <p className='truncate cursor-pointer w-11/12'>{showAssetInfo(asset)}</p>
                                    {asset?.created_at && <span className='text-scogogray font-normal text-font10 '>{moment(asset.created_at).format('DD/MM/YYYY HH:mm')}

                                    </span>}
                                    {(asset?.created_at && (isScm(role, type) || isCustomerGroup(role, type))) && <EditAssetHistoryDate assetSource={assetSource} assetId={assetId} asset={asset} />}
                                </div>
                            </div>
                            <Verticalline isLast={i + 1 === assetHistory?.length} lineHeight='5.8rem' marginTop='9rem' />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}


const EditAssetHistoryDate = ({ assetSource, assetId, asset }) => {
    const dispatch = useDispatch();
    const editDate = (asset) => {
        dispatch(
            openCustomModal({
                heading: 'Edit Date',
                modalWidth: '20vw',
                modalHeight: 'auto',
                modalComponent: <EditAssetDate asset={asset} assetSource={assetSource} assetId={assetId} />
            })
        );
    }

    return (
        <IconToolTip title='Edit'><span
            className='bg-scogoddd text-scogoprimary material-icons text-base text-center w-6 cursor-pointer ml-2 rounded-full px-1 hover:text-white hover:bg-scogoorange'
            onClick={() => {
                editDate(asset);
            }}
        >
            edit
        </span></IconToolTip>
    )
}
const Warehouse = ({ asset }) => {

    let warehouseName = asset?.warehouse?.name || '';
    let title = 'Added on Warehouse';
    if (asset?.warehouse?.type === SP.type && asset?.warehouse?.user) {
        warehouseName = `${validateStringValue(asset.warehouse.user.first_name)} ${validateStringValue(asset.warehouse.user.last_name)}`;
        title = 'Ticket Executed By'
    }

    return (
        <>
            <p>{title}</p>
            <IconToolTip title='Show Spares' placement='bottom'>
                <a
                    className=' block max-w-290 truncate font-bold  hover:text-scogoorange text-font11 cursor-pointer text-scogobgsky m-0'
                    target='_blank'
                    href={`/inventory?tab=spareAssets&selectedWarehouseId=${asset?.warehouse?.id}`}
                    rel='noreferrer'
                >
                    {warehouseName}
                </a>
            </IconToolTip>
        </>
    );
};

const Site = ({ asset }) => {
    return (
        <>
            <p>Added on Site</p>
            {asset?.site?.external_site_id && (
                <IconToolTip title='Show Assets' placement='bottom'>
                    <a
                        className=' block max-w-290 truncate font-bold  hover:text-scogoorange text-font11 cursor-pointer text-scogobgsky m-0'
                        target='_blank'
                        href={`/inventory?tab=assets&selectedSiteId=${asset?.site?.id}`}
                        rel='noreferrer'
                    >
                        {asset?.site?.external_site_id}
                    </a>
                </IconToolTip>
            )}
        </>
    );
};

const Ticket = ({ ticket }) => {
    const dispatch = useDispatch();
    const { loggedUser } = useSelector(state => state.auth);
    const loggedUserCustomerId = getCustomerIdOfLoginUser(loggedUser);
    const showUsecase = loggedUserCustomerId !== 209;

    const isMigrationTicket = ticket.service_type_id === serviceTypeIds.migration;
    const showRemark = ![serviceTypeIds.service_type_id, serviceTypeIds.reverse_logistics].includes(ticket.service_type_id);

    return (
        <>
            <div className='flex'>
                <IconToolTip title='Open Chat' placement='bottom'>
                    <span
                        className='text-scogobgsky block max-w-290 font-bold hover:text-scogoorange truncate'
                        onClick={() => dispatch(openChatInNewTab({ conversationId: ticket?.id }))}
                    >
                        {ticket?.job_ticket_number}
                    </span>
                </IconToolTip>
                &nbsp;
                {ticket?.usecase && showUsecase && (
                    <>
                        created for&nbsp;
                        <IconToolTip title={`Usecase: ${ticket.usecase.use_case_name}`} placement='bottom'>
                            <span className='max-w-12 text-ellipsis overflow-hidden'>{ticket.usecase.use_case_name}</span>
                        </IconToolTip>
                    </>
                )}
            </div>
            <div className='max-w-290'>
                <div className=''>
                    <IconToolTip title={`Project Name : ${ticket?.project?.project_name}`}>
                        <p className='text-scogogray truncate w-full font-normal text-font11'>Project Name : {ticket?.project?.project_name}</p>
                    </IconToolTip>
                </div>
                {isMigrationTicket &&
                    validateStringValue(ticket.description) && <div className=''>
                        <IconToolTip title={`Description : ${ticket.description}`}>
                            <p className='text-scogogray truncate w-full font-normal text-font11'>Description : {ticket.description}</p>
                        </IconToolTip>
                    </div>}
                {/* <div className='w-1/3'>
                    <IconToolTip title={`Service Type : ${ticket?.service_type?.service_name}`}>
                        <p className='text-scogogray truncate w-full font-normal text-font11'>{ticket?.service_type?.service_name}</p>
                    </IconToolTip>
                </div> */}
                {/* <div className=''>
                    <IconToolTip title={`Title : ${ticket?.title}`}>
                        <p className='text-scogogray truncate w-full font-normal text-font11'>{ticket?.title}</p>
                    </IconToolTip>
                </div> */}
            </div>

            <div className='max-w-290 gap-2'>
                {validateStringValue(ticket?.issue?.title) && <div className='w-full'>
                    <IconToolTip title={`Issue Category : ${ticket?.issue?.title}`}>
                        <p className='text-scogogray truncate w-full font-normal text-font11'>Issue : {ticket?.issue?.title}</p>
                    </IconToolTip>
                </div>}
                {showRemark && validateStringValue(ticket?.job_sign_off_notes) && <div className='w-full'>
                    <IconToolTip title={`Sign off remark : ${ticket?.job_sign_off_notes}`}>
                        <p className='text-scogogray truncate w-full font-normal text-font11'>Remark : {ticket?.job_sign_off_notes}</p>
                    </IconToolTip>
                </div>}
            </div>
        </>
    );
};


