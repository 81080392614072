import { useVideo } from "@100mslive/react-sdk";


export const BigScreenShare = ({ presenter }) => {
    const { videoRef } = useVideo({
        trackId: presenter.auxiliaryTracks[0],
        attach: true
    });
    return (
        <div className="h-full w-full transition-all ease-linear duration-300 animate-fadeIn">
            <video ref={videoRef} autoPlay muted playsInline className='w-full h-full'></video>
        </div>
    )
}
