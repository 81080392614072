const development = {
    name: 'dev',
    rootDomain: 'scogo.in',
    ui4Domain: 'dev.cloud.scogo.in',
    remoteTerminal: 'https://dev-console.scogo.in',
    api: {
        baseUrl: 'https://api.dev.cloud.scogo.in:8080/v1',
        lambdaBaseUrl: 'https://auhyfy2waa.execute-api.ap-south-1.amazonaws.com/dev/api',
        WebsocketBaseUrl: 'wss://44e8mr8ls9.execute-api.ap-south-1.amazonaws.com/dev',
        staticAssetsUrl: 'https://s3.ap-south-1.amazonaws.com/static.assets.scogo.in',
        microservices: 'https://dev.api.scogo.in',
    },
    googleAuthUrl:
        'https://accounts.google.com/o/oauth2/auth?response_type=code&access_type=online&client_id=429914762977-ccv2khou740dlca66g8dveao50hjs8ph.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fdev.cloud.scogo.in&state&scope=profile%20email&approval_prompt=auto',
    tracking: 'https://tracking.scogo.in',
    partnerStrength: {
        low: { start: 0, end: 5 },
        medium: { start: 5, end: 10 },
        Strong: { start: 10, end: undefined },
    },
    timer: 604800,
    pagination: {
        pageSizes: [10, 50, 100, 200],
    },
    maxAllowance: 500,
    googleMapApiKey: 'AIzaSyA6XlPqo0QRMvqHRWQxoUEgeJiKf8gwDaE',
    appUrls: {
        baseUrl: 'https://vge63.app.link/invitation',
        referral: '?referral={referralCode}',
        verification: '?verification={verificationCode}',
        verificationWithMobile: '?verification={verificationCode}&mobile={mobile}',
        resetPassword: '?token={tokenCode}',
        transactionalAuth: '?transaction={transactionalToken}',
    },
    whitelabel: {
        documentTitle: 'Scogo',
        favicon: 'https://scogo.s3.ap-south-1.amazonaws.com/superapp/SuperApp_icon.svg',
        homepage: {
            logoUrl: 'https://scogo.s3.ap-south-1.amazonaws.com/superapp/SuperApp_logo.svg',
            header: 'Scogo',
            subHeader: 'Simple ticketing system for your business',
            description: 'that comes with on-demand IT Field Services. Anytime. AnyWhere.',
        },
    },
    roles: {
        Admin: 1,
        PM: 2,
        SDM: 3,
        Customer: 4,
        CSPOC: 5,
        SCM: 6,
        SP: 7,
        FE: 8,
        CP: 9,
        FM: 10,
        Freelancer: 12,
        CSP: 13,
        CustomerAdmin: 14,
        CustomerPM: 15,
        CustomerNOC: 16,
        CustomerEndUser: 17,
    },
    advDash: {
        newTab: 'https://analytics.scogo.in/login',
    },
    bankopen: {
        paymenturl: 'https://sandbox-payments.open.money/layer',
        accessKey: '46f99940-b07a-11ea-8ab8-5d3d8cc78a42',
    },
    otpLess: {
        url: 'https://scogo.authlink.me',
    },
    gTagId: "G-T3RY03RXTR",
    clarityKey: 'jjk687v1kt',
    store_affiliate_link_static: 'https://store.scogo.in/?ref=',
    serUrl: 'ser.scogo.in'

};

export default development;
