import React, { useEffect, useState, useRef, useCallback } from 'react';
import SitesTop from './components/SitesTop.js';
import SitesOption from './components/SitesOptions.js';
import InventoryButtonOptions from './components/InventoryButtonOptions.js';
import AssetDetails from './sites/AssetDetails.js';
import SiteDetails from './sites/SitesDetails.js';
import SpareAssetTable from './spares/SpareAssetDetails';
import WarehouseDetails from './spares/WarehouseDetails';
import {
    sites as sitesTab,
    assets as assetsTab,
    warehouses as warehousesTab,
    spareAssets as spareAssetsTab,
    GetInventoryActions,
    defaultRowsPerPage,
    defaultPage,
} from './InventoryController';
import { useSelector, useDispatch } from 'react-redux';
import { getCustomerIdOfLoginUser } from '../../utils/utils';
import SideDrawer from './reusableComponents/AddOption/InventorySideDrawer.js';
import { setInventorySideDrawer, setSiteIdInStore, setWarehouseIdInStore, setArchiveAssetToStore, clearInventoryTableDataOnUnmount } from '../../actions/inventory';
import { GetQueryParam, debounce } from '../../utils/common';
import { updateQueryParams, updateSearchTermQuery } from '../../utils/filter';
import { useNavigate, useLocation } from 'react-router-dom';
import AssetGroupView from './sites/AssetGroupView';
import { GET_ENDUSERS_LIST_SUCCESS } from '../../types/user.js';

export const assetGroupView = 'assetGroupView';

function Inventory() {
    const navigate = useNavigate();
    const location = useLocation();
    const tabs = GetInventoryActions('tabs');
    let { rootTab: defaultRootTab, subTab: defaultSubTabOfDefaultRootTab } = GetInventoryActions('defaultTab', { urlQueryString: location.search });
    const siteIdQueryParam = GetQueryParam('selectedSiteId', Number);
    const warehouseIdQueryParam = GetQueryParam('selectedWarehouseId', Number);
    const limitQueryParam = GetQueryParam('limit', Number);
    const pageQueryParam = GetQueryParam('page', Number);
    const archiveQueryParam = GetQueryParam('archive', Boolean);
    const isAssetGroupView = GetQueryParam(assetGroupView, Boolean);
    const searchQueryParam = GetQueryParam('query');
    const [selectedSiteId, setSelectedSiteId] = useState(siteIdQueryParam);
    const [selectedWarehouseId, setSelectedWarehouseId] = useState(warehouseIdQueryParam);
    const [clearFilterButton, setClearFilterButton] = useState(false);

    const [selectedSiteExternalSiteId, setSelectedSiteExternalSiteId] = useState('');
    const [selectedWarehouseName, setSelectedWarehouseName] = useState('');
    const [customerIdOfSelectedSite, setCustomerIdOfSelectedSite] = useState(0);
    const showInventoryBulkActionButton = GetInventoryActions('bulkActionButton');
    const showInventoryAddNewButtonRoleWise = GetInventoryActions('addNewButton', { tabName: defaultSubTabOfDefaultRootTab });
    const [searchTerm, setSearchTerm] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
    const [page, setPage] = useState(defaultPage);

    const { loggedUser } = useSelector((state) => state?.auth);
    const { filterPayload, showInventorySideDrawer, isFilterActive } = useSelector((state) => state.inventory);

    const customerId = loggedUser && getCustomerIdOfLoginUser(loggedUser);
    const dispatch = useDispatch();
    const role = loggedUser?.role?.id;
    const type = loggedUser?.type;
    const inventoryTableRefs = useRef({});

    useEffect(() => {
        document.title = "Inventory";
        return () => {
            dispatch({ type: GET_ENDUSERS_LIST_SUCCESS, payload: { data: undefined, page: 0 } })
        }
    }, [dispatch]);

    const openInventorySideDrawer = ({ inventorySideDrawerName, inventorySideDrawerPayload }) => {
        dispatch(setInventorySideDrawer(true, inventorySideDrawerName, inventorySideDrawerPayload));
    };
    const closeInventorySideDrawer = useCallback(() => dispatch(setInventorySideDrawer(false)), [dispatch]);

    const goToInventoryTab = (destinationTab, { siteId, warehouseId, archive } = {}) => {
        dispatch(clearInventoryTableDataOnUnmount());

        let search = `?tab=${destinationTab}`;
        if (siteId) search += `&selectedSiteId=${siteId}`;
        if (warehouseId) search += `&selectedWarehouseId=${warehouseId}`;
        if (archive) search += `&archive=${archive}`;

        navigate(`/inventory${search}`);
    };

    const goToAssetTab = (siteId, archive) => {
        goToInventoryTab(assetsTab.path, { siteId, archive });
    };

    const goToSpareAssetTab = (warehouseId) => {
        goToInventoryTab(spareAssetsTab.path, { warehouseId });
    };

    useEffect(() => {
        let page = defaultPage;
        let limit = defaultRowsPerPage;
        let searchTerm = '';
        if (limitQueryParam) limit = limitQueryParam;
        if (searchQueryParam) searchTerm = searchQueryParam;
        if (pageQueryParam) page = pageQueryParam;
        setRowsPerPage(limit);
        setSearchTerm(searchTerm);
        setPage(page);
        setSelectedSiteId(siteIdQueryParam);
        dispatch(setSiteIdInStore(siteIdQueryParam));
        setSelectedWarehouseId(warehouseIdQueryParam);
        dispatch(setWarehouseIdInStore(warehouseIdQueryParam));
        dispatch(setArchiveAssetToStore(archiveQueryParam));
    }, [searchQueryParam, limitQueryParam, pageQueryParam, siteIdQueryParam, warehouseIdQueryParam, dispatch, archiveQueryParam]);

    const getBulkHeaderActions = () => {
        const findRootTab = tabs.find((tab) => tab.tabName === defaultRootTab);
        if (findRootTab) {
            return findRootTab.subTabs.find((subTab) => subTab.id === defaultSubTabOfDefaultRootTab)?.getBulkActions(role, type, { selectedSiteId, selectedWarehouseId });
        }
    };

    const getSingularTabName = () => {
        const findRootTab = tabs.find((tab) => tab.tabName === defaultRootTab);
        if (findRootTab) {
            return findRootTab.subTabs.find((subTab) => subTab.id === defaultSubTabOfDefaultRootTab)?.singular;
        }
    };

    const debouncedSearchCall = useCallback(debounce(updateSearchTermQuery, 500), []);

    const setSeachQuery = (searchTerm) => {
        let limit = limitQueryParam ? limitQueryParam : defaultRowsPerPage;
        setSearchTerm(searchTerm);
        debouncedSearchCall({ navigate, searchTerm, limit });
    };

    const setIsArchiveAssetSelected = (isSelected) => {
        dispatch(setArchiveAssetToStore(isSelected));
    };

    const getSubTabsOfRootTab = (rootTabName) => {
        return tabs.find((tab) => tab.tabName === rootTabName).subTabs;
    };

    const handleClearFilterClick = () => {
        updateQueryParams({
            navigate,
            destinationTab: defaultSubTabOfDefaultRootTab,
            query: searchQueryParam,
            limit: limitQueryParam,
            page: 1,
            defaultRowsPerPage,
            defaultPage,
            archive: archiveQueryParam,
            warehouseId: selectedWarehouseId,
            siteId: selectedSiteId,
        });
    };

    const updateQueryFilters = ({
        filterList,
        destinationTab = defaultSubTabOfDefaultRootTab,
        limit = limitQueryParam,
        page = pageQueryParam,
        filterSchema,
        query = searchQueryParam,
        archive = archiveQueryParam,
        warehouseId = selectedWarehouseId,
        siteId = selectedSiteId,
    }) => {
        updateQueryParams({ navigate, destinationTab, limit, page, filterList, filterSchema, query, defaultRowsPerPage, defaultPage, archive, warehouseId, siteId });
    };

    const TableComponent = (tab) => {
        const InventoryTableHandler = {
            [sitesTab.id]: SiteDetails,
            [assetsTab.id]: AssetDetails,
            [warehousesTab.id]: WarehouseDetails,
            [spareAssetsTab.id]: SpareAssetTable,
        };
        let InventoryComponent = InventoryTableHandler[tab];
        if (isAssetGroupView && tab === assetsTab.id) InventoryComponent = AssetGroupView;
        return (
            <InventoryComponent
                ref={(elem) => (inventoryTableRefs.current[tab] = elem)}
                setCustomerIdOfSelectedSite={setCustomerIdOfSelectedSite}
                setSelectedSiteExternalSiteId={setSelectedSiteExternalSiteId}
                openInventorySideDrawer={openInventorySideDrawer}
                closeInventorySideDrawer={closeInventorySideDrawer}
                selectedSiteId={selectedSiteId}
                selectedSubTab={defaultSubTabOfDefaultRootTab}
                goToSpareAssetTab={goToSpareAssetTab}
                goToAssetTab={goToAssetTab}
                setSelectedWarehouseName={setSelectedWarehouseName}
                selectedWarehouseId={selectedWarehouseId}
                customerId={customerId}
                isFilterActive={isFilterActive}
                inventoryRowsPerPage={rowsPerPage}
                inventoryPage={page}
                filterPayload={filterPayload}
                isArchiveAssetSelected={archiveQueryParam}
                role={role}
                type={type}
                searchQueryParam={searchQueryParam}
                limitQueryParam={limitQueryParam}
                pageQueryParam={pageQueryParam}
                setClearFilterButton={setClearFilterButton}
                updateQueryFilters={updateQueryFilters}
                siteIdQueryParam={siteIdQueryParam}
                warehouseIdQueryParam={warehouseIdQueryParam}
                archiveQueryParam={archiveQueryParam}
                setAssetGroupView={setAssetGroupView}
            />
        );
    };

    const setAssetGroupView = useCallback((status) => {
        let searchParams = new URLSearchParams(window.location.search);
        if (status) searchParams.set(assetGroupView, status);
        else searchParams.delete(assetGroupView);
        searchParams = searchParams.toString();
        navigate({
            pathname: window.location.pathname,
            search: searchParams,
        });
    }, [navigate]);

    return (
        <>
            <div className={`subContainer title-13 px-5 pt-5`}>
                <div className='titleContainer sticky w-full z-20 '>
                    <SitesTop
                        isSearch
                        title='Inventory'
                        setSeachQuery={setSeachQuery}
                        searchTerm={searchTerm}
                        singular={getSingularTabName()}
                        handleClearFilterClick={handleClearFilterClick}
                        clearFilterButton={clearFilterButton}
                        selectedSubTab={defaultSubTabOfDefaultRootTab}
                        isAssetGroupView={isAssetGroupView}
                    />
                    <SitesOption tabs={tabs} selectedRootTab={defaultRootTab} goToInventoryTab={goToInventoryTab} />
                    <InventoryButtonOptions
                        headerBulkDropdownActions={getBulkHeaderActions()}
                        customerIdOfSelectedSite={customerIdOfSelectedSite}
                        setCustomerIdOfSelectedSite={setCustomerIdOfSelectedSite}
                        subTabs={getSubTabsOfRootTab(defaultRootTab)}
                        openInventorySideDrawer={openInventorySideDrawer}
                        selectedSubTab={defaultSubTabOfDefaultRootTab}
                        setIsArchiveAssetSelected={setIsArchiveAssetSelected}
                        selectedSiteId={selectedSiteId}
                        selectedRootTab={defaultRootTab}
                        tabs={tabs}
                        selectedWarehouseId={selectedWarehouseId}
                        showInventoryBulkActionButton={showInventoryBulkActionButton}
                        showInventoryAddNewButtonRoleWise={showInventoryAddNewButtonRoleWise}
                        selectedSiteExternalSiteId={selectedSiteExternalSiteId}
                        setSelectedSiteExternalSiteId={setSelectedSiteExternalSiteId}
                        selectedWarehouseName={selectedWarehouseName}
                        setSelectedWarehouseName={setSelectedWarehouseName}
                        singular={getSingularTabName()}
                        goToAssetTab={goToAssetTab}
                        goToInventoryTab={goToInventoryTab}
                        getSelectedData={() => inventoryTableRefs?.current?.[defaultSubTabOfDefaultRootTab]?.getSelectedData()}
                        archiveQueryParam={archiveQueryParam}
                        setAssetGroupView={setAssetGroupView}
                        isAssetGroupView={isAssetGroupView}
                    />
                </div>
                {TableComponent(defaultSubTabOfDefaultRootTab)}
            </div>
            {showInventorySideDrawer && <SideDrawer selectedSiteId={selectedSiteId} selectedWarehouseId={selectedWarehouseId} />}
        </>
    );
}

export default Inventory;
