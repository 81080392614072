export const TeamItemsClassName =
    "origin-top-right absolute px-3 py-2  top-20 mt-2 w-auto z-10 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none";
export const TeamItemClassName =
    "bg-scogoorange block mt-1 text-white text-font11 px-p-6 py-p-3 rounded-3px";

export const TeamMenuButtonIcon = (count) => {
    return (
        <span className="text-font12 font-normal text-scogoorange cursor-pointer">
            {""}
            {count}
        </span>
    );
};