import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import moment from 'moment';

import ButtonScogoPrimary from '../../../common/Buttons/ButtonScogoPrimary';
import ButtonScogoPrimaryOutlined from '../../../common/Buttons/ButtonScogoPrimaryOutlined';
import { addNewSites } from '../../../actions/tickets';
import { closeModalAction } from '../../../actions/modal';

const useExistingSiteForm = 'use_existing_site';
const createNewSiteForm = 'create_new_site';

export const DuplicateSite = ({ siteDetails, projectId, bulk, duplicateSites, count }) => {
    const dispatch = useDispatch();
    const { formButtonLoading } = useSelector((state) => state.loading);

    const [csvHeaders, setCsvHeaders] = useState([])
    const [csvData, setCsvData] = useState([])

    useEffect(() => {
        if (siteDetails && bulk) {
            const duplicateCsvHeaders = []
            const duplicateCsvData = []
            // if (siteDetails.duplicate_serial_number.length > 0) {
            //     siteDetails.duplicate_serial_number.map(serial => csvData.push(serial))
            // }
            if (siteDetails.error_location_courier.length > 0) {
                siteDetails.error_location_courier.map(courier => duplicateCsvData.push(courier))
            }
            if (siteDetails.error_pincode.length > 0) {
                siteDetails.error_pincode.map(pincode => duplicateCsvData.push(pincode))
            }
            if (siteDetails.asset_type_error.length > 0) {
                siteDetails.asset_type_error.map(asset => duplicateCsvData.push(asset))
            }
            let index = duplicateCsvData.reduce((p, c, i, a) => a[p].length > c.length ? p : i, 0)
            let iterator = (duplicateCsvData[index].length - 17) / 4
            duplicateCsvHeaders.push('Reason')
            duplicateCsvHeaders.push('External Ticket Id')
            duplicateCsvHeaders.push('Site Name')
            duplicateCsvHeaders.push('Branch Name')
            duplicateCsvHeaders.push('Region')
            duplicateCsvHeaders.push('Zone')
            duplicateCsvHeaders.push('Branch/Office Address')
            duplicateCsvHeaders.push('PIN Code')
            duplicateCsvHeaders.push('Site Contact Person')
            duplicateCsvHeaders.push('Site Contact Email')
            duplicateCsvHeaders.push('Site Contact Phone')
            duplicateCsvHeaders.push('Tracking ID')
            duplicateCsvHeaders.push('Tracking Company')
            duplicateCsvHeaders.push('Pickup Address')
            duplicateCsvHeaders.push('Pickup Pincode')
            duplicateCsvHeaders.push('Site Latitude')
            duplicateCsvHeaders.push('Site Longitude')
            duplicateCsvHeaders.push('Issue Category')
            for (let i = 0; i < iterator; i++) {
                duplicateCsvHeaders.push('ASSET Type')
                duplicateCsvHeaders.push('ASSET Serial Number')
                duplicateCsvHeaders.push('ASSET External Order Id')
                duplicateCsvHeaders.push('Part Code')
            }
            setCsvHeaders(duplicateCsvHeaders)
            setCsvData([...new Set(duplicateCsvData)])
        }
    }, [siteDetails, bulk])

    const onUseExistingClick = () => {
        dispatch(addNewSites({ projectId, data: { location_data: siteDetails.duplicate_sites }, existing: true, addNew: false, formName: useExistingSiteForm }));
    };

    const createNewClick = () => {
        dispatch(addNewSites({ projectId, data: { location_data: siteDetails.duplicate_sites }, addNew: true, formName: createNewSiteForm }));
    };

    const close = () => {
        dispatch(closeModalAction());
    };

    return (
        <div className='px-4 py-2'>
            {!bulk && duplicateSites &&
                <p>{count} duplicate {count > 1 ? 'sites' : 'site'} found</p>
            }
            {bulk ?
                <>
                    <br />
                    <p> Please
                        <CSVLink
                            data={csvData}
                            headers={csvHeaders}
                            filename={`Bulk_Upload_Tickets_Error_${moment(new Date()).format('DD-MM-YYYY')}.csv`}
                            className={'text-scogoorange'}
                        > Download the CSV. </CSVLink>
                    </p>
                    <br />
                    <b>
                        Make changes accordingly in downloaded csv and upload same.
                        Also Remove the reason column from csv while uploading again.
                    </b>
                    <br />
                    {duplicateSites &&
                        <p>
                            Also take action on <b>{count}</b> duplicate {count > 1 ? 'sites' : 'site'}
                        </p>
                    }
                </>
                :
                <></>
            }
            <div className='flex gap-4 justify-end py-4'>
                {duplicateSites ?
                    <>
                        <ButtonScogoPrimaryOutlined textOrComponent='Use Existing' onClick={onUseExistingClick} loading={formButtonLoading[useExistingSiteForm]} />
                        <ButtonScogoPrimary textOrComponent={'Create New'} onClick={createNewClick} loading={formButtonLoading[createNewSiteForm]} />
                    </>
                    :
                    <>
                        <ButtonScogoPrimaryOutlined textOrComponent='Close' onClick={close} />
                    </>
                }
            </div>
        </div>
    );
};
