import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { inviteCustomersEndUsers } from '../../../actions/customer';
import { closeModalAction } from '../../../actions/modal';
import Form from '../../../common/Form';
import Input from '../../../common/Form/Input';
import { useForm } from 'react-hook-form';

export default function InviteEndUsers(props) {
    const dispatch = useDispatch();
    const { formButtonLoading } = useSelector((state) => state.loading);

    const closeModal = () => {
        dispatch(closeModalAction());
    };
    const formName = 'inviteEndUser';

    const defaultValues = {
        first_name: '',
        last_name: '',
        email: '',
        mobile: '',
    };

    const onSubmit = (form) => {
        const payload = {
            first_name: form.first_name,
            last_name: form.last_name,
            email: form.email,
            mobile: form.mobile,
            project_id: props.projectDetail.id,
        };
        dispatch(inviteCustomersEndUsers({ form: payload, formName }));
    };

    const methods = useForm({ defaultValues, mode: 'all' });

    return (
        <Form
            onSubmit={onSubmit}
            onCancel={closeModal}
            submitButtonText={'Save'}
            showSubmit
            submitContainerClassName='flex gap-2 justify-end py-4 px-4'
            isLoading={formButtonLoading[formName]}
            methods={methods}
        >
            <div className='px-3 flex justify-between'>
                <Input className='w-6/12' label='First Name' name='first_name' required type={'text'} />
                <Input className='w-6/12' label='Last Name' name='last_name' required type={'text'} />
            </div>
            <div className='px-3 flex justify-between'>
                <Input
                    className='w-6/12'
                    label='Email Address'
                    name='email'
                    required
                    type={'text'}
                    validate={(email) => {
                        if (email === '') return true;
                        return (
                            email.match(
                                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            ) || 'Invalid Email'
                        );
                    }}
                />
                <Input
                    className='w-6/12'
                    label='Mobile Number'
                    name='mobile'
                    required
                    type={'number'}
                    validate={(mobile) => {
                        if (mobile === '') return true;
                        return mobile.length === 10 || 'Invalid Mobile';
                    }}
                />
            </div>
        </Form>
    );
}
