import { call, put, takeLatest } from 'redux-saga/effects';
import microservices from '../lambdas/microservices';
import { admin, loading, toast } from '../types';

export function* getIssueCategories(action) {
    const { customerId, limit = 10, page = 1, dropdown = false } = action.payload;
    let queryParams = { limit, page, dropdown };
    if (!isNaN(customerId) && customerId > 0) queryParams.customerId = customerId;
    yield put({ type: loading.LIST_ISSUE_CATEGORIES_LOADING, payload: true });
    try {
        const response = yield call(microservices.admin.getIssueCategories, {}, {}, queryParams);
        if (response.data.code === 200) {
            let issueCategories = response.data.data;
            if (dropdown) {
                issueCategories = response.data.data.map((issue) => {
                    return { key: issue.id, value: issue.id, label: issue.title };
                });
            }
            yield put({ type: admin.LIST_ISSUE_CATEGORIES_SUCCESS, payload: issueCategories });
        } else {
            yield put({
                type: toast.SEND_TOAST,
                payload: {
                    status: 'danger',
                    message: response.data.data.message,
                },
            });
        }
    } catch (error) {
        yield put({ type: admin.LIST_ISSUE_CATEGORIES_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.LIST_ISSUE_CATEGORIES_LOADING, payload: false });
}

export default function* adminSaga() {
    yield takeLatest(admin.LIST_ISSUE_CATEGORIES_REQUEST, getIssueCategories);
}
