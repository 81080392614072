import * as React from 'react';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';


const PrettoSlider = styled(Slider)({
    color: '#F48A21',
    height: 5,
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        height: 20,
        width: 20,
        backgroundColor: '#12355F',
        border: '2px solid #12355F',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 10,
        background: 'unset',
        padding: 0,
        width: 24,
        height: 24,
        borderRadius: '50% 50% 50%',
        backgroundColor: '#12355F',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -70%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
});




export default function CustomizedSlider(props) {
    const { low, high, keyName, customMethod, min, max } = props;

    const handleChange = (event, newValue) => {
        customMethod({ keyName, filterRange: newValue })
    }

    return (
        <Box sx={{ width: 'full' }}>
            <div className='px-2'>
                <PrettoSlider
                    aria-label="Always visible"
                    valueLabelDisplay="on"
                    defaultValue={[low, high]}
                    min={min}
                    max={max}
                    onChange={handleChange}
                />
            </div>
        </Box>
    );
}