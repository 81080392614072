import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uploadFiles } from '../../../actions/utils';
import { closeModalAction } from '../../../actions/modal';
import { useForm } from 'react-hook-form';
import Form from '../../../common/Form';
import Upload from '../../../common/Form/FileUpload';
import { excelTypes } from '../../../utils/common';

const formName = 'updateDocketDetails';
export default function UpdateDocketDetails(props) {
    const dispatch = useDispatch();
    const { formButtonLoading } = useSelector((state) => state.loading);

    const closeModal = () => {
        dispatch(closeModalAction());
    };

    const defaultValues = {
        files: [],
    };

    const submit = (form) => {
        const payload = {
            actionType: 'UPLOAD_BULK_NON_SHIPWAY_DOCKETS',
            query: { projectId: props.projectDetail.id },
            files: form.files,
            formName,
        };
        dispatch(uploadFiles(payload));
    };

    const methods = useForm({ defaultValues, mode: 'all' });

    return (
        <Form
            methods={methods}
            onSubmit={(e) => {
                submit(e);
            }}
            buttonPostion={'justify-end'}
            submitButtonName={'Submit'}
            onCancel={closeModal}
            showSubmit
            submitContainerClassName='flex gap-2 justify-end py-4 px-4'
            isLoading={formButtonLoading[formName]}
        >
            <div className='px-3'>
                <Upload
                    label='Upload Docket Data File'
                    name='files'
                    required
                    multiple
                    accept={'.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'}
                    validateFileType={(file) => {
                        const isImage = excelTypes.includes(file.type);
                        return isImage || `${file.name} is not a CSV type`;
                    }}
                />
            </div>
        </Form>
    );
}
