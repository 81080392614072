import { v4 as uuidv4 } from 'uuid';
import { conversations, loading, toast, modal, user, utils, tickets } from '../types';
import { takeEvery, call, put, all, fork, select, takeLatest, delay, take } from 'redux-saga/effects';
import lambdas from '../lambdas/apis';
import { fileUploadOnChange, getFileType, uploadFileToS3WithSignedUrl } from '../utils/common';
import { getSingleConvoIdFromUserIds } from '../utils/utils';
import { checkSendMessageError } from './websocket';
import { getLoggedUser } from '../selectors/users';
import api from '../apis';
import { chatSidebarTab } from '../layout/sidebar';
import { allTicketsTab, chatType, getChatMainTabs, ticketsTab as ticketsTabMaster } from '../pages/chat/ChatController';
import { getViewTicket } from '../selectors/tickets';
import { scogo_new_chat_created } from '../pages/chat/ChatMessage';
import { generateRoomLink } from '../pages/videomeet/components/constants';
import microservices from '../lambdas/microservices';
import { transformTicketforChatAction } from '../reducers/tickets';

export function* getGroupChats(action) {
    let { limit, offset, query } = action.payload;
    let queryParams = { limit, offset, type: 'group,single' };
    if (query) queryParams.query = query;
    yield put({ type: loading.SET_CHAT_LIST_LOADING, payload: true });
    try {
        const response = yield call(lambdas.conversations.getConversations, {}, {}, queryParams);
        if (response.data.code === 200) {
            yield put({ type: conversations.GET_CONVERSATIONS_SUCCESS, payload: { chatsList: response.data.data } });
        } else {
            yield put({ type: conversations.GET_CONVERSATIONS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: conversations.GET_CONVERSATIONS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.SET_CHAT_LIST_LOADING, payload: false });
}

export function* getNextGroupChats(action) {
    let { limit, offset } = action.payload;
    try {
        yield put({ type: loading.SET_NEXT_CONVERSATIONS_LOADING, payload: true });
        const response = yield call(lambdas.conversations.getConversations, {}, {}, { limit, offset, type: 'group,single' });
        if (response.data.code === 200) {
            yield put({ type: conversations.GET_NEXT_CONVERSATIONS_SUCCESS, payload: { chatsList: response.data.data } });
        } else {
            yield put({ type: conversations.GET_NEXT_CONVERSTAION_MESSAGES_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: conversations.GET_NEXT_CONVERSTAION_MESSAGES_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.SET_NEXT_CONVERSATIONS_LOADING, payload: false });
}

export function* getHelpChats(action) {
    let { limit, offset, query, isActive } = action.payload;
    let queryParams = { limit, offset, type: 'support', isActive };
    if (query) queryParams.searchQuery = query;
    yield put({ type: loading.SET_CHAT_LIST_LOADING, payload: true });
    try {
        const response = yield call(lambdas.conversations.getConversations, {}, {}, queryParams);
        if (response.data.code === 200) {
            yield put({ type: conversations.GET_CONVERSATION_COUNT_REQUEST, payload: { type: chatType.help } });
            yield put({ type: conversations.GET_CONVERSATIONS_SUCCESS, payload: { chatsList: response.data.data } });
        } else {
            yield put({ type: conversations.GET_CONVERSATIONS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: conversations.GET_CONVERSATIONS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.SET_CHAT_LIST_LOADING, payload: false });
}

export function* getNextHelpChats(action) {
    let { limit, offset, isActive } = action.payload;
    try {
        yield put({ type: loading.SET_NEXT_CONVERSATIONS_LOADING, payload: true });
        const response = yield call(lambdas.conversations.getConversations, {}, {}, { limit, offset, type: 'support', isActive });
        if (response.data.code === 200) {
            yield put({ type: conversations.GET_NEXT_CONVERSATIONS_SUCCESS, payload: { chatsList: response.data.data } });
        } else {
            yield put({ type: conversations.GET_NEXT_CONVERSTAION_MESSAGES_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: conversations.GET_NEXT_CONVERSTAION_MESSAGES_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.SET_NEXT_CONVERSATIONS_LOADING, payload: false });
}

export function* createSupport(action) {
    const { formName, onSuccess = () => { } } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.conversations.createSupport, {});
        if (response.data.code === 200) {
            onSuccess(response.data.data);
            yield put({ type: conversations.SET_HELP_SUPPORT_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: conversations.CREATE_HELP_SUPPORT_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({
                type: toast.SEND_TOAST,
                payload: {
                    status: 'danger',
                    message: 'Something went wrong!!!',
                },
            });
        }
    } catch (error) {
        yield put({ type: conversations.CREATE_HELP_SUPPORT_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* closeHelpSupport(action) {
    const { _id, formName } = action.payload;

    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });

        const user = yield select(getLoggedUser);
        const response = yield call(lambdas.conversations.closeConversation, {}, { _id });
        if (response.data.code === 200) {
            if (user.role.id !== 6) {
                yield put({
                    type: toast.SEND_TOAST,
                    payload: {
                        status: 'success',
                        message: 'Support Request closed Successfully!!!',
                    },
                });
            } else {
                yield put({
                    type: toast.SEND_TOAST,

                    payload: {
                        status: 'success',
                        message: 'Bot will ask for closure.',
                    },
                });
            }
        } else {
            yield put({
                type: toast.SEND_TOAST,

                payload: {
                    status: 'danger',
                    message: `Something went wrong!!!`,
                },
            });
            yield put({ type: conversations.CLOSE_CONVERSATION_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
        yield put({ type: modal.CLOSE_MODAL });
    } catch (error) {
        yield put({ type: conversations.CLOSE_CONVERSATION_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* getConversationDetails(action) {
    let { _id, isScogoConnect, useConversationId } = action.payload;
    let queryParams = { skipMedia: true };
    if (isScogoConnect) queryParams = { ...queryParams, isScogoConnect };
    if (useConversationId) queryParams.useConversationId = useConversationId;
    try {
        yield put({ type: loading.SET_CONVERSATION_DETAILS_LOADING, payload: true });
        const response = yield call(lambdas.conversations.getConversationDetails, {}, { _id }, queryParams);
        if (response.data.code === 200) {
            yield put({ type: conversations.GET_CONVERSTAION_DETAILS_SUCCESS, payload: { conversationDetail: response.data.data, _id } });
        } else {
            yield put({ type: conversations.GET_CONVERSTAION_DETAILS_FAILURE, payload: { message: 'Oops Something went wrong', conversationDetailsLoadFailure: true } });
        }
    } catch (error) {
        yield put({ type: conversations.GET_CONVERSTAION_DETAILS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.SET_CONVERSATION_DETAILS_LOADING, payload: false });
}

export function* createGroupChat(action) {
    let { data, formName, imageFile, onSuccess = () => { } } = action.payload;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    try {
        yield put({ type: conversations.CREATE_GROUP_CONVERSATION_LOADING, payload: { createGroupChatLoading: true } });
        const response = yield call(lambdas.conversations.createChat, {}, {}, {}, data);
        if (response.data.code === 201) {
            if (imageFile) {
                const signedUrl = response.data.data.images?.[0]?.signedUrl;
                if (signedUrl) {
                    yield call(uploadFileToS3WithSignedUrl, signedUrl, imageFile);
                }
            }
            yield put({
                type: toast.SEND_TOAST,
                payload: {
                    status: 'success',
                    message: 'Group Created Successfully',
                },
            });
            onSuccess(response.data.data.createdChats[0]);
            yield put({
                type: conversations.CREATE_GROUP_CONVERSATION_SUCCESS,
                payload: { createdConversation: response.data.data.createdChats[0] },
            });
        } else {
            yield put({ type: conversations.CREATE_GROUP_CONVERSATION_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({
                type: toast.SEND_TOAST,
                payload: {
                    status: 'danger',
                    message: response.data.data.message ? response.data.data.message : 'Something went wrong',
                },
            });
        }
        yield put({ type: conversations.CREATE_GROUP_CONVERSATION_LOADING, payload: { createGroupChatLoading: false } });
    } catch (error) {
        yield put({ type: conversations.CREATE_GROUP_CONVERSATION_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* getConversationMessages(action) {
    let { _id, offset, limit } = action.payload;
    const query = {}
    if (offset) query.offset = offset
    if (limit) query.limit = limit

    try {
        yield put({ type: loading.SET_CONVERSTAION_MESSAGES_LOADING, payload: { _id, isLoading: true } });
        const response = yield call(lambdas.conversations.getConversationMessages, {}, {}, query, { _id });

        if (response.data.code === 200) {
            yield put({ type: conversations.GET_CONVERSTAION_MESSAGES_SUCCESS, payload: { messages: response.data.data, _id, offset } });
            yield put({
                type: conversations.SET_CONVERSATION_UNREADABLE_MESSAGE_COUNT,
            });
        } else {
            yield put({ type: conversations.GET_CONVERSTAION_MESSAGES_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: conversations.GET_CONVERSTAION_MESSAGES_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.SET_CONVERSTAION_MESSAGES_LOADING, payload: { _id, isLoading: false } });
}
export function* getNextConversationMessages(action) {
    let { _id, messageId, when } = action.payload;
    try {
        yield put({ type: loading.SET_NEXT_CONVERSTAION_MESSAGES_LOADING, payload: true });
        const response = yield call(lambdas.conversations.getConversationMessages, {}, {}, {}, { _id, messageId, when });
        if (response.data.code === 200) {
            yield put({
                type: conversations.GET_NEXT_CONVERSTAION_MESSAGES_SUCCESS,
                payload: { messages: response.data.data, _id, noMessage: response.data.data.length === 0, when },
            });
        } else {
            yield put({ type: conversations.GET_NEXT_CONVERSTAION_MESSAGES_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: conversations.GET_NEXT_CONVERSTAION_MESSAGES_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.SET_NEXT_CONVERSTAION_MESSAGES_LOADING, payload: false });
}

export function* getReplyConversationMessages(action) {
    let { _id, messageId } = action.payload;
    try {
        yield put({ type: loading.SET_REPLY_CONVERSTAION_MESSAGES_LOADING, payload: true });
        const response = yield call(lambdas.conversations.getConversationMessages, {}, {}, {}, { _id, messageId, when: 'middle' });
        if (response.data.code === 200) {
            const messages = response.data.data;
            let isLatest;
            if (response.data.data.length < 20 && response.data.data.length > 0) {
                const response2 = yield call(lambdas.conversations.getConversationMessages, {}, {}, {}, { _id, messageId: response.data.data[0]._id, when: 'before' });
                if (response2.data.code === 200) {
                    messages.unshift(...response2.data.data);
                    isLatest = true;
                }
            }
            yield put({
                type: conversations.GET_REPLY_CONVERSTAION_MESSAGES_SUCCESS,
                payload: { messages, _id, isLatest },
            });
            yield put({
                type: conversations.SET_OPEN_CONVERSTAION_MESSAGES_ISLATEST,
                payload: { isLatest: false, _id },
            });
        } else {
            yield put({ type: conversations.GET_REPLY_CONVERSTAION_MESSAGES_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: conversations.GET_REPLY_CONVERSTAION_MESSAGES_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.SET_REPLY_CONVERSTAION_MESSAGES_LOADING, payload: false });
}

export function* changeNotificationSettings(action) {
    let { _id, sendNotificationsFor } = action.payload;
    yield put({ type: loading.SET_CHANGE_NOTIFICATION_SETTINGS_LOADING, payload: true });
    try {
        const response = yield call(lambdas.conversations.modifyNotificationSettings, {}, { _id }, {}, { sendNotificationsFor });
        if (response.data.code === 200) {
            let message = 'Saved';
            yield put({
                type: toast.SEND_TOAST,
                payload: {
                    status: 'success',
                    message: message,
                },
            });
            yield put({ type: conversations.CHANGE_NOTIFICATION_SETTINGS_SUCCESS, payload: { sendNotificationsFor, _id } });
        } else {
            yield put({ type: conversations.CHANGE_NOTIFICATION_SETTINGS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: conversations.CHANGE_NOTIFICATION_SETTINGS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.SET_CHANGE_NOTIFICATION_SETTINGS_LOADING, payload: false });
}

export function* uploadFilesToChat(action) {
    let { _id, fileData, from, messageId, useConversationId = false, bot = false, isSupportTicket, isAddToQueue = true, isReplying, replyToMessage, imp_image = false, callParser, customer_id, auth_token, source, ticket_id, source_id, user_id } = action.payload;
    const files = new Array(...fileData.map((item) => item.file));
    const filesInRequestBody = fileData.map(({ file, caption, tags = [], height, width }) => ({
        caption,
        fileName: file.name,
        mimeType: file.type,
        tags: tags,
        type: getFileType(file.type),
        height,
        width
    }));
    const uploadedFiles = fileData.map(({ file, caption, tags = [], height, width }) => {
        return {
            mimeType: file.type,
            fileUrl: URL.createObjectURL(file),
            fileName: file.name,
            caption,
            type: getFileType(file.type),
            tags: tags,
            height,
            width
        };
    });
    let messageType;
    if (isReplying) {
        messageType = "reply"
    }
    else {
        messageType = "attachments";
    }
    try {
        if (!messageId) messageId = uuidv4();
        if (!['', undefined, null].includes(from) && isAddToQueue) {
            yield put({
                type: conversations.ADD_TO_MESSAGE_QUEUE,
                payload: { message: { type: messageType, attachments: uploadedFiles, messageId, conversation: _id, from, fileData, repliedTo: replyToMessage } },
            });
        }
        yield fork(checkSendMessageError, {
            conversationId: _id,
            messageId: messageId,
            waitForEvent: { success: conversations.CHAT_UPLOAD_FILES_SUCCESS, failure: conversations.CHAT_UPLOAD_FILES_FAILURE },
        });
        const response = yield call(lambdas.conversations.uploadFiles, {}, { _id }, {}, { filesData: filesInRequestBody, messageId, useConversationId, bot });
        if (response.data.code === 200) {
            yield* response.data.data.map(({ signedUrl, fileName }) => {
                const file = files.find((f) => f.name === fileName);
                return call(uploadFileToS3WithSignedUrl, signedUrl, file);
            });

            if (callParser && isSupportTicket) {
                yield* response.data.data.map(({ fileUrl, fileName }) => {
                    const body = {
                        "url": fileUrl,
                        "invoice_name": fileName,
                        customer_id,
                        auth_token,
                        source,
                        ticket_id,
                        source_id, user_id
                    }
                    return call(fileUploadOnChange, body);
                });
            };

            let requestBodyMessage = {
                type: messageType,
                messageId,
                attachments: response.data.data.map((item) => {
                    if (imp_image) {
                        return { ...item, imp_image }
                    }
                    return item;
                }),
            }
            if (isReplying) {
                requestBodyMessage.repliedTo = replyToMessage;
            }
            const requestBody = {
                useConversationId,
                bot,
                message: requestBodyMessage,
                isSupportTicket
            };
            const saveResponse = yield call(lambdas.conversations.saveAndBroadcastMessage, {}, { _id }, {}, requestBody);
            yield put({
                type: conversations.CHAT_UPLOAD_FILES_SUCCESS,
                payload: { _id: saveResponse.data.data.conversation._id, media: saveResponse.data.media },
            });
        } else {
            yield put({ type: conversations.CHAT_UPLOAD_FILES_FAILURE, payload: { message: 'Could not upload files' } });
        }
    } catch (error) {
        console.error(error);
        yield put({ type: conversations.CHAT_UPLOAD_FILES_FAILURE, payload: { message: error } });
    }
}

export function* leaveConversation(action) {
    let { _id, data, formName } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.conversations.leaveConversation, {}, { _id }, {}, data);
        if (response.data.code === 200) {
            yield put({
                type: conversations.LEAVE_CONVERSATION_SUCCESS,
                payload: { isConversationLeft: true, exitId: response.data.data.conversation._id },
            });
            yield put({ type: modal.CLOSE_MODAL });
        } else {
            yield put({ type: conversations.LEAVE_CONVERSATION_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: conversations.LEAVE_CONVERSATION_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* openChatInNewTab(action) {
    const { conversationId, buttonName } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName: buttonName, isLoading: true } });
        const conversationInfo = yield call(lambdas.conversations.getConversationInfo, {}, { _id: conversationId }, { query: '_id' });
        if (conversationInfo.data.code === 200) {
            const { _id } = conversationInfo.data.data;
            const loggedUser = yield select(getLoggedUser);
            const tabs = getChatMainTabs(loggedUser.role.id, loggedUser.type, { frontend_controller: loggedUser.frontend_controller, is_possible_tech_support: loggedUser.is_possible_tech_support });
            let tabParam = '';
            const ticketsTab = tabs?.find?.(tab => tab.tabID === ticketsTabMaster.tabID)
            if (ticketsTab) {
                tabParam = `?tab=${ticketsTab.subTabs[0].path}`;
            }
            window.open(`/chats/${_id}${tabParam}`, '_blank');
        } else {
            yield put({
                type: toast.SEND_TOAST,
                payload: {
                    status: 'danger',
                    message: conversationInfo.data.data?.message || 'Something went wrong',
                },
            });
        }
    } catch (error) {
        yield put({
            type: toast.SEND_TOAST,
            payload: {
                status: 'danger',
                message: 'Something Went Wrong',
            },
        });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName: buttonName, isLoading: false } });
}

export function* getConversationMedia(action) {
    let { conversationId, category, limit = 100, offset = 0 } = action.payload;
    try {
        let queryParams = { limit, offset, category };
        yield put({ type: loading.GET_CONVERSATION_MEDIA_LOADING, payload: true });
        const response = yield call(lambdas.conversations.getConversationMedia, {}, { _id: conversationId }, queryParams, {});

        if (response.data.code === 200) {
            yield put({ type: conversations.GET_CONVERSATION_MEDIA_SUCCESS, payload: { mediaList: response.data.data, _id: conversationId, category } });
        } else {
            yield put({ type: conversations.GET_CONVERSATION_MEDIA_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: conversations.GET_CONVERSATION_MEDIA_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.GET_CONVERSATION_MEDIA_LOADING, payload: false });
}

export function* getNextConversationMedia(action) {
    let { conversationId, limit = 100, offset, category } = action.payload;
    try {
        let queryParams = { limit, offset, category };
        yield put({ type: loading.GET_NEXT_CONVERSATION_MEDIA_LOADING, payload: true });
        const response = yield call(lambdas.conversations.getConversationMedia, {}, { _id: conversationId }, queryParams, {});

        if (response.data.code === 200) {
            yield put({ type: conversations.GET_NEXT_CONVERSATION_MEDIA_SUCCESS, payload: { mediaList: response.data.data, _id: conversationId, category } });
        } else {
            yield put({ type: conversations.GET_NEXT_CONVERSATION_MEDIA_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: conversations.GET_NEXT_CONVERSATION_MEDIA_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.GET_NEXT_CONVERSATION_MEDIA_LOADING, payload: false });
}

export function* tagProject(action) {
    let { conversationId, projectId, formName } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.conversations.tagProject, {}, {}, {}, { conversationId, projectId, property: 'TAG' });
        if (response.data.code === 200) {
            yield all([
                yield put({ type: conversations.TAG_PROJECT_SUCCESS, payload: { project: response.data.data.project, conversationId } }),
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({
                    type: toast.SEND_TOAST,
                    payload: {
                        status: 'success',
                        message: response.data.data.message,
                    },
                }),
            ]);
        } else {
            yield put({ type: conversations.TAG_PROJECT_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({
                type: toast.SEND_TOAST,
                payload: {
                    status: 'danger',
                    message: 'Something went wrong',
                },
            });
        }
    } catch (error) {
        yield put({ type: conversations.TAG_PROJECT_FAILURE, payload: { message: error } });
        yield put({ type: modal.CLOSE_MODAL });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* unTagProject(action) {
    let { conversationId, projectId, formName } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.conversations.tagProject, {}, {}, {}, { conversationId, projectId, property: 'UNTAG' });
        if (response.data.code === 200) {
            yield all([
                yield put({
                    type: toast.SEND_TOAST,
                    payload: {
                        status: 'success',
                        message: response.data.data.message,
                    },
                }),
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: conversations.UNTAG_PROJECT_SUCCESS, payload: { project: response.data.data.project, conversationId } }),
            ]);
        } else {
            yield put({ type: conversations.UNTAG_PROJECT_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({
                type: toast.SEND_TOAST,
                payload: {
                    status: 'danger',
                    message: 'Something went wrong',
                },
            });
        }
    } catch (error) {
        yield put({ type: conversations.UNTAG_PROJECT_FAILURE, payload: { message: error } });
        yield put({ type: modal.CLOSE_MODAL });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* updateConversationDetails(action) {
    let { conversationId, form, isImage } = action.payload;
    try {
        yield put({ type: loading.IS_UPDATE_CONVERSATION_LOADING, payload: true });
        let uploadedFile;
        if (isImage) {
            const filesData = [
                {
                    fileName: isImage.name,
                    mimeType: isImage.type,
                    type: getFileType(isImage.type),
                },
            ];
            const response = yield call(lambdas.conversations.uploadFiles, {}, { _id: conversationId }, {}, { filesData, messageId: uuidv4() });
            if (response.data.code === 200) {
                uploadedFile = response.data.data[0];
                yield call(uploadFileToS3WithSignedUrl, uploadedFile.signedUrl, isImage);
            }
        }

        let data = {
            ...form,
        };
        if (uploadedFile) data.conversationImage = uploadedFile.fileUrl;

        let updateDetailsResponse = yield call(lambdas.conversations.updateGroupChatDetail, {}, { _id: conversationId }, {}, data);
        if (updateDetailsResponse.data.code === 200) {
            let message = updateDetailsResponse.data.data.message;
            yield all([
                yield put({
                    type: toast.SEND_TOAST,
                    payload: {
                        status: 'success',
                        message: message ? message : 'Details Updated Successfully!!!',
                    },
                }),
                yield put({ type: conversations.UPDATE_CONVERSATION_DETAILS_SUCCESS, payload: updateDetailsResponse.data.data }),
            ]);
        } else {
            let message = updateDetailsResponse.data.data.message;
            yield put({ type: conversations.UPDATE_CONVERSATION_DETAILS_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({
                type: toast.SEND_TOAST,
                payload: {
                    status: 'danger',
                    message: message ? message : 'Something went wrong',
                },
            });
        }
    } catch (error) {
        yield put({ type: conversations.UPDATE_CONVERSATION_DETAILS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.IS_UPDATE_CONVERSATION_LOADING, payload: false });
}

export function* uploadConversationImage(action) {
    let { conversationId, form, isImage } = action.payload;
    try {
        yield put({ type: loading.IS_UPDATE_CONVERSATION_LOADING, payload: true });
        const filesData = [
            {
                fileName: isImage.name,
                mimeType: isImage.type,
                type: getFileType(isImage.type),
            },
        ];
        const response = yield call(lambdas.conversations.uploadFiles, {}, { _id: conversationId }, {}, { filesData, messageId: uuidv4() });
        if (response.data.code === 200) {
            let file = response.data.data[0];
            yield call(uploadFileToS3WithSignedUrl, file.signedUrl, isImage);
            let updateDetailsResponse = yield call(lambdas.conversations.updateGroupChatDetail, {}, { _id: conversationId }, {}, { ...form, conversationImage: file.fileUrl });
            if (updateDetailsResponse.data.code === 200) {
                yield all([
                    yield put({
                        type: toast.SEND_TOAST,
                        payload: {
                            status: 'success',
                            message: 'Details Updated Successfully!!!',
                        },
                    }),
                    yield put({ type: conversations.UPDATE_CONVERSATION_DETAILS_SUCCESS, payload: updateDetailsResponse.data.data }),
                ]);
            }
        } else {
            yield put({ type: conversations.UPDATE_CONVERSATION_DETAILS_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({
                type: toast.SEND_TOAST,
                payload: {
                    status: 'danger',
                    message: 'Something went wrong',
                },
            });
        }
    } catch (error) {
        yield put({ type: conversations.UPDATE_CONVERSATION_DETAILS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.IS_UPDATE_CONVERSATION_LOADING, payload: false });
}

export function* addMemberInConversation(action) {
    let { conversationId, userId, formName } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.conversations.addUserConversation, {}, { _id: conversationId }, {}, { userId });
        if (response.data.code === 200) {
            yield all([
                yield put({
                    type: toast.SEND_TOAST,
                    payload: {
                        status: 'success',
                        message: 'User Added Successfully!!!',
                    },
                }),
                yield put({
                    type: conversations.ADD_MEMBER_IN_CONVERSATION_SUCCESS,
                    payload: { newMember: response.data.newMember, conversationId },
                }),
                yield put({ type: modal.CLOSE_MODAL }),
            ]);
        } else {
            yield all([
                yield put({ type: conversations.ADD_MEMBER_IN_CONVERSATION_FAILURE, payload: { message: 'Oops Something went wrong' } }),
                yield put({
                    type: toast.SEND_TOAST,
                    payload: {
                        status: 'danger',
                        message: 'Something went wrong',
                    },
                }),
                yield put({ type: modal.CLOSE_MODAL }),
            ]);
        }
    } catch (error) {
        yield put({ type: conversations.ADD_MEMBER_IN_CONVERSATION_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* removeFromConversation(action) {
    let { conversationId, userId, teamId, formName } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.conversations.leaveConversation, {}, { _id: conversationId }, {}, { userId, team: teamId });
        if (response.data.code === 200) {
            yield all([
                yield put({
                    type: conversations.REMOVE_FROM_CONVERSATION_SUCCESS,
                    payload: { leftUserId: response.data.data.leftUser.userId, conversationId },
                }),
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({
                    type: toast.SEND_TOAST,
                    payload: {
                        status: 'success',
                        message: 'User Removed Successfully!!!',
                    },
                }),
            ]);
        } else {
            yield all([
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: conversations.REMOVE_FROM_CONVERSATION_FAILURE, payload: { message: 'Oops Something went wrong' } }),
                yield put({
                    type: toast.SEND_TOAST,
                    payload: {
                        status: 'danger',
                        message: response.data.data.message,
                    },
                }),
            ]);
        }
    } catch (error) {
        yield put({ type: conversations.REMOVE_FROM_CONVERSATION_FAILURE, payload: { message: error } });
        yield put({ type: modal.CLOSE_MODAL });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* addToMessageQueue(action) {
    yield put({ type: conversations.ADD_TO_MESSAGE_QUEUE_SUCCESS, payload: action.payload });
}

export function* grantOrRevokeAdminAccess(action) {
    let { conversationId, userId, access, formName, username, groupName } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.conversations.grantOrRevokeAdminAccess, {}, { _id: conversationId }, {}, { userId, action: access });
        if (response.data.code === 200) {
            let toastMsg = `${username} is now the admin of the ${groupName}`;
            if (access === 'revoke') toastMsg = `${username} removed as admin of the  ${groupName}`;
            yield all([
                yield put({ type: conversations.GRANT_OR_REVOKE_ACCESS_SUCCESS, payload: action.payload }),
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({
                    type: toast.SEND_TOAST,
                    payload: {
                        status: 'success',
                        message: toastMsg,
                    },
                }),
            ]);
        } else {
            yield all([
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({
                    type: toast.SEND_TOAST,
                    payload: {
                        status: 'danger',
                        message: response.data.data.message,
                    },
                }),
            ]);
        }
    } catch (error) {
        yield put({ type: modal.CLOSE_MODAL });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* createSingleChat(action) {
    let { userId, navigate } = action.payload;
    try {
        let data = {
            chats: [
                {
                    type: 'single',
                    users: [{ userId }],
                },
            ],
        };
        const response = yield call(lambdas.conversations.createChat, {}, {}, {}, data);
        if (response.data.code === 201) {
            let conversationId = response.data.data.createdChats[0]._id;
            navigate(`/chats/${conversationId}`);
        } else {
            yield put({ type: conversations.CREATE_SINGLE_CHAT_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({
                type: toast.SEND_TOAST,
                payload: {
                    status: 'danger',
                    message: 'falied',
                },
            });
        }
    } catch (error) {
        yield put({ type: conversations.CREATE_SINGLE_CHAT_FAILURE, payload: { message: error } });
    }
}

export function* getConversationInfo(action) {
    let { _id, queryParms } = action.payload;
    yield put({ type: loading.GET_CONVERSATION_INFO_LOADING, payload: true });
    try {
        const response = yield call(lambdas.conversations.getConversationInfo, {}, { _id: _id }, { query: queryParms });
        if (response.data.code === 200) {
            yield put({ type: conversations.GET_CONVERSATION_INFO_SUCCESS, payload: action.payload });
        } else {
            yield put({ type: conversations.GET_CONVERSATION_INFO_FAILURE, payload: 'Something went wrong' });
        }
    } catch (error) {
        yield put({ type: conversations.GET_CONVERSATION_INFO_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.GET_CONVERSATION_INFO_LOADING, payload: false });
}

export function* openSingleChat(action) {
    const { userId, chatId, openInNewTab, navigate, hideList, formName = false } = action.payload;
    try {
        if (formName) {
            yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        } else {
            yield put({ type: loading.OPEN_SINGLE_CHAT_LOADING, payload: true });
        }
        let _id = chatId;
        if (!chatId) {
            const loggedUser = yield select(getLoggedUser);
            const singleConversationId = getSingleConvoIdFromUserIds(loggedUser?.id, userId);
            const response = yield call(lambdas.conversations.getConversationInfo, {}, { _id: singleConversationId }, { query: '_id' });
            if (response.data.code === 200) {
                _id = response.data.data._id;
            } else if (response.data.code === 404) {
                let singleChatPayload = { chats: [{ type: 'single', users: [{ userId }] }] };
                const createdConversationResponse = yield call(lambdas.conversations.createChat, {}, {}, {}, singleChatPayload);
                if (createdConversationResponse.data.code === 201) {
                    _id = createdConversationResponse.data.data.createdChats[0]._id;
                }
            }
        }
        if (_id) {
            let url = `/chats/${_id}`;
            if (hideList) url += `hideList=${hideList}`;
            if (openInNewTab) {
                window.open(url, '_blank');
            } else {
                navigate(url);
            }
        } else {
            yield put({ type: conversations.OPEN_SINGLE_CHAT_FAILURE, payload: 'Something went wrong' });
        }
    } catch (error) {
        yield put({ type: conversations.OPEN_SINGLE_CHAT_FAILURE, payload: 'Something went wrong' });
    }

    if (formName) {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
    } else {
        yield put({ type: loading.OPEN_SINGLE_CHAT_LOADING, payload: true });
    }
    yield put({ type: loading.OPEN_SINGLE_CHAT_LOADING, payload: false });
}

export function* getSingleChatUserInfoDetails(action) {
    const { userId } = action.payload;
    try {
        yield put({ type: loading.GET_SINGLE_CHAT_USER_DETAILS_LOADING, payload: true });
        const response = yield call(api.auth.getUser, {}, { userId }, {}, {});
        if (response.data.code === 200) {
            yield put({ type: conversations.GET_SINGLE_CHAT_USER_INFO_DETAILS_SUCCESS, payload: { userId, userDetails: response.data.data.user } });
        } else {
            yield put({ type: conversations.GET_SINGLE_CHAT_USER_INFO_DETAILS_FAILURE, payload: { message: 'Oops Something went wrong', userId } });
        }
    } catch (error) {
        yield put({ type: conversations.GET_SINGLE_CHAT_USER_INFO_DETAILS_FAILURE, payload: { message: error, userId } });
    }
    yield put({ type: loading.GET_SINGLE_CHAT_USER_DETAILS_LOADING, payload: false });
}

export function* getSingleChatCustomerUserDetails(action) {
    const { userId } = action.payload;
    const queryparams = {
        giveTeams: true,
    };
    try {
        yield put({ type: loading.GET_SINGLE_CHAT_USER_DETAILS_LOADING, payload: true });
        const response = yield call(lambdas.user.getUserDetails, {}, { userId }, queryparams, {});
        if (response.data.code === 200) {
            const userDetails = response.data.data;
            yield put({ type: conversations.GET_SINGLE_CHAT_CUSTOMER_DETAILS_SUCCESS, payload: { userId, userDetails } });
        } else {
            yield put({ type: conversations.GET_SINGLE_CHAT_CUSTOMER_DETAILS_FAILURE, payload: { message: 'Oops Something went wrong', userId } });
        }
    } catch (error) {
        yield put({ type: conversations.GET_SINGLE_CHAT_CUSTOMER_DETAILS_FAILURE, payload: { message: error, userId } });
    }
    yield put({ type: loading.GET_SINGLE_CHAT_USER_DETAILS_LOADING, payload: false });
}

export function* getSingleChatSPProfileDetails(action) {
    const { userId } = action.payload;
    try {
        yield put({ type: loading.GET_SINGLE_CHAT_USER_DETAILS_LOADING, payload: true });
        const response = yield call(api.SP.getProfile, {}, { id: userId });
        if (response.data.code === 200) {
            yield put({
                type: conversations.GET_SINGLE_CHAT_SP_USER_DETAILS_SUCCESS,
                payload: { userId, userDetails: response.data.data.company_detail },
            });
        } else {
            yield put({ type: conversations.GET_SINGLE_CHAT_SP_USER_DETAILS_FAILURE, payload: { message: response.data.message, userId } });
        }
    } catch (error) {
        yield put({ type: conversations.GET_SINGLE_CHAT_SP_USER_DETAILS_FAILURE, payload: { message: error, userId } });
    }
    yield put({ type: loading.GET_SINGLE_CHAT_USER_DETAILS_LOADING, payload: false });
}

export function* getSingleChatFEProfileDetails(action) {
    const { fieldEngineerId } = action.payload;
    try {
        yield put({ type: loading.GET_SINGLE_CHAT_USER_DETAILS_LOADING, payload: true });
        const response = yield call(api.FE.getProfile, {}, { id: fieldEngineerId });
        if (response.data.code === 200) {
            yield put({
                type: conversations.GET_SINGLE_CHAT_FE_USER_DETAILS_SUCCESS,
                payload: { userId: fieldEngineerId, userDetails: response.data.data.engineer_detail },
            });
        } else {
            yield put({
                type: conversations.GET_SINGLE_CHAT_FE_USER_DETAILS_FAILURE,
                payload: { message: response.data.message, userId: fieldEngineerId },
            });
        }
    } catch (error) {
        yield put({ type: conversations.GET_SINGLE_CHAT_FE_USER_DETAILS_FAILURE, payload: { message: error, userId: fieldEngineerId } });
    }
    yield put({ type: loading.GET_SINGLE_CHAT_USER_DETAILS_LOADING, payload: false });
}

export function* addMembersToChat(action) {
    const { userIds, teamIds, _id, onSuccess = () => { } } = action.payload;
    try {
        yield put({ type: loading.ADD_MEMBERS_OR_TEAMS_TO_CHAT_LOADING, payload: true });
        const response = yield call(lambdas.conversations.addMembers, {}, { _id }, {}, { userIds, teams: teamIds });
        if (response.data.code === 200) {
            yield put({
                type: conversations.ADD_MEMBERS_OR_TEAMS_TO_CHAT_SUCCESS,
                payload: { _id, newMembers: response.data.data.newMembers },
            });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Success' } });
            onSuccess();
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data?.data?.message || 'Something went wrong!' } });
            yield put({
                type: conversations.ADD_MEMBERS_OR_TEAMS_TO_CHAT_FAILURE,
                payload: { message: response.data.message },
            });
        }
    } catch (error) {
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong!' } });
        console.error(error);
        yield put({ type: conversations.ADD_MEMBERS_OR_TEAMS_TO_CHAT_FAILURE, payload: error });
    }
    yield put({ type: loading.ADD_MEMBERS_OR_TEAMS_TO_CHAT_LOADING, payload: false });
}

export function* getTicketChats(action) {
    let { limit, offset, query, tags, toAppend, atOnly = false } = action.payload;
    let queryParams = { limit, offset, type: 'ticket', source: 'newUI' };
    if (query) queryParams.query = query;
    if (atOnly) queryParams.atOnly = true;
    if (tags && tags?.length > 0) queryParams.tags = Array.isArray(tags) ? tags.join(',') : tags;
    if (toAppend) {
        yield put({ type: loading.SET_NEXT_CONVERSATIONS_LOADING, payload: true });
    } else {
        yield put({ type: loading.SET_CHAT_LIST_LOADING, payload: true });
    }
    try {
        const response = yield call(lambdas.conversations.getConversations, {}, {}, queryParams);
        if (response.data.code === 200) {
            yield put({ type: conversations.GET_CONVERSATIONS_SUCCESS, payload: { chatsList: response.data.data, toAppend } });
        } else {
            yield put({ type: conversations.GET_CONVERSATIONS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: conversations.GET_CONVERSATIONS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.SET_CHAT_LIST_LOADING, payload: false });
    yield put({ type: loading.SET_NEXT_CONVERSATIONS_LOADING, payload: false });
}

export function* getConversationCount(action) {
    const { type } = action.payload;
    try {
        const response = yield call(lambdas.conversations.getConversationCount, {}, {}, { type });
        if (response.data.code === 200) {
            yield put({ type: conversations.GET_CONVERSATION_COUNT_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: conversations.GET_CONVERSATION_COUNT_FAILED, payload: { message: 'Something Went Wrong' } });
        }
    } catch (error) {
        yield put({ type: conversations.GET_CONVERSATION_COUNT_FAILED, payload: { message: error } });
    }
}

export function* approveOrRejectImage(action) {
    const { _id, messageId, attachmentId, isApproved, toInclude, callStepFields = false } = action.payload;
    yield put({ type: loading.APPROVE_OR_REJECT_MEDIA_LOADING, payload: { attachmentId } });

    try {
        const response = yield call(lambdas.conversations.approveOrRejectImage, {}, { _id }, {}, { messageId, attachmentId, isApproved, toInclude });
        if (response.data.code === 200) {
            const successPayload = { conversationId: _id, messageId, attachment: response.data.data.attachment };
            yield put({ type: conversations.APPROVE_OR_REJECT_IMAGE_SUCCESS, payload: successPayload });
            if (callStepFields) {
                const ticket = yield select(getViewTicket);
                const data = { ticket_id: ticket.id, is_checklist: true, template_id: ticket.fk_template_id };
                yield put({ type: tickets.GET_WORK_IN_PROGRESS_STEPS_FIELDS_REQUEST, payload: { data, source: 'chat_header' } });
            }
        } else {
            yield put({ type: conversations.APPROVE_OR_REJECT_IMAGE_FAILURE, payload: { message: 'Something Went Wrong' } });
        }
    } catch (error) {
        yield put({ type: conversations.APPROVE_OR_REJECT_IMAGE_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.APPROVE_OR_REJECT_MEDIA_LOADING, payload: { remove: true, attachmentId } });
}

export function* approveOrRejectCardMessage(action) {
    const { _id, messageId, isApproved } = action.payload;
    yield put({ type: loading.APPROVE_OR_REJECT_CARD_MESSAGE_LOADING, payload: { messageId } });
    try {
        const response = yield call(lambdas.conversations.approveOrRejectImage, {}, { _id }, {}, { messageId, isApproved });
        if (response.data.code === 200) {
            const successPayload = { conversationId: _id, messageId, promptResponses: response.data.data.promptResponses };
            yield put({ type: conversations.APPROVE_OR_REJECT_CARD_MESSAGE_SUCCESS, payload: successPayload });
        }
        else {
            yield put({ type: conversations.APPROVE_OR_REJECT_CARD_MESSAGE_FAILURE, payload: { message: 'Something Went Wrong' } });
        }
    } catch (error) {
        yield put({ type: conversations.APPROVE_OR_REJECT_CARD_MESSAGE_FAILURE, payload: { message: error } });

    }
    yield put({ type: loading.APPROVE_OR_REJECT_CARD_MESSAGE_LOADING, payload: { remove: true, messageId } });
}

export function* getMediaForSidebarMedia(action) {
    let { conversationId, category, limit = 20, offset = 0 } = action.payload;
    try {
        let queryParams = { limit, offset, category };
        yield put({ type: loading.LIST_CONVERSATION_SIDEBAR_MEDIA_LOADING, payload: true });
        const response = yield call(lambdas.conversations.getConversationMedia, {}, { _id: conversationId }, queryParams, {});

        if (response.data.code === 200) {
            yield put({ type: conversations.LIST_CONVERSATION_SIDEBAR_MEDIA_SUCCESS, payload: { mediaList: response.data.data, _id: conversationId } });
        } else {
            yield put({ type: conversations.LIST_CONVERSATION_SIDEBAR_MEDIA_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: conversations.LIST_CONVERSATION_SIDEBAR_MEDIA_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.LIST_CONVERSATION_SIDEBAR_MEDIA_LOADING, payload: false });
}
export function* getChatNotifications(action) {
    const { onlyUnread, offset, toAppend } = action.payload;
    if (toAppend) {
        yield put({ type: loading.SET_NEXT_CONVERSATIONS_LOADING, payload: true });
    } else {
        yield put({ type: loading.SET_CHAT_NOTIFICATIONS_LOADING, payload: true });
    }
    try {
        const response = yield call(lambdas.notifications.getChatNotifications, {}, {}, { onlyUnread, offset });
        if (response.data.code === 200) {
            yield put({ type: conversations.GET_CHAT_NOTIFICATIONS_SUCCESS, payload: { notifications: response.data.data, toAppend } });
        } else {
            yield put({ type: conversations.GET_CHAT_NOTIFICATIONS_FAILURE, payload: { message: 'Something Went Wrong' } });
        }
    } catch (error) {
        yield put({ type: conversations.GET_CHAT_NOTIFICATIONS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.SET_NEXT_CONVERSATIONS_LOADING, payload: false });
    yield put({ type: loading.SET_CHAT_NOTIFICATIONS_LOADING, payload: false });
}

export function* getChatNotificationsCount() {
    try {
        const response = yield call(lambdas.notifications.countChatNotifications);
        if (response.data.code === 200) {
            yield put({ type: user.GET_COUNT_SUCCESS, payload: { [chatSidebarTab.countKey]: response.data.data.count } });
        } else {
            yield put({ type: user.GET_COUNT_FAILURE, payload: { message: 'Something Went Wrong' } });
        }
    } catch (error) {
        yield put({ type: user.GET_COUNT_FAILURE, payload: { message: error } });
    }
}

export function* markNotificationsRead(action) {
    const { notificationId, onSuccess = () => { } } = action.payload;
    try {
        const response = yield call(lambdas.notifications.markReadChatNotifications, {}, { notificationId });
        if (response.data.code === 200) {
            yield put({ type: conversations.MARK_NOTIFICATIONS_READ_SUCCESS, payload: { newCount: response.data.data.newCount, notificationId } });
            onSuccess();
        } else {
            yield put({ type: conversations.MARK_NOTIFICATIONS_READ_FAILURE, payload: { message: 'Something Went Wrong' } });
        }
    } catch (error) {
        yield put({ type: conversations.MARK_NOTIFICATIONS_READ_FAILURE, payload: { message: error } });
    }
}

export function* getCustomerReportMedia(action) {
    let { conversationId, category, limit = 100, offset = 0 } = action.payload;
    try {
        let queryParams = { limit, offset, category };
        yield put({ type: loading.LIST_TICKET_CUSTOMER_REPORT_MEDIA_LOADING, payload: true });
        const response = yield call(lambdas.conversations.getConversationMedia, {}, { _id: conversationId }, queryParams, {});

        if (response.data.code === 200) {
            yield put({ type: conversations.LIST_TICKET_CUSTOMER_REPORT_MEDIA_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: conversations.LIST_TICKET_CUSTOMER_REPORT_MEDIA_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: conversations.LIST_TICKET_CUSTOMER_REPORT_MEDIA_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.LIST_TICKET_CUSTOMER_REPORT_MEDIA_LOADING, payload: false });
}

export function* downloadCustomerReport(action) {
    const { ticketId, attachmentIds = [], isTicketDetails, formName } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.conversations.downloadCustomerReport, {}, {}, { ticketId, attachmentIds: attachmentIds.join(','), isTicketDetails });

        if (response.data.code === 200) {
            const { fileUrl, fileName, mimeType } = response.data.data;
            yield put({ type: utils.DOWNLOAD_REMOTE_DATA_REQUEST, payload: { url: fileUrl, fileName, mimeType } });
        } else {
            yield put({
                type: toast.SEND_TOAST,
                payload: {
                    status: 'danger',
                    message: response.data.code === 404 ? response.data.data.message : 'Something went wrong!',
                },
            });
            yield put({ type: conversations.DOWNLOAD_CUSTOMER_REPORT_FAILURE, payload: { message: 'Something went wrong!' } });
        }
        yield put({ type: modal.CLOSE_MODAL });
    } catch (error) {
        yield put({ type: conversations.DOWNLOAD_CUSTOMER_REPORT_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}


export function* getSearchConversationMessages(action) {
    let { _id, query, limit, offset, count = false } = action.payload;
    let queryParams = { limit, offset, query, giveCount: count };

    try {
        yield put({ type: loading.SET_SEARCH_CONVERSTAION_MESSAGES_LOADING, payload: true });
        const response = yield call(lambdas.conversations.getConversationMessages, {}, {}, queryParams, { _id });

        if (response.data.code === 200) {
            yield put({ type: conversations.GET_SEARCH_CONVERSTAION_MESSAGES_SUCCESS, payload: { messages: response.data.data, _id } });
            if (count) {
                yield put({
                    type: conversations.SET_SEARCH_CONVERSATION_UNREADABLE_MESSAGE_COUNT,
                    payload: response.data.count
                });
            }
        } else {
            yield put({ type: conversations.GET_SEARCH_CONVERSTAION_MESSAGES_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: conversations.GET_SEARCH_CONVERSTAION_MESSAGES_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.SET_SEARCH_CONVERSTAION_MESSAGES_LOADING, payload: false });
}

export function* getGptConversations(action) {
    let { limit, offset, query, toAppend } = action.payload;
    let queryParams = { limit, offset, type: 'gpt', };
    if (query) queryParams.query = query;
    if (toAppend) {
        yield put({ type: loading.SET_NEXT_GPT_LIST_LOADING, payload: true });
    } else {
        yield put({ type: loading.SET_GPT_LIST_LOADING, payload: true });
    }
    try {
        const response = yield call(lambdas.conversations.getConversations, {}, {}, queryParams);
        if (response.data.code === 200) {
            yield delay(2000);
            yield put({ type: conversations.GET_GPT_CONVERSATIONS_SUCCESS, payload: { list: response.data.data, toAppend } });
        } else {
            yield put({ type: conversations.GET_GPT_CONVERSATIONS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: conversations.GET_GPT_CONVERSATIONS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.SET_GPT_LIST_LOADING, payload: false });
    yield put({ type: loading.SET_NEXT_GPT_LIST_LOADING, payload: false });
};

export function* sendScogoGptMessage(action) {
    let { conversationId, userInput } = action.payload;
    try {
        const loggedUser = yield select(getLoggedUser);
        const from = {
            email: loggedUser.email,
            role: loggedUser.type,
            roleId: loggedUser.role.id,
            userId: loggedUser.id,
            username: `${loggedUser.first_name} ${loggedUser.last_name}`,
        };
        const messageId = uuidv4();

        yield put({ type: conversations.PUT_TO_CONVERSATIONS_STORE, payload: { openedConversationId: conversationId } });
        yield put({ type: conversations.ADD_TO_MESSAGE_QUEUE, payload: { message: { message: userInput, messageId, from, conversation: conversationId } } });
        const data = { conversationId, userInput, messageId };
        if (conversationId === scogo_new_chat_created) {
            data.conversationId = '';
        };
        const response = yield call(lambdas.conversations.sendScogoGptMessage, {}, {}, {}, data);
        if (response.data.code === 200) {
            yield put({ type: conversations.SEND_SCOGO_GPT_MESSAGE_SUCCESS, payload: { conversationId: response.data.data.conversationId } });
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong!!!' } });
            yield put({ type: conversations.SEND_SCOGO_GPT_MESSAGE_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: conversations.SEND_SCOGO_GPT_MESSAGE_FAILURE, payload: { message: error } });
    }
};

export function* notifyEndCustomer(action) {
    const { template, mobile, customerId, formName, ticketId, userId } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.conversations.notifyViaWhatsappMessage, {}, {}, {}, { template, mobile, customerId, ticketId, userId });

        if (response.data.code === 200) {
            yield all([
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: response.data.data.message ? response.data.data.message : 'Success' } })
            ])
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message ? response.data.data.message : 'Something went wrong!!!' } });
        }
    } catch (error) {
        yield put({ type: conversations.NOTIFY_END_CUSTOMER_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* uploadOnChange(action) {
    let { _id, fileData, messageId, useConversationId = false, bot = false,
        customer_id, source, ticket_id, source_id, auth_token, user_id
    } = action.payload;
    const files = new Array(...fileData.map((item) => item.file));
    const filesInRequestBody = fileData.map(({ file, caption, tags = [], height, width }) => ({
        caption,
        fileName: file.name,
        mimeType: file.type,
        tags: tags,
        type: getFileType(file.type),
        height,
        width
    }));

    try {
        if (!messageId) messageId = uuidv4();
        const response = yield call(lambdas.conversations.uploadFiles, {}, { _id }, {}, { filesData: filesInRequestBody, messageId, useConversationId, bot });
        if (response.data.code === 200) {
            yield* response.data.data.map(({ signedUrl, fileName }) => {
                const file = files.find((f) => f.name === fileName);
                return call(uploadFileToS3WithSignedUrl, signedUrl, file);
            });

            yield* response.data.data.map(({ fileUrl, fileName }) => {
                const body = {
                    "url": fileUrl,
                    "invoice_name": fileName,
                    customer_id,
                    auth_token,
                    source,
                    ticket_id,
                    source_id, user_id
                }
                return call(fileUploadOnChange, body);
            });
        } else {

        }
    } catch (error) {
        console.error(error);
    }
}
export function* openChatElement(action) {
    let { conversationId, buttonName, ticket, source, userId, isRightClick = false } = action.payload;
    try {
        if (source === 'teams' && userId) {
            const loggedUser = yield select(getLoggedUser);
            conversationId = getSingleConvoIdFromUserIds(loggedUser?.id, userId);
        }
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName: buttonName, isLoading: true } });
        let conversationInfo;

        if (source !== 'first_load') { // already have conversationInfo id stored in localstorage, so don't need to call this api
            conversationInfo = yield call(lambdas.conversations.getConversationInfo, {}, { _id: conversationId }, { query: '_id' });
        }
        if (source === 'first_load' || (source !== 'first_load' && conversationInfo.data.code === 200)) {
            let _id;
            if (conversationInfo) _id = conversationInfo.data.data._id;
            if (source === 'first_load') _id = conversationId;

            const conversationsReducer = yield select((state) => state.conversations);
            const { conversationMessages, conversationDetails } = conversationsReducer;
            if (conversationMessages && !conversationMessages[_id]) {
                yield put({ type: conversations.GET_CONVERSTAION_MESSAGES_REQUEST, payload: { _id, offset: 0, limit: 20 } });
            }
            if (conversationDetails && !conversationDetails[_id]) {
                yield put({ type: conversations.GET_CONVERSTAION_DETAILS_REQUEST, payload: { _id } });
                yield take([conversations.GET_CONVERSTAION_DETAILS_SUCCESS, conversations.GET_CONVERSTAION_DETAILS_FAILURE]);
                yield delay(1000);
            }
            const updatedConversationReducer = yield select((state) => state.conversations);

            if (!ticket && updatedConversationReducer.conversationDetails && updatedConversationReducer.conversationDetails[_id].type === chatType.ticket) {
                const ticket_id = updatedConversationReducer.conversationDetails[_id].conversationId;
                const ticketResponse = yield call(api.tickets.viewTicket, {}, {}, { ticket_id: ticket_id });
                const ticketDetails = yield call(microservices.tickets.ticketAssets, {}, {}, {}, { ticketIds: [ticket_id] });
                if (ticketResponse.data.code === 200 && ticketDetails.data.code === 200) {
                    ticket = transformTicketforChatAction(ticketResponse.data.data);
                    if (Array.isArray(ticketDetails.data.data.templates) && ticketDetails.data.data.templates.length > 0) {
                        let findTemplate = ticketDetails.data.data.templates.find((temp) => temp.id === ticket.fk_template_id);
                        if (findTemplate) ticket.has_checklist = findTemplate.has_checklist;
                    }
                }
            }
            if (isRightClick && updatedConversationReducer?.conversationDetails?.[_id]) {
                let url = `/chats/${updatedConversationReducer?.conversationDetails?.[_id]?._id}`;
                if (ticket) url = `${url}?tab=${allTicketsTab.path}`
                window.open(url, '_blank');
            } else {
                yield put({ type: conversations.OPEN_CHAT_ELEMENT_SUCCESS, payload: { _id, status: true, ticket, conversationName: updatedConversationReducer?.conversationDetails?.[_id]?.conversationName } });
            }
        } else {
            yield put({
                type: toast.SEND_TOAST,
                payload: {
                    status: 'danger',
                    message: conversationInfo.data.data?.message || 'Something went wrong',
                },
            });
        }
    } catch (error) {
        yield put({
            type: toast.SEND_TOAST,
            payload: {
                status: 'danger',
                message: 'Something Went Wrong',
            },
        });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName: buttonName, isLoading: false } });
}

export function* getConversationIdandConversationDetails(action) {
    const { ticketId, buttonName, onSuccess } = action.payload;
    try {
        let queryParams = { skipMedia: true, isScogoConnect: true };
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName: buttonName, isLoading: true } });
        const response = yield call(lambdas.conversations.getConversationInfo, {}, { _id: ticketId }, { query: '_id' });
        if (response.data.code === 200) {
            const { _id } = response.data.data;
            const conversationDetailsResponse = yield call(lambdas.conversations.getConversationDetails, {}, { _id }, queryParams);
            if (conversationDetailsResponse.data.code === 200) {
                yield put({ type: conversations.GET_CONVERSATIONID_AND_CONVERSATION_DETAILS_SUCCESS, payload: { conversationDetail: conversationDetailsResponse.data.data } });
                onSuccess(_id);
            }
        }
        else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response?.data?.data?.message || 'Something Went Wrong' } });
        }
    } catch (error) {
        console.error(error);
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong!' } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName: buttonName, isLoading: false } });
}

export function* openActiveCall(action) {
    const { ticketId, room_id, singleConvoId, buttonName } = action.payload;
    try {
        if (buttonName) yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName: buttonName, isLoading: true } });
        let response;
        if (singleConvoId) {
            response = yield call(lambdas.conversations.getConversationInfo, {}, { _id: singleConvoId }, { query: '_id' });
        }
        else if (ticketId) {
            response = yield call(lambdas.conversations.getConversationInfo, {}, { _id: ticketId }, { query: '_id' });
        }
        if (response.data.code === 200) {
            const { _id } = response.data.data;
            window.open(generateRoomLink(room_id, _id), "_blank");
        }
        else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response?.data?.data?.message || 'Something Went Wrong' } });
        }
    } catch (error) {
        console.error(error);
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong!' } });
    }
    if (buttonName) yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName: buttonName, isLoading: false } });
}

export function* joinRoom(action) {
    const { userId, room_id, ticketId, openedConversationId, singleConvoId, onSuccess = () => { } } = action.payload;
    try {
        console.log(singleConvoId, ticketId);
        let conversationId;
        if (openedConversationId) {
            conversationId = openedConversationId;
        }
        else if (ticketId) {
            const conversation = yield call(lambdas.conversations.getConversationInfo, {}, { _id: ticketId }, { query: '_id' });
            conversationId = conversation?.data?.data?._id;
        }
        else if (singleConvoId) {
            const conversation = yield call(lambdas.conversations.getConversationInfo, {}, { _id: singleConvoId }, { query: '_id' });
            conversationId = conversation?.data?.data?._id;
        }
        const response = yield call(microservices.user.roomActions, {}, { userId: userId }, {}, { roomId: room_id, action: 2, conversationId });
        if (response.data.code === 200) {
            onSuccess();
        }
    } catch (error) {
        console.error(error);
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong!' } });
    }
}

export function* leaveRoom(action) {
    const { userId, room_id, ticketId, openedConversationId, onSuccess = () => { } } = action.payload;
    try {
        let conversationId;
        if (openedConversationId) {
            conversationId = openedConversationId;
        }
        else if (ticketId) {
            const conversation = yield call(lambdas.conversations.getConversationInfo, {}, { _id: ticketId }, { query: '_id' });
            conversationId = conversation?.data?.data?._id;
        }
        const response = yield call(microservices.user.roomActions, {}, { userId: userId }, {}, { roomId: room_id, action: 0, conversationId });
        if (response.data.code === 200) {
            onSuccess();
        }
    } catch (error) {
        console.error(error);
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong!' } });
    }
}

export function* holdRoom(action) {
    const { userId, room_id, ticketId, openedConversationId, singleConvoId, onSuccess = () => { }, buttonName } = action.payload;
    try {
        if (buttonName) yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName: buttonName, isLoading: true } });
        let conversationId;
        if (openedConversationId) {
            conversationId = openedConversationId;
        }
        else if (ticketId) {
            const conversation = yield call(lambdas.conversations.getConversationInfo, {}, { _id: ticketId }, { query: '_id' });
            conversationId = conversation?.data?.data?._id;
        }
        else if (singleConvoId) {
            const conversation = yield call(lambdas.conversations.getConversationInfo, {}, { _id: singleConvoId }, { query: '_id' });
            conversationId = conversation?.data?.data?._id;
        }
        const response = yield call(microservices.user.roomActions, {}, { userId: userId }, {}, { roomId: room_id, action: 3, conversationId });
        if (response.data.code === 200) {
            onSuccess();
        }
    } catch (error) {
        console.error(error);
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong!' } });
    }
    if (buttonName) yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName: buttonName, isLoading: false } });
}

export function* declineRoom(action) {
    const { userId, room_id, ticketId, openedConversationId, onSuccess = () => { } } = action.payload;
    try {
        let conversationId;
        if (openedConversationId) {
            conversationId = openedConversationId;
        }
        else if (ticketId) {
            const conversation = yield call(lambdas.conversations.getConversationInfo, {}, { _id: ticketId }, { query: '_id' });
            conversationId = conversation?.data?.data?._id;
        }
        const response = yield call(microservices.user.roomActions, {}, { userId: userId }, {}, { roomId: room_id, action: 5, conversationId });
        if (response.data.code === 200) {
            onSuccess();
        }
    } catch (error) {
        console.error(error);
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong!' } });
    }
}

export function* clearMissRoom(action) {
    const { userId, room_id, onSuccess = () => { }, buttonName } = action.payload;
    try {
        if (buttonName) yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName: buttonName, isLoading: true } });
        const response = yield call(microservices.user.roomActions, {}, { userId: userId }, {}, { roomId: room_id, action: 0 });
        if (response.data.code === 200) {
            onSuccess();
        }
    } catch (error) {
        console.error(error);
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong!' } });
    }
    if (buttonName) yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName: buttonName, isLoading: false } });
}

export function* getAttachmentWithPartialUrl(action) {
    let { partialFileUrl, limit = 1, offset = 0, conversationId } = action.payload;
    try {
        let queryParams = { limit, offset, partialFileUrl };
        yield put({ type: loading.SET_ATTACHMENT_WITH_PARTIAL_URL_LOADING, payload: { partialFileUrl, isLoading: true } });
        const response = yield call(lambdas.conversations.getConversationMedia, {}, { _id: conversationId }, queryParams, {});

        if (response.data.code === 200) {
            yield put({ type: conversations.GET_ATTACHMENT_WITH_PARTIAL_URL_SUCCESS, payload: { attachment: response.data.data[0], partialFileUrl } });
        } else {
            yield put({ type: conversations.GET_CONVERSATION_MEDIA_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: conversations.GET_CONVERSATION_MEDIA_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.SET_ATTACHMENT_WITH_PARTIAL_URL_LOADING, payload: { partialFileUrl, isLoading: false } });
}

export default function* conversationsSaga() {
    yield takeLatest(conversations.GET_GROUP_CONVERSATIONS_REQUEST, getGroupChats);
    yield takeLatest(conversations.GET_HELP_CONVERSATIONS_REQUEST, getHelpChats);
    yield takeLatest(conversations.GET_TICKET_CONVERSATIONS_REQUEST, getTicketChats);
    yield takeLatest(conversations.GET_CONVERSATION_COUNT_REQUEST, getConversationCount);
    yield takeEvery(conversations.GET_REPLY_CONVERSTAION_MESSAGES_REQUEST, getReplyConversationMessages);
    yield takeLatest(conversations.GET_NEXT_HELP_CONVERSATIONS_REQUEST, getNextHelpChats);
    yield takeEvery(conversations.CREATE_HELP_SUPPORT_REQUEST, createSupport);
    yield takeEvery(conversations.CLOSE_CONVERSATION_REQUEST, closeHelpSupport);
    yield takeLatest(conversations.GET_NEXT_GROUP_CONVERSATIONS_REQUEST, getNextGroupChats);
    yield takeEvery(conversations.GET_CONVERSTAION_DETAILS_REQUEST, getConversationDetails);
    yield takeEvery(conversations.CREATE_GROUP_CONVERSATION_REQUEST, createGroupChat);
    yield takeEvery(conversations.GET_CONVERSTAION_MESSAGES_REQUEST, getConversationMessages);
    yield takeEvery(conversations.GET_NEXT_CONVERSTAION_MESSAGES_REQUEST, getNextConversationMessages);
    yield takeEvery(conversations.CHANGE_NOTIFICATION_SETTINGS_REQUEST, changeNotificationSettings);
    yield takeEvery(conversations.CHAT_UPLOAD_FILES_REQUEST, uploadFilesToChat);
    yield takeEvery(conversations.LEAVE_CONVERSATION_REQUEST, leaveConversation);
    yield takeEvery(conversations.OPEN_CHAT_IN_NEW_TAB, openChatInNewTab);
    yield takeLatest(conversations.GET_CONVERSATION_MEDIA_REQUEST, getConversationMedia);
    yield takeEvery(conversations.GET_NEXT_CONVERSATION_MEDIA_REQUEST, getNextConversationMedia);
    yield takeEvery(conversations.TAG_PROJECT_REQUEST, tagProject);
    yield takeEvery(conversations.UNTAG_PROJECT_REQUEST, unTagProject);
    yield takeEvery(conversations.UPDATE_CONVERSATION_DETAILS_REQUEST, updateConversationDetails);
    yield takeEvery(conversations.ADD_MEMBER_IN_CONVERSATION_REQUEST, addMemberInConversation);
    yield takeEvery(conversations.REMOVE_FROM_CONVERSATION_REQUEST, removeFromConversation);
    yield takeEvery(conversations.ADD_TO_MESSAGE_QUEUE, addToMessageQueue);
    yield takeEvery(conversations.GRANT_OR_REVOKE_ACCESS_REQUEST, grantOrRevokeAdminAccess);
    yield takeLatest(conversations.GET_CONVERSATION_INFO_REQUEST, getConversationInfo);
    yield takeEvery(conversations.CREATE_SINGLE_CHAT_REQUEST, createSingleChat);
    yield takeLatest(conversations.OPEN_SINGLE_CHAT_REQUEST, openSingleChat);
    yield takeLatest(conversations.GET_SINGLE_CHAT_USER_INFO_DETAILS_REQUEST, getSingleChatUserInfoDetails);
    yield takeLatest(conversations.GET_SINGLE_CHAT_CUSTOMER_DETAILS_REQUEST, getSingleChatCustomerUserDetails);
    yield takeLatest(conversations.GET_SINGLE_CHAT_SP_USER_DETAILS_REQUEST, getSingleChatSPProfileDetails);
    yield takeLatest(conversations.GET_SINGLE_CHAT_FE_USER_DETAILS_REQUEST, getSingleChatFEProfileDetails);
    yield takeLatest(conversations.ADD_MEMBERS_OR_TEAMS_TO_CHAT_REQUEST, addMembersToChat);
    yield takeLatest(conversations.APPROVE_OR_REJECT_IMAGE_REQUEST, approveOrRejectImage);
    yield takeLatest(conversations.APPROVE_OR_REJECT_CARD_MESSAGE_REQUEST, approveOrRejectCardMessage);
    yield takeLatest(conversations.LIST_CONVERSATION_SIDEBAR_MEDIA_REQUEST, getMediaForSidebarMedia);
    yield takeLatest(conversations.GET_CHAT_NOTIFICATIONS_REQUEST, getChatNotifications);
    yield takeLatest(conversations.GET_CHAT_NOTIFICATIONS_COUNT_REQUEST, getChatNotificationsCount);
    yield takeLatest(conversations.MARK_NOTIFICATIONS_READ_REQUEST, markNotificationsRead);
    yield takeLatest(conversations.LIST_TICKET_CUSTOMER_REPORT_MEDIA_REQUEST, getCustomerReportMedia);
    yield takeLatest(conversations.DOWNLOAD_CUSTOMER_REPORT_REQUEST, downloadCustomerReport);
    yield takeEvery(conversations.GET_SEARCH_CONVERSTAION_MESSAGES_REQUEST, getSearchConversationMessages);
    yield takeEvery(conversations.GET_GPT_CONVERSATIONS_REQUEST, getGptConversations);
    yield takeEvery(conversations.SEND_SCOGO_GPT_MESSAGE_REQUEST, sendScogoGptMessage);
    yield takeEvery(conversations.NOTIFY_END_CUSTOMER_REQUEST, notifyEndCustomer);
    yield takeLatest(conversations.UPLOAD_ONCHANGE_REQUEST, uploadOnChange);
    yield takeEvery(conversations.OPEN_CHAT_ELEMENT, openChatElement);
    yield takeLatest(conversations.GET_CONVERSATIONID_AND_CONVERSATION_DETAILS, getConversationIdandConversationDetails);
    yield takeLatest(conversations.OPEN_ACTIVE_CALL, openActiveCall);
    yield takeLatest(conversations.JOIN_ROOM, joinRoom);
    yield takeLatest(conversations.LEAVE_ROOM, leaveRoom);
    yield takeLatest(conversations.HOLD_ROOM, holdRoom);
    yield takeLatest(conversations.DECLINE_ROOM, declineRoom);
    yield takeLatest(conversations.CLEAR_ROOM, clearMissRoom);
    yield takeLatest(conversations.UPLOAD_ONCHANGE_REQUEST, uploadOnChange)
    yield takeLatest(conversations.GET_ATTACHMENT_WITH_PARTIAL_URL_REQUEST, getAttachmentWithPartialUrl)
};