export const FIELD_ENGINEERS_LIST_REQUEST = 'FIELD_ENGINEERS_LIST_REQUEST';
export const FIELD_ENGINEERS_LIST_SUCCESS = 'FIELD_ENGINEERS_LIST_SUCCESS';
export const FIELD_ENGINEERS_LIST_FAILURE = 'FIELD_ENGINEERS_LIST_FAILURE';

export const DELETE_FE_REQUEST = 'DELETE_FE_REQUEST';
export const DELETE_FE_FAILURE = 'DELETE_FE_FAILURE';

export const FE_PROFILE_DETAIL_REQUEST = 'FE_PROFILE_DETAIL_REQUEST';
export const FE_PROFILE_DETAIL_SUCCESS = 'FE_PROFILE_DETAIL_SUCCESS';
export const FE_PROFILE_DETAIL_FAILURE = 'FE_PROFILE_DETAIL_FAILURE';

export const UPDATE_FE_DETAILS_REQUEST = 'UPDATE_FE_DETAILS_REQUEST';
export const UPDATE_FE_DETAILS_SUCCESS = 'UPDATE_FE_DETAILS_SUCCESS';
export const UPDATE_FE_DETAILS_FAILURE = 'UPDATE_FE_DETAILS_FAILURE';

export const FIELD_ENGINEERS_DROPDOWN_LIST_REQUEST = 'FIELD_ENGINEERS_DROPDOWN_LIST_REQUEST';
export const FIELD_ENGINEERS_DROPDOWN_LIST_SUCCESS = 'FIELD_ENGINEERS_DROPDOWN_LIST_SUCCESS';
export const FIELD_ENGINEERS_DROPDOWN_LIST_FAILURE = 'FIELD_ENGINEERS_DROPDOWN_LIST_FAILURE';
