import React, { useState } from 'react';
import Form from '../../Inventory/reusableComponents/AddOption/Form';
import Input from '../../Inventory/reusableComponents/AddOption/Input';
import { useDispatch } from "react-redux";
import { verifyPartnerDetails } from '../../../actions/partners';

const formName = 'rejectVP';

export default function RejectModal(props) {
    const dispatch = useDispatch();
    const { spProfileDetails } = props;

    const initialValues = {
        rejection_message: ''
    }

    const [form, setForm] = useState(initialValues)

    const submit = (form) => {
        let payload = {
            spId: spProfileDetails && spProfileDetails.company_detail.service_partner_id,
            bankId: spProfileDetails && spProfileDetails.company_detail.bank.service_partner_bank_id,
            data: { message: form.rejection_message, action: 2 },
            role: 'vp',
            formName
        }
        dispatch(verifyPartnerDetails(payload));
    }


    return <Form formName={formName} initialValues={initialValues} form={form} setForm={setForm} submit={(e) => { submit(e) }} buttonPostion={'justify-end'} submitButtonName={'Submit'} onCancel={props.closeModal}>
        <div className='px-3 flex justify-between'>
            <Input className='w-full' label='Reject' name='rejection_message' required type={'text'} placeholder='Add Rejection Message' />
        </div>
    </Form>
}