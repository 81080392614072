import moment from 'moment'
import _groupBy from 'lodash/groupBy'


export const generateCSVDataForAssetInventory = (assets, isCustomer) => {
    let headers = [];
    let data = [];
    headers.push('Asset Type')
    headers.push('External Order Id')
    headers.push('Site')
    headers.push('Make')
    headers.push('Model')
    headers.push('Partcode')
    headers.push('Serial Number')
    headers.push('Type')
    headers.push('Last Worked Date')
    headers.push('BOQ Available')
    headers.push('Asset Details')
    headers.push('Service support')
    headers.push('Back Lining')
    headers.push('Warranty Type')
    headers.push('Warranty Start Date')
    headers.push('Warranty End Date')
    headers.push('Warranty Duration')
    headers.push('Preventive Maintenance Schedule')
    headers.push('OEM Id')
    headers.push('OEM Warranty Type')
    headers.push('OEM Start Date')
    headers.push('OEM End Date')
    headers.push('OEM Warranty Duration')
    headers.push('OEM Last Update')
    try {
        assets.map(item => {
            let asset = []
            asset.push(item.assetType ? item.assetType.title.toString().replace('"', '') : '')
            asset.push(item.externel_order_id ? item.externel_order_id.toString().replace('"', '') : '')
            asset.push(item.site ? item.siteId.toString().replace('"', '') : '')
            asset.push(item.make ? item.make.toString().replace('"', '') : '')
            asset.push(item.model ? item.model.toString().replace('"', '') : '')
            asset.push(item.partcode ? item.partcode.toString().replace('"', '') : '')
            asset.push(item.serial_number ? item.serial_number.toString().replace('"', '') : '')
            asset.push(item.type ? item.type.toString().replace('"', '') : '')
            asset.push(item.last_worked_date ? item.last_worked_date.toString().replace('"', '') : '')
            asset.push(item.is_boq_available === 1 ? 'Yes' : 'No')
            asset.push(item.asset_details ? item.asset_details.toString().replace('"', '') : '')
            asset.push(!isNaN(item.service_support) ? parseInt(item.service_support) === 1 ? 'Yes' : 'No' : 'No')
            asset.push(!isNaN(item.backlining) ? parseInt(item.backlining) === 1 ? 'Yes' : 'No' : 'No')
            asset.push(item.warranty_type ? item.warranty_type.toString().replace('"', '') : '')
            asset.push(item.startDate && item.startDate !== '0000-00-00' ? moment(item.startDate).format('DD-MM-YYYY') : '')
            asset.push(item.endDate && item.endDate !== '0000-00-00' ? moment(item.endDate).format('DD-MM-YYYY') : '')
            asset.push(item.warranty_duration ? item.warranty_duration.toString().replace('"', '') : '')
            asset.push(item.preventive_maintenance_schedule ? item.preventive_maintenance_schedule.toString().replace('"', '') : '')
            asset.push(item.oem_id ? item.oem_id.toString().replace('"', '') : '')
            asset.push(item.oem_warranty_type ? item.oem_warranty_type.toString().replace('"', '') : '')
            asset.push(item.oem_start_date && item.oem_start_date !== '0000-00-00' ? moment(item.oem_start_date).format('DD-MM-YYYY') : '')
            asset.push(item.oem_end_date && item.oem_end_date !== '0000-00-00' ? moment(item.oem_end_date).format('DD-MM-YYYY') : '')
            asset.push(item.oem_warranty_duration ? item.oem_warranty_duration.toString().replace('"', '') : '')
            asset.push(item.oem_last_update ? item.oem_last_update.toString().replace('"', '') : '')
            data.push(asset)
        })
        return { headers, data }
    } catch (error) {
        console.error(error)
        return { headers: [], data: [] }
    }
}

export const generateCSVDataForSiteInventory = (sites, isCustomer) => {
    let headers = [];
    let data = [];
    headers.push('External Site Id')
    if (!isCustomer) {
        headers.push('Customer')
    }
    headers.push('End User')
    headers.push('Region')
    headers.push('Zone')
    headers.push('State')
    headers.push('City')
    headers.push('Pincode')
    headers.push('Address')
    headers.push('Contact Name')
    headers.push('Contact Mobile')
    headers.push('Contact Email')
    headers.push('Last Visit Date')
    headers.push('Last Worked By')
    headers.push('Last Executed By')
    headers.push('Site Vertical')
    try {
        sites.map(item => {
            let site = []
            site.push(item.siteId ? item.siteId.toString().replace('"', '') : '')
            if (!isCustomer) {
                site.push(item.customer ? item.customer.customer_company_name ? item.customer.customer_company_name.toString().replace('"', '') : '' : 'N/A')
            }
            site.push(item.enduser ? item.enduser.end_user_name ? item.enduser.end_user_name.toString().replace('"', '') : '' : 'N/A')
            site.push(item.region ? item.region.toString().replace('"', '') : '')
            site.push(item.zone ? item.zone.toString().replace('"', '') : '')
            site.push(item.stateObj ? item.stateObj.state_name.toString().replace('"', '') : '')
            site.push(item.cityObj ? item.cityObj.city_name.toString().replace('"', '') : '')
            site.push(item.fk_pincode_id ? item.fk_pincode_id.toString().replace('"', '') : '')
            site.push(item.address ? item.address.toString().replace('"', '') : '')
            site.push(item.siteContactName ? item.siteContactName.toString().replace('"', '') : '')
            site.push(item.siteContactPhone ? item.siteContactPhone.toString().replace('"', '') : '')
            site.push(item.siteContactEmail ? item.siteContactEmail.toString().replace('"', '') : '')
            site.push(item.last_visit_date ? item.last_visit_date.toString().replace('"', '') : '')
            site.push(item.sp && item.sp !== null ? item.sp.sp_name.toString().replace('"', '') : '')
            site.push(item.fe && item.fe !== null ? `${item.fe.first_name.toString().replace('"', '')} ${item.fe.last_name.toString().replace('"', '')}` : '')
            site.push(item.site_vertical ? item.site_vertical.toString().replace('"', '') : '')
            data.push(site)
        })
        return { headers, data }
    } catch (error) {
        console.error(error)
        return { headers: [], data: [] }
    }
}


export const generateCSVDataForWarehouseInventory = (warehouses, isCustomer) => {
    let headers = [];
    let data = [];
    headers.push('Warehouse Name')
    headers.push('Address')
    headers.push('City')
    headers.push('State')
    headers.push('Contact Name ')
    headers.push('Contact Email')
    headers.push('Contact Number')
    headers.push('Total Spare Count')

    if (!isCustomer) {
        headers.push('Customer')
    }
    try {
        warehouses.map(item => {
            let warehouse = []
            warehouse.push(item.name ? item.name.toString().replace('"', '') : '')
            if (!isCustomer) {
                warehouse.push(item.customer ? item.customer.customer_company_name ? item.customer.customer_company_name.toString().replace('"', '') : '' : 'N/A')
            }
            warehouse.push(item.address ? item.address.toString().replace('"', '') : 'N/A')
            warehouse.push(item.cityObj ? item.cityObj.city_name ? item.cityObj.city_name.toString().replace('"', '') : '' : 'N/A')
            warehouse.push(item.state ? item.state.toString().replace('"', '') : 'N/A')
            warehouse.push(item.warehouseContactName ? item.warehouseContactName.toString().replace('"', '') : 'N/A')
            warehouse.push(item.warehouseContactEmail ? item.warehouseContactEmail.toString().replace('"', '') : 'N/A')
            warehouse.push(item.warehouseContactPhone ? item.warehouseContactPhone.toString().replace('"', '') : 'N/A')
            warehouse.push(item.spareAssetCount ? item.spareAssetCount.toString().replace('"', '') : 'N/A')

            data.push(warehouse)
        })
        return { headers, data }
    } catch (error) {
        console.error(error)
        return { headers: [], data: [] }
    }
}



export const generateCSVDataForSpareAssetsInventory = (spareassetsList, isCustomer) => {
    let headers = [];
    let data = [];
    headers.push('Spare Number')
    headers.push('Warehouse Name')
    headers.push('Customer Name')
    headers.push('Asset Type')
    headers.push('Make')
    headers.push('Model')
    headers.push('Partcode')
    headers.push('Serial Number')
    headers.push('Added On')
    headers.push('Status')
    headers.push('Inward Source')
    headers.push('Asset received as ')
    headers.push('Ticket Number')
    headers.push('RMA Ticket number')
    headers.push('Migrated To')
    headers.push('Reconciled Status')
    headers.push('Scanned By')
    headers.push('Scanned Time')
    if (!isCustomer) {
        headers.push('Customer')
    }
    try {
        spareassetsList.map(item => {
            let spareassets = []
            spareassets.push(item.spare_number ? item.spare_number.toString().replace('"', '') : '')
            spareassets.push(item?.warehouse?.name ? item.warehouse.name.toString().replace('"', '') : 'N/A')
            spareassets.push(item?.customer?.customer_company_name ? item.customer.customer_company_name.toString().replace('"', '') : 'N/A')
            spareassets.push(item?.assetType?.title ? item.assetType.title.toString().replace('"', '') : 'N/A')
            spareassets.push(item?.make ? item.make.toString().replace('"', '') : 'N/A')
            spareassets.push(item?.model ? item.model.toString().replace('"', '') : 'N/A')
            spareassets.push(item?.partcode ? item.partcode.toString().replace('"', '') : 'N/A')
            spareassets.push(item?.serial_number ? item.serial_number.toString().replace('"', '') : 'N/A')
            spareassets.push(item?.added_on ? item.added_on.toString().replace('"', '') : 'N/A')
            spareassets.push(item?.status ? item.status.toString().replace('"', '') : 'N/A')
            spareassets.push(item?.inward_source ? item.inward_source.toString().replace('"', '') : 'N/A')
            spareassets.push(item?.asset_received_as ? item.asset_received_as.toString().replace('"', '') : 'N/A')
            spareassets.push(item?.request_type === 'MIGRATION' && item?.ticket_asset_relationships?.[0]?.ticket.job_ticket_number ? item?.ticket_asset_relationships?.[0]?.ticket.job_ticket_number?.toString().replace('"', '') : 'N/A')
            spareassets.push(item?.request_type === 'NO_REQUESTS' && item?.ticket_asset_relationships?.[0]?.ticket.job_ticket_number ? item?.ticket_asset_relationships?.[0]?.ticket.job_ticket_number?.toString().replace('"', '') : 'N/A')
            spareassets.push(item?.delivered_to ? item.delivered_to.toString().replace('"', '') : 'N/A')
            spareassets.push(item?.scanned_by ? item.scanned_by.toString().replace('"', '') : 'N/A')
            spareassets.push(item?.scanned_time ? item.scanned_time.toString().replace('"', '') : 'N/A')
            data.push(spareassets)
        })
        return { headers, data }
    } catch (error) {
        console.error(error)
        return { headers: [], data: [] }
    }
}