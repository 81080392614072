import React from 'react';
import { useDispatch } from 'react-redux';

import IconToolTip from './IconToolTip';
import { copyToClipboard } from '../actions/utils';

const tdLight = 'text-font10 font-normal';
const tdDark = ' text-font10 font-medium';

const getOrange = (status, condition) => {
    if (['TRANSIT_IN', 'TRANSIT_OUT'].includes(status)) return 50;
    return 0;
};
const getGreen = (status, condition) => {
    if (status === 'AVAILABLE' && ['REFURBISHED', 'NEW'].includes(condition)) return 100;
    return 0;
};
const getRed = (status, condition) => {
    if (status === 'DELIVERED' && condition === 'FAULTY') return 100;
    if (status === 'AVAILABLE' && condition === 'FAULTY') return 100;
    return 0;
};

const getLightRed = (status, condition) => {
    if (status === 'NOT AVAILABLE' && condition === 'FAULTY') return 100;
    return 0;
};

const getLightGray = (status, condition) => {
    if (status === 'NOT AVAILABLE' && ['REFURBISHED', 'NEW'].includes(condition)) return 100;
    return 0;
};

const getYellow = (status, condition) => {
    if (status === 'TRANSIT_OUT' && ['REFURBISHED', 'NEW'].includes(condition)) return 100;
    if (status === 'TRANSIT_IN' && ['REFURBISHED', 'NEW'].includes(condition)) return 100;
    return 0;
};

const getAmber = (status, condition) => {
    if (status === 'TRANSIT_OUT' && condition === 'FAULTY') return 100;
    if (status === 'TRANSIT_IN' && condition === 'FAULTY') return 100;
    return 0;
};

const getBlue = (status, condition) => {
    if (status === 'DELIVERED' && ['REFURBISHED', 'NEW'].includes(condition)) return 100;
    return 0;
};

function MultiBar(props) {
    const { type } = props;

    const dispatch = useDispatch();
    const copySerialNumber = (serial_number) => {
        dispatch(copyToClipboard({ data: serial_number }));
    };
    if (type === 'ASSET') {
        const { serialNumber, workingCondition, status, statusForDisplay, statusHoverText, showCopyIcon = true } = props;

        return (
            <div className='w-full'>
                <div className='flex'>
                    <IconToolTip title={`Serial Number: ${serialNumber}`} placement='bottom'>
                        <p className={`${tdDark} max-w-14 truncate`}>
                            {' '}
                            <span className={tdLight}> </span> {serialNumber}
                        </p>
                    </IconToolTip>
                    {showCopyIcon && (
                        <IconToolTip placement={'bottom'} title={`Copy Serial Number`}>
                            <span className='cursor-pointer material-icons text-scogobgsky text-lg pl-1' onClick={() => copySerialNumber(serialNumber)}>
                                {' '}
                                content_copy{' '}
                            </span>
                        </IconToolTip>
                    )}
                    {status === 'TRANSIT_OUT' && (
                        <IconToolTip placement={'bottom'} title={`Transit Out`}>
                            <span className='material-icons text-scogobgsky text-lg pl-1 ml-auto'> arrow_forward </span>
                        </IconToolTip>
                    )}
                    {status === 'TRANSIT_IN' && (
                        <IconToolTip placement={'bottom'} title={`Transit In`}>
                            <span className='material-icons text-scogobgsky text-lg pl-1 ml-auto'> arrow_back </span>
                        </IconToolTip>
                    )}
                </div>
                <div className='flex items-center'>
                    <div className='bg-scogoeee w-full relative h-2.5 rounded-md overflow-hidden'>
                        <div style={{ width: `${getGreen(status, workingCondition)}%` }} className='bg-scogogoing absolute top-0 h-2.5  rounded-md z-20'></div>
                        <div style={{ width: `${getRed(status, workingCondition)}%` }} className='bg-scogoclosed absolute top-0 h-2.5 z-10 rounded-md'></div>
                        <div style={{ width: `${getOrange(status, workingCondition)}%` }} className='bg-scogoorange absolute top-0 h-2.5 z-0 rounded-md'></div>
                        <div style={{ width: `${getBlue(status, workingCondition)}%` }} className='bg-scogoprimary absolute top-0 h-2.5 z-0 rounded-md'></div>
                        <div style={{ width: `${getLightRed(status, workingCondition)}%` }} className='bg-lightRed absolute top-0 h-2.5 z-0 rounded-md'></div>
                        <div style={{ width: `${getLightGray(status, workingCondition)}%` }} className='bg-scogo99 absolute top-0 h-2.5 z-0 rounded-md'></div>
                        <div style={{ width: `${getYellow(status, workingCondition)}%` }} className='bg-scogoBasicYellow absolute top-0 h-2.5 z-0 rounded-md'></div>
                        <div style={{ width: `${getAmber(status, workingCondition)}%` }} className='bg-scogoAmber absolute top-0 h-2.5 z-0 rounded-md'></div>
                    </div>
                </div>

                <div className='flex mt-1'>
                    <IconToolTip title={statusHoverText} placement='bottom'>
                        <div className='text-scogogray text-font10 font-normal'>{statusForDisplay}</div>
                    </IconToolTip>
                    &nbsp;-&nbsp;
                    <IconToolTip title={`CONDITION: ${workingCondition}`} placement='bottom'>
                        <div className='text-scogogray text-font10 font-normal'>{workingCondition}</div>
                    </IconToolTip>
                </div>
            </div>
        );
    }
    if (type === 'PROJECT') {
        const { ticketDate, totalTickets, ongoin, hold, closed, totalPercentage } = props;
        const closedPercentage = (closed / totalTickets) * 100;
        const holdPercentage = closedPercentage + (hold / totalTickets) * 100;
        const onGoingPercentage = holdPercentage + (ongoin / totalTickets) * 100;
        return (
            <div className='w-full'>
                <div className='flex justify-between'>
                    <div>
                        <p className={tdDark}>
                            <span className={tdLight}> Created: </span> {ticketDate}
                        </p>
                    </div>
                    <div>
                        <p className={tdDark}>
                            <span className={tdLight}> Tickets: </span> {totalTickets}
                        </p>
                    </div>
                </div>

                <div className='flex items-center'>
                    <div className='bg-scogoeee w-full relative h-2.5 rounded-md overflow-hidden'>
                        <div style={{ width: `${closedPercentage}%` }} className='bg-scogogoing absolute top-0 h-2.5  rounded-md z-20'></div>
                        <div style={{ width: `${holdPercentage}%` }} className='bg-scogoclosed absolute top-0 h-2.5 z-10 rounded-md'></div>
                        <div style={{ width: `${onGoingPercentage}%` }} className='bg-scogoorange absolute top-0 h-2.5 z-0 rounded-md'></div>
                    </div>
                    <p className={`${tdDark} ml-1`}>{totalPercentage}%</p>
                </div>

                <div className='flex justify-between mt-1'>
                    <div className='text-scogogray text-font11 font-normal'>
                        <span className='material-icons text-scogoorange text-font10 mr-1'>circle</span>
                        Ongoing ({ongoin})
                    </div>
                    <div className='text-scogogray text-font11 font-normal'>
                        <span className='material-icons text-scogoclosed text-font10 mr-1'>circle</span>
                        Hold({hold})
                    </div>
                    <div className='text-scogogray text-font11 font-normal'>
                        <span className='material-icons text-scogo2e text-font10 mr-1'>circle</span>
                        Closed ({closed})
                    </div>
                </div>
            </div>
        );
    }
    return <></>;
}

export default MultiBar;
