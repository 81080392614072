import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { closeFullScreenModalAction, openCustomModal } from '../../../../actions/modal';
import Form from '../../../../common/Form';
import Input from '../../../../common/Form/Input';
import { actualValueForDropdown } from '../../../../utils/common';
import AssetDetails from './AssetDetails';
import { inventory } from '../../../../router/roleWisePageRoute';
import { docketNumber, fullDevice, handHelding, noDeviceConsumed, partDevice, radioOptions, requestPickup, rmaRequestTypeOptions } from './index';
import { useForm } from 'react-hook-form';
import Select from '../../../../common/Form/Select';
import TextArea from '../../../../common/Form/TextArea';
import Radio from '../../../../common/Form/Radio';
import { useEffect } from 'react';
import apis from '../../../../apis';
import DatePicker from '../../../../common/Form/DatePicker';
import { CurrentWarehouseDetail } from './CurrentWarehouseDetail';
import ButtonScogoOrange from '../../../../common/Buttons/ButtonScogoOrange';
import { useState } from 'react';
import IconToolTip from '../../../../common/IconToolTip';
import { RenderConsumableForm } from '../../../Payments/Modals/EditPayout/AddConsumables';

export const UpdateRMAForm = ({
    submit,
    defaultValues,
    faulty_assets,
    spareDetail,
    debouncedAssetIdChange,
    currentWarehouseId,
    viewTicket,
    ticketCustomerId,
    formName,
}) => {
    const dispatch = useDispatch();
    const { formButtonLoading } = useSelector((state) => state.loading);
    const [isWarehouseDetailsChanged, setIsWarehouseDetailsChanged] = useState(false);
    const [showRmaError, setShowRmaError] = useState(false);
    const methods = useForm({ defaultValues, mode: 'all' });
    const { courierVendors } = useSelector((state) => state.user);
    const { warehousesDropDown, repaircentersDropdown } = useSelector((state) => state.inventory);
    const { isViewTicketByIdLoading, isSpareAssetDetailsLoading, isWarehouseOwnerLoading } = useSelector((state) => state.loading);
    const currentRepairCenter = viewTicket?.repair_center;
    const { setValue, watch } = methods;
    const cl_rma_response = watch('cl_rma_response');
    const rma_request_type = watch('rma_request_type');
    const isHandHeldingSelected = cl_rma_response === handHelding.value;
    const isDocketNumberSelected = cl_rma_response === docketNumber.value;
    const isRequestPickupSelected = cl_rma_response === requestPickup.value;
    const isNoDeviceConsumendSelected = actualValueForDropdown(rma_request_type) === noDeviceConsumed.value;
    const isDevicePartSelected = actualValueForDropdown(rma_request_type) === partDevice.value;
    const isFullDeviceSelected = actualValueForDropdown(rma_request_type) === fullDevice.value;

    const onViewAllTicketClick = () => {
        let assetsTypeIds = viewTicket && Array.isArray(viewTicket.assets) && viewTicket.assets.map((asset) => asset.asset_type_id).join('^');
        let route = `${inventory.path}?tab=spareAssets&page=1&limit=10&customerId=${ticketCustomerId}&status=AVAILABLE`;
        if (assetsTypeIds) route = `${route}&title=${assetsTypeIds}`;
        if (viewTicket?.warehouse) route = `${route}&warehouseId=${viewTicket?.warehouse}`;
        window.open(`${window.location.origin}${route}`);
    };

    const spareDetailTopRightComponent = <ButtonScogoOrange textOrComponent={'  View All Asset '} onClick={onViewAllTicketClick} />;

    useEffect(() => {
        return () => {
            setShowRmaError(false);
        }
    }, []);

    useEffect(() => {
        if (cl_rma_response && requestPickup.value === cl_rma_response) {
            setValue('job_shipping_fk_pincode_id', viewTicket?.job_fk_pincode_id ? viewTicket?.job_fk_pincode_id : '');
            setValue('job_shipping_address_1', viewTicket?.job_site_address_1 ? viewTicket?.job_site_address_1 : '');
        }
    }, [cl_rma_response, viewTicket?.job_site_address_1, setValue, viewTicket?.job_fk_pincode_id]);

    useEffect(() => {
        if (currentRepairCenter) {
            let findRepairInDropdown = repaircentersDropdown?.find((center) => center.value === currentRepairCenter);
            if (findRepairInDropdown) setValue('repair_center', findRepairInDropdown);
        }
    }, [currentRepairCenter, repaircentersDropdown, currentWarehouseId, setValue, warehousesDropDown]);


    useEffect(() => {
        if (viewTicket?.rma_request_type) {
            const findDeviceType = rmaRequestTypeOptions.find((device) => device.value === viewTicket?.rma_request_type);
            if (findDeviceType) {
                setValue('rma_request_type', findDeviceType);
            }
            if (viewTicket?.cl_rma_response) {
                setValue('cl_rma_response', viewTicket?.cl_rma_response);
            }
        }
    }, [setValue, viewTicket?.rma_request_type, viewTicket?.cl_rma_response]);

    return (

        <div >
            <div className='flex w-full px-4 py-3 bg-scogolightblue rounded-t-xl'>
                <CurrentWarehouseDetail
                    warehousesDropDown={warehousesDropDown}
                    warehouseDropdownLoading={isWarehouseOwnerLoading}
                    isLoading={isViewTicketByIdLoading}
                    viewTicket={viewTicket}
                    setIsWarehouseDetailsChanged={setIsWarehouseDetailsChanged}
                    setShowRmaError={setShowRmaError}
                />
            </div>
            <Form
                defaultValues={defaultValues}
                onSubmit={submit}
                buttonPostion={'justify-end'}
                submitButtonText={'Submit'}
                onCancel={() => dispatch(closeFullScreenModalAction())}
                methods={methods}
                isLoading={formButtonLoading[formName]}
                submitContainerClassName='flex gap-2 justify-start py-4 px-4'
                showSubmit
                isSubmitDisabled={isWarehouseDetailsChanged}
                submitButtonHoverText={showRmaError ? 'Please Select Warehouse' : ''}
            >
                <div className='flex gap-8 px-4 pb-3 max-h-max bg-scogolightblue'>
                    <AssetDetails
                        partcode={faulty_assets?.partcode}
                        serial_number={faulty_assets?.serial_number}
                        assetTypeTitle={faulty_assets?.title}
                        title='Faulty Asset Details'
                        isLoading={isViewTicketByIdLoading}
                    />
                    <AssetDetails
                        partcode={spareDetail.partcode}
                        serial_number={spareDetail.serial_number}
                        assetTypeTitle={spareDetail.assetTypeTitle}
                        title='Spare Asset Details'
                        isLoading={isSpareAssetDetailsLoading || isViewTicketByIdLoading}
                        headerTopRightComponent={spareDetailTopRightComponent}
                    />
                </div>
                <div className='flex py-3 px-3 items-end'>
                    <Select
                        className={`w-full ${isNoDeviceConsumendSelected ? 'col-span-3' : ''}`}
                        label='Device Fault Type'
                        name='rma_request_type'
                        required
                        options={rmaRequestTypeOptions}
                    />
                    {!isNoDeviceConsumendSelected && (
                        <>
                            {isFullDeviceSelected && (
                                <Input
                                    className='w-full'
                                    label='AssetId'
                                    name='assetNumber'
                                    type={'text'}
                                    required
                                    shouldUnregister
                                    onChange={(event) => {
                                        debouncedAssetIdChange(event?.target?.value);
                                    }}
                                />
                            )}
                            {isDevicePartSelected && <Input className='w-full' label='Part Name' name='part_name' type={'text'} required shouldUnregister />}
                            <Select className='w-full' label='Ship Faulty Device To' name='repair_center' required options={repaircentersDropdown} shouldUnregister />
                        </>
                    )}
                </div>
                <div><AddConsumablesButton ticketId={viewTicket?.id} /></div>
                {!isNoDeviceConsumendSelected && (
                    <div className='px-3'>
                        <div className='flex items-center'>
                            <Radio id={'cl_rma_response'} name='cl_rma_response' type={'radio'} className={'flex'} options={radioOptions} required={isFullDeviceSelected || isDevicePartSelected} requiredMessage='Please select logistic type' shouldUnregister />
                        </div>
                        {isHandHeldingSelected && <DatePicker className='w-full col-span-2' label='ETA' name='eta' required shouldUnregister />}
                        {isDocketNumberSelected && (
                            <div className='grid grid-cols-2 rows-fr grid-flow-row-dense items-end'>
                                <Input className='w-full' label='Docket Number' name='docket_num' type={'text'} required shouldUnregister />
                                <Select className='w-full' label='Courier Vendor' name='courier_vendor_id' required options={courierVendors} shouldUnregister />
                            </div>
                        )}
                        {isRequestPickupSelected && (
                            <div className='grid grid-cols-3 rows-fr grid-flow-row-dense items-end'>
                                <Select
                                    className='w-full'
                                    label='Pickup'
                                    name='pickup'
                                    required
                                    options={warehousesDropDown}
                                    isLoading={isWarehouseOwnerLoading}
                                    shouldUnregister
                                />
                                <Input
                                    className='w-full'
                                    label='Shipping Pincode'
                                    name='job_shipping_fk_pincode_id'
                                    type={'text'}
                                    required
                                    pattern={/^\d{6}$/}
                                    validate={async (value) => {
                                        if (value === '') return true;
                                        const response = await apis.cities.getCityFromPincode({}, {}, {}, { pincode: value });
                                        return response.data?.code === 200 || "Pincode does't exist in the System.";
                                    }}
                                    shouldUnregister
                                />
                                <TextArea className='w-full' label='Shipping Address' name='job_shipping_address_1' type={'text'} required shouldUnregister />
                            </div>
                        )}
                    </div>
                )}
            </Form>
        </div >
    );
};


const AddConsumablesButton = () => {
    const { viewTicket } = useSelector((state) => state.tickets)
    const dispatch = useDispatch();
    const handleClick = () => {
        let heading = `Add Consumables`;
        let modalComponent = <RenderConsumableForm ticketId={viewTicket?.id} />

        let modalWidth = '70rem',
            modalHeight = 'auto';
        dispatch(openCustomModal({ modalComponent, heading, modalWidth, modalHeight }));
    };

    return <>
        <div className='pb-3 px-6'>
            <ButtonScogoOrange textOrComponent={'Add Consumables'} onClick={handleClick} />
        </div>
    </>
}
