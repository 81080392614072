import { pmscm } from '../types';

export const getTools = ({ isDropDown = false }) => ({
    type: pmscm.GET_TOOLS_REQUEST,
    payload: { isDropDown }
})

export const addTools = ({ spId, tools, role, userType = 'SP', selectedType, formName, address_proof_verified }) => ({
    type: pmscm.ADD_TOOLS_REQUEST,
    payload: { spId, tools, role, userType, selectedType, formName, address_proof_verified }
})

export const updateSiteType = ({ user_id, site_type, role, formName }) => ({
    type: pmscm.UPDATE_SITE_TYPE_REQUEST,
    payload: { user_id, site_type, role, formName }
})

export const addToolsSp = ({ spId, tools, role, userType, selectedType, customer, fk_cluster_id, formName, toolNames }) => ({
    type: pmscm.ADD_TOOLS_SP_REQUEST,
    payload: { spId, tools, role, userType, selectedType, customer, fk_cluster_id, formName, toolNames }
})