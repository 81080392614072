import React from "react";

const DetailTag = (props) => {
    const {tagHeadClass, tagValueClass, tagClass, onHeadClick = () => {}} = props;

    const render = () => {
        if (props.render) return props.render();
        return <h5 className={tagValueClass ? tagValueClass : "text-font15 font-medium text-scogo11 truncate"}>{props.tagValue}</h5>;
    }

    const renderTag = () => {
        if(props.renderTag) return props.renderTag()
        return  <h3 onClick={onHeadClick} className={tagHeadClass ?  tagHeadClass : "text-scogo99 text-font15 font-medium truncate"}>{props.tagName}</h3>
    }

    return (
        <div className={tagClass}>
            {renderTag()}
            {render()}
        </div>
    )

}

export default DetailTag
