import axios from 'axios';
import config from '../config'
import { getJWTToken } from '../utils/common';

let axiosInstance = axios.create({
    baseURL: config.api.baseUrl,
    headers: { 'Content-Type': 'application/json' }
})

const isHandlerEnabled = (config = {}) => {
    return config.hasOwnProperty('handlerEnabled') && !config.handlerEnabled ? false : true
}

const requestHandler = (request) => {
    if (isHandlerEnabled(request)) {
        // Modify request here        
        const token = getJWTToken();
        request.headers['authorization'] = `Bearer ${token}`;
    }
    return request
}

const errorHandler = (error) => {
    if (isHandlerEnabled(error.config)) {
        // Handle errors
    }
    return Promise.reject({ ...error })
}

const successHandler = (response) => {
    if (isHandlerEnabled(response.config)) {
        // Handle responses
    }
    return response
}

axiosInstance.interceptors.request.use(request => requestHandler(request))

axiosInstance.interceptors.response.use(
    response => successHandler(response),
    error => errorHandler(error)
)

export default axiosInstance