import { Menu, Transition } from "@headlessui/react";
import React from "react";
import { Fragment } from "react";
import IconToolTip from "./IconToolTip";


const Item = ({ icon, displayText }) => {
  return (
    icon ? (
      <span >
        <div className="flex items-center">
          <div className="mr-2">
            <span className="material-icons text-scogogray">
              {icon}
            </span>
          </div>
          {displayText}
        </div>
      </span>
    ) : (
      <span >
        {displayText}
      </span>
    )
  )
}

const Menus = ({
  as,
  className,
  displayType,
  position,
  items = [],
  itemsClassName,
  itemHoverClassName = "hover:bg-blue-100",
  menuButtonIcon,
  handleItemClick = () => { },
  itemClassName = '',
  itemLable,
  icons = [],
  textColor = '',
  hoverText,
  renderItem
}) => {

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <>
      <Menu as={as} className={className}>
        <div className={displayType}>
          <div className={position}>
            <Menu.Button>{menuButtonIcon}</Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className={itemsClassName}>
                <div className="py-1 px-2 max-h-[30rem] overflow-auto">
                  {itemLable?.map((item, i) => {
                    const customClass = typeof itemClassName === 'function' ? itemClassName(items[i] ? items[i] : item) : itemClassName;
                    return (
                      <Menu.Item key={i}>
                        {({ activeStatus }) => {
                          let element = <></>
                          if (renderItem) {
                            // passing items index, must check if changing in this file
                            element = renderItem(items[i])
                          } else {
                            element = (
                              <span
                                onClick={() => handleItemClick(items[i] ? items[i] : item)}
                                className={`${classNames(activeStatus ? "" : `${itemHoverClassName}`, `${customClass} `, textColor)}`} >
                                <Item displayText={item?.constructor === Object ? item?.value : item} icon={icons[i]} />
                              </span>
                            );
                          }
                          if (hoverText) {
                            return (<IconToolTip title={typeof hoverText === 'function' ? hoverText(items[i] ? items[i] : item) : hoverText} >{element}</IconToolTip>)
                          } else {
                            return element;
                          }
                        }
                        }
                      </Menu.Item>
                    );
                  })}
                </div>
              </Menu.Items>
            </Transition>
          </div>
        </div>
      </Menu>
    </>
  );
};

export default Menus;
