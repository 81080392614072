import { tickets } from '../types';

export const getSPNewTicketsList = ({ order_by = 'broadcast_timestamp', sp_id, user_type, ticketId, limit, page }) => ({
    type: tickets.SP_ASSIGNED_NEW_TICKETS_LIST_REQUEST,
    payload: { order_by, sp_id, user_type, ticketId, limit, page },
});

export const getExecutionDateChangeRemarks = () => ({
    type: tickets.EXEC_DATECHANGE_REMARKS_REQUEST,
});

export const acceptSPAssignedTicket = ({ data, order_by = 'broadcast_timestamp', ticket, userDetails }) => ({
    type: tickets.ACCEPT_SP_ASSIGNED_TICKET_REQUEST,
    payload: { data, order_by, ticket, userDetails },
});

export const getSPOngoingTickets = ({ type, order_by = 'broadcast_timestamp', sp_id, ticketId, open = false }) => ({
    type: tickets.SP_ONGOING_TICKETS_REQUEST,
    payload: { type, order_by, sp_id, ticketId, open },
});

export const acceptTicket = ({ data, formName, callViewTicket }) => ({
    type: tickets.ACCEPT_SP_MANUALLY_REQUEST,
    payload: { data, formName, callViewTicket },
});

export const withdrawTicket = ({ data, formName, callAfterSubmit, refresh, showToast }) => ({
    type: tickets.WITHDRAW_TICKET_REQUEST,
    payload: { data, formName, callAfterSubmit, refresh, showToast },
});

export const ticketAssignToScogo = ({ payload, formName, refresh, showToast }) => ({
    type: tickets.ASSIGN_TO_SCOGO_REQUEST,
    payload: { payload, formName, refresh, showToast },
});

export const bulkTicketAssignToScogo = ({ ticketIds, execution_date, ticket_instruction, formName, update_planned_date, refresh }) => ({
    type: tickets.BULK_ASSIGN_TO_SCOGO_REQUEST,
    payload: { ticketIds, execution_date, ticket_instruction, formName, update_planned_date, refresh },
});
