import { useDispatch, useSelector } from "react-redux";
import ButtonScogoPrimary from "../../common/Buttons/ButtonScogoPrimary";
import { useEffect, useMemo, useState } from "react";
import { getAssetTagDetails } from "../../actions/inventory";
import IconToolTip from "../../common/IconToolTip";
import { useNavigate } from "react-router-dom";
import { trackSupportTicket } from "../../router/roleWisePageRoute";
import { validateStringValue } from "../../utils/common";
import production from "../../config/env/production";
import config from "../../config";
import NoResultFound from "../../common/NoResultFound";
import { SkeltonCardTwo } from "../../common/SkeltonCardTwo";

export default function RenderAssetDetails({ tag }) {
    const dispatch = useDispatch();
    const { tagAssetDetails } = useSelector((state => state.inventory));
    const navigate = useNavigate();
    const { isAssetTagDetailsLoading } = useSelector((state => state.loading));

    useEffect(() => {
        dispatch(getAssetTagDetails({ tag }))
    }, [dispatch, tag]);

    useEffect(() => {
        document.title = "Scogo Smart Edge Router";
    }, []);

    const onSupportIconClick = () => {
        let to = `${trackSupportTicket.path}?tag=${tag}`;
        if (tagAssetDetails && tagAssetDetails.fk_ticket_id) to = `${to}&ticketId=${tagAssetDetails.fk_ticket_id}`;
        navigate(`${to}`);
    };

    const siteAddress = validateStringValue(tagAssetDetails?.device_config?.site?.device_installation_address);
    const enduserName = validateStringValue(tagAssetDetails?.enduser?.end_user_name);
    const customerName = validateStringValue(tagAssetDetails?.device_config?.site?.customer_name);
    const customerContactNumber = validateStringValue(tagAssetDetails?.device_config?.site?.customer_spoc_contact_number);
    const customerContactName = validateStringValue(tagAssetDetails?.device_config?.site?.customer_spoc_name);
    const endCustomerName = validateStringValue(tagAssetDetails?.device_config?.site?.end_customer_name);
    const endCustomerContactName = validateStringValue(tagAssetDetails?.device_config?.site?.end_customer_spoc_name);
    const endCustomerContactNumber = validateStringValue(tagAssetDetails?.device_config?.site?.end_customer_spoc_contact_number);
    const assetStatus = validateStringValue(tagAssetDetails?.status) ? tagAssetDetails?.status.charAt(0).toUpperCase() + tagAssetDetails?.status.slice(1).toLowerCase() : '';
    const device_latitude_longitude = validateStringValue(tagAssetDetails?.device_config?.site?.device_latitude_longitude);

    const linkDetails = useMemo(() => {
        if (tagAssetDetails?.device_config) {
            const links = [];
            Object.entries(tagAssetDetails?.device_config).forEach(([key, value]) => {
                if (key.startsWith('link')) links.push(value);
            });
            return links;
        }
        return [];
    }, [tagAssetDetails?.device_config]);

    if (isAssetTagDetailsLoading) {
        return <div className='px-6 w-full h-full'> <SkeltonCardTwo arr={[1, 2, 3, 4, 5, 6, 7, 8, 9]} height={20} className={'my-4'} /></div>
    }
    if (!tagAssetDetails && !isAssetTagDetailsLoading) {
        return <div className='subContainer h-full bg-white'><div className='max-h-max w-full'>
            <NoResultFound message='Asset details not found' /></div></div>
    }

    return <>
        <div className="w-full flex flex-col items-center">
            <div style={{ maxHeight: '90%' }} className='w-[90%] md:w-[95%] my-4 mx-4 md:grid md:grid-cols-2 gap-4 md:gap-4 overflow-hidden'>
                <Card label='Device Details' divStyle={{ maxHeight: '50vh' }}>
                    {tagAssetDetails?.serial_number && <RenderCardValue title={'Serial Number'} value={tagAssetDetails?.serial_number} />}
                    {tagAssetDetails?.make && <RenderCardValue title={'Make'} value={tagAssetDetails?.make} />}
                    {tagAssetDetails?.model && <RenderCardValue title={'Model'} value={tagAssetDetails?.model} />}
                    {tagAssetDetails?.serial_number && <RenderCardValue title={'Asset Type'} value={tagAssetDetails?.assetType?.title} />}
                    {tagAssetDetails?.spare_number && <RenderCardValue title={'Asset ID'} value={tagAssetDetails?.spare_number} />}
                    {tagAssetDetails?.serial_number && <RenderCardValue title={'Customer Name'} value={tagAssetDetails?.customer?.customer_company_name} />}
                    {siteAddress && <RenderAddress title={'Site Address'} address={siteAddress} device_latitude_longitude={device_latitude_longitude} />}
                    {enduserName && <RenderCardValue title={'End Customer Name'} value={enduserName} />}
                    {assetStatus && <RenderCardValue title={'Device Status'} value={assetStatus} />}
                </Card>
                {tagAssetDetails?.device_config && <Card divStyle={{ maxHeight: '50vh' }} label='Additional Details' isOpen={window.innerWidth < 1040 ? false : true}>
                    <div style={{ maxHeight: '40vh' }} className='h-full overflow-y-auto'>
                        {siteAddress && <RenderAddress title={'Device Installation Address'} address={siteAddress} device_latitude_longitude={device_latitude_longitude} />}
                        {customerName && <RenderCardValue title={'Customer Name'} value={customerName} />}
                        {customerContactName && <RenderCardValue title={'Customer Contact Name'} value={customerContactName} />}
                        {customerContactNumber && <RenderCardValue title={'Customer Contact Number'} value={customerContactNumber} />}
                        {endCustomerName && <RenderCardValue title={'End Customer Name'} value={endCustomerName} />}
                        {endCustomerContactNumber && <RenderCardValue title={'End Customer Contact Name'} value={endCustomerContactNumber} />}
                        {endCustomerContactName && <RenderCardValue title={'End Customer Contact Number'} value={endCustomerContactName} />}
                        {linkDetails.map(({ isp_name, isp_plan_name, isp_backhaul_name, static_ip, static_ip_available }, index) => {
                            return <LinkDetails index={index + 1} isp_name={isp_name} isp_plan_name={isp_plan_name} isp_backhaul_name={isp_backhaul_name} static_ip={static_ip} static_ip_available={static_ip_available} />
                        })
                        }
                    </div>
                </Card>}
            </div>
            <div style={{ height: '10%' }} className="flex flex-wrap justify-center gap-2 mx-8">
                <ActionButtons serialNumber={tagAssetDetails?.serial_number} />
            </div>
        </div>
        <RenderSupportTicketIcon onClick={onSupportIconClick} />
    </>
};


const RenderCardValue = ({ title, value, className = 'w-full' }) => {
    return <span className={className}>
        <span className='max-w-max text-font11 text-scogogray' >{title} : </span>
        <span className='max-w-max text-font12 capitalize'>
            {value}
        </span>
        <br />
    </span>
}

const RenderAddress = ({ title, address, device_latitude_longitude }) => {
    const lat_long = device_latitude_longitude.split(',');
    let lat, long;
    if (lat_long.length > 0) {
        lat = lat_long[0];
        long = lat_long[1];
    }
    const openAddressInMap = () => {
        return window.open(
            `https://maps.google.com?q=${!['', undefined, null, -1.0, '-1.0000000', 0.0, '0.0000000'].includes(lat) &&
                !['', undefined, null, -1.0, '-1.0000000', 0.0, '0.0000000'].includes(long)
                ? `${lat},${long}`
                : `${address}`
            }`,
            '_blank'
        );
    }
    return <span className={'w-full line-clamp-3'}>
        <span className='max-w-max text-font11 text-scogogray' >{title} : </span>
        <span className='max-w-max text-font12 capitalize'>
            {address}
        </span>
        <span
            className='cursor-pointer material-icons text-scogobgsky text-font13 pt-1'
            onClick={openAddressInMap}
        >
            place
        </span>
        <br />
    </span>
}

const Card = ({ children, label, isOpen = true, divStyle = {} }) => {
    const [openCard, setOpenCard] = useState(isOpen);

    return <div className='my-4 mx-2 overflow-hidden' style={divStyle}>
        <div
            className={`w-full border pb-3 p-2 bg-white  ${openCard ? 'h-auto' : 'h-14 '} `}
            style={{
                'box-shadow': ' 0px 3px 6px #00000029',
                'border-radius': '10px',
            }}
        >
            <div
                className='ribbon col-span-2'
                onClick={() => {
                    setOpenCard(!openCard);
                }}
            >
                <span className='ribbon3 bg-scogobgsky ribbonblue'>{label}</span>
                <div className='absolute right-2 flex gap-x-3 top-1'>
                    <IconToolTip title={openCard ? 'Close' : ' Open'}>
                        <span className='material-icons flex cursor-pointer text-font24  mr-4 text-scogoprimary  hover:text-scogoorange'>
                            {openCard ? 'keyboard_arrow_up' : 'expand_more'}
                        </span>
                    </IconToolTip>
                </div>
            </div>
            {children}
        </div>
    </div>
}

const ActionButtons = ({ serialNumber }) => {
    let url = `https://${serialNumber}.configure.development.scogo.in/`;
    if (config.name === production.name) {
        url = `https://${serialNumber}.configure.production.scogo.in/`;
    }
    const redirectToConfiguration = () => {
        window.open(url)
    }

    return <div className="flex gap-4 flex-wrap">
        <ButtonScogoPrimary
            buttonClass='h-16'
            textOrComponent={
                <div className="flex items-center gap-1">
                    <img className='h-8' src={process.env.PUBLIC_URL + '/img/configure.png'} alt='Configure' />
                    <span className="font-medium">Configure</span>
                </div>
            }
            onClick={redirectToConfiguration}
        />
    </div>
}

const RenderSupportTicketIcon = ({ onClick = () => { } }) => {
    return <div className={`fixed z-100 rounded-full cursor-pointer`}
        style={{
            bottom: '7rem',
            right: '2rem',
            height: '5rem',
            width: '5rem'
        }}
    >
        <div className='flex flex-col items-center'>
            <img onClick={onClick} alt='chat' src={process.env.PUBLIC_URL + '/img/chat_colourful.png'} />
            <span className='text-scogoprimary font-medium text-font13'>Support</span>
        </div>
    </div>
}


const LinkDetails = ({ index, isp_name, isp_plan_name, isp_backhaul_name, static_ip, static_ip_available }) => {
    return <>
        <RenderCardValue title={`Link ${index} ISP`} value={isp_name} />
        <RenderCardValue title={`Link ${index} Plan`} value={isp_plan_name} />
        <RenderCardValue title={`Link ${index} Backhaul Name`} value={isp_backhaul_name} />
        <RenderCardValue title={`Static IP Available`} value={static_ip_available} />
        <RenderCardValue title={`Static IP`} value={static_ip} />
    </>
}