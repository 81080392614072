import React from 'react'
import { isMobileView } from '../pages/auth/components/NewScogoButton';

const InputFiels = ({ inputImg, inputClass, inputImgDiv, inputDiv, error, errorMessage, inputRef, errorClass, ...rest }) => {
    return (
        <div className={inputDiv}>
            {inputImg && <div><img className={inputImgDiv} src={inputImg} alt='' /></div>}
            <input ref={inputRef} className={inputClass} {...rest} style={error ? { borderColor: 'red', 'height': isMobileView ? '4.5rem' : '3.5rem' } : { 'height': isMobileView ? '4.5rem' : '3.5rem' }} />
            {error && (
                <span className={`flex items-center font-medium tracking-wide text-red-500 text-lg mt-1 ml-1 ${errorClass} `}>
                    {errorMessage ? errorMessage : 'Invalid Input'}
                </span>
            )}
        </div>
    );
}

export default InputFiels
