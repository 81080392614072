import { useNavigatorOnLine } from "../utils/common";
import React from 'react';

export const OfflineMessage = () => {
    const isOnline = useNavigatorOnLine();
    if (!isOnline) {
        return (
            <div
                className={`${!isOnline ? 'animate-enter' : 'animate-leave'
                    }  bg-scogoToastWarning w-fit shadow-lg rounded-lg pointer-events-auto flex justify-between ring-1 ring-black ring-opacity-5 mx-auto`}
                style={{ position: 'fixed', top: '3rem', zIndex: 999999, left: 0, right: 0 }}
            >
                <div className="flex-1 w-58 p-3">
                    <div className="flex items-center">
                        <div className="flex items-center">
                            <span className={`material-icons text-scogoToastWarningIcon  cursor-pointer `}>warning</span>
                        </div>
                        <div className="ml-2 ">
                            <p className={`text-xl text-scogoToastWarning font-semibold`}>
                                You are offline
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return <></>;
}