import _ from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';

const Radio = ({ id, name, label, required = false, className = '', shouldUnregister, padding = 'px-2 py-2', options, onChange: onRadioChange = () => { }, readOnly = false, requiredMessage = 'Required' }) => {
    const {
        formState: { errors },
        control,
    } = useFormContext();

    return (
        <div
            className={`block ${padding} ${className}`}
            style={{
                minHeight: 0,
                minWidth: 0,
            }}
        >
            <Controller
                key={name}
                control={control}
                name={name}
                rules={{ required: { value: required, message: requiredMessage } }}
                shouldUnregister={shouldUnregister}
                render={({ field: { onChange, value, name: fieldName } }) => (
                    <>
                        <p className={'font-normal text-font11'}>{label}</p>
                        <div className={`${className} flex gap-2 items-center text-sm`}>
                            {options.map((radio, index) => (
                                <label
                                    key={`radio_${fieldName}_${id}_${index}`}
                                    className={`inline-block font-normal text-font11 ${value === radio.value ? '' : ` text-scogogray`} flex items-center`}
                                >
                                    <input
                                        id={`radio_${fieldName}_${id}_${index}`}
                                        name={fieldName}
                                        type='radio'
                                        className='h-4 w-4 focus:ring-0 focus:ring-offset-0 mr-2 text-scogoorange'
                                        checked={value === radio.value}
                                        value={radio.value}
                                        onChange={() => {
                                            onChange(radio.value);
                                            onRadioChange(radio.value)
                                        }}
                                        disabled={readOnly}
                                    />
                                    <p>{radio?.label}</p>
                                    <span>{required && <span className='text-scogoorange text-font12'>*</span>}</span>
                                </label>
                            ))}
                        </div>
                    </>
                )}
            />
            <p className='text-scogoclosed text-font08 font-normal inputFontSize h-6'>{_.get(errors, name) ? _.get(errors, name).message : ''}</p>
        </div>
    );
};

export default Radio;
