import _ from 'lodash';
import { useFormContext, Controller } from 'react-hook-form';

const Options = ({ name, label, required, options, shouldUnregister, className = '', readOnly, onChange: onSelectionChange = () => { } }) => {
    const {
        formState: { errors },
        control,
    } = useFormContext();
    return (
        <div className={`w-full py-2 rounded-md tab-button-container ${className}`}>
            {label && (
                <label>
                    <span className='text-scogo99 font-normal text-font09 md:text-font11 mx-4'>
                        {label} {required && <span className='text-scogoorange'>*</span>}{' '}
                    </span>
                </label>
            )}
            <Controller
                key={name}
                control={control}
                name={name}
                rules={{ required: { value: required, message: 'Required' } }}
                shouldUnregister={shouldUnregister}
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                    <div className='flex pt-2 ml-4'>
                        {options.map((option) => {
                            let optionText = option,
                                optionSelectionColor = 'bg-scogoorange text-white',
                                valueText = value;
                            if (typeof option === 'object') {
                                optionText = option.text;
                                optionSelectionColor = option.selectionColor;
                                valueText = value?.text;
                            }
                            return (
                                <button
                                    key={optionText}
                                    type='button'
                                    disabled={readOnly}
                                    className={`whitespace-nowrap capitalize ${readOnly ? 'cursor-not-allowed' : 'cursor-pointer hover:bg-scogolightblue'} font-medium px-5 py-1 text-font09 md:text-font11 border border-zinc-5 ${valueText === optionText ? optionSelectionColor : ' text-scogogray '
                                        }`}
                                    onClick={() => { onChange(option); onSelectionChange(option); }}
                                >
                                    {optionText}
                                </button>
                            );
                        })}
                    </div>
                )}
            />
            <p className='text-scogoclosed text-font08 font-normal inputFontSize h-6'>{_.get(errors, name) ? _.get(errors, name).message : ''}</p>
        </div>
    );
};

export default Options;
