export const GET_SIGN_OFF_TEMPLATES_REQUEST = 'GET_SIGN_OFF_TEMPLATES_REQUEST';
export const GET_SIGN_OFF_TEMPLATES_SUCCESS = 'GET_SIGN_OFF_TEMPLATES_SUCCESS';
export const GET_SIGN_OFF_TEMPLATES_FAILURE = 'GET_SIGN_OFF_TEMPLATES_FAILURE';

export const LIST_ALL_STATE_REQUEST = 'LIST_ALL_STATE_REQUEST';
export const LIST_ALL_STATE_SUCCESS = 'LIST_ALL_STATE_SUCCESS';
export const LIST_ALL_STATE_FAILURE = 'LIST_ALL_STATE_FAILURE';

export const LIST_ALL_CITY_REQUEST = 'LIST_ALL_CITY_REQUEST';
export const LIST_ALL_CITY_SUCCESS = 'LIST_ALL_CITY_SUCCESS';
export const LIST_ALL_CITY_FAILURE = 'LIST_ALL_CITY_FAILURE';

export const GET_ALL_STATE_CITIES_REQUEST = 'GET_ALL_STATE_CITIES_REQUEST';
export const GET_ALL_STATE_CITIES_SUCCESS = 'GET_ALL_STATE_CITIES_SUCCESS';
export const GET_ALL_STATE_CITIES_FAILURE = 'GET_ALL_STATE_CITIES_FAILURE';
export const CLEAR_STATE_CITIES_FROM_STORE = 'CLEAR_STATE_CITIES_FROM_STORE';

export const ADD_NEW_PINCODE_REQUEST = 'ADD_NEW_PINCODE_REQUEST';
export const ADD_NEW_PINCODE_FAILURE = 'ADD_NEW_PINCODE_FAILURE';
export const ADD_NEW_PINCODE_SUCCESS = 'ADD_NEW_PINCODE_SUCCESS';

export const LIST_ISSUE_CATEGORIES_REQUEST = 'LIST_ISSUE_CATEGORIES_REQUEST';
export const LIST_ISSUE_CATEGORIES_SUCCESS = 'LIST_ISSUE_CATEGORIES_SUCCESS';
export const LIST_ISSUE_CATEGORIES_FAILURE = 'LIST_ISSUE_CATEGORIES_FAILURE';
export const CLEAR_ISSUE_CATEGORIES = 'CLEAR_ISSUE_CATEGORIES';

export const LIST_ALL_DISTRICT_REQUEST = 'LIST_ALL_DISTRICT_REQUEST';
export const LIST_ALL_DISTRICT_SUCCESS = 'LIST_ALL_DISTRICT_SUCCESS';
export const LIST_ALL_DISTRICT_FAILURE = 'LIST_ALL_DISTRICT_FAILURE';

export const LIST_ALL_LOCALITY_REQUEST = 'LIST_ALL_LOCALITY_REQUEST';
export const LIST_ALL_LOCALITY_SUCCESS = 'LIST_ALL_LOCALITY_SUCCESS';
export const LIST_ALL_LOCALITY_FAILURE = 'LIST_ALL_LOCALITY_FAILURE';

export const CLEAR_LOCALITY_LIST_FROM_STORE = 'CLEAR_LOCALITY_LIST_FROM_STORE';
export const CLEAR_DISTRCT_LIST_FROM_STORE = 'CLEAR_DISTRCT_LIST_FROM_STORE';