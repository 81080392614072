import React from 'react';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { expenseTypes } from './PoApprovalForm';
import Select from '../../../common/Form/Select';
import Input from '../../../common/Form/Input';
import TextArea from '../../../common/Form/TextArea';

export const PODetails = ({ ticketPODetails, approversArrayName }) => {
    const { setValue } = useFormContext();

    useEffect(() => {
        if (ticketPODetails?.poNumber) {
            let expenseType = expenseTypes.find((type) => ticketPODetails?.expenseType === type.value);
            setValue('poNumber', ticketPODetails?.poNumber);
            setValue('expenseType', expenseType);
        }
    }, [ticketPODetails?.poNumber, ticketPODetails?.expenseType, setValue, ticketPODetails?.approvalTeams]);

    return (
        <div className='px-3 grid grid-cols-3 rows-fr grid-flow-row-dense'>
            <Select className='grow' style={{ minWidth: '20%' }} name={`expenseType`} label='Expense Type' options={expenseTypes} required shouldUnregister />
            <Input label='PO Number' required name='poNumber' type={'text'} />
            <TextArea label='Remark' required name='job_sign_off_notes' type={'text'} />
        </div>
    );
};
