import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import DetailTag from '../../../pages/project/DetailTag';
import { getSpareCategories, getInventoryPartcodes, getAssetsTypes, createInventorySpareAssets, findAllRepairCenters, getEndUsersList } from '../../../actions/inventory';
import { InventoryFormFields } from '../../Inventory/InventoryController';
import { textCss } from '../../../common/Css';
import { getCustomersList } from '../../../actions/customer';
import moment from 'moment';
import { actualValueForDropdown, isTruthyValue } from '../../../utils/common';
import { sendToastMessage } from '../../../actions/toast';
import Form from '../../../common/Form';
import Input from '../../../common/Form/Input';
import Select from '../../../common/Form/Select';
import { useForm, useFormContext } from 'react-hook-form';
import Checkbox from '../../../common/Form/Checkbox';
import DatePicker from '../../../common/Form/DatePicker';

const warrantyType = [
    { key: 1, value: 'Manufacturing', label: 'Manufacturing' },
    { key: 2, value: 'Installation', label: 'Installation' },
    { key: 3, value: 'Billing', label: 'Billing' },
];
const workingCondition = [
    { key: 1, value: 'NEW', label: 'New' },
    { key: 2, value: 'REFURBISHED', label: 'Refurbished' },
    { key: 3, value: 'FAULTY', label: 'Faulty' },
];

const assetsReceivedAs = [
    { key: 0, value: 'NEW', label: 'New' },
    { key: 1, value: 'REPLACEMENT', label: 'Replacement' },
];

const formName = 'addSpareAssets';

const AddSpareAsset = ({ modalData, customerId }) => {
    const dispatch = useDispatch();
    const isToShowCustomerDropdown = InventoryFormFields('addNewSpareAssetCustomerField');
    const warehouseId = modalData?.id;
    const { formButtonLoading } = useSelector((state) => state.loading);

    const defaultValues = {
        customer_id: modalData.customer ? { key: modalData.customer.customer_company_name, label: modalData.customer.customer_company_name, value: modalData.customer.customer_id } : '',
        warehouse_id: warehouseId,
        asset_received_as: '',
        category: '',
        external_order_id: '',
        frn: '',
        grn: '',
        hasFrn: '',
        hasGrn: '',
        hasOem: '',
        hasWarranty: '',
        inward_source: '',
        make: '',
        model: '',
        oem_end_date: '',
        oem_id: '',
        oem_start_date: '',
        oem_warranty_type: '',
        partcode: '',
        quantity: '',
        serial_number: '',
        status: '',
        title: '',
        title_name: '',
        type: '',
        unit: '',
        value: '',
        warranty_end_date: '',
        warranty_start_date: '',
        warranty_type: '',
        added_on: '',
        working_condition: workingCondition[0],
    };

    const getDataOnMount = useCallback(() => {
        dispatch(getSpareCategories({ dropdown: true }));
        dispatch(getInventoryPartcodes({ customerId, dropdown: true }));
        dispatch(findAllRepairCenters({ customerId, dropdown: true }));
        dispatch(findAllRepairCenters({ customerId, dropdown: true }));
        if (isToShowCustomerDropdown) dispatch(getCustomersList());
    }, [dispatch, isToShowCustomerDropdown, customerId]);

    useEffect(() => {
        getDataOnMount();
    }, [getDataOnMount]);

    useEffect(() => {
        dispatch(getEndUsersList({ customer_id: modalData.customer_id }))
    }, [dispatch, modalData.customer_id]);

    const submit = (formValues) => {
        const customer_id = actualValueForDropdown(formValues.customer_id);
        const warehouse_id = actualValueForDropdown(formValues.warehouse_id);

        let singleSpareAsset = {
            asset_received_as: actualValueForDropdown(formValues.asset_received_as),
            category: actualValueForDropdown(formValues.category),
            customer_id,
            external_order_id: formValues.external_order_id,
            hasFrn: formValues.hasFrn,
            hasGrn: formValues.hasGrn,
            hasOem: formValues.hasOem,
            hasWarranty: formValues.hasWarranty,
            inward_source: formValues?.inward_source?.label,
            oem_end_date: formValues.oem_end_date ? moment(formValues.oem_end_date).format('YYYY-MM-DD') : '',
            oem_id: formValues.oem_id,
            oem_start_date: formValues.oem_start_date ? moment(formValues.oem_start_date).format('YYYY-MM-DD') : '',
            oem_warranty_type: formValues.oem_warranty_type,
            warranty_end_date: formValues.warranty_end_date ? moment(formValues.warranty_end_date).format('YYYY-MM-DD') : '',
            warranty_start_date: formValues.warranty_start_date ? moment(formValues.warranty_start_date).format('YYYY-MM-DD') : '',
            warranty_type: actualValueForDropdown(formValues.warranty_type),
            working_condition: actualValueForDropdown(formValues.working_condition),
            warehouse_id,
            enduser_id: actualValueForDropdown(formValues.enduser_id),
        };

        Object.entries(singleSpareAsset).forEach(([key, value]) => {
            if (!isTruthyValue(value)) delete singleSpareAsset[key];
        });

        singleSpareAsset = Object.assign(
            {
                frn: formValues.frn,
                grn: formValues.grn,
                make: formValues.make,
                model: formValues.model,
                type: formValues.type,
                unit: formValues.unit,
                value: formValues.value,
                partcode: actualValueForDropdown(formValues.partcode),
                serial_number: formValues.serial_number,
                title: actualValueForDropdown(formValues.title),
                quantity: formValues.quantity,
                status: formValues.status,
                added_on: formValues.added_on ? moment(formValues.added_on).format('YYYY-MM-DD') : '',
            },
            singleSpareAsset
        );

        let data = { spareassets: [singleSpareAsset] };

        const hasValidCustomerId = Number.isInteger(customer_id) && customer_id > 0;
        const hasValidWarehouseId = Number.isInteger(warehouse_id) && warehouse_id > 0;

        if (hasValidCustomerId && hasValidWarehouseId) {
            dispatch(createInventorySpareAssets({ data, customerId, warehouseId, formName }));
        } else {
            dispatch(sendToastMessage({ status: 'danger', message: 'Invalid Data' }));
        }
    };

    const closeModal = () => {
        dispatch(closeModalAction());
    };

    const methods = useForm({ defaultValues, mode: 'all' });

    return (
        <>
            <Form
                className='w-full'
                onSubmit={submit}
                onCancel={closeModal}
                defaultValues={defaultValues}
                methods={methods}
                showSubmit
                submitContainerClassName='flex gap-2 justify-end py-4 px-4'
                isLoading={formButtonLoading[formName]}
            >
                <AddSpareAssetInputs modalData={modalData} isToShowCustomerDropdown={isToShowCustomerDropdown} />
            </Form >
        </>
    );
};

const AddSpareAssetInputs = ({ modalData, isToShowCustomerDropdown }) => {
    const { isCustomerListLoading, isAssetTypeLoading, isEndUserListLoading } = useSelector((state) => state.loading);
    const { spareCategory, partCodes, repaircentersDropdown, endUsersList } = useSelector((state) => state.inventory);
    const { customers } = useSelector((state) => state.customer);
    const { assetTypes } = useSelector((state) => state.inventory);
    const dispatch = useDispatch();
    const [titleOptions, setTitleOptions] = useState([]);

    useEffect(() => {
        if (Array.isArray(assetTypes)) {
            setTitleOptions(assetTypes);
        }
    }, [assetTypes]);

    const customerDropdown = useMemo(() => {
        if (Array.isArray(customers)) {
            return customers.filter((item) => {
                if (item.is_rma_eligible === 1) {
                    return {
                        key: item.customer_id,
                        value: item.customer_id,
                        label: item.label,
                        customer_id: item.customer_id,
                    };
                }
                return false;
            });
        }
    }, [customers]);

    const { watch } = useFormContext();
    const hasGrn = watch('hasGrn');
    const hasFrn = watch('hasFrn');
    const hasWarranty = watch('hasWarranty');
    const hasOem = watch('hasOem');
    const customer_id = watch('customer_id');

    const getUpdatedDropdownDataWhenCustomerChanged = useCallback(
        (customerId) => {
            dispatch(getInventoryPartcodes({ customerId, dropdown: true }));
            dispatch(findAllRepairCenters({ customerId, dropdown: true }));
        },
        [dispatch]
    );

    useEffect(() => {
        if (isToShowCustomerDropdown) getUpdatedDropdownDataWhenCustomerChanged(customer_id);
    }, [customer_id, getUpdatedDropdownDataWhenCustomerChanged, isToShowCustomerDropdown]);

    return (
        <>
            {modalData?.id && <WarehouseDetails modalData={modalData} />}
            <div className='px-5'>
                <div className={`grid rows-fr grid-flow-row-dense ${isToShowCustomerDropdown ? 'grid-cols-3' : 'grid-cols-2'}`}>
                    <Select label='Customer' required name='customer_id' options={customerDropdown} isLoading={isCustomerListLoading} shouldUnregister />
                    <Select label='Asset Received As' required name='asset_received_as' options={assetsReceivedAs} />
                    <Select label='Inward Source' required name='inward_source' options={repaircentersDropdown} />
                </div>
                <div className='grid grid-cols-3 rows-fr grid-flow-row-dense'>
                    <Select
                        label='Category'
                        required
                        name='category'
                        options={spareCategory}
                        onChange={(e) => {
                            if (e?.value) {
                                dispatch(getAssetsTypes({ customerId: customer_id, categoryId: e?.value, type: 'SPARE', dropdown: true }));
                            }
                            if (!e?.value) setTitleOptions([]);
                        }}
                    />
                    <Select label='Title' required name='title' type={'dropdown'} options={titleOptions} isLoading={isAssetTypeLoading} />
                    <Select label='End User' name='enduser_id' type={'dropdown'} options={endUsersList} isLoading={isEndUserListLoading} />
                </div>
                <div className='grid grid-cols-3 rows-fr grid-flow-row-dense'>
                    <Input label='Make' name='make' type={'text'} />
                    <Input label='Model' name='model' type={'text'} />
                    <Select label='Partcode' name='partcode' type={'dropdown'} options={partCodes} />
                </div>
                <div className='grid grid-cols-3 rows-fr grid-flow-row-dense'>
                    <Input label='Serial Number' name='serial_number' type={'text'} />
                    <Input label='Type' type={'text'} name='type' />
                    <Input label='External Order Id' type={'text'} name='external_order_id' />
                </div>
                <div className='grid grid-cols-3 rows-fr grid-flow-row-dense'>
                    <Input label='Quantity' type={'number'} name='quantity' />
                    <Input label='Unit' type={'number'} name='unit' />
                    <Input label='Price (₹)' type={'number'} name='value' step={'any'} />
                </div>
                <div className='grid grid-cols-2 rows-fr grid-flow-row-dense'>
                    <Select label='Working Condition' name='working_condition' options={workingCondition} />
                    <DatePicker label='Added On' name='added_on' dateFormat='dd-MM-yyyy' />
                </div>
                <div className='px-1'>
                    <Checkbox id='hasGrn' label='Do you have GRN' name='hasGrn' />
                </div>
                {hasGrn && <Input label='GRN' type={'text'} name='grn' />}
                <div className='px-1'>
                    <Checkbox id='hasFrn' label='Do you have FRN' name='hasFrn' />
                </div>
                {hasFrn && <Input label='FRN' type={'text'} name='frn' />}
                <div className='px-1'>
                    <Checkbox id='hasWarranty' label='Do you have Warranty Details' name='hasWarranty' />
                </div>
                {hasWarranty && (
                    <>
                        <div className='grid grid-cols-3 rows-fr grid-flow-row-dense'>
                            <Select label='Warranty Type' name='warranty_type' type={'dropdown'} options={warrantyType} />
                            <DatePicker label='Warranty Start Date' name='warranty_start_date' dateFormat='dd-MM-yyyy' />
                            <DatePicker label='Warranty End Date' name='warranty_end_date' dateFormat='dd-MM-yyyy' />
                        </div>
                    </>
                )}{' '}
                <div className='px-1'>
                    <Checkbox id='hasOem' label='Do you have OEM Details' name='hasOem' />
                </div>
                {hasOem && (
                    <>
                        <div className='grid grid-cols-4 rows-fr grid-flow-row-dense'>
                            <Input label='OEM Id' type={'text'} name='oem_id' />
                            <Input label='OEM Type' type={'text'} name='oem_warranty_type' />
                            <DatePicker label='OEM Start Date' name='oem_start_date' dateFormat='dd-MM-yyyy' />
                            <DatePicker label='OEM End Date' name='oem_end_date' dateFormat='dd-MM-yyyy' />
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default AddSpareAsset;

const WarehouseDetails = ({ modalData }) => {
    return (
        <>
            <div className='md:flex pl-5 pt-3'>
                <DetailTag
                    tagHeadClass={textCss?.detailsTitle}
                    tagValueClass={textCss?.detailsValue}
                    tagClass=' px-2 md:w-6/12'
                    tagName='Warehouse'
                    tagValue={modalData?.name ? modalData?.name : ''}
                />
                {
                    <DetailTag
                        tagHeadClass={textCss?.detailsTitle}
                        tagValueClass={textCss?.detailsValue}
                        tagClass='px-2 md:w-6/12'
                        tagName='Address'
                        tagValue={modalData?.address ? modalData?.address : ''}
                    />
                }
                {(modalData?.state || modalData?.city) && (
                    <DetailTag
                        tagHeadClass={textCss?.detailsTitle}
                        tagValueClass={textCss?.detailsValue}
                        tagClass='px-2 md:w-6/12'
                        tagName='City & State'
                        tagValue={`${modalData?.state ? modalData?.state : ''}, ${modalData?.city ? modalData?.city : ''}`}
                    />
                )}
            </div>

            <div className='md:flex pl-5 pt-6'>
                <DetailTag
                    tagHeadClass={textCss?.detailsTitle}
                    tagValueClass={textCss?.detailsValue}
                    tagClass='px-2 md:w-6/12 '
                    tagName='Contact Name'
                    tagValue={modalData?.warehouseContactName ? modalData.warehouseContactName : ''}
                />
                <DetailTag
                    tagHeadClass={textCss?.detailsTitle}
                    tagValueClass={textCss?.detailsValue}
                    tagClass='px-2 md:w-6/12 '
                    tagName='Contact Mobile'
                    tagValue={modalData?.warehouseContactPhone ? modalData.warehouseContactPhone : ''}
                />
                <DetailTag
                    tagHeadClass={textCss?.detailsTitle}
                    tagValueClass={textCss?.detailsValue}
                    tagClass='px-2 md:w-6/12'
                    tagName='Contact Email'
                    tagValue={modalData?.warehouseContactEmail ? modalData.warehouseContactEmail : ''}
                />
            </div>
        </>
    );
};
