import { useState, forwardRef } from 'react';
import { styled, alpha } from '@mui/material/styles';
import { Menu, Divider, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { sendToastMessage } from '../actions/toast';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 188,
        maxHeight: 250,
        color: theme.palette.mode === 'light' ? 'rgba(55, 65, 81, var(--tw-text-opacity))' : theme.palette.grey[300],
        boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '5',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
            },
        },
    },
}));

const menuStyle = {
    fontSize: '1.2rem',
    lineHeight: '1.25rem',
};

const disabledCss = {
    color: '#888888',
    fontSize: '1.2rem',
    lineHeight: '1.25rem',
    cursor: 'not-allowed',
};

const DropdownMenu = forwardRef(({ iconColor, componentOrIcon, dropDownClass, menuData, ...props }, ref) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { loggedUser } = useSelector((state) => state.auth);
    let frontend_controller = loggedUser.frontend_controller;
    const dispatch = useDispatch();
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event) => {
        setAnchorEl(null);
        event.stopPropagation()

    };

    const getDropdownType = (componentOrIcon) => {

        if (componentOrIcon) {
            return (
                <div className='flex items-center'>
                    <span onClick={handleClick} className={`material-icons text-font18 hover:text-scogoorange cursor-pointer ${dropDownClass}`}>
                        {typeof componentOrIcon === 'function' ? componentOrIcon() : componentOrIcon}
                    </span>
                </div>
            );
        }
        return <></>;
    };

    const isMenuData = menuData?.length > 0;

    if (!isMenuData) return <></>;
    return (
        <div {...props} ref={ref}>
            {getDropdownType(componentOrIcon)}
            <StyledMenu id='demo-customized-menu' MenuListProps={{ 'aria-labelledby': 'demo-customized-button' }} anchorEl={anchorEl} open={open} onClose={handleClose}>
                {menuData?.map((action, index) => {
                    let disabled = false,
                        disabledMessage = '';
                    if (typeof action?.isDisabled === 'function') {
                        ({ disabled, message: disabledMessage = 'Disabled' } = action.isDisabled(props.item, props?.payload));
                    }

                    if (typeof action?.conditionalRendering === 'function') {
                        const actionStyleData = action.conditionalRendering(props?.item, dispatch, props?.payload, frontend_controller);

                        if (actionStyleData?.menuName) action.menuName = actionStyleData?.menuName;
                        if (actionStyleData?.iconColor) action.iconColor = actionStyleData?.iconColor;
                        if (actionStyleData?.menuIcon) action.menuIcon = actionStyleData?.menuIcon;
                        if (actionStyleData?.imgIcon) action.imgIcon = actionStyleData?.imgIcon;
                    }
                    return (
                        <div key={index} className={disabled ? 'hover:bg-disabled' : 'hover:bg-actionHover'}>
                            <MenuItem
                                style={disabled ? disabledCss : menuStyle}
                                disableRipple
                                onClick={(event) => {

                                    if (disabled) {
                                        dispatch(sendToastMessage({ status: 'warning', message: disabledMessage }));
                                    } else {
                                        action?.onClick(props?.item, dispatch, props?.payload);
                                    }
                                    handleClose();
                                }}
                            >
                                <>
                                    {action?.subMenus ? (
                                        <SubMenu disabled={disabled} action={action} iconColor={iconColor} payload={props?.payload} item={props?.item} />
                                    ) : (
                                        <MenuList disabled={disabled} action={action} payload={props?.payload} item={props?.item} />
                                    )}
                                </>
                            </MenuItem>
                            {action.divider && <Divider sx={{ my: 0.5 }} />}
                        </div>
                    );
                })}
            </StyledMenu>
        </div>
    );
});
export default DropdownMenu;

const SubMenu = ({ action, payload, item, disabled, iconColor }) => {
    return (
        <DropdownMenu
            payload={payload}
            menuData={action?.subMenus}
            item={item}
            style={{ width: '100%', position: 'relative' }}
            componentOrIcon={
                <div className={`flex items-center text-font12 ${action?.iconColor}`}>
                    <MenuList disabled={disabled} action={action} showRightArrow />
                </div>
            }
        />
    );
};

const MenuList = ({ disabled, action, iconColor, showRightArrow, imgIcon }) => {
    return (
        <>
            {action?.imgIcon ? <img src={process.env.PUBLIC_URL + action.imgIcon} alt='' className={`mr-3 w-11 inline-block ${disabled ? "opacity-60" : ""}`} /> :
                <span className={`material-icons ${disabled ? 'text-scogogray' : action?.iconColor ? action.iconColor : iconColor} text-font17 mr-4`}>{action.menuIcon}</span>}
            <span>{action?.menuName}</span>
            {showRightArrow && <span className='material-icons text-font17 absolute right-0 hover:text-scogoorange'> navigate_next </span>}
        </>
    );
};