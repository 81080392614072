import React, { useEffect, useState } from 'react';
import { firebaseDb } from '../../../firebase';
import { ref, onValue } from 'firebase/database';
import {
    useJsApiLoader,
    GoogleMap,
    Marker,
    DirectionsRenderer
} from "@react-google-maps/api";
import config from '../../../config';
import TrackingDetailsNotFound from './TrackingDetailsNotFound';
import { SkeltonCardTwo } from '../../../common/SkeltonCardTwo';

export default function RenderTrack({ ticketId, userId, address, site_lat = 0, site_lng = 0 }) {
    const [positionFe, setPositionFe] = useState({});
    const [siteMarker, setSiteMarker] = useState({ lat: Number(site_lat), lng: Number(site_lng) });
    const [direction, setDirections] = useState({});
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: config.googleMapApiKey
    });
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const locationRef = ref(firebaseDb, `locations`);
        const unsubscribe = onValue(locationRef, (snapshot) => {
            const data = snapshot.val();
            const currentUser = data && data[userId];
            const currentTicket = currentUser && currentUser[ticketId];
            if (currentTicket) {
                const location = { lat: currentTicket.latitude, lng: currentTicket.longitude }
                setPositionFe(location);
            } else {
                setPositionFe({});
            }
            setIsLoading(false);
        });
        return () => unsubscribe();
    }, [ticketId, userId]);

    useEffect(() => {
        if (positionFe.lat && siteMarker.lat) {
            const directionsService = new window.google.maps.DirectionsService();
            directionsService.route(
                {
                    origin: positionFe,
                    destination: siteMarker,
                    travelMode: 'DRIVING',
                },
                (result, status) => {

                    if (status === 'OK') {
                        setDirections(result);
                    } else {
                        console.error(`Directions request failed due to ${status}`);
                    }
                }
            );
        }
    }, [positionFe, setSiteMarker, siteMarker]);
    let defaultIcon
    if (isLoaded) {
        defaultIcon = {
            url: process.env.PUBLIC_URL + '/img/yellow-marker.png',
            scaledSize: new window.google.maps.Size(50, 50),
        }
    };

    const showGoogleMap = positionFe.lat && siteMarker.lat;
    if (isLoading) {
        return <div className='px-6 w-full h-full bg-white overflow-hidden'> <SkeltonCardTwo arr={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]} height={20} className={'my-4'} /></div>
    }
    if (!showGoogleMap && isLoaded) {
        return <TrackingDetailsNotFound />
    }

    return <div className='w-full h-full'>
        {!isLoading && showGoogleMap ? (<>

            <GoogleMap
                center={positionFe}
                zoom={20}
                mapContainerStyle={{ width: "100%", height: "100vh" }}
                options={{
                    zoomControl: false,
                    streetViewControl: false,
                    mapTypeControl: false,
                    fullscreenControl: false,
                }}
            >
                <Marker position={positionFe} icon={defaultIcon} />
                <Marker position={siteMarker} />

                {direction && <DirectionsRenderer directions={direction} options={{ suppressMarkers: true }}
                />}
            </GoogleMap>
        </>) : (
            <></>
        )}
    </div>
}