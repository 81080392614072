import { useFormContext, Controller } from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';
import _ from 'lodash';

const DateRange = ({ label, name, required, placeholder, className = '', showTimeSelect, showTimeSelectOnly, dateFormat, shouldUnregister, onChangeHandler }) => {
    const {
        formState: { errors },
        control,
    } = useFormContext();

    return (
        <div className={`px-4 py-2 ${className}`}>
            {label && (
                <label>
                    <span className='text-scogo99 font-normal text-font09 md:text-font11'>
                        {label} {required && <span className='text-scogoorange'>*</span>}{' '}
                    </span>
                </label>
            )}
            <div className=' relative'>
                <Controller
                    key={name}
                    control={control}
                    name={name}
                    rules={{ required: { value: required, message: 'Required' } }}
                    shouldUnregister={shouldUnregister}
                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                        <ReactDatePicker
                            placeholderText={placeholder}
                            className={`border w-full border-scogoccc focus:border-scogoprimary pl-10 rounded-6px bg-white text-scogo99 font-normal text-font09 md:text-font11`}
                            selectsRange={true}
                            startDate={value && value?.[0] ? value?.[0] : null}
                            endDate={value && value?.[1] ? value?.[1] : null}
                            isClearable={false}
                            dateFormat={showTimeSelect ? (showTimeSelectOnly ? 'h:mm a' : dateFormat ? dateFormat : 'dd/MM/yyyy HH:mm') : 'dd/MM/yyyy'}
                            name={name}
                            popperProps={{ strategy: 'fixed' }}
                            onChange={(event) => {
                                onChange(event);
                                if (onChangeHandler) {
                                    onChangeHandler(event);
                                }
                            }}
                        />
                    )}
                />
                <span className='material-icons-outlined text-scogoorange absolute left-2 top-2 font-normal text-font16'>event</span>
                <p className='text-scogoclosed text-font08 font-normal inputFontSize h-6'>{_.get(errors, name) ? _.get(errors, name).message : ''}</p>
            </div>
        </div>
    );
};

export default DateRange;
