import IconToolTip from '../../../../common/IconToolTip';

export default function SystemGeneratedInvoice({ link, number }) {
    return (
        <p className='text-scogoprimary text-font11 cursor-pointer hover:text-scogoorange line-clamp-1'>
            <IconToolTip title={`System Generated: ${number}`}>
                <a href={link} target='_blank' rel='noreferrer'>
                    {number}
                </a>
            </IconToolTip>
        </p>
    );
}
