import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addMutlipleProjectsInTeams, getLinkedProjectOfTeams } from '../../../actions/project';
import { closeModalAction } from '../../../actions/modal';
import { getProjectDropDown } from '../../../actions/project';
import { SkeltonCardOne } from '../../../common/SkeltonCardOne';
import { getCustomerIdOfLoginUser } from '../../../utils/utils';
import { useForm } from 'react-hook-form';
import Form from '../../../common/Form';
import Select from '../../../common/Form/Select';

const formName = 'addProjectInTeamsForm';
export default function AddProjectInTeams(props) {
    const { team } = props;
    const { loggedUser } = useSelector((state) => state.auth);
    const customerId = getCustomerIdOfLoginUser(loggedUser);
    const { isProjectDropDownListLoading, formButtonLoading } = useSelector((state) => state.loading);
    const { projectDropdownList } = useSelector((state) => state.project);
    const dispatch = useDispatch();
    const { selectedTeamUserDetails, teamSideTabName } = useSelector((state) => state.customer);
    const { linkedTeamsProjects } = useSelector((state) => state.project);

    useEffect(() => {
        const fk_customer_id = team?.fk_customer_id;
        let payload = {};
        if (fk_customer_id !== 0) {
            payload = { customerId: fk_customer_id };
        }

        dispatch(getProjectDropDown(payload));
    }, [dispatch, team?.fk_customer_id]);

    useEffect(() => {
        dispatch(getLinkedProjectOfTeams({ teamId: team.id }))
    }, [dispatch, team?.id]);

    const projectOptions = useMemo(() => {
        if (Array.isArray(projectDropdownList) && projectDropdownList.length > 0) {
            let list = projectDropdownList;
            if (linkedTeamsProjects?.length > 0) {
                let alreadySelectedIds = linkedTeamsProjects.map((project) => project.id);
                list = projectDropdownList.filter((project) => !alreadySelectedIds.includes(project.value));
            }
            return list
        }
        return [];
    }, [projectDropdownList, linkedTeamsProjects]);

    const defaultValues = {
        projects: '',
    };

    const submit = (form) => {
        let payload = {
            teamId: team.id,
            customerId,
            projectIds: form.projects.map((project) => project.value),
            formName,
        };
        if (teamSideTabName === 'teamLinkedProjects' && selectedTeamUserDetails?.id === team.id) {
            payload.callLinkedProjectList = true;
        }
        dispatch(addMutlipleProjectsInTeams(payload));
    };

    const methods = useForm({ defaultValues, mode: 'all' });
    if (isProjectDropDownListLoading) return <SkeltonCardOne />;

    return (
        <Form
            methods={methods}
            onSubmit={submit}
            submitButtonText={'Submit'}
            onCancel={() => dispatch(closeModalAction())}
            showSubmit
            submitContainerClassName='flex gap-2 justify-end py-4 px-4'
            isLoading={formButtonLoading[formName]}
        >
            <div className='px-3 flex justify-between'>
                <Select className='w-full' label='Project' name='projects' required options={projectOptions} isMulti />
            </div >
        </Form >
    );
}
