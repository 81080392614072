import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import DropdownMenu from '../../../common/MenuDropdown';
import Buttons from '../../../common/Buttons';
import { getProjectTeamUsersActions, getProjectTeamActions } from '../ProjectController'; //get project team actions
import Bedges from '../../../common/Badges';
import { iconLookup } from '../../../pages/team/TeamController';
import OverflowTip from '../../../common/OverflowTip';
import IconToolTip from '../../../common/IconToolTip';
import { textCss, badgeClass } from '../../../common/Css';
import { useSelector } from 'react-redux';
import UserPopup from '../../../common/Popups/User';
import { validateStringValue } from '../../../utils/common';
import StatusLabel from '../../Payments/reusableComponents/StatusLabel';

export const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

export const AccordionSummary = styled((props) => <MuiAccordionSummary expandIcon={<span className='material-icons text-scogoorange '>navigate_next</span>} {...props} />)(
    ({ theme }) => ({
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1),
        },
    })
);

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export const TeamsAccordian = (props) => {
    const { team, customerId, projectDetail } = props;
    const { loggedUser } = useSelector((state) => state.auth);
    const projectTeamAction = getProjectTeamActions(loggedUser.role.id, loggedUser.type, team);
    const teamType = team?.type?.toUpperCase();
    const cardImg = iconLookup[teamType];

    const styleIfBlackListUser = (agent) => {
        let style = textCss?.headerThree;
        if (agent?.isBlackListed) style = `${style} text-scogoclosed`;
        else style = `${style} text-scogo15`;
        return style;
    };

    return (
        <div className='bg-white'>
            <Accordion className=' bg-white px-2'>
                <div className='flex '>
                    <AccordionSummary className='flex justify-between w-full h-12 absolute' sx={{ padding: 0 }}>
                        <div className='flex w-full justify-between items-center'>
                            <div className='flex gap-2 items-center w-full'>
                                <Bedges className={`${cardImg.bgClass} ${badgeClass.teamsBadge} leading-5 text-center rounded-5px`}>
                                    <IconToolTip title={cardImg.type}>
                                        <span className='material-icons text-white text-2xl	'>{cardImg?.img}</span>
                                    </IconToolTip>
                                </Bedges>
                                <Typography className={`capitalize truncate items-center inline max-w-8`} >
                                    <OverflowTip
                                        textClass={`w-full ${textCss?.headerThree}`}
                                        someLongText={team.name}
                                    ></OverflowTip>
                                </Typography>
                                <Bedges>
                                    <span className={`bg-scogo99 text-white rounded-full h-7 w-7 leading-7 text-center 2xl:text-font12 text-font10 font-medium`}>
                                        {team?.agents?.length}
                                    </span>
                                </Bedges>
                                {team && team.fk_customer_id && validateStringValue(team.customer.customer_company_name) ? (<div className='max-w-12'>
                                    <StatusLabel titleText='Customer' content={`${validateStringValue(team.customer.customer_company_name)}`} color='scogoorange' />
                                </div>) : <></>}
                            </div>
                            <div className='pt-1'>
                                {team?.hasBlackListedUsers ? (
                                    <IconToolTip title={'This team contains blacklisted users'}>
                                        <span className='material-icons text-scogo99 text-2xl'>block</span>
                                    </IconToolTip>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </AccordionSummary>
                    <div className='flex items-center mr-1'>
                        <DropdownMenu
                            componentOrIcon={'more_vert'}
                            dropDownClass={'cursor-pointer text-3xl'}
                            menuData={projectTeamAction}
                            item={team}
                            payload={{
                                team,
                                projectDetail,
                                customerId,
                            }}
                        />
                    </div>
                </div>
                <AccordionDetails sx={{ padding: '0', paddingX: '0.8rem' }}>
                    {Array.isArray(team?.agents) && team?.agents?.length > 0 ? (
                        team?.agents?.map((agent) => {
                            const projectTeamUsersActions = getProjectTeamUsersActions(loggedUser.role.id, loggedUser.type, { agent, team });
                            return (
                                <div className='flex items-center py-1'>
                                    <div className='items-center'>
                                        <div className='flex items-center'>
                                            <div className='ml-m-9 flex items-center gap-2 w-72 truncate'>
                                                <div className='max-w-60'>
                                                    <UserPopup
                                                        key={agent.id}
                                                        userId={agent.id}
                                                        anchorOrigin={{
                                                            vertical: 'center',
                                                            horizontal: 'left',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'right',
                                                        }}
                                                    >
                                                        <p className={styleIfBlackListUser(agent)}>{`${agent?.first_name} ${agent?.last_name}`}</p>
                                                    </UserPopup>
                                                </div>
                                                <div className='w-12'>
                                                    {agent?.isBlackListed ?
                                                        (
                                                            <IconToolTip title={'Blacklisted user'}>
                                                                <span className='cursor-pointer material-icons text-scogo99 text-lg'>block</span>
                                                            </IconToolTip>
                                                        ) : (
                                                            <></>
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex ml-auto'>
                                        <Buttons>
                                            <DropdownMenu
                                                componentOrIcon={'more_vert'}
                                                dropDownClass={'cursor-pointer text-3xl'}
                                                menuData={projectTeamUsersActions}
                                                item={team}
                                                payload={{
                                                    agent,
                                                    projectDetail,
                                                    customerId,
                                                }}
                                            />
                                        </Buttons>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <NoUsersInTeam />
                    )}
                </AccordionDetails>
            </Accordion>
        </div >
    );
};

const NoUsersInTeam = () => {
    return (
        <div
            className='mx-2 my-2  h-10 items-center flex px-6 rounded-md gap-6'
            style={{ border: '1px solid rgb(178,95,94)', color: 'rgb(145,45,56)' }}
        >
            <p className={`${textCss?.headerThree}`} style={{ color: 'rgb(145,45,56)' }}>
                No User in team
            </p>
        </div>
    );
};
