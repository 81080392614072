export default function RadialProgressBar({ progress, size, strokeWidth, color }) {
    const radius = size / 2 - strokeWidth / 2;
    const circumference = radius * 2 * Math.PI;
    const progressOffset = circumference - (progress / 100) * circumference;

    return (
        <div>
            <svg width={size} height={size}>
                <circle cx={size / 2} cy={size / 2} r={radius} stroke='#ddd' strokeWidth={strokeWidth} fill='none' />
                <circle
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    stroke={color}
                    strokeWidth={strokeWidth}
                    fill='none'
                    strokeDasharray={circumference}
                    strokeDashoffset={progressOffset}
                    strokeLinecap='round'
                />
                <text x='50%' y='50%' textAnchor='middle' dominantBaseline='middle' fontSize={size / 5} fill={color}>
                    {progress}%
                </text>
            </svg>
        </div>
    );
}
