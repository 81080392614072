import React from 'react';
import { closeModalAction } from '../../../actions/modal';
import { useDispatch } from 'react-redux';
import { addAmountToWallet } from '../../../actions/wallet';
import Form from '../../../common/Form';
import Input from '../../../common/Form/Input';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';


export const getTransactionStatusIcon = (status) => {
    let statusDetail = {};
    if (status === 'captured') {
        statusDetail = { icon: '/img/success_pay.png', color: 'scogoToastSuccess' };
    } else if (['pending', 'cancelled', 'failed'].includes(status)) {
        statusDetail = { icon: '/img/error_pay.png', color: 'scogoclosed' };
    } else {
        statusDetail = { icon: '/img/hourglass_half.png', color: 'scogoorange' };
    }
    return statusDetail;
};

export default function AddMoney(props) {
    const dispatch = useDispatch();
    const formName = 'addMoney';
    const { formButtonLoading } = useSelector((state) => state.loading);

    const defaultValues = {
        orderAmount: '',
    };

    const submit = (formValues) => {
        const { orderAmount } = formValues;
        let payload = { orderAmount: parseInt(orderAmount), formName };
        dispatch(addAmountToWallet(payload));
    };

    const methods = useForm({ defaultValues, mode: 'all' });

    return (
        <Form
            methods={methods}
            onSubmit={submit}
            submitButtonText={'Add'}
            onCancel={() => dispatch(closeModalAction())}
            showSubmit
            submitContainerClassName='flex gap-2 justify-end py-4 px-4'
            isLoading={formButtonLoading[formName]}
        >
            <div className='px-3'>
                <Input
                    className='w-full'
                    label='Amount'
                    type={'number'}
                    name='orderAmount'
                    placeholder={'Amount'}
                    required
                    validate={(amount) => {
                        if (amount === '') return true;
                        return parseFloat(amount) >= 100 || 'Amount should be more than Rs. 100';
                    }}
                />
            </div>
        </Form>
    );
}
