import React from 'react';
import DropdownMenu from '../../../../common/MenuDropdown';
import Timestamp from './Timestamp';
import { chatActions, chatType, messageType } from '../../ChatController';
import { getLookupWitHDefaultValue } from '../../../../utils/common';
import UserPopup from '../../../../common/Popups/User';
import ReplyIcon from '@mui/icons-material/Reply';
import { useSelector } from 'react-redux';
const avatarColors = getLookupWitHDefaultValue({
    lookup: {
        '-1': { background: '009688', color: 'fff' },
        1: { background: '009688', color: 'fff' },
        4: { background: '9c27b0', color: 'fff' },
        6: { background: '0D8ABC', color: 'fff' },
        7: { background: 'ffeb3b', color: 'fff' },
        8: { background: 'ff9800', color: 'fff' },
        12: { background: 'ffeb3b', color: 'fff' },
        13: { background: 'ffeb3b', color: 'fff' },
        14: { background: '9c27b0', color: 'fff' },
        15: { background: '9c27b0', color: 'fff' },
        16: { background: '4CAF50', color: 'fff' },
        17: { background: '4CAF50', color: 'fff' },
        18: { background: '4CAF50', color: 'fff' },
        19: { background: '4CAF50', color: 'fff' },
        100: { background: 'CB8326', color: 'fff' },
    },
    defaultValue: { background: '8F0F50', color: 'fff' },
});

function LeftMessage({ children, message, onReplyClick, messageActions, showActions, fullWidth, chips, showReplyIcon = true, isSearchMessage, messageOnclick, showJumbIcon }) {
    const senderName = message?.from?.username ? message?.from?.username : '';
    const { viewTicket } = useSelector((state) => state.tickets);
    const { openedConversationId, conversationDetails } = useSelector((state) => state.conversations);
    const openedConversationDetail = conversationDetails?.[openedConversationId];

    const getFilteredMessageActions = () => {
        const possibleActions = [chatActions.copy, chatActions.reply, chatActions.forwardMessageToWhatsapp, chatActions.notify_end_user];
        return messageActions.filter((action) => {
            if (action?.key === chatActions.copy && (message.type === messageType.attachments || message.type === messageType.card)) {
                return false;
            } else if (chatActions.forwardMessageToWhatsapp === action?.key && message?.conversation?.type === chatType?.help) {
                return false
            } else if (action?.key === chatActions.notify_end_user) {
                return message.type !== messageType.attachments && openedConversationDetail?.type === chatType.ticket;
            }
            return possibleActions.includes(action.key);
        });
    };

    const avatarSource = () => {
        if (message.from.profilePic) return message.from.profilePic;
        return `https://ui-avatars.com/api/?background=${avatarColors[message.from.userId].background}&color=${avatarColors[message.from.userId].color
            }&size=25&rounded=true&bold=true&name=${senderName}`;
    };

    const dropDown = () => {
        return (
            <>
                {showActions && (
                    <DropdownMenu
                        menuData={getFilteredMessageActions()}
                        dropDownClass='text-font16 text-scogoprimary invisible left_right_message'
                        componentOrIcon={'more_vert'}
                        item={message}
                        payload={{ onReplyClick, ticket: viewTicket }}
                    />
                )}
            </>
        );
    };

    return (
        <>
            <div className={`relative flex gap-2 ${isSearchMessage ? "hover:bg-cyan-50 py-1 cursor-pointer" : ""}`} onClick={() => {
                if (isSearchMessage) {
                    messageOnclick(message?.conversation?._id, message?._id)
                }
            }} >
                <div className={`max-w-[66%] min-w-chat bg-mesColor p-1 rounded-t-lg rounded-r-lg relative `}>
                    <img className='top-1 left-1 absolute h-7 w-6 ml-0.5 mb-1' src={avatarSource()} alt='' style={{ borderRadius: '100%' }} />
                    <div className='relative'>
                        <div className='flex w-full pl-1 '>
                            {message?.from?.userId > 0 ? (
                                <UserPopup userId={message?.from?.userId}>
                                    <p className='text-scogo15 text-font12 font-medium ml-7 w-[85%] whitespace-nowrap text-ellipsis overflow-hidden h-6'>{senderName}</p>
                                </UserPopup>
                            ) : (
                                <p className='text-scogo15 text-font12 font-medium ml-7 w-[85%] whitespace-nowrap text-ellipsis overflow-hidden h-6'>{senderName}</p>
                            )}
                            {message.edited && <span className='text-font8 text-right mr-4 text-scogogray px-1 w-2/5'>(edited)</span>}
                        </div>
                        {children({ repliedToBG: 'bg-white', audioClassName: 'audioLeftMessage w-11/12' })}
                        <div className='absolute right-0 top-0' style={{ transform: 'translateX(15%)' }}>
                            {dropDown()}
                        </div>
                        {message.timestamp && <Timestamp className='text-right cursor-pointer' timestamp={message.timestamp} isSearchMessage={isSearchMessage} />}
                    </div>
                </div>
                {showReplyIcon ? <span className='hidden items-center justify-center messageDropdown hover:bg-scogoorange  cursor-pointer bg-scogogray  rounded-full mt-4 rounded-s-full h-8 w-8 pb-1'>
                    <ReplyIcon className='text-white' onClick={() => onReplyClick(message)} />
                </span>
                    :
                    <>
                        {showJumbIcon && <p className=' hidden messageDropdown cursor-pointer bg-gray-300 h-2/4 w-1/10 justify-center rounded-md text-font09 text-black' >Jump</p>}
                    </>
                }
            </div>
            {chips(message?.conversation?._id)}
        </>
    );
}

export default LeftMessage;
