import React, { useState } from 'react';
import Form from '../../Inventory/reusableComponents/AddOption/Form';
import Input from '../../Inventory/reusableComponents/AddOption/Input';
import { useDispatch } from 'react-redux';
import { inviteUser } from '../../../actions/users';
import { closeModalAction } from '../../../actions/modal';
import { isValidEmail, isValidMobileNumber } from '../../../utils/utils';

const formName = 'AddNewFieldEngineers';

export default function AddNewFieldEngineers(props) {
    const closeModal = () => {
        dispatch(closeModalAction());
    };
    const dispatch = useDispatch();
    const initialValues = { first_name: '', last_name: '', mobile: '', email: '' };

    const [form, setForm] = useState(initialValues);

    const submit = (form) => {
        let payload = {
            data: form,
            role: 8,
            formName,
        };
        dispatch(inviteUser(payload));
    };

    return (
        <Form
            form={form}
            setForm={setForm}
            initialValues={initialValues}
            submit={(e) => {
                submit(e);
            }}
            formName={formName}
            buttonPostion={'justify-end'}
            submitButtonName={'Submit'}
            onCancel={closeModal}
        >
            <div className='px-3 flex justify-between'>
                <Input className='w-full' label='First Name' name='first_name' type={'text'} required />
                <Input className='w-full' label='Last Name' name='last_name' type={'text'} required />
                <Input
                    className='w-full'
                    label='Mobile'
                    name='mobile'
                    type={'number'}
                    customValidation={isValidMobileNumber}
                    errorMessage={'Invalid Mobile'}
                    required
                />
                <Input
                    className='w-full'
                    label='Email'
                    name='email'
                    customValidation={isValidEmail}
                    errorMessage={'Invalid Email'}
                    type={'text'}
                    required
                />
            </div>
        </Form>
    );
}
