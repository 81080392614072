export const websocketEvents = {
    chat: {
        message: 'message',
        deleted: 'deleted',
        edited: 'edited',
        read: 'read',
        markRead: 'markRead',
        sendMessage: 'sendMessage',
        editMessage: 'editMessage',
        deleteMessage: 'deleteMessage',
        tagProject: 'tagProjectToChat',
        unTagProject: 'untagProjectFromChat',
        giveAdminAccess: 'giveAdminAccessToChat',
        openConversation: 'openConversation',
        closeConversation: 'closeConversation',
        revokeAdminAccess: 'revokeAdminAccessToChat',
        toggleBot: 'toggleBot',
        leaveConversation: 'leaveConversation',
        addMembers: 'newChatMembers',
        addTeams: 'newChatTeams',
        removeTeam: 'removeChatTeam',
        modifiedNotificationSettings: 'modifiedChatNotificationSettings',
        notification: 'chatNotification',
        rejectMedia: 'rejectMedia',
        approveMedia: 'approveMedia',
        typing: "typingInChat",
        videoMeetInvite: "videoMeetInvite",
        holdCall: "holdCall",
        endCall: "endCall",
        leaveCall: "leaveCall",
        declineCall: "declineCall",
        holdOrEndcall: "holdOrEndcall",
        clearModal: "clearModal"
    },
    wallet: {
        withdrawWebhook: 'withdrawWebhook',
        updateWithdrawStatus: 'update-withdraw-status'
    },
    inventory: {
        runOutOfSpares: 'spare-alerts',
    },
    downloadProgress: {
        start: 'zip-upload-start',
        progress: 'zip-upload-progress',
        done: 'zip-upload-done',
        fail: 'zip-upload-fail'
    },
    user: {
        bulkInviteResponse: 'bulk-invite-response'
    },
    ticket: {
        signoffUnlocked: 'signoff-unlocked',
    },
    voiceCall: {
        voice_call: 'voice-call',
        voice_call_end: 'voice-call-end',
    }
};

export const websocketConnectionStates = {
    connecting: 0,
    connected: 1,
    closing: 2,
    closed: 3,
};
