import { loading } from '../types';

const INITIAL_STATE = {
    checkUserLoading: false,
    isOtpButtonLoading: false,
    sendForgotPasswordEmailLoading: false,
    isPasswordButtonLoading: false,
    isRequestOtpButtonLoading: false,
    isChatListLoading: false,
    isUserListLoading: false,
    isNextChatLoading: false,
    isConversationMessagesLoading: {},
    isConversationDetailsLoading: false,
    formButtonLoading: {},
    feIntentionLoading: {},
    reachedSiteStatusLoading: {},
    siteCompleteStatusLoading: {},
    isWalletTransactionLoading: false,
    isWalletNextTransactionLoading: false,
    approveOrRejectMediaLoadingIdSet: new Set(),
    approveOrRejectCardMessageLoadingIdSet: new Set(),
    customerDetailsByIdLoading: true,
    isTicketStatsLoading: true,
    isReplyConversationMessagesLoading: {},
    enduserGroupedPoListLoadingData: {},
    isNextEnduserGroupedPoListLoadingData: {},
    assetsGroupByEndusersListLoading: {},
    isNextAssetsGroupByEndusersListLoading: {},
    isAttachmentByPartialUrlLoading: {},
    isNextConversationMessagesLoading: {},
    userWalletDetailsByIdLoading: false,
    isGetAllBranchListLoading: false,
};

const loadingReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case loading.CHECK_USER_LOADING:
            return {
                ...state,
                checkUserLoading: action.payload,
            };
        case loading.USER_DETAILS_LOADING:
            return {
                ...state,
                userProfileLoading: action.payload,
            };
        case loading.WHATSAPP_GROUP_LIST_LOADING:
            return {
                ...state,
                whatsappChatListLoading: action.payload,
            };
        case loading.FORWARD_MESSAGE_WHATSAPP_LOADING:
            return {
                ...state,
                whatsappForwardMessageLoading: action.payload,
            };
        case loading.SET_OTP_BUTTON_LOADING:
            return {
                ...state,
                isOtpButtonLoading: action.payload,
            };
        case loading.PO_DETAILS_LOADING:
            return {
                ...state,
                ispoDetailsLoading: action.payload,
            };
        case loading.SEND_FORGOT_PASSWORD_EMAIL_LOADING:
            return {
                ...state,
                isForgotPasswordSubmitButtonLoading: action.payload,
            };
        case loading.SET_PASSWORD_BUTTON_LOADING:
            return {
                ...state,
                isPasswordButtonLoading: action.payload,
            };
        case loading.REQUEST_OTP_LOADING:
            return {
                ...state,
                isRequestOtpButtonLoading: action.payload,
            };
        case loading.SET_CHAT_LIST_LOADING:
            return {
                ...state,
                isChatListLoading: action.payload,
            };
        case loading.SET_USER_LIST_LOADING:
            return {
                ...state,
                isUserListLoading: action.payload,
            };

        case loading.SET_NEXT_CONVERSATIONS_LOADING: {
            return {
                ...state,
                isNextChatLoading: action.payload,
            };
        }
        case loading.SET_CONVERSTAION_MESSAGES_LOADING: {
            const { _id, isLoading } = action.payload;
            return {
                ...state,
                isConversationMessagesLoading: { ...state.isConversationMessagesLoading, [_id]: isLoading },
            };
        }
        case loading.SET_CONVERSATION_DETAILS_LOADING:
            return {
                ...state,
                isConversationDetailsLoading: action.payload,
            };
        case loading.AREA_COUNT_LOADING:
            return {
                ...state,
                isAeraCountLoading: action.payload,
            };
        case loading.SET_NEXT_CONVERSTAION_MESSAGES_LOADING: {
            const { _id, isLoading } = action.payload;
            return {
                ...state,
                isNextConversationMessagesLoading: { ...state.isNextConversationMessagesLoading, [_id]: isLoading },
            };
        }
        case loading.SET_REPLY_CONVERSTAION_MESSAGES_LOADING: {
            const { _id, isLoading } = action.payload;
            return {
                ...state,
                isReplyConversationMessagesLoading: { ...state.isReplyConversationMessagesLoading, [_id]: isLoading },
            };
        }

        case loading.FORM_BUTTON_LOADING:
            const { formName, isLoading } = action.payload;
            return {
                ...state,
                formButtonLoading: {
                    ...state.formButtonLoading,
                    [formName]: isLoading,
                },
            };

        case loading.CUSTOM_MODAL_LOADING:
            return {
                ...state,
                customModalLoading: action.payload,
            };

        case loading.SET_PROJECT_LIST_LOADING:
            return {
                ...state,
                isProjectListLoading: action.payload,
            };

        case loading.SET_ALL_PAYMENTS_LOADING:
            return {
                ...state,
                isAllPaymentLoading: action.payload,
            };

        case loading.SET_WALLET_REWARDS_TRANSACTIONS_LOADING:
            return {
                ...state,
                isWalletRewardsTransactionLoading: action.payload,
            };
        case loading.IS_NOTIFICATION_LOADING:
            return {
                ...state,
                isNotificationsLoading: action.payload,
            };
        case loading.IS_NEXT_NOTIFICATION_LOADING:
            return {
                ...state,
                isNextNotificationsLoading: action.payload,
            };

        case loading.SET_WALLET_WITHDRAWAL_TRANSACTIONS_LOADING:
            return {
                ...state,
                isWalletWithdrawalTransactionLoading: action.payload,
            };

        case loading.PARTNERS_LIST_LOADING:
            return {
                ...state,
                isPArtnersListLoading: action.payload,
            };

        case loading.SET_EXISTING_CITY_LIST_LOADING:
            return {
                ...state,
                allExistingCityLoading: action.payload,
            };
        case loading.SET_ALL_CITY_LIST_LOADING:
            return {
                ...state,
                allCityLoading: action.payload,
            };

        case loading.SET_FIELd_ENGINEERS_LOADING:
            return {
                ...state,
                isFieldEngineersLoading: action.payload,
            };
        case loading.SET_SP_PROFILE_DETAILS_LOADING:
            return {
                ...state,
                isSPProfileDetails: action.payload,
            };
        case loading.SEND_INVITES_LOADING:
            return {
                ...state,
                isSendInviteButtonLoading: action.payload,
            };
        case loading.SP_ASSIGNED_NEW_TICKETS_LIST_LOADING:
            return {
                ...state,
                isNewSPAssignedLoading: action.payload,
            };
        case loading.PROJECT_TEAMS_LIST_LOADING:
            return {
                ...state,
                isProjectTeamLoading: action.payload,
            };
        case loading.SET_PROJECT_DROPDOWN_LIST_LOADING:
            return {
                ...state,
                isProjectDropDownListLoading: action.payload,
            };
        case loading.CITIES_LIST_LOADING:
            return {
                ...state,
                isCitiesLoading: action.payload,
            };
        case loading.PARTNERS_DROPDOWN_LIST_LOADING:
            return {
                ...state,
                isPartnersDropdownListLoading: action.payload,
            };

        case loading.ENDUSER_LIST_LOADING:
            return {
                ...state,
                isEndUserListLoading: action.payload,
            };

        case loading.PROJECT_PREQUISITE_LIST_LOADING:
            return {
                ...state,
                isPrerequisteLoading: action.payload,
            };
        case loading.CUSTOMERS_LIST_LOADING:
            return {
                ...state,
                isCustomerListLoading: action.payload,
            };
        case loading.USECASES_LIST_LOADING:
            return {
                ...state,
                isUsecaseLoading: action.payload,
            };
        case loading.SERVICETYPE_LIST_LOADING:
            return {
                ...state,
                isServiceListLoading: action.payload,
            };
        case loading.TEMPLATE_LIST_LOADING:
            return {
                ...state,
                isTemplateListLoading: action.payload,
            };
        case loading.GET_USER_DETAILS_LOADING:
            return {
                ...state,
                isUserDetailsLoading: action.payload,
            };
        case loading.GET_CUSTOMER_VENDORS_LIST_LOADING:
            return {
                ...state,
                isCustomerVendorsLoading: action.payload,
            };
        case loading.SET_ALL_STATE_LOADING:
            return {
                ...state,
                isAllStateLoading: action.payload,
            };
        case loading.SET_ALL_CITY_LOADING:
            return {
                ...state,
                isAllCityLoading: action.payload,
            };
        case loading.SHARE_ID_CARD_LOADING:
            return {
                ...state,
                isIdCardLoading: action.payload,
            };
        case loading.SHARE_PUBLIC_PROFILE_LOADING:
            return {
                ...state,
                isPublicProfileLoading: action.payload,
            };
        case loading.GET_WAREHOUSE_OWNER_DROPDOWN_LOADING:
            return {
                ...state,
                isWarehouseOwnerLoading: action.payload,
            };
        case loading.GET_WAREHOUSE_DROPDOWN_LOADING:
            return {
                ...state,
                isWarehouseDropdownLoading: action.payload,
            };
        case loading.GET_SUPERDESK_CAPEX_TYPES_LOADING:
            return {
                ...state,
                isSuperDeskCapexTypeLoading: action.payload,
            };
        case loading.GET_DROPDOWN_REPLACE_ALL_ASSETS_LOADING:
            return {
                ...state,
                isReplaceAssetDropdownLoading: action.payload,
            };
        case loading.GET_ALL_ASSET_TYPE_LOADING:
            return {
                ...state,
                isAssetTypeLoading: action.payload,
            };
        case loading.GET_ALL_SITES_DROPDOWN_LOADING:
            return {
                ...state,
                isAllSiteDropdownLoading: action.payload,
            };
        case loading.GET_USERS_RATECARD_LOADING:
            return {
                ...state,
                isUserRateCardLoading: action.payload,
            };

        case loading.GET_RESOURCE_LOADING:
            return {
                ...state,
                isResourcesLoading: action.payload,
            };
        case loading.GET_TOOLS_LOADING:
            return {
                ...state,
                isToolsLoading: action.payload,
            };
        case loading.GET_ALL_SITES_LOADING:
            return {
                ...state,
                sitesLoading: action.payload,
            };

        case loading.GET_ALL_FILTER_SITES_LOADING:
            return {
                ...state,
                sitesForFilterLoading: action.payload,
            };

        case loading.GET_ALL_ASSETS_LOADING:
            return {
                ...state,
                assetsLoading: action.payload,
            };
        case loading.GET_SCANNED_ASSETS_LOADING:
            return {
                ...state,
                isScanAssetLoading: action.payload,
            };
        case loading.GET_ALL_FILTER_ASSETS_LOADING:
            return {
                ...state,
                assetsForFilterLoading: action.payload,
            };

        case loading.GET_ALL_WAREHOUSE_LOADING:
            return {
                ...state,
                warehouseLoading: action.payload,
            };
        case loading.SET_NEXT_WAREHOUSE_LIST_LOADING:
            return {
                ...state,
                isNextWarehouseListLoading: action.payload,
            };

        case loading.GET_ALL_SPARE_ASSETS_LOADING:
            return {
                ...state,
                spareAssetLoading: action.payload,
            };

        case loading.GET_SPAREASSETS_FILTER_LIST_LOADING: {
            return {
                ...state,
                spareAssetFilterLoading: action.payload,
            };
        }

        case loading.GET_WAREHOUSE_FILTER_LIST_LOADING: {
            return {
                ...state,
                warehouseFilterLoading: action.payload,
            };
        }

        case loading.GET_NEXT_SPARE_ASSETS_LOADING: {
            return {
                ...state,
                nextSpareAssetLoading: action.payload,
            };
        }

        case loading.SEARCH_MODULE_DATA_LOADING:
            return {
                ...state,
                searchLoading: action.payload,
            };

        case loading.GET_WALLET_TRANSACTIONS_LOADING:
            return {
                ...state,
                isWalletTransactionLoading: action.payload,
            };

        case loading.GET_WALLET_NEXT_TRANSACTIONS_LOADING:
            return {
                ...state,
                isWalletNextTransactionLoading: action.payload,
            };
        case loading.ASSEST_HISTORY_LOADING:
            return {
                ...state,
                assetHistoryLoading: action.payload,
            };
        case loading.SET_PO_TABLE_LOADING:
            return {
                ...state,
                poTableLoading: action.payload,
            };
        case loading.SET_OPEN_TICKETS_PAYMENTS_LOADING:
            return {
                ...state,
                isOpenTicketPaymentsLoading: action.payload,
            };
        case loading.SET_PO_FILTERLIST_LOADING:
            return {
                ...state,
                isPOfilterListLoading: action.payload,
            };
        case loading.SET_IS_WITHDRAWAL_FILTERLIST_LOADING:
            return {
                ...state,
                isWithdrawalFilterListLoading: action.payload,
            };
        case loading.SET_IS_OPEN_TICKET_PAYMENT_FILTERLIST_LOADING:
            return {
                ...state,
                isOpenTicketPaymentFilterListLoading: action.payload,
            };
        case loading.WHATSAPP_QR_LOADING:
            return {
                ...state,
                isWhatsappQrLoading: action.payload,
            };
        case loading.SET_IS_REWARDS_AND_PENALTIES_FILTERLIST_LOADING:
            return {
                ...state,
                isREwardsAndPenaltiesFilterlistLoading: action.payload,
            };
        case loading.SET_SEARCH_CONVERSTAION_MESSAGES_LOADING:
            return {
                ...state,
                isSearchMessageLoading: action.payload,
            };
        case loading.VIEW_TICKET_BY_ID_LOADING:
            return {
                ...state,
                isViewTicketByIdLoading: action.payload,
            };
        case loading.SET_EXECUTION_DATE_TIMELINE_LOADING:
            return {
                ...state,
                isExecutionDateTimelineLoading: action.payload,
            };
        case loading.IS_UPDATE_COURIER_STATUS_LOADING:
            return {
                ...state,
                isUpdateCourierLoading: action.payload,
            };
        case loading.IS_SOW_DOCUMENT_NOT_FOUND_LOADING:
            return {
                ...state,
                isSowNotFoundLoading: action.payload,
            };
        case loading.IS_SIGN_OFF_SAMPLE_REPORT_NOT_FOUND_LOADING:
            return {
                ...state,
                isSignOffNotFoundLoading: action.payload,
            };
        case loading.IS_REGENARATE_REPORT_LOADING:
            return {
                ...state,
                isRenerateReportLoading: action.payload,
            };
        case loading.IS_DOWNLOAD_REPORT_LOADING:
            return {
                ...state,
                isDownloadReportLoading: action.payload,
            };
        case loading.IS_TICKET_PAYMENT_LOADING:
            return {
                ...state,
                isTicketPaymentLoading: action.payload,
            };
        case loading.SET_IS_PAYOUTS_FILTERLIST_LOADING:
            return {
                ...state,
                isPayoutsFilterListLoading: action.payload,
            };
        case loading.FIND_SITE_LOADING:
            return {
                ...state,
                sitePopupLoading: action.payload,
            };
        case loading.FIND_CUSTOMER_PROFILR_LOADING:
            return {
                ...state,
                vendorPopupLoading: action.payload,
                customerDetailsByIdLoading: action.payload,
                customerProfileLoading: action.payload,
            };
        case loading.EDIT_PAYOUT_FORM_LOADING:
            return {
                ...state,
                editPayoutLoading: action.payload,
            };

        case loading.SET_RECEPIENTS_FILTERED_DATA_LOADING:
            return {
                ...state,
                isRecepientsFilterDataLoading: action.payload,
            };
        case loading.APPROVE_WALLET_LOADING:
            return {
                ...state,
                approveWalletLoading: action.payload,
            };
        case loading.REJECT_WALLET_LOADING:
            return {
                ...state,
                rejectWalletLoading: action.payload,
            };
        case loading.INSTANT_PAY_WALLET_LOADING:
            return {
                ...state,
                payNowWalletLoading: action.payload,
            };
        case loading.GET_WALLET_BALANCE_LOADING:
            return {
                ...state,
                isGetWalletBalanceLoading: action.payload,
            };

        case loading.GET_USER_BANK_DETAILS_LOADING:
            return {
                ...state,
                isBankDetailsLoading: action.payload,
            };
        case loading.GET_CONVERSATION_MEDIA_LOADING:
            return {
                ...state,
                isMediaLaoding: action.payload,
            };
        case loading.GET_NEXT_CONVERSATION_MEDIA_LOADING:
            return {
                ...state,
                isNextMediaLaoding: action.payload,
            };
        case loading.IS_UPDATE_CONVERSATION_LOADING:
            return {
                ...state,
                isUpdateConversationLoading: action.payload,
            };

        case loading.GET_CONVERSATION_INFO_LOADING: {
            return {
                ...state,
                conversationInfoLoading: action.payload,
            };
        }
        case loading.OPEN_SINGLE_CHAT_LOADING: {
            return {
                ...state,
                openSingleChatLoading: action.payload,
            };
        }
        case loading.GET_SINGLE_CHAT_USER_DETAILS_LOADING: {
            return {
                ...state,
                isSingleChatUserDetailsLoading: action.payload,
            };
        }

        case loading.GROUP_USERS_LIST_LOADING: {
            return {
                ...state,
                isGroupUsersListLoading: action.payload,
            };
        }
        case loading.TICKET_LIST_LOADING: {
            return {
                ...state,
                isTicketListLoading: action.payload,
            };
        }
        case loading.TODAY_STATUS_LOADING: {
            return {
                ...state,
                isTodayStatusLoading: action.payload,
            };
        }
        case loading.FE_INTENTION_LOADING: {
            const { intention, formName, isLoading, ticketId } = action.payload;
            let toggleLoading = {};
            if (intention === 'Yes') {
                toggleLoading = {
                    [`${ticketId}_${formName}`]: {
                        positive: isLoading,
                    },
                };
            } else {
                toggleLoading = {
                    [`${ticketId}_${formName}`]: {
                        negative: isLoading,
                    },
                };
            }
            return {
                ...state,
                feIntentionLoading: {
                    ...state.toggleButtonLoading,
                    ...toggleLoading,
                },
            };
        }
        case loading.REACHED_SITE_STATUS_LOADING: {
            const { intention, formName, isLoading, ticketId } = action.payload;
            let toggleLoading = {};
            if (intention === 'Yes') {
                toggleLoading = {
                    [`${ticketId}_${formName}`]: {
                        positive: isLoading,
                    },
                };
            } else {
                toggleLoading = {
                    [`${ticketId}_${formName}`]: {
                        negative: isLoading,
                    },
                };
            }
            return {
                ...state,
                reachedSiteStatusLoading: {
                    ...state.toggleButtonLoading,
                    ...toggleLoading,
                },
            };
        }

        case loading.SITE_COMPLETE_STATUS_LOADING: {
            const { intention, formName, isLoading, ticketId } = action.payload;
            let toggleLoading = {};
            if (intention === 'Yes') {
                toggleLoading = {
                    [`${ticketId}_${formName}`]: {
                        positive: isLoading,
                    },
                };
            } else {
                toggleLoading = {
                    [`${ticketId}_${formName}`]: {
                        negative: isLoading,
                    },
                };
            }
            return {
                ...state,
                siteCompleteStatusLoading: {
                    ...state.toggleButtonLoading,
                    ...toggleLoading,
                },
            };
        }

        case loading.COPY_TRANSACTIONS_DETAILS_LOADING:
            return {
                ...state,
                copyTxnDetailsLoading: action.payload,
            };
        case loading.TICKET_ASSET_REMARK_LOADING:
            return {
                ...state,
                ticketAssetRemarkLoading: action.payload,
            };
        case loading.IS_FE_DROPDOWN_LIST_LOADING:
            return {
                ...state,
                isFeDropdownListLoading: action.payload,
            };
        case loading.IS_SP_DROPDOWN_LIST_LOADING:
            return {
                ...state,
                isSpDropdownListLoading: action.payload,
            };

        case loading.IS_PANCARD_VERIFICATION_LOADING:
            return {
                ...state,
                isPancardVerificationLoading: action.payload,
            };
        case loading.IS_CUSTOMER_ONBOARDING_LOADING:
            return {
                ...state,
                isCustomerOnboardingLoading: action.payload,
            };
        case loading.IS_CHECK_DOMAIN_AVAILABE_CHECK_LOADING:
            return {
                ...state,
                isDomainAvailableCheckLoading: action.payload,
            };
        case loading.IS_GST_VERIFICATION_LOADING:
            return {
                ...state,
                isGSTVerificationLoading: action.payload,
            };
        case loading.IS_BUSSINESS_PANCARD_VERIFICATION_LOADING:
            return {
                ...state,
                isBussinessPanVerificationLoading: action.payload,
            };
        case loading.USER_PROFILE_DETAILS_LOADING:
            return {
                ...state,
                userProfileDetailsLoading: action.payload,
            };
        case loading.USER_BUSSINESS_DETAILS_LOADING:
            return {
                ...state,
                userBussinessDetailsLoading: action.payload,
            };
        case loading.USER_BANK_DETAILS_LOADING:
            return {
                ...state,
                userBankDetailsLoading: action.payload,
            };
        case loading.GET_ASSET_CONFIG_TEMPLATE_LOADING:
            return {
                ...state,
                assetConfigTemplateLoading: action.payload,
            };
        case loading.PAY_SECURITY_DEPOSIT_LOADING:
            return {
                ...state,
                paySecurityDepositLoading: action.payload,
            };
        case loading.GENRATE_OTP_LOADING:
            return {
                ...state,
                generateOtpLoading: action.payload,
            };
        case loading.VERIFY_OTP_LOADING:
            return {
                ...state,
                verifyOtpLoading: action.payload,
            };

        case loading.EMAIL_VERIFICATION_LOADING:
            return {
                ...state,
                emailVerificationLoading: action.payload,
            };
        case loading.ADD_GST_LOADING:
            return {
                ...state,
                createGstLoading: action.payload,
            };
        case loading.GET_GST_LIST_LOADING:
            return {
                ...state,
                getGstListLoading: action.payload,
            };

        case loading.UPDATE_GST_BY_ID_LOADING:
            return {
                ...state,
                updateGstLoading: action.payload,
            };
        case loading.DELETE_GST_BY_ID_LOADING:
            return {
                ...state,
                deleteGstLoading: action.payload,
            };
        case loading.IS_SPAREASSET_WITH_ASSET_ID_LOADING:
            return {
                ...state,
                isSpareAssetDetailsLoading: action.payload,
            };
        case loading.GET_TECH_SUPPORT_USERS_LOADING:
            return {
                ...state,
                isTechSupportUserLoading: action.payload,
            };
        case loading.SET_ALL_STATES_CITY_LOADING:
            return {
                ...state,
                isStateCitiesLoading: action.payload,
            };
        case loading.IS_TICKET_PO_DETAILS_LOADING:
            return {
                ...state,
                isTicketPODetailsLoading: action.payload,
            };
        case loading.GET_SIGN_OFF_TEMPLATES_LOADING:
            return {
                ...state,
                signOffTemplatesLoading: action.payload,
            };
        case loading.GET_COURIER_VENDORS_LOADING:
            return {
                ...state,
                courierVendorsLoading: action.payload,
            };
        case loading.GET_BOQ_ASSETS_LOADING:
            return {
                ...state,
                boqAssetsLoading: action.payload,
            };
        case loading.GET_SITE_LIST_FROM_PINCODE_LOADING:
            return {
                ...state,
                sitesByPincodeLoading: action.payload,
            };
        case loading.LIST_ISSUE_CATEGORIES_LOADING:
            return {
                ...state,
                issueCategoriesLoading: action.payload,
            };
        case loading.GET_SCOGO_DEALIDS_DROPDOWN_LOADING:
            return {
                ...state,
                isScogoDealIdsLoading: action.payload,
            };
        case loading.IS_LOADING_GET_ISSUE_CATEGORIES_DROPDOWN:
            return {
                ...state,
                isIssueCategoryLoading: action.payload,
            };

        case loading.GET_TICKET_STATS_LOADING:
            return {
                ...state,
                isTicketStatsLoading: action.payload,
            };

        case loading.LIST_TECH_ENGINEERS_LOADING:
            return {
                ...state,
                isTechEngineerListLoading: action.payload,
            };
        case loading.LIST_SLA_LOADING:
            return {
                ...state,
                isSlaListLoading: action.payload,
            };
        case loading.ADD_MEMBERS_OR_TEAMS_TO_CHAT_LOADING:
            return {
                ...state,
                addingMembersOrTeamsToChatLoading: action.payload,
            };
        case loading.LIST_RECEIVABLES_GENERATE_INVOICE_LOADING:
            return {
                ...state,
                isRecievablesGenarateInvoiceLoading: action.payload,
            };
        case loading.LIST_RECEIVABLES_PROJECT_TICKETS_LOADING:
            return {
                ...state,
                isRecievablesProjectTicketLoading: action.payload,
            };
        case loading.LIST_CONVERSATION_SIDEBAR_MEDIA_LOADING:
            return {
                ...state,
                isSidebarMediaLoading: action.payload,
            };
        case loading.SET_CHAT_NOTIFICATIONS_LOADING:
            return {
                ...state,
                isChatNotificationsLoading: action.payload,
            };
        case loading.SET_CHANGE_NOTIFICATION_SETTINGS_LOADING:
            return {
                ...state,
                isChangeNotificationSettingsLoading: action.payload,
            };
        case loading.UPDATE_TICKET_PAYMENT_ALLOWANCE_LOADING:
            return {
                ...state,
                isUpdateAllowanceLoading: action.payload,
            };
        case loading.IS_REVENUES_LIST_LOADING:
            return {
                ...state,
                isRevenuesListLoading: action.payload,
            };
        case loading.LIST_TICKET_CUSTOMER_REPORT_MEDIA_LOADING:
            return {
                ...state,
                isCustomerReportMediaLoading: action.payload,
            };
        case loading.APPROVE_OR_REJECT_MEDIA_LOADING:
            const { attachmentId, remove } = action.payload;
            const approveOrRejectMediaLoadingIdSet = new Set(...state.approveOrRejectMediaLoadingIdSet);
            if (remove) {
                approveOrRejectMediaLoadingIdSet.delete(attachmentId);
            } else {
                approveOrRejectMediaLoadingIdSet.add(attachmentId);
            }
            return {
                ...state,
                approveOrRejectMediaLoadingIdSet,
            };
        case loading.APPROVE_OR_REJECT_CARD_MESSAGE_LOADING: {
            const { messageId, remove } = action.payload;
            const approveOrRejectCardMessageLoadingIdSet = new Set(...state.approveOrRejectCardMessageLoadingIdSet);
            if (remove) {
                approveOrRejectCardMessageLoadingIdSet.delete(messageId);
            } else {
                approveOrRejectCardMessageLoadingIdSet.add(messageId);
            }
            return {
                ...state,
                approveOrRejectCardMessageLoadingIdSet,
            };
        }
        case loading.TICKET_FILTER_LIST_LOADING: {
            return {
                ...state,
                isTicketFilterListLoading: action.payload,
            };
        }
        case loading.PROJECT_POST_LOADING: {
            return {
                ...state,
                isProjectPostLoading: action.payload,
            };
        }
        case loading.GET_LINKED_PROJECT_OF_USERS_LOADING: {
            return {
                ...state,
                isUserLinkedProjectsLoading: action.payload,
            };
        }
        case loading.GET_LINKED_PROJECT_OF_TEAMS_LOADING: {
            return {
                ...state,
                isTeamsLinkedProjectsLoading: action.payload,
            };
        }
        case loading.PARTNERS_LIST_DETAILS_LOADING: {
            return {
                ...state,
                isPartnerListDetailsLoading: action.payload,
            };
        }
        case loading.PARTNERS_LIST_COUNT_LOADING: {
            return {
                ...state,
                isPartnerFIlterListLoading: action.payload,
            };
        }
        case loading.SET_FE_PROFILE_DETAILS_LOADING: {
            return {
                ...state,
                isFeDetailsLoading: action.payload,
            };
        }
        case loading.AADHAR_VALIDAION_LOADING: {
            return {
                ...state,
                isAadharValidationLoading: action.payload,
            };
        }
        case loading.SET_ALL_LOCALITY_LOADING: {
            return {
                ...state,
                isLocalityListLoading: action.payload,
            };
        }
        case loading.SET_ALL_DISTRICT_LOADING: {
            return {
                ...state,
                isDistrictListLoading: action.payload,
            };
        }
        case loading.IS_PO_GST_DETAILS_LOADING: {
            return {
                ...state,
                isPoGstDetailsLoading: action.payload,
            };
        }
        case loading.SET_NEXT_USER_LIST_LOADING:
            return {
                ...state,
                isNextUserListLoading: action.payload,
            };
        case loading.IS_BANK_DETAILS_BY_ID_LOADING:
            return {
                ...state,
                isBankDetailsByIdLoading: action.payload,
            };
        case loading.GET_WALLET_BALANCE_BY_ID_LOADING:
            return {
                ...state,
                isWalletDetailsByIdLoading: action.payload,
            };
        case loading.SET_GPT_LIST_LOADING:
            return {
                ...state,
                isGptListLoading: action.payload,
            };
        case loading.SET_NEXT_GPT_LIST_LOADING:
            return {
                ...state,
                isNextGptListLoading: action.payload,
            };

        case loading.UI_CONFIG_LOADING:
            return {
                ...state,
                isUIConfigLoading: action.payload,
            };
        case loading.GET_PROJECT_PM_LOADING:
            return {
                ...state,
                isProjectPmLoading: action.payload,
            };
        case loading.IS_ACTIVE_VOICE_CALL_TICKET_LIST_LOADING:
            return {
                ...state,
                isActiveVoiceCallListLoading: action.payload,
            };
        case loading.ASSET_TAG_DETAILS_LOADING:
            return {
                ...state,
                isAssetTagDetailsLoading: action.payload,
            };
        case loading.SET_IS_SITE_BOQ_LOADING:
            return {
                ...state,
                isSiteBoqLoading: action.payload,
            };
        case loading.IS_ENABLE_VC_USER_LOADING:
            return {
                ...state,
                isEnableVCUserLoading: action.payload,
            };

        case loading.IS_DISABLE_VC_USER_LOADING:
            return {
                ...state,
                isDisableVCUserLoading: action.payload,
            };

        case loading.SET_ENDUSER_GROUPED_PO_FILTER_DATA_LOADING: {
            const { id, isLoading } = action.payload;
            return {
                ...state,
                enduserGroupedPoListLoadingData: { ...state.enduserGroupedPoListLoadingData, [id]: isLoading },
            };
        }
        case loading.SET_ENDUSERS_LIST_REQUEST: {
            return {
                ...state,
                isEndusersLoading: action.payload,
            };
        }
        case loading.SET_NEXT_ENDUSERS_LIST_REQUEST: {
            return {
                ...state,
                isNextEndusersLoading: action.payload,
            };
        }
        case loading.SET_NEXT_ENDUSER_GROUPED_PO_FILTER_DATA_LOADING: {
            const { id, isLoading } = action.payload;
            return {
                ...state,
                isNextEnduserGroupedPoListLoadingData: { ...state.isNextEnduserGroupedPoListLoadingData, [id]: isLoading },
            };
        }
        case loading.SET_NEXT_ALL_ASSETS_GROUP_BY_ENDUSER_LOADING: {
            const { id, isLoading } = action.payload;
            return {
                ...state,
                isNextAssetsGroupByEndusersListLoading: { ...state.isNextAssetsGroupByEndusersListLoading, [id]: isLoading },
            };
        }
        case loading.SET_ALL_ASSETS_GROUP_BY_ENDUSER_LOADING: {
            const { id, isLoading } = action.payload;
            return {
                ...state,
                assetsGroupByEndusersListLoading: { ...state.assetsGroupByEndusersListLoading, [id]: isLoading },
            };
        }
        case loading.SET_ATTACHMENT_WITH_PARTIAL_URL_LOADING: {
            const { partialFileUrl, isLoading } = action.payload;
            return {
                ...state,
                isAttachmentByPartialUrlLoading: { ...state.isAttachmentByPartialUrlLoading, [partialFileUrl]: isLoading },
            };
        }
        case loading.IS_GET_PROJECT_SLAS_LOADING: {
            return {
                ...state,
                isProjectSlaLoading: action.payload,
            };
        }
        case loading.GET_USER_WALLET_BALANCE_BY_ID_LOADING: {
            return {
                ...state,
                userWalletDetailsByIdLoading: action.payload,
            };
        }
        case loading.GET_ALL_BRANCH_LIST_LOADING: {
            return {
                ...state,
                isGetAllBranchListLoading: action.payload,
            };
        }
        case loading.TRANSFORN_ATTACHMENT_LOADING: {
            return {
                ...state,
                isTransformAttachmentLoading: action.payload,
            };
        }
        case loading.IS_CHECK_AND_UPDATE_KYC_STATUS_LOADING: {
            const { loading, userId } = action.payload;
            return {
                ...state,
                isCheckAndUpdateKycLoading: {
                    ...state.isCheckAndUpdateKycLoading, [userId]: loading
                },
            };
        }
        default:
            return state;
    }
};

export default loadingReducer;
