import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import Form from '../../../common/Form';
import { rejectAsset } from '../../../actions/tickets';
import { useForm } from 'react-hook-form';
import TextArea from '../../../common/Form/TextArea';

const formName = 'reject_asset';

export default function RejectAsset({ ticketId }) {
    const defaultValues = {};
    const dispatch = useDispatch();
    const { formButtonLoading } = useSelector((state) => state.loading);

    const submit = (formValues) => {
        dispatch(rejectAsset({ job_sign_off_notes: formValues.job_sign_off_notes, formName, ticketId }));
    };

    const methods = useForm({ defaultValues, mode: 'all' });

    return (
        <Form
            methods={methods}
            onSubmit={submit}
            formName={formName}
            submitButtonText={'Submit'}
            onCancel={() => dispatch(closeModalAction())}
            showSubmit
            submitContainerClassName='flex gap-2 justify-end py-4 px-4'
            isLoading={formButtonLoading[formName]}
        >
            <div className='px-2'>
                <TextArea className='w-full' label='Rejection Message' name='job_sign_off_notes' required />
            </div>
        </Form>
    );
}
