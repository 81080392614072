import { takeEvery, put, call, all, select, takeLatest } from 'redux-saga/effects';
import { fe, loading, modal, toast, user, partners } from '../types';
import api from '../apis';
import { getLoggedUser } from '../selectors/users';

export function* getFieldEngineersList(action) {
    const { servicePartnerId } = action.payload;
    try {
        yield put({ type: loading.SET_FIELd_ENGINEERS_LOADING, payload: true });
        const response = yield call(api.SP.getFieldEngineers, {}, { id: servicePartnerId });
        const loggedUser = yield select(getLoggedUser);
        if (response.data.code === 200) {
            yield all([
                yield put({ type: user.USER_FIELD_ENGINEERS_LIST_SUCCESS, payload: { loggedUser: loggedUser, feList: response.data.data } }),
                yield put({ type: fe.FIELD_ENGINEERS_LIST_SUCCESS, payload: response.data.data }),
            ]);
            const list = response.data.data.engineer_list.filter((item) => item.user).map((item) => item.user)
            yield put({ type: partners.GET_GROUP_USER_LIST_SUCCESS, payload: { data: list } });
        } else {
            yield put({ type: fe.FIELD_ENGINEERS_LIST_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: fe.FIELD_ENGINEERS_LIST_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.SET_FIELd_ENGINEERS_LOADING, payload: false });
}

export function* deleteFEUser(action) {
    const { formName, userName, data } = action.payload;
    const loggedUser = yield select(getLoggedUser);
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(api.FE.delete, {}, data);
        if (response.data.code === 200) {
            let message = `${userName} deleted Successfully`;

            yield all([
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: message } }),
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: fe.FIELD_ENGINEERS_LIST_REQUEST, payload: { servicePartnerId: loggedUser.service_partner_id } }),
            ]);
        } else {
            yield all([
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: fe.DELETE_FE_FAILURE, payload: { message: 'Something went wrong' } }),
            ]);
        }
    } catch (error) {
        yield put({ type: fe.DELETE_FE_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* updateFEDetails(action) {
    const { form, engineerId } = action.payload;
    try {
        const response = yield call(api.FE.updateDetails, {}, {}, {}, form);
        if (response.data.code === 201) {
            yield all([
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Details Updated Successfully' } }),
                yield put({ type: fe.UPDATE_FE_DETAILS_SUCCESS, payload: response.data.data }),
                yield put({ type: fe.FE_PROFILE_DETAIL_REQUEST, payload: { feId: engineerId } }),
            ]);
        } else {
            yield put({ type: fe.UPDATE_FE_DETAILS_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: fe.UPDATE_FE_DETAILS_FAILURE, payload: { message: error } });
    }
}

export function* getFeProfileDetail(action) {
    const { feId } = action.payload;
    try {
        const response = yield call(api.profile.feProfileDetail, {}, { feId });
        if (response.data.code === 200) {
            yield put({ type: fe.FE_PROFILE_DETAIL_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: fe.FE_PROFILE_DETAIL_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: fe.FE_PROFILE_DETAIL_FAILURE, payload: { message: error } });
    }
}

export function* getFieldEngineersDropdownList(action) {
    const { servicePartnerId } = action.payload;
    try {
        yield put({ type: loading.IS_FE_DROPDOWN_LIST_LOADING, payload: true });
        const response = yield call(api.SP.getFieldEngineers, {}, { id: servicePartnerId });
        if (response.data.code === 200) {
            let feDropdown = response.data.data.engineer_list;
            yield put({ type: fe.FIELD_ENGINEERS_DROPDOWN_LIST_SUCCESS, payload: feDropdown });
        } else {
            yield put({ type: fe.FIELD_ENGINEERS_DROPDOWN_LIST_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: fe.FIELD_ENGINEERS_DROPDOWN_LIST_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.IS_FE_DROPDOWN_LIST_LOADING, payload: false });
}

export default function* feSaga() {
    yield takeEvery(fe.FIELD_ENGINEERS_LIST_REQUEST, getFieldEngineersList);
    yield takeEvery(fe.DELETE_FE_REQUEST, deleteFEUser);
    yield takeEvery(fe.FE_PROFILE_DETAIL_REQUEST, getFeProfileDetail);
    yield takeLatest(fe.UPDATE_FE_DETAILS_REQUEST, updateFEDetails);
    yield takeEvery(fe.FIELD_ENGINEERS_DROPDOWN_LIST_REQUEST, getFieldEngineersDropdownList);
}
