import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Layout from './Layout';

import { loginWithOTPAction } from '../../actions/auth';
import { useForm } from 'react-hook-form';
import { closeModalAction } from '../../actions/modal';
import Input from '../../common/Form/Input';
import Form from '../../common/Form';



function LoginWithOTP() {
    const { isOtpButtonLoading } = useSelector((state) => state.loading);
    const dispatch = useDispatch();
    const defaultValues = { mobile: '', otp: '' };
    const methods = useForm({ defaultValues, mode: 'all' });
    const navigate = useNavigate();


    const submit = (formValues) => {
        dispatch(loginWithOTPAction({ mobile: formValues?.mobile, otp: formValues?.otp, navigate }));
    };

    return (
        <Layout>

            <div style={{ width: '35rem' }} className='mt-5' >
                <Form
                    methods={methods}
                    onSubmit={submit}
                    buttonPostion={'justify-center'}
                    submitButtonText={'Login'}
                    onCancel={() => dispatch(closeModalAction())}
                    showSubmit
                    submitContainerClassName='flex gap-2 justify-center py-4 px-4'
                    isLoading={isOtpButtonLoading}
                    showCancelButton={false}
                    buttonClass="w-full text-font12 h-14"
                    disabledClass="text-black flex items-center w-full justify-center rounded-6px shadow-primaryBtnShadow text-xl gap-1 font-medium relative cursor-not-allowed border-scogoddd bg-scogoddd"

                >
                    <Input className='w-full' type={"number"} inputClassName="w-full loginput font-normal text-font13 block w-full focus:ring-0 px-4" iconLeft={{ "hoverTitle": "Mobile Number", name: "person" }} placeholder="Enter Registered Mobile Number" name='mobile' required />

                    <Input className='w-full' maxLength='4' type={"number"} inputClassName="w-full loginput font-normal text-font13  block w-full focus:ring-0 px-4" placeholder="Enter Otp" name='otp' required iconLeft={{ "hoverTitle": "Otp", name: "lock" }} />

                </Form>
            </div>

        </Layout >
    );
}

export default LoginWithOTP;
