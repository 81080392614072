import { put, call, takeLatest, takeEvery } from 'redux-saga/effects';

import lambdas from '../lambdas/apis';

import { whatsapp, toast, loading, modal, auth } from '../types';

import WhatsappAlternateMobilePopup from '../pages/Modals/WhatsappAlternateMobilePopup';
import WhatsappLinkQrPopup from '../pages/Modals/WhatsappLinkQrPopup';


export function* updateWhatsappNumber(action) {
    const { formName, mobileNumber, verifyType, userId, navigate, openModal = false } = action.payload;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    try {
        const response = yield call(lambdas.whatsapp.updateWhatsappNumber, {}, {}, {}, { userId, mobileNumber, verifyType });
        if (response.data.code === 200) {
            if (response.data.data?.is_mobile_linked_with_whatsapp === 0) {
                if (verifyType !== "PRIMARY") {
                    yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message || "Mobile number is not registered with Whatsapp" } });
                }
                if (openModal) {
                    yield put({
                        type: modal.OPEN_CUSTOM_MODAL,
                        payload: {
                            heading: `Update WhatsApp Number`,
                            modalWidth: '35vw',
                            modalHeight: 'auto',
                            modalComponent: <WhatsappAlternateMobilePopup />,
                            showClose: false
                        },
                    });
                }
            } else {
                yield put({ type: auth.USER_PROFILE_REQUEST, payload: { navigate } });
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: response.data.data.message } });
                yield put({ type: modal.CLOSE_MODAL });
            }
        } else if (response.data.code === 400) {
            if (verifyType !== "PRIMARY") {
                yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
            }
            if (openModal) {
                yield put({
                    type: modal.OPEN_CUSTOM_MODAL,
                    payload: {
                        heading: `Update WhatsApp Number`,
                        modalWidth: '35vw',
                        modalHeight: 'auto',
                        modalComponent: <WhatsappAlternateMobilePopup />,
                        showClose: false
                    },
                });
            }
        }
        else {
            yield put({ type: modal.CLOSE_MODAL });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message || 'Something Went Wrong!!!' } });
        }
    } catch (error) {
        yield put({ type: modal.CLOSE_MODAL });
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: error.message || 'Something Went Wrong!!!' } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* getWhatsappOr(action) {
    const { openModal, message } = action.payload;
    yield put({ type: loading.WHATSAPP_QR_LOADING, payload: true });
    try {
        const response = yield call(lambdas.whatsapp.getWhatsappQr, {}, {}, {}, {});
        if (response.data.code === 200) {
            if (response.data?.data?.is_mobile_linked_with_whatsapp === 0 && openModal) {
                yield put({ type: modal.CLOSE_MODAL });
                yield put({
                    type: modal.OPEN_CUSTOM_MODAL,
                    payload: {
                        heading: `Update WhatsApp Number`,
                        modalWidth: '35vw',
                        modalHeight: 'auto',
                        modalComponent: <WhatsappAlternateMobilePopup />,
                        showClose: false
                    },
                });
            } else {
                yield put({ type: whatsapp.WHATSAPP_QR_SUCCESS, payload: { url: response.data?.data?.url, status: response.data?.data?.status } });
                if (message?.length > 0 && response.data?.data?.status === 1) {
                    yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: message } });
                }
                if (response.data?.data?.status === 1) {
                    yield put({ type: modal.CLOSE_MODAL });
                }
            }
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message || 'Something Went Wrong!!!' } });
            yield put({ type: modal.CLOSE_MODAL });

        }
    } catch (error) {
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: error.message || 'Something Went Wrong!!!' } });
    }
    yield put({ type: loading.WHATSAPP_QR_LOADING, payload: false });
}


export function* getWhatsappChatList(action) {
    let data = action.payload;

    yield put({ type: loading.WHATSAPP_GROUP_LIST_LOADING, payload: true });
    try {
        const response = yield call(lambdas.whatsapp.listOfWhatsAppGroup, {}, {}, data);
        if (response.data.code === 200) {
            yield put({ type: whatsapp.WHATSAPP_GROUP_LIST_SUCCESS, payload: response.data?.data });
            yield put({ type: whatsapp.WHATSAPP_QR_SUCCESS, payload: { url: "", status: 1 } });
        } else if (response.data.code === 400) {
            yield put({ type: modal.CLOSE_MODAL });
            if (response.data?.data?.is_mobile_linked_with_whatsapp === 0) {
                yield put({
                    type: modal.OPEN_CUSTOM_MODAL,
                    payload: {
                        heading: `Update WhatsApp Number`,
                        modalWidth: '35vw',
                        modalHeight: 'auto',
                        modalComponent: <WhatsappAlternateMobilePopup />,
                        showClose: false
                    },
                });
            } else {
                yield put({
                    type: modal.OPEN_CUSTOM_MODAL,
                    payload: {
                        heading: `Connect Your WhatsApp`,
                        modalWidth: '32vw',
                        modalHeight: 'auto',
                        modalComponent: <WhatsappLinkQrPopup />,
                    },
                });
            }
        }
        else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message || 'Something Went Wrong!!!' } });
        }
    } catch (error) {
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: error.message || 'Something Went Wrong!!!' } });
    }
    yield put({ type: loading.WHATSAPP_GROUP_LIST_LOADING, payload: false });
}

export function* forwardMessageToWhatsApp(action) {
    let data = action.payload;
    yield put({ type: loading.FORWARD_MESSAGE_WHATSAPP_LOADING, payload: true });
    try {
        const response = yield call(lambdas.whatsapp.forwardMessageToWhatsApp, {}, {}, {}, data);
        if (response.data.code === 200) {
            yield put({ type: modal.CLOSE_MODAL });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: response.data.data.message || 'Something Went Wrong!!!' } });
        }
        else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message || 'Something Went Wrong!!!' } });
        }
    } catch (error) {
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: error.message || 'Something Went Wrong!!!' } });
    }
    yield put({ type: loading.FORWARD_MESSAGE_WHATSAPP_LOADING, payload: false });
}



export default function* whatsappSaga() {
    yield takeEvery(whatsapp.UPDATE_WHATSAPP_NUMBER, updateWhatsappNumber);
    yield takeLatest(whatsapp.GET_WHATSAPP_QR, getWhatsappOr);
    yield takeLatest(whatsapp.GET_WHATSAPP_GROUP_LiST, getWhatsappChatList);
    yield takeEvery(whatsapp.FORWARD_MESSAGE_WHATSAPP, forwardMessageToWhatsApp);
}
