import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSlaPolicies, updateInventorySite } from '../../../actions/inventory';
import { closeModalAction } from '../../../actions/modal';
import { useForm } from 'react-hook-form';
import Form from '../../../common/Form';
import Select from '../../../common/Form/Select';
import { actualValueForDropdown, getSelectedItemFromDropdownList } from '../../../utils/common';

const UpdateSla = ({ modalData, customerId }) => {
    const formName = 'updateSla';
    const dispatch = useDispatch();
    const { slas } = useSelector((state) => state.inventory);
    const { formButtonLoading } = useSelector((state) => state.loading);

    useEffect(() => {
        dispatch(getSlaPolicies({ customerId: modalData?.customer_id, dropdown: true }));
    }, [dispatch, modalData?.customer_id]);

    const defaultValues = {
        sla: getSelectedItemFromDropdownList(modalData?.sla, slas),
    };

    const submit = (form) => {
        const payload = {
            sla: actualValueForDropdown(form.sla),
        };
        dispatch(updateInventorySite({ data: payload, siteId: modalData?.id, customerId, formName, toastOnSuccess: 'SLA Updated Successfully!!!' }));
    };
    const closeModal = () => {
        dispatch(closeModalAction());
    };

    const methods = useForm({ defaultValues, mode: 'all' });

    return (
        <>
            <Form
                className='w-full '
                onSubmit={(e) => {
                    submit(e);
                }}
                onCancel={closeModal}
                defaultValues={defaultValues}
                methods={methods}
                showSubmit
                submitContainerClassName='flex gap-2 justify-end py-4 px-4'
                isLoading={formButtonLoading[formName]}
                submitButtonText={'Update'}
            >
                <div className='px-3 py-2'>
                    <Select label='SLA' required name='sla' options={slas} />
                </div>
            </Form>
        </>
    );
};

export default UpdateSla;
