import React from "react";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
} from "react-google-maps";
const MapWithPlaces = withScriptjs(withGoogleMap(props => {
    const { setlocation } = props;
    const getLotionOnclick = (e) => {
        let lat = e?.latLng?.lat();
        let lng = e?.latLng?.lng();
        var geocoder = new window.google.maps.Geocoder();
        geocoder.geocode(
            {
                latLng: e.latLng,
            },
            function (results, status) {

                if (status === window.google.maps.GeocoderStatus.OK) {

                    if (results) {

                        let add = results[0]?.address_components;
                        let pin = add[add.length - 1]?.long_name;
                        let location_data = results[0]?.formatted_address;
                        setlocation({
                            lat: lat,
                            lng: lng,
                            pincode: pin,
                            address: location_data,
                        });
                    }
                }
            }
        );
    };

    return (
        <>
            <GoogleMap
                id="map"
                onClick={getLotionOnclick}
            >
            </GoogleMap>
        </>
    );
}
))

export default MapWithPlaces;
