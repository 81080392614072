import React, { useEffect, useState } from 'react';
import GridTable from '../../../common/GridTable';
import moment from 'moment';
import OverflowTip from '../../../common/OverflowTip';
import IconToolTip from '../../../common/IconToolTip';
import { useSelector } from 'react-redux';

const ViewDetailsCustomer = ({ transaction }) => {
    const { sub_transaction_histories } = transaction;
    const { loggedUser } = useSelector((state) => state.auth);

    const getOverFlowDiv = (value, textClass) => <OverflowTip textClass={textClass} someLongText={value} />;

    const getHeaderWithIcon = (headName, icon, title) => {
        return (
            <div className='flex items-center gap-2'>
                <p>{headName}</p>
                <p className='pt-2'>
                    <IconToolTip title={title}>
                        <span className='material-icons text-font13 text-scogogray'>{icon}</span>
                    </IconToolTip>
                </p>
            </div>
        );
    };

    const transactionBluePrint = {
        table: [
            {
                head: {
                    render: (headName) => {
                        return headName;
                    },
                    headName: 'Ticket number',
                    width: 1.2,
                },
                body: {
                    render: (transaction) => getOverFlowDiv(transaction.scogo_tx_no, 'text-scogoprimary'),
                },
            },
            {
                head: {
                    render: (headName) => {
                        return headName;
                    },
                    headName: 'Amount',
                },
                body: {
                    render: (transaction) => getOverFlowDiv(`₹ ${transaction.amount}`),
                },
            },
            {
                head: {
                    render: (headName) => {
                        return getHeaderWithIcon(headName, 'error', 'GST will be updated when the sign off is approved');
                    },
                    headName: 'GST',
                },
                body: {
                    render: (transaction) => getOverFlowDiv(`₹ ${transaction.gst}`),
                },
            },
            {
                head: {
                    render: (headName) => {
                        return getHeaderWithIcon(headName, 'error', 'Amount + GST');
                    },
                    headName: 'Total',
                },
                body: {
                    render: (transaction) => getOverFlowDiv(`₹ ${transaction.amount + transaction.gst}`),
                },
            },
            {
                head: {
                    render: (headName) => {
                        return headName;
                    },
                    headName: 'Status',
                },
                body: {
                    render: (transaction) => {
                        const transactionStatus =
                            transaction?.txStatus?.toString() === '1' ? 'LOCKED' : transaction?.txStatus?.toString() === '2' ? 'PAID' : '';
                        return getOverFlowDiv(transactionStatus);
                    },
                },
            },
            {
                head: {
                    render: (headName) => {
                        return headName;
                    },
                    headName: 'Timestamp',
                },
                body: {
                    render: (transaction) => {
                        const txTime = moment(transaction?.txTime).isValid()
                            ? moment(new Date(transaction.txTime)).format('DD-MM-YYYY HH:mm:ss')
                            : '';
                        return getOverFlowDiv(txTime);
                    },
                },
            },
        ],
    };

    const subTransactionBluePrint = {
        table: [
            {
                head: {
                    render: (headName) => {
                        return headName;
                    },
                    headName: 'Amount in ₹',
                },
                body: {
                    render: (subTransaction) => {
                        let amount = '';
                        if (subTransaction.transaction_type === 'DEBIT') amount = `- ${subTransaction.amount}`;
                        else amount = `+ ${subTransaction.amount}`;
                        return getOverFlowDiv(amount);
                    },
                },
            },
            {
                head: {
                    render: (headName) => {
                        return headName;
                    },
                    headName: 'Transaction Type',
                },
                body: {
                    render: (subTransaction) => getOverFlowDiv(subTransaction.transaction_type),
                },
            },
            {
                head: {
                    render: (headName) => {
                        return headName;
                    },
                    headName: 'Remark',
                    width: 1.2,
                },
                body: {
                    render: (subTransaction) => getOverFlowDiv(subTransaction.remark),
                },
            },
            {
                head: {
                    render: (headName) => {
                        return headName;
                    },
                    headName: 'Wallet Used',
                },
                body: {
                    render: (subTransaction) => {
                        const title = loggedUser.id === subTransaction?.wallet_user_id ? 'Personal' : 'Company';
                        return getOverFlowDiv(title);
                    },
                },
            },
            {
                head: {
                    render: (headName) => {
                        return headName;
                    },
                    headName: 'Timestamp',
                },
                body: {
                    render: (subTransaction) => {
                        const timeStamp = moment(subTransaction.created_at).isValid()
                            ? moment(new Date(subTransaction.created_at)).format('DD-MM-YYYY HH:mm:ss')
                            : '';
                        return getOverFlowDiv(timeStamp);
                    },
                },
            },
        ],
    };

    return (
        <div className='px-7 pb-8 py-4 flex flex-col gap-6'>
            <GridTable schema={transactionBluePrint} rowData={[transaction]} tableBodyHeight={'auto'} tableError='No Ticket Found' />
            <p className='text-scogoprimary font-medium text-font14 px-2 pt-4'>Sub Transactions</p>
            <GridTable schema={subTransactionBluePrint} rowData={sub_transaction_histories} tableBodyHeight={'auto'} tableError='No Ticket Found' />
        </div>
    );
};

export default ViewDetailsCustomer;
