import _ from 'lodash';
import { useRef } from 'react';
import { useFormContext, Controller } from 'react-hook-form';

const concatFiles = (fileList1, fileList2) => {
    const dataTransferList = new DataTransfer(),
        existingFileSet = new Set();
    if (fileList1?.length > 0) {
        for (const file of fileList1) {
            existingFileSet.add(file.name);
            dataTransferList.items.add(file);
        }
    }
    if (fileList2?.length > 0) {
        for (const file of fileList2) {
            if (!existingFileSet.has(file.name)) {
                dataTransferList.items.add(file);
            }
        }
    }
    return dataTransferList.files;
};

const Upload = ({
    id,
    name,
    label,
    required,
    placeholder,
    isMulti,
    accept,
    validateFileType,
    shouldUnregister,
    appendFiles,
    className = '',
    maxSizeBytes,
    renderFileUpload,
    renderFileDisplay,
    rednderClass,
    errorClassName = 'text-scogoclosed text-font08 font-normal',
    readOnly,
    disabled = false,
    uploadText = 'No File Chosen',
    onChange: onFileUpload = () => { },
    padding = 'px-2 py-2 ',
    labelClass
}) => {
    if (accept && !validateFileType)
        throw new Error(
            'validateFileType is compulsory if you are using accept because even with accept it is possible to upload any file type, so actual validation is necessary'
        );
    const {
        formState: { errors },
        control,
    } = useFormContext();
    const fileRef = useRef();
    const triggerUpload = () => fileRef?.current?.click();

    const error = _.get(errors, name) ? _.get(errors, name).message : '';

    return (
        <div
            className={`block ${padding} ${className}`}
            style={{
                minHeight: 0,
                minWidth: 0,
            }}
        >
            {label && (
                <label className={`text-scogo99 font-normal text-font09 md:text-font11 labelFotnSize`}>
                    {label} {required && <span className='text-scogoorange'>*</span>}
                </label>
            )}
            <Controller
                key={name}
                control={control}
                name={name}
                rules={{
                    required: { value: required, message: 'Required' },
                    validate: {
                        invalidFileType: (files) => {
                            if (!validateFileType || !files || files?.length === 0) return true;
                            let invalidFileMessage;
                            for (const file of files) {
                                const res = validateFileType(file);
                                if (typeof res === 'string') {
                                    invalidFileMessage = res;
                                    break;
                                }
                            }
                            return !invalidFileMessage || invalidFileMessage;
                        },
                        fileLargerThanLimit: (files) => {
                            if (!maxSizeBytes || !files || files?.length === 0) return true;
                            const limitBreachingFile = [...files].find((file) => file.size > maxSizeBytes);
                            if (!limitBreachingFile) return true;
                            return `${limitBreachingFile.name} is more than ${maxSizeBytes / 1000} KB`;
                        },
                    },
                }}
                shouldUnregister={shouldUnregister}
                render={({ field: { onChange, onBlur, value: files, name, ref } }) => {
                    const removeFile = (file) => {
                        const dataTransferList = new DataTransfer();
                        for (const f of files) {
                            if (file.name !== f.name) dataTransferList.items.add(f);
                        }
                        onChange(dataTransferList.files.length > 0 ? dataTransferList.files : undefined);
                    };
                    return (
                        <div className={rednderClass}>
                            {renderFileUpload ? (
                                renderFileUpload({ files, triggerUpload })
                            ) : (
                                <div
                                    className={`flex justify-between  items-center w-full ${labelClass} inputFontSize ${disabled ? 'border-b-0 cursor-not-allowed' : 'border-b-2 border-scogoddd'}`}
                                    style={{ height: '2.9rem' }}
                                    onClick={triggerUpload}
                                    type='button'
                                >
                                    <div className='truncate'>  {!files || files.length === 0 ? uploadText : files.length === 1 ? files[0].name : `${files.length} Files`}
                                    </div>
                                    <span className='material-icons text-font16'>attach_file</span>
                                </div>
                            )}
                            {renderFileDisplay ? (
                                renderFileDisplay({ files, removeFile })
                            ) : (
                                <div className='flex items-center gap-2 flex-wrap'>
                                    {files &&
                                        [...files].map((file) => (
                                            <div className='flex items-center' key={file.name} title={file.name}>
                                                <div className={`max-w-6 truncate font-normal text-font09 md:text-font11 text-scogoprimary inputFontSize`}>{file.name}</div>
                                                <span className='text-font08 bg-white material-icons cursor-pointer hover:text-scogoorange ' onClick={() => removeFile(file)}>
                                                    clear
                                                </span>
                                            </div>
                                        ))}
                                </div>
                            )}
                            <input
                                id={id}
                                key={name}
                                ref={fileRef}
                                disabled={disabled}
                                type='file'
                                style={{ height: '3rem' }}
                                className={`hidden inputFontSize `}
                                onChange={(e) => {
                                    const newFiles = appendFiles ? concatFiles(files, e.target.files) : e.target.files;
                                    onChange(newFiles);
                                    onFileUpload(newFiles);
                                }}
                                onBlur={(e) => {
                                    onBlur(e.target.files);
                                }}
                                placeholder={placeholder}
                                multiple={isMulti}
                                accept={accept}
                                readOnly={readOnly}
                            />
                        </div>
                    );
                }}
            />
            {required && error && <p className='text-scogoclosed text-font08 font-normal inputFontSize h-6'>{error}</p>}
        </div>
    );
};

export default Upload;
