export const GET_PROJECT_LIST_REQUEST = 'GET_PROJECT_LIST_REQUEST';
export const GET_PROJECT_LIST_SUCCESS = 'GET_PROJECT_LIST_SUCCESS';
export const GET_PROJECT_LIST_FAILURE = 'GET_PROJECT_LIST_FAILURE';

export const GET_ALL_PROJECT_LIST_REQUEST = 'GET_ALL_PROJECT_LIST_REQUEST';
export const GET_ALL_PROJECT_LIST_SUCCESS = 'GET_ALL_PROJECT_LIST_SUCCESS';
export const GET_ALL_PROJECT_LIST_FAILURE = 'GET_ALL_PROJECT_LIST_FAILURE';
export const GET_ALL_PROJECT_COUNT_SUCCESS = 'GET_ALL_PROJECT_COUNT_SUCCESS';

export const GET_ALL_PROJECT_DROPDOWN_LIST_REQUEST = 'GET_ALL_PROJECT_DROPDOWN_LIST_REQUEST';
export const GET_ALL_PROJECT_DROPDOWN_LIST_SUCCESS = 'GET_ALL_PROJECT_DROPDOWN_LIST_SUCCESS';
export const GET_ALL_PROJECT_DROPDOWN_LIST_FAILURE = 'GET_ALL_PROJECT_DROPDOWN_LIST_FAILURE';

export const UPDATE_PM_DETAILS_REQUEST = 'UPDATE_PM_DETAILS_REQUEST';
export const UPDATE_PM_DETAILS_SUCCESS = 'UPDATE_PM_DETAILS_SUCCESS';
export const UPDATE_PM_DETAILS_FAILURE = 'UPDATE_PM_DETAILS_FAILURE';

export const EDIT_PROJECT_REQUEST = 'EDIT_PROJECT_REQUEST';
export const EDIT_PROJECT_SUCCESS = 'EDIT_PROJECT_SUCCESS';
export const EDIT_PROJECT_FAILURE = 'EDIT_PROJECT_FAILURE';

export const EMAIL_PLANNED_TICKETS_CSV_REQUEST = 'EMAIL_PLANNED_TICKETS_CSV_REQUEST';
export const EMAIL_PLANNED_TICKETS_CSV_FAILURE = 'EMAIL_PLANNED_TICKETS_CSV_FAILURE';

export const EMAIL_CONFIG_COMPLETE_TICKETS_CSV_REQUEST = 'EMAIL_CONFIG_COMPLETE_TICKETS_CSV_REQUEST';
export const EMAIL_CONFIG_COMPLETE_TICKETS_CSV_FAILURE = 'EMAIL_CONFIG_COMPLETE_TICKETS_CSV_FAILURE';

export const CREATE_PROJECT_REQUEST = 'CREATE_PROJECT_REQUEST';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_FAILURE = 'CREATE_PROJECT_FAILURE';

export const PROJECT_PREQUISITE_LIST_REQUEST = 'PROJECT_PREQUISITE_LIST_REQUEST';
export const PROJECT_PREQUISITE_LIST_SUCCESS = 'PROJECT_PREQUISITE_LIST_SUCCESS';
export const PROJECT_PREQUISITE_LIST_FAILURE = 'PROJECT_PREQUISITE_LIST_FAILURE';

export const ADD_PROJECT_PREREQUISITE_REQUEST = 'ADD_PROJECT_PREREQUISITE_REQUEST';
export const ADD_PROJECT_PREREQUISITE_SUCCESS = 'ADD_PROJECT_PREREQUISITE_SUCCESS';
export const ADD_PROJECT_PREREQUISITE_FAILURE = 'ADD_PROJECT_PREREQUISITE_FAILURE';

export const SHOW_TEMPLATE_LIST_REQUEST = 'SHOW_TEMPLATE_LIST_REQUEST';
export const SHOW_TEMPLATE_LIST_SUCCESS = 'SHOW_TEMPLATE_LIST_SUCCESS';
export const SHOW_TEMPLATE_LIST_FAILURE = 'SHOW_TEMPLATE_LIST_FAILURE';

export const IS_SHOW_ADD_PROJECT = 'IS_SHOW_ADD_PROJECT';

export const ADD_TEAM_PROJECT_REQUEST = 'ADD_TEAM_PROJECT_REQUEST';
export const ADD_TEAM_PROJECT_SUCCESS = 'ADD_TEAM_PROJECT_SUCCESS';
export const ADD_TEAM_PROJECT_FAILURE = 'ADD_TEAM_PROJECT_FAILURE';

export const REMOVE_TEAM_PROJECT_REQUEST = 'REMOVE_TEAM_PROJECT_REQUEST';
export const REMOVE_TEAM_PROJECT_SUCCESS = 'REMOVE_TEAM_PROJECT_SUCCESS';
export const REMOVE_TEAM_PROJECT_FAILURE = 'REMOVE_TEAM_PROJECT_FAILURE';

export const PROJECT_TEAM_LIST_REQUEST = 'PROJECT_TEAM_LIST_REQUEST';
export const PROJECT_TEAM_LIST_SUCCESS = 'PROJECT_TEAM_LIST_SUCCESS';
export const PROJECT_TEAM_LIST_FAILURE = 'PROJECT_TEAM_LIST_FAILURE';

export const PROJECTS_COUNT_REQUEST = 'PROJECTS_COUNT_REQUEST';
export const PROJECTS_COUNT_SUCCESS = 'PROJECTS_COUNT_SUCCESS';
export const PROJECTS_COUNT_FAILURE = 'PROJECTS_COUNT_FAILURE';

export const PROJECTS_TEAMS_USER_WHITELIST_REQUEST = 'PROJECTS_TEAMS_USER_WHITELIST_REQUEST';
export const PROJECTS_TEAMS_USER_WHITELIST_SUCCESS = 'PROJECTS_TEAMS_USER_WHITELIST_SUCCESS';
export const PROJECTS_TEAMS_USER_WHITELIST_FAILURE = 'PROJECTS_TEAMS_USER_WHITELIST_FAILURE';

export const PROJECTS_TEAMS_USER_BLACKLIST_REQUEST = 'PROJECTS_TEAMS_USER_BLACKLIST_REQUEST';
export const PROJECTS_TEAMS_USER_BLACKLIST_SUCCESS = 'PROJECTS_TEAMS_USER_BLACKLIST_SUCCESS';
export const PROJECTS_TEAMS_USER_BLACKLIST_FAILURE = 'PROJECTS_TEAMS_USER_BLACKLIST_FAILURE';

export const DOWNLOAD_PROJECT_ZIP_REQUEST = 'DOWNLOAD_PROJECT_ZIP_REQUEST';
export const DOWNLOAD_PROJECT_ZIP_SUCCESS = 'DOWNLOAD_PROJECT_ZIP_SUCCESS';
export const DOWNLOAD_PROJECT_ZIP_FAILURE = 'DOWNLOAD_PROJECT_ZIP_FAILURE';

export const GET_SCOGO_DEALIDS_DROPDOWN_REQUEST = 'GET_SCOGO_DEALIDS_DROPDOWN_REQUEST';
export const GET_SCOGO_DEALIDS_DROPDOWN_SUCCESS = 'GET_SCOGO_DEALIDS_DROPDOWN_SUCCESS';
export const GET_SCOGO_DEALIDS_DROPDOWN_FAILURE = 'GET_SCOGO_DEALIDS_DROPDOWN_FAILURE';
export const CLEAR_DEAL_DROPDOWN_LIST_FROM_STORE = 'CLEAR_DEAL_DROPDOWN_LIST_FROM_STORE';

export const GET_PROJECT_POST_LIST_REQUEST = 'GET_PROJECT_POST_LIST_REQUEST';
export const GET_PROJECT_POST_LIST_SUCCESS = 'GET_PROJECT_POST_LIST_SUCCESS';
export const GET_PROJECT_POST_LIST_FAILUIRE = 'GET_PROJECT_POST_LIST_FAILUIRE';

export const CREATE_PROJECT_TEXT_POST_REQUEST = 'CREATE_PROJECT_TEXT_POST_REQUEST';
export const CREATE_PROJECT_TEXT_POST_SUCCESS = 'CREATE_PROJECT_TEXT_POST_SUCCESS';
export const CREATE_PROJECT_TEXT_POST_FAILUIRE = 'CREATE_PROJECT_TEXT_POST_FAILUIRE';

export const CREATE_PROJECT_ATTACHMENT_POST_REQUEST = 'CREATE_PROJECT_ATTACHMENT_POST_REQUEST';
export const CREATE_PROJECT_ATTACHMENT_POST_SUCCESS = 'CREATE_PROJECT_ATTACHMENT_POST_SUCCESS';

export const PROJECT_POST_STATUS_REGUEST = 'PROJECT_POST_STATUS_REGUEST';
export const PROJECT_POST_STATUS_SUCCESS = 'PROJECT_POST_STATUS_SUCCESS';
export const PROJECT_POST_STATUS_FAILUIRE = 'PROJECT_POST_STATUS_FAILUIRE';

export const GET_LINKED_PROJECT_OF_USERS_REQUEST = 'GET_LINKED_PROJECT_OF_USERS_REQUEST';
export const GET_LINKED_PROJECT_OF_USERS_SUCCESS = 'GET_LINKED_PROJECT_OF_USERS_SUCCESS';
export const GET_LINKED_PROJECT_OF_USERS_FAILURE = 'GET_LINKED_PROJECT_OF_USERS_FAILURE';

export const GET_LINKED_PROJECT_OF_TEAMS_REQUEST = 'GET_LINKED_PROJECT_OF_TEAMS_REQUEST';
export const GET_LINKED_PROJECT_OF_TEAMS_SUCCESS = 'GET_LINKED_PROJECT_OF_TEAMS_SUCCESS';
export const GET_LINKED_PROJECT_OF_TEAMS_FAILURE = 'GET_LINKED_PROJECT_OF_TEAMS_FAILURE';

export const CREATE_PROJECT_TEMPLATE = 'CREATE_PROJECT_TEMPLATE';
export const CREATR_PROJECT_TEMPLATE_SUCCESS = 'CREATR_PROJECT_TEMPLATE_SUCCESS';
export const CREATR_WORKFLOW = 'CREATR_WORKFLOW';


export const EDIT_PROJECT_PM_REQUEST = 'EDIT_PROJECT_PM_REQUEST';
export const EDIT_PROJECT_PM_SUCCESS = 'EDIT_PROJECT_PM_SUCCESS';
export const EDIT_PROJECT_PM_FAILURE = 'EDIT_PROJECT_PM_FAILURE';

export const GET_PROJECT_PM_REQUEST = 'GET_PROJECT_PM_REQUEST';
export const GET_PROJECT_PM_SUCCESS = 'GET_PROJECT_PM_SUCCESS';
export const GET_PROJECT_PM_FAILURE = 'GET_PROJECT_PM_FAILURE';

export const CREATR_WORKFLOW_SUCCESS = 'CREATR_WORKFLOW_SUCCESS';

export const ADD_MULTIPLE_PROJECTS_IN_TEAM_PROJECT_REQUEST = 'ADD_MULTIPLE_PROJECTS_IN_TEAM_PROJECT_REQUEST';
export const ADD_MULTIPLE_PROJECTS_IN_TEAM_PROJECT_SUCCESS = 'ADD_MULTIPLE_PROJECTS_IN_TEAM_PROJECT_SUCCESS';
export const ADD_MULTIPLE_PROJECTS_IN_TEAM_PROJECT_FAILURE = 'ADD_MULTIPLE_PROJECTS_IN_TEAM_PROJECT_FAILURE';

export const ADD_SLA_IN_PROJECT_REQUEST = 'ADD_SLA_IN_PROJECT_REQUEST';
export const ADD_SLA_IN_PROJECT_SUCCESS = 'ADD_SLA_IN_PROJECT_SUCCESS';

export const GET_PROJECTS_SLA_REQUEST = 'GET_PROJECTS_SLA_REQUEST';
export const GET_PROJECTS_SLA_SUCCESS = 'GET_PROJECTS_SLA_SUCCESS';
