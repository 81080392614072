import IconToolTip from '../../../../../common/IconToolTip';
import Loader from '../../../../../common/Loader';
const baseIconClass = 'flex items-center justify-center aspect-square h-8 rounded backdrop-blur-sm hover:backdrop-blur-lg shadow-lg';
const baseIconClassNotHidden = 'flex items-center justify-center aspect-square h-8 rounded backdrop-blur-sm hover:backdrop-blur-lg shadow-lg';

export const FileDownloadIcon = ({ position = '', onClick, color = { normal: 'scogoorange', hover: 'scogoprimary' } }) => {
    return (
        <IconToolTip title='Download'>
            <p
                className={`absolute ${baseIconClass} text-${color.normal} hover:text-${color.hover} border border-${color.normal} hover:border-${color.hover} ${position}`}
                onClick={onClick}
            >
                <span className='material-icons text-font16'>file_download</span>
            </p>
        </IconToolTip>
    );
};

export const ApproveAndInclueInReportIcon = ({ onClick, isLoading, isCardMessage }) => {
    return (
        <IconToolTip title='Approve and include in Report'>
            <button className={`${isCardMessage ? baseIconClassNotHidden : baseIconClass} text-scogosuccess border border-scogosuccess ${isLoading ? 'cursor-not-allowed' : 'cursor-pointer hover:text-scogoprimary hover:border-scogoprimary'}`} onClick={onClick} disabled={isLoading} >
                {isLoading ? <Loader size='15' /> : <span className='material-icons text-font16'>check</span>}
            </button>
        </IconToolTip>
    );
};

export const RejectIcon = ({ onClick, isLoading, isCardMessage }) => {
    return (
        <IconToolTip title='Reject'>
            <button className={`${isCardMessage ? baseIconClassNotHidden : baseIconClass} text-scogoclosed border border-scogoclosed ${isLoading ? 'cursor-not-allowed' : 'cursor-pointer hover:text-scogoprimary hover:border-scogoprimary'}`} onClick={onClick} disabled={isLoading}>
                {isLoading ? <Loader size='15' /> : <span className='material-icons text-font16'>close</span>}
            </button>
        </IconToolTip>
    );
};
