export default function LabelValueWithIcon({ label, value, icon = 'perosn' }) {
    return (
        <div className='flex items-center gap-4 text-scogoprimary '>
            <span className='material-icons text-font30'>{icon}</span>
            <div>
                <p className={`text-light  inline text-gray-600 text-font14  whitespace-nowrap`}>{label}</p>
                <p className={`text-light w-auto   text-font24 font-semibold`}>{value}</p>
            </div>
        </div>
    );
}
