import moment from 'moment';
import React from 'react';
import IconToolTip from '../../../../common/IconToolTip';

function Timestamp({ className, timestamp, isSearchMessage }) {
    const dateTime = moment(timestamp);
    const time = isSearchMessage ? dateTime.format('dddd, MMMM Do YYYY') : dateTime.format('hh:mm a');
    if (timestamp) {
        let completeTime = '';
        if (moment(timestamp).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
            completeTime = `Today ${dateTime.format('hh:mm a')}`;
        } else if (moment(timestamp).format('YYYY-MM-DD') === moment().subtract(1, 'day').format('YYYY-MM-DD')) {
            completeTime = `Yesterday ${dateTime.format('hh:mm a')}`;
        } else {
            completeTime = moment(timestamp).format('dddd, MMMM Do YYYY');
        }
        return (
            <IconToolTip title={completeTime}>
                <p className={`text-font10 text-scogogray font-normal ${className}`}>{time}</p>
            </IconToolTip>
        );
    }
    return <></>;
}

export default Timestamp;
