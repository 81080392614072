import React, { useState, useEffect, useRef, forwardRef, useCallback } from 'react';
import { modulo, getLookupWitHDefaultValue, getFileType } from '../../../../utils/common';
import { MentionsInputBar } from './MentionsInput';

import { throttle } from 'lodash';
import { sendDataThroughWebsocket } from '../../../../actions/websocket';
import { websocketEvents } from '../../../../utils/websocket';
import { useDispatch } from 'react-redux';
import { transformMentionsForSubmit } from './MessageInput';
const getClass = (isSingle) => {
    return isSingle ? 'm-auto max-w-full' : ' m-auto  justify-center  ';
};
const getAudioComponent = ({ url, isSingle }) => {
    return (
        <div className='h-full w-full '>
            <img src={process.env.PUBLIC_URL + '/img/audio.png'} className={getClass(isSingle)} style={isSingle && { height: '80%' }} alt='' />
            {isSingle && <audio className='w-4/5 m-auto h-1/5' src={url} controls />}
        </div>
    );
};

const getPdfComponent = ({ url, isSingle }) => {
    return (
        <>
            {isSingle ? (
                <object data={url} aria-label='pdf' className={getClass(isSingle) + ' w-3/4 '} />
            ) : (
                <img className={getClass(isSingle)} src={process.env.PUBLIC_URL + '/img/pdf.png'} alt='' />
            )}
        </>
    );
};

const getComponentByType = ({ type, url, isSingle }) => {
    const className = getClass(isSingle);
    const getImageComponent = (src) => <img src={process.env.PUBLIC_URL + src} className={`${className} rounded-lg max-h-full`} alt='' />;
    const lookup = getLookupWitHDefaultValue({
        lookup: {
            image: <img src={url} className={`${className} max-h-full`} alt='' />,
            audio: getAudioComponent({ url, isSingle }),
            video: <video className={`${className} max-h-full`} autoPlay loop src={url} />,
            pdf: getPdfComponent({ url, isSingle }),
            text: getImageComponent('/img/txt-file.png'),
            xls: getImageComponent('/img/xls.png'),
        },
        defaultValue: <img src={process.env.PUBLIC_URL + '/img/unknownFileType.png'} className={`${className} rounded-lg `} alt='' />,
    });
    return lookup[type];
};

export default function SendFiles({ onFileSelect, closeFileUI, onFileSend, files, setFile, taggableUsers, openedConversationId, users, isChatPopup, replyShow }) {
    const [selectedMediaIndex, setSelectedmediaIndex] = useState(0);
    const [fileData, setFileData] = useState([]);
    const [selectedMediaId, setSelectedMediaId] = useState(0);
    const inputRef = useRef();
    useEffect(() => {
        inputRef.current?.focus();
    }, []);
    useEffect(() => {
        setFileData(() => {
            const newFileData = files;
            setSelectedMediaId(newFileData[0].id);
            return newFileData;
        });
    }, [files]);

    const getArrow = (direction) => {
        const classLookup = { left: 'left-2', right: 'right-2' };
        const angleLookup = { left: '<', right: '>' };

        const styleLookup = { left: { marginLeft: '6%' }, right: { marginRight: '6%' } };
        return (
            <div
                className={`${classLookup[direction]} absolute cursor-pointer backdrop-filter backdrop-blur-lg rounded-full leading-6 border border-scogoblack text-center bg-gray-300 shadow-primaryBtnShadow h-12 w-12 flex justify-center items-center`}
                onClick={() => handleArrowClick(direction)}
                style={styleLookup[direction]}
            >
                <span className='text-black text-2xl text-font25 font-bold'>{angleLookup[direction]}</span>
            </div>
        );
    };

    const handleArrowClick = (arrowDirection) => {
        const increment = arrowDirection === 'right' ? 1 : -1;
        const attachmentsArray = fileData.length;
        const nextIndex = modulo(selectedMediaIndex + increment, attachmentsArray);
        setSelectedmediaIndex(nextIndex);
        setSelectedMediaId(fileData[nextIndex].id);
    };

    const getSelectedClassName = (currentId) => {
        if (currentId === selectedMediaId) return 'border-2 rounded-md border-scogobgsky h-full ';
        return ' h-full';
    };

    const showSelectedMedia = (selectedMedia) => {
        const mediaFile = fileData?.find((el) => el?.id === selectedMedia)?.file;
        let type = getFileType(mediaFile.type);
        const fileUrl = URL?.createObjectURL(mediaFile);
        let height = '50vh';
        if (isChatPopup) height = '30vh';
        if (replyShow) height = '20vh';

        return (
            <>
                <div className={`max-w-7/5 flex items-center m-auto pt-4`} style={{ height }}>
                    {fileData.length > 1 && getArrow('left')}
                    {fileData.length > 1 && getArrow('right')}
                    {getComponentByType({ type, url: fileUrl, isSingle: true })}
                </div>
                <p className='m-auto w-9/12 text-center mt-6 text-font14 truncate'>{mediaFile?.name}</p>
            </>
        );
    };

    const showSelectedFilesList = (fileData) => {
        return (
            <div className='flex overflow-x-auto gap-3  max-w-8/8 h-full '>
                {fileData.map((item, index) => {
                    const mimeType = item.file.type;
                    const type = getFileType(mimeType);
                    const fileUrl = URL.createObjectURL(item.file);
                    let elem = getComponentByType({ type, url: fileUrl });
                    let height = '12vh';
                    if (isChatPopup) height = '8vh';

                    return (
                        <div key={item.id} className=' relative ml-1 rounded-md ' style={{ 'aspect-ratio': '1 / 1', height }}>
                            <div
                                onClick={() => {
                                    setSelectedMediaId(item.id);
                                    setSelectedmediaIndex(index);
                                }}
                                className={getSelectedClassName(item.id)}
                                style={{ 'aspect-ratio': '1 / 1' }}
                            >
                                {elem}
                            </div>
                            <span
                                onClick={() => removeFileFromList(item.id)}
                                className='material-icons absolute bg-white rounded-full shadow-lg h-8 text-3xl cursor-pointer -top-2 right-0'
                            >
                                close
                            </span>
                        </div>
                    );
                })}
            </div>
        );
    };

    const removeFileFromList = (id) => {
        setFile((prevFileData) => {
            const newFileData = prevFileData?.filter((el) => el.id !== id);
            if (newFileData.length > 0) {
                setSelectedMediaId(newFileData[0].id);
            } else {
                setSelectedMediaId(null);
                closeFileUI();
            }
            return newFileData;
        });
    };

    let sliderHeight = '15vh';
    if (isChatPopup) sliderHeight = '10vh';

    return (
        <div className='z-50 relative bg-white h-1/2 border-t-2 w-full cursor-pointer'>
            <div className='m-auto overflow-hidden'>{selectedMediaId && showSelectedMedia(selectedMediaId)}</div>
            <div className=' flex w-full items-center border-t-2'>
                <div className={`overflow-hidden m-auto flex justify-center max-w-7/8 gap-3 w-full py-2`} style={{ maxHeight: sliderHeight }}>
                    {showSelectedFilesList(fileData)}
                    <div className='max-h-max border-2  flex items-center justify-center relative cursor-pointer' style={{ 'aspect-ratio': '1 / 1' }}>
                        <input type='file' className='w-full h-full absolute cursor-pointer opacity-0 z-5 ' multiple onChange={onFileSelect} value='' />
                        <span className='material-icons cursor-pointer text-scogogray  text-font18'>add</span>
                    </div>
                </div>
            </div>
            <Input selectedMediaId={selectedMediaId} users={users} onFileSend={onFileSend} openedConversationId={openedConversationId} fileData={fileData} ref={inputRef} taggableUsers={taggableUsers} />
            <div className='absolute right-2 top-2 flex items-center'>
                <span onClick={closeFileUI} className='material-icons cursor-pointer'>
                    close
                </span>
            </div>
        </div>
    );
}

const Input = forwardRef(({ selectedMediaId, onFileSend, fileData, taggableUsers, openedConversationId, users }, ref) => {
    const [fileCaptionvalue, setFileCaption] = useState('');
    const [inputRichValue, setInputRichValue] = useState('');
    const [mentionsusers, setMentionsusers] = useState([]);
    const dispatch = useDispatch()
    const handleInputChange = (inputValue, mentions) => {
        setMentionsusers((prevState) => ({ ...prevState, [selectedMediaId]: mentions || '' }));
        setFileCaption((prevState) => ({ ...prevState, [selectedMediaId]: inputValue || '' }));
    };

    const SendFilesOnClick = () => {
        const files = fileData?.map((item) => {
            const res = { file: item.file, id: item.id, caption: item.caption, tags: [], height: item.height, width: item.width };
            if (fileCaptionvalue[item.id]) {
                res.caption = fileCaptionvalue[item.id]
                res.tags = transformMentionsForSubmit(mentionsusers?.[item.id], users)
            }
            return res;
        });
        onFileSend(files);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            SendFilesOnClick();
            e.preventDefault();
        }
    };

    const handleInputRichValue = (newRichValue) => {
        setInputRichValue((prevState) => ({ ...prevState, [selectedMediaId]: newRichValue.trimStart() || '' }));
    }

    const sendTypingEvent = (conversationId) => {
        dispatch(
            sendDataThroughWebsocket({
                action: websocketEvents.chat.typing,
                data: {
                    conversation: conversationId,
                },
            })
        );
    };

    const throttleFunTypinEvent = useCallback(throttle(sendTypingEvent, 1000), []);

    return (
        <div className='h-1/5 '>
            <div className='flex w-full justify-center items-center m-auto border-2'>
                <MentionsInputBar
                    onChange={(e, newRichValue, newPlainTextValue, mentions) => {
                        handleInputRichValue(newRichValue.trimStart());
                        handleInputChange(newPlainTextValue.trimStart(), mentions);
                        throttleFunTypinEvent(openedConversationId);
                    }}
                    onKeyDown={handleKeyDown}
                    value={inputRichValue[selectedMediaId] || ''}
                    ref={ref}
                    taggableUsers={taggableUsers}
                />
                <div className='ml-4' onClick={() => SendFilesOnClick()}>
                    <span className='material-icons cursor-pointer text-scogoprimary'>send</span>
                </div>
            </div>
        </div >
    );
});
