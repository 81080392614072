import IconToolTip from './IconToolTip';
import { displayCount } from '../utils/common';
import { circleCss } from './tabeHelpers';

const Badge = ({ count: num, background, className = 'ml-2  relative top-2 text-xs' }) => {
    const parsedCountString = displayCount(num);
    let paddingLeft, paddingRight;
    if (parsedCountString.length > 3) {
        paddingLeft = `1.5em`;
        paddingRight = `1.8em`;
    }
    return (
        <IconToolTip title={`${num}`}>
            <span style={{ borderRadius: '50%', paddingLeft, paddingRight }} className={`${circleCss} ${className} ${background}`}>
                {parsedCountString}
            </span>
        </IconToolTip>
    );
};

export default Badge;
