import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSpareCategories, getInventoryPartcodes, getAssetsTypes, updateInventorySpareAsset } from '../../../actions/inventory';
import { closeModalAction } from '../../../actions/modal';
import moment from 'moment';
import { actualValueForDropdown, isLooksLikeId, validateStringValue, validateNumberValue, getSelectedItemFromDropdownList } from '../../../utils/common';
import { sendToastMessage } from '../../../actions/toast';
import Form from '../../../common/Form';
import Input from '../../../common/Form/Input';
import Select from '../../../common/Form/Select';
import { useForm, useFormContext } from 'react-hook-form';
import DatePicker from '../../../common/Form/DatePicker';
import Checkbox from '../../../common/Form/Checkbox';
import { isCluster, isPsp, isScm } from '../../../utils/role';

const isTruthyValue = item => {
    return ![undefined, null].includes(item);
};

const warrantyType = [
    { key: 1, value: 'Manufacturing', label: 'Manufacturing' },
    { key: 2, value: 'Installation', label: 'Installation' },
    { key: 3, value: 'Billing', label: 'Billing' },
];
const assetStatus = [
    { key: 1, value: 'AVAILABLE', label: 'Available' },
    { key: 2, value: 'USED', label: 'Used' },
    { key: 3, value: 'FAULTY', label: 'Faulty' },
];

const formName = 'editInventorySpareAsset';
const EditInventorySpareAsset = ({ modalData, customerId, renderButtons }) => {
    const dispatch = useDispatch();
    const { spareCategory, partCodes, assetTypes } = useSelector((state) => state.inventory);
    const { formButtonLoading } = useSelector((state) => state.loading);

    const { loggedUser } = useSelector(state => state.auth);

    const role = loggedUser.role.id;
    const type = loggedUser.type;

    if (!(isScm(role, type) || isCluster(role, type) || isPsp(role, type))) {
        renderButtons = null;
    }

    const hasWarrantyDetails = (asset) => {
        if (validateStringValue(asset?.warranty_type) || moment(asset?.warranty_start_date).isValid() || moment(asset?.warranty_end_date).isValid()) return true;
        return false;
    };

    const hasOemDetails = (asset) => {
        if (validateNumberValue(asset?.oem_id) || moment(asset?.oem_end_date).isValid() || moment(asset?.oem_start_date).isValid() || validateStringValue(asset?.oem_warranty_type))
            return true;
        return false;
    };

    const defaultValues = {
        category: getSelectedItemFromDropdownList(modalData.category, spareCategory),
        external_order_id: modalData?.external_order_id,
        frn: modalData?.frn,
        grn: modalData?.grn,
        hasFrn: validateStringValue(modalData?.frn) ? true : false,
        hasGrn: validateStringValue(modalData?.grn) ? true : false,
        hasOem: hasOemDetails(modalData),
        hasWarranty: hasWarrantyDetails(modalData),
        make: modalData?.make,
        model: modalData?.model,
        oem_end_date: modalData?.oem_end_date && moment(modalData?.oem_end_date).isValid() ? new Date(modalData?.oem_end_date) : '',
        oem_id: modalData?.oem_id,
        oem_start_date: modalData?.oem_start_date && moment(modalData?.oem_start_date).isValid() ? new Date(modalData?.oem_start_date) : '',
        oem_warranty_type: modalData?.oem_warranty_type,
        partcode: getSelectedItemFromDropdownList(modalData?.partcode, partCodes),
        serial_number: modalData?.serial_number,
        status: getSelectedItemFromDropdownList(modalData?.status, assetStatus),
        title: getSelectedItemFromDropdownList(modalData?.title, assetTypes),
        type: modalData?.type,
        unit: modalData?.unit,
        assetTypeId: modalData?.assetTypeId,
        value: modalData?.value,
        warranty_type: getSelectedItemFromDropdownList(modalData?.warranty_type, warrantyType),
        warranty_start_date: modalData?.warranty_start_date && moment(modalData?.warranty_start_date).isValid() ? new Date(modalData?.warranty_start_date) : '',
        warranty_end_date: modalData?.warranty_end_date && moment(modalData?.warranty_end_date).isValid() ? new Date(modalData?.warranty_end_date) : '',
        customer_id: modalData?.customer_id,
    };

    const submit = (form) => {
        const warehouse_id = modalData.warehouse_id;
        const spareAssetId = modalData.id;
        let data = {
            ...form,
            warehouse_id,
            warranty_end_date: form.warranty_end_date ? moment(form.warranty_end_date).format('YYYY-MM-DD') : '',
            warranty_start_date: form.warranty_start_date ? moment(form.warranty_start_date).format('YYYY-MM-DD') : '',
            warranty_type: actualValueForDropdown(form.warranty_type),
            oem_start_date: form.oem_start_date ? moment(form.oem_start_date).format('YYYY-MM-DD') : '',
            oem_end_date: form.oem_end_date ? moment(form.oem_end_date).format('YYYY-MM-DD') : '',
            hasFrn: validateStringValue(modalData?.frn) ? true : false,
            hasGrn: validateStringValue(modalData?.grn) ? true : false,
            hasOem: form.hasOem,
            hasWarranty: form.hasWarranty,
            status: actualValueForDropdown(form.status),
            category: actualValueForDropdown(form.category),
            title: actualValueForDropdown(form.title),
            partcode: actualValueForDropdown(form.partcode),
        };

        Object.entries(data).forEach(([key, value]) => {
            if (!isTruthyValue(value)) delete data[key];
        });

        const isValidWarehouseId = isLooksLikeId(warehouse_id);

        if (isValidWarehouseId) {
            dispatch(updateInventorySpareAsset({ data, assetId: spareAssetId, warehouseId: warehouse_id, customerId, formName }));
        } else {
            dispatch(sendToastMessage({ status: 'danger', message: 'Invalid Data' }));
        }
    };
    const closeModal = () => {
        dispatch(closeModalAction());
    };

    const getDataOnMount = useCallback(() => {
        dispatch(getSpareCategories({ dropdown: true }));
        dispatch(getInventoryPartcodes({ customerId }));
    }, [customerId, dispatch]);

    useEffect(() => {
        getDataOnMount();
    }, [getDataOnMount]);

    const methods = useForm({ defaultValues, mode: 'all' });

    return (
        <>
            <Form
                className='w-full'
                onSubmit={submit}
                onCancel={closeModal}
                defaultValues={defaultValues}
                submitButtonText={'Update'}
                showSubmit
                submitContainerClassName='flex gap-2 justify-end py-4 px-4'
                isLoading={formButtonLoading[formName]}
                methods={methods}
                renderButtons={renderButtons}
            >
                <EditSpareAssetInputs customerId={customerId} />
            </Form>
        </>
    );
};

const EditSpareAssetInputs = ({ customerId }) => {
    const { watch } = useFormContext();
    const hasGrn = watch('hasGrn');
    const hasFrn = watch('hasFrn');
    const hasWarranty = watch('hasWarranty');
    const hasOem = watch('hasOem');
    const category = watch('category');
    const { spareCategory, partCodes, assetTypes } = useSelector((state) => state.inventory);
    const dispatch = useDispatch();

    const assetTypeHandler = useCallback(
        (assetTypeId) => {
            dispatch(getAssetsTypes({ customerId, categoryId: assetTypeId, type: 'SPARE', dropdown: true }));
        },
        [customerId, dispatch]
    );

    useEffect(() => {
        if (category) {
            const categoryFormValue = actualValueForDropdown(category);
            assetTypeHandler(categoryFormValue);
        }
    }, [category, assetTypeHandler]);

    return (
        <div className='px-3'>
            <div className='grid grid-cols-2 rows-fr grid-flow-row-dense'>
                <Select label='Category' required name='category' options={spareCategory} />
                <Select label='Title' required name='title' options={assetTypes} />
            </div>
            <div className='grid grid-cols-3 rows-fr grid-flow-row-dense'>
                <Input label='Make' name='make' type={'text'} />
                <Input label='Model' name='model' type={'text'} />
                <Select label='Partcode' name='partcode' type={'text'} options={partCodes} />
            </div>
            <div className='grid grid-cols-3 rows-fr grid-flow-row-dense'>
                <Input label='Serial Number' type={'text'} name='serial_number' />
                <Input label='Type' type={'text'} name='type' />
                <Input label='External Order Id' type={'text'} name='external_order_id' />
            </div>
            <div className='grid grid-cols-3 rows-fr grid-flow-row-dense'>
                <Input label='Unit' type={'number'} name='unit' />
                <Input label='Price (₹)' type={'number'} name='value' />
                <Select label='Status' name='status' options={assetStatus} />
            </div>
            <div className='px-1'>
                <Checkbox id='hasGrn' label='Do you have GRN' name='hasGrn' />
            </div>
            {hasGrn && (
                <div className='px-1'>
                    <Input label='GRN' type={'text'} name='grn' />
                </div>
            )}
            <div className='px-1'>
                <Checkbox id='hasFrn' label='Do you have FRN' name='hasFrn' />
            </div>
            {hasFrn && (
                <div>
                    <Input label='FRN' type={'text'} name='frn' />
                </div>
            )}
            <div className='px-1'>
                <Checkbox id='hasWarranty' label='Do you have Warranty Details' name='hasWarranty' />
            </div>
            {hasWarranty && (
                <>
                    <div className='grid grid-cols-3 rows-fr grid-flow-row-dense'>
                        <Select label='Warranty Type' name='warranty_type' required options={warrantyType} />
                        <DatePicker label='Warranty Start Date' name='warranty_start_date' />
                        <DatePicker label='Warranty End Date' name='warranty_end_date' />
                    </div>
                </>
            )}
            <div className='px-1'>
                <Checkbox id='hasOem' label='Do you have OEM Details' name='hasOem' />
            </div>
            {hasOem && (
                <div className='grid grid-cols-4 rows-fr grid-flow-row-dense'>
                    <Input label='OEM Id' type={'text'} name='oem_id' />
                    <Input label='OEM Type' name='oem_warranty_type' />
                    <DatePicker label='OEM Start Date' name='oem_start_date' />
                    <DatePicker label='OEM End Date' name='oem_end_date' />
                </div>
            )}
        </div>
    );
};

export default EditInventorySpareAsset;
