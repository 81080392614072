import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import Select, { components } from 'react-select';
import { textCss } from '../../../../../common/Css';
import { useSelector } from 'react-redux';

export default function DropdownField(props) {
    const {
        className,
        label,
        required,
        name,
        onChangeHandler,
        placeholder,
        errors,
        options,
        isMulti,
        form,
        customeStyle,
        selectClass,
        classForClearIcon,
        isLoading,
        isClearableOnSuccess,
        renderOption,
        padding = 'px-4 py-3',
        onSelectInputChange = () => { },
    } = props;
    const { setInitialForm } = useSelector((state) => state.utils);
    const [value, setValue] = useState();
    const selectInputRef = useRef();

    const updateValue = () => {
        if (typeof _.get(form, name) === 'object') setValue(_.get(form, name));
        else {
            if (options) {
                let option = options?.find((elem) => elem?.value === _.get(form, name));
                setValue(option);
            }
        }
    };

    useEffect(() => {
        if (Array.isArray(options)) updateValue();
    }, [_.get(form, name)?.key, _.get(form, name)?.length, options]);

    const customStyles = {
        menuPortal: (base) => ({
            ...base,
            zIndex: 99999,
            position: 'fixed',
        }),
        placeholder: (base) => ({
            ...base,
            fontSize: '1.2rem',
            padding: 0,
            paddingTop: 0,
            paddingBottom: 0,
        }),
        multiValueLabel: (base) => ({
            ...base,
            fontSize: '1rem',
        }),
        singleValue: (base) => ({
            ...base,
            fontSize: '1.2rem',
            padding: 0,
        }),
        container: (base) => ({
            ...base,
            paddingTop: 0,
            paddingBottom: 0,
        }),
        menu: (base) => ({
            ...base,
            marginTop: 0,
        }),
        control: (provided, state) => ({
            ...provided,
            minHeight: '3rem',
            border: state.isFocused ? 'none' : 'none',
            outline: state.isFocused ? 'none' : 'none',
            borderRadius: 0,
        }),
    };

    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <span className='material-icons'>expand_more</span>
            </components.DropdownIndicator>
        );
    };

    const ClearIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <span className='material-icons text-xl font-bold'>clear</span>
            </components.DropdownIndicator>
        );
    };

    const handleDropDownChange = (value) => {
        if (value?.length === 0) {
            onChangeHandler({ name: name, value: '' });
            setValue('');
        } else {
            onChangeHandler({ name: name, value: value });
            setValue(value);
        }
    };

    useEffect(() => {
        if (isClearableOnSuccess) selectInputRef?.current?.select?.clearValue?.();
    }, [setInitialForm]);

    return (
        <>
            <div className={customeStyle ? customeStyle : ` ${padding} relative ${textCss?.inputText}  ${className}`} id='dropdown'>
                <label className='relative block border-b-2 border-scogoddd'>
                    {label && (
                        <span className={textCss?.inputLabel}>
                            {label} {required && <span className='text-scogoorange'>*</span>}{' '}
                        </span>
                    )}
                    <Select
                        ref={selectInputRef}
                        components={{ DropdownIndicator, ClearIndicator }}
                        onChange={(value) => handleDropDownChange(value)}
                        placeholder={placeholder}
                        name={name}
                        options={options && options}
                        isSearchable
                        menuPortalTarget={document.getElementById('dropdown')}
                        styles={customStyles}
                        menuShouldBlockScroll={true}
                        className={'basic-multi-select select-text truncate w-full text-font12' + selectClass}
                        classNamePrefix='select'
                        isMulti={isMulti}
                        menuPlacement={'auto'}
                        value={value}
                        isLoading={isLoading}
                        isClearable
                        menuPosition='fixed'
                        formatOptionLabel={renderOption}
                        onInputChange={onSelectInputChange}
                    />
                </label>
                {_.get(errors, name) && <span className='text-scogoclosed text-font10 font-normal'>{_.get(errors, name)}</span>}
            </div>
        </>
    );
}
