import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

export const SkeltonCardTwo = (props) => {
    const { arr, height, className, padding } = props;

    return (
        <Box sx={{ paddingBottom: padding ? padding : 2, paddingTop: padding ? padding : 2 }}>
            {arr?.map((elem, index) => {
                return (
                    <div key={index} className={`mt-2 ${className}`}>
                        <Skeleton sx={{ background: 'rgb(221,221,221)' }} variant='rectangular' width={'full'} height={height} animation='wave' />
                    </div>
                );
            })}
        </Box>
    );
};
