import Buttons from '../../../../common/Buttons';
import IconToolTip from '../../../../common/IconToolTip';

export const IconButton = (props) => {
    const { loader, onClick, onHoverTitle, iconName, backgroundColor, className = '', loaderSize, text } = props;
    return (
        <>
            <IconToolTip title={onHoverTitle ? onHoverTitle : ''}>
                <div >
                    <Buttons
                        buttonClass={`text-font09 rounded-lg px-1 py-1 ${backgroundColor} border leading-6 cursor-pointer hover:text-white rounded-lg font-medium cursor-pointer`}
                        disabledClass={`text-font09 rounded-lg border-scogoddd bg-scogoddd relative px-1 py-2 flex items-center justify-center`}
                        onClick={onClick}
                        loading={loader}
                        loaderSize={loaderSize}
                    >
                        <div className='flex items-center'>
                            {iconName && <span className={`material-icons material-icons-outlined cursor-pointer  text-font15 ${className}`}>{iconName}</span>}
                            <span>{text}</span>
                        </div>
                    </Buttons>
                </div>
            </IconToolTip>
        </>
    );
};
