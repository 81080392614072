import React from 'react';
import { closeModalAction } from '../../../actions/modal';
import { useDispatch, useSelector } from 'react-redux';
import { requestStatement } from '../../../actions/wallet';
import moment from 'moment';
import Form from '../../../common/Form';
import { useForm } from 'react-hook-form';
import MultipleEmails from '../../../common/Form/MultipleEmail';
import DateRange from '../../../common/Form/DateRange';

const formName = 'requestStatement';
export default function RequestStatement({ userId }) {
    const dispatch = useDispatch();
    const { loggedUser } = useSelector((state) => state.auth);
    const { formButtonLoading } = useSelector((state) => state.loading);

    const defaultValues = {
        emails: loggedUser.email ? [loggedUser.email] : [],
        duration: '',
    };

    const submit = (formValues) => {
        let { duration, emails } = formValues;
        let payload = {
            requestStatement: true,
            fromDate: duration?.[0] ? moment(duration[0]).format('YYYY-MM-DD') : '',
            toDate: duration?.[1] ? moment(duration[1]).format('YYYY-MM-DD') : '',
            emails: emails.join(','),
            formName,
            userId,
        };
        dispatch(requestStatement(payload));
    };

    const methods = useForm({ defaultValues, mode: 'all' });

    return (
        <Form
            methods={methods}
            onSubmit={submit}
            formName={formName}
            submitButtonText={'Request'}
            onCancel={() => dispatch(closeModalAction())}
            showSubmit
            submitContainerClassName='flex gap-2 justify-end py-4 px-4'
            isLoading={formButtonLoading[formName]}
        >
            <div className='px-3 py-2'>
                <MultipleEmails name='emails' required label='Email To' />
                <DateRange label='Select Duration' className='w-full' name='duration' dateFormat='dd-MM-yyyy' placeholder={'Select Duration'} required />
            </div>
        </Form>
    );
}
