import React, { useState, useRef, useEffect } from "react";
import _ from 'lodash';
import { textCss } from "../../../../../common/Css";
import { useSelector } from "react-redux";

export default function UploadFiles(props) {
    const {
        form,
        label,
        required,
        className,
        accept,
        name,
        multiple,
        placeholder,
        errorMessage,
        errors,
        handleFileUpload,
        handleClearFileUpload,
        onBlurHandler,
        isClearableOnSuccess = true,
        overrideExistingFiles = true
    } = props;

    const [uploadedFile, setUploadedDFile] = useState([]);
    const uploadInputRef = useRef();
    const { setInitialForm } = useSelector((state) => state.utils);

    useEffect(() => {
        if (isClearableOnSuccess && uploadInputRef?.current?.value)
            uploadInputRef.current.value = null;
    }, [setInitialForm]);

    const onChangeFileUpload = (event, errorMessage) => {
        handleFileUpload(event, errorMessage, overrideExistingFiles);
    };

    const handleUploadUI = (uploadedFile) => {
        let message = "";
        const fileLength = uploadedFile.length;
        if (fileLength === 0) {
            message = "No File Choosen";
        } else if (fileLength === 1) {
            message = uploadedFile[0].name;
        } else message = `${fileLength} File`;

        return (
            <p
                className={`overflow-hidden w-full truncate ${textCss?.inputText}`}
            >
                {message}
            </p>
        );
    };

    const handleClearFile = (file, name) => {
        let files = [];
        files = [..._.get(form, name)].filter((elem) => {
            return file.name !== elem.name;
        });
        handleClearFileUpload(files, name);
        setUploadedDFile([...files]);
    };

    return (
        <div className={`px-4 py-3 ${className ? className : ''}`}>
            <label className="block  border-b-2 border-scogoddd">
                {label && <span className={textCss?.inputLabel}>
                    {label}{" "}
                    {required && <span className="text-scogoorange">*</span>}
                </span>}
                <div className="flex justify-between relative">
                    <div className="w-full">
                        <input
                            ref={uploadInputRef}
                            type="file"
                            accept={accept}
                            name={name}
                            onChange={(event) => {
                                onChangeFileUpload(event);
                                setUploadedDFile(event.target.files);
                            }}
                            multiple={multiple}
                            placeholder={placeholder}
                            className="hidden w-9/12"
                            required={required}
                            onBlurCapture={onBlurHandler}
                        />
                        <div className="py-2 truncate overflow-hidden mr-8 w-9/12">
                            {handleUploadUI(uploadedFile, errorMessage)}
                        </div>
                    </div>
                    <span className="bg-white z-20 material-icons absolute right-0 w-6 cursor-pointer bottom-1 text-font18">
                        attach_file
                    </span>
                </div>
            </label>
            <div className="pt-1 text-scogobgsky gap-2 flex flex-wrap">
                {_.get(form, name) &&
                    [..._.get(form, name)].map((elem, index) => {
                        return (
                            <div className="w-24 px-1 flex text-xs" key={index}>
                                <p
                                    className={` w-20 truncate  ${textCss?.inputText}`}
                                >
                                    {elem.name}
                                </p>
                                <span
                                    className="text-xs bg-white z-50 material-icons cursor-pointer bottom-1"
                                    onClick={() => handleClearFile(elem, name)}
                                >
                                    clear
                                </span>
                            </div>
                        );
                    })}
            </div>
            {_.get(errors, name) && (
                <span className="text-scogoclosed 2xl:text-font12 text-font10 font-normal">
                    {_.get(errors, name)}
                </span>
            )}
        </div>
    );
}
