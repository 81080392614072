import React, { useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';

import Header from '../layout/header/Header';
import TextModal from '../common/Modals/TextModal';
import FullScreenModal from '../common/Modals/FullScreenModal';
import CustomModal from '../common/Modals/CustomModal';
import { useQuery } from '../utils/common';
import { closeModalAction } from '../actions/modal';

const OnboardingLayout = () => {
    const { isTextModalOpen, isCustomModalOpen, isFullScreenModalOpen } = useSelector((state) => state.modal);

    const query = useQuery();
    const dispatch = useDispatch();

    useLayoutEffect(() => {
        dispatch(closeModalAction());
    }, [query, dispatch]);

    return (
        <div className='h-screen overflow-hidden bg-scogof2 relative'>
            <div className='onboardHeader w-full z-50 flex items-center justify-between'>
                <Header isInactiveUser />
            </div>
            <h1 className=' text-2xl font-medium text-scogo24 font-medium py-auto flex items-center justify-center createAccountHeight'>CREATE YOUR ACCOUNT</h1>
            <div className=' bg-white w-7/12 lg:mx-auto rounded-2xl shadow-logboxshadow m-auto relative onboardChildiv'>{<Outlet />}</div>
            <div className='text-2xl text-center font-medium text-scogo24 font-medium onboardLayoutFooterHeight'></div>
            {isCustomModalOpen && <CustomModal />}
            {isFullScreenModalOpen && <FullScreenModal />}
            {isTextModalOpen && <TextModal />}
        </div>
    );
};

export default OnboardingLayout;
