import { tickets } from '../types';

export const startTicketExecutionManually = ({ ticket, start = true, formName, navigate }) => ({
    type: tickets.START_TICKET_EXECUTION_REQUEST,
    payload: { ticket, start, formName, navigate },
});

export const holdTicket = ({ data, refresh = true, reportPM, formName, refreshTicketTable }) => ({
    type: tickets.HOLD_TICKET_REQUEST,
    payload: { data, refresh, reportPM, formName, refreshTicketTable },
});

// export const approveTicketSignOff = (type, projectId, data, customerId, limit, page) => ({
//     type: tickets.APPROVE_TICKET_SIGNOFF_REQUEST,
//     payload: { type, projectId, data, customerId, limit, page }
// })

// export const rejectTicketSignOff = (type, projectId, data, customerId, limit, page, sortedBy) => ({
//     type: tickets.REJECT_TICKET_SIGNOFF_REQUEST,
//     payload: { type, projectId, data, customerId, limit, page, sortedBy }
// })

// export const signOffCustomerTicket = ({ data, signOffType, type, projectId, customerId, limit, page }) => ({
//     type: tickets.CUSTOMER_TICKET_SIGNOFF_REQUEST,
//     payload: { signOffType, data, type, projectId, customerId, limit, page }
// })

export const updateRLCourierDetails = ({ form, formName }) => ({
    type: tickets.UPDATE_RL_COURIER_DETAILS_REQUEST,
    payload: { form, formName },
});

export const saveFePrequisite = (ticketDetails, reason) => {
    let form = new FormData();
    form.set(`ticket_id`, ticketDetails.id);
    if (reason) {
        form.set(`pre_req_msg`, reason);
    }
    ticketDetails.site_pre_req.map((field, index) => {
        form.set(`pre_req[${index}][project_pre_req_id]`, field.pre_req_id);
        form.set(`pre_req[${index}][is_available]`, 1);
    });
    return {
        type: tickets.SAVE_FE_PRE_REQUISITE_REQUEST,
        payload: { form },
    };
};

export const getSiteBOQList = (ticket_id) => ({
    type: tickets.GET_SITE_BOQ_REQUEST,
    payload: { ticket_id },
});

export const saveFeSiteBOQData = ({ formValues, ticket, ticketExecution = true, formName, navigate, closeModal, source, onSuccess }) => ({
    type: tickets.SAVE_FE_SITE_BOQ_REQUEST,
    payload: { formValues, ticket, ticketExecution, formName, navigate, closeModal, source, onSuccess },
});

export const getExecutionSteps = ({ ticket, data, editWorkFlow = false, navigate }) => ({
    type: tickets.GET_WORK_IN_PROGRESS_STEPS_REQUEST,
    payload: { ticket, data, editWorkFlow, navigate },
});

export const getExecutionStepsFields = ({ ticket, data, navigate, source }) => ({
    type: tickets.GET_WORK_IN_PROGRESS_STEPS_FIELDS_REQUEST,
    payload: { ticket, data, navigate, source },
});

export const updateExecutionStepData = ({ formValues, formName, ticket, stepId, endWip, nextStep, editWorkFlow = false, navigate }) => ({
    type: tickets.UPDATE_WORK_IN_PROGRESS_STEP_DATA_REQUEST,
    payload: { formValues, formName, ticket, stepId, endWip, nextStep, editWorkFlow, navigate },
});

export const uploadSignOffReport = ({ formValues, showToast = false, files, formName, updateViewTicket }) => ({
    type: tickets.UPLOAD_SIGN_OFF_REPORT_REQUEST,
    payload: { formValues, showToast, files, formName, updateViewTicket },
});

export const getSignOffSteps = ({ ticketId, viewTicket = false }) => ({
    type: tickets.GET_SIGN_OFF_STEPS_REQUEST,
    payload: { ticketId, viewTicket }
})

export const updateSignOffSteps = ({ ticketId, data = {}, download = false, ticketExecution = false, editSignOffForm = false, formName, navigate }) => ({
    type: tickets.UPDATE_SIGN_OFF_STEPS_REQUEST,
    payload: { ticketId, data, download, ticketExecution, editSignOffForm, formName, navigate }
})

export const customerSignOffAcceptTicket = ({ ticket_no, formName }) => ({
    type: tickets.CUSTOMER_TICKET_SIGNOFF_REQUEST,
    payload: { ticket_no, formName, status: 1 },
});

export const customerSignOffRejectTicket = ({ ticket_no, customer_rejection_message, formName }) => ({
    type: tickets.CUSTOMER_TICKET_SIGNOFF_REQUEST,
    payload: { ticket_no, customer_rejection_message, formName, status: 0 },
});

export const rejectTicketSignOff = ({ data, formName }) => ({
    type: tickets.REJECT_TICKET_SIGNOFF_REQUEST,
    payload: { data, formName },
});


export const updateExecutionSteps = ({ formValues, formName, closeModal, refresh, source, ticket_id }) => ({
    type: tickets.UPDATE_EXECUTION_STEP_DATA_REQUEST,
    payload: { formValues, formName, closeModal, refresh, source, ticket_id },
});

export const removeExecutionStepFieldsFromStore = () => ({
    type: tickets.REMOVE_EXECUTPION_STEPS_FIELD_FROM_STORE,
});
