import React from 'react';
import { useDispatch } from 'react-redux';

import { textCss } from '../../../common/Css';
import IconToolTip from '../../../common/IconToolTip';
import { validateNumberValue, validateStringValue } from '../../../utils/common';
import { copyToClipboard } from '../../../actions/utils';

export const DetailsToShow = ({ ticketDetails }) => {
    const dispatch = useDispatch()
    const link = validateStringValue(ticketDetails?.assets?.[0]?.title);
    const bandwidth = validateStringValue(ticketDetails?.assets?.[0]?.partcode);
    const address = validateStringValue(
        ticketDetails?.job_site_address_1 ? `${ticketDetails.job_site_address_1}${ticketDetails?.job_fk_pincode_id ? `, ${ticketDetails.job_fk_pincode_id} ` : ''}` : ''
    ).trim();
    const externalSiteId = validateStringValue(ticketDetails?.external_site_id);
    const siteId = validateNumberValue(ticketDetails?.site_id);


    return (
        <div className='py-1'>
            <div className='px-4 w-full flex'>
                <div className='w-4/12 flex flex-col gap-2'>
                    <TootTipElem label='Link' value={link} />
                    <TootTipElem label='Bandwidth' value={bandwidth} />
                </div>
                <div className='w-4/12 flex flex-col gap-2'>
                    <TootTipElem label='External Site Id' value={externalSiteId} />
                    <TootTipElem label='Site Id' value={siteId} />
                </div>
                <div className='w-4/12'>
                    <TootTipElem label='Address' value={address} />
                    <IconToolTip placement={'bottom'} title={`Copy Address`}>
                        <span
                            className='cursor-pointer material-icons text-scogobgsky text-lg'
                            onClick={() => dispatch(copyToClipboard({ data: `${address}\n\nLink: https://maps.google.com?q=${!['', undefined, null, -1.0000000, '-1.0000000', 0.0000000, '0.0000000'].includes(ticketDetails.latitude) && !['', undefined, null, -1.0000000, '-1.0000000', 0.0000000, '0.0000000'].includes(ticketDetails.longitude) ? `${ticketDetails.latitude},${ticketDetails.longitude}` : address}` }))}
                        >
                            content_copy
                        </span>
                    </IconToolTip>
                </div>
            </div>
        </div>
    );
};


const TootTipElem = ({ label, value }) => (
    <span>
        <h3 className={textCss?.detailsTitle}>{label}</h3>
        <IconToolTip title={`${label} : ${value}`}>
            <p className={textCss?.detailsValue + ' line-clamp-2'}>{value}</p>
        </IconToolTip>
    </span>
);