import IconToolTip from '../../../../common/IconToolTip';

export default function UserGeneratedInvoice({ link, number, showUserLabel }) {
    return (
        <p className='text-font11 cursor-pointer hover:text-scogoorange line-clamp-1'>
            <IconToolTip title={`${showUserLabel ? 'User Generated: ' : ''}${number}`}>
                <a href={link} target='_blank' rel='noreferrer'>
                    {number}
                </a>
            </IconToolTip>
        </p>
    );
}
