import React, { useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { getBOQList } from '../../../../actions/tickets';
import { getSiteBOQList, saveFeSiteBOQData } from '../../../../actions/ticketExecution';
import { actualValueForDropdown } from '../../../../utils/common';
import { getAssetWitSerialNumber } from '../../../../actions/inventory';
import Form from '../../../../common/Form';
import AssetsCard from '../../../../components/EditTicket/AssetsCard';
import ButtonScogoPrimary from '../../../../common/Buttons/ButtonScogoPrimary';


export const RenderBoqForm = ({ memoizedTicket, setShowChecklist, currentFeLatLong }) => {
    const { formButtonLoading } = useSelector((state) => state.loading);
    const defaultValues = {};
    const methods = useForm({ defaultValues, mode: 'all' });
    const dispatch = useDispatch();
    const formName = 'boqform';
    const boqArrayName = 'boqArrayName';
    const submitRef = useRef();
    const { ticketDetails, siteBoqList, boqList } = useSelector((state) => state.tickets);
    const { scannedAssetData } = useSelector((state) => state.inventory);

    useEffect(() => {
        dispatch(getBOQList());
        dispatch(getSiteBOQList(memoizedTicket.ticket_id));
    }, [dispatch, memoizedTicket.ticket_id]);

    const onSuccess = () => {
        setShowChecklist(true)
    }

    const { setValue, watch, trigger } = methods;

    useEffect(() => {
        if (boqList && Array.isArray(siteBoqList) && siteBoqList.length > 0) {
            const data = [];
            siteBoqList.forEach((boq, index) => {
                const assetType = boqList.find((i) => i.value === boq.asset_type_id);
                data.push({
                    id: boq.id,
                    asset_type: {
                        inputFields: assetType?.inputFields,
                        key: assetType?.key,
                        label: boq.asset_name,
                        requiredFields: assetType?.requiredFields,
                        value: boq.asset_type_id,
                    },
                    asset_name: boq.asset_name,
                    external_order_id: boq.externel_order_id,
                    quantity: 1,
                    make: boq.make,
                    model: boq.model,
                    partcode: boq.partcode,
                    serial_number: boq.serial_number,
                    asset_details: boq.asset_details,
                    additional_data: boq.additional_data,
                    is_boq_available: true,
                    last_worked_date: '',
                    latitude: '',
                    longitude: '',
                    readonly_serial: true,
                    readonly_all: false,
                });
            });
            setValue(boqArrayName, data);
        }
    }, [siteBoqList, boqList, setValue]);

    const submit = (formValues) => {
        const payload = {
            assetsData: [],
            assetsConsumableData: []
        }
        if (Array.isArray(formValues[boqArrayName]) && formValues[boqArrayName].length > 0) {
            formValues[boqArrayName].forEach(boq => {
                boq.asset_type_id = actualValueForDropdown(boq.asset_type)
                if (!['', undefined, null].includes(boq.quantity) && !isNaN(boq.quantity) && parseInt(boq.quantity) > 0) {
                    Array.from({ length: parseInt(boq.quantity) }).forEach((_, index) => {
                        if (index === 0) {
                            payload.assetsData.push({
                                ...boq,
                                quantity: 1
                            })
                        } else {
                            payload.assetsData.push({
                                id: boq.id,
                                asset_type_id: boq.asset_type_id,
                                is_boq_available: true,
                                quantity: 1
                            })
                        }
                    })
                } else {
                    payload.assetsData.push(boq)
                }
            })
        }
        dispatch(saveFeSiteBOQData({ formValues: payload, formName, ticket: ticketDetails, onSuccess }))
    }

    useEffect(() => {
        const boqData = watch(boqArrayName);
        if (scannedAssetData && boqData) {
            let updated = boqData.map((asset) => {
                let findAsset = scannedAssetData[asset.tempId];
                if (asset.newlyAdded && findAsset) {
                    return {
                        quantity: findAsset.quantity,
                        make: findAsset.make,
                        model: findAsset.model,
                        partcode: findAsset.partcode,
                        serial_number: findAsset.serial_number,
                        asset_name: findAsset?.assetType?.title,
                        asset_type: {
                            key: findAsset?.assetType?.id,
                            label: findAsset?.assetType?.title,
                            value: findAsset?.assetType?.id,
                            inputFields: "make,model,external_order_id,serial_number" // _alpesh_
                        },
                    }
                }
                return asset;

            })
            setValue(boqArrayName, updated);
            trigger(boqArrayName);
        }
    }, [scannedAssetData, setValue, watch]);

    const onScan = (value, id) => {
        const payload = { source: 'support_track', id };
        if (value) {
            const urlObj = new URL(value);
            const tag = urlObj.searchParams.get("tag");
            if (tag) payload.tag = tag
        }
        if (currentFeLatLong) {
            payload.scannedLocation = `${currentFeLatLong.lat},${currentFeLatLong.lng}`
        }
        if (memoizedTicket.site_id) payload.siteId = memoizedTicket.site_id;
        dispatch(getAssetWitSerialNumber(payload));
    };

    return <div >
        <div className={`overflow-hidden h-full`} style={{ maxHeight: '50vh', overflowY: 'auto' }}>
            <Form
                methods={methods}
                onSubmit={submit}
                submitRef={submitRef}
            >
                <div>
                    <AssetsCard
                        assetsArrayName={boqArrayName}
                        ticket={memoizedTicket}
                        ticketCustomerId={memoizedTicket.id}
                        headerText={'BOQ Details'} siteId={0}
                        fieldsClass='grid grid-cols-2 w-11/12 text-font11 text-black font-normal'
                        onScan={onScan}
                        showScanner
                        mainDivClass='border-1 border-scogoorange'
                    />
                </div>
            </Form>
        </div>
        <div className='rounded-2xl w-full flex items-center justify-end px-4' style={{ height: '6vh', overflowY: 'auto' }}>
            <ButtonScogoPrimary
                onClick={(e) => {
                    e.preventDefault();
                    submitRef.current.click();
                }}
                textOrComponent={'Next'}
                loading={formButtonLoading[formName]}
                buttonClass='bg-scogoprimary  float-right text-white font-medium h-10 rounded-lg  hover:bg-scogobgsky'
                disabledClass='bg-scogogray cursor-not-allowed float-right text-white font-medium text-font12 h-10 rounded-lg overflow-hidden'
                loaderClass={'w-full h-10'}
                disabled={false}
            />
        </div>
    </div >
}