import { Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModalAction, openCustomModal } from '../../../actions/modal';
import { updateUserProfileBussinessDetails } from '../../../actions/onboarding';
import { updateSpCustomerStatus } from '../../../actions/sp';
import { adhaarValidation, bussinessPancardValidation, getAllCities, getAllStates, getSPProfileDetails, removeValidateAadharResponse } from '../../../actions/users';
import Card from '../../../common/Card';
import IconToolTip from '../../../common/IconToolTip';
import InLineLabelValue from '../../../common/InLineLabelValue';
import { actualValueForDropdown, getValidationIcons, validateNumberValue, validateStringValue } from '../../../utils/common';
import { isValidAadhaar, isValidPincode, validatePAN } from '../../../utils/utils';
import Form from '../../Inventory/reusableComponents/AddOption/Form';
import Input from '../../Inventory/reusableComponents/AddOption/Input';
import { SpanLabelButton } from './CustomerBusinessDetails';
import { VerifyAadharForm } from '../../Onboarding/Modal/VerifyAadharForm';
import GstModal from './GstModal';
import MiniPopup from '../../../common/MiniPopup';
import GoogleMap from '../../../common/GoogleMaps';

import { getIsToShowGSTLabel } from '../UserProfileController';
import { isCluster, isFe } from '../../../utils/role';
import { getSingleChatFEProfileDetails } from '../../../actions/conversations';
import moment from 'moment';

export const aadhar = { key: 1, value: 1, label: 'Aadhaar Card' };
export const pancard = { key: 2, value: 2, label: 'Pancard' };
export const idProofCategoryOptions = [aadhar, pancard];

function ViewAllLabel({ dataToPopulate, dataToShowPerRow, label, keyToDisplay, icon, iconColor }) {
    const dispatch = useDispatch();

    const closeModal = () => {
        dispatch(closeModalAction());
    };

    const viewAllModal = (data, heading = 'view all', name) => {
        let modalWidth = '60em',
            modalHeight = '40em';
        let modalComponent = (
            <ViewAllModal icon={icon} keyToDisplay={keyToDisplay} iconColor={iconColor} data={data} closeModal={closeModal} modalWidth={modalWidth} modalHeight={modalHeight} />
        );
        let head = heading;
        dispatch(openCustomModal({ modalComponent, heading: head, modalWidth, modalHeight }));
    };

    return (
        <>
            <div className='flex items-center justify-between '>
                <IconToolTip title='View All'>
                    <span className='text-font12 font-normal text-scogoorange cursor-pointer hover:text-scogobgsky' onClick={() => viewAllModal(dataToPopulate, `${label}`)}>
                        +{dataToPopulate?.length - dataToShowPerRow}
                    </span>
                </IconToolTip>
                &nbsp;
            </div>
        </>
    );
}

export const RectangleBadge = ({ label, elementsArray, parentClass = '', icon, iconColor, chipColor = 'bg-tagbg text-scogoprimary', keyToDisplay, dataToShowPerRow = 4 }) => {
    if (!elementsArray || elementsArray?.length === 0) return <></>;
    return (
        <div className={`${parentClass} w-auto mt-2`}>
            <span className=' text-gray-600 text-font13 '>{label}</span>
            <div className='flex flex-wrap gap-2 mt-3'>
                {elementsArray?.slice(0, dataToShowPerRow)?.map((element) => {
                    let valueToShow = keyToDisplay ? element?.[keyToDisplay] : element;
                    return (
                        valueToShow && (
                            <p className={`font-md font-bold  2xl:text-font10 text-font10 px-p-6 py-p-3 flex items-center rounded-3px mr-2 mb-1 ${chipColor} `}>
                                {icon && <span className={`material-icons m-auto ${iconColor} text-font12 pr-2`}>{icon}</span>}
                                {valueToShow}
                            </p>
                        )
                    );
                })}
                {elementsArray?.length > dataToShowPerRow && (
                    <ViewAllLabel keyToDisplay={keyToDisplay} icon={icon} iconColor={iconColor} dataToShowPerRow={dataToShowPerRow} dataToPopulate={elementsArray} label={label} />
                )}
            </div>
        </div>
    );
};

const WorkingForCustomerChips = ({ label, elementsArray, dataToShowPerRow = 10, parentClass = '' }) => {
    const dispatch = useDispatch();
    if (!elementsArray || elementsArray?.length === 0) return <></>;
    const updateWorkingForStatus = ({ customerId, status }) => {
        dispatch(updateSpCustomerStatus({ customerId: customerId, status: status }));
    };
    return (
        <div className={parentClass + 'mt-4'}>
            <span className=' text-gray-600 text-font14 '>{label}</span>
            <div className='flex flex-wrap gap-2 pt-2'>
                {elementsArray?.slice(0, dataToShowPerRow)?.map((element) => (
                    <IconToolTip title={`${element.status === 1 ? 'Disaled' : 'Enabled'}`}>
                        <div
                            className={`font-bold w-max-36 border-scogoddd ${element.status === 1 ? 'bg-scogoddd text-gray-600' : 'bg-scogoprimary text-white'
                                } 2xl:text-font11 text-font10 px-p-10 py-p-3  flex items-center rounded-full mr-2 mb-1`}
                        >
                            <span className='px-2'>{element?.customer_company_name}</span>
                            {element?.customer_company_name !== 'Scogo Network' && element?.status !== 1 && (
                                <div
                                    class='cursor-pointer text-white opacity-80 text-font12 material-icons  w-2/12'
                                    onClick={() => updateWorkingForStatus({ customerId: element?.fk_customer_id, status: 1 })}
                                >
                                    cancel
                                </div>
                            )}
                            {element?.status === 1 && (
                                <div
                                    class='cursor-pointer text-green-600  text-font12 material-icons  w-2/12'
                                    onClick={() => updateWorkingForStatus({ customerId: element?.fk_customer_id, status: 0 })}
                                >
                                    check_circle
                                </div>
                            )}
                        </div>
                    </IconToolTip>
                ))}
            </div>
        </div>
    );
};

const getNonIncentiveLocations = (office_location) => {
    if (!office_location) return;
    let cities = office_location?.map((item) => item.city && item.city.city_name);
    let uniqueLocations = [...new Set(cities)];
    return uniqueLocations;
};

const getMapMarkerData = (office_location) => {
    if (!office_location) return;
    let cities = office_location?.map((item) => {
        if (item.city) return { name: item.city.city_name, color: 'yellow', lat: item.city.city_lat, lng: item.city.city_lng };
    });
    let uniqueLocations = [...new Set(cities)];
    return uniqueLocations;
};

const getMapBoundryData = (territories) => {
    if (!territories) return;
    let incentiveLocations = territories
        ?.map((item) => {
            if (item && item?.state_name) {
                return { name: item.state_name, color: '#F48A21', title: 'Incentive Area' };
            }
        })
        .filter((item) => item !== undefined);
    let uniqueLocations = [...new Set(incentiveLocations)];
    return uniqueLocations;
};

const getIncentiveLocations = (territories, role, type) => {
    if (!territories) return;
    let incentiveLocations = territories?.map((item) => {
        if (item?.state_name?.length > 0) {
            return item.state_name
        } else if (item.city_name?.length > 0) {
            return item.city_name
        } else if (item?.pincode > 0) {
            return item?.pincode
        }
    }).filter((item) => item !== undefined);
    let uniqueLocations = [...new Set(incentiveLocations)];
    return uniqueLocations;
};

const removeDuplicates = (arr) => {
    const { res } = arr?.reduce(
        ({ encountered, res }, el) => {
            if (!encountered?.has(el?.user_cases?.use_case_name)) {
                encountered?.add(el?.user_cases?.use_case_name);
                res.push(el?.user_cases);
            }
            return { encountered, res };
        },
        { encountered: new Set(), res: [] }
    );
    return res;
};

const getBuisnessData = ({ loggedUser, spProfileDetails, feProfileDetails }) => {
    let bussinessData = {
        applicable_territory: loggedUser?.applicable_territory,
        company_name: spProfileDetails?.company_detail?.sp_name ? spProfileDetails?.company_detail?.sp_name : `${loggedUser?.first_name} ${loggedUser?.last_name}`,
        gst_number: spProfileDetails?.company_detail?.sp_gst_no ? spProfileDetails?.company_detail?.sp_gst_no : 'N/A',
        pan_number: spProfileDetails?.company_detail?.sp_pan_card_number || spProfileDetails?.company_detail?.user?.pancard_number,
        adhaar_number: spProfileDetails?.company_detail?.sp_adhaar_number,
        address: isFe(loggedUser?.role?.id, loggedUser.type)
            ? `${feProfileDetails?.address}`
            : `${validateStringValue(spProfileDetails?.company_detail?.sp_address_line_1)} ${validateStringValue(spProfileDetails?.company_detail?.sp_primary_pincode)}`,
        non_incentive_location: getNonIncentiveLocations(spProfileDetails?.company_detail?.office),
        mapMarkerData: getMapMarkerData(spProfileDetails?.company_detail?.office),
        mapBoundryData: getMapBoundryData(spProfileDetails?.company_detail?.user?.applicable_territory),
        incentive_location: getIncentiveLocations(spProfileDetails?.company_detail?.user?.applicable_territory, loggedUser?.role?.id, loggedUser.type),
        city: spProfileDetails?.company_detail?.office?.map((item, index) => {
            return item.city && item.city.city_name;
        }),
        office: spProfileDetails?.company_detail?.office,
        servicePartnerId: loggedUser?.service_partner_id,
        sp_pan_card_image: spProfileDetails?.company_detail?.sp_pan_card_image,
        fe_adhar_card_image: spProfileDetails?.company_detail?.fe_adhar_card_image,
        site_tools: spProfileDetails?.company_detail?.site_tools,
        under_customer: loggedUser?.sp_under_customer,
        payment_ratio: `${parseFloat(loggedUser?.payment_ratio) * 100}%`,
        incentive_type: spProfileDetails?.company_detail?.user?.incentive_type,
        incentive_limit: spProfileDetails?.company_detail?.user?.incentive_limit,
        working_hour: spProfileDetails?.company_detail?.user?.working_sch,
        date_of_joined: spProfileDetails?.company_detail?.user?.date_joined
            ? spProfileDetails?.company_detail?.user?.date_joined
            : moment(loggedUser?.created_at).format('YYYY-MM-DD'),
        possible_tech_support: spProfileDetails?.company_detail?.user?.is_possible_tech_support === 1 && 'Yes',
        services: spProfileDetails?.company_detail?.services && removeDuplicates(spProfileDetails?.company_detail?.services),
        is_pancard_linked_with_aadhaar: validateNumberValue(spProfileDetails?.company_detail?.user?.is_pancard_linked_with_aadhaar)
    };
    return bussinessData;
};

export default function DefaultBusinessDetails({ isToShowBussinessDetails }) {
    const { loggedUser } = useSelector((state) => state.auth);
    const { spProfileDetails } = useSelector((state) => state.user);
    const { feProfileDetails } = useSelector((state) => state.conversations);
    const { isSPProfileDetails, isSingleChatUserDetailsLoading } = useSelector((state) => state.loading);
    const isToShowGstLabel = getIsToShowGSTLabel(loggedUser?.role?.id, loggedUser.type);

    const dispatch = useDispatch();

    useEffect(() => {
        if (isFe(loggedUser?.role?.id, loggedUser.type)) {
            let feId = loggedUser?.engineer_id;
            dispatch(getSingleChatFEProfileDetails({ fieldEngineerId: feId }));
        } else {
            dispatch(getSPProfileDetails({ id: loggedUser?.id }));
        }
        dispatch(getAllCities());
        dispatch(getAllStates());
    }, [loggedUser?.id, loggedUser?.engineer_id, loggedUser?.role?.id, loggedUser.type, dispatch]);

    let bussinessData = getBuisnessData({ loggedUser, spProfileDetails, feProfileDetails });

    const updateBusinessDetails = () => {
        dispatch(
            openCustomModal({
                heading: `Business Details`,
                modalWidth: '40vw',
                modalHeight: 'auto',
                modalComponent: <UpdateDefaultBusinessDetailsForm />,
            })
        );
    };

    const openImageInNewWindow = (url) => {
        window.open(url, '_blank');
    };

    const openGSTModal = () => {
        dispatch(
            openCustomModal({
                heading: `GST`,
                modalWidth: '50vw',
                modalHeight: 'auto',
                modalComponent: <GstModal userId={loggedUser?.id} />,
            })
        );
    };

    let cardActions = [{ iconName: 'edit', onHovertitle: 'Edit', onClick: () => updateBusinessDetails(), isToShowAction: true }];
    if (!isToShowBussinessDetails) return <></>;
    return (
        <Card
            className='profile_business_details'
            title={'Business Details'}
            icon={'work_outline'}
            actions={cardActions}
            isLoading={isSPProfileDetails || isSingleChatUserDetailsLoading}
        >
            <div className='h-full  overflow-hidden'>
                <div className='flex w-full h-full gap-4'>
                    <div className='w-5/12 flex flex-col  pl-6 pt-4 gap-y-4'>
                        <InLineLabelValue label={'Company Name'} value={bussinessData?.company_name} />
                        {bussinessData?.address?.length > 2 && <InLineLabelValue truncate={false} label={'Address'} value={bussinessData?.address} />}
                        <RenderPanCardNumber pan_number={bussinessData?.pan_number} sp_pan_card_image={bussinessData?.sp_pan_card_image} openImageInNewWindow={openImageInNewWindow} is_pancard_linked_with_aadhaar={bussinessData?.is_pancard_linked_with_aadhaar} />
                        {bussinessData?.adhaar_number && (
                            <div className='flex items-start gap-2 '>
                                <InLineLabelValue label={'Aadhaar Number'} value={bussinessData?.adhaar_number} />
                                {bussinessData?.sp_pfe_adhar_card_imagen_card_image && (
                                    <SpanLabelButton labelClass='mt-1' onClick={() => openImageInNewWindow(bussinessData?.fe_adhar_card_image)} label={'View Image'} />
                                )}
                            </div>
                        )}
                        {isToShowGstLabel && (
                            <div className='flex items-start gap-2 '>
                                <InLineLabelValue
                                    valueClassName={'uppercase'}
                                    label={'GST Number'}
                                    value={bussinessData?.gst_number}
                                    button={<SpanLabelButton labelClass='mt-1 ml-2 mb-2' onClick={() => openGSTModal()} label={'View All'} />}
                                />
                            </div>
                        )}

                        <RectangleBadge label={'Tools'} elementsArray={bussinessData?.site_tools} keyToDisplay={'tool_name'} />
                        <RectangleBadge label={'Non Incentive Area / Service Location'} elementsArray={bussinessData?.non_incentive_location} />
                        <RectangleBadge label={'Incentive Area / Territories'} elementsArray={bussinessData?.incentive_location} chipColor='bg-scogoorange text-white' />
                        {bussinessData?.services && (
                            <RectangleBadge label={'Services'} dataToShowPerRow={3} elementsArray={bussinessData?.services} keyToDisplay={'use_case_name'} />
                        )}
                        {bussinessData?.under_customer?.length > 0 && <WorkingForCustomerChips label={'Working For'} elementsArray={bussinessData?.under_customer} />}
                    </div>
                    <div className='w-2/3 h-full'>
                        <GoogleMap boundryData={bussinessData?.mapBoundryData} markerData={bussinessData?.mapMarkerData} />
                    </div>
                </div>
            </div>
        </Card>
    );
}

const UpdateDefaultBusinessDetailsForm = () => {
    const dispatch = useDispatch();
    const { isBussinessPanVerified, cities, states, spProfileDetails, isAadharVerified, is_aadhar_is_already_verified, aadharKycResponse } = useSelector((state) => state.user);
    const { isBussinessPanVerificationLoading } = useSelector((state) => state.loading);
    const { loggedUser } = useSelector((state) => state.auth);
    const aadharOtpRef = useRef();
    const servicePartnerId = loggedUser?.service_partner_id;
    const formName = 'updateBusinessDetailsForm';

    useEffect(() => {
        return () => {
            dispatch(removeValidateAadharResponse());
        }
    }, [dispatch]);

    const initialValues = {
        sp_name: spProfileDetails?.company_detail?.sp_name ? spProfileDetails?.company_detail?.sp_name : `${loggedUser?.first_name} ${loggedUser?.last_name}`,
        sp_address_line_1: spProfileDetails?.company_detail?.sp_address_line_1,
        sp_primary_pincode: spProfileDetails?.company_detail?.sp_primary_pincode,
        sp_pan_card_number: spProfileDetails?.company_detail?.sp_pan_card_number,
        sp_adhaar_number: spProfileDetails?.company_detail?.sp_adhaar_number,
        sp_have_gst: false,
        cities,
        id_proof_type: pancard,
    };
    const [form, setForm] = useState(initialValues);
    const [showIcon, setShowIcon] = useState(false);

    const isToShowPanOrAadharInEditDetails =
        !validatePAN(spProfileDetails?.company_detail?.sp_pan_card_number) && !isValidAadhaar(spProfileDetails?.company_detail?.sp_adhaar_number);

    useEffect(() => {
        if (spProfileDetails?.company_detail?.office?.length > 0 && cities?.length > 0) {
            let location_data = spProfileDetails?.company_detail?.office;
            let pre_selected_cities = location_data?.map((location) => {
                return location.fk_city_id;
            });
            let cityObj = cities.filter(({ value }) => pre_selected_cities.includes(value));
            let detail = { cities: cityObj };
            setForm((prevState) => ({ ...prevState, ...detail }));
        }
    }, [cities, states, spProfileDetails?.company_detail?.office]);

    const submitBusinessDetails = (formValues) => {
        dispatch(updateUserProfileBussinessDetails({ formValues, servicePartnerId, formName }));
    };

    const closeModal = () => {
        dispatch(closeModalAction());
    };

    const onPanChange = (event) => {
        const { value } = event.target;
        setShowIcon(false);
        if (validatePAN(value)) {
            setShowIcon(true);
            dispatch(bussinessPancardValidation({ pan_number: value }));
        }
    };

    useEffect(() => {
        if (isAadharVerified && isToShowPanOrAadharInEditDetails) {
            aadharOtpRef?.current?.click();
            setShowIcon(true);
        }
    }, [isAadharVerified, isToShowPanOrAadharInEditDetails]);

    const onAadharChange = (event) => {
        const { value } = event.target;
        if (isValidAadhaar(value) && !isAadharVerified) {
            dispatch(adhaarValidation({ aadhaar_number: value }));
        }
    };

    useEffect(() => {
        if (aadharKycResponse && !is_aadhar_is_already_verified) {
            aadharOtpRef?.current?.click();
        }
    }, [aadharKycResponse, is_aadhar_is_already_verified])

    return (
        <div className='p-4'>
            <Form
                className='w-full'
                submit={(e) => {
                    submitBusinessDetails(e);
                }}
                buttonPostion={'justify-end'}
                formName={formName}
                initialValues={initialValues}
                form={form}
                submitButtonName={'Update'}
                setForm={setForm}
                onCancel={() => closeModal(false)}
            >
                <div className='grid grid-cols-3 rows-fr  items-end '>
                    <Input label='Company Name' name='sp_name' required type={'text'} />
                    <Input label='Address' className='col-span-2' name='sp_address_line_1' required type={'text'} />
                    {isToShowPanOrAadharInEditDetails && (
                        <>
                            <Input
                                label='Id Proof Type'
                                placeholder='Select Id Proof'
                                name='id_proof_type'
                                type={'dropdown'}
                                defaultValue={pancard}
                                required
                                options={idProofCategoryOptions}
                                className={'w-full  text-left'}
                            />
                            <div className='col-span-2'>
                                {actualValueForDropdown(form.id_proof_type) === pancard.value && (
                                    <>
                                        <Input
                                            name='sp_pan_card_number'
                                            required
                                            label='Pan Card Number'
                                            type={'text'}
                                            customMethod={onPanChange}
                                            className={'w-full  text-left'}
                                            maxLength={10}
                                            customValidation={validatePAN}
                                            errorMessage={'Invalid Pan Number'}
                                            iconLoader={isBussinessPanVerificationLoading}
                                            isEditable={!validatePAN(form?.sp_pan_card_number)}
                                            inputIcon={showIcon && getValidationIcons(isBussinessPanVerified)}
                                        />
                                    </>
                                )}
                                {actualValueForDropdown(form.id_proof_type) === aadhar.value && (
                                    <>
                                        <Input
                                            label='Aadhaar Number'
                                            customValidation={isValidAadhaar}
                                            errorMessage={'Invalid Aadhaar'}
                                            customMethod={onAadharChange}
                                            name='sp_adhaar_number'
                                            maxLength={12}
                                            isEditable={!isAadharVerified}
                                            required
                                            type={'text'}
                                            className={'w-full text-left'}
                                            inputIcon={showIcon && getValidationIcons(isAadharVerified)}
                                        />
                                    </>
                                )}
                            </div>
                        </>
                    )}

                    <Input label='Pincode' customValidation={isValidPincode} errorMessage={'Invalid Pincode'} name='sp_primary_pincode' required type={'text'} />

                    <Input
                        label='Select Cites to work with scogo'
                        placeholder='Select Cites'
                        name='cities'
                        type={'dropdown'}
                        isMulti={true}
                        options={cities && cities}
                        className={'col-span-2 max-h-[20rem] overflow-y-auto border-b-2'}
                    />
                </div>
            </Form>
            <div className='flex items-center gap-2 relative'>
                <MiniPopup
                    handleClick={() => { }}
                    popup={<VerifyAadharForm aadhar_number={form?.sp_adhaar_number} closeModalAfterSuccess={false} />}
                    transition={false}
                    className='fixed px-3 py-2 mt-2 z-10 rounded-md shadow-lg bg-white overflow-scroll left-0 top-0 border-2'
                    menuStyle={{ left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
                >
                    <div ref={aadharOtpRef} />
                </MiniPopup>
            </div>
        </div>
    );
};

const ViewAllModal = (props) => {
    const { data, icon, keyToDisplay, iconColor } = props;
    const [allData, setAllData] = useState(data);
    const boxStyle = {
        border: 0,
        p: 1,
        height: '40em',
        width: '60em',
        bgcolor: 'white',
    };

    const handleSearch = (e) => {
        let searchKey = e.target.value.trim().toLowerCase();
        if (searchKey === '') {
            setAllData(data);
            return;
        }
        const valueListWithSearchTerm = allData.filter((value) => {
            if (keyToDisplay) {
                return value?.[keyToDisplay]?.toLowerCase().includes(searchKey);
            } else {
                return value.toLowerCase().includes(searchKey);
            }
        });
        setAllData(valueListWithSearchTerm);
    };

    return (
        <>
            <Box sx={boxStyle} className='shadow-2xl overflow-auto'>
                <input onChange={handleSearch} className='w-full py-2 my-2 px-2 border-2 rounded-l text-font11' placeholder={'Search'} />
                <div className='flex flex-wrap gap-1 items-center px-3 py-3'>
                    {Array.isArray(allData) &&
                        allData.map((element) => {
                            return (
                                <p className={`bg-tagbg font-md font-bold text-scogoprimary 2xl:text-font11 text-font10 px-p-6 py-p-3 flex items-center rounded-3px mr-2 mb-1`}>
                                    {icon && <span className={`material-icons m-auto ${iconColor} text-font13 pr-2`}>{icon}</span>}
                                    {keyToDisplay ? element?.[keyToDisplay] : element}
                                </p>
                            );
                        })}
                </div>
            </Box>
        </>
    );
};


const RenderPanCardNumber = ({ pan_number, sp_pan_card_image, openImageInNewWindow, is_pancard_linked_with_aadhaar }) => {


    return <div className='flex items-start gap-2'>
        <InLineLabelValue valueClassName={'uppercase'} label={'PAN Number'} value={pan_number} />
        <PanAdharLinkeStatus is_pancard_linked_with_aadhaar={is_pancard_linked_with_aadhaar} />
        {sp_pan_card_image && (
            <SpanLabelButton labelClass='mt-1' onClick={() => openImageInNewWindow(sp_pan_card_image)} label={'View Image'} />
        )}
    </div>
}
export const PanAdharLinkeStatus = ({ is_pancard_linked_with_aadhaar }) => {


    let text = '';
    let color = ''
    if (is_pancard_linked_with_aadhaar === 0) return <></>
    else if (is_pancard_linked_with_aadhaar === 1) {
        text = 'Linked With Aadhaar';
        color = 'scogo2e';
    } else if (is_pancard_linked_with_aadhaar === -1) {
        text = 'Not Linked With Aadhaar';
        color = 'scogoclosed';
    } else if (is_pancard_linked_with_aadhaar === -2) {
        text = 'Invalid Pancard';
        color = "scogoclosed";
    }
    return (
        <span className={`text-${color} pt-1 font-medium`}>({text})</span>
    )
}