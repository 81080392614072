import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, Navigate, useParams } from 'react-router-dom';
import { GetQueryParam, isLogged } from '../utils/common';
import { useDispatch } from 'react-redux';
import { initializeFirebaseApp } from '../firebase';
import { autoLoginAction, setRedirectURL } from '../actions/auth';
import { generateRoomLink } from '../pages/videomeet/components/constants';

const ProtectedLayout = () => {
    const dispatch = useDispatch();
    const { room_id } = useParams();
    const openedConversationId = GetQueryParam('openedConversationId', String);

    const { redirectURL } = useSelector((state) => state.auth);

    const { loggedUser } = useSelector((state) => state.auth);

    useEffect(() => {
        if (isLogged() && loggedUser) {
            initializeFirebaseApp();
        }
    }, [loggedUser]);

    const onSuccess = () => {
        window.open(generateRoomLink(room_id, openedConversationId), '_self');
    }

    const onFailure = () => {
        return <Navigate to='/login' />;
    }

    if (isLogged() && loggedUser) {
        if (redirectURL) {
            window.open(redirectURL, '_self');
            return;
        }
        return <Outlet />;
    } else {
        const mob = GetQueryParam('mob', String); //for Scogo Connect Auto Login
        if (mob && room_id && !isLogged()) {
            dispatch(autoLoginAction({ mob, room_id, onSuccess, onFailure }));
        }
        else {
            dispatch(setRedirectURL({ redirectURL: window.location.href }));
            return <Navigate to='/login' />;
        }
    }
};

export default ProtectedLayout;
