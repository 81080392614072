import React from "react";
import { GetQueryParam } from "../utils/common";
import RenderAssetDetails from "./SupportTrack/RenderAssetDetails";

export default function Details() {
    const tag = GetQueryParam('tag');

    if (tag) {
        return <RenderAssetDetails tag={tag} />
    }
    return <></>;
}

