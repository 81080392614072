import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import config from '../../../config'
import { GetQueryParam, actualValueForDropdown, excelTypes } from '../../../utils/common';
import { sites as sitesTab, assets as assetsTab, spareAssets as spareAssetsTab } from '../../Inventory/InventoryController';
import { getCustomersList } from '../../../actions/customer';
import { getWareHouseDropdown } from '../../../actions/inventory';
import { useForm } from 'react-hook-form';
import { isScm } from '../../../utils/role';
import Form from '../../../common/Form';
import Input from '../../../common/Form/Input';
import Select from '../../../common/Form/Select';
import Upload from '../../../common/Form/FileUpload';

const formName = 'bulkUploadFile';
export default function UploadFile(props) {

    const warehouseIdQueryParam = GetQueryParam('selectedWarehouseId', Number);
    const dispatch = useDispatch();
    const { customerId, uploadFileAction, type, filename, endUserId, warehouseId, externalSiteId, showCustomerDropdown } = props;
    const { customers } = useSelector((state) => state.customer);
    const { loggedUser } = useSelector((state) => state.auth);
    const userRole = loggedUser.role.id;
    const userType = loggedUser.type;
    const { isCustomerListLoading, formButtonLoading } = useSelector((state) => state.loading);

    const isToShowCustomerDropdown = isScm(userRole, userType) && showCustomerDropdown;

    const { isWarehouseOwnerLoading } = useSelector((state) => state.loading);
    const { warehousesDropDown } = useSelector((state) => state.inventory);

    const isValidWarehouseId = !isNaN(warehouseId) && warehouseId > 0;
    const isSpareAssetType = type === 'spareAssets';

    let defaultValues = {
        file: '',
        customerId: customerId ? customerId : '',
        warehouseId: warehouseIdQueryParam ? warehouseIdQueryParam : ''
    }

    const closeModal = () => { dispatch(closeModalAction()) }

    const getFunctionParameter = ({ type, form, customer_id, file }) => {
        switch (type) {
            case sitesTab.id:
                return { data: form, customerId: customer_id, formName };
            case assetsTab.id:
                return { customerId: customer_id, data: { file }, formName };
            case spareAssetsTab.id:
                return { data: form, customerId, count: true, formName, warehouseId };
            default:
                return { file, formName };
        }
    };

    const onSubmit = (data) => {
        let customer_id = customerId;
        if (isToShowCustomerDropdown) customer_id = actualValueForDropdown(data.customerId);
        let warehouse_id = warehouseId;
        if (!isValidWarehouseId && isSpareAssetType) warehouse_id = actualValueForDropdown(data.warehouseId);
        if (data.bulkFile.length > 0) {
            const f = new FormData();
            const file = data.bulkFile[0];
            f.set('file', file);
            f.set('fk_customer_id', customer_id);
            if (!['', undefined, null].includes(endUserId)) {
                f.set('end_user_id', endUserId);
            }
            f.set('warehouse_id', !['', undefined, null].includes(warehouse_id) ? warehouse_id : '');
            f.set('external_site_id', !['', undefined, null].includes(externalSiteId) ? externalSiteId : '');
            dispatch(uploadFileAction(getFunctionParameter({ type, form: f, customer_id, file })));
        }
    };

    useEffect(() => {
        if (isToShowCustomerDropdown) dispatch(getCustomersList());
        if (!isValidWarehouseId && isSpareAssetType) dispatch(getWareHouseDropdown({ customerId }));
    }, [dispatch, isToShowCustomerDropdown, isValidWarehouseId, isSpareAssetType, customerId]);

    // TODO: Use css grid and eliminate the duplicate code that is the file upload Input

    const methods = useForm({ defaultValues, mode: 'all' });

    return (
        <Form
            methods={methods}
            onSubmit={onSubmit}
            formName={formName}
            submitButtonText={'Upload'}
            onCancel={() => dispatch(closeModalAction())}
            showSubmit
            submitContainerClassName='flex gap-2 justify-end py-4 px-4'
            isLoading={formButtonLoading[formName]}
        >
            <div className='px-3 flex justify-between'>
                {isToShowCustomerDropdown && (
                    <Select
                        label='Customer'
                        required
                        name='customerId'
                        className={'w-6/12'}
                        options={customers}
                        isLoading={isCustomerListLoading}
                        isClearableOnSuccess={true}
                    />
                )}
                {!isValidWarehouseId && isSpareAssetType ? (
                    <Select
                        label='Warehouse'
                        required
                        name='warehouseId'
                        className={'w-6/12'}
                        options={warehousesDropDown}
                        isLoading={isWarehouseOwnerLoading}
                    />
                ) : (
                    <UploadInput />
                )}
            </div>
            <p className='ml-6 mt-2 mb-2 text-scogoorange font-normal text-font13 cursor-pointer'>
                <a href={`${config.api.staticAssetsUrl}/others/${filename}.csv?time=${Date.now()}`} download>
                    {' '}
                    Download Sample file
                </a>
            </p>
            {!isValidWarehouseId && isSpareAssetType ? <div className='px-3'> <UploadInput /></div> : <></>}
        </Form>
    );
}

const UploadInput = () => {
    return (
        <Upload
            name='bulkFile'
            accept='.csv, text/csv, application/vnd.ms-excel'
            label='Select CSV file'
            required
            className='w-full px-10'
            validateFileType={(file) => {
                return excelTypes.includes(file.type) || `${file.name} is not a csv`;
            }}
        />
    );
};
