import React, { useCallback, useEffect } from 'react';
import { textCss } from '../../../../common/Css';
import Checkbox from '../../../../common/Form/Checkbox';
import Select from '../../../../common/Form/Select';
import TextArea from '../../../../common/Form/TextArea';
import IconToolTip from '../../../../common/IconToolTip';
import { SkeltonCardTwo } from '../../../../common/SkeltonCardTwo';
import { actualValueForDropdown, validateStringValue } from '../../../../utils/common';
import ButtonScogoPrimary from '../../../../common/Buttons/ButtonScogoPrimary';
import { changeTicketWarehouse } from '../../../../actions/tickets';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import Form from '../../../../common/Form';
import { useSelector } from 'react-redux';

const formName = 'changeWarehouse';

export const CurrentWarehouseDetail = ({ isLoading, warehousesDropDown, warehouseDropdownLoading, viewTicket, setIsWarehouseDetailsChanged, setShowRmaError }) => {
    const currentWarehouseId = viewTicket?.warehouse;
    const currentWarehouseDetail = Array.isArray(warehousesDropDown) && currentWarehouseId && warehousesDropDown.find((warehouse) => warehouse.value === currentWarehouseId);
    const dispatch = useDispatch();
    const { formButtonLoading } = useSelector((state) => state.loading);
    const currentRepairCenter = viewTicket?.repair_center;

    const defaultValues = {
        changed_warehouse_id: '',
        warehouse_change_remark: '',
    };


    const callChangeWarehouse = useCallback(
        ({ warehouse_change_remark, changed_warehouse_id, removeSp }) => {
            const data = { warehouse_change_remark, warehouse: actualValueForDropdown(changed_warehouse_id) };
            if (removeSp) data.removeSp = removeSp;
            dispatch(changeTicketWarehouse({ formName, ticketId: viewTicket?.id, data, refreshViewTicket: true }));
        },
        [dispatch, viewTicket?.id]
    );

    const onSubmit = (formValues) => {
        const { changed_warehouse_id, warehouse_change_remark, removeSp } = formValues;
        if (actualValueForDropdown(changed_warehouse_id) !== currentWarehouseId || warehouse_change_remark || removeSp) {
            callChangeWarehouse({ removeSp, changed_warehouse_id, warehouse_change_remark });
        }
    }

    const methods = useForm({ defaultValues, mode: 'all' });
    const { setValue, watch } = methods;
    const changed_warehouse_id = watch('changed_warehouse_id');
    const isWarehouseChanged = actualValueForDropdown(changed_warehouse_id) !== currentWarehouseId;

    useEffect(() => {
        setShowRmaError(changed_warehouse_id ? false : true);
    }, [setShowRmaError, changed_warehouse_id])

    useEffect(() => {
        setIsWarehouseDetailsChanged(isWarehouseChanged);
    }, [isWarehouseChanged, setIsWarehouseDetailsChanged]);

    useEffect(() => {
        if (currentRepairCenter) {
            let findWarehouseInDropdown = warehousesDropDown?.find((warehouse) => warehouse.value === currentWarehouseId);
            if (findWarehouseInDropdown) setValue('changed_warehouse_id', findWarehouseInDropdown);
        }
    }, [currentRepairCenter, currentWarehouseId, setValue, warehousesDropDown]);

    const getDetails = () => {
        if (isLoading) {
            return (
                <div className='h-full'>
                    <SkeltonCardTwo arr={[1, 2]} height={12} className={''} padding={1} />
                </div>
            );
        }

        return (
            <Form
                defaultValues={defaultValues}
                onSubmit={onSubmit}
                onCancel={() => { }}
                methods={methods}
                isLoading={formButtonLoading[formName]}
            >
                <div className='flex'>
                    <div className='w-9/12 flex justify-between items-end'>
                        <Select className='w-full' label='Warehouse' name='changed_warehouse_id' options={warehousesDropDown} isLoading={warehouseDropdownLoading} />
                        <TextArea className='w-full' label='Remark' name='warehouse_change_remark' />
                    </div>
                    <div className='w-3/12 flex items-center'>
                        <div>
                            <p className={textCss?.detailsTitle}>Address</p>
                            <IconToolTip title={`Title : ${validateStringValue(currentWarehouseDetail?.warehouse?.address)}`}>
                                <p className={`${textCss?.detailsValue} line-clamp-3 px-2 break-all`}>{`${validateStringValue(currentWarehouseDetail?.warehouse?.address)}`}</p>
                            </IconToolTip>
                        </div>
                    </div>
                </div>
                <label className='flex items-center justify-between'>
                    <span className='pt-2'>
                        <Checkbox id={'removeSp'} label='Do you want to remove current service partner/field engineer of this RMA request' name='removeSp' type={'checkbox'} />
                    </span>
                    {isWarehouseChanged && <ButtonScogoPrimary loading={formButtonLoading[formName]} textOrComponent={'Save'} type='submit' />}
                </label>
            </Form>
        );
    };
    return (
        <div className='bg-white relative border-gray-200 shadow-lg w-full py-2 rounded-lg px-4'>
            <div className='ribbon w-3/4'>
                <span
                    className='ribbon3 bg-scogobgsky ribbonblue'
                    style={{
                        left: '-19px',
                    }}
                >
                    Current Warehouse Details
                </span>
            </div>
            <hr />
            <div className=''>{getDetails()}</div>
        </div>
    );
};
