import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getCustomerProfileDetails } from '../../actions/customer';
import { closeModalAction, openTextModalAction } from '../../actions/modal';
import { deletePartner } from '../../actions/partners';
import { getTicketStatsByCustomerId } from '../../actions/tickets';
import Card from '../../common/Card';
import InLineLabelValue from '../../common/InLineLabelValue';
import LabelValueWithIcon from '../../common/LabelValueWithIcon';
import LabelValueWithRadialProgressBar from '../../common/LabelValueWithRadialProgressBar';
import { SkeltonCardTwo } from '../../common/SkeltonCardTwo';
import { isUserChangesAllowed } from '../../utils/access';

const CustomerDetailsSkeletonLoader = () => {
    return (
        <div className='flex gap-4 h-full w-full' style={{ height: 'inherit' }}>
            <div className='w-1/3'>
                <SkeltonCardTwo className={'p-5'} arr={[1]} height={150} padding={2} />
            </div>
            <div className='w-2/3'>
                <SkeltonCardTwo className={'px-5'} arr={[1, 2, 3, 4, 5]} height={30} padding={2} />
            </div>
        </div>
    );
};

const WalletDetailsSkeletonLoader = () => {
    return (
        <>
            <div className='flex gap-4 h-1/2 w-full'>
                <div className='w-1/2'>
                    <SkeltonCardTwo className={'p-5'} arr={[1]} height={55} />
                </div>
                <div className='w-1/2'>
                    <SkeltonCardTwo className={'p-5'} arr={[1]} height={55} />
                </div>
            </div>
            <div className='flex gap-4 h-1/2 w-full'>
                <div className='w-1/2'>
                    <SkeltonCardTwo className={'p-5'} arr={[1]} height={55} />
                </div>
            </div>
        </>
    );
};

export const payment_terms_options = [
    { key: 1, value: 1, label: '0 Day' },
    { key: 2, value: 2, label: '15 Days' },
    { key: 3, value: 3, label: '30 Days' },
    { key: 4, value: 4, label: '60 Days' },
    { key: 5, value: 5, label: '45 Days' },
];

const CustomerInfo = ({ customerId }) => {
    const { transformedCustomerDetails } = useSelector((state) => state.customer);
    const { customerDetailsByIdLoading, isTicketStatsLoading } = useSelector((state) => state.loading);
    const { ticketStats } = useSelector((state) => state.tickets);
    const { loggedUser } = useSelector((state) => state.auth);
    let frontend_controller = loggedUser.frontend_controller;
    const dispatch = useDispatch();

    useEffect(() => {
        if (customerId) {
            dispatch(getCustomerProfileDetails(customerId));
            dispatch(getTicketStatsByCustomerId(customerId));
        }
    }, [customerId, dispatch]);

    const deleteCustomer = () => {
        dispatch(
            openTextModalAction({
                heading: 'Delete',
                text: 'Are you sure you want to continue?',
                closeOnBackdropClick: false,
                formName: 'deleteCustomer',
                buttons: [
                    {
                        showLoading: true,
                        textOrComponent: 'Yes',
                        type: 'red',
                        onClick: () => {
                            dispatch(
                                deletePartner({
                                    userId: transformedCustomerDetails?.userId,
                                    customerId,
                                    formName: 'deleteCustomer',
                                    userName: transformedCustomerDetails?.customer_name,
                                })
                            );
                        },
                    },
                    {
                        textOrComponent: 'No',
                        type: 'blue',
                        buttonClass: 'hover:bg-scogobgsky',
                        onClick: () => {
                            dispatch(closeModalAction());
                        },
                    },
                ],
            })
        );
    };

    let cardActions = [{ iconName: 'delete', onHovertitle: 'Delete', onClick: () => deleteCustomer(), isToShowAction: isUserChangesAllowed(frontend_controller) }];

    return (
        <div className=' h-full'>
            <div className='h-3/6'>
                <Card actions={cardActions} title='Customer Details' icon={'person'} isLoading={customerDetailsByIdLoading} loaderComponent={<CustomerDetailsSkeletonLoader />}>
                    <div className='grid grid-cols-4  gap-x-3 h-full auto-rows-fr px-10 py-4'>
                        <div className='row-span-4 flex items-center justify-center'>
                            <img
                                src={
                                    transformedCustomerDetails?.company_logo ? transformedCustomerDetails?.company_logo : process.env.PUBLIC_URL + '/img/default_customer_logo.png'
                                }
                                alt=''
                                className='w-96'
                                style={{ maxHeight: '15rem' }}
                            />
                        </div>
                        <InLineLabelValue label={'Name'} value={transformedCustomerDetails?.customer_name} />
                        <InLineLabelValue label={'Email'} value={transformedCustomerDetails?.customer_email} />
                        <InLineLabelValue label={'Mobile No'} value={transformedCustomerDetails?.customer_mobile} />
                        <InLineLabelValue label={'Company Name'} value={transformedCustomerDetails?.company_name} />
                        <InLineLabelValue label={'PAN Number'} value={transformedCustomerDetails?.pan_number} />
                        {transformedCustomerDetails?.gst_number && <InLineLabelValue label={'GST Number'} value={transformedCustomerDetails?.gst_number} />}
                        <InLineLabelValue label={'GST State'} value={transformedCustomerDetails?.gst_state} />
                        <InLineLabelValue label={'Payment Terms'} value={transformedCustomerDetails?.payment_terms} />
                        <InLineLabelValue label={'Domain'} value={transformedCustomerDetails?.domain} />
                        <InLineLabelValue parentClass={'col-span-3'} label={'Address'} value={transformedCustomerDetails?.address} />
                    </div>
                </Card>
            </div>
            <div className='h-full w-full flex gap-4'>
                <div className='h-3/6 w-1/2 pt-6'>
                    <Card title='Wallet Details' icon={'wallet'} isLoading={customerDetailsByIdLoading} loaderComponent={<WalletDetailsSkeletonLoader />}>
                        <div className='grid grid-cols-2 items-center justify-items-center h-full '>
                            <LabelValueWithIcon value={transformedCustomerDetails?.wallet_amount} label={'Current Balance'} icon={'currency_rupee'} />
                            <LabelValueWithIcon value={transformedCustomerDetails?.locked_amount} label={'Reserved Amount'} icon={'lock'} />
                            <LabelValueWithIcon value={transformedCustomerDetails?.amount_used} label={'Amount Paid'} icon={'wallet'} />
                        </div>
                    </Card>
                </div>
                <div className='h-3/6 w-1/2 pt-6'>
                    <Card title='Ticket Statistics' icon={'timeline'} isLoading={isTicketStatsLoading}>
                        <div className='grid grid-cols-2 items-center justify-items-stretch gap-x-12 h-full px-14'>
                            <LabelValueWithRadialProgressBar label='Open Tickets' total={ticketStats?.totalTicket} value={ticketStats?.openTicket} color={'#F48A21'} />
                            <LabelValueWithRadialProgressBar label='Closed Tickets' total={ticketStats?.totalTicket} value={ticketStats?.closedTicket} color={'#12355F'} />
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default CustomerInfo;
