import React, { memo } from 'react';
import memoize from 'memoize-one';

import Loader from './Loader';
import NoResultFound from './NoResultFound';
import Paginate from './Paginate';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List, areEqual } from 'react-window';

const createItemData = memoize(({ rowsData, checkRowShouldHighlight, gridTemplateColumns, schema, columnsGap, RowHoc }) => ({
    rowsData,
    checkRowShouldHighlight,
    gridTemplateColumns,
    schema,
    columnsGap,
    RowHoc,
}));

export default function VirtualizedTable(props) {
    const {
        isLoading,
        tablePagination,
        rowData: rowsData,
        rowHeight,
        schema,
        tableError,
        setRowsPerPage = () => {},
        rowsPerPage,
        checkRowShouldHighlight = () => {},
        page,
        columnsGap = 'gap-x-2',
        RowHoc,
    } = props;

    const gridTemplateColumns = schema.table
        .filter((column) => !column.head.hide)
        .map((column) => {
            return (column.head.width ? column.head.width : 1) + 'fr';
        })
        .join(' ');

    return (
        <>
            <div className={`shadow-lg tableContainer rounded-lg ${tablePagination ? 'paginatedTable' : 'nonPaginatedTable'}`}>
                <table className='block rounded-lg h-full' style={{ height: 'inherit' }}>
                    <TableHead schema={schema} gridTemplateColumns={gridTemplateColumns} columnsGap={columnsGap} />
                    <tbody className={`virTableBody block text-font11 bg-white text-scogo15 font-medium`}>
                        {isLoading && (
                            <div className='border border-white'>
                                <Loader color='#F48A21' size='65' speed='1' thickness='3' margin='150px' />
                            </div>
                        )}
                        {!isLoading && rowsData?.length === 0 && <NoResultFound message={tableError} />}
                        {!isLoading && rowsData?.length > 0 && (
                            <AutoSizer>
                                {({ height, width }) => (
                                    <List
                                        className='List'
                                        height={height}
                                        itemCount={rowsData.length}
                                        itemSize={rowHeight}
                                        width={width}
                                        itemData={createItemData({ rowsData, checkRowShouldHighlight, gridTemplateColumns, schema, columnsGap, RowHoc })}
                                    >
                                        {Row}
                                    </List>
                                )}
                            </AutoSizer>
                        )}
                    </tbody>
                </table>
            </div>
            {tablePagination && (
                <Paginate
                    count={props?.count}
                    onPageChange={props.tablePaginationHandler}
                    entriesPerPage={rowsPerPage ? rowsPerPage : 0}
                    setRowsPerPage={setRowsPerPage}
                    page={page}
                />
            )}
        </>
    );
}

const TableRow = ({ rowIsHighlighted, gridTemplateColumns, style, columnsGap, RowHoc, children, rowValue }) => {
    let row = (
        <tr
            className={`pl-2 border-b border-scogoddd py-2 ${
                rowIsHighlighted ? 'bg-scogoddd' : 'hover:bg-scogof5'
            }  text-font11 text-black font-normal md:mr-0 mr-2 items-center grid ${columnsGap}`}
            style={{ ...style, gridTemplateColumns }}
        >
            {children}
        </tr>
    );

    if (typeof RowHoc === 'function') {
        return <RowHoc rowValue={rowValue}>{row}</RowHoc>;
    }
    return row;
};

const Row = memo(({ data, index: rowIndex, style }) => {
    const { rowsData, checkRowShouldHighlight, gridTemplateColumns, schema, columnsGap, RowHoc } = data;
    const rowValue = rowsData[rowIndex];
    const rowIsHighlighted = checkRowShouldHighlight(rowValue);

    return (
        <TableRow rowIsHighlighted={rowIsHighlighted} gridTemplateColumns={gridTemplateColumns} style={style} columnsGap={columnsGap} RowHoc={RowHoc} rowValue={rowValue}>
            {schema.table?.map(({ head, body }) => {
                const columnRowCss = head.columnRowCss ? head.columnRowCss : 'truncate';
                return (
                    <td className={`${columnRowCss} w-full ${head.hide ? 'hidden' : ''}`} key={head.headName}>
                        {body.render(rowValue, rowIndex)}
                    </td>
                );
            })}
        </TableRow>
    );
}, areEqual);

const TableHead = (props) => {
    const { schema, gridTemplateColumns, columnsGap } = props;

    return (
        <thead className={`border-b-2 block w-full py-1 `}>
            <tr className={`grid ${columnsGap}`} style={{ gridTemplateColumns }}>
                {schema.table.map(({ head }) => {
                    return (
                        <th key={head.headName} className={`truncate text-left font-medium text-scogogray ${head.hide ? 'hidden' : ''} pl-2`}>
                            {head.render(head.headName)}
                        </th>
                    );
                })}
            </tr>
        </thead>
    );
};
