import { takeEvery, put, fork } from 'redux-saga/effects';

import { tickets } from '../../types';

import coreTicketSaga from './core';
import ticketExecutionSaga from './execution';


export function* removeFromTicketsStore(action) {
    const { type } = action.payload
    yield put({ type: tickets[type], payload: undefined })
}

export default function* ticketsSaga() {
    yield takeEvery(tickets.REMOVE_FROM_TICKETS_STORE_REQUEST, removeFromTicketsStore)

    yield fork(coreTicketSaga);
    yield fork(ticketExecutionSaga);
}