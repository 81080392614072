import RadialProgressBar from './RadialProgressBar';

export default function LabelValueWithRadialProgressBar({ total, value, color = '#F48A21', label = 'Label' }) {
    const percentage = Math.round((value / total) * 100);
    return (
        <div className='flex items-center gap-4 '>
            <RadialProgressBar progress={percentage} size={70} strokeWidth={8} color={color} />
            <div>
                <p className={`text-light  inline text-gray-600 text-font14  whitespace-nowrap`}>{label}</p>
                <p style={{ color: color }} className={`text-light w-auto   text-font24 font-semibold`}>
                    {value}
                    <span className='text-gray-600'> / {total}</span>
                </p>
            </div>
        </div>
    );
}
