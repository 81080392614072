import React, { useEffect, useState, useCallback, useImperativeHandle, forwardRef, useMemo } from 'react';
import Checkbox from '../../../common/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { getAllSites, getSitesFilterList, clearInventoryTableDataOnUnmount, setInventoryFilterAndPaginationData, getSitesFilteredData } from '../../../actions/inventory';
import { getCustomerIdOfLoginUser } from '../../../utils/utils';
import DropdownMenu from '../../../common/MenuDropdown';
import { GetInventoryActions } from '../InventoryController';
import IconToolTip from '../../../common/IconToolTip';
import OverflowTip from '../../../common/OverflowTip';
import { copyToClipboard, clearFilterModuleData, setClearFilterClickAction } from '../../../actions/utils';
import { validateDateValue, validateResponseDate } from '../../../utils/common';
import { openChatInNewTab } from '../../../actions/conversations';
import APIFilterPopup from '../../../common/APIFilters/FilterPopup';
import { clickableTextClass } from '../../../common/Css';
import { validateFilterNumber, readFilterQueryParams, transformQueryFilters } from '../../../utils/filter';
import { useQuery } from '../../../utils/common';
import { DateComponent } from '../../../common/TableReusableColumns';
import VirtualizedTable from '../../../common/VirtualizedTable';
import moment from 'moment';
import { GetAssetsList } from '../spares/WarehouseDetails';
import Menus from '../../../common/Menus';

const filterSchema = {
    statesFilter: {
        filterName: 'states',
        title: 'State',
        typeOfFilter: 'checkbox',
        apiValueKey: 'state_id', // key to address apiValue from filter list API response
        displayValueKey: 'state_name', // key to address displayValue from filter list API response
        submitKey: 'state_id', // key to address submit API payload key
        queryParamKey: 'state_id',
        parse: ({ state_id }) => {
            let values = validateFilterNumber(state_id);
            if (values) return { state_id: values };
            return {};
        },
    },
    citiesFilter: {
        filterName: 'cities',
        title: 'City',
        typeOfFilter: 'checkbox',
        apiValueKey: 'city_id',
        displayValueKey: 'city_name',
        submitKey: 'city_id',
        queryParamKey: 'city_id',
        parse: ({ city_id }) => {
            let values = validateFilterNumber(city_id);
            if (values) return { city_id: values };
            return {};
        },
    },
    customersFilter: {
        filterName: 'customers',
        title: 'Customer',
        typeOfFilter: 'checkbox',
        apiValueKey: 'customer_id',
        displayValueKey: 'customer_company_name',
        submitKey: 'customerId',
        queryParamKey: 'customerId',
        parse: ({ customerId }) => {
            let values = validateFilterNumber(customerId);
            if (values) return { customerId: values };
            return {};
        },
    },
    endusersFilter: {
        filterName: 'endusers',
        title: 'End User',
        typeOfFilter: 'checkbox',
        apiValueKey: 'id',
        displayValueKey: 'end_user_name',
        submitKey: 'customer_end_user',
        queryParamKey: 'customer_end_user',
        parse: ({ customer_end_user }) => {
            let values = validateFilterNumber(customer_end_user);
            if (values) return { customer_end_user: values };
            return {};
        },
    },
    assetTypeFilter: {
        filterName: 'assets',
        title: 'Asset Type',
        typeOfFilter: 'checkbox',
        apiValueKey: 'id',
        displayValueKey: 'title',
        submitKey: 'asset_type_id',
        queryParamKey: 'asset_type_id',
        parse: ({ asset_type_id }) => {
            let values = validateFilterNumber(asset_type_id);
            if (values) return { asset_type_id: values };
            return {};
        },
    },
    slaFilter: {
        filterName: 'slaList',
        title: 'SLA',
        typeOfFilter: 'checkbox',
        apiValueKey: (site) => {
            return { resolve: site.resolve, resolve_unit: site.resolve_unit };
        },
        displayValueKey: 'title',
        queryParamKey: {
            keyList: ['resolve', 'resolve_unit'],
            getQueryObject: (sites) => {
                let selectedSla = sites.filter((site) => site.isSelected);
                let resolve = selectedSla.map((site) => site.apiValue.resolve).map((sla) => Number(sla));
                let resolve_unit = selectedSla.map((site) => site.apiValue.resolve_unit);
                if (sites.length !== resolve.length) return { resolve, resolve_unit };
                return {};
            },
        },
        submitKey: ({ resolve, resolve_unit }) => {
            let res = resolve && resolve.split(',').map((num) => Number(num));
            let res_unit = resolve_unit && resolve_unit.split(',');
            return { sla: { resolve: res, resolve_unit: res_unit } };
        },
        isCurrentFilterSelected: (curretFilter, { resolve, resolve_unit }) => {
            let res = resolve && resolve.split(',').map((num) => Number(num));
            let res_unit = resolve_unit && resolve_unit.split(',');
            let isSelected = false;
            if (res && res_unit) {
                res.forEach((sla, index) => {
                    if (sla === curretFilter.resolve && res_unit[index] === curretFilter.resolve_unit)
                        isSelected = sla === curretFilter.resolve && res_unit[index] === curretFilter.resolve_unit;
                });
            } else isSelected = true;
            return isSelected;
        },
    },
};

const SiteDetails = forwardRef(
    (
        {
            setCustomerIdOfSelectedSite,
            setSelectedSiteExternalSiteId,
            inventoryRowsPerPage,
            inventoryPage,
            setInventoryRowsAndPage,
            closeInventorySideDrawer,
            goToAssetTab,
            tab,
            setClearFilterButton,
            limitQueryParam,
            pageQueryParam,
            searchQueryParam,
            queryParams,
            updateQueryFilters,
            siteIdQueryParam,
            defaultRowsPerPage,
            defaultPage,
        },
        ref
    ) => {
        const dispatch = useDispatch();
        const { loggedUser } = useSelector((state) => state.auth);
        const { sites, sitesCount, sitesFiltersData, showInventorySideDrawer } = useSelector((state) => state.inventory);

        const { sitesLoading, sitesForFilterLoading, searchLoading } = useSelector((state) => state.loading);
        const customerId = getCustomerIdOfLoginUser(loggedUser);
        const [filterQueryObject, setFilterObject] = useState({});
        const [filtersActiveStatus, setFiltersActiveStatus] = useState({});
        const query = useQuery();
        const toShowCustomer = GetInventoryActions('isToShowCustomerData');

        const [checkedSitesTracker, setCheckedSitesTracker] = useState(() => new Set());
        const [filterList, setFilterList] = useState({});

        const getSitesData = useCallback(
            ({ isFilterApplied, searchTerm, limit, page, payload }) => {
                if (!limit) limit = defaultRowsPerPage;
                if (!page) page = defaultPage;
                if (isFilterApplied || searchTerm?.length > 0) {
                    dispatch(getSitesFilteredData({ customerId: customerId, count: true, limit, page, payload, query: searchTerm, callPoList: true }));
                } else {
                    dispatch(getAllSites({ limit, page, count: true, customerId, callPoList: true }));
                }
            },
            [customerId, defaultPage, defaultRowsPerPage, dispatch]
        );

        useEffect(() => {
            let { queryObject, filterStatus } = readFilterQueryParams(filterSchema, query);
            setFilterObject(queryObject);
            setFiltersActiveStatus(filterStatus);
            let isToShowFilterButton = false;
            Object.values(filterStatus).forEach((value) => (value ? (isToShowFilterButton = value) : false));
            setClearFilterButton(isToShowFilterButton);
            let filterPayload = transformQueryFilters(filterSchema, queryObject);
            getSitesData({
                searchTerm: searchQueryParam,
                limit: limitQueryParam,
                page: pageQueryParam,
                payload: filterPayload,
                isFilterApplied: isToShowFilterButton,
            });
            dispatch(
                setInventoryFilterAndPaginationData({
                    payload: filterPayload,
                    isFilterActive: isToShowFilterButton,
                    limit: limitQueryParam,
                    page: pageQueryParam,
                    query: searchQueryParam,
                    tab,
                })
            );
        }, [queryParams, searchQueryParam, limitQueryParam, pageQueryParam, getSitesData, setClearFilterButton, query, dispatch, tab, siteIdQueryParam]);

        useImperativeHandle(ref, () => ({
            getSelectedData: () => {
                let selectedData = sitesData.filter((value, index) => checkedSitesTracker.has(index));
                return selectedData;
            },
        }));

        const filterComponent = ({ headName, keysToFilter, typeOfFilter, placement }) => (
            <div className='flex'>
                {headName}
                <APIFilterPopup
                    filterList={filterList}
                    setFilterList={setFilterList}
                    height={15}
                    keysToFilter={keysToFilter}
                    submit={({ payload, page, filterList }) => updateQueryFilters({ filterSchema, payload, page, filterList })}
                    typeOfFilter={typeOfFilter}
                    placement={placement}
                    isLoading={sitesForFilterLoading}
                    filtersData={sitesFiltersData}
                    filterSchema={filterSchema}
                    filterQueryObject={filterQueryObject}
                    filtersActiveStatus={filtersActiveStatus}
                />
            </div>
        );

        useEffect(() => {
            let filterPayload = { customerId };
            if (toShowCustomer) filterPayload = Object.assign(filterPayload, { toFilter: 'customers' });
            dispatch(getSitesFilterList(filterPayload));
            closeInventorySideDrawer();
            return () => {
                dispatch(clearFilterModuleData());
                dispatch(setClearFilterClickAction(() => { }));
                dispatch(clearInventoryTableDataOnUnmount());
            };
        }, []);

        const siteOnClickHandler = (siteID, external_site_id, fk_customer_id) => {
            goToAssetTab(siteID);
            setCustomerIdOfSelectedSite(fk_customer_id);
            setSelectedSiteExternalSiteId(external_site_id);
        };

        const handleOpenLocation = (data) => {
            return window.open(`https://maps.google.com?q=${`${data?.address}, ${data?.fk_pincode_id}, ${data?.city}`}`, '_blank');
        };

        const handleCopyAddress = (data) => {
            let addressToCopy = `${data?.address}, ${data?.fk_pincode_id}, ${data?.city}`;
            dispatch(copyToClipboard({ data: addressToCopy }));
        };

        const selectALLHandler = (checked) => {
            if (checked) {
                const allIndices = sitesData.map((_, index) => index);
                setCheckedSitesTracker(new Set(allIndices));
            } else {
                setCheckedSitesTracker(new Set());
            }
        };

        let sitesData = useMemo(() => {
            let list = [];
            if (Array.isArray(sites)) {
                list = sites?.map((site) => {
                    return {
                        id: site?.id,
                        siteId: site?.external_site_id,
                        siteContactName: site?.site_contact_person_name,
                        siteContactPhone: site?.site_contact_person_mobile,
                        siteContactEmail: site?.site_contact_person_email,
                        customerName: site?.customer?.customer_company_name,
                        customerEnduser: site?.enduser?.end_user_name,
                        address: site?.complete_site_address,
                        city: site?.city?.city_name,
                        state: site?.state?.state_name,
                        sla: site?.sla,
                        fk_pincode_id: site?.fk_pincode_id,
                        site_branch_name: site?.site_branch_name,
                        customer_end_user: site?.customer_end_user,
                        fk_city_id: site?.fk_city_id,
                        fk_state_id: site?.fk_state_id,
                        region: site?.region,
                        zone: site?.zone,
                        slaDetails: site?.sla_details?.resolve && site?.sla_details?.resolve_unit ? `${site?.sla_details?.resolve} ${site?.sla_details?.resolve_unit}` : '',
                        requester_name: `${loggedUser?.first_name} ${loggedUser?.last_name}`,
                        requester_mobile: loggedUser?.mobile,
                        requester_email: loggedUser?.email,
                        site_vertical: site?.site_vertical,
                        customer_id: site?.customer?.customer_id,
                        customer: site?.customer,
                        enduser: site?.enduser,
                        last_visit_date: site?.last_visit_date,
                        sp: site?.sp,
                        fe: site?.fe,
                        stateObj: site.state,
                        cityObj: site.city,
                        lastTicket: site?.lastTicket,
                        assetTypes: site?.assetTypes,
                        poList: site?.poList,
                    }
                })
            }
            return list;
        }, [sites, loggedUser?.email, loggedUser?.mobile, loggedUser?.first_name, loggedUser?.last_name]);

        const handleOnChangeCheckbox = (position) => {
            if (checkedSitesTracker.has(position)) {
                setCheckedSitesTracker((prev) => {
                    const next = new Set(prev);
                    next.delete(position);
                    return next;
                });
            } else {
                setCheckedSitesTracker((prev) => new Set(prev).add(position));
            }
        };

        useEffect(() => {
            setCheckedSitesTracker(new Set());
        }, [searchLoading, sitesLoading]);

        const siteTableSchema = {
            table: [
                {
                    head: {
                        render: (headName) => {
                            return (
                                <div className='flex items-center'>
                                    <Checkbox
                                        checkColor='text-scogoorange mr-2'
                                        checked={sitesData?.length > 0 && checkedSitesTracker?.size && sitesData?.length === checkedSitesTracker?.size}
                                        onChange={(event) => {
                                            selectALLHandler(event);
                                        }}
                                        dynamicSize={'1.2rem'}
                                    />
                                    {headName}
                                </div>
                            );
                        },
                        headName: 'Site ID',
                    },
                    body: {
                        render: (site, rowIndex) => {

                            return (
                                <div className='flex items-start w-full '>
                                    <Checkbox
                                        checkColor='text-scogoorange mr-2 mt-1'
                                        checked={checkedSitesTracker.has(rowIndex)}
                                        onChange={(event) => {
                                            handleOnChangeCheckbox(rowIndex);
                                        }}
                                        dynamicSize={'1.2rem'}
                                    />
                                    <div
                                        className={` cursor-pointer text-scogobgsky font-bold truncate `}

                                    >
                                        {site?.siteId ? <div className='flex'><p className='truncate max-w-max  hover:text-scogoorange '>
                                            <IconToolTip placement={'bottom'} title={`Site ID : ${site?.siteId}`}>
                                                <span className='font-bold truncate block'
                                                    onClick={() => {
                                                        siteOnClickHandler(site?.id, site.siteId, site?.customer_id);
                                                    }}
                                                >{site?.siteId}</span>
                                            </IconToolTip>
                                        </p>
                                            <IconToolTip placement={'bottom'} title={`Copy Site ID`}>
                                                <span
                                                    className='cursor-pointer material-icons text-scogobgsky text-lg pl-1'
                                                    onClick={() => dispatch(copyToClipboard({ data: site?.siteId }))}
                                                >
                                                    {' '}
                                                    content_copy{' '}
                                                </span>
                                            </IconToolTip>
                                        </div>
                                            : <></>}

                                        {site?.site_branch_name && site?.site_branch_name !== site?.siteId && (
                                            <div className='flex'><p className={'truncate font-bold max-w-max hover:text-scogoorange '} onClick={() => {
                                                siteOnClickHandler(site?.id, site.siteId, site?.customer_id);
                                            }}>
                                                <IconToolTip placement={'bottom'} title={`Branch Name : ${site?.site_branch_name}`}>
                                                    <span className='font-bold truncate'>{site?.site_branch_name}</span>
                                                </IconToolTip>
                                            </p>
                                                <IconToolTip placement={'bottom'} title={`Copy Branch Name`}>
                                                    <span
                                                        className='cursor-pointer material-icons text-scogobgsky text-lg pl-1'
                                                        onClick={() => dispatch(copyToClipboard({ data: site?.site_branch_name }))}
                                                    >
                                                        {' '}
                                                        content_copy{' '}
                                                    </span>
                                                </IconToolTip>
                                            </div>
                                        )}
                                    </div>

                                </div>
                            );
                        },
                    },
                },
                {
                    head: {
                        render: (headName) => {
                            const customerFilter = filterSchema.customersFilter;
                            const endUserFilter = filterSchema.endusersFilter;
                            const keysToFilter = [endUserFilter];
                            if (toShowCustomer) keysToFilter.unshift(customerFilter);
                            return filterComponent({ headName, keysToFilter });
                        },
                        headName: toShowCustomer ? 'Customer' : 'End User',
                        width: 0.7,
                    },
                    body: {
                        render: (site) => {
                            return (
                                <div className='w-full'>
                                    {site?.customerName && toShowCustomer ? (
                                        <p className={`truncate w-11/12`}>
                                            <IconToolTip placement={'top'} title={`Customer Name : ${site?.customerName}`}>
                                                <span className='w-full'>{site?.customerName}</span>
                                            </IconToolTip>
                                        </p>
                                    ) : (
                                        <></>
                                    )}
                                    {site?.customerEnduser ? (
                                        <p className={' font-bold text-scogo99 w-full truncate'}>
                                            <IconToolTip placement={'bottom'} title={`End User : ${site?.customerEnduser}`}>
                                                <span className='font-bold text-scogo99 max-w-max truncate'>{site?.customerEnduser}</span>
                                            </IconToolTip>
                                        </p>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            );
                        },
                    },
                },
                {
                    head: {
                        render: (headName) => {
                            return filterComponent({
                                headName,
                                keysToFilter: [filterSchema.citiesFilter, filterSchema.statesFilter],
                            });
                        },
                        headName: 'Address',
                        width: 1.5,
                        columnRowCss: 'break-words',
                    },
                    body: {
                        render: (site) => {
                            const isAddressAvailable = site?.state || site?.city || site?.address || site?.fk_pincode_id;
                            return (
                                <div className='w-4/5 break-words	'>
                                    <IconToolTip placement={'bottom'} title={`${site?.address}`}>
                                        <span className='line-clamp-3 break-all w-11/12'>{site?.address},</span>
                                    </IconToolTip>
                                    <span>
                                        <span> {site?.city},</span> <span>{site?.state},</span> <span>{site?.fk_pincode_id}</span>
                                    </span>
                                    {isAddressAvailable && (
                                        <>
                                            <IconToolTip placement={'bottom'} title={`Open Location`}>
                                                <span className='cursor-pointer material-icons text-scogobgsky text-lg' onClick={() => handleOpenLocation(site)}>
                                                    {' '}
                                                    place{' '}
                                                </span>
                                            </IconToolTip>
                                            <IconToolTip placement={'bottom'} title={`Copy Address`}>
                                                <span className='cursor-pointer material-icons text-scogobgsky text-lg' onClick={() => handleCopyAddress(site)}>
                                                    {' '}
                                                    content_copy{' '}
                                                </span>
                                            </IconToolTip>
                                        </>
                                    )}
                                </div>
                            );
                        },
                    },
                },
                {
                    head: {
                        render: (headName) => {
                            return headName;
                        },
                        headName: 'Site Contact',
                    },
                    body: {
                        render: (site) => {
                            return (
                                <div className='w-full'>
                                    <p className='font-bold text-scogoprimary w-full'>
                                        <OverflowTip someLongText={site?.siteContactName} />
                                    </p>
                                    <p className='text-scogo99'>
                                        <OverflowTip someLongText={site?.siteContactPhone} />
                                    </p>
                                    <p className='text-scogo99'>
                                        <OverflowTip someLongText={site?.siteContactEmail} />
                                    </p>
                                </div>
                            );
                        },
                    },
                },
                {
                    head: {
                        render: (headName) => {
                            return headName;
                        },
                        headName: 'Last Ticket',
                        hide: showInventorySideDrawer,
                        columnRowCss: 'break-all',
                    },
                    body: {
                        render: (site) => {

                            const lastTIcketCreatedAt = validateDateValue(site?.lastTicket?.created_at) && moment(new Date(validateResponseDate(site?.lastTicket?.created_at)));
                            return (
                                <>
                                    {site?.lastTicket?.job_ticket_number && (
                                        <p className='text-scogo15 font-medium w-full '>
                                            <div className='flex w-full gap-1'>
                                                <IconToolTip title={site?.lastTicket?.job_ticket_number} placement='bottom'>
                                                    <p
                                                        className={`${clickableTextClass} cursor-pointer hover:text-scogoorange truncate`}
                                                        onClick={() =>
                                                            dispatch(
                                                                openChatInNewTab({
                                                                    conversationId: site.lastTicket.id,
                                                                })
                                                            )
                                                        }
                                                    >
                                                        {site?.lastTicket?.job_ticket_number}{' '}
                                                    </p>
                                                </IconToolTip>
                                                <IconToolTip placement={'bottom'} title={`Copy Ticket Number`}>
                                                    <span
                                                        className='cursor-pointer material-icons text-scogobgsky text-lg'
                                                        onClick={() => dispatch(copyToClipboard({ data: site?.lastTicket?.job_ticket_number }))}
                                                    >
                                                        {' '}
                                                        content_copy{' '}
                                                    </span>
                                                </IconToolTip>
                                            </div>
                                        </p>
                                    )}
                                    {lastTIcketCreatedAt && (
                                        <DateComponent className='text-scogogray font-normal text-font10' date={lastTIcketCreatedAt} timeFormat='DD MMM YYYY HH:mm A' />
                                    )}
                                    <span className='px-2 bg-scogoprimary text-white flex gap-2 items-center rounded-2xl w-fit max-w-11/12' >
                                        <OverflowTip textClass={`text-inherit text-font10 text-black w-full font-normal`} someLongText={site?.lastTicket?.project?.project_name} />
                                    </span>
                                    <RenderSitePos poList={site.poList} />
                                </>
                            );
                        },
                    },
                },
                {
                    head: {
                        render: (headName) => {
                            return filterComponent({
                                headName,
                                keysToFilter: [filterSchema.slaFilter],
                            });
                        },
                        headName: 'SLA',
                        hide: showInventorySideDrawer,
                        width: 0.7,
                    },
                    body: {
                        render: (site) => {
                            return (
                                <>
                                    <p>{site?.slaDetails}</p>
                                </>
                            );
                        },
                    },
                },
                {
                    head: {
                        render: (headName) => {
                            return filterComponent({
                                headName,
                                keysToFilter: [filterSchema.assetTypeFilter],
                            });
                        },
                        headName: 'Assets',
                        columnRowCss: 'break-words',
                        width: 1.3
                    },
                    body: {
                        render: (site) => {
                            return <div className='pl-3  '>
                                <GetAssetsList assetStats={site?.assetTypes} isSite={true} />
                            </div>
                        },
                    },
                },
                {
                    head: {
                        render: (headName) => {
                            return headName;
                        },
                        headName: 'Actions',
                        width: 0.4,
                    },
                    body: {
                        render: (site) => {
                            return (
                                <div className='flex justify-center'>
                                    <GetSiteActions item={site} />
                                </div>
                            );
                        },
                    },
                },
            ],
        };

        return (
            <VirtualizedTable
                rowHeight={100}
                schema={siteTableSchema}
                rowData={sitesData}
                isLoading={searchLoading ? searchLoading : sitesLoading}
                tablePagination
                tablePaginationHandler={(limit, page) => updateQueryFilters({ limit, page, filterSchema, filterList })}
                setRowsPerPage={setInventoryRowsAndPage}
                count={sitesCount}
                tableError={'No Sites Found'}
                rowsPerPage={inventoryRowsPerPage}
                page={inventoryPage}
            />
        );
    }
);

export default SiteDetails;




const GetSiteActions = (props) => {
    const { item } = props;
    let sitesTableActions = GetInventoryActions('sitesTableActions');
    const { loggedUser } = useSelector(state => state.auth);
    const customerId = getCustomerIdOfLoginUser(loggedUser);

    return <DropdownMenu menuData={sitesTableActions} item={item} componentOrIcon={'more_vert'} iconColor={'text-scogoprimary'} payload={{ customerId }} />;
};

export const RenderSitePos = ({ poList }) => {
    const poLimit = 1;
    if (!poList || poList.length === 0) return <></>;

    const handleItemClick = (elem) => {
        const url = elem?.po?.approvedpdf ? elem.po.approvedpdf : elem?.po?.quotationpdf;
        window.open(url, '_blank');
    };

    return (
        <>
            <div className='items-center relative'>
                {Array.isArray(poList) && poList.length > 0 && (
                    <>
                        {poList?.slice(0, poLimit).map((elem) => {
                            return <p key={elem.creationDate} className='text-scogoprimary text-font11 cursor-pointer hover:text-scogoorange line-clamp-1 font-medium'>
                                <IconToolTip title={`PO Number ${elem.po.poNumber}`}>
                                    <a href={elem?.po?.approvedpdf ? elem.po.approvedpdf : elem?.po?.quotationpdf} target='_blank' rel='noreferrer'>
                                        {elem.po.poNumber}
                                    </a>
                                </IconToolTip>
                            </p>
                        })}
                        {poList.length > poLimit && (
                            <Menus
                                className={'relative'}
                                position='ml-auto'
                                activeStatus={false}
                                menuButtonIcon={<span className='text-font12 font-normal text-scogoorange cursor-pointer'>{`+ ${poList.length - poLimit}`}</span>}
                                handleItemClick={(elem) => handleItemClick(elem)}
                                itemLable={poList.slice(poLimit, poList.length).map((elem) => elem.po.poNumber)}
                                items={poList.slice(poLimit, poList.length)}
                                itemHoverClassName='hover:text-scogoorange cursor-pointer'
                                itemsClassName='origin-top-right absolute px-3 py-2  lg:top-0 top-4 mt-2 z-10 rounded-md  shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none w-56 max-w-xs font-medium text-scogoprimary'
                                itemClassName={(elem) =>
                                    ` block mt-1 text-font11 px-p-6 py-p-3 rounded-3px `
                                }
                            />
                        )}
                    </>
                )}
            </div>
        </>
    );
}