import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { viewTicketById } from '../../../actions/tickets';
import { clearEligibleSpListFromStore, getEligibleSPsList } from '../../../actions/sp';
import { textCss } from '../../../common/Css';
import CloseFeasibilityForm from './CloseFeasibilityForm';
import { DetailsToShow } from './Details';
import { useSelector } from 'react-redux';
import NoResultFound from '../../../common/NoResultFound';
import { getIsEligibleToCloseFeasibility } from '../TicketController';
import SearchBox from '../../../common/SearchBox';
import Loader from '../../../common/Loader';
import ButtonScogoPrimary from '../../../common/Buttons/ButtonScogoPrimary';
import { openCustomModal, closeFullScreenModalAction } from '../../../actions/modal';
import ShareFeasibilityLink from '../../team/Modals/ShareFeasibilityLink';
import IconToolTip from '../../../common/IconToolTip';
import { getCustomerIdOfLoginUser } from '../../../utils/utils';
import { useNavigate } from 'react-router-dom';
import { sendInvitePath } from '../../../router/roleWisePageRoute';

export default function CloseFeasibility({ ticketId, isOnlyView = false, heading }) {
    const [searchTerm, setSeachTerm] = useState(undefined);

    const dispatch = useDispatch();
    const { viewTicket } = useSelector((state) => state.tickets);
    const { isViewTicketByIdLoading } = useSelector((state) => state.loading);
    const { loggedUser } = useSelector((state) => state.auth);
    const customerId = getCustomerIdOfLoginUser(loggedUser);
    const navigate = useNavigate();

    const onInviteSPClick = () => {
        let customer_id = viewTicket.fk_customer_id;
        let is_feasibility_eligible = 1;
        if (!customer_id) customer_id = customerId;
        let path = `${sendInvitePath.path}?customerId=${customer_id}&isCustomerFeasibilityEligible=${is_feasibility_eligible}&invite=isp`;
        navigate(path);
    }

    useEffect(() => {
        dispatch(viewTicketById({ ticketId }));
        dispatch(getEligibleSPsList({ ticketId, type: 'ISP' }));

        return () => dispatch(clearEligibleSpListFromStore());
    }, [ticketId, dispatch]);

    const isEligibleForCloseFeasibility = (ticketId) => {
        if (!ticketId) return false;
        let ticket = {
            isEscalated: viewTicket?.is_escalated,
            serviceTypeId: viewTicket?.service_type_id,
            parent_ticket_id: viewTicket?.parent_ticket_id,
            po_raised: viewTicket?.po_raised,
        };
        return getIsEligibleToCloseFeasibility({ ticket });
    };

    const showNoResultFound = !isViewTicketByIdLoading && (!viewTicket || !isEligibleForCloseFeasibility(viewTicket));

    const onShareFeasibilityClick = () => {
        let heading = 'Share Feasibility Link';
        let modalWidth = '60rem';
        let modalHeight = 'auto';
        let modalComponent = <ShareFeasibilityLink ticketId={ticketId} />;
        dispatch(openCustomModal({ heading, modalWidth, modalHeight, modalComponent }));
    };

    return (
        <div className='subContainer title-4 px-5 pt-5'>
            <div className={`titleContainer sticky  w-full z-20 `}>
                <div className='md:flex max-w-full'>
                    <div>
                        <h1 className={textCss?.moduleTitle}>
                            {heading} : {viewTicket && <span className='text-scogobgsky text-font16'>{viewTicket.job_ticket_number}</span>}
                        </h1>
                    </div>
                    <div className='flex items-center ml-auto gap-4'>
                        <SearchBox
                            onCancel={() => setSeachTerm('')}
                            searchTerm={searchTerm}
                            onChange={(event) => setSeachTerm(event.target.value)}
                            placeholder={'Search ISP Name/Mobile/Email'}
                        />
                        <ButtonScogoPrimary
                            textOrComponent={'Share Link'}
                            onClick={() => {
                                onShareFeasibilityClick();
                            }}
                            buttonIcon={'/img/share_white.svg'}
                        />
                        {!isOnlyView && (
                            <ButtonScogoPrimary
                                textOrComponent={'Invite ISP'}
                                onClick={onInviteSPClick}
                                buttonIconComponent={<span className='material-icons text-2xl'>person_add</span>}
                            />
                        )}
                        <div
                            className='ml-auto cursor-pointer'
                            onClick={() => {
                                dispatch(closeFullScreenModalAction());
                            }}
                        >
                            <IconToolTip title='Close'>
                                <span className='material-icons text-scogoprimary font-medium align-middle text-4xl hover:text-scogoorange'>highlight_off</span>
                            </IconToolTip>
                        </div>
                    </div>
                </div>
            </div>
            {isEligibleForCloseFeasibility(ticketId) ? (
                <div className={`w-full z-0 closeFeasibiblityContainer`}>
                    <div className='closeFeasibiblityDetails bg-white relative mb-4 border-gray-200 shadow-md w-full rounded-lg'>
                        <DetailsToShow ticketDetails={viewTicket} />
                    </div>
                    <div className='closeFeasibiblityForm'>
                        <CloseFeasibilityForm ticketId={ticketId} searchTerm={searchTerm} setSeachTerm={setSeachTerm} isOnlyView={isOnlyView} />
                    </div>
                </div>
            ) : (
                <>
                    <div className=''>
                        <Loader color='#F48A21' size='65' speed='1' thickness='3' margin='150px' />
                    </div>
                </>
            )}
            {showNoResultFound && <NoResultFound message='Ticket Not Found' />}
        </div>
    );
}
