import React, { useCallback, useEffect, useRef, useState } from 'react';
import Buttons from '../../common/Buttons';
import { useNavigate } from 'react-router-dom';
import Layout from '../auth/Layout';
import Checkbox from '../../common/Checkbox';
import Form from '../Inventory/reusableComponents/AddOption/Form';
import Input from '../Inventory/reusableComponents/AddOption/Input';
import { putToAuthStore, registerWithMobile, signUpWithOtp } from '../../actions/auth';
import ButtonScogoPrimary from '../../common/Buttons/ButtonScogoPrimary';
import { useDispatch, useSelector } from 'react-redux';
import { sendToastMessage } from '../../actions/toast';

const partner = {
    tabName: 'Partner',
    value: 'SP',
};
const customer = {
    tabName: 'Customer',
    value: 'CUSTOMER',
};

const tabs = [partner, customer];

const initialValues = {
    mobileNumber: '',
    referralCode: '',
    otp: '',
};

const submitButtonClass = 'w-full text-white font-medium text-font14 h-14 mt-3 rounded-md w-full relative flex items-center justify-center';

function SignUp() {
    const [selectedTab, setSelectedTab] = useState(partner);
    const [termsAndConditionCheckbox, setTermsAndConditionCheckbox] = useState(false);
    const { formButtonLoading } = useSelector((state) => state.loading);
    const { otpSent } = useSelector((state) => state.auth);
    const formName = 'signUp';
    const dispatch = useDispatch();
    const formRef = useRef();

    const [form, setForm] = useState(initialValues);

    const clearData = useCallback(
        (tab = partner) => {
            setSelectedTab(tab);
            setForm(initialValues);
            dispatch(putToAuthStore({ otpSent: undefined }));
        },
        [dispatch]
    );

    useEffect(() => {
        return () => clearData();
    }, [clearData]);

    const navigate = useNavigate();

    const backToLoginPage = () => {
        navigate('/login');
    };

    const registerNewUser = () => {
        if (!termsAndConditionCheckbox) {
            dispatch(sendToastMessage({ status: 'danger', message: 'Please check terms and conditions' }));
        }
        else {
            if (form.otp && otpSent) {
                dispatch(signUpWithOtp({ mobile: form.mobileNumber, otp: form.otp, navigate, formName }));
            }
            else {
                dispatch(
                    registerWithMobile({ mobile: form.mobileNumber, register_as: selectedTab.value, formName, referralCode: form.referralCode })
                );
            }
        }
    };

    const openTermsAndConditionsPage = () => {
        let Url = 'https://s3.ap-south-1.amazonaws.com/static.assets.scogo.in/TnC/Scogo_T%26C+and+Privacy+Policy.pdf';
        window.open(Url, '_blank');
    };

    return (
        <>
            <Layout>
                <div className='px-2 py-1' style={{ width: '35rem' }}>
                    <div>
                        <Form
                            setForm={setForm}
                            form={form}
                            formName={formName}
                            className='w-full'
                            submit={(e) => registerNewUser(e)}
                            initialValues={initialValues}
                            customFormButtons
                            formRef={formRef}
                        >
                            <Input
                                rounded
                                required
                                className='w-full py-1'
                                type={'number'}
                                name='mobileNumber'
                                placeholder={'Enter Mobile Number'}
                                leadingIcon={<span className='material-icons text-font18 text-scogoorange absolute  h-14  p-4'>person</span>}
                                maxLength={10}
                            />
                            {otpSent && <Input rounded className='w-full py-2' type={'number'} name='otp' placeholder={'Enter OTP'} maxLength={4} />}

                            <ButtonScogoPrimary
                                textOrComponent={otpSent ? 'Verify OTP & Sign Up' : 'Continue'}
                                disabled={false}
                                buttonClass={'bg-scogoprimary hover:bg-scogobgsky' + submitButtonClass}
                                disabledClass={'bg-scogogray hover:bg-scogogray' + submitButtonClass}
                                loaderClass={'absolute w-full'}
                                onClick={(e) => {
                                    e.preventDefault();
                                    formRef?.current?.click();
                                }}
                                loading={formButtonLoading?.[formName]}
                            />
                            <div className='flex items-center'>
                                <Checkbox
                                    checkColor='text-scogoorange my-4'
                                    checked={termsAndConditionCheckbox}
                                    onChange={(isChecked) => setTermsAndConditionCheckbox(isChecked)}
                                    dynamicSize={'1.2rem'}
                                />
                                <span
                                    onClick={() => openTermsAndConditionsPage()}
                                    className='text-scogoprimary pl-2 pr-2 text-font12 cursor-pointer hover:text-scogoorange'
                                >
                                    Terms and Conditions
                                </span>
                            </div>
                            <div onClick={() => backToLoginPage()} className='text-font12 absolute text-151515 text-left font-medium mt-2'>
                                <span className='text-scogoorange cursor-pointer'> {'< Back to Login'} </span>
                            </div>
                        </Form>
                    </div>
                </div>
            </Layout>
        </>
    );
}

export default SignUp;
