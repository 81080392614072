import React from 'react';
import { useDispatch } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import { acceptAsset } from '../../../actions/tickets';
import Form from '../../../common/Form';
import { useSelector } from 'react-redux';
import DatePicker from '../../../common/Form/DatePicker';
import { useForm } from 'react-hook-form';
import TextArea from '../../../common/Form/TextArea';
import { validateDateValue } from '../../../utils/common';
import moment from 'moment';

const formName = 'accept_asset';

export default function AcceptAsset({ ticketId }) {
    const defaultValues = {};
    const dispatch = useDispatch();
    const { formButtonLoading } = useSelector((state) => state.loading);

    const submit = (formValues) => {
        let site_completed_on;
        if (validateDateValue(formValues.site_completed_on)) {
            site_completed_on = new Date(moment(formValues.site_completed_on).subtract({ hours: 5.5 }));
        }
        dispatch(acceptAsset({ site_completed_on, job_sign_off_notes: formValues.job_sign_off_notes, formName, ticketId }));
    };

    const methods = useForm({ defaultValues, mode: 'all' });

    return (
        <Form
            methods={methods}
            onSubmit={submit}
            submitButtonText={'Yes'}
            onCancel={() => dispatch(closeModalAction())}
            showSubmit
            submitContainerClassName='flex gap-2 justify-end py-4 px-4'
            isLoading={formButtonLoading[formName]}
        >
            <div className='px-4 pt-2'>
                <label className='font-medium text-font11 text-scogoprimary'>Are you Sure you want to Continue?</label>
            </div>
            <div className='flex gap-2 px-2'>
                <DatePicker className='w-6/12' name='site_completed_on' label='Activity Completed On' isClearable={false} showTimeSelect />
                <TextArea className='w-6/12' label='Remark' name='job_sign_off_notes' type={'text'} required />
            </div>
        </Form>
    );
}
