import React from 'react';
import { GetQueryParam } from '../../../utils/common';


export default function TypingUsers({ users, additionalClasses = '', maxUsernamesToShow = 3 }) {
    const chatGptQueryParams = GetQueryParam('chatGpt', Boolean);
    if (!Array.isArray(users) || users.length === 0) {
        return <></>;
    }
    if (chatGptQueryParams) {
        return <img className='h-[2rem] w-[10rem]' src={process.env.PUBLIC_URL + "/gptLoader.gif"} alt='loading...' />
    }
    const usernames = users.map((user) => user.username);
    let parts = [];
    if (usernames.length === 1) {
        parts = [usernames[0], ' is typing...'];
    } else if (usernames.length <= maxUsernamesToShow) {
        parts = [usernames.join(', '), ' are typing...'];
    } else {
        parts = [usernames.slice(0, maxUsernamesToShow).join(', '), ` +${usernames.length - maxUsernamesToShow} are typing...`];
    }

    return <div className={`text-scogoprimary ${additionalClasses} max-w-md truncate`}>
        {parts[0]}
        <span className={`italic`} >{parts[1]}</span>
    </div>
};
