import React, { useState, useCallback, useEffect } from 'react';
import { validateNumberValue } from '../../utils/common';
import { useFormContext } from 'react-hook-form';

export const TotalCard = ({ quotesArrayName, isCustomerFeasibilityEligible }) => {
    const { watch, setValue } = useFormContext();

    const [totalValues, setTotalValues] = useState({
        quote_vendor_1: 0,
        quote_vendor_2: 0,
        quote_vendor_3: 0,
        quantity: 0,
    });

    const updateTotalValues = useCallback(
        (values) => {
            let quantity = 0;
            let quote_vendor_1 = 0;
            let quote_vendor_2 = 0;
            let quote_vendor_3 = 0;
            let quotes = values?.[quotesArrayName];
            if (Array.isArray(quotes)) {
                quotes.forEach((quote) => {
                    quantity += validateNumberValue(quote.quantity);
                    quote_vendor_1 = quote_vendor_1 + validateNumberValue(quote.quantity) * validateNumberValue(quote.quote_1);
                    quote_vendor_2 = quote_vendor_2 + validateNumberValue(quote.quantity) * validateNumberValue(quote.quote_2);
                    quote_vendor_3 = quote_vendor_3 + validateNumberValue(quote.quantity) * validateNumberValue(quote.quote_3);
                });
            }
            setTotalValues((prevState) => ({ ...prevState, quantity, quote_vendor_1, quote_vendor_2, quote_vendor_3 }));
        },
        [quotesArrayName]
    );

    useEffect(() => {
        const { unsubscribe } = watch((values) => {
            updateTotalValues(values);
        });
        return () => unsubscribe();
    }, [watch, setValue, quotesArrayName, updateTotalValues]);

    return (
        <div className='flex text-font11 bg-scogof8 py-4 rounded-b-lg'>
            <p className='w-80 truncate pl-1 font-medium pl-3'>Total</p>
            <p className='w-80 truncate'></p>
            <p className='w-80 truncate'></p>
            {isCustomerFeasibilityEligible && (
                <>
                    <p className='w-40 truncate'></p>
                    <p className='w-80 truncate'></p>
                </>
            )}
            <p className='w-60 truncate'>{totalValues.quantity}</p>
            <p className='w-80 truncate'>{totalValues.quote_vendor_1}</p>
            {!isCustomerFeasibilityEligible && (
                <>
                    <p className='w-80 truncate'>{totalValues.quote_vendor_2}</p>
                    <p className='w-80 truncate'>{totalValues.quote_vendor_3}</p>
                </>
            )}
            <p className='w-24 truncate'></p>
        </div>
    );
};
