import { call, put, takeEvery, takeLatest, all, select } from 'redux-saga/effects';
import { inventory, loading, toast, modal, utils } from '../../types';
import microservices from '../../lambdas/microservices';
import lambdas from '../../lambdas/apis';
import api from '../../apis';
import { getInventoryFilterData } from '../../selectors/inventory';
import { getLoggedUser } from '../../selectors/users';
import { getCustomerIdOfLoginUser } from '../../utils/utils';

export function* getAllSpareAssets(action) {
    const { customerId, warehouseId, limit, page, count = true, dropdown = false, reconcile = false, status, working_condition } = action.payload;
    const queryParams = { status: status, warehouseType: 'ALL', limit, page, count, dropdown, working_condition, newUI: true };
    if (!isNaN(customerId) && customerId > 0) queryParams.customerId = customerId;
    if (!isNaN(warehouseId) && warehouseId > 0) queryParams.warehouseId = warehouseId;
    try {
        yield put({ type: loading.GET_ALL_SPARE_ASSETS_LOADING, payload: true });
        const response = yield call(microservices.inventory.getAllSpareAssets, {}, {}, queryParams);
        if (response.data.code === 200) {
            if (dropdown) {
                if (reconcile) {
                    yield put({ type: inventory.GET_ALL_SPARE_ASSETS_SUCCESS, payload: { spareAssets: response.data.data } });
                }
            } else {
                if (count) {
                    yield put({ type: inventory.ALL_SPARE_ASSETS_COUNT_SUCCESS, payload: response.data.count });
                }
                yield put({ type: inventory.GET_ALL_SPARE_ASSETS_SUCCESS, payload: { spareAssets: response.data.data } });
            }
        } else {
            let message = response.data.data.message;
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message } });
            yield put({ type: inventory.GET_ALL_SPARE_ASSETS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: inventory.GET_ALL_SPARE_ASSETS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.GET_ALL_SPARE_ASSETS_LOADING, payload: false });
}

export function* getAllSpareAssetForFilter(action) {
    const { customerId, warehouseId, count, limit, page, dropdown, status = 'ALL', working_condition } = action.payload;
    const queryParams = { status: status, limit, page, warehouseType: 'ALL', count, dropdown, working_condition, newUI: true };
    if (!isNaN(customerId) && customerId > 0) queryParams.customerId = customerId;
    if (!isNaN(warehouseId) && warehouseId > 0) queryParams.warehouseId = warehouseId;
    try {
        const response = yield call(microservices.inventory.getAllSpareAssets, {}, {}, queryParams);
        if (response.data.code === 200) {
            yield put({ type: inventory.GET_ALL_FILTER_SPAREASSET_SUCCESS, payload: response.data.data });
        } else {
            let message = response.data.data.message;
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message } });
            yield put({ type: inventory.GET_ALL_FILTER_SPAREASSET_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: inventory.GET_ALL_FILTER_SPAREASSET_FAILURE, payload: { message: error } });
    }
}

export function* getSpareCategories(action) {
    const { customerId, limit = 10, page = 1, count = false, dropdown = false } = action.payload;
    let queryParams = { limit, page, count, dropdown };
    if (!isNaN(customerId) && customerId > 0) queryParams.customerId = customerId;
    try {
        const response = yield call(microservices.inventory.getSpareCategories, {}, {}, queryParams);
        if (response.data.code === 200) {
            let categories = response.data.data;
            categories = [];
            response.data.data.forEach((category) => {
                categories.push({ key: category.id, value: category.id, label: category.title });
            });
            yield put({ type: inventory.GET_SPARE_CATEGORIES_SUCCESS, payload: categories });
        } else {
            yield put({ type: inventory.GET_SPARE_CATEGORIES_FAILURE, payload: { message: 'Something went Wrong' } });
        }
    } catch (error) {
        yield put({ type: inventory.GET_SPARE_CATEGORIES_FAILURE, payload: { message: error } });
    }
}
export function* getInventoryPartcodes(action) {
    const { customerId, assetTypeId, limit = 10, page = 1, count = false, dropdown = false } = action.payload;
    let queryParams = { limit, page, count, dropdown };
    if (!isNaN(assetTypeId) && parseInt(assetTypeId) > 0) queryParams.assetTypeId = assetTypeId;
    if (!isNaN(customerId) && parseInt(customerId) > 0) queryParams.customerId = customerId;
    try {
        const response = yield call(microservices.inventory.getInventoryPartcodes, {}, {}, queryParams);
        if (response.data.code === 200) {
            let partcodes = response.data.data;
            partcodes = [];
            response.data.data.forEach((item) => {
                partcodes.push({
                    key: item.id,
                    value: item.title,
                    label: item.title,
                });
            });
            yield put({ type: inventory.GET_INVENTORY_PARTCODES_SUCCESS, payload: partcodes });
        } else {
            yield put({ type: inventory.GET_INVENTORY_PARTCODES_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: inventory.GET_INVENTORY_PARTCODES_FAILURE, payload: { message: error } });
    }
}

export function* getAssetsTypes(action) {
    const { type = 'ASSET', customerId, categoryId, limit = 10, page = 1, dropdown = false, count = false } = action.payload;
    let queryParams = { type, limit, page, dropdown, count };
    if (!isNaN(customerId) && parseInt(customerId) > 0) queryParams.customerId = customerId;
    if (!isNaN(categoryId) && parseInt(categoryId) > 0) queryParams.categoryId = categoryId;
    try {
        yield put({ type: loading.GET_ALL_ASSET_TYPE_LOADING, payload: true });
        const response = yield call(lambdas.operation.getAssetsTypes, {}, {}, queryParams);
        if (response.data.code === 200) {
            let assetsOption = [];
            response.data.data.map((assets) => {
                return assetsOption.push({
                    key: assets.id,
                    value: assets.id,
                    label: assets.title,
                });
            });
            yield put({ type: inventory.GET_ASSETS_SUCCESS, payload: assetsOption });
        } else {
            yield put({ type: inventory.GET_ASSETS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: inventory.GET_ASSETS_FAILURE, payload: error });
    }
    yield put({ type: loading.GET_ALL_ASSET_TYPE_LOADING, payload: false });
}

export function* createInventorySpareAssets(action) {
    const { data, customerId, warehouseId, formName, refresh = true } = action.payload;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });

    try {
        const response = yield call(microservices.inventory.createSpareAssets, {}, {}, {}, data);
        let status = 'success',
            message = `SpareAsset Created Successfully`;
        if (response.data.code === 200) {
            yield all([yield put({ type: toast.SEND_TOAST, payload: { status, message } }), yield put({ type: modal.CLOSE_MODAL, payload: {} })]);
            yield put({ type: utils.SET_INITIAL_FORM, payload: { reinitialize: false, date: Date.now() } });
            if (refresh) {
                yield put({ type: inventory.REFRESH_SPAREASSET_PAGE, payload: {} });
            }
        } else {
            yield put({ type: inventory.CREATE_INVENTORY_SPARE_ASSETS_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
        }
    } catch (error) {
        yield put({ type: inventory.CREATE_INVENTORY_SPARE_ASSETS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* deleteInventorySpareAsset(action) {
    const { assetId, warehouseId, customerId, formName, refresh = true } = action.payload;
    let status = 'success',
        message = 'Asset Deleted Successfully!!!';
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(microservices.inventory.deleteSpareAsset, {}, { assetId });
        if (response.data.code === 200) {
            yield put({ type: modal.CLOSE_MODAL, payload: {} });
            if (refresh) {
                yield put({ type: inventory.REFRESH_SPAREASSET_PAGE, payload: {} });
            }
        } else {
            status = 'danger';
            message = response.data.data.message;
            yield put({ type: modal.CLOSE_MODAL, payload: {} });
        }
    } catch (error) {
        status = 'danger';
        message = error.message || `Internal Server Error`;
    }
    yield put({ type: toast.SEND_TOAST, payload: { status, message } });
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* updateInventorySpareAsset(action) {
    const { data, assetId, warehouseId, customerId, formName, refresh = true, closeModal = true, enduserId } = action.payload;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    let status = 'success',
        message = 'Asset Updated Successfully!!!';
    try {
        const response = yield call(microservices.inventory.updateSpareAsset, {}, { assetId }, {}, data);
        if (response.data.code === 200) {
            if (closeModal) {
                yield put({ type: modal.CLOSE_MODAL, payload: {} });
            }
            if (refresh) {
                yield put({ type: inventory.REFRESH_SPAREASSET_PAGE, payload: {} });
            }
        } else {
            status = 'danger';
            message = response.data.data.message;
        }
    } catch (error) {
        status = 'danger';
        message = error.message || `Internal Server Error`;
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
    yield put({ type: toast.SEND_TOAST, payload: { status, message } });
}

export function* bulkUploadSpareAssets(action) {
    const { data, warehouseId, customerId, limit = 25, page = 1, count = false, formName, refresh = true } = action.payload;

    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(api.inventory.bulkUploadSpareAssets, { 'Content-Type': 'multipart/form-data' }, {}, {}, data);
        if (response.data.code === 201) {
            if (response.data.data && response.data.data.asset_type_error && Array.isArray(response.data.data.asset_type_error) && response.data.data.asset_type_error.length > 0) {
                yield all([yield put({ type: modal.CLOSE_MODAL }), yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } })]);
            } else {
                yield all([
                    yield put({ type: modal.CLOSE_MODAL }),
                    yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Assets Uploaded Successfully!!!' } }),
                ]);
                if (refresh) {
                    yield put({ type: inventory.REFRESH_SPAREASSET_PAGE, payload: {} });
                }
            }
        } else {
            yield all([yield put({ type: modal.CLOSE_MODAL }), yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } })]);
            yield put({ type: inventory.BULK_UPLOAD_INVENTORY_SPARE_ASSETS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: inventory.BULK_UPLOAD_INVENTORY_SPARE_ASSETS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* getSpareAssetFilterList(action) {
    try {
        const { module, tab, projectId, customerId, toFilter = '' } = action.payload;
        let queryParams = { module, tab };
        if (!['', 'all', undefined, null].includes(projectId)) queryParams.projectId = projectId;
        if (!['', 'all', undefined, null].includes(customerId)) queryParams.customerId = customerId;
        if (toFilter) queryParams.toFilter = toFilter;
        yield put({ type: loading.GET_SPAREASSETS_FILTER_LIST_LOADING, payload: true });

        const response = yield call(microservices.inventory.getAllSpareAssetFilters, {}, {}, queryParams);
        if (response.data.code === 200) {
            yield put({ type: inventory.GET_SPAREASSETS_FILTER_LIST_SUCCESS, payload: response.data.data });
        } else if ([400, 500].includes(response.data.code)) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
        }
    } catch (err) {
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something Went Wrong' } });
    }
    yield put({ type: loading.GET_SPAREASSETS_FILTER_LIST_LOADING, payload: false });
}

export function* refreshSpareAssetPage(action) {
    const { limit = 10, page = 1, query, filterPayload = {}, warehouseId, isFilterActive } = yield select(getInventoryFilterData);
    const loggedUser = yield select(getLoggedUser);
    const customerId = getCustomerIdOfLoginUser(loggedUser);
    const isFilterOrSearchActive = isFilterActive || query?.length > 0;
    try {
        yield put({ type: loading.GET_ALL_SPARE_ASSETS_LOADING, payload: true });
        let response;
        if (isFilterOrSearchActive) {
            let queryParams = {
                module: 'INVENTORY',
                tab: 'spareassets',
                limit,
                page,
                next: true,
                count: true,
                type: 0,
                giveLastTickets: true,
                giveAssetTypes: true,
                giveSpareAssetsStats: true,
            };

            if (!['', 'all', undefined, null, 0].includes(customerId)) queryParams.customerId = customerId;
            if (!['', 'all', undefined, null, 0].includes(warehouseId)) queryParams.warehouseId = warehouseId;
            if (!['', 'all', undefined, null].includes(filterPayload?.status)) filterPayload.status = filterPayload.status.map((s) => s.split(' ').join('_'));
            if (!['', undefined, null].includes(query)) queryParams.query = query;
            response = yield call(lambdas.utils.filterModule, {}, {}, queryParams, filterPayload);
        } else {
            const queryParams = { warehouseType: 'ALL', limit, page, count: true, newUI: true, status: 'ALL' };
            if (!isNaN(customerId) && customerId > 0) queryParams.customerId = customerId;
            if (!isNaN(warehouseId) && warehouseId > 0) queryParams.warehouseId = warehouseId;
            response = yield call(microservices.inventory.getAllSpareAssets, {}, {}, queryParams);
        }
        if (response.data.code === 200) {
            if (isFilterOrSearchActive) {
                yield put({
                    type: inventory.GET_SPAREASSET_FILTERED_DATA_SUCCESS,
                    payload: { data: response.data.data, count: response.data.count },
                });
            } else {
                yield put({ type: inventory.ALL_SPARE_ASSETS_COUNT_SUCCESS, payload: response.data.count });
                yield put({ type: inventory.GET_ALL_SPARE_ASSETS_SUCCESS, payload: { spareAssets: response.data.data } });
            }
        } else if ([400, 500].includes(response.data.code)) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
        }
    } catch (err) { }
    yield put({ type: loading.GET_ALL_SPARE_ASSETS_LOADING, payload: false });
}

export function* getSpareAssetFilteredData(action) {
    let { module = 'INVENTORY', tab = 'spareassets', customerId, next = true, count = false, type = 0, limit, page, query, payload, warehouseId } = action.payload;

    try {
        yield put({ type: loading.GET_ALL_SPARE_ASSETS_LOADING, payload: true });
        let queryParams = { module, tab, limit, page, next, count, type, giveLastTickets: true, giveAssetTypes: true, giveSpareAssetsStats: true };
        if (!['', 'all', undefined, null, 0].includes(customerId)) queryParams.customerId = customerId;
        if (!['', 'all', undefined, null].includes(payload?.status)) payload.status = payload.status.map((s) => s.split(' ').join('_'));
        if (!['', undefined, null].includes(query)) queryParams.query = query;
        if (!['', 'all', undefined, null, 0].includes(warehouseId)) queryParams.warehouseId = warehouseId;
        const response = yield call(lambdas.utils.filterModule, {}, {}, queryParams, payload);
        if (response.data.code === 200) {
            yield put({ type: inventory.GET_SPAREASSET_FILTERED_DATA_SUCCESS, payload: { data: response.data.data, count: response.data.count } });
        } else if ([400, 500].includes(response.data.code)) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
        }
    } catch (err) {
        yield put({ type: inventory.GET_SPAREASSET_FILTERED_DATA_FAILURE, payload: 'Something went wrong' });
    }
    yield put({ type: loading.GET_ALL_SPARE_ASSETS_LOADING, payload: false });
}

export function* getSpareAssetWithAssetId(action) {
    let { customerId, dropdown = false, count = false, status = 'AVAILABLE', assetNumber, limit = 10, page = 1, warehouseType = 'ALL' } = action.payload;

    try {
        yield put({ type: loading.IS_SPAREASSET_WITH_ASSET_ID_LOADING, payload: true });
        let queryParams = {
            dropdown,
            count,
            limit,
            page,
            status,
            assetNumber,
            warehouseType,
        };
        if (!['', undefined, null, 0].includes(customerId)) queryParams.customerId = customerId;
        const response = yield call(microservices.inventory.getAllSpareAssets, {}, {}, queryParams);
        if (response.data.code === 200) {
            yield put({ type: inventory.GET_SPAREASSET_WITH_ASSET_ID_SUCCESS, payload: response.data.data });
        } else if ([400, 500].includes(response.data.code)) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
        }
    } catch (err) {
        yield put({ type: inventory.GET_SPAREASSET_WITH_ASSET_ID_FAILURE, payload: 'Something went wrong' });
    }
    yield put({ type: loading.IS_SPAREASSET_WITH_ASSET_ID_LOADING, payload: false });
}

export default function* spareAssetsSaga() {
    yield takeEvery(inventory.GET_ALL_SPARE_ASSETS_REQUEST, getAllSpareAssets);
    yield takeEvery(inventory.GET_ALL_FILTER_SPAREASSET_REQUEST, getAllSpareAssetForFilter);
    yield takeEvery(inventory.GET_SPARE_CATEGORIES_REQUEST, getSpareCategories);
    yield takeEvery(inventory.GET_INVENTORY_PARTCODES_REQUEST, getInventoryPartcodes);
    yield takeEvery(inventory.GET_ASSETS_REQUEST, getAssetsTypes);
    yield takeLatest(inventory.CREATE_INVENTORY_SPARE_ASSETS_REQUEST, createInventorySpareAssets);
    yield takeLatest(inventory.DELETE_INVENTORY_SPARE_ASSETS_REQUEST, deleteInventorySpareAsset);
    yield takeLatest(inventory.UPDATE_INVENTORY_SPARE_ASSETS_REQUEST, updateInventorySpareAsset);
    yield takeLatest(inventory.BULK_UPLOAD_INVENTORY_SPARE_ASSETS_REQUEST, bulkUploadSpareAssets);
    yield takeLatest(inventory.GET_SPAREASSETS_FILTER_LIST_REQUEST, getSpareAssetFilterList);
    yield takeLatest(inventory.REFRESH_SPAREASSET_PAGE, refreshSpareAssetPage);
    yield takeLatest(inventory.GET_SPAREASSET_FILTERED_DATA_REQUEST, getSpareAssetFilteredData);
    yield takeLatest(inventory.GET_SPAREASSET_WITH_ASSET_ID_REQUEST, getSpareAssetWithAssetId);
}
