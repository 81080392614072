import React from 'react';

export default function TrackingDetailsNotFound() {
    let url = process.env.PUBLIC_URL + '/img/Tracking.png';
    return <div className='h-full w-full bg-white'>
        <img src={url} alt='Ticket Not Found' style={{
            objectFit: 'contain',
            height: '94.5vh',
            width: '100vw'
        }} />
    </div>
}