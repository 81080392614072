import { call, put, takeLatest, all } from 'redux-saga/effects';
import { fm, loading, toast, modal, utils } from '../types';
import api from '../apis';
import microservices from '../lambdas/microservices';

export function* listOfGenerateInvoiceProjects(action) {
    const { order_by = 'total_signoff_tickets', order = 'desc', having = 'invoice_generate_pending_tickets', limit = 10, page = 1 } = action.payload;
    let queryParams = { order_by, order, having, limit, page };
    if (limit) queryParams.limit = limit;
    if (page) queryParams.page = page;
    try {
        yield put({ type: loading.LIST_RECEIVABLES_GENERATE_INVOICE_LOADING, payload: true });
        const response = yield call(api.fm.receivableGenerateInvoiceList, {}, {}, queryParams);
        if (response.data.code === 200) {
            yield put({ type: fm.LIST_RECEIVABLES_GENERATE_INVOICE_SUCCESS, payload: response.data.data && response.data.data.receivables ? response.data.data.receivables : [] });
        } else {
            yield put({ type: fm.LIST_RECEIVABLES_GENERATE_INVOICE_FAILURE, payload: response.data });
        }
    } catch (error) {
        yield put({ type: fm.LIST_RECEIVABLES_GENERATE_INVOICE_FAILURE, payload: error });
    }
    yield put({ type: loading.LIST_RECEIVABLES_GENERATE_INVOICE_LOADING, payload: false });
}

export function* listOfProjectTickets(action) {
    const { signoff_accepted = 0, limit = 10, page = 1, query, payload } = action.payload;
    let queryParams = { signoff_accepted, limit, page, };
    try {
        yield put({ type: loading.LIST_RECEIVABLES_PROJECT_TICKETS_LOADING, payload: true });
        const response = yield call(microservices.fm.getProjectTickets, {}, {}, queryParams, { ...payload, query });
        if (response.data.code === 200) {
            yield put({ type: fm.LIST_RECEIVABLES_PROJECT_TICKETS_SUCCESS, payload: { tickets: response.data.data.tickets, count: response.data.data.count } });
            yield put({ type: fm.RECEIVABLES_PROJECT_TICKETS_INVOICE_COUNT_SUCCESS, payload: response.data.data.invoice });
        } else {
            yield put({ type: fm.LIST_RECEIVABLES_PROJECT_TICKETS_FAILURE, payload: response.data });
        }
    } catch (error) {
        yield put({ type: fm.LIST_RECEIVABLES_PROJECT_TICKETS_FAILURE, payload: error });
    }
    yield put({ type: loading.LIST_RECEIVABLES_PROJECT_TICKETS_LOADING, payload: false });
}

export function* cbGenerateInvoice(action) {
    const { form, project_id, formName } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(api.fm.giGenerateInvoice, {}, {}, {}, form);
        if (response.data.code === 201) {
            const message = 'Invoice generated successfully!';
            const status = 'success';
            yield all([
                yield put({ type: toast.SEND_TOAST, payload: { status, message } }),
                yield put({ type: fm.CB_GENERATE_INVOICE_SUCCESS, payload: response.data }),
                yield put({ type: fm.LIST_RECEIVABLES_PROJECT_TICKETS_REQUEST, payload: { payload: { projectIds: [project_id] } } }),
                yield put({ type: modal.CLOSE_MODAL }),
            ]);
        } else if (response.data.code === 400) {
            const message = response.data.message;
            const status = 'danger';
            yield put({ type: toast.SEND_TOAST, payload: { status, message } });
        } else {
            yield put({ type: fm.CB_GENERATE_INVOICE_FAILURE, payload: response.data });
        }
    } catch (error) {
        yield put({ type: fm.CB_GENERATE_INVOICE_FAILURE, payload: error });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* listRevenuesList(action) {
    let queryParams = {
        from_date: action.payload.fromDate,
        to_date: action.payload.toDate,
    };
    try {
        yield put({ type: loading.IS_REVENUES_LIST_LOADING, payload: true });
        const response = yield call(api.fm.revenuesList, {}, {}, queryParams);
        if (response.data.code === 200) {
            yield put({ type: fm.REVENUES_LIST_SUCCESS, payload: response.data.data.data });
        } else {
            yield put({ type: fm.REVENUES_LIST_FAILURE, payload: response.data.data.data });
        }
    } catch (error) {
        yield put({ type: fm.REVENUES_LIST_FAILURE, payload: error });
    }
    yield put({ type: loading.IS_REVENUES_LIST_LOADING, payload: false });
}

export function* downloadClientMonthlyInvoices(action) {
    const { customer_invoice_id } = action.payload;
    try {
        const response = yield call(api.fm.downloadInvoice, {}, { customer_invoice_id }, {});
        if (response.data.code === 200) {
            if (response.data.data) {
                if (response.data.data.invoice) window.open(response.data.data.invoice, '_blank');
                if (response.data.data.csv)
                    yield put({
                        type: utils.DOWNLOAD_REMOTE_DATA_REQUEST,
                        payload: { url: response.data.data.csv, fileName: `${customer_invoice_id}-${Date.now()}.csv`, mimeType: 'text/csv' },
                    });
            }
        } else {
            yield put({ type: fm.DOWNLOAD_CUSTOMER_INVOICE_FAILURE, payload: response.data.data.data });
        }
    } catch (error) {
        yield put({ type: fm.DOWNLOAD_CUSTOMER_INVOICE_FAILURE, payload: error });
    }
}

export function* updateCustomerInvoiceStatus(action) {
    const { invoice_id, payment_status, message, payment_date, formName } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(api.fm.updateCustomerInvoiceStatus, {}, {}, { invoice_id, payment_status, message, payment_date });
        if (response.data.code === 201) {
            let status = 'success';
            let message = response.data.message ? response.data.message : 'Invoice Payment Status Updated Successfully!';

            yield put({ type: toast.SEND_TOAST, payload: { status, message } });
            yield put({ type: fm.REVENUES_LIST_REQUEST, payload: {} });
        } else {
            let status = 'danger';
            let message = response.data.message ? response.data.message : 'Something went wrong!';
            yield put({ type: toast.SEND_TOAST, payload: { status, message } });
            yield put({ type: fm.UPDATE_CUSTOMER_INVOICE_STATUS_FAILURE, payload: response.data });
        }
    } catch (error) {
        yield put({ type: fm.UPDATE_CUSTOMER_INVOICE_STATUS_FAILURE, payload: error });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export default function* fmSaga() {
    yield takeLatest(fm.LIST_RECEIVABLES_GENERATE_INVOICE_REQUEST, listOfGenerateInvoiceProjects);
    yield takeLatest(fm.LIST_RECEIVABLES_PROJECT_TICKETS_REQUEST, listOfProjectTickets);
    yield takeLatest(fm.CB_GENERATE_INVOICE_REQUEST, cbGenerateInvoice);
    yield takeLatest(fm.REVENUES_LIST_REQUEST, listRevenuesList);
    yield takeLatest(fm.DOWNLOAD_CUSTOMER_INVOICE_REQUEST, downloadClientMonthlyInvoices);
    yield takeLatest(fm.UPDATE_CUSTOMER_INVOICE_STATUS_REQUEST, updateCustomerInvoiceStatus);
}
