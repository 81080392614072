import { whatsapp } from "../types";

export const updateWhatsappNumber = ({ userId, mobileNumber, verifyType, formName, navigate, openModal }) => ({
    type: whatsapp.UPDATE_WHATSAPP_NUMBER,
    payload: { userId, mobileNumber, verifyType, formName, navigate, openModal },
});

export const getWhatsappOr = (data) => ({
    type: whatsapp.GET_WHATSAPP_QR,
    payload: data,
});

export const getWhatsappChatList = (data) => ({
    type: whatsapp.GET_WHATSAPP_GROUP_LiST,
    payload: data,
});

export const forwardMessageToWhatsApp = (data) => ({
    type: whatsapp.FORWARD_MESSAGE_WHATSAPP,
    payload: data,
});

export const setDialpad = (data) => ({
    type: whatsapp.SHOW_DIALPAD,
    payload: data,
});