import React, { useEffect, useState, useCallback, forwardRef } from 'react';
import Checkbox from '../../../common/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerIdOfLoginUser } from '../../../utils/utils';
import DropdownMenu from '../../../common/MenuDropdown';
import {
    getAllWarehouses,
    getWarehouseFilterList,
    clearInventoryTableDataOnUnmount,
    setInventoryFilterAndPaginationData,
    getWarehouseFilteredData,
} from '../../../actions/inventory';
import { GetInventoryActions, InventoryTableDetailsFields } from '../InventoryController';
import IconToolTip from '../../../common/IconToolTip';
import OverflowTip from '../../../common/OverflowTip';
import { copyToClipboard, clearFilterModuleData, setClearFilterClickAction } from '../../../actions/utils';
import Menus from '../../../common/Menus';
import APIFilterPopup from '../../../common/APIFilters/FilterPopup';
import { clickableTextClass } from '../../../common/Css';
import { validateFilterNumber, readFilterQueryParams, transformQueryFilters } from '../../../utils/filter';
import { useQuery } from '../../../utils/common';
import VirtualizedTable from '../../../common/VirtualizedTable';

const TeamItemsClassName = 'origin-top-right absolute px-3 py-2  top-20 mt-2 w-auto z-10 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none';
const TeamItemClassName = 'bg-scogoorange block mt-1 text-white text-font11 px-p-6 py-p-3 rounded-3px';
const labelClass = ` border border-l-4 font-bold items-center    py-1 px-2 flex items-center justify-center`;

const TeamMenuButtonIcon = (count) => {
    return (
        <span className='text-font12 font-normal text-scogoorange cursor-pointer'>
            {''}
            {count}
        </span>
    );
};

const filterSchema = {
    customersFilter: {
        filterName: 'customers',
        title: 'Customer',
        typeOfFilter: 'checkbox',
        apiValueKey: 'customer_id',
        displayValueKey: 'customer_company_name',
        submitKey: 'customerId',
        queryParamKey: 'customerId',
        parse: ({ customerId }) => {
            let values = validateFilterNumber(customerId);
            if (values) return { customerId: values };
            return {};
        },
    },
    statesFilter: {
        filterName: 'states',
        title: 'State',
        typeOfFilter: 'checkbox',
        apiValueKey: 'state_id',
        displayValueKey: 'state_name',
        submitKey: 'state_id',
        queryParamKey: 'state_id',
        parse: ({ state_id }) => {
            let values = validateFilterNumber(state_id);
            if (values) return { state_id: values };
            return {};
        },
    },
    citiesFilter: {
        filterName: 'cities',
        title: 'City',
        typeOfFilter: 'checkbox',
        apiValueKey: 'city_id',
        displayValueKey: 'city_name',
        submitKey: 'city_id',
        queryParamKey: 'city_id',
        parse: ({ city_id }) => {
            let values = validateFilterNumber(city_id);
            if (values) return { city_id: values };
            return {};
        },
    },
    assetTypeFilter: {
        filterName: 'assets',
        title: 'Asset Type',
        typeOfFilter: 'checkbox',
        apiValueKey: 'id',
        displayValueKey: 'title',
        submitKey: 'title',
        queryParamKey: 'title',
        parse: ({ title }) => {
            let values = validateFilterNumber(title);
            if (values) return { title: values };
            return {};
        },
    },
};

export const WarehouseDetails = forwardRef(
    (
        {
            goToSpareAssetTab,
            setSelectedWarehouseName,
            closeInventorySideDrawer,
            inventoryRowsPerPage,
            inventoryPage,
            setInventoryRowsAndPage,
            defaultRowsPerPage,
            defaultPage,
            setClearFilterButton,
            limitQueryParam,
            pageQueryParam,
            searchQueryParam,
            queryParams,
            updateQueryFilters,
        },
        ref
    ) => {
        const dispatch = useDispatch();
        const { loggedUser } = useSelector((state) => state.auth);
        const customerId = getCustomerIdOfLoginUser(loggedUser);
        const isToShowCustomerCompanyNameInWarehouseTable = InventoryTableDetailsFields('customerCompanyNameInWarehouseTable');
        const isToShowCustomerFilter = GetInventoryActions('isToShowCustomerData');
        const { warehouses, warehouseCount, warehouseFilterData } = useSelector((state) => state.inventory);
        const { warehouseLoading, warehouseFilterLoading } = useSelector((state) => state.loading);

        const [inventoryWarehouse, setInventoryWarehouse] = useState(warehouses);
        const [checkedWarehouseTracker, setCheckedWarehouseTracker] = useState(() => new Set());
        const [filterList, setFilterList] = useState({});
        const [filterQueryObject, setFilterObject] = useState({});
        const [filtersActiveStatus, setFiltersActiveStatus] = useState({});

        const query = useQuery();

        const getAssetData = useCallback(
            ({ isFilterApplied, searchTerm, limit, page, payload }) => {
                if (!limit) limit = defaultRowsPerPage;
                if (!page) page = defaultPage;
                if (isFilterApplied || searchTerm?.length > 0) {
                    dispatch(getWarehouseFilteredData({ customerId: customerId, count: true, limit, page, query: searchTerm, payload }));
                } else {
                    dispatch(getAllWarehouses({ customerId, count: true, limit, page }));
                }
            },
            [customerId, defaultPage, defaultRowsPerPage, dispatch]
        );

        useEffect(() => {
            let { queryObject, filterStatus } = readFilterQueryParams(filterSchema, query);
            setFilterObject(queryObject);
            setFiltersActiveStatus(filterStatus);
            let isToShowFilterButton = false;
            Object.values(filterStatus).forEach((value) => (value ? (isToShowFilterButton = value) : false));
            setClearFilterButton(isToShowFilterButton);
            let filterPayload = transformQueryFilters(filterSchema, queryObject);
            getAssetData({
                searchTerm: searchQueryParam,
                limit: limitQueryParam,
                page: pageQueryParam,
                payload: filterPayload,
                isFilterApplied: isToShowFilterButton,
            });
            dispatch(
                setInventoryFilterAndPaginationData({
                    payload: filterPayload,
                    isFilterActive: isToShowFilterButton,
                    limit: limitQueryParam,
                    page: pageQueryParam,
                    query: searchQueryParam,
                })
            );
        }, [queryParams, searchQueryParam, limitQueryParam, pageQueryParam, getAssetData, setClearFilterButton, query, dispatch]);

        useEffect(() => {
            let filterPayload = { customerId };
            if (isToShowCustomerFilter) filterPayload = Object.assign(filterPayload, { toFilter: 'customers' });
            dispatch(getWarehouseFilterList(filterPayload));
            closeInventorySideDrawer();
            return () => {
                dispatch(clearFilterModuleData());
                dispatch(setClearFilterClickAction(() => { }));
                dispatch(clearInventoryTableDataOnUnmount());
            };
        }, [customerId, dispatch, isToShowCustomerFilter, closeInventorySideDrawer]);

        useEffect(() => {
            setInventoryWarehouse(warehouses);
        }, [warehouses]);

        const warehouseOnClickHandler = (warehouseId, warehouseName) => {
            goToSpareAssetTab(warehouseId);
            setSelectedWarehouseName(warehouseName);
        };

        const handleOpenLocation = (data) => {
            return window.open(`https://maps.google.com?q=${`${data?.latitude}, ${data?.longitude}`}`, '_blank');
        };

        const handleCopyAddress = (data) => {
            let addressToCopy = `${data?.address}, ${data?.pincode}, ${data?.city}`;
            dispatch(copyToClipboard({ data: addressToCopy }));
        };

        const warehouseData = inventoryWarehouse?.map((warehouse) => {
            return {
                id: warehouse.id,
                name: warehouse?.name,
                company: warehouse?.customer?.customer_company_name,
                customerName: warehouse?.customer?.customer_company_name,
                address: warehouse?.address,
                city: warehouse?.cityDetails?.city_name,
                state: warehouse?.stateDetails?.state_name,
                warehouseContactName: warehouse?.contacts?.[0]?.name,
                warehouseContactPhone: warehouse?.contacts?.[0]?.mobile,
                warehouseContactEmail: warehouse?.contacts?.[0]?.email,
                spareAssetStats: warehouse?.spareAssetStats,
                pincode: warehouse.pincode,
                city_id: warehouse?.cityDetails?.city_id,
                type: 'warehouse',
                requester_name: `${loggedUser?.first_name} ${loggedUser?.last_name}`,
                requester_mobile: loggedUser?.mobile,
                requester_email: loggedUser?.email,
                longitude: warehouse?.longitude,
                latitude: warehouse?.latitude,
                customer_id: warehouse?.customer_id,
                customer: warehouse?.customer,
                cityObj: warehouse?.cityDetails,
                fk_user_id: warehouse?.fk_user_id,
                spareAssetCount: warehouse?.spareAssetCount,
            };
        });

        const selectALLHandler = (checked) => {
            if (checked) {
                const allIndices = warehouseData.map((_, index) => index);
                setCheckedWarehouseTracker(new Set(allIndices));
            } else {
                setCheckedWarehouseTracker(new Set());
            }
        };

        const filterComponent = ({ headName, keysToFilter, typeOfFilter, placement }) => (
            <div className='flex'>
                {headName}
                <APIFilterPopup
                    filterList={filterList}
                    setFilterList={setFilterList}
                    height={15}
                    keysToFilter={keysToFilter}
                    submit={({ payload, page, filterList }) => updateQueryFilters({ filterSchema, payload, page, filterList })}
                    typeOfFilter={typeOfFilter}
                    placement={placement}
                    filtersData={warehouseFilterData}
                    filterSchema={filterSchema}
                    isLoading={warehouseFilterLoading}
                    setPayloadAndFilterStatus={setInventoryFilterAndPaginationData}
                    filterQueryObject={filterQueryObject}
                    filtersActiveStatus={filtersActiveStatus}
                />
            </div>
        );

        const handleOnChangeCheckbox = (position) => {
            if (checkedWarehouseTracker.has(position)) {
                setCheckedWarehouseTracker((prev) => {
                    const next = new Set(prev);
                    next.delete(position);
                    return next;
                });
            } else {
                setCheckedWarehouseTracker((prev) => new Set(prev).add(position));
            }
        };

        useEffect(() => {
            setCheckedWarehouseTracker(new Set());
        }, [warehouseLoading]);

        const warehouseTableSchema = {
            table: [
                {
                    head: {
                        render: (headName) => {
                            return (
                                <div className='flex items-center'>
                                    <Checkbox
                                        checkColor='text-scogoorange mr-2 mt-1'
                                        checked={warehouseData?.length > 0 && checkedWarehouseTracker?.size > 0 && warehouseData?.length === checkedWarehouseTracker?.size}
                                        onChange={(event) => selectALLHandler(event)}
                                        dynamicSize={'1.2rem'}
                                    />
                                    {isToShowCustomerFilter
                                        ? filterComponent({
                                            headName,
                                            keysToFilter: [filterSchema.customersFilter],
                                        })
                                        : headName}
                                </div>
                            );
                        },
                        headName: 'Name',
                        width: 1.5,
                    },
                    body: {
                        render: (item, rowIndex) => {
                            return (
                                <div className='flex items-start'>
                                    <Checkbox
                                        checkColor='text-scogoorange mr-2'
                                        checked={checkedWarehouseTracker.has(rowIndex)}
                                        onChange={(event) => handleOnChangeCheckbox(rowIndex)}
                                        dynamicSize={'1.2rem'}
                                    />
                                    <div
                                        className='items-center cursor-pointer w-11/12'
                                        onClick={() => {
                                            warehouseOnClickHandler(item?.id, item?.name);
                                        }}
                                    >
                                        <p className={`${clickableTextClass} font-bold truncate`}>
                                            <OverflowTip someLongText={item?.name} />
                                        </p>
                                        {isToShowCustomerCompanyNameInWarehouseTable ? (
                                            <p className='w-12/12 truncate'>
                                                <IconToolTip placement={'bottom'} title={`Customer: ${item?.company}`}>
                                                    <span>{item?.company}</span>
                                                </IconToolTip>
                                            </p>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>
                            );
                        },
                    },
                },
                {
                    head: {
                        render: (headName) => {
                            return filterComponent({
                                headName,
                                keysToFilter: [filterSchema.citiesFilter, filterSchema.statesFilter],
                            });
                        },
                        headName: 'Address',
                        columnRowCss: 'break-words',
                        width: 1.5,
                    },
                    body: {
                        render: (item) => {
                            const isAddressAvailable = item?.state || item?.city || item?.address || item?.pincode;
                            return (
                                <div className='w-11/12 break-words'>
                                    {item?.address && (
                                        <IconToolTip placement={'bottom'} title={`${item?.address}`}>
                                            <span className='break-all w-11/12 line-clamp-3'>{item?.address},</span>
                                        </IconToolTip>
                                    )}
                                    <span>
                                        {item?.city && <span> {item?.city},</span>}
                                        <span>{item?.state},</span>
                                        <span>{item?.pincode}</span>
                                    </span>
                                    {isAddressAvailable && (
                                        <>
                                            <IconToolTip placement={'bottom'} title={`Open Location`}>
                                                <span className='cursor-pointer  material-icons text-scogobgsky text-lg' onClick={() => handleOpenLocation(item)}>
                                                    {' '}
                                                    place{' '}
                                                </span>
                                            </IconToolTip>
                                            <IconToolTip placement={'bottom'} title={`Copy Address`}>
                                                <span className='cursor-pointer material-icons text-scogobgsky text-lg' onClick={() => handleCopyAddress(item)}>
                                                    {' '}
                                                    content_copy{' '}
                                                </span>
                                            </IconToolTip>
                                        </>
                                    )}
                                </div>
                            );
                        },
                    },
                },
                {
                    head: {
                        headName: 'Contact',
                        render: (headName) => headName,
                        width: 1.5,
                    },
                    body: {
                        render: (item) => {
                            return (
                                <div className='w-full'>
                                    <p className='font-bold text-scogoprimary w-full'>
                                        <OverflowTip someLongText={item?.warehouseContactName} />
                                    </p>
                                    <p className='text-scogo99'>
                                        <OverflowTip someLongText={item?.warehouseContactPhone} />
                                    </p>
                                    <p className='text-scogo99'>
                                        <OverflowTip someLongText={item?.warehouseContactEmail} />
                                    </p>
                                </div>
                            );
                        },
                    },
                },
                {
                    head: {
                        render: (headName) => {
                            return filterComponent({
                                headName,
                                keysToFilter: [filterSchema.assetTypeFilter],
                            });
                        },
                        headName: 'Assets',
                        columnRowCss: 'break-words',
                        width: 2,
                    },
                    body: {
                        render: (item) => {
                            return <GetAssetsList assetStats={item?.spareAssetStats} />;
                        },
                    },
                },
                {
                    head: {
                        headName: 'Actions',
                        render: (headName) => headName,
                        width: 0.4,
                    },
                    body: {
                        render: (item) => {
                            return (
                                <div className='flex justify-center'>
                                    <WarehouseActionColumn item={item} />
                                </div>
                            );
                        },
                    },
                },
            ],
        };

        return (
            <VirtualizedTable
                rowHeight={100}
                schema={warehouseTableSchema}
                rowData={warehouseData}
                isLoading={warehouseLoading}
                rowsPerPage={inventoryRowsPerPage}
                tablePagination
                tablePaginationHandler={(limit, page) => updateQueryFilters({ limit, page, filterSchema, filterList })}
                count={warehouseCount}
                setRowsPerPage={setInventoryRowsAndPage}
                tableError={'No Warehouse Found'}
                page={inventoryPage}
            />
        );
    }
);

export default WarehouseDetails;


export const GetAssetsList = ({ assetStats, isSite }) => {


    return (
        <>
            <div className='relative'>
                {Array.isArray(assetStats) && assetStats?.length > 0 && (
                    <>
                        {assetStats?.slice(0, 2).map((elem) => {
                            return (
                                <p className='text-scogoprimary text-font11 flex pb-1 w-full'>
                                    <div className='mr-2 truncate w-3/6'>
                                        <OverflowTip someLongText={elem.type} />
                                    </div>
                                    {(elem.green === 0 && isSite) ? <></> : <IconToolTip title={`Available: ${elem.green}`}>
                                        <div className={`${labelClass} text-scogo2e border-scogo2e mr-2 bg-slate-50 rounded-sm`}>{elem.green}</div>
                                    </IconToolTip>}
                                    {(elem.orange === 0 && isSite) ? <></> : <IconToolTip title={`Transit: ${elem.orange}`}>
                                        <div className={`${labelClass} text-scogoorange border-scogoorange  mr-3 bg-slate-50`}>{elem.orange}</div>
                                    </IconToolTip>}
                                    {(elem.blue === 0 && isSite) ? <></> : <IconToolTip title={`Delivered: ${elem.blue}`}>
                                        <div className={`${labelClass} text-scogoprimary border-scogoprimary mr-3 bg-slate-50`}>{elem.blue}</div>
                                    </IconToolTip>}
                                </p>
                            );
                        })}

                        {assetStats.length > 2 && (
                            <Menus
                                position='ml-auto'
                                activeStatus={false}
                                menuButtonIcon={TeamMenuButtonIcon(`View all`)}
                                itemsClassName={TeamItemsClassName}
                                itemLable={assetStats?.map((elem) => `${elem.type} ${elem.green} ${elem.orange} ${elem.blue}`)}
                                itemClassName={TeamItemClassName}
                                activeStatusClass='bg-scogoclosed'
                                textColor={'text-white hover:text-black'}
                            />
                        )}
                    </>
                )}
            </div>
        </>
    );
};


const WarehouseActionColumn = (props) => {
    const { item: warehouse } = props;
    let warehouseTableActions = GetInventoryActions('warehouseTableActions', { warehouse });
    const { loggedUser } = useSelector((state) => state.auth);
    const role = loggedUser?.role?.id;
    const type = loggedUser?.type;
    const customerId = getCustomerIdOfLoginUser(loggedUser);

    return (
        <DropdownMenu
            menuData={warehouseTableActions}
            componentOrIcon={'more_vert'}
            item={warehouse}
            iconColor={'text-scogoprimary'}
            payload={{ customerId, userId: loggedUser?.id, role, type }}
        />
    );
};