import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createInventoryAssets, getAllSitesDropdown, removeAddedAssetTypeFromStore, removeAssetFromStore, removeSiteDataByIdFromStore } from '../../../../actions/inventory';
import { getBOQList } from '../../../../actions/tickets';
import { sendToastMessage } from '../../../../actions/toast';
import { getCustomerIdOfLoginUser } from '../../../../utils/utils';
import { getSuperDeskCapexTypes } from '../../../../actions/superdesk';
import moment from 'moment';
import { actualValueForDropdown } from '../../../../utils/common';
import Form from '../../../../common/Form';
import { Details } from './Details';
import { AddAssetInputs } from './AddAssetInputs';
import { useForm } from 'react-hook-form';
import { useCallback } from 'react';

export const warrantyType = [
    { key: 1, value: 'Manufacturing', label: 'Manufacturing' },
    { key: 2, value: 'Installation', label: 'Installation' },
    { key: 3, value: 'Billing', label: 'Billing' },
];
export const maintenanceSchedule = [
    { key: 1, value: 'Monthly', label: 'Monthly' },
    { key: 2, value: 'Quarterly', label: 'Quarterly' },
    { key: 3, value: 'Half Yearly', label: 'Half Yearly' },
    { key: 4, value: 'Yearly', label: 'Yearly' },
];

const formName = 'assetsForm';

export default function AssetsForm(props) {
    const dispatch = useDispatch();
    const { closeForm } = props;
    const { loggedUser } = useSelector((state) => state.auth);
    const customerId = getCustomerIdOfLoginUser(loggedUser);
    const siteId = props?.selectedSiteId;
    const { siteDataById } = useSelector((state) => state.inventory);
    const { formButtonLoading } = useSelector((state) => state.loading);
    const { setInitialForm } = useSelector((state) => state.utils);

    const defaultValues = {
        fk_site_id: siteId,
        additional_spoc_name: '',
        additional_spoc_email: '',
        additional_spoc_phone: '',
        asset_details: '',
        asset_type_id: '',
        end_user_id: '',
        externel_order_id: '',
        hasOem: false,
        hasWarranty: false,
        make: '',
        model: '',
        partcode: '',
        po_amount: '',
        preventive_maintenance_schedule: '',
        same_site_owner: true,
        serial_number: '',
        service_support: '',
        type: '',
        warranty_duration: '',
        warranty_end_date: '',
        warranty_start_date: '',
        warranty_type: '',
        capex_type: '',
        customer_item_name: '',
        capex_date: undefined,
        bill_date: undefined,
        po_number: '',
        per_item_value: '',
        vendor_supplier_name: '',
        hasSameOwner: true,
    };

    const callDataOnMount = useCallback(() => {
        dispatch(getSuperDeskCapexTypes({ customerId }));
        dispatch(getBOQList());
        if (!siteId) dispatch(getAllSitesDropdown({ customerId }));
    }, [dispatch, siteId, customerId]);

    useEffect(() => {
        callDataOnMount();
        return () => dispatch(removeSiteDataByIdFromStore());
    }, [callDataOnMount, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(removeAddedAssetTypeFromStore());
            dispatch(removeAssetFromStore());
        }
    }, [dispatch])

    const submit = (formValues) => {
        const formCustomerId = siteDataById?.fk_customer_id;
        const fk_site_id = actualValueForDropdown(formValues.fk_site_id);
        const spocDetails = {
            name: formValues.additional_spoc_name,
            email: formValues.additional_spoc_email,
            phone: formValues.additional_spoc_phone,
        };
        let data = {
            assets: [
                {
                    additional_spoc: [spocDetails],
                    asset_type_id: actualValueForDropdown(formValues.asset_type_id),
                    customer_item_name: formValues.customer_item_name,
                    capex_date: formValues.capex_date ? moment(formValues.capex_date).format('YYYY-MM-DD') : '',
                    capex_type: actualValueForDropdown(formValues.capex_type),
                    externel_order_id: formValues.externel_order_id,
                    hasOem: formValues.hasOem,
                    hasWarranty: formValues.hasWarranty,
                    make: formValues.make,
                    model: formValues.model,
                    partcode: formValues.partcode,
                    po_amount: formValues.po_amount,
                    preventive_maintenance_schedule: actualValueForDropdown(formValues.preventive_maintenance_schedule),
                    same_site_owner: formValues.hasSameOwner,
                    serial_number: formValues.serial_number,
                    service_support: formValues.service_support,
                    type: formValues.type,
                    warranty_end_date: formValues.warranty_end_date ? moment(formValues.warranty_end_date).format('YYYY-MM-DD') : '',
                    warranty_start_date: formValues.warranty_start_date ? moment(formValues.warranty_start_date).format('YYYY-MM-DD') : '',
                    warranty_type: actualValueForDropdown(formValues.warranty_type),
                    bill_date: formValues.bill_date ? moment(formValues.bill_date).format('YYYY-MM-DD') : '',
                    po_number: formValues.po_number,
                    per_item_value: formValues.per_item_value,
                    vendor_supplier_name: formValues.vendor_supplier_name,
                    end_user_id: siteDataById.customer_end_user,
                    fk_site_id,
                },
            ],
        };

        const hasCustomerId = Number.isInteger(formCustomerId) && formCustomerId > 0;
        const hasSiteId = Number.isInteger(fk_site_id) && fk_site_id > 0;

        if (hasCustomerId && hasSiteId) {
            dispatch(createInventoryAssets({ customerId: formCustomerId, data, siteId, formName }));
        } else dispatch(sendToastMessage({ status: 'danger', message: 'Invalid Data' }));
    };

    const methods = useForm({ defaultValues, mode: 'all' });
    const { reset } = methods;

    useEffect(() => {
        if (setInitialForm) {
            reset();
            dispatch(removeAddedAssetTypeFromStore());
        }
    }, [setInitialForm, reset, dispatch])

    return (
        <Form
            className='w-full'
            onSubmit={submit}
            onCancel={closeForm}
            defaultValues={defaultValues}
            submitButtonText={'Create'}
            showSubmit
            submitContainerClassName='flex gap-2 py-4 px-4'
            isLoading={formButtonLoading[formName]}
            methods={methods}
        >
            {siteId && siteDataById && <Details site={siteDataById} />}
            <AddAssetInputs siteId={siteId} />
        </Form>
    );
}
