import React from 'react';
import { useDispatch } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import { useSelector } from 'react-redux';
import { textCss } from '../../../common/Css';
import { validateStringValue } from '../../../utils/common';
import OverflowTip from '../../../common/OverflowTip';
import IconToolTip from '../../../common/IconToolTip';
import ButtonScogoPrimary from '../../../common/Buttons/ButtonScogoPrimary';
import ButtonScogoClosedOutlined from '../../../common/Buttons/ButtonScogoClosedOutlined';
import { verifyUserAddress } from '../../../actions/users';

const formName = 'verify_user';

export default function VerifyUserModal({ user, role, customer, userType, fk_cluster_id }) {
    const dispatch = useDispatch();
    const { formButtonLoading } = useSelector((state) => state.loading);

    const submit = (e, status) => {
        e.preventDefault()
        const toAccept = status === 'accept' ? true : false
        dispatch(verifyUserAddress({ userId: user.id, toAccept, formName: `${formName}_${status}`, role, customerName: customer, userType, fk_cluster_id }))
    };


    return (
        <div>
            <UserDetail user={user} />
            <div className='flex gap-2 justify-end py-4 px-4' >
                <ButtonScogoPrimary disabled={!user?.address_proof_number || formButtonLoading[`${formName}_accept`]} textOrComponent={'Verify'} loading={formButtonLoading[`${formName}_accept`]} onClick={(e) => submit(e, 'accept')} />
                <ButtonScogoClosedOutlined disabled={!user?.address_proof_number || formButtonLoading[`${formName}_accept`]} textOrComponent='Reject' loading={formButtonLoading[`${formName}_reject`]} onClick={(e) => submit(e, 'reject')} />
                <ButtonScogoClosedOutlined textOrComponent='Cancel' onClick={() => dispatch(closeModalAction())} />
            </div>

        </div>
    );
}


const UserDetail = ({ user }) => {
    const userName = `${validateStringValue(user?.first_name)} ${validateStringValue(user?.last_name)}`
    const spName = validateStringValue(user?.service_partner?.sp_name)
    const mobile = validateStringValue(user?.mobile)
    const email = validateStringValue(user?.email)
    const address_proof_number = validateStringValue(user?.address_proof_number)
    const role = [7, 12, 13].indexOf(user?.role) !== -1 ? 'Partner' : user?.role === 9 ? 'CP' : ''

    return <div className='flex w-full px-4 py-2 bg-scogolightblue'>
        <div className='bg-white relative mb-4 border-gray-200 shadow-lg w-full py-2 rounded-lg px-4'>
            <div className='ribbon w-3/4'>
                <span
                    className='ribbon3 bg-scogobgsky ribbonblue'
                    style={{
                        left: '-19px',
                    }}
                >
                    User Details
                </span>
            </div>
            <hr />
            <div className='grid grid-cols-3 rows-fr grid-flow-row-dense gap-2 py-2'>
                {userName.trim() && <span>
                    <h3 className={textCss?.detailsTitle}>Name</h3>
                    <OverflowTip textClass={textCss?.detailsValue} someLongText={userName} />
                </span>}
                {spName && <span>
                    <h3 className={textCss?.detailsTitle}>Company Name</h3>
                    <OverflowTip textClass={textCss?.detailsValue} someLongText={spName} />
                </span>}
                {role && <span>
                    <h3 className={textCss?.detailsTitle}>Role</h3>
                    <OverflowTip textClass={textCss?.detailsValue} someLongText={role} />
                </span>}
                {email && <span>
                    <h3 className={textCss?.detailsTitle}>Email</h3>
                    <OverflowTip textClass={textCss?.detailsValue} someLongText={email} />
                </span>}
                {mobile && <span>
                    <h3 className={textCss?.detailsTitle}>Mobile</h3>
                    <OverflowTip textClass={textCss?.detailsValue} someLongText={mobile} />
                </span>}
                {address_proof_number && <span className=' flex flex-col'>
                    <h3 className={textCss?.detailsTitle}>Address Proof</h3>
                    <span className={textCss?.detailsValue + ' pr-2'}>{address_proof_number}</span>
                    <IconToolTip title='ID Card'>
                        <span className='hover:text-scogoorange cursor-pointer text-scogobgsky  font-medium' onClick={() => window.open(user.address_proof_url, '_blank')}>
                            View Front Image
                        </span>
                    </IconToolTip>
                    {user?.address_proof_url_back && <IconToolTip title='ID Card'>
                        <span className='hover:text-scogoorange cursor-pointer text-scogobgsky  font-medium' onClick={() => window.open(user.address_proof_url_back, '_blank')}>
                            View Back Image
                        </span>
                    </IconToolTip>}
                </span>}
                {!address_proof_number && <span>
                    <h3 className={textCss?.detailsTitle}>Address Proof</h3>
                    <span className={textCss?.detailsValue + ' text-scogoclosed pr-2'}>Address Proof Not Updated</span>
                </span>}
            </div>
        </div>
    </div >
}