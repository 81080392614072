import { PoTab, payoutsTab } from '../pages/Payments/PaymentController';
import { ticket } from '../router/roleWisePageRoute';
import { payments, tickets } from '../types';

export const getPaymentsList = ({ customerId, limit, page, fromDate, toDate }) => ({
    type: payments.GET_ALL_PAYMENTS_REQUEST,
    payload: { customerId, limit, page, fromDate, toDate },
});

export const getPaymentPos = ({ customerId, limit, page, count }) => ({
    type: payments.GET_PO_LIST_REQUEST,
    payload: { customerId, limit, page, count },
});

export const cancelPoRequest = ({ data, ticketId, formName, isRefresh, enduserId }) => ({
    type: payments.CANCEL_PO_REQUEST,
    payload: { data, ticketId, formName, isRefresh, enduserId },
});

export const deletePoRequest = ({ ticketIds, formName, isRefresh, enduserId }) => ({
    type: payments.DELETE_PO_REQUEST,
    payload: { ticketIds, formName, isRefresh, enduserId },
});

export const updatePoInvoiceRequest = ({ data, ticketId, formName, files, isRefresh, enduserId }) => ({
    type: payments.UPDATE_PO_INVOICE_REQUEST,
    payload: { data, ticketId, formName, files, isRefresh, enduserId },
});

export const updatePo = ({ data, ticketId, formName, isRefresh, enduserId }) => ({
    type: payments.UPDATE_PO_REQUEST,
    payload: { data, ticketId, formName, isRefresh, enduserId },
});

export const getOpenTicketPaymentList = ({ customerId, limit, page, fromDate, toDate }) => ({
    type: payments.GET_OPEN_TICKET_PAYMENTS_REQUEST,
    payload: { customerId, limit, page, fromDate, toDate },
});

export const getAllPayoutsFilterList = ({ toFilter }) => ({
    type: payments.GET_ALL_PAYOUTS_FILTERLIST_REQUEST,
    payload: { toFilter },
});

export const getAllRewardsAndPenaltiesFilterList = ({ toFilter }) => ({
    type: payments.GET_ALL_REWARDS_AND_PENALTIES_FILTERLIST_REQUEST,
    payload: { toFilter },
});

export const getAllPOFilterList = ({ toFilter }) => ({
    type: payments.GET_ALL_PO_FILTERLIST_REQUEST,
    payload: { toFilter },
});

export const getAllWithdrwalFilterList = ({ toFilter }) => ({
    type: payments.GET_ALL_WITHDRAWAL_FILTERLIST_REQUEST,
    payload: { toFilter },
});

export const getWalletWithdrawlTransactions = ({ transactionType, customerId, allusers, limit, page = 1, count, status }) => ({
    type: payments.GET_WALLET_WITHDRAWAL_TRANSACTIONS_REQUEST,
    payload: { transactionType, customerId, status, allusers, limit, page, count },
});

export const getWalletRewardsTransactions = ({ transactionType, customerId, allusers, limit, page, count, status }) => ({
    type: payments.GET_WALLET_REWARDS_TRANSACTIONS_REQUEST,
    payload: { transactionType, customerId, status, allusers, limit, page, count },
});

export const payPoTax = ({ formName, ticketId, withdrawAmount, vendorId, isRefresh, type, banking_partner, updateConvo, enduserId }) => ({
    type: payments.PAY_PO_TAX_REQUEST,
    payload: { formName, ticketId, withdrawAmount, vendorId, isRefresh, type, banking_partner, updateConvo, enduserId },
});

export const generateInvoice = ({ data, formName, files, isRefresh, enduserId }) => ({
    type: payments.GENERATE_INVOICE_REQUEST,
    payload: { data, formName, files, isRefresh, enduserId },
});

export const setPaymentSideDrawer = (showPaymentSideDrawer, sideBarName, paymentSideDrawerPayload) => ({
    type: payments.SET_PAYMENTS_SIDEDRAWER,
    payload: { showPaymentSideDrawer, sideBarName, paymentSideDrawerPayload },
});

export const getPayoutsFilterData = ({ customerId, limit, page, query, payload }) => ({
    type: payments.GET_PAYOUTS_FILTER_DATA_REQUEST,
    payload: { customerId, limit, page, query, payload },
});

export const getOpenTicketFilterData = ({ customerId, limit, page, query, payload }) => ({
    type: payments.GET_OPEN_TICKET_FILTER_DATA_REQUEST,
    payload: { customerId, limit, page, query, payload },
});

export const getWithdrawlFilterData = ({ customerId, limit, page, query, payload }) => ({
    type: payments.GET_WITHDRAWAL_FILTER_DATA_REQUEST,
    payload: { customerId, limit, page, query, payload },
});

export const getRewardsFilterData = ({ customerId, limit, page, query, payload }) => ({
    type: payments.GET_REWARDS_FILTER_DATA_REQUEST,
    payload: { customerId, limit, page, query, payload },
});

export const getPoFilterData = ({ customerId, limit, page, query, payload, source, enduserId }) => ({
    type: payments.GET_PO_FILTER_DATA_REQUEST,
    payload: { customerId, limit, page, query, payload, source, enduserId },
});

export const clearPaymentTabDataOnUnmount = () => ({
    type: payments.CLEAR_PAYEMNT_TABS_DATA_ON_UNMOUNT,
});

export const setPaymentFilterAndPaginationData = ({ payload, isFilterActive, page, limit, query, tab }) => ({
    type: payments.SET_PAYMENT_FILTER_AND_PAGINATION_DATA,
    payload: { payload, isFilterActive, page, limit, query, tab },
});

export const refreshPage = ({ tab, enduserId }) => {
    if (window.location.pathname === ticket.path) {
        return { type: tickets.REFRESH_TICKET_TABLE_REQUEST };
    }
    if (tab === PoTab.tabID) {
        return { type: payments.REFRESH_PO_PAGE, payload: { enduserId } };
    }
    if (tab === payoutsTab.tabID) {
        return { type: payments.REFRESH_PAYOUTS_PAGE, payload: {} };
    }
    return { type: payments.NOT_USABLE_TYPE };
};

export const getPoAssetsCategoriesDropdown = () => ({
    type: payments.GET_PO_ASSET_CATEGORIES_DROPDOWN_REQUEST,
});

export const getTicketPODetails = (ticketId) => ({
    type: payments.GET_TICKET_PO_DETAILS_REQUEST,
    payload: { ticketId },
});

export const getPOCategories = () => ({
    type: payments.GET_PO_CATEGORIES_REQUEST,
});

export const setExportCsvPaymentsFunctionInStore = (action) => ({
    type: payments.SET_EXPORT_CSV_PO_FUNCTION_IN_STORE,
    payload: action,
});

export const exportCsvPayments = ({ module, tab, type, status, emails, formName, customerId }) => ({
    type: payments.EXPORT_CSV_PAYMENT_REQUEST,
    payload: { module, tab, type, status, emails, formName, customerId },
});

export const downloadZipPo = ({ customerId, emails, formName }) => ({
    type: payments.DOWNLOAD_ZIP_PO_REQUEST,
    payload: { emails, formName, customerId },
});

export const setDownloadZipPoFunctionInStore = (action) => ({
    type: payments.SET_DOWNLOAD_ZIP_PO_FUNCTION_IN_STORE,
    payload: action,
});

export const getPoGstDetails = ({ ticketId, incentive, user_type }) => ({
    type: payments.GET_PO_GST_DETAILS_REQUEST,
    payload: { ticketId, incentive, user_type },
});

export const clearPoGstDetailsFromStore = () => ({
    type: payments.CLEAR_PO_GST_DETAILS_FROM_STORE,
});