import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editTeam } from '../../../actions/superdesk';
import { closeModalAction } from '../../../actions/modal';
import { useForm } from 'react-hook-form';
import { validateStringValue } from '../../../utils/common';
import Form from '../../../common/Form';
import Input from '../../../common/Form/Input';

const formName = 'editTeamDetailsForm';
export default function EditTeamDetails(props) {
    const { teamDetails, customerId } = props;
    const { formButtonLoading } = useSelector((state) => state.loading);
    const dispatch = useDispatch();

    const defaultValues = {
        name: validateStringValue(teamDetails.name),
    };

    const submit = (form) => {
        let payload = {
            data: form,
            customerId,
            teamId: teamDetails.id,
            formName,
        };
        dispatch(editTeam(payload));
    };

    const methods = useForm({ defaultValues, mode: 'all' });

    return (
        <Form
            methods={methods}
            onSubmit={submit}
            submitButtonText={'Submit'}
            onCancel={() => dispatch(closeModalAction())}
            showSubmit
            submitContainerClassName='flex gap-2 justify-end py-4 px-4'
            isLoading={formButtonLoading[formName]}
        >
            <div className='px-3 flex justify-between'>
                <Input className='w-full' label='Name' name='name' type={'text'} />
            </div>
        </Form>
    );
}
