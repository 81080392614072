import _ from 'lodash';
import { useFormContext } from 'react-hook-form';

const Checkbox = ({
    id,
    name,
    label,
    required = false,
    className = 'p-2 inline-flex items-center',
    value,
    shouldUnregister,
    labelClassName = 'text-scogo15 font-normal cursor-pointer text-font09 md:text-font11',
    checkboxClass = `w-5 h-5 text-scogoorange mr-2 rounded-3px cursor-pointer border-2 border-scogo88 focus:outline-none focus:ring-0`,
    onChange = () => { },
}) => {
    const {
        register,
        formState: { errors },
    } = useFormContext();

    return (
        <label
            className={className}
            style={{
                minHeight: 0,
                minWidth: 0,
            }}
        >
            <div className='inline-flex items-center'>
                <input
                    id={id}
                    key={name}
                    type='checkbox'
                    className={checkboxClass}
                    value={value}
                    {...register(name, {
                        required: { value: required, message: 'Required' },
                        shouldUnregister,
                        onChange,
                    })}
                />
                {label && (
                    <label htmlFor={id} className={labelClassName}>
                        {label}
                    </label>
                )}
            </div>
            <p className='text-scogoclosed text-font08 font-normal inputFontSize h-6'>{_.get(errors, name) ? _.get(errors, name).message : ''}</p>
        </label>
    );
};

export default Checkbox;
