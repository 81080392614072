import { toast as sendToast } from '../types';
import { toast } from 'react-hot-toast';

const INITIAL_STATE = {
    error: undefined,
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case sendToast.SEND_TOAST:
            toast(action.payload.message, { className: action.payload.status })
            return {
                ...state,
                toasts: action.payload
            }
        default:
            return {
                ...state
            }
    }
}