import Popover from '@mui/material/Popover';
import IconToolTip from '../../../common/IconToolTip';
import MessageIcon from '@mui/icons-material/Message';
import { useState } from 'react';
import RenderChatMessages from '../../chat/ChatMessage';
import { useSelector } from 'react-redux';

export const ChatPopOver = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { openedConversationId } = useSelector((state) => state.conversations);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <IconToolTip title="Participants">
                <div className='h-full flex items-center' onClick={handleClick}>
                    <MessageIcon sx={{ fontSize: 28 }} className='hover:cursor-pointer hover:text-gray-600 transition-all' />
                </div>
            </IconToolTip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <div className='h-[70vh] w-[100vw]'>
                    <RenderChatMessages mainDivClass={`h-full w-[92%]`} showChatHeader={false} openedConversationId={openedConversationId} />
                </div>
            </Popover>
        </>
    )
}
