import React, { useEffect, useMemo } from 'react';
import Form from '../../../common/Form';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import Select from '../../../common/Form/Select';
import Radio from '../../../common/Form/Radio';
import Input from '../../../common/Form/Input';
import { addSlaInProject, getProjectSla } from '../../../actions/project';
import moment from 'moment';

const formName = 'add_sla';

const enable = { value: 'enable', name: 'sla_status', label: 'Enable' }
const disable = { value: 'disable', name: 'sla_status', label: 'Disable' }
const slaOptions = [enable, disable];

export default function AddSla({ projectId }) {
    const dispatch = useDispatch();
    const { formButtonLoading } = useSelector((state) => state.loading);
    const { projectsSlas } = useSelector((state) => state.project);

    useEffect(() => {
        if (!projectsSlas[projectId]) {
            dispatch(getProjectSla({ projectId }));
        }
    }, [dispatch, projectId]);

    const defaultValues = {
        sla: slaOptions[0].value,
        sla_type: 'CITY',
    };

    const methods = useForm({ defaultValues, mode: 'all' });
    const { watch, setValue } = methods;

    useEffect(() => {
        const sla = projectsSlas[projectId]
        if (sla) {
            const { is_applicable, tier_1, tier_2, tier_3, tier_4, sla_type, wh_end_time, wh_start_time } = sla
            setValue('sla', is_applicable ? enable.value : disable.value);
            setValue('sla_type', sla_type)
            setValue('wh_end_time', timeSlots.find(({ continentalFormat }) => {
                return continentalFormat === addHoursToTime(wh_end_time, 5.5).trim();
            }))
            setValue('wh_start_time', timeSlots.find(({ continentalFormat }) => {
                return continentalFormat === addHoursToTime(wh_start_time, 5.5).trim();
            }))
            setValue('tier_1', tier_1)
            setValue('tier_2', tier_2)
            setValue('tier_3', tier_3)
            setValue('tier_4', tier_4)
        }
    }, [projectsSlas, projectId, setValue])

    const slaValue = watch('sla');


    const submit = (formValues) => {
        let { wh_end_time, wh_start_time, tier_1, tier_2, tier_3, tier_4, sla, sla_type } = formValues;
        let today = moment();
        let wh_start_time_hours = new Date(moment(today.format('YYYY-MM-DD') + ' ' + wh_start_time.value, 'YYYY-MM-DD h:mm A').subtract({ hours: 5.5 })).getHours().toString().padStart(2, '0');
        let wh_start_time_min = new Date(moment(today.format('YYYY-MM-DD') + ' ' + wh_start_time.value, 'YYYY-MM-DD h:mm A').subtract({ hours: 5.5 })).getMinutes().toString().padStart(2, '0');
        let wh_end_time_hours = new Date(moment(today.format('YYYY-MM-DD') + ' ' + wh_end_time.value, 'YYYY-MM-DD h:mm A').subtract({ hours: 5.5 })).getHours().toString().padStart(2, '0');
        let wh_end_time_min = new Date(moment(today.format('YYYY-MM-DD') + ' ' + wh_end_time.value, 'YYYY-MM-DD h:mm A').subtract({ hours: 5.5 })).getMinutes().toString().padStart(2, '0');
        const payload = { wh_end_time: `${wh_end_time_hours}.${wh_end_time_min}`, wh_start_time: `${wh_start_time_hours}.${wh_start_time_min}`, sla_type, tier_1, tier_2, tier_3, tier_4, is_applicable: sla === 'enable', formName, projectId };
        dispatch(addSlaInProject(payload))
    }

    return <Form
        methods={methods}
        onSubmit={submit}
        submitButtonText={'Submit'}
        onCancel={() => dispatch(closeModalAction())}
        showSubmit
        submitContainerClassName='flex gap-2 justify-end py-4 px-4'
        isLoading={formButtonLoading[formName]}
    >
        <div className='px-3'>
            <Radio className='flex flex-row gap-2 items-center' name='sla' label='SLA' id='sla' options={slaOptions} />
        </div>
        {slaValue === enable.value && <div className='px-3'>
            <div className='grid grid-cols-4 rows-fr grid-flow-row-dense '>
                <Input label='T1' name='tier_1' required type='number' />
                <Input label='T2' name='tier_2' required type='number' />
                <Input label='T3' name='tier_3' required type='number' />
                <Input label='T4' name='tier_4' required type='number' />
            </div>
            <p className={'font-normal text-font09 md:text-font11 px-1 pt-2 pb-1'}>Working Hour</p>
            <div className='grid grid-cols-2 rows-fr grid-flow-row-dense '>
                <Select required isLoading={false} label='Start TIme' name='wh_start_time' options={timeSlots} />
                <Select required isLoading={false} label='End TIme' name='wh_end_time' options={timeSlots} />
            </div>
        </div>}
    </Form>
}



const timeSlots = [
    {
        "key": 0,
        "label": "08:00 AM",
        "value": "08:00 AM",
        "continentalFormat": "08.00"
    },
    {
        "key": 2,
        "label": "09:00 AM",
        "value": "09:00 AM",
        "continentalFormat": "09.00"
    },
    {
        "key": 4,
        "label": "10:00 AM",
        "value": "10:00 AM",
        "continentalFormat": "10.00"
    },
    {
        "key": 6,
        "label": "11:00 AM",
        "value": "11:00 AM",
        "continentalFormat": "11.00"
    },
    {
        "key": 8,
        "label": "12:00 PM",
        "value": "12:00 PM",
        "continentalFormat": "12.00"
    },
    {
        "key": 10,
        "label": "01:00 PM",
        "value": "01:00 PM",
        "continentalFormat": "13.00"
    },
    {
        "key": 12,
        "label": "02:00 PM",
        "value": "02:00 PM",
        "continentalFormat": "14.00"
    },
    {
        "key": 14,
        "label": "03:00 PM",
        "value": "03:00 PM",
        "continentalFormat": "15.00"
    },
    {
        "key": 16,
        "label": "04:00 PM",
        "value": "04:00 PM",
        "continentalFormat": "16.00"
    },
    {
        "key": 18,
        "label": "05:00 PM",
        "value": "05:00 PM",
        "continentalFormat": "17.00"
    },
    {
        "key": 20,
        "label": "06:00 PM",
        "value": "06:00 PM",
        "continentalFormat": "18.00"
    },
    {
        "key": 22,
        "label": "07:00 PM",
        "value": "07:00 PM",
        "continentalFormat": "19.00"
    },
    {
        "key": 24,
        "label": "08:00 PM",
        "value": "08:00 PM",
        "continentalFormat": "20.00"
    },
    {
        "key": 26,
        "label": "09:00 PM",
        "value": "09:00 PM",
        "continentalFormat": "21.00"
    },
    {
        "key": 28,
        "label": "10:00 PM",
        "value": "10:00 PM",
        "continentalFormat": "22.00"
    }
];


function addHoursToTime(timeStr, hoursToAdd) {
    const [hours, minutes] = timeStr.split('.').map(Number);

    // Create a moment object with today's date and the given time
    const timeMoment = moment().set({ hour: hours, minute: minutes });

    // Add 5.5 hours to the time
    const adjustedTime = timeMoment.add(hoursToAdd, 'hours');
    // Format the adjusted time back to HH.MM format
    const formattedTime = adjustedTime.format('HH.mm');
    return formattedTime;
}