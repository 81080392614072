import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCities, updateInventoryWarehouse, getCityFromPincode, removePinCityFromStore } from '../../../actions/inventory';
import { closeModalAction } from '../../../actions/modal';
import { textCss } from '../../../common/Css';
import { debounce } from 'lodash';
import { actualValueForDropdown, getSelectedItemFromDropdownList } from '../../../utils/common';
import Form from '../../../common/Form';
import Input from '../../../common/Form/Input';
import Select from '../../../common/Form/Select';
import { useForm, useFormContext } from 'react-hook-form';
import TextArea from '../../../common/Form/TextArea';
import apis from '../../../apis';
import { FetchGoogleAddress } from '../../SupportTrack/RenderTicketCard';

const EditInventoryWarehouse = ({ modalData, customerId }) => {
    const formName = 'editWarehouse';
    const dispatch = useDispatch();
    const { formButtonLoading } = useSelector((state) => state.loading);
    const { cities } = useSelector((state) => state.inventory);

    useEffect(() => {
        dispatch(getAllCities());

        return () => dispatch(removePinCityFromStore());
    }, [dispatch]);

    const defaultValues = {
        warehouseName: modalData.name,
        pincode: modalData.pincode,
        city: getSelectedItemFromDropdownList(modalData.city_id, cities),
        address: modalData.address,
        name: modalData.warehouseContactName,
        mobile: modalData.warehouseContactPhone,
        email: modalData.warehouseContactEmail,
    };

    const submit = (form) => {
        const warehouseId = modalData.id;
        let data = {
            name: form.warehouseName,
            pincode: form.pincode,
            city: actualValueForDropdown(form.city),
            address: form.address,
            customer_id: customerId,
            contacts: [
                {
                    name: form.name,
                    mobile: form.mobile,
                    email: form.email,
                },
            ],
        };
        dispatch(updateInventoryWarehouse({ data, customerId, warehouseId, formName }));
    };
    const closeModal = () => {
        dispatch(closeModalAction());
    };

    const methods = useForm({ defaultValues, mode: 'all' });

    return (
        <>
            <Form
                className='w-full'
                onSubmit={submit}
                onCancel={closeModal}
                defaultValues={defaultValues}
                submitButtonText={'Update'}
                showSubmit
                submitContainerClassName='flex gap-2 justify-end py-4 px-4'
                isLoading={formButtonLoading[formName]}
                methods={methods}
            >
                <EditWarehouseInputs />
            </Form>
        </>
    );
};

const EditWarehouseInputs = () => {
    const dispatch = useDispatch();
    const { cities } = useSelector((state) => state.inventory);
    const { setValue } = useFormContext();

    const fetchPincode = (pincode) => {
        if (pincode.length === 6) {
            dispatch(getCityFromPincode({ pincode: pincode }));
        }
    };

    const debouncedPincodeHandler = useCallback(debounce(fetchPincode, 300), []);

    return (
        <div className='w-full px-3'>
            <div className='justify-between'>
                <Input label='Warehouse Name' required name='warehouseName' type={'text'} placeholder={'Warehouse Name'} />
            </div>

            <div className='grid grid-cols-3 rows-fr grid-flow-row-dense'>
                <Input
                    label='Pincode'
                    type={'number'}
                    name='pincode'
                    onChange={(event) => debouncedPincodeHandler(event.target.value)}
                    pattern={/^\d{6}$/}
                    required
                    validate={async (value) => {
                        if (value === '') return true;
                        const response = await apis.cities.getCityFromPincode({}, {}, {}, { pincode: value });
                        let pinCity = response.data.data;
                        if (pinCity) {
                            let findCity = cities.find((city) => city.value === pinCity?.fk_city_id);
                            if (findCity) setValue('city', findCity);
                        }
                        return response.data?.code === 200 || "Pincode does't exist in the System.";
                    }}
                />
                <Select label='City' required name='city' options={cities} />
                <FetchGoogleAddress name='address' label='Address' required setLocation={({ pincode }) => setValue('pincode', pincode)} />
            </div>

            <div className='px-4 mt-4'>
                <p className={textCss?.formHighlights}>Contact Details</p>
            </div>
            <div className='grid grid-cols-3 rows-fr grid-flow-row-dense'>
                <Input label='Name' type={'text'} name='name' />
                <Input
                    label='Mobile'
                    type={'number'}
                    name='mobile'
                    validate={(mobile) => {
                        if (mobile === '') return true;
                        return mobile.length === 10 || 'Invalid Mobile';
                    }}
                />
                <Input
                    label='Email'
                    type={'text'}
                    name='email'
                    placeholder={'Email'}
                    validate={(email) => {
                        if (email === '') return true;
                        return (
                            email.match(
                                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            ) || 'Invalid Email'
                        );
                    }}
                />
            </div>
        </div>
    );
};

export default EditInventoryWarehouse;
