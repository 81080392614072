import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import SiteForm from './SiteForm';
import AssetsForm from '../AssetForm';
import WarehouseForm from './WarehouseForm';
import AssetHistory from './AssetHistory';

import { sites as sitesTab, assets as assetsTab, warehouses as warehousesTab, spareAssets as spareAssetsTab } from '../../InventoryController';

import IconToolTip from '../../../../common/IconToolTip';
import { textCss } from '../../../../common/Css';

import { setInventorySideDrawer } from '../../../../actions/inventory';
import SpareAssetFrom from '../SpareAssetForm';

export default function InventorySideDrawer(props) {
    const { selectedSiteId, selectedWarehouseId } = props;
    const { inventorySideDrawerName: sideBarName, inventorySideDrawerPayload } = useSelector((state) => state.inventory);

    const dispatch = useDispatch();
    const closeSideDrawer = () => dispatch(setInventorySideDrawer(false));

    const InventorySideDrawerHandler = {
        [sitesTab.id]: <SiteForm closeForm={closeSideDrawer} showTab={sideBarName} />,
        [assetsTab.id]: <AssetsForm closeForm={closeSideDrawer} selectedSiteId={selectedSiteId} showTab={sideBarName} />,
        [warehousesTab.id]: <WarehouseForm closeForm={closeSideDrawer} />,
        [spareAssetsTab.id]: <SpareAssetFrom closeForm={closeSideDrawer} selectedWarehouseId={selectedWarehouseId} />,
        assetHistory: <AssetHistory assetId={inventorySideDrawerPayload.assetId} assetSource={inventorySideDrawerPayload.assetSource} />,
    };

    const SideBarComponent = (tab) => {
        const InventoryFormComponent = InventorySideDrawerHandler[tab];
        return InventoryFormComponent;
    };

    return (
        <div className='sidedrawer'>
            <div className=' border-t-2 border-r-2 border-l-2 border-scogof2'>
                <div>
                    <div className='w-full items-center px-3 py-2 border-b border-scogogray sticky top-0 z-10 bg-white flex justify-between'>
                        <p className={`${textCss?.headerTwo} capitalize w-576`}>{inventorySideDrawerPayload?.title}</p>
                        <div className='w-6  h-6 cursor-pointer rounded-full flex items-center justify-center' onClick={closeSideDrawer}>
                            <IconToolTip title='Close'>
                                <span className='material-icons text-scogoprimary font-medium align-middle text-font25 hover:text-scogoorange'>highlight_off</span>
                            </IconToolTip>
                        </div>
                    </div>
                    <div className='border-t border-scogogray pt-2 overflow-y-auto' style={{ height: '85vh' }}>
                        {SideBarComponent(sideBarName)}
                    </div>
                </div>
            </div>
        </div>
    );
}
