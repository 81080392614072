import React, { useState, useEffect } from 'react';
import Input from '../../../../common/Form/Input';
import { useSelector, useDispatch } from 'react-redux';
import Checkbox from '../../../../common/Form/Checkbox';
import { useFormContext } from 'react-hook-form';
import { useMemo } from 'react';
import Select from '../../../../common/Form/Select';
import DatePicker from '../../../../common/Form/DatePicker';
import { maintenanceSchedule, warrantyType } from './index';
import AddAssetTypes from '../../../Modals/Inventory/AddAssetTypes';
import { openCustomModal } from '../../../../actions/modal';
import { findSiteById } from '../../../../actions/inventory';
import { actualValueForDropdown } from '../../../../utils/common';

export const AddAssetInputs = ({ siteId }) => {
    const { sitesDropdown, newlyAddedAssetType, siteDataById } = useSelector((state) => state.inventory);
    const { boqList } = useSelector((state) => state.tickets);
    const { isAllSiteDropdownLoading } = useSelector((state) => state.loading);
    const { capexTypes } = useSelector((state) => state.superdesk);
    const dispatch = useDispatch();

    const [assetTypeList, setAssetTypeList] = useState([]);
    const { watch, setValue } = useFormContext();
    const hasFinance = watch('hasFinance');
    const hasSameOwner = watch('hasSameOwner');
    const hasWarranty = watch('hasWarranty');
    const fk_site_id = watch('fk_site_id');

    const capexTypesDropdown = useMemo(() => {
        let list = [];
        if (Array.isArray(capexTypes)) {
            capexTypes.forEach((item) => {
                list.push({
                    key: `capex_types_${item.id}`,
                    value: item.id,
                    label: item.name,
                    capex: item,
                });
            });
        }
        return list;
    }, [capexTypes]);

    useEffect(() => {
        if (newlyAddedAssetType) {
            let firstElem = assetTypeList.shift();
            let newlyCreatedAssetType = { key: Date.now(), label: newlyAddedAssetType.title, value: newlyAddedAssetType.id };
            setAssetTypeList([firstElem, newlyCreatedAssetType, ...boqList]);
            setValue('asset_type_id', newlyCreatedAssetType);
        }
    }, [newlyAddedAssetType, setValue, assetTypeList, boqList]);

    useEffect(() => {
        let assetTypes = [{ key: Date.now(), label: 'Add New Asset Type', value: 'add_new_asset_type' }];
        if (Array.isArray(boqList)) {
            assetTypes.push(...boqList);
        }
        setAssetTypeList(assetTypes);
    }, [boqList]);

    const handleAddAssetType = () => {
        let heading = 'Add New Asset Type';
        let modalComponent = <AddAssetTypes />;
        let modalWidth = '60rem',
            modalHeight = 'auto';
        dispatch(openCustomModal({ modalComponent, heading, modalWidth, modalHeight }));
    };

    useEffect(() => {
        if (siteDataById?.site_contact_person_name) setValue('additional_spoc_name', siteDataById?.site_contact_person_name);
        if (siteDataById?.site_contact_person_email) setValue('additional_spoc_email', siteDataById?.site_contact_person_email);
        if (siteDataById?.site_contact_person_mobile) setValue('additional_spoc_phone', siteDataById?.site_contact_person_mobile);
    }, [siteDataById, setValue]);

    useEffect(() => {
        if (actualValueForDropdown(fk_site_id) > 0) {
            dispatch(findSiteById({ siteId: actualValueForDropdown(fk_site_id), lastTicket: true }));
        }
    }, [fk_site_id, dispatch, siteId]);

    return (
        <div className='px-3'>
            <div className=''>
                {!siteId && (
                    <div className='w-full'>
                        <Select label='Site' required name='fk_site_id' options={sitesDropdown} isLoading={isAllSiteDropdownLoading} shouldUnregister />
                    </div>
                )}
                <div className='w-full'>
                    <Select
                        label='Asset Type'
                        required
                        name='asset_type_id'
                        options={assetTypeList}
                        maxMenuHeight={150}
                        onChange={(event) => {
                            if (event) {
                                const assetTypeId = event.value;
                                if (assetTypeId === 'add_new_asset_type') {
                                    handleAddAssetType();
                                }
                            }
                        }}
                    />
                </div>
                <div className='grid grid-cols-2 rows-fr grid-flow-row-dense'>
                    <Input label='Alias' name='customer_item_name' type={'text'} />
                    <Input label='External Id' name='externel_order_id' type={'text'} />
                </div>

                <div className='grid grid-cols-2 rows-fr grid-flow-row-dense'>
                    <Input label='Serial Number' name='serial_number' type={'text'} required />
                    <Input label='Partcode' name='partcode' type={'text'} />
                </div>

                <div className='grid grid-cols-2 rows-fr grid-flow-row-dense'>
                    <Input label='Make' name='make' type={'text'} />
                    <Input label='Model' name='model' type={'text'} />
                </div>
            </div>

            <div className='px-1'>
                <Checkbox label='Owner same as Site' name='hasSameOwner' />
            </div>
            {!hasSameOwner && (
                <>
                    <div className='grid grid-cols-2 rows-fr grid-flow-row-dense'>
                        <Input label='Owner Name' type={'text'} name='additional_spoc_name' shouldUnregister />
                        <Input
                            label='Owner Number'
                            type={'text'}
                            name='additional_spoc_phone'
                            validate={(mobile) => {
                                if (mobile === '') return true;
                                return mobile.length === 10 || 'Invalid Mobile';
                            }}
                            shouldUnregister
                        />
                    </div>
                    <div className='px-1'>
                        <Input
                            label='Owner Email'
                            type={'text'}
                            name='additional_spoc_email'
                            validate={(email) => {
                                if (email === '') return true;
                                return (
                                    email.match(
                                        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                    ) || 'Invalid Email'
                                );
                            }}
                            shouldUnregister
                        />
                    </div>
                </>
            )}

            <div className='px-1'>
                <Checkbox label='Do you have Finance Details' name='hasFinance' />
            </div>

            {hasFinance && (
                <>
                    <div className='grid grid-cols-2 rows-fr grid-flow-row-dense'>
                        <Select label='Capex Type' name='capex_type' type={'dropdown'} options={capexTypesDropdown} shouldUnregister />
                        <DatePicker label='Capex Date' name='capex_date' dateFormat='dd-MM-yyyy' shouldUnregister />
                    </div>
                    <div className='grid grid-cols-2 rows-fr grid-flow-row-dense'>
                        <DatePicker label='Bill Date' name='bill_date' dateFormat='dd-MM-yyyy' shouldUnregister />
                        <Input label='Invoice Number' name='po_number' type={'text'} shouldUnregister />
                    </div>
                    <div className='grid grid-cols-2 rows-fr grid-flow-row-dense'>
                        <Input label='PO Amount' name='po_amount' type={'number'} shouldUnregister step={'any'} />
                        <Input label='Unit Value' type={'number'} name='per_item_value' shouldUnregister />
                    </div>
                    <div className='w-full'>
                        <Input label='Supplier Name' type={'text'} name='vendor_supplier_name' shouldUnregister />
                    </div>
                </>
            )}

            <div className='px-1'>
                <Checkbox label='Do you have Warranty Details' name='hasWarranty' />
            </div>
            {hasWarranty && (
                <>
                    <div className='w-full'>
                        <Select label='Type' type={'dropdown'} name='warranty_type' options={warrantyType} shouldUnregister />
                    </div>

                    <div className='grid grid-cols-2 rows-fr grid-flow-row-dense'>
                        <DatePicker label='Warranty Start Date' name='warranty_start_date' dateFormat='dd-MM-yyyy' shouldUnregister />
                        <DatePicker label='Warranty End Date' name='warranty_end_date' dateFormat='dd-MM-yyyy' shouldUnregister />
                    </div>
                    <div>
                        <Select label='Preventive Maintenance Schedule' name='preventive_maintenance_schedule' type={'dropdown'} options={maintenanceSchedule} shouldUnregister />
                    </div>
                </>
            )}
        </div>
    );
};
