import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import developmentConfig from '../config/env/development';
import { Outlet, useNavigate } from "react-router-dom";
import config from '../config';
import { getUserProfile } from '../actions/auth';
import { isLogged } from '../utils/common';
const AuthLayout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loggedUser } = useSelector(state => state.auth);

    useEffect(() => {
        if (isLogged() && !loggedUser) {
            dispatch(getUserProfile({ navigate }))
        }
    }, [dispatch, loggedUser, navigate]);

    useEffect(() => {
        let src = 'https://payments.open.money/layer';
        if (config.name === developmentConfig.name) {
            src = 'https://sandbox-payments.open.money/layer';
        }
        const script = document.createElement("script");
        script.src = src;
        script.type = 'text/javascript';
        script.id = 'context';
        document.body.appendChild(script);
    }, []);

    if (isLogged() && !loggedUser) {
        return <div className="flex items-center justify-center h-screen bg-white">
            <div className="text-center">
                <img src={process.env.PUBLIC_URL + '/img/scogo-logo-black-1.png'} alt="Logo" className="w-60 mx-auto mb-4 animate-bounce" />
            </div>
        </div>
    }

    return (
        <Outlet />

    )
}

export default AuthLayout;