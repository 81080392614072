export const GET_ALL_PAYMENTS_REQUEST = 'GET_ALL_PAYMENTS_REQUEST';
export const GET_ALL_PAYMENTS_SUCCESS = 'GET_ALL_PAYMENTS_SUCCESS';
export const GET_ALL_PAYMENTS_FAILURE = 'GET_ALL_PAYMENTS_FAILURE';

export const GET_PO_LIST_REQUEST = 'GET_PO_LIST_REQUEST';
export const GET_PO_LIST_SUCCESS = 'GET_PO_LIST_SUCCESS';
export const GET_PO_LIST_COUNT_SUCCESS = 'GET_PO_LIST_COUNT_SUCCESS';
export const GET_PO_LIST_FAILURE = 'GET_PO_LIST_FAILURE';

export const UPDATE_WALLET_PAYMENT_STATUS_SUCCESS = 'UPDATE_WALLET_PAYMENT_STATUS_SUCCESS';
export const CLEAR_PAYMENTS_TABLE_DATA_ON_UNMOUNT = 'CLEAR_PAYMENTS_TABLE_DATA_ON_UNMOUNT';


export const CANCEL_PO_REQUEST = 'CANCEL_PO_REQUEST';
export const CANCEL_PO_SUCCESS = 'CANCEL_PO_SUCCESS';
export const CANCEL_PO_FAILURE = 'CANCEL_PO_FAILURE';

export const DELETE_PO_REQUEST = 'DELETE_PO_REQUEST';
export const DELETE_PO_SUCCESS = 'DELETE_PO_SUCCESS';
export const DELETE_PO_FAILURE = 'DELETE_PO_FAILURE';

export const UPDATE_PO_INVOICE_REQUEST = 'UPDATE_PO_INVOICE_REQUEST';
export const UPDATE_PO_INVOICE_SUCCESS = 'UPDATE_PO_INVOICE_SUCCESS';
export const UPDATE_PO_INVOICE_FAILURE = 'UPDATE_PO_INVOICE_FAILURE';

export const UPDATE_PO_REQUEST = 'UPDATE_PO_REQUEST';
export const UPDATE_PO_SUCCESS = 'UPDATE_PO_SUCCESS';
export const UPDATE_PO_FAILURE = 'UPDATE_PO_FAILURE';

export const GET_OPEN_TICKET_PAYMENTS_REQUEST = 'GET_OPEN_TICKET_PAYMENTS_REQUEST';
export const GET_OPEN_TICKET_PAYMENTS_SUCCESS = 'GET_OPEN_TICKET_PAYMENTS_SUCCESS';
export const GET_OPEN_TICKET_PAYMENTS_FAILURE = 'GET_OPEN_TICKET_PAYMENTS_FAILURE';

export const GET_ALL_PAYOUTS_FILTERLIST_REQUEST = 'GET_ALL_PAYOUTS_FILTERLIST_REQUEST';
export const GET_ALL_PAYOUTS_FILTERLIST_SUCCESS = 'GET_ALL_PAYOUTS_FILTERLIST_SUCCESS';
export const GET_ALL_PAYOUTS_FILTERLIST_FAILURE = 'GET_ALL_PAYOUTS_FILTERLIST_FAILURE';

export const GET_ALL_REWARDS_AND_PENALTIES_FILTERLIST_REQUEST = 'GET_ALL_REWARDS_AND_PENALTIES_FILTERLIST_REQUEST';
export const GET_ALL_REWARDS_AND_PENALTIES_FILTERLIST_SUCCESS = 'GET_ALL_REWARDS_AND_PENALTIES_FILTERLIST_SUCCESS';
export const GET_ALL_REWARDS_AND_PENALTIES_FILTERLIST_FAILURE = 'GET_ALL_REWARDS_AND_PENALTIES_FILTERLIST_FAILURE';

export const GET_ALL_PO_FILTERLIST_REQUEST = 'GET_ALL_PO_FILTERLIST_REQUEST';
export const GET_ALL_PO_FILTERLIST_SUCCESS = 'GET_ALL_PO_FILTERLIST_SUCCESS';
export const GET_ALL_PO_FILTERLIST_FAILURE = 'GET_ALL_PO_FILTERLIST_FAILURE';

export const GET_ALL_WITHDRAWAL_FILTERLIST_REQUEST = 'GET_ALL_WITHDRAWAL_FILTERLIST_REQUEST';
export const GET_ALL_WITHDRAWAL_FILTERLIST_SUCCESS = 'GET_ALL_WITHDRAWAL_FILTERLIST_SUCCESS';
export const GET_ALL_WITHDRAWAL_FILTERLIST_FAILURE = 'GET_ALL_WITHDRAWAL_FILTERLIST_FAILURE';

export const GET_WALLET_WITHDRAWAL_TRANSACTIONS_REQUEST = 'GET_WALLET_WITHDRAWAL_TRANSACTIONS_REQUEST';
export const GET_WALLET_WITHDRAWAL_TRANSACTIONS_SUCCESS = 'GET_WALLET_WITHDRAWAL_TRANSACTIONS_SUCCESS';
export const GET_WALLET_WITHDRAWAL_TRANSACTIONS_FAILURE = 'GET_WALLET_WITHDRAWAL_TRANSACTIONS_FAILURE';
export const ALL_WITHDRAWAL_TRANSACTIONS_COUNT_SUCCESS = 'ALL_WITHDRAWAL_TRANSACTIONS_COUNT_SUCCESS';

export const GET_WALLET_REWARDS_TRANSACTIONS_REQUEST = 'GET_WALLET_REWARDS_TRANSACTIONS_REQUEST';
export const GET_WALLET_REWARDS_TRANSACTIONS_SUCCESS = 'GET_WALLET_REWARDS_TRANSACTIONS_SUCCESS';
export const GET_WALLET_REWARDS_TRANSACTIONS_FAILURE = 'GET_WALLET_REWARDS_TRANSACTIONS_FAILURE';
export const ALL_REWARDS_TRANSACTIONS_COUNT_SUCCESS = 'ALL_REWARDS_TRANSACTIONS_COUNT_SUCCESS';

export const PAY_PO_TAX_REQUEST = 'PAY_PO_TAX_REQUEST'
export const PAY_PO_TAX_SUCCESS = 'PAY_PO_TAX_SUCCESS'
export const PAY_PO_TAX_FAILURE = 'PAY_PO_TAX_FAILURE'

export const GENERATE_INVOICE_REQUEST = 'GENERATE_INVOICE_REQUEST'
export const GENERATE_INVOICE_SUCCESS = 'GENERATE_INVOICE_SUCCESS'
export const GENERATE_INVOICE_FAILURE = 'GENERATE_INVOICE_FAILURE'

export const GET_PAYOUTS_FILTERED_DATA_REQUEST = 'GET_PAYOUTS_FILTERED_DATA_REQUEST'
export const GET_PAYOUTS_FILTERED_DATA_SUCCESS = 'GET_PAYOUTS_FILTERED_DATA_SUCCESS'
export const GET_PAYOUTS_FILTERED_DATA_FAILURE = 'GET_PAYOUTS_FILTERED_DATA_FAILURE'

export const SET_PAYMENTS_SIDEDRAWER = 'SET_PAYMENTS_SIDEDRAWER'

export const GET_PAYOUTS_FILTER_DATA_REQUEST = 'GET_PAYOUTS_FILTER_DATA_REQUEST'
export const GET_PAYOUTS_FILTER_DATA_SUCCESS = 'GET_PAYOUTS_FILTER_DATA_SUCCESS'
export const GET_PAYOUTS_FILTER_DATA_FAILURE = 'GET_PAYOUTS_FILTER_DATA_FAILURE'

export const GET_OPEN_TICKET_FILTER_DATA_REQUEST = 'GET_OPEN_TICKET_FILTER_DATA_REQUEST'
export const GET_OPEN_TICKET_FILTER_DATA_SUCCESS = 'GET_OPEN_TICKET_FILTER_DATA_SUCCESS'
export const GET_OPEN_TICKET_FILTER_DATA_FAILURE = 'GET_OPEN_TICKET_FILTER_DATA_FAILURE'

export const GET_WITHDRAWAL_FILTER_DATA_REQUEST = 'GET_WITHDRAWAL_FILTER_DATA_REQUEST'
export const GET_WITHDRAWAL_FILTER_DATA_SUCCESS = 'GET_WITHDRAWAL_FILTER_DATA_SUCCESS'
export const GET_WITHDRAWAL_FILTER_DATA_FAILURE = 'GET_WITHDRAWAL_FILTER_DATA_FAILURE'

export const GET_REWARDS_FILTER_DATA_REQUEST = 'GET_REWARDS_FILTER_DATA_REQUEST'
export const GET_REWARDS_FILTER_DATA_SUCCESS = 'GET_REWARDS_FILTER_DATA_SUCCESS'
export const GET_REWARDS_FILTER_DATA_FAILURE = 'GET_REWARDS_FILTER_DATA_FAILURE'


export const GET_PO_FILTER_DATA_REQUEST = 'GET_PO_FILTER_DATA_REQUEST'
export const GET_PO_FILTER_DATA_SUCCESS = 'GET_PO_FILTER_DATA_SUCCESS'
export const GET_PO_FILTER_DATA_FAILURE = 'GET_PO_FILTER_DATA_FAILURE'

export const SET_PAYMENT_FILTER_AND_PAGINATION_DATA = "SET_PAYMENT_FILTER_AND_PAGINATION_DATA"

export const REFRESH_PO_PAGE = 'REFRESH_PO_PAGE';

export const REFRESH_PAYOUTS_PAGE = 'REFRESH_PAYOUTS_PAGE';

export const CLEAR_PAYEMNT_TABS_DATA_ON_UNMOUNT = 'CLEAR_PAYEMNT_TABS_DATA_ON_UNMOUNT'

export const NOT_USABLE_TYPE = 'NOT_USABLE_TYPE'

export const GET_PO_ASSET_CATEGORIES_DROPDOWN_REQUEST = 'GET_PO_ASSET_CATEGORIES_DROPDOWN_REQUEST';
export const GET_PO_ASSET_CATEGORIES_DROPDOWN_SUCCESS = 'GET_PO_ASSET_CATEGORIES_DROPDOWN_SUCCESS';
export const GET_PO_ASSET_CATEGORIES_DROPDOWN_FAILURE = 'GET_PO_ASSET_CATEGORIES_DROPDOWN_FAILURE';

export const GET_TICKET_PO_DETAILS_REQUEST = 'GET_TICKET_PO_DETAILS_REQUEST'
export const GET_TICKET_PO_DETAILS_SUCCESS = 'GET_TICKET_PO_DETAILS_SUCCESS'
export const GET_TICKET_PO_DETAILS_FAILURE = 'GET_TICKET_PO_DETAILS_FAILURE'

export const GET_PO_CATEGORIES_REQUEST = 'GET_PO_CATEGORIES_REQUEST'
export const GET_PO_CATEGORIES_SUCCESS = 'GET_PO_CATEGORIES_SUCCESS'
export const GET_PO_CATEGORIES_FAILURE = 'GET_PO_CATEGORIES_FAILURE'

export const EXPORT_CSV_PAYMENT_REQUEST = 'EXPORT_CSV_PAYMENT_REQUEST'
export const DOWNLOAD_ZIP_PO_REQUEST = 'DOWNLOAD_ZIP_PO_REQUEST'


export const SET_EXPORT_CSV_PO_FUNCTION_IN_STORE = 'SET_EXPORT_CSV_PO_FUNCTION_IN_STORE';
export const SET_DOWNLOAD_ZIP_PO_FUNCTION_IN_STORE = 'SET_DOWNLOAD_ZIP_PO_FUNCTION_IN_STORE';

export const CLEANUP_FOR_UPDATE_PO = 'CLEANUP_FOR_UPDATE_PO';

export const REFRESH_WALLET_ORDER_STATUS_REQUEST = 'REFRESH_WALLET_ORDER_STATUS_REQUEST';
export const REFRESH_WALLET_ORDER_STATUS_SUCCESS = 'REFRESH_WALLET_ORDER_STATUS_SUCCESS';
export const REFRESH_WALLET_ORDER_STATUS_FAILURE = 'REFRESH_WALLET_ORDER_STATUS_FAILURE';


export const GET_PO_GST_DETAILS_REQUEST = 'GET_PO_GST_DETAILS_REQUEST';
export const GET_PO_GST_DETAILS_SUCCESS = 'GET_PO_GST_DETAILS_SUCCESS';
export const GET_PO_GST_DETAILS_FAILURE = 'GET_PO_GST_DETAILS_FAILURE';

export const CLEAR_PO_GST_DETAILS_FROM_STORE = 'CLEAR_PO_GST_DETAILS_FROM_STORE';
export const GET_ENDUSER_GROUPED_PO_FILTER_DATA_SUCCESS = 'GET_ENDUSER_GROUPED_PO_FILTER_DATA_SUCCESS';
export const CANCEL_ENDUSER_GROUPED_PO_SUCCESS = 'CANCEL_ENDUSER_GROUPED_PO_SUCCESS';
export const UPDATE_GROUP_BY_ENDUSER_PO_INVOICE_SUCCESS = 'UPDATE_GROUP_BY_ENDUSER_PO_INVOICE_SUCCESS';
export const GROUP_BY_ENDUSER_PO_GENERATE_INVOICE_SUCCESS = 'GROUP_BY_ENDUSER_PO_GENERATE_INVOICE_SUCCESS';
export const PAY_GROUP_BY_ENDUSER_PO_TAX_SUCCESS = 'PAY_GROUP_BY_ENDUSER_PO_TAX_SUCCESS';