import React, { forwardRef, useState } from 'react';
import { PartnerDetailsPopUp } from '../TodayStatus/PartnerDetailsPopUp';
import IconToolTip from '../../../common/IconToolTip';
import OverflowTip from '../../../common/OverflowTip';
import { validateStringValue } from '../../../utils/common';
import { Popover } from '@mui/material';
import { useDispatch } from 'react-redux';
import PMDetails from '../../project/Modals/PmDetails';
import { openCustomModal } from '../../../actions/modal';
import { isCustomerGroup } from '../../../utils/role';
import { useSelector } from 'react-redux';

export default function Contact({ ticket, dataToShowPerRow = 1 }) {
    const dispatch = useDispatch();
    const pmName = ticket?.scogo_pm_name && ticket?.scogo_pm_mobile ? validateStringValue(ticket.scogo_pm_name) : `${validateStringValue(ticket?.pm_first_name)} ${validateStringValue(ticket?.pm_last_name)}`;
    const pmId = ticket?.scogo_pm_id ? ticket.scogo_pm_id : ticket?.created_by;
    const { loggedUser } = useSelector((state) => state.auth);

    const openPmClick = () => {
        if (!ticket.fk_project_id) return;
        const pmList = [{
            name: `${validateStringValue(ticket?.pm_first_name)} ${validateStringValue(ticket?.pm_last_name)}`, mobile: ticket?.pm_mobile,
            label: 'Project Manager'
        }];
        dispatch(openCustomModal({
            modalComponent: <PMDetails projectId={ticket.fk_project_id} pmList={pmList} />,
            modalWidth: '40rem',
            modalHeight: 'auto',
            heading: "Project Managers",
        }))
    }

    let showPmContact = true;
    if (isCustomerGroup(loggedUser.role.id, loggedUser.role) && ticket) showPmContact = ticket.assignToScogo === 1 && ticket.ticket_owned_by === 0;

    return <div className='w-full'>
        <ContactDetail id={ticket.cluster_id} name={`${ticket?.cluster_name}`} label={`CL`} />
        <ContactDetail id={ticket.psp_id} name={`${ticket?.psp_name}`} label={`PSP`} />
        <ContactDetail id={ticket.supervisor_id} name={`${ticket?.supervisor_name}`} label={`SV`} />
        {showPmContact && <ContactDetail labelClassName='cursor-pointer hover:text-scogoorange text-scogogray max-w-max text-font12' onLabelClick={openPmClick} id={pmId} name={`${pmName}`} label={`PM`} />}

        <div className='flex items-center gap-x-1 w-full'>
            {Array.isArray(ticket.agents) && ticket.agents.length > 0 &&
                ticket.agents?.slice(0, dataToShowPerRow)?.map((agent, index) => {
                    return (
                        <div className='flex inline-flex gap-2 truncate w-full'>
                            <span className='text-scogogray max-w-max text-font12' style={{ maxWidth: '40%' }}>Agent: </span>
                            <span className='max-w-max truncate' style={{ maxWidth: '70%' }}>
                                <OverflowTip textClass='truncate text-scogobgsky cursor-pointer text-left text-font11 hover:text-scogoorange w-full' someLongText={`${validateStringValue(agent.first_name)} ${validateStringValue(agent.last_name)}`} />
                            </span>
                        </div>
                    );
                })}

            {Array.isArray(ticket.agents) && ticket.agents.length > dataToShowPerRow && (
                <>
                    <AgentPopup agents={ticket.agents?.slice(dataToShowPerRow)} anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }} >
                        <span className='text-font12 round font-normal text-scogoorange relative cursor-pointer hover:text-scogobgsky countOfBOQ'>
                            +{ticket.agents.length - dataToShowPerRow}
                        </span>
                    </AgentPopup>
                </>
            )}
        </div>
    </div>
}



const ContactDetail = ({ id, name, label, onLabelClick = () => { }, labelClassName = 'text-scogogray max-w-max text-font12' }) => {
    if (parseInt(id) > 0) {
        return <div className='flex inline-flex gap-2 truncate w-full'>
            <span onClick={onLabelClick} className={labelClassName} style={{ maxWidth: '30%' }}>{label}: </span>
            <span className='max-w-max truncate' style={{ maxWidth: '70%' }}>
                <PartnerDetailsPopUp userId={id} displayName={name} />
            </span>
        </div>
    }
    return <></>
}


const Popup = forwardRef(({ agents, close }, ref) => {
    return (
        <div className='bg-white max-h-44 cursor-pointer max-w-14 max-w-56 p-2  z-100 border shadow-lg boqpop'>
            {agents?.map((agent) => {
                return (
                    <IconToolTip title={`${validateStringValue(agent.first_name)} ${validateStringValue(agent.last_name)}`}>
                        <span className={`bg-scogoorange text-white max-w-12  w-fit text-font10 px-2 flex items-center rounded-3px  mb-1`} >
                            {`${validateStringValue(agent.first_name)} ${validateStringValue(agent.last_name)}`}
                        </span>
                    </IconToolTip>
                );
            })}
        </div>
    );
});

const AgentPopup = ({
    children,
    agents,
    anchorOrigin = {
        vertical: 'top',
        horizontal: 'center',
    },
    transformOrigin = {
        vertical: 'bottom',
        horizontal: 'center',
    },
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <div aria-describedby={id} onClick={handleClick} variant='contained' className=' hover:underline cursor-pointer inline'>
                {children}
            </div>
            <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={anchorOrigin} transformOrigin={transformOrigin}>
                <Popup agents={agents} close={handleClose} />
            </Popover>
        </>
    );
};
