import React from 'react';
import Buttons from '../../../common/Buttons';
import Loader from '../../../common/Loader';


export const NewScogoButton = ({ onClick, isLoading, buttonName = 'Continue', color = 'primary' }) => {
    const classes = {
        'primary': 'bg-scogoprimary text-white rounded-full gap-1 cursor-pointer',
        'orange': 'bg-scogoorange text-white rounded-full gap-1 cursor-pointer',
        'gray': 'bg-white text-scogogray rounded-full gap-1 cursor-pointer border-scogoprimary border-1'
    }

    return <>
        <Buttons style={{ height: isMobileView ? '4.5rem' : '3.5rem', width: '100%', fontSize: '1.3rem' }} buttonClass={classes[color]} onClick={(event) => {
            onClick(event)
        }}>
            {isLoading ? <Loader color='white' size='20' speed='1' thickness='3' /> : buttonName}
        </Buttons >
    </>
}
export const isMobileView = window.innerWidth < 1040;