import { useMemo, useState } from 'react'
import { defaultSingleChatPic } from '../ChatController'
import ProfileImage from '../components/ProfileImage'
import { defaultGroupChatImage } from "../ChatController";
import IconToolTip from '../../../common/IconToolTip'
import { useSelector } from 'react-redux';
import { validateStringValue } from '../../../utils/common';
import { useDispatch } from 'react-redux';
import { CLOSE_ALL_OPEN_CHATS } from '../../../types/conversations';

export const ChhotaChatBox = ({ getOpenedConversationsIds, onClick }) => {
    const [isChatOpen, setIsChatOpen] = useState(false)
    const { conversationDetails, chatTickets, chatBoxElementData } = useSelector((state) => state.conversations);
    const [isChatMinimized, setIsChatMinimized] = useState({});
    const dispatch = useDispatch();
    let conversationImage = defaultSingleChatPic
    const handleChatArrowClick = () => {
        setIsChatOpen(!isChatOpen)
    };

    const opendChatList = useMemo(() => {
        let data = [];
        if (getOpenedConversationsIds) {
            data = getOpenedConversationsIds.map((id) => {
                const chat = conversationDetails && conversationDetails[id]
                const image = chat?.conversationImage ? chat?.conversationImage : defaultGroupChatImage;
                const ticket = chatTickets && chatTickets[chat?.conversationId];
                const storedData = chatBoxElementData[id];
                const fe_name = ticket ? ticket.fe_name : validateStringValue(storedData.fe_name);
                const conversationName = chat ? chat.conversationName : validateStringValue(storedData.conversationName);
                return {
                    fe_name,
                    conversationName,
                    unreadMessageCount: chat ? chat.unreadMessageCount : '',
                    displayPicture: image,
                    chatId: id
                }
            })
        }
        return data;
    }, [getOpenedConversationsIds, conversationDetails, chatTickets, chatBoxElementData]);

    const handleCloseAllChats = () => {

        dispatch({ type: CLOSE_ALL_OPEN_CHATS })
    }
    return (<>
        <div className='border-1 border-scogoddd w-[22rem] bg-white absolute right-2 bottom-1 rounded-lg   z-50  max-h-[39rem] overflow-y-auto ' >
            <div className='flex  justify-between bg-slate-200   border-1 border-scogoddd cursor-pointer  h-[3rem]' onClick={handleChatArrowClick}>
                <div className='flex items-center gap-2 pl-2' >

                    <ProfileImage
                        imgUrl={conversationImage}
                        className='inline-block object-cover w-10 h-10 rounded-full text-center leading-10'
                    />
                    <div className=' font-bold text-lg'>Messaging</div>
                    <div className='rounded-full bg-scogoorange text-white px-2'>{getOpenedConversationsIds.length > 0 && getOpenedConversationsIds.length}</div>
                </div>
                <div className='flex items-center'>
                    <div class="material-icons cursor-pointer  rounded-full text-font15  " onClick={(event) => {
                        event.stopPropagation();
                        handleCloseAllChats();
                    }}>
                        clear
                    </div>
                    <div class="material-icons cursor-pointer p-1 rounded-full " onClick={handleChatArrowClick}>

                        {isChatOpen ? ' keyboard_arrow_down' : ' keyboard_arrow_up'}

                    </div>
                </div>
            </div>

            {isChatOpen && <div className=''>
                {opendChatList.map(({ fe_name, displayPicture, conversationName, unreadMessageCount, chatId }) => {

                    return <div className=" hover:bg-slate-100 cursor-pointer m-2 flex items-center border-b-1 " onClick={() => onClick(chatId, conversationName)}>
                        <IconToolTip title={conversationName}>
                            <span className='p-2'>
                                <ProfileImage
                                    imgUrl={displayPicture}
                                    className='inline-block object-cover w-14 h-12 rounded-full text-center leading-10'
                                    type={'group'}
                                />
                            </span>
                        </IconToolTip>
                        <div className={` w-full flex ${unreadMessageCount > 0 ? 'justify-around items-center' : ''}`}>
                            <span className='flex flex-col'>
                                <span className=' '>{conversationName}</span>
                                {fe_name && <span>{fe_name}</span>}
                            </span>
                            {unreadMessageCount > 0 && <div className='rounded-full bg-scogoorange text-white px-2 h-6'> {unreadMessageCount}</div>}
                        </div>
                    </div>
                })}
            </div>}
        </div>
    </>)
}