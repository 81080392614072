export default function InLineLabelValue({ label, value, parentClass = '', labelClassName, valueClassName, tooltip = false, truncate = true, button }) {
    if (!value) return <></>;
    return (
        <div className={`w-auto  ${parentClass}`}>
            <p className={`text-light text-gray-600 text-font13 flex whitespace-nowrap ${labelClassName}`}>
                {label}
                {button}
            </p>
            <p className={`text-light w-full ${truncate ? 'truncate' : ' break-all line-clamp-2'} text-font13 text-scogoprimary font-semibold ${valueClassName}`}>{value}</p>
        </div>
    );
}
