import config from '../config';
import { allUserTab, clusterOrPSPTab, cspTab, feTab, ispTab, iwanTab, scmTab, spTab, vpTab } from '../pages/team/TeamController';
import { partners } from '../types';
import { validateNumberValue, validateStringValue } from '../utils/common';

const INITIAL_STATE = {
    error: undefined,
    partnersList: [],
    partnerFilterData: [],
    usersCount: {},
    filteredUsersCount: {},
    groupUserList: []
};

const partnerReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case partners.PARTNERS_LIST_SUCCESS:
            let partnersList = action.payload;
            partnersList = partnersList.sort((i, j) => j.worked_on_tickets_count - i.worked_on_tickets_count || j.status - i.status);
            let filterData = {
                inventoryStatus: ['Available', 'In Transit', 'Delivered'],
                status: ['Active', 'Profile Pending', 'Bank Details Pending', 'Verification Pending', 'Address Proof Not Updated', 'Verification Pending Address Updated', 'InActive', 'Disabled'],
                roleType: ['CLUSTER', 'PSP', 'Supervisor']
            };

            let operationAreas = [];
            partnersList.forEach((item) => {
                let user = {
                    id: item.id,
                    name: `${validateStringValue(item.first_name)} ${validateStringValue(item.last_name)}`,
                };
                let email = {
                    id: item.email.replace(/[^a-zA-Z0-9@. ]/g, ''),
                    email: item.email,
                };

                if (Array.isArray(item.office_location)) {
                    item.office_location.forEach((elem) => {
                        if (!operationAreas.includes(elem)) operationAreas.push(elem);
                    });
                }

                if (filterData['users']) {
                    let isAlreadyExit = filterData['users'].find((i) => i.id === item.id);
                    if (!isAlreadyExit && user.id) filterData['users'].push(user);
                } else filterData['users'] = [user];

                if (filterData['mobiles']) {
                    if (!filterData['mobiles'].includes(item.mobile)) {
                        filterData['mobiles'].push(item.mobile);
                    }
                } else filterData['mobiles'] = [item.mobile];

                if (filterData['emails']) {
                    let isAlreadyExit = filterData['emails'].find((i) => i.email === item.email);
                    if (!isAlreadyExit) filterData['emails'].push(email);
                } else filterData['emails'] = [email];

                const entryBy = `${validateStringValue(item?.created_by?.first_name)} ${validateStringValue(item?.created_by?.last_name)}`
                if (filterData['entryBy'] && entryBy) {
                    let isAlreadyExit = filterData['entryBy'].find((item) => item === entryBy);
                    if (!isAlreadyExit && entryBy) filterData['entryBy'].push(entryBy);
                } else filterData['entryBy'] = [entryBy];

                const pancard = `${validateStringValue(item?.customer_detail?.customer_pan_card_number)}`
                if (filterData['pancard']) {
                    let isAlreadyExit = filterData['pancard'].find((item) => item === pancard);
                    if (!isAlreadyExit && pancard) filterData['pancard'].push(pancard);
                } else filterData['pancard'] = [pancard];

                const gst = `${validateStringValue(item?.customer_detail?.customer_gst_number)}`
                if (filterData['gst']) {
                    let isAlreadyExit = filterData['gst'].find((item) => item === gst);
                    if (!isAlreadyExit && gst) filterData['gst'].push(gst);
                } else filterData['gst'] = [gst];

                const companyName = `${validateStringValue(item?.customer_detail?.customer_company_name)}`
                if (filterData['companyName']) {
                    let isAlreadyExit = filterData['companyName'].find((item) => item === companyName);
                    if (!isAlreadyExit && companyName) filterData['companyName'].push(companyName);
                } else filterData['companyName'] = [companyName];

                const netProvider = `${validateStringValue(item?.service_partner?.net_provider)}`
                if (filterData['netProvider']) {
                    let isAlreadyExit = filterData['netProvider'].find((item) => item === netProvider);
                    if (!isAlreadyExit && netProvider) filterData['netProvider'].push(netProvider);
                } else filterData['netProvider'] = [netProvider];

                const roleName = validateStringValue(item?.role?.role_name)
                if (filterData['roleName']) {
                    let isAlreadyExit = filterData['roleName'].find((item) => item === roleName);
                    if (!isAlreadyExit && roleName) filterData['roleName'].push(roleName);
                } else filterData['roleName'] = [roleName];

            });
            return {
                ...state,
                partnersList,
                partnerFilterData: { ...filterData, operationAreas },
            };

        case partners.PARTNERS_SERVICE_LOCATIONS_UPDATE: {
            const { userIds, details } = action.payload;
            const userId = userIds[0];
            const office_location = Array.isArray(details.operational_areas) && details.operational_areas.filter((area) => area.fk_user_id === userId);

            let updatedPartnersList = state.userList.map((partner) => {
                if (partner.id === userId) {
                    return { ...partner, office_location }
                } else return partner;
            });

            return {
                ...state,
                userList: updatedPartnersList,
            };
        }
        case partners.PARTNERS_LIST_DROPDOWN_SUCCESS:
            return {
                ...state,
                partnersListDropdown: action.payload,
            };
        case partners.CLEAR_PARTNERS_LIST_DROPDOWN:
            return {
                ...state,
                partnersListDropdown: undefined,
            };
        case partners.GROUP_USERS_LIST_SUCCESS:
            return {
                ...state,
                groupUsersOptions: action.payload,
            };
        case partners.CLEAR_PARTNERS_DROPDOWN:
            return {
                ...state,
                partnersListDropdown: [],
            };
        case partners.UPDATE_TOOLS_PARTNERS_IN_STORE: {
            const { spId, toolNames } = action.payload;
            const updatePartnerList = state.userList.map((partner) => {
                if (partner?.sp_id === spId) return { ...partner, service_partner_tools: toolNames };
                return partner;
            });

            return {
                ...state,
                userList: updatePartnerList,
            };
        }
        case partners.IWAN_DROPDOWN_SUCCESS: {
            return {
                ...state,
                iwanDropdown: action.payload,
            };
        }
        case partners.CLEAR_PARTNER_LIST_FROM_STORE: {
            return {
                ...state,
                partnersList: INITIAL_STATE.partnersList,
                partnerFilterData: INITIAL_STATE.partnerFilterData,
            };
        }

        case partners.GET_PARTNER_LIST_SUCCESS: {
            const userList = transformUsers(action.payload);
            return {
                ...state,
                userList,
            };
        }
        case partners.GET_PARTNER_FILTER_SUCCESS: {
            const emails = action.payload.emails.map((item) => ({
                id: item.replace(/[^a-zA-Z0-9@. ]/g, ''),
                email: item,
            }))

            const status = [
                { id: 'active', title: 'Active' },
                { id: 'profile_pending', title: 'Profile Pending' },
                { id: 'bank_details_pending', title: 'Bank Details Pending' },
                { id: 'verification_pending', title: 'Verification Pending' },
                { id: 'address_proof_not_updated', title: 'Address Proof Not Updated' },
                { id: 'verification_pending_address_updated', title: 'Verification Pending Address Updated' },
                { id: 'inactive', title: 'InActive' },
                { id: 'disabled', title: 'Disabled' },
            ]
            const roles = [
                { id: 'partner', title: 'SP' },
                { id: 'isp', title: 'ISP' },
                { id: 'iwan', title: 'IWAN' },
                { id: 'customer_partner', title: 'CSP' },
                { id: 'engineer', title: 'FE' },
                { id: 'scm', title: 'SCM' },
                { id: 'cluster', title: 'Cluster' },
                { id: 'psp', title: 'PSP' },
                { id: 'supervisor', title: 'Supervisor' },
                { id: 'customer', title: 'Customer' },
                { id: 'customer_admin', title: 'Customer Admin' },
                { id: 'customer_user', title: 'Customer User' },
            ]
            return {
                ...state,
                partnerFilterData: { ...action.payload, emails, status, roles },
            };
        }

        case partners.GET_FILTERED_PARTNER_LIST_SUCCESS: {
            const { users, count, tab } = action.payload;
            const userList = transformUsers(users);
            return {
                ...state,
                userList,
                filteredUsersCount: { ...state.filteredUsersCount, [tab]: count },
            };
        }

        case partners.GET_PARTNER_DETAILS_LIST_SUCCESS: {
            const { operational_areas, tools, usecases, inventory, incentive_areas, warehouses, engineers, asset_types, income_details, customer_details } = action.payload;

            let transformedList = state.userList;

            transformedList = transformedList.map((user) => {
                let office_location = [];
                let incentive_locations = [];
                let worked_on_asset_types = [];
                let service_partner_tools = [];
                let worked_on_use_cases = [];
                let user_inventory;
                let engineers_count;
                let income;
                let customer = {}

                if (Array.isArray(customer_details) && customer_details.length > 0) {
                    customer = customer_details.find((cust) => cust.fk_user_id === user.id);
                }
                if (Array.isArray(operational_areas) && operational_areas.length > 0) {
                    office_location = operational_areas.filter((area) => area.fk_user_id === user.id);
                }
                if (Array.isArray(incentive_areas) && incentive_areas.length > 0) {
                    incentive_locations = incentive_areas.filter((area) => area.fk_user_id === user.id);
                }
                if (Array.isArray(asset_types) && asset_types.length > 0) {
                    worked_on_asset_types = asset_types.filter((asset) => asset.fk_user_id === user.id).map((asset) => asset.title);
                }
                if (Array.isArray(tools) && tools.length > 0) {
                    service_partner_tools = tools.filter((tool) => tool.fk_user_id === user.id).map((tool) => tool.title);
                }
                if (Array.isArray(usecases) && usecases.length > 0) {
                    worked_on_use_cases = usecases.filter((usecase) => usecase.fk_user_id === user.id).map((usecase) => usecase.title);
                }
                if (Array.isArray(inventory) && inventory.length > 0) {
                    user_inventory = inventory.find((inv) => inv.fk_user_id === user.id)?.inventory;
                }
                if (Array.isArray(engineers) && engineers.length > 0) {
                    engineers_count = engineers.find((inv) => inv.fk_user_id === user.id)?.count;
                }
                if (Array.isArray(income_details) && income_details.length > 0) {
                    let findInc = income_details.find((inc) => inc.fk_user_id === user.id);
                    if (findInc) {
                        income = {
                            current_month_income: validateNumberValue(findInc.current_month_income), average_monthly_income: validateNumberValue(findInc.average_monthly_income), total_income: validateNumberValue(findInc.total_income),
                            wallet_balance: validateNumberValue(findInc.wallet_balance)
                        }
                    }
                }

                return { ...user, office_location, worked_on_asset_types, service_partner_tools, worked_on_use_cases, inventory: user_inventory, engineers_count, incentive_areas: incentive_locations, income_details: income, customer_details: customer };
            });

            return {
                ...state,
                userList: transformedList
            };
        }
        case partners.REMOVE_PARTNER_LIST_FROM_STORE: {
            return {
                ...state,
                userList: undefined
            };
        }
        case partners.SET_TEAM_FILTER_DETAILS_IN_STORE: {
            const { payload, page, limit, query, tab, roles, status } = action.payload;
            return {
                ...state,
                rowsPerPage: limit,
                page,
                searchQuery: query,
                filterPayload: payload,
                tab,
                roles,
                status
            };
        }
        case partners.GET_PARTNER_COUNT_SUCCESS: {

            const usersCount = Object.assign(state.usersCount, transformTeamCount(action.payload));


            return {
                ...state,
                usersCount,
                filteredUsersCount: { ...usersCount, ...state.filteredUsersCount }
            };
        }
        case partners.GET_SCM_CLUSTER_AND_PSP_LIST_SUCCESS: {
            return {
                ...state,
                partnersList: action.payload
            }
        }
        case partners.GET_SP_FE_LIST_SUCCESS: {
            return {
                ...state,
                partnersList: action.payload
            }
        }


        case partners.GET_GROUP_USER_LIST_SUCCESS: {
            const { data, append } = action.payload;
            let userList = [...data]
            if (append) {
                userList = [...state.groupUserList, ...data];
            }

            return {
                ...state,
                groupUserList: userList,
            };
        }
        case partners.GET_NEXT_GROUP_USER_LIST_SUCCESS: {
            const { data, append } = action.payload;
            let userList = [...data]
            if (append) {
                userList = [...state.groupUserList, ...data];
            }

            return {
                ...state,
                groupUserList: userList,
            };
        }
        case partners.GET_DEVELOPERS_LIST_SUCCESS: {
            return {
                ...state,
                groupUserList: action.payload
            }
        }
        case partners.CLEAR_GROUP_USER_FROM_STORE: {
            return {
                ...state,
                groupUserList: INITIAL_STATE.groupUserList
            }
        }
        case partners.GET_FILTERED_GROUP_USER_LIST_SUCCESS: {
            const { data, append } = action.payload;
            let userList = [...data]
            if (append) {
                userList = [...state.groupUserList, ...data];
            }

            return {
                ...state,
                groupUserList: userList,
            };
        }
        case partners.GET_AREA_COUNT_SUCCESS: {
            return {
                ...state,
                areaCountList: action.payload?.map(item => {
                    return { ...item, totalCount: ` ${item?.message ? item?.message : `${item?.ticketCount} / ${item.spoCount}`}` }
                })
            };
        }
        case partners.GENERATE_AFFILIATE_LINK_SUCCESS: {
            const { userId, affiliate_id, store_ref_code, store_affiliate_link } = action.payload;
            const userListAfterSucess = state.userList.map((user) => {
                if (user.id === userId) {
                    return { ...user, affiliate_id, store_ref_code, store_affiliate_link };
                }
                return user;
            });
            return { ...state, userList: userListAfterSucess };
        }

        case partners.GET_FILTERED_GROUP_USER_LIST_FAILURE:
        case partners.GET_GROUP_USER_LIST_FAILURE:
        case partners.GET_NEXT_GROUP_USER_LIST_FAILURE:
        case partners.GET_DEVELOPERS_LIST_FAILURE:
        case partners.GET_SP_FE_LIST_FAILURE:
        case partners.GET_SCM_CLUSTER_AND_PSP_LIST_FAILURE:
        case partners.IWAN_DROPDOWN_FAILURE:
        case partners.GET_FILTERED_PARTNER_LIST_FAILURE:
        case partners.PARTNERS_LIST_FAILURE:
        case partners.PARTNERS_LIST_DROPDOWN_FAILURE:
        case partners.GET_AREA_COUNT_FAILURE:
        case partners.REMOVE_OR_GIVE_HELP_ACCESS_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return { ...state };
    }
};

export default partnerReducer;


const transformTeamCount = (count) => {
    let allCount = 0
    Object.entries(count).forEach(([key, value]) => {
        allCount += validateNumberValue(value);
    })
    return {
        [spTab.path]: count.partner,
        [ispTab.path]: count.isp,
        [iwanTab.path]: count.iwan,
        [cspTab.path]: count.customer_partner,
        [feTab.path]: count.engineer,
        [vpTab.path]: count.verification_pending,
        [scmTab.path]: count.scm,
        [clusterOrPSPTab.path]: validateNumberValue(count.psp) + validateNumberValue(count.supervisor) + validateNumberValue(count.cluster),
        [allUserTab.path]: allCount
    }
}

const transformUsers = (users) => {
    if (Array.isArray(users)) {
        return users.map((user) => {
            if (user.affiliate_id) {
                return { ...user, sp_id: validateNumberValue(user?.service_partner?.service_partner_id), worked_on_tickets_count: user.tickets_executed, store_affiliate_link: `${config.store_affiliate_link_static}${user.store_ref_code}` }
            }
            return { ...user, sp_id: validateNumberValue(user?.service_partner?.service_partner_id), worked_on_tickets_count: user.tickets_executed }
        }
        )
    }
    return [];
}