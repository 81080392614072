import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import MaterialIconToolTip from '../../common/MaterialIconToolTip';
import FormTable from '../../common/Form/Table';
import { useSelector } from 'react-redux';
import Select from '../../common/Form/Select';
import Input from '../../common/Form/Input';
import Checkbox from '../../common/Form/Checkbox';
import DatePicker from '../../common/Form/DatePicker';
import { TotalCard } from './TotalCard';

export const QuotesCard = ({ quotesArrayName, isCustomerFeasibilityEligible, showTicketDetail }) => {
    const { watch, setValue } = useFormContext();
    const { boqList } = useSelector((state) => state.tickets);
    const { raisePOVendorsList } = useSelector((state) => state.customer);
    const { boqAssetsLoading, isCustomerVendorsLoading, isPartnersDropdownListLoading } = useSelector((state) => state.loading);
    const { poassetcategories } = useSelector((state) => state.payments);
    const { eligibleSPsDropdown } = useSelector((state) => state.sp);

    const [isEndDateEditable, setIsEndDateEditable] = useState({});

    const [vendorList1, setVendorsList1] = useState(raisePOVendorsList);
    const [vendorList2, setVendorsList2] = useState(raisePOVendorsList);
    const [vendorList3, setVendorsList3] = useState(raisePOVendorsList);

    const supplier_vendor_1 = watch('supplier_vendor_1');
    const supplier_vendor_2 = watch('supplier_vendor_2');
    const supplier_vendor_3 = watch('supplier_vendor_3');

    useEffect(() => {
        if (Array.isArray(raisePOVendorsList)) {
            setVendorsList1(raisePOVendorsList);
            setVendorsList2(raisePOVendorsList);
            setVendorsList3(raisePOVendorsList);
        }
    }, [raisePOVendorsList]);

    useEffect(() => {
        if (Array.isArray(eligibleSPsDropdown)) {
            setVendorsList1(eligibleSPsDropdown);
        }
    }, [eligibleSPsDropdown]);

    const tableSchema = [
        {
            head: {
                thClassName: `w-80 truncate pl-1`,
                headName: `Asset Type`,
                render: (headName) => {
                    return (
                        <div className='flex gap-2'>
                            {headName}
                            <span className='text-scogoorange'>*</span>
                        </div>
                    );
                },
            },
            body: {
                bodyClassName: `w-80 break-all pl-2`,
                render: (_, { rowIndex }) => {
                    return <Select name={`${quotesArrayName}[${rowIndex}].asset_type`} options={boqList} isLoading={boqAssetsLoading} required />;
                },
            },
        },
        {
            head: { thClassName: `w-80 truncate`, headName: `Category` },
            body: {
                bodyClassName: `w-80 break-all`,
                render: (_, { rowIndex }) => {
                    return <Select name={`${quotesArrayName}[${rowIndex}].category`} options={poassetcategories} isLoading={false} required />;
                },
            },
        },
        {
            head: { thClassName: `w-80 truncate`, headName: `Description` },
            body: {
                bodyClassName: `w-80 break-all`,
                render: (_, { rowIndex }) => {
                    return <Input name={`${quotesArrayName}[${rowIndex}].description`} />;
                },
            },
        },
        {
            head: {
                thClassName: `w-60 truncate`,
                headName: `Quantity`,
                render: (headName) => {
                    return (
                        <div className='flex gap-2'>
                            {headName}
                            <span className='text-scogoorange'>*</span>
                        </div>
                    );
                },
            },
            body: {
                bodyClassName: `w-60 break-all`,
                render: (_, { rowIndex }) => {
                    return <Input name={`${quotesArrayName}[${rowIndex}].quantity`} type='number' />;
                },
            },
        },
        {
            head: {
                thClassName: `w-80 truncate`,
                headName: `Vendor 1 (Unit Price)`,
                render: (headName) => {
                    return (
                        <>
                            <p>{headName}</p>
                            <Select
                                className='grow'
                                style={{ minWidth: '20%' }}
                                name={`supplier_vendor_1`}
                                label=''
                                options={vendorList1}
                                isLoading={isCustomerVendorsLoading || isPartnersDropdownListLoading}
                                onChange={(event) => {
                                    if (event && isCustomerFeasibilityEligible) {
                                        if (event.gst_pancard_number) setValue('vendor_gst_pancard', event.gst_pancard_number);
                                        if (event.vendor_code) setValue('vendor_code', event.vendor_code);
                                    }
                                    if (!isCustomerFeasibilityEligible) {
                                        let updatedList = raisePOVendorsList.filter((vendor) => {
                                            let exclude = [];
                                            if (supplier_vendor_2) exclude.push(supplier_vendor_2.value);
                                            if (supplier_vendor_3) exclude.push(supplier_vendor_3.value);
                                            if (event) exclude.push(event.value);
                                            return !exclude.includes(vendor.value);
                                        });
                                        setVendorsList2(updatedList);
                                        setVendorsList3(updatedList);
                                    }
                                }}
                            />
                        </>
                    );
                },
            },
            body: {
                bodyClassName: `w-80 break-all`,
                render: (_, { rowIndex }) => {
                    return (
                        <>
                            <Input
                                className='grow'
                                style={{ minWidth: '20%' }}
                                name={`${quotesArrayName}[${rowIndex}].quote_1`}
                                placeholder='Quotes'
                                type='number'
                                required={supplier_vendor_1}
                            />
                        </>
                    );
                },
            },
        },
        {
            head: { thClassName: `w-24 truncate`, headName: `` },
            body: {
                bodyClassName: `w-24 break-all`,
                render: (_, { rowIndex, remove }) => {
                    return (
                        <MaterialIconToolTip
                            onClick={() => {
                                remove(rowIndex);
                            }}
                            title='Remove Asset'
                            materialIconName='delete'
                            materialIconClass='material-icons text-scogoToastDangerIcon text-font16 cursor-pointer w-1/12 pl-4 hover:text-scogoorange mt-3'
                        />
                    );
                },
            },
        },
    ];

    const getTableSchema = () => {
        let schemaColumnList = tableSchema;
        if (!isCustomerFeasibilityEligible) {
            let vendor_two_three_columns = [
                {
                    head: {
                        thClassName: `w-80 truncate`,
                        headName: `Vendor 2 (Unit Price)`,
                        render: (headName) => {
                            return (
                                <>
                                    <p>{headName}</p>
                                    <Select
                                        name={`supplier_vendor_2`}
                                        label=''
                                        options={vendorList2}
                                        isLoading={isCustomerVendorsLoading || isPartnersDropdownListLoading}
                                        onChange={(event) => {
                                            let updatedList = raisePOVendorsList.filter((vendor) => {
                                                let exclude = [];
                                                if (supplier_vendor_1) exclude.push(supplier_vendor_1.value);
                                                if (supplier_vendor_3) exclude.push(supplier_vendor_3.value);
                                                if (event) exclude.push(event.value);
                                                return !exclude.includes(vendor.value);
                                            });
                                            setVendorsList1(updatedList);
                                            setVendorsList3(updatedList);
                                        }}
                                    />
                                </>
                            );
                        },
                    },
                    body: {
                        bodyClassName: `w-80 break-all`,
                        render: (_, { rowIndex, remove }) => {
                            return (
                                <>
                                    <Input name={`${quotesArrayName}[${rowIndex}].quote_2`} placeholder='Quotes' type='number' required={supplier_vendor_2} />
                                </>
                            );
                        },
                    },
                },
                {
                    head: {
                        thClassName: `w-80 truncate`,
                        headName: `Vendor 3 (Unit Price)`,
                        render: (headName) => {
                            return (
                                <>
                                    <p>{headName}</p>
                                    <Select
                                        className='grow'
                                        style={{ minWidth: '20%' }}
                                        name={`supplier_vendor_3`}
                                        label=''
                                        options={vendorList3}
                                        isLoading={isCustomerVendorsLoading || isPartnersDropdownListLoading}
                                        onChange={(event) => {
                                            let updatedList = raisePOVendorsList.filter((vendor) => {
                                                let exclude = [];
                                                if (supplier_vendor_1) exclude.push(supplier_vendor_1.value);
                                                if (supplier_vendor_2) exclude.push(supplier_vendor_2.value);
                                                if (event) exclude.push(event.value);
                                                return !exclude.includes(vendor.value);
                                            });
                                            setVendorsList1(updatedList);
                                            setVendorsList2(updatedList);
                                        }}
                                    />
                                </>
                            );
                        },
                    },
                    body: {
                        bodyClassName: `w-80 break-all`,
                        render: (_, { rowIndex, remove }) => {
                            return (
                                <>
                                    <Input name={`${quotesArrayName}[${rowIndex}].quote_3`} placeholder='Quotes' type='number' required={supplier_vendor_3} />
                                </>
                            );
                        },
                    },
                },
            ];
            schemaColumnList.splice(5, 0, ...vendor_two_three_columns);
        } else {
            let recurring_end_date_columns = [
                {
                    head: { thClassName: `w-40 truncate`, headName: `Recurring` },
                    body: {
                        bodyClassName: `w-40 break-all`,
                        render: (quote, { rowIndex }) => {
                            return (
                                <Checkbox
                                    name={`${quotesArrayName}[${rowIndex}].recurring`}
                                    onChange={(event) => {
                                        if (event) {
                                            setIsEndDateEditable((prevState) => ({ ...prevState, [quote.id]: event.target.checked }));
                                        }
                                    }}
                                />
                            );
                        },
                    },
                },
                {
                    head: { thClassName: `w-80 truncate`, headName: `End Date` },
                    body: {
                        bodyClassName: `w-80 break-all`,
                        render: (quote, { rowIndex }) => {
                            return <DatePicker name={`${quotesArrayName}[${rowIndex}].end_date`} minDate={new Date()} readOnly={!isEndDateEditable[quote.id]} />;
                        },
                    },
                },
            ];
            schemaColumnList.splice(3, 0, ...recurring_end_date_columns);
        }
        return { table: schemaColumnList };
    };

    return (
        <div className={`border bg-white rounded-lg shadow-lg`}>
            <FormTable
                isLoading={false}
                schema={getTableSchema()}
                name={quotesArrayName}
                allowAdd={true}
                rowGenerator={() => ({ id: `${Math.random()}`, asset_type: '', category: '', description: '', quantity: '' })}
                buttonDivClass='px-4 py-2'
                formTableClass=''
                tableHeadFontSize='text-font11 font-medium text-black'
                tableHeadClass='md:flex tableHeader hidden px-3 z-30 border-b-2 justify-between block items-center bg-scogof8'
            />
            <TotalCard quotesArrayName={quotesArrayName} isCustomerFeasibilityEligible={isCustomerFeasibilityEligible} />
        </div>
    );
};
