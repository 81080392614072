import { useEffect, useRef, useState } from "react"
import ButtonScogoPrimary from "../../../common/Buttons/ButtonScogoPrimary"
import { defaultSingleChatPic } from "../../chat/ChatController"

let mediastream;
const JoinNow = ({ loggedUser, userName, handleJoin }) => {
    const [buttonLoading, setButtonLoading] = useState(false);
    const audioRef = useRef(null);

    const handleClick = async () => {
        setButtonLoading(true);
        const audio = audioRef.current;
        await audio.play();
        audio.muted = false;
        await audio.play();
        handleJoin();
    }

    const getPermission = async () => {
        mediastream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
    }

    useEffect(() => {
        getPermission();
        return () => {
            mediastream?.getTracks()?.forEach(track => track.stop());
        }
    }, []);

    return (
        <div className='flex flex-col justify-center items-center gap-4'>
            <div className={`w-[35rem] md:w-[40rem] h-[22.7rem] transition-all ease-linear duration-300 border-2 border-white bg-gray-600 rounded-3xl overflow-hidden animate-fadeIn relative hover:cursor-pointer`}>
                <img src={`${loggedUser?.profile_picture || defaultSingleChatPic}`} alt='ProfilePhoto' className=' w-52 h-52 object-cover rounded-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ' />
                <p className='text-font15 text-white absolute bottom-5 left-7 drop-shadow-[0_3px_10px_rgb(0,0,0,0.2)]'>{userName}</p>
            </div>
            <h1 className=' text-font24'>Ready to join ?</h1>
            <ButtonScogoPrimary textOrComponent={`Join Now`} onClick={handleClick} buttonClass='w-[10rem]' buttonTextClass={`text-font15`} loading={buttonLoading} />
            <video ref={audioRef} id="scogo-connect-video" src="/audio/silence.mp3" muted style={{ display: 'none' }}></video>
        </div>
    )
}

export default JoinNow