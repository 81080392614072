import React, { useState } from 'react'
import { RadioGroup } from '@headlessui/react';

const isTruthValue = (value) => {
    return !['', null, undefined].includes(value)
}

const RadioCard = (props) => {

    const { className, required, radioOptions, onChangeHandler, name, value, defaultChecked } = props
    const [selected, setSelected] = useState(defaultChecked ? radioOptions[0]?.value : undefined);

    const radioHandler = (e) => {
        onChangeHandler({ name: name, value: e })
        setSelected(e)
    }

    return (
        <>
            <RadioGroup value={isTruthValue(value) ? value : selected} onChange={radioHandler}>
                <div className={`${className ? className : ''} gap-2 text-sm radio-card rounded flex`}>
                    {radioOptions.map((radio) => (
                        <RadioGroup.Option key={radio.value} value={radio.value}>
                            {({ active, checked }) => {
                                return (
                                    <>
                                        <input
                                            label={radio?.label}
                                            required={required}
                                            type='radio'
                                            className={`appearance-none outline-none cursor-pointer rounded p-2 font-normal text-black text-base transition-all duration-100 ease-linear w-auto h-full focus:ring-0 focus:ring-offset-0`}
                                            id={radio.radioName + '1'}
                                            name={radio.radioName}
                                            checked={checked}
                                            onChange={() => { }}
                                        />
                                    </>
                                );
                            }}
                        </RadioGroup.Option>
                    ))}
                </div>
            </RadioGroup>
        </>
    )
}

export default RadioCard