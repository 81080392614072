import * as React from 'react';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

const OrangeSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase': {
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#F48A21',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
    },
}));

const label = { inputProps: { 'aria-label': 'Color switch demo' } };

export default function ActiveOrangeSwitch({ onChange, isChecked }) {
    return (
        <div>
            <OrangeSwitch {...label} checked={isChecked} onChange={onChange} size='small' />
        </div>
    );
}
