import Popover from '@mui/material/Popover';
import IconToolTip from '../../../common/IconToolTip';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { selectPeers, useHMSStore } from '@100mslive/react-sdk';
import { ParticipantsList } from './ParticipantsList';
import Divider from '@mui/material/Divider';
import { useState } from 'react';

export const ParticipantsPopover = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const peers = useHMSStore(selectPeers);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <IconToolTip title="Participants">
                <div className='h-full flex items-center' onClick={handleClick}>
                    <PeopleAltIcon sx={{ fontSize: 28 }} className='hover:cursor-pointer hover:text-gray-600 transition-all' />
                </div>
            </IconToolTip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <div className=''>
                    {
                        peers.length > 0 && peers.map((peer) => {
                            return (
                                <div className='flex flex-col'>
                                    <ParticipantsList key={peer.id} peer={peer} />
                                    <Divider />
                                </div>
                            )
                        })
                    }
                </div>
            </Popover>
        </>
    )
}
