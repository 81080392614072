import { fm } from '../types';
import { validateDateValue, validateNumberValue, validateStringValue } from '../utils/common';

const INITIAL_STATE = {
    error: undefined,
};

const fmReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case fm.LIST_RECEIVABLES_GENERATE_INVOICE_SUCCESS: {
            const transformedList = action.payload.map((project) => ({
                customerUserId: project?.customer_user_id,
                company_name: validateStringValue(project.customer_company_name),
                customer_name: `${validateStringValue(project.first_name)} ${validateStringValue(project.last_name)}`,
                customer_mobile: project.mobile,
                project_name: validateStringValue(project.project_name),
                project_number: validateStringValue(project.project_number),
                total_signoff_tickets: validateNumberValue(project.total_signoff_tickets),
                outstanding_amount: validateNumberValue(project.outstanding_amount),
                project_payment_days: validateNumberValue(project.project_payment_days),
                total_tickets: validateNumberValue(project.total_tickets),
                total_invoice_generated_tickets: validateNumberValue(project.total_invoice_generated_tickets),
                total_pending_tickets: validateNumberValue(project.total_invoice_generate_pending_tickets),
                created_at: validateDateValue(project.created_at),
                fk_project_id: validateNumberValue(project.fk_project_id),
                fk_customer_id: validateNumberValue(project.fk_customer_id),
            }));
            const projectFilterData = {};
            transformedList.forEach((item) => {
                let customer = {
                    id: item.fk_customer_id,
                    name: item.company_name,
                };
                if (projectFilterData['customers']) {
                    let isAlreadyExit = projectFilterData['customers'].find((i) => i.id === item.fk_customer_id);
                    if (!isAlreadyExit && customer.id) projectFilterData['customers'].push(customer);
                } else projectFilterData['customers'] = [customer];
            });

            return {
                ...state,
                fmReceivables: transformedList,
                projectFilterData,
            };
        }

        case fm.LIST_RECEIVABLES_PROJECT_TICKETS_SUCCESS: {
            const transformedList = action.payload.tickets.map((ticket) => {
                let spCost = ticket.additional_amount_paid + ticket.partner_base_payment + ticket.additional_expenses;
                let clCost = ticket.cluster_base_payment + ticket.additional_incentive;
                let pspCost = ticket.psp_base_payment + ticket.psp_additional_incentive;
                let superVisorCost = ticket.supervisor_base_payment + ticket.supervisor_amount_paid;
                let warehouseCost = ticket.warehouse_owner_base_payment + ticket.warehouse_expenses;
                let totalCost = spCost + clCost + pspCost + superVisorCost + warehouseCost;
                return {
                    customerUserId: ticket.customer_user_id,
                    company_name: validateStringValue(ticket.customer_company_name),
                    project_name: validateStringValue(ticket.project_name),
                    customer_name: `${validateStringValue(ticket.first_name)} ${validateStringValue(ticket.last_name)}`,
                    customer_mobile: ticket.mobile,
                    job_ticket_number: ticket.job_ticket_number,
                    payment_terms: validateNumberValue(ticket.payment_terms),
                    total_amount: validateNumberValue(ticket.total_amount),
                    total_cost: totalCost,
                    job_sign_off_date: validateDateValue(ticket.job_sign_off_date),
                    is_signoff_accept: validateNumberValue(ticket.is_signoff_accept),
                    additional_amount_paid: validateNumberValue(ticket.additional_amount_paid),
                    partner_base_payment: validateNumberValue(ticket.partner_base_payment),
                    cluster_base_payment: validateNumberValue(ticket.cluster_base_payment),
                    additional_incentive: validateNumberValue(ticket.additional_incentive),
                    psp_base_payment: validateNumberValue(ticket.psp_base_payment),
                    psp_additional_incentive: validateNumberValue(ticket.psp_additional_incentive),
                    supervisor_base_payment: validateNumberValue(ticket.supervisor_base_payment),
                    supervisor_amount_paid: validateNumberValue(ticket.supervisor_amount_paid),
                    warehouse_owner_base_payment: validateNumberValue(ticket.warehouse_owner_base_payment),
                    warehouse_expenses: validateNumberValue(ticket.warehouse_expenses),
                    customer_gst_number: validateStringValue(ticket.customer_gst_number),
                    customer_address_1: validateStringValue(ticket.customer_address_1),
                    customer_address_2: validateStringValue(ticket.customer_address_2),
                    external_site_id: validateStringValue(ticket.external_site_id),
                    job_site_address_1: validateStringValue(ticket.job_site_address_1),
                    site_branch_name: validateStringValue(ticket.site_branch_name),
                    ticket_amount: validateNumberValue(ticket.ticket_amount),
                    ticket_id: validateNumberValue(ticket.id),
                    customer_invoice_id: validateNumberValue(ticket.customer_invoice_id),
                    customer_id: validateNumberValue(ticket.customer_id),
                    project_id: validateNumberValue(ticket.project_id),
                    created_at: validateNumberValue(ticket.created_at),
                    customer_additional_expenses: validateNumberValue(ticket.customer_additional_expenses),
                    isCustomerInvoiceGenerated: ticket.customer_invoice_id ? 'Yes' : 'No', // Adding Key For Easy In Filter
                    ticketStatus: ticket.is_signoff_accept === 1 ? 'Accepted' : 'Pending', // Added Key For Easy In Filter
                };
            });

            transformedList.sort((a, b) => {
                return new Date(b.created_at) - new Date(a.created_at);
            });

            return {
                ...state,
                projectTickets: transformedList,

                projectTicketsCount: action.payload.count,
            };
        }

        case fm.GET_CB_TICKET_FILTER_LIST_SUCCESS: {
            return {
                ...state,
                cbTicketFilterList: {
                    ...action.payload,
                    customerInvoiceGenerated: [
                        { id: 'Yes', title: 'Yes' },
                        { id: 'No', title: 'No' },
                    ],
                    ticketStatus: [
                        { id: 1, title: 'Accepted' },
                        { id: 0, title: 'Pending' },
                    ],
                },
            };
        }

        case fm.REVENUES_LIST_SUCCESS: {
            const revenuesList = action.payload.map((item) => ({
                customerUserId: item.customer_user_id,
                customer_invoice_id: item.customer_invoice_id,
                invoice_date: item.invoice_date,
                invoice_number: `${validateStringValue(item.invoice_prefix)}/${validateStringValue(item.invoice_no)}/${validateStringValue(item.invoice_postfix)}`,
                customer_company_name: item.customer_company_name,
                total_invoice_amount: item.total_invoice_amount,
                payment_date: item.payment_date,
                payment_message: item.payment_message,
                payment_received: item.payment_received,
                paymentLabel: item.payment_received === 1 ? 'Received' : '', // Adding Key For Easy In Filter
            }));

            revenuesList.sort((a, b) => {
                return new Date(b.invoice_date) - new Date(a.invoice_date);
            });

            const invoicesFilterData = {
                status: ['Received', 'Payment Not Received', 'Halted'],
            };

            revenuesList.forEach((item) => {
                let customer = {
                    id: item.fk_customer_id,
                    name: item.customer_company_name,
                };
                if (invoicesFilterData['customers']) {
                    let isAlreadyExit = invoicesFilterData['customers'].find((i) => i.id === item.fk_customer_id);
                    if (!isAlreadyExit) invoicesFilterData['customers'].push(customer);
                } else invoicesFilterData['customers'] = [customer];
            });

            return {
                ...state,
                revenuesList,
                invoicesFilterData,
            };
        }

        case fm.REMOVE_RECEIVABLES_PROJECT_TICKETS_FROM_STORE: {
            return {
                ...state,
                projectTickets: action.payload,
            };
        }

        case fm.RECEIVABLES_PROJECT_TICKETS_INVOICE_COUNT_SUCCESS:
            return {
                ...state,
                invoiceCount: action.payload,
            };

        case fm.CB_GENERATE_INVOICE_FAILURE:
        case fm.UPDATE_CUSTOMER_INVOICE_STATUS_FAILURE:
        case fm.REVENUES_LIST_FAILURE:
        case fm.DOWNLOAD_CUSTOMER_INVOICE_FAILURE:
        case fm.LIST_RECEIVABLES_PROJECT_TICKETS_FAILURE:
        case fm.LIST_RECEIVABLES_GENERATE_INVOICE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return {
                ...state,
            };
    }
};

export default fmReducer;
