import React from 'react';
import moment from 'moment';

export default function DateDivider({ timestamp, borderWidth = "w-[80%] ", timeWidth = "w-full", isChatPopup }) {
    if (timestamp) {
        let time = '';
        if (moment(timestamp).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
            time = 'Today';
        } else if (moment(timestamp).format('YYYY-MM-DD') === moment().subtract(1, 'day').format('YYYY-MM-DD')) {
            time = 'Yesterday';
        } else {
            time = moment(timestamp).format('dddd, MMMM Do YYYY');
        }
        return (
            <div className={`flex w-full  items-center  dateDivider `}>
                {<div className={`border ${borderWidth} border-scogoddd`}></div>}
                <div className={`max-w-max flex ${timeWidth} justify-center text-scogogray`}>
                    <div>{time}</div>
                </div>
                {<div className={`border ${borderWidth} border-scogoddd`}></div>}
            </div>
        );
    }
};

