import { conversations } from '../types';
import { getMediaCategory, removeDuplicates, validateStringValue } from '../utils/common';
import { chatType, messageType } from '../pages/chat/ChatController';
import moment from 'moment';

const chatBoxElementData = JSON.parse(localStorage.getItem('chatBoxElementData')) || {};
const chatBoxElementDataList = Object.keys(chatBoxElementData).reduce((acc, key) => {
    if (key) {
        acc[key] = chatBoxElementData[key];
    }
    return acc;
}, {});

const INITIAL_STATE = {
    error: undefined,
    chatList: [],
    openedConversationId: undefined,
    leftConversationId: 0,
    hasReachedChatListEnd: false,
    hasReachedMediaListEnd: false,
    conversationMedia: {},
    conversationMessages: {},
    conversationDetails: {},
    searchConversationMessages: [],
    messageQueueList: {},
    searchQuery: '',
    singleChatDetails: {},
    openedConversationUnreadableMessageCount: 0,
    openedConversationMessageIsLatest: {},
    sideBarMedia: {},
    mpConversationUserTyping: {},
    latestMessagesIds: {},
    gptChatList: [],
    hasReachedChatGptListEnd: false,
    videoMeetInviteUsers: [],
    chatTickets: {},
    partialFileAttachment: {},
    chatBoxElementData: chatBoxElementDataList,
};

const conversationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case conversations.GET_CONVERSATIONS_SUCCESS: {
            const { chatsList, toAppend } = action.payload;
            let hasReachedChatListEnd = chatsList.length === 0;
            if (toAppend) {
                return {
                    ...state,
                    chatList: [...state.chatList, ...chatsList],
                    hasReachedChatListEnd
                };
            } else {
                return {
                    ...state,
                    chatList: chatsList,
                    hasReachedChatListEnd
                };
            }
        }
        case conversations.GET_NEXT_CONVERSATIONS_SUCCESS: {
            const { chatsList } = action.payload;
            const filteredChats = removeDuplicates([...state.chatList, ...chatsList]);
            const hasReachedChatListEnd = chatsList.length === 0;
            return {
                ...state,
                chatList: filteredChats,
                hasReachedChatListEnd: hasReachedChatListEnd,
            };
        }

        case conversations.GET_CONVERSTAION_DETAILS_SUCCESS: {
            const { conversationDetail, _id } = action.payload;
            return {
                ...state,
                openedConversationId: _id,
                hasReachedMediaListEnd: INITIAL_STATE.hasReachedMediaListEnd,
                conversationDetails: { ...state.conversationDetails, [_id]: conversationDetail },
                conversationDetailsLoadFailure: false
            };
        }

        case conversations.GET_CONVERSATION_MEDIA_SUCCESS: {
            let { mediaList, _id, category } = action.payload;
            return {
                ...state,
                conversationMedia: { ...state.conversationMedia, [_id]: { ...state.conversationMedia[_id], [category]: mediaList } },
            };
        }

        case conversations.GET_NEXT_CONVERSATION_MEDIA_SUCCESS: {
            const { mediaList, _id, category } = action.payload;
            let updatedMediaList = state?.conversationMedia?.[_id] ? state?.conversationMedia?.[_id][category] : [];
            let hasReachedMediaListEnd = state.hasReachedMediaListEnd;

            hasReachedMediaListEnd = mediaList.length === 0;
            updatedMediaList.push(...mediaList);

            return {
                ...state,
                conversationMedia: { ...state.conversationMedia, [_id]: { ...state.conversationMedia[_id], [category]: removeDuplicates(updatedMediaList) } },
                hasReachedMediaListEnd,
            };
        }

        case conversations.GET_CONVERSTAION_MESSAGES_SUCCESS: {
            const { messages, _id, offset } = action.payload;

            let isLatest = true;
            if (offset === undefined) {
                messages[messages.length - 1].isLatest = true;
            } else {
                isLatest = false;
            }

            return {
                ...state,
                conversationMessages: { ...state.conversationMessages, [_id]: messages },
                openedConversationMessageIsLatest: { ...state.openedConversationMessageIsLatest, [_id]: isLatest },
            };
        }

        case conversations.GET_SEARCH_CONVERSTAION_MESSAGES_SUCCESS: {
            const { messages = [] } = action.payload;
            var messagesArray = [...messages].reverse();
            return {
                ...state,
                searchConversationMessages: messagesArray,
            };
        }

        case conversations.GET_NEXT_CONVERSTAION_MESSAGES_SUCCESS: {
            const { messages, _id, noMessage, when } = action.payload;

            let newMessages;
            let isLatest = state.openedConversationMessageIsLatest[_id];
            if (when === 'before') {
                newMessages = [...messages, ...state.conversationMessages[_id]];
            } else if (when === 'after') {
                newMessages = [...state.conversationMessages[_id], ...messages];
                if (noMessage) {
                    isLatest = noMessage;
                }
            }
            if (messages.length === 0 && newMessages.length > 0 && when === 'before') {
                newMessages[0].isFirstMessage = true;
            }

            return {
                ...state,
                conversationMessages: {
                    ...state.conversationMessages,
                    [_id]: newMessages,
                },
                openedConversationMessageIsLatest: { ...state.openedConversationMessageIsLatest, [_id]: isLatest },
            };
        }
        case conversations.GET_REPLY_CONVERSTAION_MESSAGES_SUCCESS: {
            const { messages, _id, isLatest } = action.payload;
            return {
                ...state,
                conversationMessages: {
                    ...state.conversationMessages,
                    [_id]: messages,
                },
                openedConversationMessageIsLatest: {
                    ...state.openedConversationMessageIsLatest,
                    [_id]: isLatest ? isLatest : state.openedConversationMessageIsLatest[_id]?.isLatest,
                },
            };
        }
        case conversations.ADD_TO_MESSAGE_QUEUE_SUCCESS: {
            let { message } = action.payload;
            const conversationId = message.conversation;
            let defaults = {
                attachments: [],
            };
            message = { ...defaults, ...message };
            const messageQueue = Object.assign({}, state.messageQueueList);
            const latestMessagesIds = state.latestMessagesIds;

            if (messageQueue[conversationId]) {
                messageQueue[conversationId] = messageQueue[conversationId]
                    .filter((msg) => {
                        return msg.messageId !== message.messageId;
                    })
                    .concat(message);
            } else messageQueue[conversationId] = [message];
            latestMessagesIds[message.conversation] = message.messageId;

            return {
                ...state,
                messageQueueList: messageQueue,
                latestMessagesIds
            };
        }

        case conversations.EDITED_MESSAGE_QUEUE: {
            let { message } = action.payload;
            const { conversation, messageId: edited_msg_id, editedMessage } = message;
            const messageList = Object.assign({}, state.conversationMessages);
            if (messageList[conversation]) {
                messageList[conversation] = messageList[conversation].map((msg) => {
                    let current_msg = msg;
                    let prevMessage = current_msg.message; // storing key for use if edit message error
                    if (msg._id === edited_msg_id) {
                        if (!current_msg.prevMessage) current_msg.prevMessage = prevMessage;
                        current_msg.message = editedMessage;
                        current_msg.isEditLoading = true;
                    }
                    return current_msg;
                });
            }

            return {
                ...state,
                conversationMessages: messageList,
            };
        }

        case conversations.CLOSE_CONVERSATION_SUCCESS: {
            const { conversation } = action.payload;
            const { _id, active, closedAt, closedBy } = conversation;
            let chats = [...state.chatList];
            let updatedChatList = chats.filter((chat) => chat._id !== _id);

            let updatedConversationDetails = JSON.parse(JSON.stringify(state.conversationDetails));
            if (updatedConversationDetails[_id]) {
                updatedConversationDetails[_id] = { ...updatedConversationDetails[_id], active, closedAt, closedBy };
            }

            return {
                ...state,
                chatList: updatedChatList,
                conversationDetails: updatedConversationDetails,
            };
        }

        case conversations.UPDATE_BOT_ENABLED_CONVERSATION: {
            const { conversation, removeChat } = action.payload;
            const { _id, isBotEnabled } = conversation;
            let chats = [...state.chatList];
            let updatedChatList = chats;
            if (removeChat) {
                updatedChatList = chats.filter((chat) => chat._id !== _id);
            }
            let updatedConversationDetails = JSON.parse(JSON.stringify(state.conversationDetails));

            if (updatedConversationDetails[_id]) {
                updatedConversationDetails[_id].isBotEnabled = isBotEnabled;
                updatedConversationDetails[_id].tags = [...updatedConversationDetails[_id].tags || [], ...conversation.tags || []]
            }
            return {
                ...state,
                chatList: updatedChatList,
                conversationDetails: updatedConversationDetails,
            };
        }

        case conversations.SET_OPEN_CONVERSTAION_MESSAGES_ISLATEST: {
            const { isLatest, _id } = action.payload;
            return {
                ...state,
                openedConversationMessageIsLatest: { ...state.openedConversationMessageIsLatest, [_id]: isLatest },
            };
        }

        case conversations.HANDLE_MESSAGE_FROM_WEBSOCKET_FOR_MESSAGE_LIST: {
            const { message, _id, loggedUserId } = action.payload;
            const conversationMessages = Object.assign({}, state.conversationMessages);
            const latestMap = Object.assign({}, state.openedConversationMessageIsLatest);
            let isLatest = latestMap?.[_id];
            const mpConversationUserTyping = state.mpConversationUserTyping;
            let openedConversationUnreadableMessageCount = state.openedConversationUnreadableMessageCount;
            const openedConversationId = state.openedConversationId;

            if (conversationMessages?.[_id]?.length > 0) {
                conversationMessages[_id] = conversationMessages[_id].filter((m) => m._id !== message._id).concat({ ...message, latestMessage: true });

                if (openedConversationId === _id) {
                    openedConversationUnreadableMessageCount += 1;
                }

            }

            const messageQueue = Object.assign({}, state.messageQueueList);
            if (Array.isArray(messageQueue?.[_id])) {
                messageQueue[_id] = messageQueue[_id].filter((msg) => msg.messageId !== message.messageId);
            }

            // handling for New SCOGO GPT chat created
            if (messageQueue['scogo_new_chat_created']) {
                messageQueue['scogo_new_chat_created'] = messageQueue['scogo_new_chat_created'].filter((msg) => msg.messageId !== message.messageId);
            }

            // Handling Scogo Assist typing 
            if (message.conversation.type === 'gpt') {
                if (mpConversationUserTyping[_id] && message.from.userId === -1) {
                    mpConversationUserTyping[_id] = [];
                } else if (message.from.userId === loggedUserId) {
                    mpConversationUserTyping[_id] = [{
                        userId: -1,
                        username: "Scogo Assist",
                        role: "Bot",
                        roleId: -1,
                    }]
                }
            }

            // Updating Media List When recieved attachment type message
            const conversationMedia = Object.assign({}, state.conversationMedia);
            const updatedSideBarMedia = Object.assign({}, state.sideBarMedia);
            const conversationDetails = Object.assign({}, state.conversationDetails);

            // Updating Media Count
            if (conversationDetails[_id]) {
                let count = conversationDetails[_id].mediaCount + message.attachments.length;
                conversationDetails[_id].mediaCount = count;
            }

            if (message.type === messageType.attachments) {
                if (updatedSideBarMedia[_id]) updatedSideBarMedia[_id] = [...message.attachments, ...updatedSideBarMedia[_id]];
                else updatedSideBarMedia[_id] = [...message.attachments];
                if (conversationMedia?.[_id]) {
                    message.attachments.forEach((msg) => {
                        if (!msg.message) msg.message = message._id;
                        const category = getMediaCategory(msg.type);
                        if (conversationMedia[_id][category]) {
                            conversationMedia[_id][category] = removeDuplicates([msg, ...conversationMedia[_id][category]]);
                        }
                    });
                }
            }

            return {
                ...state,
                conversationMessages: conversationMessages,
                messageQueueList: messageQueue,
                conversationMedia: conversationMedia,
                openedConversationUnreadableMessageCount,
                openedConversationMessageIsLatest: { ...state.openedConversationMessageIsLatest, [_id]: isLatest },
                sideBarMedia: updatedSideBarMedia,
                conversationDetails,
                mpConversationUserTyping
            };
        }

        case conversations.HANDLE_MESSAGE_FROM_WEBSOCKET_FOR_CHAT_LIST: {
            const { _id, conversation } = action.payload;
            const chatList = [...state.chatList].filter((chat) => chat._id !== _id);
            return {
                ...state,
                chatList: [conversation, ...chatList],
            };
        }
        case conversations.SET_CONVERSATION_UNREADABLE_MESSAGE_COUNT: {
            return {
                ...state,
                openedConversationUnreadableMessageCount: 0,
            };
        }

        case conversations.MARK_READ_CONVERSATION_SUCCESS: {
            const { conversation } = action.payload;
            const read = (convo) => {
                if (convo._id === conversation) {
                    convo.unreadMessageCount = 0;

                    return convo;
                }
                return convo;
            };
            return {
                ...state,
                chatList: state.chatList?.map?.(read),
            };
        }

        case conversations.UPDATE_UN_READ_MESSAGE_COUNT: {
            const { unreadCount, _id } = action.payload;
            let conversation = state.conversationDetails?.[_id];

            if (state.conversationDetails?.[_id]) {
                conversation = { ...conversation, unreadMessageCount: unreadCount };
            }

            return {
                ...state,
                conversationDetails: { ...state.conversationDetails, [_id]: conversation },
            };
        }

        case conversations.MESSAGE_READ_STATUS: {
            const { conversationId } = action.payload;
            let conversationType = state.conversationDetails?.[conversationId]?.type;
            const conversationMessages = state.conversationMessages;
            if (conversationMessages?.[conversationId] && conversationType === chatType.single) {
                conversationMessages[conversationId] = conversationMessages[conversationId].map((message) => {
                    return { ...message, readByAll: true };
                });
            }
            return { ...state, conversationMessages };
        }
        case conversations.GET_CONVERSATION_COUNT_SUCCESS: {
            return { ...state, conversationCount: action.payload };
        }

        case conversations.CHANGE_CONVERSATION_COUNT: {
            const { countNeedTo, type } = action.payload;
            let newCount = state.conversationCount;
            if (newCount) {
                if (countNeedTo === 'increase') {
                    newCount[type].open = state.conversationCount[type].open + 1;
                } else if (countNeedTo === 'both') {
                    newCount[type].open = state.conversationCount[type].open - 1;
                    newCount[type].closed = state.conversationCount[type].closed + 1;
                }
            }
            return { ...state, conversationCount: newCount };
        }

        case conversations.ADD_MEMBERS_TO_CHAT_FROM_WEBSOCKET: {
            const { _id, newMembers } = action.payload;
            const conversationDetails = Object.assign({}, state.conversationDetails);
            if (conversationDetails?.[_id]?.users) {
                conversationDetails[_id].users = [...conversationDetails[_id].users, ...newMembers];
            }
            return {
                ...state,
                conversationDetails,
            };
        }
        case conversations.ADD_TEAMS_TO_CHAT_FROM_WEBSOCKET: {
            const { _id, teams } = action.payload;
            const conversationDetails = Object.assign({}, state.conversationDetails);
            if (conversationDetails?.[_id]) {
                const prevTeams = conversationDetails[_id].teams || [];
                conversationDetails[_id].teams = [...prevTeams, ...teams];
            }
            return {
                ...state,
                conversationDetails,
            };
        }
        case conversations.REMOVE_TEAM_FROM_CHAT_FROM_WEBSOCKET: {
            const { _id, teamId } = action.payload;
            const conversationDetails = Object.assign({}, state.conversationDetails);
            if (conversationDetails?.[_id]?.teams) {
                conversationDetails[_id].teams = conversationDetails[_id].teams.filter((team) => team.id !== teamId);
            }
            return {
                ...state,
                conversationDetails,
            };
        }

        case conversations.EDITED_MESSAGE_FROM_WEBSOCKET: {
            const { message, conversationId } = action.payload;
            const conversation_messages = Object.assign({}, state.conversationMessages);
            if (Array.isArray(conversation_messages[conversationId]) && conversation_messages[conversationId].length > 0) {
                conversation_messages[conversationId] = conversation_messages[conversationId].map((msg) => {
                    if (msg._id === message._id) {
                        return message;
                    }
                    return msg;
                });
            }

            return {
                ...state,
                conversationMessages: conversation_messages,
            };
        }

        case conversations.DELETED_MESSAGE_FROM_WEBSOCKET: {
            const { message, conversationId } = action.payload;
            let conversation_messages = state.conversationMessages;
            if (Array.isArray(conversation_messages[conversationId]) && conversation_messages[conversationId].length > 0) {
                conversation_messages[conversationId] = conversation_messages[conversationId].filter((msg) => msg._id !== message._id);
            }

            // Updating Media List When deleted attachment type message
            let mediaList = Object.assign({}, state.conversationMedia);
            const updatedSideBarMedia = Object.assign({}, state.sideBarMedia);
            const conversationDetails = Object.assign({}, state.conversationDetails);
            if (message.type === messageType.attachments) {
                if (updatedSideBarMedia[conversationId]) {
                    updatedSideBarMedia[conversationId] = updatedSideBarMedia[conversationId].filter((media) => !message.attachments.includes(media._id));
                }

                // Updating Media Count
                if (conversationDetails[conversationId]) {
                    let count = conversationDetails[conversationId].mediaCount - message.attachments.length;
                    conversationDetails[conversationId].mediaCount = count;
                }

                if (mediaList[conversationId]) {
                    Object.entries(mediaList[conversationId]).forEach(([key, values]) => {
                        mediaList[conversationId][key] = values.filter((media) => !message.attachments.includes(media._id));
                    });
                }
            }

            // delete from conversation's lastMessage
            const chatList = state.chatList?.map?.((convo) => {
                if (convo._id === conversationId && convo.lastMessage?._id === message._id) {
                    return { ...convo, lastMessage: { ...convo.lastMessage, deletedFor: 'EVERYONE' } };
                }
                return convo;
            });

            return {
                ...state,
                conversationMessages: conversation_messages,
                conversationMedia: mediaList,
                sideBarMedia: updatedSideBarMedia,
                conversationDetails,
                chatList,
            };
        }

        case conversations.LEAVE_CONVERSATION_SUCCESS: {
            const { exitId } = action.payload;
            const updatedGroupList = state.chatList.filter((group) => group._id !== exitId);
            return {
                ...state,
                leftConversationId: exitId,
                chatList: updatedGroupList,
            };
        }
        case conversations.CREATE_GROUP_CONVERSATION_SUCCESS: {
            return {
                ...state,
                chatList: [action.payload.createdConversation, ...state.chatList],
            };
        }

        case conversations.SET_SEARCH_CONVERSATION_UNREADABLE_MESSAGE_COUNT: {
            return {
                ...state,
                searchMesCount: action.payload,
            };
        }

        case conversations.TAG_PROJECT_SUCCESS: {
            const { project, conversationId } = action.payload;
            let updatedConversationDetails = JSON.parse(JSON.stringify(state.conversationDetails));
            if (updatedConversationDetails[conversationId]) {
                updatedConversationDetails[conversationId].projects = updatedConversationDetails[conversationId].projects
                    .filter((proj) => proj.id !== project.id)
                    .concat({ id: project.id, name: project.project_name });
            }
            return {
                ...state,
                conversationDetails: updatedConversationDetails,
            };
        }

        case conversations.UNTAG_PROJECT_SUCCESS: {
            const { project, conversationId } = action.payload;
            const untagProjectId = project.id;
            const updatedConversationDetails = JSON.parse(JSON.stringify(state.conversationDetails));
            if (updatedConversationDetails[conversationId]) {
                updatedConversationDetails[conversationId].projects = updatedConversationDetails[conversationId]?.projects.filter((proj) => proj.id !== untagProjectId);
            }
            return {
                ...state,
                conversationDetails: updatedConversationDetails,
            };
        }

        case conversations.ADD_MEMBER_IN_CONVERSATION_SUCCESS: {
            const { newMember, conversationId } = action.payload;
            let updatedConversationDetails = JSON.parse(JSON.stringify(state.conversationDetails));
            let member = {
                _id: newMember._id,
                email: newMember.email,
                isAdmin: newMember.isAdmin ? true : false,
                lastSeen: newMember.lastSeen,
                mobile: newMember.mobile,
                role: newMember.role,
                roleId: newMember.roleId,
                userId: newMember.userId,
                username: `${newMember.username}`,
                profilePic: newMember.profilePic,
            };
            updatedConversationDetails[conversationId].users = [...updatedConversationDetails[conversationId].users, member];

            return {
                ...state,
                conversationDetails: updatedConversationDetails,
            };
        }
        case conversations.GRANT_OR_REVOKE_ACCESS_SUCCESS: {
            const { conversationId, userId, access } = action.payload;
            let updatedConversationDetails = JSON.parse(JSON.stringify(state.conversationDetails));
            let isAdmin = false;
            if (access === 'give') isAdmin = true;
            let updatedMembers = updatedConversationDetails?.[conversationId]?.users;
            if (!updatedMembers) return state;

            updatedMembers = updatedMembers.map((member) => {
                if (member.userId === userId) {
                    return { ...member, isAdmin: isAdmin };
                }
                return member;
            });

            updatedConversationDetails[conversationId].users = updatedMembers;
            return {
                ...state,
                conversationDetails: updatedConversationDetails,
            };
        }

        case conversations.REMOVE_FROM_CONVERSATION_SUCCESS: {
            const { leftUserId, conversationId } = action.payload;
            const updatedConversationDetails = JSON.parse(JSON.stringify(state.conversationDetails));
            updatedConversationDetails[conversationId].users = updatedConversationDetails[conversationId].users.filter((user) => user.userId !== leftUserId);
            return {
                ...state,
                conversationDetails: updatedConversationDetails,
            };
        }

        case conversations.CHANGE_NOTIFICATION_SETTINGS_SUCCESS: {
            const { _id, sendNotificationsFor } = action.payload;
            const updatedChatList = state.chatList?.map?.((chat) => {
                if (chat._id === _id) return { ...chat, sendNotificationsFor };
                return chat;
            });
            const updatedConversationDetails = Object.assign({}, state.conversationDetails);
            if (updatedConversationDetails?.[_id]) {
                updatedConversationDetails[_id].sendNotificationsFor = sendNotificationsFor;
            }
            return {
                ...state,
                chatList: updatedChatList,
                conversationDetails: updatedConversationDetails,
            };
        }

        case conversations.UPDATE_CONVERSATION_DETAILS_SUCCESS: {
            const { conversationName, conversationImage, requestDescription, _id } = action.payload;

            const updatedChatList = state.chatList?.map?.((chat) => {
                if (chat._id === _id) {
                    return { ...chat, conversationImage, conversationName, requestDescription };
                } else return chat;
            });
            const openedConversationId = state.openedConversationId;
            const conversation = state.conversationDetails?.[openedConversationId];
            let updatedOpenedConversation;
            if (conversation) {
                updatedOpenedConversation = { ...conversation, conversationName, conversationImage, requestDescription };
            }
            return {
                ...state,
                chatList: updatedChatList,
                conversationDetails: { ...(state.conversationDetails || {}), [openedConversationId]: updatedOpenedConversation },
            };
        }

        case conversations.GET_SINGLE_CHAT_USER_INFO_DETAILS_SUCCESS: {
            const { userId, userDetails } = action.payload;
            let user = {
                id: userDetails.id,
                profile_pic: userDetails.profile_picture,
                userName: `${validateStringValue(userDetails.first_name)} ${validateStringValue(userDetails.last_name)}`,
                email: validateStringValue(userDetails.email),
                mobile: validateStringValue(userDetails.mobile),
                userRole: validateStringValue(userDetails.role.role_name),
            };
            return { ...state, singleChatDetails: { ...state.singleChatDetails, [userId]: user } };
        }

        case conversations.GET_SINGLE_CHAT_CUSTOMER_DETAILS_SUCCESS: {
            const { userId, userDetails } = action.payload;
            let user = {
                id: userDetails.id,
                profile_pic: userDetails.profilePic,
                userName: validateStringValue(userDetails.username),
                email: validateStringValue(userDetails.email),
                mobile: validateStringValue(userDetails.mobile),
                userRole: validateStringValue(userDetails.role),
            };

            return { ...state, singleChatDetails: { ...state.singleChatDetails, [userId]: user } };
        }
        case conversations.GET_SINGLE_CHAT_SP_USER_DETAILS_SUCCESS: {
            const { userId, userDetails } = action.payload;
            let user = {
                id: userDetails.user.id,
                profile_pic: userDetails?.user?.profile_pic,
                userName: `${validateStringValue(userDetails?.user?.first_name)} ${validateStringValue(userDetails?.user?.last_name)}`,
                email: validateStringValue(userDetails?.user?.email),
                mobile: validateStringValue(userDetails?.user?.mobile),
                userRole: validateStringValue(userDetails.role),
                sp_name: validateStringValue(userDetails.sp_name),
                referral_code: userDetails.referral_code,
                address: `${validateStringValue(userDetails.sp_address_line_1)} ${validateStringValue(userDetails.sp_address_line_2)}`,
                pancard_number: userDetails.sp_pan_card_number,
                sp_gst_no: userDetails.sp_gst_no,
                sp_adhaar_number: userDetails.sp_adhaar_number,
                document_folder_id: userDetails.document_folder_id,
                officeLocations: userDetails.office,
                serviceLocations: userDetails.services,
                siteTools: userDetails.site_tools,
                spBankHolderName: userDetails?.bank?.sp_bank_account_holder_name,
                spBankName: userDetails?.bank?.sp_bank_name,
                spBankAccountNumber: userDetails?.bank?.sp_bank_account_number,
                spBankAccountType: userDetails?.bank?.sp_bank_account_type_name,
                spBankAccountIFSCCode: userDetails?.bank?.sp_bank_ifsc_code,
                fe_adhar_card_image: userDetails?.fe_adhar_card_image,
                sp_pan_card_image: userDetails?.sp_pan_card_image,
            };

            return { ...state, singleChatDetails: { ...state.singleChatDetails, [userId]: user } };
        }

        case conversations.GET_SINGLE_CHAT_FE_USER_DETAILS_SUCCESS: {
            const { userId, userDetails } = action.payload;
            let user = {
                id: userDetails.user.id,
                profile_pic: userDetails?.user?.profile_pic,
                userName: `${validateStringValue(userDetails?.user?.first_name)} ${validateStringValue(userDetails?.user?.last_name)}`,
                email: validateStringValue(userDetails?.user?.email),
                mobile: validateStringValue(userDetails?.user?.mobile),
                userRole: validateStringValue(userDetails?.user?.role),
                address: `${validateStringValue(userDetails?.engineer_address_1)} ${validateStringValue(userDetails?.engineer_address_2)}, ${userDetails?.fk_pincode_id ? userDetails.fk_pincode_id : ''
                    } `,
                pancard_number: validateStringValue(userDetails?.user?.pancard_number),
                sp_adhaar_number: userDetails?.adhar_number ? userDetails.adhar_number : '',
                document_folder_id: userDetails?.user?.document_folder_id,
            };
            return { ...state, singleChatDetails: { ...state.singleChatDetails, [userId]: user }, feProfileDetails: user };
        }
        case conversations.LEAVE_CONVERSATION_FROM_WEBSOCKET: {
            const { leavedChatIds, userId, loggedUserId } = action.payload;
            let chat_list = state.chatList || [];
            let isLeavedConversationOpened = leavedChatIds?.find((chatId) => chatId === state.openedConversationId);
            const conversationDetails = Object.assign({}, state.conversationDetails);
            leavedChatIds.forEach((chatId) => {
                if (Array.isArray(conversationDetails?.[chatId]?.users)) {
                    conversationDetails[chatId].users = conversationDetails[chatId].users.filter((user) => user.userId !== userId);
                }
            });
            if (userId === loggedUserId) {
                chat_list = chat_list.filter((chat) => !leavedChatIds?.includes(chat._id));
                if (isLeavedConversationOpened) {
                    const conversation = state.conversationDetails?.[state.openedConversationId] || {};
                    return {
                        ...state,
                        conversationDetails: { ...conversationDetails, [state.openedConversationId]: { ...conversation, hideInput: true } },
                        chatList: chat_list,
                    };
                }
                return {
                    ...state,
                    chatList: chat_list,
                    conversationDetails,
                };
            }
            return { ...state, conversationDetails };
        }
        case conversations.GET_CHAT_NOTIFICATIONS_SUCCESS: {
            const { notifications, toAppend } = action.payload;
            let chatNotfications = notifications;
            if (toAppend) {
                chatNotfications = [...state.chatNotfications, ...notifications];
            }
            return { ...state, chatNotfications };
        }
        case conversations.MARK_NOTIFICATIONS_READ_SUCCESS: {
            const { notificationId } = action.payload;
            const chatNotfications = state.chatNotfications?.map((notification) => {
                if (notification._id === notificationId || notificationId === 'all') {
                    return { ...notification, isRead: true };
                }
                return notification;
            });
            return {
                ...state,
                chatNotfications,
            };
        }

        case conversations.LIST_CONVERSATION_SIDEBAR_MEDIA_SUCCESS: {
            const { mediaList, _id } = action.payload;
            return {
                ...state,
                sideBarMedia: {
                    ...state.sideBarMedia,
                    [_id]: mediaList,
                },
            };
        }
        case conversations.RECEIVED_CHAT_NOTIFICATION: {
            let chatNotfications;
            if (state.chatNotfications) {
                chatNotfications = [action.payload, ...state.chatNotfications];
            }
            return {
                ...state,
                chatNotfications,
            };
        }
        case conversations.LIST_TICKET_CUSTOMER_REPORT_MEDIA_SUCCESS: {
            return {
                ...state,
                customerReportMedia: action.payload,
            };
        }
        case conversations.REMOVE_CUSTOMER_REPORT_MEDIA_FROM_STORE: {
            return {
                ...state,
                customerReportMedia: undefined,
            };
        }
        case conversations.APPROVE_OR_REJECT_IMAGE_SUCCESS: {
            const { conversationId, messageId, attachment } = action.payload;
            const conversationMessages = state.conversationMessages;
            let sideBarMedia = state.sideBarMedia;
            let partialFileAttachment = state.partialFileAttachment;

            const partialFileUrl = attachment.fileUrl && new URL(attachment.fileUrl).pathname.substring(1);
            if (partialFileAttachment[partialFileUrl]) {
                partialFileAttachment = { ...state.partialFileUrl, [partialFileUrl]: attachment };
            }
            if (sideBarMedia[conversationId]) {
                const updatedMedia = sideBarMedia[conversationId].map((media) => {
                    if (media._id === attachment._id) return attachment;
                    return media;
                });
                sideBarMedia = { ...state.sideBarMedia, [conversationId]: updatedMedia };
            }

            if (Array.isArray(state.conversationMessages?.[conversationId])) {
                const conversationMediaMessage = state.conversationMedia[conversationId]?.images?.map((message) => {
                    if (message?._id === attachment?._id) {
                        return attachment;
                    }
                    return message;
                });
                const findMessageIndex = conversationMessages[conversationId].findIndex((message) => message._id === messageId);
                if (findMessageIndex && conversationMessages[conversationId][findMessageIndex]?.attachments) {
                    conversationMessages[conversationId][findMessageIndex].attachments = conversationMessages[conversationId][findMessageIndex].attachments.map((a) => {
                        if (a._id === attachment._id) {
                            return attachment;
                        }
                        return a;
                    })
                };


                return {
                    ...state,
                    conversationMessages,
                    conversationMedia: { ...state.conversationMedia, [conversationId]: { images: conversationMediaMessage } },
                    partialFileAttachment,
                    sideBarMedia
                };
            }
            return { ...state, partialFileAttachment, sideBarMedia };
        }
        case conversations.APPROVE_OR_REJECT_CARD_MESSAGE_SUCCESS: {
            const { conversationId, messageId, promptResponses } = action.payload;
            let conversationMessages = { ...state.conversationMessages };

            if (Array.isArray(conversationMessages?.[conversationId])) {
                let tempArr = [...conversationMessages?.[conversationId]];
                tempArr = tempArr.map((message) => {
                    if (message?._id === messageId) {
                        return { ...message, promptResponses };
                    }
                    return message;
                });
                conversationMessages = { ...conversationMessages, [conversationId]: tempArr }
                return { ...state, conversationMessages };
            }
            return state;
        }
        case conversations.TYPING_IN_CHAT: {
            const { conversationId, user } = action.payload;

            let usersTyping = state.mpConversationUserTyping[conversationId] || [];

            let found = false;
            usersTyping = usersTyping.map((u) => {
                if (u.userId === user.userId) {
                    found = true;
                    return { ...u, updatedAt: moment() };
                }
                return u;
            });
            if (!found) {
                usersTyping.push({ ...user, updatedAt: moment() });
            }

            return { ...state, mpConversationUserTyping: { ...state.mpConversationUserTyping, [conversationId]: usersTyping } };
        }
        case conversations.REMOVE_TYPING_IN_CHAT: {
            let messageFromId;
            if (action.payload) {
                messageFromId = action.payload?.messageFromId;
            }
            if (!state.mpConversationUserTyping) return state;

            const now = moment();
            const newKeys = Object.entries(state.mpConversationUserTyping).map(([conversationId, users]) => {
                const filteredUsers = users.filter((user) => {
                    if (messageFromId) {
                        return user.userId !== messageFromId;
                    }
                    return now.diff(user.updatedAt, 'seconds') < 3;
                });

                return { [conversationId]: filteredUsers };
            });

            const newTypingObj = Object.assign({}, ...newKeys);

            return { ...state, mpConversationUserTyping: newTypingObj };
        }

        case conversations.SEND_SCOGO_GPT_MESSAGE_SUCCESS: {
            return {
                ...state
            }
        };

        case conversations.GET_ATTACHMENT_WITH_PARTIAL_URL_SUCCESS: {
            const { attachment, partialFileUrl } = action.payload;
            return {
                ...state,
                partialFileAttachment: { ...state.partialFileAttachment, [partialFileUrl]: attachment }
            }
        }
        case conversations.NEW_SCOGO_GPT_CHAT_FROM_WEBSOCKET: {
            const { conversation, message, loggedUserId } = action.payload;
            const conversationMessages = state.conversationMessages;
            conversationMessages[conversation._id] = [message];
            const mpConversationUserTyping = state.mpConversationUserTyping;
            if (message.conversation.type === 'gpt' && message.from.userId === loggedUserId) {
                mpConversationUserTyping[conversation._id] = [{
                    userId: -1,
                    username: "Scogo Assist",
                    role: "Bot",
                    roleId: -1,
                }]
            }
            return {
                ...state,
                openedConversationId: conversation._id,
                gptChatList: [conversation, ...state.gptChatList],
                conversationMessages,
                mpConversationUserTyping
            }
        }
        case conversations.OPEN_CHAT_ELEMENT_SUCCESS: {
            const { _id, status, ticket, conversationName } = action.payload;
            const chatBoxElementData = { ...state.chatBoxElementData };
            if (chatBoxElementData[_id]) delete chatBoxElementData[_id];
            const chatTickets = { ...state.chatTickets };
            let fe_name = validateStringValue(ticket?.fe_name);
            if (ticket) {
                chatTickets[ticket.ticket_id] = ticket;
            }
            if (ticket && chatTickets[ticket.ticket_id]) fe_name = chatTickets[ticket.ticket_id].fe_name;
            const updatedChatBoxElementData = { ...chatBoxElementData, [_id]: { status, fe_name, conversationName } };
            localStorage.setItem('chatBoxElementData', JSON.stringify(updatedChatBoxElementData));
            return {
                ...state,
                chatBoxElementData: updatedChatBoxElementData,
                chatTickets
            };
        }
        case conversations.CLOSE_ALL_OPEN_CHATS: {
            localStorage.removeItem('chatBoxElementData')
            return {
                ...state,
                chatBoxElementData: {}
            }
        }
        case conversations.UPDATE_CONVO_ON_PAY_PO_TAX_SUCCESS: {
            const { paymentDetails, _id } = action.payload;
            const conversationDetails = state.conversationDetails;
            if (conversationDetails && conversationDetails[_id] && conversationDetails?.[_id]?.ticket?.po?.paymentDetails) {
                conversationDetails[_id].ticket.po.paymentDetails = paymentDetails;
            }
            return { ...state, conversationDetails }
        }

        case conversations.GET_CONVERSTAION_DETAILS_FAILURE: {
            const { message, conversationDetailsLoadFailure } = action.payload
            return { ...state, conversationDetailsLoadFailure, message }
        }
        case conversations.GET_CONVERSATIONID_AND_CONVERSATION_DETAILS_SUCCESS: {
            const { conversationDetail } = action.payload;
            return { ...state, videoMeetInviteUsers: conversationDetail?.users };
        }

        case conversations.SEND_SCOGO_GPT_MESSAGE_FAILURE:
        case conversations.LIST_TICKET_CUSTOMER_REPORT_MEDIA_FAILURE:
        case conversations.GET_SINGLE_CHAT_FE_USER_DETAILS_FAILURE:
        case conversations.LIST_CONVERSATION_SIDEBAR_MEDIA_FAILURE:
        case conversations.GET_SINGLE_CHAT_USER_INFO_DETAILS_FAILURE:
        case conversations.GET_SINGLE_CHAT_SP_USER_DETAILS_FAILURE:
        case conversations.GET_SINGLE_CHAT_CUSTOMER_DETAILS_FAILURE: {
            const { userId } = action.payload;
            return { ...state, singleChatDetails: { ...state.singleChatDetails, [userId]: {} } };
        }
        case conversations.REMOVE_FROM_CONVERSATION_FAILURE:
        case conversations.ADD_MEMBER_IN_CONVERSATION_FAILURE:
        case conversations.UPDATE_CONVERSATION_DETAILS_FAILURE:
        case conversations.TAG_PROJECT_FAILURE:
        case conversations.UNTAG_PROJECT_FAILURE:
        case conversations.GET_NEXT_CONVERSTAION_MESSAGES_FAILURE:
        case conversations.GET_CONVERSATIONS_FAILURE:
        case conversations.GET_CONVERSTAION_MESSAGES_FAILURE:
        case conversations.LEAVE_CONVERSATION_FAILURE:
        case conversations.CREATE_HELP_SUPPORT_FAILURE:
        case conversations.GET_CHAT_NOTIFICATIONS_FAILURE:
        case conversations.GET_SEARCH_CONVERSTAION_MESSAGES_FAILURE:
        case conversations.APPROVE_OR_REJECT_CARD_MESSAGE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case conversations.CREATE_GROUP_CONVERSATION_LOADING: {
            const { createGroupChatLoading } = action.payload;
            return {
                ...state,
                createGroupChatLoading: createGroupChatLoading,
            };
        }
        case conversations.PUT_TO_CONVERSATIONS_STORE:
            return {
                ...state,
                ...action.payload,
            };
        case conversations.SET_SIDEBAR_MEDIA_VISIBLE_STATUS:
            return {
                ...state,
                showSideBarMedia: action.payload,
            };
        case conversations.GET_GPT_CONVERSATIONS_SUCCESS: {
            const { list, toAppend } = action.payload;
            let hasReachedChatGptListEnd = list.length === 0;
            if (toAppend) {
                return {
                    ...state,
                    gptChatList: [...state.gptChatList, ...list],
                    hasReachedChatGptListEnd
                };
            } else {
                return {
                    ...state,
                    gptChatList: list,
                    hasReachedChatGptListEnd
                };
            }
        }
        default:
            return {
                ...state,
            };
    }
};

export default conversationReducer;
