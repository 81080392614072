export const compressImage = (file) => {
    return new Promise((resolve, reject) => {
        const width = 600; // For scaling relative to width
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (ev) => {
            const img = new Image();
            img.src = ev.target.result;
            img.onload = () => {
                const element = document.createElement('canvas'); // Use Angular's Renderer2 method
                const scaleFactor = width / img.width;
                element.width = width;
                element.height = img.height * scaleFactor;
                const ctx = element.getContext('2d');
                ctx.drawImage(img, 0, 0, width, img.height * scaleFactor);
                ctx.canvas.toBlob(
                    (blob) => {
                        resolve(
                            new File([blob], file.name, {
                                type: file.type,
                                path: file.path,
                                lastModified: Date.now(),
                            })
                        );
                    },
                    file.type,
                    1
                );
            };
            img.onerror = (error) => reject(error);
        };
        reader.onerror = (error) => reject(error);
    });
};
