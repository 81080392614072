export const getTicketCountList = (state) => state.tickets.ticketsCount;

export const getFilterDetails = (state) => {
    let ticketState = state.tickets;
    return {
        limit: ticketState.rowsPerPage,
        page: ticketState.page,
        query: ticketState.searchQuery,
        tab: ticketState.tab,
        mainTab: ticketState.mainTab,
        filterPayload: ticketState.filterPayload,
    };
};

export const getViewTicket = (state) => state.tickets.viewTicket