import { onboarding } from '../types';
const INITIAL_STATE = {};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case onboarding.CHECK_DOMAIN_AVAILABILITY_SUCCESS: {
            return {
                ...state,
                domainAvailability: action.payload,
            };
        }
        case onboarding.SAVE_CUSTOMER_ONBOARDING_DETAILS_SUCCESS: {
            return {
                ...state,
                // do something
            };
        }
        case onboarding.CREATE_CUSTOMER_DOMAIN_SUCCESS: {
            return {
                ...state,
                domainCreated: action.payload,
            };
        }

        case onboarding.SP_ONBOARDING_STATUS_SUCCESS: {
            return {
                ...state,
                spOnboardingStatus: action.payload,
            };
        }
        case onboarding.UPDATE_NEW_USER_EMAIL_SUCCESS:
            return {
                ...state,
                isEmailVerified: action.payload,
            };
        case onboarding.UPDATE_USER_PROFILE_DETAILS_SUCCESS:
            let { isUserProfileUpdated, updatedEmail } = action.payload;
            return {
                ...state,
                isUserProfileUpdated,
                updatedEmail,
            };
        case onboarding.UPDATE_USER_PROFILE_DETAILS_FAILURE:
            return {
                ...state,
                isUserProfileUpdated: false,
                updatedEmail: undefined,
            };

        case onboarding.UPDATE_USER_BUSSINESS_DETAILS_SUCCESS:
            return {
                ...state,
                isUserBussinessDetailsUpdated: action.payload,
            };

        case onboarding.SAVE_FE_ONBOARDING_DETAILS_FAILURE:
        case onboarding.CREATE_CUSTOMER_DOMAIN_FAILURE:
        case onboarding.SAVE_CUSTOMER_ONBOARDING_DETAILS_FAILURE:
        case onboarding.CHECK_DOMAIN_AVAILABILITY_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }
        default:
            return {
                ...state,
            };
    }
};

export default reducer;
