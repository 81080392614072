import React from 'react';
import { defaultGroupChatImage, defaultSingleChatPic, defaultTicketChatPic, defaultHelpChatPic } from '../ChatController';

export default function ProfileImage(props) {
    let { imgUrl, className, type = 'group' } = props;
    const defaultImageLookup = {
        single: defaultSingleChatPic,
        group: defaultGroupChatImage,
        ticket: defaultTicketChatPic,
        support: defaultHelpChatPic,
    };
    if (!imgUrl) imgUrl = defaultImageLookup[type];
    return <img className={`${className}`} src={imgUrl} alt='Profile Img' />;
}
