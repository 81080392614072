import React, { useEffect, useState } from 'react';
import Form from '../../Inventory/reusableComponents/AddOption/Form';
import Input from '../../Inventory/reusableComponents/AddOption/Input';
import { useDispatch, useSelector } from "react-redux";
import { getCustomerTeams } from '../../../actions/customer';
import { addTeamsAgent } from '../../../actions/superdesk';
import { getCustomerIdOfLoginUser } from '../../../utils/utils';
import { SkeltonCardOne } from '../../../common/SkeltonCardOne';
import {
    closeModalAction,
} from '../../../actions/modal';


export default function AddToTeam(props) {
    const { agentDetails } = props;
    const [teamsDropdown, setTeamsDropdown] = useState([])
    const formName = 'addTeamsInUsers';
    const { loggedUser } = useSelector(state => state.auth);
    const customerId = getCustomerIdOfLoginUser(loggedUser);
    const dispatch = useDispatch();
    const { customerTeamsDropDown, customerTeamsLoading } = useSelector((state) => state.customer)
    const closeModal = () => {
        dispatch(closeModalAction());
    };

    const initialValues = {
        teams: []
    }

    const [form, setForm] = useState(initialValues)

    const submit = (form) => {
        const teams = form.teams.map((elem) => elem.value)
        let payload = {
            customerId,
            teams,
            agentId: agentDetails.fk_user_id,
        }
        dispatch(addTeamsAgent({ data: payload, formName }))
    }

    useEffect(() => {
        dispatch(getCustomerTeams({ customerId, dropdown: true }));
    }, [])

    useEffect(() => {
        const usersTeamsId = agentDetails?.team_details?.map((elem) => elem?.id)
        let dropdown = customerTeamsDropDown?.filter((elem) => {
            return !usersTeamsId?.includes(elem?.value)
        })
        setTeamsDropdown(dropdown)
    }, [customerTeamsDropDown])

    return customerTeamsLoading ? <SkeltonCardOne /> : <Form
        form={form}
        setForm={setForm}
        initialValues={initialValues}
        submit={(e) => { submit(e) }}
        buttonPostion={'justify-end'}
        submitButtonName={'Submit'}
        onCancel={closeModal}
        formName={formName}
    >
        <div className='px-3 flex justify-between'>
            <Input className='w-full' label='Teams' name='teams' required type={'dropdown'} placeholder='Add Teams' isMulti={true} options={teamsDropdown} />
        </div>
    </Form>
}