/* eslint-disable max-len */

export const GET_GROUP_CONVERSATIONS_REQUEST = 'GET_GROUP_CONVERSATIONS_REQUEST';
export const GET_CONVERSATIONS_SUCCESS = 'GET_CONVERSATIONS_SUCCESS';
export const GET_CONVERSATIONS_FAILURE = 'GET_CONVERSATIONS_FAILURE';
export const GET_HELP_CONVERSATIONS_REQUEST = 'GET_HELP_CONVERSATIONS_REQUEST';
export const GET_TICKET_CONVERSATIONS_REQUEST = 'GET_TICKET_CONVERSATIONS_REQUEST';
export const GET_NEXT_HELP_CONVERSATIONS_REQUEST = 'GET_NEXT_HELP_CONVERSATIONS_REQUEST';
export const GET_CONVERSTAION_DETAILS_REQUEST = 'GET_CONVERSTAION_DETAILS_REQUEST';
export const GET_CONVERSTAION_DETAILS_SUCCESS = 'GET_CONVERSTAION_DETAILS_SUCCESS';
export const GET_CONVERSTAION_DETAILS_FAILURE = 'GET_CONVERSTAION_DETAILS_FAILURE';

export const GET_CONVERSATION_COUNT_REQUEST = 'GET_CONVERSATION_COUNT_REQUEST';

export const SET_CONVERSATION_UNREADABLE_MESSAGE_COUNT = 'SET_CONVERSATION_UNREADABLE_MESSAGE_COUNT';

export const GET_CONVERSATION_COUNT_SUCCESS = 'GET_CONVERSATION_COUNT_SUCCESS';
export const GET_CONVERSATION_COUNT_FAILED = 'GET_CONVERSATION_COUNT_FAILED';

export const CHANGE_CONVERSATION_COUNT = 'CHANGE_CONVERSATION_COUNT';
export const GET_REPLY_CONVERSTAION_MESSAGES_REQUEST = 'GET_REPLY_CONVERSTAION_MESSAGES_REQUEST';
export const GET_REPLY_CONVERSTAION_MESSAGES_FAILURE = 'GET_REPLY_CONVERSTAION_MESSAGES_FAILURE';
export const GET_REPLY_CONVERSTAION_MESSAGES_SUCCESS = 'GET_REPLY_CONVERSTAION_MESSAGES_SUCCESS';

export const CREATE_HELP_SUPPORT_REQUEST = 'CREATE_HELP_SUPPORT_REQUEST';
export const CLOSE_CONVERSATION_REQUEST = 'CLOSE_CONVERSATION_REQUEST';

export const CLOSE_CONVERSATION_SUCCESS = 'CLOSE_CONVERSATION_SUCCESS';
export const CLOSE_CONVERSATION_FAILURE = 'CLOSE_CONVERSATION_FAILURE';

export const UPDATE_BOT_ENABLED_CONVERSATION = 'UPDATE_BOT_ENABLED_CONVERSATION';

export const SET_HELP_SUPPORT_SUCCESS = 'SET_HELP_SUPPORT_SUCCESS';
export const CREATE_HELP_SUPPORT_FAILURE = 'CREATE_HELP_SUPPORT_FAILURE';

export const CREATE_GROUP_CONVERSATION_REQUEST = 'CREATE_GROUP_CONVERSATION_REQUEST';
export const CREATE_GROUP_CONVERSATION_SUCCESS = 'CREATE_GROUP_CONVERSATION_SUCCESS';
export const CREATE_GROUP_CONVERSATION_FAILURE = 'CREATE_GROUP_CONVERSATION_FAILURE';
export const CREATE_GROUP_CONVERSATION_LOADING = 'CREATE_GROUP_CONVERSATION_LOADING';

export const GET_CONVERSTAION_MESSAGES_REQUEST = 'GET_CONVERSTAION_MESSAGES_REQUEST';
export const GET_CONVERSTAION_MESSAGES_SUCCESS = 'GET_CONVERSTAION_MESSAGES_SUCCESS';
export const GET_CONVERSTAION_MESSAGES_FAILURE = 'GET_CONVERSTAION_MESSAGES_FAILURE';

export const GET_NEXT_CONVERSTAION_MESSAGES_REQUEST = 'GET_NEXT_CONVERSTAION_MESSAGES_REQUEST';
export const GET_NEXT_CONVERSTAION_MESSAGES_SUCCESS = 'GET_NEXT_CONVERSTAION_MESSAGES_SUCCESS';
export const GET_NEXT_CONVERSTAION_MESSAGES_FAILURE = 'GET_NEXT_CONVERSTAION_MESSAGES_FAILURE';
export const GET_NEXT_CONVERSTAION_MESSAGES_LOADING = 'GET_NEXT_CONVERSTAION_MESSAGES_LOADING';

export const GET_NEXT_GROUP_CONVERSATIONS_REQUEST = 'GET_NEXT_GROUP_CONVERSATIONS_REQUEST';
export const GET_NEXT_CONVERSATIONS_SUCCESS = 'GET_NEXT_CONVERSATIONS_SUCCESS';

export const CHANGE_NOTIFICATION_SETTINGS_REQUEST = 'CHANGE_NOTIFICATION_SETTINGS_REQUEST';
export const CHANGE_NOTIFICATION_SETTINGS_SUCCESS = 'CHANGE_NOTIFICATION_SETTINGS_SUCCESS';
export const CHANGE_NOTIFICATION_SETTINGS_FAILURE = 'CHANGE_NOTIFICATION_SETTINGS_FAILURE';

export const CHAT_UPLOAD_FILES_REQUEST = 'CHAT_UPLOAD_FILES_REQUEST';
export const CHAT_UPLOAD_FILES_SUCCESS = 'CHAT_UPLOAD_FILES_SUCCESS';
export const CHAT_UPLOAD_FILES_FAILURE = 'CHAT_UPLOAD_FILES_FAILURE';



export const UPLOAD_ONCHANGE_REQUEST = 'UPLOAD_ONCHANGE_REQUEST';

export const LEAVE_CONVERSATION_REQUEST = 'LEAVE_CONVERSATION_REQUEST';
export const LEAVE_CONVERSATION_SUCCESS = 'LEAVE_CONVERSATION_SUCCESS';
export const LEAVE_CONVERSATION_FAILURE = 'LEAVE_CONVERSATION_FAILURE';

export const PUT_TO_CONVERSATIONS_STORE = 'PUT_TO_CONVERSATIONS_STORE';

export const HANDLE_MESSAGE_FROM_WEBSOCKET_FOR_MESSAGE_LIST = 'HANDLE_MESSAGE_FROM_WEBSOCKET_FOR_MESSAGE_LIST';
export const HANDLE_MESSAGE_FROM_WEBSOCKET_FOR_CHAT_LIST = 'HANDLE_MESSAGE_FROM_WEBSOCKET_FOR_CHAT_LIST';

export const DELETED_MESSAGE_FROM_WEBSOCKET = 'DELETED_MESSAGE_FROM_WEBSOCKET';

export const EDITED_MESSAGE_FROM_WEBSOCKET = 'EDITED_MESSAGE_FROM_WEBSOCKET';
export const MARK_READ_CONVERSATION_SUCCESS = 'MARK_READ_CONVERSATION_SUCCESS';
export const MESSAGE_READ_STATUS = 'MESSAGE_READ_STATUS';
export const OPEN_CHAT_IN_NEW_TAB = 'OPEN_CHAT_IN_NEW_TAB';
export const OPEN_CHAT_IN_NEW_TAB_SUCCESS = 'OPEN_CHAT_IN_NEW_TAB_SUCCESS'

export const OPEN_CHAT_IN_TAB = 'OPEN_CHAT_IN_TAB'
export const GET_CONVERSATION_MEDIA_REQUEST = 'GET_CONVERSATION_MEDIA_REQUEST';
export const GET_CONVERSATION_MEDIA_SUCCESS = 'GET_CONVERSATION_MEDIA_SUCCESS';
export const GET_CONVERSATION_MEDIA_FAILURE = 'GET_CONVERSATION_MEDIA_FAILURE';

export const GET_NEXT_CONVERSATION_MEDIA_REQUEST = 'GET_NEXT_CONVERSATION_MEDIA_REQUEST';
export const GET_NEXT_CONVERSATION_MEDIA_SUCCESS = 'GET_NEXT_CONVERSATION_MEDIA_SUCCESS';
export const GET_NEXT_CONVERSATION_MEDIA_FAILURE = 'GET_NEXT_CONVERSATION_MEDIA_FAILURE';

export const TAG_PROJECT_REQUEST = 'TAG_PROJECT_REQUEST';
export const TAG_PROJECT_SUCCESS = 'TAG_PROJECT_SUCCESS';
export const TAG_PROJECT_FAILURE = 'TAG_PROJECT_FAILURE';

export const UNTAG_PROJECT_REQUEST = 'UNTAG_PROJECT_REQUEST';
export const UNTAG_PROJECT_SUCCESS = 'UNTAG_PROJECT_SUCCESS';
export const UNTAG_PROJECT_FAILURE = 'UNTAG_PROJECT_FAILURE';

export const UPDATE_CONVERSATION_DETAILS_REQUEST = 'UPDATE_CONVERSATION_DETAILS_REQUEST';
export const UPDATE_CONVERSATION_DETAILS_SUCCESS = 'UPDATE_CONVERSATION_DETAILS_SUCCESS';
export const UPDATE_CONVERSATION_DETAILS_FAILURE = 'UPDATE_CONVERSATION_DETAILS_FAILURE';

export const REMOVE_FROM_CONVERSATION_REQUEST = 'REMOVE_FROM_CONVERSATION_REQUEST';
export const REMOVE_FROM_CONVERSATION_SUCCESS = 'REMOVE_FROM_CONVERSATION_SUCCESS';
export const REMOVE_FROM_CONVERSATION_FAILURE = 'REMOVE_FROM_CONVERSATION_FAILURE';

export const ADD_MEMBER_IN_CONVERSATION_REQUEST = 'ADD_MEMBER_IN_CONVERSATION_REQUEST';
export const ADD_MEMBER_IN_CONVERSATION_SUCCESS = 'ADD_MEMBER_IN_CONVERSATION_SUCCESS';
export const ADD_MEMBER_IN_CONVERSATION_FAILURE = 'ADD_MEMBER_IN_CONVERSATION_FAILURE';

export const ADD_TO_MESSAGE_QUEUE = 'ADD_TO_MESSAGE_QUEUE';
export const EDITED_MESSAGE_QUEUE = 'EDITED_MESSAGE_QUEUE';
export const ADD_TO_MESSAGE_QUEUE_SUCCESS = 'ADD_TO_MESSAGE_QUEUE_SUCCESS';

export const GRANT_OR_REVOKE_ACCESS_REQUEST = 'GRANT_OR_REVOKE_ACCESS_REQUEST';
export const GRANT_OR_REVOKE_ACCESS_SUCCESS = 'GRANT_OR_REVOKE_ACCESS_SUCCESS';
export const GRANT_OR_REVOKE_ACCESS_FAILURE = 'GRANT_OR_REVOKE_ACCESS_FAILURE';

export const GET_CONVERSATION_INFO_REQUEST = 'GET_CONVERSATION_INFO_REQUEST';
export const GET_CONVERSATION_INFO_SUCCESS = 'GET_CONVERSATION_INFO_SUCCESS';
export const GET_CONVERSATION_INFO_FAILURE = 'GET_CONVERSATION_INFO_FAILURE';

export const CREATE_SINGLE_CHAT_REQUEST = 'CREATE_SINGLE_CHAT_REQUEST';
export const CREATE_SINGLE_CHAT_SUCCESS = 'CREATE_SINGLE_CHAT_SUCCESS';
export const CREATE_SINGLE_CHAT_FAILURE = 'CREATE_SINGLE_CHAT_FAILURE';

export const OPEN_SINGLE_CHAT_REQUEST = 'OPEN_SINGLE_CHAT_REQUEST';
export const OPEN_SINGLE_CHAT_SUCCESS = 'OPEN_SINGLE_CHAT_SUCCESS';
export const OPEN_SINGLE_CHAT_FAILURE = 'OPEN_SINGLE_CHAT_FAILURE';

export const GET_SINGLE_CHAT_USER_INFO_DETAILS_REQUEST = 'GET_SINGLE_CHAT_USER_INFO_DETAILS_REQUEST';
export const GET_SINGLE_CHAT_USER_INFO_DETAILS_SUCCESS = 'GET_SINGLE_CHAT_USER_INFO_DETAILS_SUCCESS';
export const GET_SINGLE_CHAT_USER_INFO_DETAILS_FAILURE = 'GET_SINGLE_CHAT_USER_INFO_DETAILS_FAILURE';

export const GET_SINGLE_CHAT_CUSTOMER_DETAILS_REQUEST = 'GET_SINGLE_CHAT_CUSTOMER_DETAILS_REQUEST';
export const GET_SINGLE_CHAT_CUSTOMER_DETAILS_SUCCESS = 'GET_SINGLE_CHAT_CUSTOMER_DETAILS_SUCCESS';
export const GET_SINGLE_CHAT_CUSTOMER_DETAILS_FAILURE = 'GET_SINGLE_CHAT_CUSTOMER_DETAILS_FAILURE';

export const GET_SINGLE_CHAT_SP_USER_DETAILS_REQUEST = 'GET_SINGLE_CHAT_SP_USER_DETAILS_REQUEST';
export const GET_SINGLE_CHAT_SP_USER_DETAILS_SUCCESS = 'GET_SINGLE_CHAT_SP_USER_DETAILS_SUCCESS';
export const GET_SINGLE_CHAT_SP_USER_DETAILS_FAILURE = 'GET_SINGLE_CHAT_SP_USER_DETAILS_FAILURE';

export const GET_SINGLE_CHAT_FE_USER_DETAILS_REQUEST = 'GET_SINGLE_CHAT_FE_USER_DETAILS_REQUEST';
export const GET_SINGLE_CHAT_FE_USER_DETAILS_SUCCESS = 'GET_SINGLE_CHAT_FE_USER_DETAILS_SUCCESS';
export const GET_SINGLE_CHAT_FE_USER_DETAILS_FAILURE = 'GET_SINGLE_CHAT_FE_USER_DETAILS_FAILURE';

export const ADD_CONVERSATION_SQL_DYNAMODB_SUCCESS = 'ADD_CONVERSATION_SQL_DYNAMODB_SUCCESS';
export const ADD_CONVERSATION_SQL_DYNAMODB_FAILURE = 'ADD_CONVERSATION_SQL_DYNAMODB_FAILURE';

export const ADD_MEMBERS_OR_TEAMS_TO_CHAT_REQUEST = 'ADD_MEMBERS_OR_TEAMS_TO_CHAT_REQUEST';
export const ADD_MEMBERS_OR_TEAMS_TO_CHAT_SUCCESS = 'ADD_MEMBERS_OR_TEAMS_TO_CHAT_SUCCESS';
export const ADD_MEMBERS_OR_TEAMS_TO_CHAT_FAILURE = 'ADD_MEMBERS_OR_TEAMS_TO_CHAT_FAILURE';

export const APPROVE_OR_REJECT_IMAGE_REQUEST = 'APPROVE_OR_REJECT_IMAGE_REQUEST';
export const APPROVE_OR_REJECT_IMAGE_SUCCESS = 'APPROVE_OR_REJECT_IMAGE_SUCCESS';
export const APPROVE_OR_REJECT_IMAGE_FAILURE = 'APPROVE_OR_REJECT_IMAGE_FAILURE';

export const APPROVE_OR_REJECT_CARD_MESSAGE_REQUEST = 'APPROVE_OR_REJECT_CARD_MESSAGE_REQUEST';
export const APPROVE_OR_REJECT_CARD_MESSAGE_SUCCESS = 'APPROVE_OR_REJECT_CARD_MESSAGE_SUCCESS';
export const APPROVE_OR_REJECT_CARD_MESSAGE_FAILURE = 'APPROVE_OR_REJECT_CARD_MESSAGE_FAILURE';

export const GET_CHAT_NOTIFICATIONS_REQUEST = 'GET_CHAT_NOTIFICATIONS_REQUEST';
export const GET_CHAT_NOTIFICATIONS_SUCCESS = 'GET_CHAT_NOTIFICATIONS_SUCCESS';
export const GET_CHAT_NOTIFICATIONS_FAILURE = 'GET_CHAT_NOTIFICATIONS_FAILURE';

export const GET_CHAT_NOTIFICATIONS_COUNT_REQUEST = 'GET_CHAT_NOTIFICATIONS_COUNT_REQUEST';

export const LEAVE_CONVERSATION_FROM_WEBSOCKET = 'LEAVE_CONVERSATION_FROM_WEBSOCKET';
export const ADD_MEMBERS_TO_CHAT_FROM_WEBSOCKET = 'ADD_MEMBERS_TO_CHAT_FROM_WEBSOCKET';

export const SET_OPEN_CONVERSTAION_MESSAGES_ISLATEST = 'SET_OPEN_CONVERSTAION_MESSAGES_ISLATEST';
export const ADD_TEAMS_TO_CHAT_FROM_WEBSOCKET = 'ADD_TEAMS_TO_CHAT_FROM_WEBSOCKET';
export const REMOVE_TEAM_FROM_CHAT_FROM_WEBSOCKET = 'REMOVE_TEAM_FROM_CHAT_FROM_WEBSOCKET';

export const LIST_CONVERSATION_SIDEBAR_MEDIA_REQUEST = 'LIST_CONVERSATION_SIDEBAR_MEDIA_REQUEST';
export const LIST_CONVERSATION_SIDEBAR_MEDIA_SUCCESS = 'LIST_CONVERSATION_SIDEBAR_MEDIA_SUCCESS';
export const LIST_CONVERSATION_SIDEBAR_MEDIA_FAILURE = 'LIST_CONVERSATION_SIDEBAR_MEDIA_FAILURE';

export const MARK_NOTIFICATIONS_READ_REQUEST = 'MARK_NOTIFICATIONS_READ_REQUEST';
export const MARK_NOTIFICATIONS_READ_SUCCESS = 'MARK_NOTIFICATIONS_READ_SUCCESS';
export const MARK_NOTIFICATIONS_READ_FAILURE = 'MARK_NOTIFICATIONS_READ_FAILURE';

export const SET_SIDEBAR_MEDIA_VISIBLE_STATUS = 'SET_SIDEBAR_MEDIA_VISIBLE_STATUS';
export const RECEIVED_CHAT_NOTIFICATION = 'RECEIVED_CHAT_NOTIFICATION';

export const LIST_TICKET_CUSTOMER_REPORT_MEDIA_REQUEST = 'LIST_TICKET_CUSTOMER_REPORT_MEDIA_REQUEST';
export const LIST_TICKET_CUSTOMER_REPORT_MEDIA_SUCCESS = 'LIST_TICKET_CUSTOMER_REPORT_MEDIA_SUCCESS';
export const LIST_TICKET_CUSTOMER_REPORT_MEDIA_FAILURE = 'LIST_TICKET_CUSTOMER_REPORT_MEDIA_FAILURE';
export const REMOVE_CUSTOMER_REPORT_MEDIA_FROM_STORE = 'REMOVE_CUSTOMER_REPORT_MEDIA_FROM_STORE';

export const DOWNLOAD_CUSTOMER_REPORT_REQUEST = 'DOWNLOAD_CUSTOMER_REPORT_REQUEST';
export const DOWNLOAD_CUSTOMER_REPORT_SUCCESS = 'DOWNLOAD_CUSTOMER_REPORT_SUCCESS';
export const DOWNLOAD_CUSTOMER_REPORT_FAILURE = 'DOWNLOAD_CUSTOMER_REPORT_FAILURE';

export const TYPING_IN_CHAT = 'TYPING_IN_CHAT';
export const REMOVE_TYPING_IN_CHAT = 'REMOVE_TYPING_IN_CHAT';
export const GET_SEARCH_CONVERSTAION_MESSAGES_REQUEST = 'GET_SEARCH_CONVERSTAION_MESSAGES_REQUEST';
export const GET_SEARCH_CONVERSTAION_MESSAGES_SUCCESS = 'GET_SEARCH_CONVERSTAION_MESSAGES_SUCCESS';
export const GET_SEARCH_CONVERSTAION_MESSAGES_FAILURE = 'GET_SEARCH_CONVERSTAION_MESSAGES_FAILURE';
export const SET_SEARCH_CONVERSATION_UNREADABLE_MESSAGE_COUNT = 'SET_SEARCH_CONVERSATION_UNREADABLE_MESSAGE_COUNT';
export const UPDATE_UN_READ_MESSAGE_COUNT = 'UPDATE_UN_READ_MESSAGE_COUNT';

export const GET_GPT_CONVERSATIONS_REQUEST = 'GET_GPT_CONVERSATIONS_REQUEST';
export const GET_GPT_CONVERSATIONS_SUCCESS = 'GET_GPT_CONVERSATIONS_SUCCESS';
export const GET_GPT_CONVERSATIONS_FAILURE = 'GET_GPT_CONVERSATIONS_FAILURE';

export const SEND_SCOGO_GPT_MESSAGE_REQUEST = 'SEND_SCOGO_GPT_MESSAGE_REQUEST';
export const SEND_SCOGO_GPT_MESSAGE_SUCCESS = 'SEND_SCOGO_GPT_MESSAGE_SUCCESS';
export const SEND_SCOGO_GPT_MESSAGE_FAILURE = 'SEND_SCOGO_GPT_MESSAGE_FAILURE';

export const NEW_SCOGO_GPT_CHAT_FROM_WEBSOCKET = 'NEW_SCOGO_GPT_CHAT_FROM_WEBSOCKET';

export const NOTIFY_END_CUSTOMER_REQUEST = 'NOTIFY_END_CUSTOMER_REQUEST';
export const NOTIFY_END_CUSTOMER_SUCCESS = 'NOTIFY_END_CUSTOMER_SUCCESS';
export const NOTIFY_END_CUSTOMER_FAILURE = 'NOTIFY_END_CUSTOMER_FAILURE';

export const GET_CONVERSATIONID_AND_CONVERSATION_DETAILS = 'GET_CONVERSATIONID_AND_CONVERSATION_DETAILS';
export const GET_CONVERSATIONID_AND_CONVERSATION_DETAILS_SUCCESS = 'GET_CONVERSATIONID_AND_CONVERSATION_DETAILS_SUCCESS';

export const OPEN_ACTIVE_CALL = 'OPEN_ACTIVE_CALL';
export const UPDATE_CONVO_ON_PAY_PO_TAX_SUCCESS = 'UPDATE_CONVO_ON_PAY_PO_TAX_SUCCESS';
export const OPEN_CHAT_ELEMENT = 'OPEN_CHAT_ELEMENT';
export const OPEN_CHAT_ELEMENT_SUCCESS = 'OPEN_CHAT_ELEMENT_SUCCESS';
export const CLOSE_ALL_OPEN_CHATS = 'CLOSE_ALL_OPEN_CHATS'

export const JOIN_ROOM = 'JOIN_ROOM';
export const LEAVE_ROOM = 'LEAVE_ROOM';
export const HOLD_ROOM = 'HOLD_ROOM';
export const DECLINE_ROOM = 'DECLINE_ROOM';
export const CLEAR_ROOM = 'CLEAR_ROOM';

export const GET_ATTACHMENT_WITH_PARTIAL_URL_REQUEST = 'GET_ATTACHMENT_WITH_PARTIAL_URL_REQUEST';
export const GET_ATTACHMENT_WITH_PARTIAL_URL_SUCCESS = 'GET_ATTACHMENT_WITH_PARTIAL_URL_SUCCESS';
