import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import DetailTag from '../../../pages/project/DetailTag';
import { getAllCities, getAllSitesDropdown, getEndUsersList, migrateAssets, removeEnduserListFromStore, searchSiteWithExternalSiteId } from '../../../actions/inventory';
import { getCustomerAddonsListAction, } from '../../../actions/customer';
import { getAllWarehouses } from '../../../actions/inventory';
import { getCourierVendors } from '../../../actions/users';
import { textCss } from '../../../common/Css';
import GridTable from '../../../common/GridTable';
import { tableHead } from '../../../common/tabeHelpers';
import DetailsColumn from '../../../pages/Inventory/reusableComponents/AddOption/AssetDetails';
import { GetQueryParam, actualValueForDropdown, isLooksLikeId } from '../../../utils/common';
import { sendToastMessage } from '../../../actions/toast';
import { assets as assetTab, spareAssets as spareAssetsTab } from '../../Inventory/InventoryController';
import IconToolTip from '../../../common/IconToolTip';
import { debounce as localDebounce } from '../../../utils/common';
import Form from '../../../common/Form';
import Input from '../../../common/Form/Input';
import { useForm } from 'react-hook-form';
import Select from '../../../common/Form/Select';
import apis from '../../../apis';
import ButtonScogoPrimary from '../../../common/Buttons/ButtonScogoPrimary';
import ButtonScogoClosedOutlined from '../../../common/Buttons/ButtonScogoClosedOutlined';

const workingConditionOptions = [
    {
        key: 'new',
        label: 'NEW',
        value: 'NEW',
    },
    {
        key: 'faulty',
        label: 'FAULTY',
        value: 'FAULTY',
    },
    {
        key: 'refurbished',
        label: 'REFURBISHED',
        value: 'REFURBISHED',
    },
];

const MigrateAsset = ({ modalData, customerId, siteId, warehouseId }) => {
    const formName = 'migrateAsset';
    const dispatch = useDispatch();
    const tabName = GetQueryParam('tab');
    let source = {};
    let { assetslist } = modalData && modalData;

    const { loggedUser } = useSelector((state) => state.auth);
    const { sitesDropdown } = useSelector((state) => state.inventory);

    const { role } = loggedUser && loggedUser;
    const [assetsData, setAssetsData] = useState(assetslist);

    const getCustomerId = () => {
        let assetCustomerId;
        if (isLooksLikeId(warehouseId)) {
            assetCustomerId = assetslist[0]?.customer?.customer_id;
        }
        if (isLooksLikeId(siteId)) {
            assetCustomerId = assetslist[0]?.customer_id;
        }
        return assetCustomerId;
    };

    if (siteId > 0) source = { from: 'SITE', sourceId: siteId };
    if (warehouseId > 0) source = { from: 'WAREHOUSE', sourceId: warehouseId };
    const [from, setFrom] = useState(source.from);
    let sourceId;
    if (modalData && modalData.from === 'WAREHOUSE') setFrom('WAREHOUSE');
    if (source && !isNaN(source.sourceId) && source.sourceId > 0) sourceId = source.sourceId;
    let toDropdown = [
        { key: 1, value: 'SITE', label: 'Site' },
        { key: 2, value: 'WAREHOUSE', label: 'Warehouse' },
    ];

    const getDataOnMount = () => {
        dispatch(getCustomerAddonsListAction({ customerId, dropdown: true }));
        dispatch(getAllWarehouses({ customerId: getCustomerId(), count: false, limit: 10, page: 1, dropdown: true }));
        dispatch(getCourierVendors());
        dispatch(getAllSitesDropdown({ customerId: getCustomerId(), dropdown: true }));
    };

    useEffect(() => {
        getDataOnMount();
    }, []);

    const { warehouses, endUsersList } = useSelector((state) => state.inventory);
    const { sitesLoading, isEndUserListLoading, formButtonLoading } = useSelector((state) => state.loading);
    const { courierVendors } = useSelector((state) => state.user);


    const warehouseDropdown = (warehouses) => {
        const currentAsset = assetslist[0];
        if (warehouses) {
            let warehouseData = [];
            warehouses.forEach((warehouse) => {
                if (currentAsset?.warehouse?.id !== warehouse?.id) {
                    warehouseData.push({
                        key: `${warehouse.id}_${warehouse.customer_id}`,
                        value: warehouse.id,
                        label: warehouse.name,
                        warehouse,
                    });
                }
            });
            return warehouseData;
        }
    };

    const removeSelectedAssets = (id) => {
        if (!isNaN(id) && id > 0) {
            let selectedAssets = [...assetsData];
            let index = selectedAssets.findIndex((i) => parseInt(i.id) === parseInt(id));
            if (index !== -1) {
                selectedAssets.splice(index, 1);
            }
            setAssetsData(selectedAssets);
        }
    };

    const sitesDropdownList = useMemo(() => {
        let options = [
            {
                key: 'add_new_site',
                label: 'Add New Site',
                value: 'add_new_site',
            },
        ];
        if (Array.isArray(sitesDropdown)) {
            options = [...options, ...sitesDropdown];
        }

        return options;
    }, [sitesDropdown]);

    const tableSchema = {
        table: [
            {
                head: tableHead('Asset Id'),
                body: {
                    render: (item) => {
                        return (
                            <>
                                <p className='font-medium text-scogobgsky'>{item?.spare_number ? item.spare_number : item.assetId}</p>
                                {item?.serialNo && (
                                    <IconToolTip placement={'bottom'} title={`Serial Number`}>
                                        <span className='text-lg'>{item?.serialNo ? item.serialNo : ''}</span>
                                    </IconToolTip>
                                )}
                            </>
                        );
                    },
                },
            },
            {
                head: tableHead('Asset Type'),
                body: {
                    render: (item) => {
                        return <p>{item?.spare_number ? item.spareCategoryTitle : item.asset}</p>;
                    },
                },
            },
            {
                head: tableHead('Asset Details'),
                body: {
                    render: (item) => {
                        return item?.spare_number ? (
                            <DetailsColumn make={item?.make} model={item?.model} partcode={item?.partcode} />
                        ) : (
                            <DetailsColumn
                                make={item?.assetslist?.[0]?.make}
                                model={item?.assetslist?.[0]?.model}
                                partcode={item?.assetslist?.[0]?.partcode}
                                supplier={item?.assetslist?.[0]?.vendor_supplier_name}
                            />
                        );
                    },
                },
            },
            {
                head: {
                    headName: 'Actions',
                    render: (headName) => headName,
                    width: 0.5,
                },
                body: {
                    render: (item) => {
                        return (
                            <span
                                onClick={() => {
                                    removeSelectedAssets(item.id);
                                }}
                                className={`material-icons text-scogoToastDangerIcon  cursor-pointer `}
                            >
                                delete
                            </span>
                        );
                    },
                },
            },
        ],
    };

    const getDetailsToShow = () => {
        const firstElemInList = assetslist[0];
        let address = '';
        let externalId = '';
        let cityState = '';
        let tagName = '';
        if (tabName === assetTab.path && firstElemInList) {
            address = `${firstElemInList?.site?.complete_site_address ? firstElemInList?.site?.complete_site_address : ''}`;
            externalId = firstElemInList?.site?.external_site_id;
            cityState = `${firstElemInList?.site?.city?.city_name ? firstElemInList?.site?.city?.city_name : ''}, ${firstElemInList?.site?.state?.state_name ? firstElemInList?.site?.state?.state_name : ''
                }, ${firstElemInList?.site?.fk_pincode_id ? firstElemInList?.site?.fk_pincode_id : ''}`;
            tagName = 'Site Id';
        } else if (tabName === spareAssetsTab.path && firstElemInList) {
            address = `${firstElemInList?.warehouse?.address ? firstElemInList?.warehouse?.address : ''}`;
            externalId = `${firstElemInList?.warehouse?.name ? firstElemInList?.warehouse?.name : ''}`;
            cityState = `${firstElemInList?.warehouse?.pincode ? firstElemInList?.warehouse?.pincode : ''}`;
            tagName = 'Warehouse Name';
        }

        return (
            <div className='md:flex pl-5 pt-3'>
                {externalId && (
                    <DetailTag
                        tagHeadClass={textCss?.detailsTitle}
                        tagValueClass={`${textCss?.detailsValue} line-clamp-3`}
                        tagClass=' px-2 md:w-6/12'
                        tagName={tagName}
                        tagValue={externalId}
                    />
                )}
                <div className='flex flex-col px-2 md:w-6/12'>
                    {address && (
                        <DetailTag tagHeadClass={textCss?.detailsTitle} tagValueClass={`${textCss?.detailsValue} line-clamp-3`} tagClass='' tagName='Address' tagValue={address} />
                    )}
                    {cityState && <p className={`${textCss?.detailsValue} line-clamp-3`}>{cityState}</p>}
                </div>
            </div>
        );
    };

    const containsMoreThanOneSiteOrWarehouse = useCallback(() => {
        const { sitesSet, warehousesSet } = assetsData.reduce(
            (acc, el) => {
                if (el?.warehouse_id > 0) acc.warehousesSet.add(el.warehouse_id);
                if (el?.fk_site_id > 0) acc.sitesSet.add(el.fk_site_id);
                return acc;
            },
            { sitesSet: new Set(), warehousesSet: new Set() }
        );
        if (tabName === assetTab.path) {
            return sitesSet.size > 1;
        }
        if (tabName === spareAssetsTab.path) {
            return warehousesSet.size > 1;
        }
        return true;
    }, [assetsData, tabName]);

    const moreThanOneSource = containsMoreThanOneSiteOrWarehouse();

    const searchSiteDropdown = (query, siteList) => {
        if (!query) return;
        let find = siteList.filter((site) => site.label.toLowerCase().includes(query));
        if (find.length === 0) dispatch(searchSiteWithExternalSiteId({ customerId: customerId, externalSiteId: query }));
    };

    const debouncedSiteSearchCall = useCallback(localDebounce(searchSiteDropdown), []);

    const defaultValues = {
        from: warehouseId > 0 ? toDropdown[1] : toDropdown[0],
        sourceId,
        to: from,
        destinationId: undefined,
    };

    const methods = useForm({ defaultValues, mode: 'all' });
    const { watch, setValue } = methods;
    const toValue = actualValueForDropdown(watch('from'));
    const destinationId = actualValueForDropdown(watch('destinationId'));
    const addingNewSite = destinationId === 'add_new_site';

    useEffect(() => {
        if (addingNewSite) {
            dispatch(getAllCities());
            dispatch(getEndUsersList({ customer_id: getCustomerId() }));
        }
    }, [addingNewSite, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(removeEnduserListFromStore());
        }
    }, [dispatch]);

    const submit = (formValues) => {
        const destinationId = formValues?.destinationId?.value;
        const source_from = defaultValues?.from?.value;
        const source_to = formValues?.from?.value;
        let data = {
            assets: assetsData.map((item) => item.id),
            source: { from: source_from, sourceId: defaultValues.sourceId },
            destination: { to: source_to, destinationId },
        };
        let createNewSiteFormData = {};
        if (addingNewSite) {
            createNewSiteFormData = [
                {
                    fk_pincode_id: formValues?.sitePincode,
                    create_new: 'new',
                    fk_customer_id: getCustomerId(),
                    complete_site_address: formValues?.siteAddress,
                    external_site_id: formValues?.exernal_siteId,
                    fk_city_id: formValues?.fk_city_id,
                    fk_state_id: formValues?.fk_state_id,
                    customer_end_user: actualValueForDropdown(formValues.customer_end_user),
                    site_contact_person_name: formValues.site_contact_person_name,
                    site_contact_person_email: formValues.site_contact_person_email,
                    site_contact_person_mobile: formValues.site_contact_person_mobile,
                },
            ];
        }

        data = {
            ...data,
            docketNumber: formValues.docketNumber,
            courierVendorId: formValues?.courierVendorId?.value,
            logistic_cost: Number(formValues?.logistic_cost),
            reason: formValues.reason,
        };

        if ([6, 7, 12].includes(role.id)) {
            data.customerId = assetsData[0]?.customer_id;
        }

        data.workingCondition = actualValueForDropdown(formValues.workingCondition);

        if (assetsData.length > 0) {
            let site_id = isLooksLikeId(siteId) ? siteId : 0;
            let warehouse_id = isLooksLikeId(warehouseId) ? warehouseId : 0;
            dispatch(
                migrateAssets({
                    data,
                    formName,
                    siteId: site_id,
                    createNewSiteFormData,
                    warehouseId: warehouse_id,
                    count: true,
                    addingNewSite,
                    enduserId: assetslist[0]?.enduserId
                })
            );
        } else {
            dispatch(sendToastMessage({ status: 'danger', message: 'Please Select Atleast One Available Asset !' }));
        }
    };

    const isWarehouseDropdown = toValue === 'WAREHOUSE';

    return (
        <>
            <Form
                methods={methods}
                onSubmit={submit}
                submitButtonText={'Yes'}
            >
                <>
                    <div className='px-6 mt-3'>
                        <p className={textCss?.formHighlights}>Current Details</p>
                    </div>
                    <div className=''>{getDetailsToShow()}</div>
                </>
                <div className='px-3 flex justify-between  pt-5'>
                    <Select label='To' required name='from' options={toDropdown} className={'w-full'} onChange={() => {
                        setValue('destinationId', '')
                    }} />
                    <Select
                        label={isWarehouseDropdown ? 'Warehouse' : 'Site'}
                        required
                        name='destinationId'
                        options={isWarehouseDropdown ? warehouseDropdown(warehouses) : sitesDropdownList}
                        placeholder={isWarehouseDropdown ? 'Warehouse' : 'Site'}
                        className={'w-full'}
                        isLoading={isWarehouseDropdown ? false : sitesLoading}
                        onInputChange={(value) => {
                            if (!isWarehouseDropdown) {
                                debouncedSiteSearchCall(value, sitesDropdownList);
                            }
                        }}
                    />
                    {addingNewSite && (
                        <Select
                            label='End User'
                            name='customer_end_user'
                            options={endUsersList}
                            className={'w-full'}
                            isLoading={isEndUserListLoading}
                        />
                    )}
                </div>

                {addingNewSite && (
                    <>
                        <div className='px-3 flex justify-between items-end'>
                            <Input label='Site ID' name='exernal_siteId' className={'w-full'} required />
                            <Input
                                label='Pincode'
                                name='sitePincode'
                                type={'number'}
                                className={'w-full'}
                                required
                                pattern={/^\d{6}$/}
                                validate={{
                                    pincodeNotInSystem: async (value) => {
                                        if (value === '') return true;
                                        const response = await apis.cities.getCityFromPincode({}, {}, {}, { pincode: value });
                                        setValue('fk_city_id', response.data.data.fk_city_id)
                                        setValue('fk_state_id', response.data.data.fk_state_id)
                                        return response.data?.code === 200 || "Pincode does't exist in the System.";
                                    }
                                }}
                            />
                            <Input label='Address' name='siteAddress' className={'w-full'} required />
                        </div>
                        <div className='px-3 flex justify-between items-end'>
                            <Input label='Contact Name' name='site_contact_person_name' className={'w-full'} />
                            <Input
                                label='Contact Mobile'
                                type={'number'}
                                name='site_contact_person_mobile'
                                className={'w-full'}
                                validate={(mobile) => {
                                    if (mobile === '') return true;
                                    return mobile.length === 10 || 'Invalid Mobile';
                                }}
                            />
                            <Input
                                label='Contact Email'
                                type={'text'}
                                name='site_contact_person_email'
                                className={'w-full'}
                                validate={(email) => {
                                    if (email === '') return true;
                                    return (
                                        email.match(
                                            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                        ) || 'Invalid Email'
                                    );
                                }}
                            />
                        </div>
                    </>
                )}

                <div className='px-3 flex justify-between items-end'>
                    <Input label='Docket Number' name='docketNumber' className={'w-full'} />
                    <Select label='Courier Vendor' name='courierVendorId' options={courierVendors} className={'w-full'} />
                    <Input step='any' label='Logistics Cost' name='logistic_cost' type={'number'} className={'w-full'} />
                </div>

                <div className='px-3 flex justify-between items-end'>
                    <Input label='Reason' name='reason' className={'w-6/12'} />
                    <Select label='Working Condition' name='workingCondition' options={workingConditionOptions} className={'w-6/12'} />
                </div>
                <div className='rounded overflow-hidden shadow-lg px-6 mt-3 '>
                    <GridTable isLoading={false} schema={tableSchema} rowData={assetsData} tableError={'No assets selected'} tableBodyHeight={'h-max'} />
                </div>

                <div className={'flex gap-2 justify-end py-4 px-4 items-center'}>
                    {moreThanOneSource && <span className='text-scogoclosed mr-2'>Can't have more than 1 Source</span>}
                    <ButtonScogoPrimary
                        disabled={moreThanOneSource}
                        textOrComponent={'Submit'}
                        type='submit'
                        loading={formButtonLoading[formName]}
                    />
                    <ButtonScogoClosedOutlined textOrComponent={'Cancel'} type='button' onClick={() => dispatch(closeModalAction())} />
                </div>
            </Form>
        </>
    );
};

export default MigrateAsset;
