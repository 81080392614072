import React from 'react';

function EventMessage({ message, isChatPopup }) {
    return (
        <div className={`${isChatPopup ? 'px-2 py-1' : 'px-4 py-2'} bg-tagmsg rounded-15px mx-auto my-4 w-fit`}>
            <p className=' text-font11'>
                <span className='text-scogoprimary font-medium break-word'>{message?.message} </span>
            </p>
        </div>
    );
}

export default EventMessage;
