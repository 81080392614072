import { project } from '../types';

export const getProjectListAction = ({ customerId }) => ({
    type: project.GET_PROJECT_LIST_REQUEST,
    payload: { customerId },
});

export const getAllProjectList = ({ customerId }) => ({
    type: project.GET_ALL_PROJECT_LIST_REQUEST,
    payload: { customerId },
});

export const getProjectDropDown = ({ customerId }) => {
    return { type: project.GET_ALL_PROJECT_DROPDOWN_LIST_REQUEST, payload: { customerId } };
};

export const updatePmDetails = ({ pm, type, projectId, formName, customerId }) => ({
    type: project.UPDATE_PM_DETAILS_REQUEST,
    payload: { pm, type, projectId, formName, customerId },
});

export const projectPostStatus = ({ status, projectId, postId, formName }) => ({
    type: project.PROJECT_POST_STATUS_REGUEST,
    payload: { status, projectId, postId, formName },
});
export const getProjectsCount = (customer_id) => ({
    type: project.PROJECTS_COUNT_REQUEST,
    payload: { customer_id },
});

export const editProject = ({ formValues, activeTab = 'ongoing', customerId, limit = 10, page = 1, type, formName }) => {
    return {
        type: project.EDIT_PROJECT_REQUEST,
        payload: { formValues, activeTab, customerId, limit, page, type, formName },
    };
};

export const emailTomorrowsPlannedTicketsCsv = (data) => ({
    type: project.EMAIL_PLANNED_TICKETS_CSV_REQUEST,
    payload: { data },
});

export const emailTodaysConfigCompleteTicketsCsv = (data) => ({
    type: project.EMAIL_CONFIG_COMPLETE_TICKETS_CSV_REQUEST,
    payload: { data },
});

export const createProject = ({ projectForm, customerId, formName }) => ({
    type: project.CREATE_PROJECT_REQUEST,
    payload: { projectForm, customerId, formName },
});

export const getProjectPrequisites = () => ({
    type: project.PROJECT_PREQUISITE_LIST_REQUEST,
});

export const getListOfTemplates = (data) => ({
    type: project.SHOW_TEMPLATE_LIST_REQUEST,
    payload: { data },
});

export const showAddProject = ({ isOpen, mainDiv, showProjectDetails }) => ({
    type: project.IS_SHOW_ADD_PROJECT,
    payload: { isOpen, mainDiv, showProjectDetails },
});

export const addTeamProject = ({ teams, projectId, customerId, formName, callLinkedProjectList }) => ({
    type: project.ADD_TEAM_PROJECT_REQUEST,
    payload: { teams, projectId, customerId, formName, callLinkedProjectList },
});

export const removeTeamProject = ({ teamId, projectId, customerId, teamName, projectName, formName, closeModal, refresh }) => ({
    type: project.REMOVE_TEAM_PROJECT_REQUEST,
    payload: { teamId, projectId, customerId, teamName, projectName, formName, closeModal, refresh },
});

export const getProjectTeams = ({ customerId, dropdown = false, count = false, projectId, agentsInfo }) => ({
    type: project.PROJECT_TEAM_LIST_REQUEST,
    payload: { customerId, dropdown, count, projectId, agentsInfo },
});

export const projectTeamWhiteList = ({ projectId, userId, customerId, customeMessage, formName, refresh, callGetLinkedProject }) => {
    return {
        type: project.PROJECTS_TEAMS_USER_WHITELIST_REQUEST,
        payload: { projectId, userId, customerId, customeMessage, formName, callGetLinkedProject, refresh },
    };
};

export const projectTeamBlackList = ({ projectId, userId, customerId, customeMessage, formName, refresh, callGetLinkedProject }) => {
    return {
        type: project.PROJECTS_TEAMS_USER_BLACKLIST_REQUEST,
        payload: { projectId, userId, customerId, customeMessage, formName, callGetLinkedProject, refresh },
    };
};

export const downloadProjectZip = ({ projectId, emails, formName }) => ({
    type: project.DOWNLOAD_PROJECT_ZIP_REQUEST,
    payload: { projectId, emails, formName },
});

export const getScogoDealIdsDropdownList = (customerId) => ({
    type: project.GET_SCOGO_DEALIDS_DROPDOWN_REQUEST,
    payload: { customerId },
});

export const clearDealListFromStore = () => ({
    type: project.CLEAR_DEAL_DROPDOWN_LIST_FROM_STORE,
});

export const createProjectTextPost = ({ message, projectId, formName }) => ({
    type: project.CREATE_PROJECT_TEXT_POST_REQUEST,
    payload: { message, projectId, formName },
});

export const createProjectAttachmentPost = ({ caption, projectId, attachment, formName }) => ({
    type: project.CREATE_PROJECT_ATTACHMENT_POST_REQUEST,
    payload: { caption, projectId, attachment, formName },
});
export const getProjectPost = ({ projectId }) => ({
    type: project.GET_PROJECT_POST_LIST_REQUEST,
    payload: { projectId },
});

export const getLinkedProjectOfUsers = ({ userId }) => ({
    type: project.GET_LINKED_PROJECT_OF_USERS_REQUEST,
    payload: { userId }
});

export const getLinkedProjectOfTeams = ({ teamId }) => ({
    type: project.GET_LINKED_PROJECT_OF_TEAMS_REQUEST,
    payload: { teamId }
});


export const createProjectTemplate = ({ createTemplateData, formName, formData, projectId }) => ({
    type: project.CREATE_PROJECT_TEMPLATE,
    payload: { createTemplateData, formName, formData, projectId },
});

export const createWorkflow = ({ templateData, formName, checkListdata, showToast }) => ({
    type: project.CREATR_WORKFLOW,
    payload: { templateData, formName, checkListdata, showToast },
});

export const editProjectPMRequest = ({ projectId, formName, fist_pm_id, second_pm_id, third_pm_id, escalate_manager
}) => ({
    type: project.EDIT_PROJECT_PM_REQUEST,
    payload: { projectId, formName, fist_pm_id, second_pm_id, third_pm_id, escalate_manager },
});

export const getProjectPMRequest = ({ projectId }) => ({
    type: project.GET_PROJECT_PM_REQUEST,
    payload: { projectId },
});


export const addMutlipleProjectsInTeams = ({ teamId, projectIds, formName, callLinkedProjectList }) => ({
    type: project.ADD_MULTIPLE_PROJECTS_IN_TEAM_PROJECT_REQUEST,
    payload: { teamId, projectIds, formName, callLinkedProjectList },
});

export const addSlaInProject = ({ wh_end_time, wh_start_time, sla_type, tier_1, tier_2, tier_3, tier_4, is_applicable, formName, projectId }) => ({
    type: project.ADD_SLA_IN_PROJECT_REQUEST,
    payload: { wh_end_time, wh_start_time, sla_type, tier_1, tier_2, tier_3, tier_4, is_applicable, formName, projectId },
});


export const getProjectSla = ({ projectId }) => ({
    type: project.GET_PROJECTS_SLA_REQUEST,
    payload: { projectId },
});