export const LIST_RECEIVABLES_GENERATE_INVOICE_REQUEST = 'LIST_RECEIVABLES_GENERATE_INVOICE_REQUEST';
export const LIST_RECEIVABLES_GENERATE_INVOICE_SUCCESS = 'LIST_RECEIVABLES_GENERATE_INVOICE_SUCCESS';
export const LIST_RECEIVABLES_GENERATE_INVOICE_FAILURE = 'LIST_RECEIVABLES_GENERATE_INVOICE_FAILURE';

export const LIST_RECEIVABLES_PROJECT_TICKETS_REQUEST = 'LIST_RECEIVABLES_PROJECT_TICKETS_REQUEST';
export const LIST_RECEIVABLES_PROJECT_TICKETS_SUCCESS = 'LIST_RECEIVABLES_PROJECT_TICKETS_SUCCESS';
export const RECEIVABLES_PROJECT_TICKETS_INVOICE_COUNT_SUCCESS = 'RECEIVABLES_PROJECT_TICKETS_INVOICE_COUNT_SUCCESS';
export const LIST_RECEIVABLES_PROJECT_TICKETS_FAILURE = 'LIST_RECEIVABLES_PROJECT_TICKETS_FAILURE';

export const CB_GENERATE_INVOICE_REQUEST = 'CB_GENERATE_INVOICE_REQUEST';
export const CB_GENERATE_INVOICE_SUCCESS = 'CB_GENERATE_INVOICE_SUCCESS';
export const CB_GENERATE_INVOICE_FAILURE = 'CB_GENERATE_INVOICE_FAILURE';

export const REVENUES_LIST_REQUEST = 'REVENUES_LIST_REQUEST';
export const REVENUES_LIST_SUCCESS = 'REVENUES_LIST_SUCCESS';
export const REVENUES_LIST_FAILURE = 'REVENUES_LIST_FAILURE';

export const DOWNLOAD_CUSTOMER_INVOICE_REQUEST = 'DOWNLOAD_CUSTOMER_INVOICE_REQUEST';
export const DOWNLOAD_CUSTOMER_INVOICE_SUCCESS = 'DOWNLOAD_CUSTOMER_INVOICE_SUCCESS';
export const DOWNLOAD_CUSTOMER_INVOICE_FAILURE = 'DOWNLOAD_CUSTOMER_INVOICE_FAILURE';

export const UPDATE_CUSTOMER_INVOICE_STATUS_REQUEST = 'UPDATE_CUSTOMER_INVOICE_STATUS_REQUEST';
export const UPDATE_CUSTOMER_INVOICE_STATUS_SUCCESS = 'UPDATE_CUSTOMER_INVOICE_STATUS_SUCCESS';
export const UPDATE_CUSTOMER_INVOICE_STATUS_FAILURE = 'UPDATE_CUSTOMER_INVOICE_STATUS_FAILURE';
export const REMOVE_RECEIVABLES_PROJECT_TICKETS_FROM_STORE = 'REMOVE_RECEIVABLES_PROJECT_TICKETS_FROM_STORE';
export const GET_CB_TICKET_FILTER_LIST_SUCCESS = 'GET_CB_TICKET_FILTER_LIST_SUCCESS';
