import React from 'react';

export default function SitesOption(props) {
    return (
        <div className='flex border-b-2 border-scogoddd justify-between'>
            <div className='lg:w-auto w-full'>
                <ul className='flex-wrap flex list-none lg:mt-1' role='tablist'>
                    {props.tabs.map((tab, index) => {
                        return <li key={index} className="flex-auto text-center ">
                            <span
                                className={
                                    "text-font12 font-medium lg:px-8 px-2 py-3 flex items-center gap-2 leading-normal inline-flex cursor-pointer	" +
                                    (props.selectedRootTab === tab.tabName
                                        ? "scogoprimary border-b-2 border-scogoprimary"
                                        : "text-scogogray hover:text-scogoorange")
                                }
                                onClick={() => props.goToInventoryTab(tab.subTabs[0].path)}
                                data-toggle="tab"
                                role="tablist"
                            >
                                {tab?.tabIcon && <span className={`material-icons ${tab?.iconSize}`}>{tab?.tabIcon}</span>}
                                {tab.tabName}
                            </span>
                        </li>
                    })}
                </ul>
            </div>
        </div>
    )
}