import React, { useEffect, useMemo, useState } from "react";
import RenderChatMessages from "."
import { useDispatch } from "react-redux";
import { openChatElement } from "../../../actions/conversations";
import { useSelector } from "react-redux";
import { OPEN_CHAT_ELEMENT_SUCCESS, PUT_TO_CONVERSATIONS_STORE } from "../../../types/conversations";
import { ChhotaChatBox } from "./ChhotaChatBox";
import { chatType } from "../ChatController";


export const MediumChatBox = () => {
    const dispatch = useDispatch();
    const { chatBoxElementData, conversationDetails, chatTickets } = useSelector((state) => state.conversations);
    const { ticketList } = useSelector((state) => state.conversations)
    const [isChatMinimized, setIsChatMinimized] = useState({});
    const { showTicketSideDrawer } = useSelector((state) => state.tickets);

    const handleCloseChat = (id, conversationName) => {
        setIsChatMinimized((prevState) => {
            delete prevState[id];
            return prevState;
        })
        dispatch({ type: OPEN_CHAT_ELEMENT_SUCCESS, payload: { _id: [id], status: false, conversationName } })
    };

    const handleOpenChat = (id, conversationName) => {
        dispatch({ type: OPEN_CHAT_ELEMENT_SUCCESS, payload: { _id: [id], status: true, conversationName } });
        if (conversationDetails && !conversationDetails[id]) {
            dispatch(openChatElement({ conversationId: id, source: 'first_load' }))
        }
    };

    const getLastTwoConversationIds = useMemo(() => {
        const trueEntries = Object.entries(chatBoxElementData).filter(([key, { status }]) => status === true).map((elem) => elem[0])
        const lastTwoTrueEntries = trueEntries.slice(-2)
        return lastTwoTrueEntries.reverse();
    }, [chatBoxElementData]);

    useEffect(() => {
        if (Array.isArray(getLastTwoConversationIds) && getLastTwoConversationIds.length > 0) {
            const enteries = Object.entries(chatBoxElementData).filter(([key, { status }]) => status === true).map((elem) => elem[0])
            const lastTwoTrueEntries = enteries.slice(-2)
            lastTwoTrueEntries.forEach((conversationId) => {
                if (!conversationDetails[conversationId]) {
                    dispatch(openChatElement({ conversationId, source: 'first_load' }))
                }
            });
        }
    }, [dispatch, getLastTwoConversationIds, chatBoxElementData, conversationDetails]);


    const getOpenedConversationsIds = useMemo(() => {
        const trueEntries = Object.entries(chatBoxElementData).filter(([key, { status }]) => status === true).map((elem) => elem[0])
        return trueEntries.filter(conversationId => {
            return conversationId && !getLastTwoConversationIds.includes(conversationId);
        })
    }, [chatBoxElementData, getLastTwoConversationIds]);

    useEffect(() => {
        if (Array.isArray(getLastTwoConversationIds) && getLastTwoConversationIds.length > 0 &&
            window.location.pathname !== '/tickets') {
            let data = {}
            getLastTwoConversationIds.forEach((id) => data[id] = true)
            setIsChatMinimized(data);

        }
        if (showTicketSideDrawer) {
            let data = {}
            getLastTwoConversationIds.forEach((id) => data[id] = true)
            setIsChatMinimized(data);

        }

    }, [window.location.pathname, showTicketSideDrawer]);

    useEffect(() => {
        if (Array.isArray(ticketList) && ticketList.length > 0) {
            const chat_tickets = chatTickets;
            ticketList.forEach((ticket) => {
                if (chat_tickets[ticket.ticket_id]) {
                    chat_tickets[ticket.ticket_id] = ticket;
                }
            });
            dispatch({ type: PUT_TO_CONVERSATIONS_STORE, payload: { chatTickets: chat_tickets } })
        }
    }, [ticketList, dispatch]);

    const handleChatArrowClick = (conversationId, e) => {
        e.stopPropagation();
        setIsChatMinimized(prev => {
            return {
                ...prev,
                [conversationId]: !prev[conversationId]
            };
        });
    };

    return (
        <div className={`pointer-events-none absolute ${getOpenedConversationsIds.length > 0 ? 'right-[24rem]' : 'right-[1vw]'} bottom-7 bg-transparent rounded-lg flex gap-4 items-end`}>
            {
                getLastTwoConversationIds.map(conversationId => {
                    let renderChecklists = false;
                    if (conversationDetails && conversationDetails[conversationId] && conversationDetails[conversationId].type === chatType.ticket) {
                        let ticket = chatTickets && chatTickets[conversationDetails[conversationId].conversationId];
                        renderChecklists = ticket && ticket.has_checklist === 1;
                    }
                    return (
                        <div
                            key={conversationId}
                            className={`pointer-events-auto	 ${isChatMinimized[conversationId] ? 'h-[3.5rem] w-[33rem]' : 'h-[40rem] w-[33rem]'} shadow-md rounded-md border border-scogoddd flex-1 overflow-auto`}
                        >
                            <RenderChatMessages
                                openedConversationId={conversationId}
                                handleCloseChat={handleCloseChat}
                                mainDivClass="h-full rounded-lg"
                                forceShowMessageInput
                                isChatPopup
                                handleChatArrowClick={(e) => handleChatArrowClick(conversationId, e)}
                                isChatMinimized={isChatMinimized[conversationId]}
                                renderChecklists={renderChecklists}
                            />
                        </div>
                    );
                })
            }
            <RenderChatSideBar getOpenedConversationsIds={getOpenedConversationsIds} onClick={handleOpenChat} />
        </div>
    );

}



const RenderChatSideBar = ({ getOpenedConversationsIds, onClick }) => {
    return (
        <div className="pointer-events-auto mediumChatSidebar fixed right-[1vw] bottom-6 max-h-[60%] flex flex-col gap-3 ">
            {getOpenedConversationsIds.length > 0 && <ChhotaChatBox getOpenedConversationsIds={getOpenedConversationsIds} onClick={onClick} />}
        </div>
    );
}