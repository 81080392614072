import React from 'react';
import { useDispatch } from 'react-redux'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import { closeModalAction } from '../actions/modal'
import IconToolTip from '../common/IconToolTip';


const Popup = ({ children, closeOnBackdropClick }) => {

  const dispatch = useDispatch();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    alignItems: 'center',
    padding: '10px 50px 30px',
    bgcolor: 'white',
    borderRadius: '10px',
    boxShadow: 24,
  };
  if (window.innerWidth < 1024) style.width = '75vw';

  const closeModal = () => {
    dispatch(closeModalAction())
  }

  return (
    <Modal
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      open={true}
      onClose={closeOnBackdropClick ? closeModal : null}
    >

      <Box sx={style}>
        <div className='absolute right-6 top-4'>
          <IconToolTip title='Close'>
            <span className="material-icons text-scogoprimary font-medium align-middle text-4xl hover:text-scogoorange cursor-pointer" onClick={closeModal}>
              highlight_off
            </span>
          </IconToolTip>
        </div>
        <div className='mt-4'>{children}</div>
      </Box>

    </Modal>
  )

}

export default Popup;