import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GetQueryParam, actualValueForDropdown, validateStringValue } from '../../utils/common';
import { sendToastMessage } from '../../actions/toast';
import { getBOQList, raisePO, viewTicketById } from '../../actions/tickets';
import { RaisePoInputs } from './Inputs';
import { TermsAndCondtion } from './TermsAndConditions';
import { RaisePOButtons } from './SubmitButtons';
import Form from '../../common/Form';
import { getPoAssetsCategoriesDropdown } from '../../actions/payments';
import { getCustomersList } from '../../actions/customer';
import { isCustomerGroup, isScm } from '../../utils/role';
import { getCustomerIdOfLoginUser } from '../../utils/utils';
import { getAllSitesDropdown } from '../../actions/inventory';
import { getEligibleSPsList } from '../../actions/sp';
import { FORM_BUTTON_LOADING } from '../../types/loading';

const quotesArrayName = 'quotes';

export const expenseTypes = [
    { key: 'capex', value: 'CAPEX', label: 'Capex' },
    { key: 'opex', value: 'OPEX', label: 'Opex' },
];

const formName = 'raisePo';

export const RaisePOForm = ({ closeModal, ticketId }) => {
    const submitRef = useRef();
    const editorRef = useRef();
    const { raisePOVendorsList } = useSelector((state) => state.customer);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isCustomerFeasibilityEligible, setIsCustomerFeasibilityEligible] = useState(false);
    const { boqList } = useSelector((state) => state.tickets);
    const { poassetcategories } = useSelector((state) => state.payments);
    const { loggedUser } = useSelector((state) => state.auth);
    const role = loggedUser.role.id;
    const type = loggedUser.type;
    const showCustomerDropdown = isScm(role, type);
    const isLoggedUserIsCustomer = isCustomerGroup(role);
    const { eligibleSPsDropdown } = useSelector((state) => state.sp);
    const { viewTicket } = useSelector((state) => state.tickets);
    const customerIdOfLoginUser = ticketId > 0 && viewTicket ? viewTicket.fk_customer_id : getCustomerIdOfLoginUser(loggedUser);

    useEffect(() => {
        if (ticketId) {
            dispatch(viewTicketById({ ticketId }));
            dispatch(getEligibleSPsList({ ticketId, dropdown: true, type: 'ISP', raisePo: true }));
        }
    }, [ticketId, dispatch]);

    const getQuotesData = ({ formValues }) => {
        let quotes = [];
        let assignToScogo = 0;
        if (Array.isArray(formValues.quotes) && formValues.quotes.length > 0) {
            formValues.quotes.forEach((quote) => {
                let data = {
                    assetType: actualValueForDropdown(quote.asset_type),
                    category: actualValueForDropdown(quote.category),
                    description: quote.description,
                    quantity: parseInt(quote.quantity),
                };

                if (isCustomerFeasibilityEligible) {
                    data.payment = {
                        isRecurring: quote.recurring ? quote.recurring : false,
                        startDate: null,
                        endDate: quote.recurring ? quote.end_date : null,
                    };
                }

                let vendors = [];
                const supplier_vendor_1 = actualValueForDropdown(formValues.supplier_vendor_1);
                const supplier_vendor_2 = actualValueForDropdown(formValues.supplier_vendor_2);
                const supplier_vendor_3 = actualValueForDropdown(formValues.supplier_vendor_3);
                if (!isNaN(supplier_vendor_1) && parseInt(supplier_vendor_1) > 0 && !isNaN(quote.quote_1) && parseFloat(quote.quote_1) > 0) {
                    let vendor;
                    if (Array.isArray(raisePOVendorsList) && raisePOVendorsList.length > 0) {
                        vendor = raisePOVendorsList.find((vendor) => parseInt(vendor.value) === parseInt(actualValueForDropdown(supplier_vendor_1)));
                    }
                    if (Array.isArray(eligibleSPsDropdown) && eligibleSPsDropdown.length > 0) {
                        vendor = eligibleSPsDropdown.find((vendor) => parseInt(vendor.value) === parseInt(actualValueForDropdown(supplier_vendor_1)));
                    }
                    let vendorName = vendor ? vendor.sp_name : '';
                    if (vendor && !['', undefined, null].includes(vendor.user)) {
                        if (['', undefined, null].includes(vendor.sp_name)) {
                            vendorName = `${vendor.user.first_name} ${vendor.user.last_name}`;
                        }
                        if (vendor.user.role && vendor.user.role.id === 1) {
                            assignToScogo = 1;
                        }
                    }
                    vendors.push({
                        vendor: supplier_vendor_1,
                        vendorName,
                        vendorAddress: formValues.vendor_address_1 || '',
                        price: parseFloat(quote.quote_1),
                        attachments: [],
                    });
                }
                if (!isNaN(supplier_vendor_2) && parseInt(supplier_vendor_2) > 0 && !isNaN(quote.quote_2) && parseFloat(quote.quote_2) > 0) {
                    let vendor;
                    if (raisePOVendorsList) {
                        vendor = raisePOVendorsList.find((vendor) => parseInt(vendor.value) === parseInt(actualValueForDropdown(supplier_vendor_2)));
                    }
                    let vendorName = vendor ? vendor.sp_name : '';
                    if (vendor && !['', undefined, null].includes(vendor.user)) {
                        if (['', undefined, null].includes(vendor.sp_name)) {
                            vendorName = `${vendor.user.first_name} ${vendor.user.last_name}`;
                        }
                        if (vendor.user.role && vendor.user.role.id === 1) {
                            assignToScogo = 1;
                        }
                    }
                    vendors.push({
                        vendor: supplier_vendor_2,
                        vendorName,
                        vendorAddress: formValues.vendor_address_2 || '',
                        price: parseFloat(quote.quote_2),
                        attachments: [],
                    });
                }
                if (!isNaN(supplier_vendor_3) && parseInt(supplier_vendor_3) > 0 && !isNaN(quote.quote_3) && parseFloat(quote.quote_3) > 0) {
                    let vendor;
                    if (raisePOVendorsList) {
                        vendor = raisePOVendorsList.find((vendor) => parseInt(vendor.value) === parseInt(actualValueForDropdown(supplier_vendor_3)));
                    }
                    let vendorName = vendor ? vendor.sp_name : '';
                    if (vendor && !['', undefined, null].includes(vendor.user)) {
                        if (['', undefined, null].includes(vendor.sp_name)) {
                            vendorName = `${vendor.user.first_name} ${vendor.user.last_name}`;
                        }
                        if (vendor.user.role && vendor.user.role.id === 1) {
                            assignToScogo = 1;
                        }
                    }
                    vendors.push({
                        vendor: supplier_vendor_3,
                        vendorName,
                        vendorAddress: formValues.vendor_address_3 || '',
                        price: parseFloat(quote.quote_3),
                        attachments: [],
                    });
                }

                if (vendors.length > 0) {
                    data.vendors = vendors;
                    quotes.push(data);
                }
            });
        }

        return { assignToScogo, quotes };
    };

    const onSubmit = (formValues) => {
        dispatch({ type: FORM_BUTTON_LOADING, payload: { formName, isLoading: true } }); // setting loading true
        let tnc = editorRef?.current?.getContent();
        let po = {
            expenseType: formValues.expense_type,
            billingAddress: formValues.billing_address || '',
            shippingAddress: formValues.shipping_address || '',
            gst: formValues.gst,
            quotes: [],
            requester: {
                name: `${loggedUser.first_name} ${loggedUser.last_name}`,
                email: loggedUser.email,
                mobile: loggedUser.mobile,
            },
        };
        if (formValues.paymentTerms) po.paymentTerms = formValues.paymentTerms;
        if (tnc) po.tnc = tnc;

        let { quotes, assignToScogo } = getQuotesData({ formValues });
        po.quotes = quotes;

        if (po.quotes.length > 0) {
            let existing = true,
                addNew = false;

            let ticketDetails = {
                fk_team_id: actualValueForDropdown(formValues.fk_team_id),
                title: formValues.title,
                external_ticket_id: formValues.external_ticket_id || '',
                job_fk_pincode_id: formValues.job_fk_pincode_id ? formValues.job_fk_pincode_id : viewTicket?.job_fk_pincode_id ? viewTicket.job_fk_pincode_id : '',
                job_site_address_1: formValues.job_site_address_1 ? formValues.job_site_address_1 : viewTicket?.job_site_address_1 ? viewTicket.job_site_address_1 : '',
                job_site_contact_name: formValues.job_site_contact_name || '',
                job_site_contact_mobile: formValues.job_site_contact_mobile || '',
                job_site_contact_mail: formValues.job_site_contact_mail || '',
                requester_name: `${loggedUser.first_name} ${loggedUser.last_name}`,
                requester_email: loggedUser.email,
                requester_mobile: loggedUser.mobile,
                po_raised: 1,
                assign_to_scogo: assignToScogo,
                userId: loggedUser.id,
                ticket_type: 2,
                service_type_id: 10,
                use_case_id: 0,
                fk_template_id: 0,
                is_noc_required: 1,
                self_pickup_required: 0,
                hard_copy_required: 0,
                is_rma_raised: 0,
                is_boq_required: 0,
                request_type: 'RAISE_PO',
                po,
                related_emails: !['', undefined, null].includes(formValues.related_emails) ? formValues.related_emails.split(',') : [],
                description: formValues.description || '',
                fk_customer_id: customerIdOfLoginUser,
                site_id: formValues.site_id ? actualValueForDropdown(formValues.site_id) : viewTicket.site_id ? viewTicket.site_id : 0,
                external_site_id: formValues.site_id ? formValues.site_id.label : formValues.view_site_id ? formValues.view_site_id : '',
            };
            if (!isLoggedUserIsCustomer) {
                ticketDetails.fk_customer_id = actualValueForDropdown(formValues.fk_customer_id);
            }
            if (viewTicket && viewTicket.fk_customer_id) {
                ticketDetails.fk_customer_id = viewTicket.fk_customer_id
            }
            if (viewTicket) {
                ticketDetails.parent_ticket_id = viewTicket.id
            }
            if (isCustomerFeasibilityEligible) {
                ticketDetails.link_type = actualValueForDropdown(formValues.link_type);
                ticketDetails.vendor_gst_pancard = formValues.vendor_gst_pancard || '';
                ticketDetails.vendor_code = formValues.vendor_code || '';
                ticketDetails.can_id = formValues.can_id || '';
            }
            dispatch(raisePO({ formValues: { location_data: [ticketDetails] }, existing, addNew, refresh: true, navigate, formName }));
        } else {
            dispatch(sendToastMessage({ status: 'warning', message: 'Invalid Quote Details' }));
            dispatch({ type: FORM_BUTTON_LOADING, payload: { formName, isLoading: false } }); // setting loading false
        }
    };

    useEffect(() => {
        dispatch(getBOQList(customerIdOfLoginUser));
        dispatch(getPoAssetsCategoriesDropdown());
        if (!ticketId) {
            if (showCustomerDropdown) dispatch(getCustomersList());
            dispatch(getAllSitesDropdown({ customerId: customerIdOfLoginUser }));
        }
    }, [dispatch, customerIdOfLoginUser, showCustomerDropdown, ticketId]);

    const defaultValues = { expense_type: expenseTypes[0].value, [quotesArrayName]: [{ id: `${Math.random()}` }] };

    const methods = useForm({ defaultValues, mode: 'all' });
    const { setValue, watch } = methods;
    const vendor_1 = watch('supplier_vendor_1');
    const site_id = watch('site_id');

    useEffect(() => {
        if (site_id) {
            setValue('job_fk_pincode_id', site_id.fk_pincode_id);
            setValue('job_site_address_1', site_id.complete_site_address);
        }
    }, [site_id, setValue]);

    const getQuotesDefaultValues = useCallback(
        (isFeasibilityEligible, { otcPrice, routerPrice, monthlyPlanPrice, staticIpChargePrice } = {}) => {
            const oneTimeCharge = boqList?.find((boq) => boq.label === 'One Time Charge');
            const routerCost = boqList?.find((boq) => boq.label === 'Router Cost');
            const monthlyPlan = boqList?.find((boq) => boq.label === 'Monthly Plan');
            const staticIpCharge = boqList?.find((boq) => boq.label === 'Static IP Charge');
            const subscription = poassetcategories?.find((category) => category.label === 'subscription');
            if (isFeasibilityEligible) {
                return [
                    { id: `${Math.random()}`, asset_type: oneTimeCharge, category: subscription, quantity: 1, quote_1: otcPrice },
                    { id: `${Math.random()}`, asset_type: routerCost, category: subscription, quantity: 1, quote_1: routerPrice },
                    { id: `${Math.random()}`, asset_type: monthlyPlan, category: subscription, quantity: 1, quote_1: monthlyPlanPrice },
                    { id: `${Math.random()}`, asset_type: staticIpCharge, category: subscription, quantity: 1, quote_1: staticIpChargePrice },
                ];
            }

            return [{ id: `${Math.random()}` }];
        },
        [boqList, poassetcategories]
    );

    useEffect(() => {
        if (vendor_1 && vendor_1?.ispTicketResponse?.length > 0) {
            let response = vendor_1.ispTicketResponse[0];
            let otcPrice = response.otc;
            let routerPrice = response.router_cost;
            let monthlyPlanPrice = response.monthly_plan;
            let staticIpChargePrice = response.static_ip_charge;
            let vendor_gst_pancard = validateStringValue(vendor_1.customer_gst_number) || validateStringValue(vendor_1.customer_pan_card_number);
            setValue('vendor_code', validateStringValue(vendor_1?.vendor_code?.code));
            setValue('vendor_gst_pancard', vendor_gst_pancard);
            setValue(quotesArrayName, getQuotesDefaultValues(true, { otcPrice, routerPrice, monthlyPlanPrice, staticIpChargePrice }));
        }
    }, [vendor_1, getQuotesDefaultValues, setValue]);

    const fillDataFromViewTicket = useCallback(
        (viewTicket) => {
            setValue('site_id', viewTicket?.site_id)
            setValue('job_fk_pincode_id', viewTicket?.job_fk_pincode_id)
            setValue('job_site_address_1', viewTicket?.job_site_address_1)
            setValue('job_site_contact_name', viewTicket?.contact_person_name)
            setValue('job_site_contact_mail', viewTicket?.contact_person_email)
            setValue('job_site_contact_mobile', viewTicket?.contact_person_mobile)
            if (viewTicket.job_fk_pincode_id) setValue('view_job_fk_pincode_id', viewTicket.job_fk_pincode_id);
            if (viewTicket.job_site_address_1) setValue('view_job_site_address_1', viewTicket.job_site_address_1);
            if (viewTicket.job_site_address_1) setValue('view_site_id', validateStringValue(viewTicket?.site_detail?.external_site_id));
            if (viewTicket.job_site_address_1) setValue('view_fk_customer_id', validateStringValue(viewTicket?.customer_details?.customer_company_name));

            let user = undefined;

            if (Array.isArray(eligibleSPsDropdown)) {
                user = eligibleSPsDropdown.find((user) => {
                    return user.customer_id === viewTicket.isp_id;
                });
            }

            if (user) {
                if (viewTicket.service_type_id === 13) {
                    user.value = user.customer_id
                }
                let vendor_gst_pancard = validateStringValue(user.customer_gst_number) || validateStringValue(user.customer_pan_card_number);
                setValue('vendor_gst_pancard', vendor_gst_pancard);
                setValue('vendor_code', validateStringValue(user?.vendor_code?.code));
                setValue('supplier_vendor_1', user);
                if (viewTicket?.customer_details?.is_feasibility_eligible === 1) {
                    setValue(quotesArrayName, getQuotesDefaultValues(true));
                }
            }
        },
        [eligibleSPsDropdown, setValue, getQuotesDefaultValues]
    );

    useEffect(() => {
        if (ticketId && viewTicket) {
            fillDataFromViewTicket(viewTicket);
        }
    }, [viewTicket, ticketId]);

    return (
        <Form onSubmit={onSubmit} methods={methods} submitRef={submitRef} className='bg-white w-full pb-2 approvePOContainer rounded-lg shadow-lg'>
            <div className='approvePOForm overflow-y-auto bg-white rounded-t-lg'>
                <RaisePoInputs
                    quotesArrayName={quotesArrayName}
                    isCustomerFeasibilityEligible={isCustomerFeasibilityEligible}
                    setIsCustomerFeasibilityEligible={setIsCustomerFeasibilityEligible}
                    showCustomerDropdown={showCustomerDropdown}
                    viewTicket={viewTicket}
                    ticketId={ticketId}
                    getQuotesDefaultValues={getQuotesDefaultValues}
                />
                {isLoggedUserIsCustomer && (
                    <div className='w-full px-4'>
                        <TermsAndCondtion editorRef={editorRef} />
                    </div>
                )}
            </div>
            <div className='approvePOButtons flex gap-2 items-center bg-white px-7 rounded-b-lg bottom-0 sticky'>
                <RaisePOButtons submitRef={submitRef} formName={formName} closeModal={closeModal} />
            </div>
        </Form>
    );
};
