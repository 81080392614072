import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import { updatePoInvoiceRequest } from '../../../actions/payments';
import moment from 'moment';
import Form from '../../../common/Form';
import Input from '../../../common/Form/Input';
import FormTable from '../../../common/Form/Table';
import { useForm } from 'react-hook-form';
import DatePicker from '../../../common/Form/DatePicker';
import Upload from '../../../common/Form/FileUpload';

const arrayName = 'invoices';

const ManualPOInvoice = (props) => {
    const { ticket, isRefresh } = props;
    const formName = 'updatePoInvoice';
    const dispatch = useDispatch();
    const formDivRef = useRef(null);
    const { formButtonLoading } = useSelector((state) => state.loading);

    const defaultValues = {
        [arrayName]: [{ id: `${Math.random()}` }],
    };

    const onSubmit = (formValues) => {
        let files = [];
        const invoices =
            formValues[arrayName] &&
            formValues[arrayName].map((invoice) => {
                if (invoice.attachments) files = [...files, ...invoice.attachments];
                return {
                    amount: invoice.amount,
                    attachments: invoice.attachments ? [...invoice.attachments].map((file) => ({ mimeType: file.type, name: file.name })) : undefined,
                    invoiceDate: moment(invoice.invoiceDate).format('YYYY-MM-DD'), // required, so safe
                    invoiceNumber: invoice.invoiceNumber,
                    quantity: invoice.quantity,
                };
            });

        let payload = {
            formName,
            ticketId: ticket?.ticketId,
            data: { invoices },
            isRefresh,
            files,
            enduserId: ticket?.enduserId
        };
        dispatch(updatePoInvoiceRequest(payload));
    };

    const closeModal = () => {
        dispatch(closeModalAction());
    };

    const schema = {
        table: [
            {
                head: {
                    thClassName: `pl-2 w-60`,
                    headName: 'Invoice Number',
                    required: true,
                },
                body: {
                    bodyClassName: `pl-1 w-60`,
                    render: (_, { rowIndex }) => (
                        <div>
                            <Input name={`${arrayName}[${rowIndex}].invoiceNumber`} type='text' required placeholder='Invoice Number' />
                        </div>
                    ),
                },
            },
            {
                head: {
                    thClassName: `w-60`,
                    headName: 'Invoice Date',
                    required: true,
                },
                body: {
                    bodyClassName: `w-60`,
                    render: (_, { rowIndex }) => (
                        <div>
                            <DatePicker name={`${arrayName}[${rowIndex}].invoiceDate`} required placeholder='Invoice Date' padding={''} />
                        </div>
                    ),
                },
            },
            {
                head: {
                    thClassName: `w-60 pl-2`,
                    headName: 'Quantity',
                },
                body: {
                    name: 'quantity',
                    bodyClassName: `w-60`,
                    render: (_, { rowIndex }) => (
                        <div>
                            <Input name={`${arrayName}[${rowIndex}].quantity`} type='number' placeholder='Quantity' />
                        </div>
                    ),
                },
            },
            {
                head: {
                    thClassName: `w-60 pl-2`,
                    headName: 'Amount',
                },
                body: {
                    bodyClassName: `w-60`,
                    allowFloats: true,
                    render: (_, { rowIndex }) => (
                        <div>
                            <Input name={`${arrayName}[${rowIndex}].amount`} type='number' step='any' placeholder='Amount' />
                        </div>
                    ),
                },
            },
            {
                head: {
                    thClassName: `w-60 pl-2`,
                    headName: 'Customer PO',
                },
                body: {
                    bodyClassName: `w-60`,
                    render: (_, { rowIndex }) => (
                        <div>
                            <Input name={`${arrayName}[${rowIndex}].customerPoNumber`} type='text' placeholder='Customer PO' />
                        </div>
                    ),
                },
            },
            {
                head: {
                    thClassName: `w-60 pl-2`,
                    headName: 'Attachments',
                },
                body: {
                    bodyClassName: `w-60`,
                    render: (_, { rowIndex }) => (
                        <div>
                            <Upload name={`${arrayName}[${rowIndex}].attachments`} isMulti placeholder='Attachments' />
                        </div>
                    ),
                },
            },
            {
                head: {
                    thClassName: `w-24`,
                    headName: 'Delete',
                },
                body: {
                    bodyClassName: `w-24 pl-2`,
                    render: (_, { rowIndex, remove }) => {
                        return (
                            <span
                                onClick={(e) => {
                                    e.preventDefault();
                                    remove(rowIndex);
                                }}
                                className={`material-icons text-scogoToastDangerIcon cursor-pointer hover:text-scogoorange`}
                            >
                                delete
                            </span>
                        );
                    },
                },
            },
        ],
    };

    const methods = useForm({ defaultValues, mode: 'all' });

    return (
        <div ref={formDivRef}>
            <Form
                className='w-full'
                onSubmit={onSubmit}
                onCancel={closeModal}
                buttonPostion={'justify-end'}
                isLoading={formButtonLoading[formName]}
                submitButtonText='Submit'
                showSubmit
                submitContainerClassName='flex gap-2 justify-end py-4 px-4'
                methods={methods}
            >
                <div style={{ padding: '1.25rem' }}>
                    <FormTable isLoading={false} schema={schema} name={arrayName} allowAdd addButtonClassName='my-2' rowGenerator={() => ({ id: `${Math.random()}` })} />
                </div>
            </Form>
        </div>
    );
};

export default ManualPOInvoice;
