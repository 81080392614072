import React, { useState } from 'react';
import { getIsToShowAddDocketButton, serviceTypeIds, } from '../TicketController';
import { handHelding } from '../TicketTableComponent';
import ButtonScogoOrange from '../../../common/Buttons/ButtonScogoOrange';
import { getLookupWitHDefaultValue } from '../../../utils/common';
import IconToolTip from '../../../common/IconToolTip';
import { DateComponent } from '../../../common/TableReusableColumns';
import Popover from '@mui/material/Popover';



export const Shipping = ({ ticket }) => {
    const forwardLogisitics = ticket.tracking_detail;
    const rltracking_detail = ticket.rltracking_detail;
    const showAddDocket = getIsToShowAddDocketButton(ticket);

    const onAddDocketCLick = () => {
        if (ticket.serviceTypeId === serviceTypeIds.migration) {
            window.open(`/tickets/${ticket.ticket_id}/edit`);
        } else {
            window.open(`/tickets/${ticket?.ticket_id}/execute/initiate`);
        }
    };

    return (
        <div>
            {forwardLogisitics && (
                <ShippingDetails
                    awbNo={forwardLogisitics.awbno}
                    imageClass='flip-horizontally'
                    status={forwardLogisitics.carrier === handHelding ? forwardLogisitics.carrier : forwardLogisitics.current_status}
                    carrier={forwardLogisitics.carrier}
                    pod={forwardLogisitics.pod}
                    delivery_date={forwardLogisitics.delivery_date}
                    label='Forward'
                />
            )}
            {rltracking_detail && (
                <ShippingDetails
                    awbNo={rltracking_detail.awbno}
                    status={rltracking_detail.carrier === handHelding ? rltracking_detail.carrier : rltracking_detail.current_status}
                    carrier={rltracking_detail.carrier}
                    pod={rltracking_detail.pod}
                    delivery_date={rltracking_detail.delivery_date}
                    label='Reverse'
                />
            )}
            {showAddDocket && (
                <div className='mt-1'>
                    <ButtonScogoOrange buttonClass='text-font11' textOrComponent={'Add Docket'} onClick={() => onAddDocketCLick()} />
                </div>
            )}
        </div>
    );
}



const ShippingDetails = (props) => {
    const { awbNo, imageClass = '', status, carrier, pod, delivery_date, label } = props;

    const current_status = getLookupWitHDefaultValue({
        lookup: {
            INTRASIT: 'orange',
            DELIVERED: 'text-scogosuccess',
            RTO_DELIVERED: 'text-scogosuccess',
            [handHelding]: 'text-scogosuccess',
        },
        defaultValue: 'text-scogoclosed',
    });

    const isViewMoreDataAvailable = carrier || pod || delivery_date;
    const isHandHelding = handHelding === status;

    const popup = (<div className='flex flex-col gap-2 max-w-30vw px-2 py-2' >
        {[
            { value: carrier, label: 'Carrier', link: false },
            { value: pod, label: 'POD', link: pod },
        ].map(({ label, value, link }) => {
            if (!value) return <></>;
            return (
                <>
                    {link ?
                        <a href={value} alt="" target='_blank' rel="noreferrer" className='text-scogogray font-medium text-font12' >
                            {label} : <span className=' text-scogobgsky hover:text-scogoorange font-normal break-all'>{value}</span>
                        </a>
                        : <span className='text-scogogray font-medium text-font12'>
                            {label} : <span className='text-black font-normal break-all'>{value}</span>
                        </span>}
                </>
            );
        })}
        {delivery_date && (
            <span>
                <span className='text-scogogray font-medium text-font12'>Delivery Date :</span>
                <DateComponent date={delivery_date} timeFormat='DD MMM YYYY' />
            </span>
        )}
    </div>)

    return (
        <div className='w-full block'>
            <div className='md:block flex-wrap flex items-center'>
                <div className='flex md:mr-0 mr-2 md:mb-0'>
                    <div>
                        <span className={`${imageClass} material-icons text-2xl ${current_status[status]}`}>local_shipping</span>
                    </div>
                    <div>
                        <IconToolTip title={label}>
                            <p className='text-font11 text-scogo15 font-normal ml-2'>{status}</p>
                        </IconToolTip>
                    </div>
                </div>
                {!isHandHelding && (
                    <div className='md:block flex md:mr-0 mr-2 flex-wrap items-center md:mb-0'>
                        {awbNo && (
                            <p className=' text-font11 font-normal md:mr-0 mr-2'>
                                <span className='text-scogogray'> AWB No : </span> <span className='text-scogoprimary'> {` ${awbNo}`} </span>
                            </p>
                        )}
                        {isViewMoreDataAvailable && (
                            <div className='relative cursor-pointer text-scogobgsky  font-medium '>
                                <ViewMorwPopup carrier={carrier} pod={pod} delivery_date={delivery_date} popup={popup}>
                                    <p className='text-scogobgsky text-left   font-medium cursor-pointer md:mr-0 mr-2 ml-auto hover:text-scogoorange'>
                                        <span className='hover:text-scogoorange text-font11'>View More</span>
                                    </p>
                                </ViewMorwPopup>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

const ViewMorwPopup = ({
    children,
    anchorOrigin = {
        vertical: 'top',
        horizontal: 'center',
    },
    transformOrigin = {
        vertical: 'bottom',
        horizontal: 'center',
    },
    popup
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <div aria-describedby={id} onClick={handleClick} variant='contained' className=' hover:underline cursor-pointer inline'>
                {children}
            </div>
            <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={anchorOrigin} transformOrigin={transformOrigin}>
                {popup}
            </Popover>
        </>
    );
};
