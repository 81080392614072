import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import DetailTag from "../../project/DetailTag";
import { replaceAssets, getAllAssetsDropdown } from '../../../actions/inventory'
import Form from '../../Inventory/reusableComponents/AddOption/Form';
import Input from '../../Inventory/reusableComponents/AddOption/Input';
import { textCss } from '../../../common/Css';




const ReplaceAsset = ({ modalData, customerId }) => {
    const siteId = modalData?.site?.id;
    const formName = 'replaceAsset';
    const assetToReplace = modalData.assetslist[0];
    const dispatch = useDispatch();
    const { replaceAssetDropdown } = useSelector((state) => state.inventory);
    const { isReplaceAssetDropdownLoading } = useSelector((state) => state.loading);
    const [replaceAssetDropdownData, setReplaceAssetDropdownData] = useState([]);

    const assetType = (assetToReplace.assetType && assetToReplace.assetType.title) ? assetToReplace.assetType.title : '';
    const serial = (assetToReplace.serial_number) ? `(${assetToReplace.serial_number})` : '';
    const currentAssetDisplay = `${assetType} ${serial}`;
    const initialValues = {
        current: currentAssetDisplay,
        replacement: []
    };

    useEffect(() => {
        dispatch(getAllAssetsDropdown({ customerId }))
    }, []);

    useEffect(() => {
        if (Array.isArray(replaceAssetDropdown)) {
            let assets = [...replaceAssetDropdown].filter(asset => asset.key !== assetToReplace.id);
            setReplaceAssetDropdownData(assets);
        }
    }, [replaceAssetDropdown]);

    const [form, setForm] = useState(initialValues);

    const submit = (formValues) => {
        let data = {
            assets: {
                current: assetToReplace.id,
                replacement: formValues?.replacement?.value
            }
        }

        dispatch(replaceAssets({ data, formName, enduserId: modalData.enduserId }))
    }

    const closeModal = () => { dispatch(closeModalAction()) };

    return (
        <>
            <Form
                className='w-full '
                submit={(e) => { submit(e) }}
                onCancel={closeModal}
                buttonPostion={'justify-end'}
                initialValues={initialValues}
                form={form}
                setForm={setForm}
                formName={formName}
                submitButtonName='Replace'
            >
                <div className="md:flex pl-5 pt-3 ">
                    <DetailTag tagHeadClass={textCss?.detailsTitle} tagValueClass={textCss?.detailsValue} tagClass=" px-2 md:w-6/12" tagName="Site Id" tagValue={modalData?.siteId} isLoading={isReplaceAssetDropdownLoading} />
                    <DetailTag tagHeadClass={textCss?.detailsTitle} tagValueClass={textCss?.detailsValue} tagClass="px-2 md:w-6/12" tagName="Address" tagValue={modalData?.address} isLoading={isReplaceAssetDropdownLoading} />
                </div>
                <div className='px-3 flex justify-between pb-5 pt-5 items-end'>
                    <Input label='Current Asset' name='current' type={'text'} className={'w-6/12'} disabled />
                    <Input label='New Asset' required name='replacement' type={'dropdown'} options={replaceAssetDropdownData} placeholder={'New Asset'} className={'w-6/12'} isLoading={isReplaceAssetDropdownLoading} />
                </div>
            </Form>

        </>
    );

}

export default ReplaceAsset;