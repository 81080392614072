export const googleEvents = {

    projectPost: {
        main_event: "Project_Post_Event",
        add_post_button: "add_post_button",
        project_details_tab: "project_details_tab",
        post_create: "post_create"
    },

    whatsApp: {
        main_event: "WhatsApp_Event",
        whatsapp_qr_popup: "whatsapp_qr_popup",
        whatsapp_list: "whatsapp_list",
        whatsapp_message_forwarded: "whatsapp_message_forwarded"
    },
    checklist: {
        main_event: "Checklist_Event",
        checklist_execute: 'checklist_execute'
    }
}