import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import DateTimePicker from '../../pages/Inventory/reusableComponents/AddOption/fields/DatePicker';
import { textCss } from "../Css";
import moment from 'moment';

export const DateRangeFilter = forwardRef((props, ref) => {
    const { maxDateFromToday, minDate, value, dateFormat = "dd-MM-yyyy", inputValueFormat = 'DD-MM-yyyy', placeholder = 'Date Range', filterName = 'dateFilter', title, filterList, setIsApplyDisabled } = props;
    const dateFilterName = 'dateRangeFilter';


    useEffect(() => {
        setIsApplyDisabled(true)
    }, [])

    const getStartOrEndDate = () => {
        let range = [null, null]
        let selectedDate = filterList[filterName]
        if(typeof selectedDate === 'object'){
            let {fromDate, toDate} = selectedDate;
            if(fromDate) fromDate = moment(fromDate)._d
            if(toDate) toDate = moment(toDate)._d
            range = [fromDate, toDate]
        }
        return range
    }
    const [dateRange, setDateRange] = useState(getStartOrEndDate);

    const onChangeHandler = (value) => {
        let { startDate, endDate } = value.value;
        let isValidDate = (date) => moment(date).isValid();
        if (isValidDate(startDate) && isValidDate(endDate)) {
            let dRange = [moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD')];
            setDateRange(dRange);
            setIsApplyDisabled(false)
        } else if (startDate && endDate === null) {
            setIsApplyDisabled(true)
        } else {
            setDateRange([startDate, endDate])
            setIsApplyDisabled(false)
        }
    }

    useImperativeHandle(ref, () => ({
        getLocalFilter() {
            let [fromDate, toDate] = dateRange;
            let date_range = {};
            if(fromDate && toDate) date_range = {fromDate, toDate}
            return { [filterName]:  date_range}
        },
    }));

    return (
        <>
            <h1 className={`${textCss?.headerTwo} px-3 pt-2`}>{title}</h1>
            <DateTimePicker
                label={''}
                onChangeHandler={onChangeHandler}
                placeholder={placeholder}
                maxDate={maxDateFromToday}
                minDate={minDate}
                value={value}
                dateFormat={dateFormat}
                inputValueFormat={inputValueFormat}
                todayButton={false}
                defaultStartEndDate={dateRange}
                name={dateFilterName}
            />
        </>
    )
})

export default DateRangeFilter