import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { unlockSignoff } from '../../../actions/tickets';
import Form from '../../../common/Form';
import { closeModalAction } from '../../../actions/modal';
import Input from '../../../common/Form/Input';


const formName = 'unlock_signoff';

export const UnlockSignoff = ({ ticketId }) => {

    const { formButtonLoading } = useSelector((state) => state.loading);
    const defaultValues = { remark: '' };

    const dispatch = useDispatch();

    const methods = useForm({ defaultValues, mode: 'all' });

    const submit = (formValues) => {
        dispatch(unlockSignoff({ formName, ticketId, remark: formValues.remark }))
    };

    return <Form
        methods={methods}
        onSubmit={submit}
        formName={formName}
        buttonPostion={'justify-end'}
        submitButtonText={'Submit'}
        onCancel={() => dispatch(closeModalAction())}
        showSubmit
        submitContainerClassName='flex gap-2 justify-end py-4 px-4'
        isLoading={formButtonLoading[formName]}
    >
        <div className='px-2'>
            <Input name='remark' className='w-full' label='Remark' required />
        </div>
    </Form>
}