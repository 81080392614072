import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { emailTomorrowsPlannedTicketsCsv } from '../../../actions/project';
import moment from 'moment';
import { closeModalAction } from '../../../actions/modal';
import Form from '../../../common/Form';
import DatePicker from '../../../common/Form/DatePicker';
import MultipleEmails from '../../../common/Form/MultipleEmail';
import { useForm } from 'react-hook-form';

const formName = 'emailPlanningReportForm';
export default function EmailPlanningReport(props) {
    const { formButtonLoading } = useSelector((state) => state.loading);
    const dispatch = useDispatch();

    const closeModal = () => {
        dispatch(closeModalAction());
    };

    const defaultValues = {
        targetDate: new Date(),
        emails: props?.projectDetail?.project_contact_person_email_address ? [props?.projectDetail?.project_contact_person_email_address] : [],
    };

    const submit = (form) => {
        const payload = {
            targetDate: moment(form.targetDate).format('YYYY-MM-DD'),
            targetEmails: form.emails,
            projectId: props.projectDetail.id,
            formName,
        };
        dispatch(emailTomorrowsPlannedTicketsCsv(payload));
    };

    const methods = useForm({ defaultValues, mode: 'all' });

    return (
        <Form
            methods={methods}
            onSubmit={(e) => {
                submit(e);
            }}
            buttonPostion={'justify-end'}
            submitButtonName={'Submit'}
            onCancel={closeModal}
            showSubmit
            submitContainerClassName='flex gap-2 justify-end py-4 px-4'
            isLoading={formButtonLoading[formName]}
        >
            <div className='px-3'>
                <DatePicker className='w-full' label='Target Date' name='targetDate' required placeholder={'Select Date'} />
                <MultipleEmails label='Enter Emails' name='emails' required />
            </div>
        </Form>
    );
}
