import IconToolTip from '../../../common/IconToolTip';
import UserPopup from '../../../common/Popups/User';

export const PartnerDetailsPopUp = ({ displayName, userId, className = 'truncate w-10/12 text-scogobgsky cursor-pointer text-left text-font11 hover:text-scogoorange w-full' }) => {
    return (
        <UserPopup
            userId={userId}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'right',
            }}
        >
            <IconToolTip title={displayName}>
                <div className={className}>{displayName}</div>
            </IconToolTip>
        </UserPopup>
    );
};
