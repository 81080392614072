import React, { useMemo } from 'react';
import ProfileImage from '../chat/components/ProfileImage';
import OverflowTip from '../../common/OverflowTip';
import { TypingUsers } from '../chat/chatList/groups/LastMessage';
import MessageInput from '../chat/messageBox/InputBar/MessageInput';
import Message from '../chat/messageBox/Messages/Message';
import { isDateAreSame } from '../chat/ChatMessage';
import { getLastVisibleElementId } from '../chat/ChatMessage';
import DateDivider from '../chat/ChatMessage/DateDivider';
import { RenderScrollButton } from '../chat/ChatMessage';
import { useSelector } from 'react-redux';
import { useRef } from 'react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { getConversationDetails, getConversationMessages, getNextConversationMessages, putToConversationStore, setConversationUnreadableMessageCount, setopenedConversationMessageIsLatest, updateunReadMessage } from '../../actions/conversations';
import { useEffect } from 'react';
import { validateNumberValue } from '../../utils/common';
import { ChatMsgLoader } from '../chat/messageBox/ChatComponents/ChatMsgLoader';
import Loader from '../../common/Loader';
import { conversations } from '../../types';
import { TicketSearch } from './RenderTicketCard';
import { Skeleton } from '@mui/material';
import ButtonScogoPrimary from '../../common/Buttons/ButtonScogoPrimary';

export const RenderTicketConversation = ({ closeIconClick, showConversation, ticketId, isUserLoggedIn, noTicketFound, ticketDetails, onSearchIconClick }) => {
    const { conversationDetailsLoadFailure } = useSelector((state) => state.conversations);

    if (!isUserLoggedIn || !ticketId || (noTicketFound && !ticketDetails) || conversationDetailsLoadFailure) {
        return <DisabledChatInfo ticketId={ticketId} />
    }

    return <div className='overflow-hidden chat-card-wtih-edit-ticket border'>
        <ConversationCard closeIconClick={closeIconClick} showConversation={showConversation} ticketId={ticketId} onSearchIconClick={onSearchIconClick} />
    </div>
}

const ConversationCard = ({ closeIconClick, showConversation, ticketId, onSearchIconClick }) => {
    const [replyShow, setReplyShow] = useState(false);
    const [replyToMessage, setReplyToMessage] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { openedConversationMessageIsLatest, mpConversationUserTyping, latestMessagesIds, messageQueueList, conversationMessages, openedConversationId, conversationDetails, openedConversationUnreadableMessageCount } = useSelector((state) => state.conversations);
    const { isConversationMessagesLoading, isNextConversationMessagesLoading } = useSelector((state) => state.loading);
    const [prevScroll, setPrevScroll] = useState({ messageLength: 0, height: 0, lastMessaseId: 0 });
    const scrollDivRef = useRef(null);
    const conversationDetail = conversationDetails && conversationDetails[ticketId];
    const openedQueueMessagesList = messageQueueList[conversationDetail?._id];
    const openedConversationMessagesList = conversationMessages[conversationDetail?._id];
    const [showScrollButton, setShowScrollButton] = useState(false);
    const firstMessageRef = useRef(null);

    const openedConversationMessages = useMemo(() => {
        let messages = openedConversationMessagesList;
        let openedQueueLst = openedQueueMessagesList;
        if (Array.isArray(openedQueueLst) && Array.isArray(messages)) {
            messages = [...messages, ...openedQueueLst];
        }
        return messages;
    }, [openedQueueMessagesList, openedConversationMessagesList]);

    useEffect(() => {
        if (conversationDetail?._id && !openedConversationMessagesList) {
            dispatch(putToConversationStore({ openedConversationId: conversationDetail._id }))
            dispatch(getConversationMessages({ _id: conversationDetail._id }));
        }
    }, [conversationDetail?._id, dispatch]);

    useEffect(() => {
        if (firstMessageRef.current) {
            firstMessageRef.current.style.display = 'none';
        }
    }, [openedConversationId]);


    const closeReply = useCallback(() => {
        setReplyShow(false);
        setReplyToMessage(null);
    }, []);

    const onReplyClick = (selectedMessage) => {
        setReplyShow(true);
        setReplyToMessage(selectedMessage);
    };

    const onClickReplyMessage = (id) => {
        var messageDiv = document.getElementById(id);
        if (messageDiv) {
            messageDiv.scrollIntoView({ behavior: 'smooth' }, true);
            messageDiv.className = 'highLightDiv';
            _.delay(() => {
                messageDiv.classList.remove('highLightDiv');
            }, 5000);
        } else {
            // scrollDivTo(4);
            let params = new URLSearchParams(window.location.search);
            params.set('messageId', id);
            params = params.toString();
            _.delay(() => {
                navigate({
                    pathname: window.location.pathname,
                    search: params,
                });
            }, 600);
        }
    };

    const getLatestConversationMessages = (delayTime = 500) => {

        if (openedConversationMessageIsLatest?.[conversationDetail._id]) {
            scrollToBottom({ block: 'end' });
        } else {
            scrollToBottom({ block: 'end' });
            _.delay(() => {
                dispatch(setopenedConversationMessageIsLatest({ isLatest: true, _id: conversationDetail._id }));
                dispatch(getConversationMessages({ _id: conversationDetail._id }));
                let params = new //URLSearchParams(window.location.search);
                    params.delete('messageId');
                params = params.toString();
                navigate({
                    pathname: window.location.pathname,
                    search: params,
                });
            }, delayTime);
        }
        if (conversationDetail?.unreadMessageCount > 0) {
            dispatch(updateunReadMessage({ unreadCount: 0, _id: conversationDetail._id }))
        }
    };


    const scrollToBottom = useCallback((options = { behavior: 'smooth' }) => {
        let lastDiv = document.getElementById('last_element_after_messages');
        lastDiv?.scrollIntoView(options, true);
    }, []);

    const showCurrentDateOnScroll = () => {
        let chatMsgsRef = scrollDivRef.current;
        if (!chatMsgsRef) return;
        let dateDividers = chatMsgsRef && chatMsgsRef.querySelectorAll('.dateDivider');

        let currentLabel = null;
        dateDividers.forEach((dateLabel) => {
            if (chatMsgsRef.scrollTop >= dateLabel.offsetTop) {
                currentLabel = dateLabel;
            }
        });

        if (currentLabel) {
            firstMessageRef.current.style.display = 'block';
            firstMessageRef.current.innerText = currentLabel.innerText;
        }
    };

    const handleScroll = (event) => {
        let isScrollNotAtBottom = Math.abs(event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight) >= 100;
        const scrollOnBottom = Math.abs(event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight) <= 1;
        const isScrollAtTop = event.target?.scrollTop === 0;
        setShowScrollButton(isScrollNotAtBottom);
        const isFirstMessage = openedConversationMessages[0].isFirstMessage === true;
        if (isScrollAtTop && conversationDetail && openedConversationMessages) {
            if (!isNextConversationMessagesLoading[openedConversationId] && !isConversationMessagesLoading?.[conversationDetail._id] && !isFirstMessage) {
                setPrevScroll({
                    height: event.target.scrollHeight,
                    messageLength: openedConversationMessages?.length,
                    lastMessaseId: openedConversationMessages?.[openedConversationMessages?.length - 1]?._id,
                });
                dispatch(getNextConversationMessages({ _id: conversationDetail._id, messageId: openedConversationMessages?.[0]._id, when: 'before' }));
            }
        } else if (scrollOnBottom && conversationDetail && openedConversationMessages.length) {
            if (!isNextConversationMessagesLoading[openedConversationId] && !isConversationMessagesLoading?.[conversationDetail._id] && !openedConversationMessageIsLatest?.[openedConversationId] && !isFirstMessage) {
                dispatch(
                    getNextConversationMessages({ _id: conversationDetail._id, messageId: openedConversationMessages?.[openedConversationMessages.length - 1]._id, when: 'after' })
                );
            }
        }
        if (scrollOnBottom) {
            dispatch(setConversationUnreadableMessageCount());
        }
        showCurrentDateOnScroll();
    };

    useEffect(() => {
        const { messageLength, height, lastMessaseId } = prevScroll;
        const prevMessagesLen = validateNumberValue(messageLength);
        const openedMessagesLength = validateNumberValue(openedConversationMessages?.length);
        const isLastSavedMessageIsSame = openedConversationMessages?.[openedMessagesLength - 1]?._id !== lastMessaseId;
        const isSendMessage = openedConversationMessages?.[openedMessagesLength - 1] && !openedConversationMessages?.[openedMessagesLength - 1].timestamp;
        const lastVisibleElementId = scrollDivRef.current && getLastVisibleElementId({ scrollableDivRef: scrollDivRef });
        const findIndexOfLastVisibleElement = validateNumberValue(openedConversationMessages?.findIndex((message) => message._id === lastVisibleElementId));
        const isNearToBottom = (openedMessagesLength - findIndexOfLastVisibleElement) < 5;

        if (openedMessagesLength && prevMessagesLen && openedMessagesLength > prevMessagesLen && !isLastSavedMessageIsSame) {
            setPrevScroll({
                messageLength: openedConversationMessages?.length,
                height, lastMessaseId
            });
            scrollDivRef?.current?.scrollTo({
                top: scrollDivRef?.current?.scrollHeight - height
            });
        } else if (isSendMessage || isNearToBottom) {
            scrollToBottom({ block: 'end' });
        }
    }, [openedConversationMessages, conversationDetail?._id, prevScroll, scrollToBottom, latestMessagesIds]);

    useEffect(() => {
        scrollToBottom({ block: 'end' });
    }, [scrollToBottom, conversationDetail?._id, isConversationMessagesLoading?.[conversationDetail?._id]]);

    useEffect(() => {
        const typingInterval = setInterval(() => {
            dispatch({ type: conversations.REMOVE_TYPING_IN_CHAT });
        }, 3000);
        return () => clearInterval(typingInterval)
    }, [dispatch]);

    if (isConversationMessagesLoading?.[conversationDetail?._id] || !conversationDetail || !openedConversationMessages) {
        return <div className='relative  overflow-hidden px-8 mt-2  flex-auto' >
            <ChatMsgLoader length={3} />
        </div >
    }

    return <div className='bg-white relative h-full flex' style={{
        flexFlow: 'column',
    }}>
        <RenderChatHeader onSearchIconClick={onSearchIconClick} conversationName={conversationDetail.conversationName} closeIconClick={closeIconClick} showConversation={showConversation} />
        <RenderChats isConversationMessagesLoading={isConversationMessagesLoading?.[conversationDetail?._id]} scrollDivRef={scrollDivRef} handleScroll={handleScroll} firstMessageRef={firstMessageRef} conversations={openedConversationMessages} conversationDetails={conversationDetail} replyShow={replyShow} onClickReplyMessage={onClickReplyMessage} onReplyClick={onReplyClick} replyToMessage={replyToMessage} ticketId={ticketId} />
        <RenderChatInput closeReply={closeReply} replyShow={replyShow} replyToMessage={replyToMessage} scrollToBottom={scrollToBottom} getLatestConversationMessages={getLatestConversationMessages} currentlyTypingUsers={mpConversationUserTyping?.[conversationDetail?._id]} ticketId={ticketId} />
        {showScrollButton && <RenderScrollButton getLatestConversationMessages={getLatestConversationMessages} openedConversationUnreadableMessageCount={openedConversationUnreadableMessageCount} />}
    </div>
}


const RenderChatHeader = ({ conversationName = 'Ticket Chat', conversationImage, closeIconClick, showConversation, onSearchIconClick }) => {

    return <div className='chatContainerHeader flex-initial w-full py-2'>
        <div className='flex px-4 gap-3 items-center h-full'>
            <div className='inline-block object-cover h-full text-center flex items-center' >
                <ProfileImage
                    imgUrl={conversationImage}
                    className='inline-block object-cover w-11 h-10 rounded-full text-center leading-10'
                    type={'group'}
                />
            </div>
            <div className='text-scogo11 text-font14 font-medium'>
                <OverflowTip someLongText={conversationName} />
            </div>
            <div className='ml-auto flex gap-2'>
                <div className='renderSearchIcon'>
                    <TicketSearch mainDivClass='flex items-center gap-2 my-8 ' />
                </div>
                <div className='ml-auto flex items-center gap-2'>
                    <span onClick={onSearchIconClick} className='renderChatIcon material-icons cursor-pointer max-w-max text-font24'>search</span>
                    <div className='renderChatIcon'>
                        {showConversation && <RenderDetailsIcon onClick={closeIconClick} />}
                    </div>
                </div>
            </div>

        </div>
    </div>
}

const RenderChats = ({ conversations, conversationDetails, replyShow, onClickReplyMessage, onReplyClick, replyToMessage, firstMessageRef, handleScroll, scrollDivRef, isConversationMessagesLoading, ticketId }) => {
    const { loggedUser } = useSelector((state) => state.auth);

    return <div className='relative bg-scogoeee overflow-y-auto flex-auto overflow-x-hidden' ref={scrollDivRef} onScroll={handleScroll}>
        <div className='absolute top-4 left-0 right-0 z-10 '>{isConversationMessagesLoading && <Loader color='#F48A21' size='25' speed='1' />}</div>
        <div
            ref={firstMessageRef}
            style={{ left: '50%', bottom: '50%', transform: 'translate(-50%, -50%)' }}
            className='hidden rounded-xl px-2 py-2 justify-center text-scogogray sticky top-8 z-10  max-w-max bg-tagmsg text-lg'
        ></div>
        <div className='px-p-15'>
            {conversations?.map((msg, index) => {
                let isDateChanged = true;

                if (msg.timestamp && conversations[index - 1] && conversations[index - 1].timestamp) {
                    isDateChanged = !isDateAreSame(msg.timestamp, conversations[index - 1].timestamp);
                }

                return (
                    <React.Fragment key={msg._id}>
                        {isDateChanged && <DateDivider timestamp={msg.timestamp} />}
                        <Message
                            message={msg}
                            createdBy={conversationDetails?.createdBy}
                            side={loggedUser?.id === msg.from.userId ? 'right' : 'left'}
                            type={msg.type}
                            onClickReplyMessage={onClickReplyMessage}
                            onReplyClick={onReplyClick}
                            selectedMessageId={replyToMessage?._id}
                            replyShow={replyShow}
                            showMessageAction={conversationDetails?.active && !conversationDetails?.isBotEnabled}
                            toEnableChip={
                                index === conversations.length - 1 &&
                                conversationDetails?.active &&
                                loggedUser?.id === conversationDetails?.createdBy?.userId
                            }
                            showReplyIcon={conversationDetails?.active && !conversationDetails?.isBotEnabled && !conversationDetails?.hideInput}
                            ticketId={ticketId}
                        />
                    </React.Fragment>
                );
            })}
        </div>
        <div id='last_element_after_messages'></div>
    </div>
}

const RenderChatInput = ({ closeReply, replyShow, replyToMessage, scrollToBottom, getLatestConversationMessages, currentlyTypingUsers, ticketId }) => {
    const messageInputRef = useRef();
    const { openedConversationId } = useSelector((state) => state.conversations);
    return <div className='flex-intial w-full'>
        <TypingUsers users={currentlyTypingUsers} additionalClasses='bg-white pl-2 py-1' maxUsernamesToShow={5} />
        <MessageInput
            ref={messageInputRef}
            showAllTag={true}
            getLatestConversationMessages={getLatestConversationMessages}
            closeReply={closeReply}
            replyShow={replyShow}
            replyToMessage={replyToMessage}
            scrollToBottom={scrollToBottom}
            showDoubleHash={true}
            ticketId={ticketId}
            isSupportTicket={true}
            openedConversationId={openedConversationId}
        />
    </div>
}


const DisabledChatInfo = ({ ticketId }) => {
    const { uiConfig } = useSelector(state => state.user);
    const { isUIConfigLoading, isConversationDetailsLoading } = useSelector(state => state.loading);
    const { loggedUser } = useSelector(state => state.auth);
    const { ticketDetails } = useSelector((state) => state.tickets);
    const { conversationDetails } = useSelector((state) => state.conversations);
    const conversationDetail = conversationDetails && conversationDetails[ticketId];
    const dispatch = useDispatch();

    const getLogo = () => {
        if (uiConfig) return uiConfig.logoUrl;
        return process.env.PUBLIC_URL + '/img/scogo-S-logo.png';
    }

    const chatWithUsButton = !conversationDetail && ticketDetails
    return <div className='chat-card-wtih-edit-ticket border relative' >
        {loggedUser && <div className='renderSearchIcon flex items-end px-4 absolute top-2 right-4'>
            <TicketSearch mainDivClass='flex items-center gap-2 w-96 ml-auto' />
        </div>}
        <div className='w-full h-full font-bold flex flex-col items-center justify-center'>
            <div className='flex items-center align-middle'>
                {isUIConfigLoading ?
                    <Skeleton variant='rectangular' animation='wave' height='11rem' width='10rem' />
                    :
                    <img src={getLogo()} alt='chat' className='mx-auto h-48' />}
            </div>
            <div className='flex text-center text-font24 m-2'>
                {!chatWithUsButton ? <p className='h-fit'>Chat with our Support Team</p>
                    : <ButtonScogoPrimary onClick={(e) => {
                        e.preventDefault();
                        dispatch(getConversationDetails({ _id: ticketId, useConversationId: true }));
                    }}
                        buttonTextClass={'submitButtonSupportText'}
                        textOrComponent={'Click here to chat with us'}
                        loading={isConversationDetailsLoading}
                    />}
            </div>
        </div>
    </div>
}


export const RenderDetailsIcon = ({ onClick, extraClass = '' }) => {
    return <p onClick={onClick} className={'ml-auto flex flex-col items-center hover:text-scogoorange text-scogoprimary justify-center ' + extraClass}>
        <span className='px-4 py-1 cursor-pointer max-w-max text-xl text-white rounded-2xl bg-scogobgsky hover:bg-scogoprimary'>Details</span>
    </p>
}