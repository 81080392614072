import React from 'react';
import { useDispatch } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import Form from '../../../common/Form';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import TextArea from '../../../common/Form/TextArea';
import { updateSignOffNotes } from '../../../actions/tickets';

const formName = 'updateSignOffNotesForm';

export default function UpdateSignOffNotes({ ticket }) {
    const { formButtonLoading } = useSelector((state) => state.loading);
    const getRemark = () => {
        let remark = '';
        if (ticket.signOffRequest === 1) {
            remark = ticket.job_sign_off_notes;
        } else if (ticket.signOffReject === 1) {
            remark = ticket.pm_rejection_message;
        }
        return remark;
    };

    const defaultValues = { job_sign_off_notes: getRemark() };

    const dispatch = useDispatch();

    const methods = useForm({ defaultValues, mode: 'all' });

    const submit = (formValues) => {
        const payload = {
            job_sign_off_notes: formValues.job_sign_off_notes,
        };
        dispatch(updateSignOffNotes({ data: payload, formName, ticketId: ticket.ticket_id }));
    };

    return (
        <>
            <Form
                methods={methods}
                onSubmit={submit}
                formName={formName}
                buttonPostion={'justify-end'}
                submitButtonText={'Submit'}
                onCancel={() => dispatch(closeModalAction())}
                showSubmit
                submitContainerClassName='flex gap-2 justify-end py-4 px-4'
                isLoading={formButtonLoading[formName]}
            >
                <div className='px-2'>
                    <TextArea name='job_sign_off_notes' className='w-full' label='SignOff Notes' height='6rem' required />
                </div>
            </Form>
        </>
    );
}
