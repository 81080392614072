import { useDispatch } from "react-redux";
import { getConversationIdandConversationDetailsAction, joinRoomAction, openActiveCallAction, openChatInNewTab, openChatElement } from "../../../actions/conversations";
import Checkbox from "../../../common/Checkbox";
import IconToolTip from "../../../common/IconToolTip";
import Loader from "../../../common/Loader";
import OverflowTip from "../../../common/OverflowTip";
import { millisecondsToHours, validateDateValue } from "../../../utils/common";
import StatusLabel from "../../Payments/reusableComponents/StatusLabel";
import { copyToClipboard } from "../../../actions/utils";
import { TERMINAL_COLOR } from "../TicketTable";
import { clickableTextClass } from "../../../common/Css";
import { useSelector } from "react-redux";
import { InviteUsersModal } from "../../chat/sidedrawer/TicketDetails/InviteUsersModal";
import { openCustomModal } from "../../../actions/modal";
import { allowVideoMeet } from "../../videomeet/components/constants";
import EditExecutionDate from "../Modal/EditExecutionDate";
import { isEligibleForEditExecutionDate } from "../TicketController";
import moment from "moment";
import { callOnTicketDetailsCopy, getActiveVoiceCall } from "../../../actions/tickets";
import { getUserCallStatusAction } from "../../../actions/users";
import { cityTierClass } from "./Sites";

const TicketNumber = ({ ticket, rowIndex, checkedTicketTrackerLocal, handleOnChangeCheckboxLocal, handleTicketNumberCLick = () => { }, showCheckBox, showProject = false, }) => {

    return (
        <div className="flex flex-col break-all">
            <div className='w-full pl-2 items-center flex'>
                {showCheckBox && <Checkbox
                    checkColor='text-scogoorange mr-2'
                    checked={checkedTicketTrackerLocal.has(rowIndex)}
                    onChange={() => {
                        handleOnChangeCheckboxLocal(rowIndex);
                    }}
                    dynamicSize={'1.2rem'}
                />}
                <div className='max-w-full'>
                    <span className='max-w-full relative ticketNumber'>
                        <OverflowTip
                            onClick={() => {
                                handleTicketNumberCLick(ticket);
                            }}
                            textClass={clickableTextClass + 'max-w-full text-font12'}
                            someLongText={ticket.job_ticket_number}
                        />
                    </span>
                    <div className='flex items-center gap-x-2 mt-1 pb-1'>
                        <RenderFormmatedExecutionDate ticket={ticket} />
                        <RenderActions ticket={ticket} />
                    </div >
                    {
                        ticket.external_ticket_id && (
                            <div className='w-11/12 truncate mt-2'>
                                <StatusLabel titleText='External TIcket Id' content={ticket.external_ticket_id} color={'scogo999'} labelClass='text-gray-900' />
                            </div>
                        )
                    }
                    {
                        ticket?.distance_travelled?.length > 0 ?
                            <div className='w-[95%] truncate mt-2'>
                                <StatusLabel hoverText={`${ticket?.distance_travelled}`} titleText='Distance Travelled by Engineer ' content={`Dist Travelled : ${ticket?.distance_travelled}`} color={'scogoprimary'} labelClass='text-gray-900' />
                            </div>
                            : <></>
                    }
                    {
                        ticket?.project_name && showProject && (
                            <>
                                <span className='text-font09  text-scogogray'>Project</span>
                                <IconToolTip placement={'bottom'} title={`Project: ${ticket?.project_name}`}>
                                    <p className='font-medium w-10/12 truncate text-font12 '>{ticket?.project_name}</p>
                                </IconToolTip>
                            </>
                        )
                    }
                </div >
            </div >
        </div >
    );

}
export default TicketNumber;



export const TerminalIcon = ({ status, consoleUrl }) => {

    const onTerminalClick = (consoleUrl) => {
        window.open(consoleUrl, '_blank');
    };

    if (!status) return <></>;

    return <IconToolTip title={'Remote Console'}>
        <span
            className={`material-icons ${TERMINAL_COLOR[status] || ''} bg-black text-font15 cursor-pointer`}
            onClick={() => {
                onTerminalClick(consoleUrl);
            }}
        >
            terminal
        </span>
    </IconToolTip>
}



const FEStatusIcon = ({ ticket }) => {
    let title = '';
    let class_name = '';
    let onClick = () => { };

    if (ticket.is_live_tracking_enabled === 1 && ticket?.reachedSite === 0) {
        title = 'FE On The Way';
        class_name = 'text-scogosuccess cursor-pointer';
        onClick = () => {
            if (ticket?.live_tracking_url) {
                window.open(ticket?.live_tracking_url);
            } else {
            }
        }
    } else if (ticket.is_live_tracking_enabled === 2 && ticket?.reachedSite === 0) {
        title = 'Tracking Request Sent';
        class_name = 'text-yellow-500';
        onClick = () => { };
    } else if (ticket.is_live_tracking_enabled === 1 && ticket?.reachedSite === 1) {
        title = 'FE has reached site with enabled tracking';
        class_name = 'text-scogoprimary';
        onClick = () => { };
    }

    if (title) {
        return (
            <IconToolTip title={title}>
                <span
                    onClick={onClick}
                    className={`material-icons ${class_name} text-font14`}
                >
                    directions_bike
                </span>
            </IconToolTip>
        );
    }

    return <></>;
}

const RenderFormmatedExecutionDate = ({ ticket }) => {
    let date = ticket.current_execution_date;
    let title = 'Execution Date'
    if (ticket.site_completed_on) {
        date = ticket.site_completed_on;
        title = 'Site Completed On'
    }


    if (validateDateValue(date)) {
        let elem = <></>;
        elem = <p className="flex flex-col">
            <b>{moment(date).format('DD-MM-YYYY')}</b>
            <b>{moment(date).format('hh:mm A')}</b>
        </p>
        return (
            <IconToolTip title={title}>
                <span>
                    <span className={'text-font09 text-scogogray'}>{elem}</span>
                </span>
            </IconToolTip>
        );
    }
};


const RenderActions = ({ ticket }) => {
    const dispatch = useDispatch();
    const { formButtonLoading } = useSelector(state => state.loading);
    const { loggedUser } = useSelector((state) => state.auth);
    const showExecutionDate = isEligibleForEditExecutionDate({ signOffAccept: ticket.signOffAccept, signOffRequest: ticket.signOffRequest, ticket_owned_by: ticket.ticket_owned_by, assignToScogo: ticket.assignToScogo, role: loggedUser.role.id, type: loggedUser.type, closedByNoc: ticket.closedByNoc, accepted_sp_id: ticket.accepted_sp_id, sign_off_lock: ticket.sign_off_lock });
    const editExecutionDateClicked = () => {
        const heading = (
            <span className='text-black'>
                Edit Execution Date :&nbsp;<span className='text-scogobgsky text-font16'>{ticket.job_ticket_number}</span>
            </span>
        );

        dispatch(
            openCustomModal({
                heading,
                modalWidth: '60rem',
                modalHeight: 'auto',
                modalComponent: <EditExecutionDate ticketId={ticket.ticket_id} isBulk={false} current_execution_date={ticket.current_execution_date} />,
            })
        );
    }

    const onOpenChatCLick = ({ ticketId, buttonName, ticket, isRightClick }) => {
        dispatch(openChatElement({ conversationId: ticketId, buttonName, ticket, isRightClick }));
    };

    let remote_teminal_connection_status = '';
    if (!['', undefined, null].includes(ticket.remote_teminal_connection_status)) {
        remote_teminal_connection_status = ticket.remote_teminal_connection_status;
    }

    const copyTicketDetaisValue = (ticket) => {
        const ticketName = ticket?.job_ticket_number;
        let text = `${ticketName}`;
        dispatch(copyToClipboard({ data: text }));
        dispatch(callOnTicketDetailsCopy({ ticketId: ticket.ticket_id }));
    };

    const openInviteModal = (conversationId) => {
        const inviteModal = {
            modalComponent: <InviteUsersModal userId={loggedUser?.id} ticketId={ticket?.ticket_id} openedConversationId={conversationId} ticket={ticket} />,
            heading: `Invite Users`,
            modalWidth: '40rem',
            modalHeight: '50rem'
        }
        dispatch(openCustomModal(inviteModal));
    }


    const handleStartVideoMeet = () => {
        dispatch(getConversationIdandConversationDetailsAction({ ticketId: ticket?.ticket_id, buttonName: `video-chat-${ticket?.ticket_id}`, onSuccess: openInviteModal }))
    }

    const refresh = () => {
        dispatch(getActiveVoiceCall());
        dispatch(getUserCallStatusAction({ userId: loggedUser?.id, status: [1, 2, 3, 4] }));
    }

    const handleActiveCallClick = () => {
        dispatch(joinRoomAction({ userId: loggedUser?.id, room_id: ticket?.room_id, onSuccess: refresh }));
        dispatch(openActiveCallAction({ ticketId: ticket?.ticket_id, room_id: ticket?.room_id, buttonName: `video-chat-${ticket?.ticket_id}` }));
    }

    return <>
        <FEStatusIcon ticket={ticket} />
        <IconToolTip title={'Copy Ticket Number'}>
            <span
                className='material-icons text-scogobgsky hover:text-scogoorange text-font14 cursor-pointer'
                onClick={() => {
                    copyTicketDetaisValue(ticket);
                }}
            >
                content_copy
            </span>
        </IconToolTip>
        <TerminalIcon status={remote_teminal_connection_status} consoleUrl={ticket?.consoleUrl} />
        {formButtonLoading?.[`open_chat_${ticket.ticket_id}`] ? (
            <Loader size='15' />
        ) : (
            <IconToolTip
                title={'Chat'}
                onClick={() => onOpenChatCLick({ ticketId: ticket.ticket_id, buttonName: `open_chat_${ticket.ticket_id}`, ticket })}
            >
                <div onContextMenu={(event) => {
                    event.preventDefault();
                    onOpenChatCLick({ ticketId: ticket.ticket_id, buttonName: `open_chat_${ticket.ticket_id}`, ticket, isRightClick: true })
                }}><img src='/img/chat_colourful.png' alt='' className='w-5 cursor-pointer ' /> </div>
            </IconToolTip>
        )}

        {
            allowVideoMeet(ticket) &&
            <>
                {
                    formButtonLoading?.[`video-chat-${ticket.ticket_id}`] ?
                        <Loader size='15' />
                        :
                        <>
                            {
                                ticket?.room_call_status === 1 ?
                                    <IconToolTip title={'Scogo Connect Active'} onClick={handleActiveCallClick}>
                                        <div className="flex items-center w-7 h-7">
                                            <img src={process.env.PUBLIC_URL + '/img/scogo-connect-green.svg'} alt="" className="w-full h-full object-contain hover:cursor-pointer" />
                                        </div>
                                    </IconToolTip>
                                    :
                                    <IconToolTip title={'Start Scogo Connect'} onClick={handleStartVideoMeet}>
                                        <div className="flex items-center w-7 h-7">
                                            <img src={process.env.PUBLIC_URL + '/img/scogo-connect-blue.svg'} alt="" className="w-full h-full object-contain hover:cursor-pointer" />
                                        </div>
                                    </IconToolTip>
                            }
                        </>
                }
            </>
        }

        {ticket?.sign_off_lock === 1 ? <IconToolTip title={'SP/FE can not raise sign off of this ticket, as execution date is more than 7 days old'}>
            <span
                className={`material-icons text-scogoorange text-font15 cursor-pointer`}
            >
                lock
            </span>
        </IconToolTip> : <></>}
        {showExecutionDate && <IconToolTip title={`Edit Execution Date`}>
            <span>
                <span onClick={editExecutionDateClicked} className='pt-2 cursor-pointer material-icons text-scogogray hover:text-scogoorange text-font15'>edit_calendar</span>
            </span>
        </IconToolTip>}
    </>
};
