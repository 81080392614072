import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getWhatsappOr } from '../../actions/whatsapp'
import { useSelector } from 'react-redux';
import Loader from '../../common/Loader';
import { googleEvents } from '../../common/GoogleEvent';
import { setGoogleEvents } from '../../utils/common';


function WhatsappLinkQrPopup() {
    const dispatch = useDispatch();
    const { whatsappLinkDetails } = useSelector((state) => state.whatsapp)
    const { isWhatsappQrLoading } = useSelector((state) => state.loading);
    const [imgloading, setImgloading] = useState(true);
    useEffect(() => {
        setGoogleEvents(googleEvents.whatsApp.main_event, googleEvents.whatsApp.whatsapp_qr_popup)

        dispatch(getWhatsappOr({ openModal: true }))

        return () => {
            dispatch(getWhatsappOr({ message: "WhatsApp Linked Successfully" }))
        }
    }, [dispatch])

    const onLoad = () => {
        setImgloading(false)
    };


    return (
        <>
            {(isWhatsappQrLoading || imgloading) &&
                <div className='py-5 h-[30vh] mt-[12%]' >
                    <Loader color='#F48A21' size='100' speed='1' thickness='4' marginTop='' />
                </div>
            }
            <img alt='' className={`min-h-32 min-w-25 w-full max-w-[37vw] h-full  ${imgloading || isWhatsappQrLoading ? "hidden" : ""}`} src={whatsappLinkDetails?.url} onLoad={() => onLoad()} />
        </>
    )
}
export default WhatsappLinkQrPopup