import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sharePublicProfile, removePublicProfileFromStore } from '../../actions/sp';
import config from '../../config';
import GridTable from '../../common/GridTable';
import { tableHead } from '../../common/tabeHelpers';
import RatingComponent from '../../common/Rating';
import { SkeltonCardTwo } from '../../common/SkeltonCardTwo';
import { copyToClipboard } from '../../actions/utils';
import { encryptProfilePath } from '../../utils/utils';
import ButtonScogoPrimary from '../../common/Buttons/ButtonScogoPrimary';

const fieldEngineersDeviceTypes = ['Cisco', 'Huawei', 'Polycom', 'Lavelle'];

export default function PartnerProfile(props) {
    const { userId, username, copyProfileUrlButton = false, joinScogoNetwork = false } = props;

    const dispatch = useDispatch();
    const { shareProfile } = useSelector((state) => state.sp);
    const { isPublicProfileLoading } = useSelector((state) => state.loading);
    const isRole12 = shareProfile?.role === 12;
    const isRole8 = shareProfile?.role === 8;

    useEffect(() => {
        dispatch(sharePublicProfile({ userId, username }));

        return () => dispatch(removePublicProfileFromStore());
    }, []);

    const nameToShow = ({ partner }) => {
        const role = partner?.role;
        let name = '';
        let title = '';
        if (role === 12) {
            if (partner?.sp_name) {
                name = partner?.sp_name;
                title = 'Freelancer';
            } else {
                name = `${partner?.first_name} ${partner?.last_name}`;
                title = 'Scogo Service Partner';
            }
        } else if (role === 8) {
            name = `${partner?.first_name} ${partner?.last_name}`;
            title = 'Scogo Field Engineer';
        }

        return (
            <div>
                <p className='text-scogobgsky text-5xl font-medium'> {name} </p>
                <p className='pt-4 text-scogoprimary text-3xl font-medium'>{title}</p>
            </div>
        );
    };

    const showUserServiceLocations = ({ data, color }) => {
        return (
            Array.isArray(data) &&
            data?.map((elem) => {
                return <span className={`${color} scogoprimary 2xl:text-font11 text-font12 px-p-6 py-p-3 rounded-3px mr-1 my-1 mx-1`}>{elem}</span>;
            })
        );
    };

    const aboutUserComponent = ({ partner }) => {
        const role = partner?.role;
        let name = '';
        let joiningSince = `${partner?.joined}`;
        if (role === 12) {
            if (partner?.sp_name) {
                name = partner?.sp_name;
            } else {
                name = `${partner?.first_name} ${partner?.last_name}`;
            }
        } else if (role === 8) {
            name = `${partner?.first_name} ${partner?.last_name}`;
        }
        return (
            <p className='text-font12 tracking-wide'>
                Hello there,
                <br />I am {name} , we are Scogo Authorized Freelancer since {joiningSince}. We help customers in our service areas to get instant
                technical support. You can reach out to us through Scogo app .
            </p>
        );
    };

    const copyProfileUrl = ({ userId, username }) => {
        const userNameOrID = `${username ? username.replace(/\s+/g, '') : ''}-${userId}`;
        const profilePath = encryptProfilePath({ text: userNameOrID });
        let forCopy = `${window.location.origin}/profile/${profilePath}`;
        dispatch(copyToClipboard({ data: forCopy }));
    };

    const rowDataForProfile = [
        { name: 'Reports On-Time', rating: 5, rank: 5 },
        { name: 'Default Rate', rating: 0, rank: 0 },
        { name: 'Technical Skills', rating: 0, rank: 0 },
    ];

    const profileTableSchema = {
        table: [
            {
                head: {
                    headName: '',
                    render: (headName) => headName,
                    width: 1.5,
                },
                body: {
                    render: (item) => {
                        return <p className='font-normal'>{`${item?.name}`}</p>;
                    },
                },
            },
            {
                head: {
                    headName: 'Rating',
                    render: (headName) => headName,
                    width: 1.5,
                },
                body: {
                    render: (item) => {
                        return <RatingComponent rating={item.rating} />;
                    },
                },
            },
            {
                head: {
                    headName: 'Rank',
                    render: (headName) => headName,
                    width: 0.5,
                },
                body: {
                    render: (item) => {
                        return <RatingComponent rating={item.rank} />;
                    },
                },
            },
        ],
    };

    const profileImge = shareProfile?.profile_url ? shareProfile?.profile_url : `${config.api.staticAssetsUrl}/images/avatar.jpg`;

    const completedTickets = shareProfile?.total_tickets ? shareProfile?.total_tickets : '0';

    if (isPublicProfileLoading || !shareProfile) {
        return (
            <div className={'px-4'}>
                <div className='flex gap-6 items-center'>
                    <SkeltonCardTwo arr={[1]} height={60} className={'w-48'} padding={0.5} />
                    <div className='w-full'>
                        <SkeltonCardTwo arr={[1]} height={12} padding={0.1} />
                        <SkeltonCardTwo arr={[1]} height={12} padding={0.1} />
                        <SkeltonCardTwo arr={[1]} height={12} padding={0.1} />
                    </div>
                </div>
                <SkeltonCardTwo arr={[1, 2, 3, 4, 5, 6]} height={15} padding={0.5} />
                <SkeltonCardTwo arr={[1, 2, 3, 4, 5, 6]} height={20} padding={0.5} />
                <SkeltonCardTwo arr={[1, 2, 3, 4, 5, 6]} height={20} padding={0.5} />
                <SkeltonCardTwo arr={[1, 2, 3, 4, 5, 6]} height={20} padding={0.5} />
            </div>
        );
    }

    return (
        <div className='p-4'>
            <div className='relative'>
                <img src={`${config.api.staticAssetsUrl}/images/user_profile_background.svg`} alt='' className='max-w object-contain' />
                <img className='top-0 right-10 absolute my-auto w-52' src='https://scogo-production-upload.s3.ap-south-1.amazonaws.com/media/logo/scogo-logo.png' alt='Scogo Logo' />
            </div>
            <div className='flex h-28 relative '>
                <div className='w-3/12 h-full'>
                    <img className='absolute rounded-full bottom-0 left-6 h-48 w-48' src={profileImge} alt='User Profile' />
                </div>
                <div className='w-6/12 h-full'></div>
                <div className='w-3/12 h-full'></div>
            </div>
            <div className='py-4'>
                <div className='flex items-center justify-between'>
                    <div className='px-4'>{nameToShow({ partner: shareProfile })}</div>
                    <div className='px-4 pr-20'>
                        <p className='text-2xl font-medium'>Completed Tickets : {completedTickets}</p>
                    </div>
                </div>
            </div>

            <div className='px-4'>
                <p className='text-2xl font-medium'>Served Service Locations</p>
                <div className='py-2 flex flex-wrap'>
                    {showUserServiceLocations({
                        data: shareProfile?.worked_on_cities,
                        color: 'bg-tagbg',
                    })}
                </div>
            </div>
            <div>
                <Box label={'About :'}>{aboutUserComponent({ partner: shareProfile })}</Box>

                {isRole12 && (
                    <Box label={'Type of Site Served :'}>
                        {showUserServiceLocations({
                            data: shareProfile.worked_on_site_type,
                            color: 'bg-tagbg',
                        })}
                    </Box>
                )}
                {isRole12 && (
                    <Box label={'Tools Availability :'}>
                        {showUserServiceLocations({
                            data: shareProfile.tools,
                            color: 'bg-tagbg',
                        })}
                    </Box>
                )}
                <Box label={'Device Type :'}>
                    {showUserServiceLocations({
                        data: shareProfile?.worked_on_asset_type,
                        color: 'bg-tagbg',
                    })}
                </Box>
                {isRole8 && (
                    <Box label={'Device Type :'}>
                        {showUserServiceLocations({
                            data: fieldEngineersDeviceTypes,
                            color: 'bg-tagbg',
                        })}
                    </Box>
                )}
            </div>
            <div>
                <div>
                    <p className='text-2xl font-medium px-4'>Performance Summary</p>
                    <Box>
                        <GridTable schema={profileTableSchema} rowData={rowDataForProfile} tableBodyHeight={'h-max'} />
                    </Box>
                </div>
            </div>
            {copyProfileUrlButton && (
                <div className='justify-end flex'>
                    <ButtonScogoPrimary textOrComponent={'Copy Profile URL'} onClick={() => copyProfileUrl({ userId, username })} />
                </div>
            )}
            {joinScogoNetwork && (
                <div className='justify-end flex'>
                    <ButtonScogoPrimary textOrComponent={'Join Scogo Network'} onClick={() => window.open('https://cloud.scogo.in/', '_blank')} />
                </div>
            )}
        </div>
    );
}

const Box = (props) => {
    return (
        <div className='border-2 mx-2 p-4 my-4'>
            {props?.label && <p className='text-2xl font-medium pb-2'>{props?.label}</p>}
            {props?.children}
        </div>
    );
};
