import { useForm, useFormContext } from "react-hook-form";
import Form from "../../../common/Form"
import { useDispatch, useSelector } from "react-redux";
import { closeModalAction } from "../../../actions/modal";
import Input from "../../../common/Form/Input";
import { sendToastMessage } from "../../../actions/toast";
import { askAppointment } from "../../../actions/tickets";
import MultipleEmails from "../../../common/Form/MultipleEmail";


const CreateAppointment = ({ ticketId }) => {

    const defaultValues = {};
    const methods = useForm({ defaultValues, mode: 'all' });
    const { formButtonLoading } = useSelector((state) => state.loading);

    const { watch } = methods
    const formName = 'create_appointment';
    const dispatch = useDispatch();
    const personEmail = watch('person_email');


    const submit = (formValues) => {

        const mobileNumber = Number(formValues.person_mobile)

        if (!formValues.person_email && !formValues.person_mobile) {
            dispatch(sendToastMessage({ status: 'danger', message: 'Please enter phone number or email.' }))
        }
        else {
            dispatch(askAppointment({
                ticketId,
                email: formValues.person_email,
                mobile: mobileNumber,
                cc: formValues.emails,
                formName
            }))
        }
    }
    return (<>
        <Form
            methods={methods}
            onSubmit={submit}
            submitButtonText={'Submit'}
            onCancel={() => dispatch(closeModalAction())}
            showSubmit
            submitContainerClassName='flex gap-2 justify-end py-4 px-6'
            isLoading={formButtonLoading[formName]}

        ><div className=" ">
                <div className="flex  gap-8 mt-2 items-center px-5 ">
                    <Input
                        label='Contact Email'
                        type={'text'}
                        iconLeft={{ name: 'person', "hoverTitle": "Email" }}
                        inputClassName={` font-normal text-font09 md:text-font11 block w-full focus:ring-0 px-4  border-t-0 border-r-0 border-l-0  pl-11 py-2 `}
                        name='person_email'
                        validate={(email) => {
                            if (email === '') return true;
                            return (
                                email.match(
                                    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                ) || 'Invalid Email'
                            );
                        }}
                    />


                    <Input
                        label='Contact Mobile'
                        type={'number'}
                        name='person_mobile'
                        inputClassName={`font-normal text-font09 md:text-font11 block w-full focus:ring-0 px-4  border-t-0 border-r-0 border-l-0  pl-11 py-2`}
                        iconLeftImage
                        src={process.env.PUBLIC_URL + "/img/whatsapp.png"}
                        imgClassName={'w-8'}

                        validate={(mobile) => {
                            if (mobile === '') return true;
                            return mobile.length === 10 || 'Invalid Mobile';
                        }}
                    />
                </div>
                {personEmail && <div className=" px-2">
                    <MultipleEmails name='emails' label='CC Emails' />

                </div>}
            </div>
        </Form>
    </>)
}
export default CreateAppointment