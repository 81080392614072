import React, { useState, forwardRef } from 'react';
import Popover from '@mui/material/Popover';


const Popup = forwardRef(({ siteInfo }, ref) => {
    return (
        <div className='bg-white shadow-lg rounded-md p-3 max-w-[48rem]' ref={ref}>
            <p className='text-font12' >
                {siteInfo}
            </p>
        </div>
    );
});

const SitePopup = ({
    children,
    siteInfo,
    anchorOrigin = {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin = {
        vertical: 'top',
        horizontal: 'left',
    }
}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <div aria-describedby={id} onClick={handleClick} variant='contained' className='w-full hover:underline cursor-pointer inline'>
                {children}
            </div>
            <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={anchorOrigin} transformOrigin={transformOrigin}>
                <Popup siteInfo={siteInfo} />
            </Popover>
        </>
    );
};

export default SitePopup;
