import React from 'react';
import Box from '@mui/material/Box';

import DetailTag from '../../../pages/project/DetailTag';
import { textCss } from '../../../common/Css';
import GridTable from '../../../common/GridTable';
import IconToolTip from '../../../common/IconToolTip';
import { tableHead } from '../../../common/tabeHelpers';

const boxStyle = {
    border: 0,
    p: 1,
    height: '40em',
    width: '45em',
    bgcolor: 'white',
};

const LabelList = ({ customerName, labelList, make, model, partcode, supplier }) => {
    const tableSchema = {
        table: [
            {
                head: tableHead('Label'),
                body: {
                    render: (item) => {
                        return (
                            <IconToolTip title={item?.label} placement='bottom'>
                                <p className='font-medium text-scogobgsky truncate'>{item?.label}</p>
                            </IconToolTip>
                        );
                    },
                },
            },
            {
                head: tableHead('Value'),
                body: {
                    render: (item) => {
                        return (
                            <IconToolTip title={item?.value} placement='bottom'>
                                <p className='truncate'>{item?.value}</p>
                            </IconToolTip>
                        );
                    },
                },
            },
        ],
    };

    const AssetDetails = () => {
        return (
            <>
                <div className='md:flex pl-5 pt-3'>
                    {customerName && (
                        <DetailTag
                            tagHeadClass={textCss?.detailsTitle}
                            tagValueClass={`${textCss?.detailsValue} line-clamp-3`}
                            tagClass=' px-2 md:w-6/12'
                            tagName='Customer Name'
                            tagValue={customerName}
                        />
                    )}
                    {make && (
                        <DetailTag
                            tagHeadClass={textCss?.detailsTitle}
                            tagValueClass={`${textCss?.detailsValue} line-clamp-3`}
                            tagClass=' px-2 md:w-6/12'
                            tagName='Make'
                            tagValue={make}
                        />
                    )}
                    {model && (
                        <DetailTag
                            tagHeadClass={textCss?.detailsTitle}
                            tagValueClass={`${textCss?.detailsValue} line-clamp-3`}
                            tagClass=' px-2 md:w-6/12'
                            tagName='Model'
                            tagValue={model}
                        />
                    )}
                </div>
                <div className='md:flex pl-5 pt-3'>
                    {partcode && (
                        <DetailTag
                            tagHeadClass={textCss?.detailsTitle}
                            tagValueClass={`${textCss?.detailsValue} line-clamp-3`}
                            tagClass=' px-2 md:w-4/12'
                            tagName='Partcode'
                            tagValue={partcode}
                        />
                    )}
                    {supplier && (
                        <DetailTag
                            tagHeadClass={textCss?.detailsTitle}
                            tagValueClass={`${textCss?.detailsValue} line-clamp-3`}
                            tagClass=' px-2 md:w-4/12'
                            tagName='Supplier'
                            tagValue={supplier}
                        />
                    )}
                </div>
            </>
        );
    };

    return (
        <Box sx={boxStyle} className='shadow-2xl overflow-auto'>
            {<AssetDetails />}
            <div className='rounded overflow-hidden shadow-lg px-6 mt-3 '>
                <GridTable isLoading={false} schema={tableSchema} rowData={labelList} tableError={'No Data Found'} tableBodyHeight={'h-max'} />
            </div>
        </Box>
    );
};

export default LabelList;
