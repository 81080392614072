import { websocket } from '../types';

export const connectToWebsocket = ({ isReconnecting } = {}) => ({ type: websocket.CONNECT_TO_WEBSOCKET_REQUEST, payload: { isReconnecting } });

export const dicconnectFromWebsocket = () => ({ type: websocket.DISCONNECT_FROM_WEBSOCKET_REQUEST });

export const sendDataThroughWebsocket = (data) => ({
    type: websocket.SEND_WEBSOCKET_DATA,
    payload: data,
});
