import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import Form from '../../../common/Form';
import { useForm } from 'react-hook-form';
import MultipleEmails from '../../../common/Form/MultipleEmail';
import Checkbox from '../../../common/Form/Checkbox';
import { notifyIsp } from '../../../actions/tickets';

const formName = 'shareFeasibiliytLink';
export default function ShareFeasibilityLink(props) {
    const { userDetails, isps, ticketId = 0 } = props;
    const { loggedUser } = useSelector((state) => state.auth);
    const { formButtonLoading } = useSelector((state) => state.loading);
    const dispatch = useDispatch();

    const defaultValues = {
        emails: loggedUser?.email ? [loggedUser.email] : [],
    };

    const submit = (formValues) => {
        let whatsappUrl = userDetails
            ? `https://api.whatsapp.com/send/?phone=91${userDetails.mobile}&text=${`Hi ${
                  userDetails.name
              }, Please submit the Feasibility by clicking on the link ${encodeURIComponent(
                  `${window.location.origin}/?isp_number=${userDetails.mobile}&redirect_uri=${encodeURIComponent(`${window.location.origin}/feasibility`)}`
              )}`}&app_absent=0`
            : undefined;
        let payload = {
            email: formValues.isEmail,
            whatsapp: formValues.isWhatsapp,
            recepients: formValues.emails,
            ticketId: ticketId,
            isps,
        };

        dispatch(notifyIsp({ data: payload, whatsappUrl, formName }));
    };

    const methods = useForm({ defaultValues, mode: 'all' });

    return (
        <Form
            methods={methods}
            onSubmit={submit}
            submitButtonText={'Submit'}
            onCancel={() => dispatch(closeModalAction())}
            showSubmit
            submitContainerClassName='flex gap-2 justify-end py-4 px-4'
            isLoading={formButtonLoading[formName]}
        >
            <div className='px-3 '>
                <MultipleEmails name='emails' label='Emails' placeholder='Enter Emails' required />
            </div>
            <div className='px-8 text-sm'>
                <p className='text-bold text-font12'>Send Via:</p>
                <div className='gap-4 flex mt-4'>
                    <Checkbox className='flex flex-row items-center' id='isEmail' name='isEmail' label='Email' />
                    <Checkbox className='flex flex-row items-center' id='isWhatsapp' name='isWhatsapp' label='Whatsapp' />
                </div>
            </div>
        </Form>
    );
}
