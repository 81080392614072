import { websocket } from '../types';

const INITIAL_STATE = {
    error: undefined,
    websocketConnection: null,
};

const websocketReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case websocket.CONNECT_TO_WEBSOCKET_SUCCESS:
            return {
                ...state,
                websocketConnection: action.payload.socket,
            };

        case websocket.CONNECT_TO_WEBSOCKET_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return {
                ...state,
            };
    }
};

export default websocketReducer;
