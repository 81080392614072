import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import moment from 'moment';
import { validateResponseDate, validateStringValue } from '../../../utils/common';
import Form from '../../../common/Form';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import TextArea from '../../../common/Form/TextArea';
import { updateTicketProperties } from '../../../actions/tickets';
import { useSelector } from 'react-redux';

const boxStyle = {
    border: 0,
    p: 1,
    height: '40em',
    width: '60em',
    bgcolor: 'white',
};

const formName = 'addRemarkForm';

export default function ViewAllRemark({ remarkList, ticketId, source = '' }) {
    const { ticketList } = useSelector((state) => state.tickets);
    const ticketRemarks = ticketList?.find((ticket) => ticket?.ticket_id === ticketId)?.remarks;
    if (source === 'ticket_table' && ticketRemarks) remarkList = ticketRemarks;
    const [remarks, setRemarks] = useState([]);

    useEffect(() => {
        setRemarks(remarkList)
    }, [remarkList])

    const handleSearch = (e) => {
        let searchKey = e.target.value.trim().toLowerCase();
        if (!searchKey) {
            setRemarks(remarkList);
            return;
        }
        const serachedItems = remarkList.filter((item) => {
            const createdBy = `${validateStringValue(item?.created_by?.first_name)} ${validateStringValue(item?.created_by?.last_name)}`
            const remarkText = (item?.remark?.length > 0 ? item?.remark : '') + (item?.event?.length > 0 ? item?.event : '')
            return (
                createdBy.toLowerCase().includes(searchKey) ||
                remarkText?.toLowerCase().includes(searchKey)
            );
        });
        setRemarks(serachedItems)
    };


    return (
        <>
            <Box sx={boxStyle} className='shadow-2xl overflow-hidden'>
                <div
                    className='relative h-full flex'
                    style={{
                        flexFlow: 'column',
                    }}
                >
                    <div className='flex flex-initial'>
                        <input onChange={handleSearch} className='w-full py-2 my-2 px-2 border-2 rounded-l text-font11' placeholder={'Search'} />
                    </div>
                    <div className='flex items-center px-3 py-3 flex flex-initial'>
                        <div className='text-font12 px-2 text-scogoprimary font-medium w-4/12'>CreatedAt</div>
                        <div className='text-font12 px-2 text-scogoprimary  font-medium w-6/12'>Remark</div>
                        <div className='text-font12 px-2 text-scogoprimary  font-medium w-2/12'>By</div>
                        <hr className='col-span-2' />
                    </div>
                    <div className='overflow-y-auto flex-auto'>
                        {Array.isArray(remarks) &&
                            remarks?.map((item, i) => {
                                const createdAt = moment(new Date(validateResponseDate(item?.created_at))).format('YYYY-MM-DD H:m:s');
                                const createdBy = `${validateStringValue(item?.created_by?.first_name)} ${validateStringValue(item?.created_by?.last_name)}`
                                if (item?.remark?.trim() !== item?.event?.trim()) {
                                    var remarkText = (item?.event?.length > 0 ? item?.event.trim() : '') + (item?.remark?.length > 0 ? ` : ${item?.remark.trim()} ` : '')
                                } else {
                                    remarkText = (item?.remark?.length > 0 ? `${item?.remark.trim()} ` : '')
                                }
                                return (
                                    <div className={`flex items-center px-3 py-3 ${i % 2 !== 0 ? 'bg-gray-100' : ''}`}>
                                        <div className={`text-font11 py-4 px-2  w-4/12 break-all`}>{createdAt}</div>
                                        <div className={`text-font11 py-4 px-2 w-6/12 break-all`} >{remarkText}</div>
                                        <div className={`text-font11 py-4 px-2 w-2/12 break-all`}>{createdBy}</div>
                                    </div>
                                );
                            })}
                    </div>
                    <div className='flex-initial w-full'>
                        <AddremarkForm ticketId={ticketId} />
                    </div>
                </div>
            </Box>
        </>
    );
}



const AddremarkForm = ({ ticketId }) => {
    const dispatch = useDispatch();
    const { formButtonLoading } = useSelector((state) => state.loading);


    const methods = useForm({ defaultValues: {}, mode: 'all' });
    const submit = (formValues) => {
        dispatch(updateTicketProperties({
            formName, data: {
                ticketId,
                property: "ADD_REMARK",
                data: {
                    remark: formValues.remark
                }
            },
            updateTicketList: true
        }));
    };

    return (
        <Form
            methods={methods}
            onSubmit={submit}
            submitButtonText={'Add'}
            showSubmit
            submitContainerClassName='flex gap-2 justify-end py-4 px-4'
            isLoading={formButtonLoading[formName]}
            className='w-full'
            showCancelButton={false}
            buttonClass='w-32'
            disabledClass='text-black flex items-center justify-center rounded-6px shadow-primaryBtnShadow text-xl gap-1 font-medium relative cursor-not-allowed border-scogoddd bg-scogoddd w-32'
        >
            <div className='flex gap-2 px-1'>
                <TextArea className='w-full' label='Remark' name='remark' type={'text'} required />
            </div>
        </Form>
    );
}