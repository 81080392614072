import { admin } from '../types';

export const getSignOffTemplates = (customer_id) => ({
    type: admin.GET_SIGN_OFF_TEMPLATES_REQUEST,
    payload: { customer_id },
});

export const listAllState = ({ dropdown = false, stateIds, userterritories = false, role, allowAll = false, userType }) => ({
    type: admin.LIST_ALL_STATE_REQUEST,
    payload: { dropdown, stateIds, userterritories, role, allowAll, userType },
});

export const listAllCity = ({ dropdown = false, limit, offset, userterritories = false, role, userType }) => ({
    type: admin.LIST_ALL_CITY_REQUEST,
    payload: { dropdown, limit, offset, userterritories, role, userType },
});

export const getAllStateCities = ({ stateId }) => ({
    type: admin.GET_ALL_STATE_CITIES_REQUEST,
    payload: { stateId },
});

export const addNewPincode = ({ data, formName, onSuccess }) => ({
    type: admin.ADD_NEW_PINCODE_REQUEST,
    payload: { data, formName, onSuccess },
});

export const clearStateCitiesFromStore = () => ({
    type: admin.CLEAR_STATE_CITIES_FROM_STORE,
});

export const getIssueCategories = ({ customerId, limit, page, dropdown }) => ({
    type: admin.LIST_ISSUE_CATEGORIES_REQUEST,
    payload: { customerId, limit, page, dropdown },
});

export const listAllDistrict = ({ stateId, cityId, dropdown }) => ({
    type: admin.LIST_ALL_DISTRICT_REQUEST,
    payload: { stateId, cityId, dropdown }
})

export const listAllLocalities = ({ stateId, cityId, districtId, dropdown }) => ({
    type: admin.LIST_ALL_LOCALITY_REQUEST,
    payload: { stateId, cityId, districtId, dropdown }
})

export const clearDistrictListFromStore = () => ({
    type: admin.CLEAR_DISTRCT_LIST_FROM_STORE,
});

export const clearLocalityListFromStore = () => ({
    type: admin.CLEAR_LOCALITY_LIST_FROM_STORE,
});