import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Layout from './Layout';


import InputFiels from '../../common/InputFiels';


import { isValidEmail } from '../../utils/utils';

import { sendForgotPasswordEmailAction, setForgotPasswordEmailAction, resetForgotPasswordEmailSentAction } from '../../actions/auth';
import { NewScogoButton } from './components/NewScogoButton';


function ForgotPassword() {
    const [error, setError] = useState(undefined);
    const auth = useSelector((state) => state.auth);
    let { forgotPasswordEmail, showForgotPasswordEmailSent } = auth;
    const loading = useSelector((state) => state.loading);
    const { isForgotPasswordSubmitButtonLoading } = loading;
    const dispatch = useDispatch();
    const setForgotPasswordEmail = (e) => {
        if (error) setError(false);
        dispatch(setForgotPasswordEmailAction({ forgotPasswordEmail: e.target.value }));
    };

    useEffect(() => {
        dispatch(resetForgotPasswordEmailSentAction(false));
    }, []);

    const sendForgotPasswordEmail = (e) => {
        e.preventDefault();
        if (!isValidEmail(forgotPasswordEmail)) {
            setError(true);
        } else {
            dispatch(
                sendForgotPasswordEmailAction({
                    forgotPasswordEmail: forgotPasswordEmail.toString(),
                })
            );
        }
    };

    const resetForgotPasswordEmailSent = (e) => {
        e.preventDefault();
        dispatch(resetForgotPasswordEmailSentAction(false));
    };

    let content;
    if (showForgotPasswordEmailSent) {
        content = (
            <>
                <br />

                <div className='text-[28px] font-semibold flex items-center justify-center'>Kindly Check</div>
                <p className='text-font16 text-scogolightgray text-bold font-normal mt-4 text-center'>
                    An email has been sent to the email address you provided with instructions on how to reset your password , Kindly check.
                </p>


                <br />
                <p className='text-font14 text-scogolightgray text-bold font-normal mt-4 text-center'>
                    Didn’t receive the email yet? Please check your spam folder, or
                    <span style={{ cursor: 'pointer' }} onClick={resetForgotPasswordEmailSent} className='text-scogoorange'>
                        {' '}
                        click here to try again.{' '}
                    </span>
                </p>
            </>
        );
    } else {
        content = (
            <form className='w-[30rem] ml-11 flex flex-col '>
                <p className='text-[28px] font-semibold text text-scogo15 mt-2 text-center 
                '>Forgot  Password?</p>
                <p className='text-[1.3rem] text-scogolightgray text-bold font-normal text-center '>
                    Enter your registered email address with Scogo and we will send you an email with reset password link within few minutes
                </p>
                <div className='flex flex-col gap-8'>

                    <InputFiels
                        type='text'
                        inputClass='font-normal block w-full focus:ring-0 py-4 px-2 text-font14  rounded-md mt-4 placeholder:text-inputfieldtext placeholder:text-lg'
                        placeholder='Enter your email id'
                        value={forgotPasswordEmail}
                        onChange={(e) => setForgotPasswordEmail(e)}
                        autoFocus
                        error={error}
                    />

                    <NewScogoButton onClick={sendForgotPasswordEmail} isLoading={isForgotPasswordSubmitButtonLoading} />
                </div>
                <p className=' text-font14  font-medium mt-8 border-1 border-scogoprimary flex justify-center h-[3.5rem] items-center rounded-full'>
                    <Link to='/login' exact='true' className='mr-12'>
                        <span className='text-scogoprimary cursor-pointer  ml-4'> {' < Back '} </span>
                    </Link>
                </p>
            </form>
        );
    }

    return (
        <Layout>
            <div className='p-2 ' style={{ width: '35rem' }}>
                {content}
            </div>
        </Layout>
    );
}

export default ForgotPassword;
