export const OPEN_TEXT_MODAL = 'OPEN_TEXT_MODAL';

export const CLOSE_MODAL = 'CLOSE_MODAL';
export const OPEN_CUSTOM_MODAL = 'OPEN_CUSTOM_MODAL';
export const OPEN_SMALL_CUSTOM_MODAL = 'OPEN_SMALL_CUSTOM_MODAL';
export const OPEN_FULLSCREEN_MODAL = 'OPEN_FULLSCREEN_MODAL';

export const CLOSE_IMAGE_MODAL = 'CLOSE_IMAGE_MODAL';
export const OPEN_CUSTOM_IMAGE_MODAL = 'OPEN_CUSTOM_IMAGE_MODAL';
export const MEDIA_VIEW_MODAL = 'MEDIA_VIEW_MODAL';

export const OPEN_TUTORIAL_STEPS = 'OPEN_TUTORIAL_STEPS';
export const CLOSE_TUTORIAL_STEPS = 'CLOSE_TUTORIAL_STEPS';

export const OPEN_PROGRESS_BAR_MODAL = 'OPEN_PROGRESS_BAR_MODAL';
export const CLOSE_PROGRESS_BAR_MODAL = 'CLOSE_PROGRESS_BAR_MODAL';

export const DOWNLOAD_ZIP_START_WEBSOCKET = 'DOWNLOAD_ZIP_START_WEBSOCKET';
export const DOWNLOAD_ZIP_PROGRESS_WEBSOCKET = 'DOWNLOAD_ZIP_PROGRESS_WEBSOCKET';
export const DOWNLOAD_ZIP_DONE_WEBSOCKET = 'DOWNLOAD_ZIP_DONE_WEBSOCKET';
export const DOWNLOAD_ZIP_FAIL_WEBSOCKET = 'DOWNLOAD_ZIP_FAIL_WEBSOCKET';
export const OPEN_REQUESTED_CALL_MODAL = 'OPEN_REQUESTED_CALL_MODAL';
export const CLOSE_FULL_SCREEN_MODAL = 'CLOSE_FULL_SCREEN_MODAL';