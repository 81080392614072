export const getPaymentFilterData = state => {
    return {
        limit : state.payments.rowsPerPage,
        page : state.payments.page,
        query: state.payments.searchQuery,
        tab: state.payments.tab,
        filterPayload : state.payments.filterPayload,
        isFilterActive : state.payments.isFilterActive
    }
}
