import React from 'react';
import StatusLabel from '../../Payments/reusableComponents/StatusLabel';
import { isToShowExecuteTicket, ticketStatusList, ticketTypeList } from '../TicketController';
import { useSelector } from 'react-redux';
import { SimpleButton } from '../../../common/Buttons/SimpleButton';
import { getCustomerIdOfLoginUser } from '../../../utils/utils';
import { isCustomerGroup } from '../../../utils/role';

export const StatusColumn = ({ ticket }) => {

    const { loggedUser } = useSelector((state) => state.auth);
    const role = loggedUser.role.id;
    const type = loggedUser.type;
    const loggedUserCustomerId = getCustomerIdOfLoginUser(loggedUser);
    const ticket_status = ticketStatusList.find((item) => item.check(ticket, { role, type }));
    let ticket_type = ticketTypeList.find((item) => item.check(ticket));
    if (!ticket_type) ticket_type = { label: ticket.service_type_name, color: 'scogoprimary' };
    const showSignOffResponseLabel = ticket && ticket.signOffRequest === 1;
    const showExecuteTicketButton = isToShowExecuteTicket(ticket, { role, type, loggedUserId: loggedUser.id, loggedUserCustomerId });
    let hoverText = ''
    const openExecuteTicket = () => {
        let path = `/tickets/${ticket.ticket_id}/execute/initiate`
        if (ticket.signOffRequest === 1) {
            path = `${path}?editWorkFlow=true`
        }
        window.open(path)
    };

    let executeDisabled = ticket?.sign_off_lock === 1;
    if (isCustomerGroup(role, type)) executeDisabled = false;
    let btnClass = 'bg-white border flex items-center justify-center border-scogoprimary leading-6 cursor-pointer text-scogoprimary rounded-lg gap-1 font-medium cursor-pointer text-font11 px-2 hover:bg-scogoprimary hover:text-white';
    if (executeDisabled) {
        btnClass = 'text-black flex items-center justify-center rounded-6px shadow-primaryBtnShadow text-xl gap-1 font-medium relative cursor-not-allowed border-scogoddd bg-scogoddd px-2';
    }
    if (ticket_status?.getHoverText) {
        hoverText = ticket_status?.getHoverText(ticket)
    }

    return (
        <div className='w-full '>
            <div className='flex flex-col gap-2 mb-2'>

                {ticket_status?.label && (
                    <StatusLabel
                        borderColor={`border-${ticket_status.color}`}
                        content={ticket_status.label}
                        color={ticket_status.color}
                        shownNobgLabel={ticket_status?.shownNobgLabel}
                        labelClass={ticket_status?.labelClass}
                        hoverText={hoverText}
                        titleText={ticket_status.textTittle}
                    />
                )}
                {ticket_type?.label && <StatusLabel borderColor={`border-${ticket_type.color}`} titleText='Ticket Type' hoverText={ticket_type.hoverText} content={ticket_type.label} color={ticket_type.color} />}
                {showSignOffResponseLabel && ticket.sign_off_response && <StatusLabel content={ticket.sign_off_response} color={'scogo2e'} />}
            </div>
            {showExecuteTicketButton && (
                <SimpleButton
                    buttonIconComponent={<span className='material-icons text-font16'>room_preferences</span>}
                    btnClass={btnClass}
                    textOrComponent={'Execute'}
                    onClick={openExecuteTicket}
                    disabled={executeDisabled}
                    onHoverTitle={executeDisabled ? 'SP/FE can not raise sign off of this ticket, as execution date is more than 7 days old' : ''}
                />
            )}
        </div>
    );
};
