import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Popup from '../Popup';
import ButtonScogoPrimary from '../Buttons/ButtonScogoPrimary';
import ButtonScogoClosed from '../Buttons/ButtonScogoClosed';
import ButtonScogoClosedOutlined from '../Buttons/ButtonScogoClosedOutlined';

const TextModal = () => {
    const { heading, text, buttons, closeOnBackdropClick, formName } = useSelector((state) => state.modal);
    const { formButtonLoading } = useSelector((state) => state.loading);

    let buttonsUI = buttons.map((button, index) => {
        const buttonLookup = {
            red: ButtonScogoClosed,
            blue: ButtonScogoPrimary,
            redOutlined: ButtonScogoClosedOutlined,
        };

        const ButtonComponent = buttonLookup[button.type];

        return (
            <ButtonComponent
                key={`text_modal_${index}`}
                textOrComponent={button.textOrComponent}
                onClick={button.onClick}
                loading={button.showLoading ? formButtonLoading?.[formName] : false}
                buttonClass={button.buttonClass}
            />
        );
    });

    return (
        <Popup closeOnBackdropClick={closeOnBackdropClick}>
            <p className='text-font24 text-scogo30 mb-2 text-scogoprimary font-bold'>{heading}</p>
            <p className='text-scogogray text-font16'>{text}</p>
            <div className='gap-2 flex items-center justify-center mt-8'>{buttonsUI}</div>
        </Popup>
    );
};

export default TextModal;
