import { useSelector } from "react-redux";
import IconToolTip from "../../../common/IconToolTip";
import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { clearMissCallAction, holdRoomAction, joinRoomAction, openActiveCallAction } from "../../../actions/conversations";
import Loader from "../../../common/Loader";
import { getActiveVoiceCall } from "../../../actions/tickets";
import { enableRoom, getUserCallStatusAction } from "../../../actions/users";
import { openRequestedCallModal } from "../../../actions/modal";
import moment from "moment";
import { sendDataThroughWebsocket } from "../../../actions/websocket";
import { websocketEvents } from "../../../utils/websocket";
import { getSingleConvoIdFromUserIds } from "../../../utils/utils";
import { generateRoomLink } from "../../../pages/videomeet/components/constants";

const callStatuses = {
    1: {
        title: 'Invited',
        css: `linear-gradient(180deg, rgba(38, 112, 201, 0.7) 0%, #2670C9 100%)`
    },
    2: {
        title: 'On Call',
        css: `linear-gradient(180deg, rgba(38, 201, 112, 0.7) 0%, #089000 100%)`
    },
    3: {
        title: 'On Hold',
        css: `linear-gradient(180deg, rgba(255, 223, 0, 0.7) 0%, #F48A21 100%)`
    },
    4: {
        title: 'Missed',
        css: `linear-gradient(180deg, rgba(255, 60, 60, 0.7) 0%, #FF6347 100%)`
    }
}

const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;

    const padZero = (value) => String(value).padStart(2, "0");

    return `Time : ${padZero(hours)}:${padZero(minutes)}:${padZero(
        seconds
    )}`;
};


const AwaitingCallCard = ({ ticket, renderDetail, userInvitedInCall }) => {
    const { formButtonLoading } = useSelector((state) => state.loading);
    const { loggedUser } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const [time, setTime] = useState(0);

    const singleConversationId = getSingleConvoIdFromUserIds(loggedUser?.id, userInvitedInCall?.invited_by);

    const refresh = () => {
        dispatch(getActiveVoiceCall());
        dispatch(getUserCallStatusAction({ userId: loggedUser?.id, status: [1, 2, 3, 4] }));
    }

    const handleMeetOnClick = () => {
        dispatch(openRequestedCallModal({ data: undefined, isOnCall: false }));
        let singleConvoId;
        if (!ticket?.id) singleConvoId = singleConversationId;
        dispatch(joinRoomAction({ userId: loggedUser?.id, room_id: userInvitedInCall?.fk_room_id, ticketId: ticket?.id, singleConvoId: singleConvoId, onSuccess: refresh }));
        dispatch(openActiveCallAction({ ticketId: ticket?.id, room_id: userInvitedInCall.fk_room_id, singleConvoId: singleConvoId, buttonName: `awaiting-call-card-${userInvitedInCall?.id}` }));
    }

    const handleHoldOnClick = (e) => {
        e.stopPropagation();
        let singleConvoId;
        if (!ticket?.id) singleConvoId = singleConversationId;
        dispatch(holdRoomAction({ userId: loggedUser?.id, room_id: userInvitedInCall?.fk_room_id, ticketId: ticket?.id, singleConvoId: singleConvoId, onSuccess: refresh, buttonName: `hold-awaiting-call-card-${userInvitedInCall?.id}` }));
        dispatch(sendDataThroughWebsocket({ action: websocketEvents.chat.holdOrEndcall, data: { holdCall: true, room_id: userInvitedInCall?.fk_room_id } }));
    }

    const handleClearOnClick = (e) => {
        e.stopPropagation()
        dispatch(clearMissCallAction({ userId: loggedUser?.id, room_id: userInvitedInCall?.fk_room_id, onSuccess: refresh, buttonName: `miss-awaiting-call-card-${userInvitedInCall?.id}` }))
    }

    const handleOnSuccess = async ({ room_id, conversationId }) => {
        dispatch(getUserCallStatusAction({ userId: loggedUser?.id, status: [1, 2, 3, 4] }));
        dispatch(sendDataThroughWebsocket({ action: websocketEvents.chat.videoMeetInvite, data: { userIds: [userInvitedInCall?.invited_by], videoMeetDetails: { videoMeetLink: generateRoomLink(room_id, conversationId), room_id: room_id } } }));
        window.open(generateRoomLink(room_id, conversationId), "_blank");
    }

    const handleP2pMissedCall = () => {
        dispatch(clearMissCallAction({ userId: loggedUser?.id, room_id: userInvitedInCall?.fk_room_id }))
        dispatch(enableRoom({ userId: loggedUser?.id, conversationId: singleConversationId, onSuccess: handleOnSuccess, buttonName: `awaiting-call-card-${userInvitedInCall?.id}` }));
    }

    useEffect(() => {
        let startTime = userInvitedInCall?.updated_at;
        if (ticket?.id) startTime = ticket?.room_call_active_from;
        const updatedAtMoment = moment(startTime);
        const currentMoment = moment();
        const differenceInSeconds = currentMoment.diff(updatedAtMoment, 'seconds');
        setTime(differenceInSeconds);

        const interval = setInterval(() => {
            setTime((prevTime) => prevTime + 1);
        }, 1000);

        return () => clearInterval(interval);
    }, [ticket?.id, ticket?.room_call_active_from, userInvitedInCall?.updated_at])


    return <div className='h-auto border-scogoddd rounded-xl shadow-xl'>
        <div className='rounded-t-xl flex items-center gap-2 h-[6vh] px-4 relative hover:cursor-pointer'
            onClick={() => {
                if (userInvitedInCall?.status === 4 && !ticket?.id) {
                    handleP2pMissedCall()
                }
                else {
                    handleMeetOnClick();
                }
            }}
            style={{
                background:
                    `${callStatuses[userInvitedInCall?.status]?.css}`,
            }}
        >
            <p className="text-font13 font-normal max-w-max text-white">{callStatuses[userInvitedInCall?.status]?.title}</p>
            <IconToolTip title={"Join Call"}>
                {
                    formButtonLoading?.[`awaiting-call-card-${userInvitedInCall?.id}`] ?
                        <Loader size="18" />
                        :
                        <div className="flex items-center w-7 h-7">
                            <img src={process.env.PUBLIC_URL + '/img/scogo-connect-darkblue.svg'} alt="" className="w-full h-full object-contain hover:cursor-pointer" />
                        </div>
                }
            </IconToolTip>

            {userInvitedInCall?.status === 2 && <span className='text-normal text-font11 text-white' >{formatTime(time)}</span>}
            {userInvitedInCall?.status === 1 &&
                <IconToolTip title={"Hold Call"} >
                    {
                        formButtonLoading?.[`hold-awaiting-call-card-${userInvitedInCall?.id}`] ?
                            <div className="absolute right-8">
                                <Loader size="18" />
                            </div>
                            :
                            <div className="flex items-center absolute right-8" onClick={handleHoldOnClick}>
                                <span class="material-icons-outlined text-scogoAmber text-font18 cursor-pointer">phone_paused</span>
                            </div>
                    }
                </IconToolTip>
            }
            {userInvitedInCall?.status === 4 &&
                <IconToolTip title={"Clear Missedcall"} >
                    {
                        formButtonLoading?.[`miss-awaiting-call-card-${userInvitedInCall?.id}`] ?
                            <div className="absolute right-8">
                                <Loader size="18" />
                            </div>
                            :
                            <div className="flex items-center absolute right-8" onClick={handleClearOnClick}>
                                <span class="material-icons-outlined text-scogoAmber text-font18 cursor-pointer">close</span>
                            </div>
                    }
                </IconToolTip>
            }
        </div>
        {renderDetail}
    </div >
}


export default AwaitingCallCard;