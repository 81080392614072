import React, { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { getSPProfileDetails } from '../../../actions/users';
import { getTools, addTools, updateSiteType } from '../../../actions/pmscm';
import { getSiteType } from '../../../actions/inventory';
import Checkbox from '../../../common/Checkbox';
import RejectModal from './RejectModal';
import { openCustomModal, closeModalAction } from '../../../actions/modal';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { deletePartner } from '../../../actions/partners';
import ButtonScogoPrimary from '../../../common/Buttons/ButtonScogoPrimary';
import ButtonScogoClosedOutlined from '../../../common/Buttons/ButtonScogoClosedOutlined';
import { validateStringValue } from '../../../utils/common';
import IconToolTip from '../../../common/IconToolTip';
import { PanAdharLinkeStatus } from '../../UserProfile/BusinessDetails/DefaultBusinessDetails';
const classForSpans = 'w-60';
const acceptFormName = 'verifyPartner';
const deleteFormName = 'deleteVPName';

export default function VerificationPending(props) {
    const { userDetails, role } = props;
    const [selectedTools, setSelectedTools] = useState([]);
    const [selectedSiteTypes, setSelectedSiteTypes] = useState([]);
    const [expanded, setExpanded] = React.useState('panel1');
    const dispatch = useDispatch();
    const { spProfileDetails } = useSelector((state) => state.user);
    const { isSPProfileDetails } = useSelector((state) => state.loading);
    const { siteType } = useSelector((state) => state.inventory);
    const { tools } = useSelector((state) => state.pmscm);
    const { formButtonLoading } = useSelector((state) => state.loading);
    const [userType, setUserType] = useState('SP');
    const isAddressProofAvailable = spProfileDetails?.company_detail?.user?.address_proof_number;

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const closeModal = () => {
        dispatch(closeModalAction());
    };

    useEffect(() => {
        dispatch(getSPProfileDetails({ id: userDetails.id }));
        dispatch(getTools({ isDropDown: false }));
        dispatch(getSiteType({ isDropDown: false }));
    }, []);

    useEffect(() => {
        if (['ISP', 'SPISP'].includes(spProfileDetails?.company_detail?.user?.type)) {
            setUserType('ISP');
        }
    }, [spProfileDetails]);

    const handleToolsChange = (isChecked, elem) => {
        const filteredTools = selectedTools.filter((item) => item !== elem);
        if (isChecked) setSelectedTools([elem, ...selectedTools]);
        else setSelectedTools(filteredTools);
    };

    const handleSiteTypeChange = (isChecked, elem) => {
        const filteredTools = selectedSiteTypes.filter((item) => item !== elem);
        if (isChecked) setSelectedSiteTypes([elem, ...selectedSiteTypes]);
        else setSelectedSiteTypes(filteredTools);
    };

    const handleReject = () => {
        let heading = 'Reject';
        let modalComponent = <RejectModal closeModal={closeModal} spProfileDetails={spProfileDetails} />;
        let modalWidth = '40rem',
            modalHeight = 'auto';
        dispatch(openCustomModal({ modalComponent, heading, modalWidth, modalHeight }));
    };

    const handleDelete = () => {
        const userName = `${userDetails?.first_name} ${userDetails?.last_name}`;
        let payload = { userId: userDetails.id, role, userName, formName: deleteFormName };
        dispatch(deletePartner(payload));
    };

    const isFalsyValue = (item) => {
        return ['', undefined, null].includes(item);
    };

    const handleAccept = () => {
        let error = false;
        const isToolsNotSelected = selectedTools.length === 0;
        if (userType === 'SP' && isToolsNotSelected) {
            error = true;
        }
        let selectedType = '';
        if (isFalsyValue(spProfileDetails.company_detail.user.type)) {
            selectedType = 'SP';
        }

        if (!error) {
            if (userType === 'ISP') {
                const payload = {
                    spId: spProfileDetails.company_detail.service_partner_id,
                    tools: [],
                    role: 'vp',
                    selectedType,
                    formName: acceptFormName,
                    address_proof_verified: 1
                };
                dispatch(addTools(payload));
            } else {
                if (selectedTools.length > 0) {
                    const payload = {
                        spId: spProfileDetails.company_detail.service_partner_id,
                        tools: selectedTools,
                        role: 'vp',
                        selectedType,
                        formName: acceptFormName,
                        address_proof_verified: 1
                    };
                    dispatch(addTools(payload));
                }
                if (selectedSiteTypes.length > 0) {
                    const payload = {
                        user_id: spProfileDetails.company_detail.fk_user_id,
                        site_type: selectedSiteTypes,
                        role: 'vp',
                        formName: acceptFormName,
                    };
                    dispatch(updateSiteType(payload));
                }
            }
        }
    };

    const bankDetails = () => {
        return (
            <div className='text-font11 gap-3 flex flex-col'>
                <p className='w-full flex gap-4 font-normal'>
                    <span className={classForSpans}>Bank Name</span> <span>{spProfileDetails?.company_detail?.bank?.sp_bank_name}</span>
                </p>
                <p className='w-full flex gap-4 font-normal'>
                    <span className={classForSpans}>Account Holder</span> <span>{spProfileDetails?.company_detail?.bank?.sp_bank_account_holder_name}</span>
                </p>
                <p className='w-full flex gap-4 font-normal'>
                    <span className={classForSpans}>Account Number</span> <span>{spProfileDetails?.company_detail?.bank?.sp_bank_account_number}</span>
                </p>
                <p className='w-full flex  gap-4 font-normal'>
                    <span className={classForSpans}>IFSC Code</span> <span> {spProfileDetails?.company_detail?.bank?.sp_bank_ifsc_code}</span>
                </p>
            </div>
        );
    };

    const showWarning = selectedTools.length === 0;

    return (
        <>
            <div className='px-4'>
                {showWarning ? <WarningCard labelone={'Please Select Tools'} labelTwo={'Tools are Mandatory'} /> : <></>}
                {!isAddressProofAvailable ? <WarningCard labelone={'Address Proof Not Updated'} /> : <></>}
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className={'mt-4'} elevation={0}>
                    <AccordionSummary expandIcon={<span className='material-icons text-scogobgsky'> expand_more </span>} aria-controls='panel1bh-content' id='panel1bh-header'>
                        <Typography sx={{ width: '100%', flexShrink: 0, fontSize: '1.1rem' }} className='text-scogobgsky'>
                            Partner's Details
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>{!isSPProfileDetails ? <PartnerDetail spProfileDetails={spProfileDetails} /> : <SkeltonCard />}</AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} elevation={0}>
                    <AccordionSummary expandIcon={<span className='material-icons text-scogobgsky'> expand_more </span>} aria-controls='panel2bh-content' id='panel2bh-header'>
                        <Typography sx={{ width: '100%', flexShrink: 0, fontSize: '1.1rem' }} className='text-scogobgsky'>
                            Bank Details
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>{!isSPProfileDetails ? bankDetails() : <SkeltonCard />}</AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} elevation={0}>
                    <AccordionSummary expandIcon={<span className='material-icons text-scogobgsky'> expand_more </span>} aria-controls='panel3bh-content' id='panel3bh-header'>
                        <Typography sx={{ width: '100%', flexShrink: 0, fontSize: '1.1rem' }} className='text-scogobgsky'>
                            Tool Details
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {!isSPProfileDetails ? (
                            <div className='grid grid-cols-3 grid-flow-row gap-4'>
                                {tools?.map((elem, index) => {
                                    return (
                                        <Checkbox
                                            key={index}
                                            CheckContent={elem.tool_name}
                                            checkColor='text-scogoorange mr-2'
                                            textStyle={'text-font11'}
                                            onChange={(checked) => handleToolsChange(checked, elem.id)}
                                            checked={selectedTools.includes(elem.id)}
                                            dynamicSize={'1.3rem'}
                                        />
                                    );
                                })}
                            </div>
                        ) : (
                            <SkeltonCard />
                        )}
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} elevation={0}>
                    <AccordionSummary expandIcon={<span className='material-icons text-scogobgsky'> expand_more </span>} aria-controls='panel4bh-content' id='panel4bh-header'>
                        <Typography sx={{ width: '100%', flexShrink: 0, fontSize: '1.1rem' }} className='text-scogobgsky'>
                            Site Type Details
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {!isSPProfileDetails ? (
                            <div className='grid grid-cols-3 grid-flow-row gap-4'>
                                {siteType?.map((elem, index) => {
                                    return (
                                        <Checkbox
                                            key={index}
                                            CheckContent={elem.label}
                                            checkColor='text-scogoorange mr-2 '
                                            textStyle={'text-font11'}
                                            onChange={(checked) => handleSiteTypeChange(checked, elem.value)}
                                            checked={selectedSiteTypes.includes(elem.value)}
                                            dynamicSize={'1.3rem'}
                                        />
                                    );
                                })}
                            </div>
                        ) : (
                            <SkeltonCard />
                        )}
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className={`px-6  pt-2  py-10 flex gap-2 justify-end mt-4 relative`}>
                <ButtonScogoPrimary
                    textOrComponent={'Accept'}
                    onClick={handleAccept}
                    disabled={selectedTools.length === 0 || !isAddressProofAvailable}
                    loading={formButtonLoading[acceptFormName]}
                />
                <ButtonScogoClosedOutlined textOrComponent='Reject' onClick={handleReject} />

                <ButtonScogoClosedOutlined textOrComponent='Delete' onClick={handleDelete} loading={formButtonLoading[deleteFormName]} loaderColor={'#F48A21'} />
            </div>
        </>
    );
}

const SkeltonCard = () => {
    return (
        <Box>
            {[1, 2, 3, 4].map((elem, index) => {
                return (
                    <div className='mt-1' key={index}>
                        <Skeleton sx={{ background: 'rgb(221,221,221)' }} variant='rectangular' width={'full'} height={20} animation='wave' />
                    </div>
                );
            })}
        </Box>
    );
};


const WarningCard = ({ labelone, labelTwo }) => {
    return (
        <div
            className='mt-4 py-2 items-center flex px-6 rounded-md gap-4'
            style={{
                border: '1px solid rgb(227,187,187)',
                background: 'rgb(255,246,246)',
            }}
        >
            <div className='rounded-full border-2 flex justify-center items-center' style={{ background: 'rgb(178,95,94)' }}>
                <span className='material-icons' style={{ color: 'white' }}>
                    priority_high
                </span>
            </div>
            <div>
                <p className='text-font11' style={{ color: 'rgb(145,45,56)' }}>
                    {labelone}
                </p>
                <p className='text-font11' style={{ color: 'rgb(145,45,56)' }}>
                    {labelTwo}
                </p>
            </div>
        </div>
    );
};

const PartnerDetail = ({ spProfileDetails }) => {
    const user = spProfileDetails?.company_detail?.user;
    const gstNo = spProfileDetails?.company_detail?.sp_gst_no;
    const sp_pancard_number = spProfileDetails?.company_detail?.sp_pan_card_number;
    const pancard_number = spProfileDetails?.company_detail?.user?.pancard_number;
    const aadharNumber = spProfileDetails?.company_detail?.adhar_number;

    const userProofImage = spProfileDetails?.fe_adhar_card_image || spProfileDetails?.sp_pan_card_image;


    return (
        <div className='gap-3 flex flex-col text-font11'>
            <p className='flex gap-4 font-normal'>
                <span className={classForSpans}>Role</span>{' '}
                <span>
                    {[7, 12, 13].indexOf(spProfileDetails?.company_detail?.user?.role) !== -1 && 'Partner'}
                    {spProfileDetails?.company_detail?.user?.role === 9 && 'CP'}
                </span>
            </p>
            <Detail
                label='Name'
                value={`${validateStringValue(spProfileDetails?.company_detail?.user?.first_name)} ${validateStringValue(spProfileDetails?.company_detail?.user?.last_name)}`}
            />
            <Detail label='Company Name' value={spProfileDetails?.company_detail?.sp_name ? spProfileDetails?.company_detail?.sp_name : `Not Updated`} />
            {userProofImage && (
                <Detail
                    label='Id Proof'
                    value={
                        <>
                            <IconToolTip title='ID Card'>
                                <span className='material-icons hover:text-scogoorange cursor-pointer' onClick={() => window.open(userProofImage, '_blank')}>
                                    perm_identity
                                </span>
                            </IconToolTip>
                        </>
                    }
                />
            )}

            <Detail label='GST Number' value={gstNo} />

            {sp_pancard_number ? <Detail label='Business PAN Number'
                value={
                    <>
                        <span className={classForSpans}>{sp_pancard_number}</span>
                        <span className='pl-2'>
                            <PanAdharLinkeStatus
                                is_pancard_linked_with_aadhaar={user?.is_pancard_linked_with_aadhaar
                                } />
                        </span>

                    </>} />
                : pancard_number ? <Detail label='PAN Number' value={
                    <>
                        <span className={classForSpans}>{pancard_number}</span>
                        <span className='pl-2'>
                            <PanAdharLinkeStatus
                                is_pancard_linked_with_aadhaar={user?.is_pancard_linked_with_aadhaar
                                } />
                        </span>
                    </>

                } /> : <></>}
            <Detail label='Aadhaar Number' value={aadharNumber} />
            {user?.address_proof_number && (
                <Detail
                    label='Address Proof'
                    value={
                        <>
                            <span className={classForSpans}>{user.address_proof_number}</span>
                            <div className='flex flex-col'>
                                <IconToolTip title='ID Card'>
                                    <span className='hover:text-scogoorange cursor-pointer text-scogobgsky  font-medium ' onClick={() => window.open(user.address_proof_url, '_blank')}>
                                        View Front Image
                                    </span>
                                </IconToolTip>
                                {user?.address_proof_url_back &&
                                    <IconToolTip title='ID Card'>
                                        <span className='hover:text-scogoorange cursor-pointer text-scogobgsky  font-medium' onClick={() => window.open(user.address_proof_url_back, '_blank')}>
                                            View Back Image
                                        </span>
                                    </IconToolTip>}
                            </div>

                        </>
                    }
                />
            )}
        </div>
    );
};

const Detail = ({ label, value }) => {
    if (!value) return <></>;
    return (
        <p className='w-full flex  gap-4 font-normal'>
            <span className={classForSpans}>{label}</span> <span>{value}</span>
        </p>
    );
};
