import React from 'react';
import { useSelector } from 'react-redux';
import OverflowTip from '../../../common/OverflowTip';
import { SkeltonCardTwo } from '../../../common/SkeltonCardTwo';

export const AssetTable = ({ poDetails, isLoading, onVendorSelect }) => {
    const { boqList } = useSelector((state) => state.tickets);
    const { poassetcategories } = useSelector((state) => state.payments);

    const vendors = (index) => Array.isArray(poDetails) && poDetails.length > 0 && poDetails[index].vendors;

    const loaderComponent = (
        <div className='border border-white px-4'>
            <SkeltonCardTwo arr={[1, 2, 3]} height={18} />
        </div>
    );

    let totalQuantity = [];

    return (
        <div className='shadow-lg rounded-lg'>
            {isLoading && loaderComponent}
            {!isLoading && (
                <table className='block rounded-lg' style={{ height: 'inherit' }}>
                    <thead className={`border-b-2  py-1 grid-container bg-scogof8  rounded-t-lg`}>
                        <Thead>Asset</Thead>
                        <Thead>Category</Thead>
                        <Thead>Description</Thead>
                        <Thead>Quantity</Thead>
                        {Array.isArray(vendors(0)) &&
                            vendors(0).length > 0 &&
                            vendors(0).map((vendor, index) => {
                                return (
                                    <Thead key={index}>
                                        <label className='flex gap-2 items-center'>
                                            <input
                                                className='focus:ring-0 focus:ring-offset-0 text-scogoorange h-4 w-4'
                                                type='radio'
                                                name={'vendor'}
                                                onChange={(event) => onVendorSelect(event, vendor.vendor)}
                                            />
                                            {vendor.vendorName}
                                        </label>
                                    </Thead>
                                );
                            })}
                    </thead>
                    <tbody className={`block text-font11 bg-white text-scogo15 font-medium`}>
                        {Array.isArray(poDetails) &&
                            poDetails?.map((asset, index) => {
                                let assetType = Array.isArray(boqList) && boqList.find((boq) => boq.value === asset.assetType);
                                let poAssetCategory = Array.isArray(poassetcategories) && poassetcategories.find((assetCategory) => assetCategory.value === asset.category);

                                return (
                                    <tr key={asset._id} className={'pl-2 border-b border-scogoddd py-2 hover:bg-scogof5 grid-container'}>
                                        <Tbody>{assetType ? <OverflowTip someLongText={assetType.label} /> : <></>}</Tbody>
                                        <Tbody>{poAssetCategory ? <OverflowTip someLongText={poAssetCategory.label} /> : <></>}</Tbody>
                                        <Tbody>{asset.description ? <OverflowTip someLongText={asset.description} /> : <></>}</Tbody>
                                        <Tbody>{asset.quantity ? asset.quantity : <></>}</Tbody>
                                        {Array.isArray(vendors(index)) &&
                                            vendors(index).length > 0 &&
                                            vendors(index).map((vendor, vendorIndex) => {
                                                let total = vendor.price * asset.quantity;
                                                if (totalQuantity[vendorIndex] && totalQuantity[vendorIndex] > 0) {
                                                    total = total + totalQuantity[vendorIndex];
                                                }
                                                totalQuantity[vendorIndex] = total;
                                                return <Tbody index={vendorIndex}>{vendor.price}</Tbody>;
                                            })}
                                    </tr>
                                );
                            })}

                        <tr className='pl-2 border-b border-scogoddd py-2 hover:bg-scogof5 grid-container  rounded-b-lg'>
                            <Tbody>Total Price</Tbody>
                            <Tbody></Tbody>
                            <Tbody></Tbody>
                            <Tbody></Tbody>
                            {Array.isArray(totalQuantity) &&
                                totalQuantity.length > 0 &&
                                totalQuantity.map((total, index) => {
                                    return <Tbody>{total}</Tbody>;
                                })}
                        </tr>
                    </tbody>
                </table>
            )}
        </div>
    );
};

const Thead = ({ children }) => {
    return <th className={`truncate text-left font-medium text-scogogray pl-2 text-font13`}>{children}</th>;
};
const Tbody = ({ children }) => {
    return <td className={` w-full py-2 text-font11`}>{children}</td>;
};
