import React from 'react';
import { useSelector } from 'react-redux';
import ButtonScogoClosedOutlined from './Buttons/ButtonScogoClosedOutlined';
import ButtonScogoPrimary from './Buttons/ButtonScogoPrimary';

export const ConfirmationPopup = ({ closeMenu = () => {}, onClick, formName }) => {
    const { formButtonLoading } = useSelector((state) => state.loading);

    return (
        <div className='py-2 px-2 rounded-lg'>
            <p className='text-scogogray text-font16'>Are you sure you want to continue?</p>
            <div className='gap-2 flex items-center justify-center mt-8'>
                <ButtonScogoPrimary textOrComponent='Yes' onClick={() => onClick(closeMenu)} loading={formButtonLoading?.[formName]} />
                <ButtonScogoClosedOutlined textOrComponent='No' onClick={closeMenu} />
            </div>
        </div>
    );
};
