import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import { updateCustomerApprovalRecieved } from '../../../actions/tickets';
import Form from '../../../common/Form';
import Radio from '../../../common/Form/Radio';
import { useForm, useFormContext } from 'react-hook-form';

const formName = 'customer_approval_recieved';

const radioOptionYesNo = [
    { value: 1, label: 'Yes' },
    { value: 0, label: 'No' },
];

export default function CustomerApprovalReceived({ ticketId, isBulk, ticketIds }) {
    const { formButtonLoading } = useSelector((state) => state.loading);

    const defaultValues = {
        customer_approval_received: radioOptionYesNo[1].value,
        customer_po_received: radioOptionYesNo[1].value,
    };
    const dispatch = useDispatch();

    const submit = (formValues) => {
        const data = {
            customer_approval_received: formValues.customer_approval_received,
            customer_po_received: formValues.customer_po_received,
        };
        let payload = {
            data,
            bulk: isBulk,
        };
        if (isBulk) {
            payload.tickets = ticketIds;
        } else {
            payload.ticketId = ticketId;
        }
        dispatch(updateCustomerApprovalRecieved({ data: payload, formName, refresh: true }));
    };

    const methods = useForm({ defaultValues, mode: 'all' });

    return (
        <Form
            methods={methods}
            onSubmit={submit}
            formName={formName}
            submitButtonText={'Submit'}
            onCancel={() => dispatch(closeModalAction())}
            showSubmit
            submitContainerClassName='flex gap-2 justify-end py-4 px-4'
            isLoading={formButtonLoading[formName]}
        >
            <CustomerPoRecievedInputs />
        </Form>
    );
}

const CustomerPoRecievedInputs = () => {
    const { watch } = useFormContext();
    const customer_approval_received = watch('customer_approval_received');

    return (
        <div className='px-4 flex flex-col pt-6'>
            <Radio className='flex gap-2' label={'Customer Approval Received'} name={'customer_approval_received'} options={radioOptionYesNo} />
            {customer_approval_received ? <Radio className='flex gap-2' label={'PO Pending'} name={'customer_po_received'} options={radioOptionYesNo} /> : <></>}
        </div>
    );
};
