import InviteEndUsers from './Modals/InviteEndUsers';
import EmailClosedTicket from './Modals/EmailClosedTicket';
import EmailPlanningReport from './Modals/EmailPlanningReport';
import UpdateDocketDetails from './Modals/UpdateDocketDetails';
import { openCustomModal, openTextModalAction, closeModalAction } from '../../actions/modal';
import UpdatePMPopup from './Modals/UpdatePMPopup';
import { updateTicketProperties } from '../../actions/tickets';
import AddUsersInTeam from '../team/Modals/AddUsersInTeam';
import { removeTeamProject, projectTeamWhiteList, projectTeamBlackList } from '../../actions/project';
import { isScm, isCustomer, isCustomerAdmin, isCustomerUser, isCluster, isCustomerGroup } from '../../utils/role';
import { isAddSlaAllowed, isEditChecklistAllowed, isEditProjectAllowed } from '../../utils/access';
import { useSelector } from 'react-redux';
import { removeTeamsAgent } from '../../actions/superdesk';
import EditChecklist from './Modals/EditChecklist';
import AddSla from './Modals/AddSla';

const editProject = {
    key: 'editProject',
    menuName: 'Edit project',
    menuIcon: 'save_as',
    menuColor: 'text-scogoorange',
    onClick: (item, dispatch, { openAndSetProjectDetails, setProjectDetailsMode }) => {
        openAndSetProjectDetails(item);
        setProjectDetailsMode('edit');
    },
};

const addSla = {
    key: 'add_sla',
    menuName: 'Add SLA',
    menuIcon: 'schedule',
    menuColor: 'text-scogoprimary',
    onClick: (project, dispatch) => {
        return dispatch(openCustomModal({
            heading: (
                <span className='text-black'>
                    Add SLA :&nbsp;<span className='text-scogobgsky text-font16'>{`${project.project_name} | ${project.project_number}`}</span>
                </span>
            ),
            modalWidth: '60rem',
            modalHeight: 'auto',
            modalComponent: <AddSla projectId={project.id} />
        }))
    },
};

const tagPM = {
    key: 'tagPM',
    menuName: 'Tag PM',
    menuIcon: 'person_add',
    onClick: (item, dispatch, { }) => {
        return dispatch(
            openCustomModal({
                heading: 'Tag PM',
                modalWidth: '32rem',
                modalHeight: 'auto',
                modalComponent: <UpdatePMPopup projectDetail={item} />,
            })
        );
    },
};

const inviteEndUsers = {
    key: 'inviteEndUsers',
    menuName: 'Invite End Users',
    menuIcon: 'person_add',
    onClick: (item, dispatch, { }) => {
        return dispatch(
            openCustomModal({
                heading: 'Invite End Users',
                modalWidth: '70rem',
                modalHeight: 'auto',
                modalComponent: <InviteEndUsers projectDetail={item} />,
            })
        );
    },
};

const emailClosedTicketsReport = {
    key: 'emailClosedTicketsReport',
    menuName: 'Email Closed Tickets Report',
    menuIcon: 'mail',
    onClick: (item, dispatch, { }) => {
        const projectName = item?.project_name;
        return dispatch(
            openCustomModal({
                heading: `Email Closed Tickets Report | ${projectName}`,
                modalHeight: 'auto',
                modalWidth: 'auto',
                modalComponent: <EmailClosedTicket projectDetail={item} />,
            })
        );
    },
};

const emailPlanningReport = {
    key: 'emailPlanningReport',
    menuName: 'Email Planning Report',
    menuIcon: 'mail',
    onClick: (item, dispatch, { }) => {
        return dispatch(
            openCustomModal({
                heading: 'Email Planning Report',
                modalWidth: '45rem',
                modalHeight: 'auto',
                modalComponent: <EmailPlanningReport projectDetail={item} />,
            })
        );
    },
};

const enableHardCopyRequired = {
    key: 'enableHardCopyRequired',
    menuName: 'Enable Hard Copy Required',
    menuIcon: 'description',
    onClick: (item, dispatch, { }) => {
        return dispatch(
            openTextModalAction({
                heading: 'Enable Hard Copy Required',
                text: 'Are you sure you want to enable?',
                closeOnBackdropClick: false,
                buttons: [
                    {
                        showLoading: true,
                        textOrComponent: 'Enable',
                        type: 'blue',
                        buttonClass: 'hover:bg-scogobgsky',
                        onClick: () => {
                            dispatch(
                                updateTicketProperties({
                                    data: {
                                        property: 'EDIT',
                                        projectId: item?.id,
                                        data: { hard_copy_required: 1 },
                                    },
                                    formName: 'enableHardCopyModalForm',
                                })
                            );
                        },
                    },
                    {
                        textOrComponent: 'Close',
                        type: 'redOutlined',
                        buttonClass: 'hover:bg-scogobgsky',
                        onClick: () => {
                            dispatch(closeModalAction());
                        },
                    },
                ],
                formName: 'enableHardCopyModalForm',
            })
        );
    },
};

const disableHardCopyRequired = {
    menuName: 'Disable Hard Copy Required',
    menuIcon: 'block',
    onClick: (item, dispatch, { }) => {
        return dispatch(
            openTextModalAction({
                heading: 'Disable Hard Copy Required',
                text: 'Are you sure you want to disable?',
                closeOnBackdropClick: false,
                buttons: [
                    {
                        showLoading: true,
                        textOrComponent: 'Disable',
                        type: 'red',
                        buttonClass: 'hover:bg-scogoLightFillRed',
                        onClick: () => {
                            dispatch(
                                updateTicketProperties({
                                    data: {
                                        property: 'EDIT',
                                        projectId: item?.id,
                                        data: { hard_copy_required: 0 },
                                    },
                                    formName: 'disableHardCopyModalForm',
                                })
                            );
                        },
                    },
                    {
                        textOrComponent: 'Close',
                        type: 'blue',
                        buttonClass: 'hover:bg-scogobgsky',
                        onClick: () => {
                            dispatch(closeModalAction());
                        },
                    },
                ],
                formName: 'disableHardCopyModalForm',
            })
        );
    },
};

const updateDocketDetails = {
    key: 'updateDocketDetails',
    menuName: 'Update Docket Details',
    menuIcon: 'local_shipping',
    onClick: (item, dispatch, { }) => {
        return dispatch(
            openCustomModal({
                heading: 'Update Docket Details',
                modalHeight: 'auto',
                modalWidth: '45rem',
                modalComponent: <UpdateDocketDetails projectDetail={item} />,
            })
        );
    },
};

const removeFromProject = {
    key: 'removeFromProject',
    menuName: 'Remove Team',
    menuIcon: 'delete',
    iconColor: 'text-scogoclosed',
    onClick: (item, dispatch, { team, projectDetail, customerId }) => {
        const teamName = team?.name;
        const projectName = projectDetail?.project_name ? projectDetail?.project_name : 'Project';
        return dispatch(
            openTextModalAction({
                heading: 'Remove Team from project',
                text: 'Are you sure you want to continue?',
                closeOnBackdropClick: false,
                formName: 'removeTeamFromProject',
                buttons: [
                    {
                        showLoading: true,
                        textOrComponent: 'Yes',
                        type: 'red',
                        buttonClass: 'hover:bg-scogoLightFillRed',
                        onClick: () => {
                            dispatch(
                                removeTeamProject({
                                    teamId: team.id,
                                    projectId: projectDetail?.id,
                                    teamName,
                                    projectName,
                                    customerId,
                                    formName: 'removeTeamFromProject',
                                })
                            );
                        },
                    },
                    {
                        textOrComponent: 'No',
                        type: 'blue',
                        buttonClass: 'hover:bg-scogobgsky',
                        onClick: () => {
                            dispatch(closeModalAction());
                        },
                    },
                ],
            })
        );
    },
};

const addUsersInProjectTeam = {
    key: 'addUsersInProjectTeam',
    menuName: 'Add Users',
    menuIcon: 'person',
    iconColor: 'text-black',
    onClick: (item, dispatch, { projectDetail }) => {
        return dispatch(
            openCustomModal({
                heading: 'Add Users To Project Team',
                modalWidth: '60rem',
                modalHeight: 'auto',
                modalComponent: <AddUsersInTeam projectDetail={projectDetail} customerId={item.fk_customer_id} teamDetails={item} tabName={'projects'} />,
            })
        );
    },
};

const removeUserFromTeam = {
    key: 'removeUserFromTeam',
    menuName: 'Remove from team',
    menuIcon: 'delete',
    iconColor: 'text-scogoclosed',
    onClick: (item, dispatch, { agent, customerId, projectDetail }) => {
        const agentName = `${agent?.first_name} ${agent?.last_name}`;
        const teamName = `${item?.name?.toUpperCase()}`;
        const formName = 'removeUserFromTeamInProject';
        let payload = {
            customerId: item.fk_customer_id,
            teams: [item.id],
            agentId: agent.id,
            agentName,
            teamName,
        };
        return dispatch(
            openTextModalAction({
                heading: `Do you want to remove ${agent.first_name} ${agent.last_name} from ${item.name}?`,
                text: 'Are you sure you want to continue?',
                closeOnBackdropClick: false,
                formName,
                buttons: [
                    {
                        showLoading: true,
                        textOrComponent: 'Yes',
                        type: 'red',
                        buttonClass: 'hover:bg-scogoLightFillRed',
                        onClick: () => {
                            dispatch(
                                removeTeamsAgent({
                                    data: payload,
                                    tabName: 'projects',
                                    projectId: projectDetail.id,
                                    userCustomerId: customerId,
                                    formName,
                                })
                            );
                        },
                    },
                    {
                        textOrComponent: 'No',
                        type: 'blue',
                        buttonClass: 'hover:bg-scogobgsky',
                        onClick: () => {
                            dispatch(closeModalAction());
                        },
                    },
                ],
            })
        );
    },
};

const addUserToProject = {
    key: 'add_to_project',
    menuName: 'Add To Project',
    menuIcon: 'add_task',
    iconColor: 'text-scogoToastSuccess',
    onClick: (team, dispatch, { agent, customerId, projectDetail }) => {
        const agentName = `${agent?.first_name} ${agent?.last_name}`;
        let text = `${agentName} is added to project Successfully`;
        const payload = {
            projectId: projectDetail?.id,
            userId: agent?.id,
            customerId,
            customeMessage: text,
            formName: 'updateUserStatusToWhitelist',
        };

        return dispatch(
            openTextModalAction({
                heading: 'Add To Project',
                text: `Are you sure you want to add ${agentName} to project?`,
                closeOnBackdropClick: false,
                buttons: [
                    {
                        showLoading: true,
                        textOrComponent: 'Yes',
                        type: 'blue',
                        buttonClass: 'hover:bg-scogobgsky',
                        onClick: () => {
                            dispatch(projectTeamWhiteList(payload));
                        },
                    },
                    {
                        textOrComponent: 'No',
                        type: 'redOutlined',
                        buttonClass: 'hover:bg-scogobgsky',
                        onClick: () => {
                            dispatch(closeModalAction());
                        },
                    },
                ],
                formName: 'updateUserStatusToWhitelist',
            })
        );
    },
};

const removeUserFromProject = {
    key: 'blackList',
    menuName: 'Remove from Project',
    menuIcon: 'block',
    iconColor: 'text-scogo99',
    onClick: (item, dispatch, { agent, team, customerId, projectDetail }) => {
        const agentName = `${agent?.first_name} ${agent?.last_name}`;
        let text = `${agentName} is removed from project Successfully`;
        const payload = {
            projectId: projectDetail?.id,
            userId: agent?.id,
            customerId,
            customeMessage: text,
            formName: 'updateUserStatusToBlacklist',
        };

        return dispatch(
            openTextModalAction({
                heading: 'Update User Status',
                text: `Are you sure you want to remove ${agentName} from project?`,
                closeOnBackdropClick: false,
                buttons: [
                    {
                        showLoading: true,
                        textOrComponent: 'Remove',
                        type: 'red',
                        buttonClass: 'hover:bg-scogoLightFillRed',
                        onClick: () => {
                            dispatch(projectTeamBlackList(payload));
                        },
                    },
                    {
                        textOrComponent: 'Close',
                        type: 'blue',
                        buttonClass: 'hover:bg-scogobgsky',
                        onClick: () => {
                            dispatch(closeModalAction());
                        },
                    },
                ],
                formName: 'updateUserStatusToBlacklist',
            })
        );
    },
};

const editChecklist = {
    key: 'editChecklist',
    menuName: 'Edit Checklist',
    menuIcon: 'checklist',
    onClick: (project, dispatch) => {
        return dispatch(
            openCustomModal({
                heading: 'Edit Checklist',
                modalWidth: '70rem',
                modalHeight: 'auto',
                modalComponent: <EditChecklist project={project} />,
            })
        );
    },
};

export const addProjectArguments = (role, type) => {
    if (isScm(role, type) || isCluster(role, type) || isCustomerGroup(role, type)) return { isOpen: 'addProjectForSCM' };
    else return {};
};

export const GetShowAddProjectButton = () => {
    const { loggedUser } = useSelector((state) => state.auth);
    const role = loggedUser.role.id;
    const type = loggedUser.type;
    if (isScm(role, type) || isCustomerGroup(role, type)) return true;
    return false;
};

export const GetProjectTableLogic = (logic, { project } = {}) => {
    const { loggedUser } = useSelector((state) => state.auth);
    const role = loggedUser.role.id,
        type = loggedUser.type,
        frontend_controller = loggedUser.frontend_controller;
    if (logic === 'updateButton') return getUpdateButton(role, type, frontend_controller);
    else if (logic === 'editButton') return getEditButton(role, type, frontend_controller);
    else if (logic === 'addNewProjectTeam') return getAddTeamInProject(role, type);
    else if (logic === 'paymentEditButton') return getPaymentEditButton(role, type);
    else if (logic === 'additionalDetailsEditButton') return getAdditionalDetailsEditButton(role, type);
    else if (logic === 'isToShowPaymentDetails') return isToShowPaymentDetails(role, type);
    else if (logic === 'toShowCustomer') return checkIfCustomerShouldBeShown(role, type);
    return;
};

export const getProjectActions = (role, type, { frontend_controller, project }) => {
    let actions = [];
    const hardCopyRequired = project?.hard_copy_required === 1 ? disableHardCopyRequired : enableHardCopyRequired;
    if (isScm(role, type)) {
        actions = [editProject, emailClosedTicketsReport, emailPlanningReport, updateDocketDetails, hardCopyRequired, editChecklist, addSla];
    }
    if (isCluster(role, type)) {
        actions = [editProject, emailClosedTicketsReport, emailPlanningReport, updateDocketDetails, hardCopyRequired];
    } else if (isCustomer(role, type)) {
        actions = [editProject, inviteEndUsers, emailClosedTicketsReport, emailPlanningReport];
    } else if (isCustomerAdmin(role, type)) {
        actions = [editProject, emailClosedTicketsReport, emailPlanningReport, updateDocketDetails];
    } else if (isCustomerUser(role, type)) {
        actions = [emailClosedTicketsReport, emailPlanningReport, updateDocketDetails,];
    }
    actions = actions.filter((action) => {
        if (action.key === editProject.key) {
            return isEditProjectAllowed(frontend_controller);
        } else if (action.key === editChecklist.key) {
            return isEditChecklistAllowed(frontend_controller) && project.use_case_id !== 0 && project.service_type_id !== 0;
        } else if (action.key === addSla.key) {
            return isAddSlaAllowed(frontend_controller)
        }
        return true;
    });
    return actions;
};

const getUpdateButton = (role, type, frontend_controller) => {
    if (isScm(role, type) || isCustomer(role, type) || isCustomerAdmin(role, type) || isCluster(role, type)) {
        return isEditProjectAllowed(frontend_controller);
    }
    return false;
};

const getEditButton = (role, type, frontend_controller) => {
    if (isScm(role, type) || isCustomer(role, type) || isCustomerAdmin(role, type) || isCluster(role, type)) {
        return isEditProjectAllowed(frontend_controller);
    }
    return false;
};

const getPaymentEditButton = (role, type) => {
    if (isScm(role, type)) {
        return true;
    }
    return false;
};
const getAdditionalDetailsEditButton = (role, type) => {
    if (isScm(role, type) || isCustomer(role, type) || isCustomerAdmin(role, type) || isCluster(role, type)) {
        return true;
    }
    return false;
};

const isToShowPaymentDetails = (role, type) => {
    if (isScm(role, type) || isCluster(role, type)) {
        return true;
    }
    return false;
};

const getAddTeamInProject = (role, type) => {
    if (isScm(role, type) || isCustomer(role, type) || isCustomerAdmin(role, type)) {
        return true;
    }
    return false;
};

export const getProjectTeamActions = (role, type, team) => {
    let teamType = team?.type;
    if (isScm(role, type)) {
        return [addUsersInProjectTeam, removeFromProject];
    } else if (isCustomer(role, type) && teamType !== 'INTERNAL') {
        return [addUsersInProjectTeam, removeFromProject];
    } else if (isCustomerAdmin(role, type) && teamType !== 'INTERNAL') {
        return [addUsersInProjectTeam, removeFromProject];
    } else {
        return [];
    }
};

export const getProjectTeamUsersActions = (role, type, { team, agent }) => {
    let whiteOrBlackList = agent?.isBlackListed ? addUserToProject : removeUserFromProject;
    let teamType = team.type;
    if (isScm(role, type)) {
        return [whiteOrBlackList, removeUserFromTeam];
    } else if (isCustomer(role, type) && teamType !== 'INTERNAL') {
        return [whiteOrBlackList, removeUserFromTeam];
    } else if (isCustomerAdmin(role, type) && teamType !== 'INTERNAL') {
        return [whiteOrBlackList, removeUserFromTeam];
    } else {
        return [];
    }
};

export const preSelectedTeam = (teams) => {
    return teams?.filter((team) => {
        return team?.default === 1 && team?.type !== 'VENDOR';
    });
};

const checkIfCustomerShouldBeShown = (role, type) => {
    return isScm(role, type) || isCluster(role, type);
};


