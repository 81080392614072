import { useDispatch, useSelector } from 'react-redux';
import { openCustomModal } from '../../../actions/modal';
import { isCluster, isCustomerGroup, isPsp, isScm, isSp } from '../../../utils/role';
import AssignSP from '../Modal/AssignSP';
import { getIsShowAssignTicketButton } from '../TicketController';
import { PartnerDetailsPopUp } from '../TodayStatus/PartnerDetailsPopUp';
import { SimpleButton } from '../../../common/Buttons/SimpleButton';
import IconToolTip from '../../../common/IconToolTip';
import { FeRating } from '../../Payments/Modals/FeRating';
import { engineerRatingAndComment } from '../../../actions/tickets';
import MiniPopup from '../../../common/MiniPopup';

export const showInsuredIcon = ({ assignToScogo, ticket_owned_by, symbo_policy_no }) => {
    return (assignToScogo === 1 || ticket_owned_by === 0) && symbo_policy_no !== null;
};

export const PartnerDetail = ({ ticket, loading }) => {
    const { loggedUser } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const role = loggedUser.role.id;
    const type = loggedUser.type;
    const frontend_controller = loggedUser.frontend_controller;
    const showAssignButton = getIsShowAssignTicketButton({ role, type, ticket, frontend_controller });


    let spDisplayList = [
        {
            title: 'Mobile ',
            value: ticket?.sp_mobile,
        },
        {
            title: 'Email',
            value: ticket?.sp_email,
        },
    ];
    let feDisplayList = [
        {
            title: 'Mobile ',
            value: ticket?.fe_mobile,
        },
        {
            title: 'Email',
            value: ticket?.fe_email,
        },
    ];
    const showRatingButton = (ticket) => {
        return (
            isScm(role, type) &&
            !loading &&
            !ticket.rating?.id &&
            ticket.signOffAccept === 0 &&
            ticket.accepted_fe_id !== 0 &&
            ticket.is_deleted === 0 &&
            ticket.hardClose === 0 &&
            ticket.reachedSite === 1 &&
            (ticket.closedByNoc > 0 || ticket.signOffRequest !== 0)
        );
    };
    const getPartnerLabel = (ticket) => {
        if ((ticket.assignToScogo === 1 || ticket.ticket_owned_by === 0) && isCustomerGroup(role, type) && ticket.ticketType === 0 && ticket.accepted_sp_id === 0) {
            return <AssignToScogo />;
        } else if (ticket.accepted_sp_id && ticket.accepted_fe_id && ticket.accepted_sp_id === loggedUser.id && ticket.accepted_fe_id === loggedUser.id) {
            return <AssignToYou label='SP/FE' />;
        } else if (ticket.accepted_sp_id && ticket.accepted_fe_id && ticket.accepted_sp_id === ticket.accepted_fe_id) {
            return (
                <div>
                    <span className='text-scogogray  text-font11 flex items-center w-full'>
                        SP/FE :{' '}
                        {showInsuredIcon(ticket) && (
                            <IconToolTip title='FE is insured for this ticket'>
                                <span className='material-icons text-scogo2e text-font18'>gpp_good</span>
                            </IconToolTip>
                        )}{' '}
                    </span>
                    <PartnerDetailsPopUp itemList={spDisplayList} userId={ticket.accepted_sp_id} displayName={`${ticket?.sp_name}`} onHovertitle={`SP :  ${ticket?.sp_name}`} />
                </div>
            );
        } else if (ticket.accepted_sp_id === loggedUser.id && ticket.accepted_fe_id !== loggedUser.id) {
            return (
                <>
                    <AssignToYou label='SP' />
                    <div>
                        <span className='text-scogogray w-10 text-font11'>FE :</span>
                        <PartnerDetailsPopUp
                            itemList={feDisplayList}
                            userId={ticket.accepted_fe_id}
                            displayName={`${ticket?.fe_name ? ticket?.fe_name : ticket?.fe_mobile}`}
                            onHovertitle={`FE :  ${ticket?.fe_name ? ticket?.fe_name : ticket?.fe_mobile}`}
                        />
                    </div>
                </>
            );
        } else if (ticket.accepted_sp_id > 0 && ticket.accepted_fe_id > 0) {
            return (
                <>
                    <div>
                        <span className='text-scogogray w-10 text-font11'>SP :</span>
                        <PartnerDetailsPopUp
                            label='SP :'
                            itemList={spDisplayList}
                            userId={ticket.accepted_sp_id}
                            displayName={`${ticket?.sp_name}`}
                            onHovertitle={`SP :  ${ticket?.sp_name}`}
                        />
                    </div>
                    <div>
                        <span className='text-scogogray  text-font11 flex items-center w-full'>
                            FE :{' '}
                            {showInsuredIcon(ticket) && (
                                <IconToolTip title={'FE is insured for this ticket'}>
                                    <span className='material-icons text-scogo2e text-font16'>gpp_good</span>
                                </IconToolTip>
                            )}{' '}
                        </span>
                        <PartnerDetailsPopUp
                            itemList={feDisplayList}
                            userId={ticket.accepted_fe_id}
                            displayName={`${ticket?.fe_name ? ticket?.fe_name : ticket?.fe_mobile}`}
                            onHovertitle={`FE :  ${ticket?.fe_name ? ticket?.fe_name : ticket?.fe_mobile}`}
                        />
                    </div>
                </>
            );
        }
        return <></>;
    };
    function callOnRatingSubmit(formValues, ticket) {
        let payload = { userId: ticket.accepted_fe_id, fk_ticket_id: ticket.ticket_id, closeModal: true, formName: formValues.formName };
        if (formValues.work_quality_rating) payload.work_quality_rating = formValues.work_quality_rating;
        if (formValues.work_quality_comment) payload.work_quality_comment = formValues.work_quality_comment;
        if (formValues.sign_off_quality_rating) payload.sign_off_quality_rating = formValues.sign_off_quality_rating;
        if (formValues.sign_off_quality_comment) payload.sign_off_quality_comment = formValues.sign_off_quality_comment;
        dispatch(engineerRatingAndComment(payload));
    }
    const onSubmitClick = () => {
        let heading = `Rate Your Engineer : ${ticket.accepted_fe_id === ticket.accepted_sp_id ? ticket.sp_name : ticket?.fe_name}`;
        let modalComponent = <FeRating ticketId={ticket.ticket_id} callOnFormSubmit={(formValues) => callOnRatingSubmit(formValues, ticket)} />;

        let modalWidth = '50rem',
            modalHeight = 'auto';
        dispatch(openCustomModal({ modalComponent, heading, modalWidth, modalHeight }));
    };
    const workComment = () => {
        return <div className='grid grid-cols-1 p-2 gap-2 max-w-13'>{`${ticket.rating?.work_quality_comment ? ticket.rating?.work_quality_comment : 'No comments'}`}</div>;
    };
    const signOffComment = () => {
        return <div className='grid grid-cols-1 p-2 gap-2 max-w-13'>{`${ticket.rating?.work_quality_comment ? ticket.rating?.sign_off_quality_comment : 'No comments'}`}</div>;
    };
    return (
        <div className='w-full '>
            {getPartnerLabel(ticket)}
            {showAssignButton && <AssignButton ticket={ticket} />}
            {showRatingButton(ticket) ? (
                <IconToolTip title={'Rate your Engineer'}>
                    <span>
                        <SimpleButton
                            buttonIconComponent={<span className='material-icons text-font16 '>star_rate</span>}
                            btnClass='hover:bg-scogoorange flex items-center justify-center w-25 h-7 hover:text-white cursor-pointer text-scogoorange border-1 font-medium  border-scogoorange rounded-lg p-1 text-font11'
                            textOrComponent={'Engineer'}
                            onClick={onSubmitClick}
                        />
                    </span>
                </IconToolTip>
            ) : (
                <></>
            )}
            {ticket.rating?.work_quality_rating && (
                <MiniPopup
                    handleClick={() => { }}
                    popup={workComment()}
                    position='ml-auto'
                    className='origin-top-right absolute px-3 py-2 lg:top-6 top-4 mt-2 z-10 text-black rounded-md shadow-lg bg-white  max-h-50vh overflow-scroll'
                >

                    <span className='text-scogogray  text-font11 flex items-center pt-1 w-full'>
                        Work:
                        {Array.from({ length: 5 }, (_, index) => index + 1).map((starValue) => (
                            <span
                                className='material-icons text-font13 '
                                key={starValue}
                                style={starValue <= ticket.rating?.work_quality_rating ? { color: 'gold' } : { color: '#ccc' }}
                            >
                                star_rate
                            </span>
                        ))}
                    </span>

                </MiniPopup>)}
            {ticket.rating?.sign_off_quality_rating && (
                <MiniPopup
                    handleClick={() => { }}
                    popup={signOffComment()}
                    position='ml-auto'
                    className='origin-top-right absolute px-3 py-2 lg:top-6 top-4 mt-2 z-10 text-black rounded-md shadow-lg bg-white  max-h-50vh overflow-scroll'
                >

                    <span className='text-scogogray  text-font11 flex  w-full'>
                        Sign off:
                        {Array.from({ length: 5 }, (_, index) => index + 1).map((starValue) => (
                            <span
                                className='material-icons text-font13'
                                key={starValue}
                                style={starValue <= ticket.rating?.sign_off_quality_rating ? { color: 'gold' } : { color: '#ccc' }}
                            >
                                star_rate
                            </span>
                        ))}
                    </span>

                </MiniPopup>
            )}
            <AssingmentTypeLabel type={ticket.assignment_type} />
        </div>
    );
};

export const AssignButton = (props) => {
    const { ticket } = props;

    const { requestRaised, job_ticket_number, assignToScogo, accepted_sp_id, fk_assigned_primary_sp_id } = ticket;
    const { loggedUser } = useSelector((state) => state.auth);
    const role = loggedUser.role.id;
    const type = loggedUser.type;
    const dispatch = useDispatch();
    const assignActionName = {
        assign: 'Assign',
        reassign: 'ReAssign',
    };
    let actionName = '';


    if (((isCluster(role, type) || isPsp(role, type)) && fk_assigned_primary_sp_id === 0) || (requestRaised === 0 && fk_assigned_primary_sp_id === 0)) {
        actionName = assignActionName.assign;
    } else if (requestRaised === 1 || (isCustomerGroup(role, type) && assignToScogo === 1) || isSp(role, type) || (isPsp(role, type) && accepted_sp_id === loggedUser.id)) {
        actionName = assignActionName.reassign;
    }

    const onAssignClick = (ticket) => {
        let heading = (
            <span className='text-black'>
                {actionName} :&nbsp;<span className='text-scogobgsky text-font16'>{job_ticket_number}</span>
            </span>
        );
        let modalComponent = <AssignSP ticket={ticket} isReassign={actionName === assignActionName.reassign} />;
        let modalHeight = 'auto',
            modalWidth = '80rem';
        dispatch(openCustomModal({ modalComponent, heading, modalHeight, modalWidth }));
    };

    if (actionName === '') return <></>;
    return (
        <>
            <SimpleButton
                buttonIconComponent={<span className='material-icons text-font16'>manage_accounts</span>}
                btnClass='bg-white border flex items-center justify-center border-scogoprimary leading-6 cursor-pointer text-scogoprimary rounded-lg gap-1 font-medium cursor-pointer px-2 hover:bg-scogoprimary hover:text-white text-font11'
                textOrComponent={actionName}
                onClick={() => onAssignClick(ticket)}
            />
        </>
    );
};

const AssignToYou = ({ label }) => {
    return (
        <div>
            <span className='text-scogogray w-10 text-font11'>{label}:</span>
            <span className='w-11/12 line-clamp-2 text-scogobgsky cursor-pointer text-left text-font11 hover:text-scogoorange'> Assign To You</span>
        </div>
    );
};

const AssignToScogo = () => {
    return (
        <div >
            <span className='text-scogogray w-10   text-font11'>SP :</span>
            <span className='w-11/12 line-clamp-2 text-scogobgsky cursor-pointer text-left text-font11 hover:text-scogoorange'> SCOGO</span>
        </div>
    );
};

const AssingmentTypeLabel = ({ type }) => {
    if (!type) return <></>;
    return (
        <div className='my-1 ml-1 '>
            <span className={`bg-tagbg scogoprimary text-font10 px-2 py-1 rounded-3px max-w-full text-ellipsis truncate`}>{type}</span>
        </div>
    );
};
