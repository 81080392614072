import { takeLatest, takeEvery, put, call, all, select } from 'redux-saga/effects';
import { sp, loading, auth, user, toast, partners } from '../types';
import lambdas from '../lambdas/apis';
import { guid, numOfDaysBetween } from '../utils/utils';
import moment from 'moment';
import api from '../apis';
import { getLoggedUser } from '../selectors/users';
import { validateStringValue } from '../utils/common';
import { isCluster, isCustomerGroup, isPsp, isScm, isSuperVisor } from '../utils/role';

export function* sharePublicProfile(action) {
    const { userId, username } = action.payload;
    try {
        yield put({ type: loading.SHARE_PUBLIC_PROFILE_LOADING, payload: true });
        const response = yield call(lambdas.sp.sharePublicProfile, {}, { userId }, { username });
        if (response.data.code === 200) {
            yield put({ type: sp.SHARE_PUBLIC_PROFILE_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: sp.SHARE_PUBLIC_PROFILE_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: sp.SHARE_PUBLIC_PROFILE_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.SHARE_PUBLIC_PROFILE_LOADING, payload: false });
}

export function* getidCard(action) {
    const { userId } = action.payload;
    try {
        yield put({ type: loading.SHARE_ID_CARD_LOADING, payload: true });
        const response = yield call(lambdas.sp.getidCard, {}, { userId });
        if (response.data.code === 200) {
            yield put({ type: sp.SHARE_ID_CARD_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: sp.SHARE_ID_CARD_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: sp.SHARE_ID_CARD_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.SHARE_ID_CARD_LOADING, payload: false });
}

export function* getEligibleSPsList(action) {
    const { ticketId, type, dropdown = false, raisePo = false, getdetails, execution_date } = action.payload;
    try {
        const body = { ticket_id: ticketId };
        if (!['', undefined, null].includes(type)) body.type = type;
        yield put({ type: loading.IS_SP_DROPDOWN_LIST_LOADING, payload: true });
        const response = yield call(api.SP.listEligibleSPs, {}, {}, {}, body);
        if (response.data.code === 200) {
            let eligibleSPsList = [];
            let list = response.data.data;
            const loggedUser = yield select(getLoggedUser);
            list.sort((a, b) => {
                return b?.employee_type - a?.employee_type;
            });

            if (dropdown) {
                list.forEach((sp) => {
                    let mapIcon = { icon_name: 'place', color: 'scogogray' };
                    let activeIcon = { icon_name: 'lens', color: 'scogogray' };
                    if (sp.work_on_asset_type) activeIcon.color = 'scogoToastSuccess';
                    if (sp.work_on_cities) mapIcon.color = 'scogoToastSuccess';
                    let icons = { icon_name: '', color: '' };
                    icons.leftSide = [mapIcon, activeIcon];
                    let lastActive = { text: '', color: '' };
                    let active = false
                    if (sp.last_login && moment(sp.last_login).isValid()) {
                        lastActive.text = moment(sp.last_login).add(5.3, 'hours').fromNow();
                        let daysBeforeActive = numOfDaysBetween(new Date(), new Date(sp.last_login));
                        if (daysBeforeActive > 15) {
                            active = false
                            lastActive.color = 'scogoclosed';
                        } else {
                            active = true;
                            lastActive.color = 'scogoToastSuccess';
                        }
                    }
                    icons.rightSide = [lastActive];

                    eligibleSPsList.push({
                        key: guid(),
                        value: raisePo ? sp.customer_id : sp.id,
                        label: `${sp.sp_name && sp.sp_name !== '' ? `${sp.sp_name}, ${sp.first_name} ${sp.last_name}, ${sp.mobile}` : `${sp.first_name} ${sp.last_name}, ${sp.mobile}`
                            }`,
                        workAssetType: sp.work_on_asset_type ? 1 : 0,
                        icons,
                        sp_full_name: `${sp.sp_name && sp.sp_name !== '' ? `${sp.sp_name}, ${sp.first_name} ${sp.last_name}` : `${sp.first_name} ${sp.last_name}, $`}`,
                        sp_name: sp.sp_name,
                        customer_id: sp.customer_id,
                        vendor_code: sp.vendor_code,
                        customer_pan_card_number: sp.customer_pan_card_number,
                        customer_gst_number: sp.customer_gst_number,
                        ispTicketResponse: sp.ispTicketResponse,
                        employee_type: sp.employee_type,
                        active,
                        user: sp
                    });
                });

                eligibleSPsList.sort((a, b) => {
                    if (a?.employee_type?.startsWith("EMP") && !b?.employee_type?.startsWith("EMP")) {
                        return -1;
                    } else if (!a?.employee_type?.startsWith("EMP") && b?.employee_type?.startsWith("EMP")) {
                        return 1;
                    } else if (a?.workAssetType && !b?.workAssetType) {
                        return -1;
                    } else if (a?.workAssetType && b?.workAssetType) {
                        return 1;
                    } else if (a?.active && !b?.active) {
                        return -1;
                    } else if (a?.active && b?.active) {
                        return 1;
                    } else {
                        return b?.sp_full_name - a?.sp_full_name
                    }
                });

                let additionalList = [{
                    user: {
                        id: loggedUser.id,
                    }
                }]
                yield put({ type: sp.GET_ELIGIBLE_SP_DROPDOWN_LIST_SUCCESS, payload: [...additionalList, ...eligibleSPsList] });

                if (getdetails) {
                    let userIds = list.map((sp) => {
                        return sp.id
                    })
                    userIds.push(loggedUser.id)
                    yield put({ type: partners.GET_PARTNER_DETAILS_LIST_REQUEST, payload: { userIds, execution_date, accepted_ticket_count: true, forSp: true } });
                }
            } else {
                yield put({ type: sp.GET_ELIGIBLE_SP_LIST_SUCCESS, payload: response.data.data });
            }
        } else {
            yield put({ type: sp.GET_ELIGIBLE_SP_LIST_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: sp.GET_ELIGIBLE_SP_LIST_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.IS_SP_DROPDOWN_LIST_LOADING, payload: false });
}

export function* updateSPDetails(action) {
    const { form, spId } = action.payload;
    try {
        const response = yield call(api.SP.updateDetails, {}, {}, {}, form);
        if (response.data.code === 201) {
            yield all([
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: response.data.data.message } }),
                yield put({ type: sp.UPDATE_SP_DETAILS_SUCCESS, payload: response.data }),
            ]);
        } else {
            yield put({ type: sp.UPDATE_SP_DETAILS_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: sp.UPDATE_SP_DETAILS_FAILURE, payload: { message: error } });
    }
}

export function* updateStateDetail(action) {
    const { form, spId } = action.payload;
    try {
        const response = yield call(api.SP.updateStateDetails, {}, { spId }, {}, form);
        if (response.data.code === 201) {
            yield all([
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'State updated successfully.' } }),
                yield put({ type: sp.UPDATE_STATE_DETAIL_SUCCESS, payload: response.data }),
            ]);
        } else {
            yield put({ type: sp.UPDATE_STATE_DETAIL_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: sp.UPDATE_STATE_DETAIL_FAILURE, payload: { message: error } });
    }
}

export function* updateSpCustomerStatus(action) {
    const { customerId, status } = action.payload;
    try {
        const response = yield call(api.SP.updateCustomerStatus, {}, { customerId, status });
        if (response.data.code === 201) {
            yield all([
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Customer Status Updated successfully.' } }),
                yield put({ type: sp.UPDATE_SP_CUSTOMER_STATUS_SUCCESS, payload: action.payload }),
            ]);
        } else {
            yield put({ type: sp.UPDATE_SP_CUSTOMER_STATUS_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: sp.UPDATE_SP_CUSTOMER_STATUS_FAILURE, payload: { message: error } });
    }
}

export default function* spSaga() {
    yield takeEvery(sp.SHARE_PUBLIC_PROFILE_REQUEST, sharePublicProfile);
    yield takeEvery(sp.SHARE_ID_CARD_REQUEST, getidCard);
    yield takeEvery(sp.GET_ELIGIBLE_SP_LIST_REQUEST, getEligibleSPsList);
    yield takeLatest(sp.UPDATE_SP_DETAILS_REQUEST, updateSPDetails);
    yield takeLatest(sp.UPDATE_STATE_DETAIL_REQUEST, updateStateDetail);
    yield takeLatest(sp.UPDATE_SP_CUSTOMER_STATUS_REQUEST, updateSpCustomerStatus);
}
