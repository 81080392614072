import React from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';


const Tutorial = ({ handleDismiss, featureHeading, setSeachQuery, setIsSearching, setShowSearchedMessages, handlePrev, handleNext, stepCount, tutorialSteps }) => {

    const dispatch = useDispatch();

    let navigate = useNavigate();


    let openedSteps = tutorialSteps?.[stepCount];
    return (
        <>
            <div style={openedSteps?.style} className='z-10 absolute '>

                <span className={openedSteps?.styleSpan}></span>
                <div className='border-2 border-scogoprimary bg-darkgray drop-shadow-2xl flex flex-col gap-1 p-2 rounded-lg '>
                    <div className='blur-lg -z-10 rounded-b-full bg-scogoorange absolute w-12 h-12'></div>
                    <div className='flex'>
                        <span className='bg-scogoclosed  px-2 rounded-lg mx-1 text-white'>NEW</span>
                        <div className='text-scogoeee font-bold'>{featureHeading}</div>
                    </div>
                    <div className='text-scogoeee'>{stepCount < tutorialSteps.length ? tutorialSteps?.[stepCount].description : ''}</div>
                    <div className='flex justify-between  mt-6'>
                        <div
                            className='bg-scogoprimary text-scogoeee rounded-md pr-2  cursor-pointer hover:bg-scogobgsky'
                            onClick={handlePrev}
                            style={stepCount === 0 ? { visibility: 'hidden' } : { visibility: 'visible' }}
                        >
                            <ArrowBackIosNewIcon style={{ height: '10px' }} />
                            Prev
                        </div>

                        <div
                            className='bg-scogoprimary text-scogoeee rounded-md pl-2 cursor-pointer hover:bg-scogobgsky '
                            onClick={() => {
                                handleNext();
                                if (openedSteps?.onClick) {
                                    openedSteps?.onClick({ navigate, dispatch });
                                }
                            }}
                            style={stepCount === tutorialSteps.length - 1 ? { visibility: 'hidden' } : { visibility: 'visible' }}
                        >
                            Next
                            <ArrowForwardIosIcon style={{ height: '10px' }} />
                        </div>
                        {stepCount === tutorialSteps.length - 1 && <div
                            className='bg-scogoprimary text-scogoeee rounded-md px-2  cursor-pointer hover:bg-scogobgsky'
                            onClick={handleDismiss}
                            style={stepCount === 0 ? { visibility: 'hidden' } : { visibility: 'visible' }}
                        >

                            Dismiss
                        </div>}
                    </div>
                    <span className='m-1 '>
                        <div className='flex'>
                            {tutorialSteps.map((steps, index) => {
                                return (
                                    <>
                                        <div className={index > stepCount ? `w-full h-1 bg-scogogray` : ' w-full h-1 bg-scogoorange'}></div>
                                    </>
                                );
                            })}
                        </div>
                        <div className='text-scogoeee'>
                            {stepCount + 1}/{tutorialSteps.length}
                        </div>
                    </span>
                    <div className='blur-lg -z-10 rounded-b-full bg-scogoorange absolute w-12 h-12 right-1 bottom-1'></div>
                </div>
            </div>
        </>
    );
};
export default Tutorial;

export const chatTutorial = { key: 'chatTutorial' }
export const tutorialList = [chatTutorial]

export const handleTutorialDismiss = (key, value) => {
    localStorage.setItem(key, value)
}
