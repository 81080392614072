import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModalAction } from '../../actions/modal';
import Form from '../../common/Form';
import { useForm } from 'react-hook-form';
import Input from '../../common/Form/Input';
import Upload from '../../common/Form/FileUpload';
import { uploadAddressProofBack, uploadAddressProofBackAndFront } from '../../actions/users';
import { isValidAadhaar } from '../../utils/utils';
import IconToolTip from '../../common/IconToolTip';

const formName = 'upload_id_proof';
export const aadhar = { key: 'aadhar', value: 'Aadhar', label: 'Aadhar' };
export const driving_license = { key: 'driving_license', value: 'DL', label: 'Driving License' };

export default function UploadAddressProof({ onlyBackImageUpload }) {
    const defaultValues = {};
    const dispatch = useDispatch();
    const { formButtonLoading } = useSelector((state) => state.loading);
    const { loggedUser } = useSelector((state) => state.auth);

    const submit = (formValues) => {
        let payload = {
            addressProofType: 'Aadhar',
            closeMoadal: true,
            formName
        }
        if (onlyBackImageUpload) {
            payload.backFile = formValues.proofImageBack[0];
            payload.addressProofNumber = loggedUser.address_proof_number;
            payload.fileUrlFront = loggedUser.address_proof_url;
            dispatch(uploadAddressProofBack(payload));
        } else {
            payload = {
                addressProofType: 'Aadhar',
                addressProofNumber: formValues.addressProofNumber,
                proofImage: formValues.proofImage[0],
                closeMoadal: true,
                fileUrlBack: formValues.proofImageBack[0],
                formName
            }
            dispatch(uploadAddressProofBackAndFront(payload));
        }
    };

    const methods = useForm({ defaultValues, mode: 'all' });
    const { setValue } = methods;

    useEffect(() => {
        if (isValidAadhaar(loggedUser.address_proof_number)) {
            setValue('addressProofNumber', loggedUser.address_proof_number);
            setValue('addressProofType', aadhar);
        }
    }, [setValue, loggedUser])


    return (
        <Form
            methods={methods}
            onSubmit={submit}
            formName={formName}
            submitButtonText={'Upload'}
            onCancel={() => dispatch(closeModalAction())}
            showSubmit
            submitContainerClassName='flex gap-2 justify-end py-4 px-4'
            isLoading={formButtonLoading[formName]}
            showCancelButton={false}
        >
            <div className='px-4'>
                {!onlyBackImageUpload && <div className='flex gap-2'>
                    <Input
                        className='w-6/12'
                        name='addressProofNumber'
                        label='Adhaar Number'
                        required
                        validate={(aadhar) => {
                            if (aadhar === '') return true;
                            return isValidAadhaar(aadhar) || 'Invalid Aadhaar';
                        }}
                    />
                </div>}
                <div className='flex relative'>
                    {!onlyBackImageUpload && <Upload
                        className='w-full'
                        required
                        name='proofImage'
                        label='Aadhaar Front Image'
                        accept='image/jpeg,image/png'
                        validateFileType={(file) => {
                            const isImage = ['image/jpeg', 'image/png'].includes(file.type);
                            return isImage || `${file.name} is not an image`;
                        }}
                        rednderClass='w-full grid grid-cols-1 rows-fr grid-flow-row-dense'
                        renderFileDisplay={({ files, removeFile }) => {
                            if (files) {
                                return (
                                    <div className='pl-4 w-6/12'>
                                        {Array.from(files).map((file) => {
                                            let imageUrl = URL.createObjectURL(file);
                                            return (
                                                <div key={file.lastModified} className='h-48 w-48 relative'>
                                                    <IconToolTip title='Close'>
                                                        <span
                                                            onClick={() => removeFile(file)}
                                                            className='cursor-pointer material-icons text-black font-medium align-middle text-3xl hover:text-scogoclosed absolute right-0 top-0'
                                                        >
                                                            highlight_off
                                                        </span>
                                                    </IconToolTip>
                                                    <img className='object-cover h-48 w-48' src={imageUrl} alt={file.name} />
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            }
                            return <></>;
                        }}
                    />}
                    <Upload
                        className='w-full'
                        required
                        name='proofImageBack'
                        label='Aadhaar Back Image'
                        accept='image/jpeg,image/png'
                        validateFileType={(file) => {
                            const isImage = ['image/jpeg', 'image/png'].includes(file.type);
                            return isImage || `${file.name} is not an image`;
                        }}
                        rednderClass='w-full grid grid-cols-1 rows-fr grid-flow-row-dense'
                        renderFileDisplay={({ files, removeFile }) => {
                            if (files) {
                                return (
                                    <div className='pl-4 w-6/12 '>
                                        {Array.from(files).map((file) => {
                                            let imageUrl = URL.createObjectURL(file);
                                            return (
                                                <div key={file.lastModified} className='h-48 w-48 relative '>
                                                    <IconToolTip title='Close'>
                                                        <span
                                                            onClick={() => removeFile(file)}
                                                            className='cursor-pointer material-icons text-black font-medium align-middle text-3xl hover:text-scogoclosed absolute right-0 top-0'
                                                        >
                                                            highlight_off
                                                        </span>
                                                    </IconToolTip>
                                                    <img className='object-cover h-48 w-48  ' src={imageUrl} alt={file.name} />
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            }
                            return <></>;
                        }}
                    />
                </div>
            </div>
        </Form>
    );
}
