import React, { useEffect, useCallback, useState } from 'react';
import PoTable from '../components/Po/PoTable';
import Loader from '../../../common/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerIdOfLoginUser } from '../../../utils/utils';
import { Accordion, AccordionSummary, AccordionDetails } from '../../project/reusableComponents/AccordianForTeams';
import { getPoFilterData } from '../../../actions/payments';
import { getEndUsers } from '../../../actions/users';
import OverflowTip from '../../../common/OverflowTip';
import { validateNumberValue } from '../../../utils/common';
import NoResultFound from '../../../common/NoResultFound';

const defaultLimit = 10;


const RenderTableHead = () => {
    return <div className='bg-white relative border-1 border-gray-200 shadow-md w-full rounded-t-lg h-[5vh]'>
        <div className='text-[1.3rem] text-scogogray font-medium grid grid-cols-4 items-center py-2 w-full' style={{ width: '90vw', marginLeft: 'auto' }}>
            <span>Enduser</span>
            <span>Total PO</span>
            <span>Status</span>
            <span>Amount Details</span>
        </div>
    </div>
}

const RenderTableBody = ({ endUserList, handleScroll }) => {
    return <div className='shadow-md w-full rounded-lg border-gray-200 bg-white overflow-auto groupViewTableBody' onScroll={handleScroll}>
        {Array.isArray(endUserList) && endUserList.length > 0 ? endUserList.map((enduser) => {
            const { end_user_name, id, poData } = enduser;
            return <GroupCard end_user_name={end_user_name} id={id} poData={poData} />
        }) : <NoResultFound message={'No Enduser Found'} />}
    </div>
}

export const RenderPoGroupView = ({ searchQueryParam, setPoGroupView }) => {
    return <GroupView searchQueryParam={searchQueryParam} setGroupView={setPoGroupView} source={'pos'} TableHead={RenderTableHead} TableBody={RenderTableBody} />
}

export const GroupCard = ({ end_user_name, id, poData }) => {
    const [isOpen, setIsOpen] = useState(false);
    return <Accordion>
        <div className='flex justify-between w-full px-2 sticky top-0 bg-white border-1 z-50' onClick={() => setIsOpen(prevState => !prevState)}>
            <AccordionSummary sx={{ padding: 0, width: '100%', height: '5vh' }} >
                <div className='text-[1.3rem] text-scogogray font-medium grid grid-cols-4 items-center w-full' >
                    <span className='text-scogoprimary'>{end_user_name}</span>
                    <span className='text-scogoprimary'>{poData.totalPo}</span>
                    <span>
                        <div className='flex items-center gap-2 text-normal text-scogo2e'>
                            <div >Total Approved:</div>
                            <div >{validateNumberValue(poData.status.APPROVED)}</div>
                        </div>
                        <div className='flex items-center gap-2 text-normal text-scogoorange'>
                            <div >Total Pending:</div>
                            <div >{validateNumberValue(poData.totalPo) - validateNumberValue(poData.status.APPROVED)}</div>
                        </div>
                    </span>
                    <span>
                        <div className='flex items-center gap-2 text-normal'>
                            <div className='text-scogo99'>Base Amount:</div>
                            <div className='flex text-black'>
                                <OverflowTip someLongText={`₹ ${poData.amount.paidAmount?.toFixed(2)} / ${poData.amount.totalAmount?.toFixed(2)}`} />
                            </div>
                        </div>
                        <div className='flex items-center gap-2'>
                            <div className='text-scogo99'>Tax Amount:</div>
                            <div className='flex text-black'>
                                <OverflowTip someLongText={`₹ ${poData.amount.paidTax?.toFixed(2)} / ${poData.amount.totalTax?.toFixed(2)}`} />
                            </div>
                        </div>
                    </span>
                </div>
            </AccordionSummary>
        </div>
        <div className='bg-blue-100'>
            <AccordionDetails sx={{ padding: '0', paddingX: '0.8rem', paddingY: '0.8rem' }}>
                <RenderTable id={id} isOpen={isOpen} />
            </AccordionDetails>
        </div>
    </Accordion>;
}

const RenderTable = ({ id, isOpen }) => {
    const { enduserGroupedPoListLoadingData, isNextEnduserGroupedPoListLoadingData } = useSelector((state) => state.loading);
    const { groupPoListByEnduser, poCountByEnduserList, hasEndUserGroupedPoListEnd } = useSelector((state) => state.payments);
    const dispatch = useDispatch();
    const poList = groupPoListByEnduser[id] ? groupPoListByEnduser[id] : [];
    const isListEnd = hasEndUserGroupedPoListEnd[id];

    useEffect(() => {
        if (isOpen && groupPoListByEnduser && !groupPoListByEnduser[id]) {
            dispatch(getPoFilterData({ source: 'enduser_view', enduserId: id, limit: defaultLimit, page: 1, query: '', payload: { enduserIds: [id] } }));
        }
    }, [id, groupPoListByEnduser, dispatch, isOpen]);

    const onPoTableScroll = (e) => {
        const bottom = Math.abs(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight) < 10;
        if (bottom && !enduserGroupedPoListLoadingData[id] && !isNextEnduserGroupedPoListLoadingData[id] && !isListEnd) {
            const limit = defaultLimit;
            const page = Math.ceil(poList.length / limit) + 1;
            dispatch(getPoFilterData({ source: 'enduser_view', enduserId: id, limit, page, query: '', payload: { enduserIds: [id] } }));
        }
    };

    return <PoTable
        poTableList={poList}
        updateQueryFilters={() => { }}
        poCount={poCountByEnduserList[id] ? poCountByEnduserList[id] : 0}
        poTableLoading={enduserGroupedPoListLoadingData[id]}
        view='group_view'
        tableBodyHeight={'overflow-y-auto max-h-[25rem]'}
        onPoTableScroll={onPoTableScroll}
        isNextTableDataLoading={isNextEnduserGroupedPoListLoadingData[id]}
        isGroupView
    />
}

export function GroupView({ searchQueryParam, source, TableHead, TableBody, selectedSubTab, selectedSiteId }) {
    const dispatch = useDispatch();
    const { loggedUser } = useSelector((state) => state.auth);
    const { isEndusersLoading, isNextEndusersLoading } = useSelector((state) => state.loading);
    const { endUserList, hasEndUserListReachedEnd } = useSelector((state) => state.user);
    const loggedUserCustomerId = getCustomerIdOfLoginUser(loggedUser);

    const callEnduserList = useCallback(({ limit, page, customerId, query }) => {
        dispatch(getEndUsers({ customerId, limit, page, query, source }));
    }, [dispatch, source]);

    useEffect(() => {
        if (!endUserList) {
            callEnduserList({ limit: defaultLimit, page: 1, customerId: loggedUserCustomerId, query: searchQueryParam })
        }
    }, [dispatch, loggedUserCustomerId, callEnduserList, searchQueryParam, endUserList]);

    const handleScroll = (e) => {
        const bottom = Math.abs(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight) < 10;
        if (bottom && !isEndusersLoading && !isNextEndusersLoading && !hasEndUserListReachedEnd) {
            const limit = defaultLimit;
            const page = Math.ceil(endUserList.length / limit) + 1;
            callEnduserList({ limit, page, customerId: loggedUserCustomerId, query: searchQueryParam });
        }
    };

    if (isEndusersLoading) {
        return <div className='border border-white'>
            <Loader color='#F48A21' size='65' speed='1' thickness='3' margin='150px' />
        </div>
    }

    return <div className='groupViewTableContainer nonPaginatedTable overflow-hidden' >
        <TableHead />
        <TableBody handleScroll={handleScroll} endUserList={endUserList} selectedSubTab={selectedSubTab} selectedSiteId={selectedSiteId} />
        <div className='absolute bottom-8 left-0 right-0 z-50'>
            {isNextEndusersLoading && <Loader color='#F48A21' size='30' speed='1' thickness='4' />}
        </div>
    </div>
}