import React from 'react';
import { useSelector } from 'react-redux';
import IconToolTip from '../../../../common/IconToolTip';
import { getChatMainTabs, showApprovalOptionsinCardMessage, ticketsTab as ticketsTabMaster } from '../../ChatController';
import ApprovedRejectIcon from '../../components/ApprovedRejectIcon';

function CardMessage({ message, callStepFields }) {
    const { loggedUser } = useSelector((state) => state.auth);
    const { openedConversationId } = useSelector((state) => state.conversations);
    const getValue = ({ value, link, highlight }) => {
        let url,
            linkClasses = '';
        if (link?.to === 'ticket' && link.chatId) {
            const tabs = getChatMainTabs(loggedUser.role.id, loggedUser.type, {
                frontend_controller: loggedUser.frontend_controller,
                is_possible_tech_support: loggedUser.is_possible_tech_support,
            });
            const ticketsTab = tabs?.find?.((tab) => tab.tabID === ticketsTabMaster.tabID);
            let tabParam = '';
            if (ticketsTab?.subTabs?.[0]?.path) {
                tabParam = `?tab=${ticketsTab.subTabs[0].path}`;
            }
            url = `/chats/${link.chatId}${tabParam}`;
            linkClasses = 'hover:text-scogoprimary';
        }
        return (
            <a href={url} className={`${highlight ? 'text-scogoorange' : 'text-tickettext'} ${linkClasses} font-medium`}>
                {value}
            </a>
        );
    };

    let isApproved = undefined;
    let showApprovalOptions = showApprovalOptionsinCardMessage({ role: loggedUser?.role?.id, type: loggedUser?.type });
    let objectFromArrayOfResponse = undefined;
    let approver = undefined;
    let rejecter = undefined;

    const ArrayOfResponse = message?.promptResponses;

    if (Array.isArray(ArrayOfResponse) && ArrayOfResponse?.length > 0) {

        // objectFromArrayOfResponse = ArrayOfResponse.find((e) => {
        //     return e?.userId === loggedUser?.id;
        // });

        objectFromArrayOfResponse = ArrayOfResponse[0];

        if (objectFromArrayOfResponse) {
            isApproved = objectFromArrayOfResponse?.response;
            showApprovalOptions = false;
        }
    }

    if (isApproved === true) {
        approver = objectFromArrayOfResponse?.user;
    } else if (isApproved === false) {
        rejecter = objectFromArrayOfResponse?.user;
    }

    return (
        <>
            <div className='ml-auto rounded-10px border border-scogoeee mt-m-20 relative w-full'>
                <div className='p-p-10 bg-white'>
                    <div>
                        <p className='text-scogo15 font-medium 2xl:text-font15 text-font12 break-words'>{message.payload.title}</p>
                    </div>

                    {message.payload.content?.map(({ elementType, key, value, link, highlight }) => {
                        if (Array.isArray(value)) {
                            value = value.filter((x) => (x ? true : false));
                        }
                        const x = (k, v, l, h) => (
                            <div className=' mt-2 text-ellipsis overflow-hidden' key={k}>
                                <p className='text-scogogray font-normal 2xl:text-font13 text-font11'>
                                    {k}:&nbsp;
                                    <IconToolTip title={v}>{getValue({ value: v, link: l, highlight: h })}</IconToolTip>
                                </p>
                            </div>
                        );
                        if (Array.isArray(value)) {
                            return (
                                <div key={key} className='flex gap-2 justify-between'>
                                    {value.map(({ key: k, value: v, link: l, highlight: h }) => x(k, v, l, h))}
                                </div>
                            );
                        }
                        if (elementType === 'horizontal-divider') {
                            return <hr className='mt-3' />;
                        }
                        return x(key, value, link, highlight);
                    })}
                </div>
            </div>
            {
                message?.payload?.userPrompt === "yes-no" &&
                <div className='flex justify-end gap-2 p-2'>
                    <ApprovedRejectIcon showApprovalOptions={showApprovalOptions} approver={approver} rejecter={rejecter} isApproved={isApproved} conversationId={openedConversationId} messageId={message._id} isCardMessage={true} />
                </div>
            }
        </>
    );
}

export default CardMessage;
