import { useSelector } from "react-redux";
import ButtonScogoPrimary from "../../../../common/Buttons/ButtonScogoPrimary";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useState } from "react";
import { Input } from '@mui/material';
import { useDispatch } from "react-redux";
import { sendDataThroughWebsocket } from "../../../../actions/websocket";
import { websocketEvents } from "../../../../utils/websocket";
import { enableRoom, getUserCallStatusAction } from "../../../../actions/users";
import { closeModalAction } from "../../../../actions/modal";
import { defaultSingleChatPic } from "../../ChatController";
import { generateRoomLink } from "../../../videomeet/components/constants";
import { getActiveVoiceCall } from "../../../../actions/tickets";

const blockedList = ["alpesh@scogo.in", "karan@scogo.in", "monika@scogo.in", "mohit@scogo.in", "nitin@scogo.in"];

export const InviteUsersModal = ({ membersList = [], userId, ticketId, openedConversationId, ticket }) => {

    const { loggedUser } = useSelector(state => state.auth);

    const [selectedIds, setSelectedIds] = useState([]);

    const { videoMeetInviteUsers } = useSelector((state) => state.conversations);

    if (membersList.length === 0) {
        membersList = videoMeetInviteUsers;
    }

    membersList = membersList.filter(e => e?.userId !== userId);
    membersList = membersList.filter(e => !blockedList.includes(e?.email));


    const [filteredMembers, setFilteredMembers] = useState(membersList);

    const { isEnableVCUserLoading } = useSelector((state) => state.loading);

    const dispatch = useDispatch();

    const handleSelectOnChange = (id) => {
        if (selectedIds.includes(id)) {
            setSelectedIds(prev => prev.filter(e => e !== id))
        }
        else {
            setSelectedIds([...selectedIds, id]);
        }
    }

    const handleSelectAllOnChange = (e) => {
        if (e.target.checked) {
            setSelectedIds(filteredMembers.map(a => a.userId));
        }
        else {
            setSelectedIds([]);
        }
    }

    const handleInputOnChange = (e) => {
        const value = e.target.value;
        if (value) {
            setFilteredMembers(_ => membersList.filter(e => e.username.toLowerCase().includes(value.toLowerCase())));
        }
        else {
            setFilteredMembers(membersList);
        }
    }

    const refresh = () => {
        dispatch(getActiveVoiceCall());
        dispatch(getUserCallStatusAction({ userId: loggedUser?.id, status: [1, 2, 3, 4] }));
    }

    const handleOnSuccess = async ({ room_id }) => {
        try {
            if (selectedIds?.length > 0) dispatch(sendDataThroughWebsocket({ action: websocketEvents.chat.videoMeetInvite, data: { userIds: selectedIds, videoMeetDetails: { videoMeetLink: generateRoomLink(room_id, openedConversationId), ticketId: ticketId, room_id: room_id } } }))
            window.open(generateRoomLink(room_id, openedConversationId), "_blank");
            refresh();
            dispatch(closeModalAction());
        } catch (error) {
            console.log(error);
        }
    }

    const handleEnableRoom = () => {
        dispatch(enableRoom({ userId: userId, ticketId: ticketId, conversationId: openedConversationId, onSuccess: handleOnSuccess }));
    }

    return <div className='h-full'>
        <div className='h-[90%] overflow-y-scroll pl-5'>
            <FormGroup className="flex flex-col gap-4">
                <Input placeholder="Search" autoFocus onChange={handleInputOnChange} />
                <FormControlLabel key={`select-all`} control={<Checkbox onChange={handleSelectAllOnChange} checked={selectedIds.length === filteredMembers.length} />} label={<p className=" text-font14">{`Select All`}</p>} />
                {
                    filteredMembers?.length > 0 &&
                    filteredMembers.map((member) => {
                        return <FormControlLabel key={member.userId} control={<Checkbox checked={selectedIds.includes(member.userId) || selectedIds.length === filteredMembers.length} onChange={() => handleSelectOnChange(member.userId)} />} label={<div className="flex gap-3 items-center"><img src={`${member?.profilePic || defaultSingleChatPic}`} alt="profile_pic" className=" rounded-full h-[3rem] w-[3rem] object-cover" /><p className=" text-font14">{`${member.username}`}</p></div>} />
                    })
                }
            </FormGroup>
        </div>
        <div className='h-[10%] flex justify-end items-center p-5'>
            <ButtonScogoPrimary textOrComponent={`Start Call`} onClick={handleEnableRoom} loading={isEnableVCUserLoading} />
        </div>
    </div>
}