import { useState } from "react";
import { maxChatFileSizeInBytes } from "../pages/chat/ChatController";
import { sendToastMessage } from "../actions/toast";
import { useDispatch } from "react-redux";

export const useFileUploadOnClick = () => {

    const [selectedFiles, setSelectedFiles] = useState([]);

    const dispatch = useDispatch();

    const openFileExplorer = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.multiple = true;

        input.addEventListener('change', (event) => {
            const files = Array.from(event.target.files);
            if (files.map((file) => file.size <= maxChatFileSizeInBytes).includes(false)) {
                dispatch(sendToastMessage({ message: `File Size too Large`, status: 'danger' }));
            } else {
                setSelectedFiles(files);
            }
        });
        input.click();
    }

    return { selectedFiles, openFileExplorer, setSelectedFiles };
}
