import Cookies from 'universal-cookie';
import React from 'react';
import moment from 'moment';
import config from '../config';
import { useLocation, useNavigate } from 'react-router-dom';
import { isCustomerGroup } from '../utils/role';
import { gtag } from 'ga-gtag';
import axios from 'axios';
import production from '../config/env/production';

const cookies = new Cookies();

export const modulo = (a, n) => ((a % n) + n) % n;

export const getLookupWitHDefaultValue = ({ lookup, defaultValue }) => {
    let handler = {
        get: function (target, name) {
            return target.hasOwnProperty(name) ? target[name] : defaultValue;
        },
    };
    return new Proxy(lookup, handler);
};

export const excelTypes = [
    'application/vnd.ms-excel',
    'application/msexcel',
    'application/x-msexcel',
    'application/x-ms-excel',
    'application/x-excel',
    'application/x-dos_ms_excel',
    'application/xls',
    'application/x-xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/csv',
];

const isExcel = (mimeType) => excelTypes.includes(mimeType);

export const getFileType = (mimeType) => {
    const types = ['image', 'audio', 'video', 'text', 'pdf', 'xls'];
    let type = 'others';
    if (isExcel(mimeType)) {
        type = 'xls';
    } else {
        types.forEach((t) => {
            if (mimeType.includes(t)) {
                type = t;
            }
        });
    }
    return type;
};

export const uploadFileToS3WithSignedUrl = (url, file, headers = {}) => {
    return new Promise((resolve) => {
        const oReq = new XMLHttpRequest();
        oReq.open('PUT', url, true);
        oReq.onload = function (oEvent) {
            resolve(oEvent);
        };
        Object.entries(headers).map(([header, value]) => oReq.setRequestHeader(header, value));
        oReq.send(file);
    });
};
export const fileUploadOnChange = (payload) => {
    let url = `https://t1k.development.scogo.in/extract`
    if (config.name === production.name) {
        url = `https://t1k.production.scogo.in/extract`
    }
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Request-Method': 'POST',
        'Access-Control-Request-Headers': 'Content-Type'
    }
    axios.post(url, payload, { headers: headers })
        .then(response => {
            return JSON.parse(response.data);
        })
        .catch(error => {
            console.error('Error:', error)
        })
}

export const checkForHttpPrefix = (link) => {
    if (link.startsWith('http') || link.startsWith('https')) return link;
    else return `https://${link}`;
};

export const linkify = (str) => {
    const urlPattern =
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    const matches = str.match(urlPattern);
    const res = str.replace(urlPattern, '{{tobereplaced}}').split('{{tobereplaced}}');
    const linkified = res.slice(1).reduce(
        (acc, el, index) => {
            acc.push(
                <a className='text-scogoprimary underline' key={`link_${index}`} href={checkForHttpPrefix(matches[index])} rel='noopener noreferrer' target='_blank'>
                    {matches[index]}
                </a>
            );
            acc.push(el);
            return acc;
        },
        [res[0]]
    );
    return linkified;
};

export const getCodeBlocks = (text) => {
    const matches = text.match(/```(.|\n)+?```/g);
    if (!matches) return [text];
    const res = [];
    let prev = 0;
    matches.forEach((match) => {
        const segment = text.slice(prev, text.length);
        const cb = match.replace(/```\n*((.|\n)+)\n*```/, '$1');
        const cbIdx = segment.indexOf(match);
        const noncb = segment.slice(0, cbIdx);
        const next = cbIdx + match.length;
        prev = prev + next;
        if (!noncb.match(/^\s*$/)) res.push(noncb);
        res.push(<code className='text-scogoprimary border-1 border-scogoprimary mb-1'>{cb}</code>);
    });
    res.push(text.slice(prev, text.length));
    return res;
};

export const isEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const setJWTToken = ({ token, options }) => {
    const expires = new Date();
    expires.setDate(expires.getDate() + 30);
    options = { ...options, expires };
    cookies.set(`${config.name}-isLogged`, token ? 'yes' : 'no', { ...options, path: '/' });
    cookies.set(`${config.name}-authorization`, token, { ...options, path: '/' });
};

export const getJWTToken = () => {
    return cookies.get(`${config.name}-authorization`);
};

export const setWhatsappAlternateMobilePopup = (whatsappPoopUp = "yes") => {
    return cookies.set(`whatsappAlternateMobilePopup`, whatsappPoopUp);
};

export const isWhatsappPopupOpen = () => {
    return cookies.get(`whatsappAlternateMobilePopup`) !== "yes";
};

export const isLogged = () => {
    return cookies.get(`${config.name}-isLogged`) === 'yes';
};

export const getRootDomain = () => {
    return window.location.hostname.includes(config.rootDomain) ? `.${config.rootDomain}` : 'localhost';
};

export const convertSecToDays = (sec) => moment.duration(sec, 'seconds').asDays().toFixed(1);
export const convertDaysToSec = (days) => moment.duration(days, 'days').asSeconds().toFixed(1);

export const deepEqual = function (x, y) {
    if (x === y) {
        return true;
    } else if (typeof x == 'object' && x != null && typeof y == 'object' && y != null) {
        if (Object.keys(x).length !== Object.keys(y).length) return false;

        for (var prop in x) {
            if (y.hasOwnProperty(prop)) {
                if (!deepEqual(x[prop], y[prop])) return false;
            } else return false;
        }

        return true;
    } else return false;
};

export const actualValueForDropdown = (elem) => {
    if (!elem) return;
    if (typeof elem === 'object') return elem.value;
    else return elem;
};

export const isTruthyValue = (item) => {
    return !['', undefined, null, false].includes(item);
};

export const isLooksLikeId = (number) => {
    return !isNaN(number) && parseInt(number) > 0;
};

export const GoToUserDocuments = (document_folder_id) => {
    const navigate = useNavigate();

    navigate(`/documents?path=/${document_folder_id}`);

    return <></>;
};

export const getUserRole = (role, type) => {
    if (role === 4 && type !== 'ISP') return 'Customer Owner';
    else if (role === 4 && type === 'ISP') return 'ISP';
    else if (role === 6 && type === 'SCM') return 'SCM';
    else if (role === 6 && type === 'CLUSTER') return 'Cluster Lead';
    else if (role === 6 && type === 'SUPERVISOR') return 'Supervisor';
    else if (role === 8) return 'FE';
    else if (role === 12 && (type === 'SP' || type === 'SPISP')) return 'SP';
    else if (role === 12 && type === 'PSP') return 'PSP';
    else if (role === 12 && type === 'ISP') return 'ISP';
    else if (role === 14) return 'Customer Admin';
    else if (role === 15) return 'Customer User';
    else if (role === 16) return 'Customer Enduser';
    else if (role === 1) return 'Admin';
    return '';
};

export const elementCount = (count) => {
    if (!count) return '..';
    if (count > 99) return '99+';
    return count;
};

export const displayCount = (count) => {
    let result = count;
    if (!count) result = '...';
    if (count > 99) result = '99+';
    const unitLookup = { 3: 'K', 6: 'M', 9: 'G', 12: 'T' };
    for (let i = 3; i < 12; i += 3) {
        const significant = Math.floor(count / 10 ** i);
        if (count > 10 ** i) result = `${significant}${unitLookup[i]}`;
    }
    return result;
};

export const isSameArray = (a, b) => {
    let setA = new Set(a);
    return a.length === b.length && b.every((x) => setA.has(x));
};

export const getRemoteData = async (url) => {
    /* eslint-disable no-undef */
    let blob = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: { Origin: window.location.origin },
    }).then((r) => r.blob());
    /* eslint-enable no-undef */
    return blob;
};

export function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const GetQueryParam = (param, cast = (x) => x) => {
    const query = useQuery();
    if (param && query.get(param)) {
        const queryPath = query.get(param);
        return cast(queryPath);
    }
};

export const isArrayElementsSame = (arr) => {
    let setA = new Set(arr);
    return setA.size === 1;
};

const defaultFilterCheckAndUpdate = (updatedCurrentFilter) => {
    let allFilterLength;
    let filters = [];
    if (Array.isArray(updatedCurrentFilter)) {
        filters = updatedCurrentFilter.filter((filt) => filt.isSelected);
        allFilterLength = updatedCurrentFilter.length;
    }
    return { isChanged: filters.length !== allFilterLength, filters };
};

export const getFilterPayload = ({ filterSchema, filterList }) => {
    let payload = {};
    Object.values(filterSchema).map(({ filterName, submitKey, typeOfFilter }) => {
        let isFilterUpdated = true;
        let selectedFilters = [];
        if (typeOfFilter === 'checkbox') {
            if (Array.isArray(filterList[filterName])) {
                selectedFilters = filterList[filterName].filter((filt) => filt.isSelected);
                isFilterUpdated = selectedFilters.length !== filterList[filterName].length;
            }
        }
        let s = {};

        if (typeof submitKey === 'function') s = isFilterUpdated ? submitKey(selectedFilters) : {};
        else s = { [submitKey]: isFilterUpdated ? selectedFilters.map((selFilter) => selFilter.apiValue) : [] };

        payload = {
            ...payload,
            ...s,
        };
    });
    return payload;
};

export const isSomeFilterSelected = (filterList) => {
    let isSelected = false;
    filterList &&
        Object.entries(filterList).forEach(([filterKey, filterValues]) => {
            if (Array.isArray(filterValues)) {
                let currentSelectedValues = filterValues?.filter((value) => value?.isSelected);
                let isSelectedSomeValues = filterValues?.length > currentSelectedValues?.length;
                if (isSelectedSomeValues) isSelected = isSelectedSomeValues;
            }
        });
    return isSelected;
};

export const getInitialFilterList = (currentFilterList) => {
    let filters = {};
    Object.entries(currentFilterList).forEach(([filterName, filterValues]) => {
        let updatedValues = [];
        if (Array.isArray(filterValues)) {
            updatedValues = filterValues.map((filter) => {
                return { ...filter, isSelected: true };
            });
        }
        filters = {
            ...filters,
            [filterName]: updatedValues,
        };
    });
    return filters;
};

export const isValidJSON = (jsonString) => {
    try {
        JSON.parse(jsonString);
        return true;
    } catch (error) {
        return false;
    }
};

export const getDateDifference = (d1, d2) => {
    let date1 = new Date(d1);
    let date2 = new Date(d2);
    let diffTime = Math.abs(date2.getTime() - date1.getTime());
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return isNaN(diffDays) ? '' : diffDays;
};

export const getTimeDuration = (d1, d2) => {
    let date1 = moment(d1);
    let date2 = moment(d2);
    let daysBetween = date2.diff(date1, 'days');

    if (daysBetween <= 0) {
        let hours = date2.diff(date1, 'hours');
        if (hours > 0) return `${date2.diff(date1, 'hours')} Hrs`;
        return '';
    }
    return `${daysBetween} Days`;
};

export const isURL = (url) => {
    let urlPattern =
        /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    return urlPattern.test(url);
};

export const debounce = (func) => {
    let timer;
    return function (...args) {
        const context = this;
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            timer = null;
            func.apply(context, args);
        }, 500);
    };
};

export const validateStringValue = (value) => {
    if (value) return value;
    return '';
};

export const validateNumberValue = (number) => {
    if (!isNaN(parseFloat(number))) return parseFloat(number);
    return 0;
};

export const validateDateValue = (date) => {
    let timeStamp = Date.parse(date);
    if (isNaN(timeStamp)) return undefined;
    return date;
};

export const validateResponseDate = (date) => {
    if (!date) return undefined;
    let timeStamp = Date.parse(date);
    if (isNaN(timeStamp)) return undefined;
    if (date && typeof date === 'object') date = moment(date).format('YYYY-MM-DD HH:mm:ss');
    if (typeof date === 'string' && !date.endsWith('Z')) date = `${date}Z`;
    return new Date(date);
};

export const isValidNumber = (candNum, { allowFloats } = {}) => {
    let parsed = parseInt(candNum, 10);
    if (allowFloats) {
        parsed = parseFloat(candNum, 10);
    }
    if (!isNaN(parsed)) return parsed;
    return false;
};

export const removeDuplicates = (arr, compareKey = '_id') => {
    const { res } = arr.reduce(
        ({ encountered, res }, el) => {
            if (!encountered.has(el[compareKey])) {
                encountered.add(el[compareKey]);
                res.push(el);
            }
            return { encountered, res };
        },
        { encountered: new Set(), res: [] }
    );
    return res;
};

export const getFirstAndLastNameFromFullName = (full_name) => {
    let first_name,
        last_name = '';
    let split_name = full_name.split(' ');
    if (split_name.length === 1) {
        first_name = split_name[0];
    } else {
        first_name = full_name.split(' ').slice(0, -1).join(' ');
        last_name = full_name.split(' ').slice(-1).join(' ');
    }
    return { first_name, last_name };
};

export const getValidationIcons = (state) => {
    if (state === undefined) return <></>;
    if (state) return <span className='material-icons text-whatsappGreen  w-[2.5rem]'>check_circle_outline_icon</span>;
    return <span className='material-icons text-scogoclosed'>cancel_outline_icon</span>;
};

export const validateImageFile = (imageList) => {
    let isImage = false;
    if (imageList) {
        let image_type = imageList[0].type;
        if (image_type.includes('image/')) {
            isImage = true;
        }
    }
    return isImage;
};

export const checkImageDimensions = ({ image, maxWidth, maxHeight }) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = function () {
            if (this.width > maxWidth || this.height > maxHeight) {
                resolve(false);
            } else {
                resolve(true);
            }
        };
        img.onerror = function () {
            resolve(false);
        };
        img.src = URL.createObjectURL(image[0]);
    });
};
export const validateVideoFile = (videoList) => {
    let isVideo = false;
    if (videoList) {
        let video_type = videoList[0].type;
        if (video_type.includes('video/')) {
            isVideo = true;
        }
    }
    return isVideo;
};

export const formatCount = (count) => {
    if (count > 99) return '99+';
    return count;
};

export const getMediaCategory = (type) => {
    if (['image', 'video'].includes(type)) return 'images';
    else return 'docs';
};

export const getKeyNameForMediaList = (date) => {
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    date = new Date(date);
    let monthNum = date.getMonth();
    let month = monthNames[monthNum];
    let year = date.getFullYear();
    let key = `${month}, ${year}`;
    if (monthNum === new Date().getMonth() && year === new Date().getFullYear()) {
        key = `This Month`;
    }
    return key;
};

export const getAssetColor = (asset) => {
    let newRef = ['NEW', 'REFURBISHED'].includes(asset?.working_condition);
    if (asset?.status === 'AVAILABLE') {
        if (newRef) {
            return 'scogo2e hover:scogoDarkGreen';
        } else {
            return 'scogoclosed hover:scogoLightFillRed';
        }
    } else if (asset?.status === 'NOT AVAILABLE') {
        if (newRef) {
            return 'scogo99';
        } else {
            return 'lightRed';
        }
    } else if (asset?.status === 'TRANSIT_OUT' || asset?.status === 'TRANSIT_IN') {
        if (newRef) {
            return 'scogoBasicYellow';
        } else {
            return 'scogoorange';
        }
    } else if (asset?.status === 'DELIVERED') {
        if (newRef) {
            return 'scogoprimary';
        } else {
            return 'scogoclosed';
        }
    } else {
        return 'scogobgsky';
    }
};

export const getIsNotScogoPartner = (sp_under_customer) => {
    if (!sp_under_customer) return false;
    else {
        return sp_under_customer.find((sp) => sp.fk_customer_id !== 0 && sp.status === 0) ? true : false;
    }
};

export const getIsDisableActions = (loggedUser) => {
    let isNotScogoPartner = getIsNotScogoPartner(loggedUser.sp_under_customer);
    const role = loggedUser.role.id;
    const type = loggedUser.type;

    return ((loggedUser.is_enabled === 2 && loggedUser.disbaled_duration != null) || loggedUser.address_proof_verified !== 1) && !isCustomerGroup(role, type) && !isNotScogoPartner;
};

export const checkLatLong = (latlong) => {
    return !['', undefined, null, -1.0000000, '-1.0000000', 0.0000000, '0.0000000'].includes(latlong)
}
export const getSelectedItemFromDropdownList = (value, list) => {
    if (Array.isArray(list)) {
        return list.find((item) => item.value === value);
    }
    return null;
};

export const getMaskedString = (string) => {
    if (!string) return '';
    if (string?.length > 4) {
        return string.slice(-4).padStart(string.length, '*');
    }
    return string;
};

export const setGoogleEvents = (mainEvent, subEvent) => {
    gtag('event', mainEvent, {
        [subEvent]: [subEvent],
    });
}

export function convertToLakhOrCrore(number) {
    number = parseInt(number) || 0

    if (number >= 100000 && number < 10000000) {
        const lakh = number / 100000;
        return lakh.toFixed(2) + ' Lakhs';
    } else if (number >= 10000000) {
        const crore = number / 10000000;
        return crore.toFixed(2) + ' Cr';
    } else {

        return parseFloat(number) ? parseFloat(number).toFixed(2) : 0;
    }
}

export const isAffiliateLinkGenerated = (partner) => {
    return partner.affiliate_id;
}


const getOnLineStatus = () =>
    typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
        ? navigator.onLine
        : true;


export const useNavigatorOnLine = () => {
    const [status, setStatus] = React.useState(getOnLineStatus());

    const setOnline = () => setStatus(true);
    const setOffline = () => setStatus(false);

    React.useEffect(() => {
        window.addEventListener('online', setOnline);
        window.addEventListener('offline', setOffline);

        return () => {
            window.removeEventListener('online', setOnline);
            window.removeEventListener('offline', setOffline);
        };
    }, []);

    return status;
};

export const findPincode = (address) => {
    const pincodeRegex = /\b\d\s*\d\s*\d\s*\d\s*\d\s*\d\b/;
    const match = address.match(pincodeRegex);
    return match ? match[0] : '';
}

export const randomDelay = () => {
    const min = 500;
    const max = 2000;
    const stretchedRandom = Math.random() * 3; // Increase the multiplier to increase the gap
    const delay = Math.floor(stretchedRandom * (max - min + 1)) + min;
    return new Promise(resolve => setTimeout(resolve, delay));
};


export const formatSlaTime = (milliseconds) => {
    const hours = milliseconds / (1000 * 60 * 60);
    const minutes = milliseconds / (1000 * 60);
    const days = milliseconds / (1000 * 60 * 60 * 24);

    if (hours < 1) {
        return `${minutes.toFixed(2)} min`;
    } else if (hours >= 24) {
        return `${days.toFixed(2)} days`;
    } else {
        return `${hours.toFixed(2)} hrs`;
    }
}


export const filterExecutionDate = (date) => {

    const currentDate = new Date();
    const selectedDate = new Date(date);

    // Ensure the selected date and time is in the future
    if (selectedDate < currentDate) return false;


    const selectedHour = selectedDate.getHours();
    const selectedMinutes = selectedDate.getMinutes();

    if (selectedHour === 23) {
        return selectedMinutes === 0; // Allow 11:00 PM only
    }

    return selectedHour >= 7 && selectedHour < 23; // Allow between 7:00 AM and before 11:00 PM


}

export const requestLocation = (callback) => {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            async (position) => {
                const address = await getAddressFromCoordinates(position.coords.latitude, position.coords.longitude);
                if (callback) callback({ lat: position.coords.latitude, lng: position.coords.longitude, address })
            },
            (error) => {
                switch (error.code) {
                    case error.PERMISSION_DENIED:
                        console.log("User denied the request for Geolocation.");
                        break;
                    case error.POSITION_UNAVAILABLE:
                        console.log("Location information is unavailable.");
                        break;
                    case error.TIMEOUT:
                        console.log("The request to get user location timed out.");
                        break;
                    case error.UNKNOWN_ERROR:
                        console.log("An unknown error occurred.");
                        break;
                    default: {
                        console.log("An unknown error occurred.");
                        break;
                    }
                }
            }
        );
    } else {
        console.log("Geolocation is not supported by this browser.");
    }
};


const getAddressFromCoordinates = async (latitude, longitude) => {
    try {
        const response = await fetch(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&addressdetails=1`
        );
        const data = await response.json();
        return data;
    } catch (error) {
        console.log('Failed to fetch address from coordinates.')
    }
};