import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSpareCategories, getInventoryPartcodes, createInventorySpareAssets, findAllRepairCenters, getWareHouseDropdown, getEndUsersList } from '../../../../actions/inventory';
import { getCustomerIdOfLoginUser } from '../../../../utils/utils';
import { InventoryFormFields } from '../../InventoryController';
import { getCustomersList } from '../../../../actions/customer';
import moment from 'moment';
import { sendToastMessage } from '../../../../actions/toast';
import { actualValueForDropdown, isTruthyValue } from '../../../../utils/common';
import { AddSpareAssetInputs } from './AddSpareAssetInputs';
import { useForm } from 'react-hook-form';
import Form from '../../../../common/Form';
import { useCallback } from 'react';

export const warrantyType = [
    { key: 1, value: 'Manufacturing', label: 'Manufacturing' },
    { key: 2, value: 'Installation', label: 'Installation' },
    { key: 3, value: 'Billing', label: 'Billing' },
];
export const workingCondition = [
    { key: 1, value: 'NEW', label: 'New' },
    { key: 2, value: 'REFURBISHED', label: 'Refurbished' },
    { key: 3, value: 'FAULTY', label: 'Faulty' },
];

export const assetsReceivedAs = [
    { key: 0, value: 'NEW', label: 'New' },
    { key: 1, value: 'REPLACEMENT', label: 'Replacement' },
];

export default function SpareAssetFrom(props) {
    const formName = 'spareAssetForm';
    const dispatch = useDispatch();

    const { closeForm } = props;
    const { loggedUser } = useSelector((state) => state.auth);
    const customerId = getCustomerIdOfLoginUser(loggedUser);
    const { setInitialForm } = useSelector((state) => state.utils);
    const warehouseId = props?.selectedWarehouseId;

    const defaultValues = {
        customer_id: customerId,
        warehouse_id: warehouseId,
        asset_received_as: '',
        category: '',
        external_order_id: '',
        frn: '',
        grn: '',
        hasFrn: '',
        hasGrn: '',
        hasOem: '',
        hasWarranty: '',
        inward_source: '',
        make: '',
        model: '',
        oem_end_date: '',
        oem_id: '',
        oem_start_date: '',
        oem_warranty_type: '',
        partcode: '',
        quantity: '',
        serial_number: '',
        status: '',
        title: '',
        title_name: '',
        type: '',
        unit: '',
        value: '',
        warranty_end_date: '',
        warranty_start_date: '',
        warranty_type: '',
        working_condition: workingCondition[0],
        added_on: '',
    };

    const { formButtonLoading } = useSelector((state) => state.loading);

    const isToShowCustomerDropdown = InventoryFormFields('addNewSpareAssetCustomerField');

    const getDataOnMount = useCallback(() => {
        dispatch(getSpareCategories({ dropdown: true }));
        dispatch(getInventoryPartcodes({ customerId, dropdown: true }));
        dispatch(findAllRepairCenters({ customerId, dropdown: true }));
        if (isToShowCustomerDropdown) dispatch(getCustomersList());
        if (!warehouseId) dispatch(getWareHouseDropdown({ customerId }));
    }, [customerId, dispatch, isToShowCustomerDropdown, warehouseId]);

    useEffect(() => {
        getDataOnMount();
    }, [getDataOnMount]);

    useEffect(() => {
        dispatch(getEndUsersList({}))
    }, [dispatch]);

    const submit = (formValues) => {
        const customer_id = actualValueForDropdown(formValues.customer_id);
        const warehouse_id = actualValueForDropdown(formValues.warehouse_id);

        let singleSpareAsset = {
            asset_received_as: actualValueForDropdown(formValues.asset_received_as),
            category: actualValueForDropdown(formValues.category),
            customer_id,
            external_order_id: formValues.external_order_id,
            hasFrn: formValues.hasFrn,
            hasGrn: formValues.hasGrn,
            hasOem: formValues.hasOem,
            hasWarranty: formValues.hasWarranty,
            inward_source: formValues?.inward_source?.label,
            oem_end_date: formValues.oem_end_date ? moment(formValues.oem_end_date).format('YYYY-MM-DD') : '',
            oem_id: formValues.oem_id,
            oem_start_date: formValues.oem_start_date ? moment(formValues.oem_start_date).format('YYYY-MM-DD') : '',
            oem_warranty_type: formValues.oem_warranty_type,
            warranty_end_date: formValues.warranty_end_date ? moment(formValues.warranty_end_date).format('YYYY-MM-DD') : '',
            warranty_start_date: formValues.warranty_start_date ? moment(formValues.warranty_start_date).format('YYYY-MM-DD') : '',
            warranty_type: actualValueForDropdown(formValues.warranty_type),
            working_condition: actualValueForDropdown(formValues.working_condition),
            warehouse_id,
            partcode_name: formValues.partcode_name,
            category_name: formValues.category_name,
            title_name: formValues.title_name,
            added_on: formValues.added_on ? moment(formValues.added_on).format('YYYY-MM-DD') : '',
            enduser_id: actualValueForDropdown(formValues.enduser_id),
        };

        Object.entries(singleSpareAsset).forEach(([key, value]) => {
            if (!isTruthyValue(value)) delete singleSpareAsset[key];
        });

        singleSpareAsset = Object.assign(
            {
                frn: formValues.frn,
                grn: formValues.grn,
                make: formValues.make,
                model: formValues.model,
                type: formValues.type,
                unit: formValues.unit,
                value: formValues.value,
                partcode: actualValueForDropdown(formValues.partcode),
                serial_number: formValues.serial_number,
                title: actualValueForDropdown(formValues.title),
                quantity: formValues.quantity,
                status: formValues.status,
            },
            singleSpareAsset
        );

        let data = { spareassets: [singleSpareAsset] };

        const hasValidCustomerId = Number.isInteger(customer_id) && customer_id > 0;
        const hasValidWarehouseId = Number.isInteger(warehouse_id) && warehouse_id > 0;

        if (hasValidCustomerId && hasValidWarehouseId) {
            dispatch(createInventorySpareAssets({ data, customerId, warehouseId, formName }));
        } else {
            dispatch(sendToastMessage({ status: 'danger', message: 'Invalid Data' }));
        }
    };

    const methods = useForm({ defaultValues, mode: 'all' });
    const { reset } = methods;

    useEffect(() => {
        if (setInitialForm) {
            reset();
        }
    }, [setInitialForm, reset, dispatch])

    return (
        <Form
            className='w-full'
            onSubmit={submit}
            onCancel={closeForm}
            submitButtonText={'Create'}
            showSubmit
            submitContainerClassName='flex gap-2 py-4 px-4'
            isLoading={formButtonLoading[formName]}
            methods={methods}
        >
            <AddSpareAssetInputs warehouseId={warehouseId} isToShowCustomerDropdown={isToShowCustomerDropdown} />
        </Form>
    );
}
