import { takeEvery, call, put, takeLatest, all, select } from 'redux-saga/effects';
import { inventory, loading, toast, modal, utils } from '../../types';
import microservices from '../../lambdas/microservices';
import { uploadFileToS3WithSignedUrl } from '../../utils/common';
import { getCustomerIdOfLoginUser } from '../../utils/utils';
import OpenTickets from '../../pages/Modals/Inventory/OpenTickets';
import lambdas from '../../lambdas/apis';
import { getLoggedUser } from '../../selectors/users';
import { getInventoryFilterData } from '../../selectors/inventory';
import { inventory as inventoryPath } from '../../router/roleWisePageRoute';
import { assets as assetsTab, spareAssets as spareAssetsTab } from '../../pages/Inventory/InventoryController';
import { assetGroupView } from '../../pages/Inventory/Inventory';

export function* getAllAssets(action) {
    const { customerId, siteId, limit, page, count = true, archive, tag, boqId, dropdown, status, callPoList = false, source, endUserId } = action.payload;
    let queryParams = { limit, page, count, archive, giveLastTickets: true };
    if (!['', undefined, null, 0].includes(customerId)) queryParams.customerId = customerId;
    if (!['', undefined, null, 0].includes(siteId)) queryParams.siteId = siteId;
    if (!['', undefined, null, 0].includes(tag)) queryParams.tag = tag;
    if (!['', undefined, null, 0].includes(endUserId)) queryParams.endUserId = endUserId;
    if (status) queryParams.status = status;
    if (dropdown) queryParams.dropdown = dropdown;
    try {
        if (source === 'enduser_view') {
            if (page > 1) {
                yield put({ type: loading.SET_NEXT_ALL_ASSETS_GROUP_BY_ENDUSER_LOADING, payload: { id: endUserId, isLoading: true } });
            } else {
                yield put({ type: loading.SET_ALL_ASSETS_GROUP_BY_ENDUSER_LOADING, payload: { id: endUserId, isLoading: true } });
            }
        } else {
            yield put({ type: loading.GET_ALL_ASSETS_LOADING, payload: true });
        }
        const response = yield call(microservices.inventory.getAllAssets, {}, {}, queryParams);
        if (response.data.code === 200) {
            if (response.data.data.length > 0 && callPoList) {
                const siteIds = [...new Set(response.data.data.map((item) => item.fk_site_id))];
                const s = source === 'enduser_view' ? 'enduser_view' : 'assets';
                yield put({ type: inventory.GET_SITE_POS_LIST_REQUEST, payload: { siteIds, source: s, enduserId: endUserId } });
            }
            if (count) {
                yield put({ type: inventory.ALL_ASSETS_COUNT_SUCCESS, payload: response.data.count });
            }
            if (!['', undefined, null].includes(tag)) {
                const payload = { ...response.data, boqId };
                yield put({ type: inventory.GET_TAG_ASSETS_SUCCESS, payload: payload });
            } else {
                yield put({ type: inventory.GET_ALL_ASSETS_SUCCESS, payload: { data: response.data.data, source, endUserId, page } });
            }
        } else {
            yield put({ type: inventory.GET_ALL_ASSETS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: inventory.GET_ALL_ASSETS_FAILURE, payload: { message: error } });
    }
    if (source === 'enduser_view') {
        if (page > 1) {
            yield put({ type: loading.SET_NEXT_ALL_ASSETS_GROUP_BY_ENDUSER_LOADING, payload: { id: endUserId, isLoading: false } });
        } else {
            yield put({ type: loading.SET_ALL_ASSETS_GROUP_BY_ENDUSER_LOADING, payload: { id: endUserId, isLoading: false } });
        }
    } else {
        yield put({ type: loading.GET_ALL_ASSETS_LOADING, payload: false });
    }
}

export function* getAllAssetsForFilter(action) {
    const { customerId, siteId, limit, page, count, toFilter = [] } = action.payload;
    try {
        yield put({ type: loading.GET_ALL_FILTER_ASSETS_LOADING, payload: true });
        const response = yield call(microservices.inventory.getAllAssets, {}, {}, { customerId, siteId, limit, page, count, toFilter: toFilter.join(','), filter: true });
        yield put({ type: loading.GET_ALL_FILTER_ASSETS_LOADING, payload: false });
        if (response.data.code === 200) {
            yield put({ type: inventory.GET_ALL_FILTER_ASSETS_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: inventory.GET_ALL_FILTER_ASSETS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: inventory.GET_ALL_FILTER_ASSETS_FAILURE, payload: { message: error } });
    }
}

export function* createInventoryAssets(action) {
    const { data, customerId, siteId, formName, refresh = true, onSuccess = () => { } } = action.payload;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    try {
        const response = yield call(microservices.inventory.createAssets, {}, {}, { customerId }, data);
        let toastData = {
            status: 'success',
            message: `Asset Created Successfully`,
        };
        if (response.data.code === 200) {
            yield all([
                yield put({ type: toast.SEND_TOAST, payload: toastData }),
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: utils.SET_INITIAL_FORM, payload: { reinitialize: false, date: Date.now() } }),
                yield put({ type: inventory.CREATE_INVENTORY_ASSETS_SUCCESS, payload: response.data.data[0] }),
                onSuccess(response.data.data)
            ]);
            if (refresh) {
                if (window.location.pathname === inventoryPath.path) {
                    yield put({ type: inventory.REFRESH_ASSET_PAGE, payload: {} });
                }
            }
        } else {
            let message = response.data.message ? response.data.message : response.data.data && response.data.data.message ? response.data.data.message : 'Something Went Wrong!!!';
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message } });
            yield put({ type: inventory.CREATE_INVENTORY_ASSETS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: inventory.CREATE_INVENTORY_ASSETS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* deleteInventoryAsset(action) {
    const { assetId, siteId, customerId, formName, refresh = true, enduserId } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(microservices.inventory.deleteAsset, {}, { assetId });
        if (response.data.code === 200) {
            let message = 'Asset Deleted Successfully!!!',
                status = 'success';
            if (response.data.tickets && Array.isArray(response.data.tickets) && response.data.tickets.length > 0) {
                status = 'danger';
                message = 'Assets have open ticket(s)';
            }
            yield all([yield put({ type: modal.CLOSE_MODAL }), yield put({ type: toast.SEND_TOAST, payload: { message, status } })]);
            if (refresh) {
                yield put({ type: inventory.REFRESH_ASSET_PAGE, payload: { enduserId } });
            }
        } else {
            yield all([yield put({ type: toast.SEND_TOAST, payload: { message: response.data.data.message, status: 'danger' } })]);
            yield put({ type: inventory.DELETE_INVENTORY_ASSET_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: inventory.DELETE_INVENTORY_ASSET_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* updateInventoryAsset(action) {
    const { data, assetId, siteId, customerId, formName, refresh = true, closeModal = true, enduserId } = action.payload;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    let status = '',
        message = '';
    try {
        const response = yield call(microservices.inventory.updateAsset, {}, { assetId }, {}, data);
        if (response.data.code === 200) {
            status = 'success';
            message = response.data.data && response.data.data.message ? response.data.data.message : 'Asset Updated Successfully!!!';
            if (closeModal) {
                yield put({ type: modal.CLOSE_MODAL })
            }
            yield put({ type: toast.SEND_TOAST, payload: { status, message } });
            if (refresh) {
                yield put({ type: inventory.REFRESH_ASSET_PAGE, payload: { enduserId } });
            }
        } else {
            yield put({ type: inventory.UPDATE_INVENTORY_ASSET_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: inventory.UPDATE_INVENTORY_ASSET_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* migrateAssets(action) {
    let { data, warehouseId, siteId, formName, status, count, refresh = true, addingNewSite, createNewSiteFormData, enduserId } = action.payload;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    try {
        let createSitesResponse;
        if (addingNewSite) {
            createSitesResponse = yield call(microservices.inventory.createInventorySites, {}, {}, {}, { sites: createNewSiteFormData });
            if (createSitesResponse.data.code === 200) {
                data.destination.destinationId = createSitesResponse.data.data[0].id;
            }
        }
        const response = yield call(microservices.inventory.migrateAsset, {}, {}, {}, data);
        if (response.data.code === 200) {
            let toastStatus = 'success',
                message = 'Asset Migration Ticket Created!!!';
            yield all([yield put({ type: modal.CLOSE_MODAL }), yield put({ type: toast.SEND_TOAST, payload: { status: toastStatus, message } })]);
            if (data.source && data.source.from && data.source.from === 'WAREHOUSE') {
                yield put({ type: inventory.REFRESH_SPAREASSET_PAGE, payload: {} });
            }
            if (data.source && data.source.from && data.source.from === 'SITE' && refresh) {
                yield put({ type: inventory.REFRESH_ASSET_PAGE, payload: { enduserId } });
            }
        } else {
            let toastStatus = 'danger',
                message = response.data.data.message;
            yield all([yield put({ type: modal.CLOSE_MODAL }), yield put({ type: toast.SEND_TOAST, payload: { status: toastStatus, message } })]);
            yield put({ type: inventory.MIGRATE_INVENTORY_ASSET_FAILURE, payload: { message: 'Oops Something went wrong' } });
            if (response.data.tickets.length > 0) {
                yield put({
                    type: modal.OPEN_CUSTOM_MODAL,
                    payload: {
                        modalComponent: <OpenTickets tickets={response.data.tickets} />,
                        heading: 'Open Tickets',
                        modalWidth: '40rem',
                        modalHeight: 'auto',
                    },
                });
            }
        }
    } catch (error) {
        yield put({ type: inventory.MIGRATE_INVENTORY_ASSET_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* scrapAssets(action) {
    const { data, formName, refresh, siteId, customerId, warehouseId } = action.payload;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });

    try {
        const response = yield call(microservices.inventory.scrapAssets, {}, {}, {}, data);
        if (response.data.code === 200) {
            let status = 'success',
                message = 'Scrap Assets Ticket Created!!!';

            yield all([yield put({ type: modal.CLOSE_MODAL }), yield put({ type: toast.SEND_TOAST, payload: { status, message } })]);
            if (refresh === 'assets' && refresh) {
                yield put({ type: inventory.REFRESH_ASSET_PAGE, payload: {} });
            }
            if (refresh === 'scrapAssets') {
                yield put({ type: inventory.GET_ALL_SPARE_ASSETS_REQUEST, payload: { warehouseId, customerId, status: 'ALL' } });
            }
        } else {
            let status = 'danger',
                message = response.data.data.message;
            yield all([yield put({ type: modal.CLOSE_MODAL }), yield put({ type: toast.SEND_TOAST, payload: { status, message } })]);
            yield put({ type: inventory.SCRAP_INVENTORY_ASSETS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
        if (response.data.tickets.length > 0) {
            yield put({
                type: modal.OPEN_CUSTOM_MODAL,
                payload: {
                    modalComponent: <OpenTickets tickets={response.data.tickets} />,
                    heading: 'Open Tickets',
                    modalWidth: '40rem',
                    modalHeight: 'auto',
                },
            });
        }
    } catch (error) {
        yield put({ type: inventory.SCRAP_INVENTORY_ASSETS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* replaceAssets(action) {
    const { data, formName } = action.payload;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    try {
        const response = yield call(microservices.inventory.replaceAsset, {}, {}, {}, data);
        if (response.data.code === 200) {
            let status = 'success',
                message = 'Asset Replacement Ticket Created!!!';
            yield all([yield put({ type: modal.CLOSE_MODAL }), yield put({ type: toast.SEND_TOAST, payload: { status, message } })]);
        } else {
            let status = 'danger',
                message = response.data.data.message;
            yield all([yield put({ type: modal.CLOSE_MODAL }), yield put({ type: toast.SEND_TOAST, payload: { status, message } })]);
            yield put({ type: inventory.REPLACE_INVENTORY_ASSET_FAILURE, payload: { message: 'Oops Something went wrong' } });

            if (response.data.tickets.length > 0) {
                yield put({
                    type: modal.OPEN_CUSTOM_MODAL,
                    payload: {
                        modalComponent: <OpenTickets tickets={response.data.tickets} />,
                        heading: 'Open Tickets',
                        modalWidth: '40rem',
                        modalHeight: 'auto',
                    },
                });
            }
        }
    } catch (error) {
        yield put({ type: inventory.REPLACE_INVENTORY_ASSET_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* generateAssetBarcode(action) {
    const { data, formName } = action.payload;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });

    try {
        const response = yield call(microservices.inventory.generateAssetBarcode, {}, {}, {}, data);
        if (response.data.code === 200) {
            let status = 'success',
                message = response.data.data && response.data.data.message ? response.data.data.message : 'Assets QR Codes Generated';

            yield all([yield put({ type: modal.CLOSE_MODAL }), yield put({ type: toast.SEND_TOAST, payload: { status, message } })]);
            if (!['', undefined, null].includes(response.data.downloadUrl)) {
                window.open(response.data.downloadUrl, '_blank');
            }
        } else {
            let status = 'danger',
                message = response.data.data.message;

            yield all([yield put({ type: modal.CLOSE_MODAL }), yield put({ type: toast.SEND_TOAST, payload: { status, message } })]);
            yield put({ type: inventory.GENERATE_INVENTORY_ASSETS_BARCODE_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: inventory.GENERATE_INVENTORY_ASSETS_BARCODE_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* bulkUploadAssets(action) {
    const { data, customerId, endUserId, siteId, limit = 10, page = 1, count = false, formName, refresh = true } = action.payload;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    try {
        const response = yield call(microservices.inventory.bulkUploadAssets, { 'Content-Type': 'multipart/form-data' }, {}, { customerId }, data);
        if (response.data.code === 200) {
            yield call(uploadFileToS3WithSignedUrl, response.data.data.signedUrl, data.file, { 'Content-Type': 'text/csv' });
            yield all([yield put({ type: modal.CLOSE_MODAL }), yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Assets will be Uploaded!!!' } })]);
            if (refresh) {
                yield put({ type: inventory.REFRESH_ASSET_PAGE, payload: {} });
            }
        } else {
            yield put({ type: inventory.BULK_UPLOAD_INVENTORY_ASSETS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
        if (response.data.tickets && Array.isArray(response.data.tickets) && response.data.tickets.length > 0) {
            let data = { modalType: 'assetsOpenTickets', tickets: response.data.tickets };
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
            yield put({ type: modal.CLOSE_MODAL });
        }
    } catch (error) {
        yield put({ type: inventory.BULK_UPLOAD_INVENTORY_ASSETS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* getAllAssetsDropdown(action) {
    const { customerId, siteId, limit, page, count, archive, finance, lastScannedId, financeType, act, dropdown, reconcile = false } = action.payload;
    const queryParams = { customerId, siteId, limit, page, count, archive, finance, lastScannedId, financeType, act, dropdown };

    try {
        yield put({ type: loading.GET_DROPDOWN_REPLACE_ALL_ASSETS_LOADING, payload: true });
        const response = yield call(microservices.inventory.getAllAssets, {}, {}, queryParams);
        if (response.data.code === 200) {
            if (reconcile) {
                yield put({ type: inventory.GET_ALL_ASSETS_SUCCESS, payload: { data: response.data.data } });
            } else {
                let replaceAssetDropdown = [];
                Array.isArray(response.data.data) &&
                    response.data.data.forEach((asset) => {
                        const assetType = asset.assetType && asset.assetType.title ? asset.assetType.title : '';
                        const serial = asset.serial_number ? `(${asset.serial_number})` : '';
                        replaceAssetDropdown.push({
                            key: asset.id,
                            value: asset.id,
                            label: `${assetType} ${serial}`,
                        });
                    });
                yield put({ type: inventory.GET_DROPDOWN_REPLACE_ALL_ASSETS_SUCCESS, payload: replaceAssetDropdown });
            }
        } else {
            yield put({ type: inventory.GET_DROPDOWN_REPLACE_ALL_ASSETS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: inventory.GET_DROPDOWN_REPLACE_ALL_ASSETS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.GET_DROPDOWN_REPLACE_ALL_ASSETS_LOADING, payload: false });
}

export function* addAssetsMaster(action) {
    const { data, addForAssets = false, customerId, siteId, formName } = action.payload;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    try {
        const response = yield call(lambdas.operation.addAssetsMaster, {}, {}, {}, data);
        if (response.data.code === 201) {
            yield all([
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Asset Added Successfully' } }),
                yield put({ type: inventory.ADD_ASSETS_SUCCESS, payload: response.data.data }),
            ]);
            if (!addForAssets) {
                yield put({ type: modal.CLOSE_MODAL });
            }
        } else {
            yield all([yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Asset not added!!!' } }), yield put({ type: modal.CLOSE_MODAL })]);
        }
    } catch (error) {
        yield put({ type: inventory.ADD_ASSETS_FAILURE, payload: error });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* getAssetHistory(action) {
    const { assetId, assetSource } = action.payload;
    yield put({ type: loading.ASSEST_HISTORY_LOADING, payload: true });
    try {
        const response = yield call(microservices.inventory.assetHistory, {}, { assetId }, { type: assetSource });
        if (response.data.code === 200) {
            yield put({ type: inventory.GET_ASSETS_HISTORY_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: inventory.GET_ASSETS_HISTORY_SUCCESS, payload: [] });
            yield put({ type: inventory.GET_ASSETS_HISTORY_FAILURE, payload: 'Oops Something went wrong' });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
        }
    } catch (error) {
        yield put({ type: inventory.GET_ASSETS_HISTORY_FAILURE, payload: error });
    }
    yield put({ type: loading.ASSEST_HISTORY_LOADING, payload: false });
}

export function* getAssetsFilterList(action) {
    try {
        const { module, tab, projectId, customerId, toFilter = '' } = action.payload;
        let queryParams = { module, tab };
        if (!['', 'all', undefined, null].includes(projectId)) queryParams.projectId = projectId;
        if (!['', 'all', undefined, null].includes(customerId)) queryParams.customerId = customerId;
        if (toFilter) queryParams.toFilter = toFilter;

        yield put({ type: loading.GET_ALL_FILTER_ASSETS_LOADING, payload: true });
        const response = yield call(microservices.inventory.getAllAssetFilters, {}, {}, queryParams);
        if (response.data.code === 200) {
            yield put({ type: inventory.GET_ASSETS_FILTER_LIST_SUCCESS, payload: response.data.data });
        } else if ([400, 500].includes(response.data.code)) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
        }
    } catch (err) {
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something Went Wrong' } });
    }
    yield put({ type: loading.GET_ALL_FILTER_ASSETS_LOADING, payload: false });
}

export function* refreshAssetPage(action) {
    const { enduserId } = action.payload;
    const { limit = 10, page = 1, query, filterPayload = {}, siteId, archive, isFilterActive } = yield select(getInventoryFilterData);
    const loggedUser = yield select(getLoggedUser);
    const customerId = getCustomerIdOfLoginUser(loggedUser);
    const isFilterOrSearchActive = isFilterActive || query?.length > 0;
    const isAssetGroupView = new URLSearchParams(window.location.search).get(assetGroupView);
    try {
        if (isAssetGroupView && enduserId) {
            yield put({ type: loading.SET_ALL_ASSETS_GROUP_BY_ENDUSER_LOADING, payload: { id: enduserId, isLoading: true } });
        } else {
            yield put({ type: loading.GET_ALL_ASSETS_LOADING, payload: true });
        }
        let response;
        if (isFilterOrSearchActive) {
            let queryParams = {
                module: 'INVENTORY',
                tab: 'assets',
                limit,
                page,
                next: true,
                count: true,
                type: 0,
                giveLastTickets: true,
                giveAssetTypes: true,
                giveSpareAssetsStats: true,
                archive,
            };
            if (!['', 'all', undefined, null, 0].includes(customerId)) queryParams.customerId = customerId;
            if (!['', 'all', undefined, null, 0].includes(siteId)) queryParams.siteId = siteId;
            if (!['', 'all', undefined, null].includes(filterPayload?.status)) filterPayload.status = filterPayload.status.map((s) => s.split(' ').join('_'));
            if (!['', undefined, null].includes(query)) queryParams.query = query;
            response = yield call(lambdas.utils.filterModule, {}, {}, queryParams, filterPayload);
        } else {
            let queryParams = { limit, page, count: true, giveLastTickets: true, archive };
            if (!['', undefined, null, 0].includes(customerId)) queryParams.customerId = customerId;
            if (!['', undefined, null, 0].includes(siteId)) queryParams.siteId = siteId;
            response = yield call(microservices.inventory.getAllAssets, {}, {}, queryParams);
        }
        if (response.data.code === 200) {
            if (isFilterOrSearchActive) {
                yield put({ type: inventory.GET_ASSETS_FILTERED_DATA_SUCCESS, payload: { data: response.data.data, count: response.data.count } });
            } else {
                yield put({ type: inventory.ALL_ASSETS_COUNT_SUCCESS, payload: response.data.count });
                yield put({ type: inventory.GET_ALL_ASSETS_SUCCESS, payload: { data: response.data.data, source: isAssetGroupView ? 'enduser_view' : undefined, endUserId: enduserId, page } });
            }
        } else if ([400, 500].includes(response.data.code)) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
        }
    } catch (err) { }
    if (isAssetGroupView && enduserId) {
        yield put({ type: loading.SET_ALL_ASSETS_GROUP_BY_ENDUSER_LOADING, payload: { id: enduserId, isLoading: false } });
    } else {
        yield put({ type: loading.GET_ALL_ASSETS_LOADING, payload: false });
    }
}

export function* getAssetsFilteredData(action) {
    let { module = 'INVENTORY', tab = 'assets', customerId, next = true, count = false, type = 0, limit, page, query, siteId, payload, archive, callPoList } = action.payload;

    try {
        yield put({ type: loading.GET_ALL_ASSETS_LOADING, payload: true });
        let queryParams = {
            module,
            tab,
            limit,
            page,
            next,
            count,
            type,
            giveLastTickets: true,
            giveAssetTypes: true,
            giveSpareAssetsStats: true,
            archive,
        };
        if (!['', 'all', undefined, null, 0].includes(customerId)) queryParams.customerId = customerId;
        if (!['', 'all', undefined, null].includes(payload?.status)) payload.status = payload.status.map((s) => s.split(' ').join('_'));
        if (!['', undefined, null].includes(query)) queryParams.query = query;
        if (![0, '', undefined, null].includes(siteId)) queryParams.siteId = siteId;
        const response = yield call(lambdas.utils.filterModule, {}, {}, queryParams, payload);
        if (response.data.code === 200) {
            if (response.data.data.length > 0 && callPoList) {
                const siteIds = [...new Set(response.data.data.map((item) => item.fk_site_id))];
                yield put({ type: inventory.GET_SITE_POS_LIST_REQUEST, payload: { siteIds, source: 'assets' } });
            }
            yield put({ type: inventory.GET_ASSETS_FILTERED_DATA_SUCCESS, payload: { data: response.data.data, count: response.data.count } });
        } else if ([400, 500].includes(response.data.code)) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
        }
    } catch (err) {
        yield put({ type: inventory.GET_ASSETS_FILTERED_DATA_FAILURE, payload: 'Something went wrong' });
    }
    yield put({ type: loading.GET_ALL_ASSETS_LOADING, payload: false });
}

export function* linkReconcileAssets(action) {
    const { data, ticket, formName, ticketExecution = false, navigate } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(microservices.inventory.linkReconcileAssets, {}, {}, {}, data);
        if (response.data.code === 200) {
            yield all([yield put({ type: modal.CLOSE_MODAL }), yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: response.data.data.message } })]);
            if (ticketExecution) {
                navigate(`/tickets/${ticket.id}/execute/approval`);
            }
        } else {
            yield all([yield put({ type: modal.CLOSE_MODAL }), yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } })]);
            yield put({ type: inventory.LINK_RECONCILE_INVENTORY_ASSETS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: inventory.LINK_RECONCILE_INVENTORY_ASSETS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* getAssetWitSerialNumber(action) {
    const { customerId, siteId, serialNumber, tag, scannedLocation, source, id } = action.payload;

    let queryParams = {};
    if (serialNumber) queryParams.serialNumber = serialNumber;
    if (tag) queryParams.tag = tag;
    if (scannedLocation) queryParams.scannedLocation = scannedLocation;
    if (!['', undefined, null, 0].includes(customerId)) queryParams.customerId = customerId;
    if (!['', undefined, null, 0].includes(siteId)) queryParams.siteId = siteId;

    try {
        if (source === 'support_track' && id) {
            yield put({ type: loading.GET_SCANNED_ASSETS_LOADING, payload: true });
        }
        yield put({ type: loading.GET_ALL_ASSETS_LOADING, payload: true });
        yield put({ type: inventory.REMOVE_ASSET_NOT_FOUND_FROM_STORE });
        const response = yield call(microservices.inventory.getAssetWitSerialNo, {}, {}, queryParams);
        if (response.data.code === 200) {
            let asset;
            if (Array.isArray(response.data.data) && response.data.data.length > 0) {
                asset = response.data.data[0];
            }
            if (source === 'support_track' && id) {
                yield put({ type: inventory.GET_SCANNED_ASSET_SUCCESS, payload: { asset, id } });
            }
            yield put({ type: inventory.GET_ASSET_WITH_SERIAL_NUMBER_SUCCESS, payload: { asset, assetNotFound: response.data.data.length === 0 } });
        } else {
            yield put({ type: inventory.GET_ASSET_WITH_SERIAL_NUMBER_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: inventory.GET_ASSET_WITH_SERIAL_NUMBER_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.GET_ALL_ASSETS_LOADING, payload: false });
    if (source === 'support_track' && id) {
        yield put({ type: loading.GET_SCANNED_ASSETS_LOADING, payload: false });
    }
}

export function* editAssetDate(action) {
    const { id, date, source, formName, assetSource, assetId } = action.payload
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    try {
        const response = yield call(lambdas.tickets.updateTicketProperties, {}, {}, {}, {
            "property": "UPDATE_TICKET_DATES",
            "data": {
                source, date, id
            }
        })
        if (response.data.code === 200) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: response.data.data.message } });
            yield put({ type: modal.CLOSE_MODAL });
            yield put({ type: inventory.GET_ASSETS_HISTORY, payload: { assetId, assetSource } })
        }
        else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message ? response.data.data.message : 'Something went wrong' } });
        }
    }
    catch (error) {
        yield put({ type: inventory.EDIT_ASSET_DATE_FAILURE, payload: { message: error } })
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* getAssetTagDetails(action) {
    const { tag } = action.payload;
    try {
        yield put({ type: loading.ASSET_TAG_DETAILS_LOADING, payload: true });
        const response = yield call(microservices.inventory.getAssetTagDetails, {}, { tag }, { product: 'superapp' });
        if (response.data.code === 200) {
            let asset;
            if (Array.isArray(response.data.data)) {
                asset = response.data.data[0];

            }
            yield put({ type: inventory.GET_ASSET_TAG_DETAILS_SUCCESS, payload: { asset } });
        } else {
            yield put({ type: inventory.GET_ASSET_TAG_DETAILS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: inventory.GET_ASSET_TAG_DETAILS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.ASSET_TAG_DETAILS_LOADING, payload: false });

}

export function* editAssetUploadAttachment({ payload }) {
    const { serial_number, file, action, mime_type, formName, assetId, assetOrSpareAsset } = payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.inventory.uploadAttachmentsDeviceConfig, {}, {}, {}, { serial_number, file, action, mime_type });
        if (response.data.code === 200) {
            if (assetOrSpareAsset === assetsTab.path) {
                yield put({ type: inventory.EDIT_ASSET_UPLOAD_ATTACHMENT_SUCCESS, payload: { data: response.data.data.body, assetId } });
            }
            else if (assetOrSpareAsset === spareAssetsTab.path) {
                yield put({ type: inventory.EDIT_SPARE_ASSET_UPLOAD_ATTACHMENT_SUCCESS, payload: { data: response.data.data.body, assetId } });
            }
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: `File Uploaded` } });
        }
    } catch (error) {
        yield put({ type: inventory.EDIT_ASSET_UPLOAD_ATTACHMENT_FAILURE, payload: { message: error } });
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: error.message } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export default function* assetsSaga() {
    yield takeLatest(inventory.GET_ASSETS_HISTORY, getAssetHistory);
    yield takeLatest(inventory.CREATE_INVENTORY_ASSETS_REQUEST, createInventoryAssets);
    yield takeLatest(inventory.GET_DROPDOWN_REPLACE_ALL_ASSETS_REQUEST, getAllAssetsDropdown);
    yield takeEvery(inventory.GET_ALL_ASSETS_REQUEST, getAllAssets);
    yield takeEvery(inventory.GET_ALL_FILTER_ASSETS_REQUEST, getAllAssetsForFilter);
    yield takeLatest(inventory.DELETE_INVENTORY_ASSET_REQUEST, deleteInventoryAsset);
    yield takeLatest(inventory.UPDATE_INVENTORY_ASSET_REQUEST, updateInventoryAsset);
    yield takeLatest(inventory.MIGRATE_INVENTORY_ASSET_REQUEST, migrateAssets);
    yield takeLatest(inventory.SCRAP_INVENTORY_ASSETS_REQUEST, scrapAssets);
    yield takeLatest(inventory.REPLACE_INVENTORY_ASSET_REQUEST, replaceAssets);
    yield takeLatest(inventory.GENERATE_INVENTORY_ASSETS_BARCODE_REQUEST, generateAssetBarcode);
    yield takeLatest(inventory.BULK_UPLOAD_INVENTORY_ASSETS_REQUEST, bulkUploadAssets);
    yield takeLatest(inventory.ADD_ASSETS_REQUEST, addAssetsMaster);
    yield takeLatest(inventory.GET_ASSETS_FILTER_LIST_REQUEST, getAssetsFilterList);
    yield takeLatest(inventory.REFRESH_ASSET_PAGE, refreshAssetPage);
    yield takeLatest(inventory.GET_ASSETS_FILTERED_DATA_REQUEST, getAssetsFilteredData);
    yield takeLatest(inventory.LINK_RECONCILE_INVENTORY_ASSETS_REQUEST, linkReconcileAssets);
    yield takeLatest(inventory.GET_ASSET_WITH_SERIAL_NUMBER_REQUEST, getAssetWitSerialNumber);
    yield takeLatest(inventory.EDIT_ASSET_DATE_REQUEST, editAssetDate);
    yield takeLatest(inventory.GET_ASSET_TAG_DETAILS_REQUEST, getAssetTagDetails);
    yield takeEvery(inventory.EDIT_ASSET_UPLOAD_ATTACHMENT_REQUEST, editAssetUploadAttachment);
}
