import IconToolTip from './IconToolTip';
import Loader from './Loader';

export default function Card({ children, title, className = '', icon, actions, isLoading, loaderComponent, copyAction, renderStatus = <></> }) {
    const getChildren = () => {
        if (isLoading) {
            if (loaderComponent) return loaderComponent;
            return (
                <div className='flex h-full items-center justify-center '>
                    <Loader color='#F48A21' size='100' speed='1' thickness='2' marginTop='' />
                </div>
            );
        }
        return children;
    };

    return (
        <div className={`${className} bg-white  flex flex-col border h-full border-gray-200 rounded-lg shadow `}>
            <div className='text-font12 w-full px-3 py-1 h-16 font-md border-b flex items-center justify-between gap-3'>
                <div className={`flex items-center  gap-3 `}>
                    <span className='material-icons text-scogoprimary text-font14'>{icon}</span>
                    <span>{title}</span>
                    {renderStatus}
                    {copyAction?.isToShowAction && (
                        <IconToolTip title={copyAction?.onHovertitle}>
                            <span className='material-icons flex cursor-pointer text-font16  mr-4 text-scogoprimary  hover:text-scogoorange' onClick={copyAction?.onClick}>
                                content_copy
                            </span>
                        </IconToolTip>
                    )}
                </div>
                <div className={`flex items-center`}>
                    {Array.isArray(actions) &&
                        actions?.map(
                            (action) =>
                                action?.isToShowAction && (
                                    <IconToolTip title={action?.onHovertitle}>
                                        <span
                                            className={`material-icons flex cursor-pointer   mr-4  ${
                                                action?.class ? action?.class : 'text-scogoprimary text-font16  hover:text-scogoorange'
                                            }`}
                                            onClick={action?.onClick}
                                        >
                                            {action?.iconName}
                                            {<spna>{action?.label}</spna>}
                                        </span>
                                    </IconToolTip>
                                )
                        )}
                </div>
            </div>
            <div className='h-full'>{getChildren()}</div>
        </div>
    );
}
