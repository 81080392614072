import { selectIsPeerAudioEnabled, selectIsPeerVideoEnabled, selectPeerAudioByID, useHMSStore, useVideo } from '@100mslive/react-sdk';
import React from 'react'
import { defaultSingleChatPic } from '../../chat/ChatController';
import MicOffIcon from '@mui/icons-material/MicOff';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import IconToolTip from '../../../common/IconToolTip';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

const Peer = ({ peer }) => {
    const { videoRef } = useVideo({
        trackId: peer.videoTrack,
        attach: true
    });

    const peerIdGenerator = (id) => {
        return `peer-screen-${id}`;
    }

    const toggleFullScreen = () => {
        let el = document.getElementById(peerIdGenerator(peer.id));
        if (el.webkitEnterFullscreen) {
            el.webkitEnterFullscreen();
        } else if (el.webkitRequestFullscreen) {
            el.webkitRequestFullscreen();
        } else if (el.msRequestFullscreen) {
            el.msRequestFullscreen();
        } else if (el.mozRequestFullScreen) {
            el.mozRequestFullScreen();
        } else if (el.requestFullscreen) {
            el.requestFullscreen();
        }
    };

    const speakingCSS = 'shadow-[0px_0px_35px_10px_#2b6cb0]';
    const normalShadow = 'shadow-[0px_0px_60px_0px_#4a5568]';
    const isPeerVideoEnabled = useHMSStore(selectIsPeerVideoEnabled(peer.id));
    const isPeerAudioEnabled = useHMSStore(selectIsPeerAudioEnabled(peer.id));
    const audiolevel = useHMSStore(selectPeerAudioByID(peer.id));
    const isPeerScreenShareEnabled = peer.auxiliaryTracks?.length > 0;
    const isCurrentUserSpeaking = audiolevel > 20;
    const peerMetaData = peer.metadata ? JSON.parse(peer.metadata) : {};
    const isLocal = peer?.isLocal;

    return (
        <div className={`w-[40rem] h-[22.7rem] transition-all ease-linear duration-300 border-2 bg-black rounded-3xl overflow-hidden animate-fadeIn relative hover:cursor-pointer group ${isCurrentUserSpeaking ? speakingCSS : normalShadow}`}>
            {
                isPeerVideoEnabled ?
                    <video ref={videoRef} autoPlay muted playsInline className='w-full h-full rounded-3xl' id={peerIdGenerator(peer.id)}></video>
                    :
                    <div className='w-full h-full bg-gray-600'>
                        <img src={`${peerMetaData?.profile_picture || defaultSingleChatPic}`} alt='ProfilePhoto' className=' w-52 h-52 object-cover rounded-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ' />
                    </div>
            }
            <div className='w-full h-full transition-all ease-linear rounded-3xl absolute top-0 left-0 bg-black opacity-0 group-hover:opacity-50'></div>
            {
                !isPeerAudioEnabled && <MicOffIcon sx={{ fontSize: 30, color: "#FFFFFF" }} className='absolute top-5 right-4' />
            }
            {
                isPeerScreenShareEnabled && <ScreenShareIcon sx={{ fontSize: 30, color: "#FFFFFF" }} className='absolute top-5 left-5' />
            }
            {
                isCurrentUserSpeaking && isPeerAudioEnabled && <RecordVoiceOverIcon sx={{ fontSize: 30, color: "#63B3ED" }} className='absolute top-4 right-5' />
            }
            {
                isPeerVideoEnabled && !isLocal &&
                <IconToolTip title="Fullscreen">
                    <FullscreenIcon sx={{ fontSize: 40 }} onClick={toggleFullScreen} className='opacity-0 group-hover:opacity-100 hover:text-gray-700 absolute bottom-5 right-5 text-white' />
                </IconToolTip>
            }
            <p className='text-font15 text-white absolute bottom-5 left-7 drop-shadow-[0_3px_10px_rgb(0,0,0,0.2)]'>{peer.name}</p>
        </div>)
}

export default Peer