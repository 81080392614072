import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import IconToolTip from '../IconToolTip';
import { openRequestedCallModal } from '../../actions/modal';
import { useDispatch } from 'react-redux';
import { randomDelay, validateStringValue } from '../../utils/common';
import { declineRoomAction, holdRoomAction, joinRoomAction } from '../../actions/conversations';
import { getActiveVoiceCall } from '../../actions/tickets';
import { getUserCallStatusAction } from '../../actions/users';
import { sendDataThroughWebsocket } from '../../actions/websocket';
import { websocketEvents } from '../../utils/websocket';
import { Howl } from 'howler';
import _ from 'lodash';


const callRingtone = new Howl({
    src: ['/audio/call-ringtone.mp3'],
});

export default function CallModal() {

    const { activeCallDetails } = useSelector(state => state.modal);
    const { loggedUser } = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const clearLocalStorage = () => {
        localStorage.removeItem('IS_PLAYING');
    }

    const delayedFunction = async () => {
        await randomDelay();
        if (!localStorage.getItem('IS_PLAYING') && !localStorage.getItem('40_SEC_NO_PLAY') && activeCallDetails) {
            localStorage.setItem('IS_PLAYING', 'true');
            localStorage.setItem('40_SEC_NO_PLAY', 'true');
            callRingtone.play();
            _.delay(() => {
                clearLocalStorage();
                localStorage.removeItem('40_SEC_NO_PLAY');
            }, 40000);
        }
    }

    useEffect(() => {
        delayedFunction();
        const stop = () => {
            if (!localStorage.getItem('IS_PLAYING')) {
                callRingtone.stop();
            }
        }
        window.addEventListener('storage', stop);
        return () => {
            callRingtone.stop();
            window.removeEventListener('storage', stop);
        }
    }, [])

    const refresh = () => {
        dispatch(getActiveVoiceCall());
        dispatch(getUserCallStatusAction({ userId: loggedUser?.id, status: [1, 2, 3, 4] }));
    }

    const onJoinMeetClick = () => {
        try {
            clearLocalStorage();
            dispatch(joinRoomAction({ userId: loggedUser?.id, room_id: activeCallDetails?.fk_room_id, ticketId: activeCallDetails?.id, openedConversationId: activeCallDetails?.openedConversationId, onSuccess: refresh }));
            window.open(new URL(activeCallDetails.videoMeetLink), '_blank');
            dispatch(openRequestedCallModal({ data: undefined, isOnCall: false }));
        } catch (error) {
            console.error(error);
        }
    }

    const onHoldMeetClick = () => {
        try {
            clearLocalStorage();
            dispatch(holdRoomAction({ userId: loggedUser?.id, room_id: activeCallDetails?.fk_room_id, ticketId: activeCallDetails?.id, openedConversationId: activeCallDetails?.openedConversationId, onSuccess: refresh }));
            dispatch(sendDataThroughWebsocket({ action: websocketEvents.chat.holdOrEndcall, data: { holdCall: true, room_id: activeCallDetails?.fk_room_id } }));
            dispatch(openRequestedCallModal({ data: undefined, isOnCall: false }));
        } catch (error) {
            console.error(error);
        }
    }

    const onDeclineMeetClick = () => {
        try {
            clearLocalStorage();
            dispatch(declineRoomAction({ userId: loggedUser?.id, room_id: activeCallDetails?.fk_room_id, ticketId: activeCallDetails?.id, openedConversationId: activeCallDetails?.openedConversationId, onSuccess: refresh }));
            dispatch(sendDataThroughWebsocket({ action: websocketEvents.chat.holdOrEndcall, data: { declineCall: true, room_id: activeCallDetails?.fk_room_id } }));
            dispatch(openRequestedCallModal({ data: undefined, isOnCall: false }));
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        dispatch(getActiveVoiceCall());
        dispatch(getUserCallStatusAction({ userId: loggedUser?.id, status: [1, 2, 3, 4] }));
        const timeout = setTimeout(() => {
            dispatch(openRequestedCallModal({ data: undefined, isOnCall: false }));
        }, 45000);
        return () => {
            clearTimeout(timeout);
            dispatch(getUserCallStatusAction({ userId: loggedUser?.id, status: [1, 2, 3, 4] }));
        }
    }, [dispatch]);

    const ticketDetail = { value: `${validateStringValue(activeCallDetails?.job_ticket_number)}`, label: 'Ticket No.' };
    const user = { value: `${validateStringValue(activeCallDetails?.user?.first_name)}`, label: 'Invited By' };
    const project = { value: `${validateStringValue(activeCallDetails?.project?.project_name)}`, label: 'Project Name' };
    let site = {};
    if (activeCallDetails?.site?.external_site_id) {
        site = { value: `${validateStringValue(activeCallDetails?.site?.external_site_id)}`, label: 'Site Id' };
    }
    if (activeCallDetails?.site?.site_branch_name) {
        site = { value: `${validateStringValue(activeCallDetails?.site?.site_branch_name)}`, label: 'Branch Name' };
    }
    const data = [ticketDetail, user];

    return <div className='min-h-[18rem] w-[30rem] bg-scogodarkgray absolute right-8 bottom-16 rounded-xl text-white py-3 z-50 pb-6'>
        <div className=''>
            <UserIconWithName userName={`${validateStringValue(activeCallDetails?.accepted_fe?.first_name)} ${validateStringValue(activeCallDetails?.accepted_fe?.last_name)}` || ''} iconClass={'h-[10vh]'} />
        </div>
        <div className='m-auto max-w-max pt-2'>
            {data.map(({ label, value }) => {
                if (!value) return <></>;
                return <p className='max-w-max'>
                    <span className='font-medium text-font12'>{label}: </span>
                    <span className='font-normal text-scogoddd text-font11'>{value}</span>
                </p>
            })}
        </div>
        <div className='flex items-center justify-center gap-4 pt-3'>
            {activeCallDetails?.videoMeetLink && <DynamicButton label='Join Call' color={'#2ECC71'} onClick={onJoinMeetClick} />}
            <DynamicButton label='Hold Call' color={'#F48A21'} onClick={onHoldMeetClick} />
            <DynamicButton label='Decline' color={'#FF1B1B'} onClick={onDeclineMeetClick} />
        </div>
        <div className='absolute top-2 right-4'>
            <CloseIcon onClick={() => {
                clearLocalStorage();
                dispatch(openRequestedCallModal({ data: undefined, isOnCall: false }))
            }} />
        </div>
    </div>
}


export const UserIconWithName = ({ icon = "/img/call_user.svg", userName, textClass = "text-font13 font-normal capitali max-w-max m-auto text-scogoddd", iconClass = "h-[7vh]" }) => {
    return (
        <div className="flex flex-col">
            <img className={iconClass} src={process.env.PUBLIC_URL + icon} alt="User" />
            <p className={textClass}>{userName}</p>
        </div >
    );
};

const DynamicButton = ({ onClick = () => { }, color = 'red', label }) => {
    return <button style={{ background: color }} className={`rounded-xl px-4 py-2 font-medium`} onClick={onClick}>
        {label}
    </button>
};

const CloseIcon = ({ onClick = () => { } }) => {
    return <div
        className='cursor-pointer'
        onClick={onClick}
    >
        <IconToolTip title='Close'>
            <span className='material-icons text-scogoprimary font-medium align-middle text-3xl hover:text-scogoorange text-white'>close</span>
        </IconToolTip>
    </div>
}