export const UPDATE_WHATSAPP_NUMBER = 'UPDATE_WHATSAPP_NUMBER';

export const GET_WHATSAPP_QR = 'GET_WHATSAPP_QR';

export const WHATSAPP_QR_SUCCESS = 'WHATSAPP_QR_SUCCESS';

export const GET_WHATSAPP_GROUP_LiST = 'GET_WHATSAPP_GROUP_LiST';
export const WHATSAPP_GROUP_LIST_SUCCESS = 'WHATSAPP_GROUP_LIST_SUCCESS';
export const FORWARD_MESSAGE_WHATSAPP = 'FORWARD_MESSAGE_WHATSAPP';
export const FORWARD_MESSAGE_SUCCESS = 'FORWARD_MESSAGE_SUCCESS';
export const SHOW_DIALPAD = 'SHOW_DIALPAD';