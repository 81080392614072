import { superdesk } from '../types';

export const getSuperDeskCapexTypes = ({ customerId }) => ({
    type: superdesk.GET_SUPERDESK_CAPEX_TYPES_REQUEST,
    payload: { customerId },
});

export const addTeams = ({ data, type, customerId, activeTab, isToCallAPI, formName }) => ({
    type: superdesk.ADD_TEAM_REQUEST,
    payload: { data, type, customerId, activeTab, isToCallAPI, formName },
});

export const addTeamsAgent = ({ data, formName }) => ({
    type: superdesk.ADD_TEAM_AGENT_REQUEST,
    payload: { data, formName },
});

export const removeTeamsAgent = ({ data, tabName, customeMessage, projectId, userCustomerId, formName }) => ({
    type: superdesk.REMOVE_TEAM_AGENT_REQUEST,
    payload: { data, customeMessage, projectId, tabName, userCustomerId, formName },
});

export const transferUsersInTeams = ({ data, customerId }) => ({
    type: superdesk.TRANSFER_TEAMS_AGENT_REQUEST,
    payload: { data },
});

export const updateAgentsProperty = ({ data, customerId, propertyName, userName, enableOrDisable }) => ({
    type: superdesk.UPDATE_USERS_PROPERTY_REQUEST,
    payload: { data, customerId, propertyName, userName, enableOrDisable },
});

export const addUsersInTeam = ({ data, teamId, usersName, teamName, projectDetail, tabName, customerId, formName }) => ({
    type: superdesk.ADD_USERS_TEAM_REQUEST,
    payload: { data, teamId, usersName, teamName, projectDetail, tabName, customerId, formName },
});

export const updateUsersDetailsInStore = ({ userData }) => ({
    type: superdesk.UPDATE_USERS_INSTORE_PROPERTY,
    payload: { userData },
});

export const getTeamsById = ({ teamId }) => ({
    type: superdesk.GET_TEAMS_BY_ID_REQUEST,
    payload: { teamId },
});

export const deleteTeamAction = ({ teamId, teamName, formName }) => ({
    type: superdesk.DELETE_TEAM_REQUEST,
    payload: { teamId, teamName, formName },
});

export const editTeam = ({ data, customerId, teamId, formName }) => ({
    type: superdesk.EDIT_TEAM_REQUEST,
    payload: { data, teamId, customerId, formName },
});
