import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listAllState, listAllCity } from '../../../actions/admin';
import { updateUserTerritories, removeUserTerritories } from '../../../actions/users';
import { closeModalAction } from '../../../actions/modal';
import IconToolTip from '../../../common/IconToolTip';
import { isPsp, isSuperVisor } from '../../../utils/role';
import { useForm } from 'react-hook-form';
import Form from '../../../common/Form';
import Select from '../../../common/Form/Select';
import MultiplePincodes from '../../../common/Form/MultiplePincodes';

const formName = 'updateTerritories';

export default function UpdateTerritories(props) {
  const { role, userType, userDetails, customer } = props;
  const [userState, setUserState] = useState([]);
  const [userCity, setUserCity] = useState([]);
  const [userPincode, setUserPincode] = useState([]);
  const dispatch = useDispatch();
  const { allState, allCity } = useSelector((state) => state.admin);
  const { isAllStateLoading, isAllCityLoading, formButtonLoading } = useSelector((state) => state.loading);
  const [toRemove, setToRemove] = useState({ cities: [], states: [], pincodes: [] });

  const defaultValues = {
    states: [],
    cities: [],
    pincodes: [],
  };

  const submit = (form) => {
    let selectedStates = form.states.map((item) => item.value);
    let selectedCities = form.cities.map((item) => item.value);
    const pincodes = form.pincodes.filter((pincode) => pincode !== '');
    const payload = {
      userId: userDetails.id,
      states: selectedStates,
      cities: selectedCities,
      pincodes,
    };
    if (payload.states.length > 0 || payload.cities.length > 0 || pincodes.length > 0) {
      dispatch(updateUserTerritories({ data: payload, role, userType, customer, formName }));
    }

    if (toRemove.states.length > 0 || toRemove.cities.length > 0 || toRemove.pincodes.length > 0) {
      const payloadData = {
        userId: userDetails.id,
        states: toRemove.states,
        cities: toRemove.cities,
        pincodes: toRemove.pincodes,
      };
      dispatch(removeUserTerritories({ data: payloadData, role, userType, customer, formName }));
    }
  };

  const getStatePincodeOrCity = useCallback(() => {
    const states = [],
      cities = [],
      pincodes = [];
    if (Array.isArray(userDetails.incentive_areas) && userDetails.incentive_areas.length > 0) {
      userDetails.incentive_areas.forEach(item => {
        if (item.type === 'CITY') {
          cities.push({ id: item.id, name: item.title, type: item.type });
        }
        if (item.type === 'STATE') {
          states.push({ id: item.id, name: item.title, type: item.type });
        }
        if (item.type === 'PINCODE') {
          pincodes.push({ id: item.id, name: item.title, type: item.type });
        }
      });
    }
    setUserState(states);
    setUserCity(cities);
    setUserPincode(pincodes);
  }, [userDetails?.incentive_areas])

  useEffect(() => {
    getStatePincodeOrCity();
    if (userDetails?.type) {
      dispatch(listAllState({ dropdown: true, userterritories: true, role: userDetails.role, userType: userDetails?.type }));
      dispatch(listAllCity({ dropdown: true, userterritories: true, role: userDetails.role, userType: userDetails?.type }));
    }
  }, [getStatePincodeOrCity, dispatch, userDetails?.role, userDetails?.type]);

  const handleUpdateRemoveLocations = ({ type, locationId }) => {
    switch (type) {
      case 'STATE': {
        const updatedState = toRemove.states.filter((state) => state.id !== locationId).map((state) => state.id);
        const updatedUserState = userState.filter((state) => state.id !== locationId);
        setUserState(updatedUserState);
        setToRemove({ ...toRemove, states: [...updatedState, locationId] });
        break;
      }
      case 'CITY': {
        const updatedCity = toRemove.cities.filter((city) => city.id !== locationId).map((city) => city.id);
        const updatedUserCity = userCity.filter((city) => city.id !== locationId);
        setUserCity(updatedUserCity);
        setToRemove({ ...toRemove, cities: [...updatedCity, locationId] });
        break;
      }

      case 'PINCODE': {
        const updatedPicodes = toRemove.pincodes.filter((pincode) => pincode.id !== locationId).map((pincode) => pincode.id);;
        const updatedUserPicodes = userPincode.filter((picode) => picode.id !== locationId);
        setUserPincode(updatedUserPicodes);
        setToRemove({ ...toRemove, pincodes: [...updatedPicodes, locationId] });
        break;
      }
      default:
        break;
    }
  }

  const isPspOrSuperVisor = isPsp(userDetails?.role, userDetails?.type) || isSuperVisor(userDetails?.role, userDetails?.type);

  const methods = useForm({ defaultValues, mode: 'all' });

  return (
    <Form
      methods={methods}
      onSubmit={submit}
      submitButtonText={'Update'}
      onCancel={() => dispatch(closeModalAction())}
      showSubmit
      submitContainerClassName='flex gap-2 justify-end py-4 px-4'
      isLoading={formButtonLoading[formName]}
    >
      <div className='px-3 flex flex-col gap-2 pt-2'>
        <RenderTerritoryList data={userState} heading={'States'} type='STATE' handleUpdateRemoveLocations={handleUpdateRemoveLocations} />
        <RenderTerritoryList data={userCity} heading={'Cities'} type='CITY' handleUpdateRemoveLocations={handleUpdateRemoveLocations} />
        <RenderTerritoryList data={userPincode} heading={'Pincodes'} type='PINCODE' handleUpdateRemoveLocations={handleUpdateRemoveLocations} />
      </div>
      <div className='px-5 pt-2'>
        <Select label='States' name='states' options={allState} isMulti={true} isLoading={isAllStateLoading} />
        {isPspOrSuperVisor && <Select className='w-full' label='Cities' name='cities' options={allCity} isMulti={true} isLoading={isAllCityLoading} />}
      </div>
      {isPspOrSuperVisor && (
        <div className='px-4'>
          <MultiplePincodes name='pincodes' label='Pincodes' />
        </div>
      )}
    </Form>
  );
}

const RenderTerritoryList = ({ data, heading, type, handleUpdateRemoveLocations }) => {
  if (Array.isArray(data) && data.length > 0) {
    return (
      <div className='flex flex-col px-4 gap-2 mt-2'>
        <p className='text-font12 font-medium'>{heading}</p>
        <div className='flex gap-2 flex-wrap'>
          {data?.map((place, index) => {
            return (
              <p key={index} className='flex items-center gap-2 px-2 rounded-md bg-tagbg py-1'>
                <span className='material-icons text-scogoclosed text-font16'>place</span> {place.name}
                <IconToolTip title='Remove'>
                  <span
                    className='material-icons text-scogoclosed text-font14 cursor-pointer hover:text-scogoorange'
                    onClick={() => handleUpdateRemoveLocations({ type, locationId: place.id })}
                  >
                    close
                  </span>
                </IconToolTip>
              </p>
            );
          })}
        </div>
      </div>
    );
  }
  return <></>;
};
