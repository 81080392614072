import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../Inventory/reusableComponents/AddOption/Input';
import Form from '../../Inventory/reusableComponents/AddOption/Form';
import { closeModalAction } from '../../../actions/modal';
import DetailTag from "../../project/DetailTag";
import { getCustomerTeams } from '../../../actions/customer'
import { createSupportTickets } from '../../../actions/tickets';
import { textCss } from '../../../common/Css';
import { actualValueForDropdown, isArrayElementsSame, isLooksLikeId } from '../../../utils/common';
import { getUserIdOfLoginUser } from '../../../utils/utils';
import {sendToastMessage} from '../../../actions/toast';
import {findWarehouseById} from '../../../actions/inventory';
const formName = 'createReconcileTicket';

const CreateReconcileTicketSpareAsset = ({ modalData }) => {
    const { assetslist } = modalData;
    const dispatch = useDispatch();
    const {loggedUser} = useSelector((state) => state.auth)
    const {warehouse} = useSelector((state) => state.inventory);
    const customerIdsOfAssets = Array.isArray(assetslist) && assetslist.map((asset) => asset.customer_id);
    let warehouseIdsOfSpareAssets = Array.isArray(assetslist) && assetslist.map((spareasset) => spareasset?.warehouse?.id);
    let warehouseId;
    if(isArrayElementsSame(warehouseIdsOfSpareAssets)){
        warehouseId = warehouseIdsOfSpareAssets[0];
    }
    const [warehouseContact, setWarehouseContact] = useState(null);

    const initialValues = {
        fk_team_id: '',
        description: '',
        related_emails: '',
        title: '',
        external_ticket_id: ''
    };

    const [form, setForm] = useState(initialValues)

    useEffect(() => {
        if(Array.isArray(customerIdsOfAssets)){
            let customer_id = customerIdsOfAssets[0];
            dispatch(getCustomerTeams({ customerId : customer_id, dropdown: true }))
        }
    }, []);

    useEffect(() => {
        if(warehouseId){
            dispatch(findWarehouseById({warehouseId}))
        }
    }, [warehouseId]);

    const { customerTeamsDropDown, customerTeamsLoading } = useSelector((state) => state.customer);

    const getContactDetailsOfSelectedWarehouse = (warehouse) => {
        let contact;
        if (warehouse.contacts && typeof warehouse.contacts === 'object' &&
            Array.isArray(warehouse.contacts) && warehouse.contacts.length > 0) {
            contact = warehouse.contacts[0]
        }
        if (warehouse.contacts && typeof warehouse.contacts === 'string'){
            warehouse.contacts = JSON.parse(warehouse.contacts)
            if (Array.isArray(warehouse.contacts) && warehouse.contacts.length > 0) {
                contact = warehouse.contacts[0]
            }
        }
        contact = {
            ...contact,
            name : contact ? `${contact?.user?.first_name ? contact?.user?.first_name : ''} ${contact?.user?.last_name ? contact?.user?.last_name : ''}` : ''
        }
        return contact;
    }

    useEffect(() => {
        if(warehouse){
            let contacts = getContactDetailsOfSelectedWarehouse(warehouse);
            setWarehouseContact(contacts);
        }
    }, [warehouse])

    const getWarehousePayload = ({customerId, userId, form, warehouse, warehouseContact}) => {
        let data = { fk_customer_id: customerId, location_data: [] };
        data.location_data.push({
            description: form.description,
            external_ticket_id: form?.external_ticket_id || '',
            fk_customer_id: customerId,
            fk_noc_template_id : 0,
            fk_team_id: actualValueForDropdown(form.fk_team_id),
            fk_template_id: 0, 
            hard_copy_required: 0,
            is_boq_required: 0, 
            is_noc_required: 1, 
            job_fk_pincode_id: warehouse.pincode,
            job_site_address_1: warehouse.address,
            job_site_contact_name: warehouseContact?.name ? warehouseContact?.name : '',
            job_site_contact_mobile: warehouseContact?.user?.mobile ? warehouseContact?.user?.mobile : '',
            job_site_contact_mail: warehouseContact?.user?.email ? warehouseContact?.user?.email : '',
            related_emails: !['', undefined, null].includes(form.related_emails) ? form.related_emails.split(',') : [],
            requester_name: `${loggedUser.first_name} ${loggedUser.last_name}`,
            requester_email: loggedUser.email,
            requester_mobile: loggedUser.mobile,
            self_pickup_required: 0, 
            service_type_id: 12, 
            site_id: 0,
            ticket_type: 0, 
            title: form.title,
            use_case_id: 0, 
            use_existing : 1,
            userId: userId,
            warehouse: warehouseId,
        })
        return data
    }

    const submit = (form) => {
        const userId = getUserIdOfLoginUser(loggedUser);
        const isSameCustomerIds = isArrayElementsSame(customerIdsOfAssets);
        if(!isSameCustomerIds) return dispatch(sendToastMessage({ status: "danger", message: 'Multiple customer assets found' }))
        let customerId = customerIdsOfAssets[0];

        let data = getWarehousePayload({customerId, userId, form, warehouse, warehouseContact})

        if (data.location_data.length > 0) {
            dispatch(createSupportTickets({ data, existing: true, formName }))
        }
    }


    const closeModal = () => { dispatch(closeModalAction()) }

    const customer = useSelector(state => state.customer)

    let { customerTeams } = customer;

    return (
        <>
            <Form
                className='w-full'
                submit={(e) => { submit(e) }}
                onCancel={closeModal}
                buttonPostion={'justify-end'}
                initialValues={initialValues}
                form={form}
                setForm={setForm}
                formName={formName}
            >
                    <div className="md:flex pl-5 pt-3 break-words">
                        <DetailTag tagHeadClass={textCss?.detailsTitle} tagValueClass={textCss?.detailsValue + ' line-clamp-3'} tagClass=" px-2 w-6/12" tagName="Warehouse" tagValue={warehouse?.name ? warehouse?.name : ''} />
                        <div className='px-2 w-6/12'>
                            <DetailTag tagHeadClass={textCss?.detailsTitle} tagValueClass={textCss?.detailsValue  + ' line-clamp-3'} tagClass="" tagName="Address" tagValue={warehouse?.address ? `${warehouse?.address}, ` : ''} />
                            <p className={textCss?.detailsValue}>{warehouse?.pincode ? `${warehouse?.pincode}` : ''}</p>
                        </div>
                        <DetailTag tagHeadClass={textCss?.detailsTitle} tagValueClass={textCss?.detailsValue+  + ' line-clamp-3'} tagClass="px-2 w-6/12" tagName="City & State" tagValue={`${warehouse?.cityDetails?.city_name ? `${warehouse?.cityDetails?.city_name},` : ''} ${warehouse?.stateDetails?.state_name ? warehouse?.stateDetails?.state_name : ''}`} />
                    </div>

                    {warehouseContact ? <div className="md:flex pl-5 pt-6">
                        <DetailTag tagHeadClass={textCss?.detailsTitle} tagValueClass={textCss?.detailsValue  + ' line-clamp-3'} tagClass="px-2 w-6/12 " tagName="Contact Name" tagValue={warehouseContact?.name ? warehouseContact?.name : ''} />
                        <DetailTag tagHeadClass={textCss?.detailsTitle} tagValueClass={textCss?.detailsValue  + ' line-clamp-3'} tagClass="px-2 w-6/12 " tagName="Contact Mobile" tagValue={warehouseContact?.user?.mobile ? warehouseContact?.user?.mobile : ''} />
                        <DetailTag tagHeadClass={textCss?.detailsTitle} tagValueClass={textCss?.detailsValue  + ' line-clamp-3'} tagClass="px-2 w-6/12" tagName="Contact Email" tagValue={warehouseContact?.user?.email ? warehouseContact?.user?.email : ''} />

                    </div> : <></>}

                <div className='px-3 flex justify-between'>
                    <Input label='For' required name='fk_team_id' type={'dropdown'} options={customerTeamsDropDown} placeholder={'For'} className={'w-6/12'} isLoading={customerTeamsLoading} />
                    <Input maxLength={20} label='Title (Character limit 20)' required name='title' type={'text'} placeholder={'title'} className={'w-6/12 mt-1.5'} />
                </div>
                <div className='px-3 flex justify-between'>
                    <Input label='Ticket Description' required name='description' type={'text'} options={customerTeams} placeholder={'Ticket Description'} className={'w-6/12'} />
                    <Input label='External Ticket Id' name='external_ticket_id' type={'text'} placeholder={'external_ticket_id'} className={'w-6/12'} />
                </div>
                <div className='px-3 flex justify-between'>
                    <Input label='Send Updates to Users (use , for multiple emails)' name='related_emails' type={'text'} options={customerTeams} placeholder={'Users emails'} className={'w-full'} />
                </div>
            </Form>

        </>
    );
}

export default CreateReconcileTicketSpareAsset;