import React, { useEffect, useState, useCallback } from 'react';
import LeftMessage from './LeftMessage';
import RightMessage from './RightMessage';
import TextMessage from './TextMessage';
import AttachmentMessage from './AttachmentMessage';
import EventMessage from './EventMessage';
import ReplyMessage from './ReplyMessage';
import { getCodeBlocks, getLookupWitHDefaultValue } from '../../../../utils/common';
import { linkify } from '../../../../utils/common';
import { botMessageType, GetChatLogic, messageType } from '../../ChatController';
import { useDispatch } from 'react-redux';
import { sendDataThroughWebsocket } from '../../../../actions/websocket';
import { uploadFilesToChat } from '../../../../actions/conversations';
import { websocketEvents } from '../../../../utils/websocket';
import CardMessage from './CardMessage';
import { useSelector } from 'react-redux';
import UserPopup from '../../../../common/Popups/User';
import { FormattedGptMessage } from './FormattedGptMessage';

const typeComponentLookup = getLookupWitHDefaultValue({
    lookup: {
        [messageType.message]: TextMessage,
        [messageType.card]: CardMessage,
        [messageType.attachments]: AttachmentMessage,
        [messageType.reply]: ReplyMessage,
    },
    defaultValue: TextMessage,
});

const tagify = (text = '', tags = []) => {
    if (tags.length === 0) return [text];
    const tagComponent = (tag) => {
        return <UserPopup userId={tag?.user?.userId}>
            <span className='text-scogoorange cursor-pointer'>{`@${tag?.user?.username}`}</span>
        </UserPopup>
    };
    const textBeforeFirstTag = text.slice(0, tags[0].position);
    const componentList = [];
    if (textBeforeFirstTag !== '') componentList.push(textBeforeFirstTag);
    tags.forEach((tag, idx) => {
        componentList.push(tagComponent(tag));
        const textAfterTagStartIndex = tag.position + tag?.user?.username?.length + 1;
        let textAfterTagEndIndex = text.length;
        const nextTag = tags[idx + 1];
        if (nextTag) {
            textAfterTagEndIndex = nextTag.position;
        }
        const textAfterTag = text.slice(textAfterTagStartIndex, textAfterTagEndIndex);
        componentList.push(textAfterTag);
    });
    return componentList;
};

export const formatTextWithCodeBlocks = ({ text = '', tags = [] }) => {
    let finalMsgList = [];
    let tagifiedMsgList = tagify(text, tags);
    tagifiedMsgList.forEach((segment) => {
        if (typeof segment === 'string' && segment.trim().startsWith('##')) {
            segment = segment.split(' ');
            let message = <span className='text-scogobgsky'>{segment.slice(0, 1)}</span>;
            finalMsgList.push(message);
            finalMsgList.push(` ${segment.slice(1).join(' ')}`);
        } else if (typeof segment === 'string') {
            const codeBlocks = getCodeBlocks(segment);
            codeBlocks.forEach((block) => {
                if (typeof block === 'string') {
                    finalMsgList = [...finalMsgList, ...linkify(block)];
                } else {
                    finalMsgList.push(block);
                }
            });
        } else {
            finalMsgList.push(segment);
        }
    });
    return finalMsgList;
};

export const formatText = ({ text = '', tags = [] }) => {
    let finalMsgList = [];
    const tagifiedMsgList = tagify(text, tags);
    tagifiedMsgList.forEach((segment) => {
        if (typeof segment != 'string') finalMsgList.push(segment);
        else finalMsgList = [...finalMsgList, ...linkify(segment)];
    });
    return finalMsgList;
};

let greetOptions = [
    { text: 'Payment', category: 'root' },
    { text: 'Wallet', category: 'root' },
    { text: 'Approvals', category: 'root' },
    { text: 'Ticket', category: 'root' },
    { text: 'App/Web issues', category: 'root' },
    { text: 'How to Scogo', category: 'root' },
    { text: 'Others', trigger: 'bot-disable-assign' },
];

const likeDislikeOption = [{ text: '👍' }, { text: '👎' }];

function Message({ side = 'right', type = 'message', message, onReplyClick, selectedMessageId, replyShow, onClickReplyMessage, createdBy, showMessageAction, toEnableChip, showReplyIcon, messageOnclick = () => { }, isSearchMessage, showJumbIcon = false, callStepFields, ticketId, isChatPopup, isTicketChat }) {
    let messageActions = GetChatLogic('messageAction');
    const dispatch = useDispatch();
    const [isEditMessage, setIsEditMessage] = useState(false);
    const [showActions, setShowActions] = useState(showMessageAction);
    const { loggedUser } = useSelector((state) => state.auth);
    const botsMessage = message?.payload?.fulfillmentMessages ? message?.payload?.fulfillmentMessages[0] : false;

    const loggedUserDetails = {
        email: loggedUser.email,
        role: loggedUser.type,
        roleId: loggedUser.role.id,
        userId: loggedUser.id,
        username: `${loggedUser.first_name} ${loggedUser.last_name}`,
    };

    const onClickSendMessage = ({ conversationId, messageText, tags, trigger }) => {
        dispatch(
            sendDataThroughWebsocket({
                action: websocketEvents.chat.sendMessage,
                data: {
                    conversation: conversationId,
                    type: 'message',
                    message: messageText,
                    trigger,
                    tags: tags,
                },
                from: loggedUserDetails,
            })
        );
    };

    const chips = (conversationId) => {
        if (!toEnableChip) {
            return <></>;
        }
        let options = [];
        if (botsMessage?.message === 'payload') {
            const { input } = botsMessage.payload;
            if (input.type === botMessageType.Greet) {
                options = greetOptions;
            } else if (input.type === botMessageType.FAQ) {
                options = likeDislikeOption;
            } else if (input.type === botMessageType.Video) {
                options = likeDislikeOption;
            } else {
                options = input?.options;
            }
        }
        return (
            <div className="w-2/3 " >
                <ul className='flex-wrap flex list-none mt-2 gap-2'>
                    {options.map((option, index) => {
                        return (
                            <li
                                key={index}
                                className='text-center text-font10 hover:text-white text-gray-600 border-gray-400  hover:bg-scogoorange hover:border-scogoorange w-auto px-2 py-2 border-2 rounded-full '
                                onClick={() =>
                                    onClickSendMessage({
                                        conversationId: conversationId,
                                        messageText: option.text,
                                        helpCategory: option.category === 'root' ? option.text : undefined,
                                        trigger: option.trigger,
                                    })
                                }
                            >
                                <span className={' font-medium  leading-normal cursor-pointer '}>{option.text}</span>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    };

    useEffect(() => {
        let show = true;
        let isCurrentMessageSelected = replyShow && selectedMessageId === message._id;
        if (isEditMessage || isCurrentMessageSelected || !message.timestamp || !showMessageAction) show = false;
        setShowActions(show);
    }, [message, isEditMessage, replyShow, selectedMessageId, showMessageAction]);

    const onRetryMessage = useCallback(
        (message) => {
            const conversationId = message.conversation;
            if ([messageType.message, messageType.reply].includes(message.type)) {
                dispatch(
                    sendDataThroughWebsocket({
                        action: websocketEvents.chat.sendMessage,
                        data: {
                            conversation: conversationId,
                            type: message.type,
                            message: message.message,
                            repliedTo: message.repliedTo,
                            messageId: message.messageId,
                        },
                        from: message.from,
                    })
                );
            }
            if (message.type === messageType.attachments) {
                dispatch(
                    uploadFilesToChat({
                        _id: conversationId,
                        fileData: message.fileData,
                        from: message.from,
                        messageId: message.messageId,
                    })
                );
            }
        },
        [dispatch]
    );

    useEffect(() => {
        if (!message.isEditLoading) setIsEditMessage(false);
    }, [message]);

    const sideComponetLookup = {
        left: LeftMessage,
        right: RightMessage,
    };

    const isScogoGptChat = message.conversation.type === 'gpt';
    const SideComponent = sideComponetLookup[side];
    let TypeComponent = typeComponentLookup[type];
    if (isScogoGptChat) TypeComponent = FormattedGptMessage;

    let messageBody = <></>;

    const getMessageClass = () => {
        let className = 'py-4 messagesideComponent';
        if (selectedMessageId && message?._id && selectedMessageId === message?._id) className += ` bg-blue-200`;
        return className;
    };

    if (type === 'event') {
        messageBody = <EventMessage message={message} isChatPopup={isChatPopup} />;
    } else {
        messageBody = (
            <div className={getMessageClass()}>
                <SideComponent
                    message={message}
                    onReplyClick={onReplyClick}
                    chips={chips}
                    messageActions={messageActions}
                    onRetryMessage={onRetryMessage}
                    isEditMessage={isEditMessage}
                    setIsEditMessage={setIsEditMessage}
                    showActions={showActions}
                    showReplyIcon={showReplyIcon && !isScogoGptChat}
                    fullWidth={message.type === messageType.card}
                    messageOnclick={messageOnclick}
                    isSearchMessage={isSearchMessage}
                    showJumbIcon={showJumbIcon}
                    ticketId={ticketId}
                >
                    {(propsBasedOnSide) => (
                        <TypeComponent
                            {...propsBasedOnSide}
                            onClickReplyMessage={onClickReplyMessage}
                            createdBy={createdBy}
                            message={message}
                            formatText={formatText}
                            formatTextWithCodeBlocks={formatTextWithCodeBlocks}
                            isSearchMessage={isSearchMessage}
                            callStepFields={callStepFields}
                            isTicketChat={isTicketChat}
                            isChatPopup={isChatPopup}
                        />
                    )}
                </SideComponent>{' '}
            </div>
        );
    }
    return <div id={message._id} className='message_class_for_scroll'>{messageBody}</div>;
}

export default Message;
