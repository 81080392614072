import ActiveOrangeSwitch from "./Switch";
import React from 'react';

export const RenderSwitchButton = ({ setGroupView, isGroupView, leftLabel = 'List View', rightLabel = 'Group View' }) => {
    return <div className='flex items-center'>
        <span className={`${!isGroupView ? 'text-scogoprimary' : 'text-scogogray'} font-medium text-[1.2rem]`}>{leftLabel}</span>
        <ActiveOrangeSwitch
            onChange={(event) => {
                setGroupView(event.target.checked);
            }}
            isChecked={isGroupView}
        />
        <span className={`${isGroupView ? 'text-scogoprimary' : 'text-scogogray'} font-medium text-[1.2rem]`}>{rightLabel}</span>
    </div>
}