import { useFormContext, Controller } from 'react-hook-form';
import _ from 'lodash';
import { ReactMultiEmail, isEmail } from 'react-multi-email';

const MultipleEmails = ({ label, name, required, placeholder, className = '', shouldUnregister, classNameEmail = '' }) => {
    const {
        formState: { errors },
        control,
    } = useFormContext();

    return (
        <div className={`px-4 py-2 ${className}`}>
            {label && (
                <label>
                    <span className='text-scogo99 font-normal text-font11'>
                        {label} {required && <span className='text-scogoorange'>*</span>}{' '}
                    </span>
                </label>
            )}
            <div className=' relative'>
                <Controller
                    key={name}
                    control={control}
                    name={name}
                    rules={{ required: { value: required, message: 'Required' } }}
                    shouldUnregister={shouldUnregister}
                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                        <ReactMultiEmail
                            style={{
                                padding: '1em 0.5em',
                                maxHeight: '11vh',
                                overflowY: 'auto',
                            }}
                            placeholder={placeholder}
                            emails={value}
                            onChange={onChange}
                            className={classNameEmail}
                            validateEmail={isEmail}
                            getLabel={(item, index, remove) => {
                                return (
                                    <div
                                        data-tag
                                        key={index}
                                        style={{
                                            background: 'rgba(244, 138, 33, var(--tw-bg-opacity))',
                                            color: 'white',
                                            border: 'none',
                                            fontSize: '1rem'
                                        }}
                                    >
                                        {item}
                                        <span data-tag-handle onClick={() => remove(index)}>
                                            ×
                                        </span>
                                    </div>
                                );
                            }}
                        />
                    )}
                />
                <p className='text-scogoclosed text-font08 font-normal inputFontSize h-6'>{_.get(errors, name) ? _.get(errors, name).message : ''}</p>
            </div>
        </div>
    );
};

export default MultipleEmails;
