import { call, put, takeEvery, select, take, takeLatest, all } from 'redux-saga/effects';
import { auth, loading, modal, onboarding, toast, user } from '../types';
import api from '../apis';
import lambdas from '../lambdas/apis';
import { uploadFileToS3WithSignedUrl } from '../utils/common';
import { getDomainAvailabilityStatus } from '../selectors/onboarding';
import { getLoggedUser } from '../selectors/users';
import { UPDATE_USER_PROFILE_DETAILS_FAILURE } from '../types/onboarding';
import { spOnboard } from '../router/roleWisePageRoute';
import { bankForm, businessForm } from '../router/constants';

export function* getSPOnboardingStatus(action) {
    try {
        const response = yield call(api.auth.getSPOnboardingStatus, {});
        if (response.data.code === 200) {
            yield put({ type: onboarding.SP_ONBOARDING_STATUS_SUCCESS, payload: response.data.data });
        } else if (response.data.code === 400) {
            let data = {
                bank_detail: false,
                company_detail: false,
            };
            yield put({ type: onboarding.SP_ONBOARDING_STATUS_SUCCESS, payload: data });
        } else {
            yield put({ type: onboarding.SP_ONBOARDING_STATUS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: onboarding.SP_ONBOARDING_STATUS_FAILURE, payload: error });
    }
}

export function* checkDomainAvailability(action) {
    const { domain } = action.payload;
    try {
        yield put({ type: loading.IS_CHECK_DOMAIN_AVAILABE_CHECK_LOADING, payload: true });
        const response = yield call(lambdas.admin.checkDomainAvailability, {}, {}, {}, { domain });
        let status = '';
        let message = '';
        yield put({ type: onboarding.CHECK_DOMAIN_AVAILABILITY_SUCCESS, payload: response.data.data });
        if (response.data.code === 200) {
            status = 'success';
            message = response.data.data.message;
        } else if ([400, 500].includes(response.data.code)) {
            status = 'danger';
            message = response.data.data.message;
        } else {
            status = 'danger';
            message = 'Oops Something went wrong';
        }
        yield put({ type: toast.SEND_TOAST, payload: { status, message } });
    } catch (error) {
        yield put({ type: onboarding.CHECK_DOMAIN_AVAILABILITY_FAILURE, payload: error });
    }
    yield put({ type: loading.IS_CHECK_DOMAIN_AVAILABE_CHECK_LOADING, payload: false });
}

export function* saveCustomerOnboardingDetails(action) {
    let uploadCustomerLogo = false;
    const { formValues, navigate } = action.payload;
    let data = { ...formValues };
    if (formValues.company_logo) {
        uploadCustomerLogo = true;
        data.company_logo = formValues.company_logo.name;
    }
    try {
        const userDetails = yield select(getLoggedUser);
        yield put({ type: loading.IS_CUSTOMER_ONBOARDING_LOADING, payload: true });
        const response = yield call(lambdas.user.onboardUser, {}, { role: userDetails.role.id }, {}, data);
        if (response.data.code === 201) {
            let status = 'success',
                message = 'Customer Onboarded Successfully';
            yield put({ type: onboarding.SAVE_CUSTOMER_ONBOARDING_DETAILS_SUCCESS, payload: response.data.customer });
            if (response.data.uploadLogoUrl && uploadCustomerLogo) {
                yield call(uploadFileToS3WithSignedUrl, response.data.uploadLogoUrl, formValues.company_logo);
            }
            let domainStatus = yield select(getDomainAvailabilityStatus);
            let isCreateDomain = formValues.createDomain && formValues.domain && domainStatus?.available;
            if (isCreateDomain) {
                yield put({ type: onboarding.CREATE_CUSTOMER_DOMAIN_REQUEST, payload: { domain: formValues.domain } });
            } else {
                yield put({ type: toast.SEND_TOAST, payload: { status, message } });
            }
            yield put({ type: auth.USER_PROFILE_REQUEST, payload: { navigate } });
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
            yield put({ type: onboarding.SAVE_CUSTOMER_ONBOARDING_DETAILS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: onboarding.SAVE_CUSTOMER_ONBOARDING_DETAILS_FAILURE, payload: error });
    }
    yield put({ type: loading.IS_CUSTOMER_ONBOARDING_LOADING, payload: false });
}

export function* createCustomerDomain({ domain }) {
    try {
        const response = yield call(lambdas.admin.createDomain, {}, {}, {}, { domain });
        let message = '',
            status = '';
        if (response.data.code === 200) {
            status = 'success';
            message = response.data.data.message;
            yield put({ type: onboarding.CREATE_CUSTOMER_DOMAIN_SUCCESS, payload: response.data.data });
        } else if ([400, 500].includes(response.data.code)) {
            status = 'danger';
            message = response.data.data.message;
        } else {
            yield put({ type: onboarding.CREATE_CUSTOMER_DOMAIN_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
        yield put({ type: toast.SEND_TOAST, payload: { status, message } });
    } catch (error) {
        yield put({ type: onboarding.CREATE_CUSTOMER_DOMAIN_FAILURE, payload: error });
    }
}

export function* updateUsersEmail(action) {
    yield put({ type: loading.EMAIL_VERIFICATION_LOADING, payload: true });
    const { email, type } = action.payload;
    const data = {
        email: email,
        type: type ? type : '',
    };

    try {
        const response = yield call(api.auth.updateEmail, {}, {}, {}, data);
        if (response.data.code === 201 || response.data.code === 200) {
            yield put({ type: onboarding.UPDATE_NEW_USER_EMAIL_SUCCESS, payload: true });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Verification mail sent this email id' } });
        } else {
            if (response.data.code === 400) {
                yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'The email has already been taken' } });
            }
            yield put({ type: auth.AUTH_ERROR, payload: response.data.message });
        }
    } catch (error) {
        yield put({ type: onboarding.UPDATE_NEW_USER_EMAIL_FAILURE, payload: error });
    }
    yield put({ type: loading.EMAIL_VERIFICATION_LOADING, payload: false });
}

export function* updateUserProfileDetails(action) {
    const { email, type, dob = '', first_name = '', last_name = '', gender = '', pancard_number, navigate, profileImage } = action.payload;

    const updateEmailPayload = {
        email: email,
        type: type ? type : '',
    };

    let profileDataPayload = {
        dob,
        first_name,
        last_name,
        gender,
        type,
        pancard_number
    };

    yield put({ type: loading.USER_PROFILE_DETAILS_LOADING, payload: true });
    try {
        const updateEmailResponse = yield call(api.auth.updateEmail, {}, {}, {}, updateEmailPayload);
        let updateProfileImage;
        if (profileImage) {
            let form = new FormData();
            if (profileImage.length > 0) form.set('profile_image', profileImage[0]);
            updateProfileImage = yield call(api.user.uploadProfilePic, { 'Content-Type': 'multipart/form-data' }, {}, {}, form);
        }

        let isSuccses = [200, 201].includes(updateEmailResponse.data.code);
        if (updateProfileImage) isSuccses = isSuccses && updateProfileImage.data.code === 201

        if (isSuccses) {
            const updateProfileResponse = yield call(api.auth.updateProfile, {}, {}, {}, profileDataPayload);
            if (updateProfileResponse.data.code === 201) {
                navigate(`${spOnboard.path}?step=${businessForm.path}`);
                yield all([
                    yield put({ type: onboarding.UPDATE_USER_PROFILE_DETAILS_SUCCESS, payload: { updatedEmail: email, isUserProfileUpdated: true, type, pancard_number, first_name, last_name } }),
                    yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Profile Update Successfully' } }),
                ]);
            } else {
                yield all([
                    yield put({ type: onboarding.UPDATE_USER_PROFILE_DETAILS_FAILURE, payload: { message: 'Oops Something went wrong' } }),
                    yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Profile Update Error' } }),
                ]);
            }
        } else {
            if (updateEmailResponse.data.code === 400) {
                yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'The email has already been taken' } });
            }
            if (updateProfileImage.data.code !== 201) {
                yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'File Type Not Supported!!!' } });
            }
            yield put({ type: onboarding.UPDATE_USER_PROFILE_DETAILS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: UPDATE_USER_PROFILE_DETAILS_FAILURE, payload: error });
    }
    yield put({ type: loading.USER_PROFILE_DETAILS_LOADING, payload: false });
}

export function* updateUserBussinessDetails(action) {
    const { locationFormData, userFormData, servicePartnerId, cities, navigate, redirect = true } = action.payload;
    yield put({ type: loading.USER_BUSSINESS_DETAILS_LOADING, payload: true });
    try {
        if (cities.length > 0) {
            const locationResponse = yield call(api.SP.addCities, {}, { id: servicePartnerId }, {}, locationFormData);
            if (locationResponse.data.code === 201) {
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Location Updated Successfully' } });
            }
        }
        const response = yield call(api.SP.updateDetails, {}, {}, {}, userFormData);
        if (response.data.code === 201) {
            if (redirect) {
                yield all([
                    yield put({ type: onboarding.UPDATE_USER_BUSSINESS_DETAILS_SUCCESS, payload: true }),
                    yield put({ type: auth.USER_PROFILE_REQUEST, payload: { navigate } }),
                    yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Bussiness Details Updated Successfully' } }),
                ]);
                yield take([auth.USER_PROFILE_SUCCESS, auth.USER_PROFILE_FAILURE]);
                if (redirect) navigate(`${spOnboard.path}?step=${bankForm.path}`);
            } else {
                if (redirect) navigate(`${spOnboard.path}?step=${businessForm.path}`);
            }
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Oops Something went wrong' } });
            yield put({ type: onboarding.UPDATE_USER_BUSSINESS_DETAILS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: onboarding.UPDATE_USER_BUSSINESS_DETAILS_FAILURE, payload: error });
    }
    yield put({ type: loading.USER_BUSSINESS_DETAILS_LOADING, payload: false });
}

export function* updateUserBankDetails(action) {
    const { bankFormData, servicePartnerId, isRedirect, navigate, bankVerificationPayload } = action.payload;
    yield put({ type: loading.USER_BANK_DETAILS_LOADING, payload: true });
    try {
        let bankVerification = { type: user.BANK_VERIFICATION_SUCCESS };
        yield put({ type: user.BANK_VERIFICATION_REQUEST, payload: bankVerificationPayload });
        bankVerification = yield take([user.BANK_VERIFICATION_SUCCESS, user.BANK_VERIFICATION_FAILURE]);
        if (bankVerification.type === user.BANK_VERIFICATION_SUCCESS) {
            const response = yield call(api.user.saveBankDetails, {}, { id: servicePartnerId }, {}, bankFormData);
            if (response.data.code === 201) {
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Bank Details Updated Successfully' } });
                if (isRedirect) {
                    yield put({ type: auth.USER_PROFILE_REQUEST, payload: { navigate } });
                }
            }
        } else {
            yield put({ type: onboarding.UPDATE_USER_BANK_DETAILS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: onboarding.UPDATE_USER_BUSSINESS_DETAILS_FAILURE, payload: error });
    }
    yield put({ type: loading.USER_BANK_DETAILS_LOADING, payload: false });
}

export function* skipBankDetails(action) {
    const { navigate } = action.payload;
    try {
        const response = yield call(api.user.skipBankDetails, {});
        if (response.data.code === 200) {
            yield put({ type: auth.USER_PROFILE_REQUEST, payload: { navigate } });
        } else {
            yield put({ type: onboarding.ON_SKIP_BANK_DETAILS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: onboarding.ON_SKIP_BANK_DETAILS_FAILURE, payload: error });
    }
}

export function* updateUserProfileBussinessDetails(action) {
    const { locationFormData, userFormData, servicePartnerId, formName, cities } = action.payload;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    try {
        if (cities.length > 0) {
            const locationResponse = yield call(api.SP.addCities, {}, { id: servicePartnerId }, {}, locationFormData);
            if (locationResponse.data.code === 201) {
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Location Updated Successfully' } });
            }
        }
        const response = yield call(api.SP.updateDetails, {}, {}, {}, userFormData);
        if (response.data.code === 201) {
            yield all([
                yield put({ type: onboarding.UPDATE_USER_PROFILE_BUSSINESS_DETAILS_SUCCESS, payload: response.data.data }),
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Profile Bussiness Details Updated Successfully' } }),
            ]);
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Oops Something went wrong' } });
            yield put({ type: onboarding.UPDATE_USER_PROFILE_BUSSINESS_DETAILS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: onboarding.UPDATE_USER_PROFILE_BUSSINESS_DETAILS_FAILURE, payload: error });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}


export function* saveFEOnboardingDetails(action) {
    const { form, navigate, formName, addressProofType, addressProofNumber, proofImage, fileUrlBack } = action.payload
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } })
        // updating address proof
        yield put({ type: user.UPLOAD_USER_ID_PROOF_FRONT_BACK_REQUEST, payload: { addressProofType, addressProofNumber, proofImage, fileUrlBack } })

        let aadhaarImage = yield take([user.UPLOAD_USER_ID_PROOF_FRONT_BACK_SUCCESS, user.UPLOAD_USER_ID_PROOF_FRONT_BACK_FAILURE])
        if (aadhaarImage.type === user.UPLOAD_USER_ID_PROOF_FRONT_BACK_SUCCESS) {
            const response = yield call(api.FE.onboard, {}, {}, {}, form);
            if (response.data.code === 201) {
                yield put({ type: onboarding.SAVE_FE_ONBOARDING_DETAILS_SUCCESS, payload: response.data })
                yield put({ type: auth.USER_PROFILE_REQUEST, payload: { navigate } });
            } else {
                yield put({ type: onboarding.SAVE_FE_ONBOARDING_DETAILS_FAILURE, payload: { message: 'Oops Something went wrong' } })
            }
        } else {
            // do something
        }

    } catch (error) {
        yield put({ type: onboarding.SAVE_FE_ONBOARDING_DETAILS_FAILURE, payload: error })
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export default function* onboardingSaga() {
    yield takeEvery(onboarding.SP_ONBOARDING_STATUS_REQUEST, getSPOnboardingStatus);
    yield takeEvery(onboarding.CHECK_DOMAIN_AVAILABILITY_REQUEST, checkDomainAvailability);
    yield takeEvery(onboarding.SAVE_CUSTOMER_ONBOARDING_DETAILS_REQUEST, saveCustomerOnboardingDetails);
    yield takeEvery(onboarding.CREATE_CUSTOMER_DOMAIN_REQUEST, createCustomerDomain);
    yield takeEvery(onboarding.UPDATE_NEW_USER_EMAIL_REQUEST, updateUsersEmail);
    yield takeEvery(onboarding.UPDATE_USER_PROFILE_DETAILS_REQUEST, updateUserProfileDetails);
    yield takeEvery(onboarding.UPDATE_USER_BUSSINESS_DETAILS_REQUEST, updateUserBussinessDetails);
    yield takeEvery(onboarding.UPDATE_USER_BANK_DETAILS_REQUEST, updateUserBankDetails);
    yield takeLatest(onboarding.ON_SKIP_BANK_DETAILS_REQUEST, skipBankDetails);
    yield takeLatest(onboarding.UPDATE_USER_PROFILE_BUSSINESS_DETAILS_REQUEST, updateUserProfileBussinessDetails);
    yield takeLatest(onboarding.SAVE_FE_ONBOARDING_DETAILS_REQUEST, saveFEOnboardingDetails);
}
