import ShareIcon from '@mui/icons-material/Share';
import { Popover } from '@mui/material';
import { useState } from 'react';
import IconToolTip from '../../../common/IconToolTip';
import { useDispatch } from 'react-redux';
import { copyToClipboard } from '../../../actions/utils';
import { generateRoomLink } from './constants';
import { useParams } from 'react-router-dom';
import { GetQueryParam } from '../../../utils/common';

export const ShareLinkPopover = () => {

    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const { room_id } = useParams();
    const openedConversationId = GetQueryParam('openedConversationId', String);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <>
            <IconToolTip title="Settings">
                <div className='h-full flex items-center' onClick={handleClick}>
                    <ShareIcon sx={{ fontSize: 28 }} className='hover:cursor-pointer hover:text-gray-600 transition-all' />
                </div>
            </IconToolTip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}>
                <div className='w-[32rem] h-[18rem] p-5 flex flex-col justify-evenly items-center'>
                    <div>
                        <span className=' text-font24'>Your Meeting's Ready</span>
                    </div>
                    <div>
                        <span className='text-xl'>
                            Share this link with others you want in the meeting
                        </span>
                    </div>
                    <div className='flex justify-center items-center gap-8  bg-slate-200 p-5 rounded-lg hover:cursor-pointer'
                        onClick={() => {
                            dispatch(copyToClipboard({ data: generateRoomLink(room_id, openedConversationId) }));
                            handleClose();
                        }}
                    >
                        <span className='text-xl'>Copy Share Link</span>
                        <span
                            className='material-icons text-scogobgsky hover:text-scogoorange text-font16 cursor-pointer'
                        >
                            content_copy
                        </span>
                    </div>
                </div>
            </Popover>
        </>
    )
}
