import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getCount } from '../actions/users';
import { formatCount } from '../utils/common';

export const SidebarNotification = ({ countKey }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getCount({ countKey }));
    }, [dispatch, countKey]);
    const user = useSelector((state) => state.user);
    const count = user?.[countKey];

    if (count > 0)
        return (
            <span className={`absolute text-white text-sm flex items-center justify-center transform bg-scogoorange rounded-full left-[3.8rem] top-[-0.2rem] px-1`}>
                {formatCount(count)}
            </span>
        );
    else return <></>;
};
