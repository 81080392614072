import React from 'react';
import { isMobileView } from '../auth/components/NewScogoButton';

export default function ChooseLocation({ selectedAddress, confirmAddress, selectedCity }) {
    return (<div className="px-2 py-4 h-[20vh] flex flex-col justify-between">
        <div>
            <div className=" flex justify-between">
                <span className="flex items-center">
                    <span className="material-icons-outlined text-scogoorange">
                        pin_drop
                    </span> <span className="font-bold text-3xl">{selectedCity} </span>
                </span>

                {isMobileView && <span className="border-1 border-scogoddd bg-scogoeee text-scogoorange rounded-md px-2 py-1 font-medium flex items-center" onClick={confirmAddress} >CHANGE</span>}
            </div>

            <div className="pt-2 pl-1 text-lg" onClick={isMobileView ? confirmAddress : () => { }}>{selectedAddress}</div>
        </div>
        <ButtonScogoOrangeFilled buttonText={'CONFIRM LOCATION'} buttonOnClick={confirmAddress} />
    </div>)
}

export const ButtonScogoOrangeFilled = ({ buttonText, buttonOnClick }) => {
    return (<div onClick={buttonOnClick} className="bg-scogoorange text-white h-12  rounded-md flex items-center w-full font-medium justify-center text-xl cursor-pointer" >{buttonText}</div>)
}