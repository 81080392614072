import BaseTable, { Column, AutoResizer } from 'react-base-table';
import 'react-base-table/styles.css';
import Loader from './Loader';
import NoResultFound from './NoResultFound';
import Paginate from './Paginate';
function scale(number, inMin, inMax, outMin, outMax) {
    return ((number - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
}
const FrozenTable = ({
    rowsData,
    schema,
    isLoading,
    tablePagination,
    count,
    tablePaginationHandler,
    rowsPerPage,
    setRowsPerPage,
    page,
    handleOnChangeCheckbox,
    checkedTicketTracker,
    tableError,
    frozenColumnNum,
}) => {
    const humaraColumns = schema.table.map((column, columnIndex) => {
        let frozen;
        if (columnIndex < frozenColumnNum) {
            frozen = Column.FrozenDirection.LEFT;
        } else if (columnIndex === schema.table.length - 1) {
            frozen = Column.FrozenDirection.RIGHT;
        }
        return {
            key: column.head.key,
            width: column.head.width,
            frozen,
            resizable: true,
            title: column.head.headName,
            headerRenderer: ({ column: c }) =>
                column.head.render ? column.head.render(column.head.headName, c.checkedTicketTracker, c.handleOnChangeCheckbox) : <div>{column.head.headName}</div>,
            cellRenderer: ({ rowData, rowIndex, column: c }) => column.body.render(rowData, rowIndex, c.checkedTicketTracker, c.handleOnChangeCheckbox),
            handleOnChangeCheckbox,
            checkedTicketTracker,
        };
    });
    let rowCount = scale(window.innerHeight, 600, 1200, 2.6, 4);
    return (
        <>
            <div className={`tableContainer rounded-lg ${tablePagination ? 'paginatedTable' : 'nonPaginatedTable'}`}>
                <AutoResizer>
                    {({ width, height }) => (
                        <BaseTable
                            headerClassName='font-medium text-scogogray frozenTableHeader'
                            fixed
                            data={rowsData}
                            width={width}
                            height={height}
                            columns={humaraColumns}
                            ignoreFunctionInColumnCompare={false}
                            rowKey='ticket_id'
                            headerHeight={30}
                            rowHeight={(height - 108) / rowCount}
                            emptyRenderer={() => emptyRenderer(isLoading, tableError)}
                        />
                    )}
                </AutoResizer>
            </div>
            {tablePagination && (
                <Paginate count={count} onPageChange={tablePaginationHandler} entriesPerPage={rowsPerPage ? rowsPerPage : 0} setRowsPerPage={setRowsPerPage} page={page} />
            )}
        </>
    );
};

const emptyRenderer = (isLoading, tableError) => {
    if (isLoading) {
        return (
            <div className='border border-white'>
                <Loader color='#F48A21' size='65' speed='1' thickness='3' margin='150px' />
            </div>
        );
    }
    return <NoResultFound message={tableError} />;
};

export default FrozenTable;
