import React, { useEffect, useState } from 'react';
import Form from '../../Inventory/reusableComponents/AddOption/Form';
import Input from '../../Inventory/reusableComponents/AddOption/Input';
import { useDispatch, useSelector } from 'react-redux';
import { getFieldEngineersList } from '../../../actions/fe';
import GridTable from '../../../common/GridTable';
import { tableHead } from '../../../common/tabeHelpers';
import RatingComponent from '../../../common/Rating';
import { acceptSPAssignedTicket } from '../../../actions/ticketAssignment';
import { isValidMobileNumber } from '../../../utils/utils';
import { SkeltonCardTwo } from '../../../common/SkeltonCardTwo';
import moment from 'moment';

export default function AssignFE(props) {
    const dispatch = useDispatch();
    const { userDetails, ticketDetails, closeModal, fk_cluster_id } = props;
    const { remarks } = useSelector((state) => state.tickets);
    const { isFieldEngineersLoading } = useSelector((state) => state.loading);
    const { fieldEngineers } = useSelector((state) => state.fe);
    const [spid, setSPID] = useState(null);
    const { loggedUser } = useSelector((state) => state.auth);
    const [isDateChaged, setIsDateChanged] = useState(false);

    useEffect(() => {
        dispatch(getFieldEngineersList({ servicePartnerId: userDetails?.sp_id }));
    }, []);

    const getdate = () => {
        if (ticketDetails.execution_date !== null && ticketDetails.execution_date !== '' && ticketDetails.execution_date !== '0000-00-00 00:00:00') {
            let inputDate = ticketDetails.execution_date.split('-');
            let inputTime = ticketDetails.execution_date.split(' ')[1].split(':');
            if (inputDate[0] === '0000' || inputDate[1] === '00' || inputDate[2] === '00') {
                return null;
            } else {
                let year = parseInt(inputDate[0]);
                let month = parseInt(inputDate[1]) - 1;
                let date = parseInt(inputDate[2]);
                let hour = parseInt(inputTime[0]);
                let minutes = parseInt(inputTime[1]);
                let seconds = parseInt(inputTime[2]);

                return new Date(year, month, date, hour, minutes, seconds, 0);
            }
        } else {
            return '';
        }
    };

    const initialValues = {
        execution_date: getdate(),
        execution_date_remark: '',
        first_name: '',
        mobile: '',
        fe_id: '',
    };

    const [form, setForm] = useState(initialValues);

    const assignSPManually = ({ data }) => {
        console.log(data, 'Data');
    };

    const submit = (formValues) => {
        const { assignMe, ticketId, spIdRequired, type, selectedTickets, order_by, userId } = props.data;
        const loggedUserId = loggedUser.id;
        const executionDate = formValues?.execution_date && moment(formValues?.execution_date).format('YYYY-MM-DD HH:mm:ss');
        const selectedRemark = formValues?.execution_date_remark?.value;
        let tickets = [ticketId];
        const fe_id = formValues?.fe_id;
        const first_name = formValues?.first_name?.split(' ')[0];
        const last_name =
            formValues?.first_name?.split(' ')?.length > 1
                ? formValues?.first_name?.split(' ')?.length > 2
                    ? formValues?.first_name?.substring(formValues?.first_name?.indexOf(' ') + 1)
                    : formValues?.first_name?.split(' ')[1]
                : ' ';
        const mobile = formValues?.mobile;

        if (!isNaN(formValues?.sp) && parseInt(formValues?.sp) > 0 && parseInt(formValues?.sp) !== loggedUserId) {
            const data = {
                property: 'REASSIGN',
                tickets,
                sp: formValues?.sp,
                execution_date: executionDate ? moment(executionDate).format('DD-MM-YYYY HH:mm:ss') : null,
                ticket_instruction: selectedRemark ? selectedRemark : '',
            };
            assignSPManually({ data });
        } else {
            let transactionalFe = undefined;
            let property = 'ACCEPT';
            if (fe_id === 'transactional') {
                property = 'TRANSACTIONAL';
                transactionalFe = {
                    first_name,
                    last_name,
                    mobile,
                };
            } else if (['bulkassignefe'].includes(type)) {
                tickets = [];
                Object.keys(selectedTickets).map((ticketId) => {
                    if (selectedTickets[ticketId]) {
                        tickets.push(parseInt(ticketId));
                    }
                });
                property = 'CUSTOMER_FE';
            } else if (['reassignefe'].includes(type)) {
                property = 'REASSIGN';
            }
            let feId, spId;
            if (fe_id && !isNaN(fe_id)) {
                feId = parseInt(fe_id);
            }
            if (assignMe) {
                feId = parseInt(loggedUserId);
            }

            if (typeof spIdRequired === 'boolean' && spIdRequired) {
                spId = userId;
            }
            let data = {
                property,
                tickets,
                feId,
                spId,
                execution_date: executionDate,
                execution_date_remark: selectedRemark ? selectedRemark : '',
                transactionalFe,
            };
            dispatch(
                acceptSPAssignedTicket({
                    data,
                    order_by,
                    ticket: ticketDetails,
                    userDetails: loggedUser,
                    fk_cluster_id,
                })
            );
        }
    };

    const handleSelectFE = (event, item) => {
        if (!item) {
            setForm({ ...form, fe_id: 'transactional' });
            setSPID(null);
        } else {
            setForm({ ...form, fe_id: item?.fk_user_id });
            setSPID(item?.sp_id);
        }
    };

    const tableSchema = {
        table: [
            {
                head: {
                    headName: 'FE',
                    render: (headName) => headName,
                    width: 1.5,
                },

                body: {
                    render: (item) => {
                        return (
                            <div className='flex gap-2 items-center'>
                                <label className='flex items-center gap-2'>
                                    <input
                                        className='focus:ring-0 focus:ring-offset-0 text-scogoorange h-4 w-4'
                                        type='radio'
                                        name={'table'}
                                        onChange={(e) => handleSelectFE(e, item)}
                                    />
                                    <p className='font-normal'>
                                        {`${item?.user?.first_name} ${item?.user?.last_name} `}
                                        <span className='text-scogoclosed'>*</span>
                                    </p>
                                </label>
                            </div>
                        );
                    },
                },
            },
            {
                head: tableHead('Phone'),
                body: {
                    render: (item) => {
                        return <p className='font-normal'>{item?.user?.mobile}</p>;
                    },
                },
            },
            {
                head: {
                    headName: 'Rating',
                    render: (headName) => headName,
                    width: 0.8,
                },
                body: {
                    render: (item) => {
                        return <RatingComponent rating={item.rating.avg_rating} />;
                    },
                },
            },
        ],
    };

    const tableSkelton = <SkeltonCardTwo arr={[1, 2, 3, 4]} height={20} className={'px-7'} />;

    const customMethodDateChange = (event) => {
        if (event.target.value === null) {
            setIsDateChanged(false);
        } else setIsDateChanged(true);
    };

    // Have to write in Team Controller
    const isShowTable = [4, 6, 7, 12, 13, 14, 15].includes(loggedUser?.role?.id);
    const isShowAssignNewEngineerInputs = form?.fe_id === 'transactional';

    return (
        <Form
            initialValues={initialValues}
            form={form}
            setForm={setForm}
            submit={(e) => {
                submit(e);
            }}
            buttonPostion={'justify-end'}
            submitButtonName={'Submit'}
            onCancel={closeModal}
        >
            <div className='px-3 flex justify-between'>
                <Input
                    className='w-full'
                    label='Execution Date & Time'
                    name='execution_date'
                    required
                    type={'singledatepicker'}
                    customMethod={customMethodDateChange}
                    minDate={new Date()}
                    maxDate={7}
                    showTimeSelect={true}
                />
            </div>
            {isDateChaged && (
                <div className='px-3 flex justify-between'>
                    <Input className='w-full' label='Comment' name='execution_date_remark' required type={'dropdown'} options={remarks} />
                </div>
            )}
            {!isFieldEngineersLoading ? (
                isShowTable ? (
                    <div className='px-7 py-2'>
                        <p className='font-medium text-font12 pb-2'>Select Field Engineer</p>
                        <GridTable schema={tableSchema} rowData={fieldEngineers?.engineer_list} isLoading={false} tableBodyHeight={'h-max'} />
                    </div>
                ) : (
                    <></>
                )
            ) : (
                tableSkelton
            )}

            <div className='px-7 py-4 flex gap-2 items-center'>
                <input
                    className='focus:ring-0 focus:ring-offset-0 text-scogoorange h-4 w-4'
                    type='radio'
                    name={'table'}
                    onChange={(e) => handleSelectFE(e, false)}
                />
                <p className='font-medium text-font12'>Assign New Engineer</p>
            </div>
            {isShowAssignNewEngineerInputs && (
                <div
                    className='mx-8 px-5 py-1 items-center flex px-6 rounded-md gap-6'
                    style={{
                        border: '1px solid rgb(227,187,187)',
                        background: 'rgb(255,246,246)',
                    }}
                >
                    <p className='text-font12'>User does not need to onboard the platform</p>
                </div>
            )}
            {isShowAssignNewEngineerInputs && (
                <div className='px-5 flex justify-between'>
                    <Input className='w-full' label='Name' name='first_name' required type={'text'} options={remarks} />
                    <Input
                        className='w-full'
                        label='Mobile Number'
                        name='mobile'
                        required
                        type={'number'}
                        customValidation={isValidMobileNumber}
                        errorMessage={'Invalid Mobile'}
                    />
                </div>
            )}
        </Form>
    );
}
