import moment from 'moment';
import { useDispatch } from 'react-redux';
import validator from 'validator';

import IconToolTip from '../../../../common/IconToolTip';
import { openChatInNewTab } from '../../../../actions/conversations';
import { copyToClipboard } from '../../../../actions/utils';
import { clickableTextClass } from '../../../../common/Css';

const SpareAssetTicketTrackingColumn = ({ tracking, toggleViewMore, isViewingMore }) => {
    const dispatch = useDispatch();
    const isHandHeld = tracking?.tracking_detail?.carrier === 'Hand Helding';

    const ticketNumber = (
        <div className='flex gap-1'>
            <IconToolTip title={tracking?.ticket?.job_ticket_number} placement='bottom'>
                <p className={clickableTextClass + ' truncate '} onClick={() => dispatch(openChatInNewTab({ conversationId: tracking?.ticket?.id }))}>
                    {tracking?.ticket?.job_ticket_number}
                </p>
            </IconToolTip>
            <IconToolTip placement={'bottom'} title={`Copy Ticket Number`}>
                <span className='cursor-pointer material-icons text-scogobgsky text-lg' onClick={() => dispatch(copyToClipboard({ data: tracking?.ticket?.job_ticket_number }))}>
                    {' '}
                    content_copy{' '}
                </span>
            </IconToolTip>
        </div>
    );

    const deliveryDateString = tracking?.tracking_detail?.delivery_date ? moment(tracking?.tracking_detail?.delivery_date).format('DD-MM-YYYY') : '';
    const deliveryDate = (
        <IconToolTip title={`Delivery Date: ${deliveryDateString}`} placement='bottom'>
            <p className='w-full truncate'>{deliveryDateString}</p>
        </IconToolTip>
    );
    if (isHandHeld) {
        return (
            <div className='break-all'>
                {ticketNumber}
                {deliveryDate}
            </div>
        );
    } else {
        const transitDays = tracking?.tracking_detail?.pickup_date && moment().diff(moment(tracking?.tracking_detail?.pickup_date), 'days');
        const pickupDate = tracking?.tracking_detail?.pickup_date && moment(tracking?.tracking_detail?.pickup_date).format('DD-MM-YYYY');
        const transitDuration = !isNaN(transitDays) && !['', null].includes(transitDays) && (
            <IconToolTip title={`Transit Duration: ${transitDays} Days, Pickup Date: ${pickupDate}`} placement='bottom'>
                <p className='w-full truncate'>{transitDays} Days</p>
            </IconToolTip>
        );
        let pod = <></>;
        const logistic_cost = tracking.tracking_detail?.logistic_cost;
        const logisticsCost = logistic_cost > 0 && (
            <IconToolTip title={`Logistics Cost: ₹ ${logistic_cost}`} placement='bottom'>
                <p>Logistics Cost: ₹ {logistic_cost} </p>
            </IconToolTip>
        );
        if (typeof tracking?.tracking_detail?.pod === 'string' && tracking?.tracking_detail?.pod?.length > 0) {
            const c = 'text-scogobgsky font-bold w-full truncate cursor-pointer';
            if (validator.isURL(tracking?.tracking_detail?.pod)) {
                pod = (
                    <>
                        <a className={c} href={tracking?.tracking_detail?.pod} target='_blank' rel='noreferrer'>
                            View POD
                        </a>
                        <br />
                    </>
                );
            } else {
                const downloadFileName = `${tracking?.tracking_detail?.awbno}-${moment().format('DD-MM-YYYY')}.jpg`;
                pod = (
                    <>
                        <a className={c} href={tracking?.tracking_detail?.pod} download={downloadFileName}>
                            View POD
                        </a>
                        <br />
                    </>
                );
            }
        }
        const moreIcon = (
            <span onClick={() => toggleViewMore(true)} className='text-scogoprimary text-font10 cursor-pointer'>
                see more...
            </span>
        );
        const lessIcon = (
            <span onClick={() => toggleViewMore(false)} className='text-scogoprimary text-font10 cursor-pointer'>
                see less
            </span>
        );
        const more = (
            <>
                {tracking?.tracking_detail?.current_status ? (
                    <IconToolTip title={`Delivery Status: ${tracking?.tracking_detail?.current_status}`} placement='bottom'>
                        <p className='w-full truncate'>{tracking?.tracking_detail?.current_status}</p>
                    </IconToolTip>
                ) : (
                    <></>
                )}
                {tracking?.tracking_detail?.tracking_url ? (
                    <a className='text-scogobgsky font-bold w-full truncate cursor-pointer' target='_blank' href={tracking?.tracking_detail?.tracking_url} rel='noreferrer'>
                        Track Status
                    </a>
                ) : (
                    <></>
                )}
                {tracking?.tracking_detail?.current_status === 'Delivered' ? deliveryDate : transitDuration}
                {pod}
                {logisticsCost}
                {lessIcon}
            </>
        );
        return (
            <div className='break-all'>
                {ticketNumber}
                {tracking?.tracking_detail?.awbno ? (
                    <IconToolTip title={`AWB No.: ${tracking?.tracking_detail?.awbno}`} placement='bottom'>
                        <p className='w-full truncate'>{tracking?.tracking_detail?.awbno}</p>
                    </IconToolTip>
                ) : (
                    <></>
                )}
                {tracking.tracking_detail && (
                    <>
                        {!isViewingMore && moreIcon}
                        {isViewingMore && more}
                    </>
                )}
            </div>
        );
    }
};

export default SpareAssetTicketTrackingColumn;
