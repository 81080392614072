import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { InviteUsersModal } from "../../chat/sidedrawer/TicketDetails/InviteUsersModal";
import { openCustomModal } from "../../../actions/modal";
import IconToolTip from "../../../common/IconToolTip";
import { getConversationIdandConversationDetailsAction, joinRoomAction, openActiveCallAction } from "../../../actions/conversations";
import Loader from "../../../common/Loader";
import { allowVideoMeet } from "./constants";
import { getActiveVoiceCall } from "../../../actions/tickets";
import { getUserCallStatusAction } from "../../../actions/users";

export const StartorJoinCall = () => {
    const dispatch = useDispatch();
    const { loggedUser } = useSelector((state) => state.auth);
    const { viewTicket } = useSelector((state) => state.tickets);
    const { formButtonLoading } = useSelector((state) => state.loading);
    const { openedConversationId, conversationDetails } = useSelector((state) => state.conversations);

    const conversation = conversationDetails[openedConversationId];

    const membersList = useMemo(() => {
        let members = [];
        if (Array.isArray(conversation?.users)) {
            members = conversation.users.sort((a, b) => a.username.localeCompare(b.username));
        }
        return members;
    }, [conversation?.users]);


    const inviteModal = {
        modalComponent: <InviteUsersModal userId={loggedUser?.id} ticketId={viewTicket?.id} openedConversationId={openedConversationId} ticket={viewTicket} />,
        heading: `Invite Users`,
        modalWidth: '40rem',
        modalHeight: '50rem'
    }

    const openInviteModal = () => {
        dispatch(openCustomModal(inviteModal));
    }

    const handleStartOnClick = () => {
        dispatch(getConversationIdandConversationDetailsAction({ ticketId: viewTicket?.id, buttonName: `video-chat-${viewTicket?.id}`, onSuccess: openInviteModal }))
    }

    const refresh = () => {
        dispatch(getActiveVoiceCall());
        dispatch(getUserCallStatusAction({ userId: loggedUser?.id, status: [1, 2, 3, 4] }));
    }

    const handleJoinOnClick = () => {
        dispatch(joinRoomAction({ userId: loggedUser?.id, room_id: viewTicket?.fk_room_id, ticketId: viewTicket?.id, onSuccess: refresh }));
        dispatch(openActiveCallAction({ ticketId: viewTicket?.id, room_id: viewTicket?.fk_room_id, buttonName: `video-chat-${viewTicket?.id}` }));
    }

    if (formButtonLoading?.[`video-chat-${viewTicket?.id}`]) {
        return <Loader size='15' />
    }

    if (!allowVideoMeet(viewTicket)) {
        return <></>
    }

    return <>{
        viewTicket?.room_call_status === 0 ?
            <IconToolTip title={'Start Scogo Connect'} onClick={handleStartOnClick}>
                <div className="flex items-center w-7 h-7">
                    <img src={process.env.PUBLIC_URL + '/img/scogo-connect-blue.svg'} alt="" className="w-full h-full object-contain hover:cursor-pointer" />
                </div>
            </IconToolTip>
            :
            <IconToolTip title={'Scogo Connect Active'} onClick={handleJoinOnClick}>
                <div className="flex items-center w-7 h-7">
                    <img src={process.env.PUBLIC_URL + '/img/scogo-connect-green.svg'} alt="" className="w-full h-full object-contain hover:cursor-pointer" />
                </div>
            </IconToolTip>
    }</>
}