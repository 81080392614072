import { call, put, takeEvery, takeLatest, all, select } from 'redux-saga/effects';
import { inventory, loading, toast, modal, utils } from '../../types';
import microservices from '../../lambdas/microservices';
import api from '../../apis';
import lambdas from '../../lambdas/apis';
import { getInventoryFilterData } from '../../selectors/inventory';
import { getLoggedUser } from '../../selectors/users';
import { getCustomerIdOfLoginUser } from '../../utils/utils';

export function* getAllWarehouses(action) {
    const { customerId, limit, page, count = true, dropdown, distance, createWarehouse } = action.payload;
    try {
        yield put({ type: loading.GET_ALL_WAREHOUSE_LOADING, payload: true });
        const response = yield call(
            microservices.inventory.getAllWarehouses,
            {},
            {},
            { customerId, limit, page, count, dropdown, giveSpareAssetsStats: true, distance, createWarehouse }
        );
        if (response.data.code === 200) {
            if (count) {
                yield put({ type: inventory.ALL_WAREHOUSES_COUNT_SUCCESS, payload: response.data.count });
            }
            let warehouseData = response.data.data;
            yield put({ type: inventory.GET_ALL_WAREHOUSE_SUCCESS, payload: warehouseData });
        } else {
            yield put({ type: inventory.GET_ALL_WAREHOUSE_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: inventory.GET_ALL_WAREHOUSE_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.GET_ALL_WAREHOUSE_LOADING, payload: false });
}

export function* getAllWarehousesForFilter(action) {
    const { customerId, limit, page, count, dropdown } = action.payload;
    try {
        const response = yield call(microservices.inventory.getAllWarehouses, {}, {}, { customerId, limit, page, count, dropdown, giveSpareAssetsStats: true });
        if (response.data.code === 200) {
            let warehouseData = response.data.data;
            yield put({ type: inventory.GET_ALL_WAREHOUSE_FOR_FILTER_SUCCESS, payload: warehouseData });
        } else {
            yield put({ type: inventory.GET_ALL_WAREHOUSE_FOR_FILTER_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: inventory.GET_ALL_WAREHOUSE_FOR_FILTER_FAILURE, payload: { message: error } });
    }
}

export function* getNextWarehouses(action) {
    const { limit, offset, customerId, page } = action.payload;
    try {
        yield put({ type: loading.SET_NEXT_WAREHOUSE_LIST_LOADING, payload: true });
        const response = yield call(microservices.inventory.getAllWarehouses, {}, {}, { limit, page, offset, customerId, giveSpareAssetsStats: true });
        yield put({ type: loading.SET_NEXT_WAREHOUSE_LIST_LOADING, payload: false });
        if (response.data.code === 200) {
            let warehouseData = response.data.data;
            yield put({ type: inventory.GET_NEXT_WAREHOUSE_LIST_SUCCESS, payload: warehouseData });
        } else {
            yield put({ type: inventory.GET_NEXT_WAREHOUSE_LIST_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: inventory.GET_NEXT_WAREHOUSE_LIST_SUCCESS, payload: { message: error } });
    }
}

export function* createInventoryWarehouses(action) {
    const { data, customerId, limit = 10, formName } = action.payload;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    try {
        const response = yield call(microservices.inventory.createWarehouses, {}, {}, {}, data);
        let status = 'success',
            message = `Warehouse Created Successfully`;

        if (response.data.code === 200) {
            yield all([
                yield put({ type: inventory.GET_ALL_WAREHOUSE_REQUEST, payload: { customerId, limit } }),
                yield put({ type: toast.SEND_TOAST, payload: { status, message } }),
                yield put({ type: utils.SET_INITIAL_FORM, payload: { reinitialize: false, date: Date.now() } }),
            ]);
        } else {
            yield put({ type: inventory.CREATE_INVENTORY_WAREHOUSES_FAILURE, payload: 'something went wrong' });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
        }
    } catch (error) {
        yield put({ type: inventory.CREATE_INVENTORY_WAREHOUSES_FAILURE, payload: error });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* deleteInventoryWarehouse(action) {
    const { warehouseId, customerId, formName, refresh = true } = action.payload;
    let status = 'success',
        message = 'Warehouse Deleted Successfully!!!';
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(microservices.inventory.deleteWarehouse, {}, { warehouseId });
        if (response.data.code === 200) {
            yield put({ type: modal.CLOSE_MODAL, payload: {} });
            if (refresh) {
                yield put({ type: inventory.REFRESH_WAREHOUSE_PAGE, payload: {} });
            }
        } else {
            status = 'danger';
            message = response.data.data.message;
        }
    } catch (error) {
        status = 'danger';
        message = error.message || `Something Went Wrong`;
    }
    yield put({ type: toast.SEND_TOAST, payload: { status, message } });
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* updateInventoryWarehouse(action) {
    const { data, warehouseId, customerId, formName, refresh = true } = action.payload;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    let status = 'success',
        message = 'Warehouse Updated Successfully!!!';
    try {
        const response = yield call(microservices.inventory.updateWarehouse, {}, { warehouseId }, {}, data);
        if (response.data.code === 200) {
            yield put({ type: modal.CLOSE_MODAL, payload: {} });
            if (refresh) {
                yield put({ type: inventory.REFRESH_WAREHOUSE_PAGE, payload: {} });
            }
        } else {
            status = 'danger';
            message = response.data.data.message;
        }
    } catch (error) {
        status = 'danger';
        message = error.message || `Something Went Wrong`;
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
    yield put({ type: toast.SEND_TOAST, payload: { status, message } });
}

export function* getWareHouseOwners(action) {
    const { user_role = [6, 12], customer, userType = 'CLUSTER', status = 0 } = action.payload;
    try {
        const queryParams = { user_role, customer, type: userType, status };
        yield put({ type: loading.GET_WAREHOUSE_OWNER_DROPDOWN_LOADING, payload: true });

        const response = yield call(api.invitations.getUsersList, {}, {}, queryParams);
        if (response.data.code === 200) {
            let warehouseOwners = [];
            response.data.data.user_list.forEach((item) => {
                warehouseOwners.push({
                    key: item.id,
                    value: item.id,
                    label: `${item.first_name} ${item.last_name}`,
                    user: item,
                });
            });
            yield put({ type: inventory.GET_WAREHOUSE_OWNER_DROPDOWN_SUCCESS, payload: warehouseOwners });
        } else {
            yield put({ type: inventory.GET_WAREHOUSE_OWNER_DROPDOWN_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: inventory.GET_WAREHOUSE_OWNER_DROPDOWN_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.GET_WAREHOUSE_OWNER_DROPDOWN_LOADING, payload: false });
}

export function* getWareHouseDropdown(action) {
    const { customerId, limit = 10, page = 1, count = false, distance = false, dropdown, createWarehouse, assetType, siteId, serial_number } = action.payload;

    let queryParams = { limit, page, count, distance, dropdown, createWarehouse, assetType, siteId, serialNumber: serial_number };
    queryParams.customerId = !['', 'all', 'ALL', undefined, null].includes(customerId) ? customerId : undefined;

    try {
        yield put({ type: loading.GET_WAREHOUSE_OWNER_DROPDOWN_LOADING, payload: true });
        const response = yield call(microservices.inventory.findAllWarehouses, {}, {}, queryParams);
        if (response.data.code === 200) {
            let warehouseData = response.data.data;

            warehouseData = [];
            response.data.data.map((warehouse) => {
                warehouseData.push({
                    key: `${warehouse.id}_${warehouse.customer_id}`,
                    value: warehouse.id,
                    label: warehouse.name,
                    distanceToSite: warehouse.distanceToSite,
                    warehouse,
                    fk_user_id: warehouse.fk_user_id
                });
            });
            yield put({ type: inventory.FIND_ALL_WAREHOUSES_SUCCESS, payload: warehouseData });
        } else yield put({ type: inventory.FIND_ALL_WAREHOUSES_SUCCESS, payload: [] });
    } catch (error) {
        yield put({ type: inventory.FIND_ALL_WAREHOUSES_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.GET_WAREHOUSE_OWNER_DROPDOWN_LOADING, payload: false });
}

export function* findAllRepairCenters(action) {
    const { customerId, limit = 10, page = 1, count = false, dropdown = false, addCustomerDropdown = true } = action.payload;
    let queryParams = { limit, page, count, repairCenter: true, dropdown };
    if (!isNaN(customerId) && customerId > 0) queryParams.customerId = customerId;
    try {
        const response = yield call(microservices.inventory.findAllWarehouses, {}, {}, queryParams);
        if (response.data.code === 200) {
            let warehouseData = [];
            if (addCustomerDropdown) warehouseData.push({ key: Date.now(), value: 'CUSTOMER', label: 'Customer' });
            response.data.data.forEach((warehouse) => {
                warehouseData.push({
                    key: `${warehouse.id}_${warehouse.customer_id}`,
                    value: warehouse.id,
                    label: warehouse.name,
                    warehouse,
                    state: warehouse.state,
                });
            });
            yield put({ type: inventory.ALL_REPAIR_CENTERS_DROPDOWN_SUCCESS, payload: warehouseData });
        }
    } catch (error) {
        yield put({ type: inventory.ALL_REPAIR_CENTERS_DROPDOWN_FAILURE, payload: { message: error } });
    }
}

export function* findWarehouseById(action) {
    const { warehouseId } = action.payload;
    try {
        const response = yield call(microservices.inventory.findWarehouseById, {}, { warehouseId }, {}, { warehouseId });
        if (response.data.code === 200) {
            yield put({ type: inventory.FIND_WAREHOUSE_BY_ID_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: inventory.FIND_WAREHOUSE_BY_ID_FAILURE, payload: { message: 'Something Went Wrong' } });
        }
    } catch (error) {
        yield put({ type: inventory.FIND_WAREHOUSE_BY_ID_FAILURE, payload: { message: error } });
    }
}

export function* getPropertiesOfWarehouse(action) {
    const { warehouseId, attributes } = action.payload;
    try {
        const response = yield call(microservices.inventory.findWarehouseById, {}, { warehouseId }, { attributes: attributes.join(','), include: '', giveContacts: false });
        if (response.data.code === 200) {
            yield put({ type: inventory.GET_PROPERTIES_OF_WAREHOUSE_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
            yield put({ type: inventory.GET_PROPERTIES_OF_WAREHOUSE_FAILURE, payload: { message: response.data.data.message } });
        }
    } catch (error) {
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
        yield put({ type: inventory.GET_PROPERTIES_OF_WAREHOUSE_FAILURE, payload: { message: error } });
    }
}

export function* getWarehouseFilterList(action) {
    try {
        const { module, tab, projectId, customerId, toFilter = '' } = action.payload;
        let queryParams = { module, tab };
        if (!['', 'all', undefined, null].includes(projectId)) queryParams.projectId = projectId;
        if (!['', 'all', undefined, null].includes(customerId)) queryParams.customerId = customerId;
        if (toFilter) queryParams.toFilter = toFilter;
        yield put({ type: loading.GET_WAREHOUSE_FILTER_LIST_LOADING, payload: true });
        const response = yield call(microservices.inventory.getAllWarehouseFilters, {}, {}, queryParams);
        if (response.data.code === 200) {
            yield put({ type: inventory.GET_WAREHOUSE_FILTER_LIST_SUCCESS, payload: response.data.data });
        } else if ([400, 500].includes(response.data.code)) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
        }
    } catch (err) {
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something Went Wrong' } });
    }
    yield put({ type: loading.GET_WAREHOUSE_FILTER_LIST_LOADING, payload: false });
}

export function* refreshWarehousePage(action) {
    const { limit = 10, page = 1, query, filterPayload = {}, isFilterActive } = yield select(getInventoryFilterData);
    const loggedUser = yield select(getLoggedUser);
    const customerId = getCustomerIdOfLoginUser(loggedUser);
    const isFilterOrSearchActive = isFilterActive || query?.length > 0;
    try {
        yield put({ type: loading.GET_ALL_WAREHOUSE_LOADING, payload: true });
        let response;
        if (isFilterOrSearchActive) {
            let queryParams = {
                module: 'INVENTORY',
                tab: 'warehouses',
                limit,
                page,
                next: true,
                count: true,
                type: 0,
                giveLastTickets: true,
                giveAssetTypes: true,
                giveSpareAssetsStats: true,
            };
            if (!['', 'all', undefined, null, 0].includes(customerId)) queryParams.customerId = customerId;
            if (!['', 'all', undefined, null].includes(filterPayload?.status)) filterPayload.status = filterPayload.status.map((s) => s.split(' ').join('_'));
            if (!['', undefined, null].includes(query)) queryParams.query = query;
            response = yield call(lambdas.utils.filterModule, {}, {}, queryParams, filterPayload);
        } else {
            let queryParams = { limit, page, count: true, giveSpareAssetsStats: true };
            if (!['', 'all', undefined, null, 0].includes(customerId)) queryParams.customerId = customerId;
            response = yield call(microservices.inventory.getAllWarehouses, {}, {}, queryParams);
        }
        if (response.data.code === 200) {
            if (isFilterOrSearchActive) {
                yield put({ type: inventory.GET_WAREHOUSE_FILTERED_DATA_SUCCESS, payload: { data: response.data.data, count: response.data.count } });
            } else {
                yield put({ type: inventory.ALL_WAREHOUSES_COUNT_SUCCESS, payload: response.data.count });
                yield put({ type: inventory.GET_ALL_WAREHOUSE_SUCCESS, payload: response.data.data });
            }
        } else if ([400, 500].includes(response.data.code)) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
        }
    } catch (err) { }
    yield put({ type: loading.GET_ALL_WAREHOUSE_LOADING, payload: false });
}

export function* getWarehouseFilteredData(action) {
    let { module = 'INVENTORY', tab = 'warehouses', customerId, next = true, count = false, type = 0, limit, page, query, payload } = action.payload;

    try {
        yield put({ type: loading.GET_ALL_WAREHOUSE_LOADING, payload: true });
        let queryParams = { module, tab, limit, page, next, count, type, giveLastTickets: true, giveAssetTypes: true, giveSpareAssetsStats: true };
        if (!['', 'all', undefined, null, 0].includes(customerId)) queryParams.customerId = customerId;
        if (!['', 'all', undefined, null].includes(payload?.status)) payload.status = payload.status.map((s) => s.split(' ').join('_'));
        if (!['', undefined, null].includes(query)) queryParams.query = query;
        const response = yield call(lambdas.utils.filterModule, {}, {}, queryParams, payload);
        if (response.data.code === 200) {
            yield put({ type: inventory.GET_WAREHOUSE_FILTERED_DATA_SUCCESS, payload: { data: response.data.data, count: response.data.count } });
        } else if ([400, 500].includes(response.data.code)) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
        }
    } catch (err) {
        yield put({ type: inventory.GET_WAREHOUSE_FILTERED_DATA_FAILURE, payload: 'Something went wrong' });
    }
    yield put({ type: loading.GET_ALL_WAREHOUSE_LOADING, payload: false });
}

export default function* warehouseSaga() {
    yield takeEvery(inventory.GET_ALL_WAREHOUSE_REQUEST, getAllWarehouses);
    yield takeEvery(inventory.GET_ALL_WAREHOUSE_FOR_FILTER_REQUEST, getAllWarehousesForFilter);
    yield takeEvery(inventory.GET_NEXT_WAREHOUSE_LIST_REQUEST, getNextWarehouses);
    yield takeLatest(inventory.CREATE_INVENTORY_WAREHOUSES_REQUEST, createInventoryWarehouses);
    yield takeLatest(inventory.DELETE_INVENTORY_WAREHOUSE_REQUEST, deleteInventoryWarehouse);
    yield takeLatest(inventory.UPDATE_INVENTORY_WAREHOUSE_REQUEST, updateInventoryWarehouse);
    yield takeLatest(inventory.GET_WAREHOUSE_OWNER_DROPDOWN_REQUEST, getWareHouseOwners);
    yield takeLatest(inventory.FIND_ALL_WAREHOUSES_REQUEST, getWareHouseDropdown);
    yield takeLatest(inventory.ALL_REPAIR_CENTERS_DROPDOWN_REQUEST, findAllRepairCenters);
    yield takeLatest(inventory.FIND_WAREHOUSE_BY_ID_REQUEST, findWarehouseById);
    yield takeLatest(inventory.GET_WAREHOUSE_FILTER_LIST_REQUEST, getWarehouseFilterList);
    yield takeLatest(inventory.REFRESH_WAREHOUSE_PAGE, refreshWarehousePage);
    yield takeEvery(inventory.GET_PROPERTIES_OF_WAREHOUSE_REQUEST, getPropertiesOfWarehouse);
    yield takeEvery(inventory.GET_WAREHOUSE_FILTERED_DATA_REQUEST, getWarehouseFilteredData);
}
