import React from 'react';

export default function Verticalline({ isLast, color = 'scogoprimary', lineHeight = '2.8rem', marginTop = '6rem' }) {
    let divStyle = {
        height: '1.6rem',
    };
    if (!isLast) {
        divStyle = {
            'border-style': 'solid',
            'border-left-width': '2px',
            'margin-left': '2.65rem',
            'margin-top': marginTop,
            height: lineHeight,
        };
    }
    return <div className={`absolute z-10 border-${color}`} style={divStyle}></div>;
}
