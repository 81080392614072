import React, { useEffect, useRef, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import config from '../config';
import { getStatesGeoJSON } from './geoJSONLookUp';

function GoogleMap({ boundryData, markerData }) {
    const [gmapWin, setGmapWin] = useState(false);
    const [gmapObj, setGmapObj] = useState();
    const mapBox = useRef();
    const infoWindowRef = useRef(null);

    const getMapBoundry = (locations) => {
        infoWindowRef.current = new window.google.maps.InfoWindow();
        if (Array.isArray(locations) && locations.length > 0) {
            let stateGeoData = getStatesGeoJSON(locations);
            if (stateGeoData.length > 0) {
                const dataLayer = new window.google.maps.Data({
                    map: gmapObj,
                });
                dataLayer.setStyle((feature) => {
                    let boundryStyle = locations.find((location) => location.name === feature.getProperty('NAME_1'));
                    return {
                        fillColor: boundryStyle.color,
                        strokeColor: boundryStyle.color,
                        strokeWeight: 2,
                    };
                });
                dataLayer.addListener('click', (event) => {
                    let onClickOnBoundry = locations.find((location) => event.feature.getProperty('NAME_1') === location.name);
                    infoWindowRef.current.setContent(`${onClickOnBoundry.title}`);
                    infoWindowRef.current.setPosition(event.latLng);
                    infoWindowRef.current.open(gmapObj);
                });
                stateGeoData.map((item) => dataLayer.addGeoJson(item.state));
            }
        }
    };

    const getMapMarker = async (locations) => {
        let marker;
        infoWindowRef.current = new window.google.maps.InfoWindow();
        gmapObj.markers = [];
        if (locations.length > 0) {
            locations.map((location) => {
                marker = new window.google.maps.Marker({
                    position: { lat: parseFloat(location.lat), lng: parseFloat(location.lng) },
                    map: gmapObj,
                    title: location.name,
                    icon: {
                        url: `${process.env.PUBLIC_URL + './img/map_marker_blue.png'}`,
                        scaledSize: new window.google.maps.Size(30, 30),
                    },
                });
                marker.addListener('click', (event) => {
                    infoWindowRef.current.setContent(`${location.name}`);
                    infoWindowRef.current.setPosition(event.latLng);
                    infoWindowRef.current.open(gmapObj);
                });
                gmapObj.markers.push(marker);
            });
        }
    };

    useEffect(() => {
        const loader = new Loader({
            apiKey: `${config.googleMapApiKey}`,
            version: 'weekly',
        });

        const abortController = new AbortController();

        (async function () {
            loader.load().then(() => {
                if (!abortController.signal.aborted && window.google?.maps && !gmapWin) {
                    setGmapWin(true);
                }
                if (gmapWin) {
                    setGmapObj(
                        new window.google.maps.Map(mapBox.current, {
                            center: {
                                lat: 23.2713564,
                                lng: 82.0,
                            },
                            zoom: 5,
                            minZoom: 5,
                            disableDefaultUI: true,
                        })
                    );
                }
            });
        })();

        return () => {
            abortController.abort();
        };
    }, [gmapWin]);

    useEffect(() => {
        if (gmapObj && gmapWin && Object.keys(gmapObj).length > 0) {
            getMapBoundry(boundryData);
            getMapMarker(markerData);
        }
    }, [gmapWin, gmapObj]);

    return <div className='map border-4 h-full' ref={mapBox}></div>;
}

export default GoogleMap;
