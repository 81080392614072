import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { closeTicket, emailReport } from '../../../actions/tickets';

import Form from '../../../common/Form';
import Input from '../../../common/Form/Input';
import Radio from '../../../common/Form/Radio';
import DatePicker from '../../../common/Form/DatePicker';

import ButtonScogoPrimary from '../../../common/Buttons/ButtonScogoPrimary';
import ButtonScogoClosedOutlined from '../../../common/Buttons/ButtonScogoClosedOutlined';

import config from '../../../config';
import prodConfig from '../../../config/env/production';

import { closeModalAction } from '../../../actions/modal';

const activityStatusRadioOptions = [
    { value: 'YES', name: 'activity_status', label: 'Yes' },
    { value: 'NO', name: 'activity_status', label: 'No' },
];

const deviceStatusRadioOptions = [
    { value: 'YES', name: 'new_device_status', label: 'Yes' },
    { value: 'NO', name: 'new_device_status', label: 'No' },
];

const faultyPickupRadioOptions = [
    { value: 'YES', name: 'faulty_device_pickedup', label: 'Yes' },
    { value: 'NO', name: 'faulty_device_pickedup', label: 'No' },
];


// if need to change this component, please check support track component where this component using (need to update passed ticket data)
const RaiseSignOff = ({ ticket, job_sign_off_notes, isSupportTicket, showRemarkField = false }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const submitRef = useRef();

    const { formButtonLoading } = useSelector((state) => state.loading);

    const methods = useForm({
        defaultValues: {},
        mode: 'all',
    });

    const { watch } = methods;
    const watchnewDeivceStatus = watch('new_device_status');

    const formName = 'raiseSignOff';

    useEffect(() => {
        let emails = [];
        const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (ticket.sp_email && emailRegex.test(ticket.sp_email)) {
            emails.push(ticket.sp_email.trim());
        }
        if (ticket.pm_email && emailRegex.test(ticket.pm_email)) {
            emails.push(ticket.pm_email.trim());
        }
        if (ticket.fe_email && emailRegex.test(ticket.fe_email)) {
            emails.push(ticket.fe_email.trim());
        }
        if (ticket.project_contact_person_email_address && emailRegex.test(ticket.project_contact_person_email_address)) {
            emails.push(ticket.project_contact_person_email_address.trim());
        }
        if (config.name === prodConfig.name) {
            emails.push('nitin@scogo.in');
        } else {
            emails.push('alpesh@scogo.in');
        }
        emails = emails.filter((item) => item !== undefined);
        emails = [...new Set(emails)];
        const emailPayload = {
            ticket_id: ticket.id,
            emails: emails.join(','),
        };
        dispatch(emailReport({ data: emailPayload, downloadReport: false }));
    }, [ticket, dispatch]);

    const onSubmit = (formValues) => {
        const payload = {
            ticket_id: ticket.id,
            job_sign_off_notes: job_sign_off_notes,
            new_device_status: formValues?.new_device_status,
            activity_status: formValues?.activity_status
                ? formValues?.activity_status
                : ticket && ticket.is_rma_raised === 1 && formValues?.new_device_status
                    ? formValues?.new_device_status
                    : null,
            faulty_device_pickedup: ticket && ticket.is_rma_raised === 1 && formValues?.faulty_device_pickedup ? formValues?.faulty_device_pickedup : undefined,
        };
        if (!['', undefined, null].includes(formValues?.site_completed_on) && moment(formValues?.site_completed_on).isValid()) {
            payload.site_completed_on = moment(formValues?.site_completed_on).format('YYYY-MM-DD HH:mm:ss');
        }
        if (formValues.job_sign_off_notes) payload.job_sign_off_notes = formValues.job_sign_off_notes;
        dispatch(closeTicket({ data: payload, ticket, formName, ticketExecution: true, navigate, isSupportTicket }));
    };

    const closeModal = () => {
        dispatch(closeModalAction());
    };

    return (
        <>
            <Form onSubmit={(data) => onSubmit(data)} defaultValues={{}} submitRef={submitRef} methods={methods} className='w-full'>
                <div className='m-4'>
                    <div className='ml-2 mb-2 flex flex-col'>
                        <label className='font-normal text-font11'>Are you Sure you want to Continue?</label>
                        {ticket && ticket?.ticket_type === 0 && ticket?.is_rma_raised === 1 ? (
                            <>
                                <label className='flex-1 py-2 font-normal text-font09 md:text-font11 '>Reverse Logistic Ticket will be created on closure of this Ticket</label>
                            </>
                        ) : (
                            <></>
                        )}
                    </div>

                    {showRemarkField && <Input name='job_sign_off_notes' label='Remark' required />}

                    <DatePicker
                        name='site_completed_on'
                        placeholder='Activity Completed On'
                        label='Activity Completed On'
                        showTimeSelect
                        required
                        dateFormat='dd-MM-yyyy HH:mm:ss'
                        inputValueFormat='DD-MM-yyyy HH:mm:ss'
                        maxDate={new Date()}
                    />

                    {ticket && ticket?.ticket_type === 0 && ticket?.is_rma_raised === 1 ? (
                        <>
                            <div className='ml-3 mt-1 flex flex-col'>
                                <label className='font-normal text-font11 '>Please confirm new device is working fine/has been used at the site</label>
                                <Radio className='my-1 flex flex-row' id='new_device_status' name='new_device_status' required options={deviceStatusRadioOptions} />
                            </div>
                            {watchnewDeivceStatus === 'YES' ? (
                                <>
                                    <div className='ml-3 flex flex-col'>
                                        <label className='font-normal text-font11'>Please confirm Faulty Device has been Picked Up</label>
                                        <Radio
                                            className='my-1 flex flex-row'
                                            id='faulty_device_pickedup'
                                            name='faulty_device_pickedup'
                                            required
                                            options={faultyPickupRadioOptions}
                                        />
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                        </>
                    ) : (
                        <></>
                    )}

                    {ticket && ticket?.ticket_type === 0 && ticket?.is_rma_raised === 0 ? (
                        <>
                            <div className='ml-3 flex flex-col'>
                                <label className='font-normal text-font11 '>Please confirm Site is completed?</label>
                                <Radio className='my-1 flex flex-row' id='activity_status' name='activity_status' required options={activityStatusRadioOptions} />
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </Form>
            <div className='flex justify-end p-4 gap-2'>
                <ButtonScogoPrimary
                    textOrComponent='Submit'
                    onClick={(e) => {
                        e.preventDefault();
                        submitRef.current.click();
                    }}
                    loading={formButtonLoading?.[formName]}
                />
                <ButtonScogoClosedOutlined
                    textOrComponent='Cancel'
                    type='button'
                    onClick={(e) => {
                        e.preventDefault();
                        closeModal();
                    }}
                />
            </div>
        </>
    );
};

export default RaiseSignOff;
