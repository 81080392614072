export const CHECK_USER_REQUEST = 'CHECK_USER_REQUEST';
export const CHECK_USER_SUCCESS = 'CHECK_USER_SUCCESS';
export const CHECK_USER_FAILURE = 'CHECK_USER_FAILURE';

export const REQUEST_OTP_REQUEST = 'REQUEST_OTP_REQUEST';
export const REQUEST_OTP_SUCCESS = 'REQUEST_OTP_SUCCESS';
export const REQUEST_OTP_FAILURE = 'REQUEST_OTP_FAILURE';

export const LOGIN_WITH_OTP_REQUEST = 'LOGIN_WITH_OTP_REQUEST';
export const LOGIN_WITH_OTP_SUCCESS = 'LOGIN_WITH_OTP_SUCCESS';
export const LOGIN_WITH_OTP_FAILURE = 'LOGIN_WITH_OTP_FAILURE';

export const SEND_FORGOT_PASSWORD_EMAIL_REQUEST = 'SEND_FORGOT_PASSWORD_EMAIL_REQUEST';
export const SEND_FORGOT_PASSWORD_EMAIL_SUCCESS = 'SEND_FORGOT_PASSWORD_EMAIL_SUCCESS';
export const SEND_FORGOT_PASSWORD_EMAIL_FAILURE = 'SEND_FORGOT_PASSWORD_EMAIL_FAILURE';

export const AUTH_ERROR = 'AUTH_ERROR';

export const SET_MOBILE_OR_EMAIL = 'SET_MOBILE_OR_EMAIL';
export const SET_FORGOT_PASSWORD_EMAIL = 'SET_FORGOT_PASSWORD_EMAIL';
export const SHOW_FORGOT_PASSWORD_EMAIL_SENT = 'SHOW_FORGOT_PASSWORD_EMAIL_SENT';

export const SIGN_OUT_USER = 'SIGN_OUT_USER';
export const RESET_REDUX_STORE = 'RESET_REDUX_STORE';

export const USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST';
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS';
export const USER_PROFILE_FAILURE = 'USER_PROFILE_FAILURE';

export const LOGIN_WITH_PASSWORD_REQUEST = 'LOGIN_WITH_PASSWORD_REQUEST';
export const LOGIN_WITH_PASSWORD_SUCCESS = 'LOGIN_WITH_PASSWORD_SUCCESS';
export const LOGIN_WITH_PASSWORD_FAILURE = 'LOGIN_WITH_PASSWORD_FAILURE';

export const GET_USERS_BANK_DETAILS_REQUEST = 'GET_USERS_BANK_DETAILS_REQUEST';
export const GET_USERS_BANK_DETAILS_SUCCESS = 'GET_USERS_BANK_DETAILS_SUCCESS';
export const GET_USERS_BANK_DETAILS_FAILURE = 'GET_USERS_BANK_DETAILS_FAILURE';

export const GET_WALLET_TRANSFER_OTP_REQUEST = 'GET_WALLET_TRANSFER_OTP_REQUEST';

export const GET_OTP_REQUEST = 'GET_OTP_REQUEST';
export const GET_OTP_SUCCESS = 'GET_OTP_SUCCESS';
export const GET_OTP_FAILURE = 'GET_OTP_FAILURE';

export const GET_REGISTER_MOBILE_REQUEST = 'GET_REGISTER_MOBILE_REQUEST';
export const GET_REGISTER_MOBILE_SUCCESS = 'GET_REGISTER_MOBILE_SUCCESS';
export const GET_REGISTER_MOBILE_FAILURE = 'GET_REGISTER_MOBILE_FAILURE';

export const GET_SIGNUP_OTP_REQUEST = 'GET_SIGNUP_OTP_REQUEST';
export const GET_SIGNUP_OTP_FAILURE = 'GET_SIGNUP_OTP_FAILURE';

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';

export const UPDATE_PROFILE_PIC_REQUEST = 'UPDATE_PROFILE_PIC_REQUEST';
export const UPDATE_PROFILE_PIC_SUCCESS = 'UPDATE_PROFILE_PIC_SUCCESS';
export const UPDATE_PROFILE_PIC_FAILURE = 'UPDATE_PROFILE_PIC_FAILURE';

export const SET_USER_PASSWORD_REQUEST = 'SET_USER_PASSWORD_REQUEST';
export const SET_USER_PASSWORD_SUCCESS = 'SET_USER_PASSWORD_SUCCESS';
export const SET_USER_PASSWORD_FAILURE = 'SET_USER_PASSWORD_FAILURE';

export const VERIFY_USERS_EMAIL_REQUEST = 'VERIFY_USERS_EMAIL_REQUEST';
export const VERIFY_USERS_EMAIL_SUCCESS = 'VERIFY_USERS_EMAIL_SUCCESS';
export const VERIFY_USERS_EMAIL_FAILURE = 'VERIFY_USERS_EMAIL_FAILURE';

export const PUT_TO_AUTH_STORE = 'PUT_TO_AUTH_STORE';

export const UPLOAD_ID_PROOF_REQUEST = 'UPLOAD_ID_PROOF_REQUEST';
export const UPDATE_ID_PROOF_SUCCESS = 'UPDATE_ID_PROOF_SUCCESS';
export const UPDATE_ID_PROOF_FAILURE = 'UPDATE_ID_PROOF_FAILURE';

export const CLEAR_UPDATE_PROFILE_STORE = 'CLEAR_UPDATE_PROFILE_STORE';


export const RESET_USER_PASSWORD_REQUEST = 'RESET_USER_PASSWORD_REQUEST';
export const RESET_USER_PASSWORD_SUCCESS = 'RESET_USER_PASSWORD_SUCCESS';
export const RESET_USER_PASSWORD_FAILURE = 'RESET_USER_PASSWORD_FAILURE';

export const UPDATE_WITHDRAW_STATUS_FROM_WEBSOCKET = 'UPDATE_WITHDRAW_STATUS_FROM_WEBSOCKET';

export const GET_USERS_BANK_DETAILS_BY_ID_REQUEST = 'GET_USERS_BANK_DETAILS_BY_ID_REQUEST';
export const GET_USERS_BANK_DETAILS_BY_ID_SUCCESS = 'GET_USERS_BANK_DETAILS_BY_ID_SUCCESS';
export const GET_USERS_BANK_DETAILS_BY_ID_FAILURE = 'GET_USERS_BANK_DETAILS_BY_ID_FAILURE';


export const REQUEST_OTP_SUPPORT_REQUEST = 'REQUEST_OTP_SUPPORT_REQUEST';
export const REQUEST_OTP_SUPPORT_SUCCESS = 'REQUEST_OTP_SUPPORT_SUCCESS';
export const REQUEST_OTP_SUPPORT_FAILURE = 'REQUEST_OTP_SUPPORT_FAILURE';
export const REMOVE_REQUEST_OTP_SUPPORT_SUCCESS = 'REMOVE_REQUEST_OTP_SUPPORT_SUCCESS';
export const LOGIN_WITHOUT_OTP_REQUEST = 'LOGIN_WITHOUT_OTP_REQUEST';

export const GENERATE_AFFILIATE_LINK_SUCCESS_LOGGED_USER = 'GENERATE_AFFILIATE_LINK_SUCCESS_LOGGED_USER';

export const SET_REDIRECT_URL = 'SET_REDIRECT_URL';

export const USER_JOINED_ROOM = 'USER_JOINED_ROOM';

export const AUTO_LOGIN = 'AUTO_LOGIN';