import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconToolTip from '../../../../common/IconToolTip';
import OverflowTip from '../../../../common/OverflowTip';
import GridTable from '../../../../common/GridTable';
import { GetPaymentsListLogic } from '../../PaymentController';
import DropdownMenu from '../../../../common/MenuDropdown';
import moment from 'moment';
import Menus from '../../../../common/Menus';
import { findSiteById } from '../../../../actions/inventory';
import MiniPopup from '../../../../common/MiniPopup';
import { Skeleton } from '@mui/material';
import APIFilterPopup from '../../../../common/APIFilters/FilterPopup';
import SystemGeneratedInvoice from './SystemGeneratedInvoice';
import UserGeneratedInvoice from './UserGeneratedInvoice';
import { clickableTextClass } from '../../../../common/Css';
import UserPopup from '../../../../common/Popups/User';
import { validateDateValue, validateResponseDate, validateStringValue } from '../../../../utils/common';
import { openCustomModal } from '../../../../actions/modal';
import { getAssetTitle, isDeviceConfigurationRequired } from '../../../Inventory/InventoryController';
import AccordionJSON from '../../../Inventory/reusableComponents/AccordionJSON';
import { copyToClipboard } from '../../../../actions/utils';
import { filterSchema } from './PoContainer';

const SitePopup = ({ siteName, siteId }) => {
    const { siteDataById } = useSelector((state) => state.inventory);
    const { sitePopupLoading } = useSelector((state) => state.loading);
    const dispatch = useDispatch();

    const getSiteDetails = () => {
        dispatch(findSiteById({ siteId: siteId }));
    };

    const sitename = {
        title: 'Site name',
        value: siteName,
    };

    const siteNumber = {
        title: 'Site Number',
        value: siteDataById?.site_number,
    };

    const siteContactPerson = {
        title: 'Site Contact Person',
        value: siteDataById?.site_contact_person_name,
    };

    const siteEmail = {
        title: 'Email',
        value: siteDataById?.site_contact_person_email,
    };

    const siteMobile = {
        title: 'Mobile',
        value: siteDataById?.site_contact_person_mobile,
    };
    const siteAddress = {
        title: 'Site Address',
        value: siteDataById?.complete_site_address,
    };

    const item = [sitename, siteNumber, siteContactPerson, siteEmail, siteMobile, siteAddress];

    const siteDetails = () => {
        return (
            <div className='grid grid-cols-2 p-2 gap-2 w-20vw'>
                {sitePopupLoading ? (
                    <>
                        {item?.map((e, i) => {
                            return (
                                <div className='mt-2' key={i}>
                                    <Skeleton sx={{ background: 'rgb(221,221,221)' }} variant='rectangular' width={'10rem'} height={25} animation='wave' />
                                </div>
                            );
                        })}
                    </>
                ) : (
                    item?.map(({ title, value }, i) => {
                        return (
                            value && (
                                <div key={i} className='mt-2'>
                                    <p className='text-scogo99 text-font11 font-medium'> {title} </p>
                                    <p className='text-font11 font-medium line-clamp-5'>{value}</p>
                                </div>
                            )
                        );
                    })
                )}
            </div>
        );
    };

    return (
        <MiniPopup
            handleClick={getSiteDetails}
            popup={siteDetails()}
            position='ml-auto'
            className='origin-top-right absolute px-3 py-2 lg:top-6 top-4 mt-2 z-10 text-black rounded-md shadow-lg bg-white  max-h-50vh overflow-scroll'
        >
            <IconToolTip placement={'bottom'} title={`Site Name : ${siteName}`}>
                <div className='w-full truncate mt-1 cursor-pointer text-left'>{siteName}</div>
            </IconToolTip>
        </MiniPopup>
    );
};

export default function PoTable(props) {
    const {
        poTableList,
        rowsPerPage,
        paymentsPage,
        updateQueryFilters,
        openPaymentSideDrawer,
        filterList,
        setFilterList,
        poFilterList,
        filterQueryObject,
        filtersActiveStatus,
        isToShowCustomerFilter,
        view = '',
        poCount,
        poTableLoading,
        tableBodyHeight,
        onPoTableScroll,
        isNextTableDataLoading,
        isGroupView
    } = props;

    const { showPaymentSideDrawer, paymentSideDrawerPayload } = useSelector((state) => state.payments);
    const { isPOfilterListLoading } = useSelector((state) => state.loading);
    const dispatch = useDispatch();
    const file_download = (e) => {
        const link = e?.invoiceLink;
        window.open(link, '_blank');
    };

    const filterComponent = ({ headName, keysToFilter }) => {
        if (view === 'group_view') return headName;
        return <div className='flex'>
            {headName}
            <APIFilterPopup
                filterList={filterList}
                setFilterList={setFilterList}
                height={15}
                itemsList={poTableList}
                keysToFilter={keysToFilter}
                submit={({ payload, limit, page, filterList }) => updateQueryFilters({ filterSchema, payload, limit, page, filterList })}
                filtersData={poFilterList}
                filterSchema={filterSchema}
                filterQueryObject={filterQueryObject}
                isLoading={isPOfilterListLoading}
                filtersActiveStatus={filtersActiveStatus}
            />
        </div>
    };

    const getPoStatus = (status) => {
        if (status === 'CANCELLED') {
            return 'text-scogoclosed';
        } else if (status === 'APPROVED' || status === 'OPEN') {
            return 'text-scogo2e';
        } else if (status.startsWith('Pending')) {
            return 'text-scogoorange';
        }
        return 'text-black';
    };

    const poMenuButtonIcon = (count) => {
        return <span className='text-font12 font-normal text-scogoorange cursor-pointer'> {count}</span>;
    };

    const poTableBluePrint = {
        table: [
            {
                head: {
                    render: (headName) => {
                        return filterComponent({ headName, keysToFilter: [filterSchema.createdAt] });
                    },
                    headName: 'PR Number',
                },
                body: {
                    render: (po) => {
                        const createdAt = validateDateValue(po.creationDate) && moment(new Date(validateResponseDate(po.creationDate)));
                        return <RenderPRNumber prNumber={po.prNumber} requested={po.requested} createdAt={createdAt} poType={po.poType} nextRenewalDate={po.nextRenewalDate} />
                    },
                },
            },
            {
                head: {
                    render: (headName) => {
                        return filterComponent({ headName, keysToFilter: [filterSchema.nextRenewableDate] });
                    },
                    headName: 'PO Status',
                    width: 0.8,

                },
                body: {
                    render: (po) => <RenderPoStatus poType={po.poType} nextRenewalDate={po.nextRenewalDate} />
                },
            },
            {
                head: {
                    render: (headName) => {
                        return filterComponent({
                            headName,
                            keysToFilter: [filterSchema.vendors],
                        });
                    },
                    headName: 'PO Number',
                },
                body: {
                    render: (po) => {
                        return <RenderPoNumber poNmber={po.poNmber} createdAt={po.createdAt} approvals={po.approvals} _id={po._id} linkType={po.linkType} vendorId={po.vendorId} vendorName={po.vendorName} openPaymentSideDrawer={openPaymentSideDrawer} />;
                    },
                },
            },

            {
                head: {
                    render: (headName) => {
                        const customerFilter = filterSchema.customers;
                        const sitesFilter = filterSchema.sites;
                        const keysToFilter = [sitesFilter, filterSchema.endusers];
                        if (isToShowCustomerFilter) keysToFilter.unshift(customerFilter);
                        return filterComponent({ headName, keysToFilter });
                    },
                    headName: 'Site Details',
                    columnRowCss: 'break-all',
                    width: 1,
                },
                body: {
                    render: (item) => {
                        return (
                            <div className='w-48'>
                                {isToShowCustomerFilter && (
                                    <IconToolTip placement={'bottom'} title={`Customer Name : ${item?.customerName}`}>
                                        <div className='w-4/5 truncate mt-1'>{item?.customerName}</div>
                                    </IconToolTip>
                                )}
                                <div className='relative cursor-pointer text-scogobgsky w-full font-medium flex items-center gap-2'>
                                    {item?.siteId > 0 && <>
                                        <span className='max-w-8/12'>
                                            <SitePopup siteName={item?.siteName} siteId={item?.siteId} />
                                        </span>
                                        <span className='w-4/12'>
                                            <IconToolTip placement={'bottom'} title={`Copy Site Id`}>
                                                <span
                                                    className='cursor-pointer material-icons text-scogobgsky text-lg hover:text-scogoorange pt-1'
                                                    onClick={() => dispatch(copyToClipboard({ data: validateStringValue(item?.siteName) }))}
                                                >
                                                    content_copy
                                                </span>
                                            </IconToolTip>
                                        </span>
                                    </>}
                                </div>

                                <IconToolTip placement={'bottom'} title={`EndUser Name : ${item?.enduserName}`}>
                                    <div className='w-4/5 truncate mt-1'> {item?.enduserName} </div>
                                </IconToolTip>
                            </div>
                        );
                    },
                },
            },
            {
                head: {
                    render: (headName) => {
                        return headName
                    },
                    headName: 'Asset',
                    width: 0.7,
                    columnRowCss: 'break-all',
                },
                body: {
                    render: (po) => {
                        const po_assets = po.assets;
                        return (<RenderPoAssets assets={po_assets} />);
                    },
                },
            },
            {
                head: {
                    render: (headName) => {
                        return filterComponent({
                            headName,
                            keysToFilter: [filterSchema.status],
                        });
                    },
                    headName: 'Status',
                },
                body: {
                    render: (item) => {
                        const statusColor = getPoStatus(item?.status);
                        return (
                            <>
                                <div className={`flex font-medium ${statusColor}`}>
                                    <OverflowTip someLongText={item?.status} />
                                </div>
                            </>
                        );
                    },
                },
            },
            {
                head: {
                    render: (headName) => {
                        return headName;
                    },
                    headName: 'Files',
                    hide: showPaymentSideDrawer,
                },
                body: {
                    render: (po) => {
                        return (
                            <>
                                {po?.quotationpdf && (
                                    <IconToolTip placement={'bottom'} title='Download'>
                                        <a rel='noreferrer' target='_blank' href={po?.quotationpdf} className='hover:text-scogoorange'>
                                            <div className={`flex items-center mt-2 text-font12  ${clickableTextClass} `}>
                                                <span className='material-icons material-icons-outlined cursor-pointer text-scogoclosed text-font14 mr-1'>picture_as_pdf</span>
                                                <OverflowTip someLongText='Quotation.pdf' />
                                            </div>
                                        </a>
                                    </IconToolTip>
                                )}
                                {po?.approvedpdf && (
                                    <IconToolTip placement={'bottom'} title='Download'>
                                        <a rel='noreferrer' target='_blank' href={po?.approvedpdf} className='hover:text-scogoorange'>
                                            <div className={`flex items-center mt-2 text-font12  ${clickableTextClass} `}>
                                                <span className='material-icons material-icons-outlined cursor-pointer text-scogoclosed text-font14 mr-1'>picture_as_pdf</span>
                                                <OverflowTip someLongText='PO.pdf' />
                                            </div>
                                        </a>
                                    </IconToolTip>
                                )}
                            </>
                        );
                    },
                },
            },
            {
                head: {
                    render: (headName) => {
                        return headName;
                    },
                    headName: 'Invoices',
                    columnRowCss: 'break-all',
                },
                body: {
                    render: (item) => {
                        const invoiceLimit = 3;
                        const invoicesList = [];
                        if (Array.isArray(item?.invoices) && item?.invoices?.length > 0) {
                            item?.invoices?.forEach((invoice) => {
                                invoicesList.push({
                                    isSystemGenerated: invoice.isSystemGenerated ? true : false,
                                    invoiceLink: invoice.invoiceLink,
                                    invoiceNumber: invoice.invoiceNumber,
                                });
                                if (invoice.isSystemGenerated && Array.isArray(invoice.attachments) && invoice.attachments.length > 0) {
                                    invoice.attachments.forEach((attachment) => {
                                        invoicesList.push({
                                            isSystemGenerated: invoice.isSystemGenerated ? true : false,
                                            invoiceLink: attachment.path,
                                            invoiceNumber: attachment.name,
                                        });
                                    });
                                }
                            });
                        }
                        return (
                            <>
                                <div className='items-center relative'>
                                    {Array.isArray(invoicesList) && invoicesList.length > 0 && (
                                        <>
                                            {invoicesList?.slice(0, invoiceLimit).map((elem, index) => {
                                                return elem.isSystemGenerated ? (
                                                    <SystemGeneratedInvoice link={elem.invoiceLink} number={elem.invoiceNumber} key={index} />
                                                ) : (
                                                    <UserGeneratedInvoice
                                                        link={elem.invoiceLink}
                                                        number={elem.invoiceNumber}
                                                        showUserLabel={elem.isSystemGenerated === false}
                                                        key={index}
                                                    />
                                                );
                                            })}
                                            {invoicesList.length > invoiceLimit && (
                                                <Menus
                                                    position='ml-auto'
                                                    activeStatus={false}
                                                    menuButtonIcon={poMenuButtonIcon(`+ ${invoicesList.length - invoiceLimit}`)}
                                                    handleItemClick={(e) => file_download(e)}
                                                    itemLable={invoicesList.slice(invoiceLimit, invoicesList.length).map((elem) => elem?.invoiceNumber)}
                                                    items={invoicesList.slice(invoiceLimit, invoicesList.length)}
                                                    itemHoverClassName='hover:text-scogoorange cursor-pointer'
                                                    itemsClassName='origin-top-right absolute px-3 py-2  lg:top-0 top-4 mt-2 z-10 rounded-md  shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none w-56 max-w-xs '
                                                    itemClassName={(elem) =>
                                                        ` block mt-1 text-font11 px-p-6 py-p-3 rounded-3px ${elem?.isSystemGenerated ? 'text-scogoprimary' : ''}`
                                                    }
                                                    hoverText={(elem) =>
                                                        elem?.isSystemGenerated
                                                            ? `System Generated: ${elem.invoiceNumber}`
                                                            : `${elem?.isSystemGenerated === false ? 'User Generated: ' : ''}${elem?.invoiceNumber}`
                                                    }
                                                />
                                            )}
                                        </>
                                    )}
                                </div>
                            </>
                        );
                    },
                },
            },
            {
                head: {
                    render: (headName) => {
                        return headName;
                    },
                    headName: 'Amount Details',
                    hide: showPaymentSideDrawer,
                    width: 1.5,
                    columnRowCss: 'break-words',
                },
                body: {
                    render: (item) => {
                        return (
                            <>
                                <div className='text-scogo99 mt-1'>Base Amount</div>
                                <div className='flex'>
                                    <OverflowTip someLongText={`₹ ${item?.paidAmount?.toFixed(2)} / ${item?.totalAmount?.toFixed(2)}`} />
                                </div>
                                <div className='text-scogo99 mt-1'>Tax Amount</div>
                                <div className='flex'>
                                    <OverflowTip someLongText={`₹ ${item?.paidTax?.toFixed(2)} / ${item?.totalTax?.toFixed(2)}`} />
                                </div>
                            </>
                        );
                    },
                },
            },
            {
                head: {
                    render: (headName) => {
                        return headName;
                    },
                    headName: 'Actions',
                    width: 0.5,
                },
                body: {
                    render: (item) => {
                        return (
                            <div className='flex justify-center'>
                                <PoTableDropdown ticket={item} />
                            </div>
                        );
                    },
                },
            },
        ],
    };

    return (
        <GridTable
            schema={poTableBluePrint}
            rowData={poTableList}
            isLoading={poTableLoading}
            rowsPerPage={rowsPerPage}
            page={paymentsPage}
            tableError={'No PO Found'}
            tablePagination={view === 'group_view' ? false : true}
            count={poCount}
            tablePaginationHandler={(limit, page) => updateQueryFilters({ limit, page, filterSchema, filterList })}
            rowKey='_id'
            checkRowShouldHighlight={(po) => showPaymentSideDrawer && po._id === paymentSideDrawerPayload?._id}
            columnsGap='gap-x-6'
            tableBodyHeight={tableBodyHeight}
            onTableScroll={onPoTableScroll}
            isNextTableDataLoading={isNextTableDataLoading}
            isGroupView={isGroupView}
        />
    );
}


const PoTableDropdown = (props) => {
    const { ticket } = props;
    const poActionList = GetPaymentsListLogic('getPoActions', { ticket });
    return <DropdownMenu menuData={poActionList} componentOrIcon={'more_vert'} item={ticket} iconColor={'text-scogoprimary'} />;
};


const RenderPoAssets = ({ assets, showInRow = 3 }) => {
    const dispatch = useDispatch();
    const onEditConfigClick = (asset) => {
        dispatch(
            openCustomModal({
                heading: (
                    <span className='text-black'>
                        Edit Asset Config | <span className='text-scogobgsky text-font16'>{getAssetTitle(asset)}</span>
                    </span>
                ),
                modalWidth: '70rem',
                modalHeight: '84vh',
                maxHeight: '84vh',
                modalComponent: <AccordionJSON poAssets={asset} assetId={asset.id} serial_number={asset.serialNo} showJsonEditor={isDeviceConfigurationRequired(asset)} source='po' />,
            })
        );
    }
    return <div className='relative'>
        {Array.isArray(assets) && assets.length > 0 && (
            <>
                {assets?.slice(0, showInRow).map((asset) => {
                    return <p key={asset.asset_number} className='flex items-center text-font11 cursor-pointer font-medium'>
                        <IconToolTip title={`Asset Number ${validateStringValue(asset?.assetType?.title)}`}>
                            <span className='text-scogobgsky'>{validateStringValue(asset?.assetType?.title)}</span>
                        </IconToolTip>
                        <span onClick={() => onEditConfigClick(asset)} className='material-icons hover:text-scogoorange text-scogogray text-3xl'>settings_applications</span>
                    </p>
                })}
                {assets.length > showInRow && (
                    <Menus
                        className={'relative'}
                        position='ml-auto'
                        activeStatus={false}
                        menuButtonIcon={<span className='text-font12 font-normal text-scogoorange cursor-pointer'>{`+ ${assets.length - showInRow}`}</span>}
                        handleItemClick={(elem) => onEditConfigClick(elem)}
                        itemLable={assets.slice(showInRow, assets.length).map((elem) => elem.asset_number)}
                        items={assets.slice(showInRow, assets.length)}
                        itemHoverClassName='cursor-pointer'
                        itemsClassName='origin-top-right absolute px-3 py-2  lg:top-0 top-4 mt-2 z-10 rounded-md  shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none w-60 max-w-xs font-medium text-scogoprimary'
                        itemClassName={(elem) =>
                            ` block mt-1 text-font11 px-p-6 py-p-3 rounded-3px`
                        }
                        renderItem={(asset) => {
                            return <p key={asset.asset_number} className='flex items-center text-font11 cursor-pointer font-medium'>
                                <IconToolTip title={`Asset Number ${validateStringValue(asset?.assetType?.title)}`}>
                                    <span className='text-scogobgsky'>{validateStringValue(asset?.assetType?.title)}</span>
                                </IconToolTip>
                                <span onClick={() => onEditConfigClick(asset)} className='material-icons hover:text-scogoorange text-scogogray text-3xl'>settings_applications</span>
                            </p>
                        }}
                    />
                )}
            </>
        )}
    </div>
}


export const RenderPRNumber = ({ prNumber, requested, createdAt }) => {

    return (
        <>
            <IconToolTip placement={'bottom'} title={`PR Number : ${prNumber}`}>
                <div className='w-full truncate '> {prNumber} </div>
            </IconToolTip>
            <IconToolTip placement={'bottom'} title={`Requested By : ${requested}`}>
                <div className='w-full truncate  mt-1'> {requested} </div>
            </IconToolTip>
            {createdAt && (
                <p className='text-font09 font-medium mt-1'>
                    <span className='text-font09 font-normal'>Created At : </span> {moment(createdAt).format('DD MMM YYYY')}
                </p>
            )}
        </>
    );
}


export const RenderPoNumber = ({ poNmber, createdAt, approvals, _id, linkType, vendorId, vendorName, openPaymentSideDrawer = () => { }, onPoNumberClick = () => { }, source }) => {

    const handlePoDetail = (poNmber, createdAt, approvals, id) => {
        if (source === 'assets') {
            onPoNumberClick();
        } else {
            const sideBartitle = (
                <div className='cursor-pointer flex gap-1 w-full'>
                    <IconToolTip title={`Audit: ${poNmber}`} placement='bottom'>
                        <p className='truncate w-full'>Audit({poNmber})</p>
                    </IconToolTip>
                </div>
            );

            const paymentSideDrawerPayload = {
                title: sideBartitle,
                createdAt: createdAt,
                approvals: approvals,
                _id: id,
            };
            openPaymentSideDrawer({ paymentSideDrawerName: 'poAudit', paymentSideDrawerPayload });
        };
    }

    return <div className='w-11/12'>
        <IconToolTip placement={'bottom'} title={`PO Number : ${poNmber}`}>
            <div
                className='w-full truncate mt-1 hover:text-scogoorange cursor-pointer text-scogobgsky  '
                onClick={() => handlePoDetail(poNmber, createdAt, approvals, _id)}
            >
                {poNmber}
            </div>
        </IconToolTip>

        {linkType && <div
            className='w-full truncate mt-1 text-scogobgsky  '
        >
            <span className='text-scogogray'>Link Type:</span> {linkType}
        </div>}
        <UserPopup
            userId={vendorId}
            from='CUSTOMER'
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <p className='text-font12 text-scogobgsky w-fit max-w-full hover:text-scogoorange mt-2'>
                <OverflowTip textClass={'text-scogobgsky'} someLongText={vendorName} />
            </p>
        </UserPopup>
    </div>
}

export const RenderPoStatus = ({ poType, nextRenewalDate }) => {
    const getPoTypeColor = (type) => {
        if (type === 'NEW') {
            return 'bg-white text-scogo2e border border-scogosuccess';
        } else if (['RENEWAL DUE', 'RENEWAL_DUE', 'RENEWED'].includes(type)) {
            return 'bg-white text-scogoprimary border border-scogoprimary';
        }
        return 'bg-transparent';
    };
    return <div className='flex gap-1 flex-col'>
        <IconToolTip placement={'bottom'} title={`PO Type :${poType}`}>
            <span className={`max-w-max text-font08 px-p-4 py-p-3 items-center rounded-3px mr-2 mb-1 ${getPoTypeColor(poType)}`}>{poType}</span>
        </IconToolTip>
        {nextRenewalDate && (
            <IconToolTip placement={'bottom'} title='Next renewal date'>
                <span className='text-font09 font-medium'>{moment(new Date(nextRenewalDate)).format('DD MMM YYYY')}</span>
            </IconToolTip>
        )}
    </div>
}