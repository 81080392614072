import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import { exportModuleCsvData } from '../../../actions/utils';
import { actualValueForDropdown } from '../../../utils/common';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import Form from '../../../common/Form';
import Select from '../../../common/Form/Select';
import MultipleEmail from '../../../common/Form/MultipleEmail';

import { sites as sitesTab, assets as assetsTab, warehouses as warehousesTab, spareAssets as spareAssetsTab } from '../../Inventory/InventoryController';
import {
    generateCSVDataForAssetInventory,
    generateCSVDataForSiteInventory,
    generateCSVDataForWarehouseInventory,
    generateCSVDataForSpareAssetsInventory,
} from '../../../utils/csv';
import { useForm } from 'react-hook-form';

const formDropdownData = {
    acts: [
        { key: 0, value: 'company', label: 'Companies Act' },
        { key: 1, value: 'incometax', label: 'Income Tax Act' },
    ],
    rates: [
        { key: 0, value: 'slm', label: 'SLM' },
        { key: 1, value: 'wdv', label: 'WDV' },
        { key: 2, value: 'both', label: 'Both' },
    ],
    categories: [
        { key: 0, value: 'categorise', label: 'Categorise' },
        { key: 1, value: 'uncategorise', label: 'Uncategorise' },
        { key: 2, value: 'both', label: 'Both' },
    ],
    archives: [
        { key: 0, value: 'archive', label: 'Archived' },
        { key: 1, value: 'unarchive', label: 'UnArchived' },
        { key: 2, value: 'both', label: 'Both' },
    ],
    ranges: [
        { key: 0, value: 'all', label: 'All' },
        { key: 1, value: 'daterange', label: 'Custom Date Range' },
    ],
    spareassetsData: [
        { key: 0, value: 'all', label: 'All' },
        { key: 1, value: 'assetwisecount', label: 'Asset Wise Count' },
    ],
};

export default function DownloadReport(props) {
    const { tabName, siteId, warehouseId, count = 0, selectedData, role, status = 'ALL', customerId, defaultEmail } = props;
    const formName = 'downloadSitesReport';
    const dispatch = useDispatch();
    const [csvHeaders, setCsvHeaders] = useState([]);
    const [csvData, setCsvData] = useState([]);
    const { filterPayload } = useSelector((state) => state.inventory);
    const { formButtonLoading } = useSelector((state) => state.loading);

    useEffect(() => {
        let customer = true;
        if (role === 6) {
            customer = false;
        }
        let csvConfig;

        const lookupfunction = (type) => {
            let lookup = {
                [sitesTab.id]: generateCSVDataForSiteInventory,
                [assetsTab.id]: generateCSVDataForAssetInventory,
                [warehousesTab.id]: generateCSVDataForWarehouseInventory,
                [spareAssetsTab.id]: generateCSVDataForSpareAssetsInventory,
            };
            return lookup[type];
        };

        if ([sitesTab.id, assetsTab.id, warehousesTab.id, spareAssetsTab.id].includes(tabName)) {
            if (selectedData?.length > 0 && parseInt(count) > 0) {
                csvConfig = lookupfunction(tabName)(selectedData, customer);
                setCsvHeaders(csvConfig.headers);
                setCsvData(csvConfig.data);
            }
        }
    }, [count, selectedData, role, tabName]);

    let defaultValues = {
        act: formDropdownData.acts.find((act) => act.value === 'company'),
        rate: formDropdownData.rates.find((act) => act.value === 'both'),
        categorise: formDropdownData.categories.find((act) => act.value === 'both'),
        archive: formDropdownData.archives.find((act) => act.value === 'both'),
        download: true,
        range: formDropdownData.ranges.find((act) => act.value === 'all'),
        assetswise: formDropdownData.spareassetsData.find((act) => act.value === 'all'),
        emails: defaultEmail ? [defaultEmail] : [],
    };

    const closeModal = () => {
        dispatch(closeModalAction());
    };

    const onSubmit = (formData) => {
        const { emails } = formData;
        let data = {
            act: actualValueForDropdown(formData?.act),
            assetswise: actualValueForDropdown(formData?.assetswise),
            range: actualValueForDropdown(formData?.range),
            download: actualValueForDropdown(formData?.download),
            archive: actualValueForDropdown(formData?.archive),
            categorise: actualValueForDropdown(formData?.categorise),
            rate: actualValueForDropdown(formData?.rate),
        };

        dispatch(
            exportModuleCsvData({
                data,
                emails: emails,
                module: 'INVENTORY',
                tab: tabName,
                status,
                customerId,
                formName,
                siteId,
                warehouseId,
                filterData: filterPayload,
            })
        );
    };

    const conditionalDownloadReportForms = (tab) => {
        if (tab === sitesTab.id) {
            if (count === 'ALL') {
                return <MultipleEmail type={'multiEmail'} name='emails' required label='Email To' />;
            } else {
                return (
                    <div className='px-5 pb-4 flex justify-center text-scogoorange text-font13'>
                        <CSVLink data={csvData} headers={csvHeaders} filename={`Site_${moment(new Date()).format('DD-MM-YYYY')}.csv`}>
                            {' '}
                            Click Here to Download CSV
                        </CSVLink>
                    </div>
                );
            }
        }
        if (tab === assetsTab.id) {
            if (count === 'ALL') {
                return (
                    <>
                        <div className='grid grid-cols-2 rows-fr grid-flow-row-dense'>
                            <Select label='Select Act' required name='act' options={formDropdownData.acts} />
                            <Select label='Select Rate' required name='rate' options={formDropdownData.rates} />
                        </div>
                        <div className='grid grid-cols-2 rows-fr grid-flow-row-dense'>
                            <Select label='Categorise/Uncategorise' required name='categorise' options={formDropdownData.categories} />
                            <Select label='Archived/UnArchived' required name='archive' options={formDropdownData.archives} />
                        </div>
                        <MultipleEmail type={'multiEmail'} name='emails' required label='Email To' />
                    </>
                );
            } else {
                return (
                    <div className='px-2 pb-4 flex justify-center text-scogoorange text-font13'>
                        <CSVLink data={csvData} headers={csvHeaders} filename={`Assets_${moment(new Date()).format('DD-MM-YYYY')}.csv`}>
                            {' '}
                            Click Here to Download CSV
                        </CSVLink>
                    </div>
                );
            }
        }

        if (tab === warehousesTab.id) {
            if (count === 'ALL') {
                return <MultipleEmail type={'multiEmail'} name='emails' required label='Email To' />;
            } else {
                return (
                    <div className='px-5 pb-4 flex justify-center text-scogoorange text-font13'>
                        <CSVLink data={csvData} headers={csvHeaders} filename={`Warehouse_${moment(new Date()).format('DD-MM-YYYY')}.csv`}>
                            {' '}
                            Click Here to Download CSV
                        </CSVLink>
                    </div>
                );
            }
        }

        if (tab === spareAssetsTab.id) {
            if (count === 'ALL') {
                return (
                    <>
                        <div className='grid grid-cols-1 rows-fr grid-flow-row-dense'>
                            <Select label='Assets' required name='assetswise' options={formDropdownData.spareassetsData} />
                        </div>
                        <MultipleEmail type={'multiEmail'} name='emails' required label='Email To' />
                    </>
                );
            } else {
                return (
                    <div className='px-5 pb-4 flex justify-center text-scogoorange text-font13'>
                        <CSVLink data={csvData} headers={csvHeaders} filename={`SpareAssets_${moment(new Date()).format('DD-MM-YYYY')}.csv`}>
                            {' '}
                            Click Here to Download CSV
                        </CSVLink>
                    </div>
                );
            }
        }
    };

    const methods = useForm({ defaultValues, mode: 'all' });
    const isMultiSelected = count > 0;

    return (
        <>
            {isMultiSelected && <MultipleItems renderForm={conditionalDownloadReportForms(tabName)} count={count} />}
            {!isMultiSelected && (
                <Form
                    onSubmit={onSubmit}
                    onCancel={closeModal}
                    submitButtonText={'Download'}
                    defaultValues={defaultValues}
                    showSubmit
                    submitContainerClassName='flex gap-2 justify-end py-4 px-4'
                    isLoading={formButtonLoading[formName]}
                    methods={methods}
                >
                    <div className='px-3'>{conditionalDownloadReportForms(tabName)}</div>
                    <div className='px-5 flex  py-5'>
                        <span className='text-scogoorange text-font13 '>Count :</span> &nbsp; <span className='text-scogogray text-font13'>{count}</span>
                    </div>
                </Form>
            )}
        </>
    );
}

const MultipleItems = ({ renderForm, count }) => {
    return (
        <>
            {' '}
            <div className='px-5 flex justify-center py-5 '>
                <span className='text-scogoorange text-font13 '>Count :</span> &nbsp; <span className='text-scogogray text-font13'>{count}</span>
            </div>{' '}
            {renderForm}
        </>
    );
};
