import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import Form from '../../../common/Form';
import Upload from '../../../common/Form/FileUpload';

import { bulkUploadTicketFile } from '../../../actions/tickets';
import { closeModalAction } from '../../../actions/modal';

import config from '../../../config';

const formName = 'update_ticket_dates';

const UpdateTicketDate = () => {
    const dispatch = useDispatch()
    const { loggedUser } = useSelector((state) => state.auth);
    const { formButtonLoading } = useSelector((state) => state.loading);

    const fileUrl = `${config.api.staticAssetsUrl}/others/update_ticket_dates_sample.csv`

    const methods = useForm({ defaultValues: {}, mode: 'all' });

    const onSubmit = (formValues) => {
        dispatch(bulkUploadTicketFile({ property: 'TICKET_DATES', customerId: loggedUser.customer_id, files: formValues.files, formName }))
    };

    return (
        <>
            <Form
                methods={methods}
                onSubmit={onSubmit}
                formName={formName}
                submitButtonText={'Submit'}
                onCancel={() => dispatch(closeModalAction())}
                showSubmit
                submitContainerClassName='flex gap-2 justify-end py-4 px-4'
                isLoading={formButtonLoading[formName]}
            >
                <div className='px-2 my-2'>
                    Date should be in <b>YYYY-MM-DD</b> format
                </div>
                <div className='px-2 flex'>
                    <Upload className='w-full cursor-pointer' name='files' label='Upload CSV' required />
                </div>
                <div className='px-5 py-2'>
                    <p className='text-scogoorange font-normal text-font11 cursor-pointer hover:text-scogobgsky max-w-max'>
                        <a href={fileUrl} download>
                            Download Sample file
                        </a>
                    </p>
                </div>
            </Form>
        </>
    );
}

export default UpdateTicketDate