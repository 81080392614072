import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { openCustomModal } from '../../../actions/modal';
import { clickableTextClass } from '../../../common/Css';
import { validateDateValue } from '../../../utils/common';
import { DateComponent } from '../../../common/TableReusableColumns';
import { useCallback } from 'react';
import { ActionColumn } from './ActionColumn';
import ISPCloseFeasibility from '../../team/Modals/ISPCloseFeasibility';
import FormTable from '../../../common/Form/Table';
import Input from '../../../common/Form/Input';
import Select from '../../../common/Form/Select';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

export const linkTypeOptions = [
    { key: 1, value: 'fibre', label: 'Fibre' },
    { key: 2, value: 'rf', label: 'RF' },
];

export const TableForm = ({ isOnlyView, isEditingRow, setSelectedIspCustomerId, setIsEditingRow, ticketId, spList, searchTerm, isISPsListLoading, arrayName, transformedBoqList }) => {
    const dispatch = useDispatch();
    const isIspResponded = (response) => ['YES', 'NO'].includes(response);
    const isUserEditing = (id) => isEditingRow.includes(id);
    const showeFeasibleAction = ({ isEditable, response, ispId }) => {
        return !isOnlyView && (isEditable || (isUserEditing(ispId) && isIspResponded(response)));
    };
    const { eligibleSPs } = useSelector((state) => state.sp);

    const { setValue } = useFormContext();

    const onSearchSps = useCallback(
        (searchKey) => {
            let list = spList;
            if (searchKey) {
                list = spList.filter((sp) => {
                    return sp?.name.toLowerCase().trim().includes(searchKey) || sp?.mobile?.toLowerCase().includes(searchKey) || sp?.email?.toLowerCase().includes(searchKey);
                });
            }
            setValue(arrayName, list);
        },
        [spList, setValue, arrayName]
    );

    useEffect(() => {
        onSearchSps(searchTerm);
    }, [searchTerm, onSearchSps]);

    useEffect(() => {
        setValue(arrayName, eligibleSPs);
    }, [setValue, eligibleSPs, arrayName]);


    const openIspCloseFeasibility = (isp) => {
        let heading = (
            <span className='text-black'>
                Close Feasibility :&nbsp;
                <span className='text-scogobgsky text-font16'>{isp.name}</span>
            </span>
        );
        let modalWidth = '110rem';
        let modalHeight = 'auto';
        const user = {
            customerId: isp.customer_id,
            sp_id: isp?.sp_id,
            mobile: isp.mobile,
            name: isp.name,
        };
        let modalComponent = <ISPCloseFeasibility userDetails={user} />;
        dispatch(openCustomModal({ heading, modalWidth, modalHeight, modalComponent }));
    };

    const tableSchema = {
        table: [
            {
                head: {
                    thClassName: `w-80 truncate pl-3`,
                    headName: `ISP`,
                },
                body: {
                    bodyClassName: `w-80 pl-3 break-all`,
                    render: (isp) => {
                        let username = isp.name;
                        let ispMobile = isp.mobile;
                        let ispCompanyName = isp.sp_name;
                        let showRadio = isp.response === 'YES';

                        return (
                            <div>
                                <div className='flex items-center gap-2'>
                                    <label className='flex gap-2'>
                                        {showRadio && (
                                            <input
                                                className='focus:ring-0 focus:ring-offset-0 text-scogoorange h-4 w-4 mt-1'
                                                type='radio'
                                                name={'isp_customer_id'}
                                                onChange={(e) => {
                                                    setSelectedIspCustomerId(isp.customer_id);
                                                }}
                                            />
                                        )}
                                        <p className={clickableTextClass}>{username}</p>
                                    </label>
                                    {isp.response !== 'NO' && (
                                        <span
                                            onClick={() => openIspCloseFeasibility(isp)}
                                            className='text-scogoprimary material-icons text-lg cursor-pointer hover:text-scogoorange'
                                        >
                                            open_in_new
                                        </span>
                                    )}
                                </div>
                                <p className='break-words'>
                                    {ispMobile}/({ispCompanyName})
                                </p>
                            </div>
                        );
                    },
                },
            },
            {
                head: {
                    thClassName: `w-80 truncate`,
                    headName: `Share & Response`,
                },
                body: {
                    bodyClassName: `w-80`,
                    render: (isp) => {
                        return (
                            <div>
                                {validateDateValue(isp.sharedAt) ? (
                                    <p className={'font-medium'}>
                                        Shared At :{' '}
                                        <span className='font-normal'>
                                            <DateComponent date={isp.sharedAt} />
                                        </span>
                                    </p>
                                ) : (
                                    <></>
                                )}
                                {isp.sharedBy ? (
                                    <p className='font-medium'>
                                        Shared By : <span className='font-normal'>{isp.sharedBy}</span>
                                    </p>
                                ) : (
                                    <></>
                                )}
                                {validateDateValue(isp.lastResponseTime) ? (
                                    <p className={'font-medium'}>
                                        Responded At :
                                        <span className='font-normal'>
                                            <DateComponent date={isp.lastResponseTime} />
                                        </span>
                                    </p>
                                ) : (
                                    <></>
                                )}
                            </div>
                        );
                    },
                },
            },
            {
                head: {
                    thClassName: `w-80 truncate mb-2`,
                    headName: `Link Type`,
                    render: (headName) => <span>{headName} *</span>,
                },
                body: {
                    bodyClassName: `w-80 truncate`,
                    render: (isp, { rowIndex }) => {
                        let isReadOnly = !showeFeasibleAction({ isEditable: isp.isEditable, response: isp.response, ispId: isp.id });
                        return <Select name={`${arrayName}[${rowIndex}].linktype`} options={transformedBoqList} padding='pr-2 py-2' readOnly={isReadOnly} findOption={(options, value) => {
                            return options.find((opt) => {
                                return opt.value.toLowerCase() === value.toLowerCase();
                            })
                        }} />;
                    },
                },
            },
            {
                head: {
                    thClassName: `w-80 truncate`,
                    headName: `One Time Charge`,
                    render: (headName) => (
                        <div>
                            <p>{headName} *</p>
                            <p className='px-4'>(Annually)</p>
                        </div>
                    ),
                },
                body: {
                    bodyClassName: `w-80 truncate`,
                    render: (isp, { rowIndex }) => {
                        let isReadOnly = !showeFeasibleAction({ isEditable: isp.isEditable, response: isp.response, ispId: isp.id });

                        return <Input padding='pr-2 py-3' type='number' name={`${arrayName}[${rowIndex}].otc`} placeholder='One Time Charge' readOnly={isReadOnly} />;
                    },
                },
            },
            {
                head: {
                    thClassName: `w-80 truncate`,
                    headName: `Router Cost`,
                    render: (headName) => (
                        <div>
                            <p>{headName} *</p>
                            <p className='px-6'>(Annually)</p>
                        </div>
                    ),
                },
                body: {
                    bodyClassName: `w-80 truncate`,
                    render: (isp, { rowIndex }) => {
                        let isReadOnly = !showeFeasibleAction({ isEditable: isp.isEditable, response: isp.response, ispId: isp.id });

                        return <Input padding='pr-2 py-3' type='number' name={`${arrayName}[${rowIndex}].router_cost`} placeholder='Router Cost' readOnly={isReadOnly} />;
                    },
                },
            },
            {
                head: {
                    thClassName: `w-80 truncate`,
                    headName: `Monthly Plan`,
                    render: (headName) => (
                        <div>
                            <p>{headName} *</p>
                            <p className='px-4'>(Annually)</p>
                        </div>
                    ),
                },
                body: {
                    bodyClassName: `w-80 truncate`,
                    render: (isp, { rowIndex }) => {
                        let isReadOnly = !showeFeasibleAction({ isEditable: isp.isEditable, response: isp.response, ispId: isp.id });

                        return <Input padding='pr-2 py-3' type='number' name={`${arrayName}[${rowIndex}].monthly_plan`} placeholder='Monthly Plan' readOnly={isReadOnly} />;
                    },
                },
            },
            {
                head: {
                    thClassName: `w-80 truncate`,
                    headName: `Static IP Charge`,
                    render: (headName) => (
                        <div>
                            <p>{headName} *</p>
                            <p className='px-4'>(Annually)</p>
                        </div>
                    ),
                },
                body: {
                    bodyClassName: `w-80 truncate`,
                    render: (isp, { rowIndex }) => {
                        let isReadOnly = !showeFeasibleAction({ isEditable: isp.isEditable, response: isp.response, ispId: isp.id });

                        return (
                            <Input padding='pr-2 py-3' type='number' name={`${arrayName}[${rowIndex}].static_ip_charge`} placeholder='Static Ip Charges' readOnly={isReadOnly} />
                        );
                    },
                },
            },
            {
                head: {
                    thClassName: `w-80 truncate`,
                    headName: `Remark`,
                },
                body: {
                    bodyClassName: `w-80 truncate`,
                    props: { className: 'px-0 py-0' },
                    render: (isp, { rowIndex }) => {
                        let isReadOnly = !showeFeasibleAction({ isEditable: isp.isEditable, response: isp.response, ispId: isp.id });

                        return <Input padding='pr-2 py-3' type='text' name={`${arrayName}[${rowIndex}].remark`} placeholder='Remark' readOnly={isReadOnly} />;
                    },
                },
            },
            {
                head: {
                    thClassName: `w-72 truncate px-2`,
                    headName: `Feasibility`,
                },
                body: {
                    bodyClassName: `w-64 px-2`,
                    render: (isp, { rowIndex }) => (
                        <ActionColumn
                            isp={isp}
                            setIsEditingRow={setIsEditingRow}
                            isUserEditing={isUserEditing}
                            index={rowIndex}
                            isIspResponded={isIspResponded}
                            showeFeasibleAction={showeFeasibleAction}
                            ticketId={ticketId}
                            isOnlyView={isOnlyView}
                            arrayName={arrayName}
                        />
                    ),
                },
            },
        ],
    };

    return <FormTable isLoading={isISPsListLoading} schema={tableSchema} name={arrayName} allowAdd={false} tableBodyStyle={{ height: isOnlyView ? '58vh' : '50vh' }} />;
};
