import { partners } from '../types';

export const getPartnersList = ({ role, customer, userType, fk_cluster_id, stateIds, customer_id }) => ({
    type: partners.PARTNERS_LIST_REQUEST,
    payload: { role, customer, userType, fk_cluster_id, stateIds, customer_id },
});

export const getPartnersListDropDown = ({ user_role, status, type, customerId, customer }) => ({
    type: partners.PARTNERS_LIST_DROPDOWN_REQUEST,
    payload: { user_role, status, type, customerId, customer },
});
export const getPartnerDetails = ({ userIds, roles = [], status, execution_date, accepted_ticket_count, forSp }) => ({
    type: partners.GET_PARTNER_DETAILS_LIST_REQUEST,
    payload: { userIds, roles, status, execution_date, accepted_ticket_count, forSp },
});

export const clearPartnerListDropDown = () => ({
    type: partners.CLEAR_PARTNERS_DROPDOWN,
    payload: {},
});

export const deletePartner = ({ userId, role, userType, customer, customerId, formName, userName, tabName, fk_cluster_id }) => ({
    type: partners.DELETE_PARTNER_REQUEST,
    payload: { userId, role, customerName: customer, userType, customerId, formName, userName, tabName, fk_cluster_id },
});

export const verifyPartnerDetails = ({ spId, bankId, data, role, formName }) => ({
    type: partners.VERIFY_PARTNER_DETAILS_REQUEST,
    payload: { spId, bankId, data, role, formName },
});

export const sendAndroidAppLink = (formValues) => ({
    type: partners.SEND_ANDROID_APP_LINK_REQUEST,
    payload: { formValues },
});

export const getIwanDropdown = () => ({
    type: partners.IWAN_DROPDOWN_REQUEST,
    payload: {},
});

export const downloadISPFeasibityReport = ({ emails, formName, closeModalOnSuccess }) => ({
    type: partners.DOWNLOAD_ISP_FEASIBILITY_REPORT_REQUEST,
    payload: { emails, formName, closeModalOnSuccess },
});

export const getGroupsUsersListDropdown = () => ({
    type: partners.GROUP_USERS_LIST_REQUEST,
    payload: {},
});

export const clearParterListDropdownFromStore = (payload) => ({
    type: partners.CLEAR_PARTNERS_LIST_DROPDOWN,
    payload,
});

export const listCustomerISP = ({ customerId }) => ({
    type: partners.PARTNERS_LIST_DROPDOWN_REQUEST,
    payload: { user_role: [4, 7, 12], type: 'ISP', status: 0, customer: 'scogo', customerId },
});

export const clearPartnerListFromStore = (payload) => ({
    type: partners.CLEAR_PARTNER_LIST_FROM_STORE,
    payload
});


export const giveOrRemoveHelpAccess = ({ userId, access, formName, role, customerName, userType, fk_cluster_id }) => ({
    type: partners.REMOVE_OR_GIVE_HELP_ACCESS_REQUEST,
    payload: { userId, access, formName, role, customerName, userType, fk_cluster_id },
});


export const getPartnerList = ({ roles, status, limit, page, customer_id, callDetails, tab }) => ({
    type: partners.GET_PARTNER_LIST_REQUEST,
    payload: { roles, status, limit, page, customer_id, callDetails, tab },
})

export const getPartnerFilterList = ({ roles, status }) => ({
    type: partners.GET_PARTNER_FILTER_REQUEST,
    payload: { roles, status },
})

export const getPartnerCount = () => ({
    type: partners.GET_PARTNER_COUNT_REQUEST,
})

export const removePartnerListFromStore = () => ({
    type: partners.REMOVE_PARTNER_LIST_FROM_STORE
})

export const getFilteredPartnerList = ({ customerId, count, limit, page, payload, query, roles, status, tab, callDetails }) => ({
    type: partners.GET_FILTERED_PARTNER_LIST_REQUEST,
    payload: { customerId, count, limit, page, payload, query, roles, status, tab, callDetails },
})

export const setTeamFilterAndPaginationData = ({ payload, page, limit, query, tab, roles, status }) => ({
    type: partners.SET_TEAM_FILTER_DETAILS_IN_STORE,
    payload: { payload, page, limit, query, tab, roles, status }
})


export const getGroupUserList = ({ roles, status, limit, page, append }) => ({
    type: partners.GET_GROUP_USER_LIST_REQUEST,
    payload: { roles, status, limit, page, append },
})

export const getFilteresUserList = ({ roles, status, limit, page, query, append }) => ({
    type: partners.GET_FILTERED_GROUP_USER_LIST_REQUEST,
    payload: { roles, status, limit, page, query, append },
})

export const groupUserListFromStore = () => ({
    type: partners.CLEAR_GROUP_USER_FROM_STORE,
})

export const uploadAddressPincodeFile = ({ file, formName, area }) => ({
    type: partners.UPLOAD_ADDRESS_PINCODE_FILE_REQUEST,
    payload: { file, formName, area }
})

export const getAreaCount = ({ file, formName, area }) => ({
    type: partners.GET_AREA_COUNT_REQUEST,
    payload: { file, formName, area }
})

export const GenerateAffiliateLinkAction = ({ userId, formName, invokeFrom }) => ({
    type: partners.GENERATE_AFFILIATE_LINK,
    payload: { userId, formName, invokeFrom },
})