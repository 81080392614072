import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { downloadFiles } from '../../../../../actions/utils';
import Bedges from '../../../../../common/Badges';
import ReadMoreText from '../../../../../common/ReadMoreText';
import { modulo, getLookupWitHDefaultValue } from '../../../../../utils/common';
import Audio from './Audio';
import Image from './Image';
import OtherFile from './OtherFile';
import PdfFile from './PdfFile';
import TextFile from './TextFile';
import XlsFile from './XlsFile';
import Video from './Video';
import { showApprovalOptionsinMedia } from '../../../ChatController';
import { openMediaViewModal } from '../../../../../actions/modal';
import { useSelector } from 'react-redux';



const filesComponetLookup = getLookupWitHDefaultValue({
    lookup: {
        audio: Audio,
        video: Video,
        image: Image,
        pdf: PdfFile,
        text: TextFile,
        xls: XlsFile,
        others: OtherFile,
    },
    defaultValue: OtherFile,
});

function AttachmentMessage({ message, formatText, audioClassName, isSearchMessage = false, callStepFields, isTicketChat }) {
    const [currentAttachmentIndex, setCurrentAttachmentIndex] = useState(0);
    const dispatch = useDispatch();
    const { loggedUser } = useSelector(state => state.auth);
    const attachmentsArray = message?.attachments;
    const caption = attachmentsArray?.[currentAttachmentIndex]?.caption || '';
    const tags = attachmentsArray?.[currentAttachmentIndex]?.tags || [];
    const handleArrowClick = (arrowDirection) => {
        const increment = arrowDirection === 'right' ? 1 : -1;
        const nextIndex = modulo(currentAttachmentIndex + increment, attachmentsArray.length);
        setCurrentAttachmentIndex(nextIndex);
    };
    let isMultiple = attachmentsArray?.length > 1;

    const getArrow = (direction) => {
        const classLookup = { left: 'left-2', right: 'right-2' };
        const angleLookup = { left: 'navigate_before', right: 'navigate_next' };
        return (
            <div
                className={`${classLookup[direction]} absolute cursor-pointer select-none backdrop-filter backdrop-blur-lg rounded-full ${isSearchMessage ? "top-20" : "top-36"}   bg-gray-50 w-11 border border-scogoblack text-center  hover:bg-scogobgsky text-black `}
                onClick={() => handleArrowClick(direction)}
            >
                <span className=' text-font18 mt-1 material-icons font-bold '>{angleLookup[direction]}</span>
            </div>
        );
    };

    const attachmentUi = (currentAttachmentIndex) => {
        const { fileName, fileUrl, type, mimeType, _id, isApproved, approver, rejecter, height, width } = attachmentsArray?.[currentAttachmentIndex];
        const FilesComponent = filesComponetLookup?.[type];
        const role = loggedUser.role.id, lType = loggedUser.type;
        const styleComponent = () => {
            if (isMultiple && isSearchMessage) {
                return "h-52"
            } else if (isMultiple) {
                return "h-80 relative "
            } else {
                return 'w-full max-h-600'
            }
        }
        return (
            <div className={`overflow-hidden  cursor-pointer p-1 ${styleComponent()}`}>
                <div className='attachment w-full h-full'>
                    <FilesComponent
                        fileName={fileName}
                        fileUrl={fileUrl}
                        type={type}
                        mimeType={mimeType}
                        showIcon={isMultiple}
                        downloadFile={({ fileUrl, fileName, mimeType }) => {
                            const file = { url: fileUrl, fileName: fileName, mimeType };
                            dispatch(downloadFiles({ files: [file] }));
                        }}
                        audioClassName={audioClassName}
                        conversationId={message.conversation._id}
                        attachmentId={_id}
                        messageId={message._id}
                        showApprovalOptions={
                            showApprovalOptionsinMedia({ conversationType: message.conversation.type, isApproved, role, type: lType })}
                        isApproved={isApproved}
                        approver={approver}
                        rejecter={rejecter}
                        onAttachmentClick={() =>
                            dispatch(
                                openMediaViewModal({
                                    attachments: message.attachments,
                                    sender: message.from,
                                    openedMediaIndex: currentAttachmentIndex,
                                    isTicketChat,
                                    callStepFields
                                })
                            )
                        }
                        callStepFields={callStepFields}
                        height={height}
                        width={width}
                    />
                </div>
                {isMultiple && (
                    <>
                        <Bedges className='absolute bottom-4 right-2 opacity-70 text-font12 font-normal text-scogoddd bg-scogodarkgray h-5 leading-5 rounded-5px px-p-6'>
                            {currentAttachmentIndex + 1}/{attachmentsArray.length}
                        </Bedges>
                        {getArrow('left')}
                        {getArrow('right')}
                    </>
                )}
            </div>
        );
    };

    return (
        <>
            {attachmentUi(currentAttachmentIndex)}

            {
                <ReadMoreText
                    className={` text-scogo15 text-font13 pr-6 pb-1 pl-2 font-normal cursor-pointer ${isMultiple ? ` ${isSearchMessage ? "w-80" : "w-82"} ` : 'w-full '}`}
                    text={formatText({ text: caption, tags: tags })}
                    numberOfLine={4}
                />
            }
        </>
    );
}

export default AttachmentMessage;
