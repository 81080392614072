import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { demoteUser } from '../../../actions/users';
import { listAllState, listAllCity } from '../../../actions/admin';
import { getGroupUserList, groupUserListFromStore } from '../../../actions/partners';
import { closeModalAction } from '../../../actions/modal';
import { GetQueryParam, actualValueForDropdown, validateStringValue } from '../../../utils/common';
import Form from '../../../common/Form';
import Input from '../../../common/Form/Input';
import { useForm } from 'react-hook-form';
import Select from '../../../common/Form/Select';

const psp = { key: 'PSP', value: 'PSP', label: 'PSP' };
const sp = { key: 'SP', value: 'SP', label: 'SP' };


const incentiveTypeLabels = [
    { key: 'INCENTIVE', value: 'INCENTIVE', label: 'Incentive' },
    { key: 'BUSINESS', value: 'BUSINESS', label: 'Business' },
];

const formName = 'demoteUser';

export default function DemoteUserModal(props) {
    const dispatch = useDispatch();
    const { allState, allCity } = useSelector((state) => state.admin);
    const { isAllStateLoading, isAllCityLoading, formButtonLoading } = useSelector((state) => state.loading);
    const { groupUserList } = useSelector((state) => state.partners);
    const { userDetails, role, userType, customer } = props;

    const defaultValues = {
        demote: '',
        email: '',
        incentive_limit: '',
        incentive_type: '',
        states: [],
        cities: [],
        clusterId: '',
        pincodes: [],
    };
    let promoteToLabels = [psp, sp];
    const tab = GetQueryParam('tab')

    if (tab === 'cluster_psp') {
        promoteToLabels = [sp]
    }

    const submit = (formValues) => {
        let payload = {
            demote: formValues.demote.value,
            email: formValues.email,
        };

        if (actualValueForDropdown(formValues.demote) === psp.value) {
            let selectedStates = formValues.states.map((item) => item.value);
            let selectedCities = formValues.cities.map((item) => item.value);
            payload = {
                ...payload,
                clusterId: formValues?.clusterId?.value,
                incentive_limit: formValues.incentive_limit,
                incentive_type: formValues?.incentive_type?.value,
                states: selectedStates,
                cities: selectedCities,
            };
        }
        dispatch(demoteUser({ userId: userDetails.id, data: payload, role, userType, customer, formName }));
    };

    const getDropdownData = useCallback(() => {
        dispatch(getGroupUserList({ roles: 'CLUSTER,PSP,SUPERVISOR', limit: 100000, page: 1 }));
    }, [dispatch]);

    useEffect(() => {
        getDropdownData();
        return () => dispatch(groupUserListFromStore());
    }, [getDropdownData, dispatch]);

    const clusterDropdownList = useMemo(() => {
        if (Array.isArray(groupUserList) && groupUserList.length > 0) {
            return groupUserList.map((user) => {
                return {
                    value: user.id,
                    label: `${validateStringValue(user.first_name)} ${validateStringValue(user.last_name)}`,
                    key: user.id
                }
            })
        }
        return [];
    }, [groupUserList]);

    const methods = useForm({ defaultValues, mode: 'all' });
    const { watch } = methods;
    const demote = watch('demote');
    const isDemotingToPSP = actualValueForDropdown(demote) === psp.value;

    useEffect(() => {
        if (isDemotingToPSP) {
            dispatch(listAllState({ dropdown: true, userterritories: true, role: 12 }));
            dispatch(listAllCity({ dropdown: true, userterritories: true, role: 12 }));
        }
    }, [dispatch, isDemotingToPSP]);

    return (
        <Form
            methods={methods}
            isLoading={formButtonLoading[formName]}
            onSubmit={submit}
            submitButtonText={'Demote'}
            onCancel={() => dispatch(closeModalAction())}
            showSubmit
            submitContainerClassName='flex gap-2 justify-end py-4 px-4'
        >
            <div className='px-3 flex justify-between items-end'>
                <Select className={'w-full'} label='Demote To' name='demote' options={promoteToLabels} required />
                <Input
                    className='w-full pt-4'
                    label='Email'
                    name='email'
                    required
                    type={'text'}
                    validate={(email) => {
                        return (
                            email.match(
                                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            ) || 'Invalid Email'
                        );
                    }}
                />
            </div>
            {isDemotingToPSP && (
                <>
                    <div className='px-3 flex justify-between'>
                        <Select className={'w-full'} label='Cluster' name='clusterId' options={clusterDropdownList} required shouldUnregister />
                    </div>
                    <div className='px-3 flex justify-between items-end'>
                        <Select className='w-6/12' label='Type' name='incentive_type' required options={incentiveTypeLabels} shouldUnregister />
                        <Input className='w-6/12 pt-4' label='Incentive Limit' name='incentive_limit' required type={'number'} shouldUnregister />
                    </div>

                    <div className='px-3 flex'>
                        <Select className='w-6/12' label='States' name='states' options={allState} isMulti={true} isLoading={isAllStateLoading} shouldUnregister />
                        <Select className='w-6/12' label='Cities' name='cities' options={allCity} isMulti={true} isLoading={isAllCityLoading} shouldUnregister />
                    </div>
                </>
            )}
        </Form>
    );
}
