import { put, call, takeEvery, all, take } from 'redux-saga/effects';
import { superdesk, toast, teams, loading, modal, customer, project, user, utils } from '../types';
import lambdas from '../lambdas/apis';

export function* getSuperDeskCapexTypes(action) {

    const { customerId } = action.payload
    let queryParams = { customerId }
    try {
        yield put({ type: loading.GET_SUPERDESK_CAPEX_TYPES_LOADING, payload: true })
        const response = yield call(lambdas.superdesk.getCustomerCapexTypes, {}, {}, queryParams)
        if (response.data.code === 200) {
            let types = response.data.data;
            yield put({ type: superdesk.GET_SUPERDESK_CAPEX_TYPES_SUCCESS, payload: types })
        } else {
            yield put({ type: superdesk.GET_SUPERDESK_CAPEX_TYPES_FAILURE, payload: { message: 'Oops Something went wrong' } })
        }
    } catch (error) {
        yield put({ type: superdesk.GET_SUPERDESK_CAPEX_TYPES_FAILURE, payload: { message: error } })
    }
    yield put({ type: loading.GET_SUPERDESK_CAPEX_TYPES_LOADING, payload: false })
}

export function* addTeams(action) {
    const { data, type, customerId, activeTab, isToCallAPI, formName } = action.payload
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } })
        const response = yield call(lambdas.superdesk.addTeams, {}, {}, {}, data)
        if (response.data.code === 201) {
            if (isToCallAPI) {
                if (activeTab !== 'scmCustomer') {
                    yield put({ type: customer.CUSTOMERS_TEAMS_LIST_REQUEST, payload: { type, limit: 48, count: true, customerId } })
                } else {
                    yield put({ type: customer.CUSTOMERS_TEAMS_BY_CUSTOMERID_LIST_REQUEST, payload: { type, limit: 48, count: true, customerId } })
                }
            }
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Added Teams Succesfully' } })
            yield put({ type: teams.IS_SHOW_ADD_TEAM, payload: { isShowAddTeam: false } })
        } else {
            yield put({ type: superdesk.ADD_TEAM_FAILURE, payload: { message: 'Oops Something went wrong' } })
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } })
        }
    } catch (error) {
        yield put({ type: superdesk.ADD_TEAM_FAILURE, payload: { message: error } })
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } })
}

export function* deleteTeamAction(action) {
    const { teamId, teamName, formName } = action.payload
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } })
        const response = yield call(lambdas.superdesk.deleteTeam, {}, { teamId }, {}, {})
        if (response.data.code === 200) {
            const message = `${teamName} Deleted Successfully`
            yield put({ type: modal.CLOSE_MODAL })
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message } })
            yield put({ type: superdesk.DELETE_TEAM_SUCCESS, payload: { teamId } })
        } else {
            yield put({ type: modal.CLOSE_MODAL })
            yield put({ type: superdesk.DELETE_TEAM_FAILURE, payload: { message: 'Oops Something went wrong' } })
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } })
        }
    } catch (error) {
        yield put({ type: superdesk.DELETE_TEAM_FAILURE, payload: { message: error } })
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } })
}

export function* addTeamsAgent(action) {
    const { data, formName } = action.payload;
    const { customerId, teams, agentId } = data;
    const form = {
        property: "teams",
        customerId,
        teams,
        agentId,
        teamAction: "ADD"
    }
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } })
        const response = yield call(lambdas.superdesk.addorRemoveTeamsAgent, {}, {}, {}, form)
        if (response.data.code === 200) {
            yield put({ type: modal.CLOSE_MODAL })
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'User Details updated Successfully' } })
            yield put({ type: customer.CUSTOMERS_ADDONS_LIST_REQUEST, payload: { customerId } })

        } else {
            yield put({ type: modal.CLOSE_MODAL })
            yield put({ type: superdesk.ADD_TEAM_AGENT_FAILURE, payload: { message: 'Oops Something went wrong' } })
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } })
        }
    } catch (error) {
        yield put({ type: superdesk.ADD_TEAM_AGENT_FAILURE, payload: { message: error } })
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } })
}

export function* removeTeamsAgent(action) {
    const { data, customeMessage, projectId, tabName, userCustomerId, formName } = action.payload
    const { customerId, teams, agentId, agentName, teamName } = data;
    const form = {
        property: "teams",
        customerId,
        teams,
        agentId,
        teamAction: "REMOVE"
    }
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } })
        const response = yield call(lambdas.superdesk.addorRemoveTeamsAgent, {}, {}, {}, form)
        if (response.data.code === 200) {
            let message = customeMessage ? customeMessage : `${agentName} removed from ${teamName}`;
            if (tabName === 'projects') {
                yield put({ type: project.PROJECT_TEAM_LIST_REQUEST, payload: { customerId: userCustomerId, projectId, agentsInfo: true } })
            } else if (tabName === 'team_users') {
                yield put({ type: customer.CUSTOMERS_ADDONS_EDIT_LIST_SUCCESS, payload: { agent_id: agentId, team_id: teams[0] } })
            } else if (tabName === 'teams') {
                yield put({ type: user.TEAM_USER_EDIT_SUCCESS, payload: { agent_id: agentId, team_id: teams[0] } })
            }
            yield put({ type: modal.CLOSE_MODAL })
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message } })
            yield put({ type: superdesk.REMOVE_TEAM_AGENT_SUCCESS, payload: data })
        } else {
            yield put({ type: modal.CLOSE_MODAL })
            yield put({ type: superdesk.REMOVE_TEAM_AGENT_FAILURE, payload: { message: 'Oops Something went wrong' } })
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message ? response.data.data.message : 'Something went wrong' } })
        }
    } catch (error) {
        yield put({ type: superdesk.REMOVE_TEAM_AGENT_FAILURE, payload: { message: error } })
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } })
}

export function* transferUsersInTeams(action) {
    const { data } = action.payload
    const { customerId, teams, agentId, sourceTeamName, destTeamName, agentName } = data;
    const form = {
        property: "teams",
        customerId,
        teams,
        agentId,
        teamAction: "TRANSFER"
    }
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: true })
        const response = yield call(lambdas.superdesk.addorRemoveTeamsAgent, {}, {}, {}, form)
        if (response.data.code === 200) {
            let message = `${agentName} tranferred from ${sourceTeamName} to ${destTeamName}.`
            yield put({ type: modal.CLOSE_MODAL })
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message } })
            yield put({ type: superdesk.TRANSFER_TEAMS_AGENT_SUCCESS, payload: teams })
        } else {
            yield put({ type: superdesk.TRANSFER_TEAMS_AGENT_FAILURE, payload: { message: 'Oops Something went wrong' } })
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } })
        }
    } catch (error) {
        yield put({ type: superdesk.TRANSFER_TEAMS_AGENT_FAILURE, payload: { message: error } })
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: false })
}

export function* updateAgentsProperty(action) {
    const { data, propertyName, userName, enableOrDisable } = action.payload
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: true })
        const response = yield call(lambdas.superdesk.updateAgentsProperty, {}, {}, {}, data);
        if (response.data.code === 200) {
            const message = `${userName} ${propertyName} ${enableOrDisable} Successfully`
            yield all([
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message } }),
            ])
        } else {
            yield all([
                yield put({ type: superdesk.UPDATE_USERS_PROPERTY_FAILURE, payload: { message: 'Oops Something went wrong' } }),
                yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } }),
                yield put({ type: superdesk.UPDATE_USERS_INSTORE_PROPERTY, payload: { userData: data } })
            ])
        }
    } catch (error) {
        yield put({ type: superdesk.UPDATE_USERS_PROPERTY_FAILURE, payload: { message: error } })
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: false })
}

export function* addUsersInTeam(action) {
    const { data, teamId, teamName, customerId, projectDetail, tabName, formName } = action.payload

    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } })
        const response = yield call(lambdas.superdesk.addUsersInTeam, {}, { teamId }, {}, data);
        if (response.data.code === 200) {
            yield all([
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: `Users Updated Successfully in ${teamName}` } }),
                yield put({ type: modal.CLOSE_MODAL }),
            ])
            if (tabName === 'projects') {
                yield put({ type: project.PROJECT_TEAM_LIST_REQUEST, payload: { projectId: projectDetail.id, customerId, agentsInfo: true } })
            } else {
                yield put({ type: superdesk.GET_TEAMS_BY_ID_REQUEST, payload: { teamId } })
                yield take([superdesk.GET_TEAMS_BY_ID_SUCCESS, superdesk.GET_TEAMS_BY_ID_FAILURE])
                yield put({ type: superdesk.ADD_USERS_TEAM_SUCCESS, payload: { teamId } })
            }
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } })
            yield put({ type: modal.CLOSE_MODAL })
        }
        yield put({ type: superdesk.ADD_USERS_TEAM_FAILURE, payload: { message: 'Something went wrong' } })
    } catch (error) {
        yield put({ type: superdesk.ADD_USERS_TEAM_FAILURE, payload: { message: error } })
        yield put({ type: modal.CLOSE_MODAL })
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } })
}

export function* getTeamsById(action) {
    const { teamId } = action.payload
    try {
        const response = yield call(lambdas.superdesk.getTeamsById, {}, { teamId }, {}, {});
        if (response.data.code === 200) {
            yield put({ type: superdesk.GET_TEAMS_BY_ID_SUCCESS, payload: { data: response.data.data } })
        } else {

            yield put({ type: superdesk.GET_TEAMS_BY_ID_FAILURE, payload: { message: 'Oops Something went wrong' } })
        }
    } catch (error) {
        yield put({ type: superdesk.GET_TEAMS_BY_ID_FAILURE, payload: { message: error } })
    }
}

export function* editTeam(action) {
    const { data, teamId, formName } = action.payload;

    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } })
        const response = yield call(lambdas.superdesk.editTeam, {}, { teamId }, {}, data)
        if (response.data.code === 200) {
            yield put({ type: modal.CLOSE_MODAL })
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Team Details updated Successfully' } })
            yield put({ type: customer.EDIT_TEAM_SUCCESS, payload: { udatedDetails: data, teamId } })

        } else {
            yield put({ type: modal.CLOSE_MODAL })
            yield put({ type: superdesk.EDIT_TEAM_FAILURE, payload: { message: 'Oops Something went wrong' } })
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } })
        }
    } catch (error) {
        yield put({ type: superdesk.EDIT_TEAM_FAILURE, payload: { message: error } })
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } })
}

export default function* superdeskSaga() {
    yield takeEvery(superdesk.GET_SUPERDESK_CAPEX_TYPES_REQUEST, getSuperDeskCapexTypes)
    yield takeEvery(superdesk.ADD_TEAM_REQUEST, addTeams)
    yield takeEvery(superdesk.DELETE_TEAM_REQUEST, deleteTeamAction)
    yield takeEvery(superdesk.UPDATE_USERS_PROPERTY_REQUEST, updateAgentsProperty)
    yield takeEvery(superdesk.ADD_TEAM_AGENT_REQUEST, addTeamsAgent)
    yield takeEvery(superdesk.ADD_USERS_TEAM_REQUEST, addUsersInTeam)
    yield takeEvery(superdesk.REMOVE_TEAM_AGENT_REQUEST, removeTeamsAgent)
    yield takeEvery(superdesk.TRANSFER_TEAMS_AGENT_REQUEST, transferUsersInTeams)
    yield takeEvery(superdesk.GET_TEAMS_BY_ID_REQUEST, getTeamsById)
    yield takeEvery(superdesk.EDIT_TEAM_REQUEST, editTeam)
}