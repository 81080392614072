import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import { bulkEditExecutionDate, editExecutionDate } from '../../../actions/tickets';
import { getExecutionDateChangeRemarks } from '../../../actions/ticketAssignment';
import { actualValueForDropdown, filterExecutionDate } from '../../../utils/common';
import Form from '../../../common/Form';
import DatePicker from '../../../common/Form/DatePicker';
import Select from '../../../common/Form/Select';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import Checkbox from '../../../common/Form/Checkbox';
import { isScm } from '../../../utils/role';
import { isChangePlanAllowed } from '../../../utils/access';
const formName = 'edit_execution_date';

export default function EditExecutionDate({ ticketId, isBulk, ticketIds, current_execution_date }) {

    const defaultValues = {
        edited_execution_date: current_execution_date ? new Date(moment(current_execution_date).format('YYYY-MM-DD H:m:s')) : null,
    };
    const { remarks } = useSelector((state) => state.tickets);
    const dispatch = useDispatch();
    const { formButtonLoading } = useSelector((state) => state.loading);
    const { loggedUser } = useSelector((state) => state.auth);
    const role = loggedUser.role.id;
    const type = loggedUser.type;

    useEffect(() => {
        dispatch(getExecutionDateChangeRemarks());
    }, [dispatch]);

    const submit = (formValues) => {
        const edited_execution_date = new Date(moment(formValues.edited_execution_date).subtract({ hours: 5.5 }));
        let change_msg = actualValueForDropdown(formValues.change_msg);
        let update_planned_date = formValues.update_planned_date ? 1 : 0;
        if (isBulk) {
            dispatch(bulkEditExecutionDate({ ticketIds, edited_execution_date, change_msg, formName, refresh: true }));
        } else {
            dispatch(editExecutionDate({ update_planned_date, ticketId, edited_execution_date, change_msg, formName, refresh: true }));
        }
    };

    const methods = useForm({ defaultValues, mode: 'all' });

    const showUpdatePlannedDate = !isBulk && isChangePlanAllowed(loggedUser.frontend_controller);

    return (
        <Form
            methods={methods}
            onSubmit={submit}
            formName={formName}
            buttonPostion={'justify-end'}
            submitButtonText={'Submit'}
            onCancel={() => dispatch(closeModalAction())}
            showSubmit
            submitContainerClassName='flex gap-2 justify-end py-4 px-4'
            isLoading={formButtonLoading[formName]}
        >
            <div className='px-2 flex '>
                <DatePicker
                    className='w-6/12 '
                    padding='px-2 py-2'
                    name='edited_execution_date'
                    label='Execution Date & Time'
                    showTimeSelect
                    required
                    minDate={new Date()}
                    filterTime={(date) => filterExecutionDate(date)}
                />
                <Select className='w-6/12 ' label='Comment' name='change_msg' options={remarks} required />
            </div>
            {showUpdatePlannedDate && isScm(role, type) && <div className='px-2 flex'>
                <Checkbox label='Update Planned Date' id={'update_planned_date'} name='update_planned_date' />
            </div>}
        </Form>
    );
}
