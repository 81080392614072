import React, { useEffect, useState } from 'react';
import Divider from '@mui/material/Divider';
import { useDispatch, useSelector } from 'react-redux';
import { getNotification, readNotification } from '../actions/users';
import Loader from '../common/Loader';
import { textCss } from '../common/Css';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

import NotificationCard from './NotificationCard';

export default function NotificationList(props) {
    const { isUnreadNonZero } = props;
    const dispatch = useDispatch();
    const [unreadNotificationStyle, setUnreadNotificationStyle] = useState('rgb(230,230,230)')
    const { notifications } = useSelector(state => state.user);
    const { isNotificationsLoading, isNextNotificationsLoading } = useSelector(
        state => state.loading
    );

    useEffect(() => {
        dispatch(getNotification({ offset: props.offset }));
    }, []);

    const handleReadNotification = notificationId => {
        setUnreadNotificationStyle('#fff')
        dispatch(readNotification({ notificationId }));
    };

    if (isNotificationsLoading) {
        return <SkeltonCard />;
    }

    return (
        <div>
            <div className={`${textCss?.headerTwo} mb-1 p-2 flex items-center justify-between px-4 font-medium`}>
                <p className='p-2'>{`Unread: ${props.unreadCount}`}</p>
                {isUnreadNonZero && <p
                    className={`cursor-pointer text-scogoprimary hover:shadow rounded-lg p-2 ${textCss?.headerTwo}`}
                    onClick={() => handleReadNotification('all')}
                >
                    Read All
                </p>}
            </div>
            <Divider />
            <div className='h-full'>
                {notifications?.notificationList?.map(notification => {
                    return <NotificationCard onClick={handleReadNotification} notification={notification} unreadNotificationStyle={unreadNotificationStyle} />
                })}
                {isNextNotificationsLoading && (
                    <div className='my-2 absolute bottom-0 left-0 right-0'>
                        <Loader color='#F48A21' size='20' speed='1' thickness='2' />
                    </div>
                )}
            </div>
        </div>
    );
}



const SkeltonCard = () => {
    return (
        <Box sx={{ paddingLeft: 2, paddingRight: 2, paddingBottom: 2, paddingTop: 2 }}>
            <div className='mb-4'>
                <Skeleton
                    sx={{ background: 'rgb(221,221,221)' }}
                    variant='rectangular'
                    width={'full'}
                    height={25}
                    animation="wave"
                />
            </div>
            {[1, 2, 3].map(() => {
                return (
                    <div className='mt-1'>
                        <Skeleton
                            sx={{ background: 'rgb(221,221,221)' }}
                            variant='rectangular'
                            width={'full'}
                            height={50}
                            animation="wave"
                        />
                    </div>
                );
            })}
        </Box>
    );
};
