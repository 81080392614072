import React from 'react';
import _ from 'lodash';
import { textCss } from '../../../../../common/Css';

export default function TextArea(props) {
    const {
        className,
        label,
        required,
        name,
        value,
        onChangeHandler,
        onBlurHandler,
        placeholder,
        errors,
        errorMessage,
        onKeyPress,
        isEditable = true,
        disabled,
        maxLength,
        textAreaRows = '2',
        textAreaCols = '20',
    } = props;

    const handleInputChange = (event, errorMessage) => {
        if (isEditable) onChangeHandler(event, errorMessage);
    };
    return (
        <>
            <div>
                <textarea
                    onKeyPress={onKeyPress}
                    name={name}
                    required={required}
                    value={value}
                    onChange={(event) => handleInputChange(event, errorMessage)}
                    placeholder={placeholder}
                    className={`${className}`}
                    onBlur={(event) => {
                        onBlurHandler(event, errorMessage);
                    }}
                    disabled={disabled}
                    maxLength={maxLength}
                    rows={textAreaRows}
                    cols={textAreaCols}
                />
                <br />
                {_.get(errors, name) && <span className='text-scogoclosed text-font10 font-normal'>{_.get(errors, name)}</span>}
            </div>
        </>
    );
}
