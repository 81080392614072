export const GET_TOOLS_REQUEST = 'GET_TOOLS_REQUEST';
export const GET_TOOLS_SUCCESS = 'GET_TOOLS_SUCCESS';
export const GET_TOOLS_FAILURE = 'GET_TOOLS_FAILURE';


export const ADD_TOOLS_REQUEST = 'ADD_TOOLS_REQUEST';
export const ADD_TOOLS_SUCCESS = 'ADD_TOOLS_SUCCESS';
export const ADD_TOOLS_FAILURE = 'ADD_TOOLS_FAILURE';

export const ADD_TOOLS_SP_REQUEST = 'ADD_TOOLS_SP_REQUEST';
export const ADD_TOOLS_SP_SUCCESS = 'ADD_TOOLS_SP_SUCCESS';
export const ADD_TOOLS_SP_FAILURE = 'ADD_TOOLS_SP_FAILURE';

export const UPDATE_SITE_TYPE_REQUEST = 'UPDATE_SITE_TYPE_REQUEST';
export const UPDATE_SITE_TYPE_SUCCESS = 'UPDATE_SITE_TYPE_SUCCESS';
export const UPDATE_SITE_TYPE_FAILURE = 'UPDATE_SITE_TYPE_FAILURE';