import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GroupView } from '../../Payments/GroupView';
import NoResultFound from '../../../common/NoResultFound';
import { Accordion, AccordionSummary, AccordionDetails } from '../../project/reusableComponents/AccordianForTeams';
import { getAllAssets } from '../../../actions/inventory';
import { getCustomerIdOfLoginUser } from '../../../utils/utils';
import GridTable from '../../../common/GridTable';
import { AssetActionColumn, RenderEditAssetConfig, RenderOwnersList, transformAsset } from './AssetDetails';
import IconToolTip from '../../../common/IconToolTip';
import { RenderPRNumber, RenderPoNumber, RenderPoStatus } from '../../Payments/components/Po/PoTable';
import { validateDateValue, validateNumberValue, validateResponseDate, validateStringValue } from '../../../utils/common';
import moment from 'moment';
import OverflowTip from '../../../common/OverflowTip';
import { DateComponent } from '../../../common/TableReusableColumns';
import { copyToClipboard } from '../../../actions/utils';
import { clickableTextClass } from '../../../common/Css';
import { openChatInNewTab } from '../../../actions/conversations';
import AssetSerialNumber from '../../Modals/Inventory/AssetSerialNumber';
import DetailsColumn from '../reusableComponents/AddOption/AssetDetails';
import { GetInventoryActions } from '../InventoryController';

const defaultLimit = 10;

const RenderTableHead = () => {
    return <div className='bg-white relative border-1 border-gray-200 shadow-md w-full rounded-t-lg h-[5vh]'>
        <div className='text-[1.3rem] text-scogogray font-medium grid grid-cols-5 items-center py-2 w-full' style={{ width: '90vw', marginLeft: 'auto' }}>
            <span>Enduser/Customer</span>
            <span>Total Assets</span>
            <span>Available</span>
            <span>Intransit</span>
            <span>Delivered</span>
        </div>
    </div>
}

const RenderTable = ({ id, isOpen, selectedSubTab, selectedSiteId }) => {
    const { assetsGroupByEndusersListLoading, isNextAssetsGroupByEndusersListLoading } = useSelector((state) => state.loading);
    const { assetsGroupByEndusers, hasAssetsGroupByEndusersEnd } = useSelector((state) => state.inventory);
    const dispatch = useDispatch();
    const assetsData = assetsGroupByEndusers[id] ? assetsGroupByEndusers[id].map((asset) => transformAsset(asset)) : [];
    const isAssetsLoading = assetsGroupByEndusersListLoading[id];
    const isNextAssetsLoading = isNextAssetsGroupByEndusersListLoading[id];
    const isListEnd = hasAssetsGroupByEndusersEnd[id];
    const { loggedUser } = useSelector((state) => state.auth);
    const loggedUserCustomerId = getCustomerIdOfLoginUser(loggedUser);
    const siteId = selectedSiteId ? selectedSiteId : 0;

    useEffect(() => {
        if (isOpen && (!assetsGroupByEndusers[id] || assetsGroupByEndusers[id]?.length === 0)) {
            dispatch(getAllAssets({ endUserId: id, source: 'enduser_view', customerId: loggedUserCustomerId, siteId: 0, count: true, limit: defaultLimit, page: 1, callPoList: true, }));
        }
    }, [id, assetsGroupByEndusers, dispatch, isOpen, loggedUserCustomerId]);

    const onAssetsTableScroll = (e) => {
        const bottom = Math.abs(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight) < 10;
        if (bottom && !assetsGroupByEndusersListLoading[id] && !isNextAssetsGroupByEndusersListLoading[id] && !isListEnd) {
            const limit = defaultLimit;
            const page = Math.ceil(assetsData.length / limit) + 1;
            dispatch(getAllAssets({ endUserId: id, source: 'enduser_view', customerId: loggedUserCustomerId, siteId: 0, count: true, limit: defaultLimit, page, callPoList: true, }));
        }
    };

    const copyOnDoubleClick = (event, candString) => {
        const isDoubleClick = event.detail === 2;
        if (isDoubleClick) {
            dispatch(copyToClipboard({ data: candString }));
        }
    };

    const isToShowCustomerDataInTable = GetInventoryActions('isToShowCustomerData');

    const handleOpenLocation = (address) => {
        return window.open(`https://maps.google.com?q=${`${address}`}`, '_blank');
    };

    const handleCopyAddress = (address) => {
        let addressToCopy = `${address}`;
        dispatch(copyToClipboard({ data: addressToCopy }));
    };

    const isLoggedUserIsEligibleForFeasibility = loggedUser.is_feasibility_eligible === 1;

    const assetTableSchema = {
        table: [
            {
                head: {
                    render: (headName) => {
                        return headName
                    },
                    headName: 'Asset Type',
                },
                body: {
                    render: (item, rowIndex) => {
                        const createdAt = validateDateValue(item?.created_at) && moment(new Date(validateResponseDate(item?.created_at)));
                        return (
                            <div className='w-full flex items-start'>
                                <div className='cursor-pointer w-full'>
                                    <p
                                        className={`text-scogo15 truncate w-11/12 ${clickableTextClass}`}
                                    >
                                        {item?.assetType?.title}
                                    </p>

                                    {item?.assetId?.length > 0 ? <p className='text-font09 text-slate-600 font-medium ' >
                                        <OverflowTip someLongText={item?.assetId} textClass={" text-slate-600"} />
                                    </p> : <></>}

                                    <div className='flex items-center gap-2'>
                                        {createdAt && <DateComponent className='text-scogogray font-normal text-font10' date={createdAt} timeFormat='DD MMM YYYY' />}
                                        <RenderEditAssetConfig asset={item} />
                                    </div>
                                </div>
                            </div>
                        );
                    },
                },
            },
            {
                head: {
                    render: (headName) => {
                        let showCustomerFilter = !selectedSiteId && isToShowCustomerDataInTable;
                        if (showCustomerFilter) {
                            return headName
                        }
                        return headName;
                    },
                    headName: 'Asset Details',
                },
                body: {
                    render: (asset) => {
                        const customerName = asset?.customerCompanyName;
                        return (
                            <div className='w-full'>
                                {isToShowCustomerDataInTable && customerName && (
                                    <IconToolTip title={`Customer Name : ${asset.customerCompanyName}`}>
                                        <p className='w-full truncate'>{asset.customerCompanyName}</p>
                                    </IconToolTip>
                                )}
                                <DetailsColumn
                                    onClick={copyOnDoubleClick}
                                    make={asset?.assetMake}
                                    model={asset?.assetModel}
                                    partcode={asset?.partcode}
                                    supplier={asset?.vendor_supplier_name}
                                />
                            </div>
                        );
                    },
                },
            },
            {
                head: {
                    render: (headName) => {
                        return headName
                    },
                    headName: 'Serial No',
                },
                body: {
                    render: (item) => {
                        return (
                            <AssetSerialNumber
                                serialNumber={item?.serialNo}
                                status={item?.status}
                                statusForDisplay={item?.statusForDisplay}
                                workingCondition={item?.workingCondition}
                                showCopyIcon={typeof item?.serialNo === 'string' && item?.serialNo !== ''}
                            />
                        );
                    },
                },
            },
            {
                head: {
                    render: (headName) => {
                        return headName;
                    },
                    headName: 'Support',
                },
                body: {
                    render: (item) => {
                        const isStartDate = moment(item?.startDate).isValid();
                        const isEndDate = moment(item?.endDate).isValid();

                        return (
                            <>
                                {isStartDate ? (
                                    <>
                                        <p className='text-scogogray font-normal text-font11'>Start Date</p>
                                        {item?.startDate && <DateComponent date={item?.startDate} text={false} timeFormat='DD MMM YYYY' />}
                                    </>
                                ) : (
                                    <></>
                                )}
                                {isEndDate ? (
                                    <>
                                        <p className='text-scogogray font-normal text-font11'>End Date</p>
                                        {item?.endDate && <DateComponent className='text-scogo15 font-medium' date={item?.endDate} text={false} timeFormat='DD MMM YYYY' />}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </>
                        );
                    },
                },
            },
            {
                head: {
                    render: (headName) => {
                        return headName;
                    },
                    headName: 'Last Ticket',
                    width: 1.2,
                },
                body: {
                    render: (item) => {
                        const lastTIcketCreatedAt = validateDateValue(item?.lastTicket?.created_at) && moment(new Date(validateResponseDate(item?.lastTicket?.created_at)));
                        return (
                            <div className='w-full'>
                                {item?.lastTicket?.job_ticket_number && (
                                    <p className='text-scogo15 font-medium w-full '>
                                        <div className='flex w-full gap-1'>
                                            <IconToolTip title={item?.lastTicket?.job_ticket_number} placement='bottom'>
                                                <p
                                                    className={clickableTextClass + 'cursor-pointer hover:text-scogoorange truncate'}
                                                    onClick={() =>
                                                        dispatch(
                                                            openChatInNewTab
                                                                ({
                                                                    conversationId: item.lastTicket.id,
                                                                })
                                                        )
                                                    }
                                                >
                                                    {item?.lastTicket?.job_ticket_number}{' '}
                                                </p>
                                            </IconToolTip>
                                            <IconToolTip placement={'bottom'} title={`Copy Ticket Number`}>
                                                <span
                                                    className='cursor-pointer material-icons text-scogobgsky text-lg'
                                                    onClick={() => dispatch(copyToClipboard({ data: item?.lastTicket?.job_ticket_number }))}
                                                >
                                                    {' '}
                                                    content_copy{' '}
                                                </span>
                                            </IconToolTip>
                                        </div>
                                    </p>
                                )}
                                {lastTIcketCreatedAt && (
                                    <DateComponent className='text-scogogray font-normal text-font10' date={lastTIcketCreatedAt} timeFormat='DD MMM YYYY HH:mm A' />
                                )}

                                <span className='px-2 bg-scogoprimary text-white flex gap-2 items-center rounded-2xl w-fit max-w-11/12' >
                                    <OverflowTip textClass={`text-inherit text-font10 text-black w-full font-normal`} someLongText={item?.lastTicket?.project?.project_name} />
                                </span>
                                {/* <RenderSitePos poList={item.poList} /> */}
                            </div>
                        );
                    },
                },
            },
            {
                head: {
                    render: (headName) => {
                        return headName;
                    },
                    headName: 'Site Details',
                },
                body: {
                    render: (item) => {
                        const { site } = item;
                        const isAddressAvailable = site?.state?.state_name || site?.city?.city_name || site?.address;
                        const address = `${site?.complete_site_address ? site?.complete_site_address : ''}, ${site?.state?.state_name ? site?.state?.state_name : ''}, ${site?.city?.city_name ? site?.city?.city_name : ''
                            } ${site?.fk_pincode_id ? site?.fk_pincode_id : ''}`;
                        return (
                            <div className='w-full'>
                                <p className='flex gap-1'>
                                    <OverflowTip someLongText={item?.siteId} />
                                    <IconToolTip placement={'bottom'} title={`Copy Site ID`}>
                                        <span
                                            className='cursor-pointer material-icons text-scogobgsky text-lg'
                                            onClick={() => dispatch(copyToClipboard({ data: item?.siteId }))}
                                        >
                                            {' '}
                                            content_copy{' '}
                                        </span>
                                    </IconToolTip>
                                </p>
                                {item?.siteBranch && item?.siteId !== item?.siteBranch && (
                                    <p className='w-full' onClick={(e) => copyOnDoubleClick(e, item?.siteBranch)}>
                                        <OverflowTip someLongText={item?.siteBranch} />
                                    </p>
                                )}
                                {item?.enduserName && <IconToolTip placement={'bottom'} title={`EndUser Name : ${item?.enduserName}`}>
                                    <div className='w-4/5 truncate mt-1'> {item?.enduserName} </div>
                                </IconToolTip>}
                                {isAddressAvailable && (
                                    <div className='flex flex-col'>
                                        <IconToolTip placement={'bottom'} title={`${address}`}>
                                            <span className='cursor-pointer text-scogobgsky text-lg'> View Address </span>
                                        </IconToolTip>
                                        <div className='flex'>
                                            <IconToolTip placement={'bottom'} title={`Open Location`}>
                                                <span className='cursor-pointer material-icons text-scogobgsky text-lg' onClick={() => handleOpenLocation(address)}>
                                                    {' '}
                                                    place{' '}
                                                </span>
                                            </IconToolTip>
                                            <IconToolTip placement={'bottom'} title={`Copy Address`}>
                                                <span className='cursor-pointer material-icons text-scogobgsky text-lg' onClick={() => handleCopyAddress(address)}>
                                                    {' '}
                                                    content_copy{' '}
                                                </span>
                                            </IconToolTip>
                                        </div>
                                    </div>
                                )}
                            </div>
                        );
                    },
                },
            },
            {
                head: {
                    render: (headName) => {
                        return headName;
                    },
                    headName: 'Contact Details',
                },
                body: {
                    render: (item) => {
                        return (
                            <div className='w-full'>
                                {item?.usersDetails?.length > 0 ? (
                                    <RenderOwnersList owners={item?.usersDetails} />
                                ) : (
                                    <>
                                        <p className='font-bold text-scogoprimary w-full' onClick={(e) => copyOnDoubleClick(e, item?.siteContactName)}>
                                            <OverflowTip someLongText={item?.siteContactName} />
                                        </p>
                                        <p className='text-scogo99 w-full' onClick={(e) => copyOnDoubleClick(e, item?.siteContactPhone)}>
                                            <OverflowTip someLongText={item?.siteContactPhone} />
                                        </p>
                                        <p className='text-scogo99 w-full' onClick={(e) => copyOnDoubleClick(e, item?.siteContactEmail)}>
                                            <OverflowTip someLongText={item?.siteContactEmail} />
                                        </p>
                                    </>
                                )}
                            </div>
                        );
                    },
                },
            },
            {
                head: {
                    render: (headName) => {
                        return headName
                    },
                    headName: 'PR Number',
                    hide: !isLoggedUserIsEligibleForFeasibility
                },
                body: {
                    render: (asset) => {
                        let po;
                        if (Array.isArray(asset?.poList) && asset.poList.length > 0) {
                            po = asset.poList[0];
                        }
                        if (!po) return <></>;
                        const createdAt = validateDateValue(po.creationDate) && moment(new Date(validateResponseDate(po.creationDate)));
                        return <RenderPRNumber prNumber={po.ticketNumber} requested={validateStringValue(po?.po?.requester?.name)} createdAt={createdAt} poType={po?.po?.type === 'RENEWAL_DUE' ? 'RENEWAL DUE' : validateStringValue(po?.po?.type)} nextRenewalDate={po.nextRenewalDate} />
                    },
                },
            },
            {
                head: {
                    render: (headName) => {
                        return headName
                    },
                    headName: 'PO Number',
                    hide: !isLoggedUserIsEligibleForFeasibility
                },
                body: {
                    render: (asset) => {
                        let po;
                        if (Array.isArray(asset?.poList) && asset.poList.length > 0) {
                            po = asset.poList[0];
                        }
                        if (!po) return <></>;
                        const onPoNumberClick = () => {
                            const url = po?.po?.approvedpdf ? po.po.approvedpdf : po?.po?.quotationpdf;
                            window.open(url, '_blank');
                        };

                        return <RenderPoNumber poNmber={po?.po?.poNumber} createdAt={po.createdAt} approvals={po?.po?.approvals} _id={po?.po?._id} linkType={po?.linkType} vendorId={po?.po?.vendorId} vendorName={po?.po?.vendorName} onPoNumberClick={onPoNumberClick} source='assets' />;
                    },
                },
            },
            {
                head: {
                    render: (headName) => headName,
                    headName: 'PO Status',
                    width: 0.8,
                    hide: !isLoggedUserIsEligibleForFeasibility
                },
                body: {
                    render: (asset) => {
                        let po;
                        if (Array.isArray(asset?.poList) && asset.poList.length > 0) {
                            po = asset.poList[0];
                        }
                        if (!po) return <></>;
                        return <RenderPoStatus poType={po?.po?.type} nextRenewalDate={po.nextRenewalDate} />
                    }
                },
            },
            {
                head: {
                    render: (headName) => {
                        return headName;
                    },
                    headName: 'Scan Details',
                    hide: isLoggedUserIsEligibleForFeasibility,
                },
                body: {
                    render: (item) => {
                        return (
                            <div className='break-all'>
                                {item?.scanner ? (
                                    <IconToolTip title={`Scanned By: ${item?.scanner?.first_name} ${item?.scanner?.last_name}`} placement='bottom'>
                                        <p className='w-full truncate'>{item.scanner ? `${item.scanner.first_name} ${item.scanner.last_name}` : ''}</p>
                                    </IconToolTip>
                                ) : (
                                    <></>
                                )}
                                {item?.scanned_time ? (
                                    <IconToolTip
                                        title={`Time: ${!['', undefined, null].includes(item.scanned_time) && moment(item.scanned_time).isValid()
                                            ? moment(item.scanned_time).format('DD-MM-YYYY')
                                            : ''
                                            }`}
                                        placement='bottom'
                                    >
                                        <p className='w-full truncate'>
                                            {!['', undefined, null].includes(item.scanned_time) && moment(item.scanned_time).isValid()
                                                ? moment(item.scanned_time).format('DD-MM-YYYY')
                                                : ''}
                                        </p>
                                    </IconToolTip>
                                ) : (
                                    <></>
                                )}
                                {item?.scanned_location ? (
                                    <IconToolTip
                                        title={`Location: ${!['', undefined, null].includes(item.scanned_time) && moment(item.scanned_time).isValid()
                                            ? moment(item.scanned_time).format('DD-MM-YYYY')
                                            : ''
                                            }`}
                                        placement='bottom'
                                    >
                                        <p className='w-full truncate'>{item.scanned_location ? item.scanned_location : ''}</p>
                                    </IconToolTip>
                                ) : (
                                    <></>
                                )}
                            </div>
                        );
                    },
                },
            },
            {
                head: {
                    render: (headName) => {
                        return headName
                    },
                    headName: 'Discrepancy',
                    hide: isLoggedUserIsEligibleForFeasibility,
                    columnRowCss: 'break-words',
                },
                body: {
                    render: (item) => {
                        return (
                            <div className='w-full'>
                                {item?.descripencies && (
                                    <div className='text-scogo99 break-words w-full'>
                                        <span className='material-icons text-scogoToastDangerIcon text-font13 px-2'>warning</span>
                                        <span className='text-scogo99 break-words'>{item?.descripencies}</span>
                                    </div>
                                )}
                            </div>
                        );
                    },
                },
            },
            {
                head: {
                    render: (headName) => {
                        return headName;
                    },
                    headName: 'Actions',
                    width: 0.5,
                },
                body: {
                    bodyClassName: 'w-24 max-w-24 ',
                    render: (item) => {
                        return (
                            <div className='flex justify-center'>
                                <AssetActionColumn item={item} siteId={siteId} selectedSubTab={selectedSubTab} />
                            </div>
                        );
                    },
                },
            },
        ],
    };

    return <GridTable
        schema={assetTableSchema}
        rowData={assetsData}
        isLoading={isAssetsLoading}
        tableError={'No PO Found'}
        tablePagination={false}
        rowKey='_id'
        columnsGap='gap-x-6'
        tableBodyHeight={'overflow-y-auto max-h-[25rem]'}
        onTableScroll={onAssetsTableScroll}
        isNextTableDataLoading={isNextAssetsLoading}
        isGroupView={true}
    />
}

export const RenderTableBody = ({ endUserList, handleScroll, siteId, selectedSubTab, selectedSiteId }) => {
    return <div className='shadow-md w-full rounded-lg border-gray-200 bg-white overflow-auto groupViewTableBody' onScroll={handleScroll}>
        {Array.isArray(endUserList) && endUserList.length > 0 ? endUserList.map((enduser) => {
            const { end_user_name, id, assetData } = enduser;
            return <GroupCard end_user_name={end_user_name} id={id} assetData={assetData} siteId={siteId} selectedSubTab={selectedSubTab} selectedSiteId={selectedSiteId} />
        }) : <NoResultFound message={'No Enduser Found'} />}
    </div>
}

export const GroupCard = ({ end_user_name, id, assetData, selectedSubTab, selectedSiteId }) => {
    const [isOpen, setIsOpen] = useState(false);

    return <Accordion>
        <div className='flex justify-between w-full px-2 sticky top-0 bg-white border-1 z-50' onClick={() => {
            setIsOpen(prevState => !prevState);
        }}>
            <AccordionSummary sx={{ padding: 0, width: '100%', height: '5vh' }} >
                <div className='text-[1.3rem] text-scogogray font-medium grid grid-cols-5 items-center w-full' >
                    <span className='text-scogoprimary'>{end_user_name}</span>
                    {assetData?.totalAssets && <span className='text-scogoprimary pl-2'>{assetData.totalAssets}</span>}
                    <span className='text-scogo2e pl-2'>{validateNumberValue(assetData?.status?.AVAILABLE)}</span>
                    <span className='text-scogoBasicYellow pl-6'>{validateNumberValue(assetData?.status?.TRANSIT)}</span>
                    <span className='text-scogoprimary pl-4'>{validateNumberValue(assetData?.status?.DELIVERED)}</span>
                </div>
            </AccordionSummary>
        </div>
        <div className='bg-blue-100'>
            <AccordionDetails sx={{ padding: '0', paddingX: '0.8rem', paddingY: '0.8rem' }}>
                <RenderTable id={id} isOpen={isOpen} selectedSubTab={selectedSubTab} selectedSiteId={selectedSiteId} />
            </AccordionDetails>
        </div>
    </Accordion>;
}

export default function AssetGroupView({ searchQueryParam, setAssetGroupView, selectedSubTab, selectedSiteId }) {
    return <GroupView searchQueryParam={searchQueryParam} setGroupView={setAssetGroupView} source={'assets'} TableHead={RenderTableHead} TableBody={RenderTableBody} selectedSubTab={selectedSubTab} selectedSiteId={selectedSiteId} />
}