import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import Form from '../../Inventory/reusableComponents/AddOption/Form';
import Input from '../../Inventory/reusableComponents/AddOption/Input';
import { getCustomerAddonsListAction } from '../../../actions/customer'
import { updateInventoryAsset } from '../../../actions/inventory'
import { getSuperDeskCapexTypes } from '../../../actions/superdesk'
import moment from "moment";
import { getBOQList } from "../../../actions/tickets";

const CategorizeAsset = ({ modalData, customerId, siteId }) => {

    const formName = 'categoriseAsset';
    let { assetslist } = modalData;
    if (Array.isArray(assetslist)) assetslist = assetslist.filter((asset) => asset.is_categorised === 0);
    const dispatch = useDispatch()
    const initialValues = {};
    const [form, setForm] = useState(initialValues)
    const [categorizedData, setCategorizedData] = useState([]);
    const { boqList } = useSelector((state) => state.tickets);


    const getDataOnMount = () => {
        dispatch(getSuperDeskCapexTypes({ customerId }))
        dispatch(getCustomerAddonsListAction({ customerId, dropdown: true }))
        dispatch(getBOQList());
    }

    useEffect(() => {
        getDataOnMount();
    }, []);



    const capex_types_dropdown = (capexType) => {
        if (Array.isArray(capexType) && capexType.length > 0) {
            const capex = []
            capexType.map(item => {
                capex.push({
                    key: `capex_types_${item.id}`,
                    value: item.id,
                    label: item.name,
                    capex: item
                })
            })
            return capex
        }
    }



    const { capexTypes } = useSelector(state => state.superdesk);
    const { isSuperDeskCapexTypeLoading } = useSelector(state => state.loading);

    let capexTypesDropdown = capex_types_dropdown(capexTypes)


    const submit = (formValues) => {
        let data = {
            categorise: [...categorizedData]
        }
        dispatch(updateInventoryAsset({ data, assetId: 0, siteId: siteId, customerId, formName, enduserId: modalData.enduserId }))
    }

    useEffect(() => {
        Array.isArray(assetslist) && assetslist.length > 0 && assetslist.map((asset, index) => {
            if (asset.is_categorised === 0) {
                setCategorizedData(oldArray => [...oldArray, { assetId: asset.id, capex_date: '', capex_type: '', per_item_value: '', po_amount: '', po_number: '', useful_life: '', asset_type_id: '' }]);
            }
        })
    }, [])

    const updateField = (key, value, index) => {
        if (categorizedData) {
            const perIndexData = categorizedData[index];
            perIndexData[key] = value;
            setCategorizedData([...categorizedData.slice(0, index), perIndexData, ...categorizedData.slice(index + 1, categorizedData.length)]);
        }
    }



    const closeModal = () => { dispatch(closeModalAction()) }

    const actualValue = (elem) => {
        if (!elem) return;
        if (typeof elem === "object") return elem.value;
        else return elem;
    };


    useEffect(() => {
        Object.entries(form).forEach(([key, value]) => {
            let index = parseInt(key.split("_").slice(-1)[0])
            let objkey = key.replace(`_${index}`, '')
            if (objkey === 'capex_type') value = actualValue(value)
            if (objkey === 'capex_date') value = value ? moment(value).format("YYYY-MM-DD") : '';
            updateField(objkey, value, index)
        })
    }, [form]);

    const updateIntialValuePrefillData = (assetslist) => {
        let intialData = {};
        if (Array.isArray(assetslist) && assetslist.length > 0) {
            assetslist.forEach((asset, index) => {
                if (asset.is_categorised === 0) {
                    let capex_date = new Date()
                    if (asset?.capex_date) capex_date = new Date(asset?.capex_date)
                    intialData = {
                        ...intialData,
                        [`asset_type_id_${index}`]: asset.assetType ? asset.assetType.title : '',
                        [`capex_type_${index}`]: asset.capex_type,
                        [`capex_date_${index}`]: capex_date,
                        [`po_number_${index}`]: asset.po_number,
                        [`po_amount_${index}`]: asset.po_amount,
                        [`per_item_value_${index}`]: asset.per_item_value,
                        [`useful_life_${index}`]: asset.useful_life
                    }
                }
            })
        }
        setForm({ ...form, ...intialData })
    }

    useEffect(() => {
        updateIntialValuePrefillData(assetslist);
    }, [])

    const assetListForm = (assetslist) => {
        return Array.isArray(assetslist) && assetslist.length > 0 && assetslist.map((asset, index) => {
            return <>
                <div key={index} className="py-3" >
                    {assetslist.length > 1 && <div className='px-7 flex justify-between items-end font-bold text-scogobgsky text-font12'>Asset {index + 1}</div>}
                    <div className='px-3 flex justify-between items-end'>
                        <Input isEditable={false} label='Asset Type' name={`asset_type_id_${index}`} type={'text'} placeholder={'Asset Type'} className={'w-6/12'} options={boqList} />
                        <Input label='Capex Type' name={`capex_type_${index}`} type={'dropdown'} options={capexTypesDropdown} placeholder={'Capex Type'} className={'w-6/12'} />
                    </div>
                    <div className='px-3 flex justify-between items-end'>
                        <Input label='Capex Date' name={`capex_date_${index}`} type={'singledatepicker'} className={'w-6/12'} />
                        <Input label='Invoice Number' required name={`po_number_${index}`} type={'text'} placeholder={'Invoice Number'} className={'w-6/12'} />
                    </div>
                    <div className='px-3 flex justify-between '>
                        <Input label='PO Amount' required name={`po_amount_${index}`} type={'number'} placeholder={'PO Amount'} className={'w-4/12'} />
                        <Input label='Unit Price' required name={`per_item_value_${index}`} type={'number'} placeholder={'Unit Price'} className={'w-4/12'} />
                        <Input label='Useful Life' required name={`useful_life_${index}`} type={'number'} placeholder={'Useful Life'} className={'w-4/12'} />
                    </div>
                </div>
                <br />
            </>
        })
    }

    return (
        <>

            <Form
                className='w-full '
                submit={(e) => { submit(e) }}
                onCancel={closeModal}
                buttonPostion={'justify-end'}
                initialValues={initialValues}
                form={form}
                setForm={setForm}
                formName={formName}
                submitButtonName='Categorise'
            >

                {assetListForm(categorizedData)}

            </Form>

        </>
    );

}

export default CategorizeAsset;