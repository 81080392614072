import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import DetailTag from '../../../pages/project/DetailTag';
import {
    findSiteById,
    createInventoryAssets,
    searchSiteWithExternalSiteId,
    removeSiteDataByIdFromStore,
    getAllSitesDropdown,
    removeAssetFromStore,
} from '../../../actions/inventory';
import { getBOQList } from '../../../actions/tickets';
import { sendToastMessage } from '../../../actions/toast';
import { textCss } from '../../../common/Css';
import moment from 'moment';
import { getCustomerIdOfLoginUser, isValidEmail } from '../../../utils/utils';
import { actualValueForDropdown, debounce } from '../../../utils/common';
import Form from '../../../common/Form';
import Input from '../../../common/Form/Input';
import Select from '../../../common/Form/Select';
import DatePicker from '../../../common/Form/DatePicker';
import { useCallback } from 'react';
import Checkbox from '../../../common/Form/Checkbox';
import { useFormContext, useForm } from 'react-hook-form';
import { useMemo } from 'react';
import { getSuperDeskCapexTypes } from '../../../actions/superdesk';
import TextArea from '../../../common/Form/TextArea';
import apis from '../../../apis';

const warrantyType = [
    { key: 1, value: 'Manufacturing', label: 'Manufacturing' },
    { key: 2, value: 'Installation', label: 'Installation' },
    { key: 3, value: 'Billing', label: 'Billing' },
];
const maintenanceSchedule = [
    { key: 1, value: 'Monthly', label: 'Monthly' },
    { key: 2, value: 'Quarterly', label: 'Quarterly' },
    { key: 3, value: 'Half Yearly', label: 'Half Yearly' },
    { key: 4, value: 'Yearly', label: 'Yearly' },
];
const addNewAsset = { key: 'add_new_site', value: 'add_new_site', label: 'Add New Site' };
const formName = 'addAsset';

const AddAsset = ({ siteId, showSiteDetails = true, serial_number, customerId, onSuccess = () => { }, clearAssetFromStore = true }) => {
    const dispatch = useDispatch();
    const { siteDataById } = useSelector((state) => state.inventory);
    const { loggedUser } = useSelector((state) => state.inventory);
    const loggedUserCustomerId = getCustomerIdOfLoginUser(loggedUser);
    const { formButtonLoading } = useSelector((state) => state.loading);
    let customer_id = customerId ? customerId : loggedUserCustomerId;

    const defaultValues = {
        type: '',
        additional_spoc_name: '',
        additional_spoc_email: '',
        additional_spoc_phone: '',
        end_user_id: '',
        isOwnerSame: true,
        serial_number: serial_number ? serial_number : '',
        service_support: '',
        backlining: '',
    };



    const getDataOnMount = useCallback(() => {
        dispatch(getBOQList());
        if (siteId) {
            dispatch(findSiteById({ siteId }));
        }
        dispatch(getSuperDeskCapexTypes({ customerId: customer_id }));
        dispatch(getAllSitesDropdown({ customerId: customer_id }));
        return () => dispatch(removeSiteDataByIdFromStore());
    }, [dispatch, siteId, customer_id]);

    useEffect(() => {
        getDataOnMount();
    }, [getDataOnMount]);

    useEffect(() => {
        return () => {
            if (clearAssetFromStore) dispatch(removeAssetFromStore());
        }
    }, [dispatch, clearAssetFromStore]);

    const submit = (form) => {
        const isNewSite = actualValueForDropdown(form.fk_site_id) === addNewAsset.value;
        const spocDetails = {
            name: form.additional_spoc_name,
            email: form.additional_spoc_email,
            phone: form.additional_spoc_phone,
        };
        let asset = {
            additional_spoc: [spocDetails],
            asset_type_id: actualValueForDropdown(form.asset_type_id),
            backlining: form.backlining,
            capex_date: form.capex_date ? moment(form.capex_date).format('YYYY-MM-DD') : '',
            bill_date: form.bill_date ? moment(form.bill_date).format('YYYY-MM-DD') : '',
            capex_type: actualValueForDropdown(form.capex_type),
            end_user_id: actualValueForDropdown(form.end_user_id),
            externel_order_id: form.externel_order_id,
            fk_site_id: actualValueForDropdown(form.fk_site_id),
            hasOem: false,
            hasWarranty: form.hasWarrantyDetails,
            make: form.make,
            model: form.model,
            partcode: form.partcode,
            po_amount: form.po_amount,
            preventive_maintenance_schedule: actualValueForDropdown(form.preventive_maintenance_schedule),
            same_site_owner: form.isOwnerSame,
            serial_number: form.serial_number,
            service_support: form.service_support,
            type: form.type,
            warranty_end_date: form.warranty_end_date ? moment(form.warranty_end_date).format('YYYY-MM-DD') : '',
            warranty_start_date: form.warranty_start_date ? moment(form.warranty_start_date).format('YYYY-MM-DD') : '',
            warranty_type: actualValueForDropdown(form.warranty_type),
            vendor_supplier_name: form.vendor_supplier_name,
            customer_item_name: form.customer_item_name,
            po_number: form.po_number,
        };

        if (isNewSite) {
            asset.external_site_id = form.external_site_id;
            asset.zone = form.zone;
            asset.fk_pincode_id = form.pincode;
            asset.branch_name = form.branch_name;
            asset.region = form.region;
            asset.complete_site_address = form.address;
            asset.fk_site_id = 0;
        }
        let assets = [asset];

        let site_id = form.fk_site_id;
        const hasValidCustomerId = Number.isInteger(form.customerId) && form.customerId > 0;

        if (hasValidCustomerId) {
            dispatch(createInventoryAssets({ customerId: form.customerId, data: { assets }, siteId: site_id, formName, refresh: false, onSuccess }));
        } else dispatch(sendToastMessage({ status: 'danger', message: 'Invalid Data' }));
    };

    const closeModal = () => {
        dispatch(closeModalAction());
    };

    const methods = useForm({ defaultValues, mode: 'all' });

    return (
        <>
            <Form
                className='w-full'
                onSubmit={(e) => {
                    submit(e);
                }}
                methods={methods}
                onCancel={closeModal}
                isLoading={formButtonLoading[formName]}
                showSubmit
                submitContainerClassName='flex gap-2 justify-end py-4 px-4'
            >
                {showSiteDetails && siteId && siteDataById && <SiteDetails siteDataById={siteDataById} />}
                <AssetInputs siteId={siteId} customerId={customer_id} siteDataById={siteDataById} />
            </Form>
        </>
    );
};

export default AddAsset;

const AssetInputs = ({ siteId, customerId, siteDataById }) => {
    const { boqList } = useSelector((state) => state.tickets);
    const { capexTypes } = useSelector((state) => state.superdesk);
    const { sitesDropdown } = useSelector((state) => state.inventory);
    const { isAllSiteDropdownLoading } = useSelector((state) => state.loading);
    const dispatch = useDispatch();
    const { setValue, watch } = useFormContext();

    const sitesOptions = useMemo(() => {
        let dropdown = [addNewAsset];
        if (Array.isArray(sitesDropdown)) {
            dropdown = [...dropdown, ...sitesDropdown];
        }
        return dropdown;
    }, [sitesDropdown]);

    useEffect(() => {
        if (siteId) setValue('fk_site_id', siteId);
        if (siteDataById) {
            setValue('additional_spoc_name', siteDataById.site_contact_person_name);
            setValue('additional_spoc_phone', siteDataById.site_contact_person_mobile);
            setValue('additional_spoc_email', siteDataById.site_contact_person_email);
            setValue('end_user_id', siteDataById.customer_end_user);
            setValue('customerId', siteDataById.customer?.customer_id);
            setValue('pincode', siteDataById.fk_pincode_id);
            setValue('address', siteDataById.complete_site_address);
        }
        if (customerId) setValue('customerId', customerId);
    }, [siteDataById, siteId, setValue, customerId]);

    const capexTypesOptios = useMemo(() => {
        return capexTypes?.map((item) => {
            return {
                key: `capex_types_${item.id}`,
                value: item.id,
                label: item.name,
                capex: item,
            };
        });
    }, [capexTypes]);

    const isOwnerSame = watch('isOwnerSame');
    const hasFinancialDetails = watch('hasFinancialDetails');
    const hasWarrantyDetails = watch('hasWarrantyDetails');
    const fk_site_id = watch('fk_site_id');
    const isAddNewSite = actualValueForDropdown(fk_site_id) === addNewAsset.value;

    const searchSiteDropdown = (query, siteList) => {
        if (!query) return;
        let find = siteList.filter((site) => site.label.toLowerCase().includes(query));
        if (find.length === 0) dispatch(searchSiteWithExternalSiteId({ customerId, externalSiteId: query }));
    };

    const debouncedSiteSearchCall = useCallback(debounce(searchSiteDropdown), []);

    useEffect(() => {
        if (fk_site_id) {
            setValue('pincode', fk_site_id.fk_pincode_id);
            setValue('address', fk_site_id.complete_site_address);
        } else {
            setValue('pincode', '');
            setValue('address', '');
        }
    }, [fk_site_id, setValue]);

    return (
        <>
            <div className='px-3'>
                <div className={`flex gap-2`}>
                    {!siteId && (
                        <Select
                            label='Site'
                            required
                            name='fk_site_id'
                            type={'dropdown'}
                            options={sitesOptions}
                            className={'w-3/12'}
                            isLoading={isAllSiteDropdownLoading}
                            onInputChange={(value) => debouncedSiteSearchCall(value, sitesDropdown)}
                            shouldUnregister
                        />
                    )}

                    {fk_site_id && (
                        <>
                            {isAddNewSite && <Input label='External Site Id' name='external_site_id' type={'text'} className={'w-3/12'} shouldUnregister required />}
                            <Input className={isAddNewSite ? 'w-3/12' : 'w-4/12'} name='pincode' validate={{
                                pincodeNotInSystem: async (value) => {
                                    if (value === '') return true;
                                    const response = await apis.cities.getCityFromPincode({}, {}, {}, { pincode: value });
                                    return response.data?.code === 200 || "Pincode does't exist in the System.";
                                }
                            }} label='Pincode' pattern={/^\d{6}$/} readOnly={!isAddNewSite} required={isAddNewSite} />
                            <TextArea className={isAddNewSite ? 'w-3/12' : 'w-4/12'} name='address' label='Address' readOnly={!isAddNewSite} required={isAddNewSite} />
                        </>
                    )}
                </div>
                {isAddNewSite && (
                    <div className='flex'>
                        <Input label='Branch Name' name='branch_name' type={'text'} className={'w-full'} shouldUnregister />
                        <Input label='Zone' name='zone' type={'text'} className={'w-full'} shouldUnregister />
                        <Input label='Region' name='region' type={'text'} className={'w-full'} shouldUnregister />
                    </div>
                )}
                <div className='flex justify-between'>
                    <Select label='Asset Type' required name='asset_type_id' type={'dropdown'} options={boqList} className={'w-full'} />
                    <Input label='Serial Number' required name='serial_number' type={'text'} className={'w-full'} />
                    <Input label='Partcode' name='partcode' type={'text'} className={'w-full'} />
                </div>

                <div className='flex justify-between'>
                    <Input label='Make' name='make' type={'text'} className={'w-full'} />
                    <Input label='Model' name='model' type={'text'} className={'w-full'} />
                    <Input label='Alias' name='customer_item_name' type={'text'} className={'w-full'} />
                    <Input label='External Order Id' name='externel_order_id' type={'text'} className={'w-full'} />
                </div>
            </div>

            <div className='px-3'>
                <Checkbox label='Owner same as Site' name='isOwnerSame' id='isOwnerSame' />
            </div>
            {!isOwnerSame && (
                <>
                    <div className='px-3 flex justify-between'>
                        <Input label='Owner Name' type={'text'} name='additional_spoc_name' className={'w-4/12'} shouldUnregister />
                        <Input
                            label='Owner Number'
                            type={'number'}
                            name='additional_spoc_phone'
                            className={'w-4/12'}
                            validate={(mobile) => {
                                if (mobile === '') return true;
                                return mobile.length === 10 || 'Invalid Mobile';
                            }}
                            shouldUnregister
                        />
                        <Input
                            label='Owner Email'
                            type={'text'}
                            name='additional_spoc_email'
                            customValidation={isValidEmail}
                            errorMessage={'Invalid Email'}
                            className={'w-4/12'}
                            validate={(email) => {
                                if (email === '') return true;
                                return (
                                    email.match(
                                        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                    ) || 'Invalid Email'
                                );
                            }}
                            shouldUnregister
                        />
                    </div>
                </>
            )}

            <div className='px-3'>
                <Checkbox label='Do you have Finance Details?' name='hasFinancialDetails' id='hasFinancialDetails' />
            </div>

            {hasFinancialDetails && (
                <>
                    <div className='px-3 flex justify-between items-end'>
                        <Select label='Capex Type' name='capex_type' options={capexTypesOptios} className={'w-6/12'} shouldUnregister />
                        <DatePicker label='Capex Date' name='capex_date' className={'w-4/12'} shouldUnregister />
                        <DatePicker label='Bill Date' name='bill_date' className={'w-4/12'} shouldUnregister />
                    </div>
                    <div className='px-3 flex justify-between'>
                        <Input label='Invoice Number' name='po_number' type={'text'} className={'w-4/12'} shouldUnregister />
                        <Input label='PO Amount' name='po_amount' type={'number'} className={'w-4/12'} shouldUnregister step={'any'} />
                        <Input label='Unit Value' type={'text'} name='per_item_value' className={'w-4/12'} shouldUnregister />
                        <Input label='Supplier Name' type={'text'} name='vendor_supplier_name' className={'w-4/12'} shouldUnregister />
                    </div>
                </>
            )}

            <div className='px-3'>
                <Checkbox label='Do you have Warranty Details?' name='hasWarrantyDetails' id='hasWarrantyDetails' />
            </div>
            {hasWarrantyDetails && (
                <>
                    <div className='px-3 flex items-end'>
                        <Select label='Type' name='warranty_type' options={warrantyType} placeholder={'Select Type'} className='w-4/12' shouldUnregister />
                        <DatePicker label='Warranty Start Date' name='warranty_start_date' className={'w-4/12'} shouldUnregister />
                        <DatePicker label='Warranty End Date' name='warranty_end_date' className={'w-4/12'} shouldUnregister />
                        <Select label='Preventive Maintenance Schedule' name='preventive_maintenance_schedule' options={maintenanceSchedule} className='w-4/12' shouldUnregister />
                    </div>
                </>
            )}
        </>
    );
};

const SiteDetails = ({ siteDataById }) => {
    return (
        <>
            <>
                <div className='px-6 mt-2'>
                    <p className={textCss?.formHighlights}>Site Details</p>
                </div>
                <div className='md:flex pl-5 pt-2'>
                    <DetailTag
                        tagHeadClass={textCss?.detailsTitle}
                        tagValueClass={textCss?.detailsValue}
                        tagClass=' px-2 md:w-4/12'
                        tagName='Site Id'
                        tagValue={siteDataById?.external_site_id}
                    />
                    <div className='px-2 md:w-4/12'>
                        <DetailTag
                            tagHeadClass={textCss?.detailsTitle}
                            tagValueClass={textCss?.detailsValue + ' line-clamp-3'}
                            tagClass=''
                            tagName='Address'
                            tagValue={siteDataById?.complete_site_address}
                        />
                        {siteDataById?.fk_pincode_id ? <p className={textCss.detailsValue}>,{siteDataById?.fk_pincode_id || ''}</p> : <></>}
                    </div>
                    <DetailTag
                        tagHeadClass={textCss?.detailsTitle}
                        tagValueClass={textCss?.detailsValue}
                        tagClass='px-2 md:w-4/12'
                        tagName='City & State'
                        tagValue={`${siteDataById?.state?.state_name ? siteDataById?.state?.state_name : ''},${siteDataById?.city?.city_name ? siteDataById?.city?.city_name : ''}`}
                    />
                </div>

                <div className='md:flex pl-5 pt-6'>
                    <DetailTag
                        tagHeadClass={textCss?.detailsTitle}
                        tagValueClass={textCss?.detailsValue}
                        tagClass='px-2 md:w-4/12 '
                        tagName='Contact Name'
                        tagValue={siteDataById?.site_contact_person_name ? siteDataById?.site_contact_person_name : ''}
                    />
                    <DetailTag
                        tagHeadClass={textCss?.detailsTitle}
                        tagValueClass={textCss?.detailsValue}
                        tagClass='px-2 md:w-4/12'
                        tagName='Contact Email'
                        tagValue={siteDataById?.site_contact_person_email ? siteDataById?.site_contact_person_email : ''}
                    />
                    <DetailTag
                        tagHeadClass={textCss?.detailsTitle}
                        tagValueClass={textCss?.detailsValue}
                        tagClass='px-2 md:w-4/12 '
                        tagName='Contact Mobile'
                        tagValue={siteDataById?.site_contact_person_mobile ? siteDataById?.site_contact_person_mobile : ''}
                    />
                </div>
            </>

            <div className='px-6 mt-4'>
                <p className={textCss?.formHighlights}>Add Assets</p>
            </div>
        </>
    );
};
