import React from 'react';
import ReadMoreText from '../../../../common/ReadMoreText';
import { EditMessage } from './EditMessage';
import AttachmentMessage from './AttachmentMessage';

const getFileIcon = (type) => {
    const lookup = {
        image: 'image',
        audio: 'volume_up',
        video: 'videocam',
        pdf: 'picture_as_pdf',
        others: 'source',
    };
    return <span className='material-icons object-contain h-6 w-6 opacity-50 z-10'>{lookup[type]}</span>;
};

const getComponentByType = ({ type, url }) => {
    const lookup = {
        image: <img src={url} className='rounded object-cover h-12 w-12' alt='' />,
        audio: <img src={process.env.PUBLIC_URL + '/img/audio.png'} className='rounded object-cover m-auto h-12 w-12' alt='' />,
        video: <video className='rounded object-cover m-auto h-12 w-12' src={url} />,
        pdf: <img src={process.env.PUBLIC_URL + '/img/pdf.png'} className='rounded object-contain m-auto h-12 w-12' alt='' />,
        others: <img src={process.env.PUBLIC_URL + '/img/unknownFileType.png'} className='rounded object-contain m-auto h-12 w-12' alt='' />,
    };
    return lookup[type];
};

function ReplyMessage({ message, repliedToBG, formatText, isEditMessage, setIsEditMessage, chatUsers, onClickReplyMessage, callStepFields, isTicketChat }) {
    const getMessageText = () => {
        if (isEditMessage) {
            return (
                <EditMessage
                    value={message.message}
                    isEditLoading={message.isEditLoading}
                    conversationId={message.conversation._id}
                    messageId={message._id}
                    isEditMessageError={message.isEditMessageError}
                    tags={message.tags}
                    chatUsers={chatUsers}
                    setIsEditMessage={setIsEditMessage}
                />
            );
        }
        return <ReadMoreText text={formatText({ text: message.message, tags: message.tags })} numberOfLine={6} className='text-scogo15 text-font13 pr-6 pl-2 font-normal' />;
    };
    const replyMessage = () => {
        let replyMessage;
        let attachments = message?.repliedTo?.attachments;
        if (attachments?.length > 0) {
            const isSingle = attachments.length === 1;
            const attachmentsComponent = getComponentByType({ type: attachments[0].type, url: attachments[0].fileUrl });

            replyMessage = (
                <>
                    <div className='h-12 w-12 object-cover relative flex gap-2 truncate'>
                        {attachmentsComponent}
                        {!isSingle && (
                            <div className='h-6 w-6 shadow-xl rounded-full bg-black text-white absolute bottom-0 right-0 flex justify-center items-center text-xs opacity-70'>
                                + {attachments.length - 1}
                            </div>
                        )}
                    </div>
                    <div className='absolute bottom-0 left-12'>{getFileIcon(message?.repliedTo?.attachments[0]?.type)}</div>
                </>
            );
        } else {
            replyMessage = <p className='text-scogo15 text-font12 relative w-full truncate'>{message?.repliedTo?.message}</p>;
        }
        return replyMessage;
    };
    const getAttachments = () => {
        let attachments = message?.attachments;
        if (attachments?.length > 0) {
            return <AttachmentMessage message={message} formatText={formatText} callStepFields={callStepFields} isTicketChat={isTicketChat} />
        }
        return;

    }

    return (
        <div className='px-2 rounded-t-lg rounded-r-lg max-w-chatblue relative'>
            <div
                className={`relative rounded-lg shadow-sm p-4 pt-0 cursor-pointer ${repliedToBG}`}
                onClick={() => {
                    onClickReplyMessage(message.repliedTo?._id);
                }}
                Í
            >
                <p className='text-scogoorange truncate text-font12 italic font-semibold mt-2 mr-2'>{message?.repliedTo?.from?.username}</p>
                {replyMessage()}
            </div>
            {getAttachments()}
            <div className=' max-w-max'>{getMessageText()}</div>
        </div>
    );
}

export default ReplyMessage;
