import { fork } from 'redux-saga/effects';

import authSaga from './auth';
import customerSaga from './customer';
import websocketSaga from './websocket';
import conversationsSaga from './conversations';
import paymentSaga from './payments';
import walletSaga from './wallet';
import inventorySaga from './inventory';
import ticketsSaga from './ticket';
import projectSaga from './project';
import workflowSaga from './workflow';
import utilsSaga from './utils';
import userSaga from './users';
import superdeskSaga from './superdesk';
import partnersSaga from './partners';
import locationsSaga from './locations';
import feSaga from './fe';
import pmscmSaga from './pmscm';
import ticketAssignmentSaga from './assignment';
import spSaga from './sp';
import onboardingSaga from './onboarding';
import adminSaga from './admin';
import fmSaga from './fm';
import whatsappSaga from './whatsapp.js';

export default function* indexSaga() {
    //yield fork(testSaga)
    yield fork(authSaga);
    yield fork(customerSaga);
    yield fork(websocketSaga);
    yield fork(conversationsSaga);
    yield fork(paymentSaga);
    yield fork(walletSaga);
    yield fork(inventorySaga);
    yield fork(ticketsSaga);
    yield fork(projectSaga);
    yield fork(workflowSaga);
    yield fork(utilsSaga);
    yield fork(userSaga);
    yield fork(superdeskSaga);
    yield fork(partnersSaga);
    yield fork(locationsSaga);
    yield fork(feSaga);
    yield fork(pmscmSaga);
    yield fork(ticketAssignmentSaga);
    yield fork(spSaga);
    yield fork(onboardingSaga);
    yield fork(adminSaga);
    yield fork(fmSaga);
    yield fork(whatsappSaga);
}
