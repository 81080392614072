import React from "react";
import { Toaster, resolveValue } from 'react-hot-toast';

export default function Toast() {
    
    const toastTypes = {
        danger: {
            icon: 'close',
            iconColor: 'text-scogoToastDangerIcon',
            textColr: 'text-scogoToastDanger',
            toastBackGroundColor: 'bg-scogoToastDanger'
        },
        success: {
            icon: 'done',
            iconColor: 'text-scogoToastSuccessIcon',
            textColr: 'text-scogoToastSuccess',
            toastBackGroundColor: 'bg-scogoToastSuccess'
        },
        warning: {
            icon: 'warning',
            iconColor: 'text-scogoToastWarningIcon',
            textColr: 'text-scogoToastWarning',
            toastBackGroundColor: 'bg-scogoToastWarning'
        }
    }


    return <Toaster>
        {(toast) => resolveValue(
            <div
                className={`${toast.visible ? 'animate-enter' : 'animate-leave'
                    }  ${toastTypes[`${toast.className}`].toastBackGroundColor}  w-fit shadow-lg rounded-lg pointer-events-auto flex justify-between ring-1 ring-black ring-opacity-5 `}
            >
                <div className="flex-1 w-58 p-3">
                    <div className="flex items-center">
                        <div className="flex items-center">
                            <span className={`material-icons ${toastTypes[`${toast.className}`].iconColor}  cursor-pointer `}>{toastTypes[`${toast.className}`].icon}</span>
                        </div>
                        <div className="ml-2 ">
                            <p className={`text-xl ${toastTypes[`${toast.className}`].textColr} font-semibold`}>
                                {toast.message}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )}
    </Toaster>


}

