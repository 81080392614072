import { conversations, fe, partners } from '../types';
import { CLUSTER, FE, ISP, SCM, SP } from '../utils/role';

export const getGroupChats = ({ limit, offset, query }) => ({
    type: conversations.GET_GROUP_CONVERSATIONS_REQUEST,
    payload: { limit, offset, query },
});

export const getNextGroupChats = ({ limit, offset }) => ({
    type: conversations.GET_NEXT_GROUP_CONVERSATIONS_REQUEST,
    payload: { limit, offset },
});

export const getHelpChats = ({ limit, offset, query, isActive }) => ({
    type: conversations.GET_HELP_CONVERSATIONS_REQUEST,
    payload: { limit, offset, query, isActive },
});

export const getTicketChats = ({ limit, offset, query, tags, toAppend, atOnly }) => ({
    type: conversations.GET_TICKET_CONVERSATIONS_REQUEST,
    payload: { limit, offset, query, tags, toAppend, atOnly },
});

export const getNextHelpChats = ({ limit, offset, isActive }) => ({
    type: conversations.GET_NEXT_HELP_CONVERSATIONS_REQUEST,
    payload: { limit, offset, isActive },
});

export const createSupport = ({ formName, onSuccess }) => ({
    type: conversations.CREATE_HELP_SUPPORT_REQUEST,
    payload: { formName, onSuccess },
});

export const closeHelpSupport = (_id, formName) => ({
    type: conversations.CLOSE_CONVERSATION_REQUEST,
    payload: { _id, formName },
});

export const getConversationDetails = ({ _id, isScogoConnect, useConversationId }) => ({
    type: conversations.GET_CONVERSTAION_DETAILS_REQUEST,
    payload: { _id, isScogoConnect, useConversationId },
});

export const createGroupChat = ({ data, formName, imageFile, onSuccess }) => ({
    type: conversations.CREATE_GROUP_CONVERSATION_REQUEST,
    payload: { data, formName, imageFile, onSuccess },
});

export const getConversationMessages = ({ _id, offset, limit, openedConversationMessageIsLatest }) => ({
    type: conversations.GET_CONVERSTAION_MESSAGES_REQUEST,
    payload: { _id, openedConversationMessageIsLatest, offset, limit },
});

export const getSearchConversationMessages = ({ _id, limit, offset, query, count }) => ({
    type: conversations.GET_SEARCH_CONVERSTAION_MESSAGES_REQUEST,
    payload: { _id, query, limit, offset, count },
});


export const setopenedConversationMessageIsLatest = ({ isLatest, _id }) => ({
    type: conversations.SET_OPEN_CONVERSTAION_MESSAGES_ISLATEST,
    payload: { isLatest, _id },
});

export const getNextConversationMessages = ({ _id, messageId, when }) => ({
    type: conversations.GET_NEXT_CONVERSTAION_MESSAGES_REQUEST,
    payload: { _id, messageId, when },
});

export const getReplyConversationMessages = ({ _id, messageId }) => ({
    type: conversations.GET_REPLY_CONVERSTAION_MESSAGES_REQUEST,
    payload: { _id, messageId },
});

export const changeNotificationSettings = ({ _id, sendNotificationsFor }) => ({
    type: conversations.CHANGE_NOTIFICATION_SETTINGS_REQUEST,
    payload: { _id, sendNotificationsFor },
});

export const putToConversationStore = (payload) => ({
    type: conversations.PUT_TO_CONVERSATIONS_STORE,
    payload,
});

export const getConversationCount = ({ type }) => ({
    type: conversations.GET_CONVERSATION_COUNT_REQUEST,
    payload: { type },
});

export const uploadFilesToChat = ({ _id, fileData, from, messageId, isSupportTicket, isReplying, replyToMessage, imp_image, callParser, customer_id, auth_token, source, ticket_id, source_id, user_id }) => ({
    type: conversations.CHAT_UPLOAD_FILES_REQUEST,
    payload: { _id, fileData, from, messageId, isSupportTicket, isReplying, replyToMessage, imp_image, callParser, customer_id, auth_token, source, ticket_id, source_id, user_id },
});


export const leaveConversation = ({ _id, data, formName }) => ({
    type: conversations.LEAVE_CONVERSATION_REQUEST,
    payload: { _id, data, formName },
});

export const openChatInNewTab = ({ conversationId, buttonName }) => ({
    type: conversations.OPEN_CHAT_IN_NEW_TAB,
    payload: { conversationId, buttonName },
});

export const getConversationMedia = ({ conversationId, category, limit, offset }) => ({
    type: conversations.GET_CONVERSATION_MEDIA_REQUEST,
    payload: { conversationId, category, limit, offset },
});

export const getNextConversationMedia = ({ conversationId, limit, offset, category }) => ({
    type: conversations.GET_NEXT_CONVERSATION_MEDIA_REQUEST,
    payload: { conversationId, limit, offset, category },
});

export const tagProject = ({ conversationId, projectId, formName }) => ({
    type: conversations.TAG_PROJECT_REQUEST,
    payload: { conversationId, projectId, formName },
});

export const untagProject = ({ conversationId, projectId, formName }) => ({
    type: conversations.UNTAG_PROJECT_REQUEST,
    payload: { conversationId, projectId, formName },
});

export const updateConversationDetails = ({ conversationId, form, isImage }) => ({
    type: conversations.UPDATE_CONVERSATION_DETAILS_REQUEST,
    payload: { conversationId, form, isImage },
});

export const addMemberInConversation = ({ conversationId, userId, formName }) => ({
    type: conversations.ADD_MEMBER_IN_CONVERSATION_REQUEST,
    payload: { conversationId, userId, formName },
});

export const removeFromConversation = ({ conversationId, userId, teamId, formName }) => ({
    type: conversations.REMOVE_FROM_CONVERSATION_REQUEST,
    payload: { conversationId, userId, teamId, formName },
});

export const addToMessageQueue = ({ conversationId, message }) => ({
    type: conversations.ADD_TO_MESSAGE_QUEUE,
    payload: { conversationId, message },
});

export const grantOrRevokeAdminAccess = ({ conversationId, userId, access, formName, username, groupName }) => ({
    type: conversations.GRANT_OR_REVOKE_ACCESS_REQUEST,
    payload: { conversationId, userId, access, formName, username, groupName },
});

export const getConversationInfo = ({ _id, queryParms }) => ({
    type: conversations.GET_CONVERSATION_INFO_REQUEST,
    payload: { _id, queryParms },
});

export const createSingleChat = ({ userId }) => ({
    type: conversations.CREATE_SINGLE_CHAT_REQUEST,
    payload: { userId },
});

export const openSingleChat = ({ userId, chatId, openInNewTab, navigate, hideList, formName }) => ({
    type: conversations.OPEN_SINGLE_CHAT_REQUEST,
    payload: { userId, chatId, openInNewTab, navigate, hideList, formName },
});

export const getSingleChatUserInfoDetails = ({ userId }) => ({
    type: conversations.GET_SINGLE_CHAT_USER_INFO_DETAILS_REQUEST,
    payload: { userId },
});

export const getSingleChatCustomerUserDetails = ({ userId }) => ({
    type: conversations.GET_SINGLE_CHAT_CUSTOMER_DETAILS_REQUEST,
    payload: { userId },
});

export const getSingleChatSPProfileDetails = ({ userId }) => ({
    type: conversations.GET_SINGLE_CHAT_SP_USER_DETAILS_REQUEST,
    payload: { userId },
});



export const getDevelopersList = () => ({
    type: partners.GET_DEVELOPERS_LIST_REQUEST
})


export const getFEListWithSpId = (servicePartnerId) => ({
    type: fe.FIELD_ENGINEERS_LIST_REQUEST,
    payload: { servicePartnerId },
});


export const getCSPist = () => ({
    type: partners.PARTNERS_LIST_REQUEST,
    payload: { role: 13, customer: 'other' },
});

export const getIwanList = () => ({
    type: partners.PARTNERS_LIST_REQUEST,
    payload: { role: 7, customer: 'scogo', userType: SP.type, fk_cluster_id: true },
});

export const getSingleChatFEProfileDetails = ({ fieldEngineerId }) => ({
    type: conversations.GET_SINGLE_CHAT_FE_USER_DETAILS_REQUEST,
    payload: { fieldEngineerId },
});

export const addMembersToChat = ({ userIds, _id, onSuccess }) => ({
    type: conversations.ADD_MEMBERS_OR_TEAMS_TO_CHAT_REQUEST,
    payload: { userIds, _id, onSuccess },
});

export const setConversationUnreadableMessageCount = () => ({
    type: conversations.SET_CONVERSATION_UNREADABLE_MESSAGE_COUNT,
});
export const addTeamsToChat = ({ teamIds, _id, onSuccess, formName }) => ({
    type: conversations.ADD_MEMBERS_OR_TEAMS_TO_CHAT_REQUEST,
    payload: { teamIds, _id, onSuccess, formName },
});

export const approveImageAndIncludeInReport = ({ conversationId, messageId, attachmentId, callStepFields, }) => ({
    type: conversations.APPROVE_OR_REJECT_IMAGE_REQUEST,
    payload: { _id: conversationId, messageId, attachmentId, isApproved: true, toInclude: true, callStepFields, },
});

export const rejectImage = ({ conversationId, messageId, attachmentId, callStepFields }) => ({
    type: conversations.APPROVE_OR_REJECT_IMAGE_REQUEST,
    payload: { _id: conversationId, messageId, attachmentId, isApproved: false, callStepFields },
});

export const approveOrRejectCardMessageAction = ({ conversationId, messageId, isApproved }) => ({
    type: conversations.APPROVE_OR_REJECT_CARD_MESSAGE_REQUEST,
    payload: { _id: conversationId, messageId, isApproved },
});

export const getMediaForSidebarMedia = ({ conversationId, limit, offset }) => ({
    type: conversations.LIST_CONVERSATION_SIDEBAR_MEDIA_REQUEST,
    payload: { conversationId, limit, offset },
});

export const getChatNotifications = ({ onlyUnread, offset, toAppend } = {}) => ({
    type: conversations.GET_CHAT_NOTIFICATIONS_REQUEST,
    payload: { onlyUnread, offset, toAppend },
});

export const markAllNotificationsRead = ({ onSuccess }) => ({
    type: conversations.MARK_NOTIFICATIONS_READ_REQUEST,
    payload: { notificationId: 'all', onSuccess },
});

export const markNotificationRead = ({ notificationId, onSuccess }) => ({
    type: conversations.MARK_NOTIFICATIONS_READ_REQUEST,
    payload: { notificationId, onSuccess },
});

export const setSideBarMediaVisibleStatus = (status) => ({
    type: conversations.SET_SIDEBAR_MEDIA_VISIBLE_STATUS,
    payload: status,
});

export const getCustomerReportMedia = ({ conversationId, limit, offset }) => ({
    type: conversations.LIST_TICKET_CUSTOMER_REPORT_MEDIA_REQUEST,
    payload: { conversationId, limit, offset },
});

export const removeCustomerReportMediaFromStore = () => ({
    type: conversations.REMOVE_CUSTOMER_REPORT_MEDIA_FROM_STORE,
});

export const downloadCustomerReport = ({ ticketId, attachmentIds, isTicketDetails, formName }) => ({
    type: conversations.DOWNLOAD_CUSTOMER_REPORT_REQUEST,
    payload: { ticketId, attachmentIds, isTicketDetails, formName },
});

export const updateunReadMessage = ({ unreadCount, _id }) => ({
    type: conversations.UPDATE_UN_READ_MESSAGE_COUNT,
    payload: { unreadCount, _id },
});

export const getGptConversations = ({ limit, offset, query, toAppend }) => ({
    type: conversations.GET_GPT_CONVERSATIONS_REQUEST,
    payload: { limit, offset, query, toAppend },
});

export const notifyEndCustomer = ({ template, mobile, customerId, formName, ticketId, userId }) => ({
    type: conversations.NOTIFY_END_CUSTOMER_REQUEST,
    payload: { template, mobile, customerId, formName, ticketId, userId },
});


export const sendScogoGptMessage = ({ conversationId, userInput }) => {
    return {
        type: conversations.SEND_SCOGO_GPT_MESSAGE_REQUEST,
        payload: { conversationId, userInput }
    }
}

export const uploadOnChange = ({ _id, fileData, messageId, useConversationId = false, bot = false, customer_id,
    auth_token,
    source,
    ticket_id,
    source_id, user_id }) => ({
        type: conversations.UPLOAD_ONCHANGE_REQUEST,
        payload: {
            _id, fileData, messageId, useConversationId, bot, customer_id,
            auth_token,
            source,
            ticket_id,
            source_id, user_id
        }
    })


export const openChatElement = ({ conversationId, buttonName, ticket, source, userId, isRightClick }) => ({
    type: conversations.OPEN_CHAT_ELEMENT,
    payload: { conversationId, buttonName, ticket, source, userId, isRightClick },
});

export const getConversationIdandConversationDetailsAction = ({ ticketId, buttonName, onSuccess }) => {
    return {
        type: conversations.GET_CONVERSATIONID_AND_CONVERSATION_DETAILS,
        payload: { ticketId, buttonName, onSuccess }
    }
}

export const openActiveCallAction = ({ ticketId, room_id, singleConvoId, buttonName }) => {
    return {
        type: conversations.OPEN_ACTIVE_CALL,
        payload: { ticketId, room_id, singleConvoId, buttonName },
    }
}

export const joinRoomAction = ({ userId, room_id, ticketId, openedConversationId, singleConvoId, onSuccess }) => {
    return {
        type: conversations.JOIN_ROOM,
        payload: { userId, room_id, ticketId, openedConversationId, singleConvoId, onSuccess }
    }
}

export const leaveRoomAction = ({ userId, room_id, ticketId, openedConversationId, onSuccess }) => {
    return {
        type: conversations.LEAVE_ROOM,
        payload: { userId, room_id, ticketId, openedConversationId, onSuccess }
    }
}

export const holdRoomAction = ({ userId, room_id, ticketId, openedConversationId, singleConvoId, onSuccess, buttonName }) => {
    return {
        type: conversations.HOLD_ROOM,
        payload: { userId, room_id, ticketId, openedConversationId, singleConvoId, onSuccess, buttonName }
    }
}

export const declineRoomAction = ({ userId, room_id, ticketId, openedConversationId, onSuccess }) => {
    return {
        type: conversations.DECLINE_ROOM,
        payload: { userId, room_id, ticketId, openedConversationId, onSuccess }
    }
}

export const clearMissCallAction = ({ userId, room_id, onSuccess, buttonName }) => {
    return {
        type: conversations.CLEAR_ROOM,
        payload: { userId, room_id, onSuccess, buttonName }
    }

}

export const getAttachmentWithPartialUrl = ({ partialFileUrl, conversationId }) => {
    return {
        type: conversations.GET_ATTACHMENT_WITH_PARTIAL_URL_REQUEST,
        payload: { partialFileUrl, conversationId }
    }
}
