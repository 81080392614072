export const GET_SUPERDESK_CAPEX_TYPES_REQUEST = 'GET_SUPERDESK_CAPEX_TYPES_REQUEST';
export const GET_SUPERDESK_CAPEX_TYPES_SUCCESS = 'GET_SUPERDESK_CAPEX_TYPES_SUCCESS';
export const GET_SUPERDESK_CAPEX_TYPES_FAILURE = 'GET_SUPERDESK_CAPEX_TYPES_FAILURE';

export const ADD_TEAM_REQUEST = 'ADD_TEAM_REQUEST';
export const ADD_TEAM_SUCCESS = 'ADD_TEAM_SUCCESS';
export const ADD_TEAM_FAILURE = 'ADD_TEAM_FAILURE';

export const DELETE_TEAM_REQUEST = 'DELETE_TEAM_REQUEST';
export const DELETE_TEAM_SUCCESS = 'DELETE_TEAM_SUCCESS';
export const DELETE_TEAM_FAILURE = 'DELETE_TEAM_FAILURE';

export const ADD_TEAM_AGENT_REQUEST = 'ADD_TEAM_AGENT_REQUEST';
export const ADD_TEAM_AGENT_SUCCESS = 'ADD_TEAM_AGENT_SUCCESS';
export const ADD_TEAM_AGENT_FAILURE = 'ADD_TEAM_AGENT_FAILURE';

export const ADD_USERS_TEAM_REQUEST = 'ADD_USERS_TEAM_REQUEST';
export const ADD_USERS_TEAM_SUCCESS = 'ADD_USERS_TEAM_SUCCESS';
export const ADD_USERS_TEAM_FAILURE = 'ADD_USERS_TEAM_FAILURE';

export const TRANSFER_TEAMS_AGENT_REQUEST = 'TRANSFER_TEAMS_AGENT_REQUEST';
export const TRANSFER_TEAMS_AGENT_SUCCESS = 'TRANSFER_TEAMS_AGENT_SUCCESS';
export const TRANSFER_TEAMS_AGENT_FAILURE = 'TRANSFER_TEAMS_AGENT_FAILURE';

export const REMOVE_TEAM_AGENT_REQUEST = 'REMOVE_TEAM_AGENT_REQUEST';
export const REMOVE_TEAM_AGENT_SUCCESS = 'REMOVE_TEAM_AGENT_SUCCESS';
export const REMOVE_TEAM_AGENT_FAILURE = 'REMOVE_TEAM_AGENT_FAILURE';

export const UPDATE_USERS_PROPERTY_REQUEST = 'UPDATE_USERS_PROPERTY_REQUEST';
export const UPDATE_USERS_PROPERTY_SUCCESS = 'UPDATE_USERS_PROPERTY_SUCCESS';
export const UPDATE_USERS_PROPERTY_FAILURE = 'UPDATE_USERS_PROPERTY_FAILURE';

export const GET_TEAMS_BY_ID_REQUEST = 'GET_TEAMS_BY_ID_REQUEST';
export const GET_TEAMS_BY_ID_SUCCESS = 'GET_TEAMS_BY_ID_SUCCESS';
export const GET_TEAMS_BY_ID_FAILURE = 'GET_TEAMS_BY_ID_FAILURE';

export const EDIT_TEAM_REQUEST = 'EDIT_TEAM_REQUEST';
export const EDIT_TEAM_FAILURE = 'EDIT_TEAM_FAILURE';

export const UPDATE_USERS_INSTORE_PROPERTY = 'UPDATE_USERS_INSTORE_PROPERTY';
