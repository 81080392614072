import React from 'react';
import { FileDownloadIcon } from './icons';
import ApprovedRejectIcon from '../../../components/ApprovedRejectIcon';

const pxToRem = (pixels) => {
    if (parseInt(pixels)) {
        return pixels / parseInt(getComputedStyle(document.documentElement).fontSize)
    }
    return 0
};

export const getImageStyle = (height) => {
    let remValue = pxToRem(height)
    if (remValue >= 48) {
        return '48rem'
    } else remValue = 48;
    return `${remValue}rem`;
}

export default function Image({
    fileUrl,
    fileName,
    mimeType,
    conversationId,
    attachmentId,
    messageId,
    downloadFile,
    isApproved,
    approver,
    rejecter,
    showApprovalOptions,
    onAttachmentClick,
    callStepFields,
    height,
    width
}) {
    return (
        <div className='relative w-full h-full p-1' style={{ maxHeight: getImageStyle(height), overflow: 'hidden' }}>
            <img src={fileUrl} className='w-full h-full object-cover' alt='' style={{ maxHeight: getImageStyle(height), overflow: 'hidden' }} />
            <div
                className='absolute top-1 attachmentGlassEffect p-1 w-full h-full'
                onClick={() => {
                    onAttachmentClick();
                }}
            ></div>
            <FileDownloadIcon position='top-5 right-5' onClick={() => downloadFile({ fileUrl, fileName, mimeType })} />

            <div className='absolute flex gap-2 right-5 bottom-5 '>
                <ApprovedRejectIcon showApprovalOptions={showApprovalOptions} isApproved={isApproved} approver={approver} rejecter={rejecter} conversationId={conversationId} messageId={messageId} attachmentId={attachmentId} callStepFields={callStepFields} isCardMessage={false} />
            </div>
        </div>
    );
}
