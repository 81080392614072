import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    createInventorySites,
    getAllCities,
    getEndUsersList,
    getSiteType,
    getSlaPolicies,
    removeCreatedSiteFromStore,
    removeEnduserListFromStore,
    removePinCityFromStore,
} from '../../../../actions/inventory';
import { getCustomerIdOfLoginUser } from '../../../../utils/utils';
import { getCustomersList } from '../../../../actions/customer';
import { InventoryFormFields } from '../../InventoryController';
import { actualValueForDropdown } from '../../../../utils/common';
import { sendToastMessage } from '../../../../actions/toast';
import AddNewEndUser from '../../../Modals/Inventory/AddNewEndUser';
import { openCustomModal } from '../../../../actions/modal';
import { clearLastCreatedEndUserFromStore } from '../../../../actions/users';
import Form from '../../../../common/Form';
import Input from '../../../../common/Form/Input';
import Select from '../../../../common/Form/Select';
import { useForm } from 'react-hook-form';
import apis from '../../../../apis';
import { FetchGoogleAddress } from '../../../SupportTrack/RenderTicketCard';

export default function SiteForm(props) {
    const [endUserListDropdown, setEndUserListDropdown] = useState([]);
    const formName = 'addSiteForm';
    const { loggedUser } = useSelector((state) => state.auth);
    const { cities, endUsersList, slas } = useSelector((state) => state.inventory);
    const { customers } = useSelector((state) => state.customer);
    const { setInitialForm } = useSelector((state) => state.utils);
    const { isCustomerListLoading, isEndUserListLoading, isCitiesLoading, formButtonLoading } = useSelector((state) => state.loading);

    const { createdEndUser } = useSelector((state) => state.user);
    const isToShowCustomerDropdown = InventoryFormFields('addNewSiteCustomerField');

    const customerId = getCustomerIdOfLoginUser(loggedUser);

    const defaultValues = {
        complete_site_address: '',
        create_new: false,
        customer_end_user: '',
        external_site_id: '',
        fk_city_id: '',
        fk_customer_id: '',
        fk_pincode_id: '',
        fk_state_id: '',
        region: '',
        site_branch_name: '',
        site_contact_person_email: '',
        site_contact_person_mobile: '',
        site_contact_person_name: '',
        site_type: '',
        site_vertical: '',
        sla: '',
        zone: '',
    };


    const dispatch = useDispatch();
    const { closeForm } = props;

    const getDataOnMount = useCallback(() => {
        dispatch(getAllCities());
        dispatch(getEndUsersList({ customer_id: customerId }));
        dispatch(getSiteType());
        dispatch(getSlaPolicies({ customerId: customerId, dropdown: true }));
        if (isToShowCustomerDropdown) dispatch(getCustomersList());
    }, [dispatch, isToShowCustomerDropdown, customerId]);

    useEffect(() => {
        getDataOnMount();
        return () => {
            dispatch(removePinCityFromStore());
            dispatch(clearLastCreatedEndUserFromStore());
            dispatch(removeCreatedSiteFromStore());
            dispatch(removeEnduserListFromStore());
        };
    }, [dispatch, getDataOnMount]);

    useEffect(() => {
        if (Array.isArray(endUsersList)) {
            let endUsers = [{ key: Date.now(), label: 'Add New End User', value: 'add_new_end_user' }, ...endUsersList];
            setEndUserListDropdown(endUsers);
        }
    }, [endUsersList]);

    const handleAddNewEndUser = () => {
        let heading = 'Add End User';
        let modalComponent = <AddNewEndUser />;
        let modalWidth = '40rem',
            modalHeight = 'auto';
        dispatch(openCustomModal({ modalComponent, heading, modalWidth, modalHeight }));
    };

    const submit = (form) => {
        let sites = {
            complete_site_address: form.complete_site_address,
            create_new: form.create_new,
            customer_end_user: actualValueForDropdown(form.customer_end_user),
            external_site_id: form.external_site_id,
            fk_city_id: actualValueForDropdown(form.fk_city_id),
            fk_customer_id: actualValueForDropdown(form.fk_customer_id),
            fk_pincode_id: form.fk_pincode_id,
            fk_state_id: form.fk_state_id,
            region: form.region,
            site_branch_name: form.site_branch_name,
            site_contact_person_email: form.site_contact_person_email,
            site_contact_person_mobile: form.site_contact_person_mobile,
            site_contact_person_name: form.site_contact_person_name,
            site_vertical: form.site_vertical,
            sla: actualValueForDropdown(form.sla),
            zone: form.zone,
        };

        if (!isToShowCustomerDropdown) {
            sites = { ...sites, fk_customer_id: customerId };
        }

        let formCustomerId = sites.fk_customer_id;
        const hasCorrectCustomerId = Number.isInteger(formCustomerId) && formCustomerId > 0;
        if (hasCorrectCustomerId) {
            dispatch(createInventorySites({ data: [sites], customerId, formName, refresh: true }));
        } else {
            dispatch(sendToastMessage({ status: 'danger', message: 'Invalid Customer Id' }));
        }
    };

    const methods = useForm({ defaultValues, mode: 'all' });
    const { setValue, reset } = methods;

    useEffect(() => {
        if (createdEndUser) {
            let firstElem = endUserListDropdown.shift();
            let selectedEndUser = { key: Date.now(), value: createdEndUser.id, label: createdEndUser.end_user_name };
            let newEndUser = [firstElem, selectedEndUser, ...endUserListDropdown];
            setEndUserListDropdown(newEndUser);
            setValue('customer_end_user', selectedEndUser)
        }
    }, [createdEndUser, setValue, endUserListDropdown]);

    useEffect(() => {
        if (setInitialForm) {
            reset();
            dispatch(clearLastCreatedEndUserFromStore());
        }
    }, [setInitialForm, reset, dispatch])


    return (
        <Form
            methods={methods}
            onSubmit={submit}
            submitButtonText={'Create'}
            onCancel={closeForm}
            showSubmit
            submitContainerClassName='flex gap-2 py-4 px-4'
            isLoading={formButtonLoading[formName]}
        >
            {isToShowCustomerDropdown ? (
                <div className='px-3 flex justify-between'>
                    <Select
                        label='Customer'
                        required
                        name='fk_customer_id'

                        className={'w-full'}
                        options={customers}
                        isLoading={isCustomerListLoading}
                        shouldUnregister
                        onChange={(value) => {
                            const selectedCustomerId = actualValueForDropdown(value);
                            dispatch(getEndUsersList({ customer_id: selectedCustomerId }));
                            dispatch(getSlaPolicies({ customerId: selectedCustomerId, dropdown: true }));
                        }}
                    />
                </div>
            ) : (
                <></>
            )}
            <div className='px-3 flex justify-between'>
                <Select
                    label='End User'
                    name='customer_end_user'
                    options={endUserListDropdown}
                    placeholder={'Select...'}
                    className={'w-6/12'}
                    isLoading={isEndUserListLoading}
                    onChange={(value) => {
                        if (actualValueForDropdown(value) === 'add_new_end_user') {
                            handleAddNewEndUser();
                        }
                    }}
                />
                <Select
                    label='SLA'
                    name='sla'
                    options={slas}
                    placeholder={'Select...'}
                    className={'w-6/12'}
                />
            </div>
            <div className='px-3 flex justify-between'>
                <Input label='Site ID' name='external_site_id' required className={'w-6/12'} />
                <Input label='Branch Name' name='site_branch_name' className={'w-6/12'} />
            </div>

            <div className='px-3 flex justify-between items-end'>
                <Input
                    label='Pin Code'
                    required
                    name='fk_pincode_id'
                    className={'w-6/12'}
                    pattern={/^\d{6}$/}
                    validate={{
                        pincodeNotInSystem: async (value) => {
                            if (value === '') return true;
                            const response = await apis.cities.getCityFromPincode({}, {}, {}, { pincode: value });
                            if (response?.data?.data?.fk_city_id && Array.isArray(cities)) {
                                let findCity = cities.find((city) => city.value === response.data.data.fk_city_id);
                                setValue('fk_city_id', findCity);
                                setValue('fk_state_id', response.data.data.fk_state_id);
                            } else {
                                setValue('fk_city_id', '');
                                setValue('fk_state_id', '');
                            }
                            return response.data?.code === 200 || "Pincode does't exist in the System.";
                        }
                    }}
                />
                <Select
                    label='City'
                    name='fk_city_id'
                    options={cities}
                    placeholder={'Select...'}
                    className={'w-6/12'}
                    isLoading={isCitiesLoading}
                    required
                />
            </div>
            <div className='px-3'>
                {/* <Input label='Address' type={'text'} name='complete_site_address' required /> */}

                <FetchGoogleAddress name={'complete_site_address'} setLocation={({ pincode }) => setValue('fk_pincode_id', pincode)} />
            </div>

            <div className='px-3 flex justify-between'>
                <Input label='Contact Name' type={'text'} name='site_contact_person_name' className={'w-6/12'} />
                <Input
                    label='Contact Mobile'
                    type={'number'}
                    name='site_contact_person_mobile'
                    className={'w-6/12'}
                    validate={(mobile) => {
                        if (mobile === '') return true;
                        return mobile.length === 10 || 'Invalid Mobile';
                    }}
                />
            </div>
            <div className='px-3 '>
                <Input
                    label='Contact Email'
                    type={'text'}
                    name='site_contact_person_email'
                    validate={(email) => {
                        if (email === '') return true;
                        return (
                            email.match(
                                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            ) || 'Invalid Email'
                        );
                    }}
                />
            </div>
        </Form>
    );
}
