import React, { useRef } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid'

import ButtonScogoOrange from '../../../common/Buttons/ButtonScogoOrange';
import MaterialIconToolTip from '../../../common/MaterialIconToolTip';

import Input from '../../../common/Form/Input';
import Select from '../../../common/Form/Select';
import { useSelector } from 'react-redux';

const ConsumableForm = ({ assetsConsumableDataArrayName, ticket, showLabel = true }) => {

    const containerRef = useRef();

    const { boqList } = useSelector((state) => state.tickets);

    const { control } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        control,
        name: assetsConsumableDataArrayName,
        keyName: 'consumableId'
    });

    return (
        <>
            {showLabel && <div className="mt-6 px-3 py-4 sm:px-6">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Add Consumables</h3>
            </div>}
            <div className='' ref={containerRef} >
                {fields.map((field, index) => {
                    return (<div key={field.consumableId} className='flex border-b border-scogoddd py-2 items-center'>
                        <div className='flex items-center w-11/12 text-font11 text-black font-normal pl-2 '>
                            <Select key={field.consumableId} className='grow flex-1' name={`${assetsConsumableDataArrayName}.${index}.asset_type_id`} label='Asset Type' options={boqList} required shouldUnregister />
                            <Input type='text' key={field.consumableId} className='flex-1' id={field.consumableId} name={`${assetsConsumableDataArrayName}.${index}.serial_number`} shouldUnregister label='Serial Number' />
                        </div>
                        <MaterialIconToolTip
                            onClick={() => remove(index)}
                            title='Remove Asset'
                            materialIconName='delete'
                            materialIconClass='material-icons text-scogoToastDangerIcon flex justify-center text-font16 cursor-pointer w-1/12 pl-4 hover:text-scogoorange mt-3'
                        />
                    </div>)
                })}
                <ButtonScogoOrange buttonIconComponent={<PlusIcon />} textOrComponent='Add' onClick={() => {
                    append({
                        id: uuidv4(),
                        asset_type_id: undefined,
                        serial_number: '',
                        quantity: 1,
                    })
                }
                } buttonClass='my-4 ml-4 py-2' />
            </div>
        </>
    )
}

const PlusIcon = () => {
    return <span className="material-icons-outlined text-font11">
        add
    </span>
}

export default ConsumableForm