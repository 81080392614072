import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import { getAllStates, getGstList, updateGstById, deleteGstById, addGst, clearUpdateGstStore } from '../../../actions/users';
import formTable from '../../../common/formTable';
import IconToolTip from '../../../common/IconToolTip';
import ButtonScogoPrimary from '../../../common/Buttons/ButtonScogoPrimary';
import { ConfirmationPopup } from '../../../common/ConfirmationPopup';
import MiniPopup from '../../../common/MiniPopup';
import ButtonScogoOrange from '../../../common/Buttons';

import { isValidGST } from '../../../utils/utils';

import Form from '../../Inventory/reusableComponents/AddOption/Form';
import Input from '../../Inventory/reusableComponents/AddOption/Input';
import { SpanLabelButton } from './CustomerBusinessDetails';

export const gstStatus = [
    { key: 1, value: 1, label: 'Active' },
    { key: 2, value: 2, label: 'InActive' },
];

const BinaryLabel = ({ label, isActive }) => {
    return (
        <IconToolTip title={`${label}`}>
            <span
                className={`text-font08  px-p-3 py-p-1 font-bold font-roboto items-center rounded-3px  bg-white ${
                    isActive ? 'text-scogoclosed border border-scogoclosed' : 'text-green-600 border border-green-600 '
                }`}
            >
                {label}
            </span>
        </IconToolTip>
    );
};

export const DeleteGSTEntry = ({ onClickOnYes, formName }) => {
    const dispatch = useDispatch();

    const onClick = (closeMenu) => {
        onClickOnYes();
    };

    let popup = <ConfirmationPopup onClick={onClick} formName={formName} />;
    return (
        <MiniPopup
            handleClick={() => {}}
            popup={popup}
            transition={false}
            className='fixed px-3 py-2 mt-2 z-10 rounded-md shadow-lg bg-white overflow-scroll left-0 top-0 border-2'
            menuStyle={{ left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
        >
            <span className='material-icons text-scogoToastDangerIcon py-2 px-3  text-font13 cursor-pointer'>delete</span>
        </MiniPopup>
    );
};

export default function GstModal({ userId }) {
    const arrayName = 'gstData';
    const formName = 'updateGSTForm';
    const dispatch = useDispatch();

    const { states, gstList, updatedGstId } = useSelector((state) => state.user);
    const { getGstListLoading, createGstLoading, updateGstLoading } = useSelector((state) => state.loading);

    const [isEditingRow, setIsEditingRow] = useState([]);

    useEffect(() => {
        if (updatedGstId) {
            setIsEditingRow((current) => current.filter((id) => id !== updatedGstId));
        }
        return () => dispatch(clearUpdateGstStore());
    }, [updatedGstId, dispatch]);

    useEffect(() => {
        dispatch(getGstList({ userId }));
    }, [dispatch, userId]);

    const initialValues = {
        [arrayName]: [],
    };

    const [form, setForm] = useState(initialValues);

    useEffect(() => {
        if (gstList) {
            setForm({
                [arrayName]: gstList?.map((item) => {
                    return {
                        gstId: item.gstId,
                        gst_number: item.gst_number,
                        gst_state: states?.find((state) => state.value === item.gst_state),
                        gst_status: gstStatus?.find((status) => status.label === item.gst_status),
                    };
                }),
            });
        }
    }, [gstList, states]);

    const createGST = (event, data) => {
        event.preventDefault();
        let gstlist = [
            {
                gstin: data?.gst_number,
                state_code: data?.gst_state?.key,
                active_status: data?.gst_status?.label,
            },
        ];
        dispatch(addGst({ gstlist, userId }));
    };

    const updateGST = (event, rowData) => {
        event.preventDefault();
        let updatePayload = {
            gst: rowData?.gst_number,
            state_code: rowData?.gst_state?.value,
            status: rowData?.gst_status.label,
        };
        dispatch(updateGstById({ updatePayload, gstId: rowData.gstId }));
    };

    const deleteGST = (rowData) => {
        let gstId = rowData?.gstId;
        dispatch(deleteGstById({ gstId, formName: 'deleteGstEntry' }));
    };

    const isUserEditing = (idx) => isEditingRow.includes(idx);

    const getIsButtonDisabled = (data) => {
        const isDisabled = !isValidGST(data?.gst_number) || !data?.gst_state?.label || !data?.gst_status?.label;
        return isDisabled;
    };

    const onUpdateCancel = (event, rowData) => {
        event.preventDefault();
        const historyArray = gstList?.map((item) => {
            return {
                gstId: item.gstId,
                gst_number: item.gst_number,
                gst_state: states?.find((state) => state.value === item.gst_state),
                gst_status: gstStatus?.find((status) => status.label === item.gst_status),
            };
        });
        setForm({ [arrayName]: historyArray });
        setIsEditingRow((current) => current.filter((id) => id !== rowData.gstId));
    };

    const schema = {
        table: [
            {
                head: {
                    thClassName: `pl-5 flex-1`,
                    headName: 'GST Number',
                    required: true,
                },
                body: {
                    bodyClassName: `pl-5  flex-1 truncate`,
                    render: (data, idx) => {
                        if (data.isEditable || isUserEditing(data.gstId)) {
                            return (
                                <Input
                                    type='text'
                                    name={`${arrayName}[${idx}].gst_number`}
                                    customValidation={isValidGST}
                                    errorMessage={'Invalid GST'}
                                    placeholder='GST Number'
                                    required
                                />
                            );
                        } else {
                            return <div className='font-bold text-scogoprimary'>{data?.gst_number}</div>;
                        }
                    },
                },
            },
            {
                head: {
                    thClassName: `pl-5 flex-1`,
                    headName: 'State',
                    required: true,
                },
                body: {
                    bodyClassName: `pl-5 flex-1 truncate`,
                    render: (data, idx) => {
                        if (data.isEditable || isUserEditing(data.gstId)) {
                            return <Input type='dropdown' name={`${arrayName}[${idx}].gst_state`} required options={states} placeholder='GST State' />;
                        } else {
                            return <div>{data?.gst_state?.label}</div>;
                        }
                    },
                },
            },
            {
                head: {
                    thClassName: ` pl-5 flex-1`,
                    headName: 'Status',
                    required: true,
                },
                body: {
                    bodyClassName: `pl-5 flex-1 truncate`,
                    render: (data, idx) => {
                        if (data.isEditable || isUserEditing(data.gstId)) {
                            return <Input type='dropdown' name={`${arrayName}[${idx}].gst_status`} required options={gstStatus} placeholder='Status' />;
                        } else {
                            return (
                                <div>
                                    <BinaryLabel label={data?.gst_status?.label} isActive={data?.gst_status?.label === 'InActive'} />
                                </div>
                            );
                        }
                    },
                },
            },
            {
                head: {
                    thClassName: ` pl-5 flex-1`,
                    headName: 'Update / Remove',
                },
                body: {
                    bodyClassName: ` pl-5 flex-1 truncate`,
                    render: (data, idx) => {
                        if (data.isDeletable) {
                            return (
                                <div className='flex items-center gap-3'>
                                    <ButtonScogoPrimary
                                        textOrComponent={'Create'}
                                        buttonClass='h-8  rounded-md hover:bg-scogobgsky text-font10 w-5/12'
                                        onClick={(event) => createGST(event, data, idx)}
                                        loading={createGstLoading}
                                        loaderColor={'#fff'}
                                        disabled={getIsButtonDisabled(data)}
                                        disabledClass={
                                            'text-scogogray flex items-center justify-center rounded-md  text-font12 h-8 gap-1  relative cursor-not-allowed border-scogoddd bg-scogoddd w-5/12'
                                        }
                                    />
                                    <span
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setForm((prevForm) => {
                                                const afterRemove = prevForm[arrayName].slice(0, idx).concat(prevForm[arrayName].slice(idx + 1, prevForm[arrayName].length));
                                                return { [arrayName]: afterRemove };
                                            });
                                        }}
                                        className={`material-icons text-scogoToastDangerIcon cursor-pointer `}
                                    >
                                        clear
                                    </span>
                                </div>
                            );
                        } else {
                            if (isUserEditing(data.gstId)) {
                                const isButtonDisabled = getIsButtonDisabled(data);
                                return (
                                    <div className='flex items-center gap-3'>
                                        <ButtonScogoPrimary
                                            textOrComponent={'Update'}
                                            buttonClass='h-8  rounded-md hover:bg-scogobgsky text-font10 w-5/12'
                                            onClick={(event) => updateGST(event, data)}
                                            loading={updateGstLoading?.[data.gstId]}
                                            disabled={isButtonDisabled}
                                            loaderColor={'#fff'}
                                            disabledClass={
                                                'text-scogogray flex items-center justify-center rounded-md  text-font12 h-8 gap-1  relative cursor-not-allowed border-scogoddd bg-scogoddd w-5/12'
                                            }
                                        />
                                        <span onClick={(event) => onUpdateCancel(event, data)} className={`material-icons text-scogoToastDangerIcon cursor-pointer `}>
                                            clear
                                        </span>
                                    </div>
                                );
                            } else {
                                return (
                                    <div className='flex items-center'>
                                        <span
                                            onClick={() => setIsEditingRow((oldIdx) => [...oldIdx, data.gstId])}
                                            className='material-icons hover:text-scogoorange cursor-pointer text-scogoprimary text-font14  px-3'
                                        >
                                            edit
                                        </span>
                                        <DeleteGSTEntry
                                            onClickOnYes={() => {
                                                deleteGST(data);
                                            }}
                                        />
                                    </div>
                                );
                            }
                        }
                    },
                },
            },
        ],
    };

    return (
        <div className='p-5'>
            <Form
                submit={(e) => {
                    console.log(e);
                }}
                buttonPostion={'justify-center'}
                initialValues={initialValues}
                form={form}
                setForm={setForm}
                formName={formName}
                customFormButtons={true}
            >
                {formTable({
                    schema,
                    form,
                    isLoading: getGstListLoading,
                    setForm,
                    arrayName,
                    tableError: 'Empty',
                    loaderMargin: '70px',
                    allowAdd: true,
                    rowGeneratorButtonName: 'Add GST',
                    addButtonClass: `hover:bg-scogoorange  hover:text-white bg-white mt-5 border border-scogoorange px-2 leading-6 cursor-pointer text-scogoorange font-normal rounded-md gap-1 font-medium cursor-pointer px-6 ml-4`,
                    rowGenerator: () => ({
                        id: `${Math.random()}`,
                        isEditable: true,
                        isDeletable: true,
                        gst_number: '',
                        gst_state: '',
                    }),
                })}
            </Form>
        </div>
    );
}
