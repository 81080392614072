import React from 'react';
import { textCss } from '../../../common/Css.js';
import ClearFilterButton from '../../../common/Buttons/ClearFilterButton';
import SearchBox from '../../../common/SearchBox.js';
import { assets, spareAssets } from '../InventoryController';
export default function SitesTop(props) {
    let { singular, handleClearFilterClick, setSeachQuery, searchTerm, clearFilterButton, selectedSubTab, isAssetGroupView } = props;
    const advancedSearchFields = {
        [assets.id]: [{ label: 'Serial Number', key: 'serial_number' }, { label: 'Asset Type', key: 'asset_type' }],
        [spareAssets.id]: [{ label: 'Serial Number', key: 'serial_number' }, { label: 'Asset Type', key: 'asset_type' }]
    }
    if (isAssetGroupView) singular = 'Endusers'
    return (
        <>
            <div className='md:flex max-w-full'>
                <div>
                    <h1 className={textCss?.moduleTitle}>{props.title}</h1>
                </div>
                <div className='md:flex items-center ml-auto gap-2'>
                    {clearFilterButton && <ClearFilterButton textOrComponent='Clear Filter' onClick={handleClearFilterClick} />}
                    {props.isSearch && (
                        <SearchBox
                            searchTerm={searchTerm}
                            onChange={(event) => setSeachQuery(event.target.value)}
                            placeholder={`Search ${singular}`}
                            allowAdvancedSearch={[assets.id, spareAssets.id].includes(selectedSubTab)}
                            advancedSearchFields={advancedSearchFields[selectedSubTab]}
                            onAdvancedSearchApply={(advancedSearchParams) => {
                                setSeachQuery(advancedSearchParams)
                            }}
                            onCancel={() => setSeachQuery()}
                        />
                    )}
                </div>
            </div>
        </>
    );
}
