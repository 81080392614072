import React from 'react';
import { SimpleButton } from './SimpleButton';

const ClearFilterButton = ({
    btnimgClass = 'w-4 h-4 mr-1',
    type,
    Btnalt,
    textOrComponent,
    onClick,
    loading,
    disabled = false,
    iconComponent,
    loaderColor,
    disabledClass = 'text-black flex items-center justify-center rounded-6px shadow-primaryBtnShadow px-4 py-2 text-xl gap-1 font-medium relative cursor-not-allowed border-scogoddd bg-scogoddd',
}) => {
    if (!disabled) disabled = loading;

    const btnClass = disabled
        ? disabledClass
        : 'text-scogoprimary whitespace-nowrap bg-white font-medium flex items-center justify-center rounded-6px border border-scogoprimary px-4 py-2 text-xl gap-1 font-medium cursor-pointer hover:bg-scogolightblue';

    return (
        <SimpleButton
            loading={loading}
            btnClass={btnClass}
            onClick={onClick}
            disabled={disabled}
            loaderColor={loaderColor}
            buttonIconComponent={<span className="material-icons text-font14">
                filter_alt_off
            </span>}
            btnimgClass={btnimgClass}
            Btnalt={Btnalt}
            textOrComponent={textOrComponent}
        />
    );
};

export default ClearFilterButton;
