import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga'

import reducers from './reducers';
import indexSaga from './sagas';

// create the saga middleware
const sagaMiddleware = createSagaMiddleware()

const composeEnhancers = (process.env.REACT_APP_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    :
    compose;

const middlewares = [sagaMiddleware]

const enhancers = [applyMiddleware(...middlewares)]

const store = createStore(reducers, composeEnhancers(...enhancers));

sagaMiddleware.run(indexSaga);

export default store;
