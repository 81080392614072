import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExecuteSidebar from '../../components/Execute';
import { useDispatch, useSelector } from 'react-redux';
import { getExecutionStepsFields } from '../../../../actions/ticketExecution';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { validateNumberValue } from '../../../../utils/common';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '1.1rem', color: 'white' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));


const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


const colorLookup = {
    green: '#2FB768',
    orange: '#FFAB5B',
    red: '#E74C3C',
}
export const signoff = { field_title: 'Upload SignOff Images with Sign and Stamp', key: 'upload_signoff_images' };

export const ChatSubHeader = ({ onClick, ticket }) => {
    const dispatch = useDispatch();
    const { executionStepFields } = useSelector((state) => state.tickets);
    const [steps, setList] = useState([]);
    const executionSteps = executionStepFields?.single;
    const [headerColor, setHeaderColor] = useState('orange');

    useEffect(() => {
        if (Array.isArray(ticket.sign_off_document)) {
            signoff.file_values = ticket.sign_off_document;
        }
        let list = [signoff];
        if (Array.isArray(executionSteps)) {
            list = [...executionSteps, signoff]
        }
        setList(list);
    }, [ticket?.sign_off_document, executionSteps, ticket?.is_signoff_rejected]);

    useEffect(() => {
        let color = 'orange';
        if (Array.isArray(steps)) {
            const isAllStepsHasFiles = steps.every((step) => Array.isArray(step.file_values) && step.file_values.length > 0);
            const isAnyStepRejected = steps.find((step) => step.status === 2);
            if (isAnyStepRejected) color = 'red';
            else if (isAllStepsHasFiles) color = 'green';
            else color = 'orange';
            setHeaderColor(color);
        }
    }, [steps])

    useEffect(() => {
        if (ticket.id) {
            const data = { ticket_id: ticket.id, is_checklist: true, template_id: ticket.fk_template_id };
            dispatch(getExecutionStepsFields({ data }));
        }
    }, [ticket?.id, ticket?.fk_template_id, dispatch]);

    const totalSteps = validateNumberValue(steps.length);
    let completedSteps = 0;
    steps.forEach((step) => {
        if (Array.isArray(step.file_values) && step.file_values.length > 0 && step.status !== 2) return completedSteps += 1;
    });

    return <div className={`w-full z-20 chatSuHeaderTop overflow-hidden`}>
        <Accordion className='bg-white'>
            <AccordionSummary sx={{ paddingX: '2rem', minHeight: '3rem', maxHeight: '3rem', background: `${colorLookup[headerColor]}`, width: '100%' }}>
                <div className='flex items-center gap-2 text-scogoprimary cursor-pointer' onClick={onClick}>
                    <span className="text-font11 font-medium text-white">Ticket Execution Checklist</span>
                    <span className='bg-white rounded-full px-2'>({`${completedSteps}/${totalSteps}`})</span>
                </div>
            </AccordionSummary>
            <div className='shadow-lg'>
                <AccordionDetails sx={{ padding: '0', overflowY: 'scroll', maxHeight: "100%" }} >
                    <ExecuteSidebar ticket={ticket} steps={steps} />
                </AccordionDetails>
            </div>
        </Accordion>
    </div>
};