import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getBOQList, viewTicketById } from '../../../actions/tickets';
import { textCss } from '../../../common/Css';
import { useSelector } from 'react-redux';
import NoResultFound from '../../../common/NoResultFound';
import { getCustomerIdOfLoginUser } from '../../../utils/utils';
import { getPoAssetsCategoriesDropdown, getTicketPODetails } from '../../../actions/payments';
import { getCustomerTeams } from '../../../actions/customer';
import { PoApprovalForm } from './PoApprovalForm';
import Loader from '../../../common/Loader';
import IconToolTip from '../../../common/IconToolTip';
import { closeFullScreenModalAction } from '../../../actions/modal';

export default function PoApproval({ ticketId }) {
    const { loggedUser } = useSelector((state) => state.auth);
    const customerId = getCustomerIdOfLoginUser(loggedUser);
    const dispatch = useDispatch();
    const { viewTicket } = useSelector((state) => state.tickets);
    const { isViewTicketByIdLoading } = useSelector((state) => state.loading);

    useEffect(() => {
        if (ticketId) {
            dispatch(viewTicketById({ ticketId }));
            dispatch(getBOQList(customerId));
            dispatch(getPoAssetsCategoriesDropdown());
            dispatch(getTicketPODetails(ticketId));
            dispatch(getCustomerTeams({ customerId, dropdown: true }));
        }
    }, [ticketId, dispatch, customerId]);

    const isEligibleForPoApproval = (ticketId) => {
        if (!ticketId) return false;
        return viewTicket && viewTicket.po_raised === 1 && viewTicket.is_signoff_accept === 0;
    };

    const showNoResultFound = !isViewTicketByIdLoading && (!viewTicket || !isEligibleForPoApproval(viewTicket));

    return (
        <div className='subContainer title-4 px-5 pt-5'>
            <div className={`titleContainer sticky  w-full z-20`}>
                <div className='md:flex max-w-full'>
                    <div>
                        <h1 className={textCss?.moduleTitle}>Approve PO : {viewTicket && <span className='text-scogobgsky text-font16'>{viewTicket.job_ticket_number}</span>}</h1>
                    </div>
                    <div
                        className='ml-auto cursor-pointer'
                        onClick={() => {
                            dispatch(closeFullScreenModalAction());
                        }}
                    >
                        <IconToolTip title='Close'>
                            <span className='material-icons text-scogoprimary font-medium align-middle text-4xl hover:text-scogoorange'>highlight_off</span>
                        </IconToolTip>
                    </div>
                </div>
            </div>
            {isEligibleForPoApproval(ticketId) && <PoApprovalForm ticketId={ticketId} />}
            {!isEligibleForPoApproval(ticketId) && isViewTicketByIdLoading && (
                <div className=''>
                    <Loader color='#F48A21' size='65' speed='1' thickness='3' margin='150px' />
                </div>
            )}
            {showNoResultFound && <NoResultFound message='Ticket Not Found' />}
        </div>
    );
}
