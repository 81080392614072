import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { textCss } from '../../../../../common/Css';
import OverflowTip from '../../../../../common/OverflowTip';

const UploadButton = (props) => {
    const {
        form,
        label,
        required,
        className,
        accept,
        name,
        multiple,
        placeholder,
        errorMessage,
        errors,
        handleFileUpload,
        handleClearFileUpload,
        onBlurHandler,
        isClearableOnSuccess = true,
        overrideExistingFiles = true
    } = props;

    const uploadInputRef = useRef();
    const { setInitialForm } = useSelector((state) => state.utils);

    useEffect(() => {
        if (isClearableOnSuccess && uploadInputRef?.current?.value) uploadInputRef.current.value = null;
    }, [setInitialForm]);

    const onChangeFileUpload = (event, errorMessage) => {
        handleFileUpload(event, errorMessage, overrideExistingFiles);
    };

    const handleClearFile = (file, name) => {
        let files = [];
        files = [..._.get(form, name)].filter((elem) => {
            return file.name !== elem.name;
        });
        handleClearFileUpload(files, name);
    };

    return (
        <div className={`px-4 py-3 ${className ? className : ''}`}>
            <div className='w-full'>
                <div className='upload-button relative overflow-hidden inline-block mt-2'>
                    <button className='border border-solid bg-transparent py-2 px-3 rounded text-base font-bold cursor-pointer'>{label}</button>
                    <input
                        ref={uploadInputRef}
                        type='file'
                        accept={accept}
                        name={name}
                        onChange={(event) => {
                            onChangeFileUpload(event);
                        }}
                        multiple={multiple}
                        placeholder={placeholder}
                        required={required}
                        className='opacity-0 left-0 top-0 w-full h-full absolute text-[100px] cursor-pointer '
                        onBlurHandler={onBlurHandler}
                    />
                </div>
            </div>
            <div className='pt-1 flex gap-2 flex-wrap'>
                {_.get(form, name) &&
                    [..._.get(form, name)].map((elem, index) => {
                        return (
                            <div className='max-w-12 flex text-white bg-scogoorange rounded-sm px-2 text-base items-center' key={index}>
                                <OverflowTip textClass={` truncate  ${textCss?.inputText}`} someLongText={elem.name} />
                                <span
                                    className='text-base font-bold z-50 material-icons cursor-pointer ml-2'
                                    onClick={() => handleClearFile(elem, name)}
                                >
                                    clear
                                </span>
                            </div>
                        );
                    })}
            </div>
            {_.get(errors, name) && <span className='text-scogoclosed 2xl:text-font12 text-font10 font-normal'>{_.get(errors, name)}</span>}
        </div>
    );
};

export default UploadButton;
