import React, { useContext, useState } from 'react';
import _ from 'lodash';
import { FormContext } from './Form';
import InputField from './fields/InputField';
import TextArea from './fields//TextArea';
import DropdownField from './fields/DropdownField';
import CheckboxField from './fields/CheckboxField';
import CheckboxCard from './fields/CheckboxCard';
import DatePickerField from './fields/DatePickerField';
import RadioField from './fields/RadioField';
import RadioCard from './fields/RadioCard';
import { textCss } from '../../../../common/Css';
import Dropzone from '../../../../common/Dropzone';
import Multi from '../../../../common/MultiEmail';
import DateTimePicker from './fields/DatePicker';
import UploadFiles from './fields/UploadFiles';
import UploadButton from './fields/UploadButton';
import { isValidPincode } from '../../../../utils/utils';
import { isEmail } from 'react-multi-email';

export default function FormInput(props) {
    const {
        className,
        label,
        type,
        name,
        placeholder,
        required,
        options,
        defaultValue,
        customMethod = () => { },
        errorMessage,
        customValidation,
        radioOptions,
        accept,
        multiple,
        isMulti,
        isHideInput,
        isLoading,
        getacceptedFiles,
        defaultValues,
        getValues,
        maxDate,
        minDate,
        showTimeSelect,
        value,
        multiType,
        isClearableOnSuccess = false,
        dateFormat,
        inputValueFormat,
        checked,
        isEditable = true,
        disabled,
        maxLength,
        allowFloats,
        renderOption,
        defaultChecked = true,
        rounded,
        leadingIcon,
        inputIcon,
        iconLoader,
        inputClass,
        padding,
        getLabelOnCheck = false,
        overrideExistingFiles = true,
        onSelectInputChange,
        readOnly
    } = props;
    const formContext = useContext(FormContext);
    const { form, handleFormChange, handleFormOnBlur, errors, handleFileUpload, handleClearFileUpload } = formContext;
    const [uploadedFile, setUploadedDFile] = useState([]);

    const onChangeHandler = (event, errorMessage) => {
        customMethod(event);
        handleFormChange(event, errorMessage);
    };

    const onCheckboxChange = (event, label) => {
        const name = event.target.name;
        let value = event.target.checked;
        if (event.target.checked && getLabelOnCheck) {
            value = label;
        }
        customMethod(event);
        handleFormChange({ name, value });
    };

    const onlyAcceptNumbers = (event) => {
        const testRegex = allowFloats ? /[\d.\-]/ : /[\d\-]/;
        if (!testRegex.test(event.key)) {
            event.preventDefault();
        }
    };

    const onNumberChange = (event, errorMessage) => {
        customMethod(event);
        const { name, value, required } = event.target;
        handleFormChange({ name, value: value, required }, errorMessage);
    };

    const onMultiChange = (items) => {
        customMethod({ value: items.join(','), name, required });
        onChangeHandler({ value: items.join(','), name, required });
    };

    const onBlurHandler = (event, errorMessage) => {
        handleFormOnBlur(event, errorMessage, customValidation);
    };

    const handleUploadUI = (uploadedFile) => {
        let message = '';
        const fileLength = uploadedFile.length;
        if (fileLength === 0) {
            message = 'No File Choosen';
        } else if (fileLength === 1) {
            message = uploadedFile[0].name;
        } else message = `${fileLength} File`;

        return <p className={`overflow-hidden w-full truncate ${textCss?.inputText}`}>{message}</p>;
    };

    switch (type) {
        case 'text':
        case 'password':
            if (isHideInput) return <></>;
            return (
                <InputField
                    className={className}
                    label={label}
                    maxLength={maxLength}
                    required={required}
                    type={type}
                    name={name}
                    value={_.get(form, name)}
                    onChangeHandler={onChangeHandler}
                    onBlurHandler={onBlurHandler}
                    errorMessage={errorMessage}
                    errors={errors}
                    placeholder={placeholder}
                    isEditable={isEditable}
                    disabled={disabled}
                    rounded={rounded}
                    leadingIcon={leadingIcon}
                    inputIcon={inputIcon}
                    iconLoader={iconLoader}
                    inputClass={inputClass}
                    padding={padding}
                />
            );
        case 'textarea':
            if (isHideInput) return <></>;
            return (
                <TextArea
                    className={className}
                    label={label}
                    maxLength={maxLength}
                    required={required}
                    type={type}
                    name={name}
                    value={_.get(form, name)}
                    onChangeHandler={onChangeHandler}
                    onBlurHandler={onBlurHandler}
                    errorMessage={errorMessage}
                    errors={errors}
                    placeholder={placeholder}
                    isEditable={isEditable}
                    disabled={disabled}
                />
            );
        case 'number':
            if (isHideInput) return <></>;
            return (
                <InputField
                    onKeyPress={onlyAcceptNumbers}
                    className={className}
                    label={label}
                    maxLength={maxLength}
                    required={required}
                    type={'text'}
                    name={name}
                    value={_.get(form, name)}
                    onChangeHandler={onNumberChange}
                    onBlurHandler={onBlurHandler}
                    errorMessage={errorMessage}
                    errors={errors}
                    placeholder={placeholder}
                    disabled={disabled}
                    rounded={rounded}
                    leadingIcon={leadingIcon}
                    inputClass={inputClass}
                    padding={padding}
                />
            );
        case 'email':
            if (isHideInput) return <></>;
            return (
                <InputField
                    className={className}
                    label={label}
                    required={required}
                    type={type}
                    name={name}
                    value={_.get(form, name)}
                    onChangeHandler={onChangeHandler}
                    onBlurHandler={onBlurHandler}
                    errorMessage={errorMessage}
                    errors={errors}
                    placeholder={placeholder}
                    isEditable={isEditable}
                    disabled={disabled}
                />
            );
        case 'dropdown':
            if (isHideInput) return <></>;
            return (
                <DropdownField
                    className={className}
                    label={label}
                    isClearableOnSuccess={isClearableOnSuccess}
                    required={required}
                    name={name}
                    onChangeHandler={onChangeHandler}
                    placeholder={placeholder}
                    errors={errors}
                    options={options}
                    defaultValue={defaultValue}
                    isMulti={isMulti}
                    form={form}
                    isLoading={isLoading}
                    renderOption={renderOption}
                    padding={padding}
                    onSelectInputChange={onSelectInputChange}
                />
            );
        case 'checkbox':
            return <CheckboxField isEditable={isEditable} className={className} checkContent={label} onChangeHandler={onCheckboxChange} name={name} form={form} />;
        case 'checkboxcard':
            return <CheckboxCard isEditable={isEditable} className={className} checkContent={label} onChangeHandler={onCheckboxChange} name={name} form={form} />;
        case 'datepicker':
            return (
                <DateTimePicker
                    className={className}
                    label={label}
                    required={required}
                    name={name}
                    onChangeHandler={onChangeHandler}
                    placeholder={placeholder}
                    maxDate={maxDate}
                    minDate={minDate}
                    showTimeSelect={showTimeSelect}
                    value={value}
                    errors={errors}
                    dateFormat={dateFormat}
                    inputValueFormat={inputValueFormat}
                    defaultValue={defaultValue}
                    padding={padding}
                />
            );

        case 'singledatepicker':
            return (
                <DateTimePicker
                    className={className}
                    label={label}
                    required={required}
                    name={name}
                    onChangeHandler={onChangeHandler}
                    placeholder={placeholder}
                    isSingleDatePicker
                    maxDate={maxDate}
                    minDate={minDate}
                    showTimeSelect={showTimeSelect}
                    value={_.get(form, name)}
                    defaultValue={defaultValue}
                    errors={errors}
                    dateFormat={dateFormat}
                    inputValueFormat={inputValueFormat}
                    form={form}
                    readOnly={readOnly}
                />
            );
        case 'timepicker':
            return (
                <DateTimePicker
                    className={className}
                    label={label}
                    required={required}
                    name={name}
                    onChangeHandler={onChangeHandler}
                    placeholder={placeholder}
                    timePicker
                    maxDate={maxDate}
                    minDate={minDate}
                    showTimeSelect={showTimeSelect}
                    value={_.get(form, name)}
                    defaultValue={defaultValue}
                    errors={errors}
                    dateFormat={dateFormat}
                    inputValueFormat={inputValueFormat}
                    form={form}
                />
            );
        case 'radio':
            if (isHideInput) return <></>;
            return (
                <RadioField
                    value={_.get(form, name)}
                    className={className}
                    label={label}
                    required={required}
                    radioOptions={radioOptions}
                    name={name}
                    onChangeHandler={onChangeHandler}
                    isClearable={false}
                    defaultChecked={defaultChecked}
                    isEditable={isEditable}
                />
            );
        case 'radiocard':
            if (isHideInput) return <></>;
            return (
                <RadioCard
                    value={_.get(form, name)}
                    className={className}
                    label={label}
                    required={required}
                    radioOptions={radioOptions}
                    name={name}
                    onChangeHandler={onChangeHandler}
                    isClearable={false}
                    defaultChecked={defaultChecked}
                />
            );
        case 'upload':
            return (
                <UploadFiles
                    form={form}
                    label={label}
                    required={required}
                    className={className}
                    accept={accept}
                    name={name}
                    multiple={multiple}
                    placeholder={placeholder}
                    errorMessage={errorMessage}
                    errors={errors}
                    handleFileUpload={handleFileUpload}
                    handleClearFileUpload={handleClearFileUpload}
                    onBlurHandler={onBlurHandler}
                    isClearableOnSuccess={isClearableOnSuccess}
                    overrideExistingFiles={overrideExistingFiles}
                />
            );
        case 'upload-button':
            return (
                <UploadButton
                    form={form}
                    label={label}
                    required={required}
                    className={className}
                    accept={accept}
                    name={name}
                    multiple={multiple}
                    placeholder={placeholder}
                    errorMessage={errorMessage}
                    errors={errors}
                    handleFileUpload={handleFileUpload}
                    handleClearFileUpload={handleClearFileUpload}
                    onBlurHandler={onBlurHandler}
                    isClearableOnSuccess={isClearableOnSuccess}
                    overrideExistingFiles={overrideExistingFiles}
                />
            );
        case 'multiEmail':
            return (
                <div className={`px-4 py-3 ${className}`}>
                    <Multi
                        value={_.get(form, name) !== '' ? _.get(form, name).split(',') : []}
                        name={name}
                        onChangeHandler={onMultiChange}
                        errors={errors}
                        required={required}
                        label={label}
                        validate={isEmail}
                        placeholder={placeholder}
                    />
                </div>
            );
        case 'multiPincodes':
            return (
                <Multi
                    value={_.get(form, name) !== '' ? _.get(form, name).split(',') : []}
                    name={name}
                    onChangeHandler={onMultiChange}
                    errors={errors}
                    required={required}
                    label={label}
                    validate={isValidPincode}
                    placeholder={placeholder}
                />
            );
        default:
            return (
                <div className={'py-2'}>
                    <label className='block  border-b-2 border-scogoddd'>
                        <span className='text-scogo99 font-normal text-font12'>Invalid Input</span>
                    </label>
                </div>
            );
    }
}
