import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import QRCode from 'qrcode.react';
import { generateAssetBarcode } from '../../../actions/inventory';
import ButtonScogoPrimary from '../../../common/Buttons/ButtonScogoPrimary';
import ButtonScogoClosedOutlined from '../../../common/Buttons/ButtonScogoClosedOutlined';
import { detailsPath } from '../../../router/roleWisePageRoute';

const formName = 'downLoadQrCode';
const DownloadQR = ({ modalData, customerId, customerName, assetslist }) => {
    const dispatch = useDispatch();
    const { formButtonLoading } = useSelector((state) => state.loading);
    let asset_number = modalData?.assetId ? modalData.assetId : modalData?.spare_number ? modalData.spare_number : '';
    let qrtag = (modalData?.tagDetails?.tagId ? modalData.tagDetails.tagId : '').split('-')[0];
    let property = modalData.type === 'asset' ? 'ASSET' : 'SPAREASSET';

    const handleSubmit = () => {
        const assets = assetslist.map((i) => i.id);
        if (assets.length > 0) {
            dispatch(generateAssetBarcode({ data: { assets, customerId: modalData?.customer_id, property }, formName }));
        }
    };

    const closeModal = () => {
        dispatch(closeModalAction());
    };

    let tagDetailValue = `${window.location.origin}`;
    let tagLabel = `Click Print to tag the Asset to this QR`;
    let tagLabelClass = 'text-scogoorange text-base mr-10';
    if (modalData && modalData.tagDetails) {
        tagDetailValue = `${window.location.origin}${detailsPath.path}?tag=${modalData.tagDetails.tagId}`;
        tagLabel = `QR ID: ${qrtag}`
        tagLabelClass = 'text-gray-700 text-base mr-10';
    };

    return (
        <>
            <div className='max-w-sm w-full lg:max-w-full lg:flex pt-6 px-10 '>
                <div className='h-auto flex-none bg-cover text-center overflow-hidden' title={asset_number}>
                    <QRCode
                        id='SN-00966'
                        value={tagDetailValue}
                        size={120}
                        level={'H'}
                        style={{ paddingBottom: '5%' }}
                    />
                    {tagLabel && <p className={tagLabelClass}>{tagLabel}</p>}
                </div>
                <div className=' border-gray-400 lg:border-l-0 lg: lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal'>
                    <div>
                        <p className='text-sm text-gray-600 flex items-center'>
                            Customer : <p> {customerName}</p>
                        </p>
                        <div className='text-gray-900 font-bold text-xl mb-2'>
                            {`Asset ID: ${asset_number}`}

                            <p className='text-gray-700 text-base'>Powerd by Scogo Networks</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`px-6 gap-4 pb-8 pt-6 flex  justify-end`}>
                <ButtonScogoPrimary textOrComponent={'Print'} onClick={() => handleSubmit()} loading={formButtonLoading?.[formName]} />

                <ButtonScogoClosedOutlined textOrComponent={'Cancel'} onClick={closeModal} />
            </div>
        </>
    );
};

export default DownloadQR;
