import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loader from '../../common/Loader';
import Layout from './Layout';
import { initOTPless, isValidEmail, isValidMobileNumber } from '../../utils/utils';
import { checkIfUserIsRegisteredAction, loginWithoutOtp, setMobileOrEmailAction } from '../../actions/auth';
import Form from '../../common/Form';
import Input from '../../common/Form/Input';
import { useForm } from 'react-hook-form';
import { NewScogoButton, isMobileView } from './components/NewScogoButton';
import _ from 'lodash';

export const otpless_login_formname = 'otpless_login_formname';

function Login() {
    const navigate = useNavigate();
    const { formButtonLoading } = useSelector((state) => state.loading);

    const goToSignUp = () => {
        navigate('/signUp')
    };

    return (
        <Layout>
            <div className=' flex flex-col gap-6 lg:gap-3' style={{ width: '30rem' }}>
                <div className=' flex flex-col gap-6 lg:gap-4'>
                    <FormLabel text={'Login To Your Account'} />
                    <LoginWithWhatsappButton loading={formButtonLoading[otpless_login_formname]} />
                </div>
                <div className='flex justify-center items-center gap-2'>
                    <p className='border bg-scogoclosed w-40 h-0.5'></p>
                    <span className='font text-scogo99'>Or</span>
                    <p className='border bg-scogoclosed w-40 h-0.5'></p>
                </div>
                <LoginForm />
                <div className='text-center text-font14'>
                    <span className='text-scogo99'>Don't Have An Account ?</span>
                    <span onClick={goToSignUp} className=' pl-2 text-scogoorange cursor-pointer'>Sign Up</span>
                </div>
            </div>
        </Layout >
    );
}

export default Login;


const LoginForm = () => {
    const defaultValues = {};
    const methods = useForm({ defaultValues, mode: 'all' });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const submitButtonRef = useRef();

    const { checkUserLoading } = useSelector((state) => state.loading);
    const checkIfUserIsRegistered = (formValues) => {

        const { mobile_or_email } = formValues;
        // setting mobile or email in store to use in other path component 
        dispatch(setMobileOrEmailAction({ mobileOrEmail: mobile_or_email }));
        dispatch(
            checkIfUserIsRegisteredAction({
                mobileOrEmail: mobile_or_email.toString(),
                navigate,
            })
        );
    };

    return <div>
        <Form
            methods={methods}
            submitRef={submitButtonRef}
            onSubmit={checkIfUserIsRegistered}
            className='flex flex-col gap-3'
            onEnterPressSubmit
        >
            <div className='flex flex-col gap-5 lg:gap-0'>
                <div className='mb-3'>
                    <Input
                        type={'text'}
                        name='mobile_or_email'
                        inputClassName={` font-normal block w-full focus:ring-0 py-2 px-2 text-font14  rounded-md placeholder:text-inputfieldtext placeholder:text-lg `}
                        placeholder='Enter Mobile or Email'
                        required
                        inputStyle={{ 'height': isMobileView ? '4.5rem' : '3.5rem' }}
                        label='Email Or Mobile'

                        validate={(value) => {
                            return (isValidMobileNumber(value) || isValidEmail(value)) || 'Enter Valid Email Or Mobile';

                        }}

                        randerForgotLabel={<ForgotPasswordLabel />}
                    />

                </div>

                <div className='flex gap-4'>
                    <NewScogoButton onClick={(e) => {
                        e.preventDefault();
                        submitButtonRef.current.click();
                    }} isLoading={checkUserLoading} buttonName='Continue' />
                </div>
            </div>
        </Form>
    </div>
}


const LoginWithWhatsappButton = ({ loading = false }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleOtplessData = (otplessUser) => {
        dispatch(loginWithoutOtp({ waId: otplessUser.token, navigate, formName: otpless_login_formname }));
    }

    useEffect(() => {
        initOTPless(handleOtplessData);
        return () => {
            const otplessScript = document.getElementById("otplessIdScript");
            if (otplessScript) document.body.removeChild(otplessScript);
            const otpless_floating_button = document.getElementById("otpless-floating-button");
            if (otpless_floating_button) document.body.removeChild(otpless_floating_button);
        };
    }, []);

    return <button id='otpless' data-custom="true" style={{ width: '100%', height: isMobileView ? '4.5rem' : '3.5rem' }} className={'border flex items-center justify-center bg-scogoorange leading-6  text-white rounded-full gap-1 cursor-pointer'} >
        {loading && (
            <div className={`absolute`}>
                <Loader color={'#FFF'} size='15' speed='1' thickness='2' />
            </div>
        )}

        <div style={{ fontSize: '1.3rem' }} className={`${loading ? 'invisible' : ''} font-normal`}>
            Login With WhatsApp
        </div>
        {!loading && <img src={process.env.PUBLIC_URL + './img/whatapp_png.png'} className={'w-10 h-10'} alt={'Login With Whatsapp'} />}
    </button>
};

export const FormLabel = ({ text }) => {
    return <p className='text-center text-scogoblack font-medium' style={{ fontSize: '2.3rem' }}>{text}</p>
}

export const ForgotPasswordLabel = ({ text = 'Forgot Password?' }) => {
    const navigate = useNavigate();
    const onForgotClick = () => {
        navigate('/forgot-password')
    }
    return <div className='w-full flex justify-end pt-2'>
        <span onClick={onForgotClick} className=' text-font11 text-scogoorange cursor-pointer '>{text}</span>
    </div>
}