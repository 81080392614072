export const SP_ONBOARDING_STATUS_REQUEST = 'SP_ONBOARDING_STATUS_REQUEST';
export const SP_ONBOARDING_STATUS_SUCCESS = 'SP_ONBOARDING_STATUS_SUCCESS';
export const SP_ONBOARDING_STATUS_FAILURE = 'SP_ONBOARDING_STATUS_FAILURE';

export const CHECK_DOMAIN_AVAILABILITY_REQUEST = 'CHECK_DOMAIN_AVAILABILITY_REQUEST';
export const CHECK_DOMAIN_AVAILABILITY_SUCCESS = 'CHECK_DOMAIN_AVAILABILITY_SUCCESS';
export const CHECK_DOMAIN_AVAILABILITY_FAILURE = 'CHECK_DOMAIN_AVAILABILITY_FAILURE';

export const SAVE_CUSTOMER_ONBOARDING_DETAILS_REQUEST = 'SAVE_CUSTOMER_ONBOARDING_DETAILS_REQUEST';
export const SAVE_CUSTOMER_ONBOARDING_DETAILS_SUCCESS = 'SAVE_CUSTOMER_ONBOARDING_DETAILS_SUCCESS';
export const SAVE_CUSTOMER_ONBOARDING_DETAILS_FAILURE = 'SAVE_CUSTOMER_ONBOARDING_DETAILS_FAILURE';

export const CREATE_CUSTOMER_DOMAIN_REQUEST = 'CREATE_CUSTOMER_DOMAIN_REQUEST';
export const CREATE_CUSTOMER_DOMAIN_SUCCESS = 'CREATE_CUSTOMER_DOMAIN_SUCCESS';
export const CREATE_CUSTOMER_DOMAIN_FAILURE = 'CREATE_CUSTOMER_DOMAIN_FAILURE';

export const UPDATE_NEW_USER_EMAIL_REQUEST = 'UPDATE_NEW_USER_EMAIL_REQUEST';
export const UPDATE_NEW_USER_EMAIL_SUCCESS = 'UPDATE_NEW_USER_EMAIL_SUCCESS';
export const UPDATE_NEW_USER_EMAIL_FAILURE = 'UPDATE_NEW_USER_EMAIL_FAILURE';

export const UPDATE_USER_PROFILE_DETAILS_REQUEST = 'UPDATE_USER_PROFILE_DETAILS_REQUEST';
export const UPDATE_USER_PROFILE_DETAILS_SUCCESS = 'UPDATE_USER_PROFILE_DETAILS_SUCCESS';
export const UPDATE_USER_PROFILE_DETAILS_FAILURE = 'UPDATE_USER_PROFILE_DETAILS_FAILURE';

export const UPDATE_USER_BUSSINESS_DETAILS_REQUEST = 'UPDATE_USER_BUSSINESS_DETAILS_REQUEST';
export const UPDATE_USER_BUSSINESS_DETAILS_SUCCESS = 'UPDATE_USER_BUSSINESS_DETAILS_SUCCESS';
export const UPDATE_USER_BUSSINESS_DETAILS_FAILURE = 'UPDATE_USER_BUSSINESS_DETAILS_FAILURE';

export const UPDATE_USER_BANK_DETAILS_REQUEST = 'UPDATE_USER_BANK_DETAILS_REQUEST';
export const UPDATE_USER_BANK_DETAILS_SUCCESS = 'UPDATE_USER_BANK_DETAILS_SUCCESS';
export const UPDATE_USER_BANK_DETAILS_FAILURE = 'UPDATE_USER_BANK_DETAILS_FAILURE';

export const ON_SKIP_BANK_DETAILS_REQUEST = 'ON_SKIP_BANK_DETAILS_REQUEST';
export const ON_SKIP_BANK_DETAILS_FAILURE = 'ON_SKIP_BANK_DETAILS_FAILURE';

export const UPDATE_USER_PROFILE_BUSSINESS_DETAILS_REQUEST = 'UPDATE_USER_PROFILE_BUSSINESS_DETAILS_REQUEST';
export const UPDATE_USER_PROFILE_BUSSINESS_DETAILS_SUCCESS = 'UPDATE_USER_PROFILE_BUSSINESS_DETAILS_SUCCESS';
export const UPDATE_USER_PROFILE_BUSSINESS_DETAILS_FAILURE = 'UPDATE_USER_PROFILE_BUSSINESS_DETAILS_FAILURE';

export const SAVE_FE_ONBOARDING_DETAILS_REQUEST = 'SAVE_FE_ONBOARDING_DETAILS_REQUEST';
export const SAVE_FE_ONBOARDING_DETAILS_SUCCESS = 'SAVE_FE_ONBOARDING_DETAILS_SUCCESS';
export const SAVE_FE_ONBOARDING_DETAILS_FAILURE = 'SAVE_FE_ONBOARDING_DETAILS_FAILURE';
