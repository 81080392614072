import { customer, user } from '../types';

export const getCustomerAddonsListAction = (customerId, dropdown = false) => ({
    type: customer.CUSTOMERS_ADDONS_LIST_REQUEST,
    payload: { customerId, dropdown },
});

export const getCustomerAddonsListDropDown = ({ customerId }) => ({
    type: customer.CUSTOMERS_ADDONS_DROPDOWN_LIST_REQUEST,
    payload: { customerId },
});

export const getCustomerTeams = ({ limit, page = 1, dropdown = false, count = false, projectId, agentsInfo, type, query, customerId, toAppendInternalTeams }) => ({
    type: customer.CUSTOMERS_TEAMS_LIST_REQUEST,
    payload: { limit, page, dropdown, count, projectId, agentsInfo, type, query, customerId, toAppendInternalTeams },
});

export const getCustomerTeamsByCustomerID = ({ limit, page = 1, dropdown = false, count = false, projectId, agentsInfo, type, query, customerId }) => ({
    type: customer.CUSTOMERS_TEAMS_BY_CUSTOMERID_LIST_REQUEST,
    payload: { limit, page, dropdown, count, projectId, agentsInfo, type, query, customerId },
});

export const getNextCustomerTeams = ({ customerId, limit = 100, page = 1, dropdown = false, count = false, type, query }) => ({
    type: customer.CUSTOMERS_NEXT_TEAMS_LIST_REQUEST,
    payload: { customerId, limit, page, dropdown, count, type, query },
});

export const getprojectListName = ({ customerId }) => ({
    type: customer.PROJECT_LIST_NAME_REQUEST,
    payload: { customerId },
});

export const inviteCustomersEndUsers = ({ form, selectedTab, formName }) => ({
    type: customer.INVITE_CUSTOMERS_ENDUSERS_REQUEST,
    payload: { form, selectedTab, formName },
});

export const getCustomersList = () => ({
    type: customer.CUSTOMERS_LIST_REQUEST,
    payload: {},
});

export const customerInviteCSPUser = ({ data, customerId, formName }) => {
    let form = new FormData();

    Object.keys(data).map((keys) => {
        return form.append(`user[0][${keys}]`, data[keys]);
    });
    form.append(`user[0][customer_id]`, customerId);

    return {
        type: user.CUSTOMER_SEND_SP_INVITES_REQUEST,
        payload: { form, customerId, formName },
    };
};

export const customerInviteAdminPMUser = ({ data, customerId, invitationRole, formName }) => {
    let role = -1;
    switch (invitationRole) {
        case 'ca':
            role = 14;
            break;
        case 'cpm':
            role = 15;
            break;
        case 'noc':
            role = 16;
            break;
        case 'agentadmin':
            role = 18;
            break;
        case 'agent':
            role = 19;
            break;
        default:
            role = -1;
            break;
    }
    let form = new FormData();
    Object.keys(data).map((keys) => {
        if (!['role'].includes(keys)) {
            return form.set(`${keys}`, data[keys]);
        }
    });
    form.set(`customer_id`, customerId);
    form.set(`user_type`, role);
    return {
        type: user.CUSTOMER_SEND_ADMIN_PM_INVITES_REQUEST,
        payload: { form, customerId, formName },
    };
};

export const selectUserDetails = ({ data, tabName }) => ({
    type: customer.SELECTED_USER_DETAILS,
    payload: { data, tabName },
});

export const selectTeamUserDetails = ({ data, tabName }) => ({
    type: customer.SELECTED_TEAM_USER_DETAILS,
    payload: { data, tabName },
});

export const cleanupForTeam = () => ({
    type: customer.CLEANUP_CUSTOMER_TEAMS,
    payload: {},
});

export const getCustomerVendorsData = ({ customer_id }) => ({
    type: customer.GET_CUSTOMER_VENDORS_LIST_REQUEST,
    payload: { customer_id },
});

export const clearDropDown = () => ({
    type: customer.CLEAR_DROPDOWN,
    payload: {},
});

export const getCustomerProfileDetails = (customerId) => ({
    type: customer.GET_CUSTOMER_PROFILE_DETAILS_REQUEST,
    payload: { customerId },
});

export const removeCustomerProfileDetailsFromStore = () => ({
    type: customer.REMOVE_CUSTOMER_PROFILE_DETAILS_FROM_STORE,
});

export const clearCustomerAddonsDropdownFromStore = (payload) => ({
    type: customer.CLEAR_CUSTOMERS_ADDONS_DROPDOWN_LIST,
    payload,
});

export const updateCustomerDetails = ({ formValues, formName }) => {
    let form = new FormData();
    form.append('customer_company_name', formValues.customer_company_name);
    form.append('customer_address_1', formValues.customer_address_1);
    form.append('customer_address_2', formValues.customer_address_2);
    form.append('customer_pincode', formValues.customer_pincode);
    form.append('customer_pan_card_number', formValues.customer_pan_card_number);
    if (Array.isArray(formValues.customer_pan_card_image) && formValues.customer_pan_card_image.length > 0) {
        form.append('customer_pan_card_image', formValues.customer_pan_card_image[0]);
    }
    if (formValues.customer_have_gst) {
        form.append('customer_have_gst', 1);
        form.append('customer_gst_no', formValues.customer_gst_no ? formValues.customer_gst_no : '');
        form.append('customer_gst_state', formValues.customer_gst_state ? formValues.customer_gst_state : '');
    } else {
        form.append('customer_have_gst', 0);
        form.append('customer_gst_no', '');
        form.append('customer_gst_state', '');
    }
    form.append('payment_terms', formValues.payment_terms);
    return {
        type: customer.UPDATE_CUSTOMER_PROFILE_DETAILS_REQUEST,
        payload: { form: formValues, formName },
    };
};

export const uploadCustomerLogo = ({ logo }) => {
    let form = new FormData();
    if (logo.length > 0) {
        form.set('customer_logo', logo[0]);
    }
    return {
        type: customer.UPLOAD_CUSTOMER_LOGO_REQUEST,
        payload: { form },
    };
};

export const removeProjectListNameFromStore = (payload) => ({
    type: customer.REMOVE_PROJECT_LIST_NAME_FROM_STORE,
    payload,
});

export const clearVedorDataFromStore = () => ({
    type: customer.CLEAR_VENDORS_DATA_FROM_STORE,
});
