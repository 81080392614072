import React from 'react';
import IconToolTip from '../../../common/IconToolTip';

export default function StatusLabel(props) {
    const { content, color, shownNobgLabel, titleText = 'Status', hoverText, labelClass = 'bg-white' } = props;

    const lookUp = {
        true: `text-font09 px-1 rounded-xl px-4 ${labelClass}`,
        undefined: ` text-font10  px-p-3 rounded-3px  border border-l-4 border-${color} ${labelClass}`,
    };

    return (
        <>
            <div className='flex flex-wrap gap-1 items-center'>
                <IconToolTip title={`${titleText}: ${hoverText || content}`}>
                    <span className={`max-w-9rem truncate  py-p-2 items-center  mr-2 mb-1 text-${color} capitalize  ${lookUp[shownNobgLabel]} `}>{content}</span>
                </IconToolTip>
            </div>
        </>
    );
}
