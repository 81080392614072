import { teams } from '../types';

const INITIAL_STATE = {
    openedTab : '',
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case teams.SET_OPENED_TAB:
            return {
                ...state,
                openedTab : action.payload.openedTab
            }

        case teams.IS_SHOW_ADD_TEAM:
            return {
                ...state,
                isShowAddTeam : action.payload.isShowAddTeam,
                teamsMainDiv : action.payload.teamsMainDiv,
                isShowUserDetails : action.payload.isShowUserDetails,
            }

        default:
            return state
    }
}
