export const isEditProjectAllowed = (frontend_controller) => {
    return frontend_controller['EditProject'] === 1;
};

export const isUserChangesAllowed = (frontend_controller) => {
    return frontend_controller['user_change'] === 1;
};

export const isPayPoAllowed = (frontend_controller) => {
    return frontend_controller['PayPO'] === 1;
};

export const isDeletePoAllowed = (frontend_controller) => {
    return frontend_controller['deletePo'] === 1;
};

export const isViewPoAllowed = (frontend_controller) => {
    return frontend_controller['viewPO'] === 1;
};

export const isViewWithdrawlsAllowed = (frontend_controller) => {
    return frontend_controller['ViewWithdrawls'] === 1;
};

export const isViewCLIncentiveAllowed = (frontend_controller) => {
    return frontend_controller['ViewCLIncentive'] === 1;
};

export const isApprovePaymentAllowed = (frontend_controller) => {
    return frontend_controller['PaymentApprovar'] === 1;
};

export const isDownloadStatementAllowed = (frontend_controller) => {
    return frontend_controller['downloadStatement'] === 1;
};

export const isRefreshBankOpenBalanceAllowed = (frontend_controller) => {
    return frontend_controller['RefreshBankOpenBalance'] === 1;
};

export const isEditAllowedInAdditonalPayament = (frontend_controller) => {
    return frontend_controller.EditPrice === 1;
};

export const isUserOnboarded = (status) => {
    return status >= 2.5;
};

export const isAssignAllowed = (frontend_controller) => {
    return frontend_controller.Assignment === 1;
};

export const isSignOffApproveAllowed = (frontend_controller) => {
    return frontend_controller.SignOff === 1;
};

export const isHelpAllowed = (frontend_controller) => {
    return frontend_controller['HelpAccess'] === 1;
};

export const isTicketAdditionAllowed = (frontend_controller) => {
    return frontend_controller['TicketAddition'] === 1;
};

export const isToShowCustomerPaymentDetails = (frontend_controller) => {
    return frontend_controller['CustomerPaymentDetails'] === 1;
};

export const isToShowCustomerBillingTab = (frontend_controller) => {
    return frontend_controller['CustomerBilling'] === 1;
}

export const isVerifyUserAllowed = (frontend_controller) => {
    return frontend_controller['verify_user'] === 1;
}

export const isToShowUserIncomeDetails = (frontend_controller) => {
    return frontend_controller['show_income_details'] === 1;
}

export const isDirectBankTransferAllowed = (frontend_controller) => {
    return frontend_controller['DirectBankTransfer'] === 1;
}

export const hasAdditionalPriceAccess = (frontend_controller) => {
    return frontend_controller['AdditionalPriceAccess'] === 1;
};

export const isEditChecklistAllowed = (frontend_controller) => {
    return frontend_controller['EditChecklist'] === 1;
}

export const isEditCategoryAllowed = (frontend_controller) => {
    return frontend_controller['EditCategory'] === 1;
}

export const isChangePlanAllowed = (frontend_controller) => {
    return frontend_controller['changePlan'] === 1;
}

export const isAddSlaAllowed = (frontend_controller) => {
    return frontend_controller['addSLA'] === 1;
}

export const isDownloadOfflineTransactionAllowed = (frontend_controller) => {
    return frontend_controller['DownloadOfflineTransaction'] === 1;
}

export const isPancardLinkUpdateAllowed = (frontend_controller) => {
    return frontend_controller['PancardLinkUpdate'] === 1;
}

