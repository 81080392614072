import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { removePOApprover } from '../../../actions/tickets';
import { actualValueForDropdown, validateStringValue } from '../../../utils/common';
import Input from '../../../common/Form/Input';
import Select from '../../../common/Form/Select';
import StatusLabel from '../../Payments/reusableComponents/StatusLabel';

const removePoApprover = 'removePoApprover';

export const ApproverItem = ({ field, index, ticketId, approversArrayName, onRemove }) => {
    const { customerTeamsDropDown, customerTeamsLoading } = useSelector((state) => state.customer);
    const { loggedUser } = useSelector((state) => state.auth);

    const { formButtonLoading } = useSelector((state) => state.loading);
    const dispatch = useDispatch();

    const { watch } = useFormContext();

    const team = watch(`${approversArrayName}[${index}].team`);
    const email = watch(`${approversArrayName}[${index}].email`);

    const removeUser = (userId) => {
        dispatch(removePOApprover({ teamId: actualValueForDropdown(field.team), userId, ticketId, updateReducer: true, formName: 'removePoApprover' }));
    };

    const getTeamColumn = ({ isNew }, idx) => {
        if (isNew && email) return <></>;
        if (isNew) {
            return (
                <Select
                    padding='px-0 py-3'
                    name={`${approversArrayName}[${idx}].team`}
                    options={customerTeamsDropDown}
                    isLoading={customerTeamsLoading}
                    className={'w-full'}

                />
            );
        }
        if (!isNew && field?.teamName) {
            return (
                <span
                    className={`max-w-max truncate text-font10 px-p-3 py-p-2 rounded-3px mr-2 mb-1 bg-white text-scogo15 border border-scogo15 flex gap-2 items-center`}
                >
                    {validateStringValue(field?.teamName)}
                </span>
            );
        }
    };

    const getEmailColumn = ({ isNew }, idx) => {
        if (isNew && team) return <></>;

        if (isNew) {
            return <Input name={`${approversArrayName}[${idx}].email`} type={'text'} className='w-full' padding='px-0 py-1' placeholder='Email/Mobile' />;
        }

        if (Array.isArray(field?.users)) {
            return (
                <div className='flex flex-wrap'>
                    {field.users.map((user) => {
                        return (
                            <span
                                className={`max-w-max truncate text-font10 px-p-3 py-p-2 rounded-3px mr-2 mb-1 bg-white text-scogo15 border border-scogo15 flex gap-2 items-center`}
                            >
                                {`${validateStringValue(user.first_name)} ${validateStringValue(user.last_name)}`}
                                {!field?.closed && loggedUser.id !== user.id && (
                                    <span
                                        className={`material-icons text-lg cursor-pointer ${formButtonLoading[removePoApprover] ? 'animate-spin' : ''}`}
                                        onClick={() => removeUser(index)}
                                    >
                                        {formButtonLoading[removePoApprover] ? 'refresh' : 'close'}
                                    </span>
                                )}
                            </span>
                        );
                    })}
                </div>
            );
        }
        return <></>;
    };

    return (
        <>
            <div key={field.id} className='flex items-center gap-4 w-full border-b border-scogoddd hover:bg-scogof5 text-font11'>
                <div className='w-2/12 pl-3'>{index + 1}</div>
                <div className='w-4/12'>{getTeamColumn(field, index)}</div>
                <div className='w-3/12'>{getEmailColumn(field, index)}</div>
                <div className='w-2/12 '>{<StatusLabel content={`${field?.closed ? 'Approved' : 'Pending'}`} color={`${field?.closed ? 'scogobgsky' : 'scogoAmber'}`} />}</div>
                <div className='w-1/12 '>
                    <span
                        onClick={() => {
                            onRemove(field, index);
                        }}
                        className={`material-icons text-3xl cursor-pointer ${field.isNew ? ' text-scogoclosed' : 'text-gray'}`}
                    >
                        delete
                    </span>
                </div>
            </div>
        </>
    );
};
