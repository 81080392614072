import { takeEvery, call, put } from 'redux-saga/effects';
import { admin, loading, modal, toast } from '../types';
import lambdas from '../lambdas/apis';
import api from '../apis';
import microservices from '../lambdas/microservices';

export function* listAllState(action) {
    const { dropdown = false, stateIds, userterritories = false, role, allowAll = false, userType } = action.payload;

    try {
        yield put({ type: loading.SET_ALL_STATE_LOADING, payload: true });
        const response = yield call(lambdas.locations.states.listAllState, {}, {}, { state_id: stateIds, userterritories, role, userType });
        if (response.data.code === 200) {
            let statename = response.data.data;
            if (dropdown) {
                statename = [];
                response.data.data.map((item) => {
                    return statename.push({
                        key: `state_${item.state_id}`,
                        value: item.state_id,
                        label: item.state_name,
                    });
                });
            }
            if (allowAll) {
                statename.push({
                    key: 'all',
                    value: 'all',
                    text: 'All',
                });
            }
            yield put({ type: admin.LIST_ALL_STATE_SUCCESS, payload: statename });
        } else {
            yield put({ type: admin.LIST_ALL_STATE_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: admin.LIST_ALL_STATE_FAILURE, payload: error });
    }
    yield put({ type: loading.SET_ALL_STATE_LOADING, payload: false });
}

export function* listAllCity(action) {
    const { dropdown = false, userterritories = false, limit, offset, role, userType } = action.payload;
    try {
        yield put({ type: loading.SET_ALL_CITY_LOADING, payload: true });
        const response = yield call(lambdas.locations.cities.listAllCity, {}, {}, { limit, offset, userterritories, role, userType });
        if (response.data.code === 200) {
            let cityList = response.data.data;
            if (dropdown) {
                cityList = [];
                response.data.data.map((item) => {
                    return cityList.push({
                        key: `cities_${item.city_id}`,
                        value: item.city_id,
                        label: item.city_name,
                        city: item,
                    });
                });
            }
            yield put({ type: admin.LIST_ALL_CITY_SUCCESS, payload: cityList });
        } else {
            yield put({ type: admin.LIST_ALL_CITY_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: admin.LIST_ALL_CITY_FAILURE, payload: error });
    }
    yield put({ type: loading.SET_ALL_CITY_LOADING, payload: false });
}

export function* getAllStateCities(action) {
    const { stateId } = action.payload;
    try {
        yield put({ type: loading.SET_ALL_STATES_CITY_LOADING, payload: true });
        const response = yield call(api.cities.getStateCities, {}, { stateId }, {});
        if (response.data.code === 200) {
            let cityList = response.data.data;
            cityList = [];
            response.data.data.city_list.map((item) => {
                return cityList.push({
                    key: `cities_${item.city_id}`,
                    value: item.city_id,
                    label: item.city_name,
                    city: item,
                });
            });

            yield put({ type: admin.GET_ALL_STATE_CITIES_SUCCESS, payload: cityList });
        } else {
            yield put({ type: admin.GET_ALL_STATE_CITIES_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: admin.GET_ALL_STATE_CITIES_FAILURE, payload: error });
    }
    yield put({ type: loading.SET_ALL_STATES_CITY_LOADING, payload: false });
}

export function* addNewPincode(action) {
    const { data, formName, onSuccess } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.locations.pincodes.addPincode, {}, {}, {}, data);
        if (response.data.code === 201) {
            yield put({ type: modal.CLOSE_MODAL });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Pincode Added Successfully!!!' } });
            yield put({ type: admin.ADD_NEW_PINCODE_SUCCESS, payload: response.data.data });
            onSuccess(response.data.data.pincode);
        } else {
            yield put({
                type: toast.SEND_TOAST,
                payload: { status: 'danger', message: response.data.data.message ? response.data.data.message : 'Something went wrong' },
            });
            yield put({ type: admin.ADD_NEW_PINCODE_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: admin.ADD_NEW_PINCODE_FAILURE, payload: error });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* listAllDistrict(action) {
    const { stateId, cityId, dropdown = true } = action.payload;
    try {
        yield put({ type: loading.SET_ALL_DISTRICT_LOADING, payload: true });
        const response = yield call(microservices.admin.listAllDistrict, {}, {}, { stateId, cityId, dropdown });
        if (response.data.code === 200) {
            let districtList = response.data.data;
            if (dropdown) {
                districtList = [];
                response.data.data.map((item) => {
                    return districtList.push({
                        key: `district_${item.id}`,
                        value: item.id,
                        label: item.name,
                    });
                });
            }
            yield put({ type: admin.LIST_ALL_DISTRICT_SUCCESS, payload: districtList });
        } else {
            yield put({ type: admin.LIST_ALL_DISTRICT_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: admin.LIST_ALL_DISTRICT_FAILURE, payload: error });
    }
    yield put({ type: loading.SET_ALL_DISTRICT_LOADING, payload: false });
}

export function* listAllLocalities(action) {
    const { stateId, cityId, districtId, dropdown = true } = action.payload;
    try {
        yield put({ type: loading.SET_ALL_LOCALITY_LOADING, payload: true });
        const response = yield call(microservices.admin.listAllLocalities, {}, {}, { stateId, cityId, districtId, dropdown });
        if (response.data.code === 200) {
            let localityList = response.data.data;
            if (dropdown) {
                localityList = [];
                response.data.data.map((item) => {
                    return localityList.push({
                        key: `locality_${item.id}`,
                        value: item.id,
                        label: item.name,
                    });
                });
            }
            yield put({ type: admin.LIST_ALL_LOCALITY_SUCCESS, payload: localityList });
        } else {
            yield put({ type: admin.LIST_ALL_LOCALITY_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: admin.LIST_ALL_LOCALITY_FAILURE, payload: error });
    }
    yield put({ type: loading.SET_ALL_LOCALITY_LOADING, payload: false });
}

export default function* locationsSaga() {
    yield takeEvery(admin.LIST_ALL_STATE_REQUEST, listAllState);
    yield takeEvery(admin.LIST_ALL_CITY_REQUEST, listAllCity);
    yield takeEvery(admin.GET_ALL_STATE_CITIES_REQUEST, getAllStateCities);
    yield takeEvery(admin.ADD_NEW_PINCODE_REQUEST, addNewPincode);
    yield takeEvery(admin.LIST_ALL_DISTRICT_REQUEST, listAllDistrict);
    yield takeEvery(admin.LIST_ALL_LOCALITY_REQUEST, listAllLocalities);
}
