import React from 'react';
import Loader from './Loader';
import IconToolTip from './IconToolTip';

const Buttons = ({
    btnimgClass,
    type,
    buttonIcon,
    handleClick,
    Btnalt,
    children,
    buttonClass,
    onClick,
    loading,
    disabled = '',
    iconComponent,
    loaderColor,
    disabledClass = 'text-black flex items-center justify-center rounded-6px shadow-primaryBtnShadow px-4 py-2 text-xl gap-1 font-medium relative cursor-not-allowed border-scogoddd bg-scogoddd',
    loaderSize = '15',
    onHoverTitle = '',
    style = {}
}) => {
    if (!disabled) disabled = loading;
    const button = <button className={disabled ? disabledClass : buttonClass} type={type} onClick={onClick} disabled={disabled} style={style}>
        {loading && (
            <div className='absolute'>
                <Loader color={loaderColor ? loaderColor : '#FFF'} size={loaderSize} speed='1' thickness='2' />
            </div>
        )}

        {buttonIcon && <img src={buttonIcon} className={btnimgClass} alt={Btnalt} />}
        {iconComponent && iconComponent}
        <div className={loading ? 'invisible' : ''}>{children}</div>
    </button>;

    if (onHoverTitle?.length > 0) {
        return <IconToolTip title={onHoverTitle}>
            {button}
        </IconToolTip>
    }

    return button;
};

export default Buttons;
