import { Skeleton } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

function Layout(props) {
    const { uiConfig } = useSelector(state => state.user);
    const { isUIConfigLoading } = useSelector(state => state.loading);
    const { pathname } = useLocation();
    const getLogo = () => {
        if (uiConfig) return uiConfig.logoUrl
        return process.env.PUBLIC_URL + '/img/Scogo_logo-with-tagline.png';
    }
    const getText = () => {
        if (uiConfig) return uiConfig.header;
        return <img src={getLogo()} alt='' className='mx-auto w-96 ' />
    }

    useEffect(() => {
        // fix for otpless popup appearing on other pages 
        if (pathname !== '/login') {
            const mutationCallback = (mutationsList, observer) => {
                for (let mutation of mutationsList) {
                    if (mutation.type === 'childList') {
                        // Check if the added nodes contain the element with ID "otpless-floating-button"
                        const addedNodes = Array.from(mutation.addedNodes);
                        const otpless_floating_button = 'otpless-floating-button';
                        const addedElement = addedNodes.find(node => node.id === otpless_floating_button);
                        if (addedElement) {

                            addedElement.parentNode.removeChild(addedElement);
                        }
                    }
                }
            };
            const observer = new MutationObserver(mutationCallback);
            // Start observing changes in the target node and its subtree
            observer.observe(document.body, { subtree: true, childList: true });
            return () => {
                observer.disconnect();
            };
        }
    }, []);


    return (
        <div className='h-screen bg-white relative flex flex-col w-full outline-none focus:outline-none overflow-hidden'>
            <div className='mx-auto h-full flex pt-[8rem] justify-center lg:items-center lg:pt-0'>
                <div className='bg-white lg:flex  lg:[h-30rem] md:[h-full] lg:w-[90rem] md:w-full' >
                    < div className={`lg:w-1/2 md:w-full text-center px-2 md:py-16 py-4 relative`}>{
                        isUIConfigLoading ?
                            <div className='flex flex-col gap-6 items-center'>
                                <Skeleton variant='rectangular' animation='wave' height='6rem' width='20rem' />
                                <Skeleton variant='rectangular' animation='wave' height='4rem' width='30rem' />
                                <Skeleton variant='rectangular' animation='wave' height='5rem' width='35rem' />
                            </div>
                            : <div className=' '>
                                <p style={{ fontSize: '3rem' }} className='text-scogo15 font-normal '>Welcome to</p>
                                <p className='text-font24 text-scogoprimary font-medium'>{getText()}</p>
                                <p className='text-font18 text-scogo15 font-medium mt-4 lg:text-font30 '>Let's re-invent Technology Service</p>
                                <p style={{ fontSize: '1.5rem' }} className='text-scogogray font-normal  mx-6 px-4'>
                                    Join the biggest Network of Service Partners across India and grow your business revenue
                                </p>
                            </div>
                    }
                        <div className='lg:block hidden bg-scogoddd opacity-50 absolute w-px h-full top-0 right-0'></div>
                    </div>
                    <div className='lg:w-1/2 md:w-full flex items-center justify-center md:px-20 px-2'>
                        {isUIConfigLoading ?
                            <Skeleton variant='rectangular' animation='wave' height='8rem' width='30rem' className='mx-auto' />
                            : <></>}
                        {props.children}
                    </div>
                </div>
            </div >
        </div >
    );
}

export default Layout;
