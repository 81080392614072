import React from 'react';
import { useDispatch } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import Form from '../../../common/Form';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import TextArea from '../../../common/Form/TextArea';
import { holdTicket } from '../../../actions/ticketExecution';

const formName = 'HoldForm';

export default function HoldTicket({ ticket, isBulk, ticketIds }) {
    const { formButtonLoading } = useSelector((state) => state.loading);
    const defaultValues = { escalation_message: '' };

    const dispatch = useDispatch();

    const methods = useForm({ defaultValues, mode: 'all' });

    const submit = (formValues) => {
        let payload = {
            escalation_message: formValues?.escalation_message,
            is_revisit_required: 0,
            additional_comment: '',
        };
        if (isBulk) payload.tickets = [...ticketIds];
        else payload.ticket_id = ticket.ticket_id;

        dispatch(holdTicket({ data: payload, refresh: false, formName, refreshTicketTable: true }));
    };

    return (
        <>
            <Form
                methods={methods}
                onSubmit={submit}
                formName={formName}
                buttonPostion={'justify-end'}
                submitButtonText={'Submit'}
                onCancel={() => dispatch(closeModalAction())}
                showSubmit
                submitContainerClassName='flex gap-2 justify-end py-4 px-4'
                isLoading={formButtonLoading[formName]}
            >
                <div className='px-2'>
                    <TextArea name='escalation_message' className='w-full' label='Report Issue' height='6rem' required />
                </div>
            </Form>
        </>
    );
}
