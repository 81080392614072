import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import { downloadZipPo } from '../../../actions/payments';
import { getCustomersList } from '../../../actions/customer';
import { getCustomerIdOfLoginUser } from '../../../utils/utils';
import { actualValueForDropdown } from '../../../utils/common';
import { GetPaymentsListLogic } from '../PaymentController';
import Form from '../../../common/Form';
import { useForm } from 'react-hook-form';
import MultipleEmails from '../../../common/Form/MultipleEmail';
import Select from '../../../common/Form/Select';

const DownloadZipPo = (props) => {
    const { loggedUser } = useSelector((state) => state.auth);
    const formName = 'exportCsvPayments';
    const dispatch = useDispatch();
    const { customers } = useSelector((state) => state.customer);
    const { isCustomerListLoading, formButtonLoading } = useSelector((state) => state.loading);
    const isToShowCustomerInput = GetPaymentsListLogic('isToShowCustomer');

    const defaultValues = {
        emails: loggedUser?.email ? [loggedUser?.email] : [],
    };

    const onSubmit = (formValues) => {
        let customerId = getCustomerIdOfLoginUser(loggedUser);
        if (isToShowCustomerInput) customerId = actualValueForDropdown(formValues.fk_customer_id);
        let payload = {
            formName,
            emails: formValues.emails,
            customerId,
        };
        dispatch(downloadZipPo(payload));
    };

    const closeModal = () => {
        dispatch(closeModalAction());
    };

    useEffect(() => {
        if (isToShowCustomerInput) dispatch(getCustomersList());
    }, [dispatch, isToShowCustomerInput]);

    const methods = useForm({ defaultValues, mode: 'all' });

    return (
        <>
            <Form
                className='w-full'
                onSubmit={onSubmit}
                onCancel={closeModal}
                defaultValues={defaultValues}
                submitButtonText='Submit'
                showSubmit
                submitContainerClassName='flex gap-2 justify-end py-4 px-4'
                isLoading={formButtonLoading[formName]}
                methods={methods}
            >
                <div className='py-2 px-3 grid grid-cols-1 rows-fr grid-flow-row-dense'>
                    {isToShowCustomerInput && (
                        <Select
                            label='Customer'
                            required
                            name='fk_customer_id'
                            className={'w-full'}
                            options={customers}
                            isLoading={isCustomerListLoading}
                        />
                    )}
                    <MultipleEmails name='emails' label='Emails' required />
                </div>
            </Form>
        </>
    );
};

export default DownloadZipPo;
