import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { selectIsLocalAudioEnabled, selectIsLocalScreenShared, selectIsLocalVideoEnabled, selectIsSomeoneScreenSharing, selectLocalPeer, selectPeerScreenSharing, selectPeers, selectPeersScreenSharing, selectRoomState, useHMSActions, useHMSNotifications, useHMSStore } from '@100mslive/react-sdk';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getRoomToken } from '../../layout/header/CallQueue';
import { useDispatch } from 'react-redux';
import Peer from './components/Peer';
import { ScreenVideo } from './components/ScreenVideo';
import { handleNotifications, roomStateEnum } from './components/constants';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import StopScreenShareIcon from '@mui/icons-material/StopScreenShare';
import IconToolTip from '../../common/IconToolTip';
import CallEndIcon from '@mui/icons-material/CallEnd';
import { ParticipantsPopover } from './components/ParticipantsPopover';
import { SettingsPopover } from './components/SettingsPopover';
import Skeleton from '@mui/material/Skeleton';
import { getConversationDetails, getConversationMessages, getTicketChats, leaveRoomAction } from '../../actions/conversations';
import { InviteUsersPopover } from './components/InviteUsersPopover';
import { disableRoom, getUserCallStatusAction } from '../../actions/users';
import Loader from '../../common/Loader';
import { isMobileView } from '../auth/components/NewScogoButton';
import RenderChatMessages from '../chat/ChatMessage';
import MessageIcon from '@mui/icons-material/Message';
import { ShareLinkPopover } from './components/ShareLinkPopover';
import { sendDataThroughWebsocket } from '../../actions/websocket';
import { websocketEvents } from '../../utils/websocket';
import { ChatPopOver } from './components/ChatPopOver';
import { Ringing } from './components/Ringing';
import { BigScreenShare } from './components/BigScreenShare';
import JoinNow from './components/JoinNow';

const Videomeet = () => {

    document.title = "Scogo Connect";
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const hmsActions = useHMSActions();
    const notification = useHMSNotifications();
    const { loggedUser } = useSelector((state) => state.auth);
    const { room_id } = useParams();
    const userName = `${loggedUser?.first_name || ''} ${loggedUser?.last_name || ''}`;
    const peers = useHMSStore(selectPeers);
    const localPeer = useHMSStore(selectLocalPeer);
    const audioEnabled = useHMSStore(selectIsLocalAudioEnabled);
    const videoEnabled = useHMSStore(selectIsLocalVideoEnabled);
    const isSharingScreen = useHMSStore(selectIsLocalScreenShared);
    const peersWithScreenOn = useHMSStore(selectPeersScreenSharing);
    const screenshareOn = useHMSStore(selectIsSomeoneScreenSharing);
    const presenter = useHMSStore(selectPeerScreenSharing);
    const roomState = useHMSStore(selectRoomState); // [Disconnected,Connecting,Connected,Disconnecting]
    const openedConversationId = new URLSearchParams(location.search).get('openedConversationId');
    const { conversationDetails } = useSelector((state) => state.conversations);
    const { userCallStatus } = useSelector((state) => state.user);
    const conversation = conversationDetails[openedConversationId];
    const users = conversation?.users.filter(e => e.userId !== loggedUser.id) || [];
    let conversationId = conversation?.conversationId || ""; //ticketId
    const pageLoading = useRef(true);
    const [showChat, setShowChat] = useState(false);
    const [isRinging, setIsRinging] = useState(false);
    const [duplicateScreenFound, setDuplicateScreenFound] = useState(false);
    const hasChatAccess = users?.length > 0;

    const blackIconClassname = 'hover:cursor-pointer hover:text-gray-600 transition-all';
    const redIconClassName = 'hover:cursor-pointer text-scogoclosed hover:text-gray-600 transition-all';

    const addProfilePhototoMetaData = useCallback(async () => {
        const newMetadata = { profile_picture: loggedUser?.profile_picture, email: loggedUser?.email, timestamp: Date.now() };
        await hmsActions.changeMetadata(newMetadata);
    }, [hmsActions, loggedUser?.profile_picture])

    useEffect(() => {
        if (!localPeer?.metadata?.includes('profile_picture')) {//prevent circular re render
            addProfilePhototoMetaData();
        }
    }, [localPeer, loggedUser]);

    useEffect(() => {
        const localPeerMetaData = localPeer?.metadata ? JSON.parse(localPeer.metadata) : {};
        const localEmail = localPeerMetaData?.email;
        const duplicatePeer = peers.find((peer) => !peer?.isLocal && peer?.metadata?.includes(localEmail));
        if (duplicatePeer) {
            const duplicatePeerJson = duplicatePeer?.metadata ? JSON.parse(duplicatePeer.metadata) : {};
            if (localPeerMetaData?.timestamp < duplicatePeerJson?.timestamp) {
                setDuplicateScreenFound(true);
                hmsActions.leave();
            }
            else {
                hmsActions.removePeer(duplicatePeer.id, "Duplicate");
            }
        }
    }, [peers, localPeer])

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            e.preventDefault();
            const confirmationMessage = 'It is mandatory to end or leave the call before closing the tab';
            e.returnValue = confirmationMessage;
            return confirmationMessage;
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        if (roomState === roomStateEnum.Disconnected) {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        }

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [roomState]);


    useEffect(() => {
        if (openedConversationId) {
            dispatch(getConversationDetails({ _id: openedConversationId, isScogoConnect: true }));
        }
    }, []);

    useEffect(() => {
        let payload = { limit: 20, offset: 0 };
        dispatch(getTicketChats(payload));
    }, []);

    useEffect(() => {
        dispatch(getConversationMessages({ _id: openedConversationId }));
    }, [])

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(getUserCallStatusAction({ roomId: room_id, status: [1, 3] }));
        }, 4000);
        return () => {
            clearTimeout(timer);
        }
    }, [])

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsRinging(false);
        }, 40000);
        return () => {
            clearTimeout(timer);
        }
    }, [])

    useEffect(() => {
        if (peers?.length > 1) {
            setIsRinging(false);
        }
        else if (userCallStatus?.length > 0) {
            setIsRinging(true);
        }
    }, [peers, userCallStatus])

    const handleScreenShare = async () => {
        try {
            if (isSharingScreen) {
                hmsActions.setScreenShareEnabled(false);
                return;
            }
            await hmsActions.setScreenShareEnabled(true, {
                videoOnly: true
            });
        } catch (error) {
            console.log(error);
        }
    }

    const sendEndRoomEvent = () => {
        if (conversationId.includes('single')) { // non ticket calls
            dispatch(sendDataThroughWebsocket({ action: websocketEvents.chat.holdOrEndcall, data: { endCall: true, room_id: room_id } }));
        }
        else {
            dispatch(sendDataThroughWebsocket({ action: websocketEvents.chat.holdOrEndcall, data: { endCall: true, room_id: room_id, ticketId: conversationId } }));
        }
    }

    const sendLeaveRoomEvent = () => {
        dispatch(sendDataThroughWebsocket({ action: websocketEvents.chat.holdOrEndcall, data: { leaveCall: true, leavingUserId: loggedUser?.id, room_id: room_id } }));
    }

    const handleLeave = () => {
        if (peers?.length <= 2 && conversationId) {
            if (conversationId.includes('single')) {
                dispatch(disableRoom({ userId: loggedUser?.id, conversationId: openedConversationId, roomId: room_id, onSuccess: sendEndRoomEvent }));
            }
            else {
                dispatch(disableRoom({ userId: loggedUser?.id, ticketId: conversationId, conversationId: openedConversationId, roomId: room_id, onSuccess: sendEndRoomEvent }));
            }
            hmsActions.endRoom(false, 'Less than or Equal to 2 people in Room');
        }
        else {
            if (conversationId.includes('single')) {
                dispatch(leaveRoomAction({ userId: loggedUser?.id, room_id: room_id, openedConversationId: openedConversationId, onSuccess: sendLeaveRoomEvent }));
            }
            dispatch(leaveRoomAction({ userId: loggedUser?.id, room_id: room_id, ticketId: conversationId, openedConversationId: openedConversationId, onSuccess: sendLeaveRoomEvent }));
            hmsActions.leave();
        }
    }

    const handleMuteUnmute = async () => {
        await hmsActions.setLocalAudioEnabled(!audioEnabled);
    }

    const handleVideoToggle = async () => {
        await hmsActions.setLocalVideoEnabled(!videoEnabled);
    }

    const handleChat = () => {
        setShowChat(prev => !prev);
    }

    const getToken = async ({ room_id, user_id, role = 'speaker', userName = 'Anonymous' }) => {
        return getRoomToken({ role, room_id, user_id })
            .then((token) => {
                return token;
            })
            .catch((error) => {
                console.log('Token API Error', error);
            });
    };

    const joinRoom = async ({ hmsActions, userName = 'Anonymous' }) => {
        try {
            const token = await getToken({ room_id, user_id: loggedUser?.id })
            hmsActions.join({
                userName: userName,
                authToken: token,
                settings: {
                    isAudioMuted: true,
                    isVideoMuted: true,
                },
                rememberDeviceSelection: true
            });
            pageLoading.current = false;
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (!isMobileView) {
            if (loggedUser?.id) {
                joinRoom({ hmsActions, userName });
            }
            else {
                navigate('/login');
            }
        }
        return () => {
            hmsActions.leave();
        }
    }, []);

    const handleJoin = () => {
        if (loggedUser?.id) {
            const url = new URL(window.location.href);
            url.searchParams.set('enable', 'true');
            navigate(`${url.pathname}${url.search}`, { replace: true });
            joinRoom({ hmsActions, userName });
        }
        else {
            navigate('/login');
        }
    }

    useEffect(() => {
        if (!notification) {
            return;
        }
        handleNotifications(notification, dispatch, hmsActions);
    }, [notification, dispatch]);

    if (duplicateScreenFound) {
        return <div className='w-full h-full flex justify-center items-center'>
            <h1 className=' text-font30'>Meeting active on another screen</h1>
        </div>
    }

    if (roomState === roomStateEnum.Disconnected && pageLoading.current === false) {
        return <div className='w-full h-full flex justify-center items-center'>
            <h1 className=' text-font30'>This Meeting Has Ended</h1>
        </div>
    }

    if (roomState === roomStateEnum.Connecting) {
        return <div className='w-full h-full flex justify-center items-center'>
            <Loader size='40' />
        </div>
    }

    if (isMobileView && pageLoading.current === true) {
        return <div className='w-full h-full flex justify-center items-center'>
            <JoinNow loggedUser={loggedUser} userName={userName} handleJoin={handleJoin} />
        </div>
    }

    return (
        <div className='w-full h-full'>
            <div className='w-full h-[92%] flex'>
                {
                    screenshareOn && !isMobileView &&
                    <div className='h-full w-[70%]'>
                        <BigScreenShare presenter={presenter} />
                    </div>
                }
                {
                    (screenshareOn && showChat) ?
                        <></>
                        :
                        <div className={`w-${isMobileView ? 'full' : screenshareOn ? '[30%]' : showChat ? '[70%]' : 'full'} h-full flex justify-evenly items-center gap-y-3 gap-x-3 flex-wrap content-evenly overflow-y-scroll overflow-x-hidden`}>
                            {
                                peers.length > 0 && peers.map((peer) => {
                                    return <Peer key={peer.id} peer={peer} />
                                })
                            }
                            {
                                peersWithScreenOn.length > 0 && peersWithScreenOn.map((peer) => {
                                    return <ScreenVideo key={peer.id} peer={peer} />
                                })
                            }
                            {
                                isRinging && userCallStatus?.length > 0 && peers?.length === 1 && <Ringing userCallStatus={userCallStatus} />
                            }
                        </div>
                }
                {
                    showChat && hasChatAccess && //CHECK IF PART OF CHAT
                    <div className='h-full w-[40%]'>
                        <RenderChatMessages mainDivClass={`h-full`} showChatHeader={false} openedConversationId={openedConversationId} />
                    </div>
                }
            </div>
            {
                roomState === roomStateEnum.Connecting &&
                <div className='w-full h-[8%]'>
                    <Skeleton variant="rectangular" height={`100%`} />
                </div>
            }
            {
                roomState === roomStateEnum.Connected &&
                <div className='w-full h-[8%] flex justify-between items-center gap-8 pr-8 pl-8 bg-white'>
                    <div className='flex gap-8'>
                        {
                            videoEnabled ?
                                <IconToolTip title="Stop Video">
                                    <VideocamIcon sx={{ fontSize: 28 }} onClick={handleVideoToggle} className={blackIconClassname} />
                                </IconToolTip>
                                :
                                <IconToolTip title="Start Video">
                                    <VideocamOffIcon sx={{ fontSize: 28 }} onClick={handleVideoToggle} className={redIconClassName} />
                                </IconToolTip>
                        }
                        {
                            audioEnabled ?
                                <IconToolTip title="Stop Mic">
                                    <MicIcon sx={{ fontSize: 28 }} onClick={handleMuteUnmute} className={blackIconClassname} />
                                </IconToolTip>
                                :
                                <IconToolTip title="Start Mic">
                                    <MicOffIcon sx={{ fontSize: 28 }} onClick={handleMuteUnmute} className={redIconClassName} />
                                </IconToolTip>
                        }
                        {
                            !isMobileView &&
                            <>
                                {
                                    isSharingScreen ?
                                        <IconToolTip title="Stop Screen Share">
                                            <ScreenShareIcon sx={{ fontSize: 28 }} onClick={handleScreenShare} className={blackIconClassname} />
                                        </IconToolTip>
                                        :
                                        <IconToolTip title="Start Screen Share">
                                            <StopScreenShareIcon sx={{ fontSize: 28 }} onClick={handleScreenShare} className={redIconClassName} />
                                        </IconToolTip>
                                }
                            </>
                        }
                    </div>
                    <div className='flex gap-8 h-full items-center'>
                        <SettingsPopover />
                        <ShareLinkPopover />
                        {
                            hasChatAccess && !isMobileView &&
                            <IconToolTip title="Chat">
                                <MessageIcon sx={{ fontSize: 28 }} onClick={handleChat} className={blackIconClassname} />
                            </IconToolTip>
                        }
                        {
                            hasChatAccess && isMobileView &&
                            <ChatPopOver />
                        }
                        {
                            hasChatAccess &&
                            <InviteUsersPopover users={users} ticketId={conversationId} setIsRinging={setIsRinging} />
                        }
                        {
                            !isMobileView &&
                            <ParticipantsPopover />
                        }
                        <IconToolTip title={peers?.length <= 2 ? 'End' : 'Leave'}>
                            <CallEndIcon sx={{ fontSize: 28 }} onClick={handleLeave} className={redIconClassName} />
                        </IconToolTip>
                    </div>
                </div>
            }
        </div >
    )
}

export default Videomeet;