import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Form from '../../../common/Form';
import { closeModalAction } from '../../../actions/modal';
import Select from '../../../common/Form/Select';
import { addMigrationAssets } from '../../../actions/tickets';
import { getAllAssets, getAllSpareAssets } from '../../../actions/inventory';
import { CLEAR_ASSETS_AND_SPARES_LISTS } from '../../../types/inventory';
const formName = 'addMigrationAsset';

const AddMigrationAsset = ({ ticketId, migrateTo, warehouse, customerId, siteId }) => {

    const dispatch = useDispatch();
    useEffect(() => {
        switch (migrateTo) {
            case 'SITE': {
                // from warehouse to site
                if (warehouse > 0) {
                    dispatch(getAllSpareAssets({ warehouseId: warehouse, customerId, limit: 9999999, status: 'AVAILABLE' }));
                }
                // from site to site
                if (warehouse === 0 && siteId > 0) {
                    dispatch(getAllAssets({ customerId, siteId, dropdown: true, status: 'AVAILABLE', limit: 9999999 }));
                }
                break;
            }
            case 'WAREHOUSE': {
                // from warehouse to warehouse
                if (warehouse > 0) {
                    dispatch(getAllSpareAssets({ warehouseId: warehouse, customerId, limit: 9999999, status: 'AVAILABLE' }));
                }
                // from site to warehouse
                if (siteId > 0) {
                    dispatch(getAllAssets({ customerId, siteId, dropdown: true, status: 'AVAILABLE', limit: 9999999 }));
                }
                break;
            }
            default: {
                break;
            }
        }
        return () => dispatch({ type: CLEAR_ASSETS_AND_SPARES_LISTS });
    }, [dispatch, migrateTo, warehouse, customerId, siteId])


    const { assets, spareAssets } = useSelector((state) => state.inventory);
    const { formButtonLoading, assetsLoading, spareAssetLoading } = useSelector((state) => state.loading);


    let options = [];
    if (assets) {
        assets.forEach(asset => options.push({
            key: asset.id,
            value: asset.id,
            label: asset.serial_number
        }))
    } else if (spareAssets) {
        spareAssets.forEach(asset => options.push({
            key: asset.id,
            value: asset.id,
            label: asset.serial_number
        }));
    }

    const defaultValues = {
        assets: []
    };
    const closeModal = () => {
        dispatch(closeModalAction());
    };
    const submit = (form) => {
        if (form.assets.length > 0) {
            dispatch(addMigrationAssets({ ticketId, assets: form.assets.map(asset => asset.value), formName, onSuccess: closeModal }))
        }
    }

    const methods = useForm({ defaultValues, mode: 'all' });
    return (

        <Form
            className='w-full'
            onSubmit={(e) => {
                submit(e);
            }}
            methods={methods}
            onCancel={closeModal}
            isLoading={formButtonLoading[formName]}
            showSubmit
            submitContainerClassName='flex gap-2 justify-end py-4 px-8'
        >
            <div className='px-2'>
                <Select name='assets' isMulti label='Assets' options={options} isLoading={spareAssetLoading || assetsLoading} />
            </div>
        </Form>

    );
};

export default AddMigrationAsset;