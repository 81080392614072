import React, { useEffect, useRef, useState } from 'react'
import InputFiels from './InputFiels'
import IconToolTip from './IconToolTip'


const padNumber = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "", "0", ""]

function Dialpad({ onChangeHandler }) {

    const inputRef = useRef(null)
    const [lastNumer, setLastNumber] = useState("")
    const [number, setNumber] = useState("") 


    const onChange = (value) => {
        if (value?.length <= 10) {
            setLastNumber(value?.[value?.length - 1])
            setNumber(value)
            onChangeHandler(value)
        }
    } 

    useEffect(() => {
        inputRef.current?.focus()
    }, [number])

    return (
        <div className='relative mt-3 ' >
            <InputFiels
                inputRef={inputRef}
                type='number'
                inputClass={'loginput text-font18 tracking-widest border-b-1 flex items-center dialPad text-center'}
                errorClass={"justify-center"}
                inputDiv='text-gray-400 focus-within:text-gray-600 text-center  w-auto '
                value={number}
                onChange={(event) => {
                    onChange(event.target.value.trimStart())
                }
                }
                autoFocus
            />
            {number?.length > 0 && <div className='absolute right-0 top-[3%]' >
                <IconToolTip title="Clear" onClick={() => {
                    let num = number?.substring(0, number?.length - 1)
                    setNumber(num)
                    onChangeHandler(num)
                    setLastNumber("")
                }}>
                    <span className='text-scogoprimary hover:text-scogoorange cursor-pointer material-icons text-font18 mr-4'  >
                        backspace
                    </span>

                </IconToolTip>
            </div>}

            <div className='grid grid-cols-3 justify-items-center  gap-7 mt-7 w-[88%] m-auto' >
                {padNumber.map(n => {
                    return <>
                        {n?.length > 0 ? <p onClick={() => {
                            if (number.length < 10) {
                                onChangeHandler(number + n)
                                setNumber((prev) => {
                                    return prev + n
                                })
                            }
                        }} className={`text-font24 border border-gray-300 rounded-full p-3 px-7 hover:bg-slate-100 cursor-pointer ${lastNumer === n ? "dialPadNumber" : ""} `}>{n}</p>
                            : <p></p>}
                    </>

                })}
            </div>
        </div >
    )
}

export default Dialpad