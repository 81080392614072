import moment from 'moment';

export const validateFilterNumber = (values) => {
    let numbers = [];
    if (Array.isArray(values)) {
        values.forEach((value) => {
            value = Number(value);
            if (!isNaN(value)) numbers.push(value);
        });
    }
    return numbers.length > 0 ? numbers : undefined;
};

export const validateDate = (dateValue, { cutoffDate = true } = {}) => {
    // Only expects YYYY-MM-DD format (ISO)
    let timeStamp = Date.parse(dateValue);
    if (isNaN(timeStamp)) return;
    let date = new Date(timeStamp);
    if (cutoffDate) date = date < new Date() ? date : undefined;
    return date;
};

export const getDateWithISOFormat = (date) => {
    let isValidDate = (date) => date && moment(date).isValid();
    if (isValidDate(date)) return moment(date).format('YYYY-MM-DD');
    return null;
};

export const readFilterQueryParams = (filterSchema, query) => {
    let queryObject = {};
    let filterStatus = {};

    const getQuery = (key) => {
        return query.get(key);
    };

    Object.values(filterSchema).forEach((filterObject) => {
        const { queryParamKey, filterName } = filterObject;
        let currentQuery = {};

        if (typeof queryParamKey === 'object') {
            const { keyList } = queryParamKey;
            keyList.forEach((key) => {
                let queryValue = getQuery(key);
                if (queryValue) Object.assign(currentQuery, { [key]: queryValue });
            });
        } else {
            let values = getQuery(queryParamKey)?.split('^');
            if (Array.isArray(values) && values.length > 0) {
                currentQuery = { [queryParamKey]: values };
            }
        }

        if (filterObject.parse && typeof filterObject.parse === 'function') {
            currentQuery = filterObject.parse(currentQuery);
        }

        filterStatus = Object.assign(filterStatus, { [filterName]: Object.keys(currentQuery).length > 0 });
        Object.assign(queryObject, currentQuery);
    });
    return { queryObject, filterStatus };
};

export const transformQueryFilters = (filterSchema, queryObject) => {
    let filterPayload = {};
    Object.values(filterSchema).forEach(({ submitKey, queryParamKey }) => {
        let currentPayload = {};
        if (typeof queryParamKey === 'object') {
            let currentQuery = {};
            queryParamKey.keyList.forEach((key) => {
                if (queryObject[key]) {
                    Object.assign(currentQuery, { [key]: queryParamKey[queryObject] });
                }
            });
            if (Object.keys(currentQuery).length > 0) {
                currentPayload = submitKey(queryObject);
            }
        } else {
            if (typeof submitKey === 'function') currentPayload = submitKey(queryObject);
            else {
                let value = queryObject?.[queryParamKey];
                if (value) currentPayload = { [submitKey]: value };
            }
        }
        if (Object.keys(currentPayload).length > 0) Object.assign(filterPayload, currentPayload);
    });
    return filterPayload;
};

const getFilterQueryParams = (filterSchema, filterList) => {
    let filterSearchQueryParam = ``;
    Object.values(filterSchema).forEach((filterObject) => {
        const { queryParamKey, filterName } = filterObject;
        if (typeof queryParamKey === 'object') {
            let currentFilter = filterList[filterName];
            if (currentFilter) {
                let queryObject = queryParamKey.getQueryObject(currentFilter);
                Object.entries(queryObject).forEach(([queryKey, queryValue]) => {
                    if (![undefined, null, ''].includes(queryValue)) {
                        if (filterSearchQueryParam !== '') {
                            filterSearchQueryParam += '&';
                        }
                        filterSearchQueryParam += queryKey + '=' + queryValue;
                    }
                });
            }
        } else {
            let currentFilter = filterList[filterName] || [];
            let selectedFilters = [];
            if (Array.isArray(currentFilter)) {
                selectedFilters = currentFilter.filter((filt) => filt.isSelected);
            }

            let isSelected = selectedFilters.length !== currentFilter.length;
            let selectedFilterValues = selectedFilters.map((filx) => filx.apiValue);

            if (isSelected && selectedFilters.length > 0) {
                if (filterSearchQueryParam !== '') {
                    filterSearchQueryParam += '&';
                }
                filterSearchQueryParam += queryParamKey + '=' + selectedFilterValues.join('^');
            }
        }
    });
    return filterSearchQueryParam;
};

export const updateQueryParams = ({
    destinationTab,
    mainTab,
    limit,
    page,
    query,
    filterList = {},
    filterSchema,
    defaultRowsPerPage,
    defaultPage,
    navigate,
    archive,
    warehouseId,
    siteId,
} = {}) => {
    if (!limit || isNaN(limit)) limit = defaultRowsPerPage;
    if (!page || isNaN(page)) page = defaultPage;
    let search = '';
    if (mainTab) search = `?mainTab=${mainTab}`;
    if (destinationTab) {
        if (search.length > 0) search += `&tab=${destinationTab}`;
        else search = `?tab=${destinationTab}`;
    }
    if (search.length > 0) search += `&page=${page}`;
    else search = `?page=${page}`;
    if (limit) search = search + `&limit=${limit}`;
    if (query) search = search + `&query=${query}`;
    if (archive) search = search + `&archive=${true}`;
    if (warehouseId) search = search + `&selectedWarehouseId=${warehouseId}`;
    if (siteId) search = search + `&selectedSiteId=${siteId}`;
    if (filterSchema && filterList) {
        let filterQuery = getFilterQueryParams(filterSchema, filterList);
        if (filterQuery) search = search + `&${filterQuery}`;
    }
    navigate(`${window.location.pathname}${search}`);
};

export const updateSearchTermQuery = ({ searchTerm, limit, navigate }) => {
    let searchParams = new URLSearchParams(window.location.search);
    if (searchTerm) searchParams.set('query', searchTerm);
    else searchParams.delete('query');
    searchParams.set('page', 1);
    if (limit) searchParams.set('limit', limit);
    searchParams = searchParams.toString();
    navigate({
        pathname: window.location.pathname,
        search: searchParams,
    });
};
