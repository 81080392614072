import React from 'react';
import { useDispatch } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import Form from '../../../common/Form';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Input from '../../../common/Form/Input';
import { notifyEndCustomer } from '../../../actions/conversations';
import { trackSupportTicket } from '../../../router/roleWisePageRoute';

const formName = 'notifyEndCustomer';

export default function NotifyEndCustomer({ message, mobile, customerId, domain, ticketId }) {
    const defaultValues = { mobile };
    const dispatch = useDispatch();
    const { formButtonLoading } = useSelector((state) => state.loading);
    const { loggedUser } = useSelector((state) => state.auth);

    const submit = (formValues) => {
        let base_domain = window.location.origin;
        if (domain) base_domain = domain;
        let support_track_url = `${base_domain}${trackSupportTicket.path}?ticketId=${ticketId}`;
        const msg = `${message}\n\nClick on the link to chat with our support team - ${support_track_url}`
        const payload = { mobile: formValues.mobile, template: msg, customerId, formName, ticketId, userId: loggedUser.id };
        dispatch(notifyEndCustomer(payload));
    };

    const methods = useForm({ defaultValues, mode: 'all' });

    return (
        <Form
            methods={methods}
            onSubmit={submit}
            submitButtonText={'Submit'}
            onCancel={() => dispatch(closeModalAction())}
            showSubmit
            submitContainerClassName='flex gap-2 justify-end py-4 px-4'
            isLoading={formButtonLoading[formName]}
        >
            <div className='px-2'>
                <Input
                    label='Mobile'
                    type={'text'}
                    name='mobile' required validate={(value) => {
                        if (value === '') return true;
                        return value.toString().length === 10 || 'Invalid Mobile';
                    }}
                />
            </div>
        </Form>
    );
}
