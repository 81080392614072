import { useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import { textCss } from '../../../../../common/Css';

const isTruthValue = (value) => {
    return !['', null, undefined].includes(value);
};

export default function RadioField(props) {
    const { className, required, radioOptions, onChangeHandler, name, value, defaultChecked, isEditable = true } = props;
    const [selected, setSelected] = useState(defaultChecked ? radioOptions[0]?.value : undefined);

    const radioHandler = (e) => {
        if (isEditable) {
            onChangeHandler({ name: name, value: e });
            setSelected(e);
        }
    };

    return (
        <div>
            <RadioGroup value={isTruthValue(value) ? value : selected} onChange={radioHandler}>
                <div className={`${className} gap-2 text-sm`}>
                    {radioOptions.map((radio) => (
                        <RadioGroup.Option key={radio.value} value={radio.value}>
                            {({ active, checked }) => (
                                <>
                                    <div className='flex items-center '>
                                        <label className={`inline-block cursor-pointer ${active ? textCss?.inputText : `${textCss?.inputText} text-scogogray`}`}>
                                            <input
                                                type={'radio'}
                                                className={`${textCss?.radioSize} focus:ring-0 focus:ring-offset-0 mr-1 text-scogoorange cursor-pointer`}
                                                name={radio.radioName}
                                                id={radio.radioName + '1'}
                                                checked={checked}
                                                onChange={() => { }}
                                            />
                                            {radio?.label}
                                        </label>
                                        <span>{required && <span className='text-scogoorange text-font12'>*</span>}</span>
                                    </div>
                                </>
                            )}
                        </RadioGroup.Option>
                    ))}
                </div>
            </RadioGroup>
        </div>
    );
}
