
export const profileForm = {
    label: 'Profile Details',
    path: 'profile',
    id: 1,
};
export const businessForm = {
    label: 'Business Details',
    path: 'business',
    id: 2,
};
export const bankForm = {
    label: 'Bank Details',
    path: 'bank',
    id: 3,
};
