import { chatSidebarTab } from '../layout/sidebar';

export const getOpenedConversationId = (state) => state.conversations.openedConversationId;
export const getConversationChatList = (state) => state.conversations.chatList;
export const getConversationMessagesList = (state) => {
    return state.conversations.conversationMessages;
};
export const getQueueConversationMessagesList = (state) => state.conversations.messageQueueList;

export const getopenedConversationMessageIsLatest = (state) => state.conversations.openedConversationMessageIsLatest;

export const getSelectedChatTab = (state) => ({ selectedTab: state.conversations.selectedTab, selectedSubTab: state.conversations.selectedSubTab });

export const getChatNotificationCount = (state) => state.user?.[chatSidebarTab.countKey];

export const getSideBarMedia = (state) => {
    return state.conversations.sideBarMedia;
};
