import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import Form from '../../../common/Form';
import Upload from '../../../common/Form/FileUpload';
import Select from '../../../common/Form/Select';
import { useForm, useFormContext } from 'react-hook-form';
import { getprojectListName } from '../../../actions/customer';
import { bulkUploadSites, bulkUploadTicketFile } from '../../../actions/tickets';
import { getCustomerIdOfLoginUser } from '../../../utils/utils';
import config from '../../../config';
import { actualValueForDropdown } from '../../../utils/common';

const formName = 'bulk_upload_sites';

export default function BulkUploadSites() {
    const defaultValues = {};

    const dispatch = useDispatch();
    const { formButtonLoading } = useSelector((state) => state.loading);
    const { projectListNames, projectListLoading } = useSelector((state) => state.customer);
    const { loggedUser } = useSelector((state) => state.auth);
    const customerId = getCustomerIdOfLoginUser(loggedUser);

    const methods = useForm({ defaultValues, mode: 'all' });
    const { watch } = methods
    const project = watch('project');

    const submit = (formValues) => {
        const form = { project_id: actualValueForDropdown(formValues.project), formName };
        if (formValues?.bulk_sites.length > 0) {
            form.file = formValues.bulk_sites[0];
        }
        if ((project.label.toLowerCase().includes('po_support')) ||
            ([13, 17].includes(project.service_type_id)) ||
            ([23].includes(project.use_case_id))) {
            dispatch(bulkUploadTicketFile({ formName, property: 'TICKETS', projectId: project.value, customerId: project.fk_customer_id, files: formValues.bulk_sites, refresh: true }))
        } else {
            form.refresh = true;
            dispatch(bulkUploadSites(form));
        }
    };

    useEffect(() => {
        if (!projectListNames) dispatch(getprojectListName({ customerId }));
    }, [dispatch, customerId, projectListNames]);

    return (
        <Form
            methods={methods}
            onSubmit={submit}
            formName={formName}
            submitButtonText={'Upload'}
            onCancel={() => dispatch(closeModalAction())}
            showSubmit
            submitContainerClassName='flex gap-2 justify-end py-4 px-4'
            isLoading={formButtonLoading[formName]}
        >
            <BulkUploadInputs projectListNames={projectListNames} isLoading={projectListLoading} />
        </Form>
    );
}

const BulkUploadInputs = ({ isLoading, projectListNames }) => {
    const { watch } = useFormContext();
    const project = watch('project');
    const [fileUrl, setFileUrl] = useState(`${config.api.staticAssetsUrl}/others/bulk.csv`);

    useEffect(() => {
        if (project) {
            let url = `${config.api.staticAssetsUrl}/others/bulk.csv`;

            if (project.label.toLowerCase().includes('po_support')) {
                url = `${config.api.staticAssetsUrl}/others/bulk_isp_link.csv`;
            }
            if (project.service_type_id === 13) {
                url = `${config.api.staticAssetsUrl}/others/bulk_feasibility.csv`;
            }
            if (project.service_type_id === 17) {
                url = `${config.api.staticAssetsUrl}/others/bulk_commissioning.csv`;
            }
            if (project.use_case_id === 23 && actualValueForDropdown(project) !== 2220) {
                url = `${config.api.staticAssetsUrl}/others/bulk_ev.csv`;
            }
            setFileUrl(url);
        }
    }, [project]);

    return (
        <>
            <div className='px-2 flex w-full'>
                <Select className='w-6/12' name='project' label='Select Project' options={projectListNames} isLoading={isLoading} required />
                <Upload className='w-6/12' name='bulk_sites' label={'Upload CSV'} required />
            </div>
            {project ?
                <div className='px-5 py-2'>
                    <p className='text-scogoorange font-normal text-font11 cursor-pointer hover:text-scogobgsky max-w-max'>
                        <a href={fileUrl} download>
                            Download Sample file
                        </a>
                    </p>
                </div>
                :
                <></>
            }
        </>
    );
};
