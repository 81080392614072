import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModalAction } from '../../../../actions/modal';
import { getWalletDetails } from '../../../../actions/wallet';
import { getUserBankDetails } from '../../../../actions/auth';
import { getUserIdOfLoginUser } from '../../../../utils/utils';
import { SearchComponent } from './SearchComponent';
import { withdrawWalletAmount } from '../../../../actions/wallet';
import { getLoginOTP } from '../../../../actions/auth';
import { getRecepientsFilterdData } from '../../../../actions/wallet';
import { getFieldEngineersList } from '../../../../actions/fe';
import GridTable from '../../../../common/GridTable';
import { SkeltonCardTwo } from '../../../../common/SkeltonCardTwo';
import { isSp, isPsp, isIsp, isSpIsp } from '../../../../utils/role';
import OverflowTip from '../../../../common/OverflowTip';
import Form from '../../../../common/Form';
import Input from '../../../../common/Form/Input';
import { useForm } from 'react-hook-form';
import ButtonScogoPrimary from '../../../../common/Buttons/ButtonScogoPrimary';
import ButtonScogoClosedOutlined from '../../../../common/Buttons/ButtonScogoClosedOutlined';
import { sendToastMessage } from '../../../../actions/toast';
import { wallet as walletTypes } from '../../../../types';
import { convertToLakhOrCrore } from '../../../../utils/common';



const formName = 'transferAmount';
export default function Pay({ transactionStatusQuery, defaultPage, defaultLimit, ticketId = 0 }) {
    const dispatch = useDispatch();
    const { loggedUser } = useSelector((state) => state.auth);
    const role = loggedUser.role.id,
        type = loggedUser.type;
    const customerId = getUserIdOfLoginUser(loggedUser);
    const { wallet, totalPaid, isWithdrawAmountConfirmed } = useSelector((state) => state.wallet);
    const isUserSpOrPsp = isSp(role, type) || isPsp(role, type) || isIsp(role, type) || isSpIsp(role, type);
    const [fieldEngineersList, setFeildEngineersList] = useState();

    const { recepientsFilteredData } = useSelector((state) => state.wallet);
    const { isRecepientsFilterDataLoading, isFieldEngineersLoading, formButtonLoading } = useSelector((state) => state.loading);
    const { fieldEngineers } = useSelector((state) => state.fe);
    const submitRef = useRef();

    const [buttonName, setButtonName] = useState('Request OTP');
    const [showTable, setShowTable] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isUserSelected, setIsUserSelected] = useState(true);

    const defaultValues = {
        amount: '',
        remark: '',
        otp: '',
    };

    const callDataOnMount = useCallback(() => {
        const { id, service_partner_id } = loggedUser;
        dispatch(getWalletDetails({ customerId }));
        dispatch(getUserBankDetails({ id }));
        if (isUserSpOrPsp) {
            dispatch(getFieldEngineersList({ servicePartnerId: service_partner_id }));
        }
    }, [customerId, dispatch, loggedUser, isUserSpOrPsp]);

    useEffect(() => {
        callDataOnMount();
    }, [callDataOnMount]);

    useEffect(() => {
        return () => dispatch({ type: walletTypes.SET_IS_WITHDRAWAL_AMOUNT_CONFIRMED, payload: false });
    }, [dispatch])

    const handleFormSubmit = (formValues, buttonName) => {
        if (!selectedUser?.id) {
            dispatch(sendToastMessage({ message: 'Please Select User', status: 'warning' }));
            return;
        }

        if (buttonName === 'Request OTP') {
            let payload = {
                mobile: loggedUser.mobile,
                skip: true,
                bank_otp: 1,
                formName,
                confirmWithdrawAmountOnSuccess: true
            };
            dispatch(getLoginOTP(payload));
            setButtonName('Transfer');
        } else if (buttonName === 'Transfer' && isWithdrawAmountConfirmed) {
            if (!selectedUser) return setIsUserSelected(false);
            let data = {
                type: 'TRANSFER',
                withdrawAmount: parseInt(formValues.amount),
                recepient: parseInt(selectedUser?.id),
                remark: formValues.remark ? formValues.remark : '',
                otp: parseInt(formValues.otp),
                ticketId,
            };
            dispatch(withdrawWalletAmount({ data, refresh: true, formName, status: transactionStatusQuery, limit: defaultLimit, page: defaultPage }));
            setIsUserSelected(true);
        }
    };

    const handleSearch = (searchKey) => {
        const search = searchKey;
        if (!isUserSpOrPsp) {
            // or role 13, 7
            let data = {};
            const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            const mobileRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
            if (mobileRegex.test(search)) data.mobile = [search];
            if (emailRegex.test(search)) data.email = [search];
            if (Object.keys(data).length > 0) {
                dispatch(getRecepientsFilterdData({ payload: data }));
                setShowTable(true);
            } else {
                alert('Search for email or mobile number');
            }
        }
    };

    const handleTableChange = (e, item) => {
        setSelectedUser(item);
    };

    const getUserTable = (rowData) => {
        if (!showTable) return <></>;

        const tableSchema = {
            table: [
                {
                    head: {
                        render: (headName) => {
                            return headName;
                        },
                        headName: 'User',
                    },
                    body: {
                        render: (user) => {
                            let userName = `${user?.first_name} ${user?.last_name}`;
                            return (
                                <label className='px-2 flex items-center'>
                                    <input
                                        className='focus:ring-0 focus:ring-offset-0 mr-2 text-scogoorange'
                                        type='radio'
                                        name={'table'}
                                        onChange={(e) => handleTableChange(e, user)}
                                    />
                                    {userName && <OverflowTip someLongText={userName} />}
                                </label>
                            );
                        },
                    },
                },
                {
                    head: {
                        render: (headName) => {
                            return headName;
                        },
                        headName: 'Phone',
                    },
                    body: {
                        render: (user) => {
                            return <>{user?.mobile && <OverflowTip someLongText={user?.mobile} />}</>;
                        },
                    },
                },
                {
                    head: {
                        render: (headName) => {
                            return headName;
                        },
                        headName: 'Email',
                    },
                    body: {
                        render: (user) => {
                            return <>{user?.email && <OverflowTip someLongText={user?.email} />}</>;
                        },
                    },
                },
                {
                    head: {
                        render: (headName) => {
                            return headName;
                        },
                        headName: 'Role',
                        width: 0.5,
                    },
                    body: {
                        render: (user) => {
                            const userRoleName = user?.role_type?.role_name;
                            return <>{userRoleName && <OverflowTip someLongText={userRoleName} />}</>;
                        },
                    },
                },
            ],
        };

        if (isRecepientsFilterDataLoading) return <SkeltonCardTwo arr={[1, 2, 3]} height={20} />;
        return (
            <div>
                <p className='font-medium py-2'>Select User</p>
                <GridTable schema={tableSchema} rowData={rowData} tableBodyHeight={'h-max border-6'} />
            </div>
        );
    };

    const getSpOrPspTable = (rowData) => {
        const tableSchema = {
            table: [
                {
                    head: {
                        render: (headName) => {
                            return headName;
                        },
                        headName: 'User',
                    },
                    body: {
                        render: (user) => {
                            const userName = `${user?.user?.first_name} ${user?.user?.last_name}`;
                            return (
                                <label className='px-2 flex items-center'>
                                    <input
                                        className='focus:ring-0 focus:ring-offset-0 mr-2 text-scogoorange'
                                        type='radio'
                                        name={'table'}
                                        onChange={(e) => handleTableChange(e, user?.user)}
                                    />
                                    {userName && <OverflowTip someLongText={userName} />}
                                </label>
                            );
                        },
                    },
                },
                {
                    head: {
                        render: (headName) => {
                            return headName;
                        },
                        headName: 'Phone',
                    },
                    body: {
                        render: (user) => {
                            const userMobile = user?.user?.mobile;
                            return <>{userMobile && <OverflowTip someLongText={userMobile} />}</>;
                        },
                    },
                },
                {
                    head: {
                        render: (headName) => {
                            return headName;
                        },
                        headName: 'Email',
                    },
                    body: {
                        render: (user) => {
                            const userEmail = user?.user?.email;
                            return <>{userEmail && <OverflowTip someLongText={userEmail} />}</>;
                        },
                    },
                },
            ],
        };

        if (isFieldEngineersLoading) return <SkeltonCardTwo arr={[1, 2]} height={20} />;
        return <GridTable schema={tableSchema} rowData={rowData} tableBodyHeight={'h-max border-6'} tableError='No FE Found' />;
    };

    const getUsersDetails = () => {
        if (isUserSpOrPsp) return <div className='px-7 block'>{getSpOrPspTable(fieldEngineersList)}</div>;
        const isFilterDataAvailable = Array.isArray(recepientsFilteredData) && recepientsFilteredData.length > 0;
        return (
            <>
                <SearchComponent handleSearch={handleSearch} isLoading={isRecepientsFilterDataLoading} />
                {isFilterDataAvailable && <div className='px-7'>{getUserTable(recepientsFilteredData)}</div>}
                {!isUserSelected && <span className='px-7 pt-4 text-scogoclosed py-3 text-font10 font-normal'>Select user to continue</span>}
            </>
        );
    };

    const isShowOtp = buttonName === 'Transfer' && isWithdrawAmountConfirmed;

    useEffect(() => {
        const { id } = loggedUser;
        let fieldEndineerList = [];
        if (Array.isArray(fieldEngineers?.engineer_list)) {
            fieldEndineerList = fieldEngineers.engineer_list.filter((fe) => {
                return fe.fk_user_id !== id;
            });
        }
        setFeildEngineersList(fieldEndineerList);
    }, [fieldEngineers, loggedUser]);

    const sendToBankAmounCustomValidation = (amount) => {
        return parseFloat(amount) <= wallet?.wallet_amount;
    };

    const methods = useForm({ defaultValues, mode: 'all' });


    return (
        <>
            <div className='px-6 pt-4 '>
                <div className='flex gap-2'>
                    <div className='px-2 py-2 bg-tagbg rounded-lg '>Current Balance: ₹ {wallet?.wallet_amount}</div>
                    <div className='px-2 py-2 bg-tagbg rounded-lg '>Total Paid Amount: ₹ {totalPaid ? convertToLakhOrCrore(totalPaid) : 0}</div>
                </div>
            </div>
            <Form
                methods={methods}
                onSubmit={(e) => handleFormSubmit(e, buttonName)}
                submitButtonText={buttonName}
                submitRef={submitRef}
            >
                <div className='px-3'>
                    <Input
                        className='w-full'
                        label='Amount'
                        type={'number'}
                        name='amount'
                        placeholder={'Amount'}
                        required
                        validate={(amount) => {
                            if (amount === '') return true;
                            return (sendToBankAmounCustomValidation(amount) || 'Amount should be less than wallet balance.');
                        }}
                    />
                    {isShowOtp && <Input className='w-full' label='OTP' type={'text'} name='otp' placeholder={'OTP'} required />}

                    <Input className='w-full' label='Remarks' type={'text'} name='remark' placeholder={'Remarks'} />
                </div>
            </Form>
            <div>{getUsersDetails()}</div>
            <div className='flex gap-2 justify-end py-4 px-4'>
                <ButtonScogoPrimary
                    textOrComponent={buttonName}
                    onClick={(e) => {
                        e.preventDefault();
                        submitRef.current.click();
                    }}
                    loading={formButtonLoading?.[formName]}
                />
                <ButtonScogoClosedOutlined textOrComponent={'Cancel'} onClick={() => dispatch(closeModalAction())} />
            </div>
        </>
    );
}
