import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerProfileDetails, updateCustomerDetails, uploadCustomerLogo } from '../../../actions/customer';
import { closeModalAction, openCustomModal } from '../../../actions/modal';
import { sendToastMessage } from '../../../actions/toast';
import { bussinessPancardValidation, getAllStates, getGstList } from '../../../actions/users';
import Card from '../../../common/Card';
import InLineLabelValue from '../../../common/InLineLabelValue';
import { checkImageDimensions, getValidationIcons, validateImageFile } from '../../../utils/common';
import { getCustomerIdOfLoginUser, isValidPincode, validatePAN } from '../../../utils/utils';
import Form from '../../Inventory/reusableComponents/AddOption/Form';
import Input from '../../Inventory/reusableComponents/AddOption/Input';
import GstModal from './GstModal';

export const SpanLabelButton = ({
    onClick = () => {},
    label = 'span label',
    borderColor = 'border-scogoorange',
    onHoverBgColor = 'bg-scogoorange',
    textColor = 'text-scogoorange',
    labelClass = 'labelClass',
}) => {
    return (
        <span
            onClick={() => onClick()}
            className={`text-font08  px-p-3 py-p-1 font-bold font-roboto items-center hover:${onHoverBgColor} ${labelClass} cursor-pointer hover:text-white ${textColor} rounded-3px  bg-white  border ${borderColor}`}
        >
            {label}
        </span>
    );
};

export const payment_terms_options = [
    { key: 1, value: 1, label: '0 Day' },
    { key: 2, value: 2, label: '15 Days' },
    { key: 3, value: 3, label: '30 Days' },
    { key: 4, value: 4, label: '60 Days' },
    { key: 5, value: 5, label: '45 Days' },
];

export default function CustomerBusinessDetails({ isToShowPaymentTerms }) {
    const inputBrowseFileRef = useRef();
    const dispatch = useDispatch();
    const { loggedUser } = useSelector((state) => state.auth);
    const { customerProfileLoading } = useSelector((state) => state.loading);
    const { customerDetail } = useSelector((state) => state.customer);
    const customerId = getCustomerIdOfLoginUser(loggedUser);

    useEffect(() => {
        dispatch(getCustomerProfileDetails(customerId));
        dispatch(getAllStates());
    }, [loggedUser?.id, customerId, dispatch]);

    let cusotmerData = {
        company_name: customerDetail?.customer_company_name,
        company_logo: customerDetail?.logo_url,
        payment_terms: payment_terms_options.find((item) => item?.value === parseInt(customerDetail?.payment_terms)),
        address: `${customerDetail?.customer_address_1} ${customerDetail?.customer_address_2 ? customerDetail?.customer_address_2 : ''} 
        ${customerDetail?.city?.city_name ? customerDetail?.city?.city_name : ''}, 
        ${customerDetail?.state?.state_name ? customerDetail?.state?.state_name : ''}
        ${customerDetail?.pincode ? `, ${customerDetail?.pincode?.pincode}` : ''}`,
        pan_number: customerDetail?.customer_pan_card_number !== '' ? customerDetail?.customer_pan_card_number : 'N/A',
        gst_number: customerDetail?.customer_gst_number ? customerDetail.customer_gst_number : 'N/A',
        gst_state: customerDetail?.customer_gst_register_state_name ? customerDetail?.customer_gst_register_state_name.state_name : '',
        domain: customerDetail?.domain,
        pincode: customerDetail?.pincode ? customerDetail?.pincode?.pincode : '',
        customer_address_1: customerDetail?.customer_address_1,
        customer_address_2: customerDetail?.customer_address_2,
        customer_have_gst: customerDetail?.customer_have_gst,
    };

    const openGSTModal = () => {
        dispatch(
            openCustomModal({
                heading: `GST`,
                modalWidth: '50vw',
                modalHeight: 'auto',
                modalComponent: <GstModal userId={loggedUser?.id} />,
            })
        );
    };

    const uploadLogo = async (event) => {
        if (event.target.files.length > 0) {
            let imageFile = event.target.files;
            let isValidImage = validateImageFile(imageFile);
            let isValidDimension = await checkImageDimensions({ image: imageFile, maxWidth: 1100, maxHeight: 300 });
            if (isValidImage && isValidDimension) {
                dispatch(uploadCustomerLogo({ logo: imageFile }));
            } else {
                if (!isValidImage) dispatch(sendToastMessage({ status: 'danger', message: 'Only Image File can be Uploaded' }));
                else if (!isValidDimension) dispatch(sendToastMessage({ status: 'danger', message: 'Image dimensions should be 1100*300' }));
            }
        }
    };

    const updateBusinessDetailsWithIcon = () => {
        dispatch(
            openCustomModal({
                heading: `Business Details`,
                modalWidth: '40vw',
                modalHeight: 'auto',
                modalComponent: (
                    <UpdateBusinessDetailsForm
                        prefillData={{
                            company_name: cusotmerData.company_name,
                            customer_address_1: cusotmerData?.customer_address_1,
                            customer_address_2: cusotmerData?.customer_address_2,
                            pan_number: cusotmerData?.pan_number,
                            payment_terms: cusotmerData?.payment_terms,
                            pincode: cusotmerData?.pincode,
                            customer_have_gst: cusotmerData?.customer_have_gst,
                            net_provider: loggedUser?.net_provider,
                            net_plan: loggedUser?.net_plan,
                        }}
                    />
                ),
            })
        );
    };

    let cardActions = [{ iconName: 'edit', onHovertitle: 'Edit', onClick: () => updateBusinessDetailsWithIcon(), isToShowAction: true }];

    return (
        <Card isLoading={customerProfileLoading} className='profile_business_details' title={'Business Details'} icon={'work_outline'} actions={cardActions}>
            <div className='grid grid-cols-4  gap-2 h-full auto-rows-fr px-10 py-12'>
                <div className='row-span-3  flex items-center justify-center hover:bg-white hover:opacity-50'>
                    <div className='relative '>
                        <img src={cusotmerData?.company_logo ? cusotmerData?.company_logo : process.env.PUBLIC_URL + '/img/default_customer_logo.png'} alt='' className='w-96' />
                        <div className='absolute inset-y-3.5 inset-x-44  opacity-0 hover:opacity-100 bg-opacity-100   rounded-full w-1/5 flex items-center justify-center'>
                            <span
                                onClick={() => inputBrowseFileRef?.current?.click()}
                                className='material-icons hover:bg-black rounded-full cursor-pointer hover:bg-opacity-100 p-2 text-font24 focus:outline-none text-white  transition duration-200'
                            >
                                photo_camera
                            </span>
                            <input className='hidden' onChange={uploadLogo} type='file' id='file' ref={inputBrowseFileRef} />
                        </div>
                    </div>
                </div>
                <InLineLabelValue label={'Company Name'} value={cusotmerData.company_name} />
                {isToShowPaymentTerms && <InLineLabelValue label={'Payment Terms'} value={cusotmerData?.payment_terms?.label} />}
                <InLineLabelValue label={'PAN Number'} value={cusotmerData.pan_number} />
                <InLineLabelValue label={'Net Provider'} value={loggedUser?.net_provider} />
                <InLineLabelValue label={'Net Plan'} value={loggedUser?.net_plan} />
                {cusotmerData.gst_number && (
                    <div>
                        <div className='flex items-center gap-4'>
                            <InLineLabelValue label={'GST Number'} value={cusotmerData.gst_number} />
                            <SpanLabelButton onClick={() => openGSTModal()} label={'View All'} />
                        </div>
                    </div>
                )}
                <InLineLabelValue label={'GST State'} value={cusotmerData.gst_state} />
                <InLineLabelValue label={'Domain'} value={cusotmerData.domain} />
                <InLineLabelValue parentClass={'col-span-2'} label={'Address'} value={cusotmerData.address} />
            </div>
        </Card>
    );
}

const UpdateBusinessDetailsForm = ({ prefillData }) => {
    const dispatch = useDispatch();
    const { isBussinessPanVerified } = useSelector((state) => state.user);
    const { isBussinessPanVerificationLoading } = useSelector((state) => state.loading);
    const formName = 'updateBusinessDetailsForm';

    const initialValues = {
        customer_company_name: prefillData?.company_name,
        customer_address_1: prefillData?.customer_address_1,
        customer_address_2: prefillData?.customer_address_2,
        customer_pincode: prefillData?.pincode,
        payment_terms: '',
        customer_pan_card_number: prefillData?.pan_number,
        net_provider: prefillData?.net_provider,
        net_plan: prefillData?.net_plan,
        customer_have_gst: prefillData?.customer_have_gst,
    };

    const submitBusinessDetails = (formValues) => {
        if (isBussinessPanVerified || validatePAN(prefillData?.pan_number)) {
            const form = {
                customer_address_1: formValues.customer_address_1,
                customer_address_2: formValues.customer_address_2,
                customer_pincode: formValues.customer_pincode,
                customer_company_name: formValues.customer_company_name,
                customer_have_gst: formValues.customer_have_gst,
                customer_pan_card_number: formValues.customer_pan_card_number,
            };
            dispatch(updateCustomerDetails({ formValues: form, formName }));
        } else {
            dispatch(sendToastMessage({ status: 'danger', message: 'Please Verify Pan' }));
        }
    };

    const [form, setForm] = useState(initialValues);

    const closeModal = () => {
        dispatch(closeModalAction());
    };

    const onPanChange = (event) => {
        const { value } = event.target;
        if (validatePAN(value)) {
            dispatch(bussinessPancardValidation({ pan_number: value }));
        }
    };

    return (
        <div className='p-4'>
            <Form
                className='w-full'
                submit={(e) => {
                    submitBusinessDetails(e);
                }}
                buttonPostion={'justify-end'}
                formName={formName}
                initialValues={initialValues}
                form={form}
                submitButtonName={'Update'}
                setForm={setForm}
                onCancel={() => closeModal(false)}
            >
                <div className='grid grid-cols-3 rows-fr  items-end '>
                    <Input label='Company Name' className={'col-span-3'} name='customer_company_name' required type={'text'} />
                    <Input label='Address' name='customer_address_1' required type={'text'} />
                    <Input label='Address 2' name='customer_address_2' required type={'text'} />
                    {prefillData?.net_provider && <Input label='Net Provider' name='net_provider' required type={'text'} />}
                    {prefillData?.net_plan && <Input label='Net Plan' name='net_plan' required type={'text'} />}
                    {!validatePAN(prefillData?.pan_number) && (
                        <Input
                            label='Pancard'
                            customValidation={validatePAN}
                            errorMessage={'Invalid Pan Number'}
                            customMethod={onPanChange}
                            maxLength={10}
                            name='customer_pan_card_number'
                            iconLoader={isBussinessPanVerificationLoading}
                            isEditable={!validatePAN(prefillData?.pan_number)}
                            required
                            type={'text'}
                            inputIcon={getValidationIcons(isBussinessPanVerified)}
                        />
                    )}

                    <Input label='Pincode' customValidation={isValidPincode} errorMessage={'Invalid Pincode'} name='customer_pincode' required type={'text'} />
                </div>
            </Form>
        </div>
    );
};
