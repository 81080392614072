import React from 'react';

export default function UnReadMessageCount({ unReadCount, isChatPopup }) {
    if (!unReadCount || parseInt(unReadCount) <= 0) return <></>;

    return <div className={`w-full bg-slate-50 ${isChatPopup ? 'p-1' : 'p-3'} text-center`} style={{
        background: "rgb(182 182 182 / 39%)",
        width: "105%",
        marginLeft: "-3%",
    }} >
        <p className={`bg-white rounded-2xl text-center m-auto ${isChatPopup ? 'px-2 py-1' : 'px-4 py-2 '}  text-font11 shadow flex items-center gap-x-2 w-fit`}>
            <p>
                {unReadCount}
            </p>
            <p>
                {unReadCount === 1 ? "UNREAD MESSAGE" : " UNREAD MESSAGES"}
            </p>
        </p>
    </div>
};