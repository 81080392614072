import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Disclosure } from '@headlessui/react';
import NotificationsPopup from '../NotificationPopup';
import DropdownMenu from '../../common/MenuDropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { signOutUserAction } from '../../actions/auth';
import { createRootFolder } from '../../actions/users';
import { detailsPath, getDefaultRoute, trackSupportTicket } from '../../router/roleWisePageRoute';
import { headerCss } from '../../common/Css';
import { closeModalAction, openCustomModal, openTextModalAction } from '../../actions/modal';
import { connectToWebsocket, dicconnectFromWebsocket } from '../../actions/websocket';
import IconToolTip from '../../common/IconToolTip';
import { getChatMainTabs, helpCloseTab, helpOpenTab, helpTab } from '../../pages/chat/ChatController';
import { useNavigatorOnLine, useQuery } from '../../utils/common';
import { websocketConnectionStates } from '../../utils/websocket';
import config from '../../config';
import devConfig from '../../config/env/development';
import WhatsappLinkQrPopup from '../../pages/Modals/WhatsappLinkQrPopup';
import { isScm } from '../../utils/role';
import { getWhatsappOr, updateWhatsappNumber } from '../../actions/whatsapp';
import Loader from '../../common/Loader';
import CallQueueList from './CallQueue';
import { Skeleton } from '@mui/material';
import { isMobileView } from '../../pages/auth/components/NewScogoButton';

export const profileComponent = (profileImage) => {
    const defaultPic = process.env.PUBLIC_URL + '/img/default-profile-pic.svg';

    return (
        <div className='relative cursor-pointer'>
            <span className='absolute text-green-500 right-0 bottom-0'>
                <svg width='14' height='11'>
                    <circle cx='6' cy='6' r='6' fill='currentColor'></circle>
                </svg>
            </span>
            <img src={profileImage ? profileImage : defaultPic} alt='' className='profileImage' />
        </div>
    );
};

export const WebsocketBulb = () => {
    const dispatch = useDispatch();
    const isOnline = useNavigatorOnLine();
    const { websocketConnection } = useSelector((state) => state.websocket);
    const websocketDetails = {
        true: {
            icon: 'signal_cellular_alt',
            iconColr: 'text-scogosuccess',
            hoverMessage: 'Connection Alive',
        },
        false: {
            icon: 'signal_cellular_connected_no_internet_0_bar',
            iconColr: 'text-scogoclosed',
            hoverMessage: 'Disconnected',
        },
    };
    const isConnected = websocketConnection?.readyState === websocketConnectionStates.connected;
    const isLoading = websocketConnection?.readyState === websocketConnectionStates.connecting;
    const [hasConnectedBefore, setHasConnectedBefore] = useState(false);

    const reconnetWebSocket = useCallback(() => {
        if (!isLoading) {
            dispatch(connectToWebsocket({ isReconnecting: true }));
        }
    }, [dispatch, isLoading]);

    useEffect(() => {
        if (isConnected) {
            setHasConnectedBefore(true);
        }
    }, [isConnected])
    useEffect(() => {
        if (isOnline && hasConnectedBefore && !isConnected && !isLoading) {
            dispatch(dicconnectFromWebsocket());
            reconnetWebSocket();
        }
        else if (!isOnline) {
            dispatch(dicconnectFromWebsocket());
        }
    }, [dispatch, isOnline, isConnected, hasConnectedBefore, isLoading, reconnetWebSocket]);
    return (
        <>
            {!isConnected && (
                <IconToolTip title={`Reconnect`}>
                    <span onClick={reconnetWebSocket} className={` ${isLoading ? 'animate-spin cursor-not-allowed' : 'cursor-pointer'} select-none text-font18 material-icons rotate-6`}>
                        refresh
                    </span>
                </IconToolTip>
            )}
            <>
                {devConfig.name === config.name && isConnected && <IconToolTip title='Disconnect'>
                    <span onClick={() => dispatch(dicconnectFromWebsocket())} className={`material-icons text-font18 select-none cursor-pointer text-scogoclosed`}>
                        close
                    </span>
                </IconToolTip>}

                <IconToolTip title={`${websocketDetails[isConnected]?.hoverMessage}`}>
                    <span className={`material-icons text-font18 ml-0 md:ml-2 select-none cursor-pointer ${websocketDetails[isConnected]?.iconColr}`}>
                        {websocketDetails[isConnected]?.icon}
                    </span>
                </IconToolTip>
            </>
        </>
    );
};

const HelpIcon = () => {
    const query = useQuery();
    const navigate = useNavigate();
    let tab = query.get('tab');
    return (
        <IconToolTip title={`Help Chat`}>
            <span
                className={`material-icons text-font18 font-bold mr-2 cursor-pointer rounded-full p-1 ${[helpOpenTab?.path, helpCloseTab.path].includes(tab) ? 'text-scogoorange' : 'hover:text-scogoorange'
                    } `}
                onClick={() => {
                    navigate(`/chats?tab=${helpOpenTab.path}`);
                }}
            >
                support_agent
            </span>
        </IconToolTip>
    );
};


const WhatappAlternateMobileIcon = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loggedUser } = useSelector((state) => state.auth);
    const { formButtonLoading } = useSelector((state) => state.loading);
    const formName = "Whatsapp_number_loading"
    return (<>
        {
            formButtonLoading[formName] ?
                <div className='mr-3' >
                    <Loader color='#F48A21' size='15' speed='2' thickness='3' marginTop='' />
                </div>
                :
                <IconToolTip title={`Update WhatsApp Number`}>
                    <span
                        className='material-icons text-font18 font-medium mr-2 cursor-pointer rounded-full p-1 text-scogoBasicYellow hover:text-scogoorange'
                        onClick={() => {
                            let payload = {
                                userId: loggedUser?.id,
                                mobileNumber: loggedUser.mobile,
                                verifyType: "PRIMARY",
                                navigate,
                                formName,
                                openModal: true
                            };
                            dispatch(updateWhatsappNumber(payload));
                        }}
                    >
                        warning
                    </span>
                </IconToolTip>
        }
    </>

    );
};


const WhatappScanIcon = ({ loggedUser }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { whatsappLinkDetails } = useSelector((state) => state.whatsapp)
    const { formButtonLoading } = useSelector((state) => state.loading);
    const formName = "WhatsappQR_number_loading"

    return (
        <>
            {
                formButtonLoading[formName] ?
                    <div className='mr-3' >
                        <Loader color='#F48A21' size='15' speed='2' thickness='3' marginTop='' />
                    </div> :
                    whatsappLinkDetails?.status === 1 ?
                        <IconToolTip title={`WhatsApp Connected`}>
                            <span className='text-font18 mr-2 cursor-pointer' >
                                <img src={process.env.PUBLIC_URL + "/img/whatsapp.png"} alt="" className='w-8' />
                            </span>
                        </IconToolTip>
                        :
                        <IconToolTip title={`Connect your whatsapp`}>
                            <span
                                className='text-font18  hover:text-scogoorange mr-2 cursor-pointer'
                                onClick={() => {
                                    if (loggedUser?.is_mobile_linked_with_whatsapp === 0) {
                                        let payload = {
                                            userId: loggedUser?.id,
                                            mobileNumber: loggedUser.mobile,
                                            verifyType: "PRIMARY",
                                            navigate,
                                            formName,
                                            openModal: true
                                        };
                                        dispatch(updateWhatsappNumber(payload));
                                    } else {
                                        dispatch(
                                            openCustomModal({
                                                heading: `Connect Your WhatsApp`,
                                                modalWidth: '32vw',
                                                modalHeight: 'auto',
                                                modalComponent: <WhatsappLinkQrPopup />,
                                                headerComponent: <div className='flex items-center gap-2' >

                                                    <IconToolTip title="Refresh" onClick={() => {
                                                        dispatch(getWhatsappOr({ openModal: true }))
                                                    }}>
                                                        <span className='material-icons text-scogoprimary font-medium align-middle text-4xl hover:text-scogoorange cursor-pointer'  >
                                                            refresh
                                                        </span>
                                                    </IconToolTip>
                                                </div>,
                                            })
                                        );
                                    }
                                }}
                            >
                                <img src={process.env.PUBLIC_URL + "/img/whatsapp.png"} alt="" className='w-8' style={{ filter: "saturate(0%)" }} />

                            </span>
                        </IconToolTip>
            }

        </>

    );
};



const Header = ({ isInactiveUser = false }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loggedUser } = useSelector((state) => state.auth);
    const role = loggedUser.role.id;
    const type = loggedUser.type;

    const closeModal = () => {
        dispatch(closeModalAction());
    };

    const handleMyDocumentsClick = () => {
        if (!loggedUser.document_folder_id) {
            dispatch(createRootFolder(loggedUser.id, navigate));
        } else {
            navigate(`/documents?path=/${loggedUser.document_folder_id}`);
        }
    };

    const handleMyProfileClick = () => {
        navigate('/user-profile');
    };

    const getAcionDropdown = () => {
        let actions = [];
        let myDocuments = {
            menuName: 'My Documents',
            menuIcon: 'folder',
            onClick: () => {
                handleMyDocumentsClick();
            },
        };
        let logout = {
            menuName: 'Sign out',
            menuIcon: 'logout',
            onClick: (item, dispatch) => {
                return dispatch(
                    openTextModalAction({
                        heading: 'Sign Out',
                        text: 'Are you sure you want to sign out?',
                        formName: 'signOutUser',
                        buttons: [
                            {
                                showLoading: true,
                                textOrComponent: 'Yes',
                                type: 'red',
                                buttonClass: 'hover:bg-scogoLightFillRed',
                                onClick: () => {
                                    dispatch(signOutUserAction(navigate));
                                },
                            },
                            {
                                textOrComponent: 'No',
                                type: 'blue',
                                onClick: () => {
                                    closeModal();
                                },
                            },
                        ],
                        closeOnBackdropClick: false,
                    })
                );
            },
        };
        let myProfile = {
            menuName: 'My Profile',
            menuIcon: 'person',
            onClick: () => {
                handleMyProfileClick();
            },
        };
        if (isInactiveUser) actions = [logout];
        else actions = [myProfile, myDocuments, logout];
        return actions;
    };
    const chatTabs = useMemo(() => getChatMainTabs(loggedUser.role.id, loggedUser.type, { frontend_controller: loggedUser.frontend_controller, is_possible_tech_support: loggedUser.is_possible_tech_support }), [loggedUser.role.id, loggedUser.type, loggedUser.frontend_controller, loggedUser.is_possible_tech_support]);

    const showHelp = chatTabs.findIndex((tab) => tab.tabID === helpTab.tabID) >= 0;
    const showWhatappAlternateMobile = loggedUser.is_mobile_linked_with_whatsapp === 0;
    const showWhatsappQr = isScm(role, type);
    const { isUIConfigLoading } = useSelector(state => state.loading);

    return (
        <Disclosure as='div' className='header fixed  w-full z-50 flex items-center justify-between  '>
            {({ open }) => (
                <>
                    <div className={`${headerCss?.mainDiv} w-full flex items-center justify-between `}>
                        <div
                            className='flex items-center cursor-pointer justify-center '
                            onClick={() => {
                                navigate(
                                    getDefaultRoute(loggedUser.role.id, loggedUser.type, {
                                        frontend_controller: loggedUser.frontend_controller,
                                        status: loggedUser.status,
                                        spOnboardingStatus: loggedUser.spOnboardingStatus,
                                        customerOnboardingStatus: loggedUser.customerOnboardingStatus,
                                        feOnboardingStatus: loggedUser.feOnboardingStatus,
                                        email: loggedUser.email,
                                        pancard_number: loggedUser.pancard_number,
                                        address_proof_number: loggedUser.address_proof_number,
                                    })
                                );
                            }}
                        >
                            <div className='shrink-0'>
                                {isUIConfigLoading ?
                                    <Skeleton variant='rectangular' animation='wave' height='3rem' width='5rem' />
                                    : <HeaderLogo />}
                            </div>
                        </div>
                        <div className='md:block'>
                            <div className='ml-4 flex items-center md:ml-6'>
                                {!isInactiveUser && (
                                    <>
                                        <CallQueueList />
                                        {showWhatappAlternateMobile && <WhatappAlternateMobileIcon />}
                                        {showWhatsappQr && <WhatappScanIcon loggedUser={loggedUser} />}
                                        {showHelp && <HelpIcon />}
                                        <WebsocketBulb />
                                        <NotificationsPopup />
                                    </>
                                )}
                                <div className='w-[3rem]'>
                                    <IconToolTip title={loggedUser ? `${loggedUser.first_name} ${loggedUser.last_name}` : ''}>
                                        <DropdownMenu
                                            menuData={getAcionDropdown()}
                                            componentOrIcon={() => profileComponent(loggedUser.profile_picture)}
                                            iconColor={'text-scogoprimary'}
                                        />
                                    </IconToolTip>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Disclosure>
    );
};

export default Header;


export const HeaderLogo = () => {
    const { uiConfig } = useSelector(state => state.user);
    const { ticketDetails } = useSelector((state) => state.tickets);
    const { tagAssetDetails } = useSelector((state => state.inventory));
    const location = useLocation();
    let url = process.env.PUBLIC_URL + '/img/scogo-logo-black-1.png';
    if (uiConfig) url = uiConfig.logoUrl;
    else if (isMobileView) {
        url = process.env.PUBLIC_URL + '/img/scogo_logo_without_scogo.png'
    }
    let additionalClass = 'headerLogo max-w-max';
    const smart_edge_router_asset_id = 695;
    let isSmartEdgeRouter = [parseInt(tagAssetDetails?.assetType), parseInt(tagAssetDetails?.assetType?.id)].includes(smart_edge_router_asset_id);
    if (ticketDetails && ticketDetails.assets) {
        isSmartEdgeRouter = ticketDetails.assets.find((asset) => asset.asset_type_id === smart_edge_router_asset_id);
    }
    if ([detailsPath.path, trackSupportTicket.path].includes(location.pathname) && (isSmartEdgeRouter)) {
        url = process.env.PUBLIC_URL + '/img/ser_logo.png';
        additionalClass = 'h-10 w-40 min-w-40'
    };
    return <img className={additionalClass} src={url} alt='Company Logo' />
}