import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import IconToolTip from '../../../common/IconToolTip';
import { GetWalletLogic } from '../walletController';
import { convertToLakhOrCrore, validateNumberValue } from '../../../utils/common';

export default function WalletCard(props) {
    const { totalWithdrawn, totalPaid, promisedAmount, totalEarning } = useSelector((state) => state.wallet);
    const { wallet } = useSelector((state) => state.wallet);
    const { loggedUser } = useSelector((state) => state.auth);
    const isToShowTotalEarningAmountCard = GetWalletLogic('isToShowTotalEarningAmountCard');
    const isToShowPromisedAmountCard = GetWalletLogic('isToShowPromisedAmountCard');
    const isToShowReservedAmountCard = GetWalletLogic('isToShowReservedAmountCard');

    const isToShowCurrentMonthIncentiveAmountCard = loggedUser?.employee_type === 'EMP';
    const isToShowLockedAmountCard = loggedUser?.out_source === 1 || loggedUser?.payroll_type === 'SCOGO';
    const paidAmount = GetWalletLogic('getPaidAmount', { wallet, totalPaid });
    const currentBalance = GetWalletLogic('getCurrentBalance', { wallet });
    const reservedAmount = GetWalletLogic('getReservedAmount', { wallet });


    const currentMonthIncentiveAmount = wallet?.incentive_amount > 0 ? wallet.incentive_amount : 0;
    const showIncentiveCard = validateNumberValue(loggedUser.affiliate_id);

    return (
        <>

            <GetWalletCard name='Current Balance' icon='./img/rupee.svg' balance={parseFloat(currentBalance) ? parseFloat(currentBalance).toFixed(2) : 0} completeBal={parseFloat(currentBalance) ? parseFloat(currentBalance).toFixed(2) : 0} />
            {isToShowPromisedAmountCard && <GetWalletCard name='Promised Amount' icon='./img/lock.svg' balance={convertToLakhOrCrore(promisedAmount)} />}
            {!isToShowReservedAmountCard && !isToShowPromisedAmountCard && (
                <GetWalletCard name='Withdrawn Amount' icon='./img/lock.svg' balance={convertToLakhOrCrore(totalWithdrawn)} completeBal={totalWithdrawn} />
            )}
            {isToShowTotalEarningAmountCard && <GetWalletCard name='Total Earning' icon='./img/wallet.svg' balance={convertToLakhOrCrore(totalEarning)} completeBal={totalEarning} />}
            {isToShowCurrentMonthIncentiveAmountCard && (
                <GetWalletCard name='Current Month Incentive' icon='./img/rupee.svg' balance={convertToLakhOrCrore(currentMonthIncentiveAmount)} completeBal={currentMonthIncentiveAmount} />
            )}
            {isToShowReservedAmountCard && <GetWalletCard name='Reserved Amount' icon='./img/lock.svg' balance={convertToLakhOrCrore(reservedAmount)} completeBal={reservedAmount} />}
            {!isToShowTotalEarningAmountCard && <GetWalletCard name='Paid Amount' icon='./img/wallet.svg' balance={convertToLakhOrCrore(paidAmount)} completeBal={paidAmount} />}
            {isToShowLockedAmountCard && <GetWalletCard name='Locked Amount' icon='./img/wallet.svg' balance={convertToLakhOrCrore(wallet?.locked_amount)} completeBal={wallet?.locked_amount} />}
            {showIncentiveCard ? <GetWalletCard name='Store Earning' icon='./img/rupee.svg' balance={convertToLakhOrCrore(wallet?.total_commission_earned)} completeBal={wallet?.total_commission_earned} /> : <></>}
        </>
    );
}

const GetWalletCard = ({ name, icon, balance, completeBal }) => {
    const balanceCss = parseFloat(balance) < 0 ? `text-red-600` : 'text-scogoprimary';

    return (
        <div style={{ width: 'auto', height: '7rem' }} className='shadow-lg rounded-xl flex items-center px-8 bg-tagbg '>
            <div className='flex flex-col gap-1 w-9/12'>
                <img className='h-7 w-7 my-2 ' src={process.env.PUBLIC_URL + icon} alt='' />
                <p className='text-scogoprimary font-bold text-font11'>{name}</p>
            </div>
            <div className='w-4/12'>
                <IconToolTip title={completeBal}>
                    <p className={`text-font16 font-bold truncate ${balanceCss}`}> {balance}</p>
                </IconToolTip>
            </div>
        </div>
    );
};
