import React, { useRef, useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';

const OverflowTip = (props) => {
    const { someLongText, textClass, whiteSpace, placement, titleClassName = "text-font09", onClick = () => { } } = props;
    const textElementRef = useRef();
    const compareSize = () => {
        let compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
        setHover(compare);
    };

    // compare once and add resize listener on "componentDidMount"
    useEffect(() => {
        compareSize();
        window.addEventListener('resize', compareSize);
        return () => {
            // remove resize listener again on "componentWillUnmount"
            window.removeEventListener('resize', compareSize);
        };
    }, []);

    // Define state and function to update the value
    const [hoverStatus, setHover] = useState(false);

    return (
        <Tooltip title={<div className={` ${titleClassName}`}>
            {someLongText}
        </div>
        } interactive='true' disableHoverListener={!hoverStatus} enterNextDelay={1000} placement={placement}>
            <div
                ref={textElementRef}
                style={{
                    whiteSpace: whiteSpace ? whiteSpace : 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
                className={textClass}
                onClick={onClick}
            >
                {someLongText}
            </div>
        </Tooltip>
    );
};

export default OverflowTip;
