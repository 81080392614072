export const stateGeoJSONLookup = {
    'Andaman and Nicobar': {
        type: 'Feature',
        geometry: {
            type: 'MultiPolygon',
            coordinates: [
                [
                    [
                        [93.848053, 7.24028],
                        [93.686111, 7.19],
                        [93.828316, 6.754256],
                        [93.94548, 7.00941],
                        [93.848053, 7.24028],
                    ],
                ],
                [
                    [
                        [92.515831, 10.897641],
                        [92.381943, 10.540833],
                        [92.568336, 10.576112],
                        [92.515831, 10.897641],
                    ],
                ],
                [
                    [
                        [92.700867, 12.235609],
                        [92.536674, 11.838566],
                        [92.711281, 11.477318],
                        [92.785446, 11.93444],
                        [92.700867, 12.235609],
                    ],
                ],
                [
                    [
                        [92.898888, 12.915831],
                        [92.72438, 12.827971],
                        [92.700119, 12.326622],
                        [92.891937, 12.342221],
                        [92.980507, 12.709221],
                        [92.898888, 12.915831],
                    ],
                ],
                [
                    [
                        [93.029442, 13.573334],
                        [92.842499, 13.394441],
                        [92.801483, 12.90075],
                        [93.030563, 13.068891],
                        [93.029442, 13.573334],
                    ],
                ],
            ],
        },
        properties: {
            ID_0: 105,
            ISO: 'IND',
            NAME_0: 'India',
            ID_1: 1,
            NAME_1: 'Andaman and Nicobar',
            NL_NAME_1: '',
            VARNAME_1: 'Andaman & Nicobar Islands|Andaman et Nicobar|Iihas de Andama e Nicobar|Inseln Andamanen und Nikobare',
            TYPE_1: 'Union Territor',
            ENGTYPE_1: 'Union Territory',
            filename: '',
            filename_1: '',
            filename_2: '',
            filename_3: '',
            filename_4: '',
        },
    },
    Maharashtra: {
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates: [
                [
                    [80.656532, 21.330772],
                    [80.404442, 21.375811],
                    [80.188217, 21.634201],
                    [79.536621, 21.544218],
                    [79.488579, 21.674122],
                    [78.913239, 21.59236],
                    [78.72419, 21.46438],
                    [78.422562, 21.60195],
                    [77.89904, 21.37842],
                    [77.487022, 21.37775],
                    [77.477768, 21.770222],
                    [76.795349, 21.59754],
                    [76.623367, 21.192659],
                    [76.183861, 21.089951],
                    [76.098671, 21.373928],
                    [75.301903, 21.38925],
                    [75.057747, 21.564939],
                    [74.564766, 21.67985],
                    [74.435547, 22.030998],
                    [74.146019, 21.95406],
                    [73.810516, 21.823338],
                    [73.860786, 21.495823],
                    [74.309967, 21.567322],
                    [73.628723, 21.12015],
                    [73.906448, 20.98315],
                    [73.920906, 20.72353],
                    [73.748207, 20.56736],
                    [73.482613, 20.71723],
                    [73.426826, 20.201571],
                    [73.216316, 20.122],
                    [72.970428, 20.21233],
                    [72.73764, 20.136539],
                    [72.665413, 19.93486],
                    [72.798752, 19.330975],
                    [72.791817, 18.950701],
                    [72.99292, 19.059032],
                    [72.855423, 18.695971],
                    [72.941528, 18.234585],
                    [73.282639, 17.113195],
                    [73.311249, 16.54236],
                    [73.510139, 15.939581],
                    [73.680138, 15.724371],
                    [74.12603, 15.65029],
                    [74.350937, 15.76336],
                    [74.508331, 16.22294],
                    [74.253159, 16.494881],
                    [74.92382, 16.769951],
                    [74.938393, 16.94099],
                    [75.678421, 16.963942],
                    [75.63559, 17.47751],
                    [75.931152, 17.32411],
                    [76.408066, 17.369289],
                    [76.331673, 17.59487],
                    [76.916443, 17.915091],
                    [76.954109, 18.184891],
                    [77.108887, 18.152691],
                    [77.240227, 18.408648],
                    [77.549957, 18.291349],
                    [77.597267, 18.547541],
                    [77.944138, 18.842291],
                    [77.767357, 18.981421],
                    [77.860878, 19.30213],
                    [78.183319, 19.241541],
                    [78.397659, 19.836403],
                    [78.845627, 19.75835],
                    [79.018356, 19.53997],
                    [79.237091, 19.609489],
                    [79.470421, 19.497452],
                    [79.740326, 19.60314],
                    [79.973053, 19.40102],
                    [79.858047, 19.104111],
                    [79.894012, 18.830839],
                    [80.26754, 18.71797],
                    [80.267723, 18.987329],
                    [80.572861, 19.39842],
                    [80.749237, 19.286741],
                    [80.879051, 19.51903],
                    [80.402657, 19.909601],
                    [80.540367, 20.109833],
                    [80.382957, 20.24172],
                    [80.616692, 20.325741],
                    [80.622322, 20.603863],
                    [80.457298, 21.17284],
                    [80.656532, 21.330772],
                ],
            ],
        },
        properties: {
            ID_0: 105,
            ISO: 'IND',
            NAME_0: 'India',
            ID_1: 21,
            NAME_1: 'Maharashtra',
            NL_NAME_1: '',
            VARNAME_1: '',
            TYPE_1: 'State',
            ENGTYPE_1: 'State',
            filename: '',
            filename_1: '',
            filename_2: '',
            filename_3: '',
            filename_4: '',
        },
    },
    'Andhra Pradesh': {
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates: [
                [
                    [82.299515, 16.714333],
                    [82.249313, 16.90737],
                    [82.44014, 17.17375],
                    [83.238472, 17.613472],
                    [83.669586, 18.08403],
                    [84.070419, 18.276251],
                    [84.761017, 19.071312],
                    [84.730217, 19.089306],
                    [84.719032, 19.095844],
                    [84.420021, 19.01252],
                    [84.312492, 18.77919],
                    [83.886879, 18.805408],
                    [83.622719, 19.144688],
                    [83.411339, 18.859341],
                    [83.132957, 18.772449],
                    [83.066498, 18.39226],
                    [82.580742, 18.2701],
                    [82.474007, 18.536833],
                    [82.26725, 17.986441],
                    [82.024658, 18.05788],
                    [81.611153, 17.815363],
                    [81.384972, 17.806593],
                    [81.032448, 17.78973],
                    [80.973679, 18.16815],
                    [80.862022, 18.132669],
                    [80.632683, 18.518841],
                    [80.26754, 18.71797],
                    [79.894012, 18.830839],
                    [79.858047, 19.104111],
                    [79.973053, 19.40102],
                    [79.740326, 19.60314],
                    [79.470421, 19.497452],
                    [79.237091, 19.609489],
                    [79.018356, 19.53997],
                    [78.845627, 19.75835],
                    [78.397659, 19.836403],
                    [78.183319, 19.241541],
                    [77.860878, 19.30213],
                    [77.767357, 18.981421],
                    [77.944138, 18.842291],
                    [77.597267, 18.547541],
                    [77.549957, 18.291349],
                    [77.646179, 17.9932],
                    [77.439926, 17.58147],
                    [77.53347, 17.44095],
                    [77.380356, 17.21977],
                    [77.49939, 17.00732],
                    [77.28466, 16.403],
                    [77.487556, 16.249821],
                    [77.509453, 15.92086],
                    [77.076797, 15.90974],
                    [77.038033, 15.358782],
                    [77.109573, 15.028569],
                    [76.876701, 15.028679],
                    [76.76091, 14.596281],
                    [76.937843, 14.240121],
                    [77.152153, 14.337741],
                    [77.479126, 14.283741],
                    [77.240097, 13.89408],
                    [77.521523, 13.68787],
                    [77.961273, 13.949971],
                    [78.183273, 13.55638],
                    [78.380318, 13.581309],
                    [78.355156, 13.355071],
                    [78.569077, 13.156483],
                    [78.22065, 12.75259],
                    [78.547676, 12.685591],
                    [78.702446, 13.056151],
                    [79.152702, 13.007293],
                    [79.454643, 13.324321],
                    [79.783257, 13.228162],
                    [80.069443, 13.537591],
                    [80.312996, 13.427916],
                    [80.050957, 13.588192],
                    [80.252357, 13.782922],
                    [80.128197, 14.21069],
                    [80.199028, 14.587083],
                    [80.050697, 15.027918],
                    [80.270416, 15.673196],
                    [80.508751, 15.84014],
                    [81.000969, 15.751805],
                    [81.318748, 16.313194],
                    [81.71875, 16.311541],
                    [82.272919, 16.560139],
                    [82.257637, 16.690113],
                    [82.299515, 16.714333],
                ],
                [
                    [82.332077, 16.724861],
                    [82.285393, 16.697639],
                    [82.258751, 16.690111],
                    [82.332077, 16.724861],
                ],
            ],
        },
        properties: {
            ID_0: 105,
            ISO: 'IND',
            NAME_0: 'India',
            ID_1: 2,
            NAME_1: 'Andhra Pradesh',
            NL_NAME_1: '',
            VARNAME_1: '',
            TYPE_1: 'State',
            ENGTYPE_1: 'State',
            filename: '',
            filename_1: '',
            filename_2: '',
            filename_3: '',
            filename_4: '',
        },
    },
    'Arunachal Pradesh': {
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates: [
                [
                    [95.237633, 26.686291],
                    [95.423309, 26.696712],
                    [96.234329, 27.28488],
                    [96.802361, 27.354431],
                    [96.866226, 27.19969],
                    [97.171738, 27.13681],
                    [96.897583, 27.620802],
                    [97.256477, 27.909531],
                    [97.374657, 28.225601],
                    [96.664566, 28.465771],
                    [96.274483, 28.424011],
                    [96.585938, 28.721588],
                    [96.477402, 28.99404],
                    [96.166527, 28.90593],
                    [96.114502, 29.077602],
                    [96.346359, 29.17103],
                    [96.080536, 29.463345],
                    [95.545029, 29.21559],
                    [95.454338, 29.036991],
                    [94.797989, 29.16461],
                    [94.628159, 29.348591],
                    [94.214691, 29.084089],
                    [93.84404, 28.70739],
                    [93.336906, 28.64016],
                    [93.181068, 28.432741],
                    [92.662933, 28.062771],
                    [92.560692, 27.821041],
                    [92.255157, 27.862169],
                    [91.918762, 27.715101],
                    [91.628159, 27.800831],
                    [91.653236, 27.482981],
                    [92.018761, 27.479746],
                    [92.107887, 26.89382],
                    [92.658928, 27.038521],
                    [93.019371, 26.91683],
                    [93.675781, 26.971071],
                    [94.213509, 27.604422],
                    [94.479156, 27.57699],
                    [95.606743, 27.957769],
                    [95.977463, 27.9685],
                    [95.758217, 27.73111],
                    [95.849342, 27.430321],
                    [96.020912, 27.37126],
                    [95.195518, 27.04295],
                    [95.237633, 26.686291],
                ],
            ],
        },
        properties: {
            ID_0: 105,
            ISO: 'IND',
            NAME_0: 'India',
            ID_1: 3,
            NAME_1: 'Arunachal Pradesh',
            NL_NAME_1: '',
            VARNAME_1: 'Agence de la Frontire du Nord-Est(French-obsolete)|North East Frontier Agency',
            TYPE_1: 'State',
            ENGTYPE_1: 'State',
            filename: '',
            filename_1: '',
            filename_2: '',
            filename_3: '',
            filename_4: '',
        },
    },
    Assam: {
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates: [
                [
                    [95.195518, 27.04295],
                    [96.020912, 27.37126],
                    [95.849342, 27.430321],
                    [95.758217, 27.73111],
                    [95.977463, 27.9685],
                    [95.606743, 27.957769],
                    [94.479156, 27.57699],
                    [94.213509, 27.604422],
                    [93.675781, 26.971071],
                    [93.019371, 26.91683],
                    [92.658928, 27.038521],
                    [92.107887, 26.89382],
                    [91.726929, 26.812559],
                    [90.649101, 26.774281],
                    [90.409706, 26.90192],
                    [89.856941, 26.703781],
                    [89.857292, 26.38446],
                    [89.718758, 26.166048],
                    [89.88607, 25.943588],
                    [89.870102, 25.544222],
                    [90.120003, 25.95772],
                    [90.477287, 26.015261],
                    [90.957916, 25.933943],
                    [91.223877, 25.723419],
                    [91.57003, 26.027481],
                    [91.720177, 25.954071],
                    [92.301582, 26.07649],
                    [92.169762, 25.666401],
                    [92.385101, 25.753563],
                    [92.803566, 25.217239],
                    [92.425903, 25.03182],
                    [92.245232, 24.91025],
                    [92.230995, 24.504091],
                    [92.296608, 24.25173],
                    [92.466827, 24.13504],
                    [92.765198, 24.520849],
                    [93.000496, 24.403271],
                    [93.112701, 24.80221],
                    [93.474022, 25.30928],
                    [93.331741, 25.54681],
                    [93.706436, 25.853291],
                    [93.982681, 25.92627],
                    [94.013, 26.186281],
                    [94.282288, 26.56354],
                    [94.886223, 26.932461],
                    [95.195518, 27.04295],
                ],
            ],
        },
        properties: {
            ID_0: 105,
            ISO: 'IND',
            NAME_0: 'India',
            ID_1: 4,
            NAME_1: 'Assam',
            NL_NAME_1: '',
            VARNAME_1: '',
            TYPE_1: 'State',
            ENGTYPE_1: 'State',
            filename: '',
            filename_1: '',
            filename_2: '',
            filename_3: '',
            filename_4: '',
        },
    },
    Bihar: {
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates: [
                [
                    [88.106148, 26.535624],
                    [87.343414, 26.347515],
                    [87.07428, 26.585466],
                    [86.749176, 26.428003],
                    [86.030739, 26.666422],
                    [85.860893, 26.568577],
                    [85.626221, 26.873415],
                    [85.336319, 26.741415],
                    [85.191315, 26.869816],
                    [84.644852, 27.047197],
                    [84.624474, 27.336079],
                    [84.099792, 27.516773],
                    [83.858505, 27.350962],
                    [84.024902, 26.94272],
                    [84.414398, 26.627171],
                    [84.080421, 26.640753],
                    [83.902206, 26.51808],
                    [84.171356, 26.37398],
                    [84.049057, 26.099701],
                    [84.621117, 25.79236],
                    [84.488388, 25.677851],
                    [84.148232, 25.730961],
                    [83.782272, 25.398729],
                    [83.409271, 25.249281],
                    [83.316498, 25.038382],
                    [83.498169, 24.52706],
                    [83.928459, 24.547911],
                    [84.521523, 24.376261],
                    [84.832626, 24.51148],
                    [84.898033, 24.372129],
                    [85.673523, 24.595631],
                    [85.73674, 24.822651],
                    [86.050583, 24.779541],
                    [86.4618, 24.37672],
                    [86.523529, 24.535749],
                    [87.044403, 24.624031],
                    [87.176643, 25.06402],
                    [87.58007, 25.348391],
                    [87.787582, 25.22049],
                    [87.786522, 25.4487],
                    [88.070412, 25.480501],
                    [87.842117, 26.044481],
                    [88.291916, 26.35391],
                    [88.106148, 26.535624],
                ],
            ],
        },
        properties: {
            ID_0: 105,
            ISO: 'IND',
            NAME_0: 'India',
            ID_1: 5,
            NAME_1: 'Bihar',
            NL_NAME_1: '',
            VARNAME_1: '',
            TYPE_1: 'State',
            ENGTYPE_1: 'State',
            filename: '',
            filename_1: '',
            filename_2: '',
            filename_3: '',
            filename_4: '',
        },
    },
    Chandigarh: {
        type: 'Feature',
        geometry: null,
        properties: {
            ID_0: 105,
            ISO: 'IND',
            NAME_0: 'India',
            ID_1: 6,
            NAME_1: 'Chandigarh',
            NL_NAME_1: '',
            VARNAME_1: '',
            TYPE_1: 'Union Territor',
            ENGTYPE_1: 'Union Territory',
            filename: '',
            filename_1: '',
            filename_2: '',
            filename_3: '',
            filename_4: '',
        },
    },
    Chhattisgarh: {
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates: [
                [
                    [84.002502, 22.520861],
                    [84.232239, 22.68858],
                    [84.370537, 22.975071],
                    [84.148003, 22.963869],
                    [83.966888, 23.374229],
                    [84.007027, 23.632311],
                    [83.774887, 23.599501],
                    [83.509628, 24.0254],
                    [83.323563, 24.10133],
                    [83.127411, 23.89002],
                    [82.807777, 23.963058],
                    [82.544518, 23.794189],
                    [81.811729, 23.8095],
                    [81.569733, 23.58865],
                    [81.916359, 23.532091],
                    [82.184288, 23.326891],
                    [81.784279, 22.766621],
                    [81.396553, 22.44132],
                    [81.109993, 22.44112],
                    [80.978851, 22.048311],
                    [80.87896, 22.0606],
                    [80.656532, 21.330772],
                    [80.457298, 21.17284],
                    [80.622322, 20.603863],
                    [80.616692, 20.325741],
                    [80.382957, 20.24172],
                    [80.540367, 20.109833],
                    [80.402657, 19.909601],
                    [80.879051, 19.51903],
                    [80.749237, 19.286741],
                    [80.572861, 19.39842],
                    [80.267723, 18.987329],
                    [80.26754, 18.71797],
                    [80.632683, 18.518841],
                    [80.862022, 18.132669],
                    [80.973679, 18.16815],
                    [81.032448, 17.78973],
                    [81.384972, 17.806593],
                    [81.527962, 18.259081],
                    [81.954529, 18.58671],
                    [82.239563, 18.91046],
                    [82.052193, 19.791342],
                    [81.858406, 19.899549],
                    [81.944206, 20.101603],
                    [82.698441, 19.82686],
                    [82.71093, 19.983261],
                    [82.395767, 20.049],
                    [82.429169, 20.425753],
                    [82.324013, 20.553881],
                    [82.635941, 21.14945],
                    [83.119843, 21.101061],
                    [83.374817, 21.33996],
                    [83.380722, 21.612751],
                    [83.600479, 21.910841],
                    [83.645752, 22.224588],
                    [83.992973, 22.36924],
                    [84.002502, 22.520861],
                ],
            ],
        },
        properties: {
            ID_0: 105,
            ISO: 'IND',
            NAME_0: 'India',
            ID_1: 7,
            NAME_1: 'Chhattisgarh',
            NL_NAME_1: '',
            VARNAME_1: '',
            TYPE_1: 'State',
            ENGTYPE_1: 'State',
            filename: '',
            filename_1: '',
            filename_2: '',
            filename_3: '',
            filename_4: '',
        },
    },
    'Dadra and Nagar Haveli': {
        type: 'Feature',
        geometry: null,
        properties: {
            ID_0: 105,
            ISO: 'IND',
            NAME_0: 'India',
            ID_1: 8,
            NAME_1: 'Dadra and Nagar Haveli',
            NL_NAME_1: '',
            VARNAME_1: 'DAdra et Nagar Haveli|Dadra e Nagar Haveli',
            TYPE_1: 'Union Territor',
            ENGTYPE_1: 'Union Territory',
            filename: '',
            filename_1: '',
            filename_2: '',
            filename_3: '',
            filename_4: '',
        },
    },
    'Daman and Diu': {
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates: [
                [
                    [70.854416, 20.753471],
                    [70.74649, 20.98897],
                    [70.673752, 20.763472],
                    [70.870407, 20.718719],
                    [70.854561, 20.752083],
                    [70.854416, 20.753471],
                ],
            ],
        },
        properties: {
            ID_0: 105,
            ISO: 'IND',
            NAME_0: 'India',
            ID_1: 9,
            NAME_1: 'Daman and Diu',
            NL_NAME_1: '',
            VARNAME_1: '',
            TYPE_1: 'Union Territor',
            ENGTYPE_1: 'Union Territory',
            filename: '',
            filename_1: '',
            filename_2: '',
            filename_3: '',
            filename_4: '',
        },
    },
    Delhi: {
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates: [
                [
                    [77.337738, 28.50897],
                    [77.186867, 28.866819],
                    [76.942307, 28.820681],
                    [76.837616, 28.55699],
                    [77.165771, 28.408461],
                    [77.337738, 28.50897],
                ],
            ],
        },
        properties: {
            ID_0: 105,
            ISO: 'IND',
            NAME_0: 'India',
            ID_1: 10,
            NAME_1: 'Delhi',
            NL_NAME_1: '',
            VARNAME_1: '',
            TYPE_1: 'Union Territor',
            ENGTYPE_1: 'Union Territory',
            filename: '',
            filename_1: '',
            filename_2: '',
            filename_3: '',
            filename_4: '',
        },
    },
    Goa: {
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates: [
                [
                    [74.12603, 15.65029],
                    [73.680138, 15.724371],
                    [74.097359, 14.895659],
                    [74.302338, 15.03986],
                    [74.255257, 15.65421],
                    [74.12603, 15.65029],
                ],
            ],
        },
        properties: {
            ID_0: 105,
            ISO: 'IND',
            NAME_0: 'India',
            ID_1: 11,
            NAME_1: 'Goa',
            NL_NAME_1: '',
            VARNAME_1: 'Ga',
            TYPE_1: 'State',
            ENGTYPE_1: 'State',
            filename: '',
            filename_1: '',
            filename_2: '',
            filename_3: '',
            filename_4: '',
        },
    },
    Gujarat: {
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates: [
                [
                    [74.322777, 23.062691],
                    [73.826889, 23.44684],
                    [73.632751, 23.452393],
                    [73.359848, 23.85463],
                    [73.413757, 24.051069],
                    [73.082687, 24.18498],
                    [73.094383, 24.494728],
                    [72.732536, 24.361271],
                    [72.0849, 24.652752],
                    [71.297737, 24.607641],
                    [71.10714, 24.680651],
                    [71.134102, 24.413811],
                    [70.805763, 24.222727],
                    [70.574867, 24.420315],
                    [70.1101, 24.284897],
                    [70.024086, 24.169353],
                    [69.600677, 24.277847],
                    [68.764122, 24.284122],
                    [68.749245, 23.962046],
                    [68.489296, 23.635139],
                    [68.591248, 23.214312],
                    [69.197357, 22.83847],
                    [69.752357, 22.819586],
                    [70.493469, 23.07514],
                    [70.171249, 22.557081],
                    [69.223473, 22.257917],
                    [69.203476, 22.40431],
                    [68.935974, 22.312084],
                    [69.236252, 21.938471],
                    [69.705971, 21.538471],
                    [70.060417, 21.142361],
                    [70.673752, 20.763472],
                    [70.74649, 20.98897],
                    [70.854416, 20.753471],
                    [70.873749, 20.709829],
                    [70.888535, 20.709032],
                    [70.892082, 20.708191],
                    [70.962639, 20.729382],
                    [71.439583, 20.869305],
                    [72.115417, 21.202085],
                    [72.305969, 21.628469],
                    [72.363197, 22.26042],
                    [72.571251, 22.19042],
                    [72.547363, 21.65847],
                    [72.736252, 21.689583],
                    [72.593193, 21.312084],
                    [72.897636, 20.587084],
                    [72.860138, 20.470961],
                    [72.821533, 20.37108],
                    [72.73764, 20.136539],
                    [72.970428, 20.21233],
                    [73.216316, 20.122],
                    [73.426826, 20.201571],
                    [73.482613, 20.71723],
                    [73.748207, 20.56736],
                    [73.920906, 20.72353],
                    [73.906448, 20.98315],
                    [73.628723, 21.12015],
                    [74.309967, 21.567322],
                    [73.860786, 21.495823],
                    [73.810516, 21.823338],
                    [74.146019, 21.95406],
                    [74.071983, 22.360241],
                    [74.464943, 22.815081],
                    [74.322777, 23.062691],
                ],
            ],
        },
        properties: {
            ID_0: 105,
            ISO: 'IND',
            NAME_0: 'India',
            ID_1: 12,
            NAME_1: 'Gujarat',
            NL_NAME_1: '',
            VARNAME_1: 'Goudjerate|Gujerat|Gujerate',
            TYPE_1: 'State',
            ENGTYPE_1: 'State',
            filename: '',
            filename_1: '',
            filename_2: '',
            filename_3: '',
            filename_4: '',
        },
    },
    Haryana: {
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates: [
                [
                    [77.57489, 30.38452],
                    [77.202606, 30.480341],
                    [77.153526, 30.688721],
                    [76.769859, 30.906391],
                    [76.828186, 30.76379],
                    [76.819809, 30.685829],
                    [76.832458, 30.433882],
                    [76.189377, 30.00419],
                    [76.041481, 29.748781],
                    [75.444267, 29.80785],
                    [75.290649, 29.561762],
                    [75.102676, 29.89702],
                    [74.519066, 29.94335],
                    [74.57003, 29.406158],
                    [74.849518, 29.40279],
                    [75.108177, 29.22677],
                    [75.395706, 29.26194],
                    [75.55571, 28.614592],
                    [75.922379, 28.36891],
                    [76.027748, 28.172722],
                    [75.928833, 27.916809],
                    [76.205948, 27.84799],
                    [76.359833, 28.143381],
                    [76.546593, 27.971371],
                    [76.863632, 28.22464],
                    [76.961761, 28.144011],
                    [76.883347, 27.724422],
                    [77.27578, 27.806549],
                    [77.469818, 27.89473],
                    [77.53878, 28.25441],
                    [77.337738, 28.50897],
                    [77.165771, 28.408461],
                    [76.837616, 28.55699],
                    [76.942307, 28.820681],
                    [77.186867, 28.866819],
                    [77.085617, 29.6008],
                    [77.179558, 29.902269],
                    [77.57489, 30.38452],
                ],
            ],
        },
        properties: {
            ID_0: 105,
            ISO: 'IND',
            NAME_0: 'India',
            ID_1: 13,
            NAME_1: 'Haryana',
            NL_NAME_1: '',
            VARNAME_1: '',
            TYPE_1: 'State',
            ENGTYPE_1: 'State',
            filename: '',
            filename_1: '',
            filename_2: '',
            filename_3: '',
            filename_4: '',
        },
    },
    'Himachal Pradesh': {
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates: [
                [
                    [78.410759, 32.511742],
                    [78.372772, 32.760151],
                    [77.9767, 32.591942],
                    [77.712486, 32.971802],
                    [77.356476, 32.825272],
                    [76.803459, 33.155689],
                    [76.38456, 33.182892],
                    [75.933777, 32.884312],
                    [75.872543, 32.576191],
                    [75.925926, 32.411781],
                    [75.622543, 32.23468],
                    [75.894547, 31.9498],
                    [76.172829, 31.30674],
                    [76.359146, 31.41563],
                    [76.769859, 30.906391],
                    [77.153526, 30.688721],
                    [77.202606, 30.480341],
                    [77.57489, 30.38452],
                    [77.563057, 30.40449],
                    [77.799179, 30.511391],
                    [77.693947, 30.74617],
                    [77.887489, 31.154701],
                    [78.298363, 31.28821],
                    [78.470108, 31.203751],
                    [78.906883, 31.25919],
                    [78.72126, 31.540951],
                    [78.771027, 31.999811],
                    [78.439209, 32.24646],
                    [78.410759, 32.511742],
                ],
            ],
        },
        properties: {
            ID_0: 105,
            ISO: 'IND',
            NAME_0: 'India',
            ID_1: 14,
            NAME_1: 'Himachal Pradesh',
            NL_NAME_1: '',
            VARNAME_1: '',
            TYPE_1: 'Union Territor',
            ENGTYPE_1: 'Union Territory',
            filename: '',
            filename_1: '',
            filename_2: '',
            filename_3: '',
            filename_4: '',
        },
    },
    'Jammu and Kashmir': {
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates: [
                [
                    [78.410759, 32.511742],
                    [78.760277, 32.68417],
                    [78.970963, 32.34428],
                    [79.545357, 32.607361],
                    [79.365036, 32.956921],
                    [79.399086, 33.187149],
                    [79.141441, 33.2001],
                    [78.709297, 33.679611],
                    [78.729233, 34.095699],
                    [78.990051, 34.317829],
                    [78.569336, 34.61195],
                    [78.284363, 34.667362],
                    [77.990288, 35.379032],
                    [77.846779, 35.500782],
                    [77.010139, 34.956287],
                    [76.738861, 34.901928],
                    [75.749207, 34.515831],
                    [74.30278, 34.797539],
                    [73.96022, 34.696682],
                    [73.763222, 34.359631],
                    [73.976227, 34.264809],
                    [73.89817, 34.032822],
                    [74.261124, 33.924797],
                    [73.966461, 33.740322],
                    [74.185081, 33.383511],
                    [74.014275, 33.19825],
                    [74.337921, 33.02594],
                    [74.531532, 32.74073],
                    [74.700386, 32.82703],
                    [74.698418, 32.48494],
                    [75.082626, 32.480045],
                    [75.322327, 32.337326],
                    [75.711533, 32.418159],
                    [75.872543, 32.576191],
                    [75.933777, 32.884312],
                    [76.38456, 33.182892],
                    [76.803459, 33.155689],
                    [77.356476, 32.825272],
                    [77.712486, 32.971802],
                    [77.9767, 32.591942],
                    [78.372772, 32.760151],
                    [78.410759, 32.511742],
                ],
            ],
        },
        properties: {
            ID_0: 105,
            ISO: 'IND',
            NAME_0: 'India',
            ID_1: 15,
            NAME_1: 'Jammu and Kashmir',
            NL_NAME_1: '',
            VARNAME_1: '',
            TYPE_1: 'State',
            ENGTYPE_1: 'State',
            filename: '',
            filename_1: '',
            filename_2: '',
            filename_3: '',
            filename_4: '',
        },
    },
    Jharkhand: {
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates: [
                [
                    [87.787582, 25.22049],
                    [87.58007, 25.348391],
                    [87.176643, 25.06402],
                    [87.044403, 24.624031],
                    [86.523529, 24.535749],
                    [86.4618, 24.37672],
                    [86.050583, 24.779541],
                    [85.73674, 24.822651],
                    [85.673523, 24.595631],
                    [84.898033, 24.372129],
                    [84.832626, 24.51148],
                    [84.521523, 24.376261],
                    [83.928459, 24.547911],
                    [83.498169, 24.52706],
                    [83.323563, 24.10133],
                    [83.509628, 24.0254],
                    [83.774887, 23.599501],
                    [84.007027, 23.632311],
                    [83.966888, 23.374229],
                    [84.148003, 22.963869],
                    [84.370537, 22.975071],
                    [84.232239, 22.68858],
                    [84.002502, 22.520861],
                    [84.289223, 22.33732],
                    [85.051399, 22.47942],
                    [85.023743, 22.111992],
                    [85.410286, 22.15608],
                    [85.894173, 21.978291],
                    [86.026718, 22.185223],
                    [86.03849, 22.563589],
                    [86.722786, 22.215248],
                    [86.885681, 22.252121],
                    [86.755989, 22.57427],
                    [86.416153, 22.776508],
                    [86.497353, 22.989908],
                    [86.212479, 22.99185],
                    [85.835007, 23.192101],
                    [85.874123, 23.476231],
                    [86.148193, 23.56542],
                    [86.351837, 23.463631],
                    [86.444908, 23.631533],
                    [86.792038, 23.68737],
                    [86.895012, 23.879951],
                    [87.148743, 23.794371],
                    [87.689568, 24.149839],
                    [87.905449, 24.583801],
                    [87.961693, 24.93593],
                    [87.787582, 25.22049],
                ],
            ],
        },
        properties: {
            ID_0: 105,
            ISO: 'IND',
            NAME_0: 'India',
            ID_1: 16,
            NAME_1: 'Jharkhand',
            NL_NAME_1: '',
            VARNAME_1: 'Vananchal',
            TYPE_1: 'State',
            ENGTYPE_1: 'State',
            filename: '',
            filename_1: '',
            filename_2: '',
            filename_3: '',
            filename_4: '',
        },
    },
    Karnataka: {
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates: [
                [
                    [77.549957, 18.291349],
                    [77.240227, 18.408648],
                    [77.108887, 18.152691],
                    [76.954109, 18.184891],
                    [76.916443, 17.915091],
                    [76.331673, 17.59487],
                    [76.408066, 17.369289],
                    [75.931152, 17.32411],
                    [75.63559, 17.47751],
                    [75.678421, 16.963942],
                    [74.938393, 16.94099],
                    [74.92382, 16.769951],
                    [74.253159, 16.494881],
                    [74.508331, 16.22294],
                    [74.350937, 15.76336],
                    [74.12603, 15.65029],
                    [74.255257, 15.65421],
                    [74.302338, 15.03986],
                    [74.097359, 14.895659],
                    [74.355972, 14.522079],
                    [74.606796, 13.866529],
                    [74.867081, 12.754452],
                    [75.161293, 12.664071],
                    [75.430733, 12.292002],
                    [75.876747, 11.940712],
                    [76.10791, 11.970639],
                    [76.423462, 11.659321],
                    [76.850906, 11.60183],
                    [76.899689, 11.783272],
                    [77.41925, 11.750671],
                    [77.663712, 11.95296],
                    [77.726357, 12.16732],
                    [77.465668, 12.202261],
                    [77.603828, 12.353751],
                    [77.59034, 12.657541],
                    [77.924286, 12.87784],
                    [78.22065, 12.75259],
                    [78.569077, 13.156483],
                    [78.355156, 13.355071],
                    [78.380318, 13.581309],
                    [78.183273, 13.55638],
                    [77.961273, 13.949971],
                    [77.521523, 13.68787],
                    [77.240097, 13.89408],
                    [77.479126, 14.283741],
                    [77.152153, 14.337741],
                    [76.937843, 14.240121],
                    [76.76091, 14.596281],
                    [76.876701, 15.028679],
                    [77.109573, 15.028569],
                    [77.038033, 15.358782],
                    [77.076797, 15.90974],
                    [77.509453, 15.92086],
                    [77.487556, 16.249821],
                    [77.28466, 16.403],
                    [77.49939, 17.00732],
                    [77.380356, 17.21977],
                    [77.53347, 17.44095],
                    [77.439926, 17.58147],
                    [77.646179, 17.9932],
                    [77.549957, 18.291349],
                ],
            ],
        },
        properties: {
            ID_0: 105,
            ISO: 'IND',
            NAME_0: 'India',
            ID_1: 17,
            NAME_1: 'Karnataka',
            NL_NAME_1: '',
            VARNAME_1: 'Maisur|Mysore',
            TYPE_1: 'State',
            ENGTYPE_1: 'State',
            filename: '',
            filename_1: '',
            filename_2: '',
            filename_3: '',
            filename_4: '',
        },
    },
    Kerala: {
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates: [
                [
                    [76.423462, 11.659321],
                    [76.10791, 11.970639],
                    [75.876747, 11.940712],
                    [75.430733, 12.292002],
                    [75.161293, 12.664071],
                    [74.867081, 12.754452],
                    [75.230698, 12.02414],
                    [75.311668, 12.049306],
                    [75.828476, 11.122361],
                    [76.22403, 9.977079],
                    [76.349297, 9.384582],
                    [76.541252, 8.915422],
                    [77.090698, 8.29747],
                    [77.26516, 8.53745],
                    [77.132179, 9.01185],
                    [77.396233, 9.496542],
                    [77.155502, 9.601501],
                    [77.280449, 10.207252],
                    [77.162956, 10.346281],
                    [76.819672, 10.29524],
                    [76.897507, 10.7708],
                    [76.652786, 10.91996],
                    [76.686012, 11.157701],
                    [76.438026, 11.19455],
                    [76.5382, 11.35159],
                    [76.225746, 11.56444],
                    [76.423462, 11.659321],
                ],
            ],
        },
        properties: {
            ID_0: 105,
            ISO: 'IND',
            NAME_0: 'India',
            ID_1: 18,
            NAME_1: 'Kerala',
            NL_NAME_1: '',
            VARNAME_1: '',
            TYPE_1: 'State',
            ENGTYPE_1: 'State',
            filename: '',
            filename_1: '',
            filename_2: '',
            filename_3: '',
            filename_4: '',
        },
    },
    Lakshadweep: {
        type: 'Feature',
        geometry: null,
        properties: {
            ID_0: 105,
            ISO: 'IND',
            NAME_0: 'India',
            ID_1: 19,
            NAME_1: 'Lakshadweep',
            NL_NAME_1: '',
            VARNAME_1: 'les Laquedives|Laccadive|Minicoy and Amindivi Islands|Laccadives|Lackadiverna|Lakkadiven|Lakkadi',
            TYPE_1: 'Union Territor',
            ENGTYPE_1: 'Union Territory',
            filename: '',
            filename_1: '',
            filename_2: '',
            filename_3: '',
            filename_4: '',
        },
    },
    'Madhya Pradesh': {
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates: [
                [
                    [82.807777, 23.963058],
                    [82.66555, 24.699862],
                    [82.229927, 24.66964],
                    [81.899971, 25.00724],
                    [81.268646, 25.16519],
                    [81.128166, 24.89362],
                    [80.856613, 24.935951],
                    [80.836281, 25.19268],
                    [80.266937, 25.030243],
                    [80.423698, 25.174143],
                    [80.193916, 25.416231],
                    [79.84523, 25.236551],
                    [79.827217, 25.100121],
                    [79.260139, 25.31024],
                    [79.133377, 25.111061],
                    [78.986771, 25.27496],
                    [78.528061, 25.365881],
                    [78.776901, 24.59309],
                    [78.987778, 24.427971],
                    [78.784599, 24.185141],
                    [78.266876, 24.447371],
                    [78.170448, 24.885269],
                    [78.442917, 25.128771],
                    [78.294228, 25.36762],
                    [78.481087, 25.580433],
                    [78.805191, 25.624557],
                    [78.746819, 25.74909],
                    [79.132576, 26.34276],
                    [78.983887, 26.685711],
                    [78.741081, 26.792521],
                    [78.209793, 26.83609],
                    [77.522881, 26.414911],
                    [76.887779, 26.093323],
                    [76.529312, 25.797972],
                    [76.602547, 25.388981],
                    [76.952721, 25.278551],
                    [77.305298, 25.436411],
                    [77.386673, 25.12196],
                    [76.882027, 25.0341],
                    [76.800667, 24.82456],
                    [77.059456, 24.64242],
                    [76.81459, 24.53437],
                    [76.943863, 24.198851],
                    [76.352997, 24.248911],
                    [75.651833, 23.801949],
                    [75.514381, 24.048733],
                    [75.835793, 24.111351],
                    [75.738571, 24.3953],
                    [75.903389, 24.446131],
                    [75.838753, 24.72987],
                    [75.228409, 24.735102],
                    [75.349617, 25.041731],
                    [75.118866, 24.88904],
                    [74.83271, 24.967451],
                    [74.711937, 24.510941],
                    [74.857498, 24.451502],
                    [74.992989, 24.029701],
                    [74.851479, 23.5574],
                    [74.57402, 23.422543],
                    [74.695541, 23.22859],
                    [74.322777, 23.062691],
                    [74.464943, 22.815081],
                    [74.071983, 22.360241],
                    [74.146019, 21.95406],
                    [74.435547, 22.030998],
                    [74.564766, 21.67985],
                    [75.057747, 21.564939],
                    [75.301903, 21.38925],
                    [76.098671, 21.373928],
                    [76.183861, 21.089951],
                    [76.623367, 21.192659],
                    [76.795349, 21.59754],
                    [77.477768, 21.770222],
                    [77.487022, 21.37775],
                    [77.89904, 21.37842],
                    [78.422562, 21.60195],
                    [78.72419, 21.46438],
                    [78.913239, 21.59236],
                    [79.488579, 21.674122],
                    [79.536621, 21.544218],
                    [80.188217, 21.634201],
                    [80.404442, 21.375811],
                    [80.656532, 21.330772],
                    [80.87896, 22.0606],
                    [80.978851, 22.048311],
                    [81.109993, 22.44112],
                    [81.396553, 22.44132],
                    [81.784279, 22.766621],
                    [82.184288, 23.326891],
                    [81.916359, 23.532091],
                    [81.569733, 23.58865],
                    [81.811729, 23.8095],
                    [82.544518, 23.794189],
                    [82.807777, 23.963058],
                ],
            ],
        },
        properties: {
            ID_0: 105,
            ISO: 'IND',
            NAME_0: 'India',
            ID_1: 20,
            NAME_1: 'Madhya Pradesh',
            NL_NAME_1: '',
            VARNAME_1: '',
            TYPE_1: 'State',
            ENGTYPE_1: 'State',
            filename: '',
            filename_1: '',
            filename_2: '',
            filename_3: '',
            filename_4: '',
        },
    },
    Manipur: {
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates: [
                [
                    [94.682426, 25.45973],
                    [94.596947, 25.686911],
                    [94.302643, 25.494633],
                    [94.010399, 25.5954],
                    [93.608017, 25.202051],
                    [93.474022, 25.30928],
                    [93.112701, 24.80221],
                    [93.000496, 24.403271],
                    [92.976379, 24.11482],
                    [93.337372, 24.0534],
                    [93.756943, 24.009493],
                    [94.161461, 23.852779],
                    [94.262718, 24.167747],
                    [94.71907, 24.942471],
                    [94.585358, 25.21575],
                    [94.682426, 25.45973],
                ],
            ],
        },
        properties: {
            ID_0: 105,
            ISO: 'IND',
            NAME_0: 'India',
            ID_1: 22,
            NAME_1: 'Manipur',
            NL_NAME_1: '',
            VARNAME_1: '',
            TYPE_1: 'State',
            ENGTYPE_1: 'State',
            filename: '',
            filename_1: '',
            filename_2: '',
            filename_3: '',
            filename_4: '',
        },
    },
    Meghalaya: {
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates: [
                [
                    [92.425903, 25.03182],
                    [92.803566, 25.217239],
                    [92.385101, 25.753563],
                    [92.169762, 25.666401],
                    [92.301582, 26.07649],
                    [91.720177, 25.954071],
                    [91.57003, 26.027481],
                    [91.223877, 25.723419],
                    [90.957916, 25.933943],
                    [90.477287, 26.015261],
                    [90.120003, 25.95772],
                    [89.870102, 25.544222],
                    [89.873947, 25.541937],
                    [89.871452, 25.537298],
                    [89.85511, 25.476049],
                    [89.847397, 25.310251],
                    [90.454979, 25.14443],
                    [91.273788, 25.204308],
                    [91.470169, 25.134844],
                    [92.065552, 25.190031],
                    [92.425903, 25.03182],
                ],
            ],
        },
        properties: {
            ID_0: 105,
            ISO: 'IND',
            NAME_0: 'India',
            ID_1: 23,
            NAME_1: 'Meghalaya',
            NL_NAME_1: '',
            VARNAME_1: '',
            TYPE_1: 'State',
            ENGTYPE_1: 'State',
            filename: '',
            filename_1: '',
            filename_2: '',
            filename_3: '',
            filename_4: '',
        },
    },
    Mizoram: {
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates: [
                [
                    [93.000496, 24.403271],
                    [92.765198, 24.520849],
                    [92.466827, 24.13504],
                    [92.296608, 24.25173],
                    [92.268501, 23.728611],
                    [92.40863, 23.242243],
                    [92.375778, 22.933762],
                    [92.531746, 22.656141],
                    [92.60968, 21.976837],
                    [92.703438, 22.153782],
                    [93.009247, 21.983931],
                    [93.162956, 22.182341],
                    [93.091751, 22.709341],
                    [93.141853, 23.06061],
                    [93.332642, 23.035671],
                    [93.427643, 23.53437],
                    [93.337372, 24.0534],
                    [92.976379, 24.11482],
                    [93.000496, 24.403271],
                ],
            ],
        },
        properties: {
            ID_0: 105,
            ISO: 'IND',
            NAME_0: 'India',
            ID_1: 24,
            NAME_1: 'Mizoram',
            NL_NAME_1: '',
            VARNAME_1: '',
            TYPE_1: 'State',
            ENGTYPE_1: 'State',
            filename: '',
            filename_1: '',
            filename_2: '',
            filename_3: '',
            filename_4: '',
        },
    },
    Nagaland: {
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates: [
                [
                    [95.237633, 26.686291],
                    [95.195518, 27.04295],
                    [94.886223, 26.932461],
                    [94.282288, 26.56354],
                    [94.013, 26.186281],
                    [93.982681, 25.92627],
                    [93.706436, 25.853291],
                    [93.331741, 25.54681],
                    [93.474022, 25.30928],
                    [93.608017, 25.202051],
                    [94.010399, 25.5954],
                    [94.302643, 25.494633],
                    [94.596947, 25.686911],
                    [94.682426, 25.45973],
                    [95.052528, 25.762659],
                    [95.126717, 26.101],
                    [95.068787, 26.466673],
                    [95.237633, 26.686291],
                ],
            ],
        },
        properties: {
            ID_0: 105,
            ISO: 'IND',
            NAME_0: 'India',
            ID_1: 25,
            NAME_1: 'Nagaland',
            NL_NAME_1: '',
            VARNAME_1: '',
            TYPE_1: 'State',
            ENGTYPE_1: 'State',
            filename: '',
            filename_1: '',
            filename_2: '',
            filename_3: '',
            filename_4: '',
        },
    },
    Orissa: {
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates: [
                [
                    [86.722786, 22.215248],
                    [86.03849, 22.563589],
                    [86.026718, 22.185223],
                    [85.894173, 21.978291],
                    [85.410286, 22.15608],
                    [85.023743, 22.111992],
                    [85.051399, 22.47942],
                    [84.289223, 22.33732],
                    [84.002502, 22.520861],
                    [83.992973, 22.36924],
                    [83.645752, 22.224588],
                    [83.600479, 21.910841],
                    [83.380722, 21.612751],
                    [83.374817, 21.33996],
                    [83.119843, 21.101061],
                    [82.635941, 21.14945],
                    [82.324013, 20.553881],
                    [82.429169, 20.425753],
                    [82.395767, 20.049],
                    [82.71093, 19.983261],
                    [82.698441, 19.82686],
                    [81.944206, 20.101603],
                    [81.858406, 19.899549],
                    [82.052193, 19.791342],
                    [82.239563, 18.91046],
                    [81.954529, 18.58671],
                    [81.527962, 18.259081],
                    [81.384972, 17.806593],
                    [81.611153, 17.815363],
                    [82.024658, 18.05788],
                    [82.26725, 17.986441],
                    [82.474007, 18.536833],
                    [82.580742, 18.2701],
                    [83.066498, 18.39226],
                    [83.132957, 18.772449],
                    [83.411339, 18.859341],
                    [83.622719, 19.144688],
                    [83.886879, 18.805408],
                    [84.312492, 18.77919],
                    [84.420021, 19.01252],
                    [84.719032, 19.095844],
                    [85.295288, 19.553612],
                    [85.663612, 19.739168],
                    [86.365837, 19.9575],
                    [86.769173, 20.331112],
                    [86.76667, 20.644444],
                    [86.970001, 20.827778],
                    [86.824722, 21.14139],
                    [87.113892, 21.508331],
                    [87.482033, 21.608704],
                    [87.234848, 21.95438],
                    [87.094643, 21.86034],
                    [86.722786, 22.215248],
                ],
            ],
        },
        properties: {
            ID_0: 105,
            ISO: 'IND',
            NAME_0: 'India',
            ID_1: 26,
            NAME_1: 'Orissa',
            NL_NAME_1: '',
            VARNAME_1: '',
            TYPE_1: 'State',
            ENGTYPE_1: 'State',
            filename: '',
            filename_1: '',
            filename_2: '',
            filename_3: '',
            filename_4: '',
        },
    },
    Puducherry: {
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates: [
                [
                    [82.299515, 16.714333],
                    [82.257637, 16.690113],
                    [82.299385, 16.714029],
                    [82.299515, 16.714333],
                ],
            ],
        },
        properties: {
            ID_0: 105,
            ISO: 'IND',
            NAME_0: 'India',
            ID_1: 27,
            NAME_1: 'Puducherry',
            NL_NAME_1: '',
            VARNAME_1: 'Pondicherry|Puduchcheri|Pondichry',
            TYPE_1: 'Union Territor',
            ENGTYPE_1: 'Union Territory',
            filename: '',
            filename_1: '',
            filename_2: '',
            filename_3: '',
            filename_4: '',
        },
    },
    Punjab: {
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates: [
                [
                    [76.769859, 30.906391],
                    [76.359146, 31.41563],
                    [76.172829, 31.30674],
                    [75.894547, 31.9498],
                    [75.622543, 32.23468],
                    [75.925926, 32.411781],
                    [75.872543, 32.576191],
                    [75.711533, 32.418159],
                    [75.322327, 32.337326],
                    [75.22776, 32.084557],
                    [74.862251, 32.045109],
                    [74.517281, 31.722912],
                    [74.642067, 31.46155],
                    [74.553444, 31.062601],
                    [73.873215, 30.383438],
                    [73.966316, 30.195049],
                    [73.891182, 29.97007],
                    [74.519066, 29.94335],
                    [75.102676, 29.89702],
                    [75.290649, 29.561762],
                    [75.444267, 29.80785],
                    [76.041481, 29.748781],
                    [76.189377, 30.00419],
                    [76.832458, 30.433882],
                    [76.819809, 30.685829],
                    [76.828186, 30.76379],
                    [76.769859, 30.906391],
                ],
            ],
        },
        properties: {
            ID_0: 105,
            ISO: 'IND',
            NAME_0: 'India',
            ID_1: 28,
            NAME_1: 'Punjab',
            NL_NAME_1: '',
            VARNAME_1: '',
            TYPE_1: 'State',
            ENGTYPE_1: 'State',
            filename: '',
            filename_1: '',
            filename_2: '',
            filename_3: '',
            filename_4: '',
        },
    },
    Rajasthan: {
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates: [
                [
                    [74.519066, 29.94335],
                    [73.891182, 29.97007],
                    [73.966316, 30.195049],
                    [73.385399, 29.92602],
                    [73.271317, 29.556961],
                    [72.943756, 29.029911],
                    [72.384697, 28.763941],
                    [71.897377, 27.95742],
                    [71.658287, 27.868931],
                    [70.757668, 27.717209],
                    [70.56263, 28.019802],
                    [70.366165, 28.008249],
                    [70.016266, 27.555996],
                    [69.586662, 27.177984],
                    [69.522415, 26.736012],
                    [70.16774, 26.550743],
                    [70.106682, 25.92421],
                    [70.285263, 25.698931],
                    [70.665962, 25.697229],
                    [70.674171, 25.38932],
                    [70.89431, 25.144058],
                    [71.10714, 24.680651],
                    [71.297737, 24.607641],
                    [72.0849, 24.652752],
                    [72.732536, 24.361271],
                    [73.094383, 24.494728],
                    [73.082687, 24.18498],
                    [73.413757, 24.051069],
                    [73.359848, 23.85463],
                    [73.632751, 23.452393],
                    [73.826889, 23.44684],
                    [74.322777, 23.062691],
                    [74.695541, 23.22859],
                    [74.57402, 23.422543],
                    [74.851479, 23.5574],
                    [74.992989, 24.029701],
                    [74.857498, 24.451502],
                    [74.711937, 24.510941],
                    [74.83271, 24.967451],
                    [75.118866, 24.88904],
                    [75.349617, 25.041731],
                    [75.228409, 24.735102],
                    [75.838753, 24.72987],
                    [75.903389, 24.446131],
                    [75.738571, 24.3953],
                    [75.835793, 24.111351],
                    [75.514381, 24.048733],
                    [75.651833, 23.801949],
                    [76.352997, 24.248911],
                    [76.943863, 24.198851],
                    [76.81459, 24.53437],
                    [77.059456, 24.64242],
                    [76.800667, 24.82456],
                    [76.882027, 25.0341],
                    [77.386673, 25.12196],
                    [77.305298, 25.436411],
                    [76.952721, 25.278551],
                    [76.602547, 25.388981],
                    [76.529312, 25.797972],
                    [76.887779, 26.093323],
                    [77.522881, 26.414911],
                    [78.209793, 26.83609],
                    [78.139473, 26.95713],
                    [77.773483, 26.93627],
                    [77.526543, 27.066509],
                    [77.672997, 27.200361],
                    [77.340263, 27.524773],
                    [77.27578, 27.806549],
                    [76.883347, 27.724422],
                    [76.961761, 28.144011],
                    [76.863632, 28.22464],
                    [76.546593, 27.971371],
                    [76.359833, 28.143381],
                    [76.205948, 27.84799],
                    [75.928833, 27.916809],
                    [76.027748, 28.172722],
                    [75.922379, 28.36891],
                    [75.55571, 28.614592],
                    [75.395706, 29.26194],
                    [75.108177, 29.22677],
                    [74.849518, 29.40279],
                    [74.57003, 29.406158],
                    [74.519066, 29.94335],
                ],
            ],
        },
        properties: {
            ID_0: 105,
            ISO: 'IND',
            NAME_0: 'India',
            ID_1: 29,
            NAME_1: 'Rajasthan',
            NL_NAME_1: '',
            VARNAME_1: 'Greater Rajasthan|Rajputana',
            TYPE_1: 'State',
            ENGTYPE_1: 'State',
            filename: '',
            filename_1: '',
            filename_2: '',
            filename_3: '',
            filename_4: '',
        },
    },
    Sikkim: {
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates: [
                [
                    [88.751686, 27.148211],
                    [88.922371, 27.326321],
                    [88.767067, 27.56496],
                    [88.880188, 27.895241],
                    [88.616463, 28.10368],
                    [88.139061, 27.96348],
                    [88.199921, 27.790529],
                    [88.016968, 27.218872],
                    [88.431946, 27.08161],
                    [88.751686, 27.148211],
                ],
            ],
        },
        properties: {
            ID_0: 105,
            ISO: 'IND',
            NAME_0: 'India',
            ID_1: 30,
            NAME_1: 'Sikkim',
            NL_NAME_1: '',
            VARNAME_1: '',
            TYPE_1: 'State',
            ENGTYPE_1: 'State',
            filename: '',
            filename_1: '',
            filename_2: '',
            filename_3: '',
            filename_4: '',
        },
    },
    'Tamil Nadu': {
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates: [
                [
                    [80.312996, 13.427916],
                    [80.069443, 13.537591],
                    [79.783257, 13.228162],
                    [79.454643, 13.324321],
                    [79.152702, 13.007293],
                    [78.702446, 13.056151],
                    [78.547676, 12.685591],
                    [78.22065, 12.75259],
                    [77.924286, 12.87784],
                    [77.59034, 12.657541],
                    [77.603828, 12.353751],
                    [77.465668, 12.202261],
                    [77.726357, 12.16732],
                    [77.663712, 11.95296],
                    [77.41925, 11.750671],
                    [76.899689, 11.783272],
                    [76.850906, 11.60183],
                    [76.423462, 11.659321],
                    [76.225746, 11.56444],
                    [76.5382, 11.35159],
                    [76.438026, 11.19455],
                    [76.686012, 11.157701],
                    [76.652786, 10.91996],
                    [76.897507, 10.7708],
                    [76.819672, 10.29524],
                    [77.162956, 10.346281],
                    [77.280449, 10.207252],
                    [77.155502, 9.601501],
                    [77.396233, 9.496542],
                    [77.132179, 9.01185],
                    [77.26516, 8.53745],
                    [77.090698, 8.29747],
                    [77.552361, 8.077921],
                    [78.066803, 8.370416],
                    [78.208191, 8.958194],
                    [78.41153, 9.112084],
                    [79.065407, 9.299581],
                    [78.934288, 9.606811],
                    [79.396797, 10.31792],
                    [79.884033, 10.30597],
                    [79.85125, 10.813771],
                    [79.854576, 10.976022],
                    [79.761253, 11.620972],
                    [79.800415, 11.808399],
                    [79.837639, 11.94055],
                    [80.157082, 12.469861],
                    [80.345703, 13.327641],
                    [80.312996, 13.427916],
                ],
            ],
        },
        properties: {
            ID_0: 105,
            ISO: 'IND',
            NAME_0: 'India',
            ID_1: 31,
            NAME_1: 'Tamil Nadu',
            NL_NAME_1: '',
            VARNAME_1: 'Madras|Tamilnad',
            TYPE_1: 'State',
            ENGTYPE_1: 'State',
            filename: '',
            filename_1: '',
            filename_2: '',
            filename_3: '',
            filename_4: '',
        },
    },
    Tripura: {
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates: [
                [
                    [92.230995, 24.504091],
                    [91.964577, 24.38184],
                    [91.888252, 24.13703],
                    [91.375427, 24.107531],
                    [91.169006, 23.595133],
                    [91.330338, 23.157673],
                    [91.407707, 23.253563],
                    [91.618713, 22.944151],
                    [91.834518, 23.091881],
                    [91.774849, 23.278061],
                    [91.976059, 23.49596],
                    [91.942307, 23.681808],
                    [92.268501, 23.728611],
                    [92.296608, 24.25173],
                    [92.230995, 24.504091],
                ],
            ],
        },
        properties: {
            ID_0: 105,
            ISO: 'IND',
            NAME_0: 'India',
            ID_1: 32,
            NAME_1: 'Tripura',
            NL_NAME_1: '',
            VARNAME_1: '',
            TYPE_1: 'State',
            ENGTYPE_1: 'State',
            filename: '',
            filename_1: '',
            filename_2: '',
            filename_3: '',
            filename_4: '',
        },
    },
    'Uttar Pradesh': {
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates: [
                [
                    [80.07238, 28.829744],
                    [79.411217, 28.855881],
                    [79.14222, 29.119728],
                    [78.714127, 29.31933],
                    [78.900406, 29.44669],
                    [78.331741, 29.796181],
                    [78.036507, 29.58086],
                    [77.703262, 29.86767],
                    [77.93148, 30.24696],
                    [77.563057, 30.40449],
                    [77.57489, 30.38452],
                    [77.179558, 29.902269],
                    [77.085617, 29.6008],
                    [77.186867, 28.866819],
                    [77.337738, 28.50897],
                    [77.53878, 28.25441],
                    [77.469818, 27.89473],
                    [77.27578, 27.806549],
                    [77.340263, 27.524773],
                    [77.672997, 27.200361],
                    [77.526543, 27.066509],
                    [77.773483, 26.93627],
                    [78.139473, 26.95713],
                    [78.209793, 26.83609],
                    [78.741081, 26.792521],
                    [78.983887, 26.685711],
                    [79.132576, 26.34276],
                    [78.746819, 25.74909],
                    [78.805191, 25.624557],
                    [78.481087, 25.580433],
                    [78.294228, 25.36762],
                    [78.442917, 25.128771],
                    [78.170448, 24.885269],
                    [78.266876, 24.447371],
                    [78.784599, 24.185141],
                    [78.987778, 24.427971],
                    [78.776901, 24.59309],
                    [78.528061, 25.365881],
                    [78.986771, 25.27496],
                    [79.133377, 25.111061],
                    [79.260139, 25.31024],
                    [79.827217, 25.100121],
                    [79.84523, 25.236551],
                    [80.193916, 25.416231],
                    [80.423698, 25.174143],
                    [80.266937, 25.030243],
                    [80.836281, 25.19268],
                    [80.856613, 24.935951],
                    [81.128166, 24.89362],
                    [81.268646, 25.16519],
                    [81.899971, 25.00724],
                    [82.229927, 24.66964],
                    [82.66555, 24.699862],
                    [82.807777, 23.963058],
                    [83.127411, 23.89002],
                    [83.323563, 24.10133],
                    [83.498169, 24.52706],
                    [83.316498, 25.038382],
                    [83.409271, 25.249281],
                    [83.782272, 25.398729],
                    [84.148232, 25.730961],
                    [84.488388, 25.677851],
                    [84.621117, 25.79236],
                    [84.049057, 26.099701],
                    [84.171356, 26.37398],
                    [83.902206, 26.51808],
                    [84.080421, 26.640753],
                    [84.414398, 26.627171],
                    [84.024902, 26.94272],
                    [83.858505, 27.350962],
                    [83.39093, 27.47949],
                    [82.737007, 27.503101],
                    [82.709915, 27.721699],
                    [82.450714, 27.678616],
                    [82.072884, 27.922525],
                    [81.886848, 27.857159],
                    [81.322411, 28.197168],
                    [81.212418, 28.360516],
                    [80.716301, 28.567898],
                    [80.524414, 28.552715],
                    [80.07238, 28.829744],
                ],
            ],
        },
        properties: {
            ID_0: 105,
            ISO: 'IND',
            NAME_0: 'India',
            ID_1: 33,
            NAME_1: 'Uttar Pradesh',
            NL_NAME_1: '',
            VARNAME_1: 'United Provinces',
            TYPE_1: 'State',
            ENGTYPE_1: 'State',
            filename: '',
            filename_1: '',
            filename_2: '',
            filename_3: '',
            filename_4: '',
        },
    },
    Uttaranchal: {
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates: [
                [
                    [80.07238, 28.829744],
                    [80.274406, 29.144751],
                    [80.243347, 29.411587],
                    [80.494118, 29.795315],
                    [80.994057, 30.27046],
                    [80.21096, 30.5823],
                    [80.15937, 30.81209],
                    [79.738083, 31.0061],
                    [79.589577, 30.942888],
                    [79.056778, 31.47226],
                    [78.906883, 31.25919],
                    [78.470108, 31.203751],
                    [78.298363, 31.28821],
                    [77.887489, 31.154701],
                    [77.693947, 30.74617],
                    [77.799179, 30.511391],
                    [77.563057, 30.40449],
                    [77.93148, 30.24696],
                    [77.703262, 29.86767],
                    [78.036507, 29.58086],
                    [78.331741, 29.796181],
                    [78.900406, 29.44669],
                    [78.714127, 29.31933],
                    [79.14222, 29.119728],
                    [79.411217, 28.855881],
                    [80.07238, 28.829744],
                ],
            ],
        },
        properties: {
            ID_0: 105,
            ISO: 'IND',
            NAME_0: 'India',
            ID_1: 34,
            NAME_1: 'Uttaranchal',
            NL_NAME_1: '',
            VARNAME_1: 'Uttarakhand',
            TYPE_1: 'State',
            ENGTYPE_1: 'State',
            filename: '',
            filename_1: '',
            filename_2: '',
            filename_3: '',
            filename_4: '',
        },
    },
    'West Bengal': {
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates: [
                [
                    [88.751686, 27.148211],
                    [88.431946, 27.08161],
                    [88.016968, 27.218872],
                    [88.190498, 26.771412],
                    [88.105431, 26.539408],
                    [88.106216, 26.536015],
                    [88.106148, 26.535624],
                    [88.291916, 26.35391],
                    [87.842117, 26.044481],
                    [88.070412, 25.480501],
                    [87.786522, 25.4487],
                    [87.787582, 25.22049],
                    [87.961693, 24.93593],
                    [87.905449, 24.583801],
                    [87.689568, 24.149839],
                    [87.148743, 23.794371],
                    [86.895012, 23.879951],
                    [86.792038, 23.68737],
                    [86.444908, 23.631533],
                    [86.351837, 23.463631],
                    [86.148193, 23.56542],
                    [85.874123, 23.476231],
                    [85.835007, 23.192101],
                    [86.212479, 22.99185],
                    [86.497353, 22.989908],
                    [86.416153, 22.776508],
                    [86.755989, 22.57427],
                    [86.885681, 22.252121],
                    [86.722786, 22.215248],
                    [87.094643, 21.86034],
                    [87.234848, 21.95438],
                    [87.482033, 21.608704],
                    [87.789719, 21.695],
                    [88.193611, 22.110832],
                    [88.217224, 21.764444],
                    [88.578331, 21.889444],
                    [88.937607, 22.569933],
                    [88.913467, 23.23],
                    [88.696777, 23.29285],
                    [88.74292, 23.48078],
                    [88.564217, 23.714783],
                    [88.733261, 23.914339],
                    [88.737572, 24.26755],
                    [88.327736, 24.386751],
                    [88.012817, 24.671131],
                    [88.44384, 25.194891],
                    [88.923027, 25.16713],
                    [89.00782, 25.297253],
                    [88.811081, 25.517349],
                    [88.649818, 25.476999],
                    [88.093727, 25.9296],
                    [88.483246, 26.461109],
                    [88.821587, 26.300713],
                    [89.100929, 26.39427],
                    [89.173462, 26.129],
                    [89.591766, 25.99399],
                    [89.718758, 26.166048],
                    [89.857292, 26.38446],
                    [89.856941, 26.703781],
                    [89.359741, 26.863371],
                    [89.129036, 26.809191],
                    [88.751686, 27.148211],
                ],
            ],
        },
        properties: {
            ID_0: 105,
            ISO: 'IND',
            NAME_0: 'India',
            ID_1: 35,
            NAME_1: 'West Bengal',
            NL_NAME_1: '',
            VARNAME_1: 'Bangla|Bengala Occidentale|Bengala Ocidental|Bengale occidental',
            TYPE_1: 'State',
            ENGTYPE_1: 'State',
            filename: '',
            filename_1: '',
            filename_2: '',
            filename_3: '',
            filename_4: '',
        },
    },
};

export const getStatesGeoJSON = (state) => {
    if (!state || state.length === 0) return;
    let states = state.map((item) => {
        return { state: stateGeoJSONLookup[item.name], color: item.color };
    });
    return states;
};
