import React, { useEffect,useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import DetailTag from "../../../pages/project/DetailTag";
import { migrateAssets } from '../../../actions/inventory'
import Form from '../../Inventory/reusableComponents/AddOption/Form';
import Input from '../../Inventory/reusableComponents/AddOption/Input';
import { getCustomerAddonsListAction } from '../../../actions/customer';
import { textCss } from '../../../common/Css';



const AddOwner = ({ modalData, customerId, siteId}) => {
    const formName = 'addOwner';
    const dispatch = useDispatch()

    const customer = useSelector(state => state.customer)
    const { customerAddons } = customer

    let { assetslist, source } = modalData && modalData

    let sourceId, from = 'SITE'
    if (modalData && modalData.from === 'WAREHOUSE') from = 'WAREHOUSE'
    if (source && !isNaN(source.sourceId) && source.sourceId > 0) sourceId = source.sourceId

    const initialValues = {
        from: from,
        sourceId: sourceId,
        to: from,
        destinationId: sourceId
    };
    const [form, setForm] = useState(initialValues)

    useEffect(() => {
        let dropdown = true
        dispatch(getCustomerAddonsListAction(customerId, dropdown, formName))
    }, [])


    const submit = (formValues) => {
        let data = {
            assets: assetslist.map(item => item.id),
            source: { from: initialValues.from, sourceId: initialValues.sourceId },
            destination: { to: initialValues.to, destinationId: initialValues.destinationId },
            owner: { recepient: formValues?.recepient?.value }
        }

        dispatch(migrateAssets({ data, formName, siteId}))
    }

    const closeModal = () => { dispatch(closeModalAction()) }

    return (
        <>
            <Form
                className='w-full'
                submit={(e) => { submit(e) }}
                onCancel={closeModal}
                buttonPostion={'justify-end'}
                initialValues={initialValues}
                form={form}
                setForm={setForm}
                formName={formName}
            >
                {modalData?.type === 'asset' &&
                    <>
                        <div className='px-6 mt-3'>
                            <p className={textCss?.formHighlights}>
                                Current Details
                            </p>
                        </div>
                        <div className="md:flex pl-5 pt-3">
                            <DetailTag tagHeadClass={textCss?.detailsTitle} tagValueClass={textCss?.detailsValue} tagClass=" px-2 md:w-6/12" tagName="Site Id" tagValue={modalData?.siteId} />
                            <DetailTag tagHeadClass={textCss?.detailsTitle} tagValueClass={textCss?.detailsValue} tagClass="px-2 md:w-6/12" tagName="Address" tagValue={modalData?.address} />

                        </div>
                    </>
                }
                <div className='px-3 flex justify-between'>
                    <Input label='Select Owner' required name='recepient' type={'dropdown'} options={customerAddons} placeholder={'Select Owner'} className={'w-full'} />
                </div>

            </Form>

        </>
    );

}

export default AddOwner;