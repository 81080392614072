import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getIdCard, removeIdCardFromStore } from "../../actions/sp";
import config from "../../config";
import QRCode from "qrcode.react";
import { SkeltonCardTwo } from "../../common/SkeltonCardTwo";

export const IdCard = (props) => {
    const { userDetails } = props;
    const dispatch = useDispatch();
    const { idCard } = useSelector((state) => state.sp);
    const { isIdCardLoading } = useSelector((state) => state.loading);
    const { loggedUser } = useSelector((state) => state.auth);
    const loggedUserId = loggedUser.role.id;
    const qrCodeUrl =
        window.location.origin + "/profile/" + idCard?.username + idCard?.userId;

    useEffect(() => {
        dispatch(getIdCard({ userId: userDetails?.id }));

        return () => dispatch(removeIdCardFromStore());
    }, [dispatch, userDetails?.id]);

    const proofType = ({ type }) => {
        let typeOfValidation = "";
        if (type === 1) {
            typeOfValidation = "Aadhaar Card";
        } else if (type === 2) {
            typeOfValidation = "Driving License";
        } else if (type === 3) {
            typeOfValidation = "Voter ID";
        }
        return (
            <p>
                <span>Validated By :</span>
                <span>{typeOfValidation}</span>
            </p>
        );
    };

    const profileImge = idCard?.profile_url
        ? idCard?.profile_url
        : `${config.api.staticAssetsUrl}/images/avatar.jpg`;

    if (isIdCardLoading) {
        return (
            <div className={"px-4"}>
                <SkeltonCardTwo arr={[1, 2, 3, 4, 5, 6]} height={25} />
            </div>
        );
    }

    return (
        <div className="px-4">
            <div className="px-6 py-4">
                <img
                    src="https://scogo-production-upload.s3.ap-south-1.amazonaws.com/media/logo/scogo-logo.png"
                    alt=""
                    style={{ height: "4rem" }}
                />
                <p className="text-2xl font-medium pt-2 px-2 italic">
                    Let's Get Work Done
                </p>
            </div>
            <div className="relative">
                {loggedUserId && (
                    <img
                        className="absolute rounded-full top-0 left-6 h-48 w-48 z-50"
                        src={profileImge}
                        alt=""
                    />
                )}
            </div>
            <div className="relative">
                <img
                    className="pt-24 h-78vh w-full"
                    src="https://scogo-production-upload.s3.ap-south-1.amazonaws.com/media/logo/profile_bg.png"
                    alt=""
                />
                <div className="absolute top-48 text-white px-6 py-8">
                    <p className="text-3xl font-medium">{idCard?.username}</p>
                    <p className="text-font14 font-medium py-4">{idCard?.role}</p>
                    <p className="text-font14 font-medium pt-2">
                        <span>Scogo Id :</span> <span>SN-00{idCard?.userId}</span>
                    </p>
                    <p className="text-font14 font-medium pt-2">
                        {proofType({ type: idCard?.id_proof_type })}
                    </p>
                    <p className="text-font14 font-medium pt-2">
                        <span>Email Id :</span> <span>{idCard?.email}</span>
                    </p>
                    <div className="py-6">
                        <p className="text-2xl font-medium pb-4">Scogo Public Profile</p>
                        <div
                            className="h-auto flex-none bg-cover text-center overflow-hidden"
                            title={qrCodeUrl}
                        >
                            <QRCode
                                id="SN-00966"
                                value={qrCodeUrl}
                                size={120}
                                level={"H"}
                                style={{ paddingBottom: "5%" }}
                            />
                        </div>
                        <p className="pt-0 text-xl font-medium">{qrCodeUrl}</p>
                        <p className="pt-0 text-2xl font-medium pt-2">https://scogo.in/</p>
                    </div>
                </div>
            </div>
        </div>
    );
};
