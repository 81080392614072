import IconToolTip from './IconToolTip';
import React from 'react';

const MaterialIconToolTip = ({
    onClick = () => {},
    title,
    materialIconName,
    childComponent,
    materialIconClass = 'text-scogoprimary hover:text-scogoorange cursor-pointer material-icons text-font16',
    additionalClasses = '',
}) => {
    let iconToolTipChild;
    if (childComponent) {
        iconToolTipChild = childComponent;
    } else {
        iconToolTipChild = (
            <span onClick={onClick} className={`${materialIconClass} ${additionalClasses}`}>
                {materialIconName}
            </span>
        );
    }

    return <IconToolTip title={title}>{iconToolTipChild}</IconToolTip>;
};

export default MaterialIconToolTip;
