import React from 'react';

export default function Audio({ fileUrl, showIcon, audioClassName }) {
    return (
        <>
            {showIcon && <img className=' m-auto py-2 w-6/12  max-h-480 ' src='/img/audio.png' alt='' />}

            <audio src={fileUrl} className={`${audioClassName} w-full mt-2`} controls controlsList=' noplaybackrate' />
        </>
    );
}
