export default function Scroller({ openedConversationUnreadableMessageCount, getLatestConversationMessages, isChatPopup }) {
    return <div
        style={{ right: '2.8%', bottom: '4.5rem' }}
        className='absolute cursor-pointer border flex place-content-center bg-scogoprimary rounded-md outline-0 border-none opacity-75'
    >
        <span onClick={() => getLatestConversationMessages(500)} className='material-icons text-white'>
            expand_more
        </span>
        {openedConversationUnreadableMessageCount ? (
            <span
                className={`absolute  inline-flex items-center justify-center px-2 py-1 text-font10 font-bold leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-scogoorange rounded-full notificationBadge`}
                style={{
                    top: '2%',
                    right: '1%',
                }}
            >
                {openedConversationUnreadableMessageCount}
            </span>
        ) : (
            <></>
        )}
    </div>
}