import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModalAction } from '../../actions/modal';
import Form from '../../common/Form';
import { useForm } from 'react-hook-form';
import Input from '../../common/Form/Input';
import { setWhatsappAlternateMobilePopup } from '../../utils/common';
import { updateWhatsappNumber } from '../../actions/whatsapp';
import { useNavigate } from 'react-router-dom';


const formName = 'upload_id_proof';


export default function WhatsappAlternateMobilePopup() {
    const defaultValues = {};
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { formButtonLoading } = useSelector((state) => state.loading);
    const { loggedUser } = useSelector((state) => state.auth);

    const submit = (formValues) => {
        let payload = {
            userId: loggedUser?.id,
            mobileNumber: formValues.mobileNumber,
            verifyType: "ALTERNATE",
            navigate,
            formName,
        };
        dispatch(updateWhatsappNumber(payload));
    };

    const methods = useForm({ defaultValues, mode: 'all' });

    return (
        <Form
            methods={methods}
            onSubmit={submit}
            formName={formName}
            submitButtonText={'Submit'}
            onCancel={() => {
                setWhatsappAlternateMobilePopup()
                dispatch(closeModalAction())
            }}
            showSubmit
            submitContainerClassName='flex gap-2 justify-end py-4 px-4'
            isLoading={formButtonLoading[formName]}
            cancelButtonText="Skip"
        >
            <div className='px-4'>
                <div className='flex gap-2'>
                    <Input
                        name='mobileNumber'
                        label='Alternate Mobile No.'
                        type={'number'}
                        className={'w-full'}
                        validate={(mobile) => {
                            if (mobile === '') return true;
                            return mobile.length === 10 || 'Invalid Mobile';
                        }}
                    />

                </div>

            </div>
        </Form>
    );
}
