import React, { useEffect, useState, useCallback } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import config from '../../config';
import { setSelectedLocationOnMap } from '../../actions/utils';
import { useDispatch } from 'react-redux'
import ChooseLocation from './ChooseLocation';

const ReactGoogleMap = ({ mapContainerStyle = { width: '100%', height: '30vh' }, setShowMap, address }) => {
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: config.googleMapApiKey
    });
    const dispatch = useDispatch();
    const [location, setLocation] = useState({});

    const [markerPosition, setMarkerPosition] = useState({});

    const getAddress = useCallback((latitude, longitude, address = '') => {

        var geocoder = new window.google.maps.Geocoder();
        var latlng = new window.google.maps.LatLng(latitude, longitude);
        let findLatLongUsing = {};
        if (address) {
            findLatLongUsing.address = address;
        } else {
            findLatLongUsing.latLng = latlng;
        }
        if (latitude !== "" && geocoder && latlng) {
            geocoder.geocode(
                findLatLongUsing,
                function (res, status) {
                    if (res) {
                        let add = res[0]?.address_components;
                        let pin = add[add.length - 1].long_name;
                        var forcity = res[0].formatted_address;
                        var value = forcity.split(",");
                        let count = value.length;
                        let city = value[count - 3];
                        let location_data = res[0]?.formatted_address;
                        let lat = res[0]?.geometry?.location?.lat();
                        let lng = res[0]?.geometry?.location?.lng();
                        const data = {
                            lat,
                            lng,
                            pincode: pin,
                            address: location_data,
                            city: city,
                        }
                        setLocation(data);

                    }
                }
            );
        }
    }, []);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    getAddress(position.coords.latitude, position.coords.longitude, address);
                },
                (error) => {
                    console.error("Error getting current location:", error);
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
    }, [getAddress, address]);

    useEffect(() => {
        if (navigator.geolocation) {
            setMarkerPosition({
                lat: location.lat,
                lng: location.lng
            });
        }
    }, [location?.lat, location?.lng])


    const handleMarkerDrag = useCallback((e) => {
        if (e.latLng) {
            setMarkerPosition({ lat: e.latLng.lat(), lng: e.latLng.lng() })
            getAddress(e.latLng.lat(), e.latLng.lng());
        }
    }, [getAddress]);

    const confirmAddress = useCallback(() => {
        setShowMap(false);
        dispatch(setSelectedLocationOnMap(location));
    }, [setShowMap, dispatch, location]);

    useEffect(() => {
        return () => setShowMap(false);
    }, [setShowMap]);

    return isLoaded ? (
        <div className='z-50'>
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={markerPosition}
                zoom={12}
                onClick={(e) => handleMarkerDrag(e)}
            >
                {markerPosition && <Marker position={markerPosition} draggable={true}
                    onDragEnd={(e) => handleMarkerDrag(e)} />}

            </GoogleMap>
            <ChooseLocation selectedAddress={location?.address || ''} confirmAddress={confirmAddress} selectedCity={location?.city || ''} />
        </div>

    ) : (
        <></>
    );
};

export default ReactGoogleMap;  