export const headerCss = {
    mainDiv: 'h-50',
    profileImage: 'h-12 w-12',
    notificationIcon: 'pt-2 w-10 h-10',
    notificationBadge: 'top-2 right-8',
};

export const projectCss = {
    topBar: '',
    tableDiv: '',
    sideBar: '',
    table: 'h-73vh',
    addNewForm: 'h-80vh',
    scmAddProjectForm: 'h-78vh',
};

export const teamWorkCss = {
    topBar: 'top-8',
    sideBar: '',
    teamsTab: 'top-8 h-68vh',
    table: 'h-60vh ',
    teamsTabContainer: 'h-68vh',
};

export const inventoryCss = {
    table: 'h-55vh ',
};

export const textCss = {
    moduleTitle: 'text-scogo21 text-font16 font-medium font-rubik',
    headerOne: 'text-scogo21 text-font18 font-medium font-rubik',
    headerTwo: ' text-font13 font-medium ',
    headerThree: 'text-scogo21 text-font13 ',
    inputLabel: 'text-scogo99 font-normal text-font11',
    inputText: 'font-normal text-font11',
    inputHeader: 'font-normal text-font12',
    radioLabelFontSize: 'font-normal text-font12 ',
    radioSize: 'h-4 w-4',
    detailsTitle: 'text-scogo99 text-font12 font-medium ',
    detailsValue: 'text-font11 font-medium break-words',
    formHighlights: 'text-scogopriamry2 text-font12 font-rubik font-medium',
    headerTitle: 'text-font14 font-medium font-rubik text-scogogray',
    headerValue: 'text-font14 font-medium font-rubik text-scogoorange',
};

export const tableCss = {
    theadText: 'text-font11 text-left text-scogogray font-normal',
    tBodyText: 'text-font11 text-black font-normal md:mr-0 mr-2',
};

export const buttonClass = {
    scogoPrimary:
        'text-white bg-scogoprimary flex items-center justify-center rounded-6px shadow-primaryBtnShadow px-4 py-2 text-xl gap-1 font-medium relative  hover:bg-scogobgsky cursor-pointer',
    scogoOrange:
        'bg-white mt-2 border border-scogoorange px-2 leading-6 cursor-pointer text-scogoorange font-normal rounded-lg gap-1 font-medium cursor-pointer',
    scogoClosed:
        'text-scogoclosed border border-scogoclosed flex items-center justify-center rounded-6px shadow-primaryBtnShadow px-4 py-2 text-lg gap-1 text-xl font-medium hover:bg-scogoLightRed cursor-pointer',
    textModalButton:
        'text-white flex items-center justify-center rounded-6px shadow-primaryBtnShadow px-4 py-2 text-lg gap-1 text-xl font-medium cursor-pointer',
    filterButton:
        'text-scogoprimary whitespace-nowrap bg-white font-medium flex items-center justify-center rounded-6px border border-scogoprimary px-4 py-2 text-xl gap-1 font-medium cursor-pointer',
    searchInput: 'loginput truncate text-xl flex items-center w-96 cursor-pointer',
};

export const clickableTextClass = 'hover:text-scogoorange cursor-pointer text-scogobgsky  font-medium';

export const badgeClass = {
    teamsBadge: 'w-10',
};

export const paymentCss = {
    table: 'h-67vh',
};
