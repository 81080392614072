import React, { useState } from 'react';
import Input from '../../../Inventory/reusableComponents/AddOption/Input';
import ButtonScogoPrimary from '../../../../common/Buttons/ButtonScogoPrimary';

export const SearchComponent = ({ handleSearch, isLoading = false }) => {
    const [searchKey, setSearchKey] = useState('');

    return (
        <div className='flex max-h-20 items-center px-4'>
            <Input
                required
                className='w-10/12'
                label='Search For Users'
                type={'text'}
                name='searchforuser'
                placeholder={'Find Mobile/Email'}
                customMethod={(e) => setSearchKey(e.target.value)}
            />
            <ButtonScogoPrimary
                buttonClass={'w-2/12 mt-6'}
                textOrComponent={'Search'}
                onClick={(e) => {
                    e.preventDefault();
                    handleSearch(searchKey);
                }}
                loading={isLoading}
                disabledClass='text-black flex items-center justify-center rounded-6px shadow-primaryBtnShadow px-4 py-2 text-xl gap-1 font-medium relative cursor-not-allowed border-scogoddd bg-scogoddd w-2/12 mt-6'
            />
        </div>
    );
};
