import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import { promoteUser } from '../../../actions/users';
import { listAllState, listAllCity } from '../../../actions/admin';
import { getGroupUserList, groupUserListFromStore } from '../../../actions/partners';
import moment from 'moment';
import { actualValueForDropdown, validateStringValue } from '../../../utils/common';
import Form from '../../../common/Form';
import Input from '../../../common/Form/Input';
import Select from '../../../common/Form/Select';
import { useForm } from 'react-hook-form';
import DatePicker from '../../../common/Form/DatePicker';
import MultiplePincodes from '../../../common/Form/MultiplePincodes';


const cluster = { key: 'cluster', value: 'CLUSTER', label: 'Cluster' };
const psp = { key: 'PSP', value: 'PSP', label: 'PSP' };
const supervisor = { key: 'Supervisor', value: 'SUPERVISOR', label: 'Supervisor' };
const IWAN = { key: 'IWAN', value: 'IWAN', label: 'IWAN' };
const promoteToLabels = [cluster, psp, supervisor, IWAN];

const incentiveTypeLabels = [
    { key: 'INCENTIVE', value: 'INCENTIVE', label: 'Incentive' },
    { key: 'BUSINESS', value: 'BUSINESS', label: 'Business' },
];

const formName = 'promoteUser';

export default function PromoteUserModal(props) {
    const dispatch = useDispatch();
    const { allState, allCity } = useSelector((state) => state.admin);
    const { isAllStateLoading, isAllCityLoading, formButtonLoading } = useSelector((state) => state.loading);
    const { groupUserList } = useSelector((state) => state.partners);

    const { userDetails, role, userType, customer } = props;

    const defaultValues = {
        promote: cluster,
        email: '',
        date_joined: new Date(),
        incentive_limit: '',
        incentive_type: '',
        states: [],
        cities: [],
        clusterId: '',
        pincodes: [],
    };

    const getDropdownData = useCallback(() => {
        dispatch(getGroupUserList({ roles: 'CLUSTER,PSP,SUPERVISOR', limit: 1000000, page: 1 }));
    }, [dispatch]);

    useEffect(() => {
        getDropdownData();
        return () => dispatch(groupUserListFromStore());
    }, [getDropdownData, dispatch]);

    const clusterDropdownList = useMemo(() => {
        if (Array.isArray(groupUserList) && groupUserList.length > 0) {
            return groupUserList.map((user) => {
                return {
                    value: user.id,
                    label: `${validateStringValue(user.first_name)} ${validateStringValue(user.last_name)}`,
                    key: user.id
                }
            })
        }
        return [];
    }, [groupUserList]);

    const methods = useForm({ defaultValues, mode: 'all' });
    const { watch, setValue } = methods;
    const promote = watch('promote');
    const isClusterSelected = actualValueForDropdown(promote) === cluster.value;
    const isPspSelected = actualValueForDropdown(promote) === psp.value;
    const isSupervisorSelected = actualValueForDropdown(promote) === supervisor.value;
    const isIwan = actualValueForDropdown(promote) === IWAN.value;
    const showPincodeCityInput = isSupervisorSelected || isPspSelected;

    const updateStateAndCityDropDownOnPromoteChange = useCallback(() => {
        const promoteTo = actualValueForDropdown(promote);
        const role = promoteTo === 'PSP' ? 12 : 6;
        setValue('states', []);
        setValue('cities', []);
        dispatch(listAllState({ dropdown: true, userterritories: true, role, userType: promoteTo }));
        dispatch(listAllCity({ dropdown: true, userterritories: true, role, userType: promoteTo }));
    }, [dispatch, promote, setValue]);

    useEffect(() => {
        updateStateAndCityDropDownOnPromoteChange();
    }, [updateStateAndCityDropDownOnPromoteChange, promote]);

    const submit = (formValues) => {
        let selectedStates = formValues.states.map((item) => item.value);
        let selectedCities = formValues.cities.map((item) => item.value);

        let payload = {
            promote: actualValueForDropdown(formValues.promote),
            incentive_type: actualValueForDropdown(formValues?.incentive_type),
            date_joined: moment(formValues.date_joined).format('YYYY-MM-DD'),
            incentive_limit: formValues.incentive_limit,
            states: selectedStates,
        };
        if (isIwan) {
            payload = {
                promote: actualValueForDropdown(formValues.promote),
                clusterId: actualValueForDropdown(formValues.clusterId),
                date_joined: moment(formValues.date_joined).format('YYYY-MM-DD'),
            }
        }
        if (isClusterSelected) {
            payload = {
                ...payload,
                email: formValues.email,
            };
        } else if (isPspSelected || isSupervisorSelected) {
            payload = {
                ...payload,
                clusterId: actualValueForDropdown(formValues.clusterId),
                cities: selectedCities,
                pincodes: formValues.pincodes.filter((pincode) => pincode !== ''),
            };
        }
        dispatch(
            promoteUser({
                userId: userDetails?.id,
                data: payload,
                role,
                userType,
                customer,
                formName,
            })
        );
    };

    return (
        <Form
            methods={methods}
            onSubmit={submit}
            submitButtonText={'Promote'}
            onCancel={() => dispatch(closeModalAction())}
            showSubmit
            submitContainerClassName='flex gap-2 justify-end py-4 px-4'
            isLoading={formButtonLoading[formName]}
        >
            <div className='px-3 flex items-center'>
                <div className='flex w-full'>
                    <Select className='w-full' label='Promote To' name='promote' options={promoteToLabels} required />
                    {isIwan && <Select className={'w-full'} label='Cluster' name='clusterId' options={clusterDropdownList} required shouldUnregister />}
                </div>

                {isClusterSelected && (
                    <Input
                        className='w-full'
                        label='Scogo Email'
                        name='email'
                        required
                        type={'text'}
                        validate={(email) => {
                            return (
                                email.match(
                                    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                ) || 'Invalid Email'
                            );
                        }}
                        shouldUnregister
                    />
                )}
            </div>
            <div className='px-3 flex justify-between items-center'>
                {!isIwan && <DatePicker className='w-full' label='Joining Date' name='date_joined' required dateFormat='dd-MM-yyyy' />}
                {(isPspSelected) && <Select className={'w-full'} label='Cluster' name='clusterId' options={clusterDropdownList} required shouldUnregister />}
            </div>
            {!isIwan && <div className='px-3 flex justify-between items-end'>
                <Select className='w-6/12' label='Type' name='incentive_type' required options={incentiveTypeLabels} />
                <Input className='w-6/12' label='Incentive Limit' name='incentive_limit' required type={'number'} />
            </div>}
            {!isIwan && <div className='px-3'>
                <Select className='w-full' label='States' name='states' options={allState} isMulti={true} required={isClusterSelected} isLoading={isAllStateLoading} />
            </div>}
            {showPincodeCityInput && (
                <div className='px-3'>
                    <Select className='w-full' label='Cities' name='cities' options={allCity} isMulti={true} isLoading={isAllCityLoading} />
                </div>
            )}
            {showPincodeCityInput && (
                <div className='px-3'>
                    <MultiplePincodes name='pincodes' required label='Pincodes' />
                </div>
            )}
        </Form>
    );
}
