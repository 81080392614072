import React, { useState, useRef } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';

const MiniPopup = ({
    as,
    className,
    position = '',
    popup,
    handleClick = () => { },
    children,
    transition = true,
    menuStyle = {},
    onHover = 'text-scogoorange',
    onClose = () => { },
    blurBackGround = false
}) => {
    const menuRef = useRef();

    const closeMenu = () => {
        onClose();
        menuRef.current?.click();
    };

    const getMenuItems = (open) => {

        let menuItem = (
            <Menu.Items className={className} style={menuStyle}>
                <Menu.Item>{React.cloneElement(popup, { closeMenu })}</Menu.Item>
            </Menu.Items>
        );

        let renderWithBlurBackground = <div>
            <div className='opacity-25 fixed inset-0 z-20 bg-black'></div>
            {menuItem}
        </div>

        if (transition) {
            return (
                <Transition
                    show={open}
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                >
                    {menuItem}
                </Transition>
            );
        }

        if (open) {
            return blurBackGround ? renderWithBlurBackground : menuItem
        }

        return <></>;
    };

    return (
        <Menu as={as}>
            {({ open }) => (
                <Fragment>
                    <div className={position}>
                        <Menu.Button className={'w-full'}>
                            <div ref={menuRef} onClick={() => handleClick()} className={open ? 'text-scogoorange' : `hover:${onHover}`}>
                                {children}
                            </div>
                        </Menu.Button>
                        {getMenuItems(open)}
                    </div>
                </Fragment>
            )}
        </Menu>
    );
};

export default MiniPopup;
