import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllExistingCities, getAllCities, updateServiceLocations } from '../../../actions/users';
import { getSPNewTicketsList, getExecutionDateChangeRemarks, getSPOngoingTickets } from '../../../actions/ticketAssignment';
import GridTable from '../../../common/GridTable';
import { tableHead } from '../../../common/tabeHelpers';
import Buttons from '../../../common/Buttons';
import AssignFE from './AssignFE';
import { closeModalAction, openCustomModal } from '../../../actions/modal';
import DropdownField from '../../Inventory/reusableComponents/AddOption/fields/DropdownField';
import { SkeltonCardTwo } from '../../../common/SkeltonCardTwo';
import { isPsp } from '../../../utils/role';
import ButtonScogoPrimary from '../../../common/Buttons/ButtonScogoPrimary';
import ButtonScogoClosedOutlined from '../../../common/Buttons/ButtonScogoClosedOutlined';

const addCitiesFormName = 'addCitiesFormName';

export default function AcceptsTickets(props) {
    const dispatch = useDispatch();
    const { userDetails, fk_cluster_id } = props;
    const closeModal = () => {
        dispatch(closeModalAction());
    };
    const isUserIsPsp = isPsp(userDetails?.role?.id, userDetails?.type);

    const { cities, existingCities } = useSelector((state) => state.user);
    const { ongoingSpTickets } = useSelector((state) => state.tickets);
    const { spAssignedNewTickets } = useSelector((state) => state.tickets);

    const ticketsToShow = isUserIsPsp ? ongoingSpTickets : spAssignedNewTickets;

    const { isNewSPAssignedLoading, allExistingCityLoading, formButtonLoading } = useSelector((state) => state.loading);
    const initialValues = {
        service_locations: existingCities,
    };
    const [form, setForm] = useState(initialValues);
    const [isChangedServiceLocations, setIsChangedServiceLocations] = useState(false);

    const getSpTickets = () => {
        if (isUserIsPsp) {
            dispatch(getSPOngoingTickets({ type: 'new', sp_id: userDetails?.id, open: true }));
        } else {
            dispatch(getSPNewTicketsList({ sp_id: userDetails?.id }));
        }
    };

    useEffect(() => {
        dispatch(getAllCities());
        dispatch(getAllExistingCities(userDetails?.sp_id));
        dispatch(getExecutionDateChangeRemarks());
        getSpTickets();
    }, []);

    useEffect(() => {
        setForm({ ...form, service_locations: existingCities });
    }, [existingCities]);

    const handleUpdateServiceLocation = (event) => {
        const { value } = event;
        setForm({ ...form, service_locations: value });
        setIsChangedServiceLocations(true);
    };

    const handleAcceptTickets = ({ ticketDetails }) => {
        let data = {
            type: 'assignefe',
            userId: userDetails?.id,
            servicePartnerId: userDetails?.sp_id,
            ticketId: ticketDetails?.id,
            selectedTickets: {},
            assignMe: false,
            spIdRequired: true,
        };
        let heading = 'Assign FE';
        let modalComponent = (
            <AssignFE
                ticketDetails={ticketDetails}
                onClose={closeModal}
                userDetails={userDetails}
                data={data}
                closeModal={closeModal}
                fk_cluster_id={fk_cluster_id}
            />
        );
        let modalWidth = '70rem',
            modalHeight = 'auto';
        dispatch(openCustomModal({ modalComponent, heading, modalWidth, modalHeight }));
    };

    const tableSchema = {
        table: [
            {
                head: tableHead('Ticket Number'),
                body: {
                    render: (item) => {
                        return <p className='font-medium text-scogobgsky'>{item?.job_ticket_number}</p>;
                    },
                },
            },
            {
                head: {
                    headName: 'Site Address',
                    render: (headName) => headName,
                    width: 1.5,
                },
                body: {
                    render: (item) => {
                        return (
                            <p>
                                {item.job_site_address_1
                                    ? `${item.job_site_address_1}${item.job_fk_pincode_id ? `, ${item.job_fk_pincode_id}` : ''}`
                                    : ''}
                            </p>
                        );
                    },
                },
            },
            {
                head: tableHead('Site Id'),
                body: {
                    render: (item) => {
                        return <p>{item.site_detail ? item.site_detail.external_site_id : ''}</p>;
                    },
                },
            },
            {
                head: {
                    headName: 'Actions',
                    render: (headName) => headName,
                    width: 0.5,
                },
                body: {
                    render: (item) => {
                        return (
                            <Buttons
                                children='Accept'
                                buttonClass='bg-white border border-scogobgsky px-4 h-8 leading-6 cursor-pointer text-scogobgsky text-font12 font-normal rounded-5px'
                                onClick={() => handleAcceptTickets({ ticketDetails: item })}
                            />
                        );
                    },
                },
            },
        ],
    };

    const updateCities = () => {
        const cities = form?.service_locations?.map((item) => item?.value);
        const updatedCities = form?.service_locations?.map((city) => {
            city = city?.label?.split(',');
            return city[0];
        });
        const payload = {
            data: { add_service_location: cities },
            servicePartnerId: userDetails?.sp_id,
            callApiForUpdateData: false,
            formName: addCitiesFormName,
            closedModal: false,
            refresh: true,
            updatedCities,
            userIds: [userDetails.id]
        };

        dispatch(updateServiceLocations(payload));
    };

    const isApiLoading = !allExistingCityLoading && !isNewSPAssignedLoading;

    return (
        <div>
            {isApiLoading ? (
                <>
                    <div className='px-3 flex flex-col justify-between relative'>
                        <DropdownField
                            className='w-full'
                            label='Service Locations'
                            name='service_locations'
                            options={cities}
                            isMulti={true}
                            form={form}
                            onChangeHandler={handleUpdateServiceLocation}
                            errors={{}}
                        />
                        {isChangedServiceLocations && (
                            <div className='ml-auto px-7'>
                                <ButtonScogoPrimary
                                    textOrComponent={'Update'}
                                    onClick={updateCities}
                                    loading={formButtonLoading[addCitiesFormName]}
                                />
                            </div>
                        )}
                    </div>
                    {Array.isArray(ticketsToShow) && (
                        <div className='px-7 py-4'>
                            {ticketsToShow?.length > 0 ? (
                                <GridTable
                                    isLoading={isNewSPAssignedLoading}
                                    schema={tableSchema}
                                    rowData={ticketsToShow}
                                    tableError={'No Pending Tickets to Accept'}
                                    tableBodyHeight={'h-max'}
                                />
                            ) : (
                                <div
                                    className='py-2 items-center flex px-4 rounded-md'
                                    style={{
                                        border: '1px solid rgb(91,147,164)',
                                        color: 'rgb(91,147,164)',
                                    }}
                                >
                                    <p className='text-font11'>No Pending Tickets to Accept</p>
                                </div>
                            )}
                        </div>
                    )}
                </>
            ) : (
                <SkeltonCardTwo arr={[1, 2, 3, 4, 5]} height={20} className={'px-7'} />
            )}

            <div className='justify-end flex px-6 py-4'>
                <ButtonScogoClosedOutlined textOrComponent='Cancel' onClick={closeModal} />
            </div>
        </div>
    );
}
