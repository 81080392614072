import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import IconToolTip from '../../common/IconToolTip';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveVoiceCall } from '../../actions/tickets';
import AwaitingCallCard from './Call/AwaitingCall';
import { validateStringValue } from '../../utils/common';
import NoResultFound from '../../common/NoResultFound';
import FlipMove from 'react-flip-move';
import { setRoomListData } from '../../actions/utils';
import { getUserCallStatusAction } from '../../actions/users';


export const getRoomToken = async ({ role, room_id, user_id }) => {
    const response = await fetch(`https://prod-in2.100ms.live/hmsapi/scogo-livestream-1521.app.100ms.live/api/token`, {
        method: 'POST',
        body: JSON.stringify({
            user_id: `${room_id}`, // User ID assigned by you (different from 100ms' assigned id)
            role, // listener , speaker , moderator
            room_id,
        }),
    });
    const { token } = await response.json();
    return token;
};
export const getRoomList = async () => {
    const response = await fetch(`https://api.100ms.live/v2/rooms`, {
        method: 'GET',
    });
    const data = await response.json();
    return data;
};


export default function CallQueueList() {
    const { isActiveVoiceCallListLoading } = useSelector((state) => state.loading);
    const { isRoomListOpen } = useSelector((state) => state.utils);
    const { activeVoiceCallList } = useSelector((state) => state.tickets);
    const { userCallStatus } = useSelector((state) => state.user);
    const { loggedUser } = useSelector((state) => state.auth);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getActiveVoiceCall());
        dispatch(getUserCallStatusAction({ userId: loggedUser?.id, status: [1, 2, 3, 4] }));
    }, [dispatch])

    const isCallAwaiting = Array.isArray(userCallStatus) && userCallStatus?.length > 0;

    return <div className='flex items-center'>
        <IconToolTip title={`Meeting Lounge`}>
            <span
                className='text-font18 mr-4 cursor-pointer z-50'
                id='meeting_lounge_elem'
                onClick={() => {
                    dispatch(setRoomListData({ isRoomListOpen: !isRoomListOpen }));
                }}
            >
                <img src={process.env.PUBLIC_URL + "/img/call_queue_list.png"} alt="" className='w-8' />
            </span>
        </IconToolTip>
        {isCallAwaiting && <div className='relative'><div className=' absolute top-0 right-1 bg-red-600 rounded-full h-5 w-5 z-100 -translate-y-[100%] animate-pulse flex justify-center items-center text-white'>{userCallStatus?.length}</div></div>}
        <QueueList isOpen={isRoomListOpen} activeVoiceCallList={activeVoiceCallList} />
    </div >
}

const QueueList = ({ isOpen, activeVoiceCallList }) => {
    const { isActiveVoiceCallListLoading } = useSelector((state) => state.loading);
    const { userCallStatus } = useSelector((state) => state.user);
    const { activeCallId } = useSelector((state) => state.utils);

    let activeVoiceCallTicketList = useMemo(() => {
        let list = activeVoiceCallList || [];
        if (activeCallId) {
            let findIndex = list.findIndex((item) => item.id === activeCallId);
            if (findIndex > 0) {
                const value = list.splice(findIndex, 1);
                list.unshift(...value);
            }
        }
        return list;
    }, [activeCallId, activeVoiceCallList]);

    const containerStyle = {
        transform: isOpen ? 'translateY(0)' : 'translateY(-110%)',
        transition: 'transform 0.5s',
    };


    return <div className='absolute right-0 top-[6.4vh] border-l-[2px] border-scogoddd bg-white h-[93.5vh] w-[50vh] overflow-y-scroll px-4 py-4 z-0' id='render_queue_list' style={containerStyle}>
        <div className='flex gap-4 flex-col'>
            {Array.isArray(activeVoiceCallTicketList) && Array.isArray(userCallStatus) && userCallStatus?.length > 0 ? userCallStatus.map((userInvitedInCall, i) => {
                const ticket = activeVoiceCallTicketList?.find((ticket) => userInvitedInCall?.fk_room_id === ticket?.fk_room_id) || {};
                // if (!ticket) return <></>
                return <div id='shuffle' key={userInvitedInCall.id} >
                    <div><FlipMove staggerDurationBy='30' duration={500} enterAnimation='accordionVertical' leaveAnimation='accordionVertical' typeName='ul'>
                        <RenderCard ticket={ticket} zIndexValue={activeVoiceCallTicketList.length} index={i} userInvitedInCall={userInvitedInCall} />
                    </FlipMove>
                    </div>
                </div>
            }) : <div>
                <NoResultFound message={'No Active Scogo Connect'} />
            </div>
            }

        </div>
    </div>
}

const RenderCard = forwardRef(({ ticket, activeCallId, zIndexValue, index, userInvitedInCall }, ref) => {
    const [isConnecting, setIsConnecting] = useState(false);
    const style = { zIndex: zIndexValue - index };

    useEffect(() => {
        if (isConnecting) {

            let timeOut = setTimeout(() => {
                setIsConnecting(false);
            }, [2000]);

            return () => clearTimeout(timeOut);
        }
    }, [isConnecting]);

    const invitedBy = { value: `${validateStringValue(userInvitedInCall?.inviter?.first_name || 'Self')}`, label: 'Invited By' };
    const ticketDetail = { value: `${validateStringValue(ticket?.job_ticket_number)}`, label: 'Ticket No.' };
    const engineer = { value: `${validateStringValue(ticket?.accepted_fe?.first_name)}`, label: 'Engineer Name' };
    const project = { value: `${validateStringValue(ticket?.project?.project_name)}`, label: 'Project Name' };
    let site = {};
    if (ticket?.site?.external_site_id) {
        site = { value: `${validateStringValue(ticket?.site?.external_site_id)}`, label: 'Site Id' };
    }
    if (ticket?.site?.site_branch_name) {
        site = { value: `${validateStringValue(ticket?.site?.site_branch_name)}`, label: 'Branch Name' };
    }
    const data = [invitedBy, engineer, ticketDetail, project, site];

    const renderDetail = (<div className='px-4 py-2'>
        {data.map(({ label, value }) => {
            if (["", null, undefined].includes(value)) return <></>;
            return <p key={value} className='max-w-max'>
                <span className='font-medium text-font12 text-scogoprimary'>{label}: </span>
                <span className='font-normal text-scogo70 text-font11'>{value}</span>
            </p>
        })
        }
    </div>)

    let component = <AwaitingCallCard ticket={ticket} renderDetail={renderDetail} userInvitedInCall={userInvitedInCall} />
    return <li ref={ref} id={ticket._id} className='relative' style={style}>
        {component}
    </li>
})