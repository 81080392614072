import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../Inventory/reusableComponents/AddOption/Input";
import Form from "../../Inventory/reusableComponents/AddOption/Form";
import { closeModalAction } from "../../../actions/modal";
import { withdrawWalletAmount } from "../../../actions/wallet";
import { getWalletTransferOtp } from "../../../actions/auth";

const transfer = "Transfer",
  requestOtp = "Request OTP";

export default function PayFe(props) {
  const { feDetails } = props;
  const dispatch = useDispatch();
  const { loggedUser } = useSelector((state) => state.auth);
  const { wallet, totalPaid } = useSelector((state) => state.wallet);
  const [buttonName, setButtonName] = useState(requestOtp);

  const initialValues = {
    amount: "",
    remark: "",
    otp: "",
  };

  const [form, setForm] = useState(initialValues);

  const handleCancel = () => dispatch(closeModalAction());

  const handleFormSubmit = (formValues, buttonName) => {
    if (buttonName === requestOtp) {
      let payload = {
        mobile: loggedUser.mobile,
        skip: true,
        bank_otp: 1,
        formName: "requestOtp",
      };
      dispatch(getWalletTransferOtp(payload));
      setButtonName(transfer);
    } else if (buttonName === transfer) {
      let data = {
        type: "TRANSFER",
        withdrawAmount: parseInt(formValues.amount),
        recepient: parseInt(feDetails?.user?.id),
        remark: formValues.remark ? formValues.remark : "",
        otp: parseInt(formValues.otp),
        ticketId: 0,
      };
      dispatch(
        withdrawWalletAmount({
          data,
          refresh: true,
          formName: "transeferAmount",
        })
      );
    }
  };

  return (
    <>
      <div className="px-6 pt-4 ">
        <div className="flex gap-2">
          <div className=" py-2 text-font11 bg-tagbg px-2 rounded-lg">
            Current Balance: ₹ {wallet?.wallet_amount}
          </div>
          <div className=" py-2 text-font11 bg-tagbg px-2 rounded-lg ">
            Total Withdrawn Amount: ₹ {totalPaid ? totalPaid : 0}
          </div>
        </div>
      </div>
      <Form
        className="w-full"
        submit={(e) => handleFormSubmit(e, buttonName)}
        initialValues={initialValues}
        submitButtonName={buttonName}
        onCancel={handleCancel}
        buttonPostion={"justify-end"}
        form={form}
        setForm={setForm}
        formName={buttonName === transfer ? "transeferAmount" : "requestOtp"}
      >
        <div className="px-3">
          <Input
            className="w-full"
            label="Amount"
            type={"text"}
            name="amount"
            placeholder={"Amount"}
            required
          />
          {buttonName === transfer && (
            <Input
              className="w-full"
              label="OTP"
              type={"text"}
              name="otp"
              placeholder={"OTP"}
              required
            />
          )}
          <Input
            className="w-full"
            label="Remarks"
            type={"text"}
            name="remark"
            placeholder={"Remarks"}
          />
        </div>
        <div className="px-7 pt-2">
          <span className="text-font12 font-medium text-scogoprimary">
            Recepient:{" "}
          </span>
          <span className="text-font12">
            {feDetails?.user?.first_name} {feDetails?.user?.last_name}
          </span>
        </div>
      </Form>
    </>
  );
}
