import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import { customerSignOffRejectTicket, rejectTicketSignOff } from '../../../actions/ticketExecution';
import { isCustomer, isCustomerAdmin, isCustomerUser } from '../../../utils/role';
import { useSelector } from 'react-redux';
import { getTicketDetails, getTicketPayments } from '../../../actions/tickets';
import { useEffect } from 'react';
import { getBasePaymentData, getGroupByAdditonalData } from '../../Payments/Modals/EditPayout/editPayoutContainer';
import { isApprovePaymentAllowed } from '../../../utils/access';
import { getApproveSignOffTicketPayload } from '../../Payments/Modals/EditPayout';
import Form from '../../../common/Form';
import { useForm } from 'react-hook-form';
import TextArea from '../../../common/Form/TextArea';

const formName = 'reject_signoff';
export default function RejectSignOff({ ticketId }) {
    const defaultValues = {};
    const dispatch = useDispatch();
    const { loggedUser } = useSelector((state) => state.auth);
    const { ticketPayment, ticketDetails, ticketBasePayment } = useSelector((state) => state.tickets);
    const type = loggedUser.type;
    const role = loggedUser.role.id;
    let isCustomerGroup = isCustomer(role, type) || isCustomerAdmin(role, type) || isCustomerUser(role, type);
    const approvalPermission = isApprovePaymentAllowed(loggedUser.frontend_controller);
    const [ticketPaymentData, setTicketPaymentData] = useState({});
    const { formButtonLoading } = useSelector((state) => state.loading);

    useEffect(() => {
        dispatch(getTicketDetails({ ticketId: ticketId, payment_transaction: true }));
        dispatch(getTicketPayments({ ticketId }));
    }, [ticketId, dispatch]);

    useEffect(() => {
        let additionalData = [];
        let basePaymentData = {};
        if (ticketPayment && ticketPayment?.length > 0) {
            additionalData = getGroupByAdditonalData({ ticketPayment, ticketDetails, approvalPermission });
        }
        if (ticketDetails && Object.keys(ticketDetails).length > 0) {
            basePaymentData = getBasePaymentData({ ticketDetails, ticketBasePayment });
        }
        setTicketPaymentData([basePaymentData, ...additionalData]);
    }, [ticketPayment, ticketDetails, approvalPermission, ticketBasePayment]);

    const submit = (formValues) => {
        if (isCustomerGroup) {
            let payload = {
                customer_rejection_message: formValues.customer_rejection_message,
                ticket_no: ticketId,
                formName,
            };
            dispatch(customerSignOffRejectTicket(payload));
        } else {
            let isCLuster = ticketDetails?.cluster_id > 0;
            let isPsp = ticketDetails?.psp_id > 0;
            let isSuperVisor = ticketDetails?.supervisor_id > 0;
            let isWarehouseOwner = ticketDetails?.is_rma_raised === 1 && ticketDetails?.warehouse_owner > 0;
            let payload = getApproveSignOffTicketPayload({ data: ticketPaymentData, isCLuster, isPsp, isSuperVisor, isWarehouseOwner });
            payload.ticket_id = ticketId;
            payload.pm_rejection_message = formValues.customer_rejection_message;
            dispatch(rejectTicketSignOff({ data: payload, formName }));
        }
    };

    const methods = useForm({ defaultValues, mode: 'all' });

    return (
        <Form
            methods={methods}
            onSubmit={submit}
            formName={formName}
            submitButtonText={'Submit'}
            onCancel={() => dispatch(closeModalAction())}
            showSubmit
            submitContainerClassName='flex gap-2 justify-end py-4 px-4'
            isLoading={formButtonLoading[formName]}
        >
            <div className='px-2'>
                <TextArea className='w-full' label='Rejection Message' name='customer_rejection_message' type={'text'} required />
            </div>
        </Form>
    );
}
