import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actualValueForDropdown } from '../../../utils/common';
import Form from '../../../common/Form';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useEffect } from 'react';
import { sendToastMessage } from '../../../actions/toast';
import { AssetTable } from './AssetTable';
import ButtonScogoOrange from '../../../common/Buttons/ButtonScogoOrange';
import { v4 as uuidv4 } from 'uuid';
import { approveOrRejectPo } from '../../../actions/tickets';
import { useNavigate } from 'react-router-dom';
import { ApproverItem } from './ApproverItem';
import { useFieldArray, useFormContext, useForm } from 'react-hook-form';
import { PODetails } from './PoDetails';
import { FormButtons } from './FormButtons';
import StatusLabel from '../../Payments/reusableComponents/StatusLabel';

const approversArrayName = 'approvers';

export const expenseTypes = [
    { key: 'capex', value: 'CAPEX', label: 'Capex' },
    { key: 'opex', value: 'OPEX', label: 'Opex' },
];

export const actionType = {
    reject: 'REJECT',
    approve: 'APPROVE',
};

export const formName = {
    approveFormName: 'approve_po',
    rejectFormName: 'reject_po',
};

export const PoApprovalForm = ({ ticketId }) => {
    const { ticketPODetails } = useSelector((state) => state.payments);
    const { isTicketPODetailsLoading } = useSelector((state) => state.loading);
    const { viewTicket } = useSelector((state) => state.tickets);
    const { loggedUser } = useSelector((state) => state.auth);
    const [selectedVendorId, setSelectedVendorId] = useState(0);
    const [hasAccess, setHasAccess] = useState(true);
    const [hasTakenAction, setHasTakenAction] = useState(false);
    const [actionName, setActionName] = useState('');
    const dispatch = useDispatch();
    const submitRef = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        let hasApprovalAccess = false
        let actionTaken = false
        let actionTakenName = ''
        if (ticketPODetails) {
            if (Array.isArray(ticketPODetails.approvalUsers) && ticketPODetails.approvalUsers.length > 0) {
                ticketPODetails.approvalUsers.map(i => {
                    if (i.id === loggedUser.id) {
                        if (Array.isArray(ticketPODetails.approvers) && ticketPODetails.approvers.length > 0) {
                            ticketPODetails.approvers.map(approver => {
                                if (approver.authorization && Array.isArray(approver.users) &&
                                    approver.users.length > 0 && approver.users.includes(i.id)) {
                                    if (approver.closed === false) {
                                        hasApprovalAccess = true
                                    }
                                    if (approver.closed === true) {
                                        hasApprovalAccess = false
                                        actionTaken = true
                                    }
                                }
                            })
                        }
                    }
                })
            }
            if (Array.isArray(ticketPODetails.approvals) && ticketPODetails.approvals.length > 0) {
                const userHaveMoreApprovals = ticketPODetails.approvers.filter(approver => approver.closed === false && approver.users.includes(loggedUser.id))
                ticketPODetails.approvals.map(i => {
                    if (i.userId === loggedUser.id && userHaveMoreApprovals.length === 0) {
                        hasApprovalAccess = false
                        actionTaken = true
                        if (i.action) {
                            actionTakenName = i.action === 'APPROVE' ? 'APPROVED'.toLowerCase() : i.action === 'REJECT' ? 'REJECTED'.toLowerCase() : ''
                        }
                    }
                })
            }
            if (loggedUser.po_approval === 0) {
                hasApprovalAccess = false
            }
        }
        setHasAccess(hasApprovalAccess)
        setHasTakenAction(actionTaken)
        setActionName(actionTakenName)
        return () => {
            setHasAccess(false)
        }
    }, [ticketPODetails])

    const defaultValues = {};

    const approveOrRejectPO = (formValues, action) => {
        if (!selectedVendorId) return dispatch(sendToastMessage({ message: 'Select Vendor', status: 'danger' }));
        let form_name = formName.rejectFormName;
        let payload = {
            action,
            expenseType: actualValueForDropdown(formValues.expenseType),
            job_sign_off_notes: formValues.job_sign_off_notes,
            poNumber: formValues.poNumber,
            vendorId: selectedVendorId,
        };
        if (action === actionType.approve) {
            if (viewTicket?.customer_details?.po_add_new_approver === 1 && ticketPODetails.approvals.length === 0 &&
                formValues[approversArrayName].length === ticketPODetails.approvers.length) {
                return dispatch(sendToastMessage({ message: 'Add at least 1 approver', status: 'danger' }));
            }
            let teams = formValues[approversArrayName]
                .filter((team) => {
                    return team.team || team.email || (Array.isArray(team.users) && team.users.length > 0);
                })
                .map((team, idx) => {
                    let updateTeam = team;
                    if (updateTeam.teamName) delete updateTeam.teamName;
                    return { ...team, priority: idx + 1, team: actualValueForDropdown(team.team) };
                });
            payload.teams = teams;
            form_name = formName.approveFormName;
        }
        dispatch(approveOrRejectPo({ data: payload, formName: form_name, navigate, ticketId, refresh: true }));
    };

    const onVendorSelect = (event, vendor) => {
        setSelectedVendorId(vendor);
    };

    const methods = useForm({ defaultValues, mode: 'all' });

    return (
        <Form
            onSubmit={(data) => {
                approveOrRejectPO(data, actionType.approve);
            }}
            methods={methods}
            submitRef={submitRef}
            className='w-full pb-2 approvePOContainer rounded-lg shadow-lg'
        >
            <div className='approvePOForm overflow-y-auto bg-white rounded-t-lg'>
                <PODetails ticketPODetails={ticketPODetails} approversArrayName={approversArrayName} />

                <div className='px-7 py-4'>
                    <AssetTable poDetails={ticketPODetails?.quotes} isLoading={isTicketPODetailsLoading} onVendorSelect={onVendorSelect} />
                </div>
                <div className='px-7 py-4 '>
                    <p className='text-font12'>Approvers</p>
                    <b className='text-font12'>
                        <small>Drag &amp; Drop the rows to change the priorities</small>
                    </b>
                    <div className='my-2 relative shadow-lg rounded-lg '>
                        <div className='flex border-b-2  py-1 bg-scogof8 rounded-t-lg gap-4 w-full text-font13 font-medium text-scogogray'>
                            <div className='w-2/12 pl-3'>Sr No.</div>
                            <div className='w-4/12'>Team</div>
                            <div className='w-3/12'>Users</div>
                            <div className='w-2/12'>Status</div>
                            <div className='w-1/12'>Remove</div>
                        </div>
                        <ApproversTable ticketId={ticketId} />
                    </div>
                </div>
            </div>
            <div className='approvePOButtons flex gap-2 items-center bg-white px-7 rounded-b-lg bottom-0 sticky'>
                <FormButtons submitRef={submitRef} approveOrRejectPO={approveOrRejectPO} hasAccess={ticketPODetails ? hasAccess : true} />
                {ticketPODetails && !hasAccess && !hasTakenAction ?
                    <StatusLabel content='You are Not Authorised to Approve/Reject this PO' color='scogoclosed' />
                    :
                    <></>
                }
                {ticketPODetails && hasTakenAction ?
                    <StatusLabel content={`You have already ${actionName ? actionName : 'processed'} the PO`} color='scogobgsky' />
                    :
                    <></>
                }
            </div>
        </Form>
    );
};

const ApproversTable = ({ ticketId }) => {
    const { control, setValue } = useFormContext();
    const { fields, append, remove, swap } = useFieldArray({
        control,
        name: approversArrayName,
    });
    const { ticketPODetails } = useSelector((state) => state.payments);
    const { customerTeamsDropDown } = useSelector((state) => state.customer);
    const dispatch = useDispatch();

    const onRemove = (field, idx) => {
        if (field.isNew) remove(idx);
        else dispatch(sendToastMessage({ message: `Can't Delete`, status: 'danger' }));
    };

    useEffect(() => {
        if (Array.isArray(ticketPODetails?.approvalTeams)) {
            let teams = ticketPODetails?.approvalTeams.map((team) => {
                let selectedTeam = customerTeamsDropDown?.find((i) => i.value === team?.team?.id);
                return {
                    ...team,
                    team: selectedTeam,
                    teamName: team?.team?.name,
                    id: uuidv4(),
                };
            });
            setValue(approversArrayName, teams);
        }
    }, [customerTeamsDropDown, setValue, ticketPODetails?.approvalTeams]);

    function onDragEnd(result) {
        if (!result.destination) return;
        let sourceIndex = result.source.index;
        let destinationIndex = result.destination.index;
        swap(sourceIndex, destinationIndex);
    }

    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: 'none',
        backgroundColor: isDragging ? '#cae4f6' : 'white',
        ...draggableStyle,
    });

    return (
        <div className='relative'>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='item_list'>
                    {(provided) => (
                        <ul {...provided.droppableProps} ref={provided.innerRef}>
                            {fields.map((field, index) => {
                                return (
                                    <Draggable key={field.id} draggableId={`item_list_${field.id}`} index={index}>
                                        {(provided, snapshot) => (
                                            <li
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                            >
                                                <ApproverItem approversArrayName={approversArrayName} ticketId={ticketId} field={field} index={index} onRemove={onRemove} />
                                            </li>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </ul>
                    )}
                </Droppable>
                <div className='px-4 py-2'>
                    <ButtonScogoOrange
                        textOrComponent={'Add Approver'}
                        onClick={() => {
                            append({ id: uuidv4(), isNew: true, users: [], closed: 0, email: '' });
                        }}
                    />
                </div>
            </DragDropContext>
        </div>
    );
};
