import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import Form from '../../../common/Form';
import { useSelector } from 'react-redux';
import Select from '../../../common/Form/Select';
import { useForm } from 'react-hook-form';
import TextArea from '../../../common/Form/TextArea';
import { updateUserStatus } from '../../../actions/users';
import { actualValueForDropdown, validateStringValue } from '../../../utils/common';

const formName = 'disable_user';

const sevenDays = { key: 'for_seven_days', label: '7 Days', value: '7' };
const fifteenDays = { key: 'for_fifteen_days', label: '15 Days', value: '15' };
const permanent = { key: 'for_permanent', label: 'Permanent', value: 'Permanent' };
const options = [sevenDays, fifteenDays, permanent];

export default function DisableUser({ partner, role, userType, customer, fk_cluster_id }) {
    const defaultValues = {};
    const dispatch = useDispatch();
    const { formButtonLoading } = useSelector((state) => state.loading);

    const durationOption = useMemo(() => {
        let list = options;
        if (parseFloat(partner.disabled_duration) === 7) list = [fifteenDays, permanent];
        if (parseFloat(partner.disabled_duration) === 15) list = [permanent];
        return list;
    }, [partner?.disabled_duration]);

    const submit = (formValues) => {
        const userName = `${validateStringValue(partner?.first_name)} ${validateStringValue(partner?.last_name)}`;
        let payload = {
            userId: partner.id,
            status: 'disable',
            role,
            userType,
            customer,
            formName: 'disable_user',
            userName,
            fk_cluster_id,
            disabled_duration: actualValueForDropdown(formValues.disabled_duration),
            msg: formValues.msg,
        };
        dispatch(updateUserStatus(payload));
    };

    const methods = useForm({ defaultValues, mode: 'all' });

    return (
        <Form
            methods={methods}
            onSubmit={submit}
            formName={formName}
            submitButtonText={'Disable'}
            onCancel={() => dispatch(closeModalAction())}
            showSubmit
            submitContainerClassName='flex gap-2 justify-end py-4 px-4'
            isLoading={formButtonLoading[formName]}
        >
            <div className='flex gap-2 px-2'>
                <Select className='w-6/12' name='disabled_duration' label='Disable For' options={durationOption} required />
                <TextArea className='w-6/12' label='Remark' name='msg' type={'text'} required />
            </div>
        </Form>
    );
}
