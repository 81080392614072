import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { omit } from 'lodash';
import _ from 'lodash';
import FormInput from './Input';
import { deepEqual } from '../../../../utils/common';
import ButtonScogoPrimary from '../../../../common/Buttons/ButtonScogoPrimary';
import ButtonScogoClosedOutlined from '../../../../common/Buttons/ButtonScogoClosedOutlined';

export const FormContext = React.createContext({
    form: {},
});

export const recursive = (children, callback, depth) => {
    if (!children || depth > 20) return;
    const r = (child) => {
        if (typeof child === 'object') {
            if (child.type === FormInput || child?.type?.name === (<FormInput />).type.name) {
                callback(child);
            } else if (child?.props) {
                recursive(child.props.children, callback, depth + 1);
            }
        }
    };
    if (Array.isArray(children)) {
        children.forEach((child) => {
            if (Array.isArray(child)) {
                recursive(child, callback, depth + 1);
            } else {
                r(child);
            }
        });
    } else {
        r(children);
    }
};
// Reusable Form Component
function Form(props) {
    const {
        children,
        submit = () => {},
        onCancel = () => {},
        buttonPostion,
        buttonStyleClass,
        form,
        setForm,
        initialValues,
        refreshFormIfThisUpdate,
        formName,
        isPreventDefault = true,
        customFormButtons = false,
        isSubmitDisabled = false,
        formRef,
        showCancelButton = true,
        allowEmptySubmit = false,
        formClass = '',
        containerClass = '',
        errorMessage,
    } = props;

    const [errors, setErrors] = useState({});
    const [requiredWrapper, setRequiredWrapper] = useState();
    const { formButtonLoading } = useSelector((state) => state.loading);
    const { setInitialForm } = useSelector((state) => state.utils);
    let customValidationHandler = (value, name, errorMessage, customValidation) => {
        if (!customValidation) return;
        let isValid = true;
        if (typeof customValidation === 'function') {
            isValid = customValidation(value);
            if (value && !isValid) {
                const newErrors = JSON.parse(JSON.stringify(errors));
                _.set(newErrors, name, `${errorMessage ? errorMessage : `This Field is Required`}`);
                setErrors(newErrors);
                // setErrors(Object.assign({}, errors, { [`${name}`]: `${errorMessage ? errorMessage : `This Field is Required`}` }))
            }
        }
        return;
    };

    const validate = (value, name, required, errorMessage) => {
        if (!value && required) {
            const newErrors = _.cloneDeep(errors);
            _.set(newErrors, name, `${errorMessage ? errorMessage : `This Field is Required`}`);
            setErrors(newErrors);
            // setErrors(Object.assign({}, errors, { [`${name}`]: `${errorMessage ? errorMessage : `This Field is Required`}` }))
        } else {
            let newObj = omit(errors, name);
            setErrors(newObj);
        }
    };

    const getRequiredFiled = (children) => {
        let requiredObject = {};
        recursive(
            children,
            (child) => {
                if (child.props.required) {
                    _.set(requiredObject, child.props.name, child.props.required);
                }
                // requiredObject[child.props.name] = child.props.required;
            },
            0
        );
        return requiredObject;
    };

    useEffect(() => {
        let requiredFields = getRequiredFiled(children);
        if (!deepEqual(requiredWrapper, requiredFields)) {
            setRequiredWrapper(requiredFields);
            setErrors({});
        }
    }, [children]);

    useEffect(() => {
        setForm(initialValues);
    }, [setInitialForm, refreshFormIfThisUpdate]);

    const handleFormChange = (event, errorMessage) => {
        const { name, value, required } = event.target ? event.target : event;
        let trimmedValue = value;
        if (typeof trimmedValue === 'string') {
            trimmedValue = trimmedValue.trimStart();
        }
        setForm((prevForm) => {
            const newForm = _.cloneDeep(prevForm);
            _.set(newForm, name, trimmedValue);
            return newForm;
        });
        validate(value, name, required, errorMessage);
    };

    const handleFormOnBlur = (event, errorMessage, customValidation) => {
        const { name, value } = event.target ? event.target : event;
        setForm((prevForm) => {
            const newForm = _.cloneDeep(prevForm);
            _.set(newForm, name, value);
            return newForm;
        });
        customValidationHandler(value, name, errorMessage, customValidation);
    };

    const handleFileUpload = (event, errorMessage, overrideExistingFiles = true) => {
        const { name, files, required } = event.target;
        setForm((prevForm) => {
            const newForm = _.cloneDeep(prevForm);
            if (overrideExistingFiles) {
                _.set(newForm, name, files);
                return newForm;
            } else {
                const dataTransferList = new DataTransfer();
                if (prevForm[name] && prevForm[name]?.length > 0) {
                    for (const file of prevForm[name]) {
                        dataTransferList.items.add(file);
                    }
                }
                for (const file of files) {
                    dataTransferList.items.add(file);
                }
                _.set(newForm, name, dataTransferList.files);
                return newForm;
            }
        });
        validate(files, name, required, errorMessage);
    };

    const handleClearFileUpload = (files, name) => {
        setForm((prevForm) => {
            const newForm = _.cloneDeep(prevForm);
            _.set(newForm, name, [...files]);
            return newForm;
        });
    };
    const clean = (form) => {
        return Object.assign(
            {},
            ...Object.entries(form).map(([key, value]) => {
                let cleanedValue;
                if (Array.isArray(value)) {
                    cleanedValue = value.map((v) => {
                        if (typeof v === 'object') return clean(v);
                        return typeof v === 'string' ? v.trim() : v;
                    });
                } else {
                    cleanedValue = typeof value === 'string' ? value.trim() : value;
                }
                return { [key]: cleanedValue };
            })
        );
    };

    const setRequiredErrors = (form, errors, wrapper = requiredWrapper, prevKey) => {
        Object.entries(wrapper).forEach(([key, value]) => {
            const newKey = prevKey ? `${prevKey}.${key}` : key;
            if (Array.isArray(value)) {
                value.forEach((obj, idx) => {
                    setRequiredErrors(form, errors, obj, `${newKey}[${idx}]`);
                });
            } else if (typeof value === 'boolean' && value && !_.get(form, newKey)) {
                _.set(errors, newKey, `This Field is Required`);
            }
        });
    };

    const hasErrors = (errors) => {
        let isErrored = false;
        if (errors === undefined) return isErrored;
        Object.values(errors).forEach((errorMessage) => {
            if (Array.isArray(errorMessage)) {
                errorMessage.forEach((obj) => {
                    const e = hasErrors(obj);
                    if (e) isErrored = true;
                });
            } else if (typeof errorMessage === 'object') {
                const e = Object.keys(errorMessage).length > 0;
                if (e) isErrored = true;
            } else {
                if (errorMessage) isErrored = true;
            }
        });
        return isErrored;
    };

    const formSubmit = (form) => {
        const newErrors = _.cloneDeep(errors);
        setRequiredErrors(form, newErrors);
        setErrors(newErrors);
        if (!hasErrors(newErrors) && (Object.keys(form).length !== 0 || allowEmptySubmit)) {
            submit(clean(form));
        }
    };

    const handleCancel = (e) => {
        e.preventDefault();
        onCancel();
    };

    const handleFormSubmit = (event) => {
        if (event.keyCode === 13 && isPreventDefault) {
            event.preventDefault();
            formSubmit(form);
        }
    };

    return (
        <form className={`Form ${formClass}`} onKeyDown={(event) => handleFormSubmit(event)}>
            <FormContext.Provider
                value={{
                    form,
                    errors,
                    handleFormChange,
                    handleFormOnBlur,
                    handleFileUpload,
                    handleClearFileUpload,
                }}
            >
                <div className={containerClass}>{children}</div>
            </FormContext.Provider>

            <div className={buttonStyleClass ? buttonStyleClass : `px-6 gap-4 pb-6 pt-6 flex items-center ${buttonPostion}`}>
                {errorMessage && <span className='text-scogoclosed'>{errorMessage}</span>}
                {!customFormButtons && (
                    <>
                        <ButtonScogoPrimary
                            textOrComponent={props?.submitButtonName ? props.submitButtonName : 'Create'}
                            disabled={isSubmitDisabled}
                            onClick={(e) => {
                                e.preventDefault();
                                formSubmit(form);
                            }}
                            loading={formButtonLoading?.[formName]}
                        />
                        {showCancelButton ? (
                            <ButtonScogoClosedOutlined textOrComponent={props?.cancelButtonName ? props.cancelButtonName : 'Cancel'} onClick={handleCancel} />
                        ) : (
                            <></>
                        )}
                    </>
                )}
            </div>
            <div
                ref={formRef}
                onClick={(e) => {
                    e.preventDefault();
                    formSubmit(form);
                }}
                className='invisible'
            ></div>
        </form>
    );
}

export default Form;
