import React from "react";
import _ from 'lodash';

export default function CheckboxCard(props) {
    const { className, checkColor = 'text-scogoorange mr-2', checkContent, onChangeHandler, isEditable = true, name, form } = props

    const handleChange = (event) => {
        if (isEditable) {
            onChangeHandler(event, checkContent)
        }
    }

    return (
        <>
            <div className={`checkbox-card px-1 py-1 relative float-left ${className ? className : ''}`} >
                <input
                    type="checkbox"
                    onChange={handleChange}
                    className={`${checkColor} absolute top-0 left-0 opacity-0 cursor-pointer w-full h-full focus:outline-none focus:ring-0`}
                    checked={_.get(form, name)}
                    name={name}
                />
                <span className="flex items-center justify-center font-normal text-base rounded-md w-full h-full p-2 border border-solid border-black">
                    {checkContent}
                </span>
            </div>
        </>
    )
}
