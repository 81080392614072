import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Avatar from '@mui/material/Avatar';

export const ChatMsgLoader = ({ length = 2 }) => {
    return (
        <div className='overflow-hidden'>
            {[1, 2, 3, 4].map((item) => (
                <MessageLoader key={item} length={length} />
            ))}
        </div>
    );
};

const MessageLoader = ({ length }) => {
    return (
        <>
            <div className='flex items-center my-4'>
                <div className='mr-4'>
                    <Skeleton variant='circular'>
                        <Avatar sx={{ height: '4rem', width: '4rem' }} />
                    </Skeleton>
                </div>
                <div className='w-1/5'>
                    <Skeleton
                        sx={{ background: 'rgb(237, 235, 235)', borderRadius: '2rem' }}
                        variant='rectangular'
                        width={'full'}
                        height={20}
                        animation='wave'
                    />
                </div>
            </div>
            <div className='pt-2 w-full'>
                {Array.from({ length: length }).map((_, count) => {
                    return (
                        <div className='mt-2' key={count}>
                            <Skeleton
                                sx={{ background: 'rgb(237, 235, 235)', borderRadius: '2rem' }}
                                variant='rectangular'
                                width={'full'}
                                height={20}
                                animation='wave'
                            />
                        </div>
                    );
                })}
            </div>
        </>
    );
};
