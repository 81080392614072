import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllExistingCities, getAllCities, updateServiceLocations, removeExistingCitiesFromStore } from '../../../actions/users';
import { getSPNewTicketsList } from '../../../actions/ticketAssignment';
import GridTable from '../../../common/GridTable';
import { tableHead } from '../../../common/tabeHelpers';
import Buttons from '../../../common/Buttons';
import { openCustomModal } from '../../../actions/modal';
import DropdownField from '../../Inventory/reusableComponents/AddOption/fields/DropdownField';
import InputField from '../../Inventory/reusableComponents/AddOption/fields/InputField';
import { copyToClipboard } from '../../../actions/utils';
import ShareFeasibilityLink from './ShareFeasibilityLink';
import { SkeltonCardTwo } from '../../../common/SkeltonCardTwo';
import { closeModalAction } from '../../../actions/modal';
import { clearAssignSpNewTicketFromStore, respondFeasibility } from '../../../actions/tickets';
import ButtonScogoPrimary from '../../../common/Buttons/ButtonScogoPrimary';
import OverflowTip from '../../../common/OverflowTip';
import ButtonScogoClosedOutlined from '../../../common/Buttons/ButtonScogoClosedOutlined';

const linkTypes = [
    { key: 1, value: 'fibre', label: 'Fibre' },
    { key: 2, value: 'rf', label: 'RF' },
];

const addCitiesFormName = 'addCitiesFormName';
const isTruthValue = (item) => !['', 0, '0', undefined, null].includes(item);
const defaultLimit = 10;

export default function ISPCloseFeasibility({ userDetails }) {
    const { customerId, sp_id, mobile, id } = userDetails;
    const dispatch = useDispatch();
    const closeModal = () => {
        dispatch(closeModalAction());
    };
    const { cities, existingCities } = useSelector((state) => state.user);
    const { spAssignedNewTickets } = useSelector((state) => state.tickets);
    const { isNewSPAssignedLoading, formButtonLoading, allCityLoading, allExistingCityLoading } = useSelector((state) => state.loading);
    const [isChangedServiceLocations, setIsChangedServiceLocations] = useState(false);

    const [ispTicketsList, setIspTicketsList] = useState([]);

    const [rowsPerPage, setRowsPerPage] = useState(defaultLimit);

    const initialValues = {
        service_locations: existingCities,
        link_type: '',
    };

    const [form, setForm] = useState(initialValues);
    const [ticketForm, setTicketForm] = useState({});

    const handleUpdateServiceLocation = (event) => {
        const { value } = event;
        setForm({ ...form, service_locations: value });
        setIsChangedServiceLocations(true);
    };

    const getNewTickets = useCallback(
        ({ sp_id = customerId, user_type = 'ISP', page } = {}) => {
            dispatch(getSPNewTicketsList({ sp_id, user_type, page }));
        },
        [customerId, dispatch]
    );

    useEffect(() => {
        dispatch(getAllCities());
        dispatch(getAllExistingCities(sp_id));
        getNewTickets({ page: 1 });

        return () => {
            dispatch(clearAssignSpNewTicketFromStore());
            dispatch(removeExistingCitiesFromStore());
        };
    }, [customerId, dispatch, sp_id, getNewTickets]);

    useEffect(() => {
        const getLinkTypeFromOptions = (linkType) => {
            let typeFromOption = linkTypes?.filter((elem) => elem.value === linkType);
            return typeFromOption?.length > 0 ? typeFromOption[0] : '';
        };
        let intialTicketData = {};
        let ticketsToDisplay =
            Array.isArray(spAssignedNewTickets) &&
            spAssignedNewTickets.length > 0 &&
            spAssignedNewTickets.filter((item) => {
                let ispLinkDetails, ispResponseDetails;
                if (Array.isArray(item.assets_isp_link_details) && item.assets_isp_link_details.length > 0) {
                    ispLinkDetails = item.assets_isp_link_details.find((i) => i.fk_isp_id === customerId);
                }
                if (Array.isArray(item.isp_ticket_response) && item.isp_ticket_response.length > 0) {
                    ispResponseDetails = item.isp_ticket_response.find((i) => i.fk_isp_id === customerId);
                }
                if (ispResponseDetails && ['YES', 'NO'].includes(ispResponseDetails.response)) {
                    item.ispResponded = 1;
                } else {
                    intialTicketData[`link_type_${item.id}`] =
                        ispResponseDetails && isTruthValue(ispResponseDetails.link_type)
                            ? ispResponseDetails.link_type
                            : ispLinkDetails
                                ? getLinkTypeFromOptions(ispLinkDetails.link_type)
                                : undefined;

                    intialTicketData[`otc_${item.id}`] =
                        ispResponseDetails && isTruthValue(ispResponseDetails.otc)
                            ? ispResponseDetails.otc
                            : ispLinkDetails
                                ? ispLinkDetails.otc
                                : '';

                    intialTicketData[`router_cost_${item.id}`] =
                        ispResponseDetails && isTruthValue(ispResponseDetails.router_cost)
                            ? ispResponseDetails.router_cost
                            : ispLinkDetails
                                ? ispLinkDetails.router_cost
                                : '';

                    intialTicketData[`monthly_plan_${item.id}`] =
                        ispResponseDetails && isTruthValue(ispResponseDetails.monthly_plan)
                            ? ispResponseDetails.monthly_plan
                            : ispLinkDetails
                                ? ispLinkDetails.monthly_plan
                                : '';

                    intialTicketData[`static_ip_charge_${item.id}`] =
                        ispResponseDetails && isTruthValue(ispResponseDetails.static_ip_charge)
                            ? ispResponseDetails.static_ip_charge
                            : ispLinkDetails
                                ? ispLinkDetails.static_ip_charge
                                : '';
                    intialTicketData[`static_ip_charge_${item.id}`] =
                        ispResponseDetails && isTruthValue(ispResponseDetails.static_ip_charge)
                            ? ispResponseDetails.static_ip_charge
                            : ispLinkDetails
                                ? ispLinkDetails.static_ip_charge
                                : '';

                    intialTicketData[`remark_${item.id}`] =
                        ispResponseDetails && isTruthValue(ispResponseDetails.remark)
                            ? ispResponseDetails.remark
                            : ispLinkDetails
                                ? ispLinkDetails.remark
                                : '';
                }

                if (
                    ['', undefined, null].includes(ispResponseDetails) ||
                    (ispResponseDetails && ['', undefined, null].includes(ispResponseDetails.response))
                ) {
                    return item;
                }
            });

        setTicketForm({ ...ticketForm, ...intialTicketData });

        setIspTicketsList(ticketsToDisplay);
    }, [spAssignedNewTickets, customerId]);

    useEffect(() => {
        setForm({ ...form, service_locations: existingCities });
    }, [existingCities]);

    const handleUIIfNotFilleddata = ({ ticket, assetType }) => {
        let submit = true;
        let message;
        if (!isTruthValue(ticketForm[`otc_${ticket.id}`])) {
            submit = false;
            message = 'OTC is required';
        }
        if (!isTruthValue(ticketForm[`router_cost_${ticket.id}`])) {
            submit = false;
            message = 'Router Cost is required';
        }
        if (!isTruthValue(ticketForm[`monthly_plan_${ticket.id}`])) {
            submit = false;
            message = 'Monthly Plan is required';
        }
        if (!isTruthValue(ticketForm[`static_ip_charge_${ticket.id}`])) {
            submit = false;
            message = 'IP Charge is required';
        }
        if (!isTruthValue(ticketForm[`link_type_${ticket.id}`])) {
            submit = false;
            message = 'Link Type is required';
        }

        if (assetType === 'BB' && !isNaN(ticketForm[`otc_${ticket.id}`]) && parseInt(ticketForm[`otc_${ticket.id}`]) > 15000) {
            submit = false;
            message = 'OTC should be less than 15000';
        }
        if (!isNaN(ticketForm[`router_cost_${ticket.id}`]) && parseInt(ticketForm[`router_cost_${ticket.id}`]) > 5000) {
            submit = false;
            message = 'Router Cost should be less than 5000';
        }
        if (assetType === 'ILL' && !isNaN(ticketForm[`monthly_plan_${ticket.id}`]) && parseInt(ticketForm[`monthly_plan_${ticket.id}`]) < 5000) {
            submit = false;
            message = 'Monthly Plan should be more than 5000';
        }
        if (
            assetType === 'BB' &&
            !isNaN(ticketForm[`monthly_plan_${ticket.id}`]) &&
            (parseInt(ticketForm[`monthly_plan_${ticket.id}`]) < 100 || parseInt(ticketForm[`monthly_plan_${ticket.id}`]) > 5000)
        ) {
            submit = false;
            message = 'Monthly Plan should be more than 100 but less than 5000';
        }

        if (!isNaN(ticketForm[`static_ip_charge_${ticket.id}`]) && parseInt(ticketForm[`static_ip_charge_${ticket.id}`]) > 5000) {
            submit = false;
            message = 'Static IP Charge should be less than 5000';
        }

        return { submit, message };
    };

    const handleAcceptTickets = ({ ticketForm, ticket }) => {
        const formName = `accept_${ticket?.id}`;
        const ispDetails = {};
        let assetType = '';
        if (ticket.assets && Array.isArray(ticket.assets) && ticket.assets.length > 0) {
            const assetDetails = ticket.assets[0];
            if (assetDetails) {
                assetType = assetDetails.title;
            }
        }

        const { submit, message } = handleUIIfNotFilleddata({ ticket, assetType });

        if (ticketForm[`otc_${ticket.id}`]) {
            ispDetails.otc = ticketForm[`otc_${ticket.id}`];
        }
        if (ticketForm[`router_cost_${ticket.id}`]) {
            ispDetails.router_cost = ticketForm[`router_cost_${ticket.id}`];
        }
        if (ticketForm[`monthly_plan_${ticket.id}`]) {
            ispDetails.monthly_plan = ticketForm[`monthly_plan_${ticket.id}`];
        }
        if (ticketForm[`static_ip_charge_${ticket.id}`]) {
            ispDetails.static_ip_charge = ticketForm[`static_ip_charge_${ticket.id}`];
        }
        if (ticketForm[`link_type_${ticket.id}`]) {
            ispDetails.link_type = ticketForm[`link_type_${ticket.id}`]?.value;
        }
        if (ticketForm[`remark_${ticket.id}`]) {
            ispDetails.remark = ticketForm[`remark_${ticket.id}`];
        }

        const data = {
            ticketId: ticket.id,
            response: 'YES',
            ispId: customerId,
            data: ispDetails,
        };

        if (submit) {
            dispatch(respondFeasibility({ data, closeModal: false, refresh: true, ispId: customerId, formName, isGetAssignedNewTicketList: true }));
        } else alert(message);
    };

    const handleRejectTickets = ({ ticketId }) => {
        const formName = `reject_${ticketId}`;
        const data = {
            ticketId,
            response: 'NO',
            ispId: customerId,
            data: {},
        };

        dispatch(respondFeasibility({ data, closeModal: false, refresh: true, ispId: customerId, formName, isGetAssignedNewTicketList: true }));
    };

    const handleOpenLocation = (viewTicket) => {
        return window.open(
            `https://maps.google.com?q=${!['', undefined, null, -1.0, '-1.0000000', 0.0, '0.0000000'].includes(viewTicket.latitude) &&
                !['', undefined, null, -1.0, '-1.0000000', 0.0, '0.0000000'].includes(viewTicket.longitude)
                ? `${viewTicket.latitude},${viewTicket.longitude}`
                : `${viewTicket.job_site_address_1}, ${viewTicket.job_fk_pincode_id}`
            }`,
            '_blank'
        );
    };

    const handleCopyLink = (ispMobile) => {
        let forCopy = `${window.location.origin}/?isp_number=${ispMobile}&redirect_uri=${encodeURIComponent(
            `${window.location.origin}/feasibility`
        )}`;
        dispatch(copyToClipboard({ data: forCopy }));
    };

    const handleShareFeasibilityLink = (item) => {
        let heading = 'Share Feasibility Link';
        const isps = [customerId];
        let modalComponent = (
            <ShareFeasibilityLink closeModal={closeModal} userDetails={item} role={7} customer={'scogo'} userType={'ISP'} isps={isps} />
        );
        let modalWidth = '60rem',
            modalHeight = 'auto';
        dispatch(openCustomModal({ modalComponent, heading, modalWidth, modalHeight }));
    };

    const updateCities = () => {
        const cities = form?.service_locations?.map((item) => item.value);
        const updatedCities = form?.service_locations?.map((city) => {
            city = city?.label?.split(',');
            return city[0];
        });
        const payload = {
            data: { add_service_location: cities },
            servicePartnerId: sp_id,
            callApiForUpdateData: false,
            formName: addCitiesFormName,
            closedModal: false,
            updatedCities,
        };
        if (id) payload.userIds = [id]
        dispatch(updateServiceLocations(payload));
    };

    const handleChange = (event, name) => {
        const { value } = event.target ? event.target : event;
        setTicketForm({ ...ticketForm, [name]: value });
    };

    const onPageChange = (limit, page) => {
        getNewTickets({ page });
    };
    const tableSchema = {
        table: [
            {
                head: {
                    headName: 'Site Details',
                    render: (headName) => headName,
                    width: 1.5,
                },
                body: {
                    render: (item) => {
                        return (
                            <div>
                                <p className='font-medium text-scogobgsky'>{item?.job_ticket_number}</p>
                                <p className='font-normal'>Address &amp; Pincode</p>
                                <OverflowTip
                                    someLongText={
                                        item?.job_site_address_1
                                            ? `${item?.job_site_address_1}${item?.job_fk_pincode_id ? `, ${item?.job_fk_pincode_id}` : ''}`
                                            : ''
                                    }
                                />
                                <p className='font-normal break-words	 break-all	'>
                                    {item.job_site_address_1 !== '' ? (
                                        <span
                                            className='cursor-pointer material-icons text-scogobgsky text-lg'
                                            onClick={() => handleOpenLocation(item)}
                                        >
                                            place
                                        </span>
                                    ) : (
                                        ''
                                    )}
                                </p>
                            </div>
                        );
                    },
                },
            },
            {
                head: tableHead('Link Type'),
                body: {
                    render: (item) => {
                        let name = `link_type_${item.id}`;
                        return (
                            <DropdownField
                                type='number'
                                className='w-full z-20'
                                name={name}
                                options={linkTypes}
                                form={ticketForm}
                                errors={{}}
                                value={ticketForm[name]}
                                onBlurHandler={() => { }}
                                onChangeHandler={(event) => handleChange(event, name)}
                            />
                        );
                    },
                },
            },
            {
                head: tableHead('One Time Charge'),
                body: {
                    render: (item) => {
                        let name = `otc_${item.id}`;
                        return (
                            <InputField
                                type='number'
                                placeholder='One Time Charge'
                                className='w-full'
                                name={name}
                                value={ticketForm[name]}
                                options={cities}
                                form={ticketForm}
                                errors={{}}
                                onBlurHandler={() => { }}
                                onChangeHandler={(event) => handleChange(event, name)}
                            />
                        );
                    },
                },
            },
            {
                head: tableHead('Router Cost'),
                body: {
                    render: (item) => {
                        let name = `router_cost_${item.id}`;
                        return (
                            <InputField
                                type='number'
                                placeholder='Router Cost'
                                className='w-full'
                                name={name}
                                value={ticketForm[name]}
                                form={ticketForm}
                                options={cities}
                                errors={{}}
                                onBlurHandler={() => { }}
                                onChangeHandler={(event) => handleChange(event, name)}
                            />
                        );
                    },
                },
            },
            {
                head: tableHead('Monthly Plan'),
                body: {
                    render: (item) => {
                        let name = `monthly_plan_${item.id}`;
                        return (
                            <InputField
                                type='number'
                                placeholder='Monthly Plan'
                                className='w-full'
                                name={name}
                                options={cities}
                                form={ticketForm}
                                value={ticketForm[name]}
                                errors={{}}
                                onBlurHandler={() => { }}
                                onChangeHandler={(event) => handleChange(event, name)}
                            />
                        );
                    },
                },
            },
            {
                head: tableHead('Static IP Change'),
                body: {
                    render: (item) => {
                        let name = `static_ip_charge_${item.id}`;
                        return (
                            <InputField
                                type='text'
                                placeholder='Static IP Chanrge'
                                className='w-full'
                                name={`static_ip_charge_${item.id}`}
                                options={cities}
                                form={ticketForm}
                                value={ticketForm[name]}
                                errors={{}}
                                onBlurHandler={() => { }}
                                onChangeHandler={(event) => handleChange(event, name)}
                            />
                        );
                    },
                },
            },
            {
                head: tableHead('Remark'),
                body: {
                    render: (item) => {
                        let name = `remark_${item.id}`;
                        return (
                            <InputField
                                placeholder='Remarks'
                                className='w-full'
                                name={name}
                                options={cities}
                                form={ticketForm}
                                value={ticketForm[name]}
                                errors={{}}
                                onBlurHandler={() => { }}
                                onChangeHandler={(event) => handleChange(event, name)}
                            />
                        );
                    },
                },
            },
            {
                head: tableHead('Feasibilty'),
                body: {
                    render: (item) => {
                        return (
                            <div className='flex'>
                                <Buttons
                                    children='Yes'
                                    buttonClass='bg-white border border-scogobgsky h-10 leading-8 cursor-pointer text-scogobgsky text-font12 font-normal rounded-l-md px-4'
                                    onClick={() => handleAcceptTickets({ ticketForm, ticket: item })}
                                    loading={formButtonLoading[`accept_${item?.id}`]}
                                    disabled={formButtonLoading[`accept_${item?.id}`]}
                                />
                                <Buttons
                                    children='No'
                                    buttonClass='bg-white border border-scogoclosed  h-10 leading-8 cursor-pointer text-scogoclosed text-font12 font-normal rounded-r-md px-4'
                                    onClick={() => handleRejectTickets({ ticketId: item?.id })}
                                    loading={formButtonLoading[`reject_${item?.id}`]}
                                    disabled={formButtonLoading[`accept_${item?.id}`]}
                                />
                            </div>
                        );
                    },
                },
            },
        ],
    };

    const ticketsDataAvailable = Array.isArray(ispTicketsList) && ispTicketsList.length > 0;

    const handleTableWithLoader = ({ isLoading, ticketsDataAvailable }) => {
        if (isLoading) return <SkeltonCardTwo arr={[1, 2, 3]} height={20} />;
        else if (!ticketsDataAvailable) {
            return (
                <div className='py-2 items-center flex px-4 rounded-md' style={{ border: '1px solid rgb(91,147,164)', color: 'rgb(91,147,164)' }}>
                    <p className='text-font11'>No Pending Tickets to Accept</p>
                </div>
            );
        }

        return (
            <GridTable
                isLoading={isNewSPAssignedLoading}
                schema={tableSchema}
                rowData={ispTicketsList}
                tableError={'No Pending Tickets to Accept'}
                tablePagination={true}
                count={ispTicketsList?.length > 0 ? ispTicketsList.length : 0}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                tablePaginationHandler={onPageChange}
                rowsPerPageOptions={[10]}
            />
        );
    };

    return (
        <div>
            <div className='px-3 flex flex-col justify-between'>
                <DropdownField
                    className='w-full'
                    label='Service Locations'
                    name='service_locations'
                    options={cities}
                    isMulti={true}
                    form={form}
                    onChangeHandler={handleUpdateServiceLocation}
                    errors={{}}
                    isLoading={allCityLoading || allExistingCityLoading}
                />
                {isChangedServiceLocations && (
                    <div className='ml-auto px-7'>
                        <ButtonScogoPrimary textOrComponent={'Update'} onClick={updateCities} loading={formButtonLoading[addCitiesFormName]} />
                    </div>
                )}
            </div>
            <div className='px-7 py-1 gap-2 flex'>
                <Buttons
                    children='Copy Link'
                    buttonClass='bg-white border border-scogobgsky px-4 h-8 leading-6 cursor-pointer text-scogobgsky text-font12 font-normal rounded-md'
                    onClick={() => handleCopyLink(mobile)}
                />
                <Buttons
                    children='Share Link'
                    buttonClass='bg-white border border-scogobgsky px-4 h-8 leading-6 cursor-pointer text-scogobgsky text-font12 font-normal rounded-md '
                    onClick={() => handleShareFeasibilityLink(userDetails)}
                />
            </div>
            <div className='px-7 py-4'>{handleTableWithLoader({ isLoading: isNewSPAssignedLoading, ticketsDataAvailable })}</div>
            <div className='justify-end flex px-6 py-4'>
                <ButtonScogoClosedOutlined textOrComponent='Cancel' onClick={closeModal} />
            </div>
        </div>
    );
}
