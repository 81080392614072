import React from 'react';
import { useSelector } from 'react-redux';
import { Editor } from '@tinymce/tinymce-react';

export const TermsAndCondtion = ({ customer = {}, editorRef }) => {
    const { customerDetail } = useSelector((state) => state.customer);
    return (
        <Editor
            apiKey='kws9xlqkjtannzo9nd51s2q0c3p0bt0ixceobd9aqd9vdfy7'
            onInit={(evt, editor) => (editorRef.current = editor)}
            initialValue={customer ? (customerDetail?.po_tnc ? customerDetail.po_tnc : '') : ''}
            init={{
                statusbar: false,
                height: 300,
                menubar: false,
                plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount',
                ],
                toolbar:
                    'fontselect fontsizeselect formatselect | ' +
                    'bold italic underline link strikethrough | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'forecolor backcolor removeformat | print help',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:10px }',
            }}
        />
    );
};
