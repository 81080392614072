export const getInventoryFilterData = state => {
    return {
        limit: state.inventory.rowsPerPage,
        page: state.inventory.page,
        query: state.inventory.query,
        filterPayload: state.inventory.filterPayload,
        siteId: state.inventory.siteId,
        warehouseId: state.inventory.warehouseId,
        archive: state.inventory.isArchivedSelected,
        isFilterActive: state.inventory.isFilterActive
    }
}

