import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createInventorySites } from '../../../actions/inventory';
import { closeModalAction } from '../../../actions/modal';
import { buttonClass, textCss } from '../../../common/Css';
import GridTable from '../../../common/GridTable';
import { getCustomerIdOfLoginUser } from '../../../utils/utils';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import ButtonScogoPrimary from '../../../common/Buttons/ButtonScogoPrimary';
import ButtonScogoClosedOutlined from '../../../common/Buttons/ButtonScogoClosedOutlined';

export const DuplicateSites = ({ sitedata, bulkUploadError, count, inventoryType, assetDetails }) => {
    const dispatch = useDispatch();
    const { loggedUser } = useSelector((state) => state.auth);

    const customerId = getCustomerIdOfLoginUser(loggedUser);
    const [csvHeaders, setCsvHeaders] = useState([]);
    const [csvData, setCsvData] = useState([]);

    useEffect(() => {
        let headers = [];
        let data = [];
        if (bulkUploadError) {
            if (inventoryType === 'sites' && sitedata.error_pincode) {
                headers.push('Reason');
                headers.push('external_site_id');
                headers.push('site_branch_name');
                headers.push('region');
                headers.push('zone');
                headers.push('complete_site_address');
                headers.push('fk_pincode_id');
                headers.push('site_contact_person_name');
                headers.push('site_contact_person_email');
                headers.push('site_contact_person_mobile');
                headers.push('site_vertical');
                sitedata.error_pincode.map((item) => data.push(item));
            } else if (inventoryType === 'assets' && assetDetails.asset_type_error.length > 0) {
                headers.push('Reason');
                headers.push('Asset type');
                headers.push('asset_details');
                headers.push('make');
                headers.push('model');
                headers.push('partcode');
                headers.push('serial_number');
                headers.push('external_order_id');
                assetDetails.asset_type_error.map((item) => data.push(item));
            }
            setCsvHeaders(headers);
            setCsvData(data);
        }
    }, [bulkUploadError]);

    const duplicateSitesHandler = (form) => {
        let sites = [
            {
                complete_site_address: form.complete_site_address,
                create_new: true,
                customer_end_user: form?.customer_end_user?.value,
                external_site_id: form.external_site_id,
                fk_city_id: form?.fk_city_id?.value,
                fk_customer_id: form?.fk_customer_id?.value,
                fk_pincode_id: form.fk_pincode_id,
                fk_state_id: form.fk_state_id,
                region: form.region,
                site_branch_name: form.site_branch_name,
                site_contact_person_email: form.site_contact_person_email,
                site_contact_person_mobile: form.site_contact_person_mobile,
                site_contact_person_name: form.site_contact_person_name,
                site_vertical: form.site_vertical,
                sla: form?.sla?.value,
                zone: form.zone,
            },
        ];
        dispatch(createInventorySites({ data: sites, customerId }));
        dispatch(closeModalAction());
    };

    const bluePrint = {
        table: [
            {
                head: {
                    thClassName: 'w-56 max-w-56 flex items-center pl-p-15 truncate',
                    render: (headName) => {
                        return headName;
                    },
                    headName: 'Site ID',
                },
                body: {
                    bodyClassName: 'w-56 max-w-56 flex items-center pl-p-15',
                    render: (item) => {
                        return <p className='font-bold text-scogobgsky'>{item.external_site_id}</p>;
                    },
                },
            },
            {
                head: {
                    thClassName: 'w-40 max-w-40 truncate',
                    render: (headName) => {
                        return headName;
                    },
                    headName: 'Site Address',
                },
                body: {
                    bodyClassName: 'w-40 max-w-40 truncate ',
                    render: (item) => {
                        return (
                            <>
                                <p>{item.complete_site_address}</p>
                            </>
                        );
                    },
                },
            },
            {
                head: {
                    thClassName: 'w-40 max-w-40 truncate',
                    render: (headName) => {
                        return headName;
                    },
                    headName: 'Pincode',
                    width: 0.5,
                },
                body: {
                    bodyClassName: 'w-40 max-w-40 truncate ',
                    render: (item) => {
                        return (
                            <>
                                <p>{item.fk_pincode_id}</p>
                            </>
                        );
                    },
                },
            },
        ],
    };

    const formBasedOnDuplicateType = (bulkUploadError, sitedata) => {
        if (!bulkUploadError && sitedata) {
            return (
                <>
                    <div className='px-2 py-4'>
                        <h1 className={`${textCss?.moduleTitle} mb-6 ml-2`}>New Site</h1>
                        <GridTable schema={bluePrint} rowData={sitedata.new} tableError={'No Ticket Found'} tableBodyHeight='max-h-max' />
                    </div>
                    <h1 className={`${textCss?.moduleTitle} mb-2 mt-4 ml-4`}>Existing Site</h1>
                    <div className='px-2 py-4 mt-4 max-h-80 overflow-scroll'>
                        <GridTable schema={bluePrint} rowData={sitedata.old} tableError={'No Ticket Found'} tableBodyHeight='max-h-max' />
                    </div>
                </>
            );
        } else if (bulkUploadError) {
            return (
                <div className='px-5 text-font14'>
                    <br />

                    <p className='text-scogoprimary'>
                        <b>Please</b>
                        <CSVLink
                            data={csvData}
                            headers={csvHeaders}
                            filename={`Bulk_Upload_${inventoryType}_Error_${moment(new Date()).format('DD-MM-YYYY')}.csv`}
                            className={'text-scogoorange'}
                        >
                            {' '}
                            Download the CSV.{' '}
                        </CSVLink>
                        <b className='text-scogoprimary'>
                            Make changes accordingly in downloaded csv and upload same. Also Remove the reason column from csv while uploading again.
                        </b>
                    </p>
                    <br />
                    {count > 0 && (
                        <p>
                            Also take action on <b>{count}</b> duplicate {inventoryType}
                        </p>
                    )}
                </div>
            );
        }
        return <></>;
    };

    const getModalButton = (sitedata) => {
        if (sitedata.length > 0) {
            return (
                <div className='flex justify-end px-4 py-4'>
                    <ButtonScogoPrimary textOrComponent={'Create New Scogo'} onClick={() => duplicateSitesHandler(sitedata?.new?.[0])} />
                    <ButtonScogoClosedOutlined
                        textOrComponent={'Cancel'}
                        onClick={() => {
                            dispatch(closeModalAction());
                        }}
                    />
                </div>
            );
        }
        return (
            <div className='flex justify-end px-4 py-4'>
                <ButtonScogoClosedOutlined
                    textOrComponent={'Close'}
                    onClick={() => {
                        dispatch(closeModalAction());
                    }}
                />
            </div>
        );
    };

    return (
        <>
            {formBasedOnDuplicateType(bulkUploadError, sitedata)}
            {getModalButton(sitedata)}
        </>
    );
};
