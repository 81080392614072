import React from 'react';
import { useDispatch } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import Form from '../../../common/Form';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import TextArea from '../../../common/Form/TextArea';
import { hardCloseTicket } from '../../../actions/tickets';

const formName = 'hardClose';

export default function HardCloseTicket({ ticket }) {
    const { formButtonLoading } = useSelector((state) => state.loading);
    const defaultValues = { job_sign_off_notes: '' };

    const dispatch = useDispatch();

    const methods = useForm({ defaultValues, mode: 'all' });

    const submit = (formValues) => {
        const payload = {
            ticket_id: ticket.ticket_id,
            job_sign_off_notes: formValues?.job_sign_off_notes,
        };
        dispatch(hardCloseTicket({ data: payload, formName }));
    };

    return (
        <>
            <Form
                methods={methods}
                onSubmit={submit}
                formName={formName}
                buttonPostion={'justify-end'}
                submitButtonText={'Submit'}
                onCancel={() => dispatch(closeModalAction())}
                showSubmit
                submitContainerClassName='flex gap-2 justify-end py-4 px-4'
                isLoading={formButtonLoading[formName]}
            >
                <div className='px-2'>
                    <TextArea name='job_sign_off_notes' className='w-full' label='Reason' height='6rem' required />
                </div>
            </Form>
        </>
    );
}
