import React from 'react';
import { useDispatch } from 'react-redux';
import { withdrawTicket } from '../../../../actions/ticketAssignment';
import ButtonScogoOrange from '../../../../common/Buttons/ButtonScogoOrange';
import { ConfirmationPopup } from '../../../../common/ConfirmationPopup';
import MiniPopup from '../../../../common/MiniPopup';

export const RelaseButton = ({ ticketId }) => {
    const dispatch = useDispatch();
    const formName = 'releaseTicket';
    const onClick = (closeMenu) => {
        dispatch(withdrawTicket({ data: { ticket_id: ticketId }, formName, callAfterSubmit: closeMenu, refresh: true }));
    };
    let popup = <ConfirmationPopup onClick={onClick} formName={formName} />;
    return (
        <MiniPopup
            handleClick={() => { }}
            popup={popup}
            transition={false}
            className='fixed px-3 py-2 mt-2 z-10 rounded-md shadow-lg bg-white overflow-auto left-0 top-0 border-2'
            menuStyle={{ left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
        >
            <ButtonScogoOrange textOrComponent={'Release'} buttonClass={`hover:bg-scogoorange hover:text-white z-0`} />
        </MiniPopup>
    );
};
