import React from 'react';
import _ from 'lodash';

import { textCss } from '../../../../../common/Css';
import Loader from '../../../../../common/Loader';
export default function InputField(props) {
    const {
        className,
        label,
        required,
        type,
        name,
        value,
        onChangeHandler,
        onBlurHandler,
        placeholder,
        errors,
        errorMessage,
        onKeyPress,
        isEditable = true,
        disabled,
        maxLength,
        rounded,
        leadingIcon,
        inputIcon,
        iconLoader,
        inputClass = 'text-font14',
        padding = 'px-4 py-3',
    } = props;

    const handleInputChange = (event, errorMessage) => {
        if (isEditable) onChangeHandler(event, errorMessage);
    };

    return (
        <>
            {rounded ? (
                <div className={`loginput-maindiv mt-2  ${className}`}>
                    {leadingIcon && leadingIcon}
                    <input
                        className={`w-full  text-scogo15 rounded-md py-2 ${
                            leadingIcon ? 'pl-12' : 'pl-5'
                        } pr-4 focus:outline-none mx-auto border-scogoccc focus:ring-r-none h-14 ${inputClass} ${
                            disabled ? 'cursor-not-allowed' : ''
                        }`}
                        style={_.get(errors, name) ? { borderColor: 'red' } : {}}
                        type={type}
                        required={required}
                        placeholder={placeholder}
                        disabled={disabled}
                        maxLength={maxLength}
                        onKeyPress={onKeyPress}
                        onBlur={(event) => {
                            onBlurHandler(event, errorMessage);
                        }}
                        name={name}
                        value={value}
                        onChange={(event) => handleInputChange(event, errorMessage)}
                    />
                    {_.get(errors, name) && <span className='text-scogoclosed text-font10 font-normal'>{_.get(errors, name)}</span>}
                </div>
            ) : (
                <div className={`${padding} ${className}`}>
                    <label className='block border-b-2 border-scogoddd'>
                        {label && (
                            <p className={`${textCss?.inputLabel}`}>
                                {label} {required && <span className='text-scogoorange'>*</span>}
                            </p>
                        )}
                        <div className=' w-full  flex'>
                            <div className='w-11/12'>
                                <input
                                    style={{ height: '3.1rem' }}
                                    type={type}
                                    onKeyPress={onKeyPress}
                                    name={name}
                                    required={required}
                                    value={value}
                                    onChange={(event) => handleInputChange(event, errorMessage)}
                                    placeholder={placeholder}
                                    className={`${textCss?.inputText} block w-full border-none focus:ring-0 ${disabled ? 'cursor-not-allowed' : ''}`}
                                    onBlur={(event) => {
                                        onBlurHandler(event, errorMessage);
                                    }}
                                    disabled={disabled}
                                    maxLength={maxLength}
                                />
                            </div>
                            <div className='w-1/12'>
                                {iconLoader ? <Loader color={'orange'} size='15' speed='0.5' thickness='2' /> : inputIcon && inputIcon}
                            </div>
                        </div>
                    </label>
                    {_.get(errors, name) && <span className='text-scogoclosed text-font10 font-normal'>{_.get(errors, name)}</span>}
                </div>
            )}
        </>
    );
}
