import { useFormContext, Controller } from 'react-hook-form';
import _ from 'lodash';
import { useState } from 'react';

const Rating = ({ label, name, required, className = '', shouldUnregister, onChangeHandler = () => { }, totalStars = 5 }) => {
    const {
        formState: { errors },
        control,
    } = useFormContext();

    const [hoverRating, setHoverRating] = useState(0);
    const handleMouseOver = (hoverValue) => {
        setHoverRating(hoverValue);
    };

    const handleMouseLeave = () => {
        setHoverRating(0);
    };
    return (
        <div className={`px-4 py-2 ${className}`}>
            {label && (
                <label>
                    <span className='text-scogo99 font-normal text-font09 md:text-font11'>
                        {label} {required && <span className='text-scogoorange'>*</span>}{' '}
                    </span>
                </label>
            )}
            <div className=' relative'>
                <Controller
                    key={name}
                    control={control}
                    name={name}
                    rules={{ required: { value: required, message: 'Required' } }}
                    shouldUnregister={shouldUnregister}
                    render={({ field: { onChange, value } }) => {
                        return Array.from({ length: totalStars }, (_, index) => index + 1).map((starValue) => (
                            <span
                                key={starValue}
                                style={
                                    starValue <= (hoverRating || value)
                                        ? { cursor: 'pointer', fontSize: '24px', color: 'gold' }
                                        : { cursor: 'pointer', fontSize: '24px', color: '#ccc' }
                                }
                                onClick={() => {
                                    onChange(starValue);
                                    onChangeHandler(starValue);
                                }}
                                onMouseOver={() => handleMouseOver(starValue)}
                                onMouseLeave={handleMouseLeave}
                            >
                                ★
                            </span>
                        ));
                    }}
                />
                <p className='text-scogoclosed text-font08 font-normal inputFontSize h-6'>{_.get(errors, name) ? _.get(errors, name).message : ''}</p>
            </div>
        </div>
    );
};

export default Rating;
