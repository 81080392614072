import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createInventoryWarehouses, getAllCities, removePinCityFromStore } from '../../../../actions/inventory';
import { getCustomerIdOfLoginUser } from '../../../../utils/utils';
import { getWareHouseOwners, getWareHouseDropdown } from '../../../../actions/inventory';
import { getCustomersList } from '../../../../actions/customer';
import { InventoryFormFields } from '../../InventoryController';
import { actualValueForDropdown, findPincode } from '../../../../utils/common';
import { sendToastMessage } from '../../../../actions/toast';
import Form from '../../../../common/Form';
import Input from '../../../../common/Form/Input';
import Select from '../../../../common/Form/Select';
import TextArea from '../../../../common/Form/TextArea';
import { useForm, useFormContext } from 'react-hook-form';
import apis from '../../../../apis';
import { useMemo } from 'react';
import { FetchGoogleAddress } from '../../../SupportTrack/RenderTicketCard';

export default function SiteForm(props) {
    const formName = 'wareHouseAddNewForm';
    const { closeForm } = props;
    const dispatch = useDispatch();
    const { loggedUser } = useSelector((state) => state.auth);
    const { formButtonLoading } = useSelector((state) => state.loading);
    const { setInitialForm } = useSelector((state) => state.utils);
    const customerId = getCustomerIdOfLoginUser(loggedUser);

    const defaultValues = {
        warehouseName: '',
        pincode: '',
        city: '',
        address: '',
        name: '',
        mobile: '',
        email: '',
        owner: '',
        warehouse: '',
        customer: '',
        state: '',
    };
    const isToShowCustomerOrWarehouseDropdown = InventoryFormFields('addNewWarehouseCustomerField');

    const getDropDownDataOnMount = useCallback(() => {
        dispatch(getAllCities());
        dispatch(getWareHouseOwners({}));
        if (isToShowCustomerOrWarehouseDropdown) {
            dispatch(getCustomersList());
            dispatch(getWareHouseDropdown({ dropdown: true, createWarehouse: true }));
        }
    }, [dispatch, isToShowCustomerOrWarehouseDropdown]);

    useEffect(() => {
        getDropDownDataOnMount();
        return () => dispatch(removePinCityFromStore());
    }, [getDropDownDataOnMount, dispatch]);

    const submit = (form) => {
        const formCustomerIdValue = actualValueForDropdown(form.customer);
        const formWarehouseValue = actualValueForDropdown(form.warehouse);
        const ownerValue = actualValueForDropdown(form.owner);

        const customer_id = !isNaN(formCustomerIdValue) && parseInt(formCustomerIdValue) > 0 ? formCustomerIdValue : customerId;
        const fk_user_id = !isNaN(ownerValue) && parseInt(ownerValue) > 0 ? ownerValue : 0;
        const city = actualValueForDropdown(form.city);
        const warehouse = !isNaN(formWarehouseValue) && parseInt(formWarehouseValue) > 0 ? formWarehouseValue : 0;

        let contacts = [];

        if (form.name || form.email || form.mobile) {
            contacts.push({
                name: form.name,
                mobile: form.mobile,
                email: form.email,
            });
        }

        let data = {
            customer: customer_id,
            fk_user_id,
            warehouse: formWarehouseValue,
            warehouses: [
                {
                    name: form.warehouseName,
                    pincode: form.pincode,
                    city,
                    state: form.state,
                    address: form.address,
                    customer_id,
                    fk_user_id,
                    contacts,
                    warehouse,
                },
            ],
        };

        if (Number.isInteger(customer_id) && customer_id > 0) {
            dispatch(createInventoryWarehouses({ data, customerId, formName }));
        } else {
            dispatch(sendToastMessage({ status: 'danger', message: 'Invalid Customer Id' }));
        }
    };

    const methods = useForm({ defaultValues, mode: 'all' });
    const { reset } = methods;

    useEffect(() => {
        if (setInitialForm) {
            reset();
        }
    }, [setInitialForm, reset])

    return (
        <Form
            className='w-full'
            onSubmit={submit}
            defaultValues={defaultValues}
            onCancel={closeForm}
            submitButtonText={'Create'}
            showSubmit
            submitContainerClassName='flex gap-2 py-4 px-4'
            isLoading={formButtonLoading[formName]}
            methods={methods}
        >
            <CreateWarehouseInputs isToShowCustomerOrWarehouseDropdown={isToShowCustomerOrWarehouseDropdown} />
        </Form>
    );
}

const CreateWarehouseInputs = ({ isToShowCustomerOrWarehouseDropdown }) => {
    const isToShowAddNewWarehouseDetails = InventoryFormFields('isToShowAddNewWarehouseDetails');
    const { isWarehouseOwnerLoading, isCustomerListLoading, isWarehouseDropdownLoading } = useSelector((state) => state.loading);
    const { warehouseOwner, warehousesDropDown, cities } = useSelector((state) => state.inventory);
    const { customers } = useSelector((state) => state.customer);

    const warehouseCustomer = useMemo(() => {
        let list = [];
        if (Array.isArray(customers)) {
            customers.forEach((item) => {
                if (item.is_rma_eligible === 1) {
                    list.push({
                        key: item.customer_id,
                        value: item.customer_id,
                        label: item.label,
                        customer_id: item.customer_id,
                    });
                }
            });
        }
        return list;
    }, [customers]);

    const warehouseDropdown = useMemo(() => {
        let list = [
            {
                key: 'add_new_warehouse',
                value: 'add_new_warehouse',
                label: 'Add New Warehouse',
            },
        ];
        if (Array.isArray(warehousesDropDown)) {
            list.push(...warehousesDropDown);
        }
        return list;
    }, [warehousesDropDown]);

    const { watch, setValue } = useFormContext();
    const warehouse = watch('warehouse');
    const addressValue = watch('address')

    const isAddNewWarehouse = actualValueForDropdown(warehouse) === 'add_new_warehouse' || isToShowAddNewWarehouseDetails;
    if (addressValue) { var pincodeFromAddress = findPincode(addressValue) }

    useEffect(() => {
        const isPincodeAddedInAddressField = addressValue && Boolean(findPincode(addressValue));
        if (isPincodeAddedInAddressField) {

            setValue('pincode', pincodeFromAddress)
        }
    }, [setValue, pincodeFromAddress, addressValue])


    return (
        <div className='px-3'>
            {isToShowCustomerOrWarehouseDropdown && (
                <div className='grid grid-cols-2 rows-fr grid-flow-row-dense'>
                    <Select label='Customer' required name='customer' options={warehouseCustomer} isLoading={isCustomerListLoading} shouldUnregister />
                    <Select
                        label='Warehouse'
                        name='warehouse'

                        options={warehouseDropdown}
                        isLoading={isWarehouseDropdownLoading}
                        required
                        shouldUnregister
                    />
                </div>
            )}
            {isAddNewWarehouse ? (
                <>
                    <div className='grid grid-cols-2 rows-fr grid-flow-row-dense'>
                        <Input maxLength={60} label='Warehouse Name' required name='warehouseName' shouldUnregister />
                        <Select
                            label='Owner'
                            name='owner'
                            options={warehouseOwner}
                            isLoading={isWarehouseOwnerLoading}
                            onChange={(event) => {
                                const selectedOwner = event.user;
                                if (selectedOwner) {
                                    setValue('name', `${selectedOwner.first_name} ${selectedOwner.last_name}`);
                                    setValue('mobile', `${selectedOwner.mobile}`);
                                    setValue('email', `${selectedOwner.email}`);
                                }
                            }}
                            shouldUnregister
                        />
                    </div>

                    <div className='grid grid-cols-2 rows-fr grid-flow-row-dense'>
                        <Input
                            label='Pin Code'
                            type={'text'}
                            name='pincode'
                            required
                            pattern={/^\d{6}$/}
                            validate={async (value) => {
                                if (value === '') return true;
                                const response = await apis.cities.getCityFromPincode({}, {}, {}, { pincode: value });
                                let pinCity = response.data.data;
                                if (pinCity) {
                                    let findCity = cities.find((city) => city.value === pinCity?.fk_city_id);
                                    if (findCity) setValue('city', findCity);
                                    if (pinCity?.fk_state_id) setValue('state', pinCity.fk_state_id);
                                }
                                return response.data?.code === 200 || "Pincode does't exist in the System.";
                            }}
                            shouldUnregister
                        />
                        <Select label='City' required name='city' options={cities} shouldUnregister />
                    </div>
                    <div className='w-full'>
                        {/* <TextArea label='Address' type={'text'} name='address' placeholder={'Address'} required shouldUnregister /> */}
                        <FetchGoogleAddress name={'address'} setLocation={({ pincode }) => setValue('pincode', pincode)} />
                    </div>

                    <div className='grid grid-cols-2 rows-fr grid-flow-row-dense'>
                        <Input label='Name' type={'text'} name='name' shouldUnregister />
                        <Input
                            label='Mobile'
                            type={'text'}
                            name='mobile'
                            validate={(mobile) => {
                                if (mobile === '') return true;
                                return mobile.length === 10 || 'Invalid Mobile';
                            }}
                            shouldUnregister
                        />
                    </div>
                    <div className='px-3'>
                        <Input
                            label='Email'
                            type={'text'}
                            name='email'
                            validate={(email) => {
                                if (email === '') return true;
                                return (
                                    email.match(
                                        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                    ) || 'Invalid Email'
                                );
                            }}
                            shouldUnregister
                        />
                    </div>
                </>
            ) : (
                <></>
            )}
        </div>
    );
};
