import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Buttons from '../../../../common/Buttons';
import { approveWalletPayment } from '../../../../actions/wallet';
import { buttonClass } from '../../../../common/Css';
import moment from 'moment';
import ButtonScogoPrimary from '../../../../common/Buttons/ButtonScogoPrimary';
import ButtonScogoClosedOutlined from '../../../../common/Buttons/ButtonScogoClosedOutlined';

export const ApprovePayment = ({ scheduled_on, scogo_transaction_no, closeMenu = () => {} }) => {
    const dispatch = useDispatch();
    const { approveWalletLoading } = useSelector((state) => state.loading);

    const onApproveBasePayment = (e) => {
        e.preventDefault();
        const due_date = moment(scheduled_on).format('YYYY-MM-DD');
        const payload = {
            action: 'Approve',
            scogo_transaction_no,
            due_date,
        };
        dispatch(approveWalletPayment({ data: payload }));
    };

    return (
        <div className='py-2 px-2 rounded-lg'>
            <p className='text-scogogray text-font16'>Are you sure you want to change the transaction date?</p>
            <div className='gap-2 flex items-center justify-center mt-8'>
                <ButtonScogoPrimary textOrComponent='Confirm' onClick={(e) => onApproveBasePayment(e)} loading={approveWalletLoading} />
                <ButtonScogoClosedOutlined textOrComponent={'Cancel'} onClick={closeMenu} />
            </div>
        </div>
    );
};
