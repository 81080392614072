import React from 'react';
import ReadMoreText from '../../../../common/ReadMoreText';
import { botMessageType } from '../../ChatController';
import { EditMessage } from './EditMessage';

let botemessage = <></>;

const findTimeStamp = (timestamp) => {
    if (timestamp.getHours() >= 6 && timestamp.getHours() < 12) {
        return 'Good Morning';
    } else if (timestamp.getHours() >= 12 && timestamp.getHours() < 17) {
        return 'Good Afternoon';
    } else {
        return 'Good Evening';
    }
};

function TextMessage({ message, formatTextWithCodeBlocks, isEditMessage, setIsEditMessage, chatUsers, createdBy, isChatPopup }) {
    if (isEditMessage) {
        return (
            <EditMessage
                value={message.message}
                setIsEditMessage={setIsEditMessage}
                isEditLoading={message.isEditLoading}
                conversationId={message.conversation._id}
                messageId={message._id}
                isEditMessageError={message.isEditMessageError}
                tags={message.tags}
                chatUsers={chatUsers}
                isChatPopup={isChatPopup}
            />
        );
    }

    const timestamp = new Date(message.timestamp);

    if (message.payload && message?.payload?.fulfillmentMessages?.[0]?.message === 'payload') {
        const { input, replies } = message?.payload?.fulfillmentMessages[0].payload;

        switch (input.type) {
            case botMessageType.Chips:
                botemessage = (
                    <ReadMoreText
                        text={formatTextWithCodeBlocks({ text: replies, tags: message.tags })}
                        numberOfLine={6}
                        className='text-scogo15 text-font13 pr-6 pl-2 font-normal'
                    />
                );
                break;
            case botMessageType.FAQ:
                botemessage = (
                    <>
                        <p className='text-font13 ml-2'>
                            <b>{replies}</b>
                        </p>

                        <ReadMoreText
                            text={formatTextWithCodeBlocks({ text: input?.options[0].text, tags: message.tags })}
                            numberOfLine={6}
                            className='text-scogo15 text-font13 pr-6 pl-2 font-normal'
                        />
                    </>
                );
                break;
            case botMessageType.Video:
                botemessage = <iframe title='scogo' src={`https://youtube.com/embed/${input?.options[0].videoId}`}></iframe>;
                break;
            case botMessageType.Greet:
                botemessage = (
                    <div className='w-full text-font13'>
                        <div className='flex gap-2 items-center '>
                            <img src={process.env.PUBLIC_URL + '/img/ic_hand.png'} alt='' className='w-10' />
                            <p>{findTimeStamp(timestamp)}</p>
                        </div>
                        <div className='ml-2'>{createdBy?.username}</div>
                    </div>
                );

                break;
            default:
                botemessage = (
                    <ReadMoreText
                        text={formatTextWithCodeBlocks({ text: message.message, tags: message.tags })}
                        numberOfLine={6}
                        className='text-scogo15 text-font13 pr-6 pl-2 font-normal'
                    />
                );
        }
    } else {
        botemessage = (
            <ReadMoreText
                text={formatTextWithCodeBlocks({ text: message.message, tags: message.tags })}
                numberOfLine={6}
                className='text-scogo15 text-font13 pr-6 pl-2 font-normal'
            />
        );
    }

    return <>{botemessage}</>;
}

export default TextMessage;
