import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import Form from '../../Inventory/reusableComponents/AddOption/Form';
import Input from '../../Inventory/reusableComponents/AddOption/Input';
import { generateInvoice } from '../../../actions/payments';
import { getCustomerProfileDetails, removeCustomerProfileDetailsFromStore } from '../../../actions/customer';
import moment from 'moment';
import { isValidGST, validatePAN } from '../../../utils/utils';
import { getBOQList } from '../../../actions/tickets';
import { getPOCategories } from '../../../actions/payments';
import { CLEANUP_FOR_UPDATE_PO } from '../../../types/payments';
import IconToolTip from '../../../common/IconToolTip';
import formTable from '../../../common/formTable';
import { getWalletTransactionsDetails } from '../../../actions/wallet';

const getVendors = (quotes) => {
    const vendors = quotes.reduce((acc, quote) => {
        // transform, because I don't like how the vendor id is stored in the key 'vendor'
        acc.push(
            ...quote.vendors.map((vendor) => ({
                vendorName: vendor.vendorName,
                vendorId: vendor.vendor,
            }))
        );
        return acc;
    }, []);
    const encountered = new Set();
    const vendorsWithoutDups = vendors.reduce((acc, vendor) => {
        if (!encountered.has(vendor.vendorId)) acc.push(vendor);
        encountered.add(vendor.vendorId);
        return acc;
    }, []);
    return vendorsWithoutDups;
};

const GenerateInvoice = (props) => {
    const { ticket, isRefresh } = props;
    const dispatch = useDispatch();
    const formName = 'generateInvoice';
    const arrayName = 'poitems';
    const vendorId = ticket?.po?.vendorId;
    const poDetails = ticket?.po;

    const { customerDetail } = useSelector((state) => state.customer);
    const { copyTxnDetailsLoading } = useSelector((state) => state.loading);
    const vendors = useMemo(() => getVendors(poDetails?.quotes), [poDetails]);

    const [activeCopyItemId, setActiveCopyItemId] = useState(undefined);

    useEffect(() => {
        if (!copyTxnDetailsLoading) {
            setActiveCopyItemId(undefined);
        }
        return () => {
            if (!copyTxnDetailsLoading && !['', undefined, null].includes(activeCopyItemId)) {
                setActiveCopyItemId(undefined);
            }
        };
    }, []);

    const getClipboardContent = ({ itemId, transactions }) => {
        const payload = {
            scogoTransactions: transactions,
            isPoItem: true,
            customerId: ticket.customer,
        };
        setActiveCopyItemId(itemId);
        dispatch(getWalletTransactionsDetails({ data: payload, copyToClipboard: true }));
    };

    const initialValues = useMemo(
        () => ({
            poItemIds: [],
            customer_address: '',
            circuitId: '',
            customer_gst: '',
            invoice_date: '',
            invoice_no: '',
            sac_code: '',
            vendor_gst: '',
            customerPoNumber: '',
            supportingDocuments: [],
            [arrayName]: poDetails?.quotes.map((quote) => {
                const correspondingPOItem = quote?.itemId && poDetails.poitems?.find((poItem) => poItem?._id === quote?.itemId);
                let endDate;
                if (quote.payment.endDate || quote.payment.displayEndDate) {
                    endDate = new Date(quote.payment.endDate || quote.payment.displayEndDate);
                }
                return {
                    id: quote?.itemId,
                    quantity: quote.quantity,
                    description: quote.description,
                    isRecurring: quote.payment.isRecurring,
                    endDate,
                    vendors: quote.vendors?.map((vendor) => ({
                        vendorName: vendor.vendorName,
                        price: vendor.price,
                        attachments: vendor.attachments,
                        vendorId: vendor.vendor,
                    })),
                    status: correspondingPOItem?.payment?.status,
                    channel: correspondingPOItem?.payment?.channel,
                    isDeletable: correspondingPOItem?.payment?.status !== 'PAID',
                    itemId: quote?.itemId,
                    isInvoiceGenerated: correspondingPOItem?.isInvoiceGenerated === true ? true : false,
                    totalPrice: correspondingPOItem?.totalPrice,
                    transactions: correspondingPOItem?.payment?.transactions,
                };
            }),
        }),
        [poDetails]
    );

    const [form, setForm] = useState(initialValues);

    useEffect(() => {
        if (!isNaN(vendorId) && vendorId > 0) {
            dispatch(getCustomerProfileDetails(vendorId));
        }

        return () => dispatch(removeCustomerProfileDetailsFromStore());
    }, [vendorId, dispatch]);

    const [poCategoryOptions, setPOCategoryOptions] = useState();
    const [assetTypeOptions, setAssetTypeOptions] = useState();

    const { boqList } = useSelector((state) => state.tickets);
    const { poCategories } = useSelector((state) => state.payments);

    useEffect(() => {
        dispatch(getBOQList(ticket.customer));
        dispatch(getPOCategories());

        return () => {
            dispatch({ type: CLEANUP_FOR_UPDATE_PO });
        };
    }, [ticket.customer, dispatch]);

    useEffect(() => {
        if (!boqList) return;
        setAssetTypeOptions(boqList);
        setForm((prevForm) => {
            return {
                ...prevForm,
                [arrayName]: prevForm[arrayName].map((quote, idx) => {
                    return {
                        ...quote,
                        assetType: boqList?.find((assetType) => assetType?.value === poDetails?.quotes?.[idx]?.assetType),
                    };
                }),
            };
        });
    }, [boqList, poDetails]);

    useEffect(() => {
        if (!poCategories) return;
        const options = poCategories.map((cat) => ({
            key: cat.id,
            value: cat.id,
            label: cat.name,
        }));
        setPOCategoryOptions(options);
        setForm((prevForm) => {
            return {
                ...prevForm,
                [arrayName]: prevForm[arrayName].map((quote, idx) => {
                    return {
                        ...quote,
                        category: options?.find((category) => category?.value === poDetails?.quotes?.[idx]?.category),
                    };
                }),
            };
        });
    }, [poCategories, poDetails]);

    const submit = (formValues) => {
        let files = [];
        if (formValues?.supportingDocuments) {
            files = [...formValues.supportingDocuments];
        }
        const poItemIds = new Set();
        formValues?.poitems.map((loi) => {
            if (loi.isSelected) {
                poItemIds.add(loi.itemId);
            }
        });
        if (poItemIds.size === 0) {
            alert('Please Select atleast one item');
            return;
        }
        const ticketId = ticket?.ticketId;
        const payload = {
            poItemIds: Array.from(poItemIds),
            circuitId: formValues?.circuitId,
            customer_address: formValues?.customer_address,
            customer_gst: formValues?.customer_gst,
            invoice_date: formValues?.invoice_date ? moment(formValues?.invoice_date).format('YYYY-MM-DD') : '',
            invoice_no: formValues?.invoice_no,
            vendor_gst: formValues?.vendor_gst,
            sac_code: formValues?.sac_code,
            customerPoNumber: formValues?.customerPoNumber,
            ticketId,
            supportingDocuments: formValues.supportingDocuments ? [...formValues.supportingDocuments].map((file) => ({ mimeType: file.type, name: file.name })) : undefined,
            invoice_description: formValues.invoice_description,
        };
        if (ticketId) {
            dispatch(generateInvoice({ data: payload, files, formName, isRefresh, enduserId: ticket.enduserId }));
        }
    };

    const closeModal = () => {
        dispatch(closeModalAction());
    };

    useEffect(() => {
        let customerPanOrGst = customerDetail?.customer_gst_number
            ? customerDetail.customer_gst_number
            : customerDetail?.customer_pan_card_number
                ? customerDetail.customer_pan_card_number
                : '';
        setForm((prevState) => ({ ...prevState, vendor_gst: customerPanOrGst }));
    }, [customerDetail]);

    const customValidationVendorGstOrPancard = (value) => {
        return isValidGST(value) || validatePAN(value);
    };

    const schema = useMemo(() => {
        return {
            table: [
                {
                    head: {
                        thClassName: `pl-5 flex-1`,
                        headName: 'Asset Type',
                        required: true,
                    },
                    body: {
                        bodyClassName: `flex-1 truncate`,
                        render: (quote, idx) => {
                            if (quote.isDeletable || quote.isInvoiceGenerated) {
                                return <div className='pl-5'>{quote?.assetType?.label}</div>;
                            } else {
                                return <Input type='checkbox' name={`${arrayName}[${idx}].isSelected`} label={quote?.assetType?.label} />;
                            }
                        },
                    },
                },
                {
                    head: {
                        thClassName: `mx-2 flex-1`,
                        headName: 'Category',
                        required: true,
                    },
                    body: {
                        bodyClassName: `flex-1 truncate`,
                        render: (quote, idx) => {
                            return <div>{quote?.category?.label}</div>;
                        },
                    },
                },
                {
                    head: {
                        thClassName: `flex-1`,
                        headName: 'Description',
                    },
                    body: {
                        bodyClassName: `flex-1 truncate`,
                        render: (quote, idx) => {
                            return <div>{quote?.description}</div>;
                        },
                    },
                },
                {
                    head: {
                        thClassName: `flex-1`,
                        headName: 'Recurring',
                    },
                    body: {
                        bodyClassName: `flex-1 truncate`,
                        render: (quote, idx) => {
                            return <div>{quote?.isRecurring ? 'Yes' : 'No'}</div>;
                        },
                    },
                },
                {
                    head: {
                        thClassName: `flex-1`,
                        headName: 'End Date',
                    },
                    body: {
                        bodyClassName: `flex-1 truncate`,
                        render: (quote, idx) => {
                            return <div>{quote?.endDate && moment(quote?.endDate).format('DD MMM YYYY')}</div>;
                        },
                    },
                },
                {
                    head: {
                        thClassName: `flex-1`,
                        headName: 'Quantity',
                        required: true,
                    },
                    body: {
                        bodyClassName: `flex-1 truncate`,
                        render: (quote, idx) => {
                            return <div>{quote?.quantity}</div>;
                        },
                    },
                },
                ...vendors.map((vendor) => {
                    return {
                        head: {
                            thClassName: `flex-1`,
                            headName: `${vendor.vendorName} Price`,
                            required: true,
                            isVendor: true,
                            vendor,
                        },
                        body: {
                            bodyClassName: `flex-1 truncate`,
                            render: (quote, idx) => {
                                const vendorIdx = quote.vendors.findIndex((quoteVendor) => quoteVendor.vendorId === vendor.vendorId);
                                let quoteVendor = quote.vendors[vendorIdx];
                                if (vendorIdx === -1) {
                                    quoteVendor = { vendorId: vendor.vendorId };
                                }
                                return <div>{quoteVendor.price}</div>;
                            },
                        },
                    };
                }),

                {
                    head: {
                        thClassName: `flex-1`,
                        headName: 'Total Price',
                    },
                    body: {
                        bodyClassName: `flex-1 truncate`,
                        render: (quote, idx) => {
                            return <div>{quote.totalPrice}</div>;
                        },
                    },
                },

                {
                    head: {
                        thClassName: `flex-1`,
                        headName: 'Status',
                    },
                    body: {
                        bodyClassName: `flex-1 truncate`,
                        render: (quote, idx) => (
                            <GetPaymentStatus
                                quote={quote}
                                copyTxnDetailsLoading={copyTxnDetailsLoading}
                                activeCopyItemId={activeCopyItemId}
                                getClipboardContent={getClipboardContent}
                            />
                        ),
                    },
                },
            ],
        };
    }, [vendors, assetTypeOptions, poCategoryOptions, activeCopyItemId, copyTxnDetailsLoading, boqList]);

    return (
        <>
            <Form
                className='w-full'
                submit={(e) => {
                    submit(e);
                }}
                onCancel={closeModal}
                buttonPostion={'justify-end'}
                initialValues={initialValues}
                form={form}
                setForm={setForm}
                formName={formName}
                submitButtonName='Submit'
            >
                <div style={{ padding: '1.25rem' }}>
                    {formTable({
                        schema,
                        form,
                        setForm,
                        arrayName,
                    })}
                </div>
                <div className='px-3 grid grid-cols-3 rows-fr grid-flow-row-dense'>
                    <Input label='Invoice No' required name='invoice_no' type={'text'} />
                    <Input label='Invoice Date' required name='invoice_date' type={'singledatepicker'} dateFormat='dd-MM-yyyy' inputValueFormat={'DD-MM-yyyy'} />
                    <Input label='Supporting Documents' name='supportingDocuments' multiple={true} type='upload' />
                    <Input label='Customer PO' name='customerPoNumber' type={'text'} />
                    <Input label='Circuit Id' name='circuitId' type={'text'} />
                    <Input label='GST' name='customer_gst' type={'text'} customValidation={isValidGST} errorMessage={'Invalid GST'} />
                    <Input label='Address' name='customer_address' type={'text'} />
                    <Input label='SAC Code' name='sac_code' type={'text'} />
                    <Input label='Vendor GST/Pancard' name='vendor_gst' type={'text'} customValidation={customValidationVendorGstOrPancard} errorMessage={'Invalid GST/Pancard'} />
                    <Input label='Description' name='invoice_description' type={'text'} className='col-span-3' />
                </div>
            </Form>
        </>
    );
};

export default GenerateInvoice;

const GetPaymentStatus = ({ quote, copyTxnDetailsLoading = false, activeCopyItemId = '', getClipboardContent = () => { } }) => {
    const lookup = {
        UNPAID: { label: 'UNPAID', color: 'scogoclosed' },
        PAID: { label: 'PAID', color: 'scogogoing' },
        INITIATED: { label: 'INITIATED', color: 'scogoorange' },
        AWAITING: { label: 'AWAITING', color: 'scogoorange' },
        PROCESSING: { label: 'PROCESSING', color: 'scogoorange' },
    };
    let status = lookup?.[quote?.status];
    if (status) {
        return (
            <>
                <div className='flex flex-row'>
                    <p className={`bg-${status.color} scogoprimary max-w-max text-white text-font10 px-p-6 py-p-3 flex items-center rounded-3px mr-2 mb-1`}>
                        {status.label}
                        {status.label === 'PAID' && quote?.transactions?.length > 0 ? (
                            <>
                                &nbsp;&nbsp;
                                <span
                                    onClick={(e) => {
                                        e.preventDefault();
                                        getClipboardContent({ transactions: quote?.transactions, itemId: quote?.id });
                                    }}
                                    className={`material-icons text-base text-scogogray cursor-pointer hover:text-scogogray`}
                                >
                                    content_copy
                                </span>
                                {copyTxnDetailsLoading && activeCopyItemId === quote?.id ? (
                                    <div className='animate-spin w-4 h-4 border-t-2 border-scogoprimary rounded-full' />
                                ) : (
                                    <></>
                                )}
                            </>
                        ) : (
                            <></>
                        )}
                    </p>
                    {status.label === 'PAID' && !['', undefined, null].includes(quote?.channel) ? (
                        <>
                            <p className={`bg-scogogoing scogoprimary max-w-max text-white text-font10 px-p-6 py-p-3 flex items-center rounded-3px mr-2 mb-1`}>
                                {quote?.channel === 'BANK_TRANSFER' ? 'BANK TRANSFER' : quote?.channel}
                            </p>
                        </>
                    ) : (
                        <></>
                    )}
                    {quote.isInvoiceGenerated ? (
                        <>
                            <IconToolTip title='Invoice Generated'>
                                <span className={`material-icons text-scogo2e cursor-pointer hover:text-scogo2e`}>receipt_long</span>
                            </IconToolTip>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </>
        );
    }
};
