export const PARTNERS_LIST_REQUEST = 'PARTNERS_LIST_REQUEST';
export const PARTNERS_LIST_SUCCESS = 'PARTNERS_LIST_SUCCESS';
export const PARTNERS_LIST_FAILURE = 'PARTNERS_LIST_FAILURE';

export const PARTNERS_LIST_DROPDOWN_REQUEST = 'PARTNERS_LIST_DROPDOWN_REQUEST';
export const PARTNERS_LIST_DROPDOWN_SUCCESS = 'PARTNERS_LIST_DROPDOWN_SUCCESS';
export const PARTNERS_LIST_DROPDOWN_FAILURE = 'PARTNERS_LIST_DROPDOWN_FAILURE';

export const CLEAR_PARTNERS_DROPDOWN = 'CLEAR_PARTNERS_DROPDOWN';

export const DELETE_PARTNER_REQUEST = 'DELETE_PARTNER_REQUEST';
export const DELETE_PARTNER_SUCCESS = 'DELETE_PARTNER_SUCCESS';
export const DELETE_PARTNER_FAILURE = 'DELETE_PARTNER_FAILURE';

export const VERIFY_PARTNER_DETAILS_REQUEST = 'VERIFY_PARTNER_DETAILS_REQUEST';
export const VERIFY_PARTNER_DETAILS_FAILURE = 'VERIFY_PARTNER_DETAILS_FAILURE';

export const SEND_ANDROID_APP_LINK_REQUEST = 'SEND_ANDROID_APP_LINK_REQUEST';
export const SEND_ANDROID_APP_LINK_SUCCESS = 'SEND_ANDROID_APP_LINK_SUCCESS';
export const SEND_ANDROID_APP_LINK_FAILURE = 'SEND_ANDROID_APP_LINK_FAILURE';

export const PARTNERS_SERVICE_LOCATIONS_UPDATE = 'PARTNERS_SERVICE_LOCATIONS_UPDATE';

export const UPDATE_TOOLS_PARTNERS_IN_STORE = 'UPDATE_TOOLS_PARTNERS_IN_STORE';

export const IWAN_DROPDOWN_REQUEST = 'IWAN_DROPDOWN_REQUEST';
export const IWAN_DROPDOWN_SUCCESS = 'IWAN_DROPDOWN_SUCCESS';
export const IWAN_DROPDOWN_FAILURE = 'IWAN_DROPDOWN_FAILURE';

export const DOWNLOAD_ISP_FEASIBILITY_REPORT_REQUEST = 'DOWNLOAD_ISP_FEASIBILITY_REPORT_REQUEST';
export const DOWNLOAD_ISP_FEASIBILITY_REPORT_SUCCESS = 'DOWNLOAD_ISP_FEASIBILITY_REPORT_SUCCESS';
export const DOWNLOAD_ISP_FEASIBILITY_REPORT_FAILURE = 'DOWNLOAD_ISP_FEASIBILITY_REPORT_FAILURE';

export const GROUP_USERS_LIST_REQUEST = 'GROUP_USERS_LIST_REQUEST';
export const GROUP_USERS_LIST_SUCCESS = 'GROUP_USERS_LIST_SUCCESS';
export const GROUP_USERS_LIST_FAILURE = 'GROUP_USERS_LIST_FAILURE';

export const CLEAR_PARTNERS_LIST_DROPDOWN = 'CLEAR_PARTNERS_LIST_DROPDOWN';
export const PUT_TO_PARTNERS_STORE = 'PUT_TO_PARTNERS_STORE';
export const CLEAR_PARTNER_LIST_FROM_STORE = 'CLEAR_PARTNER_LIST_FROM_STORE';

export const REMOVE_OR_GIVE_HELP_ACCESS_REQUEST = 'REMOVE_OR_GIVE_HELP_ACCESS_REQUEST';
export const REMOVE_OR_GIVE_HELP_ACCESS_SUCCESS = 'REMOVE_OR_GIVE_HELP_ACCESS_SUCCESS';
export const REMOVE_OR_GIVE_HELP_ACCESS_FAILURE = 'REMOVE_OR_GIVE_HELP_ACCESS_FAILURE';

export const GET_PARTNER_LIST_REQUEST = 'GET_PARTNER_LIST_REQUEST';
export const GET_PARTNER_LIST_SUCCESS = 'GET_PARTNER_LIST_SUCCESS';
export const GET_PARTNER_LIST_FAILURE = 'GET_PARTNER_LIST_FAILURE';

export const GET_FILTERED_PARTNER_LIST_REQUEST = 'GET_FILTERED_PARTNER_LIST_REQUEST';
export const GET_FILTERED_PARTNER_LIST_SUCCESS = 'GET_FILTERED_PARTNER_LIST_SUCCESS';
export const GET_FILTERED_PARTNER_LIST_FAILURE = 'GET_FILTERED_PARTNER_LIST_FAILURE';

export const GET_PARTNER_DETAILS_LIST_REQUEST = 'GET_PARTNER_DETAILS_LIST_REQUEST';
export const GET_PARTNER_DETAILS_LIST_SUCCESS = 'GET_PARTNER_DETAILS_LIST_SUCCESS';
export const GET_PARTNER_DETAILS_LIST_FAILURE = 'GET_PARTNER_DETAILS_LIST_FAILURE';

export const GET_PARTNER_COUNT_REQUEST = 'GET_PARTNER_COUNT_REQUEST';
export const GET_PARTNER_COUNT_SUCCESS = 'GET_PARTNER_COUNT_SUCCESS';
export const GET_PARTNER_COUNT_FAILURE = 'GET_PARTNER_COUNT_FAILURE';

export const REMOVE_PARTNER_LIST_FROM_STORE = 'REMOVE_PARTNER_LIST_FROM_STORE';

export const GET_PARTNER_FILTER_REQUEST = 'GET_PARTNER_FILTER_REQUEST';
export const GET_PARTNER_FILTER_SUCCESS = 'GET_PARTNER_FILTER_SUCCESS';
export const GET_PARTNER_FILTER_FAILURE = 'GET_PARTNER_FILTER_FAILURE';


export const REFRESH_PARTNER_FILTER_REQUEST = 'REFRESH_PARTNER_FILTER_REQUEST';
export const REFRESH_PARTNER_FILTER_FAILURE = 'REFRESH_PARTNER_FILTER_FAILURE';

export const SET_TEAM_FILTER_DETAILS_IN_STORE = 'SET_TEAM_FILTER_DETAILS_IN_STORE';

export const GET_SCM_CLUSTER_AND_PSP_LIST_REQUEST = 'GET_SCM_CLUSTER_AND_PSP_LIST_REQUEST';
export const GET_SCM_CLUSTER_AND_PSP_LIST_SUCCESS = 'GET_SCM_CLUSTER_AND_PSP_LIST_SUCCESS';
export const GET_SCM_CLUSTER_AND_PSP_LIST_FAILURE = 'GET_SCM_CLUSTER_AND_PSP_LIST_FAILURE';

export const GET_SP_FE_LIST_REQUEST = 'GET_SP_FE_LIST_REQUEST';
export const GET_SP_FE_LIST_SUCCESS = 'GET_SP_FE_LIST_SUCCESS';
export const GET_SP_FE_LIST_FAILURE = 'GET_SP_FE_LIST_FAILURE';

export const GET_DEVELOPERS_LIST_REQUEST = 'GET_DEVELOPERS_LIST_REQUEST'
export const GET_DEVELOPERS_LIST_SUCCESS = 'GET_DEVELOPERS_LIST_SUCCESS'
export const GET_DEVELOPERS_LIST_FAILURE = 'GET_DEVELOPERS_LIST_FAILURE'

export const GET_GROUP_USER_LIST_REQUEST = 'GET_GROUP_USER_LIST_REQUEST'
export const GET_GROUP_USER_LIST_SUCCESS = 'GET_GROUP_USER_LIST_SUCCESS'
export const GET_GROUP_USER_LIST_FAILURE = 'GET_GROUP_USER_LIST_FAILURE'

export const GET_NEXT_GROUP_USER_LIST_REQUEST = 'GET_NEXT_GROUP_USER_LIST_REQUEST'
export const GET_NEXT_GROUP_USER_LIST_SUCCESS = 'GET_NEXT_GROUP_USER_LIST_SUCCESS'
export const GET_NEXT_GROUP_USER_LIST_FAILURE = 'GET_NEXT_GROUP_USER_LIST_FAILURE'

export const GET_FILTERED_GROUP_USER_LIST_REQUEST = 'GET_FILTERED_GROUP_USER_LIST_REQUEST'
export const GET_FILTERED_GROUP_USER_LIST_SUCCESS = 'GET_FILTERED_GROUP_USER_LIST_SUCCESS'
export const GET_FILTERED_GROUP_USER_LIST_FAILURE = 'GET_FILTERED_GROUP_USER_LIST_FAILURE'

export const CLEAR_GROUP_USER_FROM_STORE = 'CLEAR_GROUP_USER_FROM_STORE'

export const UPLOAD_ADDRESS_PINCODE_FILE_REQUEST = 'UPLOAD_ADDRESS_PINCODE_FILE_REQUEST';
export const GET_AREA_COUNT_REQUEST = 'GET_AREA_COUNT_REQUEST';
export const GET_AREA_COUNT_SUCCESS = 'GET_AREA_COUNT_SUCCESS';
export const GET_AREA_COUNT_FAILURE = 'GET_AREA_COUNT_FAILURE';

export const GENERATE_AFFILIATE_LINK = 'GENERATE_AFFILIATE_LINK';
export const GENERATE_AFFILIATE_LINK_SUCCESS = 'GENERATE_AFFILIATE_LINK_SUCCESS';
