export default function NoResultFound({ message, paddingy = 'py-4', paddingx = 'px-6' }) {
    return (
        <div className={`bg-white ${paddingy} ${paddingx} items-center flex  border-2 border-scogoclosed rounded-md gap-5`}>
            <div className={`h-12 w-12 rounded-full  flex justify-center items-center`}>
                <span className={`material-icons text-font30 text-scogoToastDangerIcon `}>cancel</span>
            </div>
            <div>
                <p className={`text-xl text-scogoToastDanger`}>{message}</p>
            </div>
        </div>
    );
}
