import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


import Layout from './Layout';

import { loginWithPasswordAction, requestOtpAction } from '../../actions/auth';

import Form from '../../common/Form';
import Input from '../../common/Form/Input';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { ForgotPasswordLabel, FormLabel } from './Login';
import { NewScogoButton, isMobileView } from './components/NewScogoButton';

function Password() {
    const dispatch = useDispatch();
    const { checkUserDetails } = useSelector((state) => state.auth);
    const email = checkUserDetails?.data?.email;
    const userHasMobile = checkUserDetails?.data?.mobile;

    const navigate = useNavigate();

    const onSubmitClick = (formValues) => {
        dispatch(loginWithPasswordAction({ email, password: formValues.password, navigate }));
    };

    const onRequestClick = (formValues) => {
        const mobile = checkUserDetails?.data?.mobile;
        dispatch(requestOtpAction({ mobile, navigate }));
    };


    return (
        <Layout>
            <div className=' flex flex-col gap-3' style={{ width: '30rem' }}>
                <FormLabel text={'Enter Password'} />
                <PasswordForm onSubmitClick={onSubmitClick} onRequestClick={onRequestClick} userHasMobile={userHasMobile} />
            </div>
        </Layout>
    );
}

export default Password;



const PasswordForm = ({ onSubmitClick, onRequestClick, userHasMobile }) => {
    const defaultValues = {};
    const methods = useForm({ defaultValues, mode: 'all' });
    const navigate = useNavigate();
    const submitButtonRef = useRef();

    const { checkUserLoading, isPasswordButtonLoading, isRequestOtpButtonLoading } = useSelector((state) => state.loading);

    const onBackToLogin = () => {
        navigate('/login');
    }

    return <div className='flex flex-col gap-4'>
        <Form
            methods={methods}
            submitRef={submitButtonRef}
            onSubmit={onSubmitClick}
            className='flex flex-col gap-3'
            onEnterPressSubmit
        >
            <div className=' '>
                <Input
                    type={'password'}
                    name='password'
                    inputClassName={`font-normal block w-full focus:ring-0  text-font14 rounded-md
                    placeholder:text-inputfieldtext placeholder:text-lg
                    `}
                    placeholder='Enter your password'
                    required
                    label='Password'
                    inputStyle={{ 'height': isMobileView ? '4.5rem' : '3.5rem' }}

                    randerForgotLabel={<ForgotPasswordLabel />}
                />

            </div>
        </Form>
        <div className='flex flex-col gap-8 lg:gap-4 px-2 py-3'>

            <div className='flex gap-4'>
                <NewScogoButton onClick={(e) => {
                    e.preventDefault();
                    submitButtonRef.current.click();
                }} isLoading={(checkUserLoading || isPasswordButtonLoading)} buttonName='Login' color='primary' />
                {userHasMobile && <NewScogoButton onClick={onRequestClick} isLoading={isRequestOtpButtonLoading} buttonName='Request OTP' color='orange' />}
            </div>

            <NewScogoButton onClick={onBackToLogin} isLoading={false} buttonName='< Back To Login' color='gray' />
        </div>
    </div>
}