import React, { forwardRef, useEffect, useRef } from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import { defaultGroupChatImage, defaultSingleChatPic } from '../../ChatController';


const hashOptions = [
    { display: 'project-status', id: '##project-status', commandDesc: 'Tab Wise Ticket Count Of Tagged Projects' },
    { display: 'today-status', id: '##today-status', commandDesc: 'Today\'s planned tickets belonging to the Tagged Projects' },
    { display: 'site-status', id: '##site-status', commandDesc: 'Current Ticket Status Of Entered Site', renderQuery: <span className={`max-w-9rem px-2 rounded-sm truncate items-center text-white bg-scogobgsky text-font10`}>Site ID</span> },
    { display: 'signoff-status', id: '##signoff-status', commandDesc: 'Signoff statistics of the Tagged Projects' },
]

export const MentionsInputBar = forwardRef((props, ref) => {
    const { onChange, value, onKeyDown, taggableUsers, isInputDisabled, showDoubleHash = false } = props;

    var userListBottomValue = ref?.current?.clientHeight + 'px';

    const mentionStyle = {
        control: {
            fontSize: '1.5rem',
            wordBreak: 'break-word',
            lineHeight: '2.5rem',
        },
        input: {
            overflowX: 'hidden',
            overflowY: 'auto',
            outline: 0,
            padding: '3px',
            lineHeight: '1.8rem',
            borderRight: 'none',
            borderBottom: 'none',
            border: 'none',


        },
        highlighter: {
            maxHeight: '10rem',
            overflowX: 'hidden',
            overflowY: 'auto',
            padding: '2px',
        },
        suggestions: {
            top: '',
            bottom: userListBottomValue,
            left: '',
            right: '',
            maxHeight: '45vh',
            overflowX: 'scroll',
            overflowY: 'auto',
        },

    };


    return (
        <div className='w-full min-h-inherit  '>

            <MentionsInput
                disabled={isInputDisabled}
                inputRef={ref}
                className='mentions-input__input min-h-inherit focus:ring-0 rounded-lg bg-white  !border-scogoddd !border-b-0 !border-t-0 !border-r-0 !border-l-0 '
                placeholder=' Write here...'
                style={mentionStyle}
                value={value}
                onChange={(e, newRichValue, newPlainTextValue, mentions) => {
                    onChange(e, newRichValue, newPlainTextValue, mentions);
                }}
                onKeyDown={onKeyDown}
                allowSpaceInQuery
                forceSuggestionsAboveCursor
            >
                <Mention
                    trigger='@'
                    data={taggableUsers}
                    renderSuggestion={User}
                    displayTransform={(id, display) => {
                        if (id.startsWith('##')) {
                            return `##${display}`;
                        }
                        return `@${display}`
                    }}
                    style={{
                        opacity: '0.4',
                        borderRadius: '4px',
                        padding: '3px',
                        paddingBottom: '0px',
                    }}
                    appendSpaceOnAdd

                />
                {showDoubleHash && <Mention
                    trigger='##'
                    data={hashOptions}
                    renderSuggestion={HashTag}
                    displayTransform={(id, display) => {
                        return `##${display}`;
                    }}
                    style={{
                        opacity: '0.4',
                        borderRadius: '4px',
                        padding: '3px',
                        paddingBottom: '0px',
                    }}
                    appendSpaceOnAdd
                />}
            </MentionsInput>
        </div>
    );
});

const User = (user, search, highlightedDisplay, index, focus) => {
    let profilePic = defaultSingleChatPic;
    if (user.id === '@all') profilePic = defaultGroupChatImage;
    else if (user.profilePic) profilePic = user.profilePic;
    return (
        <div className={`flex cursor-pointer items-center py-2 px-3 border-b-2 border-white hover:bg-gray-200 ${focus ? 'bg-gray-200' : ' '}`}>
            <img alt='' className='w-11 h-11 rounded-full object-cover' src={profilePic} />
            <div className='ml-m-9'>
                <p className='text-scogo15 text-font11 font-normal max-w-xss'>
                    <div className='whitespace-nowrap truncate'>{user?.display}</div>
                </p>
                <p className='text-scogogray text-font10 font-normal'>{user?.role}</p>
            </div>
        </div>
    );
};

const HashTag = (item, search, highlightedDisplay, index, focus) => {
    return (
        <div className={`flex cursor-pointer items-center py-4 px-3 border-b-2 border-white hover:bg-gray-200 ${focus ? 'bg-gray-200' : ' '}`}>
            <p className='text-scogo15 text-font12 font-normal flex-col gap-4'>
                <div className='whitespace-nowrap truncate displayHash flex items-center gap-2 text-scogobgsky'>
                    {item.id}
                    {item.renderQuery && item.renderQuery}
                </div>
                {item.commandDesc && <span className='text-sm text-scogogray'>{item.commandDesc}</span>}
            </p>
        </div>
    );
}