import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import { cancelPoRequest } from '../../../actions/payments';
import Form from '../../../common/Form';
import { useForm } from 'react-hook-form';
import TextArea from '../../../common/Form/TextArea';

const CancelPO = (props) => {
    const { ticket, isRefresh } = props;
    const { formButtonLoading } = useSelector((state) => state.loading);

    const formName = 'cancelPO';
    const dispatch = useDispatch();
    const defaultValues = {
        remark: '',
    };

    const onSubmit = (formValues) => {
        const payload = {
            data: { cancelMsg: formValues.remark },
            ticketId: ticket?.ticketId,
            formName,
            isRefresh,
            enduserId: ticket.enduserId
        };
        dispatch(cancelPoRequest(payload));
    };

    const closeModal = () => {
        dispatch(closeModalAction());
    };

    const methods = useForm({ defaultValues, mode: 'all' });

    return (
        <>
            <Form
                className='w-full'
                onSubmit={onSubmit}
                onCancel={closeModal}
                showSubmit
                submitContainerClassName='flex gap-2 justify-end py-4 px-4'
                isLoading={formButtonLoading[formName]}
                submitButtonText='Submit'
                methods={methods}
            >
                <div className='px-3'>
                    <p className='px-2 pt-4 text-lg'>Are you Sure you want to Continue?</p>
                    <TextArea label='Remark' required name='remark' />
                </div>
            </Form>
        </>
    );
};

export default CancelPO;
