import { pmscm, partners, modal, toast, loading } from '../types';
import { takeLatest, put, call, all } from 'redux-saga/effects';
import api from '../apis';
import lambdas from '../lambdas/apis'


export function* getTools(action) {
    const { isDropDown = false } = action.payload
    try {
        yield put({ type: loading.GET_TOOLS_LOADING, payload: true })
        const response = yield call(api.scm.getTools, {})
        if (response.data.code === 200) {
            let tools = response.data.data;
            if (isDropDown) {
                tools = []
                response.data.data.map(item => {
                    return tools.push({
                        key: `tools_${item.id}`,
                        value: item.tool_name,
                        label: item.tool_name

                    })
                })
            }
            yield put({ type: pmscm.GET_TOOLS_SUCCESS, payload: tools })
        } else {
            yield put({ type: pmscm.GET_TOOLS_FAILURE, payload: { message: 'Oops Something went wrong' } })
        }
    } catch (error) {
        yield put({ type: pmscm.GET_TOOLS_FAILURE, payload: { message: error } })
    }
    yield put({ type: loading.GET_TOOLS_LOADING, payload: false })
}

export function* updateSiteType(action) {

    const { user_id, site_type, role, formName } = action.payload
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } })
        const response = yield call(lambdas.scm.updateSiteType, {}, {}, {}, { user_id, site_type })
        if (response.data.code === 201) {
            yield all([
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: response.data.data.message } }),
                yield put({ type: partners.PARTNERS_LIST_REQUEST, payload: { role } })
            ])
        } else {
            yield put({ type: pmscm.UPDATE_SITE_TYPE_FAILURE, payload: 'Something went Wrong' })
        }
    } catch (error) {
        yield put({ type: pmscm.UPDATE_SITE_TYPE_FAILURE, payload: error })
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } })
}

export function* addTools(action) {
    const { spId, tools, role, userType = 'SP', selectedType, formName, address_proof_verified } = action.payload;
    const payload = { tools, type: selectedType };
    if (address_proof_verified) payload.address_proof_verified = address_proof_verified

    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } })
        const response = yield call(api.scm.addTools, {}, { spId }, {}, payload)
        if (response.data.code === 201) {
            let message = userType === 'ISP' ? 'User Verified Successfully!!!' : response.data.data
            yield all([
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: message } }),
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: partners.PARTNERS_LIST_REQUEST, payload: { role } })
            ])
        } else {
            yield put({ type: pmscm.ADD_TOOLS_FAILURE, payload: 'Something went Wrong' })
        }
    } catch (error) {
        yield put({ type: pmscm.ADD_TOOLS_FAILURE, payload: error })
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } })
}

export function* addToolsSp(action) {
    const { spId, tools, role, userType, selectedType, formName, customer, fk_cluster_id, toolNames } = action.payload
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } })
        const response = yield call(api.scm.addTools, {}, { spId }, {}, { tools, type: selectedType })
        if (response.data.code === 201) {
            let message = userType === 'ISP' ? 'User Verified Successfully!!!' : response.data.data
            yield all([
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: message } }),
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: partners.UPDATE_TOOLS_PARTNERS_IN_STORE, payload: { spId, toolNames } })


            ])
        } else {
            yield put({ type: pmscm.ADD_TOOLS_SP_FAILURE, payload: 'Something went Wrong' });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.message } })
        }
    } catch (error) {
        yield put({ type: pmscm.ADD_TOOLS_SP_FAILURE, payload: error })
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } })
}

export default function* pmscmSaga() {
    yield takeLatest(pmscm.GET_TOOLS_REQUEST, getTools)
    yield takeLatest(pmscm.UPDATE_SITE_TYPE_REQUEST, updateSiteType)
    yield takeLatest(pmscm.ADD_TOOLS_REQUEST, addTools)
    yield takeLatest(pmscm.ADD_TOOLS_SP_REQUEST, addToolsSp)
}