import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import ButtonScogoClosedOutlined from '../../../common/Buttons/ButtonScogoClosedOutlined';
import ButtonScogoPrimary from '../../../common/Buttons/ButtonScogoPrimary';
import { actionType, formName } from './PoApprovalForm';

export const FormButtons = ({ submitRef, approveOrRejectPO, hasAccess = false }) => {
    const { formButtonLoading } = useSelector((state) => state.loading);
    const { getValues } = useFormContext();
    return (
        <>
            <ButtonScogoPrimary
                onClick={(event) => {
                    event.preventDefault();
                    submitRef?.current?.click();
                }}
                textOrComponent={'Approve'}
                loading={formButtonLoading[formName.approveFormName]}
                disabled={!hasAccess}
            />
            <ButtonScogoClosedOutlined
                onClick={(event) => {
                    event.preventDefault();
                    const formValues = getValues();
                    approveOrRejectPO(formValues, actionType.reject);
                }}
                textOrComponent={'Reject'}
                loading={formButtonLoading[formName.rejectFormName]}
                disabled={!hasAccess}
            />
        </>
    );
};
