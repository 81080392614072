import { admin } from '../types';

const INITIAL_STATE = {
    error: undefined,
};

const adminReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case admin.GET_SIGN_OFF_TEMPLATES_SUCCESS:
            return {
                ...state,
                signOffTemplates: action.payload,
            };

        case admin.LIST_ALL_STATE_SUCCESS:
            return {
                ...state,
                allState: action.payload,
            };
        case admin.LIST_ALL_CITY_SUCCESS:
            return {
                ...state,
                allCity: action.payload,
            };

        case admin.GET_ALL_STATE_CITIES_SUCCESS:
            return {
                ...state,
                stateAllCities: action.payload,
            };
        case admin.CLEAR_STATE_CITIES_FROM_STORE:
            return {
                ...state,
                stateAllCities: undefined,
            };
        case admin.LIST_ISSUE_CATEGORIES_SUCCESS:
            return {
                ...state,
                issueCategories: action.payload,
            };
        case admin.CLEAR_ISSUE_CATEGORIES:
            return {
                ...state,
                issueCategories: null,
            };
        case admin.LIST_ALL_DISTRICT_SUCCESS:
            return {
                ...state,
                districtsList: action.payload,
            };
        case admin.LIST_ALL_LOCALITY_SUCCESS:
            return {
                ...state,
                localityList: action.payload,
            };
        case admin.CLEAR_DISTRCT_LIST_FROM_STORE:
            return {
                ...state,
                districtsList: undefined,
            };
        case admin.CLEAR_LOCALITY_LIST_FROM_STORE:
            return {
                ...state,
                localityList: undefined,
            };
        case admin.LIST_ALL_LOCALITY_FAILURE:
        case admin.LIST_ALL_DISTRICT_FAILURE:
        case admin.GET_SIGN_OFF_TEMPLATES_FAILURE:
        case admin.LIST_ALL_STATE_FAILURE:
        case admin.LIST_ALL_CITY_FAILURE:
        case admin.LIST_ISSUE_CATEGORIES_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default adminReducer;
