import React from 'react';
import { formatText } from '../../messageBox/Messages/Message';
import IconToolTip from '../../../../common/IconToolTip';
import { botMessageType } from '../../ChatController';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { GetQueryParam } from '../../../../utils/common';
const findTimeStamp = (times) => {
    const timestamp = moment(times).format('H');

    if (timestamp >= 6 && timestamp < 12) {
        return 'Good Morning';
    } else if (timestamp >= 12 && timestamp < 17) {
        return 'Good Afternoon';
    } else {
        return 'Good Evening';
    }
};

const getLastTagedUser = ({ tags, readReceiptsIds, loggedUserId }) => {
    if (readReceiptsIds?.includes(loggedUserId)) return <></>;
    let user = '';
    if (Array.isArray(tags) && tags.length > 0) {
        for (const tag of tags) {
            if (tag?.user?.userId === loggedUserId) {
                user = tag.user.username;
                break;
            }
            if (tag?.user?.username === 'All') {
                user = tag.user.username;
            }
        }
    }
    if (user) {
        return (
            <IconToolTip title={`Last Tagged: @${user}`}>
                <div className='text-scogoorange font-semibold truncate w-52'>@{user}</div>
            </IconToolTip>
        );
    }
    return <></>;
};

export const LastMessage = ({ lastMessage, loggedUserId, createdBy, lastMessageTimeStamp }) => {

    if (!lastMessage) return '';

    const tags = lastMessage.tags;
    const lastMsgUsername = lastMessage.type !== 'event' && lastMessage.from?.username;


    let lastTaggedUser = '', attachments = <></>, lastMessageTextWithHighlightedTag = <div className='italic text-scogoorange text-font09 inline'>Deleted Message</div>, lastMessageText = '';
    if (lastMessage.deletedFor !== 'EVERYONE') {
        const readReceiptsIds = lastMessage.readReceipts?.map((receipt) => receipt.user && receipt.user.userId);
        lastTaggedUser = getLastTagedUser({ tags, readReceiptsIds, loggedUserId });
        if (lastMessage.type === 'attachments') {
            const attachmentIcon = <span className='material-icons text-scogoprimary font-medium align-middle  text-font16'>attachment</span>;
            attachments = <>{attachmentIcon}Attachment</>;
        }

        if (lastMessage.payload && lastMessage?.payload?.fulfillmentMessages?.[0]?.message === 'payload') {
            let { input, replies } = lastMessage?.payload?.fulfillmentMessages[0].payload;
            switch (input.type) {
                case botMessageType.Chips:
                    lastMessageText = replies;
                    break;
                case botMessageType.FAQ:
                    lastMessageText = replies + input?.options?.[0]?.text;
                    break;
                case botMessageType.Video:
                    let attachmentIcon = <span className='material-icons text-scogoprimary font-medium align-middle  text-font16'>attachment</span>;
                    attachments = <>{attachmentIcon}Attachment</>;
                    break;
                case botMessageType.Greet:
                    lastMessageText = findTimeStamp(lastMessageTimeStamp) + ' ' + (createdBy || '');
                    break;
                default:
                    lastMessageText = lastMessage.message;
            }
        } else {
            lastMessageText = lastMessage.message;
        }
        lastMessageTextWithHighlightedTag = formatText({ text: lastMessageText, tags: lastMessage.tags });
    }
    return (

        <div className='w-full max-w-md'>
            <IconToolTip title={`${lastMsgUsername} : ${lastMessage.type === 'attachments' ? 'attachments' : lastMessageText}`}>
                <div className='flex justify-start w-full max-w-md'>
                    <div className='cursor-pointer text-scogogray text-font09 max-w-md truncate'>
                        {lastMsgUsername && <span className='text-scogoprimary font-medium'>{lastMsgUsername}:</span>} {lastMessageTextWithHighlightedTag}
                        {attachments}
                    </div>
                </div>
            </IconToolTip>
            {lastTaggedUser}
        </div>
    );
};


export const TypingUsers = ({ users, additionalClasses = '', maxUsernamesToShow = 3 }) => {
    const chatGptQueryParams = GetQueryParam('chatGpt', Boolean);
    if (!Array.isArray(users) || users.length === 0) {
        return <></>;
    }
    if (chatGptQueryParams) {
        return <img className='h-[2rem] w-[10rem]' src={process.env.PUBLIC_URL + "/gptLoader.gif"} alt='loading...' />
    }
    const usernames = users.map((user) => user.username);
    let parts = [];
    if (usernames.length === 1) {
        parts = [usernames[0], ' is typing...'];
    } else if (usernames.length <= maxUsernamesToShow) {
        parts = [usernames.join(', '), ' are typing...'];
    } else {
        parts = [usernames.slice(0, maxUsernamesToShow).join(', '), ` +${usernames.length - maxUsernamesToShow} are typing...`];
    }

    return <div className={`text-scogoprimary ${additionalClasses} max-w-md truncate`}>
        {parts[0]}
        <span className={`italic`} >{parts[1]}</span>
    </div>
};


export const LastMessageOrTyping = (props) => {
    const { conversationId, lastMessage, lastMessageTimeStamp, createdBy } = props
    const { mpConversationUserTyping } = useSelector((state) => state.conversations);
    const { loggedUser } = useSelector((state) => state.auth);
    const typingUsers = mpConversationUserTyping?.[conversationId];
    return (
        typingUsers?.length > 0 ? <TypingUsers users={typingUsers} additionalClasses='bg-transparent' maxUsernamesToShow={2} /> :
            <LastMessage lastMessage={lastMessage} loggedUserId={loggedUser.id} lastMessageTimeStamp={lastMessageTimeStamp} createdBy={createdBy} />
    )
}