import { fe } from '../types';

const INITIAL_STATE = {
    error: undefined,
};

const feReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case fe.FIELD_ENGINEERS_LIST_SUCCESS:
            return {
                ...state,
                fieldEngineers: action.payload,
            };
        case fe.FIELD_ENGINEERS_DROPDOWN_LIST_SUCCESS:
            return {
                ...state,
                fieldEngineersDropdown: action.payload,
            };
        case fe.FIELD_ENGINEERS_LIST_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return {
                ...state,
            };
    }
};

export default feReducer;
