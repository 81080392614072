import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getWalletTransactions,
    getNextWalletTransactions,
    getWalletDetails,
    getPromisedAmount,
} from '../../../../actions/wallet';
import TransactionCard from './Card';
import { getCustomerIdOfLoginUser } from '../../../../utils/utils';
import WalletCard from '../WalletCards';
import WalletActionButtons from '../WalletActionsButton';
import Select, { components } from 'react-select';
import { openCustomModal } from '../../../../actions/modal';
import RequestStatement from '../../Modals/RequestStatement';
import AddMoney from '../../Modals/AddMoney';
import SendToBank from '../../Modals/SendToBank';
import Pay from '../../Modals/Pay/index';
import Loader from "../../../../common/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { debounce, GetQueryParam } from '../../../../utils/common';
import moment from 'moment';
import DateTimePicker from '../../../../pages/Inventory/reusableComponents/AddOption/fields/DatePicker';
import { getDateWithISOFormat, updateSearchTermQuery, validateDate } from '../../../../utils/filter';
import NoResultFound from '../../../../common/NoResultFound';
import { isCustomerGroup, isIsp, isPsp, isSp, isSpIsp } from '../../../../utils/role';
import { getBanksList } from '../../../../actions/users';
import { getUserBankDetails } from '../../../../actions/auth';
import { bankDetailPayload } from '../../../Payments/Modals/EditPayout/TicketBankTransferPayment';
import SearchBox from '../../../../common/SearchBox';


const transactionStatusOptions = [
    { label: 'All', value: 'all' },
    { label: 'Topup', value: 'topup' },
    { label: 'Paid', value: 'paid' },
    { label: 'Received', value: 'received' },
    { label: 'Failed', value: 'failed' },
    { label: 'Withdraw', value: 'withdraw' },
];

export const defaultPage = 1
export const defaultLimit = 25;

export const isShowDailyLimit = (limit, role, type) => {
    return limit !== 0 && !isCustomerGroup(role, type);
}

export default function Wallet(props) {
    const dispatch = useDispatch();
    const { walletTransactions, hasReachedWalletListEnd, wallet, totalWithdrawn } = useSelector(state => state.wallet);
    const [page, setPage] = useState(defaultPage);
    const { isWalletTransactionLoading, isWalletNextTransactionLoading } = useSelector(state => state.loading);
    const { loggedUser, bankDetails } = useSelector(state => state.auth);
    const role = loggedUser.role.id, type = loggedUser.type;
    const customerId = getCustomerIdOfLoginUser(loggedUser);
    const { search: searchQueryParam } = useLocation();
    const navigate = useNavigate();
    const transactionStatusQuery = GetQueryParam('type') || 'all';
    const fromDateQuery = GetQueryParam('fromDate');
    const toDateQuery = GetQueryParam('toDate');
    const [groupedTransactions, setGroupedTransactions] = useState([]);
    const [showRequestStatementButton, setIsShowRequestStatementButton] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const handleRequestStatement = useCallback(() => {
        let heading = 'Request Statement';
        let modalComponent = <RequestStatement userId={loggedUser.id} />;
        let modalHeight = 'auto', modalWidth = '45rem';
        dispatch(openCustomModal({ modalComponent, heading, modalHeight, modalWidth }));
    }, [dispatch, loggedUser.id]);

    const isUserSpPspSpisp = isSp(role, type) || isPsp(role, type) || isSpIsp(role, type);
    const isUserIsIsp = isIsp(role, type);

    const handleGetUSerBankDetails = useCallback(() => {
        let { id } = loggedUser;
        const payload = bankDetailPayload({ userId: id, role, type });
        dispatch(getUserBankDetails(payload));
        dispatch(getWalletDetails({ customerId }));
        dispatch(getBanksList());
    }, [dispatch, loggedUser, customerId, role, type]);

    useEffect(() => {
        handleGetUSerBankDetails();
    }, [handleGetUSerBankDetails])

    const searchKey = GetQueryParam('query');

    useEffect(() => {
        let searchTerm = '';
        if (searchKey) searchTerm = searchKey;
        setSearchTerm(searchTerm);
        setPage(defaultPage);
    }, []);

    const handleSendToBank = () => {
        let vendorId = loggedUser.id;
        let type = '';
        if (isUserSpPspSpisp) {
            type = 'SP'
            vendorId = loggedUser.service_partner_id;
        }
        if (isUserIsIsp) {
            vendorId = loggedUser?.customer_id ? loggedUser?.customer_id : 0;
            type = 'ISP';
        }
        let heading = 'Withdraw Amount';
        let modalComponent = <SendToBank transactionStatusQuery={transactionStatusQuery} defaultPage={defaultPage} defaultLimit={defaultLimit} isShowDailyLimit={isShowDailyLimit(wallet?.withdraw_limit, loggedUser.role.id, loggedUser.type)} wallet={wallet} totalWithdrawn={totalWithdrawn} bankDetails={bankDetails} isUserSpPspSpisp={isUserSpPspSpisp} isUserIsIsp={isUserIsIsp} vendorId={vendorId} type={type} userId={loggedUser.id} showUserWalletBalance />;
        let modalHeight = 'auto', modalWidth = '50rem';
        dispatch(openCustomModal({ modalComponent, heading, modalHeight, modalWidth }));

    }

    const handlePay = useCallback(({ ticketId }) => {
        let heading = 'Transfer Amount';
        let modalComponent = <Pay transactionStatusQuery={transactionStatusQuery} defaultPage={defaultPage} defaultLimit={defaultLimit} ticketId={ticketId} />;
        let modalHeight = 'auto', modalWidth = '60rem';
        dispatch(openCustomModal({ modalComponent, heading, modalHeight, modalWidth }));
    }, [dispatch, transactionStatusQuery]);

    const handleAddMoney = useCallback(() => {
        let heading = 'Add Money';
        let modalComponent = <AddMoney />;
        let modalHeight = 'auto', modalWidth = '40rem';
        dispatch(openCustomModal({ modalComponent, heading, modalHeight, modalWidth }));
    }, [dispatch]);

    useEffect(() => {
        dispatch(getWalletDetails({ customerId }));
        dispatch(getPromisedAmount({ customerId }));

        return () => setPage(defaultPage);
    }, [customerId, dispatch]);

    useEffect(() => {
        let payload = { customerId, status: 'all', time: 'all', limit: defaultLimit, page: defaultPage };
        if (transactionStatusQuery) payload.status = transactionStatusQuery;
        if (validateDate(fromDateQuery) && validateDate(toDateQuery)) {
            payload.fromDate = fromDateQuery;
            payload.toDate = toDateQuery;
        }
        if (customerId) payload.customerId = customerId;
        if (searchKey) {
            payload.query = searchKey;
            payload.page = defaultPage;
            setPage(defaultPage);
        }
        dispatch(getWalletTransactions(payload));
    }, [dispatch, customerId, transactionStatusQuery, searchQueryParam, toDateQuery, fromDateQuery, searchKey]);

    const handleScroll = (e) => {
        const bottom = Math.abs(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight) < 1;
        if (bottom) {
            if (!isWalletTransactionLoading && !hasReachedWalletListEnd) {
                let p = page + 1;
                let payload = { status: 'all', limit: defaultLimit, offset: walletTransactions.length, page: p }
                if (transactionStatusQuery) payload.status = transactionStatusQuery
                if (validateDate(fromDateQuery) && validateDate(toDateQuery)) {
                    payload.fromDate = fromDateQuery;
                    payload.toDate = toDateQuery;
                }
                if (customerId) payload.customerId = customerId;
                if (searchKey) payload.query = searchKey;
                dispatch(getNextWalletTransactions(payload));
                setPage(p);
            }
        }
    };


    const updateQueryFilters = ({ type = transactionStatusQuery, fromDate = fromDateQuery, toDate = toDateQuery }) => {
        let searchParams = `?type=${type}`;
        if (fromDate && toDate) {
            searchParams += `&fromDate=${fromDate}`;
            searchParams += `&toDate=${toDate}`
        }

        navigate(`/wallet${searchParams}`);
    };


    const getGroupedTransactions = useCallback(() => {
        const lastSevenDays = moment().subtract(7, 'days');
        const lastThirtyDays = moment().subtract(30, 'days');
        const lastYears = moment().subtract(1, 'years');

        const dateCategories = [
            { key: 'today', label: 'Today', check: (time) => moment(time).format('DD-MM-YYYY') === moment().format('DD-MM-YYYY') },
            { key: 'yesterday', label: 'Yesterday', check: (time) => moment().subtract(1, 'days').format('DD-MM-YYYY') === moment(time).format('DD-MM-YYYY') },
            { key: 'this_week', label: 'This Week', check: (time) => moment(lastSevenDays).isBefore(time) },
            { key: 'this_month', label: 'This Month', check: (time) => moment(lastThirtyDays).isBefore(time) },
            { key: 'this_year', label: 'This Year ', check: (time) => moment(lastYears).isBefore(time) },
            { key: 'All', label: 'More than a Year ago', check: (_) => true },
        ]

        let dateCategoryTransactionMap = {};
        if (Array.isArray(walletTransactions)) {
            walletTransactions.forEach((transaction) => {
                let transactionTime = transaction.updated_at;
                const { key, label } = dateCategories.find(({ check }) => check(transactionTime));
                if (dateCategoryTransactionMap[key]?.values) dateCategoryTransactionMap[key].values.push(transaction);
                else dateCategoryTransactionMap[key] = { values: [transaction], label };
            })
        }
        return dateCategoryTransactionMap;
    }, [walletTransactions]);

    useEffect(() => {
        let transactions = getGroupedTransactions(walletTransactions);
        setIsShowRequestStatementButton(Object.keys(transactions).length > 0);
        setGroupedTransactions(transactions);
    }, [walletTransactions, getGroupedTransactions]);

    const debouncedSearchCall = useCallback(debounce(updateSearchTermQuery, 300), []);
    const setSeachQuery = (searchTerm) => {
        setSearchTerm(searchTerm);
        debouncedSearchCall({ navigate, searchTerm });
    };

    return (
        <>
            <div className='passbookContainer rounded-lg'>
                <div className={`flex gap-4 ${loggedUser.is_withdraw_disabled === 1 ? '' : 'mb-5'} m-2`}>
                    <WalletActionButtons
                        handleRequestStatement={handleRequestStatement}
                        handleSendToBank={handleSendToBank}
                        handlePay={handlePay}
                        handleAddMoney={handleAddMoney}
                        showRequestStatementButton={showRequestStatementButton}
                    />
                </div>
                {loggedUser.is_withdraw_disabled === 1 && <span className='text-scogoclosed px-4 font-medium'>Bank Services are not available. Please try again after some time.</span>}
                <div className='flex flex-row w-full h-full'>
                    <div className='flex flex-col walletContainer gap-y-4 m-2 pr-5'>
                        <WalletCard />
                    </div>
                    <div className="block rounded-lg w-full overflow-y-auto relative" onScroll={handleScroll}>
                        <div className='w-full sticky top-0 bg-scogof8'>
                            <div className='flex pb-8 justify-between'>
                                <div>
                                    <p className='text-font18 font-bold text-scogoprimary pt-2'>
                                        Passbook
                                    </p>

                                    {isShowDailyLimit(wallet?.withdraw_limit, loggedUser.role.id, loggedUser.type) && <p className='text-slate-700 text-font12 italic' > *Your daily withdrawal limit is ₹ {wallet?.withdraw_limit}</p>}

                                </div>

                                <div className='flex items-center'>
                                    {isCustomerGroup(role, type) && <SearchBox
                                        onCancel={() => setSeachQuery('')}
                                        searchTerm={searchTerm} onChange={(event) => setSeachQuery(event.target.value.trimStart())}
                                        placeholder={'Transcation Number'}
                                        autoFocus={false}
                                    />}
                                    <CustomRangeDateFilter onChange={({ fromDate, toDate }) => updateQueryFilters({ fromDate, toDate })} selectedFromDate={fromDateQuery} selectedToDate={toDateQuery} />
                                    <FilterDropdown options={transactionStatusOptions} onChange={(type) => updateQueryFilters({ type })} value={transactionStatusQuery} />
                                </div>
                            </div>
                        </div>
                        {isWalletTransactionLoading && <Loader color="#F48A21" size="65" speed="1" thickness="3" margin='150px' />}
                        {!isWalletTransactionLoading && <TransactionList groupedTransactions={groupedTransactions} handlePay={handlePay} transactionStatusQuery={transactionStatusQuery} fromDateQuery={fromDateQuery} toDateQuery={toDateQuery} />}
                        {isWalletNextTransactionLoading && <Loader color="#F48A21" size="20" speed="1" thickness="3" />}
                    </div>
                </div>
            </div>
        </>
    );
}


const FilterDropdown = ({ onChange, options, value }) => {
    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <span className="material-icons">expand_more</span>
            </components.DropdownIndicator>
        );
    };

    const customStyles = {
        singleValue: base => ({
            ...base,
            fontSize: '1.2rem',
            padding: 0,
            fontWeight: 500,
            color: 'rgb(156,163,175)'
        })
    }

    return (
        <label className='relative border-scogoddd px-2 rounded-xl bg-white' style={{ height: '3.7rem' }}>
            <Select
                name={''}
                options={options}
                isSearchable
                className='basic-multi-select select-text w-40'
                classNamePrefix='select'
                onChange={(elem) => onChange(elem.value)}
                value={options?.find((elem) => elem?.value === value)}
                components={{ DropdownIndicator }}
                styles={customStyles}
            />
        </label>
    )
}

const TransactionList = ({ groupedTransactions, handlePay, transactionStatusQuery, fromDateQuery, toDateQuery }) => {
    const getTransctionsCard = (key, values, label) => {
        if (Array.isArray(values)) {
            return <div key={key} className=''>
                <p className='text-scogogray font-bold text-font13 sticky top-20 max-w-max '>{label}</p>
                {values.map((transaction) => <TransactionCard key={transaction.id} transaction={transaction} payFE={handlePay} transactionStatusQuery={transactionStatusQuery} fromDateQuery={fromDateQuery} toDateQuery={toDateQuery} />)}
            </div>
        }
        return <React.Fragment key={key} />
    }

    const isNotTransactionFound = Object.keys(groupedTransactions).length === 0;
    if (isNotTransactionFound) return <NoResultFound message='No Transactions Found' />;

    return <>
        {Object.entries((groupedTransactions)).map(([key, { values, label }]) => getTransctionsCard(key, values, label))}
    </>
}

const CustomRangeDateFilter = ({ onChange, selectedFromDate, selectedToDate }) => {
    let selectedDateRange = [null, null];
    if (selectedFromDate && selectedToDate) {
        selectedDateRange = [moment(selectedFromDate)._d, moment(selectedToDate)._d]
    }
    const dateFilterName = 'date_range';

    const handleChange = (event) => {
        const fromDate = getDateWithISOFormat(event.value.startDate);
        const toDate = getDateWithISOFormat(event.value.endDate);
        if ((fromDate && toDate) || (!fromDate && !toDate)) onChange({ fromDate, toDate });
    }

    return (
        <DateTimePicker
            onChangeHandler={handleChange}
            placeholder={'Select Date Range'}
            dateFormat={'dd-MM-yyyy'}
            inputValueFormat={'DD-MM-YYYY'}
            todayButton={false}
            name={dateFilterName}
            maxDate={0}
            defaultStartEndDate={selectedDateRange}
            className='w-96'
        />
    )
}