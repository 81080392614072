import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Message from '../messageBox/Messages/Message';
import DateDivider from './DateDivider';
import Loader from '../../../common/Loader';
import UnReadMessageCount from './UnreadMessageCount';
import { chatType } from '../ChatController';


export default function MessagesList({ openedConversationMessages, unReadMessageId, openedConversation, onClickReplyMessage, onReplyClick, replyToMessage, replyShow, showChatSubHeader, firstMessageRef, scrollDivRef, isCurrentChatLoading, handleScroll, isChatPopup, isChatMinimized = false }) {
    const { loggedUser } = useSelector((state) => state.auth);
    const isDateAreSame = (a, b) => {
        return a && b && moment(a).format('dd/MM/Y') === moment(b).format('dd/MM/Y');
    };

    return <div ref={scrollDivRef} className={`${isChatMinimized ? 'pointer-events-none' : ''} relative bg-scogoeee overflow-y-auto flex-auto overflow-x-hidden`} onScroll={handleScroll}>
        <div className='absolute top-4 left-0 right-0 z-10 '>{isCurrentChatLoading && <Loader color='#F48A21' size='25' speed='1' />}</div>
        <div
            ref={firstMessageRef}
            style={{ left: '50%', bottom: '50%', transform: 'translate(-50%, -50%)' }}
            className={`rounded-md ${isChatPopup ? 'px-1 py-1' : 'px-2 py-2'} px-1 py-1 justify-center text-scogogray sticky top-8 z-10  max-w-max bg-tagmsg text-lg`}
        >
        </div>
        <div className='px-p-15'>
            {openedConversationMessages?.map((msg, index) => {
                let isDateChanged = true;

                if (msg.timestamp && openedConversationMessages[index - 1] && openedConversationMessages[index - 1].timestamp) {
                    isDateChanged = !isDateAreSame(msg.timestamp, openedConversationMessages[index - 1].timestamp);
                }

                return (
                    <React.Fragment key={msg._id}>
                        {isDateChanged && <DateDivider timestamp={msg.timestamp} isChatPopup={isChatPopup} timeWidth={'w-full'} />}
                        {unReadMessageId === msg._id && <UnReadMessageCount unReadCount={openedConversation?.unreadMessageCount} isChatPopup={isChatPopup} />}
                        <Message
                            message={msg}
                            createdBy={openedConversation?.createdBy}
                            side={loggedUser.id === msg.from.userId ? 'right' : 'left'}
                            type={msg.type}
                            onClickReplyMessage={onClickReplyMessage}
                            onReplyClick={onReplyClick}
                            selectedMessageId={replyToMessage?._id}
                            replyShow={replyShow}
                            showMessageAction={openedConversation?.active && !openedConversation?.isBotEnabled}
                            toEnableChip={
                                index === openedConversationMessages.length - 1 &&
                                openedConversation?.active &&
                                loggedUser?.id === openedConversation?.createdBy?.userId
                            }
                            showReplyIcon={openedConversation?.active && !openedConversation?.isBotEnabled && !openedConversation?.hideInput}
                            callStepFields={showChatSubHeader}
                            isChatPopup={isChatPopup}
                            isTicketChat={openedConversation?.type === chatType.ticket}
                        />
                    </React.Fragment>
                );
            })}
        </div>
        <div id='last_element_after_messages'></div>
    </div>;
};