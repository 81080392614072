import React from 'react';
import Loader from '../Loader';
import IconToolTip from '../IconToolTip';

export const SimpleButton = (props) => {
    const { type, loading, btnClass, loaderClass, onClick, disabled, loaderColor, buttonIcon, buttonIconComponent, btnimgClass, Btnalt, textOrComponent, style, buttonTextClass = '', onHoverTitle = '', isChatPopup } =
        props;

    const button = <button type={type} className={btnClass} onClick={onClick} disabled={disabled} style={style} >
        {loading && (
            <div className={`${loaderClass ? loaderClass : 'absolute'}`}>
                <Loader color={loaderColor ? loaderColor : '#FFF'} size='15' speed='1' thickness='2' />
            </div>
        )}

        {buttonIcon && <img src={process.env.PUBLIC_URL + buttonIcon} className={btnimgClass} alt={Btnalt} />}
        {buttonIconComponent && buttonIconComponent}

        <div className={`${loading ? 'invisible' : isChatPopup ? 'truncate w-[4.5rem] ' : ''}} ${buttonTextClass}`}>{textOrComponent}</div>


    </button>;

    if (onHoverTitle?.length > 0) {
        return <IconToolTip title={onHoverTitle}>
            {button}
        </IconToolTip>
    }

    return button;
};
