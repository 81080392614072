import React from 'react';
import _ from 'lodash';

export default function CheckboxField(props) {
    const { className, checkColor = 'text-scogoorange mr-2', checkContent, onChangeHandler, name, isEditable = true, form } = props;

    const handleChange = (event) => {
        if (isEditable) {
            onChangeHandler(event, checkContent);
        }
    };

    return (
        <>
            <div className={`px-4 py-3 ${className ? className : ''}`}>
                <div>
                    <label className='inline-flex items-center '>
                        <input
                            type='checkbox'
                            onChange={handleChange}
                            className={`${checkColor} rounded-3px cursor-pointer border-2 border-scogo88 focus:outline-none focus:ring-0`}
                            style={{ height: '1.3rem', width: '1.3rem' }}
                            checked={_.get(form, name)}
                            name={name}
                        />
                        <span className='text-scogo15 font-normal cursor-pointer text-font12'>{checkContent}</span>
                    </label>
                </div>
            </div>
        </>
    );
}
