import moment from 'moment';
import React, { useEffect } from 'react';
import IconToolTip from '../../../common/IconToolTip';
import Loader from '../../../common/Loader';
import MiniPopup from '../../../common/MiniPopup';
import { validateStringValue } from '../../../utils/common';
import { LineClampComponent } from '../TicketTableComponent';
import ViewAllRemark from '../Modal/ViewAllRemark';
import { useDispatch } from 'react-redux';
import { closeModalAction, openCustomModal } from '../../../actions/modal';
import ButtonScogoOrange from '../../../common/Buttons/ButtonScogoOrange';
import { AddIssueCategory } from './AddIssueCategory';
import OverflowTip from '../../../common/OverflowTip';
import ButtonScogoPrimary from '../../../common/Buttons/ButtonScogoPrimary';



export const RemarkColumn = ({ remarkList, positionClass, loading, job_ticket_number, customerId, issue_category, ticket_id, issue_title }) => {
    const dispatch = useDispatch();

    if (loading) {
        return (
            <div className='relative w-3/12'>
                <Loader color={'#F48A21'} size='15' speed='1' thickness='2' />
            </div>
        );
    }
    let showViewAllRemark = remarkList.length > 1;

    const closeModal = () => {
        dispatch(closeModalAction());
    };

    const viewRemarks = () => {
        let modalComponent = <ViewAllRemark closeModal={closeModal} ticketId={ticket_id} source='ticket_table' />;
        let modalWidth = '60em',
            modalHeight = '40em';
        let head = <span className='text-black'>
            Remarks :&nbsp;<span className='text-scogobgsky text-font16'>{job_ticket_number}</span>
        </span>
        dispatch(openCustomModal({ modalComponent, heading: head, modalWidth, modalHeight }));
    };

    return (
        <div className='w-full'>
            {remarkList?.slice(0, 2)?.map((remark) => {
                let someLongText = '';
                if (validateStringValue(remark?.remark)) {
                    someLongText = remark?.remark
                } else if (remark?.event) {
                    someLongText = remark?.event
                }
                return (
                    <Remark
                        remarkText={someLongText}
                        remarkBy={`${validateStringValue(remark?.created_by?.first_name)} ${validateStringValue(remark?.created_by?.last_name)}`}
                        remarkDate={moment(remark.created_at).format('DD/MM/YYYY')}
                        key={remark.created_at}
                    />
                );
            })}


            {/* {showViewAllRemark && ( */}
            <IconToolTip title={'View All Remark'}>
                <div
                    onClick={() => {
                        viewRemarks();
                    }}
                    className='max-w-max line-clamp-2 text-scogobgsky cursor-pointer text-left text-font11 hover:text-scogoorange'
                >
                    Add/View Remarks
                </div>
            </IconToolTip>
            {/* )} */}
            <AddRemark issue_category={issue_category} customerId={customerId} ticket_id={ticket_id} issue_title={issue_title} />
        </div>
    );
};

const Remark = ({ remarkBy, remarkText, remarkDate }) => {
    return (
        <>
            <p className='td_p_light_primary md:mt-2 mt-0 md:mr-0 mr-2 md:hidden block text-font11'>Remarks :</p>
            <LineClampComponent value={`${remarkText ? remarkText : ''}`} lineClamp='line-clamp-2' />
            <div className='md:block flex flex-wrap items-center'>
                <p className='text-font10 text-scogogray font-normal md:mr-0 mr-2'>{`${remarkBy} on ${remarkDate}`}</p>
            </div>
        </>
    );
};

export const ViewAllRemarks = ({ remarkList, displayName, onHovertitle, positionClass = 'lg:top-6 top-4' }) => {
    if (!displayName.trim()) return <></>;
    const Details = () => {
        return (
            <div className='flex flex-col gap-4 w-20vw z-50'>
                {remarkList?.map((remark, index) => {
                    let someLongText = (remark?.remark?.length > 0 ? remark?.remark : '') + (remark?.event?.length > 0 ? remark?.event : '');

                    return (
                        remark && (
                            <div>
                                <Remark
                                    key={remark.created_at}
                                    remarkText={someLongText}
                                    remarkBy={`${validateStringValue(remark?.created_by?.first_name)} ${validateStringValue(remark?.created_by?.last_name)}`}
                                    remarkDate={moment(remark.created_at).format('DD/MM/YYYY')}
                                />
                            </div>
                        )
                    );
                })}
            </div>
        );
    };
    return (
        <div className='w-full relative  cursor-pointer flex items-center text-scogobgsky  font-medium gap-3 max-h-50vh'>
            <MiniPopup
                handleClick={() => { }}
                popup={Details()}
                position='ml-auto w-full'
                className={`absolute  px-3 py-2 mt-2  bg-white  max-h-50vh  text-black overflow-scroll rounded-md shadow-xl z-50 ${positionClass}`}
                onHover=''
            >
                {displayName?.trim() && <div className='flex items-start w-full '></div>}
            </MiniPopup>
        </div>
    );
};


const AddRemark = ({ issue_category, customerId, ticket_id, issue_title }) => {
    const dispatch = useDispatch();

    const addIssueCategory = () => {
        const heading = issue_category === 0 ? 'Add Issue' : 'Edit Issue';
        dispatch(openCustomModal({
            heading: heading,
            modalWidth: '40vw',
            modalHeight: 'auto',
            modalComponent: <AddIssueCategory issue_category={issue_category} customerId={customerId} ticket_id={ticket_id} />
        }
        ));
    }

    if (issue_category === 0) {
        return <ButtonScogoOrange buttonClass='text-font11' textOrComponent={'Add Issue'} onClick={addIssueCategory} />;
    }
    const textValueClass = 'text-font10 text-black w-full font-normal truncate';
    return (
        <span className='px-2 flex gap-2 items-center rounded-md w-fit max-w-[80%] border-2 border-scogoddd'>
            {/* <IconToolTip textClass={`text-inherit ${textValueClass}`} someLongText={`${issue_title} `} /> */}
            <IconToolTip title={`Issue category : ${issue_title}`}>
                <span className={textValueClass}>{issue_title}</span>
            </IconToolTip>
            <>
                <span className=''>|</span>
                <span
                    className='material-icons text-font12  pr-1 cursor-pointer '
                    onClick={addIssueCategory}
                >
                    edit
                </span>
            </>
        </span>
    )
}