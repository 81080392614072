import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { validateNumberValue, validateStringValue } from '../../../utils/common';
import { getProjectPMRequest } from '../../../actions/project';
import { Skeleton } from '@mui/material';
import { SkeltonCardTwo } from '../../../common/SkeltonCardTwo';
import IconToolTip from '../../../common/IconToolTip';
import OverflowTip from '../../../common/OverflowTip';

export const RenderPMs = ({ projectId, loaderComponent, mainDivClass = 'grid grid-cols-2 p-2 gap-2 w-20vw', pmList = [] }) => {
    const { projectPMs } = useSelector((state) => state.project);
    const openedProjectPms = projectPMs && projectPMs[projectId];
    const { isProjectPmLoading } = useSelector((state) => state.loading);
    const dispatch = useDispatch();

    const pms = useMemo(() => {
        let pm_list = [];
        if (openedProjectPms) {
            pm_list = openedProjectPms.sort((a, b) => {
                return a - b
            }).map((pm) => {
                let label = pm.order < 4 ? `Project Manager ${pm.order}` : 'Escalation Manager';
                let name = validateStringValue(`${pm.user.first_name} ${pm.user.last_name}`);
                let mobile = validateNumberValue(pm?.user?.mobile);
                return { ...pm, name, label, mobile };
            });
        } else if (Array.isArray(pmList) && pmList.length > 0) {
            pm_list = pmList;
        }
        return pm_list;
    }, [openedProjectPms, pmList]);



    useEffect(() => {
        if (!openedProjectPms) {
            dispatch(getProjectPMRequest({ projectId }));
        }
    }, [dispatch, projectId, openedProjectPms]);

    const noResultFound = <div className={mainDivClass + ' text-scogoprimary text-font14 px-10'}>
        No PM found
    </div>;

    if (!isProjectPmLoading && pms?.length === 0) {
        return noResultFound
    }

    if (isProjectPmLoading) {
        if (loaderComponent) return loaderComponent;

        return <div className={mainDivClass}>
            {[1, 2, 3, 40].map((i) => {
                return <div className='mt-2' >
                    <Skeleton sx={{ background: 'rgb(221,221,221)' }} variant='rectangular' width={'10rem'} height={25} animation='wave' />
                </div>
            })}
        </div>
    }

    return (
        <div className={mainDivClass}>
            {pms?.map((pm, i) => {
                return <User pm={pm} />
            })}
        </div>
    );
}


const User = ({ pm }) => {
    return <div key={pm} className='mt-1 py-4'>
        <p className='text-scogo99 text-font11 font-medium'> {pm.label} : </p>
        <p className='flex items-center gap-2  text-font11'>
            <IconToolTip title={'Mobile Number'}>
                <span className="material-icons text-scogoorange text-font12">person</span>
            </IconToolTip>
            <OverflowTip someLongText={pm?.name} />
        </p>
        <p className='flex items-center gap-2 text-font11'>
            <IconToolTip title={'Mobile Number'}>
                <span className="material-icons text-scogoprimary text-font12">phone</span>
            </IconToolTip>
            <OverflowTip someLongText={pm?.mobile} />
        </p>
    </div>
}

export default function PMDetails({ projectId, pmList }) {
    return <RenderPMs pmList={pmList} projectId={projectId} loaderComponent={
        <SkeltonCardTwo arr={[1, 2]} height='25' className='px-6' />
    } mainDivClass='grid grid-cols-2 p-2 gap-2 px-6' />;
}
