import { useEffect, useState } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import IconToolTip from '../../../common/IconToolTip';
import { InputLabel, MenuItem, Popover, Select } from '@mui/material';
import { useHMSActions } from '@100mslive/react-sdk';

let mediastream;
export const SettingsPopover = () => {

    const [cameras, setCameras] = useState([]);
    const [microphones, setMicrophones] = useState([]);
    const [outputs, setOutputs] = useState([]);
    const [currentCam, setCurrentCam] = useState("");
    const [currentMic, setCurrentMic] = useState("");
    const [currentOutput, setCurrentOutput] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const hmsActions = useHMSActions();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCameraChange = async (e) => {
        if (e.target.value) {
            await hmsActions.setVideoSettings({ deviceId: e.target.value });
            setCurrentCam(e.target.value);
        }
    }

    const handleMicChange = async (e) => {
        if (e.target.value) {
            await hmsActions.setAudioSettings({ deviceId: e.target.value });
            setCurrentMic(e.target.value);
        }
    }

    const handleOutputChange = async (e) => {
        if (e.target.value) {
            await hmsActions.setAudioOutputDevice({ deviceId: e.target.value });
            setCurrentOutput(e.target.value);
        }
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const getDevices = async () => {
        mediastream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        navigator.mediaDevices.enumerateDevices()
            .then(devices => {
                const mics = devices.filter(d => d.kind === 'audioinput');
                const cams = devices.filter(d => d.kind === 'videoinput');
                const outputs = devices.filter(d => d.kind === 'audiooutput');
                setCameras(cams);
                setMicrophones(mics);
                setOutputs(outputs);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                mediastream?.getTracks()?.forEach(track => track.stop());
            })
    }

    useEffect(() => {
        getDevices();
        return () => {
            mediastream?.getTracks()?.forEach(track => track.stop());
        }
    }, []);

    return (
        <>
            <IconToolTip title="Settings">
                <div className='h-full flex items-center' onClick={handleClick}>
                    <SettingsIcon sx={{ fontSize: 28 }} className='hover:cursor-pointer hover:text-gray-600 transition-all' />
                </div>
            </IconToolTip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}>
                <div className='w-[30rem] p-5'>
                    {
                        cameras.length > 0 &&
                        <>
                            <InputLabel sx={{ fontSize: 20 }} id="camera-label-id">Cameras</InputLabel>
                            <Select labelId="camera-label-id" label="Cameras" fullWidth displayEmpty onChange={handleCameraChange} size='small' value={currentCam}>
                                <MenuItem value="">Select Camera</MenuItem>
                                {
                                    cameras.map((camera) => {
                                        return <MenuItem value={camera.deviceId} key={camera.deviceId}>{camera.label}</MenuItem>
                                    })
                                }
                            </Select>
                        </>
                    }
                    {
                        microphones.length > 0 &&
                        <>
                            <InputLabel sx={{ fontSize: 20 }} id="mic-label-id">Microphones</InputLabel>
                            <Select labelId="mic-label-id" label="Microphones" displayEmpty onChange={handleMicChange} fullWidth size='small' value={currentMic}>
                                <MenuItem value="">Select Microphone</MenuItem>
                                {
                                    microphones.map((microphone) => {
                                        return <MenuItem value={microphone.deviceId} key={microphone.deviceId}>{microphone.label}</MenuItem>
                                    })
                                }
                            </Select>
                        </>
                    }
                    {
                        outputs.length > 0 &&
                        <>
                            <InputLabel sx={{ fontSize: 20 }} id="output-label-id">Audio Outputs</InputLabel>
                            <Select labelId="output-label-id" label="Audio Outputs" displayEmpty onChange={handleOutputChange} fullWidth size='small' value={currentOutput}>
                                <MenuItem value="">Select Audio Output</MenuItem>
                                {
                                    outputs.map((output) => {
                                        return <MenuItem value={output.deviceId} key={output.deviceId}>{output.label}</MenuItem>
                                    })
                                }
                            </Select>
                        </>
                    }
                </div>
            </Popover>
        </>
    )
}
