import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import GridTable from '../../../common/GridTable';
import ButtonScogoClosedOutlined from '../../../common/Buttons/ButtonScogoClosedOutlined';

const OpenTickets = ({ tickets }) => {

    const dispatch = useDispatch();

    const bluePrint = {
        table: [
            {
                head: {
                    render: (headName) => {
                        return headName;
                    },
                    headName: 'Asset Number',
                },
                body: {
                    render: (item) => {
                        return <p>{item.asset_number}</p>;
                    },
                },
            },
            {
                head: {
                    render: (headName) => {
                        return headName;
                    },
                    headName: 'Ticket',
                },
                body: {
                    render: (item) => {
                        return (
                            <>
                                <p>{item.job_ticket_number}</p>
                            </>
                        );
                    },
                },
            },
        ],
    };

    const handleCancel = () => {
        dispatch(closeModalAction());
    };

    return (
        <div className='px-2 py-4'>
            <GridTable schema={bluePrint} rowData={tickets} tableError={'No Ticket Found'} tableBodyHeight='max-h-max' />
            <div className='flex justify-end py-2 w-full'>
                <ButtonScogoClosedOutlined textOrComponent={'Cancel'} onClick={handleCancel} />
            </div>
        </div>
    );
};

export default OpenTickets;
