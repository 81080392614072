import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { closeModalAction } from '../../../../actions/modal';
import DatePicker from '../../../../common/Form/DatePicker';
import Form from '../../../../common/Form';
import { EditAssetHistoryCreatedDate } from '../../../../actions/inventory';


const EditAssetDate = ({ asset, assetSource, assetId }) => {
    const { formButtonLoading } = useSelector((state) => state.loading)
    const defaultValues = { capex_date: new Date(asset.created_at) };
    const dispatch = useDispatch();
    const methods = useForm({ defaultValues, mode: 'all' });
    const formName = 'Edit_Date'
    const submit = (formValues) => {

        const payload = { date: formValues.capex_date, source: asset.itemType, id: asset.id, formName, assetSource, assetId }
        dispatch(EditAssetHistoryCreatedDate(payload))
    }

    return (<>
        <Form
            methods={methods}
            onSubmit={submit}
            submitButtonText={'Submit'}
            onCancel={() => dispatch(closeModalAction())}
            showSubmit

            submitContainerClassName='flex gap-2 justify-end py-4 px-4'
            isLoading={formButtonLoading[formName]}
        >
            <DatePicker name='capex_date' className={'w-full'} required showTimeSelect />
        </Form>


    </>)
}

export default EditAssetDate