import * as React from 'react';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import { ClickAwayListener } from '@mui/base';
import Buttons from '../common/Buttons';
import NotificationList from './NotificationList';
import { useDispatch, useSelector } from 'react-redux';
import { getNextNotification } from '../actions/users';
import IconToolTip from '../common/IconToolTip';

const boxStyle = {
    bgcolor: '#fff',
    width: '35rem',
    maxHeight: '30rem',
};

export default function NotificationsPopup() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { notifications } = useSelector((state) => state.user);
    const { loggedUser } = useSelector((state) => state.auth);
    const { isNextNotificationsLoading } = useSelector((state) => state.loading);
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const onClickAwayHandler = () => {
        if (!anchorEl) return;
        if (anchorEl) setAnchorEl(false);
    };

    const handleScroll = (e) => {
        const difference = e.target.scrollHeight - e.target.clientHeight;
        const scrollposition = difference - e.target.scrollTop;
        if (scrollposition <= 5) {
            if (!isNextNotificationsLoading) {
                dispatch(getNextNotification({ offset: notifications.next_offset }));
            }
        }
    };

    const isUnreadNonZero = loggedUser?.count?.unread_notification > 0;

    const getUnreadNotificationCount = (unreadCount) => {
        if (unreadCount > 99) return '99+';
        return unreadCount;
    };

    return (
        <ClickAwayListener onClickAway={onClickAwayHandler}>
            <div className='relative px-6 mr-3'>
                <Buttons onClick={handleClick}>
                    <IconToolTip title='Notifications'>
                        <img className={`notificationIcon`} src={process.env.PUBLIC_URL + '/img/bell.svg'} alt='' />
                    </IconToolTip>
                    {loggedUser?.count?.unread_notification > 0 ? (
                        <span
                            className={`absolute  inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-scogoorange rounded-full notificationBadge`}
                        >
                            {getUnreadNotificationCount(loggedUser?.count?.unread_notification)}
                        </span>
                    ) : (
                        ''
                    )}
                </Buttons>
                <Popper id={id} open={open} anchorEl={anchorEl} placement={'bottom-end'} className='z-50'>
                    <Box sx={boxStyle} className='shadow-2xl rounded-xl mt-4 border-4 overflow-auto' onScroll={handleScroll}>
                        <NotificationList
                            unreadCount={isUnreadNonZero ? loggedUser?.count?.unread_notification : 0}
                            isUnreadNonZero={isUnreadNonZero}
                        />
                    </Box>
                </Popper>
            </div>
        </ClickAwayListener>
    );
}
