import React, { useEffect, useLayoutEffect, useRef, } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import Sidebar from '../layout/sidebar/Sidebar';
import Header from '../layout/header/Header';
import Footer from '../layout/footer/Footer';
import TextModal from '../common/Modals/TextModal';
import FullScreenModal from '../common/Modals/FullScreenModal';
import CustomModal from '../common/Modals/CustomModal';
import SmallCustomModal from '../common/Modals/SmallCustomModal';
import { getIsNotScogoPartner, isLogged, isWhatsappPopupOpen } from '../utils/common';
import { closeModalAction, openCustomModal, openProgressBarModal, closeFullScreenModalAction } from '../actions/modal';
import { useDispatch } from 'react-redux';
import MediaView from '../common/MediaView';
import UploadAddressProof from '../pages/Modals/UploadAddressProof';
import { isCustomerGroup } from '../utils/role';
import { signOutUserAction } from '../actions/auth';
import WhatsappAlternateMobilePopup from '../pages/Modals/WhatsappAlternateMobilePopup';
import ProgressBarModal from '../common/Modals/ProgressBarModal';
import CallModal from '../common/Modals/CallModal';
import { chats, detailsPath, trackSupportTicket } from './roleWisePageRoute';
import { isMobileView } from '../pages/auth/components/NewScogoButton';
import { MediumChatBox } from '../pages/chat/ChatMessage/MediumChatBox';
import { connectToWebsocket } from '../actions/websocket';

const SidebarLayout = () => {
    const { isTextModalOpen, isCustomModalOpen, isSmallCustomModalOpen, isFullScreenModalOpen, isMediaViewModalOpen, isProgressBarModalOpen, isOnCall } = useSelector((state) => state.modal);
    const dispatch = useDispatch();
    const { loggedUser } = useSelector((state) => state.auth);
    const { downloadProgressList } = useSelector((state) => state.modal);
    const { chatBoxElementData } = useSelector((state) => state.conversations)

    const role = loggedUser.role.id;
    const type = loggedUser.type;
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const showReuiredPopups = ![trackSupportTicket.path, detailsPath.path].includes(pathname);
    const mainChatContanerRef = useRef()

    const { websocketConnection } = useSelector((state) => state.websocket);


    useEffect(() => {
        localStorage.removeItem('IS_PLAYING'); //videomeet
        localStorage.removeItem('40_SEC_NO_PLAY');
    }, []);


    useEffect(() => {
        if (isLogged() && !websocketConnection) {
            dispatch(connectToWebsocket());
        }
    }, [dispatch, websocketConnection]);

    useEffect(() => {
        if (loggedUser.is_enabled === 2 && loggedUser.disabled_duration === 'Permanent') {
            dispatch(signOutUserAction(navigate));
        }

        let isNotScogoPartner = getIsNotScogoPartner(loggedUser.sp_under_customer);

        if (!loggedUser.address_proof_type && !isCustomerGroup(role, type) && !isNotScogoPartner && showReuiredPopups) {
            dispatch(
                openCustomModal({
                    heading: `Upload Address Proof`,
                    modalWidth: '40vw',
                    modalHeight: 'auto',
                    modalComponent: <UploadAddressProof />,
                    showClose: false,
                    showLogout: true,
                })
            );
        } else if (loggedUser.address_proof_type && !loggedUser.address_proof_url_back && !isCustomerGroup(role, type) && !isNotScogoPartner && showReuiredPopups) {
            dispatch(
                openCustomModal({
                    heading: `Upload Address Proof Back Image`,
                    modalWidth: '40vw',
                    modalHeight: 'auto',
                    modalComponent: <UploadAddressProof onlyBackImageUpload />,
                    showClose: false,
                    showLogout: true,
                })
            );
        } else if (isWhatsappPopupOpen() && loggedUser?.is_mobile_linked_with_whatsapp === 0 && showReuiredPopups) {
            dispatch(
                openCustomModal({
                    heading: `Update WhatsApp Number`,
                    modalWidth: '35vw',
                    modalHeight: 'auto',
                    modalComponent: <WhatsappAlternateMobilePopup />,
                    showClose: false
                })
            );
        }


    }, [loggedUser, dispatch, role, type, navigate]);

    useLayoutEffect(() => {
        dispatch(closeModalAction());
        dispatch(closeFullScreenModalAction());
    }, [window.location.href, dispatch]);

    useEffect(() => {
        if (Array.isArray(downloadProgressList) && downloadProgressList.length > 0 && !isProgressBarModalOpen) {
            dispatch(openProgressBarModal());
        }
    }, [downloadProgressList, dispatch, isProgressBarModalOpen]);


    const isMediumChatBoxOpened = Object.keys(chatBoxElementData).length > 0 && !window.location.pathname.startsWith(chats.path)


    return (
        <div className={`h-screen overflow-hidden ${showReuiredPopups ? 'bg-scogogray' : 'bg-ticketSideDawerColor'}`}>
            <Header isInactiveUser={!showReuiredPopups} isSupoortTicketPath={[trackSupportTicket.path].includes(pathname)} />
            <div className='flex bg-scogof8 md:overflow-auto lg:overflow-hidden'>
                <div>
                    {showReuiredPopups && <Sidebar mainChatContanerRef={mainChatContanerRef} />}
                </div>

                <div ref={mainChatContanerRef} className={showReuiredPopups ? isMobileView ? "mainContainerMobileView" : "mainContainer" : 'mainContainerForUnauthorisedUser'}>
                    <Outlet />
                </div>
            </div>
            {showReuiredPopups && <Footer />}
            {/* adding new modals here is not a good idea, think of storing in a variable */}
            {isTextModalOpen && <TextModal />}
            {isCustomModalOpen && <CustomModal />}
            {isFullScreenModalOpen && <FullScreenModal />}
            {isSmallCustomModalOpen && <SmallCustomModal />}
            {isMediaViewModalOpen && <MediaView />}
            {isProgressBarModalOpen && <ProgressBarModal />}
            {isOnCall && <CallModal />}
            {isMediumChatBoxOpened && <MediumChatBox />}
        </div>
    );
};

export default SidebarLayout;
