import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import { actualValueForDropdown } from '../../../utils/common';
import { getCityFromPincode, removePinCityFromStore } from '../../../actions/inventory';
import { addNewPincode, clearDistrictListFromStore, clearLocalityListFromStore, clearStateCitiesFromStore, getAllStateCities, listAllDistrict, listAllLocalities, listAllState } from '../../../actions/admin';
import Form from '../../../common/Form';
import Input from '../../../common/Form/Input';
import Select from '../../../common/Form/Select';
import { useForm, useFormContext } from 'react-hook-form';
import apis from '../../../apis';

const formName = 'add_new_pincode';

export default function AddNewPincode({ pincode, onSuccess = () => { } }) {
    const defaultValues = {
        pincode: pincode ? pincode : null,
    };
    const { formButtonLoading } = useSelector((state) => state.loading);
    const dispatch = useDispatch();

    useEffect(() => {
        if (pincode) {
            dispatch(getCityFromPincode({ pincode }));
        }
        dispatch(listAllState({ dropdown: true }));

        return () => {
            dispatch(removePinCityFromStore());
            dispatch(clearStateCitiesFromStore());
            dispatch(clearDistrictListFromStore());
            dispatch(clearLocalityListFromStore());
        };
    }, [dispatch, pincode]);

    const submit = (formValues) => {
        let payload = {
            fk_city_id: actualValueForDropdown(formValues.city),
            fk_state_id: actualValueForDropdown(formValues.state),
            fk_locality_id: actualValueForDropdown(formValues.fk_locality_id),
            fk_district_id: actualValueForDropdown(formValues.fk_district_id),
            pincode: formValues.pincode,
        };
        dispatch(addNewPincode({ data: payload, formName, onSuccess }));
    };

    const methods = useForm({ defaultValues, mode: 'all' });

    return (
        <Form
            methods={methods}
            onSubmit={submit}
            formName={formName}
            submitButtonText={'Submit'}
            onCancel={() => dispatch(closeModalAction())}
            showSubmit
            submitContainerClassName='flex gap-2 justify-end py-4 px-4'
            isLoading={formButtonLoading[formName]}
        >
            <FormInputs pincode={pincode} />
        </Form>
    );
}

const FormInputs = (props) => {
    const { allState, stateAllCities, districtsList, localityList } = useSelector((state) => state.admin);
    const { isStateCitiesLoading, isAllStateLoading, isLocalityListLoading, isDistrictListLoading } = useSelector((state) => state.loading);
    const dispatch = useDispatch();
    const { watch, setValue } = useFormContext();
    const city = watch('city');
    const state = watch('state');
    const district = watch('fk_district_id');
    const cityId = actualValueForDropdown(city);
    const districtId = actualValueForDropdown(district);
    const stateId = actualValueForDropdown(state);

    return (
        <div className='px-2 py-2'>
            <div className='flex'>
                <Input
                    label='Pincode'
                    required
                    name='pincode'
                    type={'number'}
                    pattern={/^\d{6}$/}
                    validate={async (pincode) => {
                        if (pincode === '') return true;
                        const response = await apis.cities.getCityFromPincode({}, {}, {}, { pincode });
                        return response.data?.code !== 200 || 'Pincode already exist in the System.';
                    }}
                    className='w-4/12'
                />
                <Select name={`state`} label='State' options={allState} required isLoading={isAllStateLoading} onChange={(event) => {
                    const value = actualValueForDropdown(event);
                    if (value) {
                        dispatch(getAllStateCities({ stateId: value }));
                        dispatch(listAllDistrict({ stateId: value, cityId }));
                        dispatch(listAllLocalities({ stateId: value, cityId, districtId }));
                    } else {
                        dispatch(clearStateCitiesFromStore());
                        dispatch(clearDistrictListFromStore());
                        dispatch(clearLocalityListFromStore());
                        setValue('city', null);
                        setValue('fk_district_id', null);
                        setValue('fk_locality_id', null);
                    }
                }} className='w-4/12' />
                <Select name={`city`} label='City' options={stateAllCities} required isLoading={isStateCitiesLoading} onChange={(event) => {
                    const value = actualValueForDropdown(event);
                    if (value) {
                        dispatch(listAllDistrict({ cityId: value, stateId }));
                        dispatch(listAllLocalities({ stateId, cityId: value, districtId }));
                    } else {
                        dispatch(clearDistrictListFromStore());
                        dispatch(clearLocalityListFromStore());
                        setValue('fk_district_id', null);
                        setValue('fk_locality_id', null);
                    }
                }} className='w-4/12' />
            </div>
            <div className='flex'>
                <Select name={`fk_district_id`} label='District' options={districtsList} required isLoading={isDistrictListLoading} onChange={(event) => {
                    const value = actualValueForDropdown(event);
                    if (value) {
                        dispatch(listAllLocalities({ cityId, districtId: value, stateId }));
                    } else {
                        setValue('fk_locality_id', null);
                        dispatch(clearLocalityListFromStore());
                    }
                }} className='w-6/12' />
                <Select name={`fk_locality_id`} label='Locality' options={localityList} required isLoading={isLocalityListLoading} className='w-6/12' />
            </div>
        </div>
    );
};
