import React, { useState, useEffect, useMemo } from 'react';
import FormTable from '../../../../common/Form/Table';
import Select from '../../../../common/Form/Select';
import Input from '../../../../common/Form/Input';
import { useSelector, useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import Checkbox from '../../../../common/Form/Checkbox';
import { validateNumberValue } from '../../../../utils/common';
import moment from 'moment';
import DatePicker from '../../../../common/Form/DatePicker';
import { getWalletTransactionsDetails } from '../../../../actions/wallet';
import IconToolTip from '../../../../common/IconToolTip';
import { ActionButton } from '../PayPO';
import { isCustomerGroup } from '../../../../utils/role';
import { PayBankPopup, payPoFormname } from '../PayPO/BankPopup';
import { PayManualPopup } from '../PayPO/ManualPopup';
import { WalletPopup } from '../PayPO/WalletPopup';

export const UpdatePOTable = ({ vendors, arrayName, ticket, poCategoryOptions, assetTypeOptions, isPayPoEligible }) => {
    const { boqList } = useSelector((state) => state.tickets);
    const { getValues } = useFormContext();
    const [totalRowPrice, setTotalRowPrice] = useState({});
    const [isRecurringSelected, setIsRecurringSelected] = useState({});
    const ticketId = ticket?.ticketId || ticket?.ticket_id;
    const [activeCopyItemId, setActiveCopyItemId] = useState(undefined);
    const { copyTxnDetailsLoading } = useSelector((state) => state.loading);

    useEffect(() => {
        if (!copyTxnDetailsLoading) {
            setActiveCopyItemId(undefined);
        }
        return () => {
            if (!copyTxnDetailsLoading && !['', undefined, null].includes(activeCopyItemId)) {
                setActiveCopyItemId(undefined);
            }
        };
    }, []);


    const schema = useMemo(() => {
        return {
            table: [
                {
                    head: {
                        thClassName: `pl-5 w-48`,
                        headName: 'Asset Type',
                        required: true,
                    },
                    body: {
                        bodyClassName: `truncate w-48`,
                        render: (quote, { rowIndex }) => {
                            if (quote.isEditable) {
                                return <Select name={`${arrayName}[${rowIndex}].assetType`} required options={assetTypeOptions} isLoading={!boqList} />;
                            } else {
                                return <div className='pl-5'>{quote?.assetName}</div>;
                            }
                        },
                    },
                },
                {
                    head: {
                        thClassName: `w-36`,
                        headName: 'Category',
                        required: true,
                    },
                    body: {
                        bodyClassName: `truncate w-36`,
                        render: (quote, { rowIndex }) => {
                            if (quote.isEditable) {
                                return (
                                    <Select
                                        name={`${arrayName}[${rowIndex}].category`}
                                        required
                                        options={poCategoryOptions}

                                        isLoading={!poCategoryOptions}
                                    />
                                );
                            } else {
                                return <div>{quote?.category?.label}</div>;
                            }
                        },
                    },
                },
                {
                    head: {
                        thClassName: `w-36`,
                        headName: 'Description',
                    },
                    body: {
                        bodyClassName: `truncate w-36`,
                        render: (quote, { rowIndex }) => {
                            if (quote.isEditable) {
                                return <Input type='text' name={`${arrayName}[${rowIndex}].description`} placeholder='Description' />;
                            } else {
                                return <div>{quote?.description}</div>;
                            }
                        },
                    },
                },
                {
                    head: {
                        thClassName: `w-36`,
                        headName: 'Recurring',
                    },
                    body: {
                        bodyClassName: `truncate w-36`,
                        render: (quote, { rowIndex }) => {
                            if (quote.isEditable) {
                                return (
                                    <Checkbox
                                        type='checkbox'
                                        name={`${arrayName}[${rowIndex}].isRecurring`}
                                        onChange={(event) => {
                                            setIsRecurringSelected((prevState) => ({ ...prevState, [rowIndex]: event.target.checked }));
                                        }}
                                    />
                                );
                            } else {
                                return <div>{quote?.isRecurring ? 'Yes' : 'No'}</div>;
                            }
                        },
                    },
                },
                {
                    head: {
                        thClassName: `w-36`,
                        headName: 'End Date',
                    },
                    body: {
                        bodyClassName: `truncate w-36`,
                        render: (quote, { rowIndex }) => {
                            if (quote.isEditable) {
                                return <DatePicker name={`${arrayName}[${rowIndex}].endDate`} placeholder='End Date' readOnly={!isRecurringSelected[rowIndex]} />;
                            } else {
                                return <div>{quote?.endDate && moment(quote?.endDate).format('DD MMM YYYY')}</div>;
                            }
                        },
                    },
                },
                {
                    head: {
                        thClassName: `flex-1 w-28`,
                        headName: 'Quantity',
                        required: true,
                    },
                    body: {
                        bodyClassName: `flex-1 truncate w-28`,
                        render: (quote, { rowIndex }) => {
                            const isNotPaid = quote?.status !== 'PAID';
                            let isEditable = quote.isEditable || isNotPaid;
                            if (isEditable) {
                                return (
                                    <Input
                                        type='number'
                                        name={`${arrayName}[${rowIndex}].quantity`}
                                        required
                                        placeholder='Quantity'
                                        onChange={() => {
                                            const formValues = getValues();
                                            const currentRowValues = formValues[arrayName][rowIndex];
                                            let quantity = 1;
                                            if (validateNumberValue(currentRowValues.quantity) > 0) quantity = validateNumberValue(currentRowValues.quantity);
                                            let totalPrice = 0;
                                            currentRowValues.vendors.forEach((user) => {
                                                let price = validateNumberValue(user.price);
                                                totalPrice += price * quantity;
                                            });
                                            setTotalRowPrice((prevState) => ({
                                                ...prevState,
                                                [quote.id]: totalPrice,
                                            }));
                                        }}
                                    />
                                );
                            }
                            return <div className=''>{quote?.quantity}</div>;
                        },
                    },
                },
                ...vendors.map((vendor) => {
                    return {
                        head: {
                            thClassName: `flex-1 w-36`,
                            headName: `${vendor.vendorName} Price`,
                            required: true,
                            isVendor: true,
                            vendor,
                        },
                        body: {
                            bodyClassName: `flex-1 truncate w-36`,
                            render: (quote, { rowIndex }) => {
                                const vendorIdx = quote.vendors.findIndex((quoteVendor) => quoteVendor.vendorId === vendor.vendorId);
                                let quoteVendor = quote.vendors[vendorIdx];
                                if (vendorIdx === -1) {
                                    quoteVendor = { vendorId: vendor.vendorId };
                                }
                                const isNotPaid = quote?.status !== 'PAID';
                                let isEditable = quote.isEditable || isNotPaid;
                                if (isEditable) {
                                    return (
                                        <Input
                                            type='number'
                                            step='any'
                                            name={`${arrayName}[${rowIndex}].vendors[${vendorIdx}].price`}
                                            required
                                            onChange={(event) => {
                                                const formValues = getValues();
                                                const currentRowValues = formValues[arrayName][rowIndex];
                                                let quantity = 1;
                                                if (validateNumberValue(currentRowValues.quantity) > 0) quantity = validateNumberValue(currentRowValues.quantity);
                                                let totalPrice = 0;
                                                currentRowValues.vendors.forEach((user) => {
                                                    let price = 1;
                                                    if (validateNumberValue(user.price)) price = validateNumberValue(user.price);
                                                    totalPrice += price * quantity;
                                                });
                                                setTotalRowPrice((prevState) => ({
                                                    ...prevState,
                                                    [quote.id]: totalPrice,
                                                }));
                                            }}
                                        />
                                    );
                                }
                                return <div className=''>{quoteVendor.price}</div>;
                            },
                        },
                    };
                }),

                {
                    head: {
                        thClassName: `w-36`,
                        headName: 'Total Price',
                    },
                    body: {
                        bodyClassName: `truncate w-36`,
                        render: (quote, { rowIndex }) => {
                            let price = totalRowPrice && totalRowPrice?.[quote.id];
                            if (!price) price = quote.totalPrice;
                            return <div>{price}</div>;
                        },
                    },
                },
                {
                    head: {
                        thClassName: `w-80`,
                        headName: 'Status/Actions',
                    },
                    body: {
                        bodyClassName: `truncate w-80`,
                        render: (quote, { rowIndex, remove }) => {
                            let deleteIcon = <></>;
                            let poAction = <></>;
                            if (isPayPoEligible) {
                                poAction = <RenderPayPoAction data={quote} ticketId={ticketId} customer={ticket.customer} setActiveCopyItemId={setActiveCopyItemId} activeCopyItemId={activeCopyItemId} />
                            }
                            if (quote.isDeletable) {
                                deleteIcon = (
                                    <span
                                        onClick={(e) => {
                                            e.preventDefault();
                                            remove(rowIndex);
                                        }}
                                        className={`select-none material-icons text-scogoToastDangerIcon cursor-pointer hover:text-scogoprimary`}
                                    >
                                        delete
                                    </span>
                                );
                            }
                            return <div className='flex items-center'>
                                {poAction}
                                {deleteIcon}
                            </div>;
                        },
                    },
                }
            ],
        };
    }, [
        vendors,
        assetTypeOptions,
        poCategoryOptions,
        boqList,
        arrayName,
        totalRowPrice,
        getValues,
        isRecurringSelected,
        ticket?.customer,
        ticketId,
        isPayPoEligible,
        activeCopyItemId
    ]);

    return (
        <>
            <FormTable
                schema={schema}
                addButtonClassName='my-2'
                name={arrayName}
                rowGenerator={() => ({
                    id: `${Math.random()}`,
                    isEditable: true,
                    isDeletable: true,
                    vendors: vendors.map((vendor) => {
                        return { vendorName: vendor.vendorName, price: '', vendorId: vendor.vendorId, attachments: [] };
                    }),
                })}
                scrollToBottomOnAddRow
            />
            <TotalCard schema={schema} arrayName={arrayName} />
        </>
    );
};

const TotalCard = ({ schema, arrayName }) => {
    const { watch, setValue } = useFormContext();
    const [totalValues, setTotalValues] = useState({});

    useEffect(() => {
        const { unsubscribe } = watch((values) => {
            let assets = values?.[arrayName];
            if (Array.isArray(assets)) {
                let quantity = 0;
                let vendorPrice = {};
                assets.forEach((asset) => {
                    quantity += validateNumberValue(asset.quantity);
                    let vendors = asset.vendors;
                    vendors.forEach((vendor) => {
                        const vendorId = vendor.vendorId;
                        if (vendorPrice[vendorId] === undefined) {
                            vendorPrice[vendorId] = asset.quantity * validateNumberValue(vendor.price);
                        } else {
                            vendorPrice[vendorId] += asset.quantity * validateNumberValue(vendor.price);
                        }
                    });
                });
                setTotalValues((prevState) => ({ ...prevState, quantity, price: vendorPrice }));
            }
        });
        return () => unsubscribe();
    }, [watch, setValue, arrayName]);

    return (
        <div className='flex w-full'>
            {schema.table.map(({ head }, { rowIndex }) => {
                let elem = <></>;
                if (head.headName === 'Quantity') {
                    elem = (
                        <IconToolTip title={`Total Quantity: ${totalValues.quantity}`}>
                            <span>{totalValues.quantity}</span>
                        </IconToolTip>
                    );
                } else if (head.isVendor) {
                    const vendorId = head.vendor.vendorId;
                    elem = (
                        <IconToolTip title={`Total Price: ${validateNumberValue(totalValues?.price?.[vendorId])}`}>
                            <span>{validateNumberValue(totalValues?.price?.[vendorId])}</span>
                        </IconToolTip>
                    );
                }
                return (
                    <div key={rowIndex} className={`${head.thClassName} pl-7 pt-3 flex-1 border-t font-bold text-xl`}>
                        {elem}
                    </div>
                );
            })}
        </div>
    );
};

const RenderPayPoAction = ({ data, ticketId, isRefresh = false, customer, activeCopyItemId, setActiveCopyItemId, enduserId }) => {
    const dispatch = useDispatch();
    const { loggedUser } = useSelector((state) => state.auth);
    const { walletDetailsById } = useSelector((state) => state.wallet);
    const { formButtonLoading, copyTxnDetailsLoading } = useSelector((state) => state.loading);
    const { bankDetails } = useSelector((state) => state.auth);
    const scogoBankAccountBalance = validateNumberValue(walletDetailsById?.scogo_bankopen_account_balance);
    const role = loggedUser.role.id;
    const type = loggedUser.type;
    const lookup = {
        PAID: { label: 'PAID', color: 'scogogoing' },
        INITIATED: { label: 'INITIATED', color: 'scogoorange' },
        AWAITING: { label: 'AWAITING', color: 'scogoorange' },
        PROCESSING: { label: 'PROCESSING', color: 'scogoorange' },
    };
    let status = lookup?.[data.status];

    const getClipboardContent = ({ itemId, transactions }) => {
        const payload = {
            scogoTransactions: transactions,
            isPoItem: true,
            customerId: customer,
        };
        setActiveCopyItemId(itemId);
        dispatch(getWalletTransactionsDetails({ data: payload, copyToClipboard: true }));
    };



    if (status) {
        return (
            <>
                <div className='flex flex-row'>
                    <p className={`bg-${status.color} scogoprimary max-w-max text-white text-font10 px-p-6 py-p-3 flex items-center rounded-3px mr-2 mb-1`}>
                        {status.label}
                        {status.label === 'PAID' && data?.transactions?.length > 0 ? (
                            <>
                                &nbsp;&nbsp;
                                <span
                                    onClick={(e) => {
                                        e.preventDefault();
                                        getClipboardContent({ transactions: data?.transactions, itemId: data?.itemId });
                                    }}
                                    className={`material-icons text-base text-scogogray cursor-pointer hover:text-scogogray`}
                                >
                                    content_copy
                                </span>
                                {activeCopyItemId && copyTxnDetailsLoading && activeCopyItemId === data?.itemId ? (
                                    <div className='animate-spin w-4 h-4 border-t-2 border-scogoprimary rounded-full' />
                                ) : (
                                    <></>
                                )}
                            </>
                        ) : (
                            <></>
                        )}
                    </p>
                    {status.label === 'PAID' && !['', undefined, null].includes(data?.channel) ? (
                        <>
                            <p className={`bg-scogogoing scogoprimary max-w-max text-white text-font10 px-p-6 py-p-3 flex items-center rounded-3px mr-2 mb-1`}>
                                {data?.channel === 'BANK_TRANSFER' ? 'BANK TRANSFER' : data?.channel}
                            </p>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </>
        );
    }


    const po = {
        _id: data.itemId,
        ticketId,
        totalPrice: data.totalPrice,
        vendorId: data.vendorId
    };

    const disableButtons = formButtonLoading[payPoFormname];
    if (data.status === 'UNPAID') {
        return <div className='flex items-center gap-2 relative'>
            <ActionButton disabled={isCustomerGroup(role, type) || disableButtons} buttonName='Wallet' popup={<WalletPopup poItem={po} isRefresh={isRefresh} enduserId={enduserId} />} />
            <ActionButton disabled={isCustomerGroup(role, type) || (scogoBankAccountBalance < 400 && bankDetails) || disableButtons} buttonName='Bank' popup={<PayBankPopup poItem={po} isRefresh={isRefresh} enduserId={enduserId} />} />
            <ActionButton disabled={disableButtons} buttonName='Manually' popup={<PayManualPopup poItem={po} isRefresh={isRefresh} enduserId={enduserId} />} />
        </div>
    }
    return <></>
}