import React from 'react';
import { useDispatch } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import moment from 'moment';
import { updateInventoryAsset } from '../../../actions/inventory';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Form from '../../../common/Form';
import Input from '../../../common/Form/Input';
import DatePicker from '../../../common/Form/DatePicker';

const getFYEnd = () => {
    const today = new Date();
    let month = today.getMonth();
    let year = today.getFullYear();
    if (month > 2) {
        // more than march
        year = year + 1;
    }
    let endDate = new Date(year, 2, 31, 0, 0, 0);
    return endDate;
};

const formName = 'approveRmaSupportForm';

const ApproveRmaSupport = ({ modalData, customerId, siteId, rmaEligibility }) => {
    const dispatch = useDispatch();
    const { assetslist } = modalData && modalData;
    const financialYearEnd = getFYEnd();
    const { formButtonLoading } = useSelector((state) => state.loading);

    const defaultValues = {
        rma_start_date: '',
        backlining: '',
        rma_end_date: financialYearEnd,
    };

    const submit = (form) => {
        let assets = [];
        if (rmaEligibility === 'RMA_ELIGIBILITY_REQUEST') {
            assets = assetslist.filter((i) => i.is_rma_eligible === 0).map((i) => i.id);
        } else if (rmaEligibility === 'RMA_ELIGIBILITY_APPROVE') {
            assets = assetslist.filter((i) => i.is_rma_eligible !== 2).map((i) => i.id);
        }
        if (assets.length > 0) {
            let data = { property: rmaEligibility, assets };
            if (rmaEligibility === 'RMA_ELIGIBILITY_APPROVE') {
                data.rma_start_date = form.rma_start_date ? moment(form.rma_start_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
                data.rma_end_date = form.rma_end_date ? moment(form.rma_end_date).format('YYYY-MM-DD') : moment(financialYearEnd).format('YYYY-MM-DD');
                data.backlining = form.backlining ? form.backlining : moment().format('YYYY-MM-DD');
            }
            dispatch(updateInventoryAsset({ data, assetId: 0, siteId, customerId, formName }));
        } else {
            if (rmaEligibility === 'RMA_ELIGIBILITY_REQUEST') {
                alert('Selected Asset(s) are approved or already requested');
            } else if (rmaEligibility === 'RMA_ELIGIBILITY_APPROVE') {
                alert('Selected Asset(s) are already approved');
            }
        }
    };

    const closeModal = () => {
        dispatch(closeModalAction());
    };

    const methods = useForm({ defaultValues, mode: 'all' });

    return (
        <>
            <Form
                className='w-full'
                onSubmit={submit}
                onCancel={closeModal}
                defaultValues={defaultValues}
                methods={methods}
                submitButtonText={'Update'}
                showSubmit
                submitContainerClassName='flex gap-2 justify-end py-4 px-4'
                isLoading={formButtonLoading[formName]}
            >
                <div>
                    <div className='grid grid-cols-3 rows-fr grid-flow-row-dense'>
                        <DatePicker label='Start Date' dateFormat={'dd-MM-yyyy'} name={`rma_start_date`} />
                        <DatePicker label='End Date' dateFormat={'dd-MM-yyyy'} name={`rma_end_date`} />
                        <DatePicker label='Backlining' dateFormat={'dd-MM-yyyy'} name={`backlining`} />
                    </div>
                </div>
            </Form>
        </>
    );
};

export default ApproveRmaSupport;
