import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModalAction } from '../../actions/modal';


const SmallCustomModal = (props) => {
    const dispatch = useDispatch();
    const { modalComponent, heading } = useSelector(state => state.modal)
    const closeModal = () => { dispatch(closeModalAction()) }
    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-3/10 mb-6  mt-12 mx-auto max-w-6xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-center justify-between py-5 px-7 border-b  border-scogoddd rounded-10px w-full">
                            <h3 className="text-font24 font-medium text-scogo15">
                                {heading}
                            </h3>
                            <div className="ml-auto cursor-pointer" onClick={() => { closeModal() }} >
                                <img src={process.env.PUBLIC_URL + '/img/close.svg'} alt="" />
                            </div>
                        </div>
                        <div style={{'height':'12rem'}}>
                            <div className='h-full  overflow-y-auto'>
                                {modalComponent && modalComponent}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}

export default SmallCustomModal