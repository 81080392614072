import React from "react";
import { Button } from "@mui/material";

const SomethingWentWrong = () => {
    return (
        <div  >
            <div className="flex w-full" style={{ justifyContent: "center" }}>
                <img src={process.env.PUBLIC_URL + '/img/something-went-wrong-img.png'} alt="Something went Wrong" />
            </div>
            <div className="flex w-full" style={{ justifyContent: "center", margin: "70px 0px" }}>
                <h1 style={{ font: "normal normal bold 48px/24px Roboto", color: "#12355F" }}>SOMETHING WENT WRONG</h1>
            </div>
            <div className="flex w-full" style={{ justifyContent: "center" }}>
                <Button style={{ background: "#F48A21", color: "#FFFFFF", font: "normal normal medium 16px/19px Roboto", borderRadius: "6px" }} onClick={() => window.location.reload()}>Please try again</Button>
            </div>
        </div>
    )
}

export default SomethingWentWrong