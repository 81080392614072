import React, { useRef } from 'react';
import Loader from './Loader';
import NoResultFound from './NoResultFound';
import { buttonClass, tableCss } from './Css';
import Input from '../pages/Inventory/reusableComponents/AddOption/Input';
import Buttons from './Buttons';

export default function formTable(props) {
    const {
        arrayName,
        isLoading,
        form,
        setForm,
        schema,
        trClassNames,
        tableError,
        tableStyle,
        tableBodyStyle,
        checkRowShouldHighlight = () => {},
        rowKey = 'id',
        rowGenerator = () => ({ id: `${Math.random()}` }),
        allowAdd = false,
        addButtonClass = `hover:bg-scogoorange hover:text-white bg-white mt-2 border border-scogoorange px-2 leading-6 cursor-pointer text-scogoorange font-normal rounded-lg gap-1 font-medium cursor-pointer px-6`,
        tableBodyRef,
        showNoResultError = true,
        formTableClass = `shadow-lg tableContainer rounded-lg nonPaginatedTable`,
        loaderMargin = '150px',
        rowGeneratorButtonName = 'Add',
    } = props;
    const rowData = form[arrayName];

    const cells = (rowValue, rowIndex) => {
        return schema.table?.map(({ head, body }, index) => {
            return (
                <td style={body?.style} className={body?.bodyClassName + ` min-h-2 ${head?.hide ? 'hidden' : ''}`} key={index}>
                    {body.input ? (
                        <Input
                            type={body.type}
                            name={`${arrayName}[${rowIndex}].${body.name}`}
                            placeholder={body.placeholder}
                            required={head.required}
                            allowFloats={body.allowFloats}
                            {...(body.props ? body.props : {})}
                        />
                    ) : (
                        body?.render(rowValue, rowIndex)
                    )}
                </td>
            );
        });
    };

    const rows = () => {
        if (isLoading)
            return (
                <div className='border border-white'>
                    <Loader color='#F48A21' size='55' speed='1' thickness='3' margin={loaderMargin} />
                </div>
            );
        else if (showNoResultError && rowData?.length === 0) return <NoResultFound message={tableError} />;
        else if (!rowData) return <></>;
        return rowData?.map((rowValue, index) => {
            const rowIsHighlighted = checkRowShouldHighlight(rowValue);
            return (
                <div key={rowValue[rowKey]} className={`border-b border-scogoddd	py-2  ${rowIsHighlighted ? 'bg-scogoddd' : 'hover:bg-scogof5'}`}>
                    <tr className={`flex items-center md:flex w-full grid  items-start Content justify-between ${trClassNames}  ${tableCss?.tBodyText}`}>
                        {cells(rowValue, index)}
                    </tr>
                </div>
            );
        });
    };

    return (
        <>
            <div style={{ ...tableStyle }} className={formTableClass}>
                <table className='block rounded-lg formTable' style={{ height: 'inherit' }}>
                    <thead className={`md:flex tableHeader hidden  p-3 z-30 border-b-2 justify-between block items-center`}>
                        {schema.table.map(({ head }) => {
                            return (
                                <th style={head?.style} key={head?.headName} className={`${head?.thClassName} ${tableCss?.theadText} ${head?.hide ? 'hidden' : ''}`}>
                                    {head?.render ? head.render(head?.headName) : head?.headName} {head?.required && <span className='text-scogoorange'>*</span>}
                                </th>
                            );
                        })}
                    </thead>

                    <tbody ref={tableBodyRef} style={{ ...tableBodyStyle }} className={` tableBody  block  2xl:text-font13 text-font11 text-scogo15 font-medium`}>
                        {rows()}
                    </tbody>
                </table>
            </div>

            {allowAdd && (
                <Buttons
                    buttonClass={addButtonClass}
                    onClick={(e) => {
                        e.preventDefault();
                        setForm((prevForm) => {
                            const afterAdd = prevForm?.[arrayName]?.concat([rowGenerator()]);
                            return { ...prevForm, [arrayName]: afterAdd };
                        });
                    }}
                >
                    {rowGeneratorButtonName}
                </Buttons>
            )}
        </>
    );
}
