import React from 'react';
import moment from 'moment';
import OverflowTip from '../common/OverflowTip';

export default function NotificationCard({ notification, onClick, unreadNotificationStyle }) {

    return (
        <div
            className={'w-full border-b-2 flex items-center px-4 truncate'}
            style={{
                backgroundColor: notification?.viewed_time ? '#ffff' : unreadNotificationStyle,
                cursor: notification?.viewed_time ? 'default' : 'pointer',
                marginTop: '2px',
            }}
            onClick={() => onClick(notification.id)}
        >
            <div className='w-2/12'>
                {notification?.icon && (
                    <img className='h-12 w-12' src={notification?.icon} alt='' />
                )}
            </div>
            <div className='w-10/12 py-1'>
                <p className='font-medium truncate text-font11 '><OverflowTip textClass={'h-6 w-full'} whiteSpace={'initial'} someLongText={notification?.notification_title}></OverflowTip></p>
                <p className='font-light truncate text-font10'><OverflowTip textClass={'h-14 truncate w-full'} whiteSpace={'initial'} someLongText={notification?.notification_message}></OverflowTip></p>

                <p className='flex justify-end font-light pt-1'>
                    {moment(notification.created_at).fromNow()}
                </p>
            </div>
        </div>
    );
}