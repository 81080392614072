import { fork, takeEvery, put } from 'redux-saga/effects';

import { inventory } from '../../types';

import siteSaga from './site';
import assetsSaga from './asset';
import warehouseSaga from './warehouse';
import spareAssetsSaga from './spareAsset';

export function* removeFromInventoryStore(action) {
    const { type } = action.payload
    yield put({ type: inventory[type], payload: undefined })
}

export default function* inventorySaga() {
    yield takeEvery(inventory.REMOVE_FROM_INVENTORY_STORE_REQUEST, removeFromInventoryStore)

    yield fork(siteSaga)
    yield fork(assetsSaga)
    yield fork(warehouseSaga)
    yield fork(spareAssetsSaga)
}
