// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging';
import apis from './apis';
import { getDatabase } from 'firebase/database';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyC9yWIrg8RvmpEKh4DPQalCtf4pywXAwLQ',
    authDomain: 'scogonetworks.firebaseapp.com',
    databaseURL: 'https://scogonetworks.firebaseio.com',
    projectId: 'scogonetworks',
    storageBucket: 'scogonetworks.appspot.com',
    messagingSenderId: '816191872672',
    appId: '1:816191872672:web:3c85fe72bd64b2d66f73c5',
    measurementId: 'G-3F57SV8JE2',
};

// Initialize Firebase
export const initializeFirebaseApp = () => {
    isSupported().then((isBrowserSupported) => {
        if (isBrowserSupported) {
            const firebaseApp = initializeApp(firebaseConfig);
            const messaging = getMessaging(firebaseApp);
            getToken(messaging, { vapidKey: 'BJulImt0u75bM8gr2Ieqee9gtbsOb8whQqY1bcEG9A6DABZJA5bFlYh_i0kWHZRTEMPkGjUkb8lFmAu4R5IOeVo' })
                .then((currentToken) => {
                    if (currentToken) {
                        // Send the token to your server and update the UI if necessary
                        if (localStorage.getItem('__scogo_notifications') !== currentToken) {
                            apis.fcm.mapUser({}, {}, { fcm_app_id: currentToken, type: 0, mobile_app: 0 }).then((response) => {
                                if (response.data.code === 201) {
                                    localStorage.setItem('__scogo_notifications', currentToken);
                                } else {
                                    localStorage.removeItem('__scogo_notifications');
                                }
                            });
                        }
                    } else {
                        // Show permission request UI
                        requestPermission();
                    }
                })
                .catch((err) => {
                    requestPermission();
                });

            onMessage(messaging, (payload) => {
                console.log('[onMessage] payload', payload);
            });
        }
    });
};
function requestPermission() {
    Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
        }
    });
}


const firebaseApp = initializeApp(firebaseConfig);
export const firebaseDb = getDatabase(firebaseApp);