import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { payPO } from '../../../../actions/wallet';
import ButtonScogoPrimary from '../../../../common/Buttons/ButtonScogoPrimary';
import ButtonScogoClosedOutlined from '../../../../common/Buttons/ButtonScogoClosedOutlined';
import { payPoFormname } from './BankPopup';

export const WalletPopup = ({ poItem, closeMenu = () => { }, isRefresh, enduserId }) => {
    const dispatch = useDispatch();
    const { formButtonLoading } = useSelector((state) => state.loading);

    const handlePayToWallet = (poItem) => {
        const data = {
            type: 'PAY_PO_ITEM',
            vendorId: poItem.vendorId,
            ticketId: poItem.ticketId,
            itemId: poItem._id,
            withdrawAmount: poItem.totalPrice,
        };
        dispatch(payPO({ data, formName: payPoFormname, isRefresh, callViewTicket: true, onSuccess: closeMenu, enduserId }));
    };

    return (
        <div className='py-2 px-2 rounded-lg'>
            <p className='text-scogogray text-font16'>Are you sure you want to pay to wallet?</p>
            <div className='gap-2 flex items-center justify-center mt-8'>
                <ButtonScogoPrimary textOrComponent='Yes' onClick={() => handlePayToWallet(poItem)} loading={formButtonLoading?.[payPoFormname]} />
                <ButtonScogoClosedOutlined textOrComponent='No' onClick={closeMenu} />
            </div>
        </div>
    );
};
