import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { closeModalAction } from '../../actions/modal';
import { useDispatch } from 'react-redux';

const FullScreenModal = () => {
    const { fullScreenModalComponent } = useSelector((state) => state.modal);
    const dispatch = useDispatch()

    const mediaViewRef = useRef(null)

    const closeMedia = (event) => {
        if (event.keyCode === 27 && event.key === "Escape") {
            dispatch(closeModalAction());
        }
    }

    useEffect(() => {
        if (mediaViewRef?.current) {
            mediaViewRef.current.focus();
        }
    }, []);

    return (
        <>
            <div className='fullscreen-position relative overflow-x-hidden overflow-y-hidden inset-0 z-30 outline-none bg-white shadow-lg border-0' ref={mediaViewRef} tabIndex={-1} onKeyDown={closeMedia} >
                <div className='fullscreen-modal overflow-y-auto'>{fullScreenModalComponent && fullScreenModalComponent}</div>
            </div>
        </>
    );
};

export default FullScreenModal;
