import React from 'react';
import Loader from './Loader';
import NoResultFound from './NoResultFound';
import Paginate from './Paginate';
import { tableCss } from './Css';
import { SkeltonCardTwo } from './SkeltonCardTwo';

export default function GridTable(props) {
    const {
        isLoading,
        tablePagination,
        rowData: rowsData,
        schema,
        tableError,
        setRowsPerPage = () => { },
        rowsPerPage,
        checkRowShouldHighlight = () => { },
        page,
        rowKey = 'id',
        tableRowHeight = '',
        columnsGap = 'gap-x-2',
        tableHeadClass = '',
        tableBodyHeight = "",
        loadingMargin = "150px",
        onTableScroll,
        isNextTableDataLoading,
        isGroupView = false
    } = props;

    const gridTemplateColumns = schema.table
        .filter((column) => !column.head.hide)
        .map((column) => {
            return (column.head.width ? column.head.width : 1) + 'fr';
        })
        .join(' ');

    const rows = () => {
        if (isLoading && isGroupView) {
            return <div className='px-4'><SkeltonCardTwo arr={[1, 2, 3, 4]} height={20} /></div>;
        } else if (isLoading) {
            return (
                <div className='border border-white'>
                    <Loader color='#F48A21' size='65' speed='1' thickness='3' margin={loadingMargin} />
                </div>
            );
        }
        else if (rowsData?.length === 0) return <NoResultFound message={tableError} />;
        return (
            <TableBody
                rowsData={rowsData}
                checkRowShouldHighlight={checkRowShouldHighlight}
                schema={schema}
                rowKey={rowKey}
                tableRowHeight={tableRowHeight}
                gridTemplateColumns={gridTemplateColumns}
                columnsGap={columnsGap}
                tableBodyHeight={tableBodyHeight}
                onTableScroll={onTableScroll}
                isNextTableDataLoading={isNextTableDataLoading}
            />
        );
    };

    return (
        <>
            <div className={`shadow-lg tableContainer rounded-lg ${tablePagination ? 'paginatedTable' : 'nonPaginatedTable'}`}>
                <table className='block rounded-lg' style={{ height: 'inherit' }}>
                    <TableHead schema={schema} gridTemplateColumns={gridTemplateColumns} columnsGap={columnsGap} tableHeadClass={tableHeadClass} />

                    {rows()}
                </table>
            </div>
            {tablePagination && (
                <Paginate
                    count={props?.count}
                    onPageChange={props.tablePaginationHandler}
                    entriesPerPage={rowsPerPage ? rowsPerPage : 0}
                    setRowsPerPage={setRowsPerPage}
                    page={page}
                />
            )}
        </>
    );
}

const TableHead = (props) => {
    const { schema, gridTemplateColumns, columnsGap, tableHeadClass } = props;

    return (
        <thead className={` border-b-2  py-1 grid ${columnsGap} ${tableHeadClass}`} style={{ gridTemplateColumns }}>
            {schema.table.map(({ head }) => {
                return (
                    <th key={head.headName} className={`truncate text-left font-medium text-scogogray ${head.hide ? 'hidden' : ''} pl-2`}>
                        <div className='flex items-center py-1 text-font13'>{head.render(head.headName)}</div>
                    </th>
                );
            })}
        </thead>
    );
};

const TableBody = (props) => {
    const { rowsData, checkRowShouldHighlight, schema, rowKey, gridTemplateColumns, columnsGap, tableBodyHeight, onTableScroll = () => { }, isNextTableDataLoading } = props;
    const trClassName = (rowIsHighlighted) =>
        `pl-2 border-b border-scogoddd py-2 ${rowIsHighlighted ? 'bg-scogoddd' : 'hover:bg-scogof5'}  ${tableCss.tBodyText} items-center grid ${columnsGap}`;

    const cell = (rowValue, rowIndex) => {
        return schema.table?.map(({ head, body }) => {
            const columnRowCss = head.columnRowCss ? head.columnRowCss : 'truncate';
            return (
                <td className={`${columnRowCss} w-full ${head.hide ? 'hidden' : ''}`} key={head.headName}>
                    {body.render(rowValue, rowIndex)}
                </td>
            );
        });
    };

    return (
        <tbody className={`tableBody block text-font11 bg-white text-scogo15 font-medium ${tableBodyHeight}`} onScroll={onTableScroll}>
            {Array.isArray(rowsData) &&
                rowsData?.map((rowValue, index) => {
                    const rowIsHighlighted = checkRowShouldHighlight(rowValue);
                    return (
                        <tr key={rowValue[rowKey]} className={trClassName(rowIsHighlighted)} style={{ gridTemplateColumns }}>
                            {cell(rowValue, index)}
                        </tr>
                    );
                })}
            <div className='absolute bottom-5 left-0 right-0 z-50'>
                {isNextTableDataLoading && <Loader color='#F48A21' size='25' speed='1' thickness='3' />}
            </div>
        </tbody>
    );
};
