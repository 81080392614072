import { chatSidebarTab } from '../layout/sidebar';
import { conversations, partners, user, utils } from '../types';
import { adminInviteUserForm, getBankDetailsForm } from '../utils/form';
import { CLUSTER, PSP } from '../utils/role';

export const getServiceTypesList = () => ({
    type: user.SERVICETYPE_LIST_REQUEST,
});

export const getAllUsecases = () => ({
    type: user.USECASES_LIST_REQUEST,
});

export const getUserProfileDetails = ({ userId, from }) => ({
    type: user.GET_USER_PROFILE_DETAILS,
    payload: { userId, from },
});

export const getCourierVendors = () => ({
    type: user.COURIER_VENDORS_LIST_REQUEST,
});

export const getNotification = ({ offset }) => ({
    type: user.GET_NOTIFICATION_REQUEST,
    payload: { offset },
});

export const getNextNotification = ({ offset }) => ({
    type: user.GET_NEXT_NOTIFICATION_REQUEST,
    payload: { offset },
});

export const readNotification = (notificationId) => ({
    type: user.READ_NOTIFICATION_REQUEST,
    payload: { notificationId },
});

export const updateUserStatus = ({ userId, status, role, userType, servicePartnerId, customer, formName, fk_cluster_id, disabled_duration, msg }) => ({
    type: user.UPDATE_USER_STATUS_REQUEST,
    payload: { userId, status, role, userType, servicePartnerId, customer, formName, fk_cluster_id, disabled_duration, msg },
});

export const updatePartnersProfile = ({ data, role, userType, customer, customerId, formName, tabName, fk_cluster_id, address_proof, address_proof_back, callDetails }) => ({
    type: user.UPDATE_PARTNER_PROFILE_REQUEST,
    payload: { data, role, userType, customerName: customer, customerId, formName, tabName, fk_cluster_id, address_proof, address_proof_back, callDetails },
});

export const promoteUser = ({ userId, data, role, userType, customer, formName }) => ({
    type: user.PROMOTE_USER_REQUEST,
    payload: { userId, data, role, userType, formName, customer },
});

export const demoteUser = ({ userId, data, role, userType, customer, formName }) => ({
    type: user.DEMOTE_USER_REQUEST,
    payload: { userId, data, role, userType, formName, customer },
});

export const getAllStates = () => ({ type: user.STATES_LIST_REQUEST });

export const getAllCities = () => ({ type: user.CITIES_LIST_REQUEST });

export const getAllExistingCities = (servicePartnerId) => ({
    type: user.EXISTING_CITIES_LIST_REQUEST,
    payload: { servicePartnerId },
});

export const updateServiceLocations = ({ data, servicePartnerId, formName, tabName, callApiForUpdateData = true, closedModal, updatedCities, userIds, status, roles }) => {
    let form = new FormData();
    data.add_service_location.map((item, index) => {
        return form.append(`sp_city_ids[${index}]`, item);
    });
    return {
        type: user.UPDATE_SERVICE_LOCATIONS_REQUEST,
        payload: { form, servicePartnerId, formName, tabName, callApiForUpdateData, closedModal, updatedCities, userIds, status, roles },
    };
};

export const updateUserTerritories = ({ data, role, userType, customer, formName }) => ({
    type: user.UPDATE_USER_TERRITORIES_REQUEST,
    payload: { data, role, userType, customer, formName },
});

export const removeUserTerritories = ({ data, role, userType, customer, formName }) => ({
    type: user.REMOVE_USER_TERRITORIES_REQUEST,
    payload: { data, role, userType, customer, formName },
});

export const getSPProfileDetails = ({ id }) => ({
    type: user.GET_SP_PROFILE_DETAILS_REQUEST,
    payload: { id },
});

export const getFeProfileDetails = ({ id }) => ({
    type: user.GET_FE_PROFILE_DETAILS_REQUEST,
    payload: { id },
});

export const clearProfileDetailsFromStore = () => ({
    type: user.CLEAR_SP_PROFILE_DETAILS_REQUEST,
    payload: {},
});

export const inviteUser = ({ data, role, userId, formName }) => {
    let roleid = role;
    let form = new FormData();

    Object.keys(data).map((keys) => {
        return form.append(`user[0][${keys}]`, data[keys]);
    });
    form.append(`user[0][role]`, roleid);
    return {
        type: user.INVITE_USER_REQUEST,
        payload: { form, role, userId, formName },
    };
};

export const adminInviteSPUser = ({ data, formName }) => {
    let form = adminInviteUserForm(data);
    return {
        type: user.ADMIN_INVITE_SP_USER_REQUEST,
        payload: { form, formName },
    };
};

export const adminInviteCSPUser = ({ data, formName }) => {
    let form = adminInviteUserForm(data);
    return {
        type: user.ADMIN_INVITE_CSP_USER_REQUEST,
        payload: { form, formName },
    };
};

export const updateTechSupportStatusOfUser = ({ userId, is_possible_tech_support, role, customer = 'scogo', userType, formName, fk_cluster_id }) => ({
    type: user.UPDATE_TECH_SUPPORT_STATUS_OF_USER_REQUEST,
    payload: { userId, is_possible_tech_support, role, customer, userType, formName, fk_cluster_id },
});

export const getTechEngineers = () => ({
    type: user.LIST_TECH_ENGINEERS_REQUEST,
});

export const getUserDetails = ({ userId }) => ({
    type: user.GET_USER_DETAILS_REQUEST,
    payload: { userId },
});

export const removeExistingCitiesFromStore = () => ({
    type: user.REMOVE_EXISTING_CITIES_LIST_FROM_STORE,
});

export const reInviteFE = ({ feId, formName, toastMsg }) => ({
    type: user.REINVITE_FE_REQUEST,
    payload: { feId, formName, toastMsg },
});

export const newResourceFolder = ({ resources, parentPath, formName }) => ({
    type: user.NEW_RESOURCE_ADD_REQUEST,
    payload: { resources, parentPath, formName },
});

export const newResourceFile = ({ parentPath, fileData, formName }) => ({
    type: user.NEW_RESOURCE_FILE_ADD_REQUEST,
    payload: { parentPath, fileData, formName },
});

export const getResource = ({ parentPath }) => ({
    type: user.GET_RESOURCE_REQUEST,
    payload: { parentPath },
});

export const clearResources = () => ({
    type: user.CLEAR_RESOURCES,
});

export const addEndUser = ({ end_user_name, formName, customerId }) => ({
    type: user.ADD_END_USER_REQUEST,
    payload: { end_user_name, formName, customerId },
});

export const clearLastCreatedEndUserFromStore = () => ({
    type: user.CLEAR_LAST_CREATED_ENDUSER,
    payload: {},
});

export const getUserInfo = ({ userId }) => ({
    type: user.GET_USER_INFO_REQUEST,
    payload: { userId },
});

export const deleteUserResource = ({ resourceId, formName, toastMsg, parentPath }) => ({
    type: user.DELETE_RESOURCE_REQUEST,
    payload: { resourceId, formName, toastMsg, parentPath },
});

export const createRootFolder = (userId, navigate) => ({
    type: user.CREATE_ROOT_FOLDER_REQUEST,
    payload: { userId, navigate },
});

export const getUsersRatecards = ({ customerId = 0 }) => ({
    type: user.GET_USERS_RATECARD_REQUEST,
    payload: { customerId },
});

export const bulkUploadISPResponses = ({ ispId, file, formName, toCloseModal }) => ({
    type: utils.BULK_UPLOAD_FILES_REQUEST,
    payload: { property: 'ISP_RESPONSES', ispId, file, formName, toCloseModal },
});

export const getBanksList = () => ({ type: user.BANKS_LIST_REQUEST });

export const updateUserBankDetailsAndPay = ({ data, id, formName, callPay, isRefresh, payPoData, isSP, callAfterBankSubmit, type, source, ticketId, withdrawAmount, userId, remark }) => {
    let form = getBankDetailsForm(data, isSP);
    return {
        type: user.UPDATE_USERS_BANK_DETAILS_REQUEST,
        payload: { form, id, formName, callPay, isRefresh, payPoData, callAfterBankSubmit, type, source, ticketId, withdrawAmount, userId, remark },
    };
};

export const cleanupBankUddateDetailsFromStore = () => {
    return {
        type: user.CLEANUP_BANK_UPDATED_DETAILS_FROM_STORE,
    };
};

export const pancardValidation = ({ pan_number, isBussinessPancard }) => ({
    type: user.PANCARD_VALIDATION_REQUEST,
    payload: { pan_number, isBussinessPancard },
});

export const bussinessPancardValidation = ({ pan_number }) => ({
    type: user.BUSSINESS_PANCARD_VALIDATION_REQUEST,
    payload: { pan_number },
});

export const adhaarValidation = ({ aadhaar_number, already_verified }) => ({
    type: user.USER_AADHAAR_VALIDATE_REQUEST,
    payload: { aadhaar_number, already_verified },
});

export const gstValidation = (gst_number) => ({
    type: user.GST_VALIDATION_REQUEST,
    payload: { gst_number },
});

export const submitAdhaarOtp = (otp, formName, aadhaar_number, closeMoadal) => ({
    type: user.SUBMIT_AADHAAR_OTP_REQUEST,
    payload: { otp, formName, aadhaar_number, closeMoadal },
});

export const bankVerification = (account_number, ifsc) => ({
    type: user.BANK_VERIFICATION_REQUEST,
    payload: { account_number, ifsc },
});

export const initailizeEsign = () => ({ type: user.ESIGN_TOKEN_REQUEST });

export const clearFieldEngineersFromStore = (payload) => ({
    type: user.CLEAR_FIELD_ENGINEERS_LIST,
});

export const generateOTP = ({ mobile }) => ({
    type: user.GENERATE_OTP_REQUEST,
    payload: { mobile },
});

export const verifyOTP = ({ mobile, temp_otp }) => ({
    type: user.VERIFY_OTP_REQUEST,
    payload: { mobile, temp_otp },
});

export const clearOtpGenerateStore = () => ({
    type: user.CLEAR_PROFILE_UPDATE_OTP_STORE,
});

export const updateUserProfileBankDetails = ({ formValues, vendorId, isSP, formName }) => {
    let form = getBankDetailsForm(formValues, isSP);
    let bankVerificationPayload = {
        account_number: formValues?.bank_account_number,
        ifsc: formValues?.bank_ifsc_code,
    };
    return {
        type: user.UPDATE_USER_PROFILE_BANK_DETAILS_REQUEST,
        payload: { bankFormData: form, vendorId, formName, bankVerificationPayload },
    };
};

export const getGstList = ({ customerId, userId }) => ({
    type: user.GET_GST_LIST_REQUEST,
    payload: { customerId, userId },
});

export const getGstById = ({ gstId }) => ({
    type: user.GET_GST_BY_ID_REQUEST,
    payload: { gstId },
});

export const addGst = ({ gstlist, userId }) => ({
    type: user.ADD_GST_REQUEST,
    payload: { gstlist, userId },
});
export const updateGstById = ({ gstId, updatePayload }) => ({
    type: user.UPDATE_GST_REQUEST,
    payload: { gstId, updatePayload },
});

export const deleteGstById = ({ gstId, formName }) => ({
    type: user.DELETE_GST_REQUEST,
    payload: { gstId, formName },
});

export const clearUpdateGstStore = () => ({
    type: user.CLEAR_UPDATE_GST_STORE,
});

export const putToUserStore = (payload) => ({
    type: user.PUT_TO_USER_STORE,
    payload,
});

export const getTechSupportUsersDropdown = () => ({
    type: user.GET_TECH_SUPPORT_USERS_DROPDOWN_REQUEST,
});

export const getClustersPspSupervisorsDropdown = () => ({
    type: partners.PARTNERS_LIST_DROPDOWN_REQUEST,
    payload: { user_role: [CLUSTER.role, PSP.role], status: 0, type: CLUSTER.type },
});

export const getCount = ({ countKey }) => {
    if (countKey === chatSidebarTab.countKey) {
        return {
            type: conversations.GET_CHAT_NOTIFICATIONS_COUNT_REQUEST,
        };
    }
};


export const uploadAddressProofBackAndFront = ({ addressProofType, addressProofNumber, proofImage, formName, closeMoadal, fileUrlBack }) => ({
    type: user.UPLOAD_USER_ID_PROOF_FRONT_BACK_REQUEST,
    payload: { addressProofType, addressProofNumber, proofImage, formName, closeMoadal, fileUrlBack },
});

export const uploadAddressProofBack = ({ addressProofType, addressProofNumber, fileUrlFront, formName, backFile }) => ({
    type: user.UPLOAD_USER_ID_PROOF_BACK_REQUEST,
    payload: { addressProofType, addressProofNumber, fileUrlFront, formName, backFile },
});

export const verifyUserAddress = ({ userId, toAccept, formName, role, customerName, userType, fk_cluster_id }) => ({
    type: user.VERIFY_USER_ADDRESS_REQUEST,
    payload: { userId, toAccept, formName, role, customerName, userType, fk_cluster_id },
});

export const removeValidateAadharResponse = () => ({
    type: user.REMOVE_VALIDATE_AADHAR_RESPONSE_FROM_STORE,
});


export const setSystemUIConfig = (uiConfig) => ({
    type: user.SET_SYSTEM_UI_CONFIG,
    payload: uiConfig
})

export const bulkInviteUseFileUpload = ({ formName, attachment, type, customerId, roleId, sendNotification }) => ({
    type: user.BULK_INVITE_FILE_UPLOAD_REQUEST,
    payload: { formName, attachment, type, customerId, roleId, sendNotification }
});

export const enableRoom = ({ userId, ticketId, conversationId, onSuccess, buttonName }) => ({
    type: user.ENABLE_USER_ROOM_REQUEST,
    payload: { userId, ticketId, conversationId, onSuccess, buttonName },
});

export const disableRoom = ({ userId, ticketId, conversationId, roomId, onSuccess }) => ({
    type: user.DISABLE_USER_ROOM_REQUEST,
    payload: { userId, ticketId, conversationId, roomId, onSuccess },
});


export const clearEnabledRoomId = () => ({
    type: user.CLEAR_ENABLED_ROOM_ID
})

export const getUserCallStatusAction = ({ userId, roomId, status }) => ({
    type: user.GET_USER_CALL_STATUS_REQUEST,
    payload: { userId, roomId, status }
})
export const getEndUsers = ({ customerId, limit, page, query, source }) => ({ type: user.GET_ENDUSERS_LIST_REQUEST, payload: { customerId, limit, page, query, source } });

export const checkAndUpdateKycStatus = ({ pancard_number, adhaar_number, refreshUserTable, tabName, callDetails, userId }) => ({
    type: user.CHECK_AND_UPDATE_KYC_STATUS,
    payload: { pancard_number, adhaar_number, refreshUserTable, tabName, callDetails, userId }
})