import React from 'react';
import { closeModalAction } from '../../../actions/modal';
import { useDispatch, useSelector } from 'react-redux';
import { downloadISPFeasibityReport } from '../../../actions/partners';
import Form from '../../../common/Form';
import { useForm } from 'react-hook-form';
import MultipleEmails from '../../../common/Form/MultipleEmail';

const formName = 'downloadISPFeasibilityReport';
export default function DownloadISPFeasibilityReport() {
    const dispatch = useDispatch();
    const { loggedUser } = useSelector((state) => state.auth);
    const { formButtonLoading } = useSelector((state) => state.loading);

    const defaultValues = {
        emails: loggedUser.email ? [loggedUser.email] : [],
    };

    const submit = (formValues) => {
        let { emails } = formValues;

        dispatch(downloadISPFeasibityReport({ emails, formName, closeModalOnSuccess: true }));
    };

    const methods = useForm({ defaultValues, mode: 'all' });

    return (
        <Form
            methods={methods}
            onSubmit={submit}
            submitButtonText={'Download'}
            onCancel={() => dispatch(closeModalAction())}
            showSubmit
            submitContainerClassName='flex gap-2 justify-end py-4 px-4'
            isLoading={formButtonLoading[formName]}
        >
            <div className='px-3 flex justify-between flex-col py-2'>
                <MultipleEmails name='emails' required label='Email To' />
            </div>
        </Form>
    );
}
