import React, { memo, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { forwardMessageToWhatsApp, getWhatsappChatList, setDialpad } from '../../../actions/whatsapp';
import InputFiels from '../../../common/InputFiels';
import IconToolTip from '../../../common/IconToolTip';
import { useSelector } from 'react-redux';
import Loader from '../../../common/Loader';

import { chatType, defaultGroupChatImage, defaultSingleChatPic, messageType } from '../ChatController';
import OverflowTip from '../../../common/OverflowTip';
import { debounce, memoize } from 'lodash';
import Checkbox from '../../../common/Checkbox';
import { setGoogleEvents, validateStringValue } from '../../../utils/common';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List, areEqual } from 'react-window';
import NoResultFound from '../../../common/NoResultFound';
import { Avatar, Skeleton } from '@mui/material';
import { googleEvents } from '../../../common/GoogleEvent';
import useRemToPx from '../../../hooks/useRemToPx';
import Dialpad from '../../../common/Dialpad';


export default function WhatsappChatList({ openedConversationId, ticketDetail = "", message, selectedMedia = [], showSideBarMedia, clearSelectedMedia = () => { } }) {

    const { whatsappChatList, whatsappAllChatList, showDialpad } = useSelector((state) => state.whatsapp)
    const { conversationDetails } = useSelector((state) => state?.conversations);
    const { whatsappChatListLoading, whatsappForwardMessageLoading } = useSelector((state) => state.loading)
    const [searchTerm, setSearchTerm] = useState('');
    const [checkedTracker, setCheckedTracker] = useState(() => new Set());
    const [selectedChat, setSelectedChat] = useState(() => []);
    const [textMessage, setTextMessage] = useState(message?.message || ticketDetail)
    const [warning, setWarning] = useState(false)
    const dispatch = useDispatch();
    const [number, setNumber] = useState("")
    const openedConversation = conversationDetails?.[openedConversationId];
    const showMessageTextBox = ((message?.message?.length > 0 || ticketDetail?.length > 0 || message?.type !== messageType?.attachments) && selectedMedia?.length === 0);


    const [chatListHeight, setChatListHeight] = useState(showMessageTextBox ? "h-[34rem]" : "h-[40rem]")

    const searchGroupList = ({ searchKey = "" }) => {
        let data = {
            query: searchKey,
        }
        if (openedConversation?.type === chatType.ticket) {
            data.ticketId = openedConversation?.conversationId
            data.type = "TICKET_CHAT"
        } else if (openedConversation?.type === chatType.group || openedConversation?.type === chatType.single) {
            data.messageId = message?._id
            data.type = "GROUP_CHAT"
        }
        else {
            data.messageId = message?._id
            data.type = "MULTIPLE_MEDIA"
        }

        dispatch(getWhatsappChatList(data))
    };
    const debouncedSearchCall = useCallback(debounce(searchGroupList, 500), []);

    useEffect(() => {
        return () => {
            clearSelectedMedia(() => new Set())
            setTextMessage("")
            setSelectedChat([])
            dispatch(setDialpad(false))

        }
    }, [dispatch])

    useEffect(() => {
        setChatListHeight(selectedChat?.length === 0 ? "h-[40rem] " : "h-[32rem]")
    }, [selectedChat?.length])

    useEffect(() => {
        setGoogleEvents(googleEvents.whatsApp.main_event, googleEvents.whatsApp.whatsapp_list)
        let data = {}

        if (openedConversation?.type === chatType.ticket) {
            data.ticketId = openedConversation?.conversationId
            data.type = "TICKET_CHAT"
        } else if (openedConversation?.type === chatType.group || openedConversation?.type === chatType.single) {
            data.messageId = message?._id
            data.type = "GROUP_CHAT"
        }
        else {
            data.messageId = message?._id
            data.type = "MULTIPLE_MEDIA"
        }

        dispatch(getWhatsappChatList(data))

    }, [dispatch])

    useEffect(() => {
        setNumber("")
        setCheckedTracker(new Set())
        setSelectedChat([])
        setChatListHeight("h-[40rem]")
    }, [showDialpad])


    useEffect(() => {
        if (checkedTracker) {
            const filterName = whatsappAllChatList?.filter((value, index, self) => {
                let unique = index === self.findIndex((t) => (
                    t.id === value.id
                ))
                return checkedTracker.has(value.id) && unique
            })

            if (filterName?.length === 5) {
                setWarning(true)
            } else {
                setWarning(false)
            }
            setSelectedChat(filterName);
        }
    }, [checkedTracker, whatsappAllChatList])


    const handleOnChangeCheckbox = (position) => {
        if (checkedTracker.has(position)) {
            setCheckedTracker((prev) => {
                const next = new Set(prev);
                next.delete(position);
                return next;
            });
        } else {
            setCheckedTracker((prev) => new Set(prev).add(position));
        }
    };

    const setSeachQuery = (searchTerm) => {
        setSearchTerm(searchTerm);
        debouncedSearchCall({ searchKey: searchTerm });
    };


    const sendMessage = () => {
        let sendkeys = {
            recipients: selectedChat?.map(e => { return { receiverName: e?.subject, receiverId: e?.id } })
        }

        if (showSideBarMedia && selectedMedia?.length > 0) {
            sendkeys = { ...sendkeys, attachmentIds: selectedMedia, conversationId: openedConversationId }
        }
        else if (message?.type === messageType?.message || message?.type === messageType.card || message?.type === messageType.reply) {
            sendkeys = { ...sendkeys, text: textMessage, messageId: message?._id }
        } else if (message?.type === messageType?.attachments) {
            let attachmentIds = message.attachments.map(e => e._id)
            sendkeys = { ...sendkeys, attachmentIds: attachmentIds, conversationId: openedConversationId, }
        }

        if (openedConversation?.type === chatType.ticket) {
            sendkeys = { ...sendkeys, ticketId: openedConversation?.conversationId }
            if (ticketDetail?.length > 0) {
                sendkeys = { ...sendkeys, text: textMessage }
            }
        }

        if (number?.length > 9 && showDialpad) {
            sendkeys = {
                ...sendkeys, type: "TO_USER", recipients: [{
                    "receiverId": `91${number}@c.us`, "receiverName": number
                }
                ],

            }
            if (textMessage?.length > 0) {
                sendkeys = {
                    ...sendkeys, text: textMessage
                }
            }
        }

        setGoogleEvents(googleEvents.whatsApp.main_event, googleEvents.whatsApp.whatsapp_message_forwarded)
        dispatch(forwardMessageToWhatsApp(sendkeys))
    }

    const onFocus = () => {
        setChatListHeight("h-[32rem]")

    }



    const onKeyDown = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === 13 && !event.shiftKey && (selectedChat?.length > 0 || number?.length === 10) && !whatsappForwardMessageLoading) {
            sendMessage()
            event.preventDefault()
        }
    }

    const onChangeHandler = (value) => {
        setNumber(value)
    }

    return (
        <div className='p-2 ' >
            {!showDialpad && <div className='relative w-full h-[3.5rem] shadow-sm py-2 z-20 -mb-5 border-b border-gray-200 bg-white' >
                <InputFiels
                    type='text'
                    inputImg={process.env.PUBLIC_URL + '/img/search.png'}
                    inputClass={'w-[90%] loginput truncate text-xl flex items-center'}
                    placeholder={"Search Group "}
                    inputImgDiv='loginput-imgdiv top-3 left-4 h-6 w-6 mr-4 items-center '
                    inputDiv='text-gray-400 focus-within:text-gray-600 relative  flex  w-full '
                    value={searchTerm}
                    onChange={(event) => setSeachQuery(event.target.value.trimStart())}
                    autoFocus
                />
                {searchTerm?.length > 0 && <div className='absolute right-0 top-[25%]' >
                    <IconToolTip title="Cancel" onClick={() => {
                        setSeachQuery('')
                    }}>
                        <span className='text-scogoprimary hover:text-scogoorange cursor-pointer material-icons text-font18 mr-4'  >
                            cancel
                        </span>

                    </IconToolTip>
                </div>}
                {warning && <div className='absolute w-full bg-warning -bottom-[2.3rem] flex justify-between text-font13 py-[0.40rem] pl-2 rounded-sm' >

                    You can only share with up to 5 chats.
                    <IconToolTip title="Cancel" onClick={() => {
                        setWarning(false)
                    }}>
                        <span className='text-scogoprimary hover:text-scogoorange cursor-pointer material-icons text-font18 mr-4'  >
                            cancel
                        </span>

                    </IconToolTip>
                </div>}
            </div>}

            {whatsappChatListLoading ? <div className='w-full pl-2' >
                <ChatListLoader />
            </div>

                :

                <> {whatsappChatList?.length === 0 ?
                    <div className="py-4 px-4" >
                        <NoResultFound message="Chat Not Found" paddingy="py-2" paddingx="px-2" />
                    </div>
                    :
                    <div tabIndex={-1} onKeyDown={(event) => onKeyDown(event)} >
                        {showDialpad ?
                            <div className='pb-10 h-[33rem]' >
                                <Dialpad onChangeHandler={onChangeHandler} />
                            </div>
                            :
                            <div className={`overflow-hidden ${chatListHeight}  pl-2`} >
                                <ChatList chatList={whatsappChatList} handleOnChangeCheckbox={handleOnChangeCheckbox} checkedTracker={checkedTracker}
                                    disableClick={selectedChat?.length === 5} setWarning={setWarning} />
                            </div>}

                        {selectedChat?.length > 0 || number?.length === 10 ?
                            <div className={`h-auto flex items-center px-2 border-t border-gray-400 pt-2`} >
                                <div className='w-[88%]' >

                                    <div className='flex' >{selectedChat?.map((e) => {
                                        let subject = e?.subject
                                        return <IconToolTip title={subject}>

                                            <div className='border-2 p-1 m-1 rounded-lg bg-scogoprimary text-white truncate'>{subject}</div>
                                        </IconToolTip>
                                    })} </div>
                                    {showMessageTextBox && <div className='mt-2 border-1 border-scogoorange' >
                                        <textarea
                                            className={`font-normal text-font10 md:text-font12 block w-full focus:ring-0 px-2 resize-none  border-gray-400 border-2 focus:border-scogoprimary h-auto `}
                                            placeholder={textMessage}
                                            rows={3}
                                            value={textMessage}
                                            onChange={(event) => setTextMessage(event.target.value.trimStart())}
                                            onFocus={() => {
                                                onFocus()
                                            }}

                                        />
                                    </div>}
                                </div>
                                <div className={`p-4 rounded-full w-16 h-16 flex items-center mt-2 ml-auto cursor-pointer bg-whatsappGreen `} onClick={() => {
                                    if (!whatsappForwardMessageLoading) {
                                        sendMessage()
                                    }
                                }}  >
                                    {whatsappForwardMessageLoading ?
                                        <>
                                            <Loader color='#FFF' size='21' speed='1' thickness='4' marginTop='' />
                                        </>
                                        : <span className="material-icons  text-white">
                                            send
                                        </span>
                                    }
                                </div>
                            </div>
                            : <></>
                        }
                    </div>
                }

                </>

            }

        </div >
    )
}


const ChatListLoader = () => {


    return [1, 2, 3, 4, 5].map(e => <div className='flex items-center my-4 w-full mt-8' >
        <div className='mr-4'>
            <Skeleton variant='circular'>
                <Avatar sx={{ height: '5rem', width: '5rem' }} />
            </Skeleton>
        </div>
        <div className='w-4/5'>
            <Skeleton
                sx={{ background: 'rgb(237, 235, 235)', borderRadius: '2rem' }}
                variant='rectangular'
                width={'full'}
                height={25}
                animation='wave'
            />
        </div>
    </div >
    )

}

const createItemData = memoize(({ chatList, handleOnChangeCheckbox, disableClick, checkedTracker, setWarning }) => ({
    chatList, handleOnChangeCheckbox, disableClick, checkedTracker, setWarning
}));

const ChatList = ({ chatList, handleOnChangeCheckbox, checkedTracker, disableClick, setWarning }) => {
    let itemSize = useRemToPx(5.5)
    return <>
        <div className='w-full h-full ' >

            <AutoSizer>
                {({ height, width }) => (
                    <List
                        className='List'
                        height={height}
                        width={width}
                        itemCount={chatList?.length}
                        itemSize={itemSize}
                        itemData={createItemData({ chatList, handleOnChangeCheckbox, disableClick, checkedTracker, setWarning })}
                    >
                        {Row}
                    </List>
                )}
            </AutoSizer>
        </div>
    </>
}

const whatsappDes = (message) => {

    if (message?.lastMessageSender?.length > 0) {
        return `${message?.lastMessageSender} : ${validateStringValue(message?.lastMessage)}`
    } else if (message?.lastMessage?.length > 0) {
        return `${validateStringValue(message?.lastMessage)}`
    } else if (message?.description === "No Description" && message?.description?.length > 0) {
        return `${validateStringValue(message?.description)}`
    } else {
        return ""
    }
}

const listStyle = (disableClick, isSelected) => {

    if (disableClick && !isSelected) {
        return " opacity-60"
    }
    return ` hover:bg-tagbg`
}

const userProfileImg = (list) => {
    if (list?.profile?.iconName?.length === 1) {
        return <p className='flex items-center justify-center text-font18 font-normal rounded-full h-full' style={list?.profile?.style} >{list?.profile?.iconName}</p>
    }
    return <img src={list.isGroup ? defaultGroupChatImage : defaultSingleChatPic} alt="" className='w-full h-full object-cover rounded-full' />
}

const Row = memo(({ data, index, style }) => {
    const { chatList, handleOnChangeCheckbox, disableClick, checkedTracker, setWarning } = data;
    let isSelected = checkedTracker.has(chatList?.[index]?.id)
    let backgroundColor = (index % 2) === 0 ? " bg-slate-100  " : "bg-white";

    return (
        <div style={style}  >
            {chatList?.[index]?.title?.length > 0 ? <p className='text-font15 mt-8 pl-1 border-b border-gray-400 pb-2' >{chatList?.[index]?.title}</p> :

                <div className={`flex gap-x-3 items-center py-4 cursor-pointer px-2 border-b-2 border-gray-200 ${backgroundColor}  ${listStyle(disableClick, isSelected)}`} onClick={() => {
                    if (disableClick && !isSelected) {
                        setWarning(true)
                    } else {
                        handleOnChangeCheckbox(chatList?.[index]?.id);
                    }
                }}>
                    <div>
                        <Checkbox
                            checkColor='text-whatsappGreen mr-2'
                            checked={isSelected}
                            dynamicSize={"1.4rem"}
                        />
                    </div>
                    <div className='w-[3.8rem] h-[3.8rem] '>{userProfileImg(chatList[index])} </div>
                    <p className='text-left w-[76%] ml-2' >
                        <OverflowTip someLongText={chatList?.[index]?.subject} textClass=' text-font14' />
                        <OverflowTip someLongText={whatsappDes(chatList[index])} textClass=' text-font12 text-scogogray' />
                    </p>
                </div>

            }

        </div>
    );
}, areEqual);