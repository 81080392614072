import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import { getSpareCategories, getInventoryPartcodes, getAssetsTypes, addAssetsMaster } from '../../../actions/inventory';
import { getSuperDeskCapexTypes } from '../../../actions/superdesk';
import Form from '../../../common/Form';
import Input from '../../../common/Form/Input';

import { getCustomerTeams } from '../../../actions/customer';
import { useForm } from 'react-hook-form';
import Select from '../../../common/Form/Select';

const AddAssetTypes = ({ customerId, siteId }) => {
    const formName = 'addAssetsTypeForm';
    const dispatch = useDispatch();
    const { formButtonLoading } = useSelector((state) => state.loading);

    const defaultValues = {
        code_prefix: '',
        capex_type: '',
        fk_category_id: '',
        fk_team_id: '',
        title: '',
    };

    const callDataOnMount = useCallback(() => {
        dispatch(getSpareCategories({ customerId, dropdown: true }));
        dispatch(getInventoryPartcodes({ customerId }));
        dispatch(getAssetsTypes({ customerId }));
        dispatch(getCustomerTeams({ customerId, dropdown: true }));
        dispatch(getSuperDeskCapexTypes({ customerId }));
    }, [dispatch, customerId]);

    useEffect(() => {
        callDataOnMount();
    }, [dispatch, callDataOnMount]);

    const submit = (formValues) => {
        let data = {
            code_prefix: formValues.code_prefix,
            capex_type: formValues.capex_type.value,
            fk_category_id: formValues.fk_category_id.value,
            fk_team_id: formValues.fk_team_id.value,
            title: formValues.title,
        };
        dispatch(addAssetsMaster({ data, customerId, siteId, formName }));
    };

    const capex_types_dropdown = (capexType) => {
        if (capexType) {
            const capex = [];
            capexType.forEach((item) => {
                capex.push({
                    key: `capex_types_${item.id}`,
                    value: item.id,
                    label: item.name,
                    capex: item,
                });
            });
            return capex;
        }
    };

    const closeModal = () => {
        dispatch(closeModalAction());
    };

    const { spareCategory, assetTypes } = useSelector((state) => state.inventory);
    const customer = useSelector((state) => state.customer);
    const { capexTypes } = useSelector((state) => state.superdesk);
    let capexTypesDropdown = capex_types_dropdown(capexTypes);

    let { customerTeamsDropDown } = customer;

    const methods = useForm({ defaultValues, mode: 'all' });

    return (
        <>
            <Form
                className='w-full'
                onSubmit={submit}
                onCancel={closeModal}
                defaultValues={defaultValues}
                submitButtonText={'Add'}
                showSubmit
                submitContainerClassName='flex gap-2 justify-end py-4 px-4'
                isLoading={formButtonLoading[formName]}
                methods={methods}
            >
                <div className='px-3'>
                    <div className='my-2 px-2'>
                        <h6 className='my-2 text-font12 font-medium'>Existing Assets:</h6>
                        <div className='flex flex-wrap gap-2'>
                            {Array.isArray(assetTypes) &&
                                assetTypes.length > 0 &&
                                assetTypes.map((asset) => <p className='bg-tagbg scogoprimary text-font11 px-p-6 py-p-3 rounded-3px'>{asset.label}</p>)}
                        </div>
                    </div>

                    <div className='grid grid-cols-2 rows-fr grid-flow-row-dense'>
                        <Input label='Asset Name' placeholder='Asset Name' required name='title' type={'text'} />
                        <Input label='Asset Prefix Name' name='code_prefix' placeholder='Asset Prefix Name' required />
                    </div>

                    <div className='grid grid-cols-3 rows-fr grid-flow-row-dense'>
                        <Select label='Capex Type' name='capex_type' type={'dropdown'} options={capexTypesDropdown} />
                        <Select label='Team' name='fk_team_id' type={'dropdown'} options={customerTeamsDropDown} />
                        <Select label='Category' name='fk_category_id' type={'dropdown'} options={spareCategory} required />
                    </div>
                </div>
            </Form>
        </>
    );
};

export default AddAssetTypes;
