import React from 'react';
import MultiBar from '../../../common/MultiBar';

export default function AssetSerialNumber(props) {
    const { serialNumber, status, statusForDisplay, workingCondition, showCopyIcon, delivered_to, received_from } = props;
    let statusHoverText = `STATUS: ${statusForDisplay}`;
    if (delivered_to) statusHoverText += ` | Delivered To: ${delivered_to}`;
    if (received_from) statusHoverText += ` | Received From: ${received_from}`;
    return (
        <>
            <MultiBar
                type={'ASSET'}
                serialNumber={serialNumber}
                status={status}
                statusForDisplay={statusForDisplay}
                statusHoverText={statusHoverText}
                workingCondition={workingCondition}
                showCopyIcon={showCopyIcon}
            />
        </>
    );
}
