import React, { useEffect, useState } from 'react'
import { groupBy } from 'lodash'
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Form from '../../../common/Form';
import { useSelector } from 'react-redux';
import FormTable from '../../../common/Form/Table';
import Input from '../../../common/Form/Input';
import { createProjectTemplate, createWorkflow } from '../../../actions/project';
import { getExecutionStepsFields, removeExecutionStepFieldsFromStore } from '../../../actions/ticketExecution';
import { v4 as uuidv4 } from 'uuid';

const arrayName = 'signOffCheckList';
const formName = "checklistFrom";
const newCreatedStepsId = 'new_created_step_id';

export default function EditChecklist({ project }) {
    const { formButtonLoading } = useSelector((state) => state.loading);
    const { executionStepFields } = useSelector((state) => state.tickets);
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(true)
    const defaultValues = {};
    const [deletedIds, setDeletedIds] = useState([]);

    const methods = useForm({ defaultValues, mode: 'all' });
    const { watch, setValue } = methods


    useEffect(() => {
        const data = { is_checklist: true, template_id: project.fk_template_id, project_id: project.id, ticket_id: 0 };
        dispatch(getExecutionStepsFields({ data }));
        return () => {
            dispatch(removeExecutionStepFieldsFromStore());
        }
    }, [dispatch, project.fk_template_id, project.id]);

    useEffect(() => {
        if (executionStepFields && Array.isArray(executionStepFields.single)) {
            let steps = executionStepFields.single.map((item) => {
                return {
                    id: item.step_fields_relationship_id,
                    title: item.field_title,
                    ...item,
                    existing: true
                }
            })
            setValue(arrayName, steps);
        } else {
            setValue(arrayName, [{ id: `${Math.random()}`, isCreated: true, step_id: newCreatedStepsId }]);
        }
    }, [executionStepFields, setValue])

    const onSubmit = (formValues) => {
        if (project.fk_template_id === 0) {
            const createTemplateData = {
                "title": `${project.project_name}_checklist`,
                "has_checklist": 1,
                "service_type_id": project.service_type_id,
                "use_case_id": project.use_case_id,
                "is_boq_required": 0
            }
            dispatch(createProjectTemplate({ createTemplateData, formName, formData: formValues?.[arrayName], projectId: project.id }));
        } else {
            const checklists = formValues[arrayName] || [];
            if (Array.isArray(executionStepFields?.single)) {
                executionStepFields.single.forEach((item) => {
                    if (deletedIds.includes(item.step_fields_relationship_id)) {
                        checklists.push({
                            ...item,
                            isDeleted: true,
                            id: item.step_fields_relationship_id,
                            title: item.field_title,
                            existing: true
                        })
                    }
                })
            }
            const data = {
                templateData: { id: project.fk_template_id },
                checkListdata: transformChecklistData({ list: checklists, projectId: project.id }),
                formName,
                showToast: true
            }
            dispatch(createWorkflow(data));
        }

    };

    useEffect(() => {
        let flag = false;
        const { unsubscribe } = watch((values) => {
            let fields = values?.[arrayName];
            if (fields.length === 0) {
                flag = false
            }
            else if (Array.isArray(fields)) {
                flag = fields.every((item) => {
                    return item.title?.length > 0
                });
            }
            setDisabled(!flag)
        });
        return () => unsubscribe();
    }, [watch]);



    const schema = {
        table: [
            {
                head: {
                    thClassName: `pl-2 w-full`,
                    headName: 'Check list Point',
                    required: true,
                },
                body: {
                    bodyClassName: `pl-1 w-full`,
                    render: (_, { rowIndex }) => {
                        return <div className='text' >
                            <Input name={`${arrayName}[${rowIndex}].title`} type='text' required placeholder='Check list point'
                                inputClassName={` text-font12 bg-sky-500/20 text- px-2 py-1 italic rounded-md text-black truncate w-96 font-normal text-font09 md:text-font11 block w-full focus:ring-0 px-4 border-2 border-scogoprimary `}
                                maxLength={40}
                            />
                        </div>
                    }
                },
            },
            {
                head: {
                    thClassName: `w-24`,
                    headName: 'Delete',
                },
                body: {
                    bodyClassName: `w-24 pl-2`,
                    render: (item, { rowIndex, remove }) => {
                        return (
                            <span
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (!item.isCreated) {
                                        setDeletedIds((prevState) => ([...prevState, item.step_fields_relationship_id]));
                                    }
                                    remove(rowIndex);
                                }}
                                className={`material-icons text-scogoToastDangerIcon cursor-pointer hover:text-scogoorange`}
                            >
                                delete
                            </span>
                        );
                    },
                },
            },
        ],
    };

    return (
        <div className='' >
            <Form
                className='w-full'
                onSubmit={onSubmit}
                buttonPostion={'justify-end'}
                isLoading={formButtonLoading[formName]}
                submitButtonText='Submit'
                showSubmit
                isSubmitDisabled={disabled}
                submitContainerClassName='flex gap-2 justify-end py-4 px-4'
                methods={methods}
                showCancelButton={false}
            >
                <div style={{ padding: '1.25rem' }}>
                    <FormTable isLoading={false} schema={schema} name={arrayName} allowAdd addButtonClassName='my-4 text-font12 py-1' tableBodyStyle={{ maxHeight: "20rem", overflow: "auto" }} rowGenerator={() => ({ id: `${Math.random()}`, isCreated: true, step_id: newCreatedStepsId })} />

                    <Input name={"name"} type='text' placeholder='Upload SignOff Images with Sign and Stamp'
                        readOnly={true}
                        inputClassName={` text-font12 bg-sky-500/20 italic px-2 py-1 rounded-md text-black truncate w-96 font-normal text-font09 md:text-font11 block w-full focus:ring-0 px-4 cursor-not-allowed `}
                    />
                </div>

            </Form>
        </div>
    )
};


const transformChecklistData = ({ list, projectId }) => {
    const groupList = groupBy(list, 'step_id');
    const stepData = Object.entries(groupList).map(([key, value]) => {
        let step = !isNaN(key) && parseInt(key, 10) > 0 ? parseInt(key, 10) : key;
        if (key === newCreatedStepsId) step = uuidv4();
        return {
            step,
            repeater: false,
            fields: value.map((item, index) => {
                return {
                    "id": item.step_fields_relationship_id,
                    "inputId": 12,
                    "repeater": false,
                    "title": item?.title,
                    "serial_order": index + 1,
                    "is_field_required": true,
                    "is_file_required": false,
                    "is_geo_coordinates": false,
                    "is_checklist": true,
                    existing: item.existing,
                    isCreated: item.isCreated,
                    isDeleted: item.isDeleted,
                }
            })
        }
    })
    return {
        existing: true,
        projectId,
        stepData
    }
}

