import React from 'react';

import { FormProvider } from 'react-hook-form';
import ButtonScogoClosedOutlined from '../Buttons/ButtonScogoClosedOutlined';
import ButtonScogoPrimary from '../Buttons/ButtonScogoPrimary';
import ButtonScogoOrange from '../Buttons/ButtonScogoOrange';

const Form = ({
    children,
    onSubmit,
    className,
    showSubmit,
    submitRef,
    methods,
    onCancel,
    showCancelButton = true,
    isLoading,
    submitButtonText = 'Save',
    cancelButtonText = 'Cancel',
    submitContainerClassName = 'flex ml-2 gap-4',
    buttonClass,
    disabledClass,
    isSubmitDisabled = false,
    onEnterPressSubmit = false,
    submitButtonHoverText = '',
    renderButtons = <></>
}) => {
    const { handleSubmit } = methods;

    return (
        <FormProvider {...methods}>
            <form
                onSubmit={handleSubmit(onSubmit)}
                className={className}
                onKeyDown={(e) => {
                    if (e.key === 'Enter' && e.target.tagName !== 'TEXTAREA' && !onEnterPressSubmit) e.preventDefault();
                }}
            >
                {children}
                {showSubmit && (
                    <div className={submitContainerClassName}>
                        {renderButtons}
                        <ButtonScogoPrimary
                            disabled={isSubmitDisabled}
                            textOrComponent={submitButtonText}
                            disabledClass={disabledClass}
                            type='submit'
                            loading={isLoading}
                            buttonClass={buttonClass}
                            onHoverTitle={submitButtonHoverText}
                        />
                        {showCancelButton && <ButtonScogoClosedOutlined textOrComponent={cancelButtonText} type='button' onClick={onCancel} />}
                    </div>
                )}
                <input type='submit' ref={submitRef} className='invisible absolute' />
            </form>
        </FormProvider>
    );
};

export default Form;
