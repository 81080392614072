import { HMSNotificationTypes, } from "@100mslive/react-sdk";
import { sendToastMessage } from "../../../actions/toast";
import { isValidJSON } from "../../../utils/common";
import { closeModalAction, openTextModalAction } from "../../../actions/modal";


export const handleNotifications = (notification, dispatch, hmsActions) => {
    switch (notification.type) {
        case HMSNotificationTypes.PEER_JOINED:
            dispatch(sendToastMessage({ message: `${notification.data.name} Joined`, status: 'success' }));
            break;
        case HMSNotificationTypes.PEER_LEFT:
            dispatch(sendToastMessage({ message: `${notification.data.name} Left`, status: 'success' }));
            break;
        case HMSNotificationTypes.ERROR:
            if (notification.data.code === 3008) {
                dispatch(openTextModalAction({
                    heading: 'Enable Audio',
                    text: 'Browser has blocked audio, Click here to enable',
                    formName: 'enableaudio',
                    buttons: [
                        {
                            showLoading: true,
                            textOrComponent: 'Allow',
                            type: 'blue',
                            onClick: async () => {
                                await hmsActions.unblockAudio();
                                dispatch(closeModalAction());
                            },
                        },
                    ],
                    closeOnBackdropClick: false,
                }));
                return;
            }
            if (isValidJSON(notification.data.description)) {
                const error = JSON.parse(notification.data.description);
                dispatch(sendToastMessage({ message: error?.message, status: 'danger' }));
                break
            }
            dispatch(sendToastMessage({ message: `${notification.data.description}`, status: 'danger' }));
            break;
        case HMSNotificationTypes.RECONNECTING:
            dispatch(sendToastMessage({ message: `Reconnecting`, status: 'warning' }));
            break;
        case HMSNotificationTypes.RECONNECTED:
            dispatch(sendToastMessage({ message: `Reconnected`, status: 'success' }));
            break;
        default:
            break;
    }
}

export const allowVideoMeet = (ticket) => {
    if (ticket?.signOffAccept === 1 || ticket?.is_signoff_accept === 1) {
        return false;
    }

    return true;
}

export const generateRoomLink = (room_id, conversationId) => {
    return `${window.location.origin}/room/${room_id}?openedConversationId=${conversationId}`;
}

export const roomStateEnum = {
    Disconnected: 'Disconnected',
    Connecting: 'Connecting',
    Connected: 'Connected',
    Disconnecting: 'Disconnecting'
}