import { useFormContext, useFieldArray } from 'react-hook-form';
import ButtonScogoOrange from '../Buttons/ButtonScogoOrange';
import { tableCss } from '../Css';
import Loader from '../Loader';
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import _ from 'lodash';

const FormTable = forwardRef(({
    name,
    schema,
    allowAdd = true,
    rowGenerator,
    className = '',
    addButtonClassName = '',
    formTableClass = 'shadow-lg tableContainer rounded-lg nonPaginatedTable',
    tableBodyStyle,
    isLoading,
    buttonDivClass,
    tableHeadFontSize = 'text-font10 font-normal',
    tableHeadClass = 'md:flex tableHeader hidden  p-1 z-30 border-b-2 justify-between block items-center',
    style,
    scrollToBottomOnAddRow = false
}, ref) => {
    const { control, trigger } = useFormContext();
    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name, // unique name for your Field Array
    });
    const formTableRef = useRef(null);

    const rows = () => {
        if (isLoading)
            return (
                <div className='border border-white'>
                    <Loader color='#F48A21' size='65' speed='1' thickness='3' margin='150px' />
                </div>
            );
        return fields.map((field, index) => {
            return (
                <tr key={field.id} className={`border-b border-scogoddd py-2  flex items-center md:flex w-full Content justify-between ${tableCss?.tBodyText}`}>
                    {cells(field, index)}
                </tr>
            );
        });
    };
    useImperativeHandle(ref, () => ({
        appendRow() {
            append(rowGenerator());
            if (scrollToBottomOnAddRow) {
                scrollToBottom()
            }
        },
    }));

    const cells = (field, rowIndex) => {
        return schema.table.map(({ head, body }, colIndex) => {
            return (
                <td style={body?.style} className={body?.bodyClassName + ` min-h-2 ${head?.hide ? 'hidden' : ''}`} key={colIndex}>
                    {body.render(field, { rowIndex, colIndex, trigger, append, prepend, remove, swap, move, insert })}
                </td>
            );
        });
    };

    const scrollToBottom = () => {
        if (formTableRef.current) {
            _.delay(() => {
                const scrollHeight = formTableRef.current.scrollHeight;
                formTableRef.current.scrollTop = scrollHeight;
            }, 0);
        }
    }

    return (
        <>
            <div className={formTableClass}>
                <table className='block rounded-lg formTable' style={{ height: 'inherit' }}>
                    <thead className={tableHeadClass}>
                        {schema.table.map(({ head }) => {
                            return (
                                <th
                                    style={head?.style}
                                    key={head?.headName}
                                    className={` ${tableHeadFontSize} text-left text-scogogray ${head?.hide ? 'hidden' : ''} ${head?.thClassName}`}
                                >
                                    {head?.render ? head.render(head?.headName) : head?.headName} {head?.required && <span className='text-scogoorange'>*</span>}
                                </th>
                            );
                        })}
                    </thead>

                    <tbody ref={formTableRef} style={{ ...tableBodyStyle, ...style }} className={`tableBody  block  2xl:text-font13 text-font11 text-scogo15 font-medium h-full overflow-y-auto`}>
                        {rows()}
                    </tbody>
                </table>
            </div>

            {allowAdd && (
                <div className={buttonDivClass}>
                    <ButtonScogoOrange textOrComponent='Add' onClick={() => {
                        append(rowGenerator())
                        if (scrollToBottomOnAddRow) scrollToBottom();
                    }}
                        buttonClass={`px-4 ${addButtonClassName}`} />
                </div>
            )}
        </>
    );
});

export default FormTable;
