import { takeEvery, takeLatest, put, call, all, select } from 'redux-saga/effects';
import { tickets, toast, loading, modal, conversations } from '../../types';
import api from '../../apis';
import lambdas from '../../lambdas/apis';
import { refreshPage } from '../../actions/payments';
import { getPaymentFilterData } from '../../selectors/payments';
import { getLoggedUser } from '../../selectors/users';
import { generateBoqExecutionStepForm, generateExecutionStepForm, generateSignOffStepForm } from '../../utils/form';
import { uploadFileToS3WithSignedUrl } from '../../utils/common';
import config from '../../config';
import prodConfig from '../../config/env/production';
import { todayStatus } from '../../router/roleWisePageRoute';

export function* startTicketExecutionManually(action) {
    const { start } = action.payload;
    try {
        yield all([yield put({ type: tickets.START_TICKET_EXECUTION_SUCCESS, payload: start }), yield put({ type: modal.CLOSE_MODAL })]);
    } catch (error) {
        yield put({ type: tickets.START_TICKET_EXECUTION_FAILURE, payload: error });
    }
}

export function* holdTicket(action) {
    const { data, refresh = true, reportPM, formName, refreshTicketTable = false } = action.payload;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    try {
        const response = yield call(api.ticketExecution.holdTicket, {}, {}, {}, data);
        if (response.data.code === 200) {
            if (reportPM) {
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Ticket escalated' } });
            } else {
                let message =
                    response.data.data && response.data.data.resume_message_pm && (response.data.data.resume_message_pm !== '' || response.data.data.resume_message_pm !== null)
                        ? response.data.data.resume_message_pm
                        : 'Ticket is kept on hold';
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message } });
            }
            yield put({ type: modal.CLOSE_MODAL });

            if (refreshTicketTable) {
                yield put({ type: tickets.REFRESH_TICKET_TABLE_REQUEST });
            }

            if (data.is_revisit_required === 1) {
                // const userDetails = yield select(getLoggedUser);
                // let message = `Revisit Approved by ${userDetails.first_name} ${userDetails.last_name}${!['', undefined, null].includes(data.escalation_message) ? `since ${data.escalation_message}` : ''}${!['', undefined, null].includes(data.additional_comment) ? `, ${data.additional_comment}` : ''}`
                // yield put({ type: ccs.ADD_SYSTEM_GENERATED_MESSAGES_REQUEST, payload: { conversationId: data.ticket_id, data: { message, useConversationId: true } } })
            }
            if (reportPM) {
                yield put({ type: tickets.REPORT_PM, payload: response.data.data });
                if (refresh) {
                    yield put({ type: tickets.VIEW_TICKET_REQUEST, payload: { ticketId: data.ticket_id } });
                }
            }
        } else {
            yield put({ type: tickets.HOLD_TICKET_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.HOLD_TICKET_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* getSiteBOQList(action) {
    const { ticket_id } = action.payload;
    try {
        yield put({ type: loading.SET_IS_SITE_BOQ_LOADING, payload: true });
        const response = yield call(api.ticketExecution.getFESiteBOQ, {}, {}, { ticket_id });
        if (response.data.code === 200) {
            yield put({ type: tickets.GET_SITE_BOQ_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: tickets.GET_SITE_BOQ_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.GET_SITE_BOQ_FAILURE, payload: error });
    }
    yield put({ type: loading.SET_IS_SITE_BOQ_LOADING, payload: false });
}

export function* saveFeSiteBOQData(action) {
    const { formValues, ticket, ticketExecution = true, formName, navigate, closeModal = false, source, onSuccess = () => { } } = action.payload;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    try {
        const form = yield call(generateBoqExecutionStepForm, formValues, ticket);
        const response = yield call(api.ticketExecution.updateSiteBOQ, { 'Content-Type': `multipart/form-data;` }, {}, {}, form);
        if (response.data.code === 200) {
            if (typeof onSuccess === 'function' || source === 'editPayout') {
                onSuccess();
            }
            if (source === 'editPayout') {
                yield put({ type: tickets.GET_SITE_BOQ_REQUEST, payload: { ticket_id: ticket.id } });
            }
            if (ticket && ticketExecution && source !== 'editPayout') {
                if (ticket.fk_template_id === 0) {
                    if (ticket.fk_sign_off_template_id > 0) {
                        navigate(`/tickets/${ticket.id}/execute/sign-off`);
                    } else {
                        navigate(`/tickets/${ticket.id}/execute/approval`);
                    }
                } else {
                    navigate(`/tickets/${ticket.id}/execute/wip`);
                }
            }
            if (closeModal) {
                yield put({ type: modal.CLOSE_MODAL });
            }
            yield put({ type: tickets.SAVE_FE_SITE_BOQ_SUCCESS, payload: response.data });
        } else {
            yield put({ type: tickets.SAVE_FE_SITE_BOQ_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.SAVE_FE_SITE_BOQ_FAILURE, payload: error });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* saveFePrequisite(action) {
    const { form } = action.payload;
    try {
        const response = yield call(api.ticketExecution.savePrequisite, { 'Content-Type': `multipart/form-data;` }, {}, {}, form);
        if (response.data.code === 200) {
            yield put({ type: tickets.SAVE_FE_PRE_REQUISITE_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: tickets.SAVE_FE_PRE_REQUISITE_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.SAVE_FE_PRE_REQUISITE_FAILURE, payload: error });
    }
}

export function* uploadSignOffReport(action) {
    yield put({ type: tickets.TICKETS_PUT_TO_STORE_REQUEST, payload: { signoffImagesUploadLoading: true } });
    const { formValues, showToast = false, files, formName, updateViewTicket = false } = action.payload;
    if (formName) {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    }
    try {
        const fileMap = new Map([...files].map((file) => [file.name, file]));
        const files_data = [...files].map((file) => ({ fileName: file.name }));
        // get the signed urls
        const signedUrlRes = yield call(lambdas.tickets.signedUrlsForSignoffUpload, {}, {}, {}, { ticket_id: formValues.ticket_id, files_data });
        if (signedUrlRes.data.code === 200) {
            // do PUT requests on these signed urls so that the files get uplaoded to s3
            yield* signedUrlRes.data.data.map(({ signedUrl, fileName }) => {
                const file = fileMap.get(fileName);
                if (!file) return null;
                return call(uploadFileToS3WithSignedUrl, signedUrl, fileMap.get(fileName));
            });
            const response = yield call(lambdas.tickets.uploadSignOff, {}, {}, {}, { ticket_id: formValues.ticket_id, sign_off_report: signedUrlRes.data.data });
            if (response.data.code === 200) {
                yield put({ type: tickets.UPLOAD_SIGN_OFF_REPORT_SUCCESS, payload: response.data.data });
                if (showToast) {
                    yield all([
                        yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Sign Off Uploaded Successfully!!!' } }),
                        yield put({ type: modal.CLOSE_MODAL }),
                    ]);
                }
                if (updateViewTicket) {
                    yield put({ type: tickets.UPDATE_VIEW_TICKET, payload: response.data.files });
                }
            } else {
                if (response.data.code === 400) {
                    yield put({
                        type: toast.SEND_TOAST,
                        payload: {
                            status: 'danger',
                            message:
                                response.data.data.code === 'BLURRY'
                                    ? `Images ${response.data.data.blurryImages.join(', ')} are blurry. Please reupload.`
                                    : response.data.data.message,
                        },
                    });
                }
                yield put({ type: tickets.UPLOAD_SIGN_OFF_REPORT_FAILURE, payload: { message: 'Something Went wrong' } });
            }
        } else {
            yield put({ type: tickets.UPLOAD_SIGN_OFF_REPORT_FAILURE, payload: { message: 'Something Went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.UPLOAD_SIGN_OFF_REPORT_FAILURE, payload: error });
    }
    yield put({ type: tickets.TICKETS_PUT_TO_STORE_REQUEST, payload: { signoffImagesUploadLoading: false } });
    if (formName) {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
    }
}

export function* updateRLCourierDetails(action) {
    const { form } = action.payload;
    try {
        const response = yield call(api.tickets.updateRLCourierDetails, { 'Content-Type': `multipart/form-data;` }, {}, {}, form);
        if (response.data.code === 200) {
        } else {
            yield put({ type: tickets.UPDATE_RL_COURIER_DETAILS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.UPDATE_RL_COURIER_DETAILS_FAILURE, payload: { message: error } });
    }
}

export function* emailReport(action) {
    const { data, downloadReport = false } = action.payload;
    try {
        yield put({ type: loading.IS_REGENARATE_REPORT_LOADING, payload: true });
        const response = yield call(api.ticketExecution.sendEmailReport, {}, {}, {}, data);
        if (response.data.code === 200) {
            if (downloadReport) {
                yield put({ type: tickets.TICKET_REPORT_LINK_REQUEST, payload: { ticketId: data.ticket_id, download: false, popError: false, loading: false } });
            }
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Report Mailed Successfully!!!' } });
            yield put({ type: tickets.EMAIL_REPORT_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: tickets.EMAIL_REPORT_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.EMAIL_REPORT_FAILURE, payload: error });
    }
    yield put({ type: loading.IS_REGENARATE_REPORT_LOADING, payload: false });
}

export function* approveTicketSignOff(action) {
    const { data, isRefresh, navigateBack, navigate, closeModal = true, formName } = action.payload;
    const { tab } = yield select(getPaymentFilterData);
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } })
    try {
        const response = yield call(api.ticketExecution.approveSignOff, {}, {}, {}, data);
        if (response.data.code === 200) {
            yield all([yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Ticket SignOff Approved' } }), yield put({ type: modal.CLOSE_MODAL })]);
            if (isRefresh) {
                yield put(refreshPage({ tab }));
            }
            if (navigateBack) {
                navigate(-1);
            }
        } else if (response.data.code === 400) {
            yield all([
                yield put({
                    type: toast.SEND_TOAST,
                    payload: { status: 'danger', message: 'Ticket SignOff is Approved but Invoice is not generated. Please retrigger from Payments' },
                }),
                yield put({ type: modal.CLOSE_MODAL }),
            ]);
        } else {
            yield put({ type: tickets.APPROVE_TICKET_SIGNOFF_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
        if (closeModal) {
            yield put({ type: modal.CLOSE_MODAL });
        }
    } catch (error) {
        yield put({ type: tickets.APPROVE_TICKET_SIGNOFF_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } })
}

export function* initiateTicket(action) {
    const { ticketId, serviceTypeId, sign_off_response = 'Completed', intention, formName, navigate, ticketExecution = false, refresh, updateReachedSite = false, closeModal = false } = action.payload;
    const user = yield select(getLoggedUser);
    if (ticketExecution || updateReachedSite) {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    } else {
        yield put({ type: loading.REACHED_SITE_STATUS_LOADING, payload: { intention, formName, ticketId, isLoading: true } });
    }
    try {
        const response = yield call(api.ticketExecution.initiateTicket, {}, {}, {}, { ticket_id: ticketId, sign_off_response });
        if (response.data.code === 200) {
            if (closeModal) {
                yield put({ type: modal.CLOSE_MODAL });
            }
            if (updateReachedSite) {
                yield put({ type: tickets.UPDATE_REACHED_SITE_IN_TICKET_DETAILS, payload: { ticketId, reachedSite: 1 } });
            };

            if (ticketExecution) {
                yield all([
                    yield put({ type: tickets.INITIATE_TICKET_SUCCESS_IN_EXECUTION, payload: response.data.data }),
                    yield put({ type: tickets.START_TICKET_EXECUTION_SUCCESS, payload: true }),
                    yield put({ type: modal.CLOSE_MODAL }),
                ]);
                if ([8, 12].includes(serviceTypeId)) {
                    navigate(`/tickets/${ticketId}/execute/wip`);
                }
            } else {
                yield put({ type: tickets.INITIATE_TICKET_SUCCESS, payload: { ticketId, responseTicketData: response.data.data, user } });
            }
            if (refresh) {
                if (window.location.pathname === todayStatus.path) {
                    yield put({ type: tickets.REFRESH_TODAY_STATUS_TABLE_REQUEST });
                }
            }
        } else {
            yield put({ type: tickets.INITIATE_TICKET_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.INITIATE_TICKET_FAILURE, payload: { message: error } });
    }
    if (ticketExecution || updateReachedSite) {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
    } else {
        yield put({ type: loading.REACHED_SITE_STATUS_LOADING, payload: { intention, formName, ticketId, isLoading: false } });
    }
}

export function* getExecutionSteps(action) {
    const { ticket, data, editWorkFlow = false, navigate } = action.payload;
    try {
        const response = yield call(api.ticketExecution.getSteps, {}, {}, {}, data);
        if (response.data.code === 200) {
            if (Array.isArray(response.data.data) && response.data.data.length === 0) {
                if (editWorkFlow) {
                    yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Ticket is closed!!! You cannot execute the ticket' } });
                    navigate(`/tickets`);
                } else {
                    navigate(`/tickets/${ticket.id}/execute/approval`);
                }
            }
            yield put({ type: tickets.GET_WORK_IN_PROGRESS_STEPS_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: tickets.GET_WORK_IN_PROGRESS_STEPS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.GET_WORK_IN_PROGRESS_STEPS_FAILURE, payload: error });
    }
}

export function* getExecutionStepsFields(action) {
    const { data, source } = action.payload;
    try {
        const response = yield call(api.ticketExecution.getStepsFields, {}, {}, {}, data);
        if (response.data.code === 200) {
            if (source === 'chat_header') {
                yield put({ type: tickets.GET_WORK_IN_PROGRESS_CHAT_HEADER_STEPS_FIELDS_SUCCESS, payload: { data: response.data.data.single, ticket_id: data.ticket_id } });
            } else {
                yield put({ type: tickets.GET_WORK_IN_PROGRESS_STEPS_FIELDS_SUCCESS, payload: response.data.data });
            }
        } else {
            yield put({ type: tickets.GET_WORK_IN_PROGRESS_STEPS_FIELDS_SUCCESS, payload: { message: 'done all' } });
        }
    } catch (error) {
        yield put({ type: tickets.GET_WORK_IN_PROGRESS_STEPS_FIELDS_FAILURE, payload: error });
    }
}

export function* updateExecutionStepData(action) {
    const { formValues, formName, ticket, endWip, nextStep, editWorkFlow = false, navigate } = action.payload;
    try {
        if (formName) {
            yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        }
        const { form, filesToPost } = yield call(generateExecutionStepForm, formValues, ticket);
        const response = yield call(api.ticketExecution.updateStepData, { 'Content-Type': `multipart/form-data;` }, {}, {}, form);
        if (response.data.code === 200) {
            yield put({ type: tickets.REMOVE_FROM_TICKETS_STORE_REQUEST, payload: { type: 'GET_WORK_IN_PROGRESS_STEPS_FIELDS_SUCCESS' } });
            yield put({ type: tickets.REMOVE_FROM_TICKETS_STORE_REQUEST, payload: { type: 'GET_WORK_IN_PROGRESS_STEPS_FIELDS_FAILURE' } });
            if (endWip) {
                if (editWorkFlow) {
                    const emails = new Set();
                    if (!['', undefined, null].includes(ticket.sp_email)) {
                        emails.add(ticket.sp_email);
                    }
                    if (!['', undefined, null].includes(ticket.pm_email)) {
                        emails.add(ticket.pm_email);
                    }
                    if (!['', undefined, null].includes(ticket.fe_email)) {
                        emails.add(ticket.fe_email);
                    }
                    if (!['', undefined, null].includes(ticket.project_contact_person_email_address)) {
                        emails.add(ticket.project_contact_person_email_address);
                    }
                    if (config.name === prodConfig.name) {
                        emails.add('nitin@scogo.in');
                    } else {
                        emails.add('alpesh@scogo.in');
                    }
                    const emailReportPayload = {
                        ticket_id: ticket.id,
                        emails: Array.from(emails).join(','),
                    };
                    yield put({ type: tickets.EMAIL_REPORT_REQUEST, payload: { data: emailReportPayload, downloadReport: true } });
                    navigate('/tickets');
                } else {
                    if (ticket.fk_sign_off_template_id > 0) {
                        navigate(`/tickets/${ticket.id}/execute/sign-off`);
                    } else {
                        navigate(`/tickets/${ticket.id}/execute/approval`);
                    }
                }
            } else {
                const executionStepsPayload = {
                    ticket_id: ticket.id,
                    use_case_service_type_relationship_id: ticket.use_case_service_type_relationship_id,
                    template_id: ticket.fk_template_id,
                };
                yield all([
                    yield put({ type: tickets.GET_WORK_IN_PROGRESS_STEPS_REQUEST, payload: { ticket: ticket, data: executionStepsPayload, navigate } }),
                    yield put({ type: tickets.UPDATE_WORK_IN_PROGRESS_STEP_DATA_SUCCESS, payload: response.data.data }),
                ]);
                if (!isNaN(nextStep) && parseInt(nextStep, 10) > 0) {
                    if (editWorkFlow) {
                        navigate(`?editWorkFlow=true&stepId=${nextStep}`);
                    } else {
                        navigate(`?stepId=${nextStep}`);
                    }
                }
            }
            if (ticket && Array.isArray(filesToPost) && filesToPost.length > 0) {
                yield put({ type: conversations.CHAT_UPLOAD_FILES_REQUEST, payload: { _id: ticket.id, fileData: filesToPost, bot: true, useConversationId: true } });
            }
        } else {
            yield put({ type: tickets.UPDATE_WORK_IN_PROGRESS_STEP_DATA_FAILURE, payload: { message: 'Something Went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.UPDATE_WORK_IN_PROGRESS_STEP_DATA_FAILURE, payload: error });
    }
    if (formName) {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
    }
}

export function* ticketsPutToStore(action) {
    yield put({ type: tickets.TICKETS_PUT_TO_STORE_SUCCESS, payload: action.payload });
}

export function* signOffCustomerTicket(action) {
    const { ticket_no, status, formName, customer_rejection_message } = action.payload;
    let payload = {
        ticket_no,
        status,
    };
    if (customer_rejection_message) payload.customer_rejection_message = customer_rejection_message;

    try {
        if (formName) {
            yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        }
        const response = yield call(api.tickets.customerSignOff, {}, {}, {}, payload);
        if (response.data.code === 200) {
            let message = `Ticket Sign Off ${status === 1 ? 'Accepted' : 'Rejected'}!!!`;
            let toastColor = `${status === 1 ? 'success' : 'danger'}`;
            yield put({ type: toast.SEND_TOAST, payload: { status: toastColor, message } });
            yield put({ type: modal.CLOSE_MODAL });
            yield put({ type: tickets.REFRESH_TICKET_TABLE_REQUEST });
        } else {
            let toastColor = 'danger';
            let message = response.data.message;
            yield put({ type: toast.SEND_TOAST, payload: { status: toastColor, message } });
            yield put({ type: tickets.CUSTOMER_TICKET_SIGNOFF_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.CUSTOMER_TICKET_SIGNOFF_FAILURE, payload: { message: error } });
    }
    if (formName) {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
    }
}

export function* rejectTicketSignOff(action) {
    const { data, formName } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(api.ticketExecution.rejectSignOff, {}, {}, {}, data);
        if (response.data.code === 200) {
            let message = `Ticket SignOff Rejected!!!`;
            let status = 'danger';
            yield all([
                yield put({ type: toast.SEND_TOAST, payload: { status, message } }),
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: tickets.REFRESH_TICKET_TABLE_REQUEST }),
            ]);
        } else {
            yield put({ type: tickets.REJECT_TICKET_SIGNOFF_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.REJECT_TICKET_SIGNOFF_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* getSignOffSteps(action) {
    const { ticketId, viewTicket = false } = action.payload;
    try {
        const response = yield call(lambdas.tickets.getSignOffSteps, {}, {}, { ticketId });
        if (response.data.code === 200) {
            if (viewTicket) {
                const ticket = yield call(api.tickets.viewTicket, {}, {}, { ticket_id: ticketId });
                if (ticket.data.code === 200) {
                    yield put({ type: tickets.VIEW_TICKET_SUCCESS, payload: ticket.data.data });
                }
            }
            yield put({ type: tickets.GET_SIGN_OFF_STEPS_SUCCESS, payload: response.data.steps });
            yield put({ type: tickets.GET_SIGN_OFF_STEP_VALUES_SUCCESS, payload: response.data.values });
        } else {
            yield put({ type: tickets.GET_SIGN_OFF_STEPS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.GET_SIGN_OFF_STEPS_FAILURE, payload: error });
    }
}

export function* updateSignOffSteps(action) {
    const { ticketId, data, download = false, ticketExecution = false, editSignOffForm = false, formName, navigate } = action.payload;
    if (formName) {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    }
    if (download) {
        yield put({ type: loading.IS_DOWNLOAD_REPORT_LOADING, payload: true });
    }
    try {
        if (data && Array.isArray(data.files) && data.files.length === 1) {
            const response = yield call(lambdas.tickets.updateSignOffSteps, {}, {}, {}, { ticketId, files: [{ name: data.files[0].path }] });
            if (response.data.code === 200) {
                if (response.data.data && response.data.data.signedUrl) {
                    yield call(uploadFileToS3WithSignedUrl, response.data.data.signedUrl, data.files[0]);
                }
            }
        }
        const form = yield call(generateSignOffStepForm, ticketId, data);
        form.download = download;
        const response = yield call(lambdas.tickets.updateSignOffSteps, {}, {}, {}, form);
        if (response.data.code === 200) {
            if (download && response.data.data && response.data.data.document) {
                window.open(response.data.data.document, '_blank');
            }
            if (ticketExecution) {
                if (editSignOffForm) {
                    navigate('/tickets');
                } else {
                    navigate(`/tickets/${ticketId}/execute/approval`);
                }
            }
        }
        if (!download) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: response.data.data.message } });
        }
    } catch (error) {
        yield put({ type: tickets.UPDATE_SIGN_OFF_STEPS_FAILURE, payload: { message: error } });
    }
    if (formName) {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
    }
    if (download) {
        yield put({ type: loading.IS_DOWNLOAD_REPORT_LOADING, payload: false });
    }
}

export function* updateExecutionSteps(action) {
    const { formValues, formName, closeModal, refresh, source, ticket_id } = action.payload;
    try {
        if (formName) {
            yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        }
        const response = yield call(api.ticketExecution.updateStepData, { 'Content-Type': `multipart/form-data;` }, {}, {}, formValues);
        if (response.data.code === 200) {
            if (closeModal) yield put({ type: modal.CLOSE_MODAL });
            if (refresh) {
                yield put({ type: tickets.UPDATE_EXECUTION_STEP_DATA_SUCCESS, payload: { step: response.data.data['1'], source, ticket_id } });
            }

        } else {
            yield put({ type: tickets.UPDATE_WORK_IN_PROGRESS_STEP_DATA_FAILURE, payload: { message: 'Something Went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.UPDATE_WORK_IN_PROGRESS_STEP_DATA_FAILURE, payload: error });
    }
    if (formName) {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
    }
}

export default function* ticketExecutionSaga() {
    yield takeLatest(tickets.START_TICKET_EXECUTION_REQUEST, startTicketExecutionManually);
    yield takeLatest(tickets.INITIATE_TICKET_REQUEST, initiateTicket);
    yield takeLatest(tickets.HOLD_TICKET_REQUEST, holdTicket);
    yield takeLatest(tickets.GET_SITE_BOQ_REQUEST, getSiteBOQList);
    yield takeLatest(tickets.SAVE_FE_SITE_BOQ_REQUEST, saveFeSiteBOQData);
    yield takeLatest(tickets.SAVE_FE_PRE_REQUISITE_REQUEST, saveFePrequisite);
    yield takeLatest(tickets.UPLOAD_SIGN_OFF_REPORT_REQUEST, uploadSignOffReport);
    yield takeLatest(tickets.UPDATE_RL_COURIER_DETAILS_REQUEST, updateRLCourierDetails);
    yield takeLatest(tickets.EMAIL_REPORT_REQUEST, emailReport);
    yield takeLatest(tickets.APPROVE_TICKET_SIGNOFF_REQUEST, approveTicketSignOff);
    yield takeEvery(tickets.GET_WORK_IN_PROGRESS_STEPS_REQUEST, getExecutionSteps);
    yield takeEvery(tickets.GET_WORK_IN_PROGRESS_STEPS_FIELDS_REQUEST, getExecutionStepsFields);
    yield takeLatest(tickets.UPDATE_WORK_IN_PROGRESS_STEP_DATA_REQUEST, updateExecutionStepData);
    yield takeLatest(tickets.TICKETS_PUT_TO_STORE_REQUEST, ticketsPutToStore);
    yield takeLatest(tickets.CUSTOMER_TICKET_SIGNOFF_REQUEST, signOffCustomerTicket);
    yield takeLatest(tickets.REJECT_TICKET_SIGNOFF_REQUEST, rejectTicketSignOff);
    yield takeLatest(tickets.GET_SIGN_OFF_STEPS_REQUEST, getSignOffSteps);
    yield takeLatest(tickets.UPDATE_SIGN_OFF_STEPS_REQUEST, updateSignOffSteps);
    yield takeLatest(tickets.UPDATE_EXECUTION_STEP_DATA_REQUEST, updateExecutionSteps);
}
