import { isCluster, isCustomer, isCustomerAdmin, isCustomerUser, isFe, isIsp, isPsp, isScm, isSp, isSpIsp, isSuperVisor } from '../../utils/role';

export const getIsToShowRateCard = (roleId, type) => {
    if (!isScm(roleId, type)) return true;
    return false;
};

export const getIsToShowBankCard = (roleId, type) => {
    if (isCustomer(roleId, type) || isCustomerAdmin(roleId, type) || isCustomerUser(roleId, type)) return false;
    return true;
};

export const getIsToShowPaymentTerms = (roleId, type) => {
    if (isIsp(roleId, type)) return false;
    return true;
};

export const getIsToShowShareProfileIcon = (roleId, type) => {
    if (isSp(roleId, type) || isPsp(roleId, type) || isFe(roleId, type)) return true;
    return false;
};

export const getIsToShowWorkingHours = (roleId, type) => {
    if (isSp(roleId, type) || isFe(roleId, type) || isCluster(roleId, type) || isPsp(roleId, type)) return true;
    return false;
};

export const getIsToShowReffralCode = (roleId, type) => {
    if (isSp(roleId, type) || isSpIsp(roleId, type) || isIsp(roleId, type)) return true;
    return false;
};

export const getIsToShowPaymentPercentage = (roleId, type) => {
    if (isScm(roleId, type) || isCluster(roleId, type) || isSuperVisor(roleId, type) || isPsp(roleId, type)) return true;
    return false;
};

export const getIsToShowPossibleTechSupport = (roleId, type) => {
    if (isScm(roleId, type) || isCluster(roleId, type) || isSuperVisor(roleId, type) || isPsp(roleId, type)) return true;
    return false;
};

export const getIsToShowPaymentRatio = (roleId, type) => {
    if (isScm(roleId, type) || isCluster(roleId, type) || isSuperVisor(roleId, type) || isPsp(roleId, type) || isSp(roleId, type) || isIsp(roleId, type)) return true;
    return false;
};

export const getIsToShowGSTLabel = (roleId, type) => {
    if (isFe(roleId, type)) return false;
    return true;
};

export const getIsToShowSecurityDepositButton = (roleId, type) => {
    if (isCluster(roleId, type) || isPsp(roleId, type) || isSuperVisor(roleId, type)) return true;
    return false;
};

export const getIstoShowBuisnessDetails = (roleId, type) => {
    if (isScm(roleId, type) || isFe(roleId, type)) return false;
    return true;
};

export const getIsToShowMapBoundry = (roleId, type) => {
    if (isCluster(roleId, type) || isPsp(roleId, type)) return true;
    return false;
};

export const getIsToShowMapMarker = (roleId, type) => {
    if (isSp(roleId, type) || isPsp(roleId, type)) return true;
    return false;
};

export const getIsToShowCustomerLayout = (roleId, type) => {
    if (isCustomer(roleId, type) || isCustomerAdmin(roleId, type) || isCustomerUser(roleId, type)) return true;
    return false;
};
