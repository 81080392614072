import React from 'react';
import { SimpleButton } from './SimpleButton';

const ButtonScogoOrange = ({
    btnimgClass = 'w-4 h-4 mr-1 ',
    buttonIcon,
    Btnalt = 'img',
    textOrComponent,
    onClick,
    loading,
    disabled = false,
    buttonIconComponent,
    loaderColor,
    hoverCss = 'hover:bg-scogoorange hover:text-white',
    disabledClass = 'text-black flex items-center justify-center rounded-6px shadow-primaryBtnShadow text-xl gap-1 font-medium relative cursor-not-allowed border-scogoddd bg-scogoddd',
    buttonPadding = 'px-2',
    buttonClass = '',
    type = 'button',
}) => {
    if (!disabled) disabled = loading;

    const btnClass = disabled
        ? `${disabledClass} ${buttonPadding}`
        : `bg-white border flex items-center justify-center border-scogoorange leading-6 cursor-pointer text-scogoorange rounded-md gap-1 font-medium cursor-pointer ${hoverCss} ${buttonClass} ${buttonPadding}`;

    return (
        <SimpleButton
            type={type}
            loading={loading}
            btnClass={btnClass}
            onClick={onClick}
            disabled={disabled}
            loaderColor={loaderColor}
            buttonIcon={buttonIcon}
            buttonIconComponent={buttonIconComponent}
            btnimgClass={btnimgClass}
            Btnalt={Btnalt}
            textOrComponent={textOrComponent}
        />
    );
};

export default ButtonScogoOrange;
