import React, { useEffect } from 'react';
import { textCss } from '../common/Css';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerProfileDetails } from '../actions/customer';
import { getCustomerIdOfLoginUser } from '../utils/utils';
import { RaisePOForm } from '../components/RaisePO/RaisePoForm';
import IconToolTip from '../common/IconToolTip';
import { closeFullScreenModalAction } from '../actions/modal';

export default function RaisePo({ ticketId, heading = 'Raise PO' }) {
    const dispatch = useDispatch();
    const { loggedUser } = useSelector((state) => state.auth);
    const customerIdOfLoginUser = getCustomerIdOfLoginUser(loggedUser);

    useEffect(() => {
        if (customerIdOfLoginUser > 0) {
            dispatch(getCustomerProfileDetails(customerIdOfLoginUser));
        }
    }, [dispatch, customerIdOfLoginUser]);

    const closeModal = () => dispatch(closeFullScreenModalAction());

    useEffect(() => {
        document.title = "RaisePo";
    }, []);


    return (
        <div className='subContainer title-4 px-5 pt-5'>
            <div className={`titleContainer sticky  w-full z-20`}>
                <div className='md:flex max-w-full justify-between'>
                    <div>
                        <h1 className={textCss?.moduleTitle}>{heading}</h1>
                    </div>
                    <div className='ml-auto cursor-pointer' onClick={closeModal}>
                        <IconToolTip title='Close'>
                            <span className='material-icons text-scogoprimary font-medium align-middle text-4xl hover:text-scogoorange'>highlight_off</span>
                        </IconToolTip>
                    </div>
                </div>
            </div>
            <RaisePOForm closeModal={closeModal} ticketId={ticketId} />
        </div>
    );
}
