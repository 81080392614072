import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFieldArray, useFormContext } from 'react-hook-form';
import debounce from 'lodash/debounce'
import { v4 as uuidv4 } from 'uuid';

import EditTicketCard from './EditTicketCard';

import Input from '../../common/Form/Input';
import Select from '../../common/Form/Select';
import { getBOQList } from '../../actions/tickets';

import ButtonScogoOrange from '../../common/Buttons/ButtonScogoOrange';
import MaterialIconToolTip from '../../common/MaterialIconToolTip';

import microservices from '../../lambdas/microservices';
import QRCodeScanner from '../../pages/SupportTrack/QrScanner';

const absentSerialNumbers = new Set();
const AssetsCard = ({ ticket, assetsArrayName, assets, ticketCustomerId, headerText = 'Asset Details', className = '', readOnly, siteId, fieldsClass = '', showScanner = false, onScan, mainDivClass = '' }) => {
    const { control, setValue, setError, watch } = useFormContext();
    const { fields, append, remove, update } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: assetsArrayName, // unique name for your Field Array
    });

    const { boqList } = useSelector((state) => state.tickets);
    const { boqAssetsLoading } = useSelector((state) => state.loading);
    const dispatch = useDispatch();

    /** get assets in the effect if we have assets against the ticket already for pre fill */
    useEffect(() => {
        if (assets?.length > 0) {
            dispatch(getBOQList(ticketCustomerId));
        }
    }, [dispatch, assets, ticketCustomerId]);

    /** pre select the asset type in the ticket among the options */
    useEffect(() => {
        if (boqList && assets?.length > 0) {
            assets.forEach((asset, i) => {
                update(i, {
                    asset_type: boqList.find((assetType) => asset.asset_type_id === assetType.value),
                    assetId: asset.id,
                    serial_number: asset.serial_number,
                    make: asset.make,
                    model: asset.model,
                    quantity: asset.quantity,
                    partcode: asset.partcode,
                    external_order_id: asset.external_order_id,
                });
            });
        }
    }, [assetsArrayName, boqList, update, assets]);

    const [loadingIndex, setLoadingIndex] = useState(undefined)

    /** Scroll to the bottom when new assets are added */
    const containerRef = useRef();
    const prevNumRowsRef = useRef();
    const numRows = fields.length;
    useEffect(() => {
        if (numRows > prevNumRowsRef.current) {
            containerRef?.current?.scroll({ top: containerRef.current.scrollHeight, behavior: 'smooth' });
        }
        prevNumRowsRef.current = numRows;
    }, [numRows]);

    const setAssetInFieldArray = (asset, assetType, index) => {
        setValue(`${assetsArrayName}[${index}].assetId`, asset.id);
        setValue(`${assetsArrayName}[${index}].asset_type`, assetType);
        setValue(`${assetsArrayName}[${index}].make`, asset.make);
        setValue(`${assetsArrayName}[${index}].model`, asset.model);
        setValue(`${assetsArrayName}[${index}].quantity`, asset.quantity);
        setValue(`${assetsArrayName}[${index}].partcode`, asset.partcode);
        setValue(`${assetsArrayName}[${index}].external_order_id`, asset.external_order_id);
        setValue(`${assetsArrayName}[${index}].warehouse_id`, asset.warehouse_id);
    };

    const assetInSystem = useCallback(debounce(async ({ serialNumber, index, boqList, siteId }) => {
        if (ticket?.customer_details?.check_serial_number) {
            setLoadingIndex(index)
            let throwError = false;
            const queryParams = { limit: 1, page: 1, tag: serialNumber };
            if (siteId) queryParams.siteId = siteId;
            const response = await microservices.inventory.getAllAssets({}, {}, queryParams);
            if (response.data.code === 200) {
                const asset = response.data.data[0]
                const assetType = boqList.find((assetType) => ((asset?.asset_type_id === assetType.value) || (asset?.title === assetType.value) || (asset?.assetType?.id === assetType.value)));
                if (asset && assetType) {
                    if (absentSerialNumbers.has(serialNumber)) {
                        absentSerialNumbers.delete(serialNumber)
                    }
                    setAssetInFieldArray(asset, assetType, index);
                } else {
                    throwError = true
                }
            } else {
                throwError = true
            }
            if (throwError) {
                absentSerialNumbers.add(serialNumber);
                setError(`${assetsArrayName}[${index}].serial_number`, {
                    type: 'serialNumberNotInSystem',
                    message: 'Serial Number not in system',
                });
            }
            setLoadingIndex(undefined)
        }
    }, 500), []);

    const onSerialNumberChange = (serialNumber, index) => {
        assetInSystem({ serialNumber, index, boqList, siteId })
    }

    return (
        <EditTicketCard className={`edit-ticket-asset overflow-y-hidden ${className}`}>
            <div className='text-font13 pl-3 font-bold border-b w-full py-2'>{headerText}</div>
            <div ref={containerRef}>
                {/**need this div because of height calculations in index.css */}
                {fields.map((field, index) => {
                    const selectedAssetType = watch(`${assetsArrayName}[${index}].asset_type`);
                    const assetId = watch(`${assetsArrayName}[${index}].assetId`);
                    return (
                        <div key={field.id} className={`flex border-b border-scogoddd py-2 items-center ${mainDivClass}`} >
                            <div className={fieldsClass ? fieldsClass : `flex items-center w-11/12 text-font11 text-black font-normal pl-2`}>
                                <Select
                                    className='grow'
                                    style={fieldsClass ? {} : { minWidth: '20%' }}
                                    name={`${assetsArrayName}[${index}].asset_type`}
                                    label='Asset Type'
                                    options={boqList}
                                    isLoading={boqAssetsLoading}
                                    required
                                    readOnly={readOnly || assetId > 0}

                                />
                                {selectedAssetType?.inputFields?.split(',')?.map?.((inputField) => {
                                    const requiredFields = selectedAssetType.requiredFields?.split(',') || [];
                                    return (
                                        <Input
                                            key={inputField}
                                            name={`${assetsArrayName}[${index}].${inputField}`}
                                            required={requiredFields.includes(inputField)}
                                            label={inputField.replace('_', ' ')}
                                            labelClassName='capitalize'
                                            validate={{
                                                serialNumberNotInSystem: (serialNumber) => {
                                                    return serialNumber === '' || !absentSerialNumbers.has(serialNumber) || 'Serial Number not in system';
                                                },
                                            }}
                                            isLoading={inputField === 'serial_number' && loadingIndex === index}
                                            onChange={async (e) => {
                                                if (inputField === 'serial_number' && e.target.value?.length > 0) {
                                                    if (ticket?.customer_details?.check_serial_number) {
                                                        onSerialNumberChange(e.target.value, index);
                                                    }
                                                }
                                            }}
                                            readOnly={readOnly || (assetId > 0 && requiredFields.includes(inputField) && !field.newlyAdded)}
                                        />
                                    );
                                })}
                            </div>
                            <div className='flex items-center gap-3'>
                                {field.req_to_delete === 1 ? (
                                    <div className='px-1 mt-3'>
                                        <span className='px-1 py-1 text-font07 rounded-3px bg-white text-scogoclosed border border-scogoclosed'>Removed</span>
                                        <MaterialIconToolTip
                                            onClick={() => update(index, { ...field, req_to_delete: 0 })}
                                            title='Restore'
                                            materialIconName='undo'
                                            materialIconClass='material-icons text-font16 cursor-pointer text-scogoprimary hover:text-scogoorange px-3'
                                        />
                                    </div>
                                ) : (
                                    <MaterialIconToolTip
                                        onClick={() => {
                                            if (readOnly) return;
                                            if (field.assetId > 0 && !field.newlyAdded) {
                                                update(index, { ...field, req_to_delete: 1 });
                                            } else {
                                                setLoadingIndex(undefined)
                                                remove(index);
                                            }
                                        }}
                                        title='Remove Asset'
                                        materialIconName='delete'
                                        materialIconClass={`material-icons text-font16  pl-4 ${readOnly ? 'cursor-not-allowed text-scogogray hover:text-scogoddd' : 'hover:text-scogoorange text-scogoToastDangerIcon cursor-pointer'}`}
                                    />
                                )}
                                {showScanner && !field.asset_type && <QRCodeScanner onScan={(value) => onScan(value, field.tempId)} label='Scan Asset' />}
                            </div>
                        </div>
                    );
                })}
                {!readOnly && <ButtonScogoOrange
                    buttonIconComponent={<PlusIcon />}
                    textOrComponent={'Add Asset'}
                    onClick={() => {
                        append({ asset_type: null, newlyAdded: true, tempId: uuidv4() });
                        if (!boqList && !boqAssetsLoading) {
                            dispatch(getBOQList(ticketCustomerId));
                        }
                    }}
                    buttonClass='my-4 ml-4 py-2'
                />}
            </div>
        </EditTicketCard>
    );
};

const PlusIcon = () => {
    return <span className='material-icons-outlined text-font11'>add</span>;
};

export default AssetsCard;
