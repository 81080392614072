export const CUSTOMERS_ADDONS_LIST_REQUEST = 'CUSTOMERS_ADDONS_LIST_REQUEST';
export const CUSTOMERS_ADDONS_LIST_SUCCESS = 'CUSTOMERS_ADDONS_LIST_SUCCESS';
export const CUSTOMERS_ADDONS_LIST_FAILURE = 'CUSTOMERS_ADDONS_LIST_FAILURE';
export const CUSTOMERS_ADDONS_LIST_LOADING = 'CUSTOMERS_ADDONS_LIST_LOADING';

export const CUSTOMERS_ADDONS_DROPDOWN_LIST_REQUEST = 'CUSTOMERS_ADDONS_DROPDOWN_LIST_REQUEST';
export const CUSTOMERS_ADDONS_DROPDOWN_LIST_SUCCESS = 'CUSTOMERS_ADDONS_DROPDOWN_LIST_SUCCESS';
export const CUSTOMERS_ADDONS_DROPDOWN_LIST_FAILURE = 'CUSTOMERS_ADDONS_DROPDOWN_LIST_FAILURE';
export const CUSTOMERS_ADDONS_DROPDOWN_LIST_LOADING = 'CUSTOMERS_ADDONS_DROPDOWN_LIST_LOADING';

export const CUSTOMERS_TEAMS_LIST_REQUEST = 'CUSTOMERS_TEAMS_LIST_REQUEST';
export const CUSTOMERS_TEAMS_LIST_SUCCESS = 'CUSTOMERS_TEAMS_LIST_SUCCESS';
export const CUSTOMERS_TEAMS_DROPDOWN_LIST_SUCCESS = 'CUSTOMERS_TEAMS_DROPDOWN_LIST_SUCCESS';
export const CUSTOMERS_TEAMS_LIST_FAILURE = 'CUSTOMERS_TEAMS_LIST_FAILURE';
export const CUSTOMERS_TEAMS_LIST_LOADING = 'CUSTOMERS_TEAMS_LIST_LOADING';

export const CUSTOMERS_TEAMS_BY_CUSTOMERID_LIST_REQUEST = 'CUSTOMERS_TEAMS_BY_CUSTOMERID_LIST_REQUEST';
export const CUSTOMERS_TEAMS_BY_CUSTOMERID_LIST_SUCCESS = 'CUSTOMERS_TEAMS_BY_CUSTOMERID_LIST_SUCCESS';
export const CUSTOMERS_TEAMS_BY_CUSTOMERID_LIST_FAILURE = 'CUSTOMERS_TEAMS_BY_CUSTOMERID_LIST_FAILURE';

export const CUSTOMERS_NEXT_TEAMS_LIST_REQUEST = 'CUSTOMERS_NEXT_TEAMS_LIST_REQUEST';
export const CUSTOMERS_NEXT_TEAMS_LIST_SUCCESS = 'CUSTOMERS_NEXT_TEAMS_LIST_SUCCESS';
export const CUSTOMERS_NEXT_TEAMS_LIST_FAILURE = 'CUSTOMERS_NEXT_TEAMS_LIST_FAILURE';
export const CUSTOMERS_NEXT_TEAMS_LIST_LOADING = 'CUSTOMERS_NEXT_TEAMS_LIST_LOADING';

export const PROJECT_LIST_NAME_REQUEST = 'PROJECT_LIST_NAME_REQUEST';
export const PROJECT_LIST_NAME_SUCCESS = 'PROJECT_LIST_NAME_SUCCESS';
export const PROJECT_LIST_NAME_FAILURE = 'PROJECT_LIST_NAME_FAILURE';
export const PROJECT_LIST_NAME_LOADING = 'PROJECT_LIST_NAME_LOADING';

export const INVITE_CUSTOMERS_ENDUSERS_REQUEST = 'INVITE_CUSTOMERS_ENDUSERS_REQUEST';
export const INVITE_CUSTOMERS_ENDUSERS_FAILURE = 'INVITE_CUSTOMERS_ENDUSERS_FAILURE';

export const CUSTOMERS_LIST_REQUEST = 'CUSTOMERS_LIST_REQUEST';
export const CUSTOMERS_LIST_SUCCESS = 'CUSTOMERS_LIST_SUCCESS';
export const CUSTOMERS_LIST_FAILURE = 'CUSTOMERS_LIST_FAILURE';

export const CUSTOMERS_ADDONS_EDIT_LIST_SUCCESS = 'CUSTOMERS_ADDONS_EDIT_LIST_SUCCESS';
export const SELECTED_USER_DETAILS = 'SELECTED_USER_DETAILS';
export const SELECTED_TEAM_USER_DETAILS = 'SELECTED_TEAM_USER_DETAILS';

export const EDIT_TEAM_SUCCESS = 'EDIT_TEAM_SUCCESS';
export const CLEANUP_CUSTOMER_TEAMS = 'CLEANUP_CUSTOMER_TEAMS';

export const CLEAR_DROPDOWN = 'CLEAR_DROPDOWN';

export const GET_CUSTOMER_VENDORS_LIST_REQUEST = 'GET_CUSTOMER_VENDORS_LIST_REQUEST';
export const GET_CUSTOMER_VENDORS_LIST_SUCCESS = 'GET_CUSTOMER_VENDORS_LIST_SUCCESS';
export const GET_CUSTOMER_VENDORS_LIST_FAILURE = 'GET_CUSTOMER_VENDORS_LIST_FAILURE';
export const GET_CUSTOMER_VENDORS_LIST_LOADING = 'GET_CUSTOMER_VENDORS_LIST_LOADING';

export const CUSTOMER_SERVICE_LOCATIONS_UPDATE = 'CUSTOMER_SERVICE_LOCATIONS_UPDATE';

export const GET_CUSTOMER_PROFILE_DETAILS_REQUEST = 'GET_CUSTOMER_PROFILE_DETAILS_REQUEST';
export const GET_CUSTOMER_PROFILE_DETAILS_SUCCESS = 'GET_CUSTOMER_PROFILE_DETAILS_SUCCESS';
export const GET_CUSTOMER_PROFILE_DETAILS_FAILURE = 'GET_CUSTOMER_PROFILE_DETAILS_FAILURE';

export const REMOVE_CUSTOMER_PROFILE_DETAILS_FROM_STORE = 'REMOVE_CUSTOMER_PROFILE_DETAILS_FROM_STORE';

export const CLEAR_CUSTOMERS_ADDONS_DROPDOWN_LIST = 'CLEAR_CUSTOMERS_ADDONS_DROPDOWN_LIST';
export const PUT_TO_CUSTOMER_STORE = 'PUT_TO_CUSTOMER_STORE';

export const UPDATE_CUSTOMER_PROFILE_DETAILS_REQUEST = 'UPDATE_CUSTOMER_PROFILE_DETAILS_REQUEST';
export const UPDATE_CUSTOMER_PROFILE_DETAILS_SUCCESS = 'UPDATE_CUSTOMER_PROFILE_DETAILS_SUCCESS';
export const UPDATE_CUSTOMER_PROFILE_DETAILS_FAILURE = 'UPDATE_CUSTOMER_PROFILE_DETAILS_FAILURE';

export const UPLOAD_CUSTOMER_LOGO_REQUEST = 'UPLOAD_CUSTOMER_LOGO_REQUEST';
export const UPLOAD_CUSTOMER_LOGO_SUCCESS = 'UPLOAD_CUSTOMER_LOGO_SUCCESS';
export const UPLOAD_CUSTOMER_LOGO_FAILURE = 'UPLOAD_CUSTOMER_LOGO_FAILURE';
export const REMOVE_PROJECT_LIST_NAME_FROM_STORE = 'REMOVE_PROJECT_LIST_NAME_FROM_STORE';
export const CLEAR_VENDORS_DATA_FROM_STORE = 'CLEAR_VENDORS_DATA_FROM_STORE';
export const RAISE_PO_VENDORS_LIST = 'RAISE_PO_VENDORS_LIST';
