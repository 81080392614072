import React, { useCallback, useEffect } from 'react';
import Input from '../../common/Form/Input';
import Radio from '../../common/Form/Radio';
import Select from '../../common/Form/Select';
import TextArea from '../../common/Form/TextArea';
import { useSelector, useDispatch } from 'react-redux';
import { clearVedorDataFromStore, getCustomerTeams, getCustomerVendorsData } from '../../actions/customer';
import { getCustomerIdOfLoginUser } from '../../utils/utils';
import { expenseTypes } from './RaisePoForm';
import { QuotesCard } from './QuotesCard';
import apis from '../../apis';
import { useFormContext } from 'react-hook-form';
import { listCustomerISP } from '../../actions/partners';
import { isCustomerGroup } from '../../utils/role';
import { getAllSitesDropdown, searchSiteWithExternalSiteId } from '../../actions/inventory';
import { debounce, findPincode } from '../../utils/common';
import { FetchGoogleAddress } from '../../pages/SupportTrack/RenderTicketCard';

const linkTypes = [
    { key: 'PRIMARY', value: 'PRIMARY', label: 'Primary' },
    { key: 'SECONDARY', value: 'SECONDARY', label: 'Secondary' },
    { key: 'TERNARY', value: 'TERNARY', label: 'Ternary' },
    { key: 'wireless_4g', value: 'wireless/4G', label: 'wireless/4G' },
];

export const RaisePoInputs = ({
    quotesArrayName,
    isCustomerFeasibilityEligible,
    setIsCustomerFeasibilityEligible,
    showCustomerDropdown,
    ticketId,
    viewTicket,
    getQuotesDefaultValues,
}) => {
    const { customerTeamsDropDown, customerTeamsLoading, customers, raisePOVendorsList } = useSelector((state) => state.customer);
    const { sitesDropdown } = useSelector((state) => state.inventory);
    const { isCustomerListLoading, isPartnersDropdownListLoading, isCustomerVendorsLoading, isAllSiteDropdownLoading } = useSelector((state) => state.loading);
    const dispatch = useDispatch();
    const { loggedUser } = useSelector((state) => state.auth);
    const role = loggedUser.role.id;
    const loggedUserCustomerId = getCustomerIdOfLoginUser(loggedUser);
    const isLoggedUserIsCustomer = isCustomerGroup(role);

    const { watch, setValue } = useFormContext();
    const fk_customer_id = watch('fk_customer_id');
    const supplier_vendor_1 = watch('supplier_vendor_1');
    const supplier_vendor_2 = watch('supplier_vendor_2');
    const supplier_vendor_3 = watch('supplier_vendor_3');
    const addressValue = watch('job_site_address_1')
    const getVendorList = useCallback(
        (is_feasibility_eligible, fk_customer_id) => {
            if (is_feasibility_eligible === 1) {
                dispatch(listCustomerISP({ customerId: fk_customer_id }));
            } else {
                dispatch(getCustomerVendorsData({ customer_id: fk_customer_id }));
            }
        },
        [dispatch]
    );

    useEffect(() => {
        let is_feasibility_eligible = loggedUser.is_feasibility_eligible === 1 ? true : false;
        if (viewTicket && ticketId) {
            is_feasibility_eligible = viewTicket.customer_details.is_feasibility_eligible;
        }
        if (fk_customer_id) {
            getVendorList(fk_customer_id.is_feasibility_eligible, fk_customer_id.value);
            if (fk_customer_id.is_feasibility_eligible === 1) {
                is_feasibility_eligible = true;
            }
            dispatch(clearVedorDataFromStore());
        } else dispatch(clearVedorDataFromStore());
        setValue(quotesArrayName, getQuotesDefaultValues(is_feasibility_eligible));
        setIsCustomerFeasibilityEligible(is_feasibility_eligible);

        if (isLoggedUserIsCustomer && !raisePOVendorsList && !isPartnersDropdownListLoading && !isCustomerVendorsLoading) {
            getVendorList(loggedUser.is_feasibility_eligible, loggedUser.customer_id);
        }
    }, [
        fk_customer_id,
        dispatch,
        setIsCustomerFeasibilityEligible,
        loggedUser?.is_feasibility_eligible,
        getQuotesDefaultValues,
        quotesArrayName,
        setValue,
        getVendorList,
        isLoggedUserIsCustomer,
        loggedUser.customer_id,
        ticketId,
        viewTicket,
    ]);

    useEffect(() => {
        let customerId = loggedUserCustomerId;
        if (fk_customer_id) {
            customerId = fk_customer_id.value;
        }
        if (viewTicket?.fk_customer_id > 0) {
            customerId = viewTicket?.fk_customer_id
        }
        dispatch(getCustomerTeams({ dropdown: true, customerId, agentsInfo: false }));
        dispatch(getAllSitesDropdown({ customerId }));
    }, [fk_customer_id, viewTicket, dispatch, loggedUserCustomerId]);

    const showTicketDetail = viewTicket && ticketId > 0;

    const searchSiteDropdown = (query, siteList, fk_customer_id) => {
        if (!query) return;
        let find = siteList.filter((site) => site.label.toLowerCase().includes(query));
        if (find.length === 0) dispatch(searchSiteWithExternalSiteId({ customerId: fk_customer_id ? fk_customer_id.value : loggedUserCustomerId, externalSiteId: query }));
    };
    if (addressValue) { var pincodeFromAddress = findPincode(addressValue) }

    useEffect(() => {
        const isPincodeAddedInAddressField = addressValue && Boolean(findPincode(addressValue));
        if (isPincodeAddedInAddressField) {

            setValue('job_fk_pincode_id', pincodeFromAddress)
        }
    }, [setValue, pincodeFromAddress, addressValue])
    const debouncedSiteSearchCall = useCallback(debounce(searchSiteDropdown), []);

    return (
        <div className='px-3 py-2'>
            {showTicketDetail && (
                <div className={`grid ${showCustomerDropdown ? 'grid-cols-4' : 'grid-cols-3'} rows-fr grid-flow-row-dense`}>
                    <Input options={customers} label='Customer' name='view_fk_customer_id' readOnly />
                    <Input label='Pincode' name='view_job_fk_pincode_id' type='number' readOnly />
                    <Input label='Site ID' name='view_site_id' readOnly />
                    <TextArea label='Address' name='view_job_site_address_1' readOnly />
                </div>
            )}
            <div className={`grid ${showCustomerDropdown && !showTicketDetail ? 'grid-cols-4' : 'grid-cols-3'} rows-fr grid-flow-row-dense`}>
                {showCustomerDropdown && !showTicketDetail && (
                    <Select options={customers} label='Customer' isLoading={isCustomerListLoading} name='fk_customer_id' required shouldUnregister />
                )}
                <Select options={customerTeamsDropDown} label='For' isLoading={customerTeamsLoading} name='fk_team_id' required />
                <Input label='Title' name='title' />
                <TextArea label='Description' name='description' required />
            </div>
            <div className='grid grid-cols-3 rows-fr grid-flow-row-dense'>
                <Radio options={expenseTypes} label='Expense Type' name='expense_type' className='flex gap-2 items-center' />
                <Input label='Payment Terms in Days' name='paymentTerms' type='number' />
                <Input label='External Ticket Id' name='external_ticket_id' />
            </div>
            {!showTicketDetail && (
                <div className='grid grid-cols-3 rows-fr grid-flow-row-dense'>
                    <Input
                        label='Pincode'
                        name='job_fk_pincode_id'
                        type='number'
                        pattern={/^\d{6}$/}
                        validate={async (value) => {
                            if (value === '') return true;
                            const response = await apis.cities.getCityFromPincode({}, {}, {}, { pincode: value });
                            return response.data?.code === 200 || "Pincode does't exist in the System.";
                        }}
                        required
                    />
                    <Select
                        options={sitesDropdown}
                        label='Site ID'
                        name='site_id'
                        required
                        onInputChange={(value) => debouncedSiteSearchCall(value, sitesDropdown, fk_customer_id)}
                        isLoading={isAllSiteDropdownLoading}
                    />
                    <FetchGoogleAddress label='Address' name='job_site_address_1' required setLocation={({ pincode }) => setValue('job_fk_pincode_id', pincode)} />
                </div>
            )}
            <div className='grid grid-cols-4 rows-fr grid-flow-row-dense'>
                {/* <TextArea label='Billing Address' name='billing_address' />

                <TextArea label='Shipping Address' name='shipping_address' /> */}
                <div><FetchGoogleAddress label={'Billing Address'} name={'billing_address'} /></div>
                <div><FetchGoogleAddress label={'Shipping Addres'} name={'shipping_address'} /></div>

                <Input label='GST' name='gst' pattern={/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/} />
                <Input name='related_emails' label='Send Updates to Users (use , for multiple emails)' />
            </div>
            {isCustomerFeasibilityEligible && (
                <div className='grid grid-cols-4 rows-fr grid-flow-row-dense'>
                    <Select options={linkTypes} label='Link Type' isLoading={false} name='link_type' shouldUnregister />
                    <Input label='Can Id' name='can_id' shouldUnregister />
                    <Input label='Vendor GST/Pancard' name='vendor_gst_pancard' shouldUnregister />
                    <Input label='Vendor Code' name='vendor_code' shouldUnregister />
                </div>
            )}
            {!isCustomerFeasibilityEligible && (
                <div className='grid grid-cols-3 rows-fr grid-flow-row-dense'>
                    {supplier_vendor_1 && <TextArea label='Vendor 1 Address' name='vendor_address_1' shouldUnregister />}
                    {supplier_vendor_2 && <TextArea label='Vendor 2 Address' name='vendor_address_2' shouldUnregister />}
                    {supplier_vendor_3 && <TextArea label='Vendor 3 Address' name='vendor_address_3' shouldUnregister />}
                </div>
            )}
            <div className='px-2 py-2'>
                <QuotesCard
                    quotesArrayName={quotesArrayName}
                    customerId={loggedUserCustomerId}
                    isCustomerFeasibilityEligible={isCustomerFeasibilityEligible}
                    showTicketDetail={showTicketDetail}
                />
            </div>
        </div>
    );
};
