import { useDispatch } from 'react-redux';
import Form from '../../../common/Form';
import { closeModalAction } from '../../../actions/modal';
import { getIssueCategories } from '../../../actions/admin';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Select from '../../../common/Form/Select';
import { updateTicketProperties } from '../../../actions/tickets';

const formName = 'Add_issue_category'
export const AddIssueCategory = ({ customerId, ticket_id, issue_category }) => {
    const dispatch = useDispatch();
    const { issueCategories } = useSelector((state) => state.admin);
    const { isIssueCategoryLoading } = useSelector((state) => state.loading);
    const { formButtonLoading } = useSelector((state) => state.loading);
    const defaultValues = {}
    const methods = useForm({ defaultValues, mode: 'all' });
    const { setValue } = methods;

    useEffect(() => {
        dispatch(getIssueCategories({ customerId, dropdown: true }));
    }, [dispatch, customerId]);

    useEffect(() => {
        if (issue_category && issueCategories) {
            const find = issueCategories.find((issue) => issue.value === issue_category);
            if (find) setValue('issue_category', find);
        }
    }, [issue_category, setValue, issueCategories])

    const submit = (formValues) => {
        const selectedValue = formValues.issue_category.value
        dispatch(
            updateTicketProperties({
                data: {
                    property: "UPDATE_ISSUE_CATEGORY",
                    data: {
                        issue_category: selectedValue,
                    },
                    ticketId: ticket_id
                },
                formName
            }))
    }

    return (<>
        <Form
            methods={methods}
            onSubmit={submit}
            submitButtonText={'Submit'}
            onCancel={() => dispatch(closeModalAction())}
            showSubmit
            submitContainerClassName='flex gap-2 justify-end py-4 px-4'
            isLoading={formButtonLoading[formName]}
        ><div className='p-4'>
                <Select name='issue_category' label='Issue Category' options={issueCategories} isLoading={isIssueCategoryLoading} required />
            </div>

        </Form>

    </>)
}