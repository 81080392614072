import { superdesk } from '../types';

const INITIAL_STATE = {
    error: undefined,
    capextypes: [],
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case superdesk.GET_SUPERDESK_CAPEX_TYPES_SUCCESS: {
            return {
                ...state,
                capexTypes: action.payload,
            };
        }
        case superdesk.GET_SUPERDESK_CAPEX_TYPES_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }
        case superdesk.ADD_USERS_TEAM_FAILURE:
        case superdesk.GET_TEAMS_BY_ID_FAILURE:
        case superdesk.EDIT_TEAM_FAILURE:
        case superdesk.UPDATE_USERS_PROPERTY_FAILURE:
        case superdesk.REMOVE_TEAM_AGENT_FAILURE:
        case superdesk.DELETE_TEAM_FAILURE:
        case superdesk.ADD_TEAM_FAILURE:
        case superdesk.ADD_TEAM_AGENT_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};
