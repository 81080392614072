import React from 'react';
import DetailTag from '../../../project/DetailTag';
import { textCss } from '../../../../common/Css';

const detailsValueAdditionalCss = ' w-60 line-clamp-3';

export const Details = ({ site }) => {
    const stateCityName = `${site?.city?.city_name || ''}, ${site?.state?.state_name || ''}, ${site?.fk_pincode_id || ''}`;
    return (
        <div className=''>
            <div className='flex pl-5 pt-6 justify-between'>
                <DetailTag
                    tagHeadClass={textCss.detailsTitle}
                    tagValueClass={`${textCss.detailsValue} ${detailsValueAdditionalCss}`}
                    tagClass='w-6/12  px-2'
                    tagName='Site Id'
                    tagValue={site?.external_site_id}
                />
                <div className='flex flex-col px-2 w-6/12'>
                    <DetailTag
                        tagHeadClass={textCss.detailsTitle}
                        tagValueClass={`${textCss.detailsValue} ${detailsValueAdditionalCss}`}
                        tagClass='px-2'
                        tagName='Address'
                        tagValue={site.complete_site_address}
                    />
                    {stateCityName ? <p className={`${textCss?.detailsValue} line-clamp-3 px-2`}>{`${stateCityName}`}</p> : <></>}
                </div>
            </div>

            <div className='md:flex pl-5 pt-6'>
                <DetailTag
                    tagHeadClass={textCss.detailsTitle}
                    tagValueClass={`${textCss.detailsValue} ${detailsValueAdditionalCss}`}
                    tagClass='px-2 md:w-6/12'
                    tagName='City & State'
                    tagValue={`${site?.state?.state_name ? site?.state?.state_name : ''},${site?.city?.city_name ? site?.city?.city_name : ''}`}
                />
                <DetailTag
                    tagHeadClass={textCss.detailsTitle}
                    tagValueClass={`${textCss.detailsValue} ${detailsValueAdditionalCss}`}
                    tagClass='px-2 md:w-6/12 '
                    tagName='Contact Name'
                    tagValue={site?.site_contact_person_name ? site?.site_contact_person_name : ''}
                />
            </div>
            <div className='md:flex pl-5 pt-6 '>
                <DetailTag
                    tagHeadClass={textCss.detailsTitle}
                    tagValueClass={`${textCss.detailsValue} ${detailsValueAdditionalCss}`}
                    tagClass='px-2 md:w-6/12'
                    tagName='Contact Email'
                    tagValue={site?.site_contact_person_email ? site?.site_contact_person_email : ''}
                />
                <DetailTag
                    tagHeadClass={textCss.detailsTitle}
                    tagValueClass={`${textCss.detailsValue} ${detailsValueAdditionalCss}`}
                    tagClass='px-2 md:w-6/12 '
                    tagName='Contact Mobile'
                    tagValue={site?.site_contact_person_mobile ? site?.site_contact_person_mobile : ''}
                />
            </div>
        </div>
    );
};
