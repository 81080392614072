import React, { useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../../common/Loader';
import { sendDataThroughWebsocket } from '../../../../actions/websocket';
import { putToConversationStore } from '../../../../actions/conversations';
import { websocketEvents } from '../../../../utils/websocket';
import { MentionsInputBar } from '../InputBar/MentionsInput';
import { specialUser } from '../../ChatController';

const transormInputRichValue = (value, tags) => {
    let updatedValue = value;
    if (Array.isArray(tags)) {
        tags.forEach((tag) => {
            if (!tag.user) return;
            let { user: { _id, username, userId } } = tag;
            if (userId === specialUser.all) _id = '@all'
            updatedValue = updatedValue.replace(`@${username}`, `@[${username}](${_id})`);
        });
    }
    return updatedValue;
};

export const EditMessage = ({
    value,
    setIsEditMessage,
    isEditLoading,
    messageId: message_id,
    conversationId,
    isEditMessageError,
    tags,
    chatUsers,
    isChatPopup
}) => {
    const [editedMessage, setEditedMessage] = useState(value);
    const [inputRichValue, setInputRichValue] = useState(transormInputRichValue(value, tags));
    const [updatedTags, setUpdatedTags] = useState([]);
    const { conversationMessages } = useSelector((state) => state.conversations);
    const dispatch = useDispatch();
    let inputBarRef = useRef(null);

    const taggableUsers = useMemo(() => {
        const allUser = { display: 'All', id: '@all' };
        const transformed = chatUsers?.map((user) => ({ id: user._id, display: user.username, ...user })) || [];
        if (transformed.length > 0) {
            return [allUser, ...transformed];
        }
        return [];
    }, [chatUsers]);

    const transformMentionsForSubmit = (mentions) => {
        const tags = mentions.map((mention) => {
            const position = mention.plainTextIndex;
            const allUser = { userId: -2, username: 'All', role: '' };
            if (mention.id === '@all') {
                return { position, user: allUser };
            }
            const mentionedUser = chatUsers.find((user) => user._id === mention.id);
            return { position, user: mentionedUser };
        });

        return tags;
    };

    const onSaveEditedMessage = (tags = updatedTags) => {
        let data = {
            conversation: conversationId,
            messageId: message_id,
            editedMessage,
        };
        if (Array.isArray(tags) && tags.length > 0) data.tags = transformMentionsForSubmit(tags);
        dispatch(
            sendDataThroughWebsocket({
                action: websocketEvents.chat.editMessage,
                data,
            })
        );
    };

    const onCancel = () => {
        setIsEditMessage(false);
        const messages = conversationMessages;
        let isToSetInStore = false;
        if (Array.isArray(messages[conversationId])) {
            messages[conversationId] = messages[conversationId].map((msg) => {
                let message = msg;
                if (message._id === message_id) {
                    if (message.prevMessage) {
                        message.message = message.prevMessage;
                    }
                    if (message.isEditLoading || message.isEditMessageError) {
                        isToSetInStore = true;
                        delete message.isEditLoading;
                        delete message.isEditMessageError;
                    }
                }
                return message;
            });
        }
        if (isToSetInStore) dispatch(putToConversationStore({ conversationMessages: messages }));
    };

    const getEditButtonComponent = () => {
        if (isEditLoading) {
            return (
                <div className='w-8 items-start mt-2'>
                    <Loader size='10' thickness='2' />
                </div>
            );
        }

        return <SaveCancelComponent isEditMessageError={isEditMessageError} onSaveEditedMessage={onSaveEditedMessage} onCancel={onCancel} />;
    };

    return (
        <>
            <div className={`${isChatPopup ? 'min-w-[17rem]' : 'min-w-30'}: `}>
                <MentionsInputBar
                    onChange={(e, newRichValue, newPlainTextValue, mentions) => {
                        setEditedMessage(newPlainTextValue.trimStart());
                        setInputRichValue(newRichValue.trimStart());
                        setUpdatedTags(mentions);
                    }}
                    value={inputRichValue}
                    onKeyDown={() => { }}
                    taggableUsers={taggableUsers}
                    inputRef={inputBarRef}
                />
            </div>
            <div className='flex gap-2 relative'>{getEditButtonComponent()}</div>
        </>
    );
};

const SaveCancelComponent = ({ onSaveEditedMessage, onCancel, isEditMessageError }) => {
    let actionClickText = isEditMessageError ? 'Retry' : 'Save';
    let textColor = isEditMessageError ? 'text-scogoclosed' : 'text-scogobgsky ';
    return (
        <div className='flex items-center gap-2'>
            <div className='flex items-center'>
                {isEditMessageError && <span className='material-icons text-scogoclosed text-font12 pr-1'>error</span>}{' '}
                <span onClick={() => onSaveEditedMessage()} className={textColor + ' text-font10 hover:text-scogoorange cursor-pointer'}>
                    {actionClickText}
                </span>
            </div>
            <span onClick={onCancel} className='text-scogobgsky text-font10 hover:text-scogoorange cursor-pointer'>
                Cancel
            </span>
        </div>
    );
};
