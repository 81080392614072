import { useMemo } from 'react';

const useRemToPx = (multiplier) => {
    const itemSize = useMemo(() => {
        return parseInt(getComputedStyle(document.documentElement).fontSize) * multiplier;
    }, [multiplier]);
    return itemSize;
};

export default useRemToPx;
