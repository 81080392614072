import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeFullScreenModalAction, closeModalAction, openCustomModal } from '../../../../actions/modal';
import { getBOQList, viewTicketById, removeFromTicketsStore, getPoDetails, removePoDetailsFromStore } from '../../../../actions/tickets';
import { getPOCategories, updatePo } from '../../../../actions/payments';
import moment from 'moment';
import { CLEANUP_FOR_UPDATE_PO } from '../../../../types/payments';
import { isValidJSON, validateDateValue, validateStringValue } from '../../../../utils/common';
import Form from '../../../../common/Form';
import Input from '../../../../common/Form/Input';
import { useForm, useFormContext } from 'react-hook-form';
import DatePicker from '../../../../common/Form/DatePicker';
import { UpdatePOTable } from './UpdatePoTable';
import Loader from '../../../../common/Loader';
import ButtonScogoPrimary from '../../../../common/Buttons/ButtonScogoPrimary';
import ButtonScogoClosedOutlined from '../../../../common/Buttons/ButtonScogoClosedOutlined';
import PayPoTax from '../PayPoTax';
import { isScm } from '../../../../utils/role';
import { isPayPoAllowed } from '../../../../utils/access';
import { getWalletDetailsById, removeWalletDetailsFromStore } from '../../../../actions/wallet';

const getVendors = (quotes) => {
    const vendors = quotes.reduce((acc, quote) => {
        // transform, because I don't like how the vendor id is stored in the key 'vendor'
        acc.push(
            ...quote.vendors.map((vendor) => ({
                vendorName: vendor.vendorName,
                vendorId: vendor.vendor,
            }))
        );
        return acc;
    }, []);
    const encountered = new Set();
    const vendorsWithoutDups = vendors.reduce((acc, vendor) => {
        if (!encountered.has(vendor.vendorId)) acc.push(vendor);
        encountered.add(vendor.vendorId);
        return acc;
    }, []);
    return vendorsWithoutDups;
};

const arrayName = 'quotes';
const formName = 'updatePo';

const UpdatePo = (props) => {
    const { ticket, isRefresh } = props;
    let ticketPO = ticket?.po;
    let { poDetails, boqList } = useSelector((state) => state.tickets);
    if (poDetails) ticketPO = poDetails;
    const { poCategories } = useSelector((state) => state.payments);
    const { ispoDetailsLoading, formButtonLoading } = useSelector((state) => state.loading);
    const dispatch = useDispatch();
    const [poCategoryOptions, setPOCategoryOptions] = useState([]);
    const [assetTypeOptions, setAssetTypeOptions] = useState([]);
    const { loggedUser } = useSelector((state) => state.auth);
    const role = loggedUser.role.id;
    const type = loggedUser.type;
    const frontend_controller = loggedUser.frontend_controller;
    const submitRef = useRef(null);

    const isPayPoEligible = isScm(role, type) && isPayPoAllowed(frontend_controller);

    useEffect(() => {
        if (ticket && !ticket.po) {
            dispatch(getPoDetails({ ticketId: ticket.ticket_id }));
        }
        return () => dispatch(removePoDetailsFromStore());
    }, [dispatch, ticket]);

    useEffect(() => {
        if (isPayPoEligible) {
            dispatch(getWalletDetailsById({ updateBalance: false, accountBalance: 'BANKOPEN' }));

            return () => {
                dispatch(removeWalletDetailsFromStore());
            }
        }
    }, [dispatch, isPayPoEligible]);

    useEffect(() => {
        if (Array.isArray(boqList)) setAssetTypeOptions(boqList);
        if (Array.isArray(poCategories)) {
            const options = poCategories.map((cat) => ({
                key: cat.id,
                value: cat.id,
                label: cat.name,
            }));
            setPOCategoryOptions(options);
        }
    }, [poCategories, boqList]);

    const vendors = useMemo(() => {
        if (Array.isArray(ticketPO?.quotes)) {
            return getVendors(ticketPO?.quotes);
        }
        return [];
    }, [ticketPO]);

    const defaultValues = {};

    const { viewTicket } = useSelector((state) => state.tickets);

    useEffect(() => {
        dispatch(viewTicketById({ ticketId: ticket?.ticketId || ticket.ticket_id }));
        return () => {
            dispatch(removeFromTicketsStore('VIEW_TICKET_SUCCESS'));
            dispatch(removeFromTicketsStore('VIEW_TICKET_FAILURE'));
        };
    }, [dispatch, ticket?.ticketId, ticket?.ticket_id]);

    useEffect(() => {
        dispatch(getBOQList(ticket.customer));
        dispatch(getPOCategories());

        return () => {
            dispatch({ type: CLEANUP_FOR_UPDATE_PO });
        };
    }, [ticket.customer, dispatch]);

    const onSubmit = (formValues) => {
        const quotes = formValues[arrayName].map((quote) => {
            return {
                addedToPO: quote.addedToPO,
                assetType: quote.assetType?.value,
                category: quote.category?.value,
                description: quote.description,
                itemId: quote.itemId,
                payment: { isRecurring: quote.isRecurring, endDate: quote.isRecurring ? quote.endDate : null },
                quantity: quote.quantity,
                quoteId: quote.quoteId,
                vendors: quote.vendors.map((vendor) => ({
                    // transform because API needs vendor id in the key 'vendor'
                    vendor: vendor.vendorId,
                    vendorName: vendor.vendorName,
                    price: vendor.price,
                    attachments: vendor.attachments,
                })),
            };
        });
        let reopen = ticketPO.status === 'CANCELLED';
        if (Array.isArray(formValues.quotes) && formValues.quotes.length > 0 && formValues.quotes.length !== ticketPO.quotes.length) {
            reopen = true;
        }
        let relatedEmail = '';
        if (!['', undefined, null].includes(formValues.related_emails)) {
            relatedEmail = formValues.related_emails.replace(/ /g, '');
        }
        let poDate = validateDateValue(formValues.poDate) ? moment(formValues.poDate).format('YYYY-MM-DD') : null;
        let payload = {
            formName,
            ticketId: ticket?.ticketId || ticket.ticket_id,
            data: { related_emails: relatedEmail.split(','), quotes, reopen, poDate },
            isRefresh,
            callViewTicket: true,
            enduserId: ticket.enduserId
        };
        dispatch(updatePo(payload));
    };

    const methods = useForm({ defaultValues, mode: 'all' });
    const { setValue } = methods;

    useEffect(() => {
        if (ticketPO) {
            let poDate = ticketPO?.poDate ? new Date(ticketPO.poDate) : ticketPO?.approvalTime ? new Date(ticketPO.approvalTime) : null;
            const quotes = ticketPO.quotes.map((quote) => {
                const correspondingPOItem = quote?.itemId && ticketPO.poitems?.find((poItem) => poItem?._id === quote?.itemId);
                let endDate;
                if (quote.payment.endDate || quote.payment.displayEndDate) {
                    endDate = new Date(quote.payment.endDate || quote.payment.displayEndDate);
                }

                let category = {};
                let assetType = {};

                if (Array.isArray(assetTypeOptions) && assetTypeOptions.length > 0) {
                    assetType = assetTypeOptions?.find((type) => type?.value === quote.assetType);
                }
                if (Array.isArray(poCategoryOptions) && poCategoryOptions.length > 0) {
                    category = poCategoryOptions?.find((cat) => cat?.value === quote.category);
                }
                let totalPrice = 0;
                if (correspondingPOItem?.totalPrice) {
                    totalPrice = correspondingPOItem?.totalPrice;
                } else {
                    quote.vendors.forEach((ven) => {
                        totalPrice += (ven.price * quote.quantity);
                    })
                }
                return {
                    id: `${Math.random()}`,
                    quantity: quote.quantity,
                    description: quote.description,
                    isRecurring: quote.payment.isRecurring,
                    endDate,
                    vendors: quote.vendors?.map((vendor) => ({
                        vendorName: vendor.vendorName,
                        price: vendor.price,
                        attachments: vendor.attachments,
                        vendorId: vendor.vendor,
                    })),
                    addedToPO: quote.addedToPO,
                    isEditable: !quote.addedToPO,
                    status: correspondingPOItem?.payment?.status,
                    channel: correspondingPOItem?.payment?.channel,
                    isDeletable: correspondingPOItem?.payment?.status !== 'PAID',
                    itemId: quote?.itemId,
                    quoteId: correspondingPOItem?.quoteId,
                    totalPrice,
                    transactions: correspondingPOItem?.payment?.transactions,
                    category,
                    assetType,
                    assetName: validateStringValue(correspondingPOItem?.assetName),
                    vendorId: validateStringValue(correspondingPOItem?.vendorId)
                };
            });
            setValue('poDate', poDate);
            setValue(arrayName, quotes);
        }
    }, [ticketPO, setValue, assetTypeOptions, poCategoryOptions]);

    if (ispoDetailsLoading) {
        return (
            <div className='my-11'>
                <Loader color='#F48A21' size='80' speed='1' thickness='6' />
            </div>
        );
    }

    return (
        <div className='bg-white overflow-hidden'>
            <Form
                className='w-full bg-white'
                onSubmit={onSubmit}
                submitButtonText='Submit'
                submitContainerClassName='flex gap-2 justify-end py-4 px-4'
                methods={methods}
                submitRef={submitRef}
            >
                <div className='relative h-full flex bg-scogoeee overflow-hidden bg-white'
                    style={{
                        flexFlow: 'column',
                        height: '63vh'
                    }}
                >
                    <div className='flex flex-initial pt-1'>
                        <RelatedEmailInput related_emails={viewTicket?.related_emails} />
                    </div>
                    <div className='flex flex-col flex-auto px-5 py-3 overflow-hidden'>
                        <UpdatePOTable
                            vendors={vendors}
                            poDetails={ticketPO}
                            arrayName={arrayName}
                            ticket={ticket}
                            poCategoryOptions={poCategoryOptions}
                            assetTypeOptions={assetTypeOptions}
                            isPayPoEligible={isPayPoEligible}
                        />
                    </div>
                    <div className='flex flex-initial justify-end py-3 px-5'>
                        <RenderFormButtons isLoading={formButtonLoading[formName]} isDisabled={false} ticket={ticket} isPayPoEligible={isPayPoEligible} submitRef={submitRef} />
                    </div>
                </div>

            </Form>
        </div>
    );
};

export default UpdatePo;

const RelatedEmailInput = ({ related_emails }) => {
    const { setValue } = useFormContext();

    useEffect(() => {
        if (!related_emails) return;
        let relatedMails = [];
        if (typeof related_emails === 'object' && Array.isArray(related_emails) && related_emails.length > 0) {
            relatedMails = related_emails;
        } else if (typeof related_emails === 'string' && isValidJSON(related_emails)) {
            const parsedData = JSON.parse(related_emails);
            if (Array.isArray(parsedData) && parsedData.length > 0) {
                relatedMails = parsedData;
            }
        }
        setValue('related_emails', relatedMails.join(','));
    }, [related_emails, setValue]);

    return (
        <div className='flex gap-2 w-full px-4'>
            <Input label='Send Updates to Users (use , for multiple emails)' placeholder='Send Updates to Users' name='related_emails' type='text' className='w-6/12' />
            <DatePicker label='PO Date' placeholder='PO Date' name='poDate' className='w-6/12' />
        </div>
    );
};

const RenderFormButtons = ({ submitRef, isDisabled, isLoading, ticket, isPayPoEligible }) => {
    const dispatch = useDispatch();

    const onPayPoTaxClick = () => {
        dispatch(
            openCustomModal({
                heading: (
                    <>
                        Pay PO Tax | <span className='text-scogobgsky'>{ticket.prNumber || ticket.ticketNumber}</span>
                    </>
                ),
                modalWidth: '70rem',
                modalHeight: 'auto',
                modalComponent: <PayPoTax ticketId={ticket.ticketId || ticket.ticket_id} vendorId={ticket?.po?.vendorId} isRefresh paymentDetails={ticket?.po?.paymentDetails} enduserId={ticket.enduserId} />,
            })
        );
    };

    const isPoCancelled = ticket.po.status === 'CANCELLED';
    const isPoNotApproved = ticket.po.status !== 'APPROVED';


    return <div className='flex items-center gap-2'>
        {isPayPoEligible && <ButtonScogoPrimary
            disabled={isPoCancelled || isPoNotApproved}
            textOrComponent={'Pay PO Tax'}
            loading={false}
            onClick={onPayPoTaxClick}
            type={"button"}
            onHoverTitle={isPoCancelled ? 'PO is cancelled' : 'PO is not Approved'}
        />}
        <ButtonScogoPrimary
            disabled={isDisabled}
            textOrComponent={'Update'}
            loading={isLoading}
            onClick={(e) => {
                e.preventDefault();
                submitRef.current.click();
            }}
            type={"button"}
        />
        <ButtonScogoClosedOutlined textOrComponent='Cancel' type='button' onClick={() => dispatch(closeFullScreenModalAction())} />
    </div>
}