import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from './Layout';
import { verifyUsersEmail } from '../../actions/auth';
import { SimpleButton } from '../../common/Buttons/SimpleButton';

function EmailVerification() {
    const { token } = useParams()
    const navigate = useNavigate();
    const { isEmailVerifiedFromToken, error } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        if (token) dispatch(verifyUsersEmail({ token: token }));
    }, [token, dispatch]);

    const showtext = {
        true: {
            title: "Mail Verified Successfully",
            style: { border: '1px solid rgb(0 255 62)', color: 'rgb(0 185 104)', background: 'rgb(232 255 232)' },
            btnText: "Login",
            onClick: () => navigate("/login")

        },
        false: {
            title: error?.message?.length > 0 ? error?.message : "Mail Not Verified",
            style: { border: '1px solid rgb(178,95,94)', color: 'rgb(145,45,56)', background: 'rgb(255,246,246)' },
            btnText: "Login",
            onClick: () => navigate("/login")
        }
    }

    return (
        <Layout>

            <div
                className='my-6 h-10 items-center flex justify-center rounded-md gap-6 border-4 px-2'
                style={showtext?.[isEmailVerifiedFromToken]?.style}
            >
                <p className="font-normal text-font12">
                    {showtext?.[isEmailVerifiedFromToken]?.title}
                </p>
            </div>
            <SimpleButton
                btnClass={`border flex items-center text-font11 justify-center leading-6 cursor-pointer rounded-lg gap-1 font-medium cursor-pointer px-2 bg-scogoprimary text-white m-auto px-8 py-4 text-font14`}
                textOrComponent={showtext?.[isEmailVerifiedFromToken]?.btnText}
                onClick={showtext?.[isEmailVerifiedFromToken]?.onClick}
            />
        </Layout>
    );
}

export default EmailVerification;
