import { pmscm } from '../types';

const INITIAL_STATE = {
    error: undefined
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case pmscm.GET_TOOLS_SUCCESS:
            return {
                ...state,
                tools: action.payload
            }
            case pmscm.GET_TOOLS_FAILURE:
                return {
                    ...state,
                    error: action.payload
                }
            default:
            return {
                ...state
            }
    }
}
