export const SHARE_PUBLIC_PROFILE_REQUEST = 'SHARE_PUBLIC_PROFILE_REQUEST';
export const SHARE_PUBLIC_PROFILE_SUCCESS = 'SHARE_PUBLIC_PROFILE_SUCCESS';
export const SHARE_PUBLIC_PROFILE_FAILURE = 'SHARE_PUBLIC_PROFILE_FAILURE';

export const SHARE_ID_CARD_REQUEST = 'SHARE_ID_CARD_REQUEST';
export const SHARE_ID_CARD_SUCCESS = 'SHARE_ID_CARD_SUCCESS';
export const SHARE_ID_CARD_FAILURE = 'SHARE_ID_CARD_FAILURE';

export const REMOVE_ID_CARD_REQUEST = 'REMOVE_ID_CARD_REQUEST';
export const REMOVE_PUBLIC_PROFILE_REQUEST = 'REMOVE_PUBLIC_PROFILE_REQUEST';

export const GET_ELIGIBLE_SP_LIST_REQUEST = 'GET_ELIGIBLE_SP_LIST_REQUEST';
export const GET_ELIGIBLE_SP_LIST_SUCCESS = 'GET_ELIGIBLE_SP_LIST_SUCCESS';
export const GET_ELIGIBLE_SP_DROPDOWN_LIST_SUCCESS = 'GET_ELIGIBLE_SP_DROPDOWN_LIST_SUCCESS';
export const GET_ELIGIBLE_SP_LIST_FAILURE = 'GET_ELIGIBLE_SP_LIST_FAILURE';
export const UPDATE_SP_DETAILS_REQUEST = 'UPDATE_SP_DETAILS_REQUEST';
export const UPDATE_SP_DETAILS_SUCCESS = 'UPDATE_SP_DETAILS_SUCCESS';
export const UPDATE_SP_DETAILS_FAILURE = 'UPDATE_SP_DETAILS_FAILURE';

export const CLEAR_ELIGIBLE_SP_LIST = 'CLEAR_ELIGIBLE_SP_LIST';
export const CLEAR_ELIGIBLE_SP_DROPDOWN_LIST = 'CLEAR_ELIGIBLE_SP_DROPDOWN_LIST';
export const UPDATE_STATE_DETAIL_REQUEST = 'UPDATE_STATE_DETAIL_REQUEST';
export const UPDATE_STATE_DETAIL_SUCCESS = 'UPDATE_STATE_DETAIL_SUCCESS';
export const UPDATE_STATE_DETAIL_FAILURE = 'UPDATE_STATE_DETAIL_FAILURE';

export const UPDATE_SP_CUSTOMER_STATUS_REQUEST = 'UPDATE_SP_CUSTOMER_STATUS_REQUEST';
export const UPDATE_SP_CUSTOMER_STATUS_SUCCESS = 'UPDATE_SP_CUSTOMER_STATUS_SUCCESS';
export const UPDATE_SP_CUSTOMER_STATUS_FAILURE = 'UPDATE_SP_CUSTOMER_STATUS_FAILURE';

export const PUT_TO_SP_STORE = 'PUT_TO_SP_STORE';
export const GET_PARTNER_DETAILS_LIST_SUCCESS_SP = 'GET_PARTNER_DETAILS_LIST_SUCCESS_SP';
