import _ from 'lodash';
import { useFormContext } from 'react-hook-form';

const TextArea = ({ id, name, label, required, placeholder, validate, className = '', rows = '4', shouldUnregister, readOnly, onChange = () => { }, height = '3.1rem', padding = 'px-4 pt-3' }) => {
    const {
        register,
        formState: { errors },
    } = useFormContext();

    const error = _.get(errors, name) ? _.get(errors, name).message : '';
    return (
        <div className={`block ${padding} ${className}`}>
            {label && (
                <label htmlFor={id} className='text-scogo99 font-normal text-font09 md:text-font11'>
                    {label} {required && <span className='text-scogoorange'>*</span>}
                </label>
            )}
            <textarea
                id={id}
                key={name}
                style={{ height: height }}
                className='font-normal text-font09 md:text-font11 block w-full focus:ring-0 px-0 border-scogoddd border-b-2 border-t-0 border-r-0 border-l-0 focus:border-scogoprimary'
                {...register(name, {
                    required: { value: required, message: 'Required' },
                    validate,
                    shouldUnregister,
                })}
                placeholder={placeholder}
                rows={rows}
                readOnly={readOnly}
                onChange={onChange}
            />
            <div className='mb-1 flex'>
                {error && <p className='text-scogoclosed text-font09 font-normal h-6'>{error}</p>}
            </div>
        </div>
    );
};

export default TextArea;
