import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import { sites as sitesTab, assets as assetsTab, warehouses as warehousesTab, spareAssets as spareAssetsTab } from '../InventoryController';
import Buttons from '../../../common/Buttons';
import DropdownMenu from '../../../common/MenuDropdown';
import IconToolTip from '../../../common/IconToolTip';
import Badge from '../../../common/Badge';
import { getInventoryCount, getPropertiesOfSite, getPropertiesOfWarehouse } from '../../../actions/inventory';
import ButtonScogoPrimary from '../../../common/Buttons/ButtonScogoPrimary';
import { RenderSwitchButton } from '../../../common/RenderSwitchButton';

export default function InventoryButtonOptions(props) {
    const {
        headerBulkDropdownActions,
        customerIdOfSelectedSite,
        setCustomerIdOfSelectedSite,
        showInventoryAddNewButtonRoleWise,
        showInventoryBulkActionButton,
        selectedSiteExternalSiteId,
        setSelectedSiteExternalSiteId,
        selectedSiteId,
        selectedWarehouseId,
        selectedWarehouseName,
        setSelectedWarehouseName,
        selectedSubTab,
        archiveQueryParam,
        setIsArchiveAssetSelected,
        singular,
        goToAssetTab,
        goToInventoryTab,
        getSelectedData,
        subTabs,
        isAssetGroupView,
        setAssetGroupView
    } = props;
    const { loggedUser } = useSelector((state) => state.auth);
    const { sitesCount, spareAssetsCount, assetsCount, warehouseCount, selectedInventorySite, selectedInventoryWarehouse } = useSelector(
        (state) => state.inventory
    );
    const { id: userId, email, customer_id } = loggedUser;
    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedSiteId > 0 && !selectedInventorySite) {
            dispatch(getPropertiesOfSite({ siteId: selectedSiteId, attributes: ['id', 'external_site_id', 'fk_customer_id'] }));
        }
    }, [selectedSiteId, dispatch, selectedInventorySite]);

    useEffect(() => {
        if (selectedWarehouseId > 0 && !selectedInventoryWarehouse) {
            dispatch(getPropertiesOfWarehouse({ warehouseId: selectedWarehouseId, attributes: ['id', 'name'] }));
        }
    }, [selectedWarehouseId, selectedInventoryWarehouse, dispatch]);

    useEffect(() => {
        if (selectedInventorySite?.externalSiteId) {
            setSelectedSiteExternalSiteId(selectedInventorySite.externalSiteId);
        }
        if (selectedInventorySite?.customerId) {
            setCustomerIdOfSelectedSite(selectedInventorySite.customerId);
        }
    }, [selectedInventorySite?.externalSiteId, selectedInventorySite?.customerId, setCustomerIdOfSelectedSite, setSelectedSiteExternalSiteId]);

    useEffect(() => {
        if (selectedInventoryWarehouse?.name) {
            setSelectedWarehouseName(selectedInventoryWarehouse.name);
        }
    }, [selectedInventoryWarehouse, setSelectedWarehouseName]);

    useEffect(() => {
        dispatch(getInventoryCount({ subTabs, selectedSubTab, customerId: customer_id }));
    }, [selectedSubTab, customer_id, dispatch, subTabs]);

    const buttonComponent = () => {
        return (
            <>
                <Button
                    id='demo-customized-button'
                    aria-haspopup='true'
                    variant='contained'
                    disableElevation
                    endIcon={<span className='material-icons'>keyboard_arrow_down</span>}
                    style={{ backgroundColor: '#F48A21', color: 'white' }}
                >
                    Actions
                </Button>
            </>
        );
    };

    const headerDetails = (selectedSiteId, selectedWarehouseId, tab) => {
        let tagName = '';
        let tagValue = '';
        if (selectedSiteId > 0 && tab === 'assets') {
            tagName = 'Selected Site :';
            tagValue = selectedSiteExternalSiteId;
        }
        if (selectedWarehouseId > 0 && tab === 'spareAssets') {
            tagName = 'Selected Warehouse:';
            tagValue = selectedWarehouseName;
        }
        return (
            <IconToolTip placement={'bottom'} title={`${tagName} ${tagValue}`}>
                <div className='ml-2 max-w-3xl truncate'>
                    <div className='w-full flex items-center'>
                        <span className={'text-font13 font-medium font-rubik text-scogoorange'}>{tagName}</span>
                        <span className={'gap-4 px-2 text-font12 truncate w-full break-words'}>{tagValue}</span>
                    </div>
                </div>
            </IconToolTip>
        );
    };
    const tabWiseCount = (tabName) => {
        const inventoryWiseCountLookup = {
            [sitesTab.id]: sitesCount,
            [assetsTab.id]: assetsCount,
            [warehousesTab.id]: warehouseCount,
            [spareAssetsTab.id]: spareAssetsCount,
        };
        return inventoryWiseCountLookup[tabName];
    };

    return (
        <>
            <div className='relative flex mt-4 w-full'>
                <div className='flex items-center w-full'>
                    <div className='border-zinc-5 border-2 rounded-md tab-button-container flex'>
                        {props.subTabs.map((subTab, id) => {
                            const isCurrentTabSelected = selectedSubTab === subTab.id;
                            return (
                                <div
                                    key={id}
                                    className={` flex ${isCurrentTabSelected ? ' bg-scogoorange text-white' : ' text-scogogray hover:bg-scogolightblue'
                                        }`}
                                >
                                    <Buttons
                                        buttonClass={`whitespace-nowrap font-medium px-4 py-2 text-font13`}
                                        children={subTab.title}
                                        onClick={() => goToInventoryTab(subTab.path)}
                                    />
                                    <Badge count={tabWiseCount(subTab.id)} background={isCurrentTabSelected ? 'bg-scogogray' : 'bg-scogoorange'} />
                                </div>
                            );
                        })}
                    </div>
                    <div className='max-w-lg'>{headerDetails(selectedSiteId, selectedWarehouseId, selectedSubTab)}</div>
                    <div className='flex items-center'>
                        {archiveQueryParam && selectedSubTab === 'assets' && (
                            <p className={'text-font13 font-medium font-rubik text-scogogray flex items-center'}>Archived Assets</p>
                        )}
                    </div>
                    <div className='flex gap-2 ml-auto'>
                        {assetsTab.path === selectedSubTab && <div className='flex mr-2 h-full mt-2'><RenderSwitchButton isGroupView={isAssetGroupView} setGroupView={setAssetGroupView} /></div>}
                        {showInventoryBulkActionButton && (
                            <div className='relative cursor-pointer '>
                                <DropdownMenu
                                    menuData={headerBulkDropdownActions}
                                    customerId={customer_id}
                                    componentOrIcon={buttonComponent}
                                    isTableAction={false}
                                    iconColor={'text-scogoprimary'}
                                    payload={{
                                        getSelectedData,
                                        defaultEmail: email,
                                        customerId: customer_id,
                                        tab: selectedSubTab,
                                        siteId: selectedSiteId,
                                        warehouseId: selectedWarehouseId,
                                        customerIdOfSelectedSite,
                                        tabName: selectedSubTab,
                                        isArchiveAssetSelected: archiveQueryParam,
                                        setIsArchiveAssetSelected,
                                        goToAssetTab,
                                        userId: userId,
                                    }}
                                />
                            </div>
                        )}

                        {showInventoryAddNewButtonRoleWise && (
                            <ButtonScogoPrimary
                                textOrComponent={'Add New'}
                                buttonIcon={'/img/plus.svg'}
                                onClick={() => {
                                    props.openInventorySideDrawer({
                                        inventorySideDrawerName: selectedSubTab,
                                        inventorySideDrawerPayload: { title: `Add ${singular}` },
                                    });
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
