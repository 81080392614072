import { payment_terms_options } from '../pages/Clients/CustomerInfo';
import { customer, partners, superdesk } from '../types';
import { validateNumberValue, validateStringValue } from '../utils/common';
import { findMinMax } from './project';

const INITIAL_STATE = {
    error: undefined,
    hanReachedEndTeams: false,
    allCount: 0,
    helpdeskCount: 0,
    customerCount: 0,
    customerTeams: [],
    teamData: undefined,
};

const customerReducers = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case customer.CUSTOMERS_ADDONS_LIST_SUCCESS: {
            const transformed_list = transFormCustomerAddon(action.payload);
            return {
                ...state,
                customerAddons: transformed_list,
            };
        }
        case customer.CUSTOMERS_ADDONS_DROPDOWN_LIST_SUCCESS: {
            return {
                ...state,
                customerAddonsDropdown: action.payload,
            };
        }
        case customer.CLEAR_CUSTOMERS_ADDONS_DROPDOWN_LIST: {
            return {
                ...state,
                customerAddonsDropdown: undefined,
            };
        }
        case customer.CLEAR_DROPDOWN: {
            return {
                ...state,
                customerAddonsDropdown: [],
            };
        }
        case customer.CUSTOMERS_ADDONS_LIST_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }
        case customer.CUSTOMERS_ADDONS_LIST_LOADING: {
            return {
                ...state,
                customerAddonsLoading: action.payload,
            };
        }
        case customer.CUSTOMERS_ADDONS_DROPDOWN_LIST_LOADING: {
            return {
                ...state,
                customerDropdownAddonsLoading: action.payload,
            };
        }
        case customer.SELECTED_USER_DETAILS: {
            return {
                ...state,
                selectedUserDetails: action.payload.data,
                teamSideTabName: action.payload.tabName,
            };
        }

        case customer.SELECTED_TEAM_USER_DETAILS: {
            return {
                ...state,
                selectedTeamUserDetails: action.payload.data,
                teamSideTabName: action.payload.tabName,
            };
        }

        case customer.CUSTOMERS_ADDONS_EDIT_LIST_SUCCESS: {
            const { team_id } = action.payload;
            let filteredUser = state.selectedUserDetails.team_details.filter((elem) => elem.id !== team_id);
            return {
                ...state,
                selectedUserDetails: { ...state.selectedUserDetails, team_details: filteredUser },
            };
        }
        case customer.CUSTOMERS_TEAMS_LIST_SUCCESS: {
            const { data } = action.payload;
            const allCount = action.payload.count.allCount;
            const helpdeskCount = action.payload.count.helpdeskCount;
            const customerCount = action.payload.count.customerCount;
            const internalCount = action.payload.count.internalCount;
            return {
                ...state,
                customerTeams: data,
                hanReachedEndTeams: false,
                allCount,
                helpdeskCount,
                customerCount,
                internalCount,
                scmCustomerCount: 0,
            };
        }
        case customer.CUSTOMERS_TEAMS_BY_CUSTOMERID_LIST_SUCCESS: {
            return {
                ...state,
                customerTeams: action.payload.data,
                scmCustomerCount: action.payload.count.allCount,
                hanReachedEndTeams: false,
            };
        }
        case superdesk.DELETE_TEAM_SUCCESS: {
            const updatedCustomer = state.customerTeams.filter((elem) => {
                return elem.id !== action.payload.teamId;
            });
            return {
                ...state,
                customerTeams: updatedCustomer,
                allCount: state.allCount - 1,
            };
        }
        case customer.CUSTOMERS_TEAMS_DROPDOWN_LIST_SUCCESS: {
            return {
                ...state,
                customerTeamsDropDown: action.payload.data,
            };
        }

        case superdesk.GET_TEAMS_BY_ID_SUCCESS: {
            return {
                ...state,
                teamData: action.payload.data,
            };
        }

        case superdesk.ADD_USERS_TEAM_SUCCESS: {
            let customerTeamsData = state.customerTeams;
            let newCustomeTeamsData = customerTeamsData.map((elem) => {
                if (elem.id === action.payload.teamId && state.teamData) {
                    return state.teamData;
                } else return elem;
            });
            return {
                ...state,
                customerTeams: newCustomeTeamsData,
            };
        }

        case superdesk.REMOVE_TEAM_AGENT_SUCCESS: {
            let customerTeams = state.customerTeams;
            let findIndexElem = customerTeams.filter((elem) => {
                return elem.id === action.payload.teams[0];
            });
            findIndexElem = findIndexElem[0];
            let updatedUser = findIndexElem.agents.filter((elem) => elem.id !== action.payload.agentId);
            let agentCounts = findIndexElem.agentsCount - 1;
            findIndexElem = { ...findIndexElem, agents: updatedUser, agentsCount: agentCounts };
            customerTeams = customerTeams.map((elem) => {
                if (elem.id === findIndexElem.id) return findIndexElem;
                else return elem;
            });
            return {
                ...state,
                customerTeams,
            };
        }

        case superdesk.TRANSFER_TEAMS_AGENT_SUCCESS: {
            const [from, to] = action.payload;
            let newCustomeTeamsData = state.customerTeams.map((team) => {
                if (team.id === from) {
                    let fromTeamAgentCount = team?.agentsCount - 1;
                    return { ...team, agentsCount: fromTeamAgentCount };
                } else if (team.id === to) {
                    let toTeamAgentCount = team?.agentsCount + 1;
                    return { ...team, agentsCount: toTeamAgentCount };
                } else return team;
            });
            return {
                ...state,
                customerTeams: newCustomeTeamsData,
            };
        }

        case customer.CUSTOMERS_NEXT_TEAMS_LIST_SUCCESS: {
            const hanReachedEndTeams = action.payload.data.length === 0;
            return {
                ...state,
                customerTeams: [...state.customerTeams, ...action.payload.data],
                hanReachedEndTeams,
                allCount: action.payload.count.allCount,
                helpdeskCount: action.payload.count.helpdeskCount,
                customerCount: action.payload.count.customerCount,
            };
        }

        case superdesk.UPDATE_USERS_INSTORE_PROPERTY: {
            const { userData } = action.payload;
            const { property } = userData;
            let customerAddons = state.customerAddons;
            let newcustomerAddons = customerAddons.map((elem) => {
                if (elem.fk_user_id === userData.agentId) {
                    return {
                        ...elem,
                        user: {
                            ...elem.user,
                            [property]: ((elem?.user?.[property] || 0) + 1) % 2,
                        },
                    };
                } else return elem;
            });

            return {
                ...state,
                customerAddons: newcustomerAddons,
            };
        }

        case customer.CUSTOMERS_NEXT_TEAMS_LIST_FAILURE:
        case customer.CUSTOMERS_TEAMS_LIST_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        case customer.CUSTOMERS_TEAMS_LIST_LOADING: {
            return {
                ...state,
                customerTeamsLoading: action.payload,
            };
        }
        case customer.CUSTOMERS_NEXT_TEAMS_LIST_LOADING: {
            return {
                ...state,
                customerNextTeamsLoading: action.payload,
            };
        }
        case customer.PROJECT_LIST_NAME_SUCCESS: {
            return {
                ...state,
                projectListNames: action.payload,
            };
        }
        case customer.REMOVE_PROJECT_LIST_NAME_FROM_STORE: {
            return {
                ...state,
                projectListNames: undefined,
            };
        }

        case customer.PROJECT_LIST_NAME_FAILURE: {
            return {
                ...state,
                error: action.payload,
            };
        }

        case customer.PROJECT_LIST_NAME_LOADING: {
            return {
                ...state,
                projectListLoading: action.payload,
            };
        }

        case customer.CUSTOMERS_LIST_SUCCESS: {
            return {
                ...state,
                customers: action.payload,
            };
        }

        case partners.DELETE_PARTNER_SUCCESS: {
            let { userId } = action.payload;
            let updatedCustomersList = state.customers && state.customers.filter((customer) => customer.fk_user_id !== userId);
            return {
                ...state,
                customers: updatedCustomersList,
            };
        }

        case customer.EDIT_TEAM_SUCCESS: {
            const { udatedDetails, teamId } = action.payload;
            const updatedData = state.customerTeams.map((elem) => {
                if (elem.id === teamId) {
                    return { ...elem, name: udatedDetails.name };
                } else return elem;
            });
            return {
                ...state,
                customerTeams: updatedData,
            };
        }

        case customer.CLEANUP_CUSTOMER_TEAMS: {
            return {
                ...state,
                customerTeamsDropDown: [],
            };
        }
        case customer.CUSTOMER_SERVICE_LOCATIONS_UPDATE: {
            const { updatedCities, servicePartnerId } = action.payload;

            let updatedCustomerVendors = state.customerVendorsData.map((vendors) => {
                if (vendors?.sp_id === servicePartnerId) {
                    return { ...vendors, office_location: updatedCities };
                } else return vendors;
            });
            return {
                ...state,
                customerVendorsData: updatedCustomerVendors,
            };
        }
        case customer.GET_CUSTOMER_VENDORS_LIST_SUCCESS: {
            const updatedData = transformCustomerVendorList(action.payload);
            const withdrawn_amount = findMinMax({ list: updatedData, key: 'withdrawn_amount' });

            let customerFilterData = {
                status: ['Active', 'Profile Pending', 'Bank Details Pending', 'Verification Pending', 'InActive', 'Disabled'],
                kyc_details: ['Details Updated', 'Details Pending', 'Not Linked'],
                withdrawn_amount
            };

            updatedData.forEach((item) => {

                let user = {
                    id: item.user.id,
                    name: `${validateStringValue(item.user.first_name)} ${validateStringValue(item.user.last_name)}`,
                };
                let email = {
                    id: item.user.email.replace(/[^a-zA-Z0-9@. ]/g, ''),
                    email: item.user.email,
                };

                if (customerFilterData['users']) {
                    let isAlreadyExit = customerFilterData['users'].find((i) => i.id === item.id);
                    if (!isAlreadyExit && user.id) customerFilterData['users'].push(user);
                } else customerFilterData['users'] = [user];

                if (customerFilterData['mobiles']) {
                    if (!customerFilterData['mobiles'].includes(item.user.mobile)) {
                        customerFilterData['mobiles'].push(item.user.mobile);
                    }
                } else customerFilterData['mobiles'] = [item.user.mobile];

                if (customerFilterData['emails']) {
                    let isAlreadyExit = customerFilterData['emails'].find((i) => i.email === item.user.email);
                    if (!isAlreadyExit) customerFilterData['emails'].push(email);
                } else customerFilterData['emails'] = [email];

            });
            return {
                ...state,
                customerVendorsData: updatedData,
                customerFilterData
            };
        }
        case customer.RAISE_PO_VENDORS_LIST: {
            return {
                ...state,
                raisePOVendorsList: action.payload,
            };
        }
        case customer.CLEAR_VENDORS_DATA_FROM_STORE: {
            return {
                ...state,
                customerVendorsData: undefined,
            };
        }

        case customer.GET_CUSTOMER_PROFILE_DETAILS_SUCCESS: {
            const transformedCustomerDetails = transformCustomerDetails(action.payload);
            return {
                ...state,
                customerDetail: action.payload,
                transformedCustomerDetails: transformedCustomerDetails,
            };
        }
        case customer.REMOVE_CUSTOMER_PROFILE_DETAILS_FROM_STORE: {
            return {
                ...state,
                customerDetail: undefined,
            };
        }

        case customer.UPLOAD_CUSTOMER_LOGO_SUCCESS: {
            let logo_url = action.payload;
            let { customerDetail } = state;
            let updatedCustomerDetail = { ...customerDetail, logo_url };
            return {
                ...state,
                customerDetail: updatedCustomerDetail,
            };
        }
        case customer.UPDATE_CUSTOMER_PROFILE_DETAILS_SUCCESS: {
            let response_data = action.payload || {};
            const { customerDetail } = state;
            const updated_data = {
                ...response_data,
                city: {
                    city_name: response_data?.customer_city_name,
                    city_id: response_data?.customer_city_id,
                },
                pincode: {
                    pincode_id: response_data?.customer_pincode,
                    pincode: response_data?.customer_pincode_data,
                },
            };

            const updatedCustomerDetail = { ...customerDetail, ...updated_data };
            return {
                ...state,
                customerDetail: updatedCustomerDetail,
            };
        }
        case customer.PUT_TO_CUSTOMER_STORE: {
            return {
                ...state,
                ...action.payload,
            };
        }
        case customer.CUSTOMERS_LIST_FAILURE:
        case customer.CUSTOMERS_TEAMS_BY_CUSTOMERID_LIST_FAILURE:
        case customer.CUSTOMERS_ADDONS_DROPDOWN_LIST_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        default:
            return {
                ...state,
            };
    }
};

const transformCustomerVendorList = (customeVendorsData) => {
    let newVendorList = customeVendorsData?.map((item) => {
        let obj = {
            first_name: item?.user?.first_name,
            last_name: item?.user?.last_name,
            sp_name: item?.sp_name,
            status: item?.user?.status,
            created_at: item?.created_at,
            mobile: item?.user?.mobile,
            office_location: Array.isArray(item?.office) && item?.office?.map((elem) => elem?.city?.city_name),
            user_enabled: item?.user_enabled,
            email: item?.user?.email,
            sp_id: item?.service_partner_id,
            id: item?.fk_user_id,
            user_status: item?.user?.user_status,

        };
        return Object.assign(obj, item);
    });
    return newVendorList;
};

const transformCustomerDetails = (customerDetail) => {
    let transformedCustomerDetails = {
        company_logo: customerDetail?.logo_url,
        customer_name: `${customerDetail?.user_detail?.first_name} ${customerDetail?.user_detail?.last_name}`,
        customer_email: `${customerDetail?.user_detail?.email}`,
        customer_mobile: `${customerDetail?.user_detail?.mobile}`,
        company_name: customerDetail?.customer_company_name,
        payment_terms: payment_terms_options.find((item) => item?.value === parseInt(customerDetail?.payment_terms)).label,
        address: `${validateStringValue(customerDetail?.customer_address_1)} ${validateStringValue(customerDetail?.customer_address_2)}
        ${validateStringValue(customerDetail?.city?.city_name)}, 
        ${customerDetail?.state?.state_name ? customerDetail?.state?.state_name : ''}
        ${customerDetail?.pincode ? `, ${customerDetail?.pincode?.pincode}` : ''}`,
        pan_number: customerDetail?.customer_pan_card_number !== '' ? customerDetail?.customer_pan_card_number : 'N/A',
        gst_number: customerDetail?.customer_have_gst !== 0 ? customerDetail?.customer_gst_number : 'N/A',
        gst_state: customerDetail?.customer_gst_register_state_name ? customerDetail?.customer_gst_register_state_name.state_name : '',
        domain: customerDetail?.domain,
        pincode: customerDetail?.pincode ? customerDetail?.pincode?.pincode : '',
        customer_address_1: customerDetail?.customer_address_1,
        customer_address_2: customerDetail?.customer_address_2,
        wallet_amount: customerDetail?.wallet_detail?.wallet_amount,
        locked_amount: customerDetail?.wallet_detail?.locked_amount,
        amount_used: customerDetail?.wallet_detail?.amount_used,
        userId: customerDetail?.fk_user_id,
    };
    return transformedCustomerDetails;
};

export default customerReducers;


const transFormCustomerAddon = (list) => {
    if (!list) return [];
    return list.map((user) => {
        let is_possible_tech_support = validateNumberValue(user?.user?.is_possible_tech_support);
        return { ...user, is_possible_tech_support }
    })
}