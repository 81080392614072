import { useSelector } from 'react-redux';
import Input from '../../../../common/Form/Input';
import GridTable from '../../../../common/GridTable';
import RatingComponent from '../../../../common/Rating';
import { SkeltonCardTwo } from '../../../../common/SkeltonCardTwo';
import { isValidMobileNumber } from '../../../../utils/utils';
import { transactional } from './index';

export const FieldEngineerTable = ({ fieldEngineers, handleSelectfe, assignNew, setAssignNew }) => {
    const { isFieldEngineersLoading } = useSelector((state) => state.loading);
    const tableSkelton = <SkeltonCardTwo arr={[1, 2, 3, 4]} height={20} className={'px-7'} />;

    const tableSchema = {
        table: [
            {
                head: {
                    headName: 'FE',
                    render: (headName) => headName,
                    width: 1.5,
                },

                body: {
                    render: (item) => {
                        return (
                            <div className='flex gap-2 items-center'>
                                <label className='flex items-center gap-2'>
                                    <input
                                        className='focus:ring-0 focus:ring-offset-0 text-scogoorange h-4 w-4'
                                        type='radio'
                                        name={'table'}
                                        onChange={(e) => {
                                            handleSelectfe(item.fk_user_id);
                                            setAssignNew(false);
                                        }}
                                        required
                                    />
                                    <p className='font-normal'>
                                        {`${item.name}`}
                                        <span className='text-scogoclosed'>*</span>
                                    </p>
                                </label>
                            </div>
                        );
                    },
                },
            },
            {
                head: {
                    headName: 'Phone',
                    render: (headName) => headName,
                    width: 1.5,
                },
                body: {
                    render: (item) => {
                        return <p className='font-normal'>{item?.mobile}</p>;
                    },
                },
            },
            {
                head: {
                    headName: 'Rating',
                    render: (headName) => headName,
                    width: 0.8,
                },
                body: {
                    render: (item) => {
                        return <RatingComponent rating={item.rating} />;
                    },
                },
            },
        ],
    };

    const getTable = () => {
        if (isFieldEngineersLoading) {
            return tableSkelton;
        }
        return (
            <div>
                <div className='px-7 py-2'>
                    <p className='font-medium text-font12 pb-2'>Select Field Engineer</p>
                    <GridTable schema={tableSchema} rowData={fieldEngineers} isLoading={false} tableBodyHeight={'h-max'} />
                </div>
            </div>
        );
    };

    return (
        <>
            {getTable()}

            <label className='px-7 py-4 flex gap-2 items-center'>
                <input
                    className='focus:ring-0 focus:ring-offset-0 text-scogoorange h-4 w-4'
                    type='radio'
                    name={'table'}
                    onChange={(e) => {
                        setAssignNew(true);
                        handleSelectfe(transactional);
                    }}
                    required
                />
                <p className='font-medium text-font12'>Assign New Engineer</p>
            </label>
            {assignNew && (
                <>
                    <div
                        className='mx-8 px-5 py-1 items-center flex px-6 rounded-md gap-6'
                        style={{
                            border: '1px solid rgb(227,187,187)',
                            background: 'rgb(255,246,246)',
                        }}
                    >
                        <p className='text-font12'>User does not need to onboard the platform</p>
                    </div>

                    <div className='px-5 flex justify-between'>
                        <Input className='w-full' label='Name' name='feName' required type={'text'} />
                        <Input
                            className='w-full'
                            label='Mobile Number'
                            name='feMobile'
                            required
                            type={'number'}
                            validate={(mobile) => {
                                if (mobile === '') return true;
                                return mobile.length === 10 || 'Invalid Mobile';
                            }}
                        />
                    </div>
                </>
            )}
        </>
    );
};
