import React from 'react';
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
    gruvboxLight,
    grayscale,
    nord,
    dark,
    dracula,
    docco,
    foundation,
    tomorrow,
    xcode
} from "react-syntax-highlighter/dist/esm/styles/prism";

export const FormattedGptMessage = ({ message }) => {
    let modifiedText = message.message.replace(/\\n/g, "\n");

    return (
        <div className='text-scogo15 text-font13 pr-6 pl-2 font-normal scogoGptClass'>
            <Markdown
                remarkPlugins={[[remarkGfm, { singleTilde: false }]]}
                children={modifiedText}
                components={{
                    code(props) {
                        const { children, className, node, ...rest } = props;
                        const match = /language-(\w+)/.exec(className || "");
                        return match ? (
                            <SyntaxHighlighter
                                {...rest}
                                children={String(children).replace(/\n$/, "")}
                                style={dracula}
                                language={match[1]}
                                PreTag="div"
                            />
                        ) : (
                            <code {...rest} className={className}>
                                {children}
                            </code>
                        );
                    }
                }}
            />
        </div>
    );
}