import Menus from './Menus';
import Checkbox from './Checkbox';

export const itemLables = ['Your Profile', 'Reply', 'Settings'];
export const menuButtonIcon = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6 mt-1' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
            <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z'
            />
        </svg>
    );
};
export let itemsClassName =
    'origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50';
export let itemClassName = 'block px-4 py-2 text-sm ';

export const actionHead = (title, className, customStyle, { hide } = {}) => {
    return {
        thClassName: className,
        render: (data) => {
            return data;
        },
        headName: title,
        style: customStyle,
        hide,
    };
};

export const actionBody = (classname, customStyle) => {
    return {
        bodyClassName: classname,
        render: (item) => {
            return (
                <Menus
                    className='relative'
                    displayType='flex'
                    position='ml-auto'
                    menuButtonIcon={menuButtonIcon}
                    itemsClassName={itemsClassName}
                    itemLable={itemLables}
                    itemClassName={itemClassName}
                />
            );
        },
        style: customStyle,
    };
};

export const checkBoxColumnHead = (title, classname, customStyle) => {
    return {
        thClassName: classname,
        render: (data) => {
            return (
                <>
                    <Checkbox checkColor='text-scogoorange mr-2' dynamicSize={'1.3rem'} />
                    {data}
                    <span className='material-icons text-font10 text-scogoprimary ml-1 cursor-pointer'>filter_alt</span>
                </>
            );
        },
        headName: title,
        style: customStyle,
    };
};

export const tableHeadWithImage = (title, classname, customStyle) => {
    return {
        thClassName: classname,
        render: (data) => {
            return (
                <>
                    {data}
                    <span className='material-icons text-font10 text-scogoprimary ml-1 cursor-pointer'>filter_alt</span>
                </>
            );
        },
        headName: title,
        style: customStyle,
    };
};

export const tableHead = (title, classname, customStyle, { hide, width = '', columnRowCss } = {},) => {
    return {
        thClassName: classname,
        render: (data) => {
            return data;
        },
        headName: title,
        style: customStyle,
        columnRowCss: columnRowCss,
        hide,
        width,
    };
};

export const circleCss = 'rounded-full flex justify-center font-bold items-center h-7 w-7 text-white mr-2';

export const widthClasses = {
    width40: 'min-w-40 w-40',
    width20: 'min-w-20 w-20',
    width28: 'min-w-28 w-28',
    width44: 'min-w-44 w-44',
    width48: 'min-w-48 w-48',
    width16: 'min-w-16 w-16',
    width14: 'min-w-14 w-14',
    width36: 'min-w-36 w-36',
    width32: 'min-w-32 w-32',
    width24: 'min-w-24 w-24',
};

export const textsClass = {
    medium: '2xl:text-font13 text-font11 text-scogo15 font-medium md:mr-0 mr-2',
    normal: '2xl:text-font13 text-font11 text-scogogray font-normal  md:mr-0 mr-2',
};

export const tableBodyOneElementCommonFunction = (className, getElem) => {
    return {
        bodyClassName: className,
        render: (item) => {
            return (
                <div className='md:block flex items-center'>
                    <p className={`${textsClass.medium}`}>{item?.[getElem]}</p>
                </div>
            );
        },
    };
};
