import React from 'react';

import FileUpload from './FileUpload';
import { useFormContext } from 'react-hook-form';
import { defaultSingleChatPic } from '../../pages/chat/ChatController';

const ProfileUpload = ({ name, id, required, appendFiles, accept, validateFileType, shouldUnregister, imageClass = 'w-32 h-32 object-cover rounded-full border-2', defaultPic = defaultSingleChatPic }) => {
    const { watch } = useFormContext();
    const imageList = watch(name);
    let uploadedImage = defaultPic

    if (imageList && imageList?.length > 0 && typeof imageList === 'object') {
        let imageUrl = URL.createObjectURL(imageList[0]);
        uploadedImage = imageUrl;
    }

    return (
        <FileUpload
            name={name}
            id={id}
            required={required}
            appendFiles={appendFiles}
            isMulti={false}
            accept={accept}
            validateFileType={validateFileType}
            shouldUnregister={shouldUnregister}
            renderFileUpload={({ triggerUpload }) => (
                <div
                    className='bg-transparent rounded font-bold cursor-pointer flex items-center custom-file-input'
                    onClick={(e) => {
                        e.preventDefault();
                        triggerUpload();
                    }}
                >
                    <img className={`${imageClass}`} src={uploadedImage} alt='Profile' />
                    {required && <span className='absolute top-3 right-3 text-scogoorange'>*</span>}
                </div>
            )}
            errorClassName='text-scogoclosed text-font08 font-normal pl-8'
        />
    );
};

export default ProfileUpload;
