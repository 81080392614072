import React from 'react'

const Bedges = ({ className, children }) => {
    // <p className={typeof children !== "string" ? children.props.className : "text-white font-semibold text-center 2xl:text-font13 text-font11"}>{children}</p>
    
    return (
        <>
        <div className={className} >
                <p className={typeof children === "object" ? children?.props?.className : "text-white font-semibold text-center text-xs truncate"}>{children}</p>
            </div>
        </>
    )
}

export default Bedges
