import { useSelector } from "react-redux";
import GridTable from "../../../../common/GridTable";
import OverflowTip from "../../../../common/OverflowTip";
import { actualValueForDropdown, validateStringValue } from "../../../../utils/common";
import ConsumableForm from "../../../../components/TicketExecution/BOQ/ConsumableForm";
import Form from "../../../../common/Form";
import { useForm } from "react-hook-form";
import { CLEANUP_FOR_UPDATE_PO } from "../../../../types/payments";
import { getBOQList, viewTicketById } from "../../../../actions/tickets";
import { useEffect } from "react";
import { getSiteBOQList, saveFeSiteBOQData } from "../../../../actions/ticketExecution";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { closeModalAction, openCustomModal } from "../../../../actions/modal";
import ButtonScogoPrimary from "../../../../common/Buttons/ButtonScogoPrimary";
import { SkeltonCardTwo } from "../../../../common/SkeltonCardTwo";


const formName = 'addConsumables'

export default function RenderAddConsumables({ ticketId }) {
    const dispatch = useDispatch();

    const handleClick = () => {
        let heading = `Add Consumables`;
        let modalComponent = <RenderConsumableForm ticketId={ticketId} />

        let modalWidth = '70rem',
            modalHeight = 'auto';
        dispatch(openCustomModal({ modalComponent, heading, modalWidth, modalHeight }));
    };

    return <ButtonScogoPrimary
        textOrComponent={'Add Consumables'}
        onClick={handleClick}
        loading={false}
        disabled={false}
        buttonIcon={'/img/plus.svg'}
    />
}

export const RenderConsumableForm = ({ ticketId, additionalClass = 'px-4', callOnFormCancel }) => {
    const assetsConsumableDataArrayName = 'assetsConsumableDataArrayName';
    const defaultValues = {
        [assetsConsumableDataArrayName]: []
    };
    const { viewTicket } = useSelector((state) => state.tickets);
    const { formButtonLoading } = useSelector((state) => state.loading);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const methods = useForm({ defaultValues, mode: 'all' });
    const { watch, setValue } = methods;

    const onCancel = () => {
        if (callOnFormCancel && typeof callOnFormCancel === 'function') {
            callOnFormCancel();
        } else {
            dispatch(closeModalAction())
        }
    }

    const onSubmit = (formValues) => {
        let consumables = [];
        if (Array.isArray(formValues[assetsConsumableDataArrayName]) && formValues[assetsConsumableDataArrayName].length > 0) {
            consumables = formValues[assetsConsumableDataArrayName].map(consumable => {
                consumable.asset_type_id = actualValueForDropdown(consumable.asset_type_id)
                return {
                    ...consumable,
                    asset_type_id: consumable.asset_type_id
                }
            })
        }

        const payload = {
            assetsData: [],
            assetsConsumableData: consumables
        }
        dispatch(saveFeSiteBOQData({
            formValues: payload, formName, ticket: viewTicket, ticketExecution: true, navigate, source: 'editPayout', onSuccess: () => setValue(assetsConsumableDataArrayName, [])
        }));
    };

    useEffect(() => {
        if (!viewTicket) {
            dispatch(viewTicketById({ ticketId }));
        }
        dispatch(getSiteBOQList(ticketId))
    }, [viewTicket, ticketId, dispatch]);

    useEffect(() => {
        dispatch(getBOQList(viewTicket?.customer_details?.customer_id));
        return () => {
            dispatch({ type: CLEANUP_FOR_UPDATE_PO });
        };
    }, [dispatch, viewTicket?.customer_details?.customer_id]);

    const consumeData = watch(assetsConsumableDataArrayName);

    return <div className="w-full">
        <div className={additionalClass}>
            <RenderAvailableBoqList />
        </div>
        <Form onCancel={onCancel} submitContainerClassName={'flex ml-2 gap-4 justify-end px-6 my-2'} showSubmit onSubmit={(formValues) => onSubmit(formValues)} methods={methods} defaultValues={defaultValues} className='w-full pb-2' isSubmitDisabled={consumeData?.length === 0} isLoading={formButtonLoading[formName]}>
            <ConsumableForm showLabel={false} assetsConsumableDataArrayName={assetsConsumableDataArrayName} ticket={viewTicket} />
        </Form>
    </div >
}

const RenderAvailableBoqList = () => {
    const { isSiteBoqLoading } = useSelector((state) => state.loading);
    const { siteBoqList } = useSelector((state) => state.tickets);
    if (isSiteBoqLoading) {
        return <SkeltonCardTwo height={20} arr={[1, 2, 3, 4, 5]} />
    }

    const schema = {
        table: [
            {
                head: {
                    render: (headName) => {
                        return (
                            <div className='flex items-center'>
                                {headName}
                            </div>
                        );
                    },
                    headName: 'Asset Type',
                },
                body: {
                    render: (asset, rowIndex) => {
                        return (
                            <div className='flex items-start w-full '>
                                {validateStringValue(asset.asset_name)}
                            </div>
                        );
                    },
                },
            },
            {
                head: {
                    render: (headName) => {
                        return (
                            <div className='flex items-center'>
                                {headName}
                            </div>
                        );
                    },
                    headName: 'Make',
                },
                body: {
                    render: (asset, rowIndex) => {
                        return (
                            <div className='flex items-start w-full '>
                                {validateStringValue(asset.make)}
                            </div>
                        );
                    },
                },
            },
            {
                head: {
                    render: (headName) => {
                        return (
                            <div className='flex items-center'>
                                {headName}
                            </div>
                        );
                    },
                    headName: 'Model',
                },
                body: {
                    render: (asset, rowIndex) => {
                        return (
                            <div className='flex items-start w-full '>
                                {validateStringValue(asset.model)}
                            </div>
                        );
                    },
                },
            },
            {
                head: {
                    render: (headName) => {
                        return (
                            <div className='flex items-center'>
                                {headName}
                            </div>
                        );
                    },
                    headName: 'Partcode',
                },
                body: {
                    render: (asset, rowIndex) => {
                        return <OverflowTip someLongText={validateStringValue(asset.partcode)} />
                    },
                },
            },
            {
                head: {
                    render: (headName) => {
                        return (
                            <div className='flex items-center'>
                                {headName}
                            </div>
                        );
                    },
                    headName: 'Serial Number',
                },
                body: {
                    render: (asset, rowIndex) => {
                        return <OverflowTip someLongText={validateStringValue(asset.serial_number)} />
                    },
                },
            },
        ]
    }

    if (Array.isArray(siteBoqList) && siteBoqList.length > 0) {
        return <GridTable
            schema={schema}
            rowData={siteBoqList}
            isLoading={false}
            tableError={'No Payments Found'}
            tableHeadClass='bg-scogof8 text-white'
        />
    }
    return <></>;
}