import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import { assignTechSupportUser, clearViewTicketFromStore, viewTicketById } from '../../../actions/tickets';
import { getTechSupportUsersDropdown } from '../../../actions/users';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { actualValueForDropdown } from '../../../utils/common';
import Form from '../../../common/Form';
import Select from '../../../common/Form/Select';
import { useForm, useFormContext } from 'react-hook-form';

const formName = 'assign_tech_support';

export default function AssignTechSupport({ ticketId, ticketIds, isBulk }) {
    const defaultValues = {};
    const dispatch = useDispatch();
    const { formButtonLoading } = useSelector((state) => state.loading);

    useEffect(() => {
        dispatch(getTechSupportUsersDropdown());
        if (ticketId) dispatch(viewTicketById({ ticketId }));

        return () => dispatch(clearViewTicketFromStore());
    }, [dispatch, ticketId]);

    const submit = (formValues) => {
        let userIds = formValues.users.map((user) => actualValueForDropdown(user));
        let payload = {
            userIds,
            formName,
        };
        if (isBulk) {
            payload.ticketIds = ticketIds;
        } else {
            payload.ticketId = ticketId;
        }
        dispatch(assignTechSupportUser(payload));
    };

    const methods = useForm({ defaultValues, mode: 'all' });

    return (
        <Form
            methods={methods}
            onSubmit={submit}
            formName={formName}
            buttonPostion={'justify-end'}
            submitButtonText={'Submit'}
            onCancel={() => dispatch(closeModalAction())}
            showSubmit
            submitContainerClassName='flex gap-2 justify-end py-4 px-4'
            isLoading={formButtonLoading[formName]}
        >
            <div className='px-4 py-2'>
                <TechSupportDropdown />
            </div>
        </Form>
    );
}

const TechSupportDropdown = (props) => {
    const { techSupportEngineersDropdown } = useSelector((state) => state.user);
    const { isTechSupportUserLoading } = useSelector((state) => state.loading);
    const { viewTicket } = useSelector((state) => state.tickets);

    const renderOption = ({ value, label, icons }) => {
        return (
            <div className='flex items-center gap-2'>
                <span className='flex items-center'>
                    {icons?.leftSide &&
                        icons.leftSide.map((icon) => {
                            return <span className={`text-lg material-icons text-${icon.color}`}>{icon.icon_name}</span>;
                        })}
                </span>
                <span>{label}</span>
            </div>
        );
    };

    const { setValue } = useFormContext();

    useEffect(() => {
        if (viewTicket && Array.isArray(viewTicket.user_relations) && viewTicket.user_relations.length > 0 && Array.isArray(techSupportEngineersDropdown)) {
            let users = techSupportEngineersDropdown.filter((supportEngineer) => {
                return viewTicket.user_relations.find((user) => {
                    return supportEngineer.value === user.fk_user_id;
                });
            });
            setValue('users', users);
        }
    }, [viewTicket, techSupportEngineersDropdown, setValue]);

    return (
        <Select
            label='Tech Support Engineer'
            name='users'
            options={techSupportEngineersDropdown}
            required
            isLoading={isTechSupportUserLoading}
            renderOption={renderOption}
            isMulti
        />
    );
};
