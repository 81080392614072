import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateTicketRMADetails, viewTicketById } from '../../../../actions/tickets';
import { clearSpareAssetDetail, findAllRepairCenters, getSpareAssetWithAssetId, getWareHouseDropdown } from '../../../../actions/inventory';
import { actualValueForDropdown, debounce, validateStringValue } from '../../../../utils/common';
import { getCourierVendors } from '../../../../actions/users';
import moment from 'moment';
import { UpdateRMAForm } from './UpdateRmaForm';
import { projectCss, textCss } from '../../../../common/Css';

const formName = 'update_rma_details';

export const fullDevice = { key: 1, value: 'full', label: 'Full Device' };
export const partDevice = { key: 2, value: 'part', label: 'Device Part' };
export const componentConsumedFromSystem = { key: 3, value: 'component_consumed_from_system', label: 'Component Consumed from system' };
export const noDeviceConsumed = { key: 4, value: 'no_device_consumed', label: 'No Device Consumed' };

export const rmaRequestTypeOptions = [fullDevice, partDevice, componentConsumedFromSystem, noDeviceConsumed];

export const handHelding = { value: 'HAND_HELDING', name: 'cl_rma_response', label: 'Hand Helding' };
export const docketNumber = { value: 'DOCKET_NUMBER', name: 'cl_rma_response', label: 'Docket Number' };
export const requestPickup = { value: 'REQUEST_PICKUP', name: 'cl_rma_response', label: 'Request Pickup' };

export const radioOptions = [handHelding, docketNumber, requestPickup];

export default function UpdateRmaDetails({ ticketId, heading = 'Update RMA Details' }) {
    const { viewTicket } = useSelector((state) => state.tickets);
    const { spateAssetDetail } = useSelector((state) => state.inventory);
    const dispatch = useDispatch();
    const [spareDetail, setSpareDetail] = useState({
        partcode: '',
        serial_number: '',
        assetTypeTitle: '',
    });

    const defaultValues = {
        cl_rma_response: handHelding.value,
        rma_request_type: fullDevice,
        assetNumber: '',
        job_shipping_fk_pincode_id: '',
        job_shipping_address_1: '',
        repair_center: '',
        pickup: '',
        docket_num: '',
        eta: new Date(),
        courier_vendor_id: '',
        part_name: '',
    };

    const ticketSpareAsset = viewTicket && viewTicket?.spare_assets?.[0];
    const faulty_assets = viewTicket && viewTicket?.faulty_assets;
    const ticketCustomerId = viewTicket?.customer_details?.customer_id;
    const currentWarehouseId = viewTicket?.warehouse;

    useEffect(() => {
        if (ticketSpareAsset) {
            const assetTypeTitle = validateStringValue(ticketSpareAsset?.title);
            const partcode = validateStringValue(ticketSpareAsset?.partcode);
            const serial_number = validateStringValue(ticketSpareAsset?.serial_number);
            setSpareDetail({ serial_number, partcode, assetTypeTitle });
        }
    }, [ticketSpareAsset]);

    useEffect(() => {
        if (spateAssetDetail) {
            const assetTypeTitle = validateStringValue(spateAssetDetail?.assetType?.title);
            const partcode = validateStringValue(spateAssetDetail?.partcode);
            const serial_number = validateStringValue(spateAssetDetail?.serial_number);
            setSpareDetail({ serial_number, partcode, assetTypeTitle });
        }
    }, [spateAssetDetail]);

    useEffect(() => {
        if (viewTicket?.id !== ticketId) {
            dispatch(viewTicketById({ ticketId }));
        }
        return () => {
            dispatch(clearSpareAssetDetail());
        };
    }, [dispatch, ticketId, viewTicket?.id]);

    const callDataOnMount = useCallback(
        (customerId) => {
            dispatch(getCourierVendors());
            dispatch(getWareHouseDropdown({ customerId }));
            dispatch(findAllRepairCenters({ customerId, dropdown: true, addCustomerDropdown: false }));
        },
        [dispatch]
    );

    useEffect(() => {
        if (ticketCustomerId) {
            callDataOnMount(ticketCustomerId);
        }
    }, [dispatch, callDataOnMount, ticketCustomerId]);

    const onAssetIdChange = (ticketId) => {
        let payload = {
            customerId: ticketCustomerId,
            assetNumber: ticketId,
            status: 'AVAILABLE',
        };
        dispatch(getSpareAssetWithAssetId(payload));
    };
    const debouncedAssetIdChange = useCallback(debounce(onAssetIdChange, 300), []);



    const updateRmaDetails = useCallback(
        ({
            assetNumber,
            rma_request_type,
            pickup,
            repair_center,
            job_shipping_address_1,
            job_shipping_fk_pincode_id,
            cl_rma_response,
            eta,
            docket_num,
            courier_vendor_id,
            part_name,
        }) => {
            const isDevicePartSelected = actualValueForDropdown(rma_request_type) === partDevice.value;
            const isFullDeviceSelected = actualValueForDropdown(rma_request_type) === fullDevice.value;
            const isNoDeviceConsumendSelected = actualValueForDropdown(rma_request_type) === noDeviceConsumed.value;
            const isDocketNumberSelected = cl_rma_response === docketNumber.value;
            const isHandHeldingSelected = cl_rma_response === handHelding.value;
            const isRequestPickupSelected = cl_rma_response === requestPickup.value;

            let payload = {
                cl_rma_response,
            };

            if (!isNoDeviceConsumendSelected) {
                if (repair_center?.warehouse) payload.repair_center = repair_center.warehouse.id;
                payload.rma_request_type = actualValueForDropdown(rma_request_type);

                if (isDevicePartSelected) {
                    payload.part_name = part_name;
                } else if (isFullDeviceSelected) {
                    payload.assetNumber = assetNumber;
                }

                if (isHandHeldingSelected) payload.eta = moment(eta).format('YYYY-MM-DD');
                else if (isDocketNumberSelected) {
                    payload.docket_num = docket_num;
                    payload.courier_vendor_id = actualValueForDropdown(courier_vendor_id);
                } else if (isRequestPickupSelected) {
                    payload.warehouse = actualValueForDropdown(pickup);
                    payload.job_shipping_fk_pincode_id = job_shipping_fk_pincode_id.toString();
                    payload.job_shipping_address_1 = job_shipping_address_1;
                }
            } else {
                payload.rma_request_type = actualValueForDropdown(rma_request_type)
                payload.cl_rma_response = 'HAND_HELDING'
                payload.eta = moment(new Date()).format('YYYY-MM-DD')
            }
            dispatch(updateTicketRMADetails({ formName, ticketId, data: payload, refresh: true }));
        },
        [dispatch, ticketId]
    );

    const submit = useCallback(
        (formValues) => {
            updateRmaDetails(formValues);
        },
        [updateRmaDetails]
    );

    return (
        <>
            <div className={`subContainer title-4 p-5`}>
                <div className={`titleContainer`}>
                    <h1 className='text-scogo21 text-font16 font-medium font-rubik '>{heading} : {viewTicket && <span className='text-scogobgsky text-font16'>{viewTicket.job_ticket_number}</span>}</h1>
                </div>
                <div className='flex-auto'>
                    <div className='flex-auto '>
                        <div className='tab-content tab-space bg-white shadow-xl rounded-lg	'>
                            <div className={`${projectCss?.scmAddProjectForm} lg:pb-0 md:pb-168 pb-230 overflow-auto mb-24 md:mb-24`}>
                                <UpdateRMAForm
                                    defaultValues={defaultValues}
                                    submit={submit}
                                    ticketId={ticketId}
                                    faulty_assets={faulty_assets}
                                    spareDetail={spareDetail}
                                    debouncedAssetIdChange={debouncedAssetIdChange}
                                    currentWarehouseId={currentWarehouseId}
                                    viewTicket={viewTicket}
                                    ticketCustomerId={ticketCustomerId}
                                    formName={formName}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
