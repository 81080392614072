import React, { useState, forwardRef } from 'react';

import { useDispatch } from 'react-redux';

import Popover from '@mui/material/Popover';
import AssetHistory from '../../pages/Inventory/reusableComponents/AddOption/AssetHistory';
import { openCustomModal } from '../../actions/modal';
import { getAssetColor } from '../../utils/common';
import IconToolTip from '../IconToolTip';
import { copyToClipboard } from '../../actions/utils';


const Popup = forwardRef(({ assetList, close }, ref) => {
    const dispatch = useDispatch();
    const viewAssetHistory = (asset) => {
        if (asset?.serial_number?.length > 1) {
            let modalComponent = <AssetHistory assetId={asset?.fk_asset_id} assetSource={asset.is_spare === 0 ? 'asset' : 'spare'} />;
            let modalWidth = '50em',
                modalHeight = '40em';
            let head = <span >
                Asset History
                <span className={`ml-2 text-font14 text-${getAssetColor(asset)}`} >{asset?.serial_number}</span>
            </span>
            dispatch(openCustomModal({ modalComponent, heading: head, modalWidth, modalHeight }));
            close();
        }
    };


    return (
        <div className='bg-white max-h-44 overflow-scroll cursor-pointer max-w-14 max-w-56 p-2  z-100 border shadow-lg boqpop'>
            {assetList?.map((asset) => {
                const status = `${asset?.status?.length > 0 ? ` ${asset?.status}` : ""}`;
                const workingStatus = `${asset?.working_condition?.length > 0 ? `, ${asset?.working_condition}` : ""}`;
                return (
                    <IconToolTip title={`${status} ${workingStatus}`} onClick={() => viewAssetHistory(asset)}>
                        <span className={`bg-${getAssetColor(asset)}  text-white max-w-12  w-fit text-font10 px-2 flex items-center rounded-3px  mb-1`} >
                            <span className='max-w-4/5 truncate'  >
                                {(asset?.serial_number?.length > 0 ? asset?.serial_number : asset?.title)}
                            </span>
                            <span>
                                {(asset?.count > 1 ? `: ${asset?.count}` : '')}
                            </span>
                            <span className="material-icons text-font12 ml-1 hover:text-scogobgsky" onClick={(event) => {
                                event.stopPropagation();
                                const text = asset?.serial_number?.length > 0 ? asset?.serial_number : asset?.title
                                dispatch(copyToClipboard({ data: text }))
                            }}>
                                content_copy
                            </span>
                        </span>
                    </IconToolTip>
                );
            })}
        </div>
    );
});

const AssetPopup = ({
    children,
    assetList,
    anchorOrigin = {
        vertical: 'top',
        horizontal: 'center',
    },
    transformOrigin = {
        vertical: 'bottom',
        horizontal: 'center',
    },
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <div aria-describedby={id} onClick={handleClick} variant='contained' className=' hover:underline cursor-pointer inline'>
                {children}
            </div>
            <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={anchorOrigin} transformOrigin={transformOrigin}>
                <Popup assetList={assetList} close={handleClose} />
            </Popover>
        </>
    );
};

export default AssetPopup;
