import { utils } from '../types';

const INITIAL_STATE = {
    geolocation: {
        latitude: 0.00,
        longitude: 0.00
    },
    error: undefined,
    clearFilterAction: () => { },
    isRoomListOpen: false
};

const utilsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case utils.SEARCH_MODULE_DATA_SUCCESS:
            return {
                ...state,
                searchData: action.payload,
            }
        case utils.SEARCH_MODULE_DATA_COUNT_SUCCESS:
            return {
                ...state,
                searchCount: action.payload
            }
        case utils.FILTER_MODULE_DATA_SUCCESS:
            return {
                ...state,
                filteredData: action.payload
            }
        case utils.CLEAR_FILTER_MODULE_DATA:
            return {
                ...state,
                filteredData: []
            }
        case utils.FILTER_MODULE_DATA_COUNT_SUCCESS:
            return {
                ...state,
                filterDataCount: action.payload
            }
        case utils.SET_INITIAL_FORM:
            return {
                ...state,
                setInitialForm: action.payload
            }
        case utils.SET_CLEAR_FILTER_BUTTON_ACTION:
            return {
                ...state,
                clearFilterAction: action.payload
            }
        case utils.UPDATE_LAT_LONG_SUCCESS:
            return {
                ...state,
                geolocation: action.payload
            }
        case utils.SET_ROOM_LIST_DATA:
            const state_data = { ...state };
            const { isRoomListOpen, activeCallId } = action.payload;
            if (isRoomListOpen !== undefined) state_data.isRoomListOpen = isRoomListOpen;
            if (activeCallId !== undefined) state_data.activeCallId = activeCallId;
            return state_data;

        case utils.SET_SELECTED_LOCATION_FROM_MAP: {
            return { ...state, selectedLocationOnMap: action.payload }
        }
        default:
            return {
                ...state,
            };
    }
};

export default utilsReducer;


