import React from "react";
import { useDispatch } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import DetailTag from "../../../pages/project/DetailTag";
import { textCss } from '../../../common/Css';
import Form from "../../../common/Form";
import Input from "../../../common/Form/Input";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { isCustomer, isScm } from "../../../utils/role";
import { scrapAssets } from "../../../actions/inventory";


const formName = 'scrapAsset';
const ScrapAsset = ({ modalData, customerId, customerIdOfSelectedSite, modalName, siteId, warehouseId }) => {
    const { assetslist } = modalData;
    const dispatch = useDispatch();
    const { formButtonLoading } = useSelector((state) => state.loading);
    const defaultValues = {};
    const { loggedUser } = useSelector((state) => state.auth);
    const loggedUserRole = loggedUser.role.id,
        loggedUserType = loggedUser.type;

    const submit = (formValues) => {
        const scrapAssetList = [];
        Object.entries(formValues).forEach(([key, value]) => {
            let ticketId = parseInt(key.split("_").slice(-1)[0]);
            scrapAssetList.push({
                id: ticketId,
                resale_value: parseInt(value)
            })
        })
        const scrapAssetPayload = getScrapAssetApiPayload({ scrapAssetList, customerIdOfSelectedSite, formName, modalName, siteId, warehouseId, customerId, loggedUserType, loggedUserRole });

        dispatch(scrapAssets(scrapAssetPayload));
    }

    const methods = useForm({ defaultValues, mode: 'all' });

    return (
        <>
            <Form
                methods={methods}
                onSubmit={submit}
                submitButtonText={'Submit'}
                onCancel={() => dispatch(closeModalAction())}
                showSubmit
                submitContainerClassName='flex gap-2 justify-end py-4 px-4'
                isLoading={formButtonLoading[formName]}
            >
                {
                    Array.isArray(assetslist) && assetslist.map((asset, index) => {
                        return <AssetDetailsRow assetTypeTitle={asset.assetType.title} assetId={asset.assetId} index={index} id={asset.id} />
                    })
                }
            </Form>

        </>
    );

}

export default ScrapAsset;



const AssetDetailsRow = ({ assetTypeTitle, assetId, index, id }) => {
    return <div className="md:flex pl-5 pt-3 ">
        <DetailTag tagHeadClass={textCss?.detailsTitle} tagValueClass={textCss?.detailsValue} tagClass=" px-2 md:w-6/12" tagName="Asset Id" tagValue={assetId} />
        <DetailTag tagHeadClass={textCss?.detailsTitle} tagValueClass={textCss?.detailsValue} tagClass="px-2 md:w-6/12" tagName="Asset" tagValue={assetTypeTitle} />

        <Input label='Resale Value' required name={`resale_value_${id}`} type={'number'} placeholder={'Resale Value'} className={'w-full'} />
    </div>
}


export const getScrapAssetApiPayload = ({ scrapAssetList, customerIdOfSelectedSite, formName, modalName, siteId, warehouseId, customerId, loggedUserRole, loggedUserType }) => {
    let payload = {
        assets: [...scrapAssetList],
    };

    if (isScm(loggedUserRole, loggedUserType)) {
        payload = Object.assign(payload, { customerId: customerIdOfSelectedSite });
        return { data: payload, formName, modalName, siteId, warehouseId, customerId, refresh: modalName };
    }
    if (isCustomer(loggedUserRole, loggedUserType)) return { data: payload, formName, modalName, siteId, warehouseId, customerId, refresh: modalName };
    return { data: payload, formName, modalName, siteId, warehouseId, customerId, refresh: modalName };
};