import { modal } from '../types';

export const openTextModalAction = ({ heading = '', text = '', type = '', buttons = [], closeOnBackdropClick = true, formName, selectedCount }) => ({
    type: modal.OPEN_TEXT_MODAL,
    payload: { heading, text, type, buttons, closeOnBackdropClick, formName, selectedCount },
});

export const closeModalAction = () => ({
    type: modal.CLOSE_MODAL,
    payload: {},
});

export const openCustomModal = ({ modalComponent, heading, modalWidth, selectedCount, modalHeight, showClose, showLogout, maxHeight, headerComponent, onClose }) => ({
    type: modal.OPEN_CUSTOM_MODAL,
    payload: { modalComponent, heading, modalWidth, selectedCount, modalHeight, showClose, showLogout, maxHeight, headerComponent, onClose },
});

export const openFullscreenModal = ({ modalComponent, heading }) => ({
    type: modal.OPEN_FULLSCREEN_MODAL,
    payload: { modalComponent, heading },
});

export const openSmallCustomModal = ({ modalComponent, heading }) => ({
    type: modal.OPEN_SMALL_CUSTOM_MODAL,
    payload: { modalComponent, heading },
});

export const closeImageModalAction = () => ({
    type: modal.CLOSE_IMAGE_MODAL,
    payload: {},
});

export const openCustomImageModal = ({ imageUrl, imageheading, modalImageWidth, modalImageHeight }) => ({
    type: modal.OPEN_CUSTOM_IMAGE_MODAL,
    payload: { imageUrl, imageheading, modalImageWidth, modalImageHeight },
});

export const openMediaViewModal = ({ attachments, sender, openedMediaIndex, showSenderDetail = true, isTicketChat, callStepFields, isExecuteImage, currentConversationId }) => ({
    type: modal.MEDIA_VIEW_MODAL,
    payload: { attachments, sender, openedMediaIndex, showSenderDetail, isTicketChat, callStepFields, isExecuteImage, currentConversationId },
});

export const openTutorialSteps = ({ steps }) => ({
    type: modal.OPEN_TUTORIAL_STEPS,
    payload: { steps },
});

export const closeTutorialSteps = () => ({
    type: modal.CLOSE_TUTORIAL_STEPS,
    payload: {},
});


export const openProgressBarModal = () => ({
    type: modal.OPEN_PROGRESS_BAR_MODAL,
});

export const closeProgressBarModal = () => ({
    type: modal.CLOSE_PROGRESS_BAR_MODAL
})

export const openRequestedCallModal = ({ data, isOnCall }) => ({
    type: modal.OPEN_REQUESTED_CALL_MODAL,
    payload: { data, isOnCall }
});


export const closeFullScreenModalAction = () => ({
    type: modal.CLOSE_FULL_SCREEN_MODAL,
    payload: {},
});