import React from 'react';
import Form from '../../../common/Form';
import { useForm } from 'react-hook-form';
import { closeModalAction } from '../../../actions/modal';
import { useDispatch } from 'react-redux';
import MultipleEmails from '../../../common/Form/MultipleEmail';
import { useSelector } from 'react-redux';
import { zipTicketReports } from '../../../actions/tickets';

const formName = 'DownloadCustomerReport';

export default function DownloadCustomerReport({ ticketIds }) {
    const { loggedUser } = useSelector((state) => state.auth);
    const { formButtonLoading } = useSelector((state) => state.loading);
    const defaultValues = {
        emails: loggedUser?.email ? [loggedUser?.email] : [],
    };
    const dispatch = useDispatch();
    const methods = useForm({ defaultValues, mode: 'all' });

    const submit = (formValues) => {
        dispatch(zipTicketReports({ ticketsData: ticketIds, reportType: 'customer', emails: formValues.emails, formName }));
    };
    return (
        <Form
            onSubmit={submit}
            methods={methods}
            showSubmit
            submitContainerClassName='flex gap-2 justify-end py-4 px-4'
            isLoading={formButtonLoading[formName]}
            submitButtonText='Download'
            onCancel={() => dispatch(closeModalAction())}
        >
            <div className='py-2'>
                <MultipleEmails name='emails' label='Emails' required />
            </div>
        </Form>
    );
}
