import React from 'react';
import { useNavigate } from 'react-router-dom';
import { GetQueryParam } from '../../utils/common';
import { trackSupportTicket } from '../../router/roleWisePageRoute';
import { useSelector } from 'react-redux';


export default function RenderChatIcon({ onChatIconClick, showConversation, loggedUser, ticketDetails, showIcon }) {

    if (!showIcon && (showConversation || !loggedUser || !ticketDetails)) return <></>;

    return <div className={`fixed z-30 rounded-full cursor-pointer ${showIcon ? '' : 'renderChatIcon'}`}
        style={{
            bottom: '7rem',
            right: '2rem',
            height: '5rem',
            width: '5rem'
        }}
    >
        <div className='flex flex-col items-center'>
            <img onClick={onChatIconClick} alt='chat' src={process.env.PUBLIC_URL + '/img/chat_colourful.png'} />
            <span className='text-scogoprimary font-medium text-font13'>Chat</span>
        </div>
    </div>
}

export const RenderMapIcon = ({ show }) => {
    const navigate = useNavigate();
    const ticketId = GetQueryParam('ticketId', Number);
    const redirectToTicketDetails = GetQueryParam('redirectToTicketDetails', Boolean);
    const { ticketDetails, openTicketDetails } = useSelector((state) => state.tickets);

    const isLiveTrackingEnabled = (ticketDetails && ticketDetails.is_live_tracking_enabled === 1 && ticketDetails.reached_site === 0) || (openTicketDetails && openTicketDetails.is_live_tracking_enabled === 1 && openTicketDetails.reached_site === 0);

    const onMapClick = () => {
        navigate(`${trackSupportTicket.path}?ticketId=${ticketId}&redirectToMap=true`);
    };

    if (show || (redirectToTicketDetails && isLiveTrackingEnabled)) {
        return <div className={`fixed z-30 rounded-full cursor-pointer`}
            style={{
                bottom: '7rem',
                left: '2rem',
                height: '5rem',
                width: '5rem'
            }}
        >
            <div className='flex flex-col items-center'>
                <img onClick={onMapClick} alt='chat' src={process.env.PUBLIC_URL + '/img/placeholder.png'} />
                <span className='text-scogoprimary font-medium text-font13'>Track</span>
            </div>
        </div>
    }
    return <></>;
}