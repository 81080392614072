import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Layout from './Layout';
import { Link } from 'react-router-dom';
import { isValidEmail, isValidMobileNumber } from '../../utils/utils';
import Form from '../Inventory/reusableComponents/AddOption/Form';
import Input from '../Inventory/reusableComponents/AddOption/Input';
import { loginWithOTPAction, putToAuthStore, setUserPassword } from '../../actions/auth';
import { requestOtpAction } from '../../actions/auth';
import ButtonScogoPrimary from '../../common/Buttons/ButtonScogoPrimary';
import ButtonScogoPrimaryOutlined from '../../common/Buttons/ButtonScogoPrimaryOutlined';

const formName = 'setPassword';
const setPassword = 'Set Password';
const login = 'Login';

const SetPassword = () => {
    const auth = useSelector((state) => state.auth);
    const { formButtonLoading, isRequestOtpButtonLoading } = useSelector((state) => state.loading);
    const [submitButtonName, setSubmitButtonName] = useState(setPassword);
    const { mobileOrEmail, checkUserDetails, requestOTPSuccess } = auth;
    const [isResend, setIsResend] = useState(false);
    const isUserEmailExist = isValidEmail(checkUserDetails?.data?.email);
    const [isSkippedClicked, setIsSkippedClicked] = useState(false);
    const formRef = useRef();
    const [errors, setErrors] = useState({});

    const navigate = useNavigate();
    if (!mobileOrEmail && !checkUserDetails) navigate('/login');

    let mobile_number = mobileOrEmail.toString();
    if (isValidEmail(mobileOrEmail) && isValidMobileNumber(checkUserDetails?.data?.mobile)) {
        mobile_number = checkUserDetails?.data?.mobile;
    }

    const initialValues = {
        email: '',
        password: '',
        password_confirmation: '',
        otp: '',
    };

    const [form, setForm] = useState(initialValues);
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(putToAuthStore({ requestOTPSuccess: undefined }));
            setIsSkippedClicked(false);
        };
    }, [dispatch]);

    const [counter, setCounter] = useState(0);

    useEffect(() => {
        if (counter === 0) setIsResend(false);
        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);

    const resendOTP = () => {
        setIsResend(true);
        setCounter(20);
        const mobile = checkUserDetails?.data?.mobile;
        dispatch(loginWithOTPAction({ mobile, otp: form.otp, navigate, resendOtp: true }));
    };

    const callRequestOTP = ({ mobile, isRedirect = false }) => {
        dispatch(
            requestOtpAction({
                mobile,
                navigate,
                isRedirect,
            })
        );
    };

    const submit = (formValues) => {
        let { password_confirmation, password, email, otp } = formValues;
        let isError = validateForm({ password_confirmation, password, email });
        if (isError) return;
        setErrors({});
        if (submitButtonName === setPassword) {
            callRequestOTP({ mobile: mobile_number });
            setSubmitButtonName(login);
        } else {
            let payload = {
                auth_otp: otp?.toString,
                password,
                password_confirmation,
                mobile: mobile_number,
            };

            if (!isUserEmailExist) {
                payload.email = email;
            } else {
                payload.email = checkUserDetails?.data?.email;
            }

            if (payload.password && payload.password_confirmation && payload.email) {
                dispatch(setUserPassword({ data: payload, navigate, formName }));
            } else {
                const mobile = checkUserDetails?.data?.mobile;
                dispatch(loginWithOTPAction({ mobile, otp, navigate }));
            }
        }
    };

    const resendButton = () => {
        if (isResend) {
            return (
                <p className='text-font14 text-151515 text-center font-medium'>
                    Resend OTP in <span className='text-scogoorange'>00:{counter}</span>{' '}
                </p>
            );
        }

        return (
            <p className='text-font14 text-151515 text-center font-medium'>
                Didn’t Receive OTP? &nbsp;
                <span
                    className='text-scogoorange cursor-pointer'
                    onClick={() => {
                        resendOTP();
                    }}
                >
                    Resend
                </span>
            </p>
        );
    };

    const validateForm = ({ password_confirmation, password, email }) => {
        let emailError = false;
        if (!isUserEmailExist) {
            emailError = validateEmail(email);
            if (emailError.error) setErrors((errors) => ({ ...errors, email: emailError.message }));
        }
        let passwordError = isValidPassword(password, password_confirmation);
        if (passwordError.error) setErrors((errors) => ({ ...errors, password: passwordError.message }));
        let confirmPasswordError = isValidPassword(password_confirmation, password);
        if (confirmPasswordError.error) setErrors((errors) => ({ ...errors, password_confirmation: confirmPasswordError.message }));
        return (!isUserEmailExist && emailError.error) || passwordError.error || confirmPasswordError.error;
    };

    return (
        <Layout>
            <div className='px-2 ' style={{ width: '35rem' }}>
                <p className='text-font16 text-scogoorange text-bold font-normal mt-4 text-center'>
                    Set
                    {!isUserEmailExist && <span> Email and</span>} Password for your scogo account
                </p>
                <Form
                    setForm={setForm}
                    form={form}
                    formName={formName}
                    className='w-full'
                    submit={(e) => submit(e)}
                    initialValues={initialValues}
                    customFormButtons
                    formRef={formRef}
                >
                    {!isUserEmailExist && (
                        <>
                            <Input
                                rounded
                                className='w-full py-1'
                                type={'text'}
                                name='email'
                                placeholder={'Email'}
                                leadingIcon={<span className='material-icons text-font18 text-scogoorange absolute  h-14  p-4'>email</span>}
                                isEditable={submitButtonName !== login}
                            />
                            <ErrorInput error={errors.email} />
                        </>
                    )}
                    <>
                        <Input
                            rounded
                            className='w-full py-1'
                            type={'password'}
                            name='password'
                            placeholder={'Password'}
                            leadingIcon={<span className='material-icons text-font18 text-scogoorange absolute  h-14  p-4'>lock</span>}
                            inputClass={form.password ? 'text-font24' : 'text-font14'}
                            isEditable={submitButtonName !== login}
                        />
                        <ErrorInput error={errors.password} />
                    </>
                    <>
                        <Input
                            rounded
                            className='w-full py-1'
                            type={'password'}
                            name='password_confirmation'
                            placeholder={'Confirm Password'}
                            leadingIcon={<span className='material-icons text-font18 text-scogoorange absolute  h-14  p-4'>lock</span>}
                            inputClass={form.password_confirmation ? 'text-font24' : 'text-font14'}
                            isEditable={submitButtonName !== login}
                        />
                        <ErrorInput error={errors.password_confirmation} />
                    </>

                    {submitButtonName === login && requestOTPSuccess && (
                        <Input rounded required className='w-full py-1' type={'number'} name='otp' placeholder={'OTP'} maxLength='4' />
                    )}
                    <div className='flex w-full gap-4'>
                        <ButtonScogoPrimary
                            textOrComponent={submitButtonName}
                            disabled={false}
                            buttonClass='h-14 mt-3 rounded-md hover:bg-scogobgsky text-font14 w-6/12'
                            disabledClass='bg-scogogray text-white font-medium h-14 mt-3 rounded-md hover:bg-scogogray w-6/12 text-font14'
                            loaderClass={'w-full py-2'}
                            onClick={(e) => {
                                e.preventDefault();
                                formRef?.current?.click();
                            }}
                            loading={formButtonLoading?.[formName] || (isRequestOtpButtonLoading && !isSkippedClicked)}
                        />

                        <ButtonScogoPrimaryOutlined
                            textOrComponent={'Skip'}
                            disabled={false}
                            buttonClass='font-medium text-font12 h-14 mt-3 rounded-md hover:bg-scogoprimary hover:text-white w-6/12'
                            disabledClass='bg-scogogray text-white font-medium text-font16 h-14 mt-3 rounded-md hover:bg-scogogray w-6/12'
                            loaderClass={'w-full py-2'}
                            onClick={(e) => {
                                e.preventDefault();
                                callRequestOTP({ mobile: mobile_number, isRedirect: true });
                                setIsSkippedClicked(true);
                            }}
                            loading={isRequestOtpButtonLoading && isSkippedClicked}
                        />
                    </div>
                </Form>
                {submitButtonName === login && requestOTPSuccess && resendButton()}
                <p className='text-font14 text-151515 font-medium text-left'>
                    <Link to='/login' exact='true'>
                        <span className='text-scogoorange cursor-pointer hover:text-scogoprimary'> {'< Back'} </span>
                    </Link>
                </p>
            </div>
        </Layout>
    );
}

export default SetPassword;

const validateEmail = (email) => {
    let error = false;
    let message = '';
    if (email.length === 0) {
        message = 'This Field is Required';
        error = true;
    } else if (!isValidEmail(email)) {
        message = 'Invalid Email';
        error = true;
    }
    return { error, message };
};

const isValidPassword = (password_a, password_b) => {
    let message = '';
    let error = false;
    if (!password_a) {
        message = `This Field is Required`;
        error = true;
    } else if (password_a.length < 6) {
        message = `Password must contain at least 6 characters`;
        error = true;
    } else if (password_b && password_b !== password_a) {
        message = `Password and Confirm Password is Different`;
        error = true;
    }
    return { message, error };
};

const ErrorInput = ({ error }) => {
    if (!error) return <></>;
    return <span className='text-scogoclosed text-font10 font-normal'>{error}</span>;
};
