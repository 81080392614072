import { takeLatest, call, put, all } from 'redux-saga/effects';
import api from '../apis';
import apis from '../lambdas/apis';
import { project, loading, toast, modal, utils } from '../types';
import lambdas from '../lambdas/apis';
import { generateCreateProjectform } from '../utils/form';
import microservices from '../lambdas/microservices';
import { getFileType, uploadFileToS3WithSignedUrl } from '../utils/common';
import { teamWork } from '../router/roleWisePageRoute';

export function* getProjectList(action) {
    const { customerId } = action.payload;
    try {
        yield put({ type: loading.SET_PROJECT_LIST_LOADING, payload: true });
        const response = yield call(api.project.getProjectList, {}, {}, { customer_id: customerId });
        if (response.data.code === 200) {
            yield put({ type: project.GET_PROJECT_LIST_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: project.GET_PROJECT_LIST_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: project.GET_PROJECT_LIST_FAILURE, payload: error });
    }
    yield put({ type: loading.SET_PROJECT_LIST_LOADING, payload: false });
}

export function* getAllProjectList(action) {
    const { customerId } = action.payload;
    let queryParams = {};
    if (customerId) {
        queryParams.customer_id = customerId;
    }
    try {
        yield put({ type: loading.SET_PROJECT_LIST_LOADING, payload: true });
        const response = yield call(api.project.listAll, {}, {}, queryParams);
        if (response.data.code === 200) {
            yield put({ type: project.GET_ALL_PROJECT_LIST_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: project.GET_ALL_PROJECT_LIST_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: project.GET_ALL_PROJECT_LIST_FAILURE, payload: error });
    }
    yield put({ type: loading.SET_PROJECT_LIST_LOADING, payload: false });
}

export function* getProjectsCount(action) {
    const { customer_id } = action.payload;
    let queryParams = {};
    if (customer_id) {
        queryParams.customer_id = customer_id;
    }
    try {
        let response = yield call(api.project.getCount, {}, {}, queryParams);
        if (response.data.code === 200) {
            yield put({ type: project.PROJECTS_COUNT_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: project.PROJECTS_COUNT_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: project.PROJECTS_COUNT_FAILURE, payload: error });
    }
}

export function* getProjectDropDown(action) {
    const { customerId } = action.payload;
    try {
        yield put({ type: loading.SET_PROJECT_DROPDOWN_LIST_LOADING, payload: true });
        const response = yield call(api.project.listAll, {}, {}, { customer_id: customerId });
        if (response.data.code === 200) {
            let projectsDropdown = [];
            response.data.data.forEach((item) => {
                projectsDropdown.push({
                    key: `teams_${item?.id}}`,
                    value: item?.id,
                    label: `${item?.project_name}`,
                });
            });
            yield put({ type: project.GET_ALL_PROJECT_DROPDOWN_LIST_SUCCESS, payload: projectsDropdown });
        } else {
            yield put({ type: project.GET_ALL_PROJECT_DROPDOWN_LIST_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: project.GET_ALL_PROJECT_DROPDOWN_LIST_FAILURE, payload: error });
    }
    yield put({ type: loading.SET_PROJECT_DROPDOWN_LIST_LOADING, payload: false });
}

export function* addTeamProject(action) {
    const { teams, projectId, customerId, formName, callLinkedProjectList = false } = action.payload;
    const form = {
        property: 'LINK',
        teams: teams,
    };
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.projects.addorRemoveTeamProject, {}, { projectId }, {}, form);
        if (response.data.code === 201) {
            yield all([
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: project.ADD_TEAM_PROJECT_SUCCESS, payload: response.data.data }),
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Added Team in Project Succesfully' } }),
                yield put({ type: project.PROJECT_TEAM_LIST_REQUEST, payload: { projectId, customerId, agentsInfo: true } }),
            ]);
            if (callLinkedProjectList) yield put({ type: project.GET_LINKED_PROJECT_OF_TEAMS_REQUEST, payload: { teamId: teams[0] } });
        } else {
            yield put({ type: modal.CLOSE_MODAL });
            yield put({ type: project.ADD_TEAM_PROJECT_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: project.ADD_TEAM_PROJECT_FAILURE, payload: error });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* removeTeamProject(action) {
    const { teamId, projectId, customerId, teamName, projectName, formName, closeModal = true, refresh = true } = action.payload;
    const form = {
        property: 'REMOVE',
        teamId: teamId,
    };
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.projects.addorRemoveTeamProject, {}, { projectId }, {}, form);
        if (response.data.code === 201) {
            let message = `${teamName} is succesfully removed from ${projectName}`;
            let actions = [yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message } })];
            if (refresh) {
                if (window.location.pathname === teamWork.path) {
                    actions.push(yield put({ type: project.GET_LINKED_PROJECT_OF_TEAMS_REQUEST, payload: { teamId } }))
                }
                else actions.push(yield put({ type: project.PROJECT_TEAM_LIST_REQUEST, payload: { agentsInfo: true, projectId, customerId } }));
            }
            if (closeModal) actions.push(yield put({ type: modal.CLOSE_MODAL }));
        } else {
            yield put({ type: project.REMOVE_TEAM_PROJECT_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: project.REMOVE_TEAM_PROJECT_FAILURE, payload: error });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* updatePmDetails(action) {
    const { pm, type, projectId, formName } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });

        const response = yield call(apis.projects.updatePmDetails, {}, {}, {}, { pm, type, projectId });
        if (response.data.code === 200) {
            yield all([
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: project.UPDATE_PM_DETAILS_SUCCESS, payload: response.data.data }),
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'PM Assigned Succesfully' } }),
            ]);
        } else {
            yield all([
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: project.UPDATE_PM_DETAILS_FAILURE, payload: { message: 'Oops Something went wrong' } }),
                yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } }),
            ]);
        }
    } catch (error) {
        yield put({ type: project.UPDATE_PM_DETAILS_FAILURE, payload: error });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* editProject(action) {
    const { formValues, customerId, type, formName } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const form = yield call(generateCreateProjectform, { ...formValues });
        const response = yield call(api.project.editProject, { 'Content-Type': 'multipart/form-data' }, {}, {}, form);
        if (response.data.code === 200) {
            yield all([
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Project Updated Succesfully' } }),
                yield put({ type: project.EDIT_PROJECT_SUCCESS, payload: { formValues } }),
            ]);
            if (type === 'modal') {
                yield put({ type: modal.CLOSE_MODAL });
            }
        } else {
            yield put({ type: project.EDIT_PROJECT_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: project.EDIT_PROJECT_FAILURE, payload: error });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* emailTomorrowsPlannedTicketsCsv(action) {
    const { data } = action.payload;
    let formName = data.formName;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        let response = yield call(lambdas.projects.emailTomorrowsPlannedTicketsCsv, {}, {}, {}, data);
        if (response.data.code === 200) {
            yield all([yield put({ type: modal.CLOSE_MODAL }), yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: response.data.message } })]);
        } else if (response.data.code !== 200) {
            yield all([yield put({ type: modal.CLOSE_MODAL }), yield put({ type: project.EMAIL_PLANNED_TICKETS_CSV_FAILURE, payload: { message: 'Oops Something went wrong' } })]);
        }
    } catch (error) {
        yield put({ type: project.EMAIL_PLANNED_TICKETS_CSV_FAILURE, payload: error });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* emailTodaysConfigCompleteTicketsCsv(action) {
    const { data } = action.payload;
    let formName = data.formName;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        let response = yield call(lambdas.projects.emailTodaysConfigCompleteTicketsCsv, {}, {}, {}, data);
        if (response.data.code === 200) {
            yield all([yield put({ type: modal.CLOSE_MODAL }), yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: response.data.message } })]);
        } else if (response.data.code !== 200) {
            yield all([
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: project.EMAIL_CONFIG_COMPLETE_TICKETS_CSV_FAILURE, payload: { message: 'Oops Something went wrong' } }),
                yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } }),
            ]);
        }
    } catch (error) {
        yield put({ type: modal.CLOSE_MODAL });
        yield put({ type: project.EMAIL_CONFIG_COMPLETE_TICKETS_CSV_FAILURE, payload: error });
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* createProject(action) {
    const { projectForm, customerId, formName } = action.payload;

    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const form = yield call(generateCreateProjectform, { ...projectForm });
        let response = yield call(api.project.create, { 'Content-Type': 'multipart/form-data' }, {}, {}, form);

        if (response.data.code === 200) {
            const projectId = response.data.data.id;

            if (projectForm.fe_prerequisite) {
                let fePreRequisite = new FormData();
                fePreRequisite.append('project_id', projectId);
                fePreRequisite.append('pre_req_type', '1');

                projectForm.fe_prerequisite.map((req, reqindex) => {
                    return fePreRequisite.append(`pre_req[${reqindex}]`, req);
                });
                yield put({ type: project.ADD_PROJECT_PREREQUISITE_REQUEST, payload: { form: fePreRequisite } });
            }
            yield put({ type: modal.CLOSE_MODAL });
            yield put({ type: project.GET_ALL_PROJECT_LIST_REQUEST, payload: { customerId } });
            yield put({ type: project.IS_SHOW_ADD_PROJECT, payload: false });
            yield put({ type: utils.SET_INITIAL_FORM, payload: { reinitialize: false, date: Date.now() } });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Project Added Succesfully' } });
        } else {
            yield put({ type: project.CREATE_PROJECT_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
            yield put({ type: modal.CLOSE_MODAL });
        }
    } catch (error) {
        yield put({ type: project.CREATE_PROJECT_FAILURE, payload: error });
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* getProjectPrequisites(action) {
    try {
        yield put({ type: loading.PROJECT_PREQUISITE_LIST_LOADING, payload: true });
        const response = yield call(api.project.listPrerequisite, {});
        if (response.data.code === 200) {
            let sitePreRequisite = [];
            response.data.data.forEach((preRequisite) => {
                if (preRequisite.type === 1) {
                    return sitePreRequisite.push({
                        key: `${preRequisite.id}`,
                        value: preRequisite.id,
                        label: preRequisite.title,
                    });
                }
            });
            yield put({ type: project.PROJECT_PREQUISITE_LIST_SUCCESS, payload: { sitePreRequisite } });
        } else {
            yield put({ type: project.PROJECT_PREQUISITE_LIST_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: project.PROJECT_PREQUISITE_LIST_FAILURE, payload: error });
    }
    yield put({ type: loading.PROJECT_PREQUISITE_LIST_LOADING, payload: false });
}

export function* addProjectPrequisite(action) {
    const { form } = action.payload;
    try {
        const response = yield call(api.project.savePreRequisite, {}, {}, {}, form);
        if (response.data.code === 200) {
        } else {
            yield put({ type: project.ADD_PROJECT_PREREQUISITE_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: project.ADD_PROJECT_PREREQUISITE_FAILURE, payload: error });
    }
}

export function* getListOfTemplates(action) {
    const { data } = action.payload;

    try {
        yield put({ type: loading.TEMPLATE_LIST_LOADING, payload: true });
        const response = yield call(api.project.listAllTemplates, {}, {}, {}, data);
        if (response.data.code === 200) {
            let templateslist = [];
            response.data.data.map((template) => {
                return templateslist.push({
                    key: `${template.id}_${template.is_boq_required}`,
                    value: template.id,
                    label: template.title,
                });
            });
            yield put({ type: project.SHOW_TEMPLATE_LIST_SUCCESS, payload: templateslist });
        } else {
            yield put({ type: project.SHOW_TEMPLATE_LIST_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: project.SHOW_TEMPLATE_LIST_FAILURE, payload: error });
    }
    yield put({ type: loading.TEMPLATE_LIST_LOADING, payload: false });
}

export function* getProjectTeams(action) {
    const { agentsInfo = false, customerId, limit = 100, page, dropdown = false, count = false, query, projectId } = action.payload;
    let queryParams = { limit, page, dropdown, count, agentsInfo, projectId };
    if (customerId > 0) queryParams.customerId = customerId;
    if (!['', undefined, null].includes(query)) queryParams.query = query;
    try {
        yield put({ type: loading.PROJECT_TEAMS_LIST_LOADING, payload: true });
        const response = yield call(lambdas.superdesk.getCustomerTeams, {}, {}, queryParams, {});
        if (response.data.code === 200) {
            let teams = response.data.data;
            yield put({ type: project.PROJECT_TEAM_LIST_SUCCESS, payload: { data: teams } });
        } else {
            yield put({ type: project.PROJECT_TEAM_LIST_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: project.PROJECT_TEAM_LIST_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.PROJECT_TEAMS_LIST_LOADING, payload: false });
}

export function* projectTeamWhiteList(action) {
    const { projectId, userId, customerId, customeMessage, formName, refresh = true, callGetLinkedProject } = action.payload;

    const form = { useProjectId: true, userId };

    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.conversations.whitelistProjectTeamUser, {}, { projectId }, {}, form);
        if (response.data.code === 200) {
            const actionList = [
                yield put({ type: project.PROJECTS_TEAMS_USER_WHITELIST_SUCCESS, payload: {} }),
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: customeMessage } }),
                yield put({ type: modal.CLOSE_MODAL }),
            ];
            if (refresh) yield put({ type: project.PROJECT_TEAM_LIST_REQUEST, payload: { projectId, customerId, agentsInfo: true } });
            if (callGetLinkedProject) yield put({ type: project.GET_LINKED_PROJECT_OF_USERS_REQUEST, payload: { userId } });
            yield all(actionList);
        } else {
            yield all([
                yield put({ type: project.PROJECTS_TEAMS_USER_WHITELIST_FAILURE, payload: { message: 'Oops Something went wrong' } }),
                yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } }),
                yield put({ type: modal.CLOSE_MODAL }),
            ]);
        }
    } catch (error) {
        yield put({ type: project.PROJECTS_TEAMS_USER_WHITELIST_FAILURE, payload: { message: error } });
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
        yield put({ type: modal.CLOSE_MODAL });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* projectTeamBlackList(action) {
    const { projectId, userId, customerId, customeMessage, formName, refresh = true, callGetLinkedProject = false } = action.payload;
    const form = {
        projectId,
        userId,
    };
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.conversations.blackListProjectteamUser, {}, {}, {}, form);
        if (response.data.code === 200) {
            const actionList = [
                yield put({ type: project.PROJECTS_TEAMS_USER_BLACKLIST_SUCCESS, payload: {} }),
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: customeMessage } }),
                yield put({ type: modal.CLOSE_MODAL }),
            ];
            if (refresh) actionList.push(yield put({ type: project.PROJECT_TEAM_LIST_REQUEST, payload: { projectId, customerId, agentsInfo: true } }));
            if (callGetLinkedProject) actionList.push(yield put({ type: project.GET_LINKED_PROJECT_OF_USERS_REQUEST, payload: { userId } }));
            yield all[actionList];
        } else {
            yield all([
                yield put({ type: project.PROJECTS_TEAMS_USER_BLACKLIST_FAILURE, payload: { message: 'Oops Something went wrong' } }),
                yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } }),
                yield put({ type: modal.CLOSE_MODAL }),
            ]);
        }
    } catch (error) {
        yield put({ type: project.PROJECTS_TEAMS_USER_BLACKLIST_FAILURE, payload: { message: error } });
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
        yield put({ type: modal.CLOSE_MODAL });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* downloadProjectZip(action) {
    let { formName, projectId, emails } = action.payload;
    let payload = {
        emails: emails.split(','),
        projectId,
    };
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(microservices.project.getProjectZip, {}, {}, {}, payload);
        if (response.data.code === 200) {
            yield put({ type: modal.CLOSE_MODAL });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: response.data.data.message } });
        } else {
            yield put({ type: project.DOWNLOAD_PROJECT_ZIP_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
        }
    } catch (error) {
        yield put({ type: project.DOWNLOAD_PROJECT_ZIP_FAILURE, payload: { message: error } });
        yield put({ type: modal.CLOSE_MODAL });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* getScogoDealIdsDropdownList(action) {
    let { customerId } = action.payload;
    try {
        yield put({ type: loading.GET_SCOGO_DEALIDS_DROPDOWN_LOADING, payload: true });
        const response = yield call(lambdas.customer.getDealIds, {}, {}, { customerId }, {});
        if (response.data.code === 200) {
            let dropdown = response.data.data.map((deal) => {
                return {
                    label: `${deal.scogo_deal_id} (${deal.deal_name})`,
                    value: deal.id,
                    key: deal.id,
                };
            });
            yield put({ type: project.GET_SCOGO_DEALIDS_DROPDOWN_SUCCESS, payload: { list: dropdown } });
        } else {
            yield put({ type: project.GET_SCOGO_DEALIDS_DROPDOWN_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
        }
    } catch (error) {
        yield put({ type: project.GET_SCOGO_DEALIDS_DROPDOWN_FAILURE, payload: { message: error } });
        yield put({ type: modal.CLOSE_MODAL });
    }
    yield put({ type: loading.GET_SCOGO_DEALIDS_DROPDOWN_LOADING, payload: false });
}
export function* createProjectAttachmentPost(action) {
    const { caption, projectId, attachment, formName } = action.payload;

    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const filesInRequestBody = {
            fileName: attachment.name,
            mimeType: attachment.type,
        };
        const signedUrl = yield call(lambdas.projects.uploadPostAttachment, {}, {}, {}, { files: [filesInRequestBody], projectId, reason: 'POST_MESSAGE' });

        if (signedUrl.status === 200) {
            yield call(uploadFileToS3WithSignedUrl, signedUrl.data.data[0].signedUrl, attachment);
            const files = signedUrl.data.data.map(({ fileName, fileUrl, mimeType }) => {
                return { fileName, mimeType, caption, type: getFileType(mimeType), fileUrl };
            });
            const saveData = yield call(microservices.project.projectPost, {}, { projectId }, {}, { files, type: 'attachments' });
            if (saveData.data.code === 201) {
                yield all([
                    yield put({ type: project.CREATE_PROJECT_ATTACHMENT_POST_SUCCESS, payload: { projectId, data: saveData.data.data } }),
                    yield put({ type: modal.CLOSE_MODAL }),
                    yield put({
                        type: toast.SEND_TOAST,
                        payload: { status: 'success', message: signedUrl.data.data.message ? signedUrl.data.data.message : 'File posted Successfully' },
                    }),
                ]);
            } else {
                yield all([
                    yield put({
                        type: toast.SEND_TOAST,
                        payload: { status: 'danger', message: signedUrl.data.data.message ? signedUrl.data.data.message : 'Something went wrong' },
                    }),
                ]);
            }
        } else {
            yield all([
                yield put({ type: modal.CLOSE_MODAL }),

                yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: signedUrl.data.data.message ? signedUrl.data.data.message : 'Something Went wrong' } }),
            ]);
        }
    } catch (error) {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}
export function* createProjectTextPost(action) {
    const { message, projectId, formName } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(microservices.project.projectPost, {}, { projectId }, {}, { message, type: 'message' });
        if (response.data.code === 201) {
            yield put({ type: modal.CLOSE_MODAL });
            yield put({ type: project.CREATE_PROJECT_TEXT_POST_SUCCESS, payload: { projectId, data: response.data.data } });
            yield put({
                type: toast.SEND_TOAST,
                payload: { status: 'success', message: response.data.message ? response.data.message : 'Added Post in Project Succesfully' },
            });
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.message ? response.data.message : 'Something went wrong' } });
            yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
        }
    } catch (error) {
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* getProjectPostList(action) {
    const { projectId } = action.payload;
    try {
        yield put({ type: loading.PROJECT_POST_LOADING, payload: true });
        const response = yield call(microservices.project.getProjectPostList, {}, { projectId }, {}, {});

        if (response.data.code === 200) {
            yield put({ type: project.GET_PROJECT_POST_LIST_SUCCESS, payload: { projectId, postList: response.data.data } });
        } else {
            yield put({ type: project.GET_PROJECT_POST_LIST_FAILUIRE, payload: 'Oops Something went wrong' });
        }
    } catch (error) {
        yield put({ type: project.GET_PROJECT_POST_LIST_FAILUIRE, payload: error });
    }
    yield put({ type: loading.PROJECT_POST_LOADING, payload: false });
}

export function* projectPostStatus(action) {
    const { status, postId, projectId, formName } = action.payload;

    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(microservices.project.projectPostStatus, {}, { postId }, {}, { status });

        if (response.data.code === 200) {
            yield put({ type: modal.CLOSE_MODAL });
            yield put({ type: project.PROJECT_POST_STATUS_SUCCESS, payload: { postId, status: response.data.data.status, projectId } });

            yield put({
                type: toast.SEND_TOAST,
                payload: { status: 'success', message: response.data.message ? response.data.message : 'Added Post in Project Succesfully' },
            });
        } else {
            yield put({ type: project.PROJECT_POST_STATUS_FAILUIRE, payload: 'Oops Something went wrong' });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.message ? response.data.message : 'Added Post in Project Succesfully' } });
        }
    } catch (error) {
        yield put({ type: project.PROJECT_POST_STATUS_FAILUIRE, payload: error });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* getLinkedProjectOfUsers(action) {
    let { userId } = action.payload;
    try {
        yield put({ type: loading.GET_LINKED_PROJECT_OF_USERS_LOADING, payload: true });
        const response = yield call(microservices.project.linkedProjectOfUser, {}, { userId }, {}, {});
        if (response.data.code === 200) {
            yield put({ type: project.GET_LINKED_PROJECT_OF_USERS_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: project.GET_LINKED_PROJECT_OF_USERS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: project.GET_LINKED_PROJECT_OF_USERS_FAILURE, payload: { message: error } });
        yield put({ type: modal.CLOSE_MODAL });
    }
    yield put({ type: loading.GET_LINKED_PROJECT_OF_USERS_LOADING, payload: false });
}

export function* getLinkedProjectOfTeams(action) {
    let { teamId } = action.payload;
    try {
        yield put({ type: loading.GET_LINKED_PROJECT_OF_TEAMS_LOADING, payload: true });
        const response = yield call(microservices.project.linkedProjectOfTeam, {}, { teamId }, {}, {});
        if (response.data.code === 200) {
            yield put({ type: project.GET_LINKED_PROJECT_OF_TEAMS_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: project.GET_LINKED_PROJECT_OF_TEAMS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: project.GET_LINKED_PROJECT_OF_TEAMS_FAILURE, payload: { message: error } });
        yield put({ type: modal.CLOSE_MODAL });
    }
    yield put({ type: loading.GET_LINKED_PROJECT_OF_TEAMS_LOADING, payload: false });
}

export function* createProjectTemplate(action) {
    let { createTemplateData, formName, formData, projectId, existing = false } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.template.createTemplate, {}, {}, {}, createTemplateData);
        if (response.data.code === 201) {
            let data = { use_case_id: createTemplateData?.use_case_id, service_type_id: createTemplateData?.service_type_id }
            yield put({ type: project.CREATR_PROJECT_TEMPLATE_SUCCESS, payload: { data: response.data.template, } });
            yield put({ type: project.CREATR_WORKFLOW, payload: { templateData: response.data.template, formName, checkListdata: checkListdata(formData, response.data.template, existing, projectId), showTemplateData: data } });

        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.message ? response.data.message : 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
        yield put({ type: modal.CLOSE_MODAL });
    }
}

export function* createWorkflow(action) {
    let { templateData, formName, checkListdata, showTemplateData, showToast = false, } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.workflow.createWorkflow, {}, { templateId: templateData?.id }, {}, checkListdata);
        if (response.data.code === 200) {
            if (showTemplateData) {
                yield put({ type: project.SHOW_TEMPLATE_LIST_REQUEST, payload: { data: showTemplateData } });
            }
            yield put({ type: project.CREATR_WORKFLOW_SUCCESS, payload: { fk_template_id: response?.data?.data?.[0]?.savedStep?.fk_template_id, projectId: checkListdata.projectId } });
            if (showToast) {
                yield put({
                    type: toast.SEND_TOAST,
                    payload: { status: 'success', message: response.data.message ? response.data.message : 'Checklist Updated Succesfully' },
                });
            }
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.message ? response.data.message : 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Oops Something went wrong' } });

    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
    yield put({ type: modal.CLOSE_MODAL });
}

export function* editProjectPMRequest(action) {
    const { fist_pm_id, second_pm_id, third_pm_id, escalate_manager, formName, projectId } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(microservices.project.editProjectPM, {}, { projectId }, {}, { fist_pm_id, second_pm_id, third_pm_id, escalate_manager });
        if (response.data.code === 200) {
            yield all([
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: project.EDIT_PROJECT_PM_SUCCESS, payload: { projectId, projectPM: response.data.data } }),
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: response.data.message ? response.data.message : 'Updated Successfully!!!' } }),
            ])
        } else {
            yield put({ type: project.EDIT_PROJECT_PM_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.message ? response.data.message : 'Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* getProjectPMRequest(action) {
    const { projectId } = action.payload;
    try {
        yield put({ type: loading.GET_PROJECT_PM_LOADING, payload: true });
        const response = yield call(microservices.project.getProjectPM, {}, { projectId }, {}, {});
        if (response.data.code === 200) {
            yield put({ type: project.GET_PROJECT_PM_SUCCESS, payload: { projectId, projectPM: response.data.data } });
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.message ? response.data.message : 'Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
    }
    yield put({ type: loading.GET_PROJECT_PM_LOADING, payload: false });
}

export function* addMutlipleProjectsInTeams(action) {
    const { teamId, projectIds, formName, callLinkedProjectList } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.projects.addMultipleProjectInTeams, {}, {}, {}, {
            teamId,
            projectIds
        });
        if (response.data.code === 201) {
            yield all([
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Added proects in team succesfully' } }),
            ]);
            if (callLinkedProjectList) yield put({ type: project.GET_LINKED_PROJECT_OF_TEAMS_REQUEST, payload: { teamId: teamId } });
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.message ? response.data.message : 'Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* addSlaInProject(action) {
    const { wh_end_time, wh_start_time, sla_type, tier_1, tier_2, tier_3, tier_4, is_applicable, formName, projectId } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.projects.addSlaInProject, {}, { projectId }, {}, { wh_end_time, wh_start_time, sla_type, tier_1, tier_2, tier_3, tier_4, is_applicable });
        if (response.data.code === 200) {
            yield put({ type: modal.CLOSE_MODAL })
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Added sla in project succesfully!!!' } })
            yield put({ type: project.GET_PROJECTS_SLA_SUCCESS, payload: { projectId, slas: response.data.data } })
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.message ? response.data.message : 'Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* getProjectSla(action) {
    const { projectId } = action.payload;
    try {
        yield put({ type: loading.IS_GET_PROJECT_SLAS_LOADING, payload: true });
        const response = yield call(lambdas.projects.getProjectsSla, {}, { projectId }, {});
        if (response.data.code === 200) {
            yield put({ type: project.GET_PROJECTS_SLA_SUCCESS, payload: { projectId, slas: response.data.data[0] } });
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.message ? response.data.message : 'Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
    }
    yield put({ type: loading.IS_GET_PROJECT_SLAS_LOADING, payload: false });
}

export default function* projectSaga() {
    yield takeLatest(project.GET_PROJECT_LIST_REQUEST, getProjectList);
    yield takeLatest(project.GET_ALL_PROJECT_LIST_REQUEST, getAllProjectList);
    yield takeLatest(project.GET_ALL_PROJECT_DROPDOWN_LIST_REQUEST, getProjectDropDown);
    yield takeLatest(project.UPDATE_PM_DETAILS_REQUEST, updatePmDetails);
    yield takeLatest(project.EDIT_PROJECT_REQUEST, editProject);
    yield takeLatest(project.EMAIL_PLANNED_TICKETS_CSV_REQUEST, emailTomorrowsPlannedTicketsCsv);
    yield takeLatest(project.EMAIL_CONFIG_COMPLETE_TICKETS_CSV_REQUEST, emailTodaysConfigCompleteTicketsCsv);
    yield takeLatest(project.CREATE_PROJECT_REQUEST, createProject);
    yield takeLatest(project.PROJECT_PREQUISITE_LIST_REQUEST, getProjectPrequisites);
    yield takeLatest(project.ADD_PROJECT_PREREQUISITE_REQUEST, addProjectPrequisite);
    yield takeLatest(project.SHOW_TEMPLATE_LIST_REQUEST, getListOfTemplates);
    yield takeLatest(project.ADD_TEAM_PROJECT_REQUEST, addTeamProject);
    yield takeLatest(project.REMOVE_TEAM_PROJECT_REQUEST, removeTeamProject);
    yield takeLatest(project.PROJECT_TEAM_LIST_REQUEST, getProjectTeams);
    yield takeLatest(project.PROJECTS_COUNT_REQUEST, getProjectsCount);
    yield takeLatest(project.PROJECTS_TEAMS_USER_WHITELIST_REQUEST, projectTeamWhiteList);
    yield takeLatest(project.PROJECTS_TEAMS_USER_BLACKLIST_REQUEST, projectTeamBlackList);
    yield takeLatest(project.DOWNLOAD_PROJECT_ZIP_REQUEST, downloadProjectZip);
    yield takeLatest(project.GET_SCOGO_DEALIDS_DROPDOWN_REQUEST, getScogoDealIdsDropdownList);
    yield takeLatest(project.CREATE_PROJECT_ATTACHMENT_POST_REQUEST, createProjectAttachmentPost);
    yield takeLatest(project.CREATE_PROJECT_TEXT_POST_REQUEST, createProjectTextPost);
    yield takeLatest(project.GET_PROJECT_POST_LIST_REQUEST, getProjectPostList);
    yield takeLatest(project.PROJECT_POST_STATUS_REGUEST, projectPostStatus);
    yield takeLatest(project.GET_LINKED_PROJECT_OF_USERS_REQUEST, getLinkedProjectOfUsers);
    yield takeLatest(project.GET_LINKED_PROJECT_OF_TEAMS_REQUEST, getLinkedProjectOfTeams);
    yield takeLatest(project.CREATE_PROJECT_TEMPLATE, createProjectTemplate);
    yield takeLatest(project.CREATR_WORKFLOW, createWorkflow);
    yield takeLatest(project.EDIT_PROJECT_PM_REQUEST, editProjectPMRequest);
    yield takeLatest(project.GET_PROJECT_PM_REQUEST, getProjectPMRequest);
    yield takeLatest(project.ADD_MULTIPLE_PROJECTS_IN_TEAM_PROJECT_REQUEST, addMutlipleProjectsInTeams);
    yield takeLatest(project.ADD_SLA_IN_PROJECT_REQUEST, addSlaInProject);
    yield takeLatest(project.GET_PROJECTS_SLA_REQUEST, getProjectSla);
}


const checkListdata = (array, template, existing = false, projectId) => {
    const data = {
        "existing": existing,
        "steps": [{
            "id": "5a3444a7-34f5-e034-5a79-40692b9a533a",
            "title": "checklist",
            "repeater": false,
            "use_case_service_type_relationship_id": template?.use_case_service_type_relationship_id,
            "serial_order": 1
        }],
        "stepData": [{
            "step": "5a3444a7-34f5-e034-5a79-40692b9a533a",
            "repeater": false,
            "fields": array?.map((value, index) => {
                return {
                    "inputId": 12,
                    "repeater": false,
                    "title": value?.title,
                    "serial_order": index + 1,
                    "is_field_required": true,
                    "is_file_required": false,
                    "is_geo_coordinates": false,
                    "is_checklist": true
                }
            }
            )
        }]
    }
    if (projectId) {
        data.projectId = projectId;
    }
    return data;
}