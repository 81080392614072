import { useVideo } from '@100mslive/react-sdk';
import React from 'react'
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import IconToolTip from '../../../common/IconToolTip';

export const ScreenVideo = ({ peer }) => {
    const { videoRef } = useVideo({
        trackId: peer.auxiliaryTracks[0],
        attach: true
    });

    const screenIdGenerator = (id) => {
        return `shared-screen-${id}`;
    }

    const toggleFullScreen = () => {
        let el = document.getElementById(screenIdGenerator(peer.id));
        if (el.webkitEnterFullscreen) {
            el.webkitEnterFullscreen();
        } else if (el.webkitRequestFullscreen) {
            el.webkitRequestFullscreen();
        } else if (el.msRequestFullscreen) {
            el.msRequestFullscreen();
        } else if (el.mozRequestFullScreen) {
            el.mozRequestFullScreen();
        } else if (el.requestFullscreen) {
            el.requestFullscreen();
        }
    };

    return (
        <div className=' w-[40rem] h-[22.7rem] transition-all ease-linear duration-300 border-2 bg-black rounded-3xl overflow-hidden animate-fadeIn relative hover:cursor-pointer group shadow-[0px_0px_60px_0px_#4a5568]'>
            <video ref={videoRef} autoPlay muted playsInline className='w-full h-full rounded-md' id={screenIdGenerator(peer.id)}></video>
            <div className='w-full h-full transition-all ease-linear rounded-md absolute top-0 left-0 bg-black opacity-0 group-hover:opacity-70'></div>
            <p className='text-font15 text-white opacity-0 absolute bottom-5 left-5 drop-shadow-[0_3px_10px_rgb(0,0,0,0.2)] group-hover:opacity-100'>{`${peer.name}'s Screen`}</p>
            <IconToolTip title="Fullscreen">
                <FullscreenIcon sx={{ fontSize: 40 }} onClick={toggleFullScreen} className='opacity-0 group-hover:opacity-100 hover:text-gray-700 absolute bottom-5 right-5 text-white' />
            </IconToolTip>
        </div>)
}
