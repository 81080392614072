import { auth } from '../types';

export const checkIfUserIsRegisteredAction = ({ mobileOrEmail, navigate, isRedirect }) => ({
    type: auth.CHECK_USER_REQUEST,
    payload: { mobileOrEmail, navigate, isRedirect },
});

export const requestOtpAction = ({ mobile, navigate, isRedirect }) => ({
    type: auth.REQUEST_OTP_REQUEST,
    payload: { mobile, navigate, isRedirect },
});

export const setMobileOrEmailAction = ({ mobileOrEmail }) => ({
    type: auth.SET_MOBILE_OR_EMAIL,
    payload: { mobileOrEmail },
});

export const setForgotPasswordEmailAction = ({ forgotPasswordEmail }) => ({
    type: auth.SET_FORGOT_PASSWORD_EMAIL,
    payload: { forgotPasswordEmail },
});

export const loginWithOTPAction = ({ mobile, otp, redirectDashboard = true, navigate, resendOtp, logged_in_from, waId, redirect_to }) => ({
    type: auth.LOGIN_WITH_OTP_REQUEST,
    payload: { mobile, auth_otp: parseInt(otp), logged_in_from, skip: 'true', redirectDashboard, navigate, resendOtp, waId, redirect_to },
});

export const sendForgotPasswordEmailAction = ({ forgotPasswordEmail }) => ({
    type: auth.SEND_FORGOT_PASSWORD_EMAIL_REQUEST,
    payload: { forgotPasswordEmail },
});

export const resetForgotPasswordEmailSentAction = (value) => ({
    type: auth.SHOW_FORGOT_PASSWORD_EMAIL_SENT,
    payload: value,
});

export const signOutUserAction = (navigate) => ({
    type: auth.SIGN_OUT_USER,
    payload: navigate,
});

export const getUserProfile = ({ navigate }) => ({
    type: auth.USER_PROFILE_REQUEST,
    payload: { navigate },
});

export const loginWithPasswordAction = ({ email, password, navigate }) => ({
    type: auth.LOGIN_WITH_PASSWORD_REQUEST,
    payload: { email, password, navigate },
});

export const getWalletTransferOtp = ({ mobile, skip, bank_otp = 0, amount, source_url = false, formName }) => ({
    type: auth.GET_WALLET_TRANSFER_OTP_REQUEST,
    payload: { mobile, skip, bank_otp, amount, source_url, formName },
});

export const getUserBankDetails = ({ id, type }) => ({
    type: auth.GET_USERS_BANK_DETAILS_REQUEST,
    payload: { id, type },
});

export const getLoginOTP = ({ mobile, skip, bank_otp = 0, amount, source_url = false, confirmWithdrawAmountOnSuccess, formName, redirectToOtp, navigate, waId }) => ({
    type: auth.GET_OTP_REQUEST,
    payload: { mobile, skip, bank_otp, amount, source_url, confirmWithdrawAmountOnSuccess, formName, redirectToOtp, navigate, waId },
});

export const registerWithMobile = ({ mobile, register_as, registered_via_whatsapp, formName, referralCode }) => ({
    type: auth.GET_REGISTER_MOBILE_REQUEST,
    payload: { mobile, register_as, registered_via_whatsapp, formName, referralCode },
});

export const signUpWithOtp = ({ mobile, otp, navigate, formName }) => ({
    type: auth.GET_SIGNUP_OTP_REQUEST,
    payload: { mobile, otp: parseInt(otp), navigate, formName },
});

export const updateProfile = ({ firstName, lastName, dob, type, gender, alternate_mobile, pancard_number, formName, working_sch, home_pincode }) => ({
    type: auth.UPDATE_PROFILE_REQUEST,
    payload: { firstName, lastName, dob, type, gender, alternate_mobile, pancard_number, formName, working_sch, home_pincode },
});

export const updateProfilePicture = (profile_image, selectedLogoToShow, user_id) => {
    let form = new FormData();
    if (profile_image.length > 0) form.set('profile_image', profile_image[0]);
    if (user_id) form.set('user_id', user_id);
    return {
        type: auth.UPDATE_PROFILE_PIC_REQUEST,
        payload: { form, selectedLogoToShow, updateUser: !user_id },
    };
};

export const setUserPassword = ({ data, navigate, formName }) => ({
    type: auth.SET_USER_PASSWORD_REQUEST,
    payload: { data, navigate, formName },
});

export const verifyUsersEmail = ({ token, navigate }) => ({
    type: auth.VERIFY_USERS_EMAIL_REQUEST,
    payload: { token, navigate },
});

export const putToAuthStore = (payload) => ({
    type: auth.PUT_TO_AUTH_STORE,
    payload,
});

export const uploadUsersIdProof = ({ plan_image, shop_image }) => {
    let form = new FormData();

    if (plan_image && plan_image.length > 0) {
        form.append('plan_image', plan_image[0]);
    }
    if (shop_image && shop_image.length > 0) {
        form.append('shop_image', shop_image[0]);
    }

    return {
        type: auth.UPLOAD_ID_PROOF_REQUEST,
        payload: { form },
    };
};

export const clearUpdateProfileStore = () => ({
    type: auth.CLEAR_UPDATE_PROFILE_STORE,
});


export const resetUserPassword = ({ forgot_password_token, password, password_confirmation, formName, redirect, navigate }) => ({
    type: auth.RESET_USER_PASSWORD_REQUEST,
    payload: { forgot_password_token, password, password_confirmation, formName, redirect, navigate },
});

export const getUserBankDetailsById = ({ id, type, check_user, source }) => ({
    type: auth.GET_USERS_BANK_DETAILS_BY_ID_REQUEST,
    payload: { id, type, check_user, source },
});


export const requestOtpSupport = (data) => ({
    type: auth.REQUEST_OTP_SUPPORT_REQUEST,
    payload: data,
});

export const removeOTPSuccessFromStore = () => ({
    type: auth.REMOVE_REQUEST_OTP_SUPPORT_SUCCESS,
});


export const loginWithoutOtp = ({ waId, navigate, formName }) => ({
    type: auth.LOGIN_WITHOUT_OTP_REQUEST,
    payload: { waId, navigate, formName },
});

export const setRedirectURL = ({ redirectURL }) => ({
    type: auth.SET_REDIRECT_URL,
    payload: { redirectURL: redirectURL }
});

export const autoLoginAction = ({ mob, room_id, onSuccess, onFailure }) => ({
    type: auth.AUTO_LOGIN,
    payload: { mob, room_id, onSuccess, onFailure }
});