import React from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonScogoClosedOutlined from '../../common/Buttons/ButtonScogoClosedOutlined';
import ButtonScogoPrimary from '../../common/Buttons/ButtonScogoPrimary';
import { useSelector } from 'react-redux';

export const RaisePOButtons = ({ submitRef, formName, closeModal }) => {
    const navigate = useNavigate();
    const { formButtonLoading } = useSelector((state) => state.loading);
    return (
        <div className='flex gap-2'>
            <ButtonScogoClosedOutlined
                textOrComponent={'Back'}
                onClick={(e) => {
                    e.preventDefault();
                    closeModal();
                }}
            />
            <ButtonScogoPrimary
                textOrComponent={'Request PO'}
                onClick={(e) => {
                    e.preventDefault();
                    submitRef.current.click();
                }}
                loading={formButtonLoading[formName]}
            />
        </div>
    );
};
