import React, { useState } from "react";
import _ from 'lodash';
import { ReactMultiEmail as ReactMulti } from "react-multi-email";
import 'react-multi-email/dist/style.css';

import { textCss } from '../common/Css'

export default function Multi(props) {
    const {
        onChangeHandler,
        placeholder,
        label,
        value = '',
        name,
        errors,
        required,
        validate,
    } = props;

    const [emails, setEmails] = useState([...value]);

    const onChangeController = (_emails) => {
        setEmails(_emails);
        onChangeHandler(_emails)
    }



    return (
        <>
            {label && (
                <label className="block border-scogoddd py-2">
                    <span className={textCss?.inputLabel}>{label}
                    </span>&nbsp;
                    {required && <span className="text-scogoorange">*</span>}
                </label>
            )}
            <ReactMulti
                style={{
                    padding: "1em 0.5em",
                    maxHeight: "11vh",
                    overflowY: "auto",
                }}
                placeholder={placeholder}
                emails={emails}
                onChange={onChangeController}
                validateEmail={(value) => validate(value)}
                getLabel={(item, index, remove) => {
                    return (
                        <div
                            data-tag
                            key={index}
                            style={{
                                background: "rgba(244, 138, 33, var(--tw-bg-opacity))",
                                color: "white",
                                border: "none",
                            }}
                        >
                            {item}
                            <span data-tag-handle onClick={() => remove(index)}>
                                ×
                            </span>
                        </div>
                    );
                }}
            />
            {_.get(errors, name) && <span className="text-scogoclosed text-font10 font-normal">{_.get(errors, name)}</span>}
        </>
    );
}
