import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { closeProgressBarModal } from '../../actions/modal';
import { useSelector } from 'react-redux';
import { validateNumberValue } from '../../utils/common';
import OverflowTip from '../OverflowTip';
import IconToolTip from '../IconToolTip';

export default function ProgressBarModal() {
    const { downloadProgressList } = useSelector((state) => state.modal);
    const dispatch = useDispatch();
    const [isDownloadOpen, setIsDownloadOpen] = useState(true);
    const [showCloseIcon, setShowCloseIcon] = useState(false);


    useEffect(() => {
        let flag = false;
        if (Array.isArray(downloadProgressList)) {
            flag = downloadProgressList.every((item) => item.progress === 1);
        }
        setShowCloseIcon(flag);
    }, [downloadProgressList])

    return <div className='absolute bg-white rounded-xl z-50 overflow-hidden shadow-xl border-1 border-gray' style={{ width: '30rem', bottom: '1.7rem', right: '2rem' }}>
        <div className='flex items-center justify-between px-3 py-2 bg-scogolightblue rounded-t-xl h-4/12' style={{ height: '5vh' }}>
            <p className='text-xl font-medium'>Downloads &nbsp;({`${validateNumberValue(downloadProgressList.length)}`})</p>
            <div className='flex items-center'>
                <span className='material-icons cursor-pointer text-md' onClick={() => setIsDownloadOpen(prevState => !prevState)}>{isDownloadOpen ? 'expand_more' : 'expand_less'}</span>
                {showCloseIcon && <span className='material-icons cursor-pointer text-font14' onClick={() => dispatch(closeProgressBarModal())}>close</span>}
            </div>
        </div>
        {isDownloadOpen && <div className='w-full h-8/12 px-2 overflow-y-scroll' style={{ maxHeight: '30vh' }}>
            {
                downloadProgressList.map(({ progress, zipFileName, downloadFail = false, fileUrl }) => {
                    return <Progress percentage={validateNumberValue(progress) * 100} fileName={zipFileName} downloadFail={downloadFail} fileUrl={fileUrl} />
                })
            }
        </div>}
    </div>
}


const Progress = ({ percentage, fileName, downloadFail, fileUrl }) => {
    let color = '';
    let icon = '';
    if (downloadFail) {
        color = 'scogoclosed';
        icon = <IconToolTip title={'Failed'}>
            <span className={`material-icons text-scogoclosed text-font16`}>error</span>
        </IconToolTip>;
    } else if (percentage === 100) {
        color = 'scogo2e';

    } else if (percentage > 0 && percentage < 100) {
        color = 'scogoprimary';
    }
    return (<div className='flex gap-2 items-center py-2 my-2'>
        <div className='w-11/12' style={{ height: '2.5rem' }}>
            <OverflowTip someLongText={fileName} textClass='w-full' />
            <div className='flex items-center gap-2'>
                <div className='bg-scogoeee w-full relative h-2.5 rounded-xl' >
                    <div style={{ width: `${percentage}%` }} className={`h-2.5 z-0 rounded-md bg-${color}`}></div>
                </div>
                {icon}

            </div>
        </div>
        <div className='flex items-center'>
            {fileUrl &&
                <a href={fileUrl} download>
                    <p
                        className={`h-6 w-6 text-scogoorange hover:text-scogoprimary border border-scogoorange hover:border-scogoprimary flex items-center py-1`}
                    >
                        <span className='material-icons text-font13'>file_download</span>
                    </p>
                </a>
            }
        </div>
    </div>)
}