import React from 'react';
import { useDispatch } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import Form from '../../../common/Form';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import TextArea from '../../../common/Form/TextArea';
import { bulkResumeTicket, resumeTicket } from '../../../actions/tickets';

const formName = 'resumeForm';

export default function ResumeTicket({ ticket, isBulk, ticketIds }) {

    const { formButtonLoading } = useSelector((state) => state.loading);
    const defaultValues = { resume_message_pm: '' };

    const dispatch = useDispatch();

    const methods = useForm({ defaultValues, mode: 'all' });

    const submit = (formValues) => {
        const payload = {};
        if (isBulk) {
            payload.ticketIds = [...ticketIds];
            payload.remark = formValues?.resume_message_pm
            dispatch(bulkResumeTicket({ data: payload, formName }))
        }
        else {
            payload.ticket_id = ticket.ticket_id;
            payload.resume_message_pm = formValues?.resume_message_pm

            dispatch(resumeTicket({ data: payload, formName }));
        }
    };

    return (
        <>
            <Form
                methods={methods}
                onSubmit={submit}
                formName={formName}
                buttonPostion={'justify-end'}
                submitButtonText={'Submit'}
                onCancel={() => dispatch(closeModalAction())}
                showSubmit
                submitContainerClassName='flex gap-2 justify-end py-4 px-4'
                isLoading={formButtonLoading[formName]}
            >
                <div className='px-2'>
                    <TextArea name='resume_message_pm' className='w-full' label='Reason' height='6rem' required />
                </div>
            </Form>
        </>
    );
}
