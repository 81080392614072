import React, { useEffect, useState, useCallback, forwardRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getPaymentPos, getAllPOFilterList, getPoFilterData, setPaymentFilterAndPaginationData, setExportCsvPaymentsFunctionInStore, setDownloadZipPoFunctionInStore } from "../../../../actions/payments";
import PoTab from "./PoTable";
import { validateFilterNumber, readFilterQueryParams, transformQueryFilters, getDateWithISOFormat, validateDate } from '../../../../utils/filter';
import { useQuery } from '../../../../utils/common';
import { GetPaymentsListLogic } from '../../PaymentController';
import { openCustomModal } from '../../../../actions/modal';
import ExportCsvPayments from '../../Modals/ExportCsvPayment';
import DownloadZipPo from '../../Modals/DownloadZipPo';

export const filterSchema = {
    sites: {
        filterName: "siteDetails",
        title: "Site",
        typeOfFilter: "checkbox",
        apiValueKey: "siteId",
        displayValueKey: "siteName",
        submitKey: "siteId",
        queryParamKey: 'siteId',
        parse: ({ siteId }) => {
            let values = validateFilterNumber(siteId)
            if (values) return { siteId: values }
            return {}
        },
    },
    endusers: {
        filterName: "enduserDetails",
        title: "Enduser",
        typeOfFilter: "checkbox",
        apiValueKey: "enduserId",
        displayValueKey: "enduserName",
        submitKey: "enduserIds",
        queryParamKey: 'enduserIds',
        parse: ({ enduserIds }) => {
            let values = validateFilterNumber(enduserIds)
            if (values) return { enduserIds: values }
            return {}
        },
    },
    vendors: {
        filterName: "vendorDetails",
        title: "Vendor",
        typeOfFilter: "checkbox",
        apiValueKey: "vendorId",
        displayValueKey: "vendorName",
        submitKey: "vendorId",
        queryParamKey: 'vendorId',
        parse: ({ vendorId }) => {
            let values = validateFilterNumber(vendorId)
            if (values) return { vendorId: values }
            return {}
        },
    },
    status: {
        filterName: "status",
        title: "Status",
        typeOfFilter: "checkbox",
        apiValueKey: (status) => status,
        displayValueKey: (status) => status,
        submitKey: "status",
        queryParamKey: 'status'
    },
    customers: {
        filterName: "customers",
        title: "Customer",
        typeOfFilter: "checkbox",
        apiValueKey: "customer_id",
        displayValueKey: "customer_company_name",
        submitKey: "customerId",
        queryParamKey: 'customerId',
        parse: ({ customerId }) => {
            let values = validateFilterNumber(customerId)
            if (values) return { customerId: values }
            return {}
        },
    },
    expenseType: {
        filterName: "expenseType",
        title: "Expense Type",
        typeOfFilter: "checkbox",
        apiValueKey: (type) => type,
        displayValueKey: (type) => type,
        submitKey: "expenseType",
        queryParamKey: "expenseType",
    },
    createdAt: {
        filterName: 'createdAt',
        title: 'Created At',
        typeOfFilter: 'daterange',
        submitKey: ({ cFromDate, cToDate } = {}) => ({
            createdAt: { fromDate: getDateWithISOFormat(cFromDate), toDate: getDateWithISOFormat(cToDate) },
        }),
        queryParamKey: {
            keyList: ['cFromDate', 'cToDate'],
            getQueryObject: ({ fromDate, toDate }) => ({ cFromDate: fromDate, cToDate: toDate }),
        },
        parse: ({ cFromDate, cToDate }) => {
            let validatedFrom = validateDate(cFromDate, { cutoffDate: false });
            let validatedTo = validateDate(cToDate, { cutoffDate: false });
            if (validatedFrom > validatedTo) return {};
            if (validatedFrom && validatedTo) {
                return { cFromDate: validatedFrom, cToDate: validatedTo };
            } else if (validatedFrom) {
                return { cFromDate: validatedFrom, cToDate: new Date() };
            }
            return {};
        },
        getSelectedFilterValues: ({ cFromDate, cToDate } = {}) => ({
            fromDate: getDateWithISOFormat(cFromDate),
            toDate: getDateWithISOFormat(cToDate),
        }),
    },
    nextRenewableDate: {
        filterName: 'nextRenewableDate',
        title: 'Next Renewable Date',
        typeOfFilter: 'daterange',
        submitKey: ({ nFromDate, nToDate } = {}) => ({
            nextRenewableDate: { fromDate: getDateWithISOFormat(nFromDate), toDate: getDateWithISOFormat(nToDate) },
        }),
        queryParamKey: {
            keyList: ['nFromDate', 'nToDate'],
            getQueryObject: ({ fromDate, toDate }) => ({ nFromDate: fromDate, nToDate: toDate }),
        },
        parse: ({ nFromDate, nToDate }) => {
            let validatedFrom = validateDate(nFromDate, { cutoffDate: false });
            let validatedTo = validateDate(nToDate, { cutoffDate: false });
            if (validatedFrom > validatedTo) return {};
            if (validatedFrom && validatedTo) {
                return { nFromDate: validatedFrom, nToDate: validatedTo };
            } else if (validatedFrom) {
                return { nFromDate: validatedFrom, nToDate: new Date() };
            }
            return {};
        },
        getSelectedFilterValues: ({ nFromDate, nToDate } = {}) => ({
            fromDate: getDateWithISOFormat(nFromDate),
            toDate: getDateWithISOFormat(nToDate),
        }),
    },
}

const PoContainer = forwardRef((props, ref) => {
    const { rowsPerPage, paymentsPage, updateQueryFilters, limitQueryParam, pageQueryParam, searchQueryParam, queryParams, openPaymentSideDrawer, defaultRowsPerPage, defaultPage, setClearFilterButton, cleanupOnTabChange, rootTab, setSearchPlaceholder } = props;
    const { poList, poFilterList, poCount } = useSelector(state => state.payments);
    const { poTableLoading } = useSelector(state => state.loading);
    const dispatch = useDispatch();
    const [filterQueryObject, setFilterObject] = useState({});
    const [filtersActiveStatus, setFiltersActiveStatus] = useState({});
    const [filterList, setFilterList] = useState({});
    const query = useQuery();
    const isToShowCustomerFilter = GetPaymentsListLogic('isToShowCustomer');

    const getPoData = useCallback(({ isFilterApplied, searchTerm, limit, page, payload }) => {
        if (!limit) limit = defaultRowsPerPage;
        if (!page) page = defaultPage;
        if ((isFilterApplied || searchTerm?.length > 0)) {
            dispatch(getPoFilterData({ limit, page, query: searchTerm, payload }));
        } else {
            dispatch(getPaymentPos({ limit, page }));
        }
    }, [defaultPage, defaultRowsPerPage, dispatch])

    useEffect(() => {
        let { queryObject, filterStatus } = readFilterQueryParams(filterSchema, query);
        setFilterObject(queryObject);
        setFiltersActiveStatus(filterStatus);
        let isToShowFilterButton = false;
        Object.values(filterStatus).forEach((value) => value ? isToShowFilterButton = value : false);
        setClearFilterButton(isToShowFilterButton);
        let filterPayload = transformQueryFilters(filterSchema, queryObject);
        dispatch(setPaymentFilterAndPaginationData({ payload: filterPayload, isFilterActive: isToShowFilterButton, limit: limitQueryParam, page: pageQueryParam, query: searchQueryParam, tab: rootTab }))
        getPoData({ searchTerm: searchQueryParam, limit: limitQueryParam, page: pageQueryParam, payload: filterPayload, isFilterApplied: isToShowFilterButton });
    }, [queryParams, searchQueryParam, limitQueryParam, pageQueryParam, getPoData, setClearFilterButton, query]);

    useEffect(() => {
        let payload = {};
        if (isToShowCustomerFilter) payload.toFilter = 'customers';
        dispatch(getAllPOFilterList(payload));
    }, [isToShowCustomerFilter, dispatch]);

    useEffect(() => {
        setSearchPlaceholder('Search For Ticket Number');
        return () => {
            cleanupOnTabChange();
        }
    }, [cleanupOnTabChange, setSearchPlaceholder]);


    const handleExportCsvClick = useCallback(() => {
        let modalWidth = '40rem', modalHeight = 'auto';
        let modalComponent = <ExportCsvPayments tab='PO' module='PO' />
        let head = 'Download CSV';
        dispatch(openCustomModal({ modalComponent, heading: head, modalWidth, modalHeight }))
    }, [dispatch]);

    const handleDownloadZipClick = useCallback(() => {
        let modalWidth = '40rem', modalHeight = 'auto';
        let modalComponent = <DownloadZipPo />
        let head = 'Download PO Zip';
        dispatch(openCustomModal({ modalComponent, heading: head, modalWidth, modalHeight }))
    }, [dispatch]);


    useEffect(() => {
        dispatch(setExportCsvPaymentsFunctionInStore(handleExportCsvClick));
        dispatch(setDownloadZipPoFunctionInStore(handleDownloadZipClick));
        return () => {
            dispatch(setExportCsvPaymentsFunctionInStore(() => { }))
            dispatch(setDownloadZipPoFunctionInStore(() => { }))
        }
    }, [handleExportCsvClick, dispatch, handleDownloadZipClick]);

    return <PoTab
        poTableList={poList}
        updateQueryFilters={updateQueryFilters}
        rowsPerPage={rowsPerPage}
        paymentsPage={paymentsPage}
        openPaymentSideDrawer={openPaymentSideDrawer}
        poFilterList={poFilterList}
        filterQueryObject={filterQueryObject}
        filtersActiveStatus={filtersActiveStatus}
        filterList={filterList}
        setFilterList={setFilterList}
        isToShowCustomerFilter={isToShowCustomerFilter}
        poCount={poCount}
        poTableLoading={poTableLoading}
    />
})

export default PoContainer