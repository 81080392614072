import React from 'react';
import ButtonScogoOrange from '../../../common/Buttons/ButtonScogoOrange';
import Loader from '../../../common/Loader';
import { getAssetColor, validateStringValue } from '../../../utils/common';
import { getIsToAddAssetButton, isToShowUpdateRmaButton, serviceTypeIds, ticketTypes } from '../TicketController';
import { useSelector, useDispatch } from 'react-redux';
import UpdateRmaDetails from '../Modal/UpdateRmaDetails';
import { openCustomModal, openFullscreenModal } from '../../../actions/modal';
import { useNavigate } from 'react-router-dom';
import AssetHistory from '../../Inventory/reusableComponents/AddOption/AssetHistory';
import AssetPopup from '../../../common/Popups/AssetPop';
import AddMigrationAsset from '../Modal/AddMigrationAsset';
import IconToolTip from '../../../common/IconToolTip';
import { copyToClipboard } from '../../../actions/utils';
import UserPopup from '../../../common/Popups/User';
import { isCustomerGroup, isScm } from '../../../utils/role';

export const findAssetdublicetCount = (array) => {
    const serialNumberAssest =
        array?.filter((e) => {
            return e?.serial_number?.length > 3;
        }) || [];


    let withOutSerialNumberAssest =
        array?.filter((e) => {
            return !e?.serial_number || e?.serial_number?.length < 3;
        }) || [];

    const dublictAsset =
        withOutSerialNumberAssest?.reduce((acc, item) => {
            const findtitle = acc?.find(({ title }) => item?.assetType?.title === title || item?.title === title);
            if (findtitle) {
                findtitle.count++;
            } else {
                acc.push({
                    title: item?.assetType?.title || item.title, count: 1, status: item.status, is_spare: item.is_spare, fk_asset_id: item.fk_asset_id, working_condition
                        : item.working_condition
                });
            }

            return acc;
        }, []) || [];


    return [...serialNumberAssest, ...dublictAsset,];
};

export const AssetsColumn = ({ loading, ticket, dataToShowPerRow = 3, onINeedRMASupportClick = () => { } }) => {
    const dispatch = useDispatch();
    const assetList = findAssetdublicetCount(ticket.assets);

    const { loggedUser } = useSelector((state) => state.auth);
    const showAddAsset = getIsToAddAssetButton(ticket, { role: loggedUser.role.id, type: loggedUser.type, loggedUserId: loggedUser.id });
    const showUpdateRmaButton = isToShowUpdateRmaButton(ticket, { role: loggedUser.role.id, type: loggedUser.type, loggedUserId: loggedUser.id });
    const navigate = useNavigate()

    const openUpdateRmaModal = () => {
        let heading = (
            <span className='text-black'>
                Update RMA Details :&nbsp;<span className='text-scogobgsky text-font16'>{ticket.job_ticket_number}</span>
            </span>
        );
        let modalWidth = '85rem';
        let modalHeight = 'auto';
        let modalComponent = <UpdateRmaDetails ticketId={ticket.ticket_id} heading={"Update RMA Details"} />;
        dispatch(
            openFullscreenModal({
                heading,
                modalWidth,
                modalHeight,
                modalComponent,
            })
        );
    };


    const onAddAssetClick = () => {
        if (ticket.serviceTypeId === serviceTypeIds.migration && ticket.ticketType === ticketTypes.support) {
            let modalComponent = <AddMigrationAsset ticketId={ticket.ticket_id} migrateTo={ticket.migrateTo} warehouse={ticket.warehouse} customerId={ticket.customerId} siteId={ticket.siteId} />
            dispatch(
                openCustomModal({
                    heading: 'Add Migration Assets',
                    modalComponent,
                    modalHeight: 'auto',
                })
            );
        } else {
            navigate(`/tickets/${ticket.ticket_id}/edit?addAsset=true`)
        }
    };

    if (loading) {
        return (
            <div className='relative w-4/12'>
                <Loader color={'#F48A21'} size='15' speed='1' thickness='2' />
            </div>
        );
    }

    const viewAssetHistory = (asset) => {
        if (asset?.serial_number?.length > 1) {
            let modalComponent = <AssetHistory assetId={asset?.fk_asset_id} assetSource={asset.is_spare === 0 ? 'asset' : 'spare'} />;
            let modalWidth = '50em',
                modalHeight = '40em';
            let head = <span className='' >
                Asset History
                <span className={`ml-2 text-font14 text-${getAssetColor(asset)}`} >{asset?.serial_number}</span>
            </span>

            dispatch(openCustomModal({ modalComponent, heading: head, modalWidth, modalHeight }));
        }
    };

    const showNeedRmaSupportAction = (ticket?.serviceTypeId !== serviceTypeIds.reverse_logistics && ticket?.replacement_required === 1 && ticket?.signOffAccept === 0 && ticket?.reachedSite === 1 && ticket?.isRmaRaised === 0 && (isScm(loggedUser.role.id, loggedUser.type) || isCustomerGroup(loggedUser.role.id, loggedUser.type)));

    const showMoveToRma = (ticket?.serviceTypeId !== serviceTypeIds.reverse_logistics && ticket?.replacement_required === 1 && ticket?.signOffAccept === 0 && ticket?.isRmaRaised === 0 && (isScm(loggedUser.role.id, loggedUser.type) || isCustomerGroup(loggedUser.role.id, loggedUser.type)) && ticket?.signOffRequest === 0);

    return (
        <div className='w-full'>

            {ticket?.isRmaRaised === 1 && ticket?.warehouse_details?.name?.length > 0 ? <div className=' w-[95%] truncate' >
                <UserPopup userId={ticket?.warehouse_owner} anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                    }}>
                    <IconToolTip title={<>
                        Name: {validateStringValue(ticket?.warehouse_details?.name)}
                        <br />
                        Address : {validateStringValue(ticket?.warehouse_details?.address)}
                    </>
                    } >
                        <span
                            className={`bg-tagbg scogoprimary text-font10 px-2 py-1 rounded-3px max-w-full hover:text-scogoorange text-ellipsis truncate`}
                        >
                            {validateStringValue(ticket?.warehouse_details?.name)}
                        </span>
                    </IconToolTip>
                </UserPopup>
            </div> : <></>
            }

            <div className='flex-wrap flex items-center gap-x-1 mt-2'>
                {assetList.length > 0 &&
                    assetList?.slice(0, dataToShowPerRow)?.map((asset, index) => {
                        const status = `${asset?.status?.length > 0 ? ` ${asset?.status}` : ""}`
                        const workingStatus = `${asset?.working_condition?.length > 0 ? `, ${asset?.working_condition}` : ""}`;
                        return (
                            <IconToolTip key={index} title={`${status} ${workingStatus}`} onClick={() => viewAssetHistory(asset)}>
                                <span className={`bg-${getAssetColor(asset)} cursor-pointer text-white max-w-12   text-font10 px-2 flex items-center rounded-3px mb-1`}>
                                    <span className='max-w-4/5 truncate'  >
                                        {(asset?.serial_number?.length > 0 ? asset?.serial_number : asset?.title)}
                                    </span>
                                    <span>
                                        {(asset?.count > 1 ? `: ${asset?.count}` : '')}
                                    </span>
                                    <span className="material-icons text-font12 ml-1 hover:text-scogobgsky" onClick={(event) => {
                                        event.stopPropagation();
                                        const text = asset?.serial_number?.length > 0 ? asset?.serial_number : asset?.title

                                        dispatch(copyToClipboard({ data: text }))
                                    }}>
                                        content_copy
                                    </span>
                                </span>
                            </IconToolTip>
                        );
                    })}

                {assetList?.length > dataToShowPerRow && (
                    <>
                        <AssetPopup assetList={assetList?.slice(dataToShowPerRow)} anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }} >
                            <span className='text-font12 round font-normal text-scogoorange relative cursor-pointer hover:text-scogobgsky countOfBOQ'>
                                +{assetList?.length - dataToShowPerRow}
                            </span>
                        </AssetPopup>
                    </>
                )}
            </div>
            {showAddAsset && <ButtonScogoOrange buttonClass='text-font11' textOrComponent={'Add Assets'} onClick={onAddAssetClick} />}
            {showUpdateRmaButton && <ButtonScogoOrange buttonClass='text-font11' textOrComponent={ticket.cl_rma_response ? 'Edit RMA' : 'Update RMA'} onClick={openUpdateRmaModal} />}
            {/* {showNeedRmaSupportAction && <div className='pt-1'>
                <ButtonScogoOrange buttonClass='text-font11' textOrComponent={'Device Replacement'} onClick={() => onINeedRMASupportClick({ ticketId: ticket.ticket_id })} />
            </div>} */}
            {showMoveToRma && <div className='pt-1'>
                <ButtonScogoOrange buttonClass='text-font11' textOrComponent={'Move to RMA'} onClick={() => onINeedRMASupportClick({ ticketId: ticket.ticket_id, moveToRma: true })} />
            </div>}
        </div>
    );
};
