import _ from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';
import ReactSelect, { components } from 'react-select';

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <span className='material-icons text-font10 font-bold'>expand_more</span>
        </components.DropdownIndicator>
    );
};

const ClearIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <span className='material-icons text-font10 font-bold'>clear</span>
        </components.DropdownIndicator>
    );
};

const customStyles = {
    menuPortal: (base) => ({
        ...base,
        zIndex: 99999,
        position: 'fixed',
    }),
    placeholder: (base) => ({
        ...base,
        fontSize: window.innerWidth >= 1024 ? '1rem' : '1.2rem',
        padding: 0,
        paddingTop: 0,
        paddingBottom: 0,
    }),
    multiValueLabel: (base) => ({
        ...base,
        fontSize: window.innerWidth >= 1024 ? '1rem' : '1.2rem',
    }),
    singleValue: (base) => ({
        ...base,
        fontSize: window.innerWidth >= 1024 ? '1rem' : '1.2rem',
        padding: 0,
    }),
    container: (base) => ({
        ...base,
        paddingTop: 0,
        paddingBottom: 0,
    }),
    menu: (base) => ({
        ...base,
        marginTop: 0,
    }),
    control: (provided, state) => ({
        ...provided,
        minHeight: '2.9rem',
        border: state.isFocused ? 'none' : 'none',
        outline: state.isFocused ? 'none' : 'none',
        borderRadius: 0,
    }),
};

const { Option } = components;
const IconOption = (props) => (
    <Option {...props}>
        <div className='flex items-center gap-2 '>
            <span className='material-icons-outlined material-icons text-font15 inputFontSize'>{props.data.icon}</span>
            {props.data?.label}
        </div>
    </Option>
);

const Select = ({
    id,
    name,
    label,
    placeholder,
    validate,
    required = false,
    options,
    isMulti,
    isLoading,
    isClearable = true,
    readOnly,
    shouldUnregister,
    className = '',
    style = {},
    onFocus = () => { },
    onChange: onSelectChange = () => { },
    onInputChange = () => { },
    renderOption,
    minMenuHeight = 300,
    maxMenuHeight = 300,
    renderInfo,
    labelClassName = '',
    findOption
}) => {
    const {
        formState: { errors },
        control,
    } = useFormContext();

    const components = { DropdownIndicator, ClearIndicator };
    if (!renderOption) components.Option = IconOption;

    const error = _.get(errors, name) ? _.get(errors, name).message : '';
    return (
        <div
            className={`px-2 relative font-normal text-font09 md:text-font11 ${className}`}
            style={{
                minHeight: 0,
                minWidth: 0,
                ...style,
            }}
        >
            <label className='relative block border-b-2 border-scogoddd'>
                {label && (
                    <span className={`text-scogo99 font-normal text-font09 md:text-font11 labelFotnSize ${labelClassName}`}>
                        {label} {required && <span className='text-scogoorange'>*</span>}{' '}
                        {renderInfo ? renderInfo : <></>}
                    </span>
                )}
                <Controller
                    key={name}
                    control={control}
                    name={name}
                    rules={{ required: { value: required, message: 'Required' }, validate }}
                    shouldUnregister={shouldUnregister}
                    render={({ field: { onChange, onBlur, value, name, ref } }) => {
                        if (typeof value === 'string' && typeof findOption === 'function') {
                            let find = findOption(options, value);
                            if (find) value = find;
                        }
                        return <ReactSelect
                            minMenuHeight={minMenuHeight}
                            maxMenuHeight={maxMenuHeight}
                            id={id}
                            onFocus={(_) => onFocus(name)}
                            components={components}
                            isSearchable
                            styles={customStyles}
                            menuShouldBlockScroll
                            className='select-text truncate w-full text-font09 md:text-font11'
                            options={options}
                            isLoading={isLoading}
                            onChange={(value) => {
                                onChange(value);
                                onSelectChange(value);
                            }}
                            isMulti={isMulti}
                            onBlur={onBlur}
                            value={value}
                            name={name}
                            ref={ref}
                            isClearable={isClearable}
                            menuPlacement={'auto'}
                            menuPosition='fixed'
                            onInputChange={onInputChange}
                            formatOptionLabel={renderOption}
                            isDisabled={readOnly}
                            placeholder={placeholder}
                        />
                    }}
                />
            </label>
            <div className='flex mb-1'>
                {error && <p className='text-scogoclosed text-font08 font-normal inputFontSize h-6'>{error}</p>}
            </div>
        </div>
    );
};

export default Select;
