import React, { useEffect, useState, useRef } from 'react';
import { parseAdvancedSearchParamsFromString, serializeAdvancedSearchParams } from '../utils/utils';
import ButtonScogoClosedOutlined from './Buttons/ButtonScogoClosedOutlined';
import ButtonScogoPrimary from './Buttons/ButtonScogoPrimary';
import { textCss } from './Css';
import IconToolTip from './IconToolTip';

import Popover from './Popover';

const SearchBox = ({ searchTerm, onChange, onCancel, placeholder, allowAdvancedSearch, onAdvancedSearchApply = () => { }, advancedSearchFields = [], inputWidth = 'w-96', inputWidthOnFocus = 'w-608', showClosedIcon = true, onKeyDown = () => { }, isExpandingEnabled = true, autoFocus = true, inputClass = '' }) => {
    const [advancedSearchParams, setAdvancedSearchParams] = useState();
    const [prevAdvancedSearchParams, setPrevAdvancedSearchParams] = useState();
    const [isFocus, setIsFocus] = useState(false);
    useEffect(() => {
        if (typeof searchTerm === 'string' && parseAdvancedSearchParamsFromString(searchTerm)) {
            setAdvancedSearchParams(parseAdvancedSearchParamsFromString(searchTerm));
            setPrevAdvancedSearchParams(parseAdvancedSearchParamsFromString(searchTerm));
        } else {
            setAdvancedSearchParams();
        }
    }, [searchTerm]);

    const iconContainerRef = useRef();
    const popOverRef = useRef();

    const isAdvancedSearchActive = advancedSearchParams && Object.keys(advancedSearchParams).length > 0;
    const advancedSearchIcon = (
        <IconToolTip placement={'bottom'} title={`Advanced Search`}>
            <span className={`material-icons hover:text-scogoorange cursor-pointer text-font16`} onClick={() => setIsFocus(true)}>
                tune
            </span>
        </IconToolTip>
    );
    const handleAdvancedSearch = () => {
        onAdvancedSearchApply(serializeAdvancedSearchParams(advancedSearchParams));
        popOverRef.current.close();
    };
    const advancedSearch = (
        <Popover trigger={advancedSearchIcon} ref={popOverRef} containerRef={iconContainerRef} onClose={() => setIsFocus(false)}>
            <div className={'w-608'}>
                {advancedSearchFields.map((field) => (
                    <div className={`px-4 py-3 `} key={field.label}>
                        <label className='block border-b-2 border-scogoddd'>
                            {<p className={`${textCss?.inputLabel}`}>{field.label}</p>}
                            <input
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleAdvancedSearch();
                                    }
                                }}
                                style={{ height: '3.1rem' }}
                                type='text'
                                className={`${textCss?.inputText} block w-full border-none focus:ring-0`}
                                value={advancedSearchParams?.[field.key]}
                                onChange={(e) => setAdvancedSearchParams((prev) => ({ ...prev, [field.key]: e.target.value.trimStart() }))}
                            />
                        </label>
                    </div>
                ))}

                <ButtonScogoClosedOutlined
                    buttonClass='float-right my-5 mr-3'
                    textOrComponent={'Cancel'}
                    onClick={() => {
                        popOverRef.current.close();
                        setAdvancedSearchParams(prevAdvancedSearchParams);
                    }}
                />
                <ButtonScogoPrimary
                    buttonClass='float-right my-5 mx-3'
                    textOrComponent='Search'
                    onClick={() => {
                        handleAdvancedSearch();
                    }}
                />
            </div>
        </Popover>
    );
    const isSearchInputEmpty = !searchTerm || searchTerm.length === 0;

    return (
        <div className='text-gray-400 focus-within:text-gray-600 relative flex items-center'>
            <img className={'loginput-imgdiv  h-6 w-6 mr-4 ml-4'} src={process.env.PUBLIC_URL + '/img/search.png'} alt='' />
            <input
                value={searchTerm}
                onChange={onChange}
                onKeyDown={onKeyDown}
                placeholder={placeholder}
                autoFocus={autoFocus}
                className={`text-scogo15 rounded-md py-2 pl-12 focus:outline-none mx-auto border-scogoccc focus:ring-r-none text-xl items-center cursor-pointer pr-12 ${(isFocus || !isSearchInputEmpty) && isExpandingEnabled ? inputWidthOnFocus : inputWidth
                    } transition-all duration-500 ${isAdvancedSearchActive && 'pr-16'} ${inputClass}`}
                onFocus={() => setIsFocus(true)}
                onBlur={() => setIsFocus(false)}
            />
            <div className='absolute flex right-0 h-full pr-2 py-2' ref={iconContainerRef}>
                {allowAdvancedSearch && advancedSearch}
                {(!isSearchInputEmpty && showClosedIcon) ?
                    <IconToolTip placement={'bottom'} title={`Clear`}>
                        <span
                            className='material-icons hover:text-scogoorange cursor-pointer text-font16'
                            onClick={() => {
                                onCancel();
                                setAdvancedSearchParams();
                                setPrevAdvancedSearchParams();
                            }}
                        >
                            close
                        </span>
                    </IconToolTip>
                    : <></>}
            </div>
        </div>
    );
};

export default SearchBox;
