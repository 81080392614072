import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Input from "../../../Inventory/reusableComponents/AddOption/Input";
import Form from "../../../Inventory/reusableComponents/AddOption/Form";

import { payPoManually } from '../../../../actions/wallet';
import { payPoFormname } from './BankPopup';

export const PayManualPopup = ({ poItem, closeMenu = () => { }, isRefresh, enduserId }) => {
    const dispatch = useDispatch();
    const initialValues = {
        file: "",
    };

    const [form, setForm] = useState(initialValues);

    const { vendorId, ticketId, _id, totalPrice } = poItem;

    const payPoData = {
        type: 'PAY_PO_ITEM_MANUALLY',
        vendorId: vendorId,
        ticketId: ticketId,
        itemId: _id,
        withdrawAmount: totalPrice,
        attachments: []
    };

    const submit = (form) => {
        const uploadedData = [...form.file];
        const files = uploadedData.map((file, idx) => {
            const now = new Date();
            const id = now.getTime() + idx;
            return { id, caption: '', file, };
        })
        if (files.length > 0) {
            dispatch(payPoManually({ ticketId, fileData: files, reason: "PO_MANUAL_PAYMENT", data: payPoData, formName: payPoFormname, isRefresh, callViewTicket: true, enduserId }));
        }
    };

    return (
        <div className='py-2 px-2 h-full w-full overflow-hidden'>
            <p className='text-scogogray text-font16'>Are you sure you want to pay manually?</p>
            <Form
                className="w-full "
                submit={(e) => {
                    submit(e);
                }}
                buttonPostion={"justify-end"}
                onCancel={closeMenu}
                initialValues={initialValues}
                form={form}
                setForm={setForm}
                formName={payPoFormname}
                submitButtonName="Yes"
            >
                <div className="px-3 my-5">
                    <Input
                        type="upload"
                        required
                        label='Files'
                        name="file"
                        multiple={true}
                    />
                </div>
            </Form>
        </div>
    );
};
